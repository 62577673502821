import React, { useState, useEffect } from 'react';
import {
    Typography,
    Link,
    Grid,
    Card,
    CardContent,
    Tooltip,
    FormControlLabel,
    Switch
} from "@material-ui/core";
import Skeleton from "react-loading-skeleton";
import {
    getStatusColor,
    getStatusText,
    isAuthorized
} from "../../helpers/utils";
import LaunchIcon from '@material-ui/icons/Launch';
import CustomButton from "../custombutton/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next'
import { AppConstants } from '../../constants/appconstants';
import { EnvironmentModel, EnvironmentState, UserRole } from '../../models/Environment.model';
import { getProgressIcon } from '../../helpers/utils.ext';
import { CreateApp_TemplateTypes } from '../../constants/enums';
import CopyIcon from '../copyicon/CopyIcon';

type Props = {
    environmentState: EnvironmentState,
    tab: string,
    startEnv?: () => void,
    linkClick?: () => void,
    helm?: boolean
    envRole: UserRole,
    environmentDetails: EnvironmentModel,
    externalHandler?: () => void
}

const useStyles = makeStyles((theme) => ({
    overviewImg: {
        width: 120,
        float: "right",
        [theme.breakpoints.down('xs')]: {
            width: 80
        }
    },
    envState: {
        display: "inline",
    },
    // openLinkIcon: {
    //     fontSize: "0.9rem !important",
    //     marginLeft: "5px",
    //     verticalAlign: "middle",
    // },
    // topIcon: {
    //     height: "0.8rem!important",
    //     fontSize: "1rem!important", 
    // },
    cnameLink: {
        color: "blue!important",
    },
    externalUrl: {
        display: 'flex',
        gap: 4,
        paddingBottom: 4
    },
    progress:{display:'flex',alignItems:'center'}
}));

export default function DomainInfoCard(props: Props) {
    const classes = useStyles();
    const [t] = useTranslation();
    const [cname, setCname] = useState("")
    const [externalUrl, setExternalUrl] = useState("")
    const [internalUrl, setInternalUrl] = useState("")
    const [statusColor, setStatusColor] = useState("")
    const [statusTxt, setStatusText] = useState("")
    const { environmentState } = props;

    useEffect(() => {
        if (props?.environmentState && props?.environmentState.state === AppConstants.EnvironmentStatus.Running) {
            if (props?.environmentState.cname !== "") {
                setCname("https://" + props?.environmentState.cname)
            }
            if (props?.environmentState.external_url !== "") {
                setExternalUrl("https://" + props?.environmentState.external_url)
            }
            if (props?.environmentState.master_url !== "") {
                setInternalUrl("https://" + props?.environmentState.master_url)
            }
        }
        setStatusColor(getStatusColor(props?.environmentState?.state))
        if(props.helm){
            setStatusText(`Your Chart is ${environmentState.state}.`)
        }
        else if(props?.environmentDetails?.service_type === CreateApp_TemplateTypes.manageDB){
            setStatusText(`Your Service is ${environmentState.state}.`)
        }
        else{
            setStatusText(getStatusText(props?.environmentState?.state))
        }
    }, [props?.environmentState])
    
    return (
        <>
            <Card className={props.tab === 'Overview' ? 'overviewCard' : 'customCard'} data-test="main-card">
                <CardContent>
                    <Grid>
                        {props.tab === 'Overview' &&
                            <Grid
                                data-test="overview-tab"
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                spacing={2}
                            >
                                <Grid item md={10} xs={9}>
                                    {environmentState?.state && (
                                        <div className={classes.progress}>
                                            <Tooltip title="Status">
                                               {getProgressIcon(environmentState.state,statusColor,12)}
                                               
                                            </Tooltip>
                                            <Typography style={{ color: statusColor,marginTop:2 }} variant="body2" className={classes.envState} data-test="state-typography">
                                                {environmentState.state}
                                            </Typography>
                                        </div>
                                    )}
                                    <Typography style={{ color: 'black' }} variant="h4" data-test="status-text">
                                        {" "} {statusTxt}{" "}
                                    </Typography>
                                    {!props.helm && (
                                        <> 
                                            {
                                                (environmentState?.master_url && environmentState?.state === AppConstants.EnvironmentStatus.Running) && (
                                                    <Typography className="cnameLink" >
                                                        <Link
                                                            href={internalUrl}
                                                            target="_blank"
                                                            className="cnameLink"
                                                            rel="noreferrer"
                                                            underline="always"
                                                            data-test="cnameLink"
                                                        >
                                                            {environmentState.master_url}<LaunchIcon className="openLinkIcon" />
                                                        </Link>
                                                    </Typography>

                                                )
                                            }
                                            {
                                                (environmentState?.cname && environmentState?.state === AppConstants.EnvironmentStatus.Running) && (
                                                    <Typography className="cnameLink" >
                                                        <Link
                                                            href={cname}
                                                            target="_blank"
                                                            className="cnameLink"
                                                            rel="noreferrer"
                                                            underline="always"
                                                            data-test="cnameLink"
                                                        >
                                                            {environmentState.cname}<LaunchIcon className="openLinkIcon" />
                                                        </Link>
                                                    </Typography>

                                                )
                                            }

                                            {
                                                environmentState?.state === "Stopped" && isAuthorized("update", props.envRole?.name) &&
                                                <div className="m-t-20" data-test="start-now">
                                                    <CustomButton onClick={props.startEnv} label={t('StartNow')} data-test={"startNowButton"} />
                                                </div>
                                            }
                                            {
                                                environmentState && environmentState.state !== "Stopped" && (props.environmentDetails?.service_type !== CreateApp_TemplateTypes.manageDB) &&
                                                <div className="seeVariables" data-test="see-variables">
                                                    <Link onClick={props.linkClick} underline="none" data-test={"domainSettingLink"}> {t('Environment.OverviewTab.domainSettings')} </Link>
                                                </div>
                                            }

                                            {
                                                environmentState && environmentState.state !== "Stopped" && props.environmentDetails?.service_type === CreateApp_TemplateTypes.manageDB &&
                                                <div className="seeVariables" data-test="see-variables">
                                                    <Grid item data-test="externalUrl-switch">
                                                        <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={props.environmentDetails.external_url}
                                                                onChange={props.externalHandler}
                                                                name="enabled"
                                                                color="primary"
                                                            />
                                                            }
                                                        data-test="externalUrl-switch-button"
                                                        label={<Typography variant="h5">External URL</Typography>}
                                                        labelPlacement="start"
                                                        className='externalUrllabel'
                                                        />
                                                    </Grid>
                                                    {
                                                        (environmentState?.external_url && environmentState?.state === AppConstants.EnvironmentStatus.Running) && (
                                                            <Typography className="externalUr"> 
                                                                <Link
                                                                    href={externalUrl}
                                                                    target="_blank"
                                                                    className="cnameLink"
                                                                    rel="noreferrer"
                                                                    underline="always"
                                                                    data-test="externalURLLink"
                                                                >
                                                                    {environmentState.external_url}
                                                                </Link>
                                                                <CopyIcon copyText={environmentState.external_url} fontSize='small'/>
                                                            </Typography>
                                                        )
                                                    }
                                                </div>
                                            }
                                        </>
                                    )}
                                </Grid>
                                <Grid item md={2} xs={3}>
                                    <img
                                        data-test="overview-image"
                                        src="/images/infographics/overview.svg"
                                        alt="No Apps"
                                        className={classes.overviewImg}
                                    />
                                </Grid>
                            </Grid>
                        }
                        {props.tab === 'Domain' &&
                            (
                                environmentState?.state ? (
                                    <>
                                        <Grid
                                            data-test="domain-card"
                                            container
                                            direction="row"
                                            justify="center"
                                            alignItems="center"
                                        >
                                            <Grid item md={10} xs={10}>
                                                <Typography style={{ color: "black" }} variant="h4" data-test="domain-status-text">
                                                    {" "}
                                                    {statusTxt}{" "}
                                                </Typography>
                                                {environmentState.state === AppConstants.EnvironmentStatus.Running &&
                                                    <Typography className="cnameLink">
                                                        <Link
                                                            data-test="domain-link"
                                                            href={cname}
                                                            target="_blank"
                                                            className="cnameLink"
                                                            rel="noreferrer"
                                                            underline="always"
                                                        >
                                                            {environmentState?.cname}
                                                            <LaunchIcon className="openLinkIcon" />
                                                        </Link>
                                                    </Typography>
                                                }
                                            </Grid>
                                            <Grid item md={2} xs={2} justify="flex-end">
                                                <img
                                                    data-test="domain-overview"
                                                    src="/images/infographics/overview.svg"
                                                    alt="No Apps"
                                                />
                                            </Grid>
                                        </Grid>
                                    </>
                                ) : (
                                    <Card className="skeletoncard" data-test="domain-skeleton">
                                        <CardContent>
                                            <Grid>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justify="center"
                                                    alignItems="center"
                                                >
                                                    <Grid item md={10} xs={10}>
                                                        <Typography style={{ color: "black" }} variant="h4">
                                                            <Skeleton height={20} width="50%" />
                                                        </Typography>
                                                        <Typography className="cnameLink">
                                                            <Skeleton height={20} width="20%" />
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item md={2} xs={2} justify="flex-end">
                                                        <Skeleton height={150} width={"100%"} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                )
                            )}
                    </Grid>
                </CardContent>
            </Card>
        </>
    )
}