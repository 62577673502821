import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import SubscriptionForm from "../SubscriptionForm";
import { editSubscriptionCall } from "../redux/actions";
import { fetchSubscription } from "../redux/actions";
import BackdropLoader from '../../../components/loader/BackdropLoader';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Dispatch } from "redux";
import { History } from 'history';

interface Props extends WithTranslation, PropsFromRedux {
  subId: number;
  handleCreateEditSuccess:()=>void;
  history?: History;
}

export class EditSubscription extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      // subscription: this.props.location.subscription,
      //subId: this.props.match.params.id,
    };
  }

  componentDidMount() {
    this.props.fetchSubData(this.props.subId);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if(nextProps) {
      if(!this.props.editSubscriptionCallSuccess && nextProps.editSubscriptionCallSuccess){
        this.props.handleCreateEditSuccess();
      }
    }
  }

  editSubscription = (values: any) => {
    const payload = {
      name: values.subscriptionName,
      // description: values.label ? values.label : "",
      disk_space: parseFloat(values.diskSpace),
      memory: parseFloat(values.memory),
      data_transfer: parseFloat(values.dataTransfer),
      cores: parseFloat(values.cores),
      apps: parseInt(values.apps),
      price: parseFloat(values.price),
      backups: parseInt(values.backups),
      active: values.active,
      resource_list : {
        configmaps: values.resource_list.configmaps.toString(),
        persistentvolumeclaims: values.resource_list.persistentvolumeclaims.toString(),
        pods: values.resource_list.pods.toString(),
        replicationcontrollers: values.resource_list.replicationcontrollers.toString(),
        secrets: values.resource_list.secrets.toString(),
        services: values.resource_list.services.toString(),
        loadbalancers: values.resource_list.loadbalancers.toString(),
        gpu: values.resource_list.gpu.toString()
      }
    };
    this.props.editSubscriptionApiCall(values.id, payload, this.props.history);
  };

  render() {
    const { t, subscription } = this.props;
    return (
        <div data-test="main-container">
            <SubscriptionForm
              //history={history}
              //t={t}
              subscriptionAction={(values: any) => this.editSubscription(values)}
              subData={subscription}
              edit={true}
            />
            {this.props.executingEditSubscription && <BackdropLoader message={t('Subscription.SubscriptionForm.updatingSubscription')} />}
            {this.props.fetchingSubscription && <BackdropLoader message={t('Subscription.SubscriptionForm.fetchingSubscription')} />}
        </div>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => {
  return {
    subscription: state.SubscriptionReducer.subscription,
    editSubscriptionCallSuccess: state.SubscriptionReducer.editSubscriptionCallSuccess,
    executingEditSubscription: state.SubscriptionReducer.executingEditSubscription,
    fetchingSubscription: state.SubscriptionReducer.fetchingSubscription
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  editSubscriptionApiCall: (subId: number, payload: any, history: History|undefined) =>
    dispatch(editSubscriptionCall(subId, payload, history)),
  fetchSubData: (subId: number) => {
    dispatch(fetchSubscription(subId));
  }
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withTranslation()(EditSubscription));
