import { call, takeLatest, put, select } from 'redux-saga/effects';
import axios from 'axios';
import endpoints from '../../../constants/endpoints';
import { sessionTokenSelector } from '../../login/redux/selectors'
import {
  FETCH_BACKUP_LIST,
  FETCH_BACKUP_LIST_SUCCESS,
  FETCH_BACKUP_LIST_FAILURE,
  CREATE_BACKUP,
  CREATE_BACKUP_SUCCESS,
  CREATE_BACKUP_FAILURE,
  DELETE_BACKUP,
  DELETE_BACKUP_SUCCESS,
  DELETE_BACKUP_FAILURE,
  PRESERVE_BACKUP,
  PRESERVE_BACKUP_SUCCESS,
  PRESERVE_BACKUP_FAILURE,
  RESTORE_BACKUP,
  RESTORE_BACKUP_SUCCESS,
  RESTORE_BACKUP_FAILURE,
  FETCH_BACKUP_SETTINGS,
  FETCH_BACKUP_SETTINGS_SUCCESS,
  FETCH_BACKUP_SETTINGS_FAILURE,
  UPDATE_BACKUP_SETTINGS,
  UPDATE_BACKUP_SETTINGS_SUCCESS,
  UPDATE_BACKUP_SETTINGS_FAILURE,
  FETCH_BACKUP_RESTORE_LIST,
  FETCH_BACKUP_RESTORE_LIST_SUCCESS,
  FETCH_BACKUP_RESTORE_LIST_FAILURE
} from './actions';
import toast from "../../../components/toast/Toast";

function* fetchBackupList(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const config = { headers: { 'Authorization': 'basic ' + sessionToken } }
    const endpoint = payload.data?.isHelm ?
      endpoints.HELMENVIRONMENT.GET_BACKUP_LIST.replace(":eId", payload.data.eId) :
      endpoints.BACKUP.GET_BACKUP_LIST.replace(":eId", payload.data.eId)

    const response = yield axios.get(endpoint, config)
    const data = response.data;
    if (data !== null) {
      yield put({ type: FETCH_BACKUP_LIST_SUCCESS, data })
    } else {
      yield put({ type: FETCH_BACKUP_LIST_FAILURE })
    }
  } catch (error) {
    yield put({ type: FETCH_BACKUP_LIST_FAILURE })
    if (error && error.response && error.response.data && error.response.data.error) {
      toast.error(error.response.data.error);
    }
  }
}

function* createBackup(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const config = { headers: { 'Authorization': 'basic ' + sessionToken } }
    const endpoint = payload.data?.isHelm ?
      endpoints.HELMENVIRONMENT.CREATE_BACKUP.replace(":eId", payload.data.eId) :
      endpoints.BACKUP.CREATE_BACKUP.replace(":eId", payload.data.eId)
    yield axios.post(endpoint, payload.data.jsonBody, config);
    yield put({ type: CREATE_BACKUP_SUCCESS })
    yield call(fetchBackupList, payload);
    toast.success("Creating new snapshot initiated");
  } catch (error) {
    if (error && error.response && error.response.data && error.response.data.error) {
      toast.error(error.response.data.error);
      yield put({ type: CREATE_BACKUP_FAILURE })
    }
  }
}

function* deleteBackup(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const config = { headers: { 'Authorization': 'basic ' + sessionToken } }
    const endpoint = payload.data?.isHelm ?
      endpoints.HELMENVIRONMENT.DELETE_BACKUP.replace(":eId", payload.data.eId).replace(':bId', payload.data.bId) :
      endpoints.BACKUP.DELETE_BACKUP.replace(":eId", payload.data.eId).replace(':bId', payload.data.bId)
    yield axios.delete(endpoint, config);
    yield put({ type: DELETE_BACKUP_SUCCESS })
    yield call(fetchBackupList, payload);
    toast.success("Deleting snapshot initiated");
  } catch (error) {
    if (error && error.response && error.response.data && error.response.data.error) {
      toast.error(error.response.data.error);
      yield put({ type: DELETE_BACKUP_FAILURE })
    }
  }
}

function* preserveBackup(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const config = { headers: { 'Authorization': 'basic ' + sessionToken } }
    const endpoint = payload.data?.isHelm ?
      endpoints.HELMENVIRONMENT.PRESERVE_BACKUP.replace(":eId", payload.data.eId).replace(':bId', payload.data.bId) :
      endpoints.BACKUP.PRESERVE_BACKUP.replace(":eId", payload.data.eId).replace(':bId', payload.data.bId)
    yield axios.post(endpoint, payload.data.jsonBody, config);
    yield put({ type: PRESERVE_BACKUP_SUCCESS })
    yield call(fetchBackupList, payload);
    toast.success("Snapshot " + (payload.data.jsonBody && payload.data.jsonBody.preserved ? "preserved" : "unpreserved"));
  } catch (error) {
    if (error && error.response && error.response.data && error.response.data.error) {
      toast.error(error.response.data.error);
      yield put({ type: PRESERVE_BACKUP_FAILURE })
    }
  }
}

function* restoreBackup(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const config = { headers: { 'Authorization': 'basic ' + sessionToken } }
    const endpoint = payload.data?.isHelm ?
      endpoints.HELMENVIRONMENT.RESTORE_BACKUP.replace(":eId", payload.data.eId).replace(':bId', payload.data.bId) :
      endpoints.BACKUP.RESTORE_BACKUP.replace(":eId", payload.data.eId).replace(':bId', payload.data.bId)
    const response = yield axios.post(endpoint, null, config);
    const data = response.data;
    if (data !== null) {
      yield put({ type: RESTORE_BACKUP_SUCCESS, data })
      toast.success("Restoring snapshot initiated");
    } else {
      yield put({ type: RESTORE_BACKUP_FAILURE })
    }
  } catch (error) {
    if (error && error.response && error.response.data && error.response.data.error) {
      toast.error(error.response.data.error);
      yield put({ type: RESTORE_BACKUP_FAILURE })
    }
  }
}

function* fetchBackupSettings(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const config = { headers: { 'Authorization': 'basic ' + sessionToken } }
    const endpoint = payload.data?.isHelm ?
      endpoints.HELMENVIRONMENT.GET_BACKUP_SETTINGS.replace(":eId", payload.data.eId) :
      endpoints.BACKUP.GET_BACKUP_SETTINGS.replace(":eId", payload.data.eId)
    const response = yield axios.get(endpoint, config);
    const data = response.data;
    if (data !== null) {
      let settings;
      if (data.settings) {
        settings = JSON.parse(data.settings);
      }
      yield put({ type: FETCH_BACKUP_SETTINGS_SUCCESS, data: settings })
    } else {
      yield put({ type: FETCH_BACKUP_SETTINGS_FAILURE })
    }
  } catch (error) {
    if (error && error.response && error.response.data && error.response.data.error) {
      toast.error(error.response.data.error);
      yield put({ type: FETCH_BACKUP_SETTINGS_FAILURE })
    }
  }
}

function* updateBackupSettings(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const config = { headers: { 'Authorization': 'basic ' + sessionToken, 'Content-Type': "text/plain" } }
    const endpoint = payload.data?.isHelm ?
      endpoints.HELMENVIRONMENT.UPDATE_BACKUP_SETTINGS.replace(":eId", payload.data.eId) :
      endpoints.BACKUP.UPDATE_BACKUP_SETTINGS.replace(":eId", payload.data.eId)
    const response = yield axios.put(endpoint, JSON.stringify(JSON.stringify(payload.data.jsonBody)), config);
    const data = response.data;
    if (data !== null) {
      yield put({ type: UPDATE_BACKUP_SETTINGS_SUCCESS, data })
      yield call(fetchBackupSettings, payload)
      toast.success("Backup settings updated");
      if (payload.data.callback) {
        payload.data.callback();
      }
    } else {
      yield put({ type: UPDATE_BACKUP_SETTINGS_FAILURE })
    }
  } catch (error) {
    yield put({ type: UPDATE_BACKUP_SETTINGS_FAILURE })
    if (error && error.response && error.response.data && error.response.data.error) {
      toast.error(error.response.data.error);
    }
  }
}

function* fetchBackupRestoreList(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const config = { headers: { 'Authorization': 'basic ' + sessionToken } }
    const endpoint = payload.data?.isHelm ?
      endpoints.HELMENVIRONMENT.GET_BACKUP_RESTORE_LIST.replace(":eId", payload.data.eId) :
      endpoints.BACKUP.GET_BACKUP_RESTORE_LIST.replace(":eId", payload.data.eId)
    const response = yield axios.get(endpoint, config)
    const data = response.data;
    if (data !== null) {
      yield put({ type: FETCH_BACKUP_RESTORE_LIST_SUCCESS, data })
    } else {
      yield put({ type: FETCH_BACKUP_RESTORE_LIST_FAILURE })
    }
  } catch (error) {
    yield put({ type: FETCH_BACKUP_RESTORE_LIST_FAILURE })
    if (error && error.response && error.response.data && error.response.data.error) {
      toast.error(error.response.data.error);
    }
  }
}

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(FETCH_BACKUP_LIST, fetchBackupList);
  yield takeLatest(CREATE_BACKUP, createBackup);
  yield takeLatest(DELETE_BACKUP, deleteBackup);
  yield takeLatest(PRESERVE_BACKUP, preserveBackup);
  yield takeLatest(RESTORE_BACKUP, restoreBackup);
  yield takeLatest(FETCH_BACKUP_SETTINGS, fetchBackupSettings);
  yield takeLatest(UPDATE_BACKUP_SETTINGS, updateBackupSettings);
  yield takeLatest(FETCH_BACKUP_RESTORE_LIST, fetchBackupRestoreList);
}