import {
    Card,
    CardActions,
    CardContent,
    //Checkbox,
    Divider,
    //FormControl,
    // FormControlLabel,
    Button,
    Popover,
    Link,
    //RadioGroup,
    // Switch,
    Typography,
    Grid,
    //Radio,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
//import InfoIcon from '@material-ui/icons/Info';
import React, { Component } from 'react';
import { withTranslation,WithTranslation } from "react-i18next";
import { connect,ConnectedProps } from 'react-redux';
import DeploymentStrategy from '../../../../../components/cddeployment/DeploymentStrategy';
import ConfirmActionPopup from '../../../../../components/confirmactionpopup/ConfirmActionPopup';
//import EmailInput from "../../../../../components/emailinput/EmailInput";
import BackdropLoader from '../../../../../components/loader/BackdropLoader';
import { CreateApp_TemplateTypes } from '../../../../../constants/enums';
//import MuiTextField from '../../../../../components/textfield/MuiTextField';
import { isAuthorized } from '../../../../../helpers/utils';
import { updateEnvironment } from '../../../redux/actions'
import { Dispatch } from 'redux';
import { WithStyles, createStyles } from "@material-ui/core";
import { IdeploymentStrategy ,DeploymentValue} from '../../../../../models/Environment.model';
/* istanbul ignore next */
const useStyles = () => createStyles({
    branchInfo: {
        display: 'flex',
        alignItems: 'center'
    },
    paper: {
        maxWidth: 400,
        padding: 10,
        borderRadius: 9
    }
});

interface Iprops extends PropsFromRedux,WithStyles,WithTranslation{
    handleCDChange:()=>void;
}
type Tdata={
    isPush: boolean;
    }
interface Istate {
    isPush:                    boolean;
    isTag:                     boolean;
    isConfirmSettingsSaveOpen: boolean;
    anchorEl: Element | ((element: Element) => Element) | null;
    popOverType:               number;
    oldData:                   Partial<Tdata>;
    deploymentErrors:          any; //[]

    deployment_strategy:       Partial<IdeploymentStrategy>;
}
export class CDSettingsTab extends Component<Iprops,Istate> {
    constructor(props:Iprops) {
        super(props);
        this.state = {
            isPush: false,
            isTag: false,
            // event_type: "all",
            // email_notification: false,
            // emails: [],
            // slack_notification: false,
            // slack_webhook_url: '',
            // webhook_notification: false,
            // webhook_url: '',
            // webhook_token: '',
            isConfirmSettingsSaveOpen: false,
            anchorEl: null,
            popOverType: 0,
            oldData: {},
            deploymentErrors: [],
            deployment_strategy: {},
        }
    }

    componentDidMount() {
        const eventsArray = this.props.cdSettings && this.props.cdSettings.events ? this.props.cdSettings.events.split(',') : [];
        const oldData = {
            isPush: eventsArray.includes("push"),
            //isTag: eventsArray.includes("release"),
            // event_type: this.props.cicdSettings.event_type ? this.props.cicdSettings.event_type : "all",
            // email_notification: this.props.cicdSettings.email_notification ?? false,
            // emails: this.props.cicdSettings.emails ? this.props.cicdSettings.emails.split(',') : [],
            // slack_notification: this.props.cicdSettings.slack_notification ?? false,
            // slack_webhook_url: this.props.cicdSettings.slack_webhook_url ?? "",
            // webhook_notification: this.props.cicdSettings.webhook_notification ?? false,
            // webhook_url: this.props.cicdSettings.webhook_url ?? "",
            // webhook_token: this.props.cicdSettings.webhook_token ?? "",
        }
        this.setState({
            oldData
        })
    }

    // componentWillUnmount() {
    //     //this.props.clearCDSettings();
    // }

    UNSAFE_componentWillReceiveProps = (newProps:Iprops) => {
        if (newProps.cdSettings) {
            if (JSON.stringify(this.props.cdSettings) !== JSON.stringify(newProps.cdSettings)) {
                const eventsArray = newProps.cdSettings.events ? newProps.cdSettings.events.split(',') : [];
                const oldData = {
                    isPush: eventsArray.includes("push"),
                    // isTag: eventsArray.includes("release"),
                    // event_type: newProps.cicdSettings.event_type ? newProps.cicdSettings.event_type : "all",
                    // email_notification: newProps.cicdSettings.email_notification,
                    // emails: newProps.cicdSettings.emails ? newProps.cicdSettings.emails.split(',') : [],
                    // slack_notification: newProps.cicdSettings.slack_notification,
                    // slack_webhook_url: newProps.cicdSettings.slack_webhook_url,
                    // webhook_notification: newProps.cicdSettings.webhook_notification,
                    // webhook_url: newProps.cicdSettings.webhook_url,
                    // webhook_token: newProps.cicdSettings.webhook_token,
                }
                this.setState({
                    isPush: eventsArray.includes("push"),
                    // isTag: eventsArray.includes("release"),
                    // event_type: newProps.cicdSettings.event_type ? newProps.cicdSettings.event_type : "all",
                    // email_notification: newProps.cicdSettings.email_notification,
                    // emails: newProps.cicdSettings.emails ? newProps.cicdSettings.emails.split(',') : [],
                    // slack_notification: newProps.cicdSettings.slack_notification,
                    // slack_webhook_url: newProps.cicdSettings.slack_webhook_url,
                    // webhook_notification: newProps.cicdSettings.webhook_notification,
                    // webhook_url: newProps.cicdSettings.webhook_url,
                    // webhook_token: newProps.cicdSettings.webhook_token,
                    oldData,
                })
            }
        }
    }
    
    /* istanbul ignore next */
    // handlePushTrigger = (e) => {
    //     this.setState({
    //         isPush: e.target.checked
    //     })
    // }

    handleSave = () => {
        this.setState({
            isConfirmSettingsSaveOpen: true
        })
    }

    disagreeSettingsSaveHandler = () => {
        this.setState({
            isConfirmSettingsSaveOpen: false
        })
    }

    agreeSettingsSaveHandler = () => {
        // let _events = this.state.isPush ? "push" : '';
        // if (this.state.isTag)
        //     _events = (_events ? _events + "," : '') + "release";

        const jsonBody:{
            deployment_strategy: Partial<IdeploymentStrategy>;
        } = {
            // events: _events,
            deployment_strategy: this.state.deployment_strategy
            // event_type: this.state.event_type,
            // email_notification: this.state.email_notification,
            // emails: this.state.email_notification ? this.state.emails.join(",") : "",
            // slack_notification: this.state.slack_notification,
            // slack_webhook_url: this.state.slack_notification ? this.state.slack_webhook_url : "",
            // webhook_notification: this.state.webhook_notification,
            // webhook_url: this.state.webhook_notification ? this.state.webhook_url : "",
            // webhook_token: this.state.webhook_token
        }
        this.props.updateEnvironment(this.props.environmentDetails.id, jsonBody);
        this.setState({
            isConfirmSettingsSaveOpen: false
        })
    }
    
    /* istanbul ignore next */
    // handleMouseOver = (e, popType) => {
    //     this.setState({
    //         anchorEl: e.currentTarget,
    //         popOverType: popType
    //     });
    // }

    // handleMouseOut = (e) => {
    //     this.setState({
    //         anchorEl : null
    //       });
    // }

    handlePopoverClose = (e:{}) => {
        this.setState({ anchorEl: null });
    };

    /* istanbul ignore next */
    // testEmail = () => {
    //     this.props.testEmail(this.props.environmentDetails?.id)
    // }

    /* istanbul ignore next */
    // testSlack = () => {
    //     // if(this.state.emails?.trim()?.length > 0){
    //     this.props.testSlack(this.props.environmentDetails?.id)
    //     // }
    // }

    /* istanbul ignore next */
    // testWebhook = () => {
    //     // if(this.state.emails?.trim()?.length > 0){
    //     this.props.testWebhook(this.props.environmentDetails?.id)
    //     // }
    // }

    /* istanbul ignore next */
    // handleEventType = (event) => {
    //     this.setState({ event_type: event.target.value })
    // }
    //isFormValid

    isError = () => {
        let error = false
        // if (this.state.slack_notification) {
        //     error = error || this.state.errors.slack_webhook_url || this.state.slack_webhook_url?.trim()?.length <= 0
        // }
        // if (this.state.webhook_notification) {
        //     error = error || this.state.errors.webhook_url || this.state.errors.webhook_token || this.state.webhook_url?.trim()?.length <= 0 || this.state.webhook_token?.trim()?.length <= 0
        // }
        // if (this.state.email_notification) {
        //     error = error || this.state.errors.emails || this.state.emails?.length <= 0
        // }
        return error
    }

    /* istanbul ignore next */
    hasChanged = () => {
        if (Object.keys(this.state.oldData).length > 0) {
            const newData = {
                isPush: this.state.isPush,
                // isTag: this.state.isTag,
                // event_type: this.state.event_type,
                // email_notification: this.state.email_notification,
                // emails: this.state.emails,
                // slack_notification: this.state.slack_notification,
                // slack_webhook_url: this.state.slack_webhook_url,
                // webhook_notification: this.state.webhook_notification,
                // webhook_url: this.state.webhook_url,
                // webhook_token: this.state.webhook_token,
            }

            let changed = false
            Object.keys(this.state.oldData).forEach((k:string) => {
                if (JSON.stringify(newData[k as keyof typeof newData]) !== JSON.stringify(this.state.oldData[k as keyof Tdata])) {
                    changed = true
                    return
                }
            })
            return changed
        }
    }

    handleDeploymentTypeChange = (payload:DeploymentValue, deploymentType:string, err:any) => {
        this.setState({
            deployment_strategy: {[deploymentType]: payload},
            deploymentErrors: err
        })

    }

    render() {
        const { classes, environmentDetails, envRole, t } = this.props

        return (
            <div data-test="main-container">
                <Card>
                    <form>
                        <Divider />
                        <CardContent>
                            {
                                this.props.cicdSettingsError !== 401 &&
                                <Grid container spacing={1}>
                                    <Grid item md={4} className={classes.branchInfo} data-test="branch-info">
                                        <AccountTreeOutlinedIcon color="primary" />
                                        <Typography variant="h5" className='oneRemLeftMarginSeperator' data-test="branch-name">
                                            { this.props.environmentDetails?.service_type === CreateApp_TemplateTypes.git ? environmentDetails?.git_branch : environmentDetails?.image_tag}
                                        </Typography>
                                        {this.props.environmentDetails?.service_type ===
                                            CreateApp_TemplateTypes.cr &&
                                            isAuthorized("update", envRole.name) && (
                                                <Button
                                                color="primary"
                                                variant="contained"
                                                className="oneRemLeftMarginSeperator"
                                                onClick={this.props.handleCDChange}
                                                >
                                                {t("Environment.SettingsTab.change")}
                                                </Button>
                                            )}
                                    </Grid>
                                    {/* <Grid item md={12} sm={6} xs={12} className='m-t-20'>
                                        <Typography color="primary" variant="h6" >Triggers</Typography>
                                    </Grid>
                                    <Grid item md={12} sm={6} xs={12} >
                                        <FormControlLabel
                                            data-test="push-switch"
                                            control={
                                                <Switch
                                                    name="push"
                                                    color="primary"
                                                    checked={this.state.isPush}
                                                    onChange={e => this.handlePushTrigger(e)}
                                                />
                                            }
                                            label="Push"
                                        />
                                    </Grid> */}
                                    <DeploymentStrategy 
                                        handleChange={(val,type, err) => {
                                            this.handleDeploymentTypeChange(val,type, err)
                                        }}
                                        deployment = {this.props.environmentDetails?.deployment_strategy}
                                    />
                                    {/* <Grid item md={12} sm={6} xs={12} >
                                        <FormControlLabel
                                            data-test="tag-switch"
                                            control={
                                                <Switch
                                                    name="tag"
                                                    color="primary"
                                                    checked={this.state.isTag}
                                                    onChange={e => this.handleTagTrigger(e)}
                                                />
                                            }
                                            label="Tag"
                                        />
                                    </Grid> */}

                                    {/* <Grid item md={12} sm={6} xs={12} >
                                        <Typography color="primary" variant="h6" >Notifications</Typography>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Grid container justify="row">
                                            <Grid item md={2} sm={4} xs={12} className="centertxt" >
                                                <Typography variant="body1">Event Types</Typography>
                                            </Grid>
                                            <Grid item md={10} sm={8} xs={12}>
                                                <FormControl component="fieldset">
                                                    <RadioGroup aria-label="" name="eventType" value={this.state.event_type} onChange={e => this.handleEventType(e)} data-test="event-radio">
                                                        <Grid container spacing={1}>
                                                            <Grid item md={4} sm={4} xs={12}>
                                                                <FormControlLabel value="normal" control={<Radio color="primary" />} label="Normal" />
                                                            </Grid>
                                                            <Grid item md={4} sm={4} xs={12}>
                                                                <FormControlLabel value="error" control={<Radio color="primary" />} label="Error" />
                                                            </Grid>
                                                            <Grid item md={4} sm={4} xs={12}>
                                                                <FormControlLabel value="all" control={<Radio color="primary" />} label="Both" />
                                                            </Grid>
                                                        </Grid>
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item md={12} sm={6} xs={12}>
                                        <FormControlLabel
                                            data-test="email-checkbox"
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={this.state.email_notification}
                                                    onChange={(e) => this.handleIsEmail(e)}
                                                />
                                            }
                                            label="Send Email after build completion"
                                        />
                                    </Grid>
                                    {
                                        this.state.email_notification &&
                                        <Grid item sm={6} xs={12} className='oneRemLeftMarginSeperator' data-test="email-input-grid">
                                            <EmailInput error={this.state.errors.emails} helperText={this.state.helperText.emails} emails={this.state.emails} handleEmails={this.handleEmails} />
                                            <Button className="m-t-10" onClick={this.testEmail} color="primary" variant="contained" disabled={!this.props.cicdSettings?.emails || (this.state.emails.join(",") !== this.props.cicdSettings?.emails)}>Test</Button>
                                        </Grid>
                                    }
                                    <Grid item md={12} sm={6} xs={12}>
                                        <FormControlLabel
                                            data-test="slack-checkbox"
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={this.state.slack_notification}
                                                    onChange={(e) => this.handleIsSlack(e)}
                                                />
                                            }
                                            label="Enable Slack Notifications"
                                        />
                                    </Grid>
                                    {
                                        this.state.slack_notification &&
                                        <Grid item sm={6} xs={12} className='oneRemLeftMarginSeperator' data-test="slack-webhook-grid">
                                            <Grid container direction="column" spacing={1}>
                                                <Grid item className={classes.branchInfo}>
                                                    <Typography variant='h5'>Webhook URL </Typography>
                                                    <InfoIcon color='primary' aria-describedby="webhook_pop" onMouseEnter={(e) => this.handleMouseOver(e, 1)} />
                                                </Grid>
                                                <Grid item >
                                                    <MuiTextField
                                                        name='slack_webhook_url'
                                                        value={this.state.slack_webhook_url}
                                                        onChange={(e) => this.handleSlackUrl(e)}
                                                        error={this.state.errors.slack_webhook_url}
                                                        helperText={this.state.helperText.slack_webhook_url}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Button onClick={this.testSlack} color="primary" variant="contained" disabled={!this.props.cicdSettings?.slack_webhook_url || (this.state.slack_webhook_url !== this.props.cicdSettings?.slack_webhook_url)}>Test</Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                    <Grid item md={12} sm={6} xs={12}>
                                        <FormControlLabel
                                            data-test="webhook-checkbox"
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={this.state.webhook_notification}
                                                    onChange={(e) => this.handleIsWebhook(e)}
                                                />
                                            }
                                            label="Enable Webhook"
                                        />
                                    </Grid>
                                    {
                                        this.state.webhook_notification &&
                                        <Grid item xs={12} className='oneRemLeftMarginSeperator' data-test="webhook-input-grid">
                                            <Grid container spacing={3}>
                                                <Grid item sm={6} xs={12}>
                                                    <Grid container direction="column" spacing={1}>
                                                        <Grid item className={classes.branchInfo}>
                                                            <Typography variant='h5'>Webhook URL </Typography>
                                                            <InfoIcon color='primary'
                                                                aria-describedby="webhook_pop"
                                                                onMouseEnter={(e) => this.handleMouseOver(e, 2)}
                                                            //onMouseLeave={(e) => this.handleMouseOut(e)}
                                                            />

                                                        </Grid>
                                                        <Grid item>
                                                            <MuiTextField
                                                                name='webhook_url'
                                                                value={this.state.webhook_url}
                                                                onChange={(e) => this.handleWebhookUrl(e)}
                                                                error={this.state.errors.webhook_url}
                                                                helperText={this.state.helperText.webhook_url}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <Button onClick={this.testWebhook} color="primary" variant="contained" disabled={!this.props.cicdSettings?.webhook_url || (this.state.webhook_url !== this.props.cicdSettings?.webhook_url)}>Test</Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                    <Grid container direction="column">
                                                        <Grid item className={classes.branchInfo}>
                                                            <Typography variant='h5' >Token </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <MuiTextField
                                                                name='webhook_token'
                                                                value={this.state.webhook_token}
                                                                onChange={(e) => this.handleWebhookUrl(e)}
                                                                error={this.state.errors.webhook_token}
                                                                helperText={this.state.helperText.webhook_token}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    } */}

                                </Grid>

                            }
                            {
                                this.props.cdSettingsError === 401 &&
                                <Typography variant='h5' data-test="cd-error-message">{t('Environment.CDSettingsTab.permissionError')}</Typography>
                            }
                        </CardContent>
                        {
                            this.props.cdSettingsError !== 401 &&
                            <>
                                <Divider />
                                {
                                    isAuthorized("update", envRole.name) && (
                                        <CardActions>
                                            <Button color="primary" 
                                                disabled={this.state.deploymentErrors?.length>0 }
                                                variant="contained"
                                                onClick={() => this.handleSave()}
                                                data-test="save-button"
                                            >
                                                {t('Environment.CDSettingsTab.save')}
                                            </Button>
                                        </CardActions>
                                    )
                                }
                            </>
                        }
                    </form>
                </Card>
                <Popover
                    id='webhook_pop'
                    open={Boolean(this.state.anchorEl)}
                    //className={classes.popover}
                    classes={{
                        paper: classes.paper,
                    }}
                    anchorEl={this.state.anchorEl}
                    onClose={(e) => this.handlePopoverClose(e)}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    {
                        this.state.popOverType === 1 &&
                        <Typography variant='h5'>
                        {t('Environment.CDSettingsTab.createUrl')} <Link href='https://api.slack.com/messaging/webhooks#create_a_webhook' target="_blank" rel="noreferrer" underline='hover'>{t('Environment.CDSettingsTab.slack')} </Link>
                        </Typography>
                    }
                    {
                        this.state.popOverType === 2 &&
                        <Typography variant='h5'>
                        {t('Environment.CDSettingsTab.hmacError')}  <Link href='#' target="_blank" rel="noreferrer" underline='hover'>{t('Environment.CDSettingsTab.readMore')} </Link>
                        </Typography>
                    }
                </Popover>
                <ConfirmActionPopup open={this.state.isConfirmSettingsSaveOpen} handleAgree={this.agreeSettingsSaveHandler} handleDisAgree={this.disagreeSettingsSaveHandler} message={t('Environment.CDSettingsTab.update')}  yesText={t('Environment.CDSettingsTab.yesText')}  noText={t('Environment.CDSettingsTab.noText')}  />
                {this.props.updatingEnv && <BackdropLoader message={t('Environment.CDSettingsTab.updatingSettings')}  />}
                {/* {this.props.testing && <BackdropLoader message="Initiating Test" />} */}
            </div>
        )
    }
}

/* istanbul ignore next */
const mapStateToProps = (state:any) => ({
    envRole: state.EnvironmentReducer.envRole,
    environmentDetails: state.EnvironmentReducer.environmentDetails,
    updatingEnv: state.EnvironmentReducer.updatingEnv,
    //testing: state.CICDReducer.testing,
    cdSettings:state.CICDReducer.cdSettings,
    cicdSettingsError:state.CICDReducer.cicdSettingsError,
    cdSettingsError: state.CICDReducer.cdSettingsError,
})

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch:Dispatch) => { //dispatch
    return {
        updateEnvironment : (id:number, jsonBody:{
            deployment_strategy: Partial<IdeploymentStrategy>;
        },source?:number, changeHash?:(hash:string,value:number,envId?:number,delay?:number)=>void) => dispatch(updateEnvironment(id, jsonBody, source, changeHash)),
        // fetchCDSettings: (id) => dispatch(fetchCDSettings(id)),
        // clearCDSettings: () => dispatch(clearCDSettings()),
        // testEmail: (eId) => dispatch(testEmail(eId)),
        // testSlack: (eId) => dispatch(testSlack(eId)),
        // testWebhook: (eId) => dispatch(testWebhook(eId)),
    }
}
const connector=connect(
    mapStateToProps,
    mapDispatchtoProps
)
export default connector(withStyles(useStyles)(withTranslation()(CDSettingsTab)))
type PropsFromRedux=ConnectedProps<typeof connector>