export const CreateApp_TemplateTypes = {
    default : 0,
    git: 1,
    cr: 2,
    helm:3,
    op: 4,
    manageDB: 5
};

export const Get_Enum_Description = (type, value) => {
    let name = '';
    for (const key in type) {
        if (type[key] === value)
            name = key;
    }
    return name;
}

export const Cluster_Scope = [
    { key:'Shared Cluster', value:0 },
    { key:'Orgnization Cluster', value:1 }
]

export const Get_Enum_Key = (type, value) => {
    let name = '';
    const obj = type.find( x => x.value === value);
    if(obj)
    {
        name = obj.key;
    }
    return name;
}
