import React from 'react'
import {
    Link, Paper, TableContainer, TableHead, TableBody, TableRow, Table, Card, CardContent, CardHeader
} from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import { StyledTableCell, StyledTableRow } from '../styledtabelcell/StyledTabelCell';
import CopyIcon from '../copyicon/CopyIcon';
import Skeleton from 'react-loading-skeleton';
import { EnvServiceDetails, ServiceDetailItem } from '../../models/HelmCharts.model';
// import Loader from '../loader/Loader';

type Props = {
    serviceDetails: EnvServiceDetails
}

export default function HelmServiceDetails(props: Props) {
    const { serviceDetails } = props

    const getPort = (ser: ServiceDetailItem) => {
        let allPorts = [];
        allPorts = ser.spec?.ports?.map((p) => {
            return p.port
        })
        return allPorts?.length ? allPorts.join(", ") : "";
        // switch (ser.spec?.type) {
        //     case "ClusterIP":
        //         break
        //     case "LoadBalancer":
        //         allPorts = ser.status?.loadBalancer?.ingress?.map((p) => {
        //             return p.ip ? p.ip : p.hostname
        //         })
        //         break
        //     default:
        //         break

        // }
    }

    // const getLink = (ser) => {
    //     let navLink = ""
    //     switch (ser.spec?.type) {
    //         case "ClusterIP":
    //             navLink = `${ser.spec?.ports[0]?.targetPort}://${ser.metadata?.name}.${ser.metadata?.namespace}:${ser.spec?.ports[0]?.port}`
    //             break
    //         case "LoadBalancer":
    //             navLink = `http://${ser.status?.loadBalancer?.ingress[0]?.ip ?? ser.status?.loadBalancer?.ingress[0]?.hostname}`
    //             break
    //         default:
    //             break
    //     }
    //     return navLink
    // }
    const renderLink = (ser: ServiceDetailItem) => {
        let navLink = ""
        let toRender
        switch (ser.spec?.type) {
            case "ClusterIP":
                navLink = `https://${ser.metadata?.name}.${ser.metadata?.namespace}:${ser.spec?.ports[0]?.port}`
                toRender = <CopyIcon copyText={navLink} fontSize="small" />
                break
            case "LoadBalancer":
                if (ser.status?.loadBalancer?.ingress?.length) {
                navLink = `http://${ser.status?.loadBalancer?.ingress[0]?.ip ?? ser.status?.loadBalancer?.ingress[0]?.hostname}`
                toRender =  <Link href={navLink} target="_blank" className="cnameLink" rel="noreferrer" underline="always">
                                <LaunchIcon className="openLinkIcon" />
                            </Link>
                } else {
                    toRender = <></>
                }
                break
            default:
                toRender = <> </>
                break
        }
        return toRender
    }
    return (
        <Card className="overviewCard" data-test="main-container">
            <CardHeader title="Service Details" />
            <CardContent>
                <TableContainer component={Paper}>
                    <Table data-test="details-table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Name</StyledTableCell>
                                <StyledTableCell>Type</StyledTableCell>
                                <StyledTableCell>Namespace</StyledTableCell>
                                <StyledTableCell>Cluster-IP</StyledTableCell>
                                <StyledTableCell>External-URL</StyledTableCell>
                                <StyledTableCell>PORT(S)</StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {serviceDetails?.items?.length > 0 && serviceDetails?.items?.map((service, ind) => (
                                <StyledTableRow key={ind} data-test="data-container">
                                    <StyledTableCell data-test="name">{service.metadata?.name} </StyledTableCell>
                                    <StyledTableCell data-test="type">{service.spec?.type}</StyledTableCell>
                                    <StyledTableCell data-test="namespace">{service.metadata?.namespace} </StyledTableCell>
                                    <StyledTableCell data-test="cluster-ip">{service.spec?.clusterIP}</StyledTableCell>
                                    <StyledTableCell data-test="external-ip">{service?.status?.loadBalancer?.ingress?.length ? service.status?.loadBalancer?.ingress[0]?.ip ?? service.status?.loadBalancer?.ingress[0]?.hostname : ""}</StyledTableCell>
                                    <StyledTableCell data-test="port">
                                        {getPort(service)}
                                    </StyledTableCell>
                                    <StyledTableCell data-test="link">
                                        {renderLink(service)}
                                    </StyledTableCell>

                                </StyledTableRow>
                            ))}
                            {
                                !serviceDetails && (
                                    [1, 2, 3, 4].map((val, ind) => (
                                        <StyledTableRow key={ind} data-test="skeleton">
                                            <StyledTableCell><Skeleton height={20} width="50%" /></StyledTableCell>
                                            <StyledTableCell><Skeleton height={20} width="50%" /></StyledTableCell>
                                            <StyledTableCell><Skeleton height={20} width="50%" /></StyledTableCell>
                                            <StyledTableCell><Skeleton height={20} width="50%" /></StyledTableCell>
                                            <StyledTableCell><Skeleton height={20} width="50%" /></StyledTableCell>
                                            <StyledTableCell>
                                                <Skeleton height={20} width="50%" />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <Skeleton height={20} width="30%" />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* {!serviceDetails && (
                    // <div className='alignCenter' style={{ marginTop: 3 }}>
                    //     <Grid container direction="column" justify="center" alignItems="center">
                    //         <Grid item>
                    //             <Loader />
                    //         </Grid>
                    //         <Grid item>
                    //             <Typography variant="body2" >Fetching service details</Typography>
                    //         </Grid>
                    //     </Grid>
                    // </div>
                    [1, 2, 3, 4].map((val) => (
                        <CardContent key={val} data-test="skeleton">
                            <Grid container md={12}>
                                <Grid item md={2} >
                                    <Skeleton height={20} width="30%" />
                                </Grid>
                                <Grid item md={2}>
                                    <Skeleton height={20} width="30%" />
                                </Grid>
                                <Grid item md={2}>
                                    <Skeleton height={20} width="30%" />
                                </Grid>
                                <Grid item md={2}>
                                    <Skeleton height={20} width="30%" />
                                </Grid>
                                <Grid item md={2}>
                                    <Skeleton height={20} width="30%" />
                                </Grid>
                            </Grid>
                        </CardContent>
                    ))
                )} */}
            </CardContent>
        </Card >
    )
}
