const PREFIX = '@GET_PROJECT_LIST';

export const GET_PROJECT_LIST_CALL = `${ PREFIX }/PROJECT_LIST`
export const GET_PROJECT_LIST_CALL_SUCCESS = `${ PREFIX }/PROJECT_LIST_SUCCESS`
export const GET_PROJECT_LIST_CALL_FAILURE = `${ PREFIX }/PROJECT_LIST_FAILURE`
export const BEGIN_LOADING_INDICATOR = `${ PREFIX }/BEGIN_LOADING_INDICATOR`
export const END_LOADING_INDICATOR = `${ PREFIX }/END_LOADING_INDICATOR`

const getProjectListApiCall = (history) => ({
	type: GET_PROJECT_LIST_CALL,
	data: {
		history
	}
})

const beginLoading = () => ({
    type: BEGIN_LOADING_INDICATOR,
})

const endLoading = () => ({
    type: END_LOADING_INDICATOR,
})

export {
	getProjectListApiCall,
	beginLoading,
    endLoading,
}