import {
  FETCH_PODS,
  FETCH_PODS_SUCCESS,
  FETCH_PODS_FAILURE,
  CLEAR_PODS,
  FETCH_POD_INSIGHTS,
  FETCH_POD_INSIGHTS_SUCCESS,
  FETCH_POD_INSIGHTS_FAILURE,
  FETCH_CONTAINER_INSIGHTS,
  FETCH_CONTAINER_INSIGHTS_SUCCESS,
  FETCH_CONTAINER_INSIGHTS_FAILURE,
  CLEAR_CONTAINER_INSIGHTS,
  FETCH_CONTAINER_LOGS_URL,
  FETCH_CONTAINER_LOGS_URL_SUCCESS,
  FETCH_CONTAINER_LOGS_URL_FAILURE,
  CLEAR_CONTAINER_LOGS_URL,
  FETCH_CONTAINER_WEBSHELL_URL,
  FETCH_CONTAINER_WEBSHELL_URL_SUCCESS,
  FETCH_CONTAINER_WEBSHELL_URL_FAILURE,
  CLEAR_CONTAINER_WEBSHELL_URL,
  FETCH_POD_EVENTS_SUCCESS,
  CLEAR_POD_EVENTS,
  FETCH_INSIGHT_GRAPH_DATA,
  FETCH_INSIGHT_GRAPH_DATA_SUCCESS,
  FETCH_INSIGHT_GRAPH_DATA_FAILURE,
  CLEAR_INSIGHT_GRAPH_DATA,
  UPDATE_POD_LABELS,
  UPDATE_POD_LABELS_SUCCESS,
  UPDATE_POD_LABELS_FAILURE,
  CLEAR_UPDATE_POD_LABELS,
} from "./actions";
import initialState from "./initialState";

const reducer = (state = initialState, payload) => {
  switch (payload.type) {
    case FETCH_PODS:
      return {
        ...state,
        fetchingPods: true,
      };
    case FETCH_PODS_SUCCESS:
      return {
        ...state,
        podsList: payload.data,
        fetchingPods: false,
      };
    case FETCH_PODS_FAILURE:
      return {
        ...state,
        fetchingPods: false,
      };
    case CLEAR_PODS:
      return {
        ...state,
        podsList: initialState.podsList,
      };

    case UPDATE_POD_LABELS:
      return {
        ...state,
        updatingPodLabels: true,
        updatePodLabelsSuccess: false,
        updatePodLabelsError: false,
      };

    case UPDATE_POD_LABELS_SUCCESS:
      return {
        ...state,
        updatingPodLabels: false,
        updatePodLabelsSuccess: true,
        updatePodLabelsError: false,
      };

    case UPDATE_POD_LABELS_FAILURE:
      return {
        ...state,
        updatingPodLabels: false,
        updatePodLabelsSuccess: false,
        updatePodLabelsError: true,
      };

    case CLEAR_UPDATE_POD_LABELS:
      return {
        ...state,
        updatingPodLabels: false,
        updatePodLabelsError: false,
        updatePodLabelsSuccess: false,
      };

    case FETCH_POD_INSIGHTS:
      return {
        ...state,
        fetchingPodInsights: true,
      };
    case FETCH_POD_INSIGHTS_SUCCESS:
      return {
        ...state,
        podInsights: payload.data,
        fetchingPodInsights: false,
      };
    case FETCH_POD_INSIGHTS_FAILURE:
      return {
        ...state,
        fetchingPodInsights: false,
      };
    case FETCH_CONTAINER_INSIGHTS:
      return {
        ...state,
        fetchingContainersInsights: true,
      };
    case FETCH_CONTAINER_INSIGHTS_SUCCESS:
      return {
        ...state,
        containerInsights: payload.data,
        fetchingContainersInsights: false,
      };
    case FETCH_CONTAINER_INSIGHTS_FAILURE:
      return {
        ...state,
        fetchingContainersInsights: false,
      };
    case CLEAR_CONTAINER_INSIGHTS:
      return {
        ...state,
        containerInsights: initialState.containerInsights,
      };
    case FETCH_CONTAINER_LOGS_URL: {
      return {
        ...state,
        fetchingContainerLogsUrl: true,
      };
    }
    case FETCH_CONTAINER_LOGS_URL_SUCCESS: {
      return {
        ...state,
        containerLogsUrl: payload.data,
        fetchingContainerLogsUrl: false,
      };
    }
    case FETCH_CONTAINER_LOGS_URL_FAILURE: {
      return {
        ...state,
        fetchingContainerLogsUrl: false,
      };
    }
    case CLEAR_CONTAINER_LOGS_URL: {
      return {
        ...state,
        containerLogsUrl: initialState.containerLogsUrl,
      };
    }
    case FETCH_CONTAINER_WEBSHELL_URL: {
      return {
        ...state,
        fetchingContainerWebShellUrl: true,
      };
    }
    case FETCH_CONTAINER_WEBSHELL_URL_SUCCESS: {
      return {
        ...state,
        containerWebShellUrl: payload.data,
        fetchingContainerWebShellUrl: false,
      };
    }
    case FETCH_CONTAINER_WEBSHELL_URL_FAILURE: {
      return {
        ...state,
        fetchingContainerWebShellUrl: false,
      };
    }
    case CLEAR_CONTAINER_WEBSHELL_URL: {
      return {
        ...state,
        containerWebShellUrl: initialState.containerWebShellUrl,
      };
    }
    case FETCH_POD_EVENTS_SUCCESS: {
      return {
        ...state,
        podEvents: payload.data,
      };
    }
    case CLEAR_POD_EVENTS: {
      return {
        ...state,
        podEvents: initialState.podEvents,
      };
    }
    case FETCH_INSIGHT_GRAPH_DATA:
      return {
        ...state,
        fetchingInsightGraphData: true,
      };
    case FETCH_INSIGHT_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        podInsightGraphData: {
          ...payload.data.pod_insight,
          total_cpu: payload.data.total_cpu,
          total_memory: payload.data.total_memory,
        },
        containerInsightGraphData: {
          ...payload.data.container_insight,
          total_cpu: payload.data.total_cpu,
          total_memory: payload.data.total_memory,
        },
        fetchingInsightGraphData: false,
      };
    case FETCH_INSIGHT_GRAPH_DATA_FAILURE:
      return {
        ...state,
        fetchingInsightGraphData: false,
      };
    case CLEAR_INSIGHT_GRAPH_DATA:
      return {
        ...state,
        podInsightGraphData: initialState.podInsightGraphData,
        containerInsightGraphData: initialState.containerInsightGraphData,
      };
    default:
      return state;
  }
};

export default reducer;
