const initialState = {
    envWorkflows: [],
    envWorkflowsLoaded: false,
    envWorkflowLog: [],
    envCILogs: [],
    cicdSettings: {},
    cicdSettingsError: 0,
    ciBuildTriggered: false,
    updatingCiSettings: false,
    stoppingCI: false,
    ciStatistics: null,
    fetchingEnvWorkflows: false,
    fetchingCiStats: false,
    fetchingCiSettings: false,
    testing: false,
    fetchingDeployments: false,
    cdConfig: null,
    updatingCdSettings: false,
    fetchingCdSettings: false,
    cdSettings: null,
    cdSettingsError:null,
    cdList: null,
    fetchingCdList: false,
    cdListStatus: null,
    fetchingCdListStatus: false
}

export default initialState