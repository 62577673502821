import { takeLatest, put, select, call } from 'redux-saga/effects';
import axios from "axios";
import { sessionTokenSelector} from '../../login/redux/selectors'
import toast from '../../../components/toast/Toast';
import endpoints from '../../../constants/endpoints';
import {
    FETCH_OPERATORS,
    FETCH_OPERATORS_FAILURE,
    FETCH_OPERATORS_SUCCESS,
    
    INITIATE_FETCH_OPERATORS_STATUS,
    INITIATE_FETCH_OPERATORS_STATUS_SUCCESS,
    INITIATE_FETCH_OPERATORS_STATUS_FAILURE,

    FETCH_AVAILABLE_OPERATORS,
    FETCH_AVAILABLE_OPERATORS_SUCCESS,
    FETCH_AVAILABLE_OPERATORS_FAILURE,

    FETCH_OPERATOR_DETAILS,
    FETCH_OPERATOR_DETAILS_SUCCESS,
    FETCH_OPERATOR_DETAILS_FAILURE,
    
    UNINSTALL_OPERATOR,
    UNINSTALL_OPERATOR_SUCCESS,
    UNINSTALL_OPERATOR_FAILURE,

    DEPLOY_OPERATOR,
    DEPLOY_OPERATOR_SUCCESS,
    DEPLOY_OPERATOR_FAILURE,

    REDEPLOY_OPERATOR,
    REDEPLOY_OPERATOR_SUCCESS,
    REDEPLOY_OPERATOR_FAILURE,

    CREATE_OPERATOR_ENVIRONMENT,
    CREATE_OPERATOR_ENVIRONMENT_SUCCESS,
    CREATE_OPERATOR_ENVIRONMENT_FAILURE,
    UPDATE_OPERATOR_DETAILS_LIST,
        
    FETCH_OPERATOR_LOGS,
    FETCH_OPERATOR_LOGS_SUCCESS,
    FETCH_OPERATOR_LOGS_FAILURE,
  } from './actions';

  function* fetchInstalledOperators(payload) {
    try {
      const sessionToken = yield select(sessionTokenSelector);
      const config = { headers: { Authorization: "Basic " + sessionToken } };
  
      const response = yield axios.get(
        endpoints.OPERATOR.FETCH_INSTALLED_OPERATORS.replace(
          ":id",
          payload.data.clusterId
        ),
        config
      );
      if (response.data) {
        yield put({ type: FETCH_OPERATORS_SUCCESS, data: response.data });
      }
    } catch (error) {
      if (error?.response?.data?.error) {
        toast.error(error.response.data.error);
      }
      yield put({ type: FETCH_OPERATORS_FAILURE });
    }
  }

function* fetchAvailableOperators() {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = { headers: { Authorization: "Basic " + sessionToken } };

    const response = yield axios.get(endpoints.OPERATOR.GET_AVAILABLE_OPERATORS, config)
                       
    if(response.data) {
      yield put({type: FETCH_AVAILABLE_OPERATORS_SUCCESS, data: response.data});
    }
  } catch(error) {
    if(error?.response?.data?.error){
      toast.error(error.response.data.error)
    }
    yield put({type: FETCH_AVAILABLE_OPERATORS_FAILURE});
  }
}

  function* fetchOperatorDetails(payload) {
    try {
      const sessionToken = yield select(sessionTokenSelector)
      const config = { headers: { Authorization: "Basic " + sessionToken } };

      const response = yield axios.get(endpoints.OPERATOR.GET_OPERATOR_DETAIL.replace(":packageName", payload.data.packageName), config);
      
      if(response.data) {
        yield put({type: FETCH_OPERATOR_DETAILS_SUCCESS, data: response.data});
        yield put({ type: UPDATE_OPERATOR_DETAILS_LIST, data: response.data });
      }
    } catch(error) {
      if(error?.response?.data?.error){
        toast.error(error.response.data.error)
      }
      yield put({type: FETCH_OPERATOR_DETAILS_FAILURE});
    }
  }

function* uninstallOperator(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "basic " + sessionToken,
      },
    };
    
    const response = yield axios.delete(
      endpoints.OPERATOR.UNINSTALL_OPERATOR.replace(
        ":clusterId",
        payload.data.clusterId
      ).replace(":operatorId", payload.data.operatorId),
      config
    );
    
    if(response.data) {
      yield put({type: UNINSTALL_OPERATOR_SUCCESS, data: response.data});
      yield call(fetchInstalledOperators, { data: { clusterId: payload.data.clusterId } })
      toast.success(response.data);
      if (payload.data.callback) {
        payload.data.callback();
      }
    }
  } catch(error) {
    if(error?.response?.data?.error){
      toast.error(error.response.data.error);
    }
    yield put({type: UNINSTALL_OPERATOR_FAILURE});
  }
}

function* deployOperator(payload){
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "basic " + sessionToken,
      },
    };

    yield axios.post(endpoints.OPERATOR.INSTALL_OPERATOR.replace(":id", payload.data.clusterId), payload.data.jsonBody, config);
    
    // if(response.data) {
      yield put({ type: DEPLOY_OPERATOR_SUCCESS });
      yield call(fetchInstalledOperators, { data: { clusterId: payload.data.clusterId } })
      //toast.success(response.data);
      if (payload.data.callBack) {
        payload.data.callBack();
      }
    // }
  } catch(error) {
    if(error?.response?.data?.error){
      toast.error(error.response.data.error);
    }
    yield put({ type: DEPLOY_OPERATOR_FAILURE });
  }
}

function* reDeployOperator(payload){
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "basic " + sessionToken,
      },
    };

    const response = yield axios.post(endpoints.OPERATOR.REINSTALL_OPERATOR.replace(":clusterId", payload.data.clusterId).replace(":operatorId", payload.data.operatorId), null ,config);
    
    if(response.data) {
      yield put({ type: REDEPLOY_OPERATOR_SUCCESS, data: response.data });
      toast.success(response.data);
    }
  } catch(error) {
    if(error?.response?.data?.error){
      toast.error(error.response.data.error);
    }
    yield put({ type: REDEPLOY_OPERATOR_FAILURE });
  }
}

function* createOperatorEnvironment(payload) {
  try {
      const sessionToken = yield select(sessionTokenSelector)
      const config = { headers: { 'Authorization': 'basic ' + sessionToken } }
      const endpoint = "";
                     
      const response = yield axios.post(endpoint, payload.data.payload, config);

      if ((response.status === 201 || response.status === 200)) {
          yield put({ type: CREATE_OPERATOR_ENVIRONMENT_SUCCESS, data: response.data });
          toast.success("Environment creation process started")
          //const data = response.data
          // payload.data.history.push({
          //     pathname: paths.OPERATORENVIRONMENTINFO.replace(":eId", data.id) + "#overview",
          //     state: { initial: true }
          // })
      } else {
          yield put({ type: CREATE_OPERATOR_ENVIRONMENT_FAILURE })
          toast.error("Environment creation failed. Please try again")
      }
  } catch (error) {
      yield put({ type: CREATE_OPERATOR_ENVIRONMENT_FAILURE })
      if (error && error.response && error.response.data && error.response.data.error) {
          toast.error(error.response.data.error);
      }
  }
}

function* fetchOperatorStatus(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const config = { headers : { 'Authorization': 'basic ' + sessionToken } }
    const response = yield axios.get(endpoints.OPERATOR.FETCH_OPERATOR_STATUS.replace(":id", payload.data.id), config);

    // const data = response.data;
    if (response.status === 201 || response.status === 200) {
      yield put({ type: INITIATE_FETCH_OPERATORS_STATUS_SUCCESS });
    } else {
      yield put({ type: INITIATE_FETCH_OPERATORS_STATUS_FAILURE })
    }
  } catch (error) {
    yield put({ type: INITIATE_FETCH_OPERATORS_STATUS_FAILURE }) 
    if(error && error.response && error.response.data && error.response.data.error){
     toast.error(error.response.data.error);
    }
  }
}

function* fetchOperatorLogs(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const config = { headers : { 'Authorization': 'basic ' + sessionToken } }
    const response = yield axios.get(endpoints.OPERATOR.FETCH_OPERATOR_LOGS.replace(":clusterId", payload.data.clusterId).replace(":operatorId", payload.data.operatorId), config);

    // const data = response.data;
    if (response.status === 201 || response.status === 200) {
      yield put({ type: FETCH_OPERATOR_LOGS_SUCCESS, data: response.data });
    } else {
      yield put({ type: FETCH_OPERATOR_LOGS_FAILURE })
    }
  } catch (error) {
    yield put({ type: FETCH_OPERATOR_LOGS_FAILURE }) 
    if(error && error.response && error.response.data && error.response.data.error){
     toast.error(error.response.data.error);
    }
  }
}

export default function* watcherSaga() {
  yield takeLatest(FETCH_OPERATORS, fetchInstalledOperators);
  yield takeLatest(FETCH_OPERATOR_DETAILS, fetchOperatorDetails);
  yield takeLatest(UNINSTALL_OPERATOR, uninstallOperator);
  yield takeLatest(DEPLOY_OPERATOR, deployOperator);
  yield takeLatest(REDEPLOY_OPERATOR, reDeployOperator);
  yield takeLatest(FETCH_AVAILABLE_OPERATORS, fetchAvailableOperators);
  yield takeLatest(CREATE_OPERATOR_ENVIRONMENT, createOperatorEnvironment); 
  yield takeLatest(INITIATE_FETCH_OPERATORS_STATUS, fetchOperatorStatus);
  yield takeLatest(FETCH_OPERATOR_LOGS, fetchOperatorLogs);
}
