import React, { Component } from 'react';
import {
  Grid,
  Card,
  CardContent,
  FormControl,
  Select,
  MenuItem,
  Divider,
  withStyles,
  CardHeader,
  Button,
  createStyles
} from '@material-ui/core';
import LBpopup from '../../pages/project/projectinfo/loadbalancertab/LBpopup';
import { connect, ConnectedProps } from 'react-redux';
import { fetchLoadbalancers, createLoadbalancer, clearLoadBanalcerData } from '../../pages/project/redux/actions';
import BackdropLoader from '../loader/BackdropLoader';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Dispatch } from "redux";
import { WithStyles } from "@material-ui/styles";
import { LoadbalancerInfoModel } from '../../models/Project.model';

/* istanbul ignore next */
const useStyles = () => createStyles({
  formControl: {
    minWidth: '100%',
  },
  discp: {
    marginTop: '20px',
  },
});

type Payload = {
  project_id?: number,
  name: string,
  region: string
}

interface Props extends PropsFromRedux, WithTranslation, WithStyles<typeof useStyles> {
  projectId: number,
  load_balancer_id: number,
  loadbalancersList: LoadbalancerInfoModel[],
  setLoadbalancer:(selectedLb: string|number, error: any) => void,
  loadSource: number
  // clearLoadBanalcerData: function clearLoadBanalcerData(),
}

type State = {
  createLBpopupOpen: boolean,
  selectedLb: string|number,
  error: any,
  helperText: {}
}


export class Loadbalancer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      createLBpopupOpen: false,
      selectedLb: 'Select Loadbalancer',
      error: {},
      helperText: {},
    };
  }

  setLoadbalancer = (lb:number) => {
    this.setState({
      selectedLb: lb
    }, () => {
      this.props.setLoadbalancer(this.state.selectedLb, this.state.error?.selectedLb)
    })
  }

  componentDidMount = () => {
    if (this.props.projectId) {
      this.props.fetchLoadbalancers(this.props.projectId);
    }
    if(this.props.load_balancer_id){
      this.setLoadbalancer(this.props.load_balancer_id)
    }
    else if(this.props?.loadbalancersList?.length > 0 && !this.props.load_balancer_id) {
      let listofLb = this.props.loadbalancersList.length - 1
      this.setLoadbalancer( this.props.loadbalancersList[listofLb].id ?? 'Select LoadBalancer')
    }
  };
  UNSAFE_componentWillReceiveProps = (newProps: Props) => {
    if (!this.props.load_balancer_id && newProps.load_balancer_id) {
      this.setLoadbalancer(newProps.load_balancer_id)
    }
    else if (!this.props.loadbalancersList && !this.props.load_balancer_id && !newProps.load_balancer_id && newProps.loadbalancersList?.length > 0) {
      let newLb = newProps.loadbalancersList.length - 1
      this.setLoadbalancer( newProps.loadbalancersList[newLb].id ?? 'Select Loadbalancer')
    }else if(this.props.loadbalancersList && newProps.loadbalancersList && this.props.loadbalancersList.length !== newProps.loadbalancersList.length){
      let changeLb = newProps.loadbalancersList.length - 1
      this.setLoadbalancer( newProps.loadbalancersList[changeLb].id ?? 'Select Loadbalancer')
    }
    if (!this.props.projectId && newProps.projectId) {
      this.props.fetchLoadbalancers(newProps.projectId);
    }
  };
  
  agreeCreateHandler = (payload:Payload) => {
    payload.project_id = this.props.projectId;
    this.props.createLoadbalancer(payload);
    this.setState({
      createLBpopupOpen: false,
    });
  };

  disagreeCreateHandler = () => {
    this.setState({
      createLBpopupOpen: false,
    });
  };

  /* istanbul ignore next */
  setLb = () => {
      const err = this.state.error?.selectedLb;
      this.props.setLoadbalancer(this.state.selectedLb, err);
  }

  handleLBChange = (e: React.ChangeEvent<{ name?: string, value: unknown }>) => {
    if (e.target.value === 'Select Loadbalancer') {
      this.setState({
        error: { ...this.state.error, [e.target.name as string]: true },
        helperText: {
          ...this.state.helperText,
          [e.target.name as string]: 'Please select valid loadbalancer',
        },
      });
    } else {
      this.setState({
        error: { ...this.state.error, [e.target.name as string]: false },
        helperText: { ...this.state.helperText, [e.target.name as string]: '' },
      });
    }
    this.setState(
      {
        selectedLb: e.target.value as string,
      },
      () => {
        const err = this.state.error?.selectedLb;
        this.props.setLoadbalancer(e.target.value as string, err);
      }
    );
  };

  componentWillUnmount() {
    this.props.setLoadbalancer(0, false);
    this.props.clearLoadBanalcerData();
  }

  render() {
    const { classes, t } = this.props;
    return (
        <div className="m-20" data-test="main-container">
            <Card>
                <CardHeader title={t('HPA.lb')} />
                <Divider />
                <CardContent>
                  <Grid container 
                  // row 
                  spacing={2}>
                    <Grid item md={ 4 }>
                        <FormControl className={ classes.formControl }>
                            <Select
                              variant="outlined"
                              color="primary"
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={ this.state.selectedLb }
                              onChange={ (e: React.ChangeEvent<{ name?: string, value: unknown }>) => this.handleLBChange(e) }
                //   error={this.state.error?.selectedLb}
                //   helperText={this.state.helperText?.selectedLb}
                              name="selectedLb"
                              MenuProps={ {
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                  } }
                              disabled={this.props.loadSource === 2}          
                              data-test="lb-select"
                            >
                                <MenuItem value="Select Loadbalancer" data-test="select-lb-item">
                                {t('HPA.selectLb')}
                                </MenuItem>
                                {this.props.loadbalancersList &&
                    // versionsList.versions[0].versions &&
                    this.props.loadbalancersList?.map((lb, index) => (
                        <MenuItem key={ index } value={ lb.id } data-test="lb-item">
                            {lb.name}
                        </MenuItem>
                    ))}
                            </Select>
                            {/* <FormHelperText error={this.state.isRoleFieldError}>{ this.state.RoleFieldErrorMessage }</FormHelperText> */}
                        </FormControl>
                    </Grid>
                  {this.props.loadSource !== 2 &&
                    <Grid item md={4}>
                    <Button variant="contained" color="primary" style={{height: 51}} onClick={() => this.setState({ createLBpopupOpen : true })} data-test="add-lb-btn">
                      {t('HPA.addLb')}
                    </Button> 
                    </Grid>
                  }
                  </Grid>
                </CardContent>
            </Card>

            {this.state.createLBpopupOpen && (
            <LBpopup
              handleAgree={this.agreeCreateHandler}
              handleDisAgree={this.disagreeCreateHandler}
              open={this.state.createLBpopupOpen}
              data-test="lb-popup"
            />
        )}
        {this.props.creatingLoadbalancer && (
            <BackdropLoader message={t('Projects.LoadBalancerTab.creatingLb')} data-test="loader" />
        )}
        </div>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  loadbalancersList: state.ProjectReducer.loadbalancersList,
  creatingLoadbalancer: state.ProjectReducer.creatingLoadbalancer,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    fetchLoadbalancers: (id: number) => dispatch(fetchLoadbalancers(id)),
    createLoadbalancer: (payload: any) => dispatch(createLoadbalancer(payload)),
    clearLoadBanalcerData: () => dispatch(clearLoadBanalcerData()),
  };
};

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withStyles(useStyles)(withTranslation()(Loadbalancer)));