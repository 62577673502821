import { call, takeLatest, put, select, delay } from 'redux-saga/effects';
import axios from 'axios';
import endpoints from '../../../constants/endpoints';
import { sessionTokenSelector } from '../../login/redux/selectors'
import { 
  FETCH_JOB_LIST,
  FETCH_JOB_LIST_SUCCESS,
  FETCH_JOB_LIST_FAILURE,
  FETCH_JOB_DETAILS,
  FETCH_JOB_DETAILS_SUCCESS,
  FETCH_JOB_DETAILS_FAILURE,
  CREATE_CRONJOB,
  CREATE_CRONJOB_SUCCESS,
  CREATE_CRONJOB_FAILURE,
  UPDATE_CRONJOB,
  UPDATE_CRONJOB_SUCCESS,
  UPDATE_CRONJOB_FAILURE,
  DELETE_CRONJOB,
  DELETE_CRONJOB_SUCCESS,
  DELETE_CRONJOB_FAILURE,
  CRONJOB_FETCH,
  //CRONJOB_FETCH_SUCCESS,
  CRONJOB_FETCH_FAILURE,
  GET_CRONJOB_STATUS,
  GET_CRONJOB_STATUS_SUCCESS,
  GET_CRONJOB_STATUS_FAILURE,
  GET_CRON_IMAGES,
  GET_CRON_IMAGES_SUCCESS,
  GET_CRON_IMAGES_FAILURE,
  RUN_CRONJOB,
  RUN_CRONJOB_SUCCESS,
  RUN_CRONJOB_FAILURE,
  FETCH_CRONJOB_LOG,
  FETCH_CRONJOB_LOG_SUCCESS,
  FETCH_MORE_CRONJOB_LOG_SUCCESS,
  FETCH_CRONJOB_LOG_FAILURE
 } from './actions';
 import toast from '../../../components/toast/Toast';
 //import paths from '../../../constants/paths'
 
function cronJobListCall(sessionToken , payload) {
   const config = {
    headers : {
      'Authorization': 'basic ' + sessionToken
    }
  }
  return axios.get(endpoints.CRONJOB.GET_CRONJOB_LIST.replace(':eId' , payload.data.eId), config)
}

function* fetchCronJobList(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const response = yield call(cronJobListCall, sessionToken , payload)
    const data = response.data;
    if (data !== null) {
      yield put({ type: FETCH_JOB_LIST_SUCCESS, data })
    } else {
      yield put({ type: FETCH_JOB_LIST_FAILURE })
    }
  } catch (error) {
    if(error && error.response && error.response.data && error.response.data.error) {
      toast.error(error.response.data.error);
      yield put({ type: FETCH_JOB_LIST_FAILURE })
    }
  }
}

function cronJobDetailsCall(sessionToken , payload) {
  const config = {
   headers : {
     'Authorization': 'basic ' + sessionToken
   }
 }
 return axios.get(endpoints.CRONJOB.GET_CRONJOB_DETAILS.replace(':eId' , payload.data.eId).replace(':cId' , payload.data.cId), config)
}

function* fetchCronJobDetails(payload) {
 try {
   const sessionToken = yield select(sessionTokenSelector)
   const response = yield call(cronJobDetailsCall, sessionToken , payload)
   const data = response.data;
   if (data !== null) {
     yield put({ type: FETCH_JOB_DETAILS_SUCCESS, data })
   } else {
     yield put({ type: FETCH_JOB_DETAILS_FAILURE })
   }
  } catch (error) {
    yield put({ type: FETCH_JOB_DETAILS_FAILURE })
   if(error && error.response && error.response.data && error.response.data.error) {
     toast.error(error.response.data.error);
   }
 }
}

function createCronJobCall(sessionToken , payload) {
  const config = {
   headers : {
     'Content-Type': 'application/json',
     'Authorization': 'basic ' + sessionToken,
   }
 }
 return axios.post(endpoints.CRONJOB.CREATE_CRONJOB.replace(':eId' , payload.data.eId) , payload.data.jsonBody , config)
}

function* createCronJob(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    yield call(createCronJobCall, sessionToken , payload)
    toast.success('Job scheduling process started');
    yield put({ type: CREATE_CRONJOB_SUCCESS }) 
  } catch (error) {
    if(error && error.response && error.response.data && error.response.data.error)
       toast.error(error.response.data.error);
    else 
       toast.error('Job creation failed. Please try again');
    yield put({ type: CREATE_CRONJOB_FAILURE })
  }
}

function updateCronJobCall(sessionToken , payload) {
  const config = {
   headers : {
     'Content-Type': 'application/json',
     'Authorization': 'basic ' + sessionToken,
   }
 }
 return axios.put(endpoints.CRONJOB.UPDATE_CRONJOB.replace(':eId' , payload.data.eId).replace(':cId' , payload.data.cId) , payload.data.jsonBody , config)
}

function* updateCronJob(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    yield call(updateCronJobCall, sessionToken , payload)
    toast.success('Job updated successfully');
    yield put({ type: UPDATE_CRONJOB_SUCCESS })
    if(payload.data.callback)
        payload.data.callback(); 
  } catch (error) {
    if(error && error.response && error.response.data && error.response.data.error)
       toast.error(error.response.data.error);
    else 
       toast.error('Job update failed. Please try again');
    yield put({ type: UPDATE_CRONJOB_FAILURE })
  }
}

function deleteCronJobCall(sessionToken , payload) {
  const config = {
   headers : {
     'Authorization': 'basic ' + sessionToken
   }
 }
 return axios.delete(endpoints.CRONJOB.DELETE_CRONJOB.replace(':eId' , payload.data.eId).replace(':cId' , payload.data.cId), config)
}

function* deleteCronJob(payload) {
 try {
    const sessionToken = yield select(sessionTokenSelector)
   //const response =
    yield call(deleteCronJobCall, sessionToken, payload)
    toast.success('Job deleted successfully');
    yield put({ type: DELETE_CRONJOB_SUCCESS })
    yield call(fetchCronJobList, payload);
    //payload.data.history.push((payload.data.app_id > 0 ?  "/app/" +  payload.data.app_id : "/projects" )) 
 } catch (error) {
    yield put({ type: DELETE_CRONJOB_FAILURE })
    if(error && error.response && error.response.data && error.response.data.error)
      toast.error(error.response.data.error);
    else
      toast.error('Operation failed. Please try again');
 }
}

function fetchCronJobCall(sessionToken , payload) {
  const config = {
   headers : {
     'Authorization': 'basic ' + sessionToken
   }
 }
 return axios.get(endpoints.CRONJOB.CRONJOB_FETCH.replace(':eId' , payload.data.eId), config)
}

function* fetchCronJob(payload) {
 try {
   const sessionToken = yield select(sessionTokenSelector)
   const response = yield call(fetchCronJobCall, sessionToken , payload)
   const data = response.data;
   if (data !== null) {
     //yield put({ type: CRONJOB_FETCH_SUCCESS, data })
     yield delay(2000);
     yield call(getCronJobStatus, payload);
   } 
 } catch (error) {
   if(error && error.response && error.response.data && error.response.data.error) {
     toast.error(error.response.data.error);
     yield put({ type: CRONJOB_FETCH_FAILURE })
   }
 }
}

function getCronJobStatusCall(sessionToken , payload) {
  const config = {
   headers : {
     'Authorization': 'basic ' + sessionToken
   }
 }
 return axios.get(endpoints.CRONJOB.GET_CRONJOB_STATUS.replace(':eId' , payload.data.eId), config)
}

function* getCronJobStatus(payload) {
 try {
   const sessionToken = yield select(sessionTokenSelector)
   const response = yield call(getCronJobStatusCall, sessionToken , payload)
   const data = response.data;
   if (data !== null) {
     yield put({ type: GET_CRONJOB_STATUS_SUCCESS, data })
   } 
 } catch (error) {
   if(error && error.response && error.response.data && error.response.data.error) {
     toast.error(error.response.data.error);
     yield put({ type: GET_CRONJOB_STATUS_FAILURE })
   }
 }
}

function getCronImagesCall(sessionToken) {
  const config = {
   headers : {
     'Authorization': 'basic ' + sessionToken
   }
 }
 return axios.get(endpoints.CRONJOB.GET_CRON_IMAGES, config)
}

function* getCronImages() {
 try {
   const sessionToken = yield select(sessionTokenSelector)
   const response = yield call(getCronImagesCall, sessionToken)
   const data = response.data;
   if (data !== null) {
     yield put({ type: GET_CRON_IMAGES_SUCCESS, data })
   } 
 } catch (error) {
   if(error && error.response && error.response.data && error.response.data.error) {
     toast.error(error.response.data.error);
     yield put({ type: GET_CRON_IMAGES_FAILURE })
   }
 }
}

function runCronJobCall(sessionToken, payload) {
  const config = {
   headers : {
     'Authorization': 'basic ' + sessionToken
   }
 }
 return axios.get(endpoints.CRONJOB.RUN_CRONJOB.replace(':eId' , payload.data.eId).replace(':cId' , payload.data.cId), config)
}

function* runCronJob(payload) {
 try {
   const sessionToken = yield select(sessionTokenSelector)
   const response = yield call(runCronJobCall, sessionToken, payload)
   const data = response.data;
   if (data !== null) {
     yield put({ type: RUN_CRONJOB_SUCCESS, data })
     toast.success(data.message);
     yield delay(8000)
     yield call(fetchCronJob, payload);
     let d = { ...payload.data }
     d.pageNo = 1
     d.pageSize = 15
     yield call(fetchCronJobLog, { data:d })
   } 
 } catch (error) {
   if(error && error.response && error.response.data && error.response.data.error) {
     toast.error(error.response.data.error);
     yield put({ type: RUN_CRONJOB_FAILURE })
   }
 }
}

function fetchCronJobLogCall(sessionToken, payload) {
  const config = {
   headers : {
     'Authorization': 'basic ' + sessionToken
   }
 }
 return axios.get(endpoints.CRONJOB.GET_CRONJOB_LOG.replace(':eId' , payload.data.eId).replace(':cId' , payload.data.cId).replace(':page', payload.data.pageNo).replace(':limit', payload.data.pageSize), config)
}

function* fetchCronJobLog(payload) {
 try {
   const sessionToken = yield select(sessionTokenSelector)
   const response = yield call(fetchCronJobLogCall, sessionToken, payload)
   const data = response.status === 204 ? [] : response.data;
   if (data !== null) {
    if(payload.data.pageNo === 1)
      yield put({ type: FETCH_CRONJOB_LOG_SUCCESS, data })
    else
      yield put({ type: FETCH_MORE_CRONJOB_LOG_SUCCESS, data })
     //toast.success("Run job triggered successfully");
   } 
 } catch (error) {
   if(error && error.response && error.response.data && error.response.data.error) {
     toast.error(error.response.data.error);
     yield put({ type: FETCH_CRONJOB_LOG_FAILURE })
   }
 }
}

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(FETCH_JOB_LIST, fetchCronJobList);
  yield takeLatest(FETCH_JOB_DETAILS, fetchCronJobDetails);
  yield takeLatest(CREATE_CRONJOB, createCronJob);
  yield takeLatest(UPDATE_CRONJOB, updateCronJob);
  yield takeLatest(DELETE_CRONJOB, deleteCronJob);
  yield takeLatest(CRONJOB_FETCH, fetchCronJob);
  yield takeLatest(GET_CRONJOB_STATUS, getCronJobStatus);
  yield takeLatest(GET_CRON_IMAGES, getCronImages);
  yield takeLatest(RUN_CRONJOB, runCronJob);
  yield takeLatest(FETCH_CRONJOB_LOG, fetchCronJobLog);
}