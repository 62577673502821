/* eslint-disable object-curly-spacing */
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import { withTranslation,WithTranslation } from "react-i18next";
import ResourcePaper from '../../../../../components/resourceenvironment/ResourcePaper';
import ConfirmActionPopup from '../../../../../components/confirmactionpopup/ConfirmActionPopup';
import ConfirmDeletePopup from '../../../../../components/confirmdeletepopup/ConfirmDeletePopup'
import './resourcestab.css';
import { connect,ConnectedProps } from 'react-redux';
import { fetchResources, updateEnvironment, changeEnvTabValue } from '../../../redux/actions'
import { fetchAppAvailableResource } from '../../../../app/redux/actions'
import BackdropLoader from '../../../../../components/loader/BackdropLoader';
import { Dispatch } from 'redux'; 
import { createStyles, WithStyles } from "@material-ui/core";
import { SystemDetail ,Storage, Resource, AutoScaler} from '../../../../../models/Environment.model';
import { CreateApp_TemplateTypes } from '../../../../../constants/enums';
import _ from 'lodash'
/* istanbul ignore next */
const useStyles = () => createStyles({
    actions: {
        display: "flex",
        justifyContent: 'center'
    },
    header: {
        display: "flex", 
        justifyContent: "flex-end"
    }
});

interface Iprops extends PropsFromRedux,WithStyles,WithTranslation {
 changeHash:(hash:string,value:number,envId:number,delay?:number)=>void;
}
interface Ipvc extends Partial<Storage>{
    enabled?:boolean;
}
type TjsonBody={
    resource_id:number;
    replicas: SystemDetail["replicas"];
    pvc:Ipvc;
    load_balancer_id:number|null;
    apply_immediately:boolean;
    auto_scaler: any;
}
interface Istate {
    currentStep:                number;
    systemDetail:Partial<SystemDetail>;
    isConfirmPopupOpen:         boolean;
    updated:                    boolean;
    lowerSubscriptionPopupOpen: boolean;
    initialResource: Resource;
    initialSystemDetails:Partial<SystemDetail>
}

export class Resources extends Component<Iprops,Istate> {
    constructor(props:Iprops){
        super(props);
        this.state = {
            currentStep : 2,
            systemDetail : {},
            isConfirmPopupOpen: false,
            updated: false,
            initialResource: this.props.environmentDetails && this.props.environmentDetails.resource,
            lowerSubscriptionPopupOpen: false,
            initialSystemDetails:{}
        }
    }

    componentDidMount(){
        this.props.fetchResources()
        if(this.props.environmentDetails && this.props.environmentDetails.application_id) {
            this.props.fetchAppAvailableResource(this.props.environmentDetails.application_id);
        }
    }

    handleResourceUpdate = (systemDetail:SystemDetail, initial=false) => {
        if(initial){
            this.setState((prevState)=>{
                return {...prevState,initialSystemDetails:{...systemDetail}}
            })
        }

        this.setState({
            updated: !initial && !(_.isEqual(this.state.initialResource,systemDetail.resource) && _.isEqual(this.state.initialSystemDetails,systemDetail) ),
            systemDetail
        })  
    }
    
      handleDisAgreeHandler = () => {
          this.setState({
              isConfirmPopupOpen : false,
              lowerSubscriptionPopupOpen: false

          })
      }

      isLowerSubscriptionSelected = () => {
        if(this.state.systemDetail&& this.state.systemDetail.resource && this.state.systemDetail.resource.id){
            const selectedResource = this.state.systemDetail.resource
            const initialResource = this.state.initialResource
            return (selectedResource.memory < initialResource.memory && selectedResource.cores<initialResource.cores)
        }
      }
    
      handleAgreeHandler = () => {
        this.setState({
            isConfirmPopupOpen : false,
            lowerSubscriptionPopupOpen: false
        })
          const jsonBody:Partial<TjsonBody> = {
              "resource_id": this.state.systemDetail?.resource?.id,
              "replicas": this.state.systemDetail?.replicas,
              "pvc": {enabled: this.state.systemDetail?.support_pv,...this.state.systemDetail?.storage},
            //   "horizontal_pod_autoscaler": {
            //    enabled: this.state.systemDetail?.enabled, 
            //    ...this.state.systemDetail?.horizontal_pod_autoscaler},
               "auto_scaler": {
                enabled: this.state.systemDetail.auto_scaler?.enabled,
                horizontal_pod_autoscaler: ((this.props.environmentDetails?.service_type === CreateApp_TemplateTypes.default && this.props.environmentDetails?.variables?.rwxEnable === true) ||
                this.props.environmentDetails?.service_type > 0) ? {...this.state.systemDetail.auto_scaler?.horizontal_pod_autoscaler} : undefined,
                vertical_pod_autoscaler: (this.props.environmentDetails?.service_type === CreateApp_TemplateTypes.default && (this.props.environmentDetails?.variables?.rwxEnable === false || 
                this.props.environmentDetails?.variables?.rwxEnable === undefined )) ? {...this.state.systemDetail.auto_scaler?.vertical_pod_autoscaler} : undefined,
                advanced_scheduling: ((this.props.environmentDetails?.service_type === CreateApp_TemplateTypes.default && this.props.environmentDetails?.variables?.rwxEnable === true) ||
                this.props.environmentDetails?.service_type > 0) ? {...this.state.systemDetail.auto_scaler?.advanced_scheduling} : undefined,
               },
              "load_balancer_id": this.state.systemDetail?.enable_loadbalancer ? this.state.systemDetail?.load_balancer_id : null,
              "apply_immediately": this.props.environmentDetails?.apply_immediately
            }
            
            this.props.updateEnvironment(this.props.environmentDetails?.id, jsonBody, 2, this.props.changeHash); 
            this.props.environmentDetails && this.props.environmentDetails?.service_type > 0 ?
            this.props.changeEnvTabValue(3) : this.props.changeEnvTabValue(2) 
      }

    handleContinue = () => {
        //this.props.handleChange(this.state.currentStep + 1, this.state)
        if(this.state.systemDetail && this.state.systemDetail.resource && this.state.systemDetail.resource.id && this.state.updated){
            if(this.isLowerSubscriptionSelected()){
                this.setState({
                  lowerSubscriptionPopupOpen: true
                })
            } else{
                this.setState({
                  //   systemDetail,
                    isConfirmPopupOpen: true
                })
            }
        }
    }

    arePropsReady = () => {
        const { resourcesList, appDetails, envRole, updatingEnv, appAvailableResource, environmentDetails, fetchingResources } = this.props
        if (fetchingResources || updatingEnv) return false
        return resourcesList?.length && appDetails && envRole && appAvailableResource && environmentDetails
    }

    render () {
        const { t } = this.props;
        return (
            <div>
              {!this.arePropsReady()?null: <ResourcePaper buttonName={t('Environment.ResourcesTab.apply')} 
                  handleButton={this.handleContinue} 
                  resources={this.props.resourcesList} 
                  handleResourceUpdate={this.handleResourceUpdate} 
                  loadSource={2} 
                  defaultResource={this.props.environmentDetails ? this.props.environmentDetails.resource : null} 
                  defaultReplicas={this.props.environmentDetails ? this.props.environmentDetails.replicas : null}
                  defaultBranch={this.props.environmentDetails ? this.props.environmentDetails.git_branch || this.props.environmentDetails.image_tag : null}
                  pvcDetails={this.props.environmentDetails && this.props.environmentDetails.pvc ? this.props.environmentDetails.pvc : null }
                  availableResources = { this.props.appAvailableResource }
                  updating = {this.props.updatingEnv}
                  envRole={this.props.envRole}
                  //gitRepository={this.props.environmentDetails && this.props.environmentDetails.git_url !== ""}
                    service_type={this.props.environmentDetails?.application?.service_type }
                  edit={true}
                  updated={this.state.updated}
                  initialSystemDetails={this.state.initialSystemDetails}
                  actionType={"updateResources"}
                //   horizontal_pod_autoscaler={this.props.environmentDetails && this.props.environmentDetails?.auto_scaler?.horizontal_pod_autoscaler ?  this.props.environmentDetails?.auto_scaler?.horizontal_pod_autoscaler : null}
                //   vertical_pod_autoscaler={this.props.environmentDetails && this.props.environmentDetails?.auto_scaler?.vertical_pod_autoscaler ?  this.props.environmentDetails?.auto_scaler?.vertical_pod_autoscaler : null}
                  appDetails={this.props.environmentDetails?.application}
                  load_balancer_id={this.props.environmentDetails?.load_balancer_id}
                  hasReponseRwx = {this.props.environmentDetails?.variables?.rwxEnable}
                  auto_scaler = {this.props.environmentDetails && this.props.environmentDetails?.auto_scaler ?  this.props.environmentDetails?.auto_scaler : null}
                />} 
                <ConfirmActionPopup open={ this.state.isConfirmPopupOpen } handleAgree={ this.handleAgreeHandler } handleDisAgree={ this.handleDisAgreeHandler } message='Are you sure you want to update the resource?' yesText='Yes' noText='No'/>
                <ConfirmDeletePopup open={this.state.lowerSubscriptionPopupOpen} handleAgree={this.handleAgreeHandler} handleDisAgree={this.handleDisAgreeHandler} message={`Downgrading the resources could lead to the unstable environment? `}  toMatchName={this.props.environmentDetails.name} toDeleteModule="environment" loading={this.props.updatingEnv} action={"update"} /> 
                {this.props.updatingEnv && <BackdropLoader message={t('Environment.ResourcesTab.updatingResource')} />}
                {this.props.fetchingResources && <BackdropLoader message={t('Environment.ResourcesTab.fetchingResource')} />}

            </div>
        )
    }
}

/* istanbul ignore next */
const mapStateToProps = (state:any) => ({
    resourcesList: state.EnvironmentReducer.resourcesList,
    fetchingResources: state.EnvironmentReducer.fetchingResources,
    environmentDetails: state.EnvironmentReducer.environmentDetails,
    appAvailableResource: state.AppsReducer.appAvailableResource,
    updatingEnv: state.EnvironmentReducer.updatingEnv,
    envRole: state.EnvironmentReducer.envRole,
    appDetails: state.AppsReducer.appDetails,
})

/* istanbul ignore next */
const mapDispatchtoProps =(dispatch :Dispatch)=> {
    return {
      fetchResources : () => dispatch(fetchResources()),
      updateEnvironment : (id:number, jsonBody:Partial<TjsonBody>,source:number, changeHash:(hash:string,value:number,envId:number,delay?:number)=>void) => dispatch(updateEnvironment(id, jsonBody, source, changeHash)),
      fetchAppAvailableResource : (id:number) => dispatch(fetchAppAvailableResource(id)),
      changeEnvTabValue : (id:number) => dispatch(changeEnvTabValue(id)),
    }
  }
const connector=connect(
    mapStateToProps,
    mapDispatchtoProps
  )
export default connector(withStyles(useStyles)(withTranslation()(Resources)))
type PropsFromRedux=ConnectedProps<typeof connector>