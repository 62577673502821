const initialState = {
    recentNotificationCount: 0,
    notificationList : null,
    fetchingNotifications: false,
    recentNotificationList : null,
    fetchingRecentNotifications: false,
    unseenCount: 0,
    isCheckNavigation: false,
    isMarkAllAsRead: false,
    isLoading: false,
    showNotificationBubble: false,
}

export default initialState
