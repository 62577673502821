const initialState = {
  podsList: [],
  updatingPodLabels: false,
  updatePodLabelsSuccess: false,
  updatePodLabelsError: false,
  fetchingPods: false,
  podInsights: null,
  fetchingPodInsights: false,
  containerInsights: null,
  fetchingContainersInsights: false,
  containerLogsUrl: null,
  fetchingContainerLogsUrl: false,
  containerWebShellUrl: null,
  fetchingContainerWebShellUrl: false,
  podEvents: [],
  podInsightGraphData: [],
  containerInsightGraphData: [],
  fetchingInsightGraphData: false,
};
export default initialState;
