import axios from 'axios';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import toast from '../../../../components/toast/Toast';
import endpoints from '../../../../constants/endpoints';
import { sessionTokenSelector } from '../../../login/redux/selectors';
import { getProjectListApiCall } from '../../projectlist/redux/actions';
import { editProjectApiCall, FETCH_PROJECT_DETAIL_SUCCESS, GET_SIDEBAR_PROJECT_DETAILS } from '../../redux/actions';
import {
  CREATE_PROJECT_CALL,
  CREATE_PROJECT_CALL_FAILURE, 
  CREATE_PROJECT_CALL_SUCCESS,
  GET_SUBSCRIPTION_LIST_CALL,
  GET_SUBSCRIPTION_LIST_CALL_FAILURE, 
  GET_SUBSCRIPTION_LIST_CALL_SUCCESS,
  UPDATE_PROJECT_ICON
} from './actions';
import { parseApiResponse } from "../../../../helpers/utils";

// createProject Axios call
function createProject(sessionToken , payload) {
   const config = {
    headers : {
      'Content-Type': 'application/json',
      'Authorization': 'basic ' + sessionToken,
    }
  }
  return axios.post(endpoints.CREATE_PROJECT , payload , config)
}

// Generator Call 
function* createProjectGenerator(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    if(payload.data.iconPayload)
    {
      const iconResponse = yield call(uploadProjectIconCall, sessionToken, payload)
      const iconData = iconResponse.data;
      if (iconResponse.status === 200 && iconData) {
        payload.data.payload['image'] = iconData.url;
      } 
    }
    const response = yield call(createProject, sessionToken , payload.data.payload)
    const data = response.data;
    if (response.status === 200){   
      const { id } = data;
      yield put({ type: FETCH_PROJECT_DETAIL_SUCCESS, data })
      yield put({
        type: CREATE_PROJECT_CALL_SUCCESS,
        data: {
          message: 'Some error while creating Project',
        },
      })
      yield put(getProjectListApiCall({}))
      yield put({ type: GET_SIDEBAR_PROJECT_DETAILS, data: { id: id } })
      toast.success('Project Created Successfully');
      payload.data.history.push(id > 0 ? '/project/' + id : '/projects' );
    } else {
      yield put({
        type: CREATE_PROJECT_CALL_FAILURE,
        data: {
          message: 'Some error while creating Project',
        },
      })
      toast.error('Some error while creating Project');
    }
  } catch (error) {
    if(error && error.response && error.response.data && error.response.data.error) {
      toast.error(parseApiResponse(error.response.data.error).message);
      yield put({
        type: CREATE_PROJECT_CALL_FAILURE,
        data: {
          message: error.response.data.error,
        },
      })
    }
    else {
      toast.error('Some error while creating Project. Please try again');
      yield put({
        type: CREATE_PROJECT_CALL_FAILURE,
        data: {
          message: 'Some error while creating Project',
        },
      })
    }
  }
}

// project list Axios call
function getSubscriptionList(sessionToken) {
  const config = {
   headers : {
     'Content-Type': 'application/json',
     'Authorization': 'basic ' + sessionToken
   }
 }
 return axios.get(endpoints.GET_SUBSCRIPTION_LIST,config);
}

// Generator Call 
function* getSubscriptionListGenerator() {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const response = yield call(getSubscriptionList, sessionToken )
    const data = response.data;
    if (response.status === 200){
      yield put({ type: GET_SUBSCRIPTION_LIST_CALL_SUCCESS, data })
    } else {
      yield put({
        type: GET_SUBSCRIPTION_LIST_CALL_FAILURE,
        data: {
          message: 'Some error while getting Subscription List',
        },
      })
    }
  } catch (error) {
    // yield put({
    //   type: GET_SUBSCRIPTION_LIST_CALL_FAILURE,
    //   data: {
    //     message: 'Some error while getting Project List',
    //   },
    // })
    if(error && error.response && error.response.data && error.response.data.error)
      toast.error(error.response.data.error);
    else
      toast.error('Some error while getting Subscription List');
  }
}

function uploadProjectIconCall(sessionToken, payload) {
  const config = {
   headers : {
     'Content-Type': 'multipart/form-data',
     'Authorization': 'basic ' + sessionToken
   }
 }
 return axios.post(endpoints.GCS_UPLOAD, payload.data.iconPayload, config);
}

// Generator Call 
function* updateProjectIcon(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    if(payload.data.iconPayload)
    {
      const iconResponse = yield call(uploadProjectIconCall, sessionToken, payload)
      const iconData = iconResponse.data;
      if (iconResponse.status === 200 && iconData) {
        payload.data.payload = {};
        payload.data.payload['image'] = iconData.url;
        payload.data.payload['id'] = payload.data.id;
        
        yield put(editProjectApiCall(payload.data.payload , payload.data.id))
      } 
    }
    
  } catch (error) {
    if(error && error.response && error.response.data && error.response.data.error)
      toast.error(error.response.data.error);
    else
      toast.error('Some error while uploading icon');
  }
}

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(CREATE_PROJECT_CALL, createProjectGenerator);
  yield takeLatest(GET_SUBSCRIPTION_LIST_CALL, getSubscriptionListGenerator)
  yield takeLatest(UPDATE_PROJECT_ICON, updateProjectIcon)
}