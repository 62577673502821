import { 
    CREATE_PROJECT_CALL,
    CREATE_PROJECT_CALL_SUCCESS,
    CREATE_PROJECT_CALL_FAILURE,
    GET_SUBSCRIPTION_LIST_CALL,
    GET_SUBSCRIPTION_LIST_CALL_SUCCESS,
    GET_SUBSCRIPTION_LIST_CALL_FAILURE,
    RESET_CREATE_PROJECT_CALL_ERROR
    //UPLOAD_PROJECT_ICON_SUCCESS
} from './actions';
import initialState from './initialState';

const reducer = (state = initialState, payload ) => {
  switch (payload.type) {
    case CREATE_PROJECT_CALL:
      return {
        ...state,
        createProjectCallStarted : true,
        createProjectCallSuccess : false,
        createProjectCallFailure : false,
        createProjectCallError : null,
        creatingProject: true
      }
    case CREATE_PROJECT_CALL_SUCCESS:
      return {
        ...state,
        createProjectCallStarted : false,
        createProjectCallSuccess : true,
        createProjectCallFailure : false,
        createProjectCallError : null,
        creatingProject: false

    }
    case CREATE_PROJECT_CALL_FAILURE:
      return {
        ...state,
        createProjectCallStarted : false,
        createProjectCallSuccess : false,
        createProjectCallFailure : true,
        creatingProject: false,
        createProjectCallError: payload.data.message
    }
    case RESET_CREATE_PROJECT_CALL_ERROR:
      return {
        ...state,
        createProjectCallError: null
    }
    case GET_SUBSCRIPTION_LIST_CALL:
      return {
        ...state,
        getSubscriptionListCallStarted : true,
        getSubscriptionListCallSuccess : false,
        getSubscriptionListCallFailure : false,
      }
    case GET_SUBSCRIPTION_LIST_CALL_SUCCESS:
      return {
        ...state,
        subscriptionList : payload.data,
        getSubscriptionListCallStarted : false,
        getSubscriptionListCallSuccess : true,
        getSubscriptionListCallFailure : false,
    }
    case GET_SUBSCRIPTION_LIST_CALL_FAILURE:
      return {
        ...state,
        getSubscriptionListCallStarted : false,
        getSubscriptionListCallSuccess : false,
        getSubscriptionListCallFailure : true,
    }
    // case UPLOAD_PROJECT_ICON_SUCCESS:
    //   return {
    //     ...state,
    //     postProjectIconUploadData: payload.data
    // }
    default:
      return state
  }
}

export default reducer