import axios, { AxiosResponse } from "axios";
import endpoints from "../constants/endpoints";

export const getAlertHistory = async <T extends unknown>(
  sessionToken: string,
  eId: number,
  pageNo: number,
  pageSize: number
): Promise<AxiosResponse<T>> => {
    try {
        const url = endpoints.ENVIRONMENT.ALERT_HISTORY
        .replace(":id", eId.toString())
        .replace(":page", pageNo.toString())
				.replace(":limit", pageSize.toString());
        const headers = {
          Authorization: `basic ${sessionToken}`,
        };
    
        const response = await axios.get<T>(url, { headers });
        return response;
      } catch (error) {
        throw error;
      }
};
