import {
    GET_CHART_VERSIONS,
    GET_CHART_VERSIONS_FAILURE,
    GET_CHART_VERSIONS_SUCCESS,
    GET_CHART_VERSION_CONFIG,
    GET_CHART_VERSION_CONFIG_FAILURE,
    GET_CHART_VERSION_CONFIG_SUCCESS,
    CLEAR_CHART_INFO,
    CREATE_HELM_ENVIRONMENT,
    CREATE_HELM_ENVIRONMENT_SUCCESS,
    CREATE_HELM_ENVIRONMENT_FAILURE,
    FETCH_HELM_ENVIRONMENT_DETAIL,
    FETCH_HELM_ENVIRONMENT_DETAIL_FAILURE,
    FETCH_HELM_ENVIRONMENT_DETAIL_SUCCESS,
    UPDATE_HELM_ENV_STATUS,
    SET_CURRENT_HELM_ENVIRONMENT,
    DELETE_HELM_ENVIRONMENT,
    DELETE_HELM_ENVIRONMENT_FAILURE,
    DELETE_HELM_ENVIRONMENT_SUCCESS,
    FETCH_HELM_ENV_STATE,
    FETCH_HELM_ENV_STATE_FAILURE,
    FETCH_HELM_ENV_STATE_SUCCESS,
    UPDATE_HELM_ACTIVITY_LOGS,
    UPDATE_HELM_SERVICE_DETAILS,
    FETCH_HELM_ACTIVITY_LOGS,
    FETCH_HELM_ACTIVITY_LOGS_FAILURE,
    FETCH_HELM_ACTIVITY_LOGS_SUCCESS,
    CLEAR_ENVIRONMENT_DETAILS,
    FETCH_HELM_LOGS,
    FETCH_HELM_LOGS_FAILURE,
    FETCH_HELM_LOGS_SUCCESS,
    FETCH_HELM_LOGS_INITIATE,
    FETCH_HELM_LOGS_INITIATE_FAILURE,
    FETCH_HELM_LOGS_INITIATE_SUCCESS,
    FETCH_HELM_PODS,
    FETCH_HELM_PODS_SUCCESS,
    FETCH_HELM_PODS_FAILURE,
    CLEAR_HELM_LOGS,
    UPDATE_HELM_ENVIRONMENT,
    UPDATE_HELM_ENVIRONMENT_FAILURE,
    UPDATE_HELM_ENVIRONMENT_SUCCESS,
    START_HELM_ENVIRONMENT,
    START_HELM_ENVIRONMENT_FAILURE,
    START_HELM_ENVIRONMENT_SUCCESS,
    STOP_HELM_ENVIRONMENT,
    STOP_HELM_ENVIRONMENT_FAILURE,
    STOP_HELM_ENVIRONMENT_SUCCESS,
    REDEPLOY_HELM_ENVIRONMENT,
    REDEPLOY_HELM_ENVIRONMENT_FAILURE,
    REDEPLOY_HELM_ENVIRONMENT_SUCCESS,
    LAUNCH_HELM_ENVIRONMENT,
    LAUNCH_HELM_ENVIRONMENT_FAILURE,
    LAUNCH_HELM_ENVIRONMENT_SUCCESS,
    FETCH_HELM_ENV_INSIGHT,
    FETCH_HELM_ENV_INSIGHT_FAILURE,
    FETCH_HELM_ENV_INSIGHT_SUCCESS,
    FETCH_HELM_ENV_OVERVIEW,
    FETCH_HELM_ENV_OVERVIEW_FAILURE,
    FETCH_HELM_ENV_OVERVIEW_SUCCESS,
} from "./actions"
import initialState from "./initialState"
let envState

const reducer = (state = initialState, payload) => {
    switch (payload.type) {
        case GET_CHART_VERSIONS:
            return {
                ...state,
                fetchingChartVersions: true,
            }
        case GET_CHART_VERSIONS_SUCCESS:
            return {
                ...state,
                fetchingChartVersions: false,
                chartVersions: payload.data
            }
        case GET_CHART_VERSIONS_FAILURE:
            return {
                ...state,
                fetchingChartVersions: false,
            }
        case GET_CHART_VERSION_CONFIG:
            return {
                ...state,
                fetchingChartConfig: true,
            }
        case GET_CHART_VERSION_CONFIG_SUCCESS:
            return {
                ...state,
                fetchingChartConfig: false,
                chartConfig: payload.data,
            }
        case GET_CHART_VERSION_CONFIG_FAILURE:
            return {
                ...state,
                fetchingChartConfig: false,
            }
        case CREATE_HELM_ENVIRONMENT:
            return {
                ...state,
                creatingHelmEnv: true,
            }
        case CREATE_HELM_ENVIRONMENT_SUCCESS:
            return {
                ...state,
                creatingHelmEnv: false,
            }
        case CREATE_HELM_ENVIRONMENT_FAILURE:
            return {
                ...state,
                creatingHelmEnv: false,
            }
        case DELETE_HELM_ENVIRONMENT:
            return {
                ...state,
                deletingHelmEnv: true,
            }
        case DELETE_HELM_ENVIRONMENT_SUCCESS:
            return {
                ...state,
                deletingHelmEnv: false,
            }
        case DELETE_HELM_ENVIRONMENT_FAILURE:
            return {
                ...state,
                deletingHelmEnv: false,
            }
        case UPDATE_HELM_ENVIRONMENT:
            return {
                ...state,
                updatingHelmEnv: true,
            }
        case UPDATE_HELM_ENVIRONMENT_SUCCESS:
            return {
                ...state,
                updatingHelmEnv: false,
            }
        case UPDATE_HELM_ENVIRONMENT_FAILURE:
            return {
                ...state,
                updatingHelmEnv: false,
            }
        case START_HELM_ENVIRONMENT:
            return {
                ...state,
                startingHelmEnv: true,
            }
        case START_HELM_ENVIRONMENT_SUCCESS:
            return {
                ...state,
                startingHelmEnv: false,
            }
        case START_HELM_ENVIRONMENT_FAILURE:
            return {
                ...state,
                startingHelmEnv: false,
            }
        case STOP_HELM_ENVIRONMENT:
            return {
                ...state,
                stoppingHelmEnv: true,
            }
        case STOP_HELM_ENVIRONMENT_SUCCESS:
            return {
                ...state,
                stoppingHelmEnv: false,
            }
        case STOP_HELM_ENVIRONMENT_FAILURE:
            return {
                ...state,
                stoppingHelmEnv: false,
            }
        case REDEPLOY_HELM_ENVIRONMENT:
            return {
                ...state,
                redeployingHelmEnv: true,
            }
        case REDEPLOY_HELM_ENVIRONMENT_SUCCESS:
            return {
                ...state,
                redeployingHelmEnv: false,
            }
        case REDEPLOY_HELM_ENVIRONMENT_FAILURE:
            return {
                ...state,
                redeployingHelmEnv: false,
            }
        case LAUNCH_HELM_ENVIRONMENT:
            return {
                ...state,
                launchingHelmEnv: true,
            }
        case LAUNCH_HELM_ENVIRONMENT_SUCCESS:
            return {
                ...state,
                launchingHelmEnv: false,
            }
        case LAUNCH_HELM_ENVIRONMENT_FAILURE:
            return {
                ...state,
                launchingHelmEnv: false,
            }
        case FETCH_HELM_ENVIRONMENT_DETAIL:
            return {
                ...state,
                fetchingHelmEnv: true,
                isDataNotFound: false,
            }
        case FETCH_HELM_ENVIRONMENT_DETAIL_SUCCESS:
            return {
                ...state,
                fetchingHelmEnv: false,
                helmEnvironmentDetails: payload.data?.environment,
                helmEnvironmentMetadata: payload.data?.metadata,
                isDataNotFound: false,
            }
        case FETCH_HELM_ENVIRONMENT_DETAIL_FAILURE:
            return {
                ...state,
                fetchingHelmEnv: false,
                isDataNotFound: true,
            }
        case FETCH_HELM_ENV_INSIGHT:
            return {
                ...state,
                fetchingHelmEnvInsight: true,
            }
        case FETCH_HELM_ENV_INSIGHT_SUCCESS:
            return {
                ...state,
                fetchingHelmEnvInsight: false,
                helmEnvironmentInsights: payload.data,
            }
        case FETCH_HELM_ENV_INSIGHT_FAILURE:
            return {
                ...state,
                fetchingHelmEnvInsight: false,
            }
        case FETCH_HELM_ENV_OVERVIEW:
            return {
                ...state,
                fetchingHelmEnvOverview: true,
            }
        case FETCH_HELM_ENV_OVERVIEW_SUCCESS:
            return {
                ...state,
                fetchingHelmEnvOverview: false,
                helmEnvironmentOverview: payload.data,
            }
        case FETCH_HELM_ENV_OVERVIEW_FAILURE:
            return {
                ...state,
                fetchingHelmEnvOverview: false,
            }
        case FETCH_HELM_ENV_STATE:
            return {
                ...state,
                fetchingHelmEnvState: true,
                isDataNotFound: false,
            }
        case FETCH_HELM_ENV_STATE_SUCCESS:
            return {
                ...state,
                fetchingHelmEnvState: false,
                helmEnvironmentState: payload.data,
            }
        case FETCH_HELM_ENV_STATE_FAILURE:
            return {
                ...state,
                fetchingHelmEnvState: false,
            }
        case CLEAR_CHART_INFO:
            return {
                ...state,
                chartConfig: initialState.chartConfig,
                fetchingChartConfig: initialState.fetchingChartConfig,
            }
        case UPDATE_HELM_ENV_STATUS:
            if (state.helmEnvironmentState) {
                envState = { ...state.helmEnvironmentState, state: payload.data.status }
            } else {
                envState = { State: payload.data.status }
            }
            return {
                ...state,
                helmEnvironmentState: envState
            }
        case SET_CURRENT_HELM_ENVIRONMENT:
            return {
                ...state,
                currentHelmEnvironment: payload.data.id
            }
        case UPDATE_HELM_ACTIVITY_LOGS:
            return {
                ...state,
                realTimeHelmActivityLogs: payload.data.logs
            }
        case UPDATE_HELM_SERVICE_DETAILS:
            return {
                ...state,
                helmServiceDetails: payload.data.payload
            }
        case FETCH_HELM_ACTIVITY_LOGS:
            return {
                ...state,
                fetchingHelmActivityLogs: true,
            }
        case FETCH_HELM_ACTIVITY_LOGS_SUCCESS:
            return {
                ...state,
                fetchingHelmActivityLogs: false,
                helmActivityLogs: payload.data,
            }
        case FETCH_HELM_ACTIVITY_LOGS_FAILURE:
            return {
                ...state,
                fetchingHelmActivityLogs: false,
            }
        case FETCH_HELM_PODS:
            return {
                ...state,
                fetchingHelmPods: true,
            }
        case FETCH_HELM_PODS_SUCCESS:
            return {
                ...state,
                fetchingHelmPods: false,
                helmPods: payload.data,
            }
        case FETCH_HELM_PODS_FAILURE:
            return {
                ...state,
                fetchingHelmPods: false,
            }
        case FETCH_HELM_LOGS_INITIATE:
            return {
                ...state,
                isLoading: true,
            }
        case FETCH_HELM_LOGS_INITIATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                helmLogsInitiate: payload.data,
            }
        case FETCH_HELM_LOGS_INITIATE_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case FETCH_HELM_LOGS:
            return {
                ...state,
                fetchingHelmLogs: true,
            }
        case FETCH_HELM_LOGS_SUCCESS:
            return {
                ...state,
                fetchingHelmLogs: false,
                helmLogs: payload.data,
            }
        case FETCH_HELM_LOGS_FAILURE:
            return {
                ...state,
                fetchingHelmLogs: false,
            }
        case CLEAR_ENVIRONMENT_DETAILS:
            return {
                ...state,
                fetchingHelmActivityLogs: false,
                helmEnvironmentDetails: initialState.helmEnvironmentDetails,
                helmEnvironmentState: initialState.helmEnvironmentState,
                helmEnvironmentMetadata: initialState.helmEnvironmentMetadata,
                helmActivityLogs: initialState.helmActivityLogs,
                realTimeHelmActivityLogs: initialState.realTimeHelmActivityLogs,
                helmServiceDetails: initialState.helmServiceDetails,
            }
        case CLEAR_HELM_LOGS:
            return {
                ...state,
                fetchingHelmLogs: false,
                fetchingHelmPods: initialState.fetchingHelmPods,
                helmLogs: initialState.helmLogs,
                helmPods: initialState.helmPods,
                isLoading: initialState.isLoading,
                helmLogsInitiate: initialState.helmLogsInitiate,
            }
        default:
            return state
    }
}

export default reducer