import React from "react";
import { Typography, IconButton } from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import { useTranslation } from "react-i18next";
import "./ContainerWebShell.css";

interface Props{
  url:string;
  handleLaunchExternalShell:()=>void;
  isOpen:boolean

}
const ContainerWebShell = ({ url, handleLaunchExternalShell, isOpen }: Props) => {
  const [t] = useTranslation();
  return (
    <>
      {
        isOpen && (
          <div className="webshell-container">
            <iframe
              src={url}
              title="Web Shell"
              width="100%"
              height={500}
              data-test="webshell-container"
            />
            <IconButton
              className="launch-external-shell"
              onClick={handleLaunchExternalShell}
              data-test="launch-external-shell-button"
            >
              <LaunchIcon />
            </IconButton>
            <Typography variant="body1">
              <strong>{t("Support.TicketNotePopup.note")}:</strong>{" "}
              {t("Pods.webShellNote")}
            </Typography>
          </div>
        )
      }
    </>
  );
};

export default ContainerWebShell;
