import {
  GET_ALERT_LISTS,
  GET_ALERT_LISTS_FAILURE,
  GET_ALERT_LISTS_SUCCESS,
  GET_ALERT_RULES_SUCCESS,
  GET_ALERT_STATUS,
  GET_ALERT_STATUS_FAILURE,
  GET_ALERT_STATUS_SUCCESS,
  NOTIFI_ALERT_LIST_SUCCESS,
} from "./action";
import initialState from './initialState';

const reducer = (state = initialState, payload) => {
  switch (payload.type) {
    case GET_ALERT_LISTS:
      return {
        ...state,
        gettingTempList: true,
        rulesList: initialState.rulesList,
      };
    case GET_ALERT_LISTS_SUCCESS:
      return {
        ...state,
        templateLists: payload.data,
        gettingTempList: false,
      };
    case GET_ALERT_LISTS_FAILURE:
      return {
        ...state,
        gettingTempList: false,
      };
    case GET_ALERT_RULES_SUCCESS:
      return {
        ...state,
        rulesList: [...state.rulesList, payload.data],
      };
    case NOTIFI_ALERT_LIST_SUCCESS:
      return {
        ...state,
        notifiDetails: payload.data,
      };
    case GET_ALERT_STATUS:
      return {
        ...state,
        alertStatus: true
      };
    case GET_ALERT_STATUS_SUCCESS:
      return {
        ...state,
        alertStatusDetails: payload.data,
        alertStatus: false
      };
    case GET_ALERT_STATUS_FAILURE:
      return {
        ...state,
        alertStatus: false
      };
      
    default:
      return state;
  }
};

export default reducer;
