const initialState = {
  userInvoice: null,
  paymentSetting: null,
  updatingPaymentSetting: false,
  paymentThreshold: null,
  updatingPaymentThreshold: false,
  deduction: null,
  latestPaymentHistory: null,
  paymentHistory: null,
  fetchingPaymentHistory: false,
  previousInvoice: null,
  paymentDetails: null,
  fetchingPaymentDetails: false,
  invoiceData: null,
  gatewayData: [],
};
export default initialState;