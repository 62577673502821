import axios from "axios";
import toast from '../../../../components/toast/Toast';
import { takeLatest, select, put } from "redux-saga/effects";
import endpoints from "../../../../constants/endpoints";
import { sessionTokenSelector } from "../../../login/redux/selectors";
import {
  RESET_DOWNLOAD_INVOICE_BY_ID,
  GET_INVOICE,
  GET_INVOICE_SUCCESS,
  GET_PAYMENT_SETTING,
  GET_PAYMENT_SETTING_SUCCESS,
  UPDATE_PAYMENT_SETTING,
  UPDATE_PAYMENT_SETTING_SUCCESS,
  UPDATE_PAYMENT_SETTING_FAILURE,
  GET_PAYMENT_THRESHOLD,
  GET_PAYMENT_THRESHOLD_SUCCESS,
  UPDATE_PAYMENT_THRESHOLD,
  UPDATE_PAYMENT_THRESHOLD_SUCCESS,
  UPDATE_PAYMENT_THRESHOLD_FAILURE,
  GET_DEDUCTION_BY_ID,
  GET_DEDUCTION_BY_ID_SUCCESS,
  GET_PAYMENT_HISTORY,
  GET_PAYMENT_HISTORY_SUCCESS,
  GET_INVOICE_BY_ID,
  GET_INVOICE_BY_ID_SUCCESS,
  GET_PAYMENT_DETAILS,
  GET_PAYMENT_DETAILS_SUCCESS,
  GET_PAYMENT_DETAILS_FAILURE,
  DOWNLOAD_INVOICE_BY_ID,
  DOWNLOAD_INVOICE_BY_ID_SUCCESS,
  DOWNLOAD_INVOICE_BY_ID_FAILURE,
  GET_GATEWAY_DATA,
  GET_GATEWAY_DATA_SUCCESS,
  GET_PAYMENT_HISTORY_FAILURE,
  GET_LATEST_PAYMENT_HISTORY,
  GET_LATEST_PAYMENT_HISTORY_SUCCESS,
  ADDRESS_UPDATED,
} from "./actions";

function* getInvoice() {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "basic " + sessionToken,
      },
    };
    const response = yield axios.get(endpoints.PAYMENT.GET_INVOICE, config);
    if (response.status === 200) {
      yield put({ type: GET_INVOICE_SUCCESS, data: response.data });
    } else {
      toast.error(response?.data?.error);
    }
  } catch (error) {
    toast.error(error?.response?.data?.error);
  }
}

function* getPaymentSetting() {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "basic " + sessionToken,
      },
    };
    const response = yield axios.get(endpoints.PAYMENT.GET_PAYMENT_SETTING, config);
    if (response.status === 200) {
      yield put({ type: GET_PAYMENT_SETTING_SUCCESS, data: response.data });
    } else {
      toast.error(response?.data?.error);
    }
  } catch (error) {
    toast.error(error?.response?.data?.error);
  }
}

function* updatePaymentSetting(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "basic " + sessionToken,
      },
    };
    const response = yield axios.put(endpoints.PAYMENT.UPDATE_PAYMENT_SETTING, payload.data.jsonBody, config);
    if (response.data) {
      yield put({ type: UPDATE_PAYMENT_SETTING_SUCCESS, data: response.data });
      toast.success('Payment settings updated successfully');
      yield put({type: GET_INVOICE});
      yield put({type: ADDRESS_UPDATED});
      yield put({
        type: GET_LATEST_PAYMENT_HISTORY,
        data: { size: 1, page: 1 }
      })
      yield put({
        type: GET_PAYMENT_HISTORY,
        data: { size:10, page:1 }
      })
    
    } else {
      yield put({ type: UPDATE_PAYMENT_SETTING_FAILURE });
      toast.error(response?.data?.error);
    }
  
  } catch (error) {
    yield put({ type: UPDATE_PAYMENT_SETTING_FAILURE });
    toast.error(error?.response?.data?.error ?? "Some error occured while updating...");
  }
}

function* getPaymentThreshold() {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "basic " + sessionToken,
      },
    };
    const response = yield axios.get(endpoints.PAYMENT.GET_PAYMENT_THRESHOLD, config);
    if (response.status === 200) {
      yield put({ type: GET_PAYMENT_THRESHOLD_SUCCESS, data: response.data });
    } else {
      toast.error(response?.data?.error);
    }
  } catch (error) {
    toast.error(error?.response?.data?.error);
  }
}

function* updatePaymentThreshold(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "basic " + sessionToken,
      },
    };
    const response = yield axios.put(endpoints.PAYMENT.UPDATE_PAYMENT_THRESHOLD, payload.data.jsonBody, config);
    if (response.data) {
      yield put({ type: UPDATE_PAYMENT_THRESHOLD_SUCCESS, data: response.data });
      toast.success('Payment threshold updated successfully')
    } else {
      yield put({ type: UPDATE_PAYMENT_THRESHOLD_FAILURE });
      toast.error(response?.data?.error);
    }
  } catch (error) {
    yield put({ type: UPDATE_PAYMENT_THRESHOLD_FAILURE });
    toast.error(error?.response?.data?.error ?? "Some error occured while updating...");
  }
}

function* getDeductionById(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "basic " + sessionToken,
      },
    };
    const response = yield axios.get(endpoints.PAYMENT.GET_DEDUCTION_BY_ID.replace(':id', payload.data.id), config);
    if (response.status === 200) {
      yield put({ type: GET_DEDUCTION_BY_ID_SUCCESS, data: response.data });
    } else {
      toast.error(response?.data?.error);
    }
  } catch (error) {
    toast.error(error?.response?.data?.error);
  }
}

function* getPaymentHistory(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "basic " + sessionToken,
      },
    };
    const endpoint = endpoints.PAYMENT.GET_PAYMENT_HISTORY.replace(/:size|:page/g, matched => {
      if(matched === ":size") return payload.data.size;
      else if(matched === ":page") return payload.data.page;
    })
    const response = yield axios.get(endpoint, config);
    if (response.data) {
      yield put({ type: GET_PAYMENT_HISTORY_SUCCESS, data: {data:response.data,count:response.count} });
    } else {
      yield put({ type: GET_PAYMENT_HISTORY_FAILURE });
      toast.error(response?.data?.error);
    }
  } catch (error) {
    yield put({ type: GET_PAYMENT_HISTORY_FAILURE });
    toast.error(error?.response?.data?.error);
  }
}

function* getLatestPaymentHistory() {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "basic " + sessionToken,
      },
    };
    const endpoint = endpoints.PAYMENT.GET_PAYMENT_HISTORY.replace(/:size|:page/g, () => 1)
    const response = yield axios.get(endpoint, config);
    if (response.data) {
      yield put({ type: GET_LATEST_PAYMENT_HISTORY_SUCCESS, data: response.data });
    } else {
      toast.error(response?.data?.error);
    }
  } catch (error) {
    toast.error(error?.response?.data?.error);
  }
}

function* getInvoiceById(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "basic " + sessionToken,
      },
    };
    const response = yield axios.get(endpoints.PAYMENT.GET_INVOICE_BY_ID.replace(':id', payload.data.id), config);
    if (response.status === 200) {
      yield put({ type: GET_INVOICE_BY_ID_SUCCESS, data: response.data });
    } else {
      toast.error(response?.data?.error);
    }
  } catch (error) {
    toast.error(error?.response?.data?.error);
  }
}

function* downloadInvoiceById(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    let URI = endpoints.PAYMENT.INVOICE_DOWNLOAD.replace(':id', payload.data.id).replace(':token', sessionToken); 
    const response = yield axios.get(
      URI, 
      config
    );   

    if (response.data) {
      yield put({ type: DOWNLOAD_INVOICE_BY_ID_SUCCESS, data: URI });
      toast.success('Invoice Download Successful')
      yield put({ type: RESET_DOWNLOAD_INVOICE_BY_ID})
    } else {
      yield put({ type: DOWNLOAD_INVOICE_BY_ID_FAILURE });
      toast.error(response?.data?.error);
    }
  } catch (error) {
    toast.error(error?.response?.data?.error ?? "Invoice Download Failed");
  }
}

function* getPaymentDetails(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "basic " + sessionToken,
      },
    };
    let endpoint;
    if(!payload.data.amt) {
      endpoint = endpoints.PAYMENT.GET_INIT_DATA.replace(':code', payload.data.code);
    } else {
      endpoint = endpoints.PAYMENT.GET_INIT_DATA_BY_ID.replace(/:code|:amount/g, matched => {
        if(matched === ":code") return payload.data.code;
        else if(matched === ":amount") return payload.data.amt;
      })
    }
    const response = yield axios.get(endpoint, config);
    if (response.data) {
      yield put({ type: GET_PAYMENT_DETAILS_SUCCESS, data: response.data });
    } else {
      toast.error(response?.data?.error);
      yield put({ type: GET_PAYMENT_DETAILS_FAILURE });
    }
  } catch (error) {
    yield put({ type: GET_PAYMENT_DETAILS_FAILURE });
    toast.error(error?.response?.data?.error);
  }
}

function* getGatewayData() {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "basic " + sessionToken,
      },
    };
    const response = yield axios.get(endpoints.PAYMENT.GET_GATEWAY_DATA, config);
    if (response.data) {
      yield put({ type: GET_GATEWAY_DATA_SUCCESS, data: response.data });
    } else {
      toast.error(response?.data?.error);
    }
  } catch (error) {
    toast.error(error?.response?.data?.error);
  }
}

export default function* watcherSaga() {
  yield takeLatest(DOWNLOAD_INVOICE_BY_ID, downloadInvoiceById);
  yield takeLatest(GET_INVOICE, getInvoice);
  yield takeLatest(GET_INVOICE_BY_ID, getInvoiceById);
  yield takeLatest(GET_PAYMENT_SETTING, getPaymentSetting);
  yield takeLatest(UPDATE_PAYMENT_SETTING, updatePaymentSetting);
  yield takeLatest(GET_PAYMENT_THRESHOLD, getPaymentThreshold);
  yield takeLatest(UPDATE_PAYMENT_THRESHOLD, updatePaymentThreshold);
  yield takeLatest(GET_DEDUCTION_BY_ID, getDeductionById);
  yield takeLatest(GET_PAYMENT_HISTORY, getPaymentHistory);
  yield takeLatest(GET_LATEST_PAYMENT_HISTORY, getLatestPaymentHistory);
  yield takeLatest(GET_PAYMENT_DETAILS, getPaymentDetails);
  yield takeLatest(GET_GATEWAY_DATA, getGatewayData);
}
