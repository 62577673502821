import React, { useEffect, useState } from "react";
import { Typography, Table,
    TableBody,
    // TableCell,
    TableContainer,
    TableHead,
    // TableRow,
    Paper,
    Divider
} from '@material-ui/core';
import CustomButton from "../../../../components/custombutton/CustomButton";
import { connect, ConnectedProps } from 'react-redux'
import AddRegistry from './AddRegistry';
import { getRegistries, deleteRegistry, clearRegistryInfo } from '../../redux/actions'
import CloseIcon from '@material-ui/icons/Close';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { getDateInStandardFormat } from '../../../../helpers/utils'
import ConfirmDeletePopup from '../../../../components/confirmdeletepopup/ConfirmDeletePopup';
import { useTranslation } from 'react-i18next';
import { StyledTableCell, StyledTableRow } from "../../../../components/styledtabelcell/StyledTabelCell";
import { Dispatch } from "redux";
import { Registry } from "../../../../models/Organization.model";
import { History } from 'history';

// function TabPanel(props) {
//     const { children, value, index, ...other } = props;

//     return (
//         <Typography
//             component="div"
//             role="tabpanel"
//             hidden={value !== index}
//             id={`settingsTab-${index}`}
//             aria-labelledby={`settingsTab-${index}`}
//             {...other}
//         >
//             {value === index && <Box p={2}>{children}</Box>}
//         </Typography>
//     );
// }

// TabPanel.propTypes = {
//     children: PropTypes.node,
//     index: PropTypes.any.isRequired,
//     value: PropTypes.any.isRequired,
// };

// function a11yProps(index) {
//     return {
//         id: `vertical-tab-${index}`,
//         'aria-controls': `vertical-tabpanel-${index}`,
//     };
// }

/* istanbul ignore next */
// const useStyles = makeStyles(() => ({
    // root: {
    //     backgroundColor: 'inherit',
    //     display: 'flex',
    //     padding: '0',
    //     margin: '0',
    //     [theme.breakpoints.down('md')]: {
    //         flexDirection: "column",
    //     }
    // },
    // tabs: {
    //     borderRight: `1px solid ${theme.palette.divider}`,
    //     [theme.breakpoints.down('md')]: {
    //         backgroundColor: 'white'
    //     }
    // },
    // tabPanel: {
    //     padding: '0',
    //     margin: '0',
    //     width: '100%',
    //     minWidth: 250,
    // }
// }));

interface Props extends PropsFromRedux {
    history: History
}

export function RegistryTab(props: Props) {
    //const [mode, setMode] = useState(0);
    const [openAddPopup, setOpenAddPopup] = useState(false);
    const [deleteObject, setDeleteObject] = useState<any>(null);
    const [editObject, setEditObject] = useState<any>(null);
    const [t] = useTranslation()
    
    useEffect(() => {
        props.getRegistries();
        return(() => {
            props.clearRegistryInfo()
        })
    }, []);

    const handleAddRegistry = () => {
        //setMode(1);
        setOpenAddPopup(true);
    }

    const handleCancelPopUp = () => {
        setOpenAddPopup(false);
        setEditObject(null);
    }

    const addSuccessCallback = () => {
        props.getRegistries();
        handleCancelPopUp();
    }

    const handleDeleteRegistry = (registry: Registry) => {  
        setDeleteObject(registry);
    }
    
    const handleDeleteRegistryDisAgreeHandler = () => { 
        setDeleteObject(null);
        //setDeleteConfirmPopup(false);
    }

    const handleDeleteRegistryAgreeHandler = () => { 
        props.deleteRegistry(deleteObject.id)
        handleDeleteRegistryDisAgreeHandler();
    }

    const handleEdit = (registry: Registry) => {
        const _registry = { ...registry } ;
        setOpenAddPopup(true);
        setEditObject(_registry);
    } 

    return (
        <div data-test="main-container">
            {
                //mode === 0 &&
                <>
                    <div className="listContainer">
                        <Typography color="textPrimary" variant="h5">
                        {t('Organization.RegistryTab.registries')}
                        </Typography>
                        <CustomButton
                            label={t('Organization.RegistryTab.addRegistry')}
                            onClick={() => handleAddRegistry()}
                            data-test="add-btn"
                        />
                    </div>
                    <TableContainer component={Paper} data-test="table-container">
                        <Table aria-label="simple table">
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell>{t('Organization.DnsTab.name')}</StyledTableCell>
                                    {/* <StyledTableCell align="left">Service Url</StyledTableCell>
                                    <StyledTableCell align="left">Project name</StyledTableCell> */}
                                    <StyledTableCell align="left">{t('Organization.DnsTab.provider')}</StyledTableCell>
                                    {/* <StyledTableCell align="left">User name</StyledTableCell> */}
                                    <StyledTableCell align="left">{t('Organization.DnsTab.created')}</StyledTableCell>
                                    <StyledTableCell align="center">{t('Organization.DnsTab.actions')}</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {props.registrylist && props.registrylist.length > 0 && props.registrylist.map((registry: Registry, ind: number) => {
                                    //const backup = JSON.parse(item.status);
                                    return (
                                        <StyledTableRow key={ind} data-test="registry-row">
                                            <StyledTableCell component="th" scope="row" data-test="registry-name"> {registry.name} </StyledTableCell>
                                            {/* <StyledTableCell data-test="backup-created" align="left">{registry.service}</StyledTableCell>
                                            <StyledTableCell align="left">{registry.project_name}</StyledTableCell> */}
                                            <StyledTableCell align="left" data-test="registry-provider">{registry.provider}</StyledTableCell>
                                            {/* <StyledTableCell align="left">{registry.user_name}</StyledTableCell> */}
                                            <StyledTableCell data-test="registry-created" align="left">{getDateInStandardFormat(registry.createdat)}</StyledTableCell>
                                            <StyledTableCell align="center">
                                                <IconButton aria-label="edit" onClick={() => handleEdit(registry)} data-test="registry-edit" >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton>
                                                    <DeleteIcon onClick={() =>  handleDeleteRegistry(registry)} data-test="registry-delete" />
                                                </IconButton>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            }
            {
                openAddPopup &&
                <Dialog
                    open={openAddPopup}
                    keepMounted
                    onClose={handleCancelPopUp}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    disableBackdropClick={true}
                    data-test="add-reg-popup"
                >
                    <DialogTitle id="alert-dialog-slide-title">
                        <Typography className='dialogtitle'>{ editObject ? t('Organization.RegistryTab.editRegistry') : t('Organization.RegistryTab.addRegistry') }</Typography>
                        <IconButton aria-label="close" size="small" className='right' onClick={handleCancelPopUp}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <Divider />
                    <DialogContent dividers>
                        <DialogContentText id="alert-dialog-slide-description">
                            <AddRegistry successCallback={addSuccessCallback} registryDetails={editObject}/>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            }
            {
                deleteObject && deleteObject.id > 0 &&
                <ConfirmDeletePopup
                    open={deleteObject && deleteObject.id > 0}
                    handleAgree={handleDeleteRegistryAgreeHandler}
                    handleDisAgree={handleDeleteRegistryDisAgreeHandler}
                    message={`This action will permanently delete registry data. Please type "${ deleteObject.name }" to delete the Registry : ${ deleteObject.name }`}
                    // yesText={t('Projects.VariablesTab.yesText')}
                    // noText={t('Projects.VariablesTab.noText')}
                    action="delete"
                    toMatchName={ deleteObject.name }
                    toDeleteModule="registry"
                    loading={props.deletingRegistry}
                    data-test="delete-reg-popup"
                />
            }
        </div>
    );
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
    registrylist: state.OrganizationReducer.registrylist,
    fetchingRegistries: state.OrganizationReducer.fetchingRegistries,
    deletingRegistry: state.OrganizationReducer.deletingRegistry
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
    getRegistries: () => dispatch(getRegistries()),
    clearRegistryInfo: () => dispatch(clearRegistryInfo()),
    deleteRegistry: (id: number) => dispatch(deleteRegistry(id))
    // fetchBackupList : (eId) => dispatch(fetchBackupList(eId)),
    // createBackup: (eId, jsonBody) => dispatch(createBackup(eId, jsonBody)),
    // preserveBackup: (eId, bId, jsonBody) => dispatch(preserveBackup(eId, bId, jsonBody)),
    // restoreBackup: (eId, bId) => dispatch(restoreBackup(eId, bId)),
    // deleteBackup: (eId, bId) => dispatch(deleteBackup(eId, bId)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(RegistryTab);
