import { 
  UPLOAD_FILE,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  UPLOAD_MULTIPLE_FILES,
  AUTOCOMPLETE_SEARCH,
  AUTOCOMPLETE_SEARCH_FAILURE,
  AUTOCOMPLETE_SEARCH_SUCCESS,
  CLEAR_SUGGESTIONS,
} from './actions';
import initialState from './initialState';

const reducer = (state = initialState, payload ) => {
  switch (payload.type) {
    case UPLOAD_FILE:
      return {
        ...state,
        uploadingFile : true
      }
    case UPLOAD_MULTIPLE_FILES:
      return {
        ...state,
        uploadingFile : true
      }
    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        uploadingFile: false
      }
    case UPLOAD_FILE_FAILURE:
      return {
        ...state,
        uploadingFile : false
      }
    case AUTOCOMPLETE_SEARCH:
      return {
        ...state,
        searching : true
      }
    case AUTOCOMPLETE_SEARCH_SUCCESS:
      return {
        ...state,
        searching: false,
        autocompleteOptions: payload?.data?.users
      }
    case AUTOCOMPLETE_SEARCH_FAILURE:
      return {
        ...state,
        searching : false
      }
    case CLEAR_SUGGESTIONS:
      return {
        ...state,
        autocompleteOptions : null
      }
    default:
      return state
  }
}

export default reducer