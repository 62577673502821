const OPERATOR_PREFIX = "@OPERATORS";

export const FETCH_OPERATORS = `${OPERATOR_PREFIX}/FETCH_OPERATORS`;
export const FETCH_OPERATORS_SUCCESS = `${OPERATOR_PREFIX}/FETCH_OPERATORS_SUCCESS`;
export const FETCH_OPERATORS_FAILURE = `${OPERATOR_PREFIX}/FETCH_OPERATORS_FAILURE`;
export const CLEAR_OPERATORS = `${OPERATOR_PREFIX}/CLEAR_OPERATORS`;

export const INITIATE_FETCH_OPERATORS_STATUS = `${OPERATOR_PREFIX}/INITIATE_FETCH_OPERATORS_STATUS`;
export const INITIATE_FETCH_OPERATORS_STATUS_SUCCESS = `${OPERATOR_PREFIX}/INITIATE_FETCH_OPERATORS_STATUS_SUCCESS`;
export const INITIATE_FETCH_OPERATORS_STATUS_FAILURE = `${OPERATOR_PREFIX}/INITIATE_FETCH_OPERATORS_STATUS_FAILURE`;
export const UPDATE_OPERATORS_STATUS = `${OPERATOR_PREFIX}/UPDATE_OPERATORS_STATUS`;

export const FETCH_AVAILABLE_OPERATORS = `${OPERATOR_PREFIX}/FETCH_AVAILABLE_OPERATORS`;
export const FETCH_AVAILABLE_OPERATORS_SUCCESS = `${OPERATOR_PREFIX}/FETCH_AVAILABLE_OPERATORS_SUCCESS`;
export const FETCH_AVAILABLE_OPERATORS_FAILURE = `${OPERATOR_PREFIX}/FETCH_AVAILABLE_OPERATORS_FAILURE`;

export const FETCH_OPERATOR_DETAILS = `${OPERATOR_PREFIX}/FETCH_OPERATOR_DETAILS`;
export const FETCH_OPERATOR_DETAILS_SUCCESS = `${OPERATOR_PREFIX}/FETCH_OPERATOR_DETAILS_SUCCESS`;
export const FETCH_OPERATOR_DETAILS_FAILURE = `${OPERATOR_PREFIX}/FETCH_OPERATOR_DETAILS_FAILURE`;
export const CLEAR_OPERATOR_DETAILS = `${OPERATOR_PREFIX}/CLEAR_OPERATOR_DETAILS`;
export const UNINSTALL_OPERATOR = `${OPERATOR_PREFIX}/UNINSTALL_OPERATOR`;
export const UNINSTALL_OPERATOR_SUCCESS = `${OPERATOR_PREFIX}/UNINSTALL_OPERATOR_SUCCESS`;
export const UNINSTALL_OPERATOR_FAILURE = `${OPERATOR_PREFIX}/UNINSTALL_OPERATOR_FAILURE`;

export const DEPLOY_OPERATOR = `${OPERATOR_PREFIX}/DEPLOY_OPERATOR`;
export const DEPLOY_OPERATOR_SUCCESS = `${OPERATOR_PREFIX}/DEPLOY_OPERATOR_SUCCESS`;
export const DEPLOY_OPERATOR_FAILURE = `${OPERATOR_PREFIX}/DEPLOY_OPERATOR_FAILURE`;

export const REDEPLOY_OPERATOR = `${OPERATOR_PREFIX}/REDEPLOY_OPERATOR`;
export const REDEPLOY_OPERATOR_SUCCESS = `${OPERATOR_PREFIX}/REDEPLOY_OPERATOR_SUCCESS`;
export const REDEPLOY_OPERATOR_FAILURE = `${OPERATOR_PREFIX}/REDEPLOY_OPERATOR_FAILURE`;

export const CREATE_OPERATOR_ENVIRONMENT = `${OPERATOR_PREFIX}/CREATE_OPERATOR_ENVIRONMENT`
export const CREATE_OPERATOR_ENVIRONMENT_SUCCESS = `${OPERATOR_PREFIX}/CREATE_OPERATOR_ENVIRONMENT_SUCCESS`
export const CREATE_OPERATOR_ENVIRONMENT_FAILURE = `${OPERATOR_PREFIX}/CREATE_OPERATOR_ENVIRONMENT_FAILURE`
export const UPDATE_OPERATOR_DETAILS_LIST = `${OPERATOR_PREFIX}/UPDATE_OPERATOR_DETAILS_LIST`;

export const FETCH_OPERATOR_LOGS = `${OPERATOR_PREFIX}/INITIATE_FETCH_OPERATOR_LOGS`;
export const FETCH_OPERATOR_LOGS_SUCCESS = `${OPERATOR_PREFIX}/INITIATE_FETCH_OPERATOR_LOGS_SUCCESS`;
export const FETCH_OPERATOR_LOGS_FAILURE = `${OPERATOR_PREFIX}/INITIATE_FETCH_OPERATOR_LOGS_FAILURE`;
export const UPDATE_OPERATOR_LOGS = `${OPERATOR_PREFIX}/UPDATE_OPERATOR_LOGS`;
export const CLEAR_OPERATOR_LOGS = `${OPERATOR_PREFIX}/CLEAR_OPERATOR_LOGS`;

export const fetchInstalledOperators = (clusterId) => ({
    type: FETCH_OPERATORS,
    data: { clusterId }
  });

export const clearInstalledOperators = () => ({
  type: CLEAR_OPERATORS
});  

export const fetchAvailableOperators = () => ({
  type: FETCH_AVAILABLE_OPERATORS
});

export const fetchOperatorDetails = ({packageName}) => ({
    type: FETCH_OPERATOR_DETAILS,
    data: { packageName }
  })

export const deployOperator = (clusterId, jsonBody, callBack) => ({
  type: DEPLOY_OPERATOR,
  data: {
    clusterId,
    jsonBody,
    callBack
  },
});

export const reDeployOperator = (clusterId, operatorId) => ({
  type: REDEPLOY_OPERATOR,
  data: {
    clusterId,
    operatorId,
  },
});

export const uninstallOperator = (clusterId, operatorId, callback) => ({
  type: UNINSTALL_OPERATOR,
  data: { clusterId, operatorId, callback},
});

export const clearOperatorDetails = () => ({
  type: CLEAR_OPERATOR_DETAILS
});

export const createOperatorEnvironment = (payload, history) => (
  {
      type: CREATE_OPERATOR_ENVIRONMENT,
      data: {
          payload,
          history
      }
  }
)
export const updateOperatorDetailsList = ({ operatorDetail }) => ({
  type: UPDATE_OPERATOR_DETAILS_LIST,
  data: { operatorDetail }
});

export const initiateFetchOperatorStatus = id => ({
  type: INITIATE_FETCH_OPERATORS_STATUS,
  data: {
    id,
  },
});

export const updateOperatorStatus = status => {
  return {
    type: UPDATE_OPERATORS_STATUS,
    data: { status },
  };
};

export const fetchOperatorLogs = (clusterId, operatorId) => ({
  type: FETCH_OPERATOR_LOGS,
  data: {
    clusterId,
    operatorId
  },
});

export const updateOperatorLogs = logs => {
  return {
    type: UPDATE_OPERATOR_LOGS,
    data: { logs },
  };
};

export const clearOperatorLogs = () => ({
  type: CLEAR_OPERATOR_LOGS,
});
