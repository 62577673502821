const BACKUP_PREFIX = '@BACKUP';

export const FETCH_BACKUP_LIST = `${ BACKUP_PREFIX }/FETCH_BACKUP_LIST`
export const FETCH_BACKUP_LIST_SUCCESS = `${ BACKUP_PREFIX }/FETCH_BACKUP_LIST_SUCCESS`
export const FETCH_BACKUP_LIST_FAILURE = `${ BACKUP_PREFIX }/FETCH_BACKUP_LIST_FAILURE`

export const CREATE_BACKUP = `${ BACKUP_PREFIX }/CREATE_BACKUP`
export const CREATE_BACKUP_SUCCESS = `${ BACKUP_PREFIX }/CREATE_BACKUP_SUCCESS`
export const CREATE_BACKUP_FAILURE = `${ BACKUP_PREFIX }/CREATE_BACKUP_FAILURE`

export const DELETE_BACKUP = `${ BACKUP_PREFIX }/DELETE_BACKUP`
export const DELETE_BACKUP_SUCCESS = `${ BACKUP_PREFIX }/DELETE_BACKUP_SUCCESS`
export const DELETE_BACKUP_FAILURE = `${ BACKUP_PREFIX }/DELETE_BACKUP_FAILURE`

export const PRESERVE_BACKUP = `${ BACKUP_PREFIX }/PRESERVE_BACKUP`
export const PRESERVE_BACKUP_SUCCESS = `${ BACKUP_PREFIX }/PRESERVE_BACKUP_SUCCESS`
export const PRESERVE_BACKUP_FAILURE = `${ BACKUP_PREFIX }/PRESERVE_BACKUP_FAILURE`

export const RESTORE_BACKUP = `${ BACKUP_PREFIX }/RESTORE_BACKUP`
export const RESTORE_BACKUP_SUCCESS = `${ BACKUP_PREFIX }/RESTORE_BACKUP_SUCCESS`
export const RESTORE_BACKUP_FAILURE = `${ BACKUP_PREFIX }/RESTORE_BACKUP_FAILURE`

export const FETCH_BACKUP_SETTINGS = `${ BACKUP_PREFIX }/FETCH_BACKUP_SETTINGS`
export const FETCH_BACKUP_SETTINGS_SUCCESS = `${ BACKUP_PREFIX }/FETCH_BACKUP_SETTINGS_SUCCESS`
export const FETCH_BACKUP_SETTINGS_FAILURE = `${ BACKUP_PREFIX }/FETCH_BACKUP_SETTINGS_FAILURE`

export const UPDATE_BACKUP_SETTINGS = `${ BACKUP_PREFIX }/UPDATE_BACKUP_SETTINGS`
export const UPDATE_BACKUP_SETTINGS_SUCCESS = `${ BACKUP_PREFIX }/UPDATE_BACKUP_SETTINGS_SUCCESS`
export const UPDATE_BACKUP_SETTINGS_FAILURE = `${ BACKUP_PREFIX }/UPDATE_BACKUP_SETTINGS_FAILURE`

export const FETCH_BACKUP_RESTORE_LIST = `${ BACKUP_PREFIX }/FETCH_BACKUP_RESTORE_LIST`
export const FETCH_BACKUP_RESTORE_LIST_SUCCESS = `${ BACKUP_PREFIX }/FETCH_BACKUP_RESTORE_LIST_SUCCESS`
export const FETCH_BACKUP_RESTORE_LIST_FAILURE = `${ BACKUP_PREFIX }/FETCH_BACKUP_RESTORE_LIST_FAILURE`

export const UPDATE_BACKUP_LIST = `${ BACKUP_PREFIX }/UPDATE_BACKUP_LIST`

export const CLEAR_BACKUP_RESTORE_DETAIL = `${ BACKUP_PREFIX }/CLEAR_BACKUP_RESTORE_DETAIL`
export const CLEAR_BACKUP_SETTINGS = `${ BACKUP_PREFIX }/CLEAR_BACKUP_SETTINGS`

// export const CHECK_RESTORE_STATUS_COMPLETED = `${ BACKUP_PREFIX }/CHECK_RESTORE_STATUS_COMPLETED`
// export const CHECK_RESTORE_STATUS_COMPLETED_FALSE = `${ BACKUP_PREFIX }/CHECK_RESTORE_STATUS_COMPLETED_FALSE`

export const fetchBackupList = (eId, isHelm) => ({
	type: FETCH_BACKUP_LIST,
	data: { eId, isHelm }
})

export const createBackup = (eId, jsonBody, isHelm) => ({
	type: CREATE_BACKUP,
	data: { eId, jsonBody, isHelm }
})

export const deleteBackup = (eId, bId, isHelm) => ({
	type: DELETE_BACKUP,
	data: { eId, bId, isHelm }
})

export const preserveBackup = (eId, bId, jsonBody, isHelm) => ({
	type: PRESERVE_BACKUP,
	data: { eId, bId, jsonBody, isHelm }
})

export const restoreBackup = (eId, bId, isHelm) => ({
	type: RESTORE_BACKUP,
	data: { eId, bId, isHelm }
})

export const fetchBackupSettings = (eId, isHelm) => ({
	type: FETCH_BACKUP_SETTINGS,
	data: { eId, isHelm }
})

export const clearBackupSettings = () => ({
	type: CLEAR_BACKUP_SETTINGS,
})

export const updateBackupSettings = (eId, jsonBody, callback, isHelm) => ({
	type: UPDATE_BACKUP_SETTINGS,
	data: { eId, jsonBody, callback, isHelm }
})

export const fetchBackupRestoreList = (eId, isHelm) => ({
	type: FETCH_BACKUP_RESTORE_LIST,
	data: { eId, isHelm }
})

export const clearBackupRestoreDetail = () => ({
	type: CLEAR_BACKUP_RESTORE_DETAIL,
})

export const updateBackupList = (jsonBody, isHelm) => ({
	type: UPDATE_BACKUP_LIST,
	data: { jsonBody, isHelm }
})

// export const checkBackupRestoreComplete = () => ({
// 	type: CHECK_RESTORE_STATUS_COMPLETED
// })

// export const checkBackupRestoreCompleteFalse = () => ({
// 	type: CHECK_RESTORE_STATUS_COMPLETED_FALSE
// })