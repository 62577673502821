import React, { useState, useEffect } from "react";
import {
  ButtonBase,
  Typography,
  Button,
  Grid,
  Tooltip,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import {
  ContainerCollapse,
  ContainerOverViewCard,
  ContainerMonitoring,
  ContainerMounts,
  ContainerHealthCheck,
  ContainerWebShell,
  ContainerLogs,
} from "../../components/pods";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import { connect, ConnectedProps } from "react-redux";
import ActivityLogs from "../../components/activitylogs/ActivityLogs";
import BackdropLoader from "../../components/loader/BackdropLoader";
import ConfirmActionPopup from "../../components/confirmactionpopup/ConfirmActionPopup";
import { useTranslation } from "react-i18next";
import {
  //fetchContainerInsights,
  //clearContainerInsights,
  fetchContainerLogsUrl,
  clearContainerLogsUrl,
  fetchContainerWebshellUrl,
  clearContainerWebshellUrl,
  fetchPodEvents,
  clearPodEvents,
} from "./redux/actions";
import { removeServiceAccountFromMount } from "../../helpers/utils";
import "./PodInfo.css";
import { Container, Pod, PodEvent } from "../../models/Environment.model";
import { Dispatch } from "redux";

interface Props extends PropsFromRedux {
  environmentId: number
  podInfo: Pod
  handleBack: (updateAction?:boolean) => void
  restartCount: number
  isHelmEnv: boolean
}

interface Payload {
  env_id: number,
  pod_name: string,
  type: string,
  container_name: string,
  lines: number,
}

export const PodInfo = (props: Props) => {
  const { podInfo, handleBack, environmentId, restartCount } = props;

  const [selectedTab, setSelectedTab] = useState("pod");
  const [selectedContainer, setSelectedContainer] = useState<Container | null>(null);
  // const [selectedContainerIndex, setSelectedContainerIndex] = useState<number | null>(null);
  const [filteredPodEvents, setFilteredPodEvents] = useState([]);

  const [isWebShellOpen, setIsWebShellOpen] = useState(false);
  const [isLogsOpen, setIsLogsOpen] = useState(false);

  const [selectedLines, setSelectedLines] = useState("50");

  const [containerStatuses, setContainerStatuses] = useState<any>(null);

  const [isLogsConfirmActionOpen, setIsLogsConfirmActionOpen] = useState(false);
  const [isWebShellConfirmActionOpen, setIsWebShellConfirmActionOpen] =
    useState(false);

  const [t] = useTranslation();

  const handleTabChange = (tab: string | Container, index: number | null) => {
    setIsWebShellOpen(false);
    setIsLogsOpen(false);
    if (typeof tab === 'string' && tab === "pod") {
      setSelectedTab(tab);
    } else {
      if (typeof tab === 'object') {
        setSelectedContainer(tab);
        // setSelectedContainerIndex(index);
        setSelectedTab(tab.name);
      }
    }
  };

  const handleWebShellOpen = () => {
    setIsWebShellOpen(true);
    props.fetchContainerWebshellUrl(
      {
        env_id: environmentId,
        pod_name: podInfo.name,
        type: "exec",
        container_name: selectedContainer ? selectedContainer.name : "",
      },
      props.isHelmEnv
    );
  };

  const handleWebShellClose = () => {
    setIsWebShellConfirmActionOpen(true);
  };

  const handleLogsOpen = () => {
    setIsLogsOpen(true);
    props.fetchContainerLogsUrl(
      {
        env_id: environmentId,
        pod_name: podInfo.name,
        type: "logs",
        container_name: selectedContainer ? selectedContainer.name : "",
        lines: parseInt(selectedLines),
      },
      props.isHelmEnv
    );
  };

  const handleLogsClose = () => {
    setIsLogsConfirmActionOpen(true);
  };

  const handleLinesChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedLines(e.target.value as string);
    props.fetchContainerLogsUrl(
      {
        env_id: environmentId,
        pod_name: podInfo.name,
        type: "logs",
        container_name: selectedContainer ? selectedContainer.name : "",
        lines: parseInt(e.target.value as string),
      },
      props.isHelmEnv
    );
  };

  const renderContainerOptions = () => {
    if (isLogsOpen) {
      return (
        <Grid
          container
          spacing={1}
          alignItems="center"
          style={{ marginBottom: 10 }}
        >
          <Grid item>
            <FormControl variant="outlined">
              <Select
                labelId="log-lines-select-label"
                id="log-lines-select"
                value={selectedLines}
                onChange={e => handleLinesChange(e)}
                MenuProps={{
                  anchorOrigin: { vertical: "bottom", horizontal: "left" },
                  transformOrigin: { vertical: "top", horizontal: "left" },
                  getContentAnchorEl: null,
                }}
                data-test="log-lines-select"
              >
                <MenuItem value="50">{t("Environment.LogsTab.fifty")}</MenuItem>
                <MenuItem value="100">
                  {t("Environment.LogsTab.hundred")}
                </MenuItem>
                <MenuItem value="500">{t("Environment.LogsTab.five")}</MenuItem>
                <MenuItem value="1000">
                  {t("Environment.LogsTab.thou")}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleLogsClose}
              startIcon={<CloseIcon />}
              data-test="close-logs-button"
            >
              {t("Pods.closeLogs")}
            </Button>
          </Grid>
        </Grid>
      );
    }

    if (isWebShellOpen) {
      return (
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleWebShellClose}
          startIcon={<CloseIcon />}
          data-test="exit-webshell-button"
        >
          {t("Pods.exitShell")}
        </Button>
      );
    }

    return (
      <>
        <Button
          variant="contained"
          color="primary"
          onClick={handleLogsOpen}
          style={{ marginRight: "10px" }}
          data-test="view-logs-button"
        >
          {t("Pods.viewLogs")}
        </Button>
        {containerStatuses && selectedContainer && 
          containerStatuses[selectedContainer.name] &&
          containerStatuses[selectedContainer.name].isStarted && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleWebShellOpen}
              data-test="web-shell-button"
            >
              {t("Pods.shell")}
            </Button>
          )}
      </>
    );
  };

  const getPodInfoTitle = () => {
    if (selectedTab === "pod") {
      return t("Pods.overview");
    }

    if (isLogsOpen) {
      return `${selectedContainer?.name} - ${t(
        "Environment.EnvironmentInfo.logs"
      )}`;
    }

    if (isWebShellOpen) {
      return `${selectedContainer?.name} - ${t("Pods.shell")}`;
    }

    return selectedContainer ? selectedContainer.name : "";
  };

  const launchExternalUrl = (url: string) => {
    window.open(
      url,
      url,
      `scrollbars=yes,resizable=yes,status=no,location=no,toolbar=no,menubar=no,height=500,width=800,top=${
        (window.innerHeight / 2) - (500 / 2)
      },left=${(window.innerWidth / 2) - (800 / 2)}`
    );
  };

  const handleLaunchExternalShell = () => {
    props.fetchContainerWebshellUrl(
      {
        env_id: environmentId,
        pod_name: podInfo.name,
        type: "exec",
        container_name: selectedContainer ? selectedContainer.name : "",
      },
      props.isHelmEnv,
      launchExternalUrl
    );
  };

  const handleLaunchExternalLogs = () => {
    props.fetchContainerLogsUrl(
      {
        env_id: environmentId,
        pod_name: podInfo.name,
        type: "logs",
        container_name: selectedContainer ? selectedContainer.name : "",
        lines: parseInt(selectedLines),
      },
      props.isHelmEnv,
      launchExternalUrl
    );
  };

  const logsConfirmActionAgreeHandler = () => {
    setIsLogsConfirmActionOpen(false);
    setIsLogsOpen(false);
    props.clearContainerLogsUrl();
  };

  const logsConfirmActionDisagreeHandler = () => {
    setIsLogsConfirmActionOpen(false);
  };

  const webShellConfirmActionAgreeHandler = () => {
    setIsWebShellConfirmActionOpen(false);
    setIsWebShellOpen(false);
    props.clearContainerWebshellUrl();
  };

  const webShellConfirmActionDisagreeHandler = () => {
    setIsWebShellConfirmActionOpen(false);
  };

  useEffect(() => {
    // props.fetchContainerInsights(environmentId, podInfo.name);
    props.fetchPodEvents(environmentId, props.isHelmEnv);

    return () => {
      // props.clearContainerInsights();
      props.clearContainerLogsUrl();
      props.clearContainerWebshellUrl();
      props.clearPodEvents();
    };
  }, []);

  useEffect(() => {
    if (podInfo.status?.containerStatuses) {
      let statuses: any = {};

      podInfo.status.containerStatuses.forEach(status => {
        statuses[status.name] = {
          restartCount: status.restartCount,
          createdAt: status.state?.running?.startedAt,
          isStarted: status.started,
        };
      });

      setContainerStatuses(statuses);
    }
  }, [podInfo]);

  useEffect(() => {
    if (props.podEvents?.length) {
      setFilteredPodEvents(
        props.podEvents.filter((podEvent: PodEvent) => {
          if (podEvent.involvedObject.kind !== "Pod") {
            return false;
          }

          if (podEvent.involvedObject.name === podInfo.name) {
            return true;
          }

          if (
            podEvent.involvedObject.name.slice(0, -17) ===
            podInfo.name.slice(0, -17)
          ) {
            return true;
          }
        })
      );
    }
  }, [props.podEvents, podInfo]);

  return (
    <div data-test="pod-info-container">
      <ButtonBase
        onClick={()=>{
          handleBack()
        }}
        style={{ color: "#357dfd" }}
        data-test="backButton"
      >
        <ArrowBackIcon fontSize="small" />{" "}
        <span className="jobNav">{t("Pods.backToList")}</span>
      </ButtonBase>
      <Typography className="m-t-10 m-b-20" variant="h3">
        {podInfo?.name}
      </Typography>
      <div className="pod-tabs-container">
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={3}
            md={3}
            lg={2}
            className="pod-info-tabs-grid"
          >
            <div className="pod-info-tabs">
              <ButtonBase
                onClick={() => handleTabChange("pod", null)}
                className={clsx(
                  "tab-label pod-tab-label",
                  selectedTab === "pod" ? "active" : ""
                )}
                data-test="pod-label-button"
              >
                <Tooltip title={t("Pods.overview")} arrow>
                  <Typography
                    color="textPrimary"
                    variant="h5"
                    className="container-label"
                  >
                    {t("Pods.overview")}
                  </Typography>
                </Tooltip>
                <ArrowForwardIosIcon />
              </ButtonBase>
              <div className="container-labels-div">
                <Typography color="textPrimary" variant="h5">
                  {t("Pods.containers")}
                </Typography>
                {podInfo.containers?.map((container, index) => (
                  <ButtonBase
                    onClick={() => handleTabChange(container, index)}
                    className={clsx(
                      "tab-label container-tab-label",
                      selectedTab === container.name ? "active" : ""
                    )}
                    key={container.name}
                    data-test="container-label-button"
                  >
                    <Tooltip title={container.name} arrow>
                      <Typography
                        color="textPrimary"
                        variant="h5"
                        className="container-label"
                      >
                        {container.name}
                      </Typography>
                    </Tooltip>
                    <ArrowForwardIosIcon />
                  </ButtonBase>
                ))}
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={9}
            md={9}
            lg={10}
            className="pod-info-container-grid"
          >
            <>
              <Grid
                container
                style={{ justifyContent: "space-between" }}
                alignItems="center"
              >
                <Grid item>
                  <Typography
                    className="m-t-10 m-b-20"
                    variant="h5"
                    data-test="pod-info-title"
                  >
                    {getPodInfoTitle()}
                  </Typography>
                </Grid>
                <Grid item>
                  {selectedTab !== "pod" && renderContainerOptions()}
                </Grid>
              </Grid>

              <div
                className={isLogsOpen || isWebShellOpen ? "d-none" : ""}
                data-test="pod-info-components"
              >
                {podInfo && (
                  <ContainerOverViewCard
                    pod={selectedTab === "pod" ? podInfo : null}
                    envId={environmentId}
                    container={selectedTab === "pod" ? null : selectedContainer}
                    type={selectedTab === "pod" ? "pod" : "container"}
                    handleBack={handleBack}
                    //containerInsights={props.containerInsights}
                    //containerIndex={selectedContainerIndex}
                    restartCount={
                      selectedTab === "pod"
                        ? restartCount
                        : containerStatuses && selectedContainer &&
                          containerStatuses[selectedContainer.name] &&
                          containerStatuses[selectedContainer.name].restartCount
                    }
                    containerCreatedAt={
                      containerStatuses && selectedContainer &&
                      containerStatuses[selectedContainer.name] &&
                      containerStatuses[selectedContainer.name].createdAt
                    }
                  />
                )}

                {selectedTab !== "pod" && (
                  <ContainerCollapse
                    title={t("Pods.Mounts.mounts")}
                    defaultExpand={true}
                  >
                    <ContainerMounts
                      mounts={removeServiceAccountFromMount(
                        selectedContainer?.volumeMounts
                      )}
                    />
                  </ContainerCollapse>
                )}

                {selectedTab === "pod" && (
                  <ContainerCollapse
                    title={t("Pods.events")}
                    defaultExpand={true}
                  >
                    <ActivityLogs activityLogs={filteredPodEvents} showAllLogs={true}/>
                  </ContainerCollapse>
                )}

                {!props.isHelmEnv && (
                  <ContainerCollapse
                    title={t("Projects.CreateProject.monitoringLabel")}
                    defaultExpand={true}
                  >
                    {
                      selectedContainer ?
                      <ContainerMonitoring
                        environmentId={environmentId}
                        podName={podInfo.name}
                        containerName={selectedContainer.name}
                        type={selectedTab === "pod" ? "pod" : "container"}
                      /> : 
                      <ContainerMonitoring
                        environmentId={environmentId}
                        podName={podInfo.name}
                        type={selectedTab === "pod" ? "pod" : "container"}
                      /> 
                    }
                  </ContainerCollapse>
                )}

                {selectedTab !== "pod" && (
                  <ContainerCollapse
                    title={t("Pods.HealthCheck.healthCheck")}
                    defaultExpand={true}
                  >
                    <ContainerHealthCheck
                      readinessProbe={selectedContainer?.readinessProbe}
                      livenessProbe={selectedContainer?.livenessProbe}
                      startupProbe={selectedContainer?.startupProbe}
                    />
                  </ContainerCollapse>
                )}
              </div>

              <ContainerLogs
                url={props.containerLogsUrl}
                isOpen={isLogsOpen && props.containerLogsUrl ? true : false}
                handleLaunchExternalLogs={handleLaunchExternalLogs}
              />

              <ContainerWebShell
                url={props.containerWebShellUrl}
                handleLaunchExternalShell={handleLaunchExternalShell}
                isOpen={isWebShellOpen && props.containerWebShellUrl}
              />
            </>
          </Grid>
        </Grid>
      </div>
      {props.fetchingContainerLogsUrl && (
        <BackdropLoader
          message={t("Pods.fetchingLogs")}
          data-test="fetching-logs-loader"
        />
      )}
      {props.fetchingContainerWebShellUrl && (
        <BackdropLoader
          message={t("Pods.initializingWebshell")}
          data-test="fetching-webshell-loader"
        />
      )}

      <ConfirmActionPopup
        open={isLogsConfirmActionOpen}
        handleAgree={logsConfirmActionAgreeHandler}
        handleDisAgree={logsConfirmActionDisagreeHandler}
        message={t("Pods.logsConfirmMessage")}
        yesText={t("Projects.VariablesTab.yesText")}
        noText={t("Projects.VariablesTab.noText")}
        data-test="logs-confirm-action"
      />

      <ConfirmActionPopup
        open={isWebShellConfirmActionOpen}
        handleAgree={webShellConfirmActionAgreeHandler}
        handleDisAgree={webShellConfirmActionDisagreeHandler}
        message={t("Pods.webShellConfirmMessage")}
        yesText={t("Projects.VariablesTab.yesText")}
        noText={t("Projects.VariablesTab.noText")}
        data-test="web-shell-confirm-action"
      />
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: any) => {
  return {
    containerInsights: state.PodsReducer.containerInsights,
    fetchingContainerInsights: state.PodsReducer.fetchingContainerInsights,
    containerLogsUrl: state.PodsReducer.containerLogsUrl,
    fetchingContainerLogsUrl: state.PodsReducer.fetchingContainerLogsUrl,
    containerWebShellUrl: state.PodsReducer.containerWebShellUrl,
    fetchingContainerWebShellUrl:
      state.PodsReducer.fetchingContainerWebShellUrl,
    podEvents: state.PodsReducer.podEvents,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    // fetchContainerInsights: (envId, podName) =>
    //   dispatch(fetchContainerInsights(envId, podName)),
    //clearContainerInsights: () => dispatch(clearContainerInsights()),
    fetchContainerLogsUrl: (jsonBody: Payload, isHelmEnv: boolean, callback?: (url: string) => void) =>
      dispatch(fetchContainerLogsUrl(jsonBody, isHelmEnv, callback)),
    clearContainerLogsUrl: () => dispatch(clearContainerLogsUrl()),
    fetchContainerWebshellUrl: (jsonBody: Omit<Payload, "lines">, isHelmEnv: boolean, callback?: (url:string) => void) =>
      dispatch(fetchContainerWebshellUrl(jsonBody, isHelmEnv, callback)),
    clearContainerWebshellUrl: () => dispatch(clearContainerWebshellUrl()),
    fetchPodEvents: (envId: number, isHelmEnv: boolean) =>
      dispatch(fetchPodEvents(envId, isHelmEnv)),
    clearPodEvents: () => dispatch(clearPodEvents()),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(PodInfo);
