export default {
  
  elevation1: {
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '10px',
  },
  elevation2: {
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '10px',
  },
  elevation3: {
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '10px',
  },
  elevation4: {
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 2px 4px 0 rgba(63,63,68,0.2)',
    borderRadius: '10px',
  },
  elevation5: {
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 4px 6px -1px rgba(63,63,68,0.2)',
    borderRadius: '10px',
  },
  elevation6: {
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 6px 9px -3px rgba(63,63,68,0.2)',
    borderRadius: '10px',
  }
  
};
