//import './CreateProject.css';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import React, { Component } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { connect,ConnectedProps } from "react-redux";
import { withRouter, Prompt , RouteComponentProps} from "react-router-dom";
import ImageCropper from "../../../components/imagecropper/ImageCropper";
import BackdropLoader from "../../../components/loader/BackdropLoader";
// import OrgSubscriptionCard from '../../../components/subscriptioncard/OrgSubscriptionCard';
import SubscriptionOrgList from "../../../components/subscriptionlist/SubscriptionOrgList";
import MuiTextField from "../../../components/textfield/MuiTextField";
import {
  setCurrentProject,
  updateBreadcrumb,
} from "../../project/redux/actions";
import { createOrganization, getOrgPlansList } from "../redux/actions";
import "./CreateOrganization.css";
import { WithTranslation, withTranslation } from "react-i18next";
import { validateInputField } from "../../../helpers/utils";
import {OrganizationModel} from './../../../models/Organization.model';
import {Dispatch} from 'redux';
import { SubscriptionModel } from "../../../models/Project.model";

interface Props extends PropsFromRedux, WithTranslation,RouteComponentProps {
  //setCurrentProjectInfo?: (id: number) => void;
  //tReady: boolean;
  source?:number;
  // history:History
}
 interface State {
  name:                     string;
  isSubscriptionSelected:   boolean;
  selectedSubscriptionId:   number;
  isOrgFeildError:          boolean;
  orgFeildErrorMessage:     string;
  selectedFile:             any;
  imagePreview:             string;
  domain:                   string;
  isDomainFeildError:       boolean;
  domainFeildErrorMessage:  string;
  imgUploadeditModeEnabled: boolean;
  isShowPreview:            boolean;
  isBlocking:               boolean;
}

type JSONbodyType = {
  name: string;
  organization_plan_id: number;
  description: string;
  domain: string;
  image?: string;
  id?: number;
};

export class CreateOrganization extends Component<Props,State> {
  constructor(props:Props) {
    super(props);
    this.state = {
      name: "",
      //isSubmitButtonDisabled : true,
      isSubscriptionSelected: false,
      selectedSubscriptionId: 0,
      //selectedSubscriptionAmount : 0,
      isOrgFeildError: false,
      orgFeildErrorMessage: "",
      //selectedSubscriptionName : 'Starter',
      //orgPlansList : this.props.orgPlansList,
      selectedFile: null,
      imagePreview: "",

      domain: "",
      isDomainFeildError: false,
      domainFeildErrorMessage: "",

      imgUploadeditModeEnabled: false,
      isShowPreview: false,
      isBlocking: false,
    };
  }

  initializeData = (orgDetails:OrganizationModel) => {
    this.setState({
      name: orgDetails.name,
      domain: orgDetails.domain,
      imagePreview: orgDetails.image,
      selectedSubscriptionId: orgDetails.organization_plan_id,
    });
  };

  componentDidMount() {
    this.props.getOrgPlansList();
    if (this.props.source === 2) {
      if (this.props.organizationInfo.id > 0) {
        this.initializeData(this.props.organizationInfo);
      }
    } else {
      this.props.updateBreadcrumb([]);
      this.props.setCurrentProject(0);
    }
  }

  UNSAFE_componentWillReceiveProps = (nextProps:Props) => {
    if (
      this.props.source !== 2 &&
      nextProps.orgPlansList !== this.props.orgPlansList
    ) {
      this.setState({
        //orgPlansList : nextProps.orgPlansList,
        selectedSubscriptionId: nextProps.orgPlansList[0].id,
      });
    }
    if (this.props.source === 2 && nextProps.organizationInfo.id > 0) {
      if (
        !this.props.organizationInfo.id ||
        this.props.organizationInfo.id !== nextProps.organizationInfo.id
      ) {
        this.initializeData(nextProps.organizationInfo);
      }
    }
  };

  handleCreateOrg = () => {
    const payload: JSONbodyType= {
      name: this.state.name.trim(),
      organization_plan_id: this.state.selectedSubscriptionId,
      description: "",
      domain: this.state.domain.trim(),
    };

    let iconPayload = null;
    if (this.state.selectedFile) {
      iconPayload = new FormData();
      iconPayload.append("file_name", this.state.selectedFile.name);
      iconPayload.append("file_type", this.state.selectedFile.type);
      iconPayload.append("file", this.state.selectedFile);
    } else {
      if (this.props.source === 2)
        payload.image = this.props.organizationInfo.image;
    }

    if (this.props.source === 2) payload.id = this.props.organizationInfo.id;
    this.setState({ isBlocking: false });
    this.props.createOrganization(payload, iconPayload, this.props.history);
  };

  handleOrgNameOnChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    let validation = validateInputField(e.target.value as string);
    this.setState({
      name: e.target.value,
      isOrgFeildError: validation.error,
      orgFeildErrorMessage: validation.error
        ? this.props.t(validation.message)
        : "",
    });
    this.setState({ isBlocking: true });
  };

  handleDomainOnChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    let validation = validateInputField(e.target.value as string, "domain");
    this.setState({
      domain: e.target.value,
      isDomainFeildError: validation.error,
      domainFeildErrorMessage: validation.error
        ? this.props.t(validation.message)
        : "",
    });
    this.setState({ isBlocking: true });
  };

  handleOrgNameOnBlur = () => {
    const { name } = this.state;

    if (name.length === 0) {
      this.setState({
        isOrgFeildError: true,
        orgFeildErrorMessage: this.props.t(
          "Organization.CreateOrganization.nameError"
        ),
      });
    }
  };

  // handleDomainOnBlur = () => {
  //     const { domain } = this.state;

  //     if(domain.length === 0){
  //         this.setState({
  //             isDomainFeildError :true,
  //             domainFeildErrorMessage: `Domain can't be empty`,
  //         })
  //     }
  // }

  handleSubscriptionClick = (subscription: SubscriptionModel) => {
    this.setState({
      selectedSubscriptionId: subscription.id,
      //selectedSubscriptionAmount: subscription.price,
      //selectedSubscriptionName : subscription.name
    });
  };

  handleIconSelection = (data:string) => {
    this.setState(
      {
        selectedFile: data,
        isShowPreview: true,
      },
      () => this.handleImgDoneClick()
    );
    this.setState({ isBlocking: true });
  };

  handleImgUpload = () => {
    this.setState({
      imgUploadeditModeEnabled: true,
    });
  };

  handleImgDoneClick = () => {
    this.setState({
      imagePreview: URL.createObjectURL(this.state.selectedFile),
    });
    this.handleImgUploadCancel();
  };

  handleImgUploadCancel = () => {
    this.setState({
      imgUploadeditModeEnabled: false,
    });
  };

  isSubmitEnable = () => {
    let disable = true;
    if (this.props.source === 2) {
      const { organizationInfo } = this.props;
      if (
        organizationInfo.name === this.state.name &&
        organizationInfo.domain === this.state.domain &&
        organizationInfo.organization_plan_id ===
          this.state.selectedSubscriptionId &&
        !this.state.selectedFile
      ) {
        return disable;
      }
    }
    if (
      this.state.name.trim().length > 0 &&
      !this.state.isOrgFeildError &&
      (this.state.domain.trim().length === 0 ||
        (this.state.domain.trim().length > 0 &&
          !this.state.isDomainFeildError)) &&
      this.state.selectedSubscriptionId
    ) {
      disable = false;
    }
    return disable;
  };

  render() {
    const { orgPlansList, t } = this.props;
    return (
      <div data-test="main-container">
        <Prompt
          when={this.state.isBlocking}
          message={() => t("CronJob.CronJobList.errorMessage")} //  ${location.pathname}
          data-test="unsave-prompt"
        />
        {this.props.source !== 2 && (
          <Typography
            variant="h5"
            className="m-b-20"
            data-test="create-org-label"
          >
            {t("Organization.CreateOrganization.createOrg")}
          </Typography>
        )}
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Grid container spacing={2}>
              <Grid item xs={12} md={9}>
                <Card className="orginfo-card">
                  <CardHeader
                    title={t(
                      "Organization.CreateOrganization.organizationInfo"
                    )}
                  />
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        {/* <label>Job Name</label> */}
                        <MuiTextField
                          //id='ProjectName'
                          value={this.state.name}
                          //className='oneRemMarginSeperator'
                          label={t("Organization.CreateOrganization.name")}
                          error={this.state.isOrgFeildError}
                          helperText={this.state.orgFeildErrorMessage}
                          // required
                          onBlur={this.handleOrgNameOnBlur}
                          onChange={(e:React.ChangeEvent<HTMLInputElement>) => this.handleOrgNameOnChange(e)}
                          data-test="org-name-input"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MuiTextField
                          //id='ProjectName'
                          value={this.state.domain}
                          //className='oneRemMarginSeperator'
                          label={t("Organization.CreateOrganization.domain")}
                          error={this.state.isDomainFeildError}
                          helperText={this.state.domainFeildErrorMessage}
                          //required
                          // onBlur={this.handleDomainOnBlur}
                          onChange={(e:React.ChangeEvent<HTMLInputElement>) => this.handleDomainOnChange(e)}
                          data-test="org-domain-input"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={3} xs={12} sm={6}>
                <Card>
                  <CardHeader
                    title={t("Organization.CreateOrganization.logo")}
                  />
                  <CardContent>
                    <Grid container justify="center" alignItems="center">
                      <div className="imgContainer" data-test="img-container">
                        <div
                          className="imgWrapper"
                          style={{
                            backgroundImage: `url(${
                              this.state.imagePreview
                                ? this.state.imagePreview
                                : "/images/infographics/noimg.jpg"
                            })`,
                          }}
                          data-test="org-icon"
                        >
                        </div>
                        {/* {isAuthorized("update", projectRole.name) && ( */}
                        <span className="circlebtn">
                          <EditIcon
                            onClick={() => this.handleImgUpload()}
                            fontSize="small"
                            className="upload-icon-align"
                            data-test="upload-icon"
                          />
                        </span>
                        {/* )} */}
                      </div>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Card>
              <CardHeader title={t("Organization.CreateOrganization.plan")} />
              <CardContent>
                <div className="subscriptionCardsContainer">
                  <Grid container className="subscriptionContianer" spacing={2}>
                    {typeof orgPlansList === "undefined" &&
                      [0, 1, 2, 3, 4, 5, 6, 7].map((value) => {
                        return (
                          <Grid item md={3} sm={6} xs={12} key={value}>
                            <Card
                              variant="outlined"
                              style={{ padding: "1rem" }}
                              data-test="skeleton-card"
                            >
                              <SkeletonTheme height={250}>
                                <div className="oneRemMarginBottomSeperator">
                                  <Skeleton />
                                </div>
                                <div className="oneRemMarginBottomSeperator">
                                  <Skeleton />
                                </div>
                                <br />
                                <Skeleton count={5} />
                              </SkeletonTheme>
                            </Card>
                          </Grid>
                        );
                      })}
                  </Grid>

                  <Grid container className="subscriptionContianer" spacing={2}>
                    {
                      // orgPlansList && orgPlansList.map(subscription => {
                      //     return (
                      //         <Grid item md={ 3 } sm= { 6 } xs={ 12 } key={ subscription.id }>
                      //             <OrgSubscriptionCard
                      //               details = { subscription }
                      //               handleSubscriptionClick = { this.handleSubscriptionClick }
                      //               selectedSubscriptionId = { this.state.selectedSubscriptionId }
                      //             />
                      //         </Grid>
                      //     )
                      // })
                      <SubscriptionOrgList
                        handleSubscriptionClick={this.handleSubscriptionClick}
                        selectedSubscriptionId={
                          this.state.selectedSubscriptionId
                        }
                        subscriptionList={orgPlansList}
                        data-test="sub-list"
                      />
                    }
                  </Grid>
                </div>
              </CardContent>
            </Card>

            <div className="margintoponerem">
              <Button
                onClick={() => this.handleCreateOrg()}
                variant="contained"
                color="primary"
                disabled={this.isSubmitEnable()}
                data-test="create-btn"
              >
                {this.props.source === 2
                  ? t("Organization.CreateOrganization.update")
                  : t("Organization.CreateOrganization.create")}
              </Button>
              {this.props.creatingOrganization && (
                <BackdropLoader
                  message={
                    this.props.source === 2
                      ? t("Organization.CreateOrganization.updatingOrg")
                      : t("Organization.CreateOrganization.creatingOrg")
                  }
                />
              )}
            </div>
          </Grid>
        </Grid>

        {this.state.imgUploadeditModeEnabled && (
          <ImageCropper
            title={t("Organization.CreateOrganization.chooseIcon")}
            dontUpload={true}
            isOpen={this.state.imgUploadeditModeEnabled}
            onClose={this.handleImgUploadCancel}
            onSuccess={this.handleIconSelection}
            data-test="image-cropper"
          />
        )}
      </div>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state:any) => {
  return {
    orgPlansList: state.OrganizationReducer.orgPlansList,
    creatingOrganization: state.OrganizationReducer.creatingOrganization,
    organizationInfo: state.OrganizationReducer.organizationInfo,
  };
};

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch:Dispatch) => {
  return {
    createOrganization: (jsonBody:JSONbodyType, iconPayload:any, history:RouteComponentProps["history"]) =>
      dispatch(createOrganization(jsonBody, iconPayload, history)),
    getOrgPlansList: () => dispatch(getOrgPlansList()),
    setCurrentProject: (id:number) => dispatch(setCurrentProject(id)),
    updateBreadcrumb: (breadcrumbData:any) =>
      dispatch(updateBreadcrumb(breadcrumbData)),
  };
};

const connector= connect(
  mapStateToProps,
  mapDispatchtoProps
)
export default withRouter(
 connector(withTranslation()(CreateOrganization))
);
type PropsFromRedux = ConnectedProps<typeof connector>;