import React from 'react';
import { Card, CardContent, Grid, Tooltip, Divider, Typography, Avatar, ButtonBase } from '@material-ui/core';
import './ProjectCardDetails.css';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import ScheduleIcon from '@material-ui/icons/Schedule';
//import GitHubIcon from '@material-ui/icons/GitHub';
import { getStatusColor, is_hex_light, isAuthorized, getAppNameChar } from '../../helpers/utils'
import { Link } from 'react-router-dom';
import { DateHandler } from '../dateHandler/DateHandler';
// import { CreateApp_TemplateTypes } from '../../constants/enums';
import { RepoName } from "../../components/reponame/RepoName"
import { useTranslation } from 'react-i18next';
import paths from '../../constants/paths';
import { LabelHandler } from '../labelHandler/LabelHandler';
import { AppDetailsModel } from '../../models/Application.model';
import { UserRole } from '../../models/Environment.model';
import { History } from 'history';

type Props = {
    appData: AppDetailsModel,
    history: History,
    projectRole: UserRole
}

export const ProjectCardDetails = ({ appData, history, projectRole }: Props) => {
    const [t] = useTranslation();
    const isHelmChart = appData?.chart_id ? true : false;
    const navigateToCreateEnv = (appId: number) => {
        history.push({
            pathname: isHelmChart ? paths.CREATEHELMENVIRONMENT.replace(":appId", appId.toString()) : paths.CREATEENVIRONMENT.replace(":appId", appId.toString())
        });
    }
    
    const navigateToEnv = (eId: number) => {
        history.push({
            pathname: isHelmChart ? paths.HELMENVIRONMENTINFO.replace(":eId", eId.toString()) : paths.ENVIRONMENTINFO.replace(":eId", eId.toString())
        });
    }

    const navigateToOperatorEnv = (appId: number) =>{
        history.push({
            pathname: paths.CREATEOPERATORENVIRONMENT.replace(":appId", appId.toString())
        });
    }
    
    const getImage = () => {
        let _image = "";
        if (appData) {
            if (isHelmChart && appData.chart && appData.chart.Icon) {
                _image = appData.chart.Icon
            }
            else {
                _image = appData.plugin?.image;
            }
        }
        return _image;
    }

    const getClusterFontColor = (bgColor: string) => {
      if (!bgColor) {
        return "#43425D";
      }
  
      if (is_hex_light(bgColor)) {
        return "#000000";
      }
  
      return "#ffffff";
    };
    
    // const getRepoName = (value) => {
    //     let repoName = '';
    //     if(value){
    //         // /^(https|git)(:\/\/|@)([^\/:]+)[\/:]([^\/:]+)\/(.+).git$/
    //         const res = /^(https|git)(:\/\/|@)([^/:]+)[/:]([^/:]+)\/(.+).git$/.exec(value);
    //         if(res)
    //             repoName = res[res.length -1];
    //     }
    //     return repoName;
    // }

    return (
        <>
            {/* <Paper elevation={ 1 } >
          <Link href={ `/app/${ appData.id }` } underline='none'>
          <Link to={ {
                                      pathname: '/app/' +  appData.id,
                                      state: { appData }
                                    } } style={{textDecoration:'none'}}>
              <div className='projectListsContainer'>
                  <div className='projectTitleShorter'>

                      <p className='projectSmallTitle'>{ appData.name != '' && appData.name.charAt(0).toUpperCase() }</p>
                  </div>

                  <img src={appData.plugin && appData.plugin.image} className="projectImg"/>

                  <div className='projectTitlesContainer'>
                      <span className='projectTitle'>{ appData.name }</span>
                      <span className='apps'>{appData.cluster && appData.cluster.zone}</span>
                  </div>
                 
              </div>
          </Link>
      </Paper> */}

            <Card data-test="appCardContainer">
                <CardContent>
                    <Link data-test="appdetails-link" to={ { pathname: paths.APPDETAILS.replace(":appId", (appData.id).toString()), state: { appData } } } style={ { textDecoration: 'none', color:'#43425D' } }>
                        <Grid container spacing={ 2 } >
                            <Grid item xs={2}>
                                <img src={getImage()} className="projectImg" data-test="appPluginImg" alt=''/>
                            </Grid>
                            <Grid item xs={10}>
                                <div className='projectTitlesContainer'>
                                    <span className='projectTitle' data-test="appName">{appData.name}</span>
                                    {
                                        appData.owner && <span className='apps' data-test="appCreateByName">{t("Projects.ProjectInfo.createdBy")} { appData.owner.first_name + " " + appData.owner.last_name}</span>
                                    }
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container spacing={ 2 }>
                          <Grid item md={4} className="topgridalign">
                            <LabelHandler
                              icon={<LanguageOutlinedIcon />}
                              iconTooltip={t("ClusterLabel")}
                              label={
                                <span
                                  style={{
                                    backgroundColor: appData?.cluster?.color
                                      ? appData.cluster.color
                                      : "#ffffff",
                                    color:  getClusterFontColor(appData?.cluster?.color),
                                    padding: "3px 5px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  {appData?.cluster?.name}
                                </span>
                              }
                              labelTooltip={appData?.cluster?.zone}
                              data-test="clustername-label"
                            />
                          </Grid>

                            <Grid item md={4} className="topgridalign">
                            <DateHandler date={appData.createdat} data-test="createdat-date"  icon={<ScheduleIcon className={"svgicon"}/>}/>
                                {/* <Tooltip title={getDateInStandardFormat(appData.createdat)} arrow>
                                    <ScheduleIcon className="svgicon" />
                                </Tooltip>
                                <span data-test="appCreateTime">{ getDiffDays(appData.createdat, true) }</span> */}
                            </Grid>
                            <RepoName source={2} appDetails={appData} />
                            {/* {
                                appData.service_type === CreateApp_TemplateTypes.git &&
                                <Grid item md="4" className="topgridalign">
                                    <Tooltip title="Repository name" arrow>
                                        <img src="/images/icons/git_blue.svg" alt="Git" title="Git" className="svgiconimg"/>
                                    </Tooltip>
                                    <span data-test="appRepoUrl">{ getRepoName(appData.git_repo_url) }</span>
                                </Grid>
                            }
                            {
                                appData.service_type === CreateApp_TemplateTypes.cr &&
                                <Grid item md="4" className="topgridalign">
                                    <Tooltip title="Repository name" arrow>
                                        <img src="/images/icons/containerregistry_blue.svg" alt="Container Registry" title="Container Registry" className="svgiconimg"/>
                                    </Tooltip>
                                    <span data-test="appRepoUrl">{appData.image_namespace}/{appData.image_repo}</span>
                                </Grid>
                            } */}
                        </Grid>
                    </Link>

                        <Grid container spacing={2} data-test="appEnvContainer">
                            <Grid item md={12}>
                                <Divider className="carddivider" />
                                <Typography  variant="body1" className="m-b-10" data-test="appEnvHeader">
                                {t('Environments')}
                                </Typography>
                                <div className="envavatardiv">
                                    {
                                        appData.env && appData.env.length > 0 &&
                                        appData.env.map((envItem, ind) =>
                                            <Tooltip title={ envItem.name + " (" + envItem.status +")" } arrow key={ ind } data-test="envs-card">
                                                <ButtonBase 
                                                    onClick={ () => navigateToEnv(envItem.id) }
                                                    data-test="env-navigate"
                                                >
                                                    <Avatar 
                                                    // style={{ boxShadow: "inset 0 0 0px 2px white, 0 0 0px 1px " + getStatusColor(envItem.status)}}
                                                    style={{ boxShadow: "inset 0 0 0px 2px white, 0 0 0px 1px "+ getStatusColor(envItem.status), 
                                                    backgroundColor:getStatusColor(envItem.status),
                                                }}
                                                        variant="rounded" 
                                                        className="envavatar"
                                                    > {getAppNameChar(envItem?.name)}
                                                    </Avatar>
                                                </ButtonBase>
                                            </Tooltip>
                                        )
                                    }
                            {appData?.cluster?.active && isAuthorized('create', projectRole?.name) && 
                                <Tooltip title={t('AddEnvironment')} arrow>
                                    <ButtonBase onClick={ () => appData?.operator_package_name? navigateToOperatorEnv(appData.id): navigateToCreateEnv(appData.id) } data-test="addenv-button" >
                                        <Avatar variant="rounded" className="addenvavatar"  >+</Avatar>
                                    </ButtonBase>
                                </Tooltip>
                            }
                                </div>
                            {/* {
                                !appData?.cluster?.active &&
                                    <Typography color="error" className={classes.envAddInfo} variant="caption">Associated cluster is <b><em>inactive</em></b>. Cluster must be active in order to add environment(s).</Typography>
                            } */}
                            </Grid>
                        </Grid>
                </CardContent>
            </Card>
        </>
    )
}

export default ProjectCardDetails;