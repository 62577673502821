import { OperatorClass, Channel } from "../../models/Operator.model";
export const Operators = {
  getDefaultChannel: (operator: OperatorClass) => {
    return operator.channels.find((ch) => ch.name === operator.channel);
  },

  global: (channel: Channel) => {
    return !!channel?.currentCSVDesc?.installModes?.find(
      (m) => m.type === "AllNamespaces"
    )?.supported;
  },
};
