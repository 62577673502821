import React, { Component } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next';
import { Button,
         withStyles,
         Typography,
         FormControl,
         MenuItem,
         Select,
         Card , CardContent,
         FormHelperText,
         InputLabel,
         TextField,
        //  ButtonBase,
       } from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
// import ArrowBackIcon from "@material-ui/icons/ArrowBack";
// import { fetchPluginsList } from './redux/actions';
import { fetchProjectDetails, clearProjectDetails } from '../../project/redux/actions'
import { fetchChartDetailById } from '../../organization/redux/actions'
import { fetchPluginDetailsById, createApplication, fetchGITOrganizations, fetchGITRepos, fetchGITOrgRepos, clearPluginDetails } from '../redux/actions'
import {clearOperatorDetails, fetchOperatorDetails} from "../../operators/redux/actions"
import { fetchClusterList, fetchRegionList } from './redux/actions'
import MuiTextField from '../../../components/textfield/MuiTextField';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { CreateApp_TemplateTypes } from '../../../constants/enums'
import Loader from '../../../components/loader/Loader'
import BackdropLoader from '../../../components/loader/BackdropLoader';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { AppConstants } from '../../../constants/appconstants';
import { validateInputField } from '../../../helpers/utils';
import BackButton from '../../../components/button/BackButton';
// import { compose } from 'redux';
import { Theme } from "@material-ui/core";
import { WithStyles, createStyles } from "@material-ui/core";
import { Dispatch } from "redux";
import { RouteComponentProps } from 'react-router-dom';
import { AuthProvider } from '../../../models/Account.model';
import { AppDetailsModel } from '../../../models/Application.model';
import { History } from 'history';
import { ClusterModel } from '../../../models/Cluster.model';

/* istanbul ignore next */
const useStyles = (theme: Theme) => createStyles({
    container:{
        marginTop: 20,
        
    },
    paperContainer: {
        //width:'100%',
        border : '1px solid #0057fa',
        //display: 'flex',
        //flexDirection : 'column',
    },
    icon:{
        fontSize:85
    },
    iconContainer: {
     flex : 1,
     alignItems: 'center',
     display: 'flex',
     flexDirection: 'column',
     justifyContent: 'flex-end',
    },
    gridItem: {
        width: '25%',
        minWidth: 260
    },
    logo: {
        height: '12vh',
        width:'auto',
        objectFit:'cover',
        padding:'10px 0'
    },
    selectRegion:{
        textAlign:'left'
    },
    selectBox:{
        //minWidth : '100%',
        marginBottom : '1rem',
        fullWidth: true,
        display: "flex"
    },
    regionDiv:{
        minWidth:350,
        [ theme.breakpoints.down('sm') ]: {
            minWidth:260,
        },
    },
    dark:{
        color:'#000000'
    },
    typoPadding : {
        paddingBottom: '1.2rem'
    },
    loader: {
        textAlign: "center"
    }
});

type Payload = Pick<AppDetailsModel, "name"|"project_id"|"cluster_id"|"service_type"|"chart_id"|"operator_package_name"
|"plugin_id"|"git_service"|"git_repository">

interface ExtendedPayload extends Payload{
    region: string; 
    image_service?: string
}

type Json = { service_name: string }

interface MatchParams {
    pId: string;
    tType: string;
    plugId: string;
    source: string;
}

interface Props extends WithTranslation, PropsFromRedux, RouteComponentProps<MatchParams>, WithStyles<typeof useStyles> {}

type State = {
    appName: string,
    isappFeildError : boolean,
    isDropDownFeildError : boolean,
    isSubmitButtonDisabled : boolean,
    appFeildErrorMessage : string,
    dropDownErrorMessage : string,
    region : string,
    isRepoDropDownFeildError :boolean,
    repoDropDownErrorMessage : string,
    selectedRepository:string,
    selectedTemplateSourceName: string,
    selectedTemplateType: number,
    selectedRepositoryType: number,
    selectedOrg: string,
    selectedCluster: string,
    isClusterDropDownError: boolean,
    clusterDropDownErrorMessage: string,
    operatorPackageName: string,
    clusterId: string|number,
    isOrgFeildError: boolean,
    orgErrorMessage: string,
    isRepoTypeDropDownFeildError: boolean,
    repoTypeDropDownErrorMessage: string,
    serviceProvider: null | AuthProvider
}

export class RegionSelection extends Component<Props, State, MatchParams> {
    constructor(props: Props){
        super(props);
        this.state = {
            appName:'My App',
            isappFeildError : false,
            isDropDownFeildError : false,
            isSubmitButtonDisabled : true,
            appFeildErrorMessage : '',
            dropDownErrorMessage : '',
            region : 'Select Region',
            isRepoDropDownFeildError :false,
            repoDropDownErrorMessage : '',
            selectedRepository:'Select Repository',
            selectedTemplateSourceName: '',
            selectedTemplateType: 0,
            selectedRepositoryType: -1,
            selectedOrg: 'Select Organization',
            selectedCluster: "Select Cluster",
            isClusterDropDownError: false,
            clusterDropDownErrorMessage: "",
            operatorPackageName: "",
            clusterId: "",
            isOrgFeildError: false,
            orgErrorMessage:"",
            isRepoTypeDropDownFeildError: false,
            repoTypeDropDownErrorMessage: "",
            serviceProvider: null
        }
    }
  
    componentDidMount() {
        if(this.props.match.params.tType === CreateApp_TemplateTypes.helm.toString()){
            this.props.fetchChartDetailById(this.props.match.params.plugId)

        }
        else if(this.props.match.params.tType === CreateApp_TemplateTypes.op.toString()){
            if(this.props.match.params.plugId){
                if(this.props.match.params.plugId !== "x"){
                    const temp = this.props.match.params.plugId.split("__");
                    this.setState({
                        operatorPackageName:temp[0],
                        clusterId:parseInt(temp[1]),
                        region: temp[2]
                    })

                    this.props.fetchOperatorDetails({packageName: temp[0]})

                }else{
                    this.setState({operatorPackageName: "x", clusterId: "x", region: 'x'})
                }
                
            }

        }
        else if (this.props.match.params.tType !== CreateApp_TemplateTypes.cr.toString()) {
            this.props.fetchPluginDetailsById(parseInt(this.props.match.params.plugId));
        } else {
            const _provider: any = AppConstants.crProviders.find(x => x.service_name === this.props.match.params.source)
            this.setState({
                serviceProvider: _provider
            })
        }
        // this.props.fetchPluginDetailsById(1);
        if(this.props.match.params.pId)
        {
            this.props.fetchProjectDetails(this.props.match.params.pId);
        }
        this.props.fetchRegionListAction();
        this.setState({
            selectedTemplateSourceName: this.props.match.params.source, //Get_Enum_Description(CreateApp_TemplateTypes, this.props.match.params.tType)
            selectedTemplateType: parseInt(this.props.match.params.tType)
            
        })
    }
    
    componentWillUnmount(){
        this.props.clearProjectDetails()
        this.props.clearPluginDetails();
        //clear org list
        //clear repo list
        //clear region list
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if(nextProps) {
            if (nextProps.projectDetails?.id > 0) {
                if (!this.props.projectDetails.id || this.props.projectDetails.id !== nextProps.projectDetails.id)
                {
                    if(nextProps.projectDetails.region) {
                        this.setState({
                            region : nextProps.projectDetails.region
                        })
                    } else if(nextProps?.regions?.length === 1) {
                        this.setState({
                            region : nextProps.regions[0]
                        })
                    }
                }
            }
            if (this.props.regions.length === 0 && nextProps.regions.length > 0)
            {
                if (nextProps.regions.length === 1)
                {
                    this.setState({
                        region: nextProps.regions[0]
                    }, () => {
                        if (this.props.currentOrganization && this.props.currentOrganization.id > 0) {
                            this.props.fetchClusterList(this.state.region)
                        }
                    })
                }
            }
        }
        // if((!this.props.gitOrganizations && nextProps.gitOrganizations?.length > 0) || (!this.props.gitRepos && nextProps.gitRepos?.length > 0)) {
        //     if(nextProps.gitOrganizations?.length > 0) this.setState({selectedOrg: nextProps.gitOrganizations[0].login}, () => {
        //         this.props.fetchGITOrgRepos( this.state.selectedOrg, { "service_name": this.state.selectedTemplateSourceName });
        //     });
        //     if(nextProps.gitRepos?.length > 0) this.setState({selectedRepository: nextProps.gitRepos[0].id})
        // }
    }


    getGitRepoName=()=>{
      if( this.state.selectedTemplateSourceName==="bitbucket"){
       return  this.state.selectedOrg !== "Select Organization" && this.state.selectedOrg !== ""? `${this.state.selectedOrg}/${this.state.selectedRepository}` :  this.state.selectedRepository.toString();
      }
      return   this.state.selectedRepository.toString()

        
    }

    handleCreateApp = () => {
        let payload: any = {
            "name": this.state.appName.trim(),
            "project_id": this.props.match.params.pId ? parseInt(this.props.match.params.pId) : 0,
            "region": this.state.region,
            "cluster_id": this.props.currentOrganization && this.props.currentOrganization.id > 0
                && this.state.selectedTemplateType !== CreateApp_TemplateTypes.op ? this.state.selectedCluster : 1,
            "service_type": this.state.selectedTemplateType
        }
        if(this.state.selectedTemplateType === CreateApp_TemplateTypes.helm) {
            payload = {
                ...payload,
                "chart_id": this.props.match.params.plugId && this.props.match.params.plugId !== "x" ? this.props.match.params.plugId : "",
            }
        }else if(this.state.selectedTemplateType === CreateApp_TemplateTypes.op) {
            payload = {
                "name": this.state.appName.trim(),
                "project_id": this.props.match.params.pId ? parseInt(this.props.match.params.pId) : 0,
                //"service_type": this.state.selectedTemplateType,
                "operator_package_name": this.state.operatorPackageName,
                "cluster_id":this.state.clusterId,
                "region": this.state.region
            }
        }else if(this.state.selectedTemplateType === CreateApp_TemplateTypes.manageDB) {
            payload = {
                ...payload,
                "plugin_id": this.props.match.params.plugId && this.props.match.params.plugId !== "x" ? parseInt(this.props.match.params.plugId) : 0,
                "git_service": "",
                "git_repository": "",
                "image_service": "",
                "image_namespace": "",
                "image_repo": "",
                "managed_service" : "true"
            }
        }
       else{
            payload = {
                ...payload,
                //"cluster_id":1,
                "plugin_id": this.props.match.params.plugId && this.props.match.params.plugId !== "x" ? parseInt(this.props.match.params.plugId) : 0,
                "git_service": this.state.selectedTemplateType === CreateApp_TemplateTypes.git ? this.state.selectedTemplateSourceName : "",
                "git_repository": this.state.selectedTemplateType === CreateApp_TemplateTypes.git  ? this.getGitRepoName()    : "",
                "image_service": this.state.selectedTemplateType === CreateApp_TemplateTypes.cr ? this.state.selectedTemplateSourceName : "",
                "image_namespace": this.state.selectedTemplateType === CreateApp_TemplateTypes.cr ? this.state.selectedOrg : "",
                "image_repo": this.state.selectedTemplateType === CreateApp_TemplateTypes.cr ? this.state.selectedRepository.toString() : "",
            }
        }
        this.props.createApplication(payload, this.props.history)
    }

    handletextFeildsOnChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        let validation = validateInputField(e.target.value);
        this.setState({
            appName : e.target.value as string,
            isSubmitButtonDisabled : validation.error,
            isappFeildError : validation.error,
            appFeildErrorMessage: validation.error ? this.props.t(validation.message) : "", 
        })

    }

    habdleDropDownOnChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        if(e.target.value === 'Select Region'){
            this.setState({
                region : e.target.value,
                isDropDownFeildError :true,
                dropDownErrorMessage : this.props.t('App.RegionSelection.dropDownErrorMessage'),  
            })
        } else {
            this.setState({
                region : e.target.value as string,
                isDropDownFeildError :false,
                dropDownErrorMessage: '',
                selectedCluster: "Select Cluster"
            })
            if (this.props.currentOrganization && this.props.currentOrganization.id > 0) {
                this.props.fetchClusterList(e.target.value as string)
            }
        }
    }

    handleClusterOnChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        if (e.target.value === 'Select Cluster') {
            this.setState({
                selectedCluster: e.target.value,
                isClusterDropDownError: true,
                clusterDropDownErrorMessage: this.props.t('App.RegionSelection.dropDownErrorMessage'),
            })
        } else {
            this.setState({
                selectedCluster: e.target.value as string,
                isClusterDropDownError: false,
                clusterDropDownErrorMessage: '',
            })
        }
    }

    handleRepoTypeDropDownChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        if(e.target.value === -1){
            this.setState({
                selectedRepositoryType : e.target.value,
                isRepoTypeDropDownFeildError :true,
                repoTypeDropDownErrorMessage : this.props.t('App.RegionSelection.repoTypeDropDownErrorMessage'),  
            })
        } else {
            this.setState({
                selectedRepositoryType : e.target.value as number,
                isRepoTypeDropDownFeildError :false,
                repoTypeDropDownErrorMessage : '',  
            })
            if(e.target.value === 1)
            {
                this.props.fetchGITOrganizations({ "service_name": this.state.selectedTemplateSourceName }, this.state.selectedTemplateType)
            }
            else if(e.target.value === 2)
            {
                this.props.fetchGITRepos({ "service_name": this.state.selectedTemplateSourceName }, this.state.selectedTemplateType);
            }
            this.setState({
                selectedOrg: '',
                selectedRepository:'',
            })
            // this.setState({
            //     selectedOrg: 'Select Organization',
            //     selectedRepository:'Select Repository',
            // })
        }
    }

    // handleOrgChange = (e) => {
    //     if(e.target.value === 'Select Organization'){
    //         this.setState({
    //             selectedOrg : e.target.value,
    //             isOrgFeildError :true,
    //             orgErrorMessage : this.props.t('App.RegionSelection.orgErrorMessage'),  
    //         })
    //     } else {
    //         this.setState({
    //             selectedOrg : e.target.value,
    //             isOrgFeildError :false,
    //             orgErrorMessage : '',  
    //         })
    //         this.props.fetchGITOrgRepos(e.target.value, { "service_name": this.state.selectedTemplateSourceName }, this.state.selectedTemplateType);
    //         this.setState({
    //             selectedRepository:'Select Repository',
    //         })
    //     }
    // }

    // handleRepoDropDownChange = (e) => {

    //     if(e.target.value === 'Select Repository'){
    //         this.setState({
    //             selectedRepository : e.target.value,
    //             isRepoDropDownFeildError :true,
    //             repoDropDownErrorMessage : this.props.t('App.RegionSelection.repoTypeDropDownErrorMessageOne'),  
    //         })
    //     } else {
    //         this.setState({
    //             selectedRepository : e.target.value,
    //             isRepoDropDownFeildError :false,
    //             repoDropDownErrorMessage : '',  
    //         })
    //     }
    // }

    handleRepoSelect = (e: React.ChangeEvent<{}>, newValue: any) => {
        const validRepo = this.props.gitRepos?.find((repo: any) => {
            return repo.name === newValue
        })
        if(validRepo){
            this.setState({
                selectedRepository : validRepo.value,
                isRepoDropDownFeildError :false,
                repoDropDownErrorMessage : '',  
            })

        }else{
            this.setState({
                selectedRepository : "",
                isRepoDropDownFeildError :true,
                repoDropDownErrorMessage : this.props.t('App.RegionSelection.repoTypeDropDownErrorMessageTwo'),  
            })   
        }

    }

    handleOrgSelect = (e: React.ChangeEvent<{}>, newValue: any) => {
        const validOrg = this.props.gitOrganizations?.find((org: any) => {
            return org.name === newValue
        })
        if(validOrg){
            this.setState({
                selectedOrg : validOrg.value,
                isOrgFeildError :false,
                orgErrorMessage : '',  
            })
            this.props.fetchGITOrgRepos(validOrg.value, { "service_name": this.state.selectedTemplateSourceName }, this.state.selectedTemplateType);
            this.setState({
                selectedRepository:"",
            })

        }else{
            this.setState({
                selectedOrg : "",
                isOrgFeildError :true,
                orgErrorMessage : this.props.t('App.RegionSelection.orgErrorMessage'), 
            })   
        }
        
    }

    handleAppNameOnBlur = () => {
        const { appName } = this.state;

        if(appName.length === 0){
            this.setState({
                isappFeildError :true,
                appFeildErrorMessage: this.props.t('App.RegionSelection.appFieldErrorMessage'),
            })
        }
    }   

    checkCanSubmit = () => {
        let isErrorExists = true;

        if(this.state.appName && this.state.selectedTemplateType === CreateApp_TemplateTypes.op){
            return false;
        }

        if(!this.state.isappFeildError && this.state.region !== 'Select Region')
        {
            if (this.state.selectedTemplateType &&
                (this.state.selectedTemplateType !== CreateApp_TemplateTypes.default && this.state.selectedTemplateType !== CreateApp_TemplateTypes.manageDB
                    && this.state.selectedTemplateType !== CreateApp_TemplateTypes.helm && this.state.selectedTemplateType !== CreateApp_TemplateTypes.op ) )
            {
                if(this.state.selectedRepositoryType === 2 || (this.state.selectedRepositoryType === 1 && this.state.selectedOrg !== "Select Organization" && this.state.selectedOrg !== "")) {
                    if(this.state.selectedRepository !== 'Select Repository' && this.state.selectedRepository !== "")
                        isErrorExists = false;
                }
            }
            else {
                isErrorExists = false;
            }

            if (this.props.currentOrganization && this.props.currentOrganization.id > 0
                && this.state.selectedTemplateType !== CreateApp_TemplateTypes.op) {
                if (this.state.selectedCluster === "Select Cluster") {
                    isErrorExists = true
                }
            }
        }
        return isErrorExists;
    }
    handleBack = () =>{
        this.props.clearOperatorDetails()
        this.props.history.goBack()
    }

    render () {
        const { classes, pluginDetails, regions, gitRepos, t, chartDetails, operatorDetails } = this.props;        
        return (
            <>
            <BackButton
                clickHandler={this.handleBack}
                data-test='backButton'
            />
            <div className='alignCenter ' data-test="main-container">
                
                <Card>
                    <CardContent className={ classes.regionDiv }>
              
                        <div className='alignCenter'>
                            {
                                    this.state.selectedTemplateType !== CreateApp_TemplateTypes.cr && Object.keys(pluginDetails).length === 0 && !chartDetails && !operatorDetails &&(
                                <SkeletonTheme height={100} width={100}> 
                                    <div className='oneRemMarginBottomSeperator'>
                                        <Skeleton width={100}/>
                                    </div>
                                    <div className='oneRemMarginBottomSeperator'>
                                        <Skeleton width={100}/>
                                    </div>
                                </SkeletonTheme>
                                )
                            }
                                {this.state.selectedTemplateType === CreateApp_TemplateTypes.helm && chartDetails && Object.keys(chartDetails).length !== 0 && <img src={chartDetails ? chartDetails.icon : ""} className={classes.logo} alt="" data-test="chart-image" />}
                                {(this.state.selectedTemplateType === CreateApp_TemplateTypes.git ||
                                    this.state.selectedTemplateType === CreateApp_TemplateTypes.default || this.state.selectedTemplateType === CreateApp_TemplateTypes.manageDB)
                                    && Object.keys(pluginDetails).length !== 0 && <img src={pluginDetails ? pluginDetails.image : ""} className={classes.logo} alt="" data-test="plugin-image" />}
                                {this.state.selectedTemplateType === CreateApp_TemplateTypes.cr && this.state.serviceProvider && <img src={this.state.serviceProvider.icon} className={classes.logo} alt="" data-test="plugin-image" />}

                                {this.state.selectedTemplateType === CreateApp_TemplateTypes.op && operatorDetails && Object.keys(operatorDetails).length !== 0 && <img src={operatorDetails ? operatorDetails.thumbUrl : ""} className={classes.logo} alt="" data-test="chart-image" />}
                        </div>
                            <Typography align="center" style={{ display: 'block', marginTop: '1rem', marginBottom: '1rem' }} variant="h6" className={classes.dark} data-test="plugin-name">
                                {this.state.selectedTemplateType === CreateApp_TemplateTypes.helm && chartDetails ? chartDetails.name : ""}
                                {this.state.selectedTemplateType === CreateApp_TemplateTypes.op && operatorDetails ? operatorDetails.displayName : ""}
                                {(this.state.selectedTemplateType === CreateApp_TemplateTypes.git ||
                                    this.state.selectedTemplateType === CreateApp_TemplateTypes.default || this.state.selectedTemplateType === CreateApp_TemplateTypes.manageDB)
                                        && pluginDetails ? pluginDetails.name : ""}
                                {this.state.selectedTemplateType === CreateApp_TemplateTypes.cr && this.state.serviceProvider ? this.state.serviceProvider.name : "" }
                            </Typography>

                        <MuiTextField value={this.state.appName} 
                          variant='outlined'
                          className='oneRemMarginBottomSeperator' 
                          style={{display: 'block'}} 
                          id="appName" 
                          label={t('App.RegionSelection.appNameLabel')}
                          error={this.state.isappFeildError}
                          helperText={this.state.appFeildErrorMessage}
                          required
                          onBlur = {this.handleAppNameOnBlur}
                          autoFocus
                          name="appName" 
                          onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                            this.handletextFeildsOnChange(e);
                          }}
                          data-test="name-input"
                        />
                        { 
                            (this.state.selectedTemplateType !== CreateApp_TemplateTypes.default && this.state.selectedTemplateType !== CreateApp_TemplateTypes.manageDB &&
                                this.state.selectedTemplateType !== CreateApp_TemplateTypes.helm && this.state.selectedTemplateType !== CreateApp_TemplateTypes.op)  &&
                            <FormControl className={classes.selectBox} error={ this.state.isRepoTypeDropDownFeildError } variant="outlined">
                                <InputLabel id="label-repositorytype">Repository type</InputLabel>
                                <Select
                                error={this.state.isRepoTypeDropDownFeildError}
                                // helperText={this.state.repoTypeDropDownErrorMessage}
                                className={ classes.selectRegion }
                                color='primary'
                                labelId="label-repositorytype"
                                    //id="simple-select"
                                value={this.state.selectedRepositoryType}
                                name='repositoryType'
                                label={t('App.RegionSelection.repositoryTypeLabel')}
                                onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                                    this.handleRepoTypeDropDownChange(e);
                                  }}
                                MenuProps={{
                                        disableScrollLock: true,
                                        getContentAnchorEl: null,
                                        anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                        }
                                    }}
                                data-test="repo-type"
                                >
                                    <MenuItem value={-1} >Select Repository Type</MenuItem>
                                    <MenuItem value={1} >Organization Repository</MenuItem>
                                    <MenuItem value={2} >Personal Repository</MenuItem>
                                </Select>
                                <FormHelperText error={this.state.isRepoTypeDropDownFeildError}>{ this.state.repoTypeDropDownErrorMessage }</FormHelperText>
                            </FormControl>
                        }
                        {
                            this.state.selectedRepositoryType === 1 &&
                            <>
                                {
                                    !this.props.gitOrganizations &&
                                    <div className={classes.loader}>
                                        <Loader />
                                    </div>
                                }
                                {
                                    this.props.gitOrganizations && this.props.gitOrganizations.length === 0 && 
                                    <div data-test="no-org"><Typography variant="body1" className={classes.typoPadding}>{t('App.RegionSelection.noOrganization')}</Typography></div>
                                }
                                {
                                    this.props.gitOrganizations && this.props.gitOrganizations.length > 0 &&
                                    <FormControl className={classes.selectBox} error={this.state.isOrgFeildError} variant="outlined">
                                        {/* <InputLabel id="label-org">Organization</InputLabel>
                                        <Select
                                        error={this.state.isOrgFeildError}
                                        helperText={this.state.orgErrorMessage}
                                        className={ classes.selectRegion }
                                        color='primary'
                                        labelId="label-org"
                                        value={this.state.selectedOrg}
                                        name='organization'
                                        label='Organization'
                                        onChange={this.handleOrgChange}
                                        MenuProps={{
                                                getContentAnchorEl: null,
                                                anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                                }
                                            }}
                                        data-test="org-list"
                                        >
                                            <MenuItem value='Select Organization' >Select Organization</MenuItem>
                                            {
                                                this.props.gitOrganizations && this.props.gitOrganizations.length > 0 && this.props.gitOrganizations.map((org, ind) => <MenuItem value={org.login} key={ind}>{org.login}</MenuItem>)
                                            }
                                        </Select> */}
                                        
                                        <Autocomplete
                                            data-test="org-list"
                                            options={this.props.gitOrganizations.map((org: any) => org.name)}
                                            getOptionLabel={(option) => option}
                                            // classes={{
                                            //     option: classes.option,
                                            // }}
                                            // onChange={(e) => this.handleRepoSelect(e)}
                                            // onChange={(e, newValue) => this.handleOrgSelect(e, newValue)}
                                            onChange={(e: React.ChangeEvent<{}>, newValue: any) => {
                                                this.handleOrgSelect(e, newValue);
                                              }}
                                            renderInput={(params) => <TextField label="Select Organization" {...params} variant="outlined" color="primary" />}
                                        />
                                        <FormHelperText error={this.state.isOrgFeildError}>{ this.state.orgErrorMessage }</FormHelperText>
                                    </FormControl>
                                }
                            </>
                        }
                        {
                            (this.state.selectedRepositoryType === 2 || (this.state.selectedRepositoryType === 1 && this.state.selectedOrg !== "Select Organization" && this.state.selectedOrg !== "")) && 
                            <>
                                {
                                    !gitRepos &&
                                    <div className={classes.loader}>
                                        <Loader />
                                    </div>
                                }
                                {
                                    gitRepos && gitRepos.length === 0 && 
                                    <div data-test="no-repo">
                                        <Typography className={classes.typoPadding} variant="body1">{t('App.RegionSelection.noRepos')}</Typography>
                                    </div>
                                }
                                {
                                    gitRepos && gitRepos.length > 0 && 
                                    <FormControl className={classes.selectBox} error={this.state.isRepoDropDownFeildError} variant="outlined">
                                        {/* <InputLabel id="label-repository">Repository</InputLabel> */}
                                        {/* <Select
                                        error={this.state.isRepoDropDownFeildError}
                                        helperText={this.state.repoDropDownErrorMessage}
                                        className={ classes.selectRegion }
                                            //variant='outlined'
                                        color='primary'
                                        labelId="label-repository"
                                            //id="simple-select"
                                        value={this.state.selectedRepository}
                                        name='repository'
                                        label='Repository'
                                        onChange={this.handleRepoDropDownChange}
                                        MenuProps={{
                                                getContentAnchorEl: null,
                                                anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                                }
                                            }}
                                        data-test="repo-list"
                                        >
                                            <MenuItem value='Select Repository' >Select Repository</MenuItem>
                                            {
                                                gitRepos.map(repo => <MenuItem key={repo.id} value={repo.id}>{repo.name}</MenuItem>)
                                            }
                                        </Select> */}
                                        <Autocomplete
                                            data-test="repo-list"
                                            options={gitRepos.map((repo: any) => repo.name)}
                                            getOptionLabel={(option) => option}
                                            // classes={{
                                            //     option: classes.option,
                                            // }}
                                            onChange={(e: React.ChangeEvent<{}>, newValue: any) => {
                                                this.handleRepoSelect(e, newValue);
                                              }}
                                            renderInput={(params) => <TextField label="Select Repository" {...params} variant="outlined" color="primary" />}
                                        />
                                        <FormHelperText error={this.state.isRepoDropDownFeildError}>{ this.state.repoDropDownErrorMessage }</FormHelperText>
                                    </FormControl>
                                }
                            </>
                        }

                        {this.state.selectedTemplateType !== CreateApp_TemplateTypes.op &&
                        ( <FormControl className={classes.selectBox} 
                            error={this.state.isDropDownFeildError} variant="outlined"
                          >
                              <InputLabel id="label-repository">{t('App.RegionSelection.regionLabel')}</InputLabel>
                              <Select
                                error={this.state.isDropDownFeildError}
                                // helperText={this.state.dropDownErrorMessage}
                                className={ classes.selectRegion }
                                          //variant='outlined'
                                color='primary'
                                labelId="demo-simple-select-label"
                                          //id="demo-simple-select"
                                value={this.state.region}
                                name='region'
                                label={t('App.RegionSelection.regionLabel')}
                                onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                                    this.habdleDropDownOnChange(e);
                                  }}
                                MenuProps={{
                                              disableScrollLock: true,
                                              getContentAnchorEl: null,
                                              anchorOrigin: {
                                              vertical: "bottom",
                                              horizontal: "left"
                                              }
                                          }}
                                data-test="region-list"
                              >
                                  <MenuItem value='Select Region' >Select Region</MenuItem>
                                  {
                                        regions.map((region: string) => <MenuItem key={region} value={region} data-test="region-item">{region}</MenuItem>)
                                      }
                              </Select>
                              <FormHelperText error={this.state.isDropDownFeildError}>{ this.state.dropDownErrorMessage }</FormHelperText>
                          </FormControl>
                          )
                        }
                       
                            {this.props.currentOrganization && this.props.currentOrganization.id > 0
                                && this.state.selectedTemplateType !== CreateApp_TemplateTypes.op
                                && this.state.region && this.state.region !== "Select Region" &&
                                <>
                                {
                                    (!this.props.clusterList || this.props.clusterList.length === 0) &&
                                    <div data-test="no-cluster">
                                        <Typography className={classes.typoPadding} variant="body1">
                                            {t('App.RegionSelection.noClusters')}
                                        </Typography>
                                    </div>
                                }
                                {
                                    this.props.clusterList && this.props.clusterList.length > 0 &&
                                    <FormControl className={classes.selectBox}
                                        error={this.state.isClusterDropDownError} variant="outlined"
                                        data-test="form"
                                    >
                                        <InputLabel id="label-repository">{t('App.RegionSelection.clusterLabel')}</InputLabel>
                                        <Select
                                            error={this.state.isClusterDropDownError}
                                            // helperText={this.state.clusterDropDownErrorMessage}
                                            className={classes.selectRegion}
                                            //variant='outlined'
                                            color='primary'
                                            //labelId="demo-simple-select-label"
                                            //id="demo-simple-select"
                                            value={this.state.selectedCluster}
                                            name='cluster'
                                            label={t('App.RegionSelection.clusterLabel')}
                                            onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                                                this.handleClusterOnChange(e);
                                              }}
                                            MenuProps={{
                                                disableScrollLock: true,
                                                getContentAnchorEl: null,
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left"
                                                }
                                            }}
                                            data-test="cluster-list"
                                        >
                                            <MenuItem value='Select Cluster' >Select Cluster</MenuItem>
                                            {
                                                this.props.clusterList.map((clust: ClusterModel, ind: number) => <MenuItem key={ind} value={clust.id} data-test="cluster-item">{clust.name}</MenuItem>)
                                            }
                                        </Select>
                                        <FormHelperText error={this.state.isClusterDropDownError}>{this.state.clusterDropDownErrorMessage}</FormHelperText>
                                    </FormControl>
                                }
                                </> 
                            }                       
                        <div className='alignCenter'>
                            <Button 
                              variant="contained" 
                              disabled={ this.checkCanSubmit() } 
                              color='primary' 
                              onClick={ () => this.handleCreateApp() }
                              data-test="create-btn"
                            >
                               {t('App.RegionSelection.createApp')}
                            </Button>
                            {this.props.creatingApp && <BackdropLoader message={t('App.RegionSelection.creatingAppMessage')} /> }
                        </div>
                    </CardContent>
                </Card>
            </div>
            </>
        )
    }
}

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
    return {
        fetchPluginDetailsById : (id: number) => dispatch(fetchPluginDetailsById(id)),
        fetchRegionListAction : () => dispatch(fetchRegionList()),
        createApplication: (jsonBody: ExtendedPayload, history: History) => dispatch(createApplication(jsonBody, history)),
        fetchGITOrganizations: (jsonBody: Json, type: number) => dispatch(fetchGITOrganizations(jsonBody, type)),
        fetchGITRepos: (jsonBody: Json, type: number) => dispatch(fetchGITRepos(jsonBody, type)),
        fetchGITOrgRepos: (orgName: string, jsonBody: Json, type: number) => dispatch(fetchGITOrgRepos(orgName, jsonBody, type)),
        fetchProjectDetails: (payload: string) => dispatch(fetchProjectDetails(payload)),
        clearProjectDetails: () => dispatch(clearProjectDetails()),
        fetchChartDetailById: (id: string) => dispatch(fetchChartDetailById(id)),
        clearPluginDetails: () => dispatch(clearPluginDetails()),
        fetchOperatorDetails: (packageName: any) => dispatch(fetchOperatorDetails(packageName)),
        clearOperatorDetails: () => dispatch(clearOperatorDetails()),
        fetchClusterList: (region: string) => dispatch(fetchClusterList(region)),
    }
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => {
    return {
        projectDetails: state.ProjectReducer.projectDetails,
        //selectedPlugin: state.AppsReducer.selectedPlugin,
        pluginDetails: state.AppsReducer.pluginDetails,
        //regionsList: state.CreateAppReducer.regionsList,
        regions: state.CreateAppReducer.regions,
        gitOrganizations: state.AppsReducer.gitOrganizations,
        gitRepos: state.AppsReducer.gitRepos,
        creatingApp: state.AppsReducer.creatingApp,
        chartDetails: state.OrganizationReducer.chartDetails,
        operatorDetails: state.OperatorReducer.operatorDetails,
        clusterList: state.CreateAppReducer.cluster,
        currentOrganization: state.AuthReducer.currentOrganization,
        //selectedTemplate_Create: state.AppsReducer.selectedTemplate_Create
    }
}

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withStyles(useStyles)(withTranslation()(RegionSelection)));
