import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  FormControl,
  FormHelperText,
  TextField,
  Grid,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";
// import { transition } from "../../helpers/utils";
import { Transition } from "../../helpers/utils.ext";
import { CreateApp_TemplateTypes } from "../../constants/enums";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";

import { fetchGITBranches, clearBranches } from "../../pages/app/redux/actions";
import ConfirmActionPopup from "../confirmactionpopup/ConfirmActionPopup";
import { Branch } from "./../../models/Environment.model";

interface JSON {
  service_name: string;
  repo_id?: string;
  uid?: number;
}
interface Props extends PropsFromRedux {
  open: boolean;
  handleAgree: (branch: string) => void;
  handleDisagree: () => void;
}
export const CDChangePopup = ({
  open,
  handleAgree,
  handleDisagree,
  ...props
}: Props) => {
  const { service_type } = props.environmentDetails?.application;

  const [branch, setBranch] = useState(
    service_type === CreateApp_TemplateTypes.git
      ? props.environmentDetails.git_branch
      : props.environmentDetails.image_tag
  );

  const [branchErrorText, setBranchErrorText] = useState("");
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);

  const [t] = useTranslation();

  const handleBranchSelect = (e: React.ChangeEvent<{}>, newvalue: string) => {
    const validBranch = props.gitBranches?.find((br: Branch) => {
      return br.name === newvalue;
    });

    if (validBranch) {
      setBranch(validBranch.value);
      setBranchErrorText("");
    } else {
      setBranch("");
      setBranchErrorText(t("ResourcePaper.branchEmptyError"));
    }
  };

  const isValid = () => {
    if (!branch) {
      return false;
    }

    if (branchErrorText) {
      return false;
    }

    if (
      service_type === CreateApp_TemplateTypes.git &&
      branch === props.environmentDetails.git_branch
    ) {
      return false;
    }

    if (
      service_type === CreateApp_TemplateTypes.cr &&
      branch === props.environmentDetails.image_tag
    ) {
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    setIsConfirmPopupOpen(true);
  };

  const handleConfirmPopupAgree = () => {
    handleAgree(branch);
    setIsConfirmPopupOpen(false);
  };

  const handleConfirmPopupDisagree = () => {
    setIsConfirmPopupOpen(false);
  };

  useEffect(() => {
    if (props.environmentDetails.service_type === CreateApp_TemplateTypes.git) {
      const jsonBody = {
        service_name: props.environmentDetails.application.git_service,
        repo_id: props.environmentDetails.application.git_repository,
        uid: props.environmentDetails.application.owner_id,
      };
      props.fetchGITBranches(jsonBody, service_type);
    }

    if (props.environmentDetails.service_type === CreateApp_TemplateTypes.cr) {
      const jsonBody = {
        service_name: props.environmentDetails.application.image_service,
      };
      props.fetchGITBranches(
        jsonBody,
        service_type,
        props.environmentDetails.application.image_namespace,
        props.environmentDetails.application.image_repo
      );
    }

    return (): void => {
      props.clearBranches();
    };
  }, [props.environmentDetails]);


  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDisagree}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        onEscapeKeyDown={handleDisagree}
        data-test="main-container"
      >
        <DialogTitle>
          <Grid
            container
            alignItems="center"
            style={{ justifyContent: "space-between" }}
          >
            <Grid item>
              <Typography className="dialogtitle">
                <b data-test="dialog-title">
                  {props.environmentDetails.service_type ===
                  CreateApp_TemplateTypes.git
                    ? t("Environment.SettingsTab.changeBranch")
                    : t("Environment.SettingsTab.changeTag")}
                </b>
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="close"
                size="small"
                className="right"
                onClick={handleDisagree}
                data-test="close-popup"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ minWidth: 275 }} dividers>
          <Typography variant="subtitle1" data-test="popup-msg">
            {props.environmentDetails.service_type ===
            CreateApp_TemplateTypes.git
              ? t("Environment.OverviewTab.branch")
              : t("Environment.SettingsTab.tag")}
          </Typography>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              value={branch}
              options={props.gitBranches?.map((br: Branch) => br.name)}
              getOptionLabel={(option) => option}
              onChange={(event: React.ChangeEvent<{}>, newValue: string) =>
                handleBranchSelect(event, newValue)
              }
              data-test="branch-field"
              renderInput={(params) => (
                <TextField {...params} variant="outlined" color="primary" />
              )}
              style={{ width: "100%" }}
            />
            <FormHelperText error={!!branchErrorText}>
              {branchErrorText}
            </FormHelperText>
          </FormControl>

          <Typography variant="caption" data-test="popup-helper-msg">
            {t("Environment.SettingsTab.changeTagHelper")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            data-test="submit-btn"
            disabled={!isValid()}
          >
            {t("Billing.AddressForm.submitForm")}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmActionPopup
        open={isConfirmPopupOpen}
        handleAgree={handleConfirmPopupAgree}
        handleDisAgree={handleConfirmPopupDisagree}
        yesText={t("Projects.VariablesTab.yesText")}
        noText={t("Projects.VariablesTab.noText")}
        message={
          props.environmentDetails.service_type === CreateApp_TemplateTypes.git
            ? t("Environment.SettingsTab.changeBranchConfirm")
            : t("Environment.SettingsTab.changeTagConfirm")
        }
      />
    </>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  environmentDetails: state.EnvironmentReducer.environmentDetails,
  gitBranches: state.AppsReducer.gitBranches,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    fetchGITBranches: (
      jsonBody: JSON,
      type: string,
      orgName?: string,
      repoName?: string
    ) => dispatch(fetchGITBranches(jsonBody, type, orgName, repoName)),
    clearBranches: () => dispatch(clearBranches()),
  };
};

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CDChangePopup);
