import React from "react";
//import Switch from "react-switch";
import Switch from '@material-ui/core/Switch';
//import { IBasicFormParam } from "shared/types";

// export interface IStringParamProps {
//   id: string;
//   label: string;
//   param: IBasicFormParam;
//   handleBasicFormParamChange: (
//     p: IBasicFormParam,
//   ) => (e: React.FormEvent<HTMLInputElement>) => void;
// }

export function BooleanParam({ id, param, label, handleBasicFormParamChange }) {
  // handleChange transform the event received by the Switch component to a checkbox event
  //console.alert("value : ", param.value)
  const handleChange = (event) => {
    //debugger;
    // const event = {
    //   currentTarget: { value: String(e.target.checked), type: "checkbox", checked: e.target.checked },
    // };
    handleBasicFormParamChange(param)(event);
  };

  return (
    <label htmlFor={id} data-test="label-container">
      <div>
        <Switch
          // height={20}
          // width={40}
          id={id}
          onChange={handleChange}
          checked={!!param.value}
          color="primary"
          //className="react-switch"
          //onColor="#5aa220"
          //checkedIcon={false}
          //uncheckedIcon={false}
        />
        <span className="deployment-form-label">{label}</span>
      </div>
      {param.description && <span data-test="description-container" className="description">{param.description}</span>}
    </label>
  );
}

export default BooleanParam;
