import { Card, CardContent, Collapse, Divider, Grid, IconButton } from '@material-ui/core';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DeploymentStatusIndicator } from "..//statusindicator/statusindicator";
import { DateHandler } from '../dateHandler/DateHandler';
import { LabelHandler } from '../labelHandler/LabelHandler';
import { CdHistoryModel } from '../../models/Environment.model';

type CdCardProps={
    deployment: CdHistoryModel
}

const CDCard = (props: CdCardProps) => {
    const {deployment} = props;
    const [expanded, setExpanded] = React.useState(false);
    const [t] = useTranslation();

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card className="m-b-20" data-test="cardContainer">
            <CardContent>
                <Grid container alignItems="center" spacing={1}>
                    <Grid item md={1} >
                        <DeploymentStatusIndicator status={deployment.status}/>
                    </Grid>
                    <Grid item md={10}>
                        <Grid container>
                            <Grid item md={4} xs={6}>
                                <p className="commitmsg oneLine" title={"Tag Name"} data-test="tag-name">{deployment.image_tag ? deployment.image_tag : "latest"}</p>
                            </Grid>
                            <Grid item md={2} xs={6} className="header-details-grid">
                                <LabelHandler
                                    icon={<SettingsOutlinedIcon />}
                                    iconTooltip={t('Environment.EnvironmentInfo.version')}
                                    label={deployment.version}
                                    labelTooltip=""
                                />
                            </Grid>
                            <Grid item md={3} xs={6} className="header-details-grid">
                                <LabelHandler
                                    icon={<PersonOutlineOutlinedIcon />}
                                    iconTooltip={t('Projects.ProjectInfo.createdBy')}
                                    label={deployment.username}
                                    labelTooltip={deployment.username}
                                />
                            </Grid>
                            <Grid item md={3} xs={6} className="header-details-grid">
                                <DateHandler date={deployment.last_deployed} icon={<DateRangeOutlinedIcon className="svgicon" />} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={1} className="center stick-right">
                        <IconButton
                            onClick={() => handleExpandClick()}
                            aria-expanded={expanded}
                            aria-label="show details"
                            data-test="expand-icon"
                        >
                            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                    </Grid>
                </Grid>
            </CardContent>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Divider />
                <CardContent>
                    <div className="step">
                        <div className="title">{deployment.description}</div>
                    </div>
                </CardContent>
            </Collapse>
        </Card>
    )
}

export default CDCard