import React, { useState, useEffect } from "react";
import {
	Dialog,
	DialogContent,
	Typography,
	Button,
	TextField,
	Grid,
	DialogTitle,
	DialogActions,
	FormControl,
	Select,
	MenuItem,
	FormHelperText,
	InputAdornment,
	List,
	ListItem
} from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import { ClusterProviders } from "../../constants/clusterconstants";
import { AppConstants } from "../../constants/appconstants";
import ConfirmDeletePopup from "../confirmdeletepopup/ConfirmDeletePopup"
import {
	getProviderConfig,
	validateDnsPermission,
	clearDnsValidation
} from "../../pages/cluster/redux/actions";
import { createDns, updateDns, deleteDns } from "../../pages/organization/redux/actions"
import MuiTextField from "../../components/textfield/MuiTextField";
import { validateRegex } from "../../helpers/utils";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { makeStyles } from "@material-ui/styles";
import BackdropLoader from "../loader/BackdropLoader";
import InfoPopupContainer from "../infopopup/InfoPopupContainer";
import { useTranslation } from 'react-i18next';
import Alert from '@material-ui/lab/Alert';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { DNSModel, Regions } from '../../models/Organization.model'
import { ClusterDetailsModel } from "../../models/Cluster.model";
import { Transition } from "../../helpers/utils.ext";
import { Dispatch } from "redux";

type ProviderConfigModel = {
	properties: DNSProperties,
	type: string
}

type PropertyClusterName = {
	validation: string,
	description: string
}

type DNSProperties = {
	cluster_name: PropertyClusterName,
	region: Regions,
	zone: Regions
}

type ValidationDNSModel = {
	has_permission: Array<string>,
	no_permission: Array<string>
}

interface Props extends PropsFromRedux {
	openPopup: boolean,
	handleClosePopup: () => void,
	editMode?: boolean,
	editData?: DNSModel|null,
	validationDnsData: ValidationDNSModel,
	providerConfig: ProviderConfigModel
	// isValidatingDnsPermission: boolean,
	// currentOrganization: OrganizationModel,
	// validateDnsPermission: (jsonBody: DNSModel, uploadBody: FormData) => void,
	// getProviderConfig: (provider: string) => void,
	// createDns: (jsonBody: DNSModel, uploadBody: FormData, callback: () => void) => void,
	// updateDns: (dnsId: number, jsonBody: DNSModel, uploadBody: FormData, callback: () => void) => void,
	// deleteDns: (dnsId: number, callback: (dnsDeleted: boolean) => void) => void,
	// clearDnsValidation: () => void,
	edit?: boolean
	cluster?: ClusterDetailsModel
}

/* istanbul ignore next */
const useStyles = makeStyles(() => ({
	deleteButton: {
		color: "rgba(255, 0, 0, 0.75)",
		border: "1px solid",
	},
}));

const generalRegex = "^[a-z0-9-]*$";
const generalHelperText =
	"Allowed only lowercase characters, numbers and hyphen";

export const AddDNSPopup = (props: Props) => {
	const [provider, setProvider] = useState("Select");
	const [name, setName] = useState<string>("");
	const [project_id, setProject_id] = useState<string | undefined>("");
	const [access_key, setAccess_key] = useState<string | undefined>("");
	const [secret_key, setSecret_key] = useState<string | undefined>("");
	const [base_domain, setBase_domain] = useState<string | undefined>("");
	const [properties, setProperties] = useState<DNSProperties | null>(null);
	const [region, setRegion] = useState<string>("Select");
	const [regionList, setRegionList] = useState<Regions | null>(null);
	const [zone, setZone] = useState<string | undefined>("Select");
	const [zoneList, setZoneList] = useState<string[] | null>(null);
	const [errors, setErrors] = useState<any>({});
	const [helperTexts, setHelperTexts] = useState<any>({});
	const [selectedFile, setSelectedFile] = useState<any>(null);
	const [credentialValidated, setCredentialValidated] = React.useState(false);
	const [isShowAccessKey, setIsShowAccessKey] = useState(false);
	const [isShowSecretKey, setIsShowSecretKey] = useState(false);
	const [deleteObject, setDeleteObject] = useState<DNSModel | null>(null);
	const [apiKey, setApiKey] = useState<string>("");
	const [projectId, setProjectId] = useState<string>("");
	const [t] = useTranslation()

	/* istanbul ignore next */
	const handleClosePopup = () => {
		props.handleClosePopup();
	};

	/* istanbul ignore next */
	const handleSuccessDNSUpdate = () => {
		handleClosePopup();
	};

	useEffect(() => {
		let _data = props.editData;
		if(_data) {
			setProvider(_data.provider);
			setName(_data.name ? _data.name.toString() : "");
			setAccess_key(_data.access_key);
			setSecret_key(_data.secret_key);
			setBase_domain(_data.base_domain);
			setRegion(_data.region);
			setZone(_data.zone_id);
			setApiKey(_data.credentials ? _data.credentials.toString() : "");
			setProjectId(_data.project_id ? _data.project_id.toString() : "");
		}
	}, [props?.editData])

	useEffect(() => {
		if (props.validationDnsData) {
			const permissionCount = {
				hasPermission: props.validationDnsData.has_permission 
					? props.validationDnsData.has_permission.length
					: 0,
				noPermission: props.validationDnsData.no_permission
					? props.validationDnsData.no_permission.length
					: 0,
			};
			//setPermissionCount(permissionCount)
			setCredentialValidated(permissionCount.noPermission === 0);
		}
	}, [props.validationDnsData]);

	//handle validate for validation of GCP and EKS Credentials
	const handleValidate = () => {
		let _provider = provider;
		let uploadBody = null;
		let jsonBody: DNSModel = {
			id: 0,
			provider: _provider,
			region: region,
		};
		//{provider(gcp, eks), region,  access_key for aws, secret_key, for aws,  credential (file path - use /upload api for geting path for gcp}
		if (_provider === AppConstants.ClusterProvider.EKS) {
			jsonBody["access_key"] = access_key;
			jsonBody["secret_key"] = secret_key;
		} else if (_provider === AppConstants.ClusterProvider.GCP) {
			if (selectedFile) {
				uploadBody = new FormData();
				uploadBody.append("file_name", selectedFile.name);
				uploadBody.append("file_type", "json");
				uploadBody.append("file", selectedFile);
			}
		}else if(_provider === AppConstants.ClusterProvider.CLOUDFLARE){
			jsonBody["credentials"] = apiKey;
			jsonBody["project_id"] = projectId;
		}
		props.validateDnsPermission(jsonBody, uploadBody);
	};

	const handleSubmit = () => {
		let _provider = provider;
		let uploadBody = null;
		let jsonBody: DNSModel = {
			id: 0,
			provider: _provider,
			name: name,
			region: "",
			base_domain: base_domain,
			zone_id: zone,
			tls: "zerone-tls-cert",
			organization_id: props?.currentOrganization.id ?? 0,
			active: props?.editData?.active ?? true
		};
		if (_provider === AppConstants.ClusterProvider.EKS) {
			jsonBody["region"] = region;
			jsonBody["access_key"] = access_key;
			jsonBody["secret_key"] = secret_key;
		} else if (_provider === AppConstants.ClusterProvider.GCP) {
			jsonBody["project_id"] = project_id;

			uploadBody = new FormData();
			uploadBody.append("file_name", selectedFile.name);
			uploadBody.append("file_type", "json");
			uploadBody.append("file", selectedFile);
		} else if(_provider === AppConstants.ClusterProvider.CLOUDFLARE){
			jsonBody = {
				id: 0,
				provider: _provider,
				name: name,
				project_id: projectId,
				base_domain: base_domain,
				organization_id: props?.currentOrganization.id ?? 0,
				active: props?.editData?.active ?? true,
				credentials: apiKey,
				zone_id: "NA",
				tls: "zerone-tls-cert",
				region: "",
			};
		}
		if(props.editMode && props.editData) {
			props.updateDns(props?.editData?.id, jsonBody, uploadBody, handleSuccessDNSUpdate);
		} else {
			props.createDns(jsonBody, uploadBody, handleSuccessDNSUpdate);
		}
	};

	const handleProviderChange = (e: React.ChangeEvent<{ value: unknown }>) => {
		setProvider(e.target.value as string);
		setRegion("Select");
		if (e.target.value === AppConstants.ClusterProvider.EKS || e.target.value === AppConstants.ClusterProvider.GCP)
			setZone("");
	};

	const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setName(e.target.value);
		let regx;
		if (properties?.cluster_name?.validation) {
			regx = properties?.cluster_name?.validation;
		} else {
			regx = generalRegex;
		}
		setErrors({
			...errors,
			[e.target.name]: validateRegex(e.target.value, regx) ? false : true,
		});
		setHelperTexts({
			...helperTexts,
			[e.target.name]: !validateRegex(e.target.value, regx)
				? properties?.cluster_name?.description
					? properties?.cluster_name?.description
					: generalHelperText
				: "",
		});
		// setRegion("Select")
		// setZone("Select")
	};

	const onFileChange = (e: any) => {
		if (e.target.files && e.target.files.length > 0) {
			const _file = e.target.files[0];
			if (_file) {
				//&& _file.size <= 102400
				setSelectedFile(_file);
			}
			var reader = new FileReader();
			//const $this = this;
			reader.onload = function (event) {
				try {
					if (event?.target?.result) {
						const _jsonFile = JSON.parse(event.target.result as any)
						if (_jsonFile) {
							setProject_id(_jsonFile.project_id)
						}
					}
				}
				catch (err) {
					console.log(err);
				}
			};
			reader.readAsText(_file);
		}
	};

	useEffect(() => {
		if (props.providerConfig) {
			const _properties =
				props.providerConfig && props.providerConfig.properties;
			setProperties(_properties);
			setRegionList(_properties.region);
		}
	}, [props.providerConfig]);

	useEffect(() => {
		if (
			provider !== "Select" &&
			provider !== AppConstants.ClusterProvider.Other &&
			provider !== AppConstants.ClusterProvider.CLOUDFLARE
		) {
			props.getProviderConfig(provider);
			setCredentialValidated(false)
		}
		if(provider === AppConstants.ClusterProvider.CLOUDFLARE){
			// setCredentialValidated(true)
			setRegion("cloudflare")
		}
		if(provider === AppConstants.ClusterProvider.GCP){
			setRegion("gcp");
		}
		if(provider === "Select") {
			setCredentialValidated(false);
		}
	}, [provider]);

	useEffect(() => {
		let regx;
		if (properties?.region?.validation) {
			regx = properties?.region?.validation;
		} else {
			regx = generalRegex;
		}
		setErrors({
			...errors,
			region: validateRegex(region, regx) ? false : true,
		});
		setHelperTexts({
			...helperTexts,
			region: !validateRegex(region, regx)
				? properties?.region?.description
					? properties?.region?.description
					: generalHelperText
				: "",
		});
		if (region === "Select") {
			setZoneList(null);
		} else {
			if (properties && properties.zone) {
				setZoneList(
					properties.zone.items &&
						properties.zone.items.filter((zn) =>
							zn.startsWith(region)
						)
				);
			}
		}
		// if(props.edit){
		//     const z = zoneList?.find(_z => {return props.cluster?.cluster?.zone === _z})
		//     if(z){
		//         setZone(props.cluster?.cluster?.zone)
		//     }else if(zoneList && zoneList[0]){
		//         setZone(zoneList[0])
		//     }
		// }else{
		//     setZone("Select")
		// }
	}, [region]);

	useEffect(() => {

	}, [projectId])

	useEffect(() => {
		if (props.edit) {
			const z = zoneList?.find((_z) => {
				return props.cluster?.cluster?.zone === _z;
			});
			if (z) {
				setZone(props.cluster?.cluster?.zone);
			} else if (zoneList && zoneList[0]) {
				setZone(zoneList[0]);
			}
		} else if (provider !== AppConstants.ClusterProvider.Other) {
			if (provider === AppConstants.ClusterProvider.GCP)
				setZone("Select");
			else if (provider === AppConstants.ClusterProvider.EKS) setZone("");
		}
	}, [zoneList]);

	const isFormValid = () => {
		let valid = false;
		let e = false;
		Object.keys(errors).forEach((k) => {
			if (errors[k]) {
				e = true;
				return;
			}
		});
		if (
			name.trim() !== "" && //&& !isClusterNameError
			provider !== "Select" &&
			region !== "Select" &&
			base_domain !== "" &&
			!e
		) {
			//&& (selectedFile || props.edit))
			if (provider === AppConstants.ClusterProvider.GCP) {
				if (zone !== "" && project_id !== "" && selectedFile) {
					valid = true;
				}
			} else if (provider === AppConstants.ClusterProvider.EKS) {
				if (zone !== "" && access_key !== "" && secret_key !== "") {
					valid = true;
				}
			} else if(provider === AppConstants.ClusterProvider?.CLOUDFLARE){
				if(projectId?.trim()?.length > 0 && apiKey?.trim()?.length > 0  ){
					valid = true
				}
			} else {
				valid = true;
			}
		}
		if (
			props.editMode && props.editData && 
			props.editData.name === name &&
			(props.editData?.provider !== AppConstants.ClusterProvider.CLOUDFLARE && props.editData.zone_id === zone) &&
			props.editData.base_domain === base_domain
		) {
			valid = false;
		}
		return !valid;
	};

	useEffect(() => {
		return () => {
			props.clearDnsValidation();
		}
	}, [])

	const isValidateFormValid = () => {
		let valid = false;
		let e = false;
		Object.keys(errors).forEach((k) => {
			if (errors[k]) {
				e = true;
				return;
			}
		});
		if (provider !== "Select" && region !== "Select" && !e) {
			if (provider === AppConstants.ClusterProvider.GCP) {
				if (selectedFile) {
					valid = true;
				}
			} else if (provider === AppConstants.ClusterProvider.EKS) {
				if (access_key?.trim() !== "" && secret_key?.trim() !== "") {
					valid = true;
				}
			}else if (provider === AppConstants.ClusterProvider.CLOUDFLARE) {
				if (apiKey?.trim() !== "" && projectId?.trim() !== "") {
					valid = true;
				}
			} else {
				valid = true;
			}
		}
		return !valid;
	};

	const handleDelete = (dns: DNSModel) => {
		setDeleteObject(dns);
	}

	const handleDeleteDnsDisagree = (dnsDeleted?: boolean) => {
		setDeleteObject(null);
		if(dnsDeleted) props.handleClosePopup();
	};
	
	const handleDeleteDnsAgree = () => {
		if (deleteObject)
			props.deleteDns(deleteObject.id, handleDeleteDnsDisagree);
	};

	const classes = useStyles();

	const getInfoMessage = () => {
		let message: string | JSX.Element = ""
		let scope = ""
		switch(provider){
			case AppConstants.ClusterProvider.EKS:
				message = (<Typography variant="h5"> Required Permission: route53:* and route53domains:* </Typography> )
				scope = "component"
				break
			case AppConstants.ClusterProvider.GCP:
				message = (<Typography variant="h5"> Required Permission: roles/dns.admin </Typography> )
				scope = "component"
				break
			case "cloudflare":
				message = "Requires Global API key. "
				break
			default:
				message = ""
				
		}
		return {message, scope}
	}

	const getInfoUrl = () => {
		let url = ""
		switch(provider){
			case "cloudflare":
				url = "https://dash.cloudflare.com/profile/api-tokens"
				break
			default:
				url = ""
		}
		return url
	}

	return (
		<>
			<Dialog
				disableEscapeKeyDown = {true}
				open={props.openPopup}
				TransitionComponent={Transition}
				onClose={(_, reason) => {
				if (reason !== 'backdropClick') {
					props.handleClosePopup()
				}}
				}
				keepMounted
				data-test="main-container"
			>
				<DialogTitle>
					<Typography className="dialogtitle">
						{props.editMode ? t('AddDNSPopup.update') : t('AddDNSPopup.add')}
					</Typography>

					<IconButton
						aria-label="close"
						size="small"
						className="right"
						onClick={props.handleClosePopup}
						data-test="close-icon"
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<DialogContent dividers>
					<Grid>
						<Grid container spacing={2}>
							<Grid
								item
								xs={12}
								md={provider !== "Select" ? 6 : 12}
							>
								<Typography variant="h5">{t('Cluster.ClusterStorage.provider')}</Typography>
								<FormControl
									className="w-100"
									variant="outlined"
									margin="normal"
								>
									<Select
										disabled={
											(credentialValidated ||
											props.editMode) && (provider !== AppConstants?.ClusterProvider?.CLOUDFLARE)
										}
										name="provider"
										value={provider}
										color="primary"
										onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
											handleProviderChange(e)
										}
										MenuProps={{
											getContentAnchorEl: null,
											anchorOrigin: {
												vertical: "bottom",
												horizontal: "left",
											},
										}}
										data-test="provider-select"
									>
										<MenuItem value="Select">
										{t('Cluster.ClusterStorage.selectProvider')}
										</MenuItem>
										{ClusterProviders &&
											ClusterProviders.length > 0 &&
											ClusterProviders.map(
												(item, ind) => (
													<MenuItem
														value={item.provider}
														key={ind}
													>
														{item.name}
													</MenuItem>
												)
											)}
											<MenuItem value={AppConstants.ClusterProvider.CLOUDFLARE}>
												{t('AddDNSPopup.cloudFlare')}
											</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							{provider !== "Select" && (
								<>
									{credentialValidated && (
										<Grid item xs={12} md={6}>
											<Typography variant="h5">
											{t('Projects.LoadbalancerInfo.nameLabel')}
											</Typography>
											<MuiTextField
												name="name"
												value={name}
												onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
													handleNameChange(e)
												}
												type="text"
												margin="normal"
												inputProps={{
													regex:
														properties &&
														properties.cluster_name &&
														properties.cluster_name
															.validation,
												}}
												error={errors.name}
												helperText={helperTexts.name}
												placeholder={t('Projects.LoadbalancerInfo.nameLabel')}
												data-test="dns-name"
											/>
										</Grid>
									)}
									{ provider !== AppConstants?.ClusterProvider?.CLOUDFLARE && provider !== AppConstants?.ClusterProvider?.GCP &&
										<Grid item xs={12} md={6}>
											<Typography variant="h5">
											{t('Projects.LoadbalancerInfo.regionLabel')}
											</Typography>
											<FormControl
												className="w-100"
												variant="outlined"
												margin="normal"
											>
												<Select
													name="region"
													value={region}
													disabled={
														credentialValidated
													}
													color="primary"
													onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
														setRegion(
															e.target.value as string
														)
													}
													MenuProps={{
														getContentAnchorEl: null,
														anchorOrigin: {
															vertical: "bottom",
															horizontal: "left",
														},
													}}
													data-test="region-select"
												>
													<MenuItem value="Select">
													{t('Cluster.ClusterStorage.selectRegion')}
													</MenuItem>
													{regionList &&
														regionList.items &&
														regionList.items
															.length > 0 &&
														regionList.items.map(
															(reg, ind) => (
																<MenuItem
																	value={reg}
																	key={ind}
																>
																	{reg}
																</MenuItem>
															)
														)}
												</Select>
												<FormHelperText>
													{regionList &&
														regionList.description}
												</FormHelperText>
											</FormControl>
										</Grid>
									}
									{provider === AppConstants.ClusterProvider.CLOUDFLARE && (
										<Grid item md={6} xs={12}>
											{/* <Grid container spacing={1} className={"infoGrid"}>
												<Typography variant="h5">
													API Key {" "}
												</Typography>
												<InfoPopup message={getInfoMessage()} url={getInfoUrl()}/>
											</Grid> */}
											<InfoPopupContainer label={t('AddDNSPopup.apiKey')} message={getInfoMessage().message} url={getInfoUrl()} scope={getInfoMessage().scope} />

										<MuiTextField
											className="w-100"
											margin="normal"
											name="apiKey"
											value={apiKey}
											disabled={credentialValidated}
											//inputProps = {{
											//regex: properties && properties.cluster_name && properties.cluster_name.validation
											//    }}
											onChange={(e) =>
												setApiKey(e.target.value)
											}
											placeholder={t('AddDNSPopup.keyPlaceHolder')}
											error={errors.apiKey}
											helperText={
												helperTexts.apiKey
											}
											data-test="api-key"
										/>
										</Grid>
									)}
									{provider === AppConstants.ClusterProvider.CLOUDFLARE && (
										<Grid item xs={12} md={6}>
										<Typography variant="h5">
										{t('AddDNSPopup.cloudFlareEmail')}
										</Typography>
										<MuiTextField
											className="w-100"
											margin="normal"
											name="projectId"
											value={projectId}
											disabled={credentialValidated}
											//inputProps = {{regex: "!/([\w.-]+@([\w-]+)\.+\w{2,}$)/"}}
											onChange={(e) =>
												setProjectId(e.target.value)
											}
											placeholder={t('AddDNSPopup.cloudFlareEmail')}
											error={errors.projectId}
											helperText={
												helperTexts.projectId
											}
											data-test="cf-email"
										/>
										</Grid>
									)}
									{(provider ===
										AppConstants.ClusterProvider.EKS || provider === AppConstants?.ClusterProvider?.GCP) &&
										credentialValidated && (
											<Grid item xs={12} md={6}>
												<Typography variant="h5">
												{t('Cluster.ClusterDNS.zone')}
												</Typography>
												<MuiTextField
													className="w-100"
													margin="normal"
													name="zone"
													value={zone}
													//inputProps = {{
													//regex: properties && properties.cluster_name && properties.cluster_name.validation
													//    }}
													onChange={(e) =>
														setZone(e.target.value)
													}
													placeholder={ provider === AppConstants?.ClusterProvider?.EKS ? t('AddDNSPopup.awsZone') : t('AddDNSPopup.zone')}
													error={errors.zone}
													helperText={
														helperTexts.zone
													}
													data-test="dns-hosted-zone"
												/>
											</Grid>
										)}
									{provider ===
										AppConstants.ClusterProvider.GCP &&
										credentialValidated && (
											<Grid item xs={12} md={6}>
												<Typography variant="h5">
													{t('Cluster.ClusterStorage.projectId')}
												</Typography>
												<MuiTextField
													name="project_id"
													value={project_id}
													onChange={(e) =>
														setProject_id(
															e.target.value
														)
													}
													type="text"
													margin="normal"
													disabled={AppConstants.ClusterProvider.GCP && credentialValidated ? true : false}
													inputProps={
														{
															//regex: properties && properties.cluster_name && properties.cluster_name.validation
														}
													}
													error={errors.project_id}
													helperText={
														helperTexts.project_id
													}
													placeholder={t('Cluster.ClusterStorage.projectId')}
													data-test="project-id"
												/>
											</Grid>
										)}
									{provider ===
										AppConstants.ClusterProvider.EKS && (
										<>
											<Grid item xs={12} md={6}>
												<Typography variant="h5">
												{t('Cluster.ClusterStorage.accessKey')}
												</Typography>
												<TextField
													style={{
														width: "0px",
														height: "0px",
													}}
												/>
												{/* <OutlinedInput
													name="access_key"
													disabled={
														credentialValidated
													}
													value={access_key}
													onChange={(e) =>
														setAccess_key(
															e.target.value
														)
													}
													type={
														isShowAccessKey
															? "text"
															: "password"
													}
													variant="outlined"
													fullWidth
													margin="normal"
													error={errors.access_key}
													helperText={
														helperTexts.access_key
													}
													placeholder={t('Cluster.ClusterStorage.accessKey')}
													className="m-t-20"
													endAdornment={
														<>
															<InputAdornment position="end">
																<IconButton
																	aria-label="toggle visibility"
																	onClick={() =>
																		setIsShowAccessKey(
																			!isShowAccessKey
																		)
																	}
																	edge="end"
																>
																	{isShowAccessKey ? (
																		<Visibility />
																	) : (
																		<VisibilityOff />
																	)}
																</IconButton>
															</InputAdornment>
														</>
													}
													data-test="access-key"
												>
												</OutlinedInput> */}
												<MuiTextField
													name="access_key"
													disabled={
														credentialValidated
													}
													value={access_key}
													onChange={(e) =>
														setAccess_key(
															e.target.value
														)
													}
													type={
														isShowAccessKey
															? "text"
															: "password"
													}
													margin="normal"
													error={errors.access_key}
													helperText={
														helperTexts.access_key
													}
													placeholder={t('Cluster.ClusterStorage.accessKey')}
													InputProps = {{
														endAdornment:(
														<>
															<InputAdornment position="end">
																<IconButton
																	aria-label="toggle visibility"
																	onClick={() =>
																		setIsShowAccessKey(
																			!isShowAccessKey
																		)
																	}
																	edge="end"
																>
																	{isShowAccessKey ? (
																		<Visibility />
																	) : (
																		<VisibilityOff />
																	)}
																</IconButton>
															</InputAdornment>
														</>
														)
													}}
													data-test="access-key"
												/>
											</Grid>
											<Grid item md={6} xs={12}>
												{/* <Grid container className={"infoGrid"} style={{ margin: "-2px"}}>
													<Grid item>
														<Typography variant="h5">
															Secret Key {" "}
														</Typography>
													</Grid>
													<Grid item>
														<InfoPopup message={getInfoMessage()} url={getInfoUrl()}/>
													</Grid>
												</Grid> */}
												<InfoPopupContainer label={t('Cluster.ClusterStorage.secretKey')} message={getInfoMessage().message} url={getInfoUrl()} scope={getInfoMessage().scope} />
												{/* <OutlinedInput
													name="secret_key"
													disabled={
														credentialValidated
													}
													value={secret_key}
													onChange={(e) =>
														setSecret_key(
															e.target.value
														)
													}
													type={
														isShowSecretKey
															? "text"
															: "password"
													}
													variant="outlined"
													fullWidth
													margin="normal"
													inputProps={
														{
															//regex: properties && properties.cluster_name && properties.cluster_name.validation
														}
													}
													error={errors.secret_key}
													helperText={
														helperTexts.secret_key
													}
													placeholder={t('Cluster.ClusterStorage.secretKey')}
													className="m-t-20"
													endAdornment={
														<>
															<InputAdornment position="end">
																<IconButton
																	aria-label="toggle visibility"
																	onClick={() =>
																		setIsShowSecretKey(
																			!isShowSecretKey
																		)
																	}
																	edge="end"
																>
																	{isShowSecretKey ? (
																		<Visibility />
																	) : (
																		<VisibilityOff />
																	)}
																</IconButton>
															</InputAdornment>
														</>
													}
													data-test="secret-key"
												>
												</OutlinedInput> */}
												<MuiTextField
												name="secret_key"
												disabled={
													credentialValidated
												}
												value={secret_key}
												onChange={(e) =>
													setSecret_key(
														e.target.value
													)
												}
												type={
													isShowSecretKey
														? "text"
														: "password"
												}
												margin="normal"
												inputProps={
													{
														//regex: properties && properties.cluster_name && properties.cluster_name.validation
													}
												}
												error={errors.secret_key}
												helperText={
													helperTexts.secret_key
												}
												placeholder={t('Cluster.ClusterStorage.secretKey')}
												InputProps={{
													endAdornment:(
														<>
															<InputAdornment position="end">
																<IconButton
																	aria-label="toggle visibility"
																	onClick={() =>
																		setIsShowSecretKey(
																			!isShowSecretKey
																		)
																	}
																	edge="end"
																>
																	{isShowSecretKey ? (
																		<Visibility />
																	) : (
																		<VisibilityOff />
																	)}
																</IconButton>
															</InputAdornment>
														</>
													)
												}}
												data-test="secret-key"
												/>
											</Grid>
										</>
									)}
									{provider ===
										AppConstants.ClusterProvider.GCP && (
										<Grid item md={credentialValidated ? 12 : 6} xs={12}>
											<InfoPopupContainer label={t('Cluster.ClusterStorage.credentials')} message={getInfoMessage().message} scope={getInfoMessage().scope} url={getInfoUrl()} />

											<TextField
												disabled={credentialValidated}
												id=""
												type="file"
												title=""
												color="primary"
												name=""
												variant="outlined"
												className="w-100"
												margin="normal"
												onChange={(e) =>
													onFileChange(e)
												}
												data-test="cred-file"
											/>
										</Grid>
									)}
									{credentialValidated && (
										<Grid item xs={12} md={6}>
											<Typography variant="h5">
											{t('Cluster.ClusterDNS.baseDomain')}
											</Typography>
											<MuiTextField
												name="base_domain"
												value={base_domain}
												onChange={(e) =>
													setBase_domain(
														e.target.value
													)
												}
												type="text"
												margin="normal"
												inputProps={
													{
														//regex: properties && properties.cluster_name && properties.cluster_name.validation
													}
												}
												error={errors.base_domain}
												helperText={
													helperTexts.base_domain
												}
												placeholder={t('Cluster.ClusterDNS.baseDomain')}
												data-test="base-domain"
											/>
										</Grid>
									)}
								</>
							)}
							{
								props.validationDnsData &&
								props.validationDnsData.no_permission &&
								props.validationDnsData.no_permission.length > 0 &&
								<Grid item xs={12}>
									<Alert severity="error" className="m-b-20">
										<Typography variant="body1" >Below permissions required</Typography>
										<List dense={true}>
											{
												props.validationDnsData.no_permission.map((permission, ind) => (
													<ListItem key={ind}>
														<Typography variant="body1" ><FiberManualRecordIcon style={{ fontSize: 10, marginRight: "5px" }} />{permission}</Typography>
													</ListItem>
												))
											}
										</List>
									</Alert>
								</Grid>
							}
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Grid
						container
						justify={props.editMode ? "space-between" : "flex-end"}
					>
						{props.editMode  &&(
							<Grid item>
								<Button
									className={classes.deleteButton}
									variant="outlined"
									startIcon={<DeleteIcon />}
									onClick={() => {
										if(!!props.editData){
											handleDelete(props.editData)
										}
									}}
									data-test="delete-btn"
								>
									{t("Common.delete")}
								</Button>
							</Grid>
						)}
						<Grid item>
							{credentialValidated ? (
								<Button
									onClick={handleSubmit}
									color="primary"
									variant="contained"
									disabled={isFormValid()}
									data-test="add-btn"
								>
									{props.editMode ? t('Cluster.ClusterStorage.update') : t('Cluster.ClusterStorage.add')}
								</Button>
							) : (
								<Button
									onClick={handleValidate}
									color="primary"
									variant="contained"
									disabled={isValidateFormValid()}
									data-test="validate-btn"
								>
									{t('Cluster.ClusterDNS.validate')}
								</Button>
							)}
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
			{
				deleteObject && deleteObject.id > 0 ?(
					<ConfirmDeletePopup 
						open={deleteObject && deleteObject.id? true : false}
						handleAgree={handleDeleteDnsAgree}
						handleDisAgree={handleDeleteDnsDisagree}
						message={`This action will permanently delete DNS data. Please type "${ deleteObject.name }" to delete the DNS : ${ deleteObject.name }`}
						//yesText={t('Projects.VariablesTab.yesText')}
						//noText={t('Projects.VariablesTab.noText')}
						action="delete"
						toMatchName={ deleteObject.name ? deleteObject.name?.toString() : "" }
						toDeleteModule="dns"
						loading={props.deletingDns}
						data-test="delete-popup"
					/>
				) : <></>
			}
			{
				props.isValidatingDnsPermission && <BackdropLoader message={t('Cluster.ClusterDNS.validatingMessage')} data-test="loader"/>
			}
		</>
	);
};

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
	providerConfig: state.ClusterReducer.providerConfig,
	validationDnsData: state.ClusterReducer.validationDnsData,
	isValidatingDnsPermission: state.ClusterReducer.isValidatingDnsPermission,
	currentOrganization: state.AuthReducer.currentOrganization,
	deletingDns: state.OrganizationReducer.deletingDns
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
	return {
		validateDnsPermission: (jsonBody: DNSModel, uploadBody: FormData | null) =>
			dispatch(validateDnsPermission(jsonBody, uploadBody)),
		getProviderConfig: (provider: string) => dispatch(getProviderConfig(provider)),
		createDns: (jsonBody: DNSModel, uploadBody: FormData | null, callback: () => void) => dispatch(createDns(jsonBody, uploadBody, callback)),
		updateDns: (dnsId: number, jsonBody: DNSModel, uploadBody: FormData | null, callback: () => void) => dispatch(updateDns(dnsId, jsonBody, uploadBody, callback)),
		clearDnsValidation: () => dispatch(clearDnsValidation()),
		deleteDns: (dnsId: number, callback: () => void) => dispatch(deleteDns(dnsId, callback)),
	};
};

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AddDNSPopup);
