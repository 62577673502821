import { takeLatest, put, select, call } from "redux-saga/effects";
import axios from "axios";
import endpoints from "../../../constants/endpoints";
import { sessionTokenSelector } from "../../login/redux/selectors";
import {
	GET_NOTIFICATION_LIST,
	GET_NOTIFICATION_LIST_SUCCESS,
	GET_NOTIFICATION_LIST_FAILURE,
	GET_RECENT_NOTIFICATION_LIST,
	GET_RECENT_NOTIFICATION_LIST_SUCCESS,
	GET_RECENT_NOTIFICATION_LIST_FAILURE,
	GET_MORE_NOTIFICATION_LIST_SUCCESS,
	UPDATE_SEEN_UNSEEN,
	UPDATE_SEEN_UNSEEN_SUCCESS,
	UPDATE_SEEN_UNSEEN_FAILURE,
	GET_UNSEEN_COUNT,
	GET_UNSEEN_COUNT_SUCCESS,
	GET_UNSEEN_COUNT_FAILURE,
	NAVIGATION_CHECK,
	NAVIGATION_CHECK_SUCCESS,
	NAVIGATION_CHECK_FAILURE,
	MARK_ALL_AS_READ,
	MARK_ALL_AS_READ_SUCCESS,
	MARK_ALL_AS_READ_FAILURE
} from "./actions";
import Toast from "../../../components/toast/Toast";

function* markAllAsRead(){
	try {
		const sessionToken = yield select(sessionTokenSelector);
		const config = {
			headers: {
				Authorization: "basic " + sessionToken,
				'Content-Type': 'application/json',
			},
		};
		yield axios.post(
			endpoints.NOTIFICATIONS.MARK_ALL_AS_READ,{},
			config
		)

			yield put({ type: MARK_ALL_AS_READ_SUCCESS });
			yield call(getUnseenCount)
			Toast.success("Mark All as Read done");
			yield put({ type: GET_NOTIFICATION_LIST , data: {
				pageNo: 1,
				pageSize: 50,
				filter: "all",
			}});
			
			yield put({ type: GET_RECENT_NOTIFICATION_LIST , data: {
				pageNo: 1,
				pageSize: 50,
				filter: "all",
			}});
	} catch (error) {
		yield put({ type: MARK_ALL_AS_READ_FAILURE });
		if (
			error &&
			error.response &&
			error.response.data &&
			error.response.data.error
		) {
			Toast.error(error.response.data.error);
		}
	}
}

function* getNotifications(payload) {
	try {
		const sessionToken = yield select(sessionTokenSelector);
		const config = { headers: { Authorization: "basic " + sessionToken } };
		const response = yield axios.get(
			endpoints.NOTIFICATIONS.GET_NOTIFICATION_LIST.replace(
				":filter",
				payload.data.filter
			)
				.replace(":page", payload.data.pageNo)
				.replace(":limit", payload.data.pageSize),
			config
		);

		const data = response.data;
		
		if (data) {
			if (payload.data.pageNo === 1)
				yield put({ type: GET_NOTIFICATION_LIST_SUCCESS, data });
			else yield put({ type: GET_MORE_NOTIFICATION_LIST_SUCCESS, data });
		} else {
			yield put({ type: GET_NOTIFICATION_LIST_FAILURE });
		}
	} catch (error) {
		yield put({ type: GET_NOTIFICATION_LIST_FAILURE });
		if (
			error &&
			error.response &&
			error.response.data &&
			error.response.data.error
		) {
			Toast.error(error.response.data.error);
		}
	}
}

function* getRecentNotifications(payload) {
	try {
		const sessionToken = yield select(sessionTokenSelector);
		const config = { headers: { Authorization: "basic " + sessionToken } };
		const response = yield axios.get(
			endpoints.NOTIFICATIONS.GET_NOTIFICATION_LIST.replace(
				":filter",
				payload.data.filter
			)
				.replace(":page", payload.data.pageNo)
				.replace(":limit", payload.data.pageSize),
			config
		);

		const data = response.data;
		if (data) {
			yield put({ type: GET_RECENT_NOTIFICATION_LIST_SUCCESS, data });
		} else {
			yield put({ type: GET_RECENT_NOTIFICATION_LIST_FAILURE });
		}
	} catch (error) {
		yield put({ type: GET_RECENT_NOTIFICATION_LIST_FAILURE });
		if (
			error &&
			error.response &&
			error.response.data &&
			error.response.data.error
		) {
			Toast.error(error.response.data.error);
		}
	}
}
/*
function* markAsRead(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const config = {
      headers : {
        'Content-Type': 'application/json',
        'Authorization': 'basic ' + sessionToken
      }
    }
    const response = yield axios.put(endpoints.NOTIFICATIONS.MARK_AS_READ_NOTIFICATION.replace(':id' , payload.data.id), payload.data.jsonBody, config);
    const data = response.data;
    // if (data){   
    //   yield put({ type: UPDATE_CLUSTER_SUCCESS, data })
    // } else {
    //   yield put({ type: UPDATE_CLUSTER_FAILURE })
    //   toast.error('Some error while updating cluster. Please try again');
    // }
  }
  catch (error) {
    // yield put({ type: UPDATE_CLUSTER_FAILURE })
    // if(error && error.response && error.response.data && error.response.data.error)
    //   toast.error(error.response.data.error);
    // else
    //   toast.error('Some error while updating cluster. Please try again');
  }
}
*/
function* updateSeenUnseen(payload) {
	try {
		const sessionToken = yield select(sessionTokenSelector);
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: "basic " + sessionToken,
			},
		};
		yield axios.post(
			endpoints.NOTIFICATIONS.UPDATE_SEEN_UNSEEN,
			payload.data.jsonBody,
			config
		);
			yield call(getUnseenCount)
			yield put({
				type: UPDATE_SEEN_UNSEEN_SUCCESS,
				data: payload.data.jsonBody,
			});
	} catch (error) {
		yield put({ type: UPDATE_SEEN_UNSEEN_FAILURE });
		if (
			error &&
			error.response &&
			error.response.data &&
			error.response.data.error
		)
			Toast.error(error.response.data.error);
		else
			Toast.error("Some error while updating notification. Please try again");
	}
}

function* getUnseenCount() {
	try {
		const sessionToken = yield select(sessionTokenSelector);
		const config = { headers: { Authorization: "basic " + sessionToken } };
		const response = yield axios.get(
			endpoints.NOTIFICATIONS.GET_UNSEEN_COUNT,
			config
		);

		const data = response.data;
		if (data) {
			yield put({ type: GET_UNSEEN_COUNT_SUCCESS, data });
		} else {
			yield put({ type: GET_UNSEEN_COUNT_FAILURE });
		}
	} catch (error) {
		yield put({ type: GET_UNSEEN_COUNT_FAILURE });
		if (
			error &&
			error.response &&
			error.response.data &&
			error.response.data.error
		) {
			Toast.error(error.response.data.error);
		}
	}
}

function* navigationCheck(payload) {
	try {
		const sessionToken = yield select(sessionTokenSelector);
		const config = { headers: { Authorization: "basic " + sessionToken } };
		let response = null;
		switch (payload.data.module) {
			case "environment":
				response = yield axios.get(
					endpoints.ENV_DETAILS.replace(":eId", payload.data.id),
					config
				);
				break;
			case "project":
				response = yield axios.get(
					endpoints.PROJECT_DETAILS.replace(":pId", payload.data.id),
					config
				);
				break;
			case "application":
				response = yield axios.get(
					endpoints.GET_APP_DETAILS.replace(":aid", payload.data.id),
					config
				);
				break;
			case "support":
				response = yield axios.get(
					endpoints.SUPPORT.GET_TICKET_DETAIL.replace(":id", payload.data.id),
					config
				);
				break;
			default:
				response = {};
		}

		const data = response.data;
		yield put({ type: NAVIGATION_CHECK_SUCCESS });
		if (data && payload.data.callBack) {
			payload.data.callBack(true, payload.data.module, payload.data.id);
		}
	} catch (error) {
		yield put({ type: NAVIGATION_CHECK_FAILURE });
		if (payload.data.callBack) {
			payload.data.callBack(false, payload.data.module);
		}
	}
}

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
	yield takeLatest(GET_NOTIFICATION_LIST, getNotifications);
	yield takeLatest(GET_RECENT_NOTIFICATION_LIST, getRecentNotifications);
	//yield takeLatest(MARK_AS_READ_NOTIFICATION, markAsRead);
	yield takeLatest(UPDATE_SEEN_UNSEEN, updateSeenUnseen);
	yield takeLatest(GET_UNSEEN_COUNT, getUnseenCount);
	yield takeLatest(NAVIGATION_CHECK, navigationCheck);
	yield takeLatest(MARK_ALL_AS_READ, markAllAsRead);
}
