import React,{useEffect,useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Paper, Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { sessionTokenSelector } from '../../../../login/redux/selectors';
import { getAlertHistory } from '../../../../../services/AlertService';
import { connect, ConnectedProps } from "react-redux";
import HistoryRow from '../../../../../components/alertmanager/HistoryTable';
import InfiniteScroll from 'react-infinite-scroll-component';

interface Iprops extends PropsFromRedux {}

function History(props:Iprops) {
  const sessionToken = useSelector(sessionTokenSelector);
  const [historyDetails, setHistoryDetails] = useState<any>([]);
  const [pageNo, setPageNo] = useState(1);
  const pageSize = 20;

  useEffect(() => {
    const eId = props.environmentDetails?.id;
    getAlertHistory(sessionToken, eId, pageNo, pageSize)
      .then((response: any) => {
        if (response && response.data) {
          setHistoryDetails((prevHistory: any) => { 
            if(response.data.data && response.data.data.length > 0) {
              return pageNo > 1 ? [...prevHistory, ...response.data.data] : response.data.data
            }
            return prevHistory;
          });
        }
      })
      .catch((error: any) => {
        setHistoryDetails([]);
      });
  }, [pageNo]);

  const loadMore = () => {
    setPageNo((prevPageNo) => prevPageNo + 1);
  };

  return (
    <div>
    <InfiniteScroll
      dataLength={ historyDetails.length ?? 0 }
      next={loadMore}
      hasMore= { historyDetails.length === pageSize * pageNo }
      loader={<h4>Loading...</h4>}
    >
      <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell  align="left" component="th" scope="row">Alert History</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {historyDetails?.map((history:any) => (
            <HistoryRow 
              historyDetails={history}
            />
          ))}
          {historyDetails && historyDetails.length === 0 &&
            <TableRow>
              <TableCell>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    No history available
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          }
        </TableBody>
      </Table>
    </TableContainer>
    </InfiniteScroll>
    </div>
  );
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  environmentDetails: state.EnvironmentReducer.environmentDetails,
});

const connector = connect(mapStateToProps);

export default connector(History);
type PropsFromRedux = ConnectedProps<typeof connector>;
