import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import CDCard from "../../../../../components/cicdcard/CdCard";
import BackdropLoader from "../../../../../components/loader/BackdropLoader";
import NoContentImage from "../../../../../components/nocontentimagecontainer/NoContentImage";
import { clearCDlist, fetchCDList } from "../../../cicdredux/actions";
import { Dispatch } from "redux";
import { CdHistoryModel, EnvironmentModel } from "../../../../../models/Environment.model";
//import CicdCard from '../../../../../components/cicdcard/CicdCard';
import "./cdtab.css";

interface Iprops extends PropsFromRedux {
  environmentDetails:EnvironmentModel;
}
export const CdTab = (props: Iprops) => {
  const { cdList, fetchingCdList, environmentDetails } = props;

  const { t } = useTranslation();
  useEffect(() => {
    if (environmentDetails.id > 0) {
      props.fetchCDList(environmentDetails.id);
    }
    return () => {
      props.clearCDlist()
    };
  }, [environmentDetails]);

  return (
    <div data-test="cd-container">
      {cdList?.deployments?.map((deployment:CdHistoryModel, i:number) => (
        <CDCard deployment={deployment} key={i} />
      ))}
      {!cdList?.deployments || cdList?.deployments?.length < 1 ? (
        <NoContentImage
          type="env"
          alt="No Deployments"
          message={t("Environment.CdTab.nodeployment")}
        />
      ) : (
        ""
      )}
      {fetchingCdList && (
        <BackdropLoader message={t("Environment.CdTab.fetchingList")} />
      )}
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  cdList: state.CICDReducer.cdList,
  fetchingCdList: state.CICDReducer.fetchingCdList,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    fetchCDList: (id:number) => dispatch(fetchCDList(id)),
    clearCDlist: () => dispatch(clearCDlist()),
  };
};

const connector = connect(mapStateToProps, mapDispatchtoProps);
export default connector(CdTab);
type PropsFromRedux = ConnectedProps<typeof connector>;
