import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { makeStyles } from "@material-ui/core/styles";

import { Grid, Card, CardContent } from "@material-ui/core";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import { LabelHandler } from "../../../../components/labelHandler/LabelHandler";
import AccessTimeOutlinedIcon from "@material-ui/icons/AccessTimeOutlined";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getDateInStandardFormat, getDiffDays, gitDiffBtnScanDate } from "../../../../helpers/utils";
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import RestoreOutlinedIcon from '@material-ui/icons/RestoreOutlined';

const useStyles = makeStyles({
  cardPadding:{
    padding: "15px"
  },
});

interface Props extends PropsFromRedux {
  openDetails: (listID:number) => void
  list:any;
  serialNo: number;
}

export function ScannedList(props: Props) {
  const classes = useStyles();

  const openDetailsPage = (id:number) => {
    props.openDetails(id);
  };

  return (
    <div>
      <Card
        className="m-b-20"
        data-test="pods-overview-container"
        onClick={() => openDetailsPage(props.list.id)}
        style={{ cursor: "pointer" }}
      >
        <CardContent className={classes.cardPadding}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item md={3} xs={12}>
                  <LabelHandler
                    icon={props.list.status === "completed" ? <CheckOutlinedIcon fontSize="medium" style={{color:"#4CAF50"}} /> :
                    props.list.status === "error"?<CloseOutlinedIcon fontSize="medium" style={{color:"#FF5252"}} />
                    :<CircularProgress size={18} style={{color:"#FF9800"}} />}
                    iconTooltip={props.list.status}
                    label={`${props.serialNo+1}. ${props.list.name}`}
                    labelTooltip={props.list.name}
                    data-test="pod-namespace"
                  />
                </Grid>
                <Grid item md={2} xs={5}>
                  <LabelHandler
                    icon={<SettingsOutlinedIcon />}
                    iconTooltip={props.list.plugin_name}
                    label={props.list.plugin_name}
                    labelTooltip={props.list.plugin_name}
                    data-test="Trivy"
                  />
                </Grid>
                <Grid item md={2} xs={7}>
                  <LabelHandler
                    icon={<RestoreOutlinedIcon />}
                    iconTooltip={props.list.time_taken + " sec"}
                    // label={(props.list.time_taken/60).toFixed(2)+"m"}
                    label={gitDiffBtnScanDate(props.list.time_taken)}
                    
                    labelTooltip={props.list.time_taken + " sec"}
                    data-test="pod-age"
                  />
                </Grid>
                <Grid item md={2} xs={5}>
                  <LabelHandler
                    icon={<AccessTimeOutlinedIcon />}
                    iconTooltip={getDateInStandardFormat(props.list.createdAt)}
                    label={getDiffDays(props.list.createdAt, true)}
                    labelTooltip={getDateInStandardFormat(props.list.createdAt)}
                    data-test="pod-age"
                  />
                </Grid>
                <Grid item md={3} xs={7}>
                  <LabelHandler
                    icon={<ReportProblemOutlinedIcon color="error" />}
                    iconTooltip={props.list.vulnerability_count}
                    label={`Vulnerability Count(${props.list.vulnerability_count})`}
                    labelTooltip={props.list.vulnerability_count}
                    data-test="pod-age"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ScannedList);
