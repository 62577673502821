import React, { useEffect } from 'react'
import {
    Grid,
    Link,
    Card,
    List, 
    ListItem, 
    ListItemText, 
    ListItemIcon,
    CardContent,
    CardHeader
} from '@material-ui/core';
import { connect,ConnectedProps } from 'react-redux';
import CheckIcon from '@material-ui/icons/Check';
import LaunchIcon from '@material-ui/icons/Launch';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import { AppConstants } from '../../../../constants/appconstants';
import { useTranslation } from 'react-i18next';
import { getProviderPermissions } from '../../redux/actions'
import { makeStyles } from '@material-ui/core/styles';
import { Dispatch } from 'redux';
/* istanbul ignore next */
const useStyles = makeStyles(() => ({
    actions: {
        display: 'flex',
        justifyContent: 'center'
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        justifyContent: 'space-between',
        padding: '1rem',
    },
    continueButton: {
        marginTop: '2rem'
    },
    center: {
        justifyContent: 'center'
    },
    permissionList: {
        maxHeight: 200,
        overflowY: "auto"
    },
    alignmiddle:{
        verticalAlign:"middle"
    },
    allowd:{
        color:"green"
    },
    notallowd:{
        color:"red"
    }
}));


interface ValidationData {
    has_permission: string[];
    no_permission: string[];
}
interface Iprops extends PropsFromRedux{
    provider:string;
    validationData:ValidationData;
}

export function Permissions(props:Iprops) {
    const { validationData, providerPermissions } = props;
    const [t] = useTranslation();
    const classes = useStyles();
    const [learnMoreLink, setLearnMoreLink] = React.useState("");
   
    useEffect(() => {
        if (props.provider) {
            switch (props.provider) {
                case AppConstants.ClusterProvider.GCP:
                    setLearnMoreLink("https://cloud.google.com/iam/docs/understanding-roles/")
                    break;
                case AppConstants.ClusterProvider.EKS:
                    setLearnMoreLink("https://aws.amazon.com/iam/features/manage-permissions/")
                    break;
                default:
                    return
            }
            props.getProviderPermissions(props.provider);
        }
    }, [props.provider]);
    return (
        <div>
            {
                (providerPermissions || validationData) &&
                <Grid container justify="center" alignItems="center" spacing={2} className="m-t-20" data-test="main-container">
                    <Grid item md={12} >
                        <Card>
                            <CardHeader title="Permissions" />
                            <CardContent>
                                <div>
                                    {
                                        validationData &&
                                        <Grid container spacing={2} justify="center" alignContent="center" alignItems="center">
                                            <Grid item data-test="total-permissions"> {t('Cluster.ProviderPermissions.total')} : {validationData.has_permission.length + validationData.no_permission.length}: </Grid>
                                            <Grid item><span className={classes.alignmiddle}><CheckIcon className={classes.allowd} data-test="check-icon" /> </span> <span className={classes.alignmiddle} data-test="available-permissions">{validationData.has_permission ? validationData.has_permission.length : 0}</span></Grid>
                                            <Grid item><span className={classes.alignmiddle}><ClearOutlinedIcon fontSize="small" className={classes.notallowd} data-test="cross-icon" /></span><span className={classes.alignmiddle} data-test="unavailable-permissions">{validationData.no_permission ? validationData.no_permission.length : 0}</span></Grid>
                                        </Grid>
                                    }
                                    {
                                        (providerPermissions && !validationData) &&
                                        <Grid container spacing={2} justify="center" alignContent="center" alignItems="center">
                                            <Grid item data-test="total-provider-permissions"> {t('Cluster.ProviderPermissions.total')} : {providerPermissions.length} </Grid>
                                        </Grid>
                                    }
                                    <List className={classes.permissionList}>
                                        {
                                            validationData && validationData.no_permission && validationData.no_permission.length > 0 && validationData.no_permission.map((item:string, ind:number) => (
                                                <ListItem key={ind}>
                                                    <ListItemIcon><ClearOutlinedIcon className={classes.notallowd} /></ListItemIcon>
                                                    <ListItemText primary={item} data-test="no-perm-text" />
                                                </ListItem>
                                            ))
                                        }
                                        {
                                            validationData && validationData.has_permission && validationData.has_permission.length > 0 && validationData.has_permission.map((item:string, ind:number) => (
                                                <ListItem key={ind}>
                                                    <ListItemIcon><CheckIcon className={classes.allowd} /></ListItemIcon>
                                                    <ListItemText primary={item} data-test="has-perm-text" />
                                                </ListItem>
                                            ))
                                        }
                                        {
                                            providerPermissions && !validationData && providerPermissions.length > 0 && providerPermissions.map((item:string, ind:number) => (
                                                <ListItem key={ind}>
                                                    <ListItemIcon></ListItemIcon>
                                                    <ListItemText primary={item} data-test="perm-text" />
                                                </ListItem>
                                            ))

                                        }
                                    </List>
                                    {
                                        learnMoreLink &&
                                        <div className="m-t-20">
                                            <Link
                                                target="_blank"
                                                href={learnMoreLink}
                                                className="cnameLink"
                                                rel="noreferrer"
                                                underline="always"
                                                data-test="learn-more-link"
                                            >
                                                {t('Cluster.ProviderPermissions.learnMore')}<LaunchIcon className="openLinkIcon" />
                                            </Link>
                                        </div>
                                    }
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            }
        </div>
    )
}

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch:Dispatch) => {
    return {
        getProviderPermissions: (provider:string) => dispatch(getProviderPermissions(provider))
    }
}

/* istanbul ignore next */
const mapStateToProps = (state:any) => {
    return {
        providerPermissions: state.ClusterReducer.providerPermissions
    }
}
const connector=connect(mapStateToProps, mapDispatchtoProps)
export default connector(Permissions);
type PropsFromRedux=ConnectedProps<typeof connector>