import React from "react";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number|undefined;
  value: number;
  padding?: number;
  tabPanel: string;
  className?: any;
  name?: string;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, padding, tabPanel, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`${tabPanel}-${index}`}
      aria-labelledby={`${tabPanel}-${index}`}
      {...other}
    >
      {value === index && <Box p={padding || 0}>{children}</Box>}
    </Typography>
  );
}
