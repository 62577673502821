const APP_PREFIX = '@APP';

export const FETCH_APP_DETAIL = `${ APP_PREFIX }/FETCH_APP_DETAIL`
export const FETCH_APP_DETAIL_SUCCESS = `${ APP_PREFIX }/FETCH_APP_DETAIL_SUCCESS`
export const FETCH_APP_DETAIL_FAILURE = `${ APP_PREFIX }/FETCH_APP_DETAIL_FAILURE`
export const DELETE_APP  = `${ APP_PREFIX }/DELETE_APP`
export const DELETE_APP_SUCCESS  = `${ APP_PREFIX }/DELETE_APP_SUCCESS`
export const DELETE_APP_FAILURE  = `${ APP_PREFIX }/_FAILURE`
export const FETCH_APP_ENVIRONMENTLIST  = `${ APP_PREFIX }/FETCH_APP_ENVIRONMENTLIST`
export const FETCH_APP_ENVIRONMENTLIST_SUCCESS  = `${ APP_PREFIX }/FETCH_APP_ENVIRONMENTLIST_SUCCESS`
//export const PLUGIN_SELECTION =  `${ APP_PREFIX }/PLUGIN_SELECTION`
export const FETCH_PLUGIN_DETAIL = `${ APP_PREFIX }/FETCH_PLUGIN_DETAIL`
export const FETCH_PLUGIN_DETAIL_SUCCESS = `${ APP_PREFIX }/FETCH_PLUGIN_DETAIL_SUCCESS`
export const CLEAR_PLUGIN_DETAILS = `${ APP_PREFIX }/CLEAR_PLUGIN_DETAILS`
export const CREATE_APP  = `${ APP_PREFIX }/CREATE_APP`
export const CREATE_APP_SUCCESS  = `${ APP_PREFIX }/CREATE_APP_SUCCESS`
export const CREATE_APP_FAILURE  = `${ APP_PREFIX }/CREATE_APP_FAILURE`
export const CLEAR_APP_DETAIL = `${ APP_PREFIX }/CLEAR_APP_DETAIL`
export const FETCH_APP_AVAILABLE_RESOURCE = `${ APP_PREFIX }/FETCH_APP_AVAILABLE_RESOURCE`
export const FETCH_APP_AVAILABLE_RESOURCE_SUCCESS = `${ APP_PREFIX }/FETCH_APP_AVAILABLE_RESOURCE_SUCCESS`
export const GIT_CONNECT = `${ APP_PREFIX }/GIT_CONNECT`
export const GIT_CONNECT_SUCCESS = `${ APP_PREFIX }/GIT_CONNECT_SUCCESS`
export const GIT_CONNECT_FAILURE = `${ APP_PREFIX }/GIT_CONNECT_FAILURE`
export const CLEAR_ERROR_MESSAGE = `${ APP_PREFIX }/CLEAR_ERROR_MESSAGE`
export const CR_CONNECT = `${APP_PREFIX}/CR_CONNECT`
export const CR_CONNECT_SUCCESS = `${APP_PREFIX}/CR_CONNECT_SUCCESS`
export const CR_CONNECT_FAILURE = `${APP_PREFIX}/CR_CONNECT_FAILURE`
export const FETCH_GIT_REPOS = `${ APP_PREFIX }/FETCH_GIT_REPOS`
export const FETCH_GIT_REPOS_SUCCESS = `${ APP_PREFIX }/FETCH_GIT_REPOS_SUCCESS`
export const FETCH_GIT_REPOS_FAILURE = `${ APP_PREFIX }/FETCH_GIT_REPOS_FAILURE`
export const FETCH_GIT_BRANCHES = `${ APP_PREFIX }/FETCH_GIT_BRANCHES`
export const FETCH_GIT_BRANCHES_SUCCESS = `${ APP_PREFIX }/FETCH_GIT_BRANCHES_SUCCESS`
//export const CREATE_APP_TEMPLATETYPE = `${ APP_PREFIX }/CREATE_APP_TEMPLATETYPE`
export const RESET_GIT_AUTHORIZATION = `${ APP_PREFIX }/RESET_GIT_AUTHORIZATION`
export const FETCH_GIT_ORGANIZATIONS = `${ APP_PREFIX }/FETCH_GIT_ORGANIZATIONS`
export const FETCH_GIT_ORGANIZATIONS_SUCCESS = `${ APP_PREFIX }/FETCH_GIT_ORGANIZATIONS_SUCCESS`
export const FETCH_GIT_ORGANIZATIONS_FAILURE = `${ APP_PREFIX }/FETCH_GIT_ORGANIZATIONS_FAILURE`
export const FETCH_GIT_ORG_REPOS = `${ APP_PREFIX }/FETCH_GIT_ORG_REPOS`
//export const FETCH_GIT_ORG_REPOS_SUCCESS = `${ APP_PREFIX }/FETCH_GIT_ORG_REPOS_SUCCESS`
export const FETCH_APP_ROLE = `${ APP_PREFIX }/FETCH_APP_ROLE`
export const FETCH_APP_ROLE_SUCCESS = `${ APP_PREFIX }/FETCH_APP_ROLE_SUCCESS`
export const CLEAR_BRANCHES = `${ APP_PREFIX }/CLEAR_BRANCHES`
export const RENAME_APP = `${ APP_PREFIX }/RENAME_APP`
export const RENAME_SUCCESS=`${APP_PREFIX}/RENAME_SUCCESS`
export const RENAME_ERROR=`${APP_PREFIX}/RENAME_ERROR`

const fetchAppDetails = (id) => ({
	type: FETCH_APP_DETAIL,
	data: { id }
})

const deleteApp = (id, project_id, history) => ({
	type: DELETE_APP,
	data: { id, project_id, history }
})

const fetchEnvironmentsByAppId = (id, isHelm) => ({
	type: FETCH_APP_ENVIRONMENTLIST,
	data: { id, isHelm }
})

// const pluginSelection = (id) => ({
// 	type: PLUGIN_SELECTION,
// 	data: { id }
// })

const fetchPluginDetailsById = (id) => ({
	type: FETCH_PLUGIN_DETAIL,
	data: { id }
})

const clearPluginDetails = () => ({ type: CLEAR_PLUGIN_DETAILS })

const clearErrorMessage = () => ({ type: CLEAR_ERROR_MESSAGE })

const createApplication = (jsonBody, history) => ({
	type: CREATE_APP,
	data : { jsonBody, history }
})

export const clearBranches = () => ({
    type: CLEAR_BRANCHES,
})

const fetchAppAvailableResource = (id) => ({
	type: FETCH_APP_AVAILABLE_RESOURCE,
	data : { id }
})

const clearAppdetail = () => ({ type : CLEAR_APP_DETAIL })

const gitConnect = (jsonBody, window, callback) => ({
	type: GIT_CONNECT,
	data: { jsonBody, window, callback }
})

const crConnect = (jsonBody, callback, window) => ({
	type: CR_CONNECT,
	data: { jsonBody, callback, window }
})
const fetchGITOrganizations = (jsonBody, type) => ({
	type: FETCH_GIT_ORGANIZATIONS,
	data: { jsonBody, type }
})

const fetchGITOrgRepos = (orgName, jsonBody, type) => ({
	type: FETCH_GIT_ORG_REPOS,
	data: { orgName, jsonBody, type }
})

const fetchGITRepos = (jsonBody, type) => ({
	type: FETCH_GIT_REPOS,
	data: { jsonBody, type }
})

const fetchGITBranches = (jsonBody, type, orgName, repoName) => ({
	type: FETCH_GIT_BRANCHES,
	data: { jsonBody, type, orgName, repoName }
})

// const templateSelection_create = (type) => ({
// 	type: CREATE_APP_TEMPLATETYPE,
// 	data: { type }
// })

const resetIsGITNotAuthorized = () => ({
	type: RESET_GIT_AUTHORIZATION
})

const clearGITOrganizations = () => ({
	type: FETCH_GIT_ORGANIZATIONS_SUCCESS,
	data: null
}) 
const fetchAppRole = (id) => ({
	type: FETCH_APP_ROLE,
	data: { id }
}) 

const renameApp = (id, name) => ({
	type: RENAME_APP,
	data: { id, name }
})

export {
	fetchAppDetails,
	deleteApp,
	fetchEnvironmentsByAppId,
	//pluginSelection,
	fetchPluginDetailsById,
	createApplication,
	clearAppdetail,
	fetchAppAvailableResource,
	gitConnect,
	crConnect,
	fetchGITOrganizations,
	fetchGITOrgRepos,
	fetchGITRepos,
	fetchGITBranches,
	//templateSelection_create,
	resetIsGITNotAuthorized,
	clearGITOrganizations, 
	fetchAppRole,
	clearPluginDetails,
	clearErrorMessage,
	renameApp
}