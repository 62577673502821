import React, { Component } from 'react'
import { Button, Grid, Paper, Typography } from '@material-ui/core';
// import GitHubIcon from '@material-ui/icons/GitHub';
import { withTranslation, WithTranslation } from 'react-i18next';
import { AppConstants } from '../../constants/appconstants';
import { AuthProvider } from '../../models/Account.model'

interface Props extends WithTranslation{}

export class OAuth extends Component {
    constructor(props: Props){
        super(props);
        this.state = {
        }
    }

    // componentDidMount(){
    // }

    // handleGithubLogin = () => {
    //   const qParams = [
    //     //`client_id=504f23be42f585885a21`,
    //     `client_id=${ window?.config?.REACT_APP_GITHUB_CLIENTID }`,
    //     `redirect_uri=${ window?.config?.REACT_APP_OAUTH_REDIRECT_URL }`,
    //     'scope=user:email,repo,admin:repo_hook',
    //     //`login_hint=`,
    //     //`prompt=consent`,
    //     'state=github'
    //   ].join('&');
    //   window.location.href =  `${ window?.config?.REACT_APP_GITHUB_AUTHORIZE_ENDPOINT }?${ qParams }`;
    // }

    // handleGitlabLogin = () => {
    //   const qParams = [
    //     //`client_id=504f23be42f585885a21`,
    //     `access_type=online`,
    //     `client_id=${ window?.config?.REACT_APP_GITLAB_CLIENTID }`,
    //     `redirect_uri=${ window?.config?.REACT_APP_GITLAB_OAUTH_REDIRECT_URL }`,
    //     'scope=api+sudo+profile+email+openid+read_user+write_repository+write_registry',
    //     `response_type=code`,
    //     `state=gitlab`,
    //     //`login_hint=`,
    //     //`prompt=consent`,
    //   ].join('&');
    //   window.location.href =  `${ window?.config?.REACT_APP_GITLAB_AUTHORIZE_ENDPOINT }?${ qParams }`;
    // }

    handleOauthLogin = (provider: AuthProvider) => {
      // setTimeout(() => window.open(`${provider.authorizeEndpoint}?${provider.params}`, "01Cloud - OAuth", "status=no,location=no,toolbar=no,menubar=no,width:200,height=100"), 100);
      window.location.href =  `${ provider.authorizeEndpoint }?${ provider.params }`;
    }

    render () {
      // const {t} = this.props;
      return (
        <>
           {/* <Grid container spacing={2} data-test="main-container" > */}
            {/* <Grid item xs={12}>
              <Typography variant="h6" color="textSecondary" align="center" data-test="signin-text"> Sign in with </Typography>
            </Grid> */}
            <Grid container justify="center" spacing={2} className="m-b-10" direction='column' data-test="main-container">
              {AppConstants.oauthSignupProviders?.map((_provider, ind) => (
                <Grid item key={ind}>
                  <Button
                    size="small"
                    className={`w-100 r-69`} //${classes.btn}
                    variant="outlined"
                    aria-label={_provider.name}
                    onClick={ () => this.handleOauthLogin(_provider) }
                    data-test="oauth-login-button"
                  >
                      <img
                        src={_provider.icon}
                        alt={_provider.service_name}
                        style={{
                          margin: 3,
                        }}
                      />
                      Sign in with {_provider.name}
                      {/* {t('GitHub')} */}
                  </Button>
                </Grid>
              ))}
            </Grid>
              {/* <Typography variant="body1">Continue with other accounts</Typography> */}
              {/* <Grid align='center'>
          <Tooltip title="Github">
            <IconButton  aria-label="Github" onClick={ this.handleGithubLogin }>
                <GitHubIcon style={{ fontSize: 40, color:'black' }} />
            </IconButton>
            </Tooltip>
            <IconButton color="primary" aria-label="add to shopping cart">
                <GitHubIcon />
            </IconButton>
            <IconButton color="primary" aria-label="add to shopping cart">
                <GitHubIcon />
            </IconButton>
          </Grid> */}
          {/* </Grid> */}
          </>
      );
    }
}

export default withTranslation()(OAuth)