const initialState = {
    orgPlansList : [],
    getOrgPlansListCallStarted : false,
    getOrgPlansListCallSuccess : false,
    getOrgPlansListCallFailure : false,
    organizationList: null,
    creatingOrganization: false,
    switchingOrg: false,
    organizationInfo: {},
    fetchingOrgInfo: false,
    deletingOrganization: false,
    addingMember: false,
    editingMember: false,
    deletingMember: false,
    organizationGroups:[],
    fetchingGroups: false,
    deletingGroup: false,
    addingGroup: false,
    updatingGroup: false,
    gettingGroupInfo: false,
    groupInfo: {},
    fetchingRegistries: false,
    registrylist: null,
    registryDetails: null,
    addingRegistry: false,
    chartCatalogs: null,
    fetchingChartCatalogs: false,
    catalogCategories: null,
    fetchingCatalogCategory: false,
    chartRepos: null,
    chartDetails: null,
    fetchingChartRepos: false,
    syncingChartRepos: false,
    updatingChartRepos: false,
    deletingChartRepos: false,
    addingChartRepos: false,
    updatingRegistry: false,
    deletingRegistry: false,
    registryConfig: null,
    registryconfigLoading: false,
    dnsList: [],
    deletingDns: false,
    orgActivities: [],
    fetchingOrgActivities: false
}

export default initialState
