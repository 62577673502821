import { call, takeLatest, put, select } from 'redux-saga/effects';
import axios from 'axios';
import endpoints from '../../../../constants/endpoints';
import {
    FETCH_PLUGINS_LIST,
    FETCH_PLUGINS_LIST_SUCCESS,
    FETCH_PLUGINS_LIST_FAILURE,
    FETCH_MORE_PLUGINS_LIST_SUCCESS,
    FETCH_PLUGINS_VERSIONS,
    FETCH_PLUGINS_VERSIONS_SUCCESS,
    FETCH_PLUGINS_VERSIONS_FAILURE,
    BEGIN_LOADING_INDICATOR,
    END_LOADING_INDICATOR,
    FETCH_REGIONS,
    FETCH_REGIONS_SUCCESS,
    FETCH_REGIONS_FAILURE,
    FETCH_REGION,
    FETCH_REGION_SUCCESS,
    FETCH_REGION_FAILURE,
    FETCH_CLUSTER,
    FETCH_CLUSTER_SUCCESS,
    FETCH_CLUSTER_FAILURE
    //CREATE_APP_DEPLOY,
} from './actions';
import toast from '../../../../components/toast/Toast';
import { sessionTokenSelector } from '../../../login/redux/selectors';

function fetchPluginPluginsApicall(sessionToken , payload) {
    const config = {
     headers : {
       'Content-Type': 'application/json',
       'Authorization': 'basic ' + sessionToken
     }
   }

   const endPoint = endpoints.PLUGIN_LIST
   .replace(':support_ci', payload.data.support_ci)
   .replace(':is_managed_service', payload.data.is_managed_service)
   .replace(":page", payload?.data?.page ?? 1)
   .replace(":size", payload?.data?.size ?? 20)
   .replace(":search", payload?.data?.search?.toLowerCase() ?? "")

   return axios.get(endPoint, config)
}

function fetchRegionsApicall(sessionToken) {
  const config = {
   headers : {
     'Content-Type': 'application/json',
     'Authorization': 'basic ' + sessionToken,
   }
 }
 return axios.get(endpoints.REGIONS , config)
}

// function createEnvApiCall(sessionToken , payload) {
//   const config = {
//    headers : {
//      'Content-Type': 'application/json',
//      'Authorization': 'basic ' + sessionToken,
//    }
//  }
//  return axios.post(endpoints.CREATE_APPLICATION , payload , config)
// }

function fetchPluginPluginsVersionsApicall(sessionToken, payload) {
  const config = {
   headers : {
     'Content-Type': 'application/json',
     'Authorization': 'basic ' + sessionToken
   }
 }
 return axios.get(endpoints.PLUGIN_VERSION.replace(':pId', payload.data.id) , config)
}

/*
function deployApplication(sessionToken , applicationId) {
  const config = {
   headers : {
     'Content-Type': 'application/json',
     'Authorization': 'basic ' + sessionToken
   }
 }
 return axios.post(endpoints.DEPLOY_APPLICATION.replace(':appId' , applicationId) , config)
}
*/

function* plugin(payload) {
  yield put({ type: BEGIN_LOADING_INDICATOR })
  try { 
    const sessionToken = yield select(sessionTokenSelector)
    const response = yield call(fetchPluginPluginsApicall, sessionToken , payload);
    const data = response.data;
    if (response.status === 200) {
      yield put({ type: END_LOADING_INDICATOR })
      if (payload.data?.page === 1) {
        yield put({ type: FETCH_PLUGINS_LIST_SUCCESS, data })
      } else {
        yield put({ type: FETCH_MORE_PLUGINS_LIST_SUCCESS, data });
      }
    } else {
      yield put({ type: END_LOADING_INDICATOR })
      yield put({
        type: FETCH_PLUGINS_LIST_FAILURE,
        data: {
          message: 'Error while Fetching plugins list ',
        },
      })
    }
  } catch (error) {
    if(error && error.response && error.response.data && error.response.data.error)
       toast.error(error.response.data.error);
    else 
       toast.error('Error while Fetching plugins list');
    yield put({ type: END_LOADING_INDICATOR })
    yield put({
      type: FETCH_PLUGINS_LIST_FAILURE,
      data: {
        message: 'Error while Fetching plugins list',
      },
    })
  }
}

function* regions() {
  try { 
    const sessionToken = yield select(sessionTokenSelector)
    const response = yield call(fetchRegionsApicall , sessionToken);
    const data = response.data;
    if (response.status === 200) {
      yield put({ type: FETCH_REGIONS_SUCCESS, data })
    } else {
      yield put({
        type: FETCH_REGIONS_FAILURE,
        data: {
          message: 'Error while fetching regions',
        },
      })
    }
  } catch (error) {
    if(error && error.response && error.response.data && error.response.data.error)
       toast.error(error.response.data.error);
    else 
       toast.error('Error while fetching regions');
    yield put({
      type: FETCH_REGIONS_FAILURE,
      data: {
        message: 'Error while fetching regions',
      },
    })
  }
}

function* pluginVersions(payload) {
  const sessionToken = yield select(sessionTokenSelector)
  yield put({ type: BEGIN_LOADING_INDICATOR })
  try {
    const response = yield call(fetchPluginPluginsVersionsApicall, sessionToken , payload);
    const data = response.data;
    if (response.status === 200) {
      yield put({ type: END_LOADING_INDICATOR })
      yield put({ type: FETCH_PLUGINS_VERSIONS_SUCCESS, data })
    } else {
      yield put({ type: END_LOADING_INDICATOR })
      yield put({
        type: FETCH_PLUGINS_VERSIONS_FAILURE,
        data: {
          message: 'Error while fetching plugins',
        },
      })
    }
  } catch (error) {
    if(error && error.response && error.response.data && error.response.data.error)
       toast.error(error.response.data.error);
    else 
       toast.error('Error while fetching plugin versions');
    yield put({ type: END_LOADING_INDICATOR })
    yield put({
      type: FETCH_PLUGINS_VERSIONS_FAILURE,
      data: {
        message: 'Error while fetching plugin versions',
      },
    })
  }
}
function* fetchRegion() {
  try {
   const sessionToken = yield select(sessionTokenSelector);
    const config = { headers: { Authorization: "Basic " + sessionToken } };
                    
    const response = yield axios.get(endpoints.REGION, config);
    if(response.data) {
      yield put({type: FETCH_REGION_SUCCESS, data: response.data});
    }
  } catch(error) {
    if(error?.response?.data?.error){
      toast.error(error.response.data.error)
    }
    yield put({type: FETCH_REGION_FAILURE});
  }
}
function* fetchCluster(payload) {
  try {
   const sessionToken = yield select(sessionTokenSelector);
    const config = { headers: { Authorization: "Basic " + sessionToken } };
    
    const response = yield axios.get(endpoints.REGIONCLUSTERS.replace(':region', payload.data.region), config);
    if(response.data) {
      yield put({type: FETCH_CLUSTER_SUCCESS, data: response.data});
    }
  } catch(error) {
    if(error?.response?.data?.error){
      toast.error(error.response.data.error)
    }
    yield put({type: FETCH_CLUSTER_FAILURE});
  }
}

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(FETCH_PLUGINS_LIST, plugin);
  yield takeLatest(FETCH_PLUGINS_VERSIONS, pluginVersions);
  yield takeLatest(FETCH_REGIONS, regions);
  yield takeLatest(FETCH_REGION, fetchRegion);
  yield takeLatest(FETCH_CLUSTER, fetchCluster);
}