const ENVIRONMENTCICD_PREFIX = '@ENVIRONMENTCICD';
export const FETCH_ENV_WORKFLOWS = `${ ENVIRONMENTCICD_PREFIX }/FETCH_ENV_WORKFLOWS`
export const FETCH_ENV_WORKFLOWS_SUCCESS = `${ ENVIRONMENTCICD_PREFIX }/FETCH_ENV_WORKFLOWS_SUCCESS`
export const FETCH_ENV_WORKFLOWS_FAILURE = `${ ENVIRONMENTCICD_PREFIX }/FETCH_ENV_WORKFLOWS_FAILURE`
export const FETCH_MORE_ENV_WORKFLOWS_SUCCESS = `${ ENVIRONMENTCICD_PREFIX }/FETCH_MORE_ENV_WORKFLOWS_SUCCESS`
export const FETCH_ENV_WORKFLOW_LOG = `${ ENVIRONMENTCICD_PREFIX }/FETCH_ENV_WORKFLOW_LOG`
export const FETCH_ENV_WORKFLOW_LOG_SUCCESS = `${ ENVIRONMENTCICD_PREFIX }/FETCH_ENV_WORKFLOW_LOG_SUCCESS`
export const RERUN_ENV_CI_WORKFLOW = `${ ENVIRONMENTCICD_PREFIX }/RERUN_ENV_CI_WORKFLOW`
export const RERUN_ENV_CI_WORKFLOW_SUCCESS = `${ ENVIRONMENTCICD_PREFIX }/RERUN_ENV_CI_WORKFLOW_SUCCESS`
export const RERUN_ENV_CI_WORKFLOW_FAILURE = `${ ENVIRONMENTCICD_PREFIX }/RERUN_ENV_CI_WORKFLOW_FAILURE`
export const RERUN_ENV_CI_TRIGGERED = `${ ENVIRONMENTCICD_PREFIX }/RERUN_ENV_CI_TRIGGERED`
export const CLEAR_RERUN_ENV_CI_TRIGGERED = `${ ENVIRONMENTCICD_PREFIX }/CLEAR_RERUN_ENV_CI_TRIGGERED`
export const STOP_ENV_CI_WORKFLOW = `${ ENVIRONMENTCICD_PREFIX }/STOP_ENV_CI_WORKFLOW`
export const STOP_ENV_CI_WORKFLOW_SUCCESS = `${ ENVIRONMENTCICD_PREFIX }/STOP_ENV_CI_WORKFLOW_SUCCESS`
export const STOP_ENV_CI_WORKFLOW_FAILURE = `${ ENVIRONMENTCICD_PREFIX }/STOP_ENV_CI_WORKFLOW_FAILURE`
export const UPDATE_ENV_WORKFLOW_LOG = `${ ENVIRONMENTCICD_PREFIX }/UPDATE_ENV_WORKFLOW_LOG`
export const UPDATE_ENV_WORKFLOWS = `${ ENVIRONMENTCICD_PREFIX }/UPDATE_ENV_WORKFLOWS`
export const CLEAR_ENV_CI = `${ ENVIRONMENTCICD_PREFIX }/CLEAR_ENV_CI`
export const FETCH_CICD_SETTINGS = `${ ENVIRONMENTCICD_PREFIX }/FETCH_CICD_SETTINGS`
export const FETCH_CICD_SETTINGS_SUCCESS = `${ ENVIRONMENTCICD_PREFIX }/FETCH_CICD_SETTINGS_SUCCESS`
export const FETCH_CICD_SETTINGS_FAILURE = `${ ENVIRONMENTCICD_PREFIX }/FETCH_CICD_SETTINGS_FAILURE`
export const UPDATE_CICD_SETTINGS = `${ ENVIRONMENTCICD_PREFIX }/UPDATE_CICD_SETTINGS`
export const UPDATE_CICD_SETTINGS_SUCCESS = `${ ENVIRONMENTCICD_PREFIX }/UPDATE_CICD_SETTINGS_SUCCESS`
export const UPDATE_CICD_SETTINGS_FAILURE = `${ ENVIRONMENTCICD_PREFIX }/UPDATE_CICD_SETTINGS_FAILURE`
export const CLEAR_CICD_SETTINGS = `${ ENVIRONMENTCICD_PREFIX }/CLEAR_CICD_SETTINGS`
export const GET_CI_STATS = `${ ENVIRONMENTCICD_PREFIX }/GET_CI_STATS`
export const GET_CI_STATS_SUCCESS = `${ ENVIRONMENTCICD_PREFIX }/GET_CI_STATS_SUCCESS`
export const GET_CI_STATS_FAILURE = `${ ENVIRONMENTCICD_PREFIX }/GET_CI_STATS_FAILURE`
export const CLEAR_CI_STATS = `${ ENVIRONMENTCICD_PREFIX }/CLEAR_CI_STATS`
export const TEST_EMAIL = `${ ENVIRONMENTCICD_PREFIX }/TEST_EMAIL`
export const TEST_EMAIL_SUCCESS = `${ ENVIRONMENTCICD_PREFIX }/TEST_EMAIL_SUCCESS`
export const TEST_EMAIL_FAILURE = `${ ENVIRONMENTCICD_PREFIX }/TEST_EMAIL_FAILURE`
export const TEST_SLACK = `${ ENVIRONMENTCICD_PREFIX }/TEST_SLACK`
export const TEST_SLACK_SUCCESS = `${ ENVIRONMENTCICD_PREFIX }/TEST_SLACK_SUCCESS`
export const TEST_SLACK_FAILURE = `${ ENVIRONMENTCICD_PREFIX }/TEST_SLACK_FAILURE`
export const TEST_WEBHOOK = `${ ENVIRONMENTCICD_PREFIX }/TEST_WEBHOOK`
export const TEST_WEBHOOK_SUCCESS = `${ ENVIRONMENTCICD_PREFIX }/TEST_WEBHOOK_SUCCESS`
export const TEST_WEBHOOK_FAILURE = `${ ENVIRONMENTCICD_PREFIX }/TEST_WEBHOOK_FAILURE`
export const GET_DEPLOYMENT_TYPES = `${ ENVIRONMENTCICD_PREFIX }/GET_DEPLOYMENT_TYPES`
export const GET_DEPLOYMENT_TYPES_SUCCESS = `${ ENVIRONMENTCICD_PREFIX }/GET_DEPLOYMENT_TYPES_SUCCESS`
export const GET_DEPLOYMENT_TYPES_FAILURE = `${ ENVIRONMENTCICD_PREFIX }/GET_DEPLOYMENT_TYPES_FAILURE`
export const FETCH_CD_SETTINGS = `${ ENVIRONMENTCICD_PREFIX }/FETCH_CD_SETTINGS`
export const FETCH_CD_SETTINGS_SUCCESS = `${ ENVIRONMENTCICD_PREFIX }/FETCH_CD_SETTINGS_SUCCESS`
export const FETCH_CD_SETTINGS_FAILURE = `${ ENVIRONMENTCICD_PREFIX }/FETCH_CD_SETTINGS_FAILURE`
export const UPDATE_CD_SETTINGS = `${ ENVIRONMENTCICD_PREFIX }/UPDATE_CD_SETTINGS`
export const UPDATE_CD_SETTINGS_SUCCESS = `${ ENVIRONMENTCICD_PREFIX }/UPDATE_CD_SETTINGS_SUCCESS`
export const UPDATE_CD_SETTINGS_FAILURE = `${ ENVIRONMENTCICD_PREFIX }/UPDATE_CD_SETTINGS_FAILURE`
export const FETCH_CD_LIST = `${ ENVIRONMENTCICD_PREFIX }/FETCH_CD_LIST`
export const FETCH_CD_LIST_SUCCESS = `${ ENVIRONMENTCICD_PREFIX }/FETCH_CD_LIST_SUCCESS`
export const FETCH_CD_LIST_FAILURE = `${ ENVIRONMENTCICD_PREFIX }/FETCH_CD_LIST_FAILURE`
export const FETCH_CD_LIST_STATUS = `${ ENVIRONMENTCICD_PREFIX }/FETCH_CD_LIST_STATUS`
export const FETCH_CD_LIST_STATUS_SUCCESS = `${ ENVIRONMENTCICD_PREFIX }/FETCH_CD_LIST_STATUS_SUCCESS`
export const FETCH_CD_LIST_STATUS_FAILURE = `${ ENVIRONMENTCICD_PREFIX }/FETCH_CD_LIST_STATUS_FAILURE`
export const CLEAR_CD_LIST = `${ ENVIRONMENTCICD_PREFIX }/CLEAR_CD_LIST`
export const FETCH_ENV_CI_LOG = `${ENVIRONMENTCICD_PREFIX}/FETCH_ENV_CI_LOG`
export const FETCH_ENV_CI_LOG_SUCCESS = `${ENVIRONMENTCICD_PREFIX}/FETCH_ENV_CI_LOG_SUCCESS`
export const UPDATE_ENV_CI_LOG = `${ENVIRONMENTCICD_PREFIX}/UPDATE_ENV_CI_LOG`

const fetchEnvWorkflows = (id, pageNo, pageSize) => ({
	type: FETCH_ENV_WORKFLOWS,
	data: { id, pageNo, pageSize }
})

export const fetchCDList = (id) => ({
	type: FETCH_CD_LIST,
	data: {id}
})

export const fetchCDListStatus = (id) => ({
	type: FETCH_CD_LIST_STATUS,
	data: {id}
})

export const clearCDlist = () => ({
	type: CLEAR_CD_LIST
})

export const fetchCDSettings = (id) => ({
	type: FETCH_CD_SETTINGS,
	data: {id}
})

export const updateCDSettings = (id, payload) => ({
	type: UPDATE_CD_SETTINGS,
	data: {id, payload}
})

export const fetchDeploymentTypes = () => ({
	type: GET_DEPLOYMENT_TYPES,
	data: {}
})

const fetchEnvWorkflowLogByName = (id, workflowName, noEmptyCheck) => ({
	type: FETCH_ENV_WORKFLOW_LOG,
	data: { id, workflowName, noEmptyCheck }
})

const rerunEnvCIWorkflow = (id, workflowName) => ({
	type: RERUN_ENV_CI_WORKFLOW,
	data: { id, workflowName }
})

const stopEnvCIWorkflow = (id, workflowName) => ({
	type: STOP_ENV_CI_WORKFLOW,
	data: { id, workflowName }
})

const updateEnvWorkflowLog = (workflowlogs) => ({
	type: UPDATE_ENV_WORKFLOW_LOG,
	data: { workflowlogs }
})

const updateEnvWorkflows = (workflows) => ({
	type: UPDATE_ENV_WORKFLOWS,
	data: { workflows }
})

const clearEnvironmentCI = () => ({ type: CLEAR_ENV_CI })

const fetchCICDSettings = (id) => ({
	type: FETCH_CICD_SETTINGS,
	data: { id }
})

const testEmail = (eId) => ({
	type: TEST_EMAIL,
	data:{
		id: eId
	}
})

const testSlack = (eId) => ({
	type: TEST_SLACK,
	data:{
		id: eId
	}
})

const testWebhook = (eId) => ({
	type: TEST_WEBHOOK,
	data:{
		id: eId
	}
})

const updateCICDSettings = (id, jsonBody) => ({
	type: UPDATE_CICD_SETTINGS,
	data: { id, jsonBody }
})

const clearCICDSettings = () => ({ type: CLEAR_CICD_SETTINGS })

const clearCIBuildTriggered = () => ({ type: CLEAR_RERUN_ENV_CI_TRIGGERED })

const getCIStatistics = (id) => ({
	type: GET_CI_STATS,
	data: { id }
}) 

const clearCIStatistics = () => ({ type: CLEAR_CI_STATS })

const fetchEnvCILogs = (id, workflowName, task, step) => ({
	type: FETCH_ENV_CI_LOG,
	data: { id, workflowName, task, step }
})

const updateEnvCILogs = (workflowlogs) => ({
	type: UPDATE_ENV_CI_LOG,
	data: { workflowlogs }
})

export {
	fetchEnvWorkflows,
	fetchEnvWorkflowLogByName,
	rerunEnvCIWorkflow,
	stopEnvCIWorkflow,
	updateEnvWorkflowLog,
	updateEnvWorkflows,
	clearEnvironmentCI,
	fetchCICDSettings,
	updateCICDSettings,
	clearCICDSettings, 
	clearCIBuildTriggered,
	getCIStatistics,
	clearCIStatistics,
	testEmail,
	testSlack,
	testWebhook,
	fetchEnvCILogs,
	updateEnvCILogs
}