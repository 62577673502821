const initialState = {
    pluginList: [],
    error: '',
    deletingPlugin: false,
    creatingPlugin: false,
    editingPlugin: false,
    editPluginSuccess: false,
    editPluginFail: false,
    createPluginSuccess: false,
    createPluginFail: false,
    plugin: null,
    fetchingPlugin: false,
    fetchingPlugins: false,
  };
  export default initialState;
