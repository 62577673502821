import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from "@material-ui/core";
import {
  StyledTableRow,
  StyledTableCell,
} from "../styledtabelcell/StyledTabelCell";
import { useTranslation } from "react-i18next";

type Mount={
  name: string,
  mountPath: string,
  subPath: string,
  readOnly?: boolean,
  sourceType: string,
  sourceName: string
}

type Props = {
  mounts: Mount[]
}

const ContainerMounts = ({ mounts }: Props) => {
  const [t] = useTranslation();

  return (
    <div data-test="mounts-container">
      {mounts.length ? (
        <TableContainer component={Paper} data-test="snapshots-table">
          <Table aria-label="simple table" style={{ minWidth: 650 }}>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>{t("StoragePopup.name")}</StyledTableCell>
                <StyledTableCell align="left">
                  {t("Pods.Mounts.mountPath")}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {t("Pods.Mounts.subPath")}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {t("Pods.Mounts.readOnly")}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {t("Pods.Mounts.sourceType")}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {t("Pods.Mounts.sourceName")}
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {mounts.map((mount, index) => (
                <StyledTableRow key={index} data-test="mount-item">
                  <StyledTableCell data-test="mount-item-name">
                    {mount.name}
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    data-test="mount-item-mountPath"
                  >
                    {mount.mountPath}
                  </StyledTableCell>
                  <StyledTableCell align="left" data-test="mount-item-subPath">
                    {mount.subPath}
                  </StyledTableCell>
                  <StyledTableCell align="left" data-test="mount-item-readOnly">
                    {String(mount.readOnly || "")}
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    data-test="mount-item-sourceType"
                  >
                    {mount.sourceType}
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    data-test="mount-item-sourceName"
                  >
                    {mount.sourceName}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }} data-test="no-data">
          <Typography variant="body1">{t("Pods.Mounts.noData")}</Typography>
        </div>
      )}
    </div>
  );
};

export default ContainerMounts;
