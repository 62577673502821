import React, { Component } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Grid,
  DialogTitle,
  FormHelperText,
} from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";
import {
  addUserInProject,
  addUserInEnvironment,
  fetchUserListInProject,
  editUserInEnvironment,
  editUserInProject,
  addUserInHelmEnvironment,
  editUserInHelmEnvironment,
} from "./redux/actions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Loader from "../loader/Loader";
import AutoComplete from "../autocomplete/AutoComplete";
import { withTranslation, WithTranslation } from "react-i18next";
import { validateInputField } from "../../helpers/utils";
import { Transition } from "../../helpers/utils.ext";
import { Dispatch } from "redux";
import { EnvironmentModel, UserRole } from "../../models/Environment.model";
import { OrgGroupModel } from "../../models/Organization.model";
import { EnvironmentUser } from "../../models/Account.model";

type Payload = {
  project_id: number;
  group_id?: string;
  email?: string;
  user_role_id: string;
  environment_id?: number;
  application_id?: number;
};

interface Props extends WithTranslation, PropsFromRedux {
  instance?: EnvironmentUser | null;
  getOrgGroups?: () => void;
  environmentDetailsComponent?: boolean;
  environmentDetails?: EnvironmentModel;
  helm?: undefined | boolean;
  open: number;
}

type State = {
  userEmail: string;
  isPopupOpened: boolean;
  selectedRole: string;
  id: number;
  isEmailFieldError: boolean;
  EmailFieldErrorMessage: string;
  isRoleFieldError: boolean;
  RoleFieldErrorMessage: string;
  shareType: string;
  shareTypeError: boolean;
  shareTypeErrorMessage: string;
  groups: Array<OrgGroupModel> | null;
  groupName: string;
  isGroupFieldError: boolean;
  groupFieldErrorMessage: string;
  edit: boolean;
};

export class Popup extends Component<Props, State> {
  private EmailInput: React.RefObject<HTMLInputElement>;
  constructor(props: Props) {
    super(props);
    this.state = {
      userEmail: "",
      isPopupOpened: false,
      selectedRole: "Select role",
      id: 0,
      isEmailFieldError: false,
      EmailFieldErrorMessage: "",
      isRoleFieldError: false,
      RoleFieldErrorMessage: "",
      shareType: "email",
      shareTypeError: false,
      shareTypeErrorMessage: "",
      groups: null,
      groupName: "Select",
      isGroupFieldError: false,
      groupFieldErrorMessage: "",
      edit: false,
    };
    this.EmailInput = React.createRef();
  }

  componentDidMount = () => {
    if (this.props.instance) {
      this.initializeEdit(this.props.instance);
    }
  };

  componentWillUnmount = () => {
    this.handleClose();
  };

  initializeEdit = (instance: EnvironmentUser) => {
    if (instance?.group) {
      this.setState({
        shareType: "group",
        groupName: instance?.group?.id.toString(),
        edit: true,
      });
    } else {
      this.setState({
        shareType: "email",
        selectedRole: instance?.user_role_id,
        userEmail: instance?.email,
        edit: true,
      });
    }
  };

  UNSAFE_componentWillReceiveProps = (newProps: Props) => {
    if (this.state.id !== newProps.open) {
      this.setState(
        {
          isPopupOpened: true,
          id: newProps.open,
        },
        this.handledefaultfocus
      );
    }
    if (newProps.organizationGroups) {
      if (
        JSON.stringify(this.props.organizationGroups) !==
        JSON.stringify(newProps.organizationGroups)
      ) {
        this.setState({
          groups: newProps.organizationGroups,
        });
      }
    }
    if (newProps.instance) {
      this.initializeEdit(newProps.instance);
    }
    if (!this.props.instance && newProps.instance && this.props.getOrgGroups) {
      this.props.getOrgGroups();
    }
  };

  handledefaultfocus = (): void => {
    let $this = this;
    setTimeout(function () {
      if ($this?.EmailInput?.current) $this.EmailInput.current.focus();
    }, 200);
  };

  handleClose = () => {
    this.setState({
      isPopupOpened: false,
      userEmail: "",
      selectedRole: "Select role",
      isEmailFieldError: false,
      EmailFieldErrorMessage: "",
      isRoleFieldError: false,
      RoleFieldErrorMessage: "",
      edit: false,
    });
  };

  handleUserEmailChange = (e: React.ChangeEvent<{ value: string }>) => {
    let validation = validateInputField(e.target.value, "email");
    this.setState({
      isEmailFieldError: validation.error,
      EmailFieldErrorMessage: validation.error
        ? this.props.t(validation.message)
        : "",
    });

    this.setState({
      userEmail: e.target.value,
    });
  };

  handleEmailChange = (val: string) => {
    this.setState({
      userEmail: val,
    });
  };

  handleUserEmailOnBlur = () => {
    if (this.state.userEmail.length === 0) {
      this.setState({
        isEmailFieldError: true,
        EmailFieldErrorMessage: this.props.t("AddDNSPopup.cantBeEmpty"),
      });
    }
  };

  handleRoleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    if (e.target.value === "Select role") {
      this.setState({
        selectedRole: e.target.value,
        isRoleFieldError: true,
        RoleFieldErrorMessage: this.props.t("AddDNSPoup.selectRole"),
      });
    } else {
      this.setState({
        selectedRole: e.target.value as string,
        isRoleFieldError: false,
        RoleFieldErrorMessage: "",
      });
    }
  };

  // handleAddChip = (chip) => {
  //     const allUsers = [ ...this.state.allUsers ];
  //     allUsers.push(chip);
  //     this.setState({
  //         allUsers
  //     });
  // }

  sendRequest = () => {
    if (this.state.edit) {
      if (this.props.environmentDetailsComponent) {
        this.editUserInEnvironmentHandler();
      } else {
        this.editUserInProjectHandler();
      }
    } else {
      if (this.props.environmentDetailsComponent) {
        this.addUserInEnvironmentHandler();
      } else {
        this.addUserInProjectHandler();
      }
    }
  };

  addUserInProjectHandler = () => {
    const payload: Payload = {
      // 'email': this.state.userEmail,
      project_id: this.props.projectDetails.id,
      user_role_id: this.state.selectedRole,
    };
    if (this.state.shareType === "group") {
      payload.group_id = this.state.groupName;
    } else {
      payload.email = this.state.userEmail.trim();
    }
    this.handleClose();
    this.props.addUserInProjectAction(payload);
  };

  addUserInEnvironmentHandler = () => {
    const payload: Payload = {
      // 'email': this.state.userEmail,
      project_id: this.props.environmentDetails?this.props.environmentDetails.application.project_id:0,
      user_role_id: this.state.selectedRole,
      environment_id: this.props.environmentDetails?this.props.environmentDetails.id:0,
      application_id: this.props.environmentDetails?this.props.environmentDetails.application_id:0,
    };
    if (this.state.shareType === "group") {
      payload.group_id = this.state.groupName;
    } else {
      payload.email = this.state.userEmail.trim();
    }

    this.handleClose();
    if (this.props.helm) {
      this.props.addUserInHelmEnvironmentAction(payload);
    } else {
      this.props.addUserInEnvironmentAction(payload);
    }
  };

  editUserInProjectHandler = () => {
    const payload = {
      // 'email': this.state.userEmail,
      project_id: this.props.projectDetails.id,
      user_role_id: this.state.selectedRole,
      // 'user_id': this.props.instance?.id,
    };
    // if(this.state.shareType === "group"){
    //     payload.group_id = this.state.groupName
    // }else{
    //     payload.email = this.state.userEmail
    // }
    this.handleClose();
    this.props.editUserInProjectAction(
      payload,
      this.props.instance ? this.props.instance.id : 0
    );
  };

  editUserInEnvironmentHandler = () => {
    const payload = {
      // 'email': this.state.userEmail,
      project_id: this.props.environmentDetails?this.props.environmentDetails.application.project_id : 0,
      user_role_id: this.state.selectedRole,
      environment_id: this.props.environmentDetails?this.props.environmentDetails.id:0,
      application_id: this.props.environmentDetails?this.props.environmentDetails.application_id:0,
    };
    // if(this.state.shareType === "group"){
    //     payload.group_id = this.state.groupName
    // }else{
    //     payload.email = this.state.userEmail
    // }

    this.handleClose();
    if (this.props.helm) {
      this.props.editUserInHelmEnvironmentAction(
        payload,
        this.props.instance ? this.props.instance.id : 0
      );
    } else {
      this.props.editUserInEnvironmentAction(
        payload,
        this.props.instance ? this.props.instance.id : 0
      );
    }
  };

  handleShareTypeChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    if (!this.state.groups && e.target.value === "group" && this.props.getOrgGroups) {
      this.props.getOrgGroups();
    }
    this.setState({
      shareType: e.target.value as string,
    });
  };

  handleGroupChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    if (e.target.value === "Select") {
      this.setState({
        isGroupFieldError: true,
        groupFieldErrorMessage: this.props.t("AddDNSPopup.selectGroup"),
      });
    } else {
      this.setState({
        isGroupFieldError: false,
        groupFieldErrorMessage: "",
      });
    }
    this.setState({
      groupName: e.target.value as string,
    });
  };

  disableShare = () => {
    if (this.state.selectedRole === "Select role") {
      return true;
    }
    if (this.state.shareType === "email") {
      if (
        this.state.isEmailFieldError ||
        this.state.userEmail?.trim()?.length === 0
      ) {
        return true;
      }
      return false;
    }
    if (this.state.shareType === "group") {
      return this.state.isGroupFieldError;
    }
    return true;
  };

  updateError = (error: boolean, message: string) => {
    this.setState({
      isEmailFieldError: error,
      EmailFieldErrorMessage: message,
    });
  };

  disableGroup = () => {
    if (
      this.props.environmentDetailsComponent &&
      this.props.environmentDetails?.application?.project?.organization_id === 0
    ) {
      return true;
    } else if (
      !this.props.environmentDetailsComponent &&
      this.props.projectDetails?.organization_id === 0
    ) {
      return true;
    }
    return false;
  };

  render() {
    const { userEmail, isPopupOpened } = this.state;
    const { t } = this.props;
    return (
      <Dialog
        open={isPopupOpened}
        TransitionComponent={Transition}
        disableEscapeKeyDown={true}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            this.handleClose();
          }
        }}
        //onClose={ this.handleClose }
        keepMounted
        //   classes={{paper: classes.paper}}
        data-test="main-container"
      >
        <DialogTitle>
          <Typography className="dialogtitle">
            {t("AddDNSPopup.share")}
          </Typography>

          <IconButton
            aria-label="close"
            size="small"
            className="right"
            onClick={this.handleClose}
            data-test="close-popup"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent style={{ minWidth: 100 }} dividers>
          <Grid container spacing={2}>
            {!this.disableGroup() && (
              <Grid item xs={12}>
                <FormControl className="w-100">
                  <Select
                    variant="outlined"
                    color="primary"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.shareType}
                    onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                      this.handleShareTypeChange(e);
                    }}
                    error={this.state.shareTypeError}
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    }}
                    disabled={this.state.edit}
                    data-test="type-select"
                  >
                    <MenuItem value="email">{t("AddDNSPopup.email")}</MenuItem>
                    <MenuItem value="group">{t("AddDNSPopup.group")}</MenuItem>
                  </Select>

                  <FormHelperText error={this.state.shareTypeError}>
                    {this.state.shareTypeErrorMessage}
                  </FormHelperText>
                </FormControl>
              </Grid>
            )}

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                  {this.state.shareType === "group" &&
                    !this.props.fetchingGroups && (
                      <FormControl className="w-100">
                        <Select
                          variant="outlined"
                          color="primary"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={this.state.groupName}
                          onChange={(
                            e: React.ChangeEvent<{ value: unknown }>
                          ) => {
                            this.handleGroupChange(e);
                          }}
                          error={this.state.isGroupFieldError}
                          disabled={this.state.edit}
                          MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                          }}
                          data-test="group-select"
                        >
                          <MenuItem value="Select">
                            {t("AddDNSPopup.selectGrup")}
                          </MenuItem>
                          {this.props?.organizationGroups?.map(
                            (group: OrgGroupModel) => (
                              <MenuItem key={group.id} value={group.id}>
                                {group.Name}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        <FormHelperText error={this.state.isGroupFieldError}>
                          {this.state.groupFieldErrorMessage}
                        </FormHelperText>
                      </FormControl>
                    )}
                  {this.state.shareType === "email" &&
                    (this.disableGroup() ? (
                      <TextField
                        value={userEmail}
                        inputRef={this.EmailInput}
                        variant="outlined"
                        label={t("AddDNSPopup.emailAddress")}
                        style={{ marginRight: "1rem", width: "100%" }}
                        name="userEmail"
                        error={this.state.isEmailFieldError}
                        helperText={this.state.EmailFieldErrorMessage}
                        onBlur={this.handleUserEmailOnBlur}
                        onChange={this.handleUserEmailChange}
                        disabled={this.state.edit}
                        data-test="email-input"
                      />
                    ) : (
                      <AutoComplete
                        email={userEmail}
                        setEmail={this.handleEmailChange}
                        updateError={this.updateError}
                        disabled={this.state.edit}
                        data-test="autocomplete-email"
                      />
                    ))}
                  {this.props.fetchingGroups && (
                    <div>
                      <Loader />
                    </div>
                  )}
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl className="w-100">
                    <Select
                      variant="outlined"
                      color="primary"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.selectedRole}
                      onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                        this.handleRoleChange(e);
                      }}
                      error={this.state.isRoleFieldError}
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                      }}
                      data-test="role-select"
                    >
                      <MenuItem value="Select role">
                        {t("AddDNSPopup.role")}
                      </MenuItem>
                      {this.props.rolesData.map((roles: UserRole) => (
                        <MenuItem key={roles.id} value={roles.id}>
                          {roles.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error={this.state.isRoleFieldError}>
                      {this.state.RoleFieldErrorMessage}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.sendRequest}
            color="primary"
            variant="contained"
            disabled={this.disableShare()}
            data-test="add-btn"
          >
            {t("AddDNSPopup.share")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  rolesData: state.AddUserPopupReducer.rolesData,
  // environmentDetails: state.EnvironmentReducer.environmentDetails,
  projectDetails: state.ProjectReducer.projectDetails,
  addingUser: state.AddUserPopupReducer.addingUser,
  fetchingGroups: state.OrganizationReducer.fetchingGroups,
  organizationGroups: state.OrganizationReducer.organizationGroups,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    addUserInProjectAction: (payload: Payload) =>
      dispatch(addUserInProject(payload)),
    editUserInProjectAction: (payload: Payload, userId: number) =>
      dispatch(editUserInProject(payload, userId)),
    addUserInEnvironmentAction: (payload: Payload) =>
      dispatch(addUserInEnvironment(payload)),
    editUserInEnvironmentAction: (payload: Payload, userId: number) =>
      dispatch(editUserInEnvironment(payload, userId)),
    addUserInHelmEnvironmentAction: (payload: Payload) =>
      dispatch(addUserInHelmEnvironment(payload)),
    editUserInHelmEnvironmentAction: (payload: Payload, userId: number) =>
      dispatch(editUserInHelmEnvironment(payload, userId)),
    fetchUserListInProjectAction: (projectId: number) =>
      dispatch(fetchUserListInProject(projectId)),
  };
};

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withTranslation()(Popup));
