import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { CircularProgress, Typography } from "@material-ui/core";

type Props = {
  message: string;
  size: number;
  thickness: number;
  typoSize: "h1" | "h2" | "h3" | "h4" | "h5" | "h6"
};

const useStyles = makeStyles((theme) =>
  createStyles({
    spinner: {
        marginRight: 10,
        animationDuration: "500",
      },
  })
);

export default function CircularLoader(props: Props) {
  const classes = useStyles();

  return (
    <>
      <Typography variant={props.typoSize} style={{ display: "flex" }}>
        <CircularProgress size={props.size} thickness={props.thickness} className={classes.spinner} />{" "}
        {props.message}
      </Typography>
    </>
  );
}
