import React from 'react';
import { Tooltip, Typography } from '@material-ui/core';
import Skeleton from 'react-loading-skeleton'
import { getDiffDays, getDateInStandardFormat } from '../../helpers/utils'
import { useTranslation } from 'react-i18next'
import "./DateHandler.css";

type Props = {
    date: string,
    icon?: JSX.Element
}

export const DateHandler = (props: Props) => {
    const [t] = useTranslation()

    const iconComponent = props.icon && React.cloneElement(props.icon, {
        className: "svgicon"
    });

    return (
            <div data-test="date-container" className="date-label-container">
            {iconComponent &&
                <Tooltip title={t('Projects.LoadbalancerInfo.createdLabel')} arrow data-test="tool-tip">
                    {iconComponent}
                </Tooltip>
            }
                <span className="topinfoGrid" title={getDateInStandardFormat(props.date)} data-test="top-info">
                    <Typography color="textPrimary" variant="body2" className="label" >{props.date ? `${getDiffDays(props.date, true)}` : <Skeleton width={80} />}</Typography>
                </span>
            </div>
    )
}
