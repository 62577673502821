import { call, takeLatest, put, select, delay } from "redux-saga/effects";
import axios from "axios";
import endpoints from "../../../constants/endpoints";
import Toast from "../../../components/toast/Toast";
import { sessionTokenSelector } from "../../login/redux/selectors";
import {
    FETCH_ENV_WORKFLOWS,
  FETCH_ENV_WORKFLOWS_SUCCESS,
  FETCH_ENV_WORKFLOWS_FAILURE,
  FETCH_MORE_ENV_WORKFLOWS_SUCCESS,
  FETCH_ENV_WORKFLOW_LOG,
  FETCH_ENV_WORKFLOW_LOG_SUCCESS,
  RERUN_ENV_CI_WORKFLOW,
  STOP_ENV_CI_WORKFLOW,
  FETCH_CICD_SETTINGS,
  UPDATE_CICD_SETTINGS,
  FETCH_CICD_SETTINGS_SUCCESS,
  FETCH_CICD_SETTINGS_FAILURE,
  RERUN_ENV_CI_TRIGGERED,
  UPDATE_CICD_SETTINGS_FAILURE,
  UPDATE_CICD_SETTINGS_SUCCESS,
  //RERUN_ENV_CI_WORKFLOW_SUCCESS,
  //RERUN_ENV_CI_WORKFLOW_FAILURE,
  STOP_ENV_CI_WORKFLOW_SUCCESS,
  STOP_ENV_CI_WORKFLOW_FAILURE,
  GET_CI_STATS,
  GET_CI_STATS_SUCCESS,
  GET_CI_STATS_FAILURE,
  CLEAR_RERUN_ENV_CI_TRIGGERED,
  TEST_EMAIL,
  TEST_EMAIL_FAILURE,
  TEST_EMAIL_SUCCESS,
  TEST_SLACK,
  TEST_SLACK_FAILURE,
  TEST_SLACK_SUCCESS,
  TEST_WEBHOOK,
  TEST_WEBHOOK_FAILURE,
  TEST_WEBHOOK_SUCCESS,
  GET_DEPLOYMENT_TYPES,
  GET_DEPLOYMENT_TYPES_FAILURE,
  GET_DEPLOYMENT_TYPES_SUCCESS,
  FETCH_CD_SETTINGS,
  FETCH_CD_SETTINGS_SUCCESS,
  FETCH_CD_SETTINGS_FAILURE,
  UPDATE_CD_SETTINGS,
  UPDATE_CD_SETTINGS_SUCCESS,
  UPDATE_CD_SETTINGS_FAILURE,
  FETCH_CD_LIST,
  FETCH_CD_LIST_SUCCESS,
  FETCH_CD_LIST_FAILURE,
  FETCH_CD_LIST_STATUS,
  FETCH_CD_LIST_STATUS_SUCCESS,
  FETCH_CD_LIST_STATUS_FAILURE,
  FETCH_ENV_CI_LOG,
  FETCH_ENV_CI_LOG_SUCCESS
} from "./actions";
import { FETCH_ENV_WORKFLOW } from '../redux/actions'
import toast from "../../../components/toast/Toast";
//import paths from "../../../constants/paths";

function fetchEnvWorkflowsCall(sessionToken, payload) {
    const config = {
      headers: {
        Authorization: "basic " + sessionToken,
      },
    };
    return axios.get(
      endpoints.ENVIRONMENT.GET_ENV_WORKFLOWS.replace(":eId", payload.data.id)
        .replace(":page", payload.data.pageNo)
        .replace(":limit", payload.data.pageSize),
      config
    );
  }
  
  // Generator Call
  function* fetchEnvWorkflows(payload) {
    try {
      const sessionToken = yield select(sessionTokenSelector);
      const response = yield call(fetchEnvWorkflowsCall, sessionToken, payload);
      const data = response.data;
      if (data !== null) {
        if (payload.data.pageNo === 1)
          yield put({ type: FETCH_ENV_WORKFLOWS_SUCCESS, data: data.data });
        else
          yield put({ type: FETCH_MORE_ENV_WORKFLOWS_SUCCESS, data: data.data });
      } else {
        yield put({ type: FETCH_ENV_WORKFLOWS_FAILURE});
      }
    } catch (error) {
      yield put({ type: FETCH_ENV_WORKFLOWS_FAILURE});
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      )
        toast.error(error.response.data.error);
    }
  }

  function fetchEnvWorkflowLogByNameCall(sessionToken, payload) {
    const config = {
      headers: {
        Authorization: "basic " + sessionToken,
      },
    };
    return axios.get(
      endpoints.ENVIRONMENT.GET_ENV_WORKFLOW_LOG.replace(
        ":eId",
        payload.data.id
      ).replace(":workflow-name", payload.data.workflowName),
      config
    );
  }
  
  // Generator Call
  function* fetchEnvWorkflowLogByName(payload) {
    try {
      const sessionToken = yield select(sessionTokenSelector);
      const response = yield call(
        fetchEnvWorkflowLogByNameCall,
        sessionToken,
        payload
      );
      const data = response.status === 204 && !payload.data.noEmptyCheck ? { name: payload.data.workflowName} : response.data;
      if (data) {
        yield put({ type: FETCH_ENV_WORKFLOW_LOG_SUCCESS, data });
      } 
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      )
        toast.error(error.response.data.error);
    }
  }
  
  function rerunEnvCIWorkflowCall(sessionToken, payload) {
    const config = {
      headers: {
        Authorization: "basic " + sessionToken,
      },
    };
    return axios.get(
      endpoints.ENVIRONMENT.RERUN_ENV_CI_WORKFLOW.replace(
        ":eId",
        payload.data.id
      ),
      // .replace(":workflow-name", payload.data.workflowName)
      config
    );
  }
  
  // Generator Call
  function* rerunEnvCIWorkflow(payload) {
    try {
      const sessionToken = yield select(sessionTokenSelector);
      //const response =
        yield call(rerunEnvCIWorkflowCall, sessionToken, payload);
      //const data = response.data;
      // if (data != null) {
      //   yield put({ type: FETCH_ENV_WORKFLOW_LOG_SUCCESS, data })
      // } else {
  
      // }
      toast.success("CI process triggered");
      yield put({ type: FETCH_ENV_WORKFLOW, data: {id : payload.data.id} });
      yield put({ type: RERUN_ENV_CI_TRIGGERED });
  
      // yield delay(2000);
      // yield call(fetchEnvWorkflows, payload);
    } catch (error) {
      yield put({ type: CLEAR_RERUN_ENV_CI_TRIGGERED });
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      )
        toast.error(error.response.data.error);
        else {
          toast.error("Cannot Trigger CI process")
        }
    }
  }
  
  function stopEnvCIWorkflowCall(sessionToken, payload) {
    const config = {
      headers: {
        Authorization: "basic " + sessionToken,
      },
    };
    return axios.get(
      endpoints.ENVIRONMENT.STOP_ENV_CI_WORKFLOW.replace(
        ":eId",
        payload.data.id
      ).replace(":workflow-name", payload.data.workflowName),
      config
    );
  }
  
  // Generator Call
  function* stopEnvCIWorkflow(payload) {
    try {
      const sessionToken = yield select(sessionTokenSelector);
      //const response =
        yield call(stopEnvCIWorkflowCall, sessionToken, payload);
      toast.success("CI stop triggered");
      yield put({ type: STOP_ENV_CI_WORKFLOW_SUCCESS });
    } catch (error) {
      yield put({ type: STOP_ENV_CI_WORKFLOW_FAILURE });
  
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      )
        toast.error(error.response.data.error);
    }
  }
  
  function fetchCICDSettingsCall(sessionToken, payload) {
    const config = {
      headers: {
        Authorization: "basic " + sessionToken,
      },
    };
    return axios.get(
      endpoints.ENVIRONMENT.GET_CICD_SETTINGS.replace(":eId", payload.data.id),
      config
    );
  }
  
  // Generator Call
  function* fetchCICDSettings(payload) {
    try {
      const sessionToken = yield select(sessionTokenSelector);
      const response = yield call(fetchCICDSettingsCall, sessionToken, payload);
      const data = response.data;
      if (data !== null) {
        yield put({ type: FETCH_CICD_SETTINGS_SUCCESS, data });
      } 
    } catch (error) {
      // if(error && error.response && error.response.data && error.response.data.error)
      //   toast.error(error.response.data.error);
      if (error && error.response) {
        if (error.response.status === 401 || error.response.status === 404) {
          const data = { errorCode: error.response.status };
          yield put({ type: FETCH_CICD_SETTINGS_FAILURE, data });
        }
      }
    }
  }

  function testEmailCall(sessionToken, payload) {
    const config = {
      headers: {
        Authorization: "basic " + sessionToken,
      },
    };
    return axios.get(
      endpoints.ENVIRONMENT.TEST_EMAIL.replace(":eId", payload.data.id),
      config
    );
  }
  
  function* testEmail(payload) {
    try {
      const sessionToken = yield select(sessionTokenSelector);
      const response = yield call(testEmailCall, sessionToken, payload);
      const data = response.data;
      if (response.status >= 200  && response.status < 300) {
        toast.success(data.message ?? "Email sent.")
        yield put({ type: TEST_EMAIL_SUCCESS, data });
      } else {
        yield put({ type: TEST_EMAIL_FAILURE, data });
        toast.error(data.message ?? "Email testing failed.")
      }
    } catch (error) {
      // if(error && error.response && error.response.data && error.response.data.error)
      yield put({ type: TEST_EMAIL_FAILURE });
      //   toast.error(error.response.data.error);
      if (error && error.response) {
        if (error.response.status === 401 || error.response.status === 404) {
          const data = { errorCode: error.response.status };
          toast.error(error.message ?? data.message ?? "Email testing failed.")
        }
      }
    }
  }

  function testSlackCall(sessionToken, payload) {
    const config = {
      headers: {
        Authorization: "basic " + sessionToken,
      },
    };
    return axios.get(
      endpoints.ENVIRONMENT.TEST_SLACK.replace(":eId", payload.data.id),
      config
    );
  }
  
  function* testSlack(payload) {
    try {
      const sessionToken = yield select(sessionTokenSelector);
      const response = yield call(testSlackCall, sessionToken, payload);
      const data = response.data;
      if (response.status >= 200  && response.status < 300) {
        toast.success(data.message ?? "Slack Test Initiated.")
        yield put({ type: TEST_SLACK_SUCCESS });
      } else {
        yield put({ type: TEST_SLACK_FAILURE });
        toast.error(data.message ?? "Slack testing failed.")
      }
    } catch (error) {
      // if(error && error.response && error.response.data && error.response.data.error)
      yield put({ type: TEST_SLACK_FAILURE });
      //   toast.error(error.response.data.error);
      if (error && error.response) {
        if (error.response.status === 401 || error.response.status === 404) {
          const data = { errorCode: error.response.status };
          toast.error(error.message ?? data.message ?? "Slack testing failed.")
        }
      }
    }
  }

  function testWebhookCall(sessionToken, payload) {
    const config = {
      headers: {
        Authorization: "basic " + sessionToken,
      },
    };
    return axios.get(
      endpoints.ENVIRONMENT.TEST_WEBHOOK.replace(":eId", payload.data.id),
      config
    );
  }
  
  function* testWebhook(payload) {
    try {
      const sessionToken = yield select(sessionTokenSelector);
      const response = yield call(testWebhookCall, sessionToken, payload);
      const data = response.data;
      if (response.status >= 200  && response.status < 300) {
        toast.success(data.message ?? "Webhook Test Initiated.")
        yield put({ type: TEST_WEBHOOK_SUCCESS });
      } else {
        yield put({ type: TEST_WEBHOOK_FAILURE });
        toast.error(data.message ?? "Webhook testing failed.")
      }
    } catch (error) {
      // if(error && error.response && error.response.data && error.response.data.error)
      yield put({ type: TEST_WEBHOOK_FAILURE });
      //   toast.error(error.response.data.error);
      if (error && error.response) {
        if (error.response.status === 401 || error.response.status === 404) {
          const data = { errorCode: error.response.status };
          toast.error(error.message ?? data.message ?? "Webhook testing failed.")
        }
      }
    }
  }
  
  function updateCICDSettingsCall(sessionToken, payload) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "basic " + sessionToken,
      },
    };
    return axios.post(
      endpoints.ENVIRONMENT.UPDATE_CICD_SETTINGS.replace(":eId", payload.data.id),
      payload.data.jsonBody,
      config
    );
  }
  
  // Generator Call
  function* updateCICDSettings(payload) {
    try {
      const sessionToken = yield select(sessionTokenSelector);
      const response = yield call(updateCICDSettingsCall, sessionToken, payload);
      const data = response.data;
      if (data !== null) {
        if(!payload.data.externalSource) {
          toast.success("CI/CD settings updated successfully");
        }
        yield put({ type: UPDATE_CICD_SETTINGS_SUCCESS });
        yield call(fetchCICDSettings, payload);
      } else {
        yield put({ type: UPDATE_CICD_SETTINGS_FAILURE });
      }
    } catch (error) {
      yield put({ type: UPDATE_CICD_SETTINGS_FAILURE });
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      )
        toast.error(error.response.data.error);
    }
  }

  // Generator Call
  function* fetchCDSettings(payload) {
    try {
      const sessionToken = yield select(sessionTokenSelector);
      const config = {
        headers : {
          'Authorization': 'basic ' + sessionToken
        }
      }
      const response = yield axios.get(endpoints.ENVIRONMENT.GET_CD_SETTINGS.replace("eId", payload.data.id), config)
      const data = response.data;
      if (data !== null) {
        yield put({ type: FETCH_CD_SETTINGS_SUCCESS, data });
      } 
    } catch (error) {
      // if(error && error.response && error.response.data && error.response.data.error)
      //   toast.error(error.response.data.error);
      if (error && error.response) {
        if (error.response.status === 401 || error.response.status === 404) {
          const data = { cdSettingsError: error.response.status };
          yield put({ type: FETCH_CD_SETTINGS_FAILURE, data });
        }
      }
    }
  }
 
  function* updateCDSettings(payload) {
    try {
      const sessionToken = yield select(sessionTokenSelector);
      const config = {
        headers : {
          'Authorization': 'basic ' + sessionToken
        }
      }
      const response = yield axios.get(endpoints.ENVIRONMENT.UPDATE_CICD_SETTINGS.replace("eId", payload.data.id),payload.data.payload, config)
      const data = response.data;
      if (data !== null) {
        if(!payload.data.externalSource) {
          toast.success("CI/CD settings updated successfully");
        }
        yield put({ type: UPDATE_CD_SETTINGS_SUCCESS });
        yield call(fetchCDSettings, payload);
      } else {
        yield put({ type: UPDATE_CD_SETTINGS_FAILURE });
      }
    } catch (error) {
      yield put({ type: UPDATE_CD_SETTINGS_FAILURE });
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      )
        toast.error(error.response.data.error);
    }
  }

  function* getCIStatistics(payload) {
    try {
      const sessionToken = yield select(sessionTokenSelector);
      const config = {
        headers : {
          'Authorization': 'basic ' + sessionToken
        }
      }
      const response = yield axios.get(endpoints.ENVIRONMENT.GET_CI_STATS.replace(':eId' , payload.data.id), config)
      const data = response.status === 204 && response.data === "" ? {} : response.data;
      yield put({ type: GET_CI_STATS_SUCCESS, data })
    } catch (error) {
      yield put({ type: GET_CI_STATS_FAILURE })

      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        //yield put({ type: FETCH_ENVIRONMENT_DETAIL_FAILURE })
        toast.error(error.response.data.error);
      }
    }
  }

  function* fetchDeploymentTypes() {
    try {
      const sessionToken = yield select(sessionTokenSelector);
      const config = {
        headers : {
          'Authorization': 'basic ' + sessionToken
        }
      }
      const response = yield axios.get(endpoints.ENVIRONMENT.GET_DEPLOYMENT_TYPES, config)
      const data = response.status === 200 && response.data === "" ? {} : response.data;
      yield put({ type: GET_DEPLOYMENT_TYPES_SUCCESS, data })
    } catch (error) {
      yield put({ type: GET_DEPLOYMENT_TYPES_FAILURE })

      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        //yield put({ type: FETCH_ENVIRONMENT_DETAIL_FAILURE })
        toast.error(error.response.data.error);
      }
    }
  }

  function fetchCDListStatusCall(sessionToken , payload) {
    const config = {
     headers : {
       'Authorization': 'basic ' + sessionToken
     }
   }
   return axios.post(endpoints.ENVIRONMENT.FETCH_CD_STATUS.replace(':id' , payload.data.id), {} ,config)
  }

  function* fetchCDListStatus(payload) {
    try {
      const sessionToken = yield select(sessionTokenSelector)
      const response = yield call(fetchCDListStatusCall, sessionToken , payload)
      const data = response.data;
      if (data !== null) {
        yield put({ type: FETCH_CD_LIST_STATUS_SUCCESS, data })
      } 
    } catch (error) {
      if(error && error.response && error.response.data && error.response.data.error) {
        toast.error(error.response.data.error);
        yield put({ type: FETCH_CD_LIST_STATUS_FAILURE })
      }
    }
   }

   function fetchCDListCall(sessionToken, payload) {
    const config = {
     headers : {
       'Authorization': 'basic ' + sessionToken
     }
   }
   return axios.get(endpoints.ENVIRONMENT.FETCH_CD_LIST.replace(':id' , payload.data.id), config)
  }

  //this needs some testing 
  function* fetchCDList(payload) {
    try {
      const sessionToken = yield select(sessionTokenSelector);   
      yield call(fetchCDListStatus, payload)
      yield delay(3000)
      const response = yield call(fetchCDListCall, sessionToken, payload)
      // console.log(response.data)
      const data = response.data;
      if (data) {
        yield put({ type: FETCH_CD_LIST_SUCCESS, data });
      } else {
        yield put({ type: FETCH_CD_LIST_FAILURE });
        Toast.error(response.message ?? "Failed to fetch CD List");
      }
    } catch (error) {
      yield put({ type: FETCH_CD_LIST_FAILURE });
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        Toast.error(error.response.data.error);
      }
    }
  }
  
// Generator Call
function* fetchEnvCILogs(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = {
      headers: {
        Authorization: "basic " + sessionToken,
      },
    };

    const response = yield axios.get(
      endpoints.ENVIRONMENT.GET_ENV_CI_LOG.replace(":eId", payload.data.id)
        .replace(":workflow-name", payload.data.workflowName)
        .replace(":task", payload.data.task)
        .replace(":step", payload.data.step),
      config
    );
    const data = {
      name: payload.data.workflowName,
      task: payload.data.task,
      step: payload.data.step,
      log: response.data      
    };
    
    yield put({ type: FETCH_ENV_CI_LOG_SUCCESS, data });
    
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    )
      toast.error(error.response.data.error);
  }
}

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
    yield takeLatest(FETCH_ENV_WORKFLOWS, fetchEnvWorkflows);
    yield takeLatest(FETCH_ENV_WORKFLOW_LOG, fetchEnvWorkflowLogByName);
    yield takeLatest(RERUN_ENV_CI_WORKFLOW, rerunEnvCIWorkflow);
    yield takeLatest(STOP_ENV_CI_WORKFLOW, stopEnvCIWorkflow);
    yield takeLatest(FETCH_CICD_SETTINGS, fetchCICDSettings);
    yield takeLatest(UPDATE_CICD_SETTINGS, updateCICDSettings);
    yield takeLatest(UPDATE_CD_SETTINGS, updateCDSettings);
    yield takeLatest(FETCH_CD_SETTINGS, fetchCDSettings);
    yield takeLatest(GET_CI_STATS, getCIStatistics);
    yield takeLatest(TEST_EMAIL, testEmail);
    yield takeLatest(TEST_SLACK, testSlack);
    yield takeLatest(GET_DEPLOYMENT_TYPES, fetchDeploymentTypes);
    yield takeLatest(TEST_WEBHOOK, testWebhook);
    yield takeLatest(FETCH_CD_LIST, fetchCDList)
    yield takeLatest(FETCH_CD_LIST_STATUS, fetchCDListStatus)
    yield takeLatest(FETCH_ENV_CI_LOG, fetchEnvCILogs);
}
