import React, { useEffect , useState} from 'react'
import * as yaml from "js-yaml";
import { connect } from 'react-redux'
import OperatorInstanceUpdateForm from "./OperatorInstanceUpdateForm"
import BackdropLoader from "../../components/loader/BackdropLoader"
import { fetchEnvDetails, updateEnvironment } from '../environment/redux/actions'
import { fetchOperatorDetails} from "./redux/actions"
import { useTranslation } from "react-i18next";
import {
    Button,
    Grid,
    Typography
} from "@material-ui/core";
import { Prompt } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/ext-searchbox";
import "ace-builds/src-noconflict/mode-yaml";
import "ace-builds/src-noconflict/theme-solarized_dark";
import "ace-builds/src-noconflict/theme-xcode";

const useStyles = makeStyles(() => ({
    secret:{
        display: "flex", 
        flexDirection: "column", 
        alignItems: "flex-end", 
        border: "1px solid #000000", 
        marginBottom:"40px"
    },
    crossIcon:{
        cursor:"pointer"
    },
    // configBtn:{
    //     marginLeft:"20px"
    // },
    // btnGroup:{
    //     paddingTop:"25px",
    //     paddingBottom:"10px"
    // }
}));

export const EditOperatorEnvironment = (props) => {
    const classes = useStyles()
    const [t] = useTranslation();
    const [newValues, setNewValues] = useState({});
    const [isBlocking, setIsBlocking] = useState(false)
    const [mainConfig, setMainConfig] = useState(null);
    const [secret, setSecret] = useState([]);
   
    useEffect(() => {
        if (props?.match?.params?.eId) {
            props.fetchEnvDetails(props.match.params.eId)
        }
    }, [props.match?.params?.eId])

    useEffect (() =>{
        if(props.environmentDetails?.application?.operator_package_name){
        props.fetchOperatorDetails({packageName: props.environmentDetails?.application?.operator_package_name});
        }
    }, [props.environmentDetails])

    useEffect(() =>{
       setIsBlocking(yaml.dump(props.environmentDetails.operator_payload) !== newValues)
    }, [newValues])

    const updateValues = (_newValues) => {
        setNewValues(_newValues);
    }

    const alertUser = event =>{
        if(!isBlocking){
            return;
        }
        const e = event || window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = ''; 
        }
        return ''; 
    }
    
    useEffect(() =>{
        window.addEventListener("beforeunload", alertUser )
        return () =>{
          window.removeEventListener("beforeunload", alertUser)
        }
    }, [newValues])

    const redeployEnvironment = () => {
        setIsBlocking(false)
        const jsonBody = {
            operator_payload:  [yaml.load(newValues), ...secret]
        };
        props.updateEnvironment(props.environmentDetails?.id, jsonBody,null , null, props.history);
    };

    const responseSecret = {
        "apiVersion": "v1",
        "kind": "Secret",
        "metadata": {
            "name": "value"
        },
        "type": "Opaque",
        "data": {
            "key": "value"
        }
    }

    const responseConfig = {
        "apiVersion": "v1",
        "kind": "ConfigMap",
        "metadata": {
            "name": "value"
        },
        "data": {
            "key": "value"
        }
    }

    const handleUpdateConfig = (config) => {
        if(config){
            if(config === "secret"){
                setSecret([responseSecret, ...secret])
            }else{
                setSecret([responseConfig, ...secret])
            }
        }
    }

    const handleCancelSecret = (index) => {
        const updatedSecret = [...secret];
        updatedSecret.splice(index, 1);
        setSecret(updatedSecret)
    }

    let timeout;
    const onChange = (value,index) => {
        if(value && index > -1){
            const updatedSecret = [...secret];
            updatedSecret[index] = yaml.load(value); 
            clearTimeout(timeout);
            timeout = setTimeout(() => setSecret(updatedSecret), 500);
        }
    };

    useEffect(() => {
        if (props.environmentDetails) {
            const completeConfig = props.environmentDetails.operator_payload;
            if (completeConfig) {
                const mainConfigFilter = completeConfig && completeConfig.filter((el) => el.kind !== "Secret" && el.kind !== "ConfigMap");
                const secretConfigFilter = completeConfig && completeConfig.filter((el) => el.kind === "Secret" || el.kind === "ConfigMap");
                setMainConfig(mainConfigFilter && mainConfigFilter.length > 0 ? mainConfigFilter[0] : null);
                setSecret(secretConfigFilter && secretConfigFilter.length > 0 ? [...secretConfigFilter] : [])
            }
        }
    },[props.environmentDetails])

    return (
        <div>
             <Prompt
                    when={isBlocking}
                    message= {t("Operators.OperatorCatalog.errorMessage")}
             />
            <Typography variant="h4" color="primary" align="center" className= "m-b-20" > 
                {`${t("Operators.OperatorCatalog.updateEnv")} - ${props.environmentDetails?.name} (${props.environmentDetails?.application?.operator_package_name })` }
            </Typography>
            {
                props.environmentDetails &&
                <OperatorInstanceUpdateForm
                    operatorDetails = {props.operatorDetails}
                    // yamlValues= {props.environmentDetails.operator_payload}
                    yamlValues= {mainConfig}
                    updateValues={updateValues}
                />
            }      

            <Grid item>
                <Grid container spacing={3}>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleUpdateConfig("secret")}
                            disabled={secret.length > 9}
                        >
                            Add Secret
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleUpdateConfig("config")}
                            disabled={secret.length > 9}
                        >
                            Add Config
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item>
                <Grid container spacing={3}>
                {
                    secret && secret.length > 0 && secret.map((config, index)=>(
                        
                            <Grid item xs={12} md={4} key={index}>
                                <div className={classes.secret}>
                                <CancelIcon
                                color="secondary"
                                fontSize="medium"
                                className={classes.crossIcon}
                                onClick={()=>handleCancelSecret(index)}
                                />
                                <AceEditor
                                    data-test="editor-container"
                                    mode="yaml"
                                    theme={"xcode"}
                                    width="100%"
                                    height="300px"
                                    onChange={(value) => onChange(value, index)}
                                    setOptions={{ showPrintMargin: false }}
                                    editorProps={{ $blockScrolling: Infinity }}
                                    value={yaml.dump(config)}
                                    className="editor"
                                    fontSize="15px"
                                />
                                </div>
                            </Grid>
                    ))
                }
                </Grid>
            </Grid>
            
            <div className = "alignCenter m-t-20">
                <Button 
                 data-test="upgrade-env-button"
                 variant="contained"
                 color="primary"
                 onClick={() => redeployEnvironment()}
                 disabled={yaml.dump(props.environmentDetails.operator_payload) === newValues ? true : false}
                 // disabled={isErrors()}
                >
                 {t("Operators.OperatorCatalog.redeployEnv")}
                </Button>
            </div>

            {props.updatingEnv && (
                <BackdropLoader message={t("Operators.OperatorCatalog.updating")} />
            )}
        </div>
    )
}

/* istanbul ignore next */
const mapStateToProps = (state) => ({
    environmentDetails: state.EnvironmentReducer.environmentDetails,
    operatorDetails: state.OperatorReducer.operatorDetails,
    updatingEnv: state.EnvironmentReducer.updatingEnv,
  })
  
  /* istanbul ignore next */
  const mapDispatchToProps = dispatch => ({
    fetchEnvDetails : (payload) => dispatch(fetchEnvDetails(payload)),
    fetchOperatorDetails: ({packageName}) => dispatch(fetchOperatorDetails({packageName})),
    updateEnvironment : (id, jsonBody,source, changeHash, history) => dispatch(updateEnvironment(id, jsonBody, source, changeHash, history)),

})

export default connect(mapStateToProps, mapDispatchToProps)(EditOperatorEnvironment)
