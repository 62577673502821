import { isEmpty, isNumber } from "lodash";
import React, { useEffect, useState } from "react";
import { TextField } from '@material-ui/core';
//import { IBasicFormParam } from "shared/types";

// export interface IStringParamProps {
//   id: string;
//   label: string;
//   inputType?: string;
//   param: IBasicFormParam;
//   handleBasicFormParamChange: (
//     param: IBasicFormParam,
//   ) => (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
// }

export function TextParam({ id, param, label, inputType, handleBasicFormParamChange }) {
  const [value, setValue] = useState((param.value || ""));
  const [valueModified, setValueModified] = useState(false);
  
  const [timeout, setThisTimeout] = useState({});
  const onChange = (
    e
  ) => {
    setValue(e.currentTarget.value);
    setValueModified(true);
    // Gather changes before submitting
    clearTimeout(timeout);
    const func = handleBasicFormParamChange(param);
    // The reference to target get lost, so we need to keep a copy
    const targetCopy = {
      currentTarget: {
        value: e.currentTarget.value,
        type: e.currentTarget.type,
      },
    };
    setThisTimeout(setTimeout(() => func(targetCopy), 500));
  };

  useEffect(() => {
    if ((isNumber(param.value) || !isEmpty(param.value)) && !valueModified) {
      setValue(param.value);
    }
  }, [valueModified, param.value]);

  let input = (
    <TextField
      id={id}
      onChange={onChange}
      value={value}
      type={inputType ? inputType : "text"}
      //className="clr-input deployment-form-text-input"
      variant="outlined"
      //error={errors.firstName && touched.firstName}
      label={label}
      //name="firstName"
      fullWidth
      color='primary'
      //onBlur={ handleBlur }
      //helperText={ (errors.firstName && touched.firstName) && errors.userName }
      helperText={param.description}
      margin="normal"
    />
  );
  if (inputType === "textarea") {
    input = <textarea id={id} onChange={onChange} value={value} data-test="textarea" />;
  } else if (param.enum !== null && param?.enum?.length > 0) {
    input = (
      <select data-test="select" id={id} onBlur={()=> {}} onChange={handleBasicFormParamChange(param)} value={param.value}>
        {param.enum.map(enumValue => (
          <option data-test="option" key={enumValue}>{enumValue}</option>
        ))}
      </select>
    );
  }
  return (
   
    <>
      {/* <Grid item sm={4} xs={12}> */}
    
    {/* <label
        htmlFor={id}
        className="centered deployment-form-label deployment-form-label-text-param"
      >
        {label}
      </label> */}
    
      {input}
      {/* {param.description && <span className="description">{param.description}</span>} */}
   
       {/* </Grid> */}
    </>
  );
}

export default TextParam;
