import { Button, Card, CardContent, Grid } from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { Dispatch } from "redux";
import { History } from "history";
import { loginSso } from "./redux/actions";
import paths from "../../constants/paths";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles(() => ({
  otpInput: {
    width: "60px",
    height: "40px",
    fontSize: "25px",
  },
  navLogo: {
    marginTop: 10,
    marginBottom: 20,
    height: 100,
    width: 100,
    borderRadius: 55,
    objectFit: "cover",
    backgroundColor: "#1826ff",
    padding: "7px",
  },
  alert: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "20px",
  },
  cardWidth:{
    width: "450px"
  },
  cardPadding:{
    padding: "20px"
  }
}));

interface Props extends PropsFromRedux {
  history: History;
}

export function LoginSSO(props: Props) {
  const [otp, setOtp] = useState("");
  const [active, setActivate] = useState(false);

  const classes = useStyles();

  const ssoCallBack = () => {
    setActivate(true);
  };

  const handleSubmitOtp = () => {
    const payload = {
      code: otp.toString(),
    };
    props.loginSso(payload, props.history, ssoCallBack);
  };

  const handleHome = () => {
    props.history.push(paths.PROJECTLIST);
  };

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item>
        <img
          alt="Logo"
          width="50"
          className={classes.navLogo}
          src="/images/logos/logo-nav.svg"
        />
      </Grid>
      <Grid item>
        <Card elevation={6} className={classes.cardWidth}>
          <CardContent className={classes.cardPadding}>
            <Grid container spacing={3} direction="column" alignItems="center">
              <Grid item>
                <h2>Device Activation</h2>
              </Grid>

              {active && (
                <Grid item>
                  <Alert severity="success" className={classes.alert}>
                    Device Activation Success!
                  </Alert>
                </Grid>
              )}
              {!active && (
                <Grid item>
                  <h3>Enter the code displayed on your device</h3>
                </Grid>
              )}
              {!active && (
                <Grid item style={{ alignContent: "center" }}>
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => (
                      <input {...props} className={classes.otpInput} />
                    )}
                  />
                </Grid>
              )}

              <Grid item className="w-100">
                {active ? (
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => handleHome()}
                  >
                    Home
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={Boolean(otp.length < 6)}
                    onClick={() => handleSubmitOtp()}
                  >
                    Continue
                  </Button>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

/* istanbul-ignore-next */
const mapStateToProps = (state: any) => ({
  // projectList: state.ProjectListReducer.projectList,
});

/* istanbul-ignore-next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    loginSso: (payload: any, history: History, callBack: () => void) =>
      dispatch(loginSso(payload, history, callBack)),
  };
};

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(LoginSSO);
