const HEADER_PREFIX = '@HEADER';

export const FETCH_SEARCH_RESULT = `${ HEADER_PREFIX }/FETCH_SEARCH_RESULT`
export const FETCH_SEARCH_RESULT_SUCCESS = `${ HEADER_PREFIX }/FETCH_SEARCH_RESULT_SUCCESS`

const fetchSearchResult = (keyword) => ({
	type: FETCH_SEARCH_RESULT,
	data: { keyword }
})

export {
	fetchSearchResult
}