import React from "react";
import {
  Card,
  CardHeader,
  Grid,
  Typography,
  Collapse,
  Divider,
  Link,
  Button,
  IconButton,
} from "@material-ui/core";
import { connect } from "react-redux";
import Skeleton from '@material-ui/lab/Skeleton';
import{ SkeletonTheme } from "react-loading-skeleton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { getDateInStandardFormat, setHttpInUrl } from "../../helpers/utils";
import { useTranslation } from "react-i18next";
import "./OperatorCollapsibleCard.css";

// types
import { OperatorClass } from "../../models/Operator.model";
import { ConnectedProps } from "react-redux";

interface Props extends PropsFromRedux {
  operator: OperatorClass;
  handleOperatorDeploy: (Operator: OperatorClass) => void;
  handleCardExpand: (
    packageName: string,
    isOperationDetailsFetched: boolean,
    listIndex: number
  ) => void;
  activePackageName: string|null;

  listIndex: number;
}

export const OperatorCollapsibleCard = (props: Props) => {
  const {
    operator,
    handleOperatorDeploy,
    handleCardExpand,
    activePackageName,
    fetchingOperatorDetails,
    operatorDetailsList,
    listIndex,
  } = props;

  const [t] = useTranslation();

  const selectedOperator = operatorDetailsList.find(
    (op: OperatorClass) => op.packageName === operator.packageName
  );

  return (
    <Card
      className="m-t-10 operator-collapsible-card-container"
      data-test="operator-card"
    >
      <CardHeader
        className="collapsible-card-header"
        title={
          <Grid container alignItems="center">
            <Grid item xs={11}>
              <Grid container alignItems="center">
                <Grid item xs={2}>
                  <img
                    src={operator.thumbUrl}
                    alt={operator.displayName}
                    className="operator-icon"
                    data-test="operator-icon"
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography
                    variant="h6"
                    color="textPrimary"
                    data-test="operator-title"
                  >
                    {operator.displayName}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                className={`operator-expand ${
                  activePackageName === operator.packageName ? "expanded" : ""
                }`}
                data-test="expand-card-button"
              >
                <ExpandMoreIcon />
              </IconButton>
            </Grid>
          </Grid>
        }
        onClick={() =>
          handleCardExpand(
            operator.packageName,
            Boolean(selectedOperator),
            listIndex
          )
        }
        data-test="card-header"
      />
      <Collapse
        in={activePackageName === operator.packageName}
        className="operator-collapse"
      >
        <Divider />
        {!fetchingOperatorDetails && selectedOperator ? (
          <div
            className="operator-collapse-body"
            data-test="operator-card-body"
          >
            <div className="operator-info-container">
              {selectedOperator.version && (
                <Grid container className="m-t-5">
                  <Grid item xs={4} className="operator-info-label">
                    {t("Operators.OperatorCatalog.version")}
                  </Grid>
                  <Grid item xs={8}>
                    {selectedOperator.version}
                  </Grid>
                </Grid>
              )}
              {selectedOperator.repository && (
                <Grid container className="m-t-5">
                  <Grid item xs={4} className="operator-info-label">
                    {t("Operators.OperatorCatalog.repository")}
                  </Grid>
                  <Grid item xs={8}>
                    <Link
                      href={setHttpInUrl(selectedOperator.repository)}
                      underline="hover"
                      target="_blank"
                      rel="noopener"
                    >
                      {selectedOperator.repository}
                    </Link>
                  </Grid>
                </Grid>
              )}
              {selectedOperator.provider && (
                <Grid container className="m-t-5">
                  <Grid item xs={4} className="operator-info-label">
                    {t("Operators.OperatorCatalog.provider")}
                  </Grid>
                  <Grid item xs={8}>
                    {selectedOperator.provider}
                  </Grid>
                </Grid>
              )}
              {selectedOperator.containerImage && (
                <Grid container className="m-t-5">
                  <Grid item xs={4} className="operator-info-label">
                    {t("Operators.OperatorCatalog.containerImage")}
                  </Grid>
                  <Grid item xs={8} style={{ overflowWrap: "break-word" }}>
                    {selectedOperator.containerImage}
                  </Grid>
                </Grid>
              )}
              {selectedOperator.createdAt ? (
                <Grid
                  container
                  className="m-t-5"
                  data-test="operator-createdAt"
                >
                  <Grid item xs={4} className="operator-info-label">
                    {t("Operators.OperatorCatalog.createdAt")}
                  </Grid>
                  <Grid item xs={8}>
                    {getDateInStandardFormat(selectedOperator.createdAt)}
                  </Grid>
                </Grid>
              ) : null}
            </div>
            <div className="alignRight m-t-10">
              <Button
                onClick={() => handleOperatorDeploy(selectedOperator)}
                color="primary"
                variant="contained"
                size="small"
                data-test="deploy-button"
              >
                {t("Operators.AddOperatorPopup.deploy")}
              </Button>
            </div>
          </div>
        ) : (
          <div
            className="operator-collapse-body"
            data-test="operator-card-skeleton"
          >
            <SkeletonTheme height={200}>
              <div className="operator-info-container">
                {[0, 1, 2, 3, 4].map((idx) => (
                  <Grid container spacing={1} className="m-t-5" key={idx}>
                    <Grid item xs={4} className="operator-info-label">
                      <Skeleton animation="wave" height={15} />
                    </Grid>
                    <Grid item xs={8}>
                      <Skeleton animation="wave" height={15} />
                    </Grid>
                  </Grid>
                ))}
              </div>
              <div className="alignRight m-t-10">
                <Skeleton animation="wave" height={30} width="120px" />
              </div>
            </SkeletonTheme>
          </div>
        )}
      </Collapse>
    </Card>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state:any) => ({
  fetchingOperatorDetails: state.OperatorReducer.fetchingOperatorDetails,
  operatorDetailsList: state.OperatorReducer.operatorDetailsList,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(OperatorCollapsibleCard);
