import { Card, CardContent, Grid, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { LabelHandler } from "../../components/labelHandler/LabelHandler";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import RestoreOutlinedIcon from '@material-ui/icons/RestoreOutlined';
import AccessTimeOutlinedIcon from "@material-ui/icons/AccessTimeOutlined";
import { getDateInStandardFormat, getDiffDays, gitDiffBtnScanDate } from "../../helpers/utils";

interface Props  {
    openDeatils: (reportId: number) => void;
    index: any;
    item: any;
}

const useStyles = makeStyles((theme: Theme) => ({
    cardPadding: {
        padding: "15px"
    },
}));


function ClusterscanList (props: Props) {
    const classes = useStyles();
    const { openDeatils, index, item } = props;

    const cardHandler = () => {
        openDeatils(item.id)
    }

    return(
        <div data-test="main-container">
            <Card
                key={index}  
                className="m-b-20"
                data-test="Cluster-scaned-list-container"
                style={{ cursor: "pointer" }}
                onClick={cardHandler}
            >
                <CardContent className={classes.cardPadding}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item md={3} xs={12}>
                                    <LabelHandler
                                        icon={item.status === "completed" ? 
                                            <CheckOutlinedIcon fontSize="medium" style={{ color: "#4CAF50" }} /> :
                                            item.status === "error" ?
                                            <CloseOutlinedIcon fontSize="medium" style={{ color: "#FF5252" }} /> :
                                            <CircularProgress size={18} style={{ color: "#FF9800" }} />}
                                        iconTooltip={item.status}
                                        label={item.name}
                                        labelTooltip={item.name}
                                        data-test="cluster-name"
                                    />
                                </Grid>
                                <Grid item md={2} xs={5}>
                                    <LabelHandler
                                        icon={<SettingsOutlinedIcon />}
                                        iconTooltip={item.plugin_name}
                                        label={item.plugin_name}
                                        labelTooltip={item.plugin_name}
                                        data-test="Trivy"
                                    />
                                </Grid>
                                <Grid item md={2} xs={7}>
                                    <LabelHandler
                                        icon={<RestoreOutlinedIcon />}
                                        iconTooltip={item.time_taken + " sec"}
                                        label={gitDiffBtnScanDate(item.time_taken)}
                                        labelTooltip={item.time_taken + " sec"}
                                        data-test="time-taken"
                                    />
                                </Grid>
                                <Grid item md={2} xs={5}>
                                    <LabelHandler
                                        icon={<AccessTimeOutlinedIcon />}
                                        iconTooltip={getDateInStandardFormat(item.createdAt)}
                                        label={getDiffDays(item.createdAt, true)}
                                        labelTooltip={getDateInStandardFormat(item.createdAt)}
                                        data-test="date"
                                    />
                                </Grid>
                                <Grid item md={3} xs={7}>
                                    <LabelHandler
                                        icon={<ReportProblemOutlinedIcon color="error" />}
                                        iconTooltip={item.vulnerability_count}
                                        label={`Vulnerability Count(${item.vulnerability_count})`}
                                        labelTooltip={item.vulnerability_count}
                                        data-test="vulnerability-count"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    )
}

export default ClusterscanList;