import React from 'react';
import { CircularProgress } from '@material-ui/core';
import AdjustIcon from '@material-ui/icons/Adjust';
import Slide from '@material-ui/core/Slide';
import { AppConstants } from '../constants/appconstants';

import { TransitionProps } from '@material-ui/core/transitions';

export const Transition = React.forwardRef(function Transition(
  tprops: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...tprops} />;
});

export const getProgressIcon = (
  state: string,
  statusColor: string,
  size?: number
): JSX.Element => {
  switch (state) {
    case AppConstants.EnvironmentStatus.Running:
    case AppConstants.EnvironmentStatus.Stopped:
    case AppConstants.EnvironmentStatus.Succeeded:
    case AppConstants.EnvironmentStatus.Failed:
      return <AdjustIcon className="topIcon" style={{ color: statusColor }} />;

    default:
      return (
        <CircularProgress
          size={size || 15}
          thickness={5}
          style={{ color: statusColor, marginRight: 10 }}
        />
      );
  }
};

export function VersionRibbon() {
  return <div className="ribbon ribbon-top-right"><span>ßETA</span></div>
} 