const initialState = {
    usersListInProject : [],
    usersListInEnvironment : [],
    usersListInHelmEnvironment : [],
    rolesData : [],
    addingUser:false,
    editingUser:false,
    deletingUser: false,
    fetchingUserList: false,
}

export default initialState
