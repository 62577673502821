import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  InputAdornment
} from "@material-ui/core";

import React, { useEffect, useState } from "react";
import MuiNumberCounter from "../textfield/MuiNumberCounter";
import MuiTextField from "../textfield/MuiTextField";
import { useTranslation } from 'react-i18next';
import _ from 'lodash'

type Props = {
  vertical_pod_autoscaler: any;
  handleUpdateVpa: (updatedState: any) => void;
};
function allValuesAreFalsy(obj:any) {
  return _.every(obj, value => !value);
}


function VPA(props: Props) {
  const [t] = useTranslation();
  const [vpa, setVpa] = useState(
    props.vertical_pod_autoscaler && !allValuesAreFalsy(props.vertical_pod_autoscaler) ? 
    props.vertical_pod_autoscaler :
    {
    update_mode: "Auto",
    min_allowed_cpu: "100",
    min_allowed_memory: "50",
    max_allowed_cpu: "1000",
    max_allowed_memory: "500",
    controlled_values: "RequestsAndLimits",
  });

  useEffect(() => {
    props.handleUpdateVpa(vpa);
  }, [vpa]);

  const handleChangeVpa = (
    e: React.ChangeEvent<{ value: unknown }>,
    type: string
  ) => {
    setVpa({
      ...vpa,
      [type]: e.target.value,
    });
  };

  const getResourceMsgErrors=(max:string,min:string,maxprop:string,minProp:string)=>{
    if(parseInt(min)<=0) return `${minProp} and ${maxprop} must be greater than 0`
    if(parseInt(max)<=0) return `${minProp} and ${maxprop} must be greater than 0`
    if(parseInt(max)<parseInt(min)) return `${maxprop} must be greater than ${minProp}`
    return ""


  }
  return (
    <>
      <Card>
        <CardHeader title={`${t("HPA.options")} - Vertical`} data-test="options-header" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Grid
                container
                spacing={2}
                alignItems="center"
                className="m-b-10"
              >
                <Grid item md={4} xs={4}>
                  <label>Update Mode</label>
                </Grid>
                <Grid item md={6} xs={8}>
                  <FormControl variant="outlined" className="w-100">
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      name="deletion_policy"
                      value={vpa.update_mode}
                      onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                        handleChangeVpa(e, "update_mode")
                      }
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                      }}
                    >
                      <MenuItem value="Off">Off</MenuItem>
                      <MenuItem value="Initial">Initial</MenuItem>
                      <MenuItem value="Recreate">Recreate</MenuItem>
                      <MenuItem value="Auto">Auto</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                alignItems="center"
                className="m-b-10"
              >
                <Grid item md={4} xs={4}>
                  <label>Min Allowed CPU</label>
                </Grid>
                <Grid item md={6} xs={8}>
                  <MuiTextField
                    value={vpa.min_allowed_cpu}
                    error={!!getResourceMsgErrors(vpa.max_allowed_cpu,vpa.min_allowed_cpu,"Max Allowed CPU", "Min Allowed CPU")}
                    helperText={getResourceMsgErrors(vpa.max_allowed_cpu,vpa.min_allowed_cpu,"Max Allowed CPU", "Min Allowed CPU")
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          m
                        </InputAdornment>
                      ),
                    }}
                    //customClassName="oneRemMarginBottomSeperator"
                    onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                      handleChangeVpa(e, "min_allowed_cpu")
                    }
                    type="number"
                  />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                alignItems="center"
                className="m-b-10"
              >
                <Grid item md={4} xs={4}>
                  <label>Min Allowed Memory</label>
                </Grid>
                <Grid item md={6} xs={8}>
                  <MuiTextField
                    value={vpa.min_allowed_memory}
                    error={!!getResourceMsgErrors(vpa.max_allowed_memory,vpa.min_allowed_memory,"Max Allowed Memory", "Min Allowed Memory")}
                    helperText={getResourceMsgErrors(vpa.max_allowed_memory,vpa.min_allowed_memory,"Max Allowed Memory", "Min Allowed Memory")
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                       Mi
                        </InputAdornment>
                      ),
                    }}
                    //customClassName="oneRemMarginBottomSeperator"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChangeVpa(e, "min_allowed_memory")
                    }
                    type="number"
                  />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                alignItems="center"
                className="m-b-10"
              >
                <Grid item md={4} xs={4}>
                  <label>Max Allowed CPU</label>
                </Grid>
                <Grid item md={6} xs={8}>
                  <MuiTextField
                    value={vpa.max_allowed_cpu}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChangeVpa(e, "max_allowed_cpu")
                    }
                    error={!!getResourceMsgErrors(vpa.max_allowed_cpu,vpa.min_allowed_cpu,"Max Allowed CPU", "Min Allowed CPU")}
                    helperText={
                      getResourceMsgErrors(vpa.max_allowed_cpu,vpa.min_allowed_cpu,"Max Allowed CPU", "Min Allowed CPU")
                    }
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                         m
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                alignItems="center"
                className="m-b-10"
              >
                <Grid item md={4} xs={4}>
                  <label>Max Allowed Memory</label>
                </Grid>
                <Grid item md={6} xs={8}>
                  <MuiTextField
                    value={vpa.max_allowed_memory}
                    error={!!getResourceMsgErrors(vpa.max_allowed_memory,vpa.min_allowed_memory,"Max Allowed Memory", "Min Allowed Memory")}
                    helperText={
                   getResourceMsgErrors(vpa.max_allowed_memory,vpa.min_allowed_memory,"Max Allowed Memory", "Min Allowed Memory")
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChangeVpa(e, "max_allowed_memory")
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          Mi
                        </InputAdornment>
                      ),
                    }}
                    type="number"
                  />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                alignItems="center"
                className="m-b-10"
              >
                <Grid item md={4} xs={4}>
                  <label>Controlled Values</label>
                </Grid>
                <Grid item md={6} xs={8}>
                  <FormControl variant="outlined" className="w-100">
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      name="deletion_policy"
                      value={vpa.controlled_values}
                      onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                        handleChangeVpa(e, "controlled_values")
                      }
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                      }}
                    >
                      <MenuItem value="RequestsAndLimits">
                        RequestsAndLimits
                      </MenuItem>
                      <MenuItem value="RequestsOnly">RequestsOnly</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

export default VPA;
