import React, { Component } from 'react';
import { Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Button, }
from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import { deleteUsersInProject, deleteUsersInEnvironment, deleteUsersInHelmEnvironment } from '../adduserpopup/redux/actions';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { withTranslation, WithTranslation } from 'react-i18next';
import { Transition } from "../../helpers/utils.ext";
import { Dispatch } from "redux";
import { History } from 'history';
import { ProjectModel } from '../../models/Project.model';
import { EnvironmentModel } from '../../models/Environment.model';

type Payload = {
    environment_id?: number,
    project_id?: number,
    user_id: number
}

interface Props extends WithTranslation, PropsFromRedux {
    environmentDetailsComponent?: boolean,
    projectDetails: ProjectModel,
    userId: number,
    isLeave?: boolean,
    history?: History,
    environmentDetails?: EnvironmentModel,
    helm?: boolean,
    handleClose?: () => void,
    open: number
}

type State = {
    allUsers: any,
    isPopupOpened: boolean,
    id: number
}

export class Popup extends Component<Props, State> {
    constructor(props: Props){
        super(props);
        this.state = {
            allUsers : [],
            isPopupOpened : false,
            id : 0
        }
    }

    handleDeleteUser = () => {
        if(this.props.environmentDetailsComponent){
            this.deletUserInEnvironmentHandler();
        }else{
            this.deleteUserInProjectHandler();
        }
    }

    deleteUserInProjectHandler = () => {
        const payload = {
            project_id : this.props.projectDetails?this.props.projectDetails.id:0,
            user_id : this.props.userId
        }
        // if(this.props.deleteGroup){
        //     payload.group_id = this.props.userId
        // } else{
        //     payload.user_id = this.props.userId
        // }
        this.setState({
            isPopupOpened : false
        })
        this.props.deleteUsersInProjectAction(payload, this.props.isLeave, this.props.history)
    }

    deletUserInEnvironmentHandler = () => {
        const payload = {
            environment_id : this.props.environmentDetails?this.props.environmentDetails.id:0,
            user_id : this.props.userId
        }
        this.setState({
            isPopupOpened : false
        })
        if(this.props.helm){
            this.props.deleteUsersInHelmEnvironmentAction(payload, this.props.isLeave, this.props.history)
        }else{
            this.props.deleteUsersInEnvironmentAction(payload, this.props.isLeave, this.props.history)
        }
    }

    UNSAFE_componentWillReceiveProps = (newProps: Props) => {
        if(this.state.id !== newProps.open){
            this.setState({
                isPopupOpened : true,
                id: newProps.open
            })
        }
    }

    handleClose = () => {
        this.setState({
            isPopupOpened : false
        })
        if(this.props.handleClose){
            this.props.handleClose()
        }
    }

    render(){
        const {t} = this.props;
        return(
            <Dialog
              open={ this.state.isPopupOpened }
              TransitionComponent={ Transition }
              keepMounted
              onClose={(_, reason) => {
                if (reason !== 'backdropClick') {
                    this.handleClose()
                }}
				}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
              data-test="main-container"
            >
                <DialogTitle>
                    <Typography className="dialogtitle">
                        <b> {t('Sure')} </b>
                    </Typography>

                    <IconButton
                      aria-label="close"
                      size="small"
                      className="right"
                      onClick={this.handleClose}
                      data-test="close-btn"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography variant='subtitle1' data-test="dialog-content"> {this.props.isLeave ? t('Leave') : t('Remove')}</Typography>
                </DialogContent>
                <DialogActions>
                    {/* <Button color="secondary" onClick={ this.handleClose }>
                        No
                    </Button> */}
                    <Button onClick={ this.handleDeleteUser } color="primary" variant="contained" data-test="action-btn">
                        {this.props.isLeave ? t('LeaveLabel') : t('RemoveLabel') }
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
    projectDetails: state.ProjectReducer.projectDetails,
    // environmentDetails: state.EnvironmentReducer.environmentDetails
})

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
    return {
        deleteUsersInProjectAction : (payload: Payload, isLeave: boolean|undefined, history: History|undefined) => dispatch(deleteUsersInProject(payload, isLeave, history)),
        deleteUsersInEnvironmentAction : (payload: Payload, isLeave: boolean|undefined, history: History|undefined) => dispatch(deleteUsersInEnvironment(payload, isLeave, history)),
        deleteUsersInHelmEnvironmentAction : (payload: Payload, isLeave: boolean|undefined, history: History|undefined) => dispatch(deleteUsersInHelmEnvironment(payload, isLeave, history)),
    }
}

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withTranslation()(Popup));