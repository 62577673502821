import React, { useEffect } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  TextField, FormControl, InputAdornment, Radio, RadioGroup, FormControlLabel, Divider
} from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { useTranslation } from "react-i18next";
import {
  getGatewayData,
  getPaymentDetails,
  resetPaymentDetails,
} from "../../pages/account/billingtab/redux/actions";
import { makeStyles } from "@material-ui/core";
// import {
//   PayPalScriptProvider,
//   PayPalButtons,
//   FUNDING,
// } from "@paypal/react-paypal-js";
import { openWindowWithPost } from "../../helpers/utils";
import CloseIcon from "@material-ui/icons/Close";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import toast from "../toast/Toast";

import { createStyles } from "@material-ui/core";
import { GateWayType } from "./../../models/Account.model";
import { Theme } from "@material-ui/core";
import { UserInvoice } from "../../models/Billing.model";
/* istanbul ignore next */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    payuLogo: {
      width: "4em",
      marginLeft: 10,
      filter: "contrast(1.85)",
    },
    paypalLogo: {
      width: "6em",
      marginLeft: 10,
      filter: "contrast(1)",
    },
    payuLogoDisabled: {
      filter: "contrast(0.1) grayscale(1)",
    },
    paypalLogoDisabled: {
      filter: "contrast(0.3) grayscale(1)",
    },
    payuBtn: {
      width: "100%",
      height: "3.5em",
      background: "#28a745",
      fontFamily: "sans-serif",
      textTransform: "capitalize",
      "&:hover": {
        background: "#1a7b31",
      },
    },
    paypalBtn: {
      width: "100%",
      height: "3.5em",
      background: "#ffc439",
      color: "#2c2e2f",
      fontFamily: "sans-serif",
      textTransform: "capitalize",
      "&:hover": {
        background: "#dc9e0a",
      },
    },
    buttonGrid: {
      margin: 6
    },
    detailSpan: {
      color: "#43425d",
      fontWeight: 400,
    },
    address: {
      fontStyle: "italic",
      color: "#43425d",
    },
    addressGrid: {
      paddingLeft: 10,
    },
    detailGrid: {
      display: "flex",
      flexDirection: "column",
      // height: 180,
      justifyContent: "space-between",
      width: '100%',
      gap: theme.spacing(1)
    },
    amountSpan: {
      fontSize: 24,
    },
    spinner: {
      marginRight: 10,
      animationDuration: "500",
    },
    btnLoading: {
      filter: "contrast(0.5) grayscale(1)",
    },
    margin: {
      margin: theme.spacing(1),
    },
    paymentContainer: { display: 'flex', justifyContent: 'flex-start' },
    paymentBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    paymentBtn: {
      // boxShadow: "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
      width: "150px",
      height: "50px",
      transition: 'opacity 0.5s ease-in-out',
      boxShadow: "1px 1px 2px rgba(0, 0, 0, 0.2)",
      border: "1px solid #aaa",
    },
    disabledPaymentBtn:{
      // boxShadow: "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
      width: "150px",
      height: "50px",
      border: "1px solid #eee",
      filter: 'grayscale(100%)',
      '-webkit-filter': 'grayscale(100%)',
      opacity: 0.5,
      transition: 'opacity 0.5s ease-in-out',
      boxShadow: "1px 1px 2px rgba(0, 0, 0, 0.2)",
    },
    PayWith: {
      margin: 10, textAlign:'center'
    }
  })
);

const useMenuStyles = makeStyles((theme: Theme) =>
  createStyles({
    RadioGrp: { marginLeft: theme.spacing(4) },
    textFieldMargin: {
      marginLeft: 10
    },
    currency: {
      fontWeight: "bold"
    }

  }))  // 32 px left margin

interface MenuProps {
  amount: number;
  userInvoice: UserInvoice;
  setAmount: (amount: number | null) => void;
  setErrorMsg: (msg: string | null) => void
}
const PaymentMenu = (props: MenuProps): JSX.Element | null => {
  const classes = useMenuStyles()
  const [paymentAmt, setPaymentAmt] = React.useState<number | null>(null)
  const [paymentError, setPaymentError] = React.useState<string | null>(null)
  const [paymentOption, setPaymentOption] = React.useState<number | null>(null)
  const [inputKey, setInputKey] = React.useState<number>(0)
  useEffect(() => {
    if (paymentAmt === null || paymentAmt < 5) {
      let msg = "Amount must be greater than or equal to $5"
      setPaymentError(msg)
      props.setErrorMsg(msg)
    } else {
      let msg = null
      setPaymentError(msg)
      props.setErrorMsg(msg)
    }
  }, [paymentAmt, props.setErrorMsg])

  useEffect(() => {
    if (paymentOption !== null) {
      return
    }

    const { amount, userInvoice } = props;
    const currentUsage = userInvoice.total_cost
    setPaymentOption(0)
    if (amount >= 0) {
      let balance = amount

      if (balance - currentUsage >= 0) {
        setPaymentAmt(5)
        props.setAmount(5)

      } else {
        setPaymentAmt(currentUsage)
        props.setAmount(currentUsage)

      }
    } else {
      setPaymentAmt(Math.abs(amount))
      props.setAmount(Math.abs(amount))

    }

  }, [props.amount, props.userInvoice, props.setAmount, paymentOption])


  const handleChange = (balance: number, currentUsage: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputKey(inputKey + 1)
    let value = (event.target as HTMLInputElement).value;
    if (!value.length) {
      setPaymentAmt(5)
      props.setAmount(5)

    }

    if (event.target.name === "no-due") {

      if (currentUsage === Number(value)) {
        setPaymentAmt(Number(value))
        props.setAmount(Number(value))

        setPaymentOption(0)
        return
      }
      setPaymentOption(1)
    } else if (event.target.name === 'due-amount') {
      if (Math.abs(balance) === Number(value)) {
        setPaymentAmt(Number(value))
        props.setAmount(Number(value))
        setPaymentOption(0)
        return
      } else if (Math.abs(balance) + currentUsage === Number(value)) {
        setPaymentAmt(Number(value))
        props.setAmount(Number(value))
        setPaymentOption(1)
        return
      }
      setPaymentOption(2)
    }
  };

  const handleChangeInput = (option: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setPaymentOption(option);
      let value = (e.target as HTMLInputElement).value;
      if (!value.length) {
        setPaymentAmt(null);
        props.setAmount(null);
      }
      if (value.length > 6) return;
      setPaymentAmt(Number(parseFloat(value)));
      props.setAmount(Number(parseFloat(value)));
    };

  let { amount, userInvoice } = props;
  // amount=-300

  const currentUsage = userInvoice.total_cost
  if (amount >= 0) {
    const balance = amount
    if (balance - currentUsage >= 0) {
      return <FormControl component="fieldset">
        <RadioGroup className={classes.RadioGrp} aria-label="Payment Menu" name="no-due-custom">
          <FormControlLabel checked={paymentOption === 0} control={<Radio color='primary' />} label={<span className={classes.textFieldMargin}>
            <FormControl fullWidth variant="outlined">
              <TextField
                disabled={paymentOption !== 0}
                value={paymentOption !== 0 ? undefined : paymentAmt}
                error={!!paymentError && paymentOption === 0}
                key={inputKey}
                helperText={paymentError}
                label='custom amount'
                variant="outlined"
                type="number"
                id="outlined-adornment-amount"

                onChange={handleChangeInput(0)}
                inputProps={{ min: 5 , style: {
                  height: "1px",
                  fontWeight:"bold"
                }}}

                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      data-test="secret-key-visibility"
                    >
                      <AttachMoneyIcon fontSize='small' />
                    </InputAdornment>
                  )
                }}
              />
            </FormControl>
          </span>}
          />
        </RadioGroup>
      </FormControl>
    }
    else {
      return <FormControl component="fieldset">
        <RadioGroup className={classes.RadioGrp} aria-label="Payment Menu" name="no-due" onChange={handleChange(balance, currentUsage)}>

          <FormControlLabel checked={paymentOption === 0} value={currentUsage} control={<Radio color='primary' />} label={
            <Typography className={classes.textFieldMargin}>
              <span className={classes.currency}>${currentUsage}</span> - Pay current month usage amount
            </Typography>} />


          <FormControlLabel checked={paymentOption === 1} control={<Radio color='primary' />} label={
            <span className={classes.textFieldMargin}>
              <FormControl fullWidth variant="outlined">

                <TextField
                  value={paymentOption !== 1 ? undefined : paymentAmt}
                  error={!!paymentError && paymentOption === 1}
                  helperText={paymentError}
                  disabled={paymentOption !== 1}
                  type="number"
                  key={inputKey}
                  label="custom amount"
                  variant="outlined"
                  id="outlined-adornment-amount"
                  onChange={handleChangeInput(1)}
                  inputProps={{ min: 5 , style: {
                    height: "1px",
                    fontWeight:"bold"
                  }}}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        data-test="secret-key-visibility"
                      >
                        <AttachMoneyIcon fontSize='small' />
                      </InputAdornment>
                    )
                  }}
                />
              </FormControl>
            </span>}

          />
        </RadioGroup>

      </FormControl>
    }
  } else {

    const due = amount
    return <FormControl component="fieldset">
      <RadioGroup className={classes.RadioGrp} aria-label="Payment Menu" name="due-amount" onChange={handleChange(due, currentUsage)}>

        <FormControlLabel checked={paymentOption === 0} value={Math.abs(due)} control={<Radio color='primary' />} label={
          <Typography variant='subtitle1' className={classes.textFieldMargin}>
            <span className={classes.currency}>
              ${Math.abs(due)}
            </span> - Pay due amount
          </Typography>} />
        <FormControlLabel checked={paymentOption === 1} value={currentUsage + Math.abs(due)} control={<Radio color='primary' />} label={
          <Typography variant='subtitle1' className={classes.textFieldMargin}>
            <span className={classes.currency}>${Math.abs(due) + currentUsage}</span> - Pay due amount (${Math.abs(due)}) + current month usage amount (${currentUsage})
          </Typography>} />


        <FormControlLabel checked={paymentOption === 2} control={<Radio color='primary' />} label={
          <span className={classes.textFieldMargin}>
            <FormControl fullWidth variant="outlined">
              {/* <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel> */}
              <TextField
                value={paymentOption !== 2 ? undefined : paymentAmt}
                helperText={paymentError}
                disabled={paymentOption !== 2}
                type="number"
                key={inputKey}
                label='custom amount'
                variant="outlined"
                id="outlined-adornment-amount"
                error={!!paymentError && paymentOption === 2}
                onChange={handleChangeInput(2)}
                inputProps={{ min: 5 , style: {
                  height: "1px",
                  fontWeight:"bold"
                }}}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      data-test="secret-key-visibility"
                    >
                      <AttachMoneyIcon fontSize='small' />
                    </InputAdornment>
                  )
                }}
              />

            </FormControl>
          </span>}

        />
      </RadioGroup>

    </FormControl>

  }

}

interface Props extends PropsFromRedux {
  openPopup: boolean;
  handleClosePopup: () => void;
  invoiceId: number;
  amount: number;
  callback: () => void;
}

var win: Window | null;
var callback: null | (() => void) = null;

/* istanbul ignore next */
window.addEventListener("message", function (e) {
  if (e.origin === window.origin) {
    if (e.data === "Payment Successful") {
      if (callback) callback();
    } else if (e.data === "Close Window") {
      if (win) win.close();
    }
  }
});

export const PaymentPopup = (props: Props) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [paymentUrl, setPaymentUrl] = React.useState("");
  const [loadingIndex, setLoadingIndex] = React.useState(-1);
  const [payAmount, setPayAmount] = React.useState<number | null>(null)
  const [payError, setPayError] = React.useState<string | null>(null)

  useEffect(() => {
    props.getGatewayData();
    return () => {
      props.resetPaymentDetails();
    };
  }, []);

  var paymentObject = null;
  callback = props.callback;

  useEffect(() => {
    if (!props.paymentDetails?.amount) return;
    setLoadingIndex(-1);
    paymentObject = props.paymentDetails;
    win = openWindowWithPost(paymentUrl, paymentObject);

    if (win === null) {
      toast.warn("Please allow popup and try again");
    }
  }, [props.paymentDetails]);

  const handlePaymentClick = (code: string) => {

    let _gatewayIndex = props.gatewayData.findIndex(
      (g: GateWayType) => g.code === code
    );
    setPaymentUrl(props.gatewayData[_gatewayIndex].payment_url);
    setLoadingIndex(_gatewayIndex);
    if (!payAmount) return
    props.getPaymentDetails(code, payAmount);
  };

  // const gatewayData = (code: string) => {
  //   switch (code) {
  //     case "payu":
  //       return {
  //         buttonClass: classes.payuBtn,
  //         imageClass: classes.payuLogo,
  //         label: "PayU",
  //         logoSrc: "/images/logos/PAYU_LOGO_LIME.png",
  //       };
  //     case "paypal":
  //       return {
  //         buttonClass: classes.paypalBtn,
  //         imageClass: classes.paypalLogo,
  //         label: "PayPal",
  //         logoSrc: "/images/logos/paypal-logo-svg.png",
  //       };
  //     default:
  //       return {
  //         buttonClass: "",
  //         imageClass: "",
  //         label: "",
  //         logoSrc: "",
  //       };
  //   }
  // };

  const setAmount = (amount: number | null) => {
    setPayAmount(amount)
  }

  const setErrorMsg = (amount: string | null) => {
    setPayError(amount)
  }

  return (
    <Dialog
      data-test="main-container"
      open={props.openPopup}
      onClose={props.handleClosePopup}
    >
      <DialogTitle>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography className="dialogtitle" variant="h5">
            {t("Billing.PaymentPopup.makePayment")}
          </Typography>
          <IconButton
            aria-label="close"
            size="small"
            className="right"
            onClick={props.handleClosePopup}
            data-test="close-icon"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent>
        {props.gatewayData.length <= 0 ? (
          <Grid container className={classes.buttonGrid} justify="center">
            <Typography variant="h4" style={{ display: "flex" }}>
              <CircularProgress
                size={25}
                thickness={5}
                className={classes.spinner}
              />{" "}
              Hold on... fetching Payment gateways
            </Typography>
          </Grid>
        ) : (
          <Grid container>
            <Grid className={classes.detailGrid}>
              <Typography variant="h5" color="primary">
                Name:{" "}
                <span className={classes.detailSpan} data-test="name">
                  {`${props.userDetails?.first_name} ${props.userDetails?.last_name}`}
                </span>
              </Typography>
              <Typography variant="h5" color="primary">
                Email:{" "}
                <span className={classes.detailSpan} data-test="email">
                  {props.userDetails?.email}
                </span>
              </Typography>
              <Typography variant="h5" color="primary">
                Billing Address
              </Typography>
              <Grid className={classes.addressGrid}>
                <Typography
                  className={classes.address}
                  data-test="street-address"
                >
                  {props.paymentSetting?.street}
                </Typography>
                <Typography
                  className={classes.address}
                  data-test="city-state-address"
                >
                  {props.paymentSetting?.city}, {props.paymentSetting?.state} -{" "}
                  {props.paymentSetting?.postal_code}
                </Typography>
                <Typography
                  className={classes.address}
                  data-test="country-address"
                >
                  {props.paymentSetting?.country}
                </Typography>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Typography variant="h5" color="primary">
                    Amount:{" "}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <div className={classes.paymentContainer}>
                    <PaymentMenu
                      amount={props.amount}
                      userInvoice={props.userInvoice}
                      setAmount={setAmount}
                      setErrorMsg={setErrorMsg}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid container alignItems="center">
              <Grid item sm={5}>
                <Divider />
              </Grid>
              <Grid item sm={2}>
                <Typography
                  variant="h5"
                  color="primary"
                  className={classes.PayWith}
                >
                  Pay with
                </Typography>
              </Grid>
              <Grid item sm={5}>
                <Divider />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              className={classes.buttonGrid}
              justifyContent="space-evenly"
            >
              {props?.gatewayData.length > 0 &&
                props?.gatewayData.map(
                  (gateway: GateWayType, index: number) => {
                    if (!gateway.active) return <></>;
                    let _isLoading =
                      props.fetchingPaymentDetails && index === loadingIndex;
                    return (
                      <>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          key={index}
                          className={classes.paymentBox}
                        >
                          <Button
                            className={
                              _isLoading || payError || !payAmount
                                ? classes.disabledPaymentBtn
                                : classes.paymentBtn
                            }
                            onClick={() => handlePaymentClick(gateway.code)}
                            disabled={_isLoading || payError || !payAmount}
                            data-test="gateway-btn"
                          
                          >
                            <img
                              src={gateway.icon}
                              alt={gateway.code}
                              // width={130}
                              height={40}
                              object-fit="cover"
                              data-test="gateway-logo"
                            />
                          </Button>
                        </Grid>
                      </>
                    );
                  }
                )}
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};


/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  userDetails: state.AuthReducer.user,
  paymentDetails: state.PaymentReducer.paymentDetails,
  fetchingPaymentDetails: state.PaymentReducer.fetchingPaymentDetails,
  paymentSetting: state.PaymentReducer.paymentSetting,
  gatewayData: state.PaymentReducer.gatewayData,
  userInvoice: state.PaymentReducer.userInvoice,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    getPaymentDetails: (code: string, amt: number) =>
      dispatch(getPaymentDetails(code, amt)),
    resetPaymentDetails: () => dispatch(resetPaymentDetails()),
    getGatewayData: () => dispatch(getGatewayData()),
  };
};



const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(PaymentPopup);
