import React from "react";
import {
  CardHeader,
  Typography,
  Card,
  Divider,
} from "@material-ui/core";
import { Quotas } from "../../models/Account.model";
interface Props {
  quotaInfo: Quotas;
}
export const QuotaInfo = (props: Props) => {
  return (
    <Card style={{maxWidth:500}}>
      <CardHeader title={<Typography variant="h4">Quota</Typography>} />

      <Divider />

      <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
        <ul>
          <li>
            <Typography variant="subtitle1">
              <span style={{ fontWeight: "bold" }}>Organization Quota</span> :{" "}
              {props.quotaInfo.user_organization}
            </Typography>{" "}
          </li>
          <li>
            <Typography variant="subtitle1">
              <span style={{ fontWeight: "bold" }}>Project Quota</span> :{" "}
              {props.quotaInfo.user_project}
            </Typography>
          </li>
        </ul>
      </div>
    </Card>
  );
};
