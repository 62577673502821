import React, { useEffect, useState } from "react";
import { connect,ConnectedProps } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InfoPopupContainer from "../../../components/infopopup/InfoPopupContainer";
import AddressForm from "../../../components/billing/AddressForm";
import {
  getPaymentThreshold,
  resetPaymentThreshold,
  updatePaymentSetting,
  updatePaymentThreshold,
} from "./redux/actions";
import BackdropLoader from "../../../components/loader/BackdropLoader";
import { useTranslation } from "react-i18next";
import { PaymentThreshold } from "../../../models/Billing.model";
import { useHistory } from "react-router-dom";
import { Dispatch } from "redux";

/* istanbul ignore next */
const useStyles = makeStyles(() => ({
  actionButton: {
    borderRadius: 2,
    minWidth: 125,
  },
  cardAction: {
    marginTop: 0,
    marginRight: 0,
  },
  cardContent: {
    padding: "0px 16px",
    marginTop: "-10px"
  },
  costText: {
    fontWeight: 400,
  },
  subHeader: {
    color: "#43425d94",
  },
  ml15: {
    marginLeft: 15,
  },
  formControl: {
    margin: "10px auto 5px 0",
  },
  address: {
    fontStyle: "italic",
    color: "#72718e",
  }
}));

type JsonBodyType=Pick<PaymentThreshold,"active"|"threshold_limit"|"email">

export const BillingSettings = (props:PropsFromRedux) => {
  const [t] = useTranslation();
  const [expanded, setExpanded] = useState(true);
  const [alert, setAlert] = useState(false);
  const [threshold, setThreshold] = useState<undefined|number>(0);
  const [error, setError] = useState<null|{msg:string}>(null);
  const [openAddressForm, setOpenAddressForm] = useState(false);
  const [firstTimeSetup, setFirstTimeSetup] = useState(false);

  let history = useHistory<{ data:string}>();

  const classes = useStyles();

  useEffect(() => {
    if(history?.action === "REPLACE" && history.location.state?.data === "no-billing-address") {
      setFirstTimeSetup(true);
      setOpenAddressForm(true);
    }

    props.getPaymentThreshold();
    return () => {
      props.resetPaymentThreshold();
    }
  }, []);

  useEffect(() => {
    if (props.paymentThreshold) {
      setAlert(props.paymentThreshold?.active);
      setThreshold(props.paymentThreshold?.threshold_limit);
    }
  }, [props.paymentThreshold]);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const handleAlertChange = () => {
    setAlert(!alert);
  };

  useEffect(()=>{
    if(!alert &&  threshold===0){
      setError(null)
    }
    if(alert &&  threshold===0){
      setError({ msg: t("Billing.BillingSettings.noneZero") });    }

  },[alert,threshold])
 
  const handleThresholdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if(!e.target.value){
      setThreshold(undefined);
    }else{
      setThreshold(parseInt(e.target.value));
    }
    
    if(parseInt(e.target.value) === 0)
      setError({ msg: t("Billing.BillingSettings.noneZero") });
    else if (!e.target.value.match(/^\d+$/))
      setError({ msg: t("Billing.BillingSettings.onlyPositive") });
    else if (parseInt(e.target.value) > 100000)
      setError({ msg: t("Billing.BillingSettings.thresholdLimit") });
    else setError(null);
  };

  const handleOpenAddressForm = () => {
    setOpenAddressForm(true);
  };

  const handleCloseAddressForm = () => {
    setOpenAddressForm(false);
    setFirstTimeSetup(false);
  };

  const isValid = () => {
    if (
      ((threshold) !== props?.paymentThreshold?.threshold_limit && !alert
    
    ))

    {
      return true
    }
    
    if (
      ((threshold) !== props?.paymentThreshold?.threshold_limit || alert!==props?.paymentThreshold?.active) && 
      !error
    )
      return true;
    return false;
  };

  const handleUpdate = (e?: React.FormEvent<HTMLFormElement>|React.MouseEvent<HTMLButtonElement>) => {
  if(  e){
e.preventDefault()
  }
    if(error) return;
    const jsonBody: JsonBodyType = {
      active: alert,
      threshold_limit: (threshold||0),
      email: props.userDetails?.email ?? "",
    };

    if(threshold===0 ||(threshold && threshold >= 0) ){
      props.updatePaymentThreshold(jsonBody);
    }
  };

  return (
    <>
      <Grid item xs={12} data-test="main-container">
        <Card>
          <CardHeader
            classes={{ action: classes.cardAction }}
            title={
              <Typography variant="h4">
                {t("Billing.BillingSettings.title")}
              </Typography>
            }
            action={
              <IconButton onClick={handleExpand} data-test="expand-btn">
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            }
            data-test="card-header"
          />
          <Collapse in={expanded} data-test="collapse-container">
            <CardContent className={classes.cardContent}>
              <Grid container alignItems="center">
                <Grid item>
                  <InfoPopupContainer
                    label={t("Billing.BillingSettings.address")}
                    message={t("Billing.BillingSettings.addressInfo")}
                  />
                </Grid>
                <Grid item>
                  <IconButton
                    className={classes.ml15}
                    onClick={handleOpenAddressForm}
                    data-test="edit-address"
                  >
                    <CreateIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Typography
                variant="body1"
                color="textPrimary"
                style={{ width: 300 }}
                data-test="address"
              >
                {props.paymentSetting?.country &&
                props.paymentSetting?.postal_code ? (
                  <>
                    <Typography className={classes.address}>{props.paymentSetting?.street}</Typography>
                    <Typography className={classes.address}>{props.paymentSetting?.city}, {props.paymentSetting?.state} - {props.paymentSetting?.postal_code}</Typography>
                    <Typography className={classes.address}>{props.paymentSetting?.country}</Typography>
                  </>
                ) : (
                  <em>{t("Billing.BillingSettings.noBillingAddress")}</em>
                )}
              </Typography>

              <FormControlLabel
                value={alert}
                control={ <Switch color="primary" checked={alert} />}
                label={<Typography variant="h5">Alerts</Typography>}
                labelPlacement="start"
                // disabled={props.paymentThreshold?.threshold_limit === 0}
                className={classes.formControl}
                onChange={handleAlertChange}
                data-test="switch-form"
              />
              
                      <Button
                        style={{ borderRadius: 2 }}
                        variant="contained"
                        color="primary"
                        disabled={!isValid()}
                        onClick={handleUpdate}
                        data-test="update-btn"
                      >
                        {t("Cluster.ClusterImportEndpoints.update")}
                      </Button>
              { (alert) && (
                <form onSubmit={handleUpdate}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={8} md={5}>
                      <TextField
                        fullWidth
                        placeholder="e.g 100"
                        // helperText="Some Helper text"
                        label={t("Billing.BillingSettings.thresholdAmount")}
                        className="w-100"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          style: {
                            height: "5px",
                          },
                        }}
                        value={threshold}
                        variant="outlined"
                        size="medium"
                        type='number'
                        onChange={handleThresholdChange}
                        error={Boolean(error)}
                        helperText={error?.msg ?? ""}
                        data-test="input-field"
                      />
                    </Grid>
                  </Grid>
                </form>
              )}
            </CardContent>
          </Collapse>
        </Card>
      </Grid>
      {openAddressForm && (
        <AddressForm
          openPopup={openAddressForm}
          handleClosePopup={handleCloseAddressForm}
          updateAddress={props.paymentSetting}
          firstTimeSetup={firstTimeSetup}
          data-test="address-form"
        />
      )}
      {/* {openAlertPopup && (
        <Popup
          open={openAlertPopup}
          handleDisAgree={handleCloseAlertPopup}
          message={
            "Are you sure you want to change your payment alert setting?"
          }
          yesText="Agree"
          handleAgree={handleUpdate}
          data-test="confirm-popup"
        />
      )} */}
      {props.updatingPaymentThreshold && (
        <BackdropLoader message={"Updating Payment Threshold"} data-test="backdrop-loader"/>
      )}
    </>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state:any) => ({
  paymentSetting: state.PaymentReducer.paymentSetting,
  paymentThreshold: state.PaymentReducer.paymentThreshold,
  updatingPaymentThreshold: state.PaymentReducer.updatingPaymentThreshold,
  userDetails: state.AuthReducer.user,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch:Dispatch) => {
  return {
    updatePaymentSetting: (jsonBody:JsonBodyType) =>
      dispatch(updatePaymentSetting(jsonBody)),
    updatePaymentThreshold: (jsonBody:JsonBodyType) =>
      dispatch(updatePaymentThreshold(jsonBody)),
    getPaymentThreshold: () => dispatch(getPaymentThreshold()),
    resetPaymentThreshold: () => dispatch(resetPaymentThreshold()),
  };
};
const connector=connect(mapStateToProps, mapDispatchtoProps)
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(BillingSettings);
