import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@material-ui/core";
import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import MuiTextField from "../textfield/MuiTextField";
import { validateInputField } from "../../helpers/utils";
import { useTranslation } from 'react-i18next';

type Props = {
    open: boolean,
    handleCloseDialog:()=>void,
    appName: string,
    handleNameSubmit:(appName: string)=>void
}

function AppRenameDialog(props: Props) {
    const [t] = useTranslation();

    const [appName, setAppName] = useState(props.appName);
    const [isAppRenameFieldError, setIsAppRenameFieldError] = useState(false);
    const [AppNameFieldErrorMessage, setAppNameFieldErrorMessage] = useState("");

    const handleUpdateAppName = (e: React.ChangeEvent<{ value: string }>) => {
        let validation = validateInputField(e.target.value)
        setAppName(e.target.value);
        setIsAppRenameFieldError(validation.error);
        setAppNameFieldErrorMessage(validation.error ? t(validation.message) : "");
      }

    const handleAppNameOnBlur = () => {
        if (appName.length === 0) {
            setIsAppRenameFieldError(true);
            setAppNameFieldErrorMessage("App name can't be empty")
        }
    }

  return (
    <div>
      <Dialog
        open={props.open}
        disableEscapeKeyDown={true}
        onClose={() => {
          props.handleCloseDialog();
        }}
        keepMounted
        data-test="main-container"
      >
        <DialogTitle>
          <Typography className="dialogtitle">Update App Name</Typography>

          <IconButton
            aria-label="close"
            size="small"
            className="right"
            onClick={() => props.handleCloseDialog()}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent style={{ minWidth: 100 }} dividers>
          <MuiTextField
            label="App Name"
            error={isAppRenameFieldError}
            helperText={AppNameFieldErrorMessage}
            value={appName}
            required
            autoFocus
            onChange={(e: React.ChangeEvent<{ value: string }>) =>
              handleUpdateAppName(e)
            }
            onBlur={handleAppNameOnBlur}
          />
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => props.handleNameSubmit(appName)}
            color="primary"
            variant="contained"
            disabled={isAppRenameFieldError||appName===props.appName}
          >
            Rename
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AppRenameDialog;
