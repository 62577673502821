import React, {useCallback, useEffect, useState} from "react"
import { Paper,Card, Grid,ButtonBase, makeStyles} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Skeleton from "react-loading-skeleton";
import { useTranslation } from 'react-i18next';
import { coreConversion, spaceCoversion } from '../../helpers/utils'
import { Resource } from "../../models/Environment.model";
import { AppDetailsModel } from "../../models/Application.model";

/* istanbul ignore next */
const useStyles= makeStyles({
    containerPaper: {
        margin: 20,
        padding: 20
    },
    coreGrid: {
        marginTop: 0
    },
    corePaper: {
        padding : 20,
        width:'100%'
    },
    fullBtn:{
        width:'100%'
    },
    resourceInactive: {
        opacity: 0.5
    },

})

type Props = {
    resources:Resource[]; 
    defaultResource:Pick<Resource, "cpu"|"disk"|"memory"|"cores">;
    availableResources:Pick<Resource, "cpu"|"disk"|"memory">;
    appDetails:AppDetailsModel;
    handleResourceUpdate:(systemDetail:{resource: Resource})=>void;
    loadSource:number;
}

export const OperatorResource = ({resources,  defaultResource, availableResources, appDetails,handleResourceUpdate,loadSource}:Props) =>{ 
    const classes= useStyles()
    const [t] = useTranslation()
   
    const [systemDetail, setSystemDetail] = useState<any>({})

    const min_cpu = 100
    const min_memory = 128

    // eslint-disable-next-line no-unused-vars
    const getDefaultResource = useCallback( (_resources, _appDetails) => {
        if(_resources && _resources.length > 0 ){
            // const min_cpu = _appDetails?.plugin?.min_cpu
            // const min_memory = _appDetails?.plugin?.min_memory
            
            const defaultRes = _resources.find((res:Resource) => { 
                return res.memory >= min_memory && res.cores >= min_cpu
            })
            if(defaultRes)
                return defaultRes
            else
                return { id : 0, cores: 0, memory: 0 }
        }
        else
            return { id : 0, cores: 0, memory: 0 }
    }, [])

    useEffect(()=>{
      if(systemDetail){
        handleResourceUpdate(systemDetail)
      }
    },[systemDetail])

    useEffect(()=>{
      if(defaultResource){
        setSystemDetail({resource:defaultResource})
      }else{
          const _resources= getDefaultResource(resources, appDetails)
          setSystemDetail({resource: _resources})
      }
    },[resources, defaultResource, appDetails])

    const checkIfRamAndCoreAvailable = (type:number, currentResource:Resource) => {
        let _availableResources = { cpu: 0, memory: 0 };

        if(loadSource === 1) { //creation
            // _availableResources.cpu = availableResources.cpu;
            //  _availableResources.memory = availableResources.memory;
            _availableResources.cpu = 500;
            _availableResources.memory = 512 ;
        }
        else { //edit
            if( defaultResource) {
                _availableResources.cpu = availableResources.cpu + defaultResource.cores;
                _availableResources.memory = availableResources.memory + defaultResource.memory;
            }
            else
            {
                _availableResources.cpu = availableResources.cpu;
                _availableResources.memory = availableResources.memory;
              
            }
        }
        if(type === 2) 
        {
            return currentResource.cores  <= _availableResources.cpu && currentResource.memory <= _availableResources.memory
        }
    }
    const isResourceEnough = (res:Resource) => {
        if(appDetails && res){
            // const min_cpu = appDetails.plugin?.min_cpu
            // const min_memory = appDetails.plugin?.min_memory
            
            return res.memory >= min_memory && res.cores >= min_cpu
        }
        return true
    }
    const toggleCardSelection = (item:Resource) => {
        if(systemDetail?.resource?.id !== item.id){
            setSystemDetail({resource: item})

        }
    }
    
 return(
    <div data-test="main-container">
     <Paper>
     <Alert severity="info" data-test="alert-container">
      {t('ResourcePaper.minimumResourceRequiredCPU')} {coreConversion(min_cpu)} {t('ResourcePaper.minimumResourceRequiredMemory')} {spaceCoversion(min_memory)}
     </Alert>
     </Paper>
    <Paper elevation={ 1 } className={ classes.containerPaper }>
    <Grid container spacing={ 2 } className={ classes.coreGrid }>
    {   
        resources === null && [0,1,2,3].map(value => {
            return (
                <Grid data-test="resource-skeleton" item md={ 2 } sm= { 3 } xs={ 6 } key={ value }>
                    <Card variant='outlined' style={{padding : '1rem'}}>
                        {/* <SkeletonTheme height={250}> */}
                            <Skeleton count={5} />
                        {/* </SkeletonTheme> */}
                    </Card>
                </Grid>
            )
        })
    }
    
    {
        resources && resources.length > 0 && resources.map((item) => {
            const isActive = checkIfRamAndCoreAvailable(2, item);
            const isEnough = isResourceEnough(item)
            return ( 
                <Grid item md={ 2 } sm= { 12 } xs={ 12 } key={item.id} data-test="resource-cards">
                    <ButtonBase
                      data-test= "resource-button" 
                      onClick={ () => toggleCardSelection(item) } 
                      className={`${classes.fullBtn} ${ (!isActive || !isEnough) ? classes.resourceInactive : "" }`} 
                      disabled = { !isActive || !isEnough }
                    >
                        <Paper elevation={ 2 }className={`${systemDetail.resource && systemDetail.resource.id === item.id ? 'card-Selected' : classes.corePaper} newClass` }>
                            <Grid container direction="column" spacing={ 1 } alignItems="center" className="resourcesdiv">
                                <Grid item>
                                    <img src={ systemDetail.resource && systemDetail.resource.id === item.id ? "/images/icons/cpu-white.svg" : "/images/icons/cpu.svg" } alt="Core"/>
                                    <span className="oneLine" title={coreConversion(item.cores)}>{coreConversion(item.cores)}</span> 
                                </Grid>
                                <Grid item>
                                    <img src={ systemDetail.resource && systemDetail.resource.id === item.id ? "/images/icons/ram-white.svg" : "/images/icons/ram.svg" } alt="RAM" />
                                    <span className="oneLine" title={spaceCoversion(item.memory)} >{spaceCoversion(item.memory)}</span>
                                </Grid>
                            </Grid>
                        </Paper>
                    </ButtonBase>
                </Grid>
            )}
        )
    }
    </Grid>
    </Paper>
    </div>
 )
}

export default OperatorResource