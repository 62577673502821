import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    FormControlLabel,
    Grid,
    Switch,
    MenuItem,
    Typography,
    Checkbox
} from "@material-ui/core";
//import { makeStyles } from '@material-ui/core/styles';
import {
    Input as AntdInput
} from 'antd';
import React, { useEffect } from "react";
import Cron from 'react-js-cron';
import cronstrue from "cronstrue";
import { connect,ConnectedProps } from "react-redux";
import BackdropLoader from "../../../components/loader/BackdropLoader";
import { isAuthorized } from '../../../helpers/utils';
import { fetchBackupSettings, updateBackupSettings, clearBackupSettings } from "../redux/actions";
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useTranslation } from 'react-i18next';
import { AppConstants } from "../../../constants/appconstants"
import EmailInput from "../../../components/emailinput/EmailInput";
import { Dispatch } from "redux";
/* istanbul ignore next */
const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 180,
      },
}));

interface Props extends PropsFromRedux{
    isHelmEnv:boolean;
}

type JsonBodyType= {
    enabled: boolean;
    schedule: string;
    emails: string;
    schedule_type: string;
}

export const BackupSettingsTab = (props:Props) => {
    const envRole = props.isHelmEnv ? {name: "Admin"} :  props.envRole
    const environmentDetails = props.isHelmEnv ? props.helmEnvironmentDetails :  props.environmentDetails
    const classes = useStyles();
    const [enable, setEnable] = React.useState(false);
    const [cronExpression, setCronExpression] = React.useState<string>('');
    //const [cronError, setCronError] = React.useState(false);
    const [cronErrorMessage, setCronErrorMessage] = React.useState('');
    // const cronExpressionRef = React.createRef(null);
    const [frequency, setFrequency] = React.useState(1);
    const [scheduleTime, setScheduleTime] = React.useState(0);
    const [scheduleDate, setScheduleDate] = React.useState(1);
    const [scheduleDay, setScheduleDay] = React.useState(0);
    const [t] = useTranslation();
    const [changed, setChanged] = React.useState(false);
    const [isEmailNotificationEnabed, setIsEmailNotificationEnabed] = React.useState(false);
    const [notificationEmails, setNotificationEmails] = React.useState<string[]|null>(null);
    const [errors, setErrors] = React.useState({emails:false});
    const [helperText, setHelperText] = React.useState({emails:''});
    const [scheduleType, setScheduleType] = React.useState<string>('');

    const handleScheduleTimeChange = (event:React.ChangeEvent<{ value: unknown }>) => {
        setChanged(true);
        setScheduleTime(event.target.value as number);
    };
    const handleScheduleDateChange = (event:React.ChangeEvent<{ value: unknown }>) => {
        setChanged(true);
        setScheduleDate(event.target.value  as number);
    };
    const handleScheduleDayChange = (event:React.ChangeEvent<{ value: unknown }>) => {
        setChanged(true);
        setScheduleDay(event.target.value as number);
    };

    const resetSelection = () => {
        setScheduleTime(0);
        setScheduleDate(1);
        setScheduleDay(0);
    }

    const handleFrequencyChange = (e:React.ChangeEvent<{ value: unknown }>) => {
        resetSelection();
        setChanged(true);
        setFrequency(e.target.value as number);
        setScheduleType(e.target.value as string);
    }

    const handleEmailNotificationToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsEmailNotificationEnabed(e.target.checked as boolean);
    }

    const setScheduleDetails = () => {
        const _schedule = props.backupSettings.schedule;

        setCronExpression(_schedule);

        const schedule_type = props.backupSettings.schedule_type;

        setScheduleType(schedule_type as string);

        const arrExpr = _schedule.split(" ");

        switch (parseInt(schedule_type)) {
            case 1:
                setFrequency(1);
                setScheduleTime(arrExpr[1]);
                break;
            case 2:
                setFrequency(2)
                setScheduleDay(arrExpr[4])
                setScheduleTime(arrExpr[1]);
                break;
            case 3:
                setFrequency(3)
                setScheduleDate(arrExpr[2])
                setScheduleTime(arrExpr[1]);
                break;
            default:
                setFrequency(4)
        }
        
        // if (arrExpr[0] !== "*" && arrExpr[1] !== "*") {
        //     if (arrExpr[2] === "*" && arrExpr[3] === "*" && arrExpr[4] === "*") {
        //         setFrequency(1);
        //         setScheduleTime(arrExpr[1]);
        //     } else if (arrExpr[2] === "*" && arrExpr[3] === "*" && arrExpr[4] !== "*") {
        //         setFrequency(2)
        //         setScheduleDay(arrExpr[4])
        //         setScheduleTime(arrExpr[1]);
        //     } else if (arrExpr[2] !== "*" && arrExpr[3] === "*" && arrExpr[4] === "*") {
        //         setFrequency(3)
        //         setScheduleDate(arrExpr[2])
        //         setScheduleTime(arrExpr[1]);
        //     }
        //     else {
        //         setFrequency(4)
        //         //setCronExpression(props.backupSettings.schedule);
        //     }
        // }
        // else {
        //     setFrequency(4)
        //     //setCronExpression(props.backupSettings.schedule);
        // }
    }

    const handleEmails = (emails:string[]) => {
        setNotificationEmails(emails);
    }
  
    const emailValidation = () => {
        if(notificationEmails && notificationEmails.length <= 0){
            setErrors({ ...errors, emails: true });
            setHelperText({ ...helperText, emails: t('Backup.SettingsTab.emailError') });
        } else{
            setErrors({ ...errors, emails: false });
            setHelperText({ ...helperText, emails: "" });
        }      
    }

    useEffect(() => {
        if(notificationEmails){
            emailValidation();
        }
    },[notificationEmails])

    useEffect(() => {
        if (environmentDetails && environmentDetails.id > 0) {
            props.fetchBackupSettings(environmentDetails.id, props.isHelmEnv);
        }
        return(() => {
            props.clearBackupSettings()
        })
    }, []);

    useEffect(() => {
        if(props.backupSettings)
        {
            setEnable(props.backupSettings.enabled);
            //setCronExpression(props.backupSettings.schedule);
            //let _scheduleTime = props.backupSettings.schedule.split(' ');
            //setScheduleTime(_scheduleTime[1])
            setScheduleDetails();

            if(props.backupSettings.emails){
                setIsEmailNotificationEnabed(true);
                setNotificationEmails(props.backupSettings.emails.split(","));
            }
        }
    }, [props.backupSettings]);

    const updateCronExpression = (value:string) => {
        setCronExpression(value);
        
        // if (cronExpressionRef?.current) {
        //     cronExpressionRef.current.setValue(value)
        // }
    }

    const cronErrorhandle =(e:any) => {
        if(e?.type === "invalid_cron"){
            //setCronError(true);
            setCronErrorMessage(e?.description);
        } else {
            //setCronError(false);
            setCronErrorMessage('');
        }
    }

    useEffect(() => {
        if (changed) {
            let expr = "";
            switch (frequency) {
                case 1:
                    expr = Math.floor(Math.random() * 60) + ' ' + scheduleTime + ' * * *'
                    break;
                case 2:
                    expr = Math.floor(Math.random() * 60) + ' ' + scheduleTime + ' * * ' + scheduleDay;
                    break;
                case 3:
                    expr = Math.floor(Math.random() * 60) + ' ' + scheduleTime + ' ' + scheduleDate + ' * *';
                    break;
                default:
                    break;
            }
            setCronExpression(expr);
        }
    }, [scheduleTime, scheduleDate, scheduleDay, frequency]);

    const settingsUpdateCallback = () => {
        setChanged(false);
        setCronExpression('');
    }

    const saveBackupSettings = () => {
        const jsonBody:JsonBodyType = { 
            enabled: enable,
            schedule: cronExpression,
            emails: isEmailNotificationEnabed && notificationEmails ? notificationEmails.join(",") : "",
            schedule_type: scheduleType.toString()
        }
    
        props.updateBackupSettings(environmentDetails.id, jsonBody, settingsUpdateCallback, props.isHelmEnv);
    }

    const isFormValid = () => { 
        let isValid = false;
        if (props.backupSettings) {
            if (props.backupSettings.enabled !== enable) {
                if (!enable) { 
                    isValid = true;
                }
                if (enable && cronExpression) {
                    isValid = true;
                }
            }
            if (props.backupSettings.enabled) {
                if (cronExpression && props.backupSettings.schedule !== cronExpression) {
                    isValid = true;
                }
            }

            if (props.backupSettings.emails) {
                if (!isEmailNotificationEnabed) {
                  isValid = true;
                }
        
                if (
                  isEmailNotificationEnabed && notificationEmails?.length &&
                  notificationEmails.join(",") !== props.backupSettings.emails
                ) {
                  isValid = true;
                }
              } else {
                if (isEmailNotificationEnabed && notificationEmails?.length)
                  isValid = true;
              }
        }
        else { 
            if (enable && cronExpression)
                isValid = true;
            
            if(isEmailNotificationEnabed && notificationEmails?.length) {
                isValid = true;
            }
        }
        
        return isValid;
    }

    return (
        <div data-test="main-container">
            <Card>
                <CardHeader
                    subheader={t('Backup.SettingsTab.cardSubHeader')}
                    title={t('Backup.SettingsTab.title')}
                />
                <Divider />
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                data-test="switch"
                                control={
                                    <Switch
                                       data-test="switch-btn"
                                        name="enable"
                                        color="primary"
                                        checked={ enable }
                                        onChange={ e => setEnable(e.target.checked) }
                                    />
                                }
                                label={t('Backup.SettingsTab.enableLabel')}
                            />
                        </Grid>
                        {
                            enable &&
                            <>
                                {
                                    props.backupSettings && props.backupSettings.schedule &&
                                    <Grid item xs={12}>
                                        <Typography variant="h5">Current Schedule : {cronstrue.toString(props.backupSettings.schedule)}</Typography> 
                                    </Grid>
                                }
                                {
                                    changed && cronExpression &&
                                    <Grid item xs={12}>
                                        <Typography variant="h5">New Schedule : {cronstrue.toString(cronExpression)}</Typography>
                                    </Grid>
                                }
                                <Grid item xs={ 12 } data-test="frequency">
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel htmlFor="outlined-schedule-native-simple"> {t('Backup.SettingsTab.frequency')} </InputLabel>
                                        <Select
                                            value={frequency}
                                            onChange={handleFrequencyChange}
                                            MenuProps={{ disableScrollLock: true }}
                                            label={t('Backup.SettingsTab.frequency')}
                                            data-test="select-frequency"
                                            // inputProps={{
                                            //     name: 'Daily Backup Schedule',
                                            //     id: 'outlined-schedule-native-simple',
                                            // }}
                                        >
                                            <MenuItem value={1}> Daily </MenuItem>
                                            <MenuItem value={2}> Weekly </MenuItem>
                                            <MenuItem value={3}> Monthly </MenuItem>
                                            <MenuItem value={4}> Advanced </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                    <Grid item xs={12} data-test="cron-grid">
                                        <Grid container spacing={2}>
                                        {
                                            
                                            frequency !== 4 &&
                                            <>
                                                {
                                                    frequency === 3 &&
                                                    <Grid item>
                                                        <FormControl variant="outlined" className={classes.formControl}>
                                                            <InputLabel htmlFor="outlined-schedule-native-simple"> {t('Backup.SettingsTab.date')}</InputLabel>
                                                            <Select
                                                                value={scheduleDate}
                                                                onChange={handleScheduleDateChange}
                                                                label={t('Backup.SettingsTab.date')}
                                                                MenuProps={{ disableScrollLock: true }}
                                                                data-test="select-date"
                                                            // inputProps={{
                                                            //     name: 'Daily Backup Schedule',
                                                            //     id: 'outlined-schedule-native-simple',
                                                            // }}
                                                            > 
                                                                {
                                                                    Array(31).fill('*').map((_, i) => (
                                                                        <MenuItem key={i} value={i+1}>  {i + 1}  </MenuItem>
                                                                    ))
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                }
                                                {
                                                    frequency === 2 &&
                                                    <Grid item>
                                                        <FormControl variant="outlined" className={classes.formControl}>
                                                            <InputLabel htmlFor="outlined-schedule-native-simple"> {t('Backup.SettingsTab.day')}</InputLabel>
                                                            <Select
                                                                value={scheduleDay}
                                                                onChange={handleScheduleDayChange}
                                                                label={t('Backup.SettingsTab.day')}
                                                                data-test="select-day"
                                                            // inputProps={{
                                                            //     name: 'Daily Backup Schedule',
                                                            //     id: 'outlined-schedule-native-simple',
                                                            // }}
                                                            >
                                                                {
                                                                    AppConstants.Weekdays.map((day, i) => (
                                                                        <MenuItem key={i} value={i}> {day} </MenuItem>
                                                                    ))
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                }
                                                <Grid item>
                                                    <FormControl variant="outlined" className={classes.formControl}>
                                                        <InputLabel htmlFor="outlined-schedule-native-simple"> {t('Backup.SettingsTab.time')}</InputLabel>
                                                        <Select
                                                            value={scheduleTime}
                                                            onChange={handleScheduleTimeChange}
                                                            label={t('Backup.SettingsTab.time')}
                                                            data-test="select-time"
                                                        // inputProps={{
                                                        //     name: 'Daily Backup Schedule',
                                                        //     id: 'outlined-schedule-native-simple',
                                                        // }}
                                                        >
                                                            {
                                                                Array(24).fill('*').map((_, i) => (
                                                                    <MenuItem key={i} value={i}>  {(i) + ':00 - ' + (i) + ':59'}  </MenuItem>
                                                                ))
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </>
                                        }
                                        {
                                            frequency === 4 &&
                                            <>
                                                <Grid item md={12}>
                                                    <Cron value={cronExpression} setValue={updateCronExpression}
                                                        onError={cronErrorhandle}
                                                    />
                                                </Grid>
                                                <Grid item md={3}>
                                                    <label>Expression</label>
                                                    {/* : {this.state.cronExpression} */}
                                                    <AntdInput
                                                        // ref={cronExpressionRef}
                                                        value={cronExpression}
                                                        onBlur={(event) => updateCronExpression(event.target.value)}
                                                        onChange={(event) => updateCronExpression(event.target.value)}
                                                        // onPressEnter={() => {
                                                        //     setValue(inputRef.current?.input.value || '')
                                                        // }}
                                                        allowClear={true}
                                                        data-test="cron-expression-input"
                                                    />
                                                    <Typography variant="body2" color="secondary"> {cronErrorMessage} </Typography>
                                                </Grid>
                                            </>
                                        }
                                        </Grid>
                                    </Grid>
                                        {/* <Grid item md={12} sm={6} xs={12} >
                                            <Typography color="primary" variant="h6" >Retention Policy</Typography>
                                        </Grid>
                                        <Grid item md="12">
                                            <Grid container>
                                                <Grid item md="3">
                                                    <label>Days of daily snapshots</label>
                                                    <OutlinedInput
                                                        name="retentionDays"
                                                        value={ values.retentionDays }
                                                        variant="outlined"
                                                        //type="number"
                                                        onChange={ handleChange }
                                                        onBlur={ handleBlur }
                                                        className="w-100"
                                                        endAdornment={<InputAdornment position="end">Days</InputAdornment>}
                                                        error={ errors.retentionDays && touched.retentionDays }
                                                        helperText={ (errors.retentionDays && touched.retentionDays) && errors.retentionDays }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item md={12} sm={6} xs={12} >
                                            <Typography color="primary" variant="h6" >Notifications</Typography>
                                        </Grid>
                                        <Grid item md={12} sm={6} xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        name="emailEnabled"
                                                        checked={ values.emailEnabled }
                                                        onChange={ handleChange }
                                                    />
                                                }
                                                label="Send email after backup created"
                                            />
                                        </Grid>
                                        {
                                            values.emailEnabled &&
                                            <Grid item sm={6} xs={12} className='oneRemLeftMarginSeperator'>
                                                <div>
                                                <EmailInput emails={ values.emails } source="formik" name="emails" setFieldValue={props.setFieldValue} />
                                                <label>{ (errors.emails && touched.emails) && errors.emails }</label>
                                                </div>
                                            </Grid>
                                        }
                                        <Grid item md={12} sm={6} xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        name="slackEnabled"
                                                        checked={ values.slackEnabled }
                                                        onChange={ handleChange }
                                                    />
                                                }
                                                label="Enable Slack Notifications"
                                            />
                                        </Grid>
                                        { 
                                            values.slackEnabled && 
                                            <Grid item sm={6} xs={12} className='oneRemLeftMarginSeperator'>
                                                <Grid container direction="column" spacing={1}>
                                                    <Grid item className={classes.branchInfo}>
                                                        <Typography variant='h5'>Webhook URL </Typography>
                                                    </Grid>
                                                    <Grid item >
                                                        <MuiTextField 
                                                            error={ errors.slack_webhook && touched.slack_webhook }
                                                            name="slack_webhook" 
                                                            value={ values.slack_webhook }
                                                            onChange={ handleChange } 
                                                            onBlur={ handleBlur }
                                                            helperText={ (errors.slack_webhook && touched.slack_webhook) && errors.slack_webhook }
                                                            margin="normal"
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <Button color="primary" variant="contained" disabled >Test</Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        } */}
                            </>
                        }
                    </Grid>

                    <Typography color="primary" variant="h6" className="m-t-10" >{t('Backup.SettingsTab.notifications')}</Typography>

                    <Grid container spacing={2} data-test="grid-container">
                        <Grid item md={12} sm={6} xs={12}>
                        <FormControlLabel
                            data-test="email-checkbox"
                            control={
                            <Checkbox
                                color="primary"
                                checked={isEmailNotificationEnabed}
                                onChange={handleEmailNotificationToggle}
                            />
                            }
                            label={t("Backup.SettingsTab.sendEmail")}
                        />
                        </Grid>
                        {isEmailNotificationEnabed && (
                            <Grid
                                item
                                sm={6}
                                xs={12}
                                className="oneRemLeftMarginSeperator"
                                data-test="email-input-grid"
                            >
                                <EmailInput
                                error={errors.emails||false}
                                helperText={helperText.emails||''}
                                emails={notificationEmails ?? []}
                                handleEmails={handleEmails}
                                data-test="email-input"
                                />
                            </Grid>
                        )}
                    </Grid>
                                
                    <Grid>
                        <Grid container spacing={2}> 

                        </Grid>
                    </Grid>
                </CardContent>
                <Divider />
                {
                    isAuthorized("update", envRole.name) &&
                    <CardActions>
                        <Button data-test="save-btn" color="primary" variant="contained" onClick={() => saveBackupSettings()} disabled={!isFormValid()}>
                            {t('Backup.SettingsTab.save')}
                        </Button>
                    </CardActions>
                }
            </Card>
            { props.fetchingBackupSettings && <BackdropLoader message={t('Backup.SettingsTab.fetchingMessage')} data-test="fetching-loader"/>}
            { props.updatingBackupSettings && <BackdropLoader message={t('Backup.SettingsTab.updatingMessage')} data-test="updating-loader" /> }
        </div>
    );
}

/* istanbul ignore next */
const mapStateToProps = (state:any) => ({
    environmentDetails: state.EnvironmentReducer.environmentDetails,
    helmEnvironmentDetails: state.HelmEnvironmentReducer.helmEnvironmentDetails,
    backupSettings: state.BackupReducer.backupSettings,
    fetchingBackupSettings: state.BackupReducer.fetchingBackupSettings,
    updatingBackupSettings: state.BackupReducer.updatingBackupSettings,
    envRole: state.EnvironmentReducer.envRole
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch:Dispatch) => ({
    fetchBackupSettings : (eId:number, isHelm:boolean) => dispatch(fetchBackupSettings(eId, isHelm)),
    clearBackupSettings : () => dispatch(clearBackupSettings()),
    updateBackupSettings: (eId:number, jsonBody:JsonBodyType, callback:() => void, isHelm:boolean) => dispatch(updateBackupSettings(eId, jsonBody, callback, isHelm))
});

const connector=connect(mapStateToProps, mapDispatchToProps)
export default connector(BackupSettingsTab);
type PropsFromRedux = ConnectedProps<typeof connector>;
