import React, { Component } from "react";
import { loginOAuth } from "./redux/actions";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Typography, CircularProgress, Grid } from "@material-ui/core";
import { gitConnect } from "../app/redux/actions";
import { Dispatch } from "redux";
import { History } from "history";
import { WithTranslation } from "react-i18next";
import { createStyles, WithStyles } from "@material-ui/core";
// import toast from '../../components/toast/Toast';

/* istanbul ignore next */
const useStyles = () =>
  createStyles({
    container: {
      marginTop: 120,
    },
  });

type Payload = {
  service_name?: string;
  service_code: string | null;
  is_oauth?: boolean;
};
interface Props extends WithTranslation, WithStyles<typeof useStyles> {
  location: { search: string };
  gitConnect: (payload: Payload, window: Window) => void;
  history: History;
  loginOAuth: (payload: Payload, history: History) => void;
}
export class LoginCallback extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (this.props.location.search) {
      let payload: Payload = { service_code: "" };
      var params = new URLSearchParams(this.props.location.search);
      const service_name = params.has("state") ? params.get("state") : "";
      const code = params.has("code") ? params.get("code") : "";

      if (service_name && code) {
        if (service_name === "github_repo") {
          payload["service_name"] = "github";
          payload["service_code"] = params.has("code")
            ? params.get("code")
            : "";
          //alert("github_repo");
          this.props.gitConnect(payload, window);
          window.opener.postMessage("Github login success");
        } else if (service_name === "gitlab_repo") {
          payload["service_name"] = "gitlab";
          payload["service_code"] = params.has("code")
            ? params.get("code")
            : "";
          payload["is_oauth"] = true;
          // alert(payload.service_name)
          // alert(payload.service_code)
          // alert(payload.is_oauth)
          this.props.gitConnect(payload, window);
          // window.opener.postMessage('Gitlab login success');
        } else if (service_name === "github_repo_hook") {
          window.opener.postMessage("Github login success");
          window.close();
        } else if (service_name === "bitbucket_repo") {
          payload["service_name"] = "bitbucket";
          payload["service_code"] = params.has("code")
            ? params.get("code")
            : "";
          //alert("github_repo");
          this.props.gitConnect(payload, window);
          window.opener.postMessage("BitBucket login success");
        } else if (service_name === "github") {
          payload["service_name"] = service_name;
          payload["service_code"] = params.has("code")
            ? params.get("code")
            : "";
          this.props.loginOAuth(payload, this.props.history);
        } else if (service_name === "gitlab") {
          payload["service_name"] = service_name;
          payload["service_code"] = params.has("code")
            ? params.get("code")
            : "";
          payload["is_oauth"] = true;
          this.props.loginOAuth(payload, this.props.history);
        }
      } else {
        window.close();
      }
    }
  }

  render() {
    console.log("login callback props", this.props);
    const { classes, t } = this.props;
    return (
      <div className={classes.container} data-test="mainContainer">
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <CircularProgress />
          </Grid>
          <Grid item data-test="waitingContainer">
            <Typography variant="h5">
              {t("LoginModule.LoginPage.wait")}
            </Typography>
          </Grid>
          <Grid item data-test="redirectContainer">
            <Typography variant="subtitle1">
              {t("LoginModule.LoginPage.redirected")}
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  }
}

/* istanbul ignore next */

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    loginOAuth: (payload: Payload, history: History) =>
      dispatch(loginOAuth(payload, history)),
    gitConnect: (jsonBody: Payload, window: Window) =>
      dispatch(gitConnect(jsonBody, window)),
  };
};

export default connect(
  null,
  mapDispatchtoProps
)(withStyles(useStyles)(withTranslation()(LoginCallback)));
