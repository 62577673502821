import React, { PureComponent } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
//import { DropzoneArea } from 'material-ui-dropzone'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { uploadFile } from '../../pages/common/redux/actions'
import { Button, IconButton, Typography, Divider } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { connect, ConnectedProps } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Transition } from "../../helpers/utils.ext";
import { Dispatch } from "redux";

interface Props extends PropsFromRedux, WithTranslation{
  dontUpload?: boolean,
  onSuccess: (url: string) => void,
  isOpen: boolean,
  onClose: () => void,
  title: string
}

type Crop = {
  unit: any,
  width: number,
  x: number,
  y: number,
  aspect: number
}

// type FinalFile = {
//   name: string,
//   type: string
// }

type State = {
  src: any,
  fileName: string,
  fileType: string,
  finalFile: any,
  crop: Crop
}

export class ImageCropper extends PureComponent<Props, State> {
  constructor(props:Props) {
    super(props);
    this.state = {
      src: null,
      fileName: '',
      fileType: '',
      finalFile: null,
      crop: {
        unit: '%',
        width: 50,
        x: 25,
        y: 25,
        aspect: 1 / 1
      },
    };
  }


  imageRef ="";
  fileUrl="";
  
  onSelectFile = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
      this.setState({ fileName: e.target.files[0].name, fileType: e.target.files[0].type })
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image: any) => {
    this.imageRef = image;
  };

  onCropComplete = (crop: any) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop: any) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop: any) {
    if (this.imageRef && crop.width && crop.height) {
      // const croppedImageUrl = await this.getCroppedImg(
      //   this.imageRef,
      //   crop,
      //   'newFile.jpeg'
      // );
      // this.setState({ croppedImageUrl});
      await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
    }
  }
  /*istanbul ignore next */
  getCroppedImg(image: any, crop: any, fileName: string) {
    const $this = this;
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx: any = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob: any) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error(this.props.t('HPA.canvasError'));
          return;
        }
        blob.name = fileName;
        
        let _file = new File([ blob ], this.state.fileName, { type: this.state.fileType } );
        $this.setState({ finalFile: _file });

        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  }

  handleUploadClick = () => {
    if (this.state.finalFile) {
      if(this.props.dontUpload) return this.props.onSuccess(this.state.finalFile);
      let iconPayload = new FormData();
      iconPayload.append('file_name', this.state.finalFile.name);
      iconPayload.append('file_type', this.state.finalFile.type);
      iconPayload.append('file', this.state.finalFile);
      this.props.uploadFile(iconPayload, this.uploadCallBack)
    }
  }

  uploadCallBack = (data: any) => {
    if(data)
    {
      this.props.onSuccess(data.url);
    }
  }
  
  render() {
    const { crop, src } = this.state;
    const { t } = this.props;

    return (
        <div className="App" data-test="main-container">
            <Dialog
              open={ this.props.isOpen }
              TransitionComponent={ Transition }
              keepMounted
              onClose={ this.props.onClose }
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-slide-title">
                  <Typography className='dialogtitle' data-test="popup-title">{ this.props.title }</Typography>
                  <IconButton aria-label="close" size="small" className='right' onClick={ this.props.onClose } data-test="close-icon">
                      <CloseIcon />
                  </IconButton>
              </DialogTitle>
              <Divider />
              <DialogContent 
              // maxWidth="lg" 
              dividers>
                  <DialogContentText id="alert-dialog-slide-description">
                  <div>
                    <Button variant="contained" component="label" color="primary" data-test="choose-btn">{t('HPA.chooseFile')} <input type="file" hidden accept="image/*" onChange={this.onSelectFile} data-test="input" /></Button>
                  </div>
                  {src && (
                    <ReactCrop
                      src={src}
                      crop={crop}
                      ruleOfThirds
                      onImageLoaded={this.onImageLoaded}
                      onComplete={this.onCropComplete}
                      onChange={this.onCropChange}
                      className= "m-t-20"
                      data-test="react-crop"
                    />
                  )}
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button startIcon={<CloudUploadIcon />} color="primary" variant="contained" onClick={this.handleUploadClick} disabled={ !this.state.finalFile} data-test="upload-btn" >{t('HPA.upload')}</Button>
              </DialogActions>
            </Dialog>
        
            {/* {croppedImageUrl && (
          <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} />
        )} */}
        </div>
    );
  }
}

/*istanbul ignore next */
const mapStateToProps = () => {
  return {
      
  }
}

/*istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    uploadFile: (formData: FormData, callBack: (data: any) => void) => dispatch(uploadFile(formData, callBack)),
  }
}

// export default connect(
//   mapStateToProps,
//   mapDispatchtoProps
// )(withTranslation()(ImageCropper));

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withTranslation()(ImageCropper)); 
