const ENVIRONMENT_PREFIX = '@ENVIRONMENT';

export const FETCH_ENVIRONMENT_DETAIL = `${ ENVIRONMENT_PREFIX }/FETCH_ENVIRONMENT_DETAIL`
export const FETCH_ENVIRONMENT_DETAIL_SUCCESS = `${ ENVIRONMENT_PREFIX }/FETCH_ENVIRONMENT_DETAIL_SUCCESS`
export const FETCH_ENVIRONMENT_DETAIL_FAILURE = `${ ENVIRONMENT_PREFIX }/FETCH_ENVIRONMENT_DETAIL_FAILURE`
export const STARTSTOP_ENVIRONMENT = `${ ENVIRONMENT_PREFIX }/STARTSTOP_ENVIRONMENT`
export const STARTSTOP_ENVIRONMENT_SUCCESS = `${ ENVIRONMENT_PREFIX }/STARTSTOP_ENVIRONMENT_SUCCESS`
export const DELETE_ENVIRONMENT  = `${ ENVIRONMENT_PREFIX }/DELETE_ENVIRONMENT`
export const DELETE_ENVIRONMENT_SUCCESS  = `${ ENVIRONMENT_PREFIX }/DELETE_ENVIRONMENT_SUCCESS`
export const DELETE_ENVIRONMENT_FAILURE  = `${ ENVIRONMENT_PREFIX }/DELETE_ENVIRONMENT_FAILURE`
export const FETCH_ENV_VARIABLES = `${ ENVIRONMENT_PREFIX }/FETCH_ENV_VARIABLES`
export const FETCH_ENV_VARIABLES_SUCCESS = `${ ENVIRONMENT_PREFIX }/FETCH_ENV_VARIABLES_SUCCESS`
export const FETCH_ENV_VARIABLES_FAILURE = `${ ENVIRONMENT_PREFIX }/FETCH_ENV_VARIABLES_FAILURE`
export const UPDATE_ENV_VARIABLES = `${ ENVIRONMENT_PREFIX }/UPDATE_ENV_VARIABLES`
export const UPDATE_ENV_VARIABLES_SUCCESS = `${ ENVIRONMENT_PREFIX }/UPDATE_ENV_VARIABLES_SUCCESS`
export const UPDATE_ENV_VARIABLES_FAILURE = `${ ENVIRONMENT_PREFIX }/UPDATE_ENV_VARIABLES_FAILURE`
export const CREATE_ENVIRONMENT = `${ ENVIRONMENT_PREFIX }/CREATE_ENVIRONMENT`
export const CREATE_ENVIRONMENT_SUCCESS = `${ ENVIRONMENT_PREFIX }/CREATE_ENVIRONMENT_SUCCESS`
export const CREATE_ENVIRONMENT_FAILURE = `${ ENVIRONMENT_PREFIX }/CREATE_ENVIRONMENT_FAILURE`
export const FETCH_RESOURCES  = `${ ENVIRONMENT_PREFIX }/FETCH_RESOURCES`
export const FETCH_RESOURCES_SUCCESS  = `${ ENVIRONMENT_PREFIX }/FETCH_RESOURCES_SUCCESS`
export const FETCH_RESOURCES_FAILURE  = `${ ENVIRONMENT_PREFIX }/FETCH_RESOURCES_FAILURE`
export const FETCH_ENV_LOGS  = `${ ENVIRONMENT_PREFIX }/FETCH_ENV_LOGS`
export const FETCH_ENV_LOGS_SUCCESS  = `${ ENVIRONMENT_PREFIX }/FETCH_ENV_LOGS_SUCCESS`
export const FETCH_ENV_LOGS_FAILURE  = `${ ENVIRONMENT_PREFIX }/FETCH_ENV_LOGS_FAILURE`
export const FETCH_ENV_LOGS_STATUS  = `${ ENVIRONMENT_PREFIX }/FETCH_ENV_LOGS_STATUS`
export const FETCH_ENV_LOGS_STATUS_SUCCESS  = `${ ENVIRONMENT_PREFIX }/FETCH_ENV_LOGS_STATUS_SUCCESS`
export const FETCH_ENV_LOGS_STATUS_FAILURE  = `${ ENVIRONMENT_PREFIX }/FETCH_ENV_LOGS_STATUS_FAILURE`
export const UPDATE_ENVIRONMENT = `${ ENVIRONMENT_PREFIX }/UPDATE_ENVIRONMENT`
export const UPDATE_ENVIRONMENT_SUCCESS = `${ ENVIRONMENT_PREFIX }/UPDATE_ENVIRONMENT_SUCCESS`
export const UPDATE_ENVIRONMENT_FAILURE = `${ ENVIRONMENT_PREFIX }/UPDATE_ENVIRONMENT_FAILURE`
export const FETCH_PLUGIN_VERSION_CONFIG = `${ ENVIRONMENT_PREFIX }/FETCH_PLUGIN_VERSION_CONFIG`
export const FETCH_PLUGIN_VERSION_CONFIG_SUCCESS = `${ ENVIRONMENT_PREFIX }/FETCH_PLUGIN_VERSION_CONFIG_SUCCESS`
export const FETCH_PLUGIN_VERSION_CONFIG_FAILURE = `${ ENVIRONMENT_PREFIX }/FETCH_PLUGIN_VERSION_CONFIG_FAILURE`
export const CLEAR_ENV_LOGS_STATUS = `${ ENVIRONMENT_PREFIX }/CLEAR_ENV_LOGS_STATUS`
export const CLEAR_ENV_ACTIVITIES = `${ ENVIRONMENT_PREFIX }/CLEAR_ENV_ACTIVITIES`
export const CLEAR_ENV_INSIGHTS = `${ ENVIRONMENT_PREFIX }/CLEAR_ENV_INSIGHTS`
export const CLEAR_ENVIRONMENT_DETAIL = `${ ENVIRONMENT_PREFIX }/CLEAR_ENVIRONMENT_DETAIL`
export const FETCH_ENVIRONMENT_INSIGHT = `${ ENVIRONMENT_PREFIX }/FETCH_ENVIRONMENT_INSIGHT`
export const FETCH_ENVIRONMENT_INSIGHT_SUCCESS = `${ ENVIRONMENT_PREFIX }/FETCH_ENVIRONMENT_INSIGHT_SUCCESS`
export const FETCH_ENVIRONMENT_INSIGHT_FAILURE = `${ ENVIRONMENT_PREFIX }/FETCH_ENVIRONMENT_INSIGHT_FAILURE`
export const FETCH_ENVIRONMENT_OVERVIEW_INSIGHT = `${ ENVIRONMENT_PREFIX }/FETCH_ENVIRONMENT_OVERVIEW_INSIGHT`
export const FETCH_ENVIRONMENT_OVERVIEW_INSIGHT_SUCCESS = `${ ENVIRONMENT_PREFIX }/FETCH_ENVIRONMENT__OVERVIEW_INSIGHT_SUCCESS`
export const FETCH_ENVIRONMENT_OVERVIEW_INSIGHT_FAILURE = `${ ENVIRONMENT_PREFIX }/FETCH_ENVIRONMENT__OVERVIEW_INSIGHT_FAILURE`
export const FETCH_ENV_STATE_INITIATE = `${ ENVIRONMENT_PREFIX }/FETCH_ENV_STATE_INITIATE`
export const FETCH_ENV_STATE = `${ ENVIRONMENT_PREFIX }/FETCH_ENV_STATE`
export const FETCH_ENV_STATE_SUCCESS = `${ ENVIRONMENT_PREFIX }/FETCH_ENV_STATE_SUCCESS`
export const CHANGE_ENV_TAB_VAL = `${ ENVIRONMENT_PREFIX }/CHANGE_TAB_VAL`
export const FETCH_ENV_ROLE = `${ ENVIRONMENT_PREFIX }/FETCH_ENV_ROLE`
export const FETCH_ENV_ROLE_SUCCESS = `${ ENVIRONMENT_PREFIX }/FETCH_ENV_ROLE_SUCCESS`
export const FETCH_ACTIVITY_LOGS = `${ ENVIRONMENT_PREFIX }/FETCH_ACTIVITY_LOGS`
export const FETCH_ACTIVITY_LOGS_SUCCESS = `${ ENVIRONMENT_PREFIX }/FETCH_ACTIVITY_LOGS_SUCCESS`
export const UPDATE_ACTIVITY_LOGS = `${ ENVIRONMENT_PREFIX }/UPDATE_ACTIVITY_LOGS`
export const RECREATE_ENV = `${ ENVIRONMENT_PREFIX }/RECREATE_ENV`
export const RECREATE_ENV_SUCCESS = `${ ENVIRONMENT_PREFIX }/RECREATE_ENV_SUCCESS`
export const RECREATE_ENV_FAILURE = `${ ENVIRONMENT_PREFIX }/RECREATE_ENV_FAILURE`
export const FETCH_ADDONS = `${ ENVIRONMENT_PREFIX }/FETCH_ADDONS`
export const FETCH_ADDONS_SUCCESS = `${ ENVIRONMENT_PREFIX }/FETCH_ADDONS_SUCCESS`
export const FETCH_ADDONS_FAILURE = `${ ENVIRONMENT_PREFIX }/FETCH_ADDONS_FAILURE`
export const FETCH_ADDON_INFO = `${ ENVIRONMENT_PREFIX }/FETCH_ADDON_INFO`
export const FETCH_ADDON_INFO_SUCCESS = `${ ENVIRONMENT_PREFIX }/FETCH_ADDON_INFO_SUCCESS`
export const FETCH_ADDON_INFO_FAILURE = `${ ENVIRONMENT_PREFIX }/FETCH_ADDON_INFO_FAILURE`
export const CLEAR_ADDON_INFO = `${ ENVIRONMENT_PREFIX }/CLEAR_ADDON_INFO`
export const FETCH_INSTALLED_ADDONS = `${ ENVIRONMENT_PREFIX }/FETCH_INSTALLED_ADDONS`
export const FETCH_INSTALLED_ADDONS_SUCCESS = `${ ENVIRONMENT_PREFIX }/FETCH_INSTALLED_ADDONS_SUCCESS`
export const FETCH_INSTALLED_ADDONS_FAILURE = `${ ENVIRONMENT_PREFIX }/FETCH_INSTALLED_ADDONS_FAILURE`
export const FETCH_ADDONS_STATUS_INITIATE = `${ ENVIRONMENT_PREFIX }/FETCH_ADDONS_STATUS_INITIATE`
export const FETCH_ADDONS_STATUS = `${ ENVIRONMENT_PREFIX }/FETCH_ADDONS_STATUS`
export const FETCH_ADDONS_STATUS_SUCCESS = `${ ENVIRONMENT_PREFIX }/FETCH_ADDONS_STATUS_SUCCESS`
export const FETCH_ADDONS_STATUS_FAILURE = `${ ENVIRONMENT_PREFIX }/FETCH_ADDONS_STATUS_FAILURE`
export const CLEAR_ADDON_STATUS = `${ ENVIRONMENT_PREFIX }/CLEAR_ADDON_STATUS`
export const INSTALL_ADDON = `${ ENVIRONMENT_PREFIX }/INSTALL_ADDON`
export const INSTALL_ADDON_SUCCESS = `${ ENVIRONMENT_PREFIX }/INSTALL_ADDON_SUCCESS`
export const INSTALL_ADDON_FAILURE = `${ ENVIRONMENT_PREFIX }/INSTALL_ADDON_FAILURE`
export const MODIFY_ADDON = `${ ENVIRONMENT_PREFIX }/MODIFY_ADDON`
export const MODIFY_ADDON_SUCCESS = `${ ENVIRONMENT_PREFIX }/MODIFY_ADDON_SUCCESS`
export const MODIFY_ADDON_FAILURE = `${ ENVIRONMENT_PREFIX }/MODIFY_ADDON_FAILURE`
export const UNINSTALL_ADDON = `${ ENVIRONMENT_PREFIX }/UNINSTALL_ADDON`
export const UNINSTALL_ADDON_SUCCESS = `${ ENVIRONMENT_PREFIX }/UNINSTALL_ADDON_SUCCESS`
export const UNINSTALL_ADDON_FAILURE = `${ ENVIRONMENT_PREFIX }/UNINSTALL_ADDON_FAILURE`
export const FETCH_HPA_INSIGHT = `${ ENVIRONMENT_PREFIX }/FETCH_HPA_INSIGHT`
export const FETCH_HPA_INSIGHT_SUCCESS = `${ ENVIRONMENT_PREFIX }/FETCH_HPA_INSIGHT_SUCCESS`
export const FETCH_HPA_INSIGHT_FAILURE = `${ ENVIRONMENT_PREFIX }/FETCH_HPA_INSIGHT_FAILURE`
export const SET_CURRENT_ENVIRONMENT = `${ ENVIRONMENT_PREFIX }/SET_CURRENT_ENVIRONMENT`
export const CLEAR_CURRENT_ENVIRONMENT = `${ ENVIRONMENT_PREFIX }/CLEAR_CURRENT_ENVIRONMENT`
export const FETCH_ENVIRONMENT_PODS = `${ ENVIRONMENT_PREFIX }/FETCH_ENVIRONMENT_PODS`
export const FETCH_ENVIRONMENT_PODS_SUCCESS = `${ ENVIRONMENT_PREFIX }/FETCH_ENVIRONMENT_PODS_SUCCESS`
export const FETCH_ENVIRONMENT_PODS_FAILURE = `${ ENVIRONMENT_PREFIX }/FETCH_ENVIRONMENT_PODS_FAILURE`

export const START_ENV = `${ ENVIRONMENT_PREFIX }/START_ENV`
export const START_ENV_SUCCESS = `${ ENVIRONMENT_PREFIX }/START_ENV_SUCCESS`
export const START_ENV_FAILURE = `${ ENVIRONMENT_PREFIX }/START_ENV_FAILURE`

export const STOP_ENV = `${ ENVIRONMENT_PREFIX }/STOP_ENV`
export const STOP_ENV_SUCCESS = `${ ENVIRONMENT_PREFIX }/STOP_ENV_SUCCESS`
export const STOP_ENV_FAILURE = `${ ENVIRONMENT_PREFIX }/STOP_ENV_FAILURE`

export const UPDATE_ADDON_STATUS = `${ ENVIRONMENT_PREFIX }/UPDATE_ADDON_STATUS`
export const UPDATE_ENV_STATUS = `${ ENVIRONMENT_PREFIX }/UPDATE_ENV_STATUS`

export const CLEAR_PLUGIN_VERSION_CONFIG = `${ ENVIRONMENT_PREFIX }/CLEAR_PLUGIN_VERSION_CONFIG`

export const ROLLBACK_ENV = `${ ENVIRONMENT_PREFIX }/ROLLBACK_ENV`
export const ROLLBACK_ENV_SUCCESS = `${ ENVIRONMENT_PREFIX }/ROLLBACK_ENV_SUCCESS`
export const ROLLBACK_ENV_FAILURE = `${ ENVIRONMENT_PREFIX }/ROLLBACK_ENV_FAILURE`

export const FETCH_ENV_WORKFLOW = `${ ENVIRONMENT_PREFIX }/FETCH_ENV_WORKFLOW`
export const FETCH_ENV_WORKFLOW_SUCCESS = `${ ENVIRONMENT_PREFIX }/FETCH_ENV_WORKFLOW_SUCCESS`
export const FETCH_ENV_WORKFLOW_FAILURE = `${ ENVIRONMENT_PREFIX }/FETCH_ENV_WORKFLOW_FAILURE`

export const CREATE_STORAGE = `${ ENVIRONMENT_PREFIX }/CREATE_STORAGE`
export const CREATE_STORAGE_SUCCESS = `${ ENVIRONMENT_PREFIX }/CREATE_STORAGE_SUCCESS`
export const CREATE_STORAGE_FAILURE = `${ ENVIRONMENT_PREFIX }/CREATE_STORAGE_FAILURE`

export const GET_STORAGE = `${ ENVIRONMENT_PREFIX }/GET_STORAGE`
export const GET_STORAGE_SUCCESS = `${ ENVIRONMENT_PREFIX }/GET_STORAGE_SUCCESS`
export const GET_STORAGE_FAILURE = `${ ENVIRONMENT_PREFIX }/GET_STORAGE_FAILURE`

export const EDIT_STORAGE = `${ ENVIRONMENT_PREFIX }/EDIT_STORAGE`
export const EDIT_STORAGE_SUCCESS = `${ ENVIRONMENT_PREFIX }/EDIT_STORAGE_SUCCESS`
export const EDIT_STORAGE_FAILURE = `${ ENVIRONMENT_PREFIX }/EDIT_STORAGE_FAILURE`

export const DELETE_STORAGE = `${ ENVIRONMENT_PREFIX }/DELETE_STORAGE`
export const DELETE_STORAGE_SUCCESS = `${ ENVIRONMENT_PREFIX }/DELETE_STORAGE_SUCCESS`
export const DELETE_STORAGE_FAILURE = `${ ENVIRONMENT_PREFIX }/DELETE_STORAGE_FAILURE`

export const CLEAR_STORAGE_DATA = `${ ENVIRONMENT_PREFIX }/CLEAR_STORAGE_DATA`

export const FETCH_ADDON_CATEGORIES = `${ENVIRONMENT_PREFIX}/FETCH_ADDON_CATEGORIES`
export const FETCH_ADDON_CATEGORIES_SUCCESS = `${ENVIRONMENT_PREFIX}/FETCH_ADDON_CATEGORIES_SUCCESS`
export const FETCH_ADDON_CATEGORIES_FAILURE = `${ENVIRONMENT_PREFIX}/FETCH_ADDON_CATEGORIES_FAILURE`

export const INITIATE_FETCH_PACKAGE_STATUS = `${ENVIRONMENT_PREFIX}/INITIATE_FETCH_PACKAGE_STATUS`
export const INITIATE_FETCH_PACKAGE_STATUS_SUCCESS = `${ENVIRONMENT_PREFIX}/INITIATE_FETCH_PACKAGE_STATUS_SUCCESS`
export const INITIATE_FETCH_PACKAGE_STATUS_FAILURE = `${ENVIRONMENT_PREFIX}/INITIATE_FETCH_PACKAGE_STATUS_FAILURE`

export const UPDATE_PACKAGE_STATUS = `${ENVIRONMENT_PREFIX}/UPDATE_PACKAGE_STATUS`

export const CLEAR_ENV_OVERVIEW_INFO = `${ENVIRONMENT_PREFIX}/CLEAR_ENV_OVERVIEW_INFO`
export const CLEAR_ROLLBACK_POPUP_INFO = `${ENVIRONMENT_PREFIX}/CLEAR_ROLLBACK_POPUP_INFO`

export const UPDATE_CUSTOM_DOMAIN = `${ENVIRONMENT_PREFIX}/UPDATE_CUSTOM_DOMAIN`
export const UPDATE_CUSTOM_DOMAIN_SUCCESS = `${ENVIRONMENT_PREFIX}/UPDATE_CUSTOM_DOMAIN_SUCCESS`
export const UPDATE_CUSTOM_DOMAIN_FAILURE = `${ENVIRONMENT_PREFIX}/UPDATE_CUSTOM_DOMAIN_FAILURE`
export const UPDATE_DOMAIN_STATUS = `${ENVIRONMENT_PREFIX}/UPDATE_DOMAIN_STATUS`
//export const RECHECK_DOMAIN = `${ENVIRONMENT_PREFIX}/RECHECK_DOMAIN`

export const FETCH_ENV_SERVICE_DETAILS = `${ENVIRONMENT_PREFIX}/FETCH_ENV_SERVICE_DETAILS`
export const UPDATE_ENV_SERVICE_DETAILS = `${ENVIRONMENT_PREFIX}/UPDATE_ENV_SERVICE_DETAILS`

export const UPDATE_ENV_SCHEDULE = `${ENVIRONMENT_PREFIX}/UPDATE_ENV_SCHEDULE`
export const UPDATE_ENV_SCHEDULE_SUCCESS = `${ENVIRONMENT_PREFIX}/UPDATE_ENV_SCHEDULE_SUCCESS`
export const UPDATE_ENV_SCHEDULE_FAILURE = `${ENVIRONMENT_PREFIX}/UPDATE_ENV_SCHEDULE_FAILURE`
export const UPDATE_ENV_SCHEDULE_LOG = `${ENVIRONMENT_PREFIX}/UPDATE_ENV_SCHEDULE_LOG`
export const UPDATE_ENV_SCHEDULE_LOG_SUCCESS = `${ENVIRONMENT_PREFIX}/UPDATE_ENV_SCHEDULE_LOG_SUCCESS`
export const UPDATE_ENV_SCHEDULE_LOG_FAILURE = `${ENVIRONMENT_PREFIX}/UPDATE_ENV_SCHEDULE_LOG_FAILURE`

export const UPDATE_FILE_MANAGER_STATUS = `${ENVIRONMENT_PREFIX}/UPDATE_FILE_MANAGER_STATUS`
export const UPDATE_FILE_MANAGER_STATUS_SUCCESS = `${ENVIRONMENT_PREFIX}/UPDATE_FILE_MANAGER_STATUS_SUCCESS`
export const UPDATE_FILE_MANAGER_STATUS_FAILURE = `${ENVIRONMENT_PREFIX}/UPDATE_FILE_MANAGER_STATUS_FAILURE`

export const UPDATE_GIT_BRANCH = `${ENVIRONMENT_PREFIX}/UPDATE_GIT_BRANCH`
export const UPDATE_GIT_BRANCH_SUCCESS = `${ENVIRONMENT_PREFIX}/UPDATE_GIT_BRANCH_SUCCESS`
export const UPDATE_GIT_BRANCH_FAILURE = `${ENVIRONMENT_PREFIX}/UPDATE_GIT_BRANCH_FAILURE`

export const CLONE_ENVIRONMENT = `${ENVIRONMENT_PREFIX}/CLONE_ENVIRONMENT`
export const CLONE_ENVIRONMENT_SUCCESS = `${ENVIRONMENT_PREFIX}/CLONE_ENVIRONMENT_SUCCESS`
export const CLONE_ENVIRONMENT_FAILURE = `${ENVIRONMENT_PREFIX}/CLONE_ENVIRONMENT_FAILURE`

export const UPDATE_EXTERNAL_SECRET = `${ENVIRONMENT_PREFIX}/UPDATE_EXTERNAL_SECRET`
export const UPDATE_EXTERNAL_SECRET_SUCCESS = `${ENVIRONMENT_PREFIX}/UPDATE_EXTERNAL_SECRET_SUCCESS`
export const UPDATE_EXTERNAL_SECRET_FAILURE = `${ENVIRONMENT_PREFIX}/UPDATE_EXTERNAL_SECRET_FAILURE`

export const UPDATE_SECRET_SYNC = `${ENVIRONMENT_PREFIX}/UPDATE_SECRET_SYNC`

export const FETCH_SECRET_LOGS = `${ENVIRONMENT_PREFIX}/FETCH_SECRET_LOGS`
export const FETCH_SECRET_LOGS_SUCCESS = `${ENVIRONMENT_PREFIX}/FETCH_SECRET_LOGS_SUCCESS`

export const UPDATE_EXTERNAL_LOGGER = `${ENVIRONMENT_PREFIX}/UPDATE_EXTERNAL_LOGGER`
export const UPDATE_EXTERNAL_LOGGER_SUCCESS = `${ENVIRONMENT_PREFIX}/UPDATE_EXTERNAL_LOGGER_SUCCESS`
export const UPDATE_EXTERNAL_LOGGER_FAILURE = `${ENVIRONMENT_PREFIX}/UPDATE_EXTERNAL_LOGGER_FAILURE`

export const UPDATE_ENVIRONMENT_ERROR_STATUS=`${ENVIRONMENT_PREFIX}/UPDATE_ENVIRONMENT_ERROR_STATUS`

export const UPDATE_ENV_NAME = `${ENVIRONMENT_PREFIX}/UPDATE_ENV_NAME`

export const FETCH_SYSTEM_VARIABLE_CONFIG = `${ ENVIRONMENT_PREFIX }/FETCH_SYSTEM_VARIABLE_CONFIG`
export const FETCH_SYSTEM_VARIABLE_CONFIG_SUCCESS = `${ ENVIRONMENT_PREFIX }/FETCH_SYSTEM_VARIABLE_CONFIG_SUCCESS`

export const FETCH_LOGGER_CONFIG = `${ENVIRONMENT_PREFIX}/FETCH_LOGGER_CONFIG`
export const FETCH_LOGGER_CONFIG_SUCCESS = `${ENVIRONMENT_PREFIX}/FETCH_LOGGER_CONFIG_SUCCESS`

export const FETCH_SCANNER_PLUGINS = `${ENVIRONMENT_PREFIX}/FETCH_SCANNER_PLUGINS`
export const FETCH_SCANNER_PLUGINS_SUCCESS = `${ENVIRONMENT_PREFIX}/FETCH_SCANNER_PLUGINS_SUCCESS`
export const FETCH_SCANNER_PLUGINS_FAILURE = `${ENVIRONMENT_PREFIX}/FETCH_SCANNER_PLUGINS_FAILURE`

export const START_SCAN = `${ENVIRONMENT_PREFIX}/START_SCAN`

export const FETCH_SCAN_LIST = `${ENVIRONMENT_PREFIX}/FETCH_SCAN_LIST`
export const FETCH_SCAN_LIST_SUCCESS = `${ENVIRONMENT_PREFIX}/FETCH_SCAN_LIST_SUCCESS`
export const FETCH_SCAN_LIST_FAILURE = `${ENVIRONMENT_PREFIX}/FETCH_SCAN_LIST_FAILURE`

export const FETCH_SPECIFIC_REPORT = `${ENVIRONMENT_PREFIX}/FETCH_SPECIFIC_REPORT`
export const FETCH_SPECIFIC_REPORT_SUCCESS = `${ENVIRONMENT_PREFIX}/FETCH_SPECIFIC_REPORT_SUCCESS`
export const FETCH_SPECIFIC_REPORT_FAILURE = `${ENVIRONMENT_PREFIX}/FETCH_SPECIFIC_REPORT_FAILURE`

export const TOGGLE_EXTERNAL_URL = `${ENVIRONMENT_PREFIX}/TOGGLE_EXTERNAL_URL`
export const TOGGLE_EXTERNAL_URL_SUCCESS = `${ENVIRONMENT_PREFIX}/TOGGLE_EXTERNAL_URL_SUCCESS`
export const TOGGLE_EXTERNAL_URL_FAILURE = `${ENVIRONMENT_PREFIX}/TOGGLE_EXTERNAL_URL_FAILURE`

export const CLEAR_SPECIFIC_REPORT = `${ENVIRONMENT_PREFIX}/CLEAR_SPECIFIC_REPORT`
export const CLEAR_SCAN_LIST = `${ENVIRONMENT_PREFIX}/CLEAR_SCAN_LIST`

export const UPDATE_IP_WHITELISTING = `${ENVIRONMENT_PREFIX}/UPDATE_IP_WHITELISTING`
export const UPDATE_IP_WHITELISTING_SUCCESS = `${ENVIRONMENT_PREFIX}/UPDATE_IP_WHITELISTING_SUCCESS`
export const UPDATE_IP_WHITELISTING_FAILURE = `${ENVIRONMENT_PREFIX}/UPDATE_IP_WHITELISTING_FAILURE`

export const UPDATE_EXTERNAL_URL = `${ENVIRONMENT_PREFIX}/UPDATE_EXTERNAL_URL`
export const UPDATE_EXTERNAL_URL_SUCCESS = `${ENVIRONMENT_PREFIX}/UPDATE_EXTERNAL_URL_SUCCESS`
export const UPDATE_EXTERNAL_URL_FAILURE = `${ENVIRONMENT_PREFIX}/UPDATE_EXTERNAL_URL_FAILURE`

const fetchEnvDetails = (id) => ({
	type: FETCH_ENVIRONMENT_DETAIL,
	data: { id }
})

const startStopEnvironment = (id, isActive) => ({
	type: STARTSTOP_ENVIRONMENT,
	data: { id, isActive }
})

const deleteEnvironment = (id, app_id, history) => ({
	type: DELETE_ENVIRONMENT,
	data: { id, app_id, history }
})

const fetchEnvVariables = (id) => ({
	type: FETCH_ENV_VARIABLES,
	data: { id }
})

const updateEnvVariables = (id, jsonBody) => ({
	type: UPDATE_ENV_VARIABLES,
	data: { id, jsonBody }
})

const createEnv = (jsonBody, triggerPayload, history) => ({
	type: CREATE_ENVIRONMENT,
	data: { jsonBody, triggerPayload, history }
})

const fetchResources = () => ({
    type: FETCH_RESOURCES,
})

const fetchEnvLogs = (id, duration) => ({
	type: FETCH_ENV_LOGS,
	data: { id, duration }
})

const fetchEnvLogsStatus = (id) => ({
	type: FETCH_ENV_LOGS_STATUS,
	data: { id }
})

const updateEnvironment = (id, jsonBody, source, changeHash, history) => ({
	type: UPDATE_ENVIRONMENT,
	data: { id, jsonBody, source, changeHash, history }
})

const fetchPlugInVersionConfig = (id) => ({
	type: FETCH_PLUGIN_VERSION_CONFIG,
	data: { id }
})

const clearEnvLogsStatus = () =>({ type:CLEAR_ENV_LOGS_STATUS })

const clearEnvActivities = () => ({ type: CLEAR_ENV_ACTIVITIES})

const clearEnvInsights = () => ({ type: CLEAR_ENV_INSIGHTS})

const clearEnvironmentdetails = () => ({type: CLEAR_ENVIRONMENT_DETAIL})

const clearPluginVersionConfig = () => ({type: CLEAR_PLUGIN_VERSION_CONFIG})

const fetchEnvironmentInsights = (id, payload) => ({
	type: FETCH_ENVIRONMENT_INSIGHT,
	data: { id , payload}
})
const fetchEnvironmentOverViewInsights = (id, payload) => ({
	type: FETCH_ENVIRONMENT_OVERVIEW_INSIGHT,
	data: { id , payload}
})

const fetchEnvironmentStateInitiate = (id, initial) => ({
	type: FETCH_ENV_STATE_INITIATE,
	data: { id, initial }
})

const fetchEnvironmentState = (id) => ({
	type: FETCH_ENV_STATE,
	data: { id }
})

const changeEnvTabValue = (val) => ({
	type: CHANGE_ENV_TAB_VAL,
	data: {
		val
	}
})

const fetchEnvRole = (id) => ({
	type: FETCH_ENV_ROLE,
	data: { id }
})

const fetchActivityLogs = (id) => ({
	type: FETCH_ACTIVITY_LOGS,
	data: { id }
})

export const clearEnvOverviewInfo = () => ({
	type: CLEAR_ENV_OVERVIEW_INFO,
})

const updateActivityLogs = (logs) => ({
	type: UPDATE_ACTIVITY_LOGS,
	data : {
		logs
	}
})

const reCreateEnvironment = (id, payload, changeHash) => ({
	type: RECREATE_ENV,
	data: { id, payload, changeHash }
}) 

const fetchAddonCategorys = (id) => (
	{
		type: FETCH_ADDON_CATEGORIES,
		data: { id }
	}
)

const fetchAddons = (id, installed, category, searchText) => (
	{
		type: FETCH_ADDONS, 
		data: { id, installed, category, searchText }
	}
)
const getInstalledAddons = (id) => (
	{
		type: FETCH_INSTALLED_ADDONS, 
		data : { id }
	}
)

const installAddon = (eId, aId, payload, history, changeHash) => (
	{
		type: INSTALL_ADDON,
		data: {
			eId,
			aId,
			payload,
			history,
			changeHash
		}
	}
)

const modifyAddon = (eId, aId, payload, history, changeHash) => (
	{
		type: MODIFY_ADDON,
		data: {
			eId,
			aId,
			payload,
			history,
			changeHash
		}
	}
)

const fetchAddonInfo = (aId, eId) => {
	return {
		type: FETCH_ADDON_INFO,
		data:{
			aId, 
			eId
		}
	}
}

export const clearAddonInfo = () => {
	return {
		type: CLEAR_ADDON_INFO
	}
}

const uninstallAddon = (eId, aId) => (
	{
		type: UNINSTALL_ADDON,
		data: {
			eId,
			aId
		}
	}
)
const getAddonStatus = (id) => (
	{
		type: FETCH_ADDONS_STATUS,
		data: {
			id,
		}
	}
)

const initiateAddonStatus = (id) => (
	{
		type: FETCH_ADDONS_STATUS_INITIATE,
		data: {
			id,
		}
	}
)

const fetchHpaInsight = (id, payload) => ({
	type: FETCH_HPA_INSIGHT,
	data: { id , payload}
})

const fetchPods = (id) => ({
	type: FETCH_ENVIRONMENT_PODS,
	data: { id }
})

const setCurrentEnvironment = (id) => {
	return { type: SET_CURRENT_ENVIRONMENT, data: {id} }
 }
const clearCurrentEnvironment = () => ({ type: CLEAR_CURRENT_ENVIRONMENT })

const startEnvironment = (id) => {
	return({
		type: START_ENV,
		data: {id}
	})
}

const stopEnvironment = (id, changeHash, history) => {
	return({
		type: STOP_ENV,
		data: {id, changeHash, history}
	})
}

const updateAddonStatus = (payload) => {
	return({
		type: UPDATE_ADDON_STATUS,
		data: payload
	})
}

export const updateEnvStatus = (status) => {
	return({
		type: UPDATE_ENV_STATUS,
		data: {status}
	})
}

export const clearAddons = () => {
	return({
		type: CLEAR_ADDON_STATUS,
	})
}

export const fetchBuildImages = (id) => (
	{
		type: FETCH_ENV_WORKFLOW,
		data : {
			id
		}
	}
) 

export const clearRollbackPopupInfo = () => (
	{
		type: CLEAR_ROLLBACK_POPUP_INFO,
	}
) 

export const rollbackEnv = (id, workflow, changeHash) => (
	{
		type: ROLLBACK_ENV,
		data : {
			id,
			workflow,
			changeHash
		}
	}
) 

export const getStorage = (id) => (
	{
		type: GET_STORAGE,
		data: {id}
	}
)

export const createStorage = (id, payload) => (
	{
		type: CREATE_STORAGE,
		data: {
				id,
				payload	,
			}
	}
)

export const editStorage = (id, sId, payload) => (
	{
		type: EDIT_STORAGE,
		data:{
			id,
			sId,
			payload,
		}
	}
)

export const deleteStorage = (id, sId) => (
	{
		type: DELETE_STORAGE,
		data:{
			id,
			sId,		
		}
	}
)

export const clearStorageData = () => (
	{
		type: CLEAR_STORAGE_DATA,	
	}
)

export const initiateFetchPackageStatus = (id) => (
	{
		type: INITIATE_FETCH_PACKAGE_STATUS,
		data:{
			id
		}
	}
)

export const updatePackageStatus = (status) => (
	{
		type: UPDATE_PACKAGE_STATUS,
		data: {
			status
		}
	}
)

export const updateCustomDomain = (id, jsonBody, callBack) => (
	{
		type: UPDATE_CUSTOM_DOMAIN,
		data: { id, jsonBody, callBack }
	}
)

export const updateDomainStatus = (status) => (
	{
		type: UPDATE_DOMAIN_STATUS,
		data: { status }
	}
)

export const fetchServiceDetails = (id) => (
	{
		type: FETCH_ENV_SERVICE_DETAILS,
		data: { id }
	}
)

export const updateEnvServiceDetails = (payload) => (
	{
		type: UPDATE_ENV_SERVICE_DETAILS,
		data: { payload }
	}
)

export const updateEnvSchedule = (id, jsonBody, isHelm) => ({
	type: UPDATE_ENV_SCHEDULE,
	data: { id, jsonBody, isHelm }
})

export const fetchScheduleLog = (eId, isHelm) => ({
	type: UPDATE_ENV_SCHEDULE_LOG,
	data: { eId, isHelm }
})

export const updateFileManagerStatus = (id, status) => ({
	type: UPDATE_FILE_MANAGER_STATUS,
	data: {id,status}
}); 

// export const recheckDomain = (id, callback) => ({
// 	type: RECHECK_DOMAIN,
// 	data: { id, callback }
// })

export const updateGitBranch = (eId, jsonBody, service_type, callback) => ({
	type: UPDATE_GIT_BRANCH,
	data: { eId, jsonBody, service_type, callback },
});

export const cloneEnvironment = (eId, jsonBody, callBack) => ({
	type: CLONE_ENVIRONMENT,
	data: { eId, jsonBody, callBack },
});

export const updateExternalSecret = (eId, external_secret) => ({
	type: UPDATE_EXTERNAL_SECRET,
	data: { eId, external_secret },
});

export const updateExternalLogger = (eId, external_secret) => ({
	type: UPDATE_EXTERNAL_LOGGER,
	data: { eId, external_secret },
});

export const updateSecretSync = (eId) => ({
	type: UPDATE_SECRET_SYNC,
	data: {eId}
})

export const fetchSecretLogs = (eId) => ({
	type: FETCH_SECRET_LOGS,
	data: { eId }
})

export const updateEnvErrStatus=(errSts)=>({
	type:UPDATE_ENVIRONMENT_ERROR_STATUS,
	data:{errSts}
})

export const updateEnvName = (id, name)=>({
	type: UPDATE_ENV_NAME,
	data:{id, name}
})

const getSettingVariableConfig = (pid) => ({
    type: FETCH_SYSTEM_VARIABLE_CONFIG,
	data:{pid}
})

export const fetchLoggerConfig = () => ({
	type: FETCH_LOGGER_CONFIG,
})

export const fetchScannerPlugins = (eId) => ({
	type: FETCH_SCANNER_PLUGINS,
	data: {eId},
})

export const startScan = (eId, plugin, callBack) => ({
	type: START_SCAN,
	data: {eId, plugin, callBack},
})

export const fetchScanList = (eId) => ({
	type: FETCH_SCAN_LIST,
	data: {eId},
})

export const fetchSpecificReport = (eId, listId) => ({
	type: FETCH_SPECIFIC_REPORT,
	data: {eId, listId},
})

export const toggleExternalUrlAction=(eid,aid,status)=>({
	type:TOGGLE_EXTERNAL_URL,
	data:{eid,aid,status}
})

export const clearSpecificReport = () => ({
	type: CLEAR_SPECIFIC_REPORT
})

export const clearScanList = () => ({
	type: CLEAR_SCAN_LIST
})

export const updateIPWhitelisting = (id,jsonData) => ({
	type: UPDATE_IP_WHITELISTING,
	data: {id,jsonData}
})

export const updateExternalUrl = (id,status) => ({
	type: UPDATE_EXTERNAL_URL,
	data: {id,status}
})

export {
	fetchEnvDetails,
	startStopEnvironment,
	deleteEnvironment,
	fetchEnvVariables,
	updateEnvVariables,
	createEnv,
	fetchResources,
	fetchEnvLogs,
	fetchEnvLogsStatus,
	clearEnvLogsStatus,
	clearEnvActivities,
	clearEnvInsights,
	updateEnvironment,
	fetchPlugInVersionConfig,
	clearEnvironmentdetails,
	fetchEnvironmentInsights,
	fetchEnvironmentOverViewInsights,
	fetchEnvironmentStateInitiate,
	fetchEnvironmentState,
	changeEnvTabValue,
	fetchEnvRole,
	fetchActivityLogs,
	updateActivityLogs,
	reCreateEnvironment,
	fetchAddons,
	getInstalledAddons,
	installAddon,
	modifyAddon,
	fetchAddonInfo,
	uninstallAddon,
	getAddonStatus,
	initiateAddonStatus,
	fetchHpaInsight,
	setCurrentEnvironment,
	clearCurrentEnvironment,
	fetchPods,
	startEnvironment,
	stopEnvironment,
	updateAddonStatus,
	clearPluginVersionConfig,
	fetchAddonCategorys,
	getSettingVariableConfig,
}