import initialState from './initialState';
import { 
  CLEAR_USER_LIST_IN_PROJECT,
  USERS_LIST_IN_ENVIRONMENT,
  CLEAR_USERS_LIST_IN_ENVIRONMENT,
  FETCH_ROLES_SUCCESS,
  ADD_USER_IN_ENVIRONMENT,
  ADD_USER_IN_ENVIRONMENT_FAILURE,
  ADD_USER_IN_ENVIRONMENT_SUCCESS,
  ADD_USER_IN_PROJECT,
  ADD_USER_IN_PROJECT_FAILURE,
  ADD_USER_IN_PROJECT_SUCCESS,
  EDIT_USER_IN_ENVIRONMENT,
  EDIT_USER_IN_ENVIRONMENT_FAILURE,
  EDIT_USER_IN_ENVIRONMENT_SUCCESS,
  EDIT_USER_IN_PROJECT,
  EDIT_USER_IN_PROJECT_FAILURE,
  EDIT_USER_IN_PROJECT_SUCCESS,
  DELETE_USERS_IN_ENVIRONMENT,
  DELETE_USERS_IN_ENVIRONMENT_FAILURE,
  DELETE_USERS_IN_ENVIRONMENT_SUCCESS,
  DELETE_USERS_IN_PROJECT,
  DELETE_USERS_IN_PROJECT_FAILURE,
  DELETE_USERS_IN_PROJECT_SUCCESS,
  FETCH_USER_LIST_IN_PROJECT,
  FETCH_USER_LIST_IN_PROJECT_FAILURE,
  FETCH_USER_LIST_IN_PROJECT_SUCCESS,
  FETCH_USER_LIST_IN_ENVIRONMENT_FAILURE,
  FETCH_USER_LIST_IN_ENVIRONMENT,
  FETCH_USER_LIST_IN_HELM_ENVIRONMENT,
  FETCH_USER_LIST_IN_HELM_ENVIRONMENT_FAILURE,
  FETCH_USER_LIST_IN_HELM_ENVIRONMENT_SUCCESS,
  CLEAR_USERS_LIST_IN_HELM_ENVIRONMENT,
  ADD_USER_IN_HELM_ENVIRONMENT,
  ADD_USER_IN_HELM_ENVIRONMENT_FAILURE,
  ADD_USER_IN_HELM_ENVIRONMENT_SUCCESS,
  EDIT_USER_IN_HELM_ENVIRONMENT,
  EDIT_USER_IN_HELM_ENVIRONMENT_FAILURE,
  EDIT_USER_IN_HELM_ENVIRONMENT_SUCCESS,
  DELETE_USERS_IN_HELM_ENVIRONMENT,
  DELETE_USERS_IN_HELM_ENVIRONMENT_FAILURE,
  DELETE_USERS_IN_HELM_ENVIRONMENT_SUCCESS,
} from './actions';

const reducer = (state = initialState, payload ) => {
  switch (payload.type) {
    case FETCH_USER_LIST_IN_PROJECT :
      return {
        ...state,
        // usersListInProject : payload.data,
        fetchingUserList :  true
      }
    case FETCH_USER_LIST_IN_PROJECT_SUCCESS:
      return {
        ...state,
        usersListInProject : payload.data,
        fetchingUserList: false
      }
    case FETCH_USER_LIST_IN_PROJECT_FAILURE :
      return {
        ...state,
        fetchingUserList: false
      }
    case CLEAR_USER_LIST_IN_PROJECT : 
      return {
        ...state,
        usersListInProject : initialState.usersListInProject
      }
    case FETCH_USER_LIST_IN_ENVIRONMENT :
      return {
        ...state,
        fetchingUserList: true
      }
    case FETCH_USER_LIST_IN_ENVIRONMENT_FAILURE :
      return {
        ...state,
        fetchingUserList: false
      }
    case USERS_LIST_IN_ENVIRONMENT :
      return {
        ...state,
        usersListInEnvironment : payload.data,
        fetchingUserList: false
      }
    case FETCH_USER_LIST_IN_HELM_ENVIRONMENT :
      return {
        ...state,
        fetchingUserList: true
      }
    case FETCH_USER_LIST_IN_HELM_ENVIRONMENT_FAILURE :
      return {
        ...state,
        fetchingUserList: false
      }
    case FETCH_USER_LIST_IN_HELM_ENVIRONMENT_SUCCESS :
      return {
        ...state,
        usersListInHelmEnvironment : payload.data,
        fetchingUserList: false
      }
    case CLEAR_USERS_LIST_IN_ENVIRONMENT : 
      return {
        ...state,
        usersListInEnvironment : initialState.usersListInEnvironment,
      }
    case CLEAR_USERS_LIST_IN_HELM_ENVIRONMENT : 
      return {
        ...state,
        usersListInHelmEnvironment : initialState.usersListInHelmEnvironment,
      }
    case FETCH_ROLES_SUCCESS :
      return {
        ...state,
        rolesData : payload.data
      }
    case ADD_USER_IN_ENVIRONMENT :
      return {
        ...state,
        addingUser: true
      }
    case ADD_USER_IN_ENVIRONMENT_SUCCESS :
      return {
        ...state,
        addingUser: false
      }
    case ADD_USER_IN_ENVIRONMENT_FAILURE :
      return {
        ...state,
        addingUser: false
      }
    case ADD_USER_IN_HELM_ENVIRONMENT :
      return {
        ...state,
        addingUser: true
      }
    case ADD_USER_IN_HELM_ENVIRONMENT_SUCCESS :
      return {
        ...state,
        addingUser: false
      }
    case ADD_USER_IN_HELM_ENVIRONMENT_FAILURE :
      return {
        ...state,
        addingUser: false
      }
    case ADD_USER_IN_PROJECT :
      return {
        ...state,
        addingUser: true
      }
    case ADD_USER_IN_PROJECT_SUCCESS :
      return {
        ...state,
        addingUser: false
      }
    case ADD_USER_IN_PROJECT_FAILURE :
      return {
        ...state,
        addingUser: false
      }
    case EDIT_USER_IN_ENVIRONMENT :
      return {
        ...state,
        editingUser: true
      }
    case EDIT_USER_IN_ENVIRONMENT_SUCCESS :
      return {
        ...state,
        editingUser: false
      }
    case EDIT_USER_IN_ENVIRONMENT_FAILURE :
      return {
        ...state,
        editingUser: false
      }
    case EDIT_USER_IN_HELM_ENVIRONMENT :
      return {
        ...state,
        editingUser: true
      }
    case EDIT_USER_IN_HELM_ENVIRONMENT_SUCCESS :
      return {
        ...state,
        editingUser: false
      }
    case EDIT_USER_IN_HELM_ENVIRONMENT_FAILURE :
      return {
        ...state,
        editingUser: false
      }
    case EDIT_USER_IN_PROJECT :
      return {
        ...state,
        editingUser: true
      }
    case EDIT_USER_IN_PROJECT_SUCCESS :
      return {
        ...state,
        editingUser: false
      }
    case  EDIT_USER_IN_PROJECT_FAILURE :
      return {
        ...state,
        editingUser: false
      }
    case DELETE_USERS_IN_ENVIRONMENT :
      return {
        ...state,
        deletingUser: true
      }
    case DELETE_USERS_IN_ENVIRONMENT_SUCCESS :
      return {
        ...state,
        deletingUser: false
      }
    case DELETE_USERS_IN_ENVIRONMENT_FAILURE :
      return {
        ...state,
        deletingUser: false
      }
    case DELETE_USERS_IN_HELM_ENVIRONMENT :
      return {
        ...state,
        deletingUser: true
      }
    case DELETE_USERS_IN_HELM_ENVIRONMENT_SUCCESS :
      return {
        ...state,
        deletingUser: false
      }
    case DELETE_USERS_IN_HELM_ENVIRONMENT_FAILURE :
      return {
        ...state,
        deletingUser: false
      }
    case DELETE_USERS_IN_PROJECT :
      return {
        ...state,
        deletingUser: true
      }
    case DELETE_USERS_IN_PROJECT_SUCCESS :
      return {
        ...state,
        deletingUser: false
      }
    case DELETE_USERS_IN_PROJECT_FAILURE :
      return {
        ...state,
        deletingUser: false
      }
    default:
      return state
  }
}

export default reducer