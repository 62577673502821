import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Loader from '../loader/Loader'
import { useTranslation } from 'react-i18next';
import { Transition } from "../../helpers/utils.ext";

interface Props {
  open: boolean,
  handleAgree:() => void,
  handleDisAgree: () => void,
  message: string|JSX.Element,
  loading?: boolean,
  yesText: string,
  noText?: string
}

export const Popup = (props: Props) => {
  const [t] = useTranslation()
  return (
      <Dialog
        open={ props.open }
        TransitionComponent={ Transition }
        keepMounted
        onClose={(_, reason) => {
          if (reason !== 'backdropClick') {
            props.handleDisAgree()
          }}
				}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        onEscapeKeyDown={ props.handleDisAgree }
        data-test="main-container"
      >
          <DialogTitle>
              <Typography className="dialogtitle">
                  <b> {t('Sure')} </b>
              </Typography>

              <IconButton
                aria-label="close"
                size="small"
                className="right"
                onClick={ props.handleDisAgree }
                data-test="close-popup"
              >
                  <CloseIcon />
              </IconButton>
          </DialogTitle>
          <DialogContent style={ { minWidth: 275 } } dividers>
              <Typography variant="subtitle1" data-test="popup-msg">{props.message}</Typography>
          </DialogContent>
          <DialogActions className={props.loading ? "centered" : ""}>
              {/* <Button color="secondary" onClick={props.handleDisAgree}>
          {props.noText}
        </Button> */}
              {!props.loading ? (
                  <Button
                    onClick={ props.handleAgree }
                    color="primary"
                    variant="contained"
                    data-test="agree-btn"
                  >
                      {props.yesText}
                  </Button>

        ) : (
            <div data-test="loader-div" >
                <Loader />
            </div>
        )}
          </DialogActions>
      </Dialog>
  );
};

export default Popup;
