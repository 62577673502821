import React, { useState, useEffect } from "react";
import { connect,ConnectedProps } from "react-redux";
import Scripts from "../../../../../components/scripts/Scripts";
import ConfirmActionPopup from "../../../../../components/confirmactionpopup/ConfirmActionPopup";
import BackdropLoader from "../../../../../components/loader/BackdropLoader";
import { useTranslation } from 'react-i18next';
import CIPipeline from "../../../../../components/scripts/CIPipeline";
import { Dispatch } from "redux";
import { ScriptsModel } from "../../../../../models/Environment.model";
import { changeEnvTabValue, updateEnvironment } from "../../../redux/actions";

interface Ipipeline {
  name: string;
  command: string
}
type Tpayload={scripts:ScriptsModel}|{};

interface Iprops extends PropsFromRedux{
  changeHash:(hash:string,val:number,envId:number)=>void
}
export function ScriptsTab(props:Iprops) {
  const [scripts, setScripts] = useState({});
  const [ciSteps, setCiSteps] = useState<Ipipeline[]>([]);
  const [confirmUpdate, setConfirmUpdate] = useState(false);
  const [confirmCIPUpdate, setConfirmCIPUpdate] = useState(false);
  const [isCISteps, setIsCISteps] = useState(false);
  const [t] = useTranslation()

  useEffect(() => {
    if (props.environmentDetails?.scripts) {
      setScripts(props.environmentDetails?.scripts);
      setCiSteps(props.environmentDetails?.scripts?.ci_steps);
    }
  }, [props.environmentDetails]);

  useEffect(() => {
    return(() => {
      setScripts({})
      setCiSteps([])
    })
  }, []);

  const handleScriptsChange = (_scripts:ScriptsModel) => {
    setScripts(_scripts);
  };

  const handleSubmit = () => {
    setConfirmUpdate(true);
  };

  const updateDisagreeHandler = () => {
    setConfirmUpdate(false);
  };

  const handleEnvironmentUpdate = () => {
    setConfirmUpdate(false);
    setIsCISteps(false);
    const payload:Tpayload = {
      scripts,
    };
    props.updateEnvironment(
      props.environmentDetails.id,
      payload,
      3,
      props.changeHash
    );

    props.environmentDetails && props.environmentDetails.service_type > 0
      ? props.changeEnvTabValue(3)
      : props.changeEnvTabValue(2);

  };

  const handleCIPChange = (steps:Ipipeline[]) => {
    setCiSteps(steps);
  }

  const handleCIPSave = () => {
    setConfirmCIPUpdate(true);
  }

  const updateCIPDisagreeHandler = () => {
    setConfirmCIPUpdate(false);
  };

  const handleCIPUpdate = () => {
    setConfirmCIPUpdate(false);
    setIsCISteps(true);
    const payload:Tpayload = {
      // scripts: {
      //   run: props.environmentDetails?.scripts?.run,
      //   build: props.environmentDetails?.scripts?.build,
      //   ci_steps: ciSteps
      // }
      scripts: { ...props.environmentDetails?.scripts, ci_steps: ciSteps }
    };
    props.updateEnvironment(
      props.environmentDetails.id,
      payload,
      3,
      props.changeHash
    );

    props.environmentDetails && props.environmentDetails.service_type > 0
      ? props.changeEnvTabValue(3)
      : props.changeEnvTabValue(2);
  }

  return (
    <div data-test="scriptsTabContainer">
      <Scripts
        source={2}
        readOnly={true}
        onChange={handleScriptsChange}
        scripts={props.environmentDetails?.scripts}
        handleSubmit={handleSubmit}
        envRole={props.envRole}
        data-test = "script-tab"
      />
      <div className="m-t-20">
        <CIPipeline
          source={2}
          onChange={handleCIPChange}
          pipeLine={props.environmentDetails?.scripts?.ci_steps}     
          handleSubmit={handleCIPSave}
          envRole={props.envRole}
        />
      </div>
      <ConfirmActionPopup
        open={confirmUpdate}
        handleAgree={handleEnvironmentUpdate}
        handleDisAgree={updateDisagreeHandler}
        message={t('Environment.ResourcesTab.sureUpdateScripts')}
        yesText={t('Projects.VariablesTab.yesText')}
        noText={t('Projects.VariablesTab.noText')}
        data-test="scripts-confirmation"
      />
      <ConfirmActionPopup
        open={confirmCIPUpdate}
        handleAgree={handleCIPUpdate}
        handleDisAgree={updateCIPDisagreeHandler}
        message={t('Environment.ResourcesTab.sureUpdateCISteps')}
        yesText={t('Projects.VariablesTab.yesText')}
        noText={t('Projects.VariablesTab.noText')}
        data-test="cisteps-confirmation"
      />
      {props.updatingEnv && <BackdropLoader message={isCISteps ? t('Environment.ResourcesTab.updatingCISteps') : t('Environment.ResourcesTab.updatingScripts')} data-test="backDropLoder"/>}
    </div>
  );
}

/* istanbul ignore next */
const mapStateToProps = (state:any) => ({
  environmentDetails: state.EnvironmentReducer.environmentDetails,
  updatingEnv: state.EnvironmentReducer.updatingEnv,
  envRole: state.EnvironmentReducer.envRole,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch:Dispatch) => {
  return {
    updateEnvironment: (id:number, jsonBody:Tpayload, source:number, changeHash:(hash:string,val:number,envId:number)=>void) =>
      dispatch(updateEnvironment(id, jsonBody, source, changeHash)),
    changeEnvTabValue: (id:number) => dispatch(changeEnvTabValue(id)),
  };
};

const connector=connect(mapStateToProps, mapDispatchtoProps)
export default connector(ScriptsTab);
type PropsFromRedux=ConnectedProps<typeof connector>
