import React from "react";
import { setValue, getValueFromEvent } from "./SchemaUtils";
//import { DeploymentEvent, IBasicFormParam } from "../../../shared/types";
//import { getValueFromEvent } from "../../../shared/utils";
import Param from "./Param";
import { isArray } from "lodash";
import { Grid } from "@material-ui/core";
// export interface ISubsectionProps {
//   label: string;
//   param: IBasicFormParam;
//   allParams: IBasicFormParam[];
//   appValues: string;
//   deploymentEvent: DeploymentEvent;
//   handleValuesChange: (value: string) => void;
// }

export function Subsection({
  label,
  param,
  allParams,
  appValues,
  deploymentEvent,
  handleValuesChange,
}) {
  const handleChildrenParamChange = (childrenParam) => {
    return (e) => {
      const value = getValueFromEvent(e);
      param.children = param.children.map(p =>
        p.path === childrenParam.path ? { ...childrenParam, value } : p,
      );
      handleValuesChange(setValue(appValues, childrenParam.path, value));
    };
  };

  return (
    <div className="subsection" data-test="main-container">
      <div>
        <label className="deployment-form-label" data-test="label-container">{label}</label>
        {param.description && (
          <>
            <br />
            <span className="description" data-test="description-container">{param.description}</span>
          </>
        )}
      </div>
      <Grid container spacing={2}>
      {param.children &&
        param.children.map((childrenParam, i) => {
          const id = `${childrenParam.path}-${i}`;
          const type = isArray(childrenParam.type) ? childrenParam.type[0] : childrenParam.type;
          return (
            <Grid key={i} item xs={12} sm={(type === "textArea" || type === "string" || type === "integer") ? 4 : 12}>
            <Param
              param={childrenParam}
              allParams={allParams}
              id={id}
              key={id}
              handleBasicFormParamChange={handleChildrenParamChange}
              deploymentEvent={deploymentEvent}
              appValues={appValues}
              handleValuesChange={handleValuesChange}
              data-test="children-param"
            />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

export default Subsection;
