//import _ from 'lodash';
import React, { useState, useEffect } from "react";
import MuiTextField from "../textfield/MuiTextField";
import { FormControl, Grid, IconButton, CardContent, Select, MenuItem, InputLabel, Card, Button } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

export const ArrayForm = (props) => {
    //const key = "match"
    const [rootList, setRootList] = useState([]);
    const [innerList, setInnerList] = useState([]);
    const [counter, setCounter] = useState([]);

    useEffect(() => {
        if (props.match && props.match.length > 0)
        {
            let array = []
            props.match.map((item) => {
                array.push({
                    id: array.length + 1,
                    value: item
                })
            })
            setCounter(array);
        }
    },[props.match])
    
    const handleAddNew = () => {
        const update = [...counter, { id: counter.length + 1, value:{} }]
        setCounter(update);
    }

    const getInnerList = (keyName, _data, _innerList) => {
        const _keys = Object.keys(_data.properties);
        if (_keys[0] === "anyOf") {
            _data.properties["anyOf"].map((item) => {
                if (item.type === "object") {
                    const _finalkeys = Object.keys(item.properties);
                    _innerList.push(
                        {
                            name: _finalkeys[0],
                            type: item.properties[_finalkeys[0]].type,
                            value: item.properties[_finalkeys[0]].value,
                            rootKey: keyName
                        }
                    )
                }
            })
        }
        else {
            _innerList.push(
                {
                    name: _keys[0],
                    type: _data.properties[_keys[0]].type,
                    value: _data.properties[_keys[0]].value,
                    rootKey: keyName
                }
            )
        }
        return _innerList;
    }

    const getItem = () => {
        const rootItems = props.configData.items[0];
        const rootProps = rootItems.properties;
        //let _props = {};
        let listArray = [];
        let _innerList = [];
        const __key = rootProps && Object.keys(rootProps);
        if (__key[0] === "anyOf") {
            rootProps["anyOf"].map((listItem) => {
                const _keys = Object.keys(listItem.properties);
                _keys.map(function (key) {
                    if (listItem.properties[key]) {
                        if (listItem.properties[key].type === "object") {
                            listArray.push(key);
                            _innerList = getInnerList(key, listItem.properties[key], _innerList);
                        }
                    }
                })
            })
        }
        setRootList(listArray);
        setInnerList(_innerList);
    }

    useEffect(() => {
        getItem();
    }, []);
    
    const updateHeaderObject = (objIndex, obj) => {
        let _counter = [...counter];
        if (objIndex > -1)
        {
            _counter[objIndex].value = obj;
        }
        let result = _counter.map(a => a.value);
        props.handleArrayUpdateChange(props.keyName, result)
    }

    const removeObject = (objIndex) => {
        let _counter = [...counter];
        if (objIndex > -1) {
            _counter.splice(objIndex, 1);
            setCounter(_counter);
        }
    }

    return (
        <>
            <Card data-test="card-container">
                <CardContent>
                    <div className='rightbtn'>
                        <Button variant="contained" color="primary" onClick={handleAddNew} data-test="addnew-button">Add Header</Button>
                    </div>
                    <Grid container direction="column" spacing={2} className="m-t-10">
                        {
                            counter && counter.length > 0 && counter.map((item, index) =>
                                <Grid item xs={12} key={index}>
                                    <FormRow
                                        innerList={innerList}
                                        rootList={rootList}
                                        item={item}
                                        index={index}
                                        updateHeaderObject={updateHeaderObject}
                                        removeObject={removeObject}
                                    />
                                </Grid>
                            )
                        }
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
}

export const FormRow = (props) => {
    const [rootValue, setRootValue] = useState('');
    const [innerValue, setInnerValue] = useState('');
    const [actualValue, setActualValue] = useState();
    const [filteredInnerList, setFilteredInnerList] = useState([]);

    const { innerList, rootList } = props;
    useEffect(() => {
        if (rootValue && innerList && innerList.length > 0) {
            setFilteredInnerList(innerList.filter(x => x.rootKey === rootValue));
            // setInnerValue('');
            // setActualValue('');
        }
    }, [rootValue]);

    useEffect(() => {
        if (!innerValue && filteredInnerList && filteredInnerList.length > 0) {
            setInnerValue(filteredInnerList[0].name);
            // setActualValue('');
        }
    }, [filteredInnerList]);

    useEffect(() => {
        if (!actualValue && innerValue && innerList && innerList.length > 0) {
            const innerObj = innerList.find(x => x.name === innerValue)
            if (innerObj) {
                setActualValue(innerObj.value);
            }
        }
    }, [innerValue]);

    useEffect(() => {
        if (props.item && props.item.value && props.item.value.headers) {
            const _headers = props.item.value.headers;
            const rootprops = Object.keys(_headers);
            if (rootprops && rootprops.length > 0)
                setRootValue(rootprops[0]);
            const innerObj = _headers[rootprops[0]];
            const innerprops = Object.keys(innerObj);
            if (innerprops && innerprops.length > 0) {
                setInnerValue(innerprops[0]);
                setActualValue(innerObj[innerprops[0]]);
            }
        }
    }, [props.item]);

    useEffect(() => {
        if (actualValue) {
            const inner = {};
            inner[innerValue] = actualValue;
            const root = { headers: {} };
            root.headers[rootValue] = inner;
        
            props.updateHeaderObject(props.index, root);
            //sample response : {
            //     "headers": {
            //         "user-agent": { "prefix": "Mobile" }
            //     }
            // }
        }
    }, [actualValue])

    return (
        <>
            <Grid container justify="center" alignItems="center" spacing={2}>
                <Grid item sm={3}>
                    {
                        rootList && rootList.length > 0 &&
                        <>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="demo-simple-select-outlined-label">Header</InputLabel>
                                <Select
                                    color="primary"
                                    labelId="demo-simple-select-outlined-label"
                                    value={rootValue}
                                    label={'Header'}
                                    onChange={(e) => setRootValue(e.target.value)}
                                    MenuProps={{
                                        getContentAnchorEl: null,
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                        },
                                    }}
                                >
                                    {/* <MenuItem value="Select">Select</MenuItem> */}
                                    {
                                        rootList.map((x, ind) => <MenuItem value={x} key={ind}>{x}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </>
                    }
                </Grid>
                <Grid item sm={3}>
                    {
                        filteredInnerList && filteredInnerList.length > 0 &&
                        <>
                            <FormControl fullWidth variant="outlined">
                                <Select
                                    color="primary"
                                    labelId="demo-simple-select-outlined-label"
                                    value={innerValue}
                                    onChange={(e) => setInnerValue(e.target.value)}
                                    MenuProps={{
                                        getContentAnchorEl: null,
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                        },
                                    }}
                                >
                                    {/* <MenuItem value="Select">Select</MenuItem> */}
                                    {
                                        filteredInnerList.map((x, ind) => <MenuItem value={x.name} key={ind}>{x.name}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </>
                    }
                </Grid>
                <Grid item sm={3}>
                    {
                        innerValue &&
                        <MuiTextField
                            onChange={(e) => setActualValue(e.target.value)}
                            value={actualValue}
                        />
                    }
                </Grid>
                <Grid item sm={3}>
                    {
                        actualValue &&
                        <IconButton onClick={() => props.removeObject(props.index)}>
                            <DeleteIcon />
                        </IconButton>
                    }
                </Grid>
            </Grid>
        </>
    );
}

export default ArrayForm;