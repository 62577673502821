const initialState = {
  resourceList: [],
  error: '',
  resource: {},
  executingDeleteResource: false,
  deleteResourceSuccess: false,
  deleteResourceFailure: false,
  executingCreateResource: false,
  createResourceCallSuccess: false,
  createResourceCallFailure: false,
  executingEditResource: false,
  editResourceCallSuccess: false,
  editResourceCallFailure: false,
  fetchingResources: false,
  fetchingResource: false
};

export default initialState;
