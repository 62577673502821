import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from "react-i18next";
import { Paper, Button, Typography, Grid } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import paths from "../../../constants/paths"
import { connect, ConnectedProps } from 'react-redux';
// import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { resetIsGITNotAuthorized, fetchGITOrganizations, clearGITOrganizations } from '../redux/actions'
import { CreateApp_TemplateTypes } from '../../../constants/enums'
import { AppConstants } from "../../../constants/appconstants";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AuthorizeContainerPopUp from '../../../components/authorizecontainerpopup/AuthorizeContainerPopUp';
import BackdropLoader from '../../../components/loader/BackdropLoader';
import BackButton from '../../../components/button/BackButton';
import { Dispatch } from "redux";
import { History } from 'history';
import { RouteComponentProps } from 'react-router-dom';
import { WithStyles, createStyles } from "@material-ui/core";
import { AuthProvider } from '../../../models/Account.model'

/* istanbul ignore next */
const useStyles = () => createStyles({
    root:{
        marginTop: 20
    },
    paperContainer: {
        height: 250,
        width:'100%',
        display : 'flex',
        flexDirection : 'column',
        justifyContent : 'center',
        alignItems  : 'center',
    },
    otherPaperContainer: {
        height: 180,
        width: '60%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    icon:{
        fontSize:100
    },
    iconContainer: {
      width:'100%',
      height: 275,
    },
    imgIcon: {
        height: '100px'
    },
    selectTemplateTypography: {
      marginTop : '1rem',
      textTransform: 'none'
    },
    typeSelection: {
        position: "absolute",
        top: 25,
        right: 20
    },

});

interface TempAuthProvider extends Pick<AuthProvider,  "service_name"> {}

interface MatchParams {
    pId: string;
}

interface Props extends WithTranslation, PropsFromRedux, RouteComponentProps<MatchParams>, WithStyles<typeof useStyles> {
    history: History,
}

type state = {
    creating: boolean,
    selectedTemplateType: number,
    selectedProvider: AuthProvider,
    isOpenAuthPopup: boolean
}

export class CreateApp extends Component<Props, state, MatchParams> {
    constructor(props: Props){
        super(props);
        this.state = {
            creating: false,
            selectedTemplateType: 0,
            selectedProvider: {service_name:'', name:'', icon:''},
            isOpenAuthPopup: false
        }
    }

    componentDidMount() {
        const $this = this;
        window.addEventListener('message', function(e){
            if(e?.data?.source?.match('react-devtools')) return;
            $this.getGITOrganizations();
        }, false) 
    }

    // handleDefaultTemplate = () => {
    //     this.navigateToPluginSelection(CreateApp_TemplateTypes.default);
    // }

    navigateToPluginSelection = () => {
        //this.props.templateSelection_create(type);
        if (this.state.selectedTemplateType !== CreateApp_TemplateTypes.cr) {
            const _source = this.state.selectedTemplateType === CreateApp_TemplateTypes.git ? this.state.selectedProvider.service_name : 'x';
            if(this.state.selectedTemplateType === CreateApp_TemplateTypes.helm){
                this.props.history.push({
                    pathname: paths.CREATEAPP_SELECTCATALOG.replace(':pId', this.props.match.params.pId).replace(':tType', this.state.selectedTemplateType.toString()).replace(':source', _source),
                });
            }else if(this.state.selectedTemplateType === CreateApp_TemplateTypes.op){
                this.props.history.push({
                    pathname: paths.CREATEAPP_SELECTOPERATOR.replace(':pId', this.props.match.params.pId).replace(':tType', this.state.selectedTemplateType.toString()).replace(':source', _source),
                });
            }
            else{
                this.props.history.push({
                    pathname: paths.CREATEAPP_SELECTPLUGIN.replace(':pId', this.props.match.params.pId).replace(':tType', this.state.selectedTemplateType.toString()).replace(':source', _source),
                });
            }
        }
        else {
            this.props.history.push({
                pathname: paths.CREATEAPP_SELECTREGION.replace(':pId', this.props.match.params.pId).replace(':tType', this.state.selectedTemplateType.toString()).replace(':source', this.state.selectedProvider.service_name).replace(':plugId', 'x'),
            });
        }
    }

    // handleConnectToGithub = () => {
    //     this.getGITOrganizations();
    // }

    getGITOrganizations = () => {
        const jsonBody = {
            "service_name": this.state.selectedProvider && this.state.selectedProvider.service_name 
        }
        this.props.fetchGITOrganizations(jsonBody, this.state.selectedTemplateType);
    }

    UNSAFE_componentWillReceiveProps = (newProps: Props) => {
        if(this.props.isGITNotAuthorized === false && newProps.isGITNotAuthorized === true) 
        {
            this.openOAuthClient();
            this.props.resetIsGITNotAuthorized();
        }
        if(this.props.gitOrganizations !== newProps.gitOrganizations)
        {
            this.navigateToPluginSelection();
        }
    }

    componentWillUnmount(){
        this.props.clearGITOrganizations()
        window.removeEventListener("message", function(){})
    }

    openOAuthClient = () => {
        const { selectedProvider } = this.state;
        // const qParams = [
        //     `client_id=${window?.config?.REACT_APP_GITHUB_CLIENTID}`,
        //     `redirect_uri=${window?.config?.REACT_APP_OAUTH_REDIRECT_URL}`,
        //     `scope=repo,admin:repo_hook`,
        //     `state=github_repo`
        //   ].join("&");
        //window.location.href =  `${window?.config?.REACT_APP_GITHUB_AUTHORIZE_ENDPOINT}?${qParams}`;
        if (selectedProvider.authType === 1) {
            setTimeout(() => window.open(`${selectedProvider.authorizeEndpoint}?${selectedProvider.params}`, "01Cloud - OAuth", "status=no,location=no,toolbar=no,menubar=no,width:200,height=100"), 100);
        }
        else if (selectedProvider.authType === 2 || selectedProvider.authType === 3) {
            this.setState({
                isOpenAuthPopup: true
            })
        }
    }

    //new section start
    handleTemplateTypeSelection = (type: number) => {

        this.setState({
            selectedTemplateType: type
        }, () => {
            if (type === CreateApp_TemplateTypes.default || type === CreateApp_TemplateTypes.helm || type === CreateApp_TemplateTypes.op || type === CreateApp_TemplateTypes.manageDB) {
                this.navigateToPluginSelection();
            }
            
        })
    }

    handleServiceSelection = (_provider: AuthProvider) => {
        this.setState({
            selectedProvider: _provider
        }, () => this.getGITOrganizations())
    }

    handleCancelPopUp = () => { 
        this.setState({
            isOpenAuthPopup: false
        })
    }

    authTokenTypeCallBack = () => { 
        this.setState({
            isOpenAuthPopup: false
        })
        this.getGITOrganizations();
    }
    //new section end

    render () {
        const { t, classes } = this.props;
        const { selectedProvider } = this.state;
        return (
            <div data-test="main-container">

                {/* <ButtonBase
                    onClick={() => this.props.history.goBack()}
                    style={{ color: "#357dfd", marginBottom: 20 }}
                    data-test="backButton"
                >
                    <ArrowBackIcon fontSize="small" />{" "}
                    <span className="jobNav">{t('App.CreateApp.back')}</span>
                </ButtonBase> */}

                <BackButton 
                    clickHandler={() => this.props.history.goBack()}
                    data-test='backButton'
                />

                <Typography variant="h5" data-test="create-label">
                    {t("CreateNewApp")}
                </Typography>
                <Grid container spacing={ 1 }  className={ classes.root }>
                    <Grid item xs={ 12 } md={3} data-test="template-grid">
                        <Button onClick={() => this.handleTemplateTypeSelection(CreateApp_TemplateTypes.default) } className={ classes.iconContainer } data-test="template-selection">
                            <Paper  className={ classes.paperContainer }>
                                <SettingsIcon className={ classes.icon }/>
                                <Typography className={ classes.selectTemplateTypography } variant="h6" color="textPrimary">
                                {t('App.CreateApp.selectTemplate')}
                                </Typography>
                                <Typography className={ classes.selectTemplateTypography } variant="body2" color="textPrimary">
                                Use templates to deploy website without any source code
                                </Typography>
                            </Paper>
                        </Button>
                    </Grid>
                    <Grid item xs={ 12 } md={3} data-test="github-grid">
                        <Button onClick={() => this.handleTemplateTypeSelection(CreateApp_TemplateTypes.git)} className={ classes.iconContainer } data-test="github-selection">
                            <Paper className={classes.paperContainer} elevation={ this.state.selectedTemplateType === CreateApp_TemplateTypes.git ? 6 : 1}>
                                {this.state.selectedTemplateType === CreateApp_TemplateTypes.git && <CheckCircleIcon color="primary" className={classes.typeSelection} /> }
                                {/* <GitHubIcon className={classes.icon} /> */}
                                <img src="/images/icons/git.svg" alt="Git" title= {t('App.CreateApp.git')} className={classes.imgIcon} />
                                <Typography className={ classes.selectTemplateTypography } variant="h6" color="textPrimary">
                                    {t('App.CreateApp.connectToGit')}
                                </Typography>
                                <Typography className={ classes.selectTemplateTypography } variant="body2" color="textPrimary">
                             Use Git to pull your code from Github, Gitlab & Bitbucket
                                </Typography>
                            </Paper>
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={3} data-test="cr-grid">
                        <Button onClick={() => this.handleTemplateTypeSelection(CreateApp_TemplateTypes.cr)} className={classes.iconContainer} data-test="cr-selection">
                            <Paper className={classes.paperContainer} elevation={ this.state.selectedTemplateType === CreateApp_TemplateTypes.cr ? 6 : 1}>
                                {this.state.selectedTemplateType === CreateApp_TemplateTypes.cr && <CheckCircleIcon color="primary" className={classes.typeSelection} />}
                                <img src="/images/icons/containerregistry.svg" alt="Container Registry" title= {t('App.CreateApp.containerRegistry')} className={classes.imgIcon} />
                                <Typography className={classes.selectTemplateTypography} variant="h6" color="textPrimary">
                                    {t('App.CreateApp.connectToCR')}
                                </Typography>
                                <Typography className={ classes.selectTemplateTypography } variant="body2" color="textPrimary">
                                     Use registry to pull image from Dockerhub & ECR
                                </Typography>
                            </Paper>
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={3} data-test="Manged-services">
                        <Button onClick={() => this.handleTemplateTypeSelection(CreateApp_TemplateTypes.manageDB)} className={classes.iconContainer} data-test="managedServices-selection">
                            <Paper className={classes.paperContainer} elevation={ this.state.selectedTemplateType === CreateApp_TemplateTypes.manageDB ? 6 : 1}>
                                {this.state.selectedTemplateType === CreateApp_TemplateTypes.manageDB && <CheckCircleIcon color="primary" className={classes.typeSelection} />}
                                <img src="/images/icons/managedDb.svg" alt="Managed Services" title= {"Manged DB"} className={classes.imgIcon} />
                                <Typography className={classes.selectTemplateTypography} variant="h6" color="textPrimary">
                                    {t('App.CreateApp.managedServices')}
                                </Typography>
                                <Typography className={ classes.selectTemplateTypography } variant="body2" color="textPrimary">
                                     Use Managed service to host Database as a service
                                </Typography>
                            </Paper>
                        </Button>
                    </Grid>
                    {this.props.currentOrganization?.id > 0 && (
                        <Grid item xs={12} md={3} data-test="helm-grid">
                            <Button onClick={() => this.handleTemplateTypeSelection(CreateApp_TemplateTypes.helm)} className={classes.iconContainer} data-test="helm-selection">
                                <Paper className={classes.paperContainer} elevation={ this.state.selectedTemplateType === CreateApp_TemplateTypes.helm ? 6 : 1}>
                                    {this.state.selectedTemplateType === CreateApp_TemplateTypes.helm && <CheckCircleIcon color="primary" className={classes.typeSelection} />}
                                    <img src="/images/icons/helm.svg" alt="Helm" title={t('App.CreateApp.helmCharts')} className={classes.imgIcon} />
                                    <Typography className={classes.selectTemplateTypography} variant="h6" color="textPrimary">
                                        {t('App.CreateApp.helmCharts')}
                                    </Typography>
                                    <Typography className={ classes.selectTemplateTypography } variant="body2" color="textPrimary">
                                    Use Helm to manage Kuberneets package
                                    </Typography>
                                </Paper>
                            </Button>
                        </Grid>
                    )}
                      {this.props.currentOrganization?.id > 0 && (
                        <Grid item xs={12} md={3} data-test="op-grid">
                            <Button onClick={() => this.handleTemplateTypeSelection(CreateApp_TemplateTypes.op)} className={classes.iconContainer} data-test="op-selection">
                                <Paper className={classes.paperContainer} elevation={ this.state.selectedTemplateType === CreateApp_TemplateTypes.op ? 6 : 1}>
                                    {this.state.selectedTemplateType === CreateApp_TemplateTypes.op && <CheckCircleIcon color="primary" className={classes.typeSelection} />}
                                    <img src="/images/icons/operator.svg" alt="Operator" title={t('App.CreateApp.operator')} className={classes.imgIcon} />
                                    <Typography className={classes.selectTemplateTypography} variant="h6" color="textPrimary">
                                        {t('App.CreateApp.operator')}
                                    </Typography>
                                    <Typography className={ classes.selectTemplateTypography } variant="body2" color="textPrimary">
                                     Use operator to apply Custom Resource Definition (CRD)
                                    </Typography>
                                </Paper>
                            </Button>
                        </Grid>
                    )}
                </Grid>
                {
                    this.state.selectedTemplateType > 0 &&
                    <>
                        <Typography variant="h5" className="m-t-20">
                            {this.state.selectedTemplateType === CreateApp_TemplateTypes.git ? t("App.CreateApp.gitProvidersLabel") : t("App.CreateApp.crProvidersLabel")}
                        </Typography>
                        {
                            this.state.selectedTemplateType === CreateApp_TemplateTypes.git && 
                            <Grid container spacing={1} className={classes.root}>
                                {
                                    AppConstants.gitProviders && AppConstants.gitProviders.length > 0 && AppConstants.gitProviders.map((_provider, ind) =>
                                        <Grid item xs={12} md={3} key={ind}>
                                            <Button onClick={() => this.handleServiceSelection(_provider)}  className={classes.iconContainer} data-test="service-selection">
                                                <Paper elevation={1} className={classes.otherPaperContainer}>
                                                    <img src={_provider.icon} alt={_provider.name} title={_provider.name} className={classes.imgIcon} />
                                                    <Typography className={classes.selectTemplateTypography} variant="h6" color="textPrimary">
                                                        {_provider.name}
                                                    </Typography>
                                                </Paper>
                                            </Button>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        }
                        {
                            this.state.selectedTemplateType === CreateApp_TemplateTypes.cr &&
                            <Grid container spacing={1} className={classes.root}>
                                {
                                    AppConstants.crProviders && AppConstants.crProviders.length > 0 && AppConstants.crProviders.map((_provider, ind) =>
                                        <Grid item xs={12} md={3} key={ind}>
                                            <Button onClick={() => this.handleServiceSelection(_provider)} className={classes.iconContainer} data-test="cr-serviceSelction">
                                                <Paper elevation={1} className={classes.otherPaperContainer}>
                                                    <img src={_provider.icon} alt={_provider.name} title={_provider.name} className={classes.imgIcon} />
                                                    <Typography className={classes.selectTemplateTypography} variant="h6" color="textPrimary">
                                                        {_provider.name}
                                                    </Typography>
                                                </Paper>
                                            </Button>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        }
                    </>
                }
                {
                    this.state.isOpenAuthPopup &&
                    <AuthorizeContainerPopUp 
                        authTokenTypeCallBack={() => this.authTokenTypeCallBack()}
                        // variables={this.state.variables}
                        // isErrors={this.state.isErrors}
                        // handleValueChange={this.handleValueChange}
                        isOpenAuthPopup={this.state.isOpenAuthPopup}
                        handleCancelPopUp={this.handleCancelPopUp}
                        selectedProvider={selectedProvider}
                        data-test="auth-popup"
                    />
                }
                {this.props.connecting && <BackdropLoader data-test="linkingAccount" message={t('Account.ProfileTab.linkingAccount')} />}
            </div>
        )
    }
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
    connecting: state.AppsReducer.connecting,
    isGITNotAuthorized: state.AppsReducer.isGITNotAuthorized,
    //gitRepos: state.AppsReducer.gitRepos,
    gitOrganizations: state.AppsReducer.gitOrganizations,
    currentOrganization: state.AuthReducer.currentOrganization,
})

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
    return {
        fetchGITOrganizations: (jsonBody: TempAuthProvider, type: number) => dispatch(fetchGITOrganizations(jsonBody, type)),
        //templateSelection_create: (type) => dispatch(templateSelection_create(type)),
        resetIsGITNotAuthorized: () => dispatch(resetIsGITNotAuthorized()),
        clearGITOrganizations: () => dispatch(clearGITOrganizations())
    }
}

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withStyles(useStyles)(withTranslation()(CreateApp)));