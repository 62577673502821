import {
    GET_CHART_VERSIONS,
    GET_CHART_VERSIONS_FAILURE,
    GET_CHART_VERSIONS_SUCCESS,
    GET_CHART_VERSION_CONFIG,
    GET_CHART_VERSION_CONFIG_FAILURE,
    GET_CHART_VERSION_CONFIG_SUCCESS,
    CREATE_HELM_ENVIRONMENT_SUCCESS,
    CREATE_HELM_ENVIRONMENT_FAILURE,
    CREATE_HELM_ENVIRONMENT,
    FETCH_HELM_ENVIRONMENT_DETAIL,
    FETCH_HELM_ENVIRONMENT_DETAIL_FAILURE,
    FETCH_HELM_ENVIRONMENT_DETAIL_SUCCESS,
    FETCH_HELM_ENV_STATE,
    FETCH_HELM_ENV_STATE_SUCCESS,
    FETCH_HELM_ENV_STATE_INITIATE,
    DELETE_HELM_ENVIRONMENT,
    DELETE_HELM_ENVIRONMENT_FAILURE,
    DELETE_HELM_ENVIRONMENT_SUCCESS,
    FETCH_HELM_ACTIVITY_LOGS,
    FETCH_HELM_ACTIVITY_LOGS_FAILURE,
    FETCH_HELM_ACTIVITY_LOGS_SUCCESS,
    FETCH_HELM_LOGS,
    FETCH_HELM_LOGS_FAILURE,
    FETCH_HELM_LOGS_SUCCESS,
    FETCH_HELM_PODS,
    FETCH_HELM_PODS_SUCCESS,
    FETCH_HELM_PODS_FAILURE,
    FETCH_HELM_LOGS_INITIATE,
    FETCH_HELM_LOGS_INITIATE_FAILURE,
    FETCH_HELM_LOGS_INITIATE_SUCCESS,
    UPDATE_HELM_ENVIRONMENT,
    UPDATE_HELM_ENVIRONMENT_FAILURE,
    UPDATE_HELM_ENVIRONMENT_SUCCESS,
    START_HELM_ENVIRONMENT,
    START_HELM_ENVIRONMENT_FAILURE,
    START_HELM_ENVIRONMENT_SUCCESS,
    STOP_HELM_ENVIRONMENT,
    STOP_HELM_ENVIRONMENT_FAILURE,
    STOP_HELM_ENVIRONMENT_SUCCESS,
    REDEPLOY_HELM_ENVIRONMENT,
    REDEPLOY_HELM_ENVIRONMENT_FAILURE,
    REDEPLOY_HELM_ENVIRONMENT_SUCCESS,
    LAUNCH_HELM_ENVIRONMENT,
    LAUNCH_HELM_ENVIRONMENT_FAILURE,
    LAUNCH_HELM_ENVIRONMENT_SUCCESS,
    FETCH_HELM_ENV_INSIGHT,
    FETCH_HELM_ENV_INSIGHT_FAILURE,
    FETCH_HELM_ENV_INSIGHT_SUCCESS,
    FETCH_HELM_ENV_OVERVIEW,
    FETCH_HELM_ENV_OVERVIEW_FAILURE,
    FETCH_HELM_ENV_OVERVIEW_SUCCESS
} from "./actions"
import toast from "../../../components/toast/Toast";
import { call, takeLatest, put, select, delay } from "redux-saga/effects";
import axios from "axios";
import endpoints from "../../../constants/endpoints";
import { sessionTokenSelector } from "../../login/redux/selectors";
import { GET_SIDEBAR_PROJECT_DETAILS } from '../../project/redux/actions'
import paths from "../../../constants/paths";
import * as helmEnvironmentSelectors from "./selectors"

function* getChartVersions(payload) {
    try {
        const sessionToken = yield select(sessionTokenSelector)
        const config = { headers: { 'Authorization': 'basic ' + sessionToken } }
        const response = yield axios.get(endpoints.CHART.GET_CHART_DETAIL.replace(":id", payload.data.id), config);

        // const data = response.data;
        if ((response.status === 201 || response.status === 200) && response.data) {
            yield put({ type: GET_CHART_VERSIONS_SUCCESS, data: response.data });
        } else {
            yield put({ type: GET_CHART_VERSIONS_FAILURE })
        }
    } catch (error) {
        yield put({ type: GET_CHART_VERSIONS_FAILURE })
        if (error && error.response && error.response.data && error.response.data.error) {
            toast.error(error.response.data.error);
        }
    }
}

function* getChartVersionConfig(payload) {
    try {
        const sessionToken = yield select(sessionTokenSelector)
        const config = { headers: { 'Authorization': 'basic ' + sessionToken } }
        const response = yield axios.get(endpoints.CHART.GET_CHART_VERSIONS.replace(":id", payload.data.id), config);

        // const data = response.data;
        if ((response.status === 201 || response.status === 200) && response.data) {
            yield put({ type: GET_CHART_VERSION_CONFIG_SUCCESS, data: response.data });
        } else {
            yield put({ type: GET_CHART_VERSION_CONFIG_FAILURE })
        }
    } catch (error) {
        yield put({ type: GET_CHART_VERSION_CONFIG_FAILURE })
        if (error && error.response && error.response.data && error.response.data.error) {
            toast.error(error.response.data.error);
        }
    }
}

function* fetchHelmEnvironmentDetails(payload) {
    try {
        const sessionToken = yield select(sessionTokenSelector)
        const config = { headers: { 'Authorization': 'basic ' + sessionToken } }
        const response = yield axios.get(endpoints.HELMENVIRONMENT.GET_HELM_ENVIRONMENT_INFO.replace(":id", payload.data.id), config);
        // const data = response.data;
        if ((response.status === 201 || response.status === 200) && response.data) {
            yield put({ type: FETCH_HELM_ENVIRONMENT_DETAIL_SUCCESS, data: response.data });
        } else {
            yield put({ type: FETCH_HELM_ENVIRONMENT_DETAIL_FAILURE })
        }
    } catch (error) {
        yield put({ type: FETCH_HELM_ENVIRONMENT_DETAIL_FAILURE })
        if (error && error.response && error.response.data && error.response.data.error) {
            toast.error(error.response.data.error);
        }
    }
}

function* fetchHelmActivityLogs(payload) {
    try {
        const currentEnvId = yield select(helmEnvironmentSelectors.currentHelmEnvironmentSelector);
        if (parseInt(currentEnvId) !== parseInt(payload.data.id)) {
            return
        }
        const sessionToken = yield select(sessionTokenSelector)
        const config = { headers: { 'Authorization': 'basic ' + sessionToken } }
        const response = yield axios.get(endpoints.HELMENVIRONMENT.GET_HELM_ACTIVITY_LOGS.replace(":id", payload.data.id), config);
        // const data = response.data;
        if ((response.status === 201 || response.status === 200) && response.data) {
            yield put({ type: FETCH_HELM_ACTIVITY_LOGS_SUCCESS, data: response.data });
        } else {
            yield put({ type: FETCH_HELM_ACTIVITY_LOGS_FAILURE })
        }
    } catch (error) {
        yield put({ type: FETCH_HELM_ACTIVITY_LOGS_FAILURE })
        if (error && error.response && error.response.data && error.response.data.error) {
            toast.error(error.response.data.error);
        }
    }
}

function* createHelmEnvironment(payload) {
    try {
        const sessionToken = yield select(sessionTokenSelector)
        const config = { headers: { 'Authorization': 'basic ' + sessionToken } }
        const response = yield axios.post(endpoints.HELMENVIRONMENT.CREATE_HELM_ENVIRONMENT, payload.data.payload, config);
        // const data = response.data;
        if ((response.status === 201 || response.status === 200)) {
            yield put({ type: CREATE_HELM_ENVIRONMENT_SUCCESS, data: response.data });
            yield put({ type: GET_SIDEBAR_PROJECT_DETAILS });
            toast.success("Environment creation process started")
            const data = response.data
            payload.data.history.push({
                pathname: paths.HELMENVIRONMENTINFO.replace(":eId", data.id) + "#overview",
                state: { initial: true }
            })
        } else {
            yield put({ type: CREATE_HELM_ENVIRONMENT_FAILURE })
            toast.error("Environment creation failed. Please try again")
        }
    } catch (error) {
        yield put({ type: CREATE_HELM_ENVIRONMENT_FAILURE })
        if (error && error.response && error.response.data && error.response.data.error) {
            toast.error(error.response.data.error);
        }
    }
}

function* updateHelmEnv(payload) {
    try {
        const sessionToken = yield select(sessionTokenSelector)
        const config = { headers: { 'Authorization': 'basic ' + sessionToken } }
        const response = yield axios.put(endpoints.HELMENVIRONMENT.UPDATE_HELM_ENVIRONMENT, payload.data.payload, config);

        // const data = response.data;
        if ((response.status === 201 || response.status === 200)) {
            yield put({ type: UPDATE_HELM_ENVIRONMENT_SUCCESS, data: response.data });
            toast.success("Environment update initiated.")
            yield call(fetchHelmEnvironmentDetails, payload);
        } else {
            yield put({ type: UPDATE_HELM_ENVIRONMENT_FAILURE })
            toast.error("Environment update failed. Please try again")
        }
    } catch (error) {
        yield put({ type: UPDATE_HELM_ENVIRONMENT_FAILURE })
        if (error && error.response && error.response.data && error.response.data.error) {
            toast.error(error.response.data.error);
        }
    }
}

function* fetchHelmState(payload) {
    try {
        const currentEnvId = yield select(helmEnvironmentSelectors.currentHelmEnvironmentSelector);
        if (parseInt(currentEnvId) !== parseInt(payload.data.id)) {
            return
        }
        const sessionToken = yield select(sessionTokenSelector);
        const config = {
            headers: {
                Authorization: "basic " + sessionToken,
            },
        };
        const response = yield axios.get(
            endpoints.HELMENVIRONMENT.GET_STATE.replace(":eId", payload.data.id),
            config
        );
        const data = response.data;
        yield put({ type: FETCH_HELM_ENV_STATE_SUCCESS, data: data ? data : {} });
    } catch (err) {
        if (err?.response?.data?.error) {
            toast.error(err.response.data.error);
        }
        else {
            toast.error("Failed to fetch State");
        }
    }
}

function* startFetchState(payload) {
    try {
        const sessionToken = yield select(sessionTokenSelector);
        const config = {
            headers: {
                Authorization: "basic " + sessionToken,
            },
        };
        let e = endpoints.HELMENVIRONMENT.GET_STATE_INITIATE.replace(":eId", payload.data.id)
        if (payload.data?.initial) {
            e += "?action=Creating"
        }
        const response = yield axios.get(
            e,
            config
        );
        const data = response.data;
        if (data !== null) {
            yield delay(500);
            yield call(fetchHelmState, payload);
        }
    } catch (error) {
        console.log(error)
    }
}

function* deleteEnvironment(payload) {
    try {
        const sessionToken = yield select(sessionTokenSelector);
        const config = {
            headers: {
                Authorization: "basic " + sessionToken,
            },
        };
        const response = yield axios.delete(
            endpoints.HELMENVIRONMENT.DELETE_HELM_ENVIRONMENT.replace(":id", payload.data.id),
            config
        );
        if (response.status === 200) {
            yield put({ type: DELETE_HELM_ENVIRONMENT_SUCCESS });
            yield put({ type: GET_SIDEBAR_PROJECT_DETAILS });
            toast.success("Environment deleted successfully");
            payload.data.history.push(
                payload.data.appId > 0 ? paths.APPDETAILS.replace(":appId", payload.data.appId) : paths.PROJECTLIST
            );
        } else {
            toast.error("Operation Failed.");
            yield put({ type: DELETE_HELM_ENVIRONMENT_FAILURE });
            // payload.data.history.push((payload.data.app_id > 0 ?  "/app/" +  payload.data.app_id : "/projects" ))
        }
    } catch (error) {
        yield put({ type: DELETE_HELM_ENVIRONMENT_FAILURE });
        if (
            error?.response?.data?.error
        )
            toast.error(error.response.data.error);
        else toast.error("Operation failed. Please try again");
    }
}

function* fetchHelmLogsInitiate(payload) {
    try {
        const sessionToken = yield select(sessionTokenSelector)
        const config = { headers: { 'Authorization': 'basic ' + sessionToken } }
        const response = yield axios.get(
            endpoints.HELMENVIRONMENT.GET_HELM_LOGS_INITIATE
                .replace(":id", payload.data.id)
                .replace(":duration", payload.data.duration),
            config
        );
        if ((response.status === 201 || response.status === 200) && response.data) {
            yield put({ type: FETCH_HELM_LOGS_INITIATE_SUCCESS, data: response.data });
            yield delay(1000)
            yield call(fetchHelmLogs, payload);
        } else {
            yield put({ type: FETCH_HELM_LOGS_INITIATE_FAILURE })
        }
    } catch (error) {
        yield put({ type: FETCH_HELM_LOGS_INITIATE_FAILURE })
        if (error && error.response && error.response.data && error.response.data.error) {
            toast.error(error.response.data.error);
        }
    }
}

function* fetchHelmLogs(payload) {
    try {
        const sessionToken = yield select(sessionTokenSelector)
        const config = { headers: { 'Authorization': 'basic ' + sessionToken } }
        const response = yield axios.get(endpoints.HELMENVIRONMENT.GET_HELM_LOGS.replace(":id", payload.data.id), config);
        if (response.data) {
            yield put({ type: FETCH_HELM_LOGS_SUCCESS, data: response.data });
        } else {
            yield put({ type: FETCH_HELM_LOGS_FAILURE })
        }
    } catch (error) {
        yield put({ type: FETCH_HELM_LOGS_FAILURE })
        if (error && error.response && error.response.data && error.response.data.error) {
            toast.error(error.response.data.error);
        }
    }
}

function* fetchHelmPods(payload) {
    try {
        const sessionToken = yield select(sessionTokenSelector)
        const config = { headers: { 'Authorization': 'basic ' + sessionToken } }
        const response = yield axios.get(endpoints.HELMENVIRONMENT.GET_HELM_PODS.replace(":id", payload.data.id), config);
        if ((response.status === 201 || response.status === 200) && response.data) {
            yield put({ type: FETCH_HELM_PODS_SUCCESS, data: response.data });
        } else {
            yield put({ type: FETCH_HELM_PODS_FAILURE })
        }
    } catch (error) {
        yield put({ type: FETCH_HELM_PODS_FAILURE })
        if (error && error.response && error.response.data && error.response.data.error) {
            toast.error(error.response.data.error);
        }
    }
}

function* launchHelmEnv(payload) {
    try {
        const sessionToken = yield select(sessionTokenSelector)
        const config = { headers: { 'Authorization': 'basic ' + sessionToken } }
        const response = yield axios.get(endpoints.HELMENVIRONMENT.LAUNCH_HELM_ENV.replace(":id", payload.data.id), config);
        if ((response.status === 201 || response.status === 200) && response.data) {
            yield put({ type: LAUNCH_HELM_ENVIRONMENT_SUCCESS, data: response.data });
            toast.success(response.message ?? "Environment Launch Inititated");

        } else {
            yield put({ type: LAUNCH_HELM_ENVIRONMENT_FAILURE })
            toast.error(response.message ?? "Environment Launch Failed");

        }
    } catch (error) {
        yield put({ type: LAUNCH_HELM_ENVIRONMENT_FAILURE })
        if (error && error.response && error.response.data && error.response.data.error) {
            toast.error(error.response.data.error);
        }
    }
}

function* startHelmEnv(payload) {
    try {
        const sessionToken = yield select(sessionTokenSelector)
        const config = { headers: { 'Authorization': 'basic ' + sessionToken } }
        const response = yield axios.post(endpoints.HELMENVIRONMENT.START_HELM_ENV.replace(":id", payload.data.id), {}, config);
        if ((response.status === 201 || response.status === 200) && response.data) {
            yield put({ type: START_HELM_ENVIRONMENT_SUCCESS });
            toast.success(response.message ?? "Environment Start initiated");
        } else {
            yield put({ type: START_HELM_ENVIRONMENT_FAILURE })
            toast.error(response.message ?? "Failed to stop environment");
        }
    } catch (error) {
        yield put({ type: START_HELM_ENVIRONMENT_FAILURE })
        if (error && error.response && error.response.data && error.response.data.error) {
            toast.error(error.response.data.error);
        }
    }
}

function* stopHelmEnv(payload) {
    try {
        const sessionToken = yield select(sessionTokenSelector)
        const config = { headers: { 'Authorization': 'basic ' + sessionToken } }
        const response = yield axios.post(endpoints.HELMENVIRONMENT.STOP_HELM_ENV.replace(":id", payload.data.id), {}, config);
        if ((response.status === 201 || response.status === 200) && response.data) {
            if (payload.data?.changeHash) {
                payload.data.changeHash("overview")
            }
            toast.success(response.message ?? "Environment Stop initiated");
            yield call(startFetchState, payload);
            yield put({ type: GET_SIDEBAR_PROJECT_DETAILS })
            yield put({ type: STOP_HELM_ENVIRONMENT_SUCCESS });
        } else {
            yield put({ type: STOP_HELM_ENVIRONMENT_FAILURE })
            toast.error(response.message ?? "Failed to stop environment");
        }
    } catch (error) {
        yield put({ type: STOP_HELM_ENVIRONMENT_FAILURE })
        if (error && error.response && error.response.data && error.response.data.error) {
            toast.error(error.response.data.error);
        }
    }
}

function* redeployHelmEnv(payload) {
    try {
        const sessionToken = yield select(sessionTokenSelector)
        const config = { headers: { 'Authorization': 'basic ' + sessionToken } }
        const response = yield axios.post(endpoints.HELMENVIRONMENT.REDEPLOY_HELM_ENV.replace(":id", payload.data.id), payload.data.payload, config);
        if ((response.status === 201 || response.status === 200) && response.data) {
            yield put({ type: REDEPLOY_HELM_ENVIRONMENT_SUCCESS });
            toast.success(response.message ?? "Redeploy environment initiated");
            payload.data.history.push({
                pathname: paths.HELMENVIRONMENTINFO.replace(":eId", payload.data.id) + "#overview",
            })
        } else {
            yield put({ type: REDEPLOY_HELM_ENVIRONMENT_FAILURE })
            toast.error(response.message ?? "Failed to rdeploy environment");
        }
    } catch (error) {
        yield put({ type: REDEPLOY_HELM_ENVIRONMENT_FAILURE })
        if (error && error.response && error.response.data && error.response.data.error) {
            toast.error(error.response.data.error);
        }
    }
}

function* fetchHelmEnvInsight(payload) {
    try {
        const sessionToken = yield select(sessionTokenSelector)
        const config = { headers: { 'Authorization': 'basic ' + sessionToken } }
        const response = yield axios.post(endpoints.HELMENVIRONMENT.GET_HELM_ENV_INSIGHT.replace(":id", payload.data.id), payload.data.payload, config);
        if ((response.status === 201 || response.status === 200) && response.data) {
            yield put({ type: FETCH_HELM_ENV_INSIGHT_SUCCESS, data: response.data });
        } else {
            yield put({ type: FETCH_HELM_ENV_INSIGHT_FAILURE })
        }
    } catch (error) {
        yield put({ type: FETCH_HELM_ENV_INSIGHT_FAILURE })
        if (error && error.response && error.response.data && error.response.data.error) {
            toast.error(error.response.data.error);
        }
    }
}

function* fetchHelmEnvOverview(payload) {
    try {
        const sessionToken = yield select(sessionTokenSelector)
        const config = { headers: { 'Authorization': 'basic ' + sessionToken } }
        const response = yield axios.post(endpoints.HELMENVIRONMENT.GET_HELM_ENV_OVERVIEW.replace(":id", payload.data.id), payload.data.duration, config);
        if ((response.status === 201 || response.status === 200) && response.data) {
            yield put({ type: FETCH_HELM_ENV_OVERVIEW_SUCCESS, data: response.data });
        } else {
            yield put({ type: FETCH_HELM_ENV_OVERVIEW_FAILURE })
        }
    } catch (error) {
        yield put({ type: FETCH_HELM_ENV_OVERVIEW_FAILURE })
        if (error && error.response && error.response.data && error.response.data.error) {
            toast.error(error.response.data.error);
        }
    }
}

export default function* watcherSaga() {
    yield takeLatest(GET_CHART_VERSIONS, getChartVersions);
    yield takeLatest(CREATE_HELM_ENVIRONMENT, createHelmEnvironment);
    yield takeLatest(GET_CHART_VERSION_CONFIG, getChartVersionConfig);
    yield takeLatest(FETCH_HELM_ENVIRONMENT_DETAIL, fetchHelmEnvironmentDetails);
    yield takeLatest(FETCH_HELM_ACTIVITY_LOGS, fetchHelmActivityLogs);
    yield takeLatest(FETCH_HELM_ENV_STATE, fetchHelmState);
    yield takeLatest(FETCH_HELM_ENV_STATE_INITIATE, startFetchState);
    yield takeLatest(DELETE_HELM_ENVIRONMENT, deleteEnvironment);
    yield takeLatest(FETCH_HELM_LOGS_INITIATE, fetchHelmLogsInitiate);
    yield takeLatest(FETCH_HELM_LOGS, fetchHelmLogs);
    yield takeLatest(FETCH_HELM_PODS, fetchHelmPods);
    yield takeLatest(UPDATE_HELM_ENVIRONMENT, updateHelmEnv);
    yield takeLatest(LAUNCH_HELM_ENVIRONMENT, launchHelmEnv);
    yield takeLatest(START_HELM_ENVIRONMENT, startHelmEnv);
    yield takeLatest(STOP_HELM_ENVIRONMENT, stopHelmEnv);
    yield takeLatest(REDEPLOY_HELM_ENVIRONMENT, redeployHelmEnv);
    yield takeLatest(FETCH_HELM_ENV_INSIGHT, fetchHelmEnvInsight);
    yield takeLatest(FETCH_HELM_ENV_OVERVIEW, fetchHelmEnvOverview);
}
