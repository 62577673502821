// import { push } from "connected-react-router";
import * as yaml from "js-yaml";
import React,{ useEffect, useState } from "react";
import { OperatorClass } from "../../models/Operator.model";

//import actions from "actions";
// import Alert from "components/js/Alert";
// import Column from "components/js/Column";
// import Row from "components/js/Row";
// import { parseCSV } from "./OperatorInstanceForm";
// import OperatorSummary from "components/OperatorSummary/OperatorSummary";
// import OperatorHeader from "components/OperatorView/OperatorHeader";
// import { useDispatch, useSelector } from "react-redux";
// import { Action } from "redux";
// import { ThunkDispatch } from "redux-thunk";
// import * as url from "shared/url";
// import placeholder from "../../placeholder.png";
// import { IClusterServiceVersionCRD, IResource, IStoreState } from "../../shared/types";
import OperatorInstanceFormBody from "./OperatorInstanceFormBody";

type Props = {
  yamlValues: any;
  operatorDetails:OperatorClass;
  updateValues:(_newValues:any)=>void;
}

function OperatorInstanceUpdateForm({
  yamlValues,
  operatorDetails,
  updateValues
}:Props) {
  //const dispatch = useDispatch();
  const [defaultValues, setDefaultValues] = useState("");
  //const [currentValues, setCurrentValues] = useState("");
  // const [crd, setCRD] = useState(undefined);
  //const [icon, setIcon] = useState(placeholder);
  const [newValues, setNewValues] = useState(defaultValues);

  // useEffect(() => {
  //   // Clean up component state
  //   setDefaultValues("");
  //   setCurrentValues("");
  //   setCRD(undefined);
  //   //setIcon(placeholder);
  //   // dispatch(actions.operators.getResource(cluster, namespace, csvName, crdName, resourceName));
  //   // dispatch(actions.operators.getCSV(cluster, namespace, csvName));
  // }, [dispatch, csvName, crdName, resourceName]); //cluster, namespace,
  // const resource = null
  // const csv=null
  // const {
  //   operators: {
  //     isFetching,
  //     csv,
  //     resource,
  //     errors: {
  //       resource: { fetch: fetchError, update: updateError },
  //     },
  //   },
  // } = useSelector((state) => state);

  // useEffect(() => {
  //   if (resource) {
  //     setCurrentValues(yaml.dump(resource));
  //   }
  // }, [resource]);

  // useEffect(() => {
  //   if (csv) {
  //     //parseCSV(csv, crdName, setIcon, setCRD, setDefaultValues);
  //     parseCSV(csv, crdName, setCRD, setDefaultValues);
  //   }
  // }, [csv, crdName]);

  // if (!fetchError && !isFetching && !resource) {
  //   return <Alert>Resource {resourceName} not found</Alert>;
  // }

  // const updateNewValues = (newValues) => {
  //   setNewValues(newValues);
  // };

  //const handleDeploy = async (updatedResource) => {
  // const created = await dispatch(
  //   actions.operators.updateResource(
  //     cluster,
  //     namespace,
  //     updatedResource.apiVersion,
  //     crdName.split(".")[0],
  //     resourceName,
  //     updatedResource,
  //   ),
  // );
  // if (created) {
  //   dispatch(
  //     push(url.app.operatorInstances.view(cluster, namespace, csvName, crdName, resourceName)),
  //   );
  // }
  //};

  // if (fetchError) {
  //   return (
  //     <Alert theme="danger">
  //       An error occurred while fetching the ClusterServiceVersion: {fetchError.message}
  //     </Alert>
  //   );
  // }

  useEffect(() => {
    if (yamlValues) {
      const example = yaml.dump(yamlValues);
      if (example) {
        setDefaultValues(example);
        setNewValues(example);
      }
    }
  }, [yamlValues]);

  const updateNewValues = (_newValues:any) => {
    setNewValues(_newValues);
  };

  useEffect(() => {
    updateValues(newValues);
  }, [newValues]);

  return (
    <section>
      {/* <OperatorHeader title={`Update ${resourceName}`} icon={icon} /> */}
      {/* <section> */}
        {/* {updateError && (
          <Alert theme="danger">Found an error updating the instance: {updateError.message}</Alert>
        )} */}
        {/* <Row> */}
          {/* <Column span={3}>
            <OperatorSummary />
          </Column> */}
          {/* <Column span={9}>
            <p>{crd?.description}</p> */}
            <OperatorInstanceFormBody
              operatorDetails= {operatorDetails}
              //selectedResource={selectedResource}
              //isFetching={isFetching}
              //namespace={namespace}
              //handleDeploy={handleDeploy}
              defaultValues={defaultValues}
              //deployedValues={defaultValues}
              updateNewValues={updateNewValues}
              data-test="operator-form"
            />
          {/* </Column>
        </Row> */}
      {/* </section> */}
    </section>
  );
}

export default OperatorInstanceUpdateForm;
