import { call, takeLatest, put, select } from "redux-saga/effects";
import axios from "axios";
// import * as environmentSelectors from "./selectors";
import endpoints from "../../../../../constants/endpoints";
import { sessionTokenSelector } from "../../../../login/redux/selectors";
import { ADD_RULES, GET_ALERT_LISTS, GET_ALERT_LISTS_FAILURE, GET_ALERT_LISTS_SUCCESS, GET_ALERT_RULES, GET_ALERT_RULES_SUCCESS, GET_ALERT_STATUS, GET_ALERT_STATUS_FAILURE, GET_ALERT_STATUS_SUCCESS, INSTALL_TEMPLATE, NOTIFI_ALERT_LIST, NOTIFI_ALERT_LIST_SUCCESS, UNINSTALL_TEMPLATE, UPDATE_NOTIFI_ALERT_DETAIL } from "./action";
import toast from "../../../../../components/toast/Toast";

function callGetAlertLists(sessionToken, payload) {
    const config = {
      headers: {
        Authorization: "basic " + sessionToken,
      },
    };
    return axios.get(
      endpoints.ENVIRONMENT.GET_ALERTS_LISTS.replace(":eId", payload.data.id),
      config
    );
  }
  
  // Generator Call
  function* getAlertLists(payload) {
    try {
      const sessionToken = yield select(sessionTokenSelector);
      const response = yield call(callGetAlertLists, sessionToken, payload);
      const data = response.data;
      yield put({ type: GET_ALERT_LISTS_SUCCESS, data });
    } catch (error) {
      yield put({ type: GET_ALERT_LISTS_FAILURE });
    }
  }

  function callGetRules(sessionToken, payload) {
    const config = {
      headers: {
        Authorization: "basic " + sessionToken,
      },
    };
    return axios.get(
      endpoints.ENVIRONMENT.GET_ALERTS_RULES.replace(":eId", payload.data.id).replace(":template", payload.data.template),
      config
    );
  }
  
  // Generator Call
  function* getRules(payload) {
    try {
      const sessionToken = yield select(sessionTokenSelector);
      const response = yield call(callGetRules, sessionToken, payload);
      const data = response.data;
      
      yield put({ type: GET_ALERT_RULES_SUCCESS, data });
    } catch (error) {
      // yield put({ type: GET_ALERT_LISTS_FAILURE });
    }
  }

  function callAddRule(sessionToken, payload) {
    const config = {
      headers: {
        Authorization: "basic " + sessionToken,
      },
    };
    return axios.put(
      endpoints.ENVIRONMENT.ADD_RULES.replace(":eId", payload.data.id).replace(":template", payload.data.template),
      payload.data.jsonBody,
      config
    );
  }
  
  // Generator Call
  function* addRule(payload) {
    try {
      const sessionToken = yield select(sessionTokenSelector);
      yield call(callAddRule, sessionToken, payload);
      toast.success("Rules updated successfully");
      yield call(getRules, payload);
    } catch (error) {
      // yield put({ type: GET_ALERT_LISTS_FAILURE });
      toast.error("Error while updating rules");
    }
  }

  function callInstallTemplate(sessionToken, payload) {
    const config = {
      headers: {
        Authorization: "basic " + sessionToken,
      },
    };
    return axios.post(
      endpoints.ENVIRONMENT.INSTALL_ALERT_TEMPLATE.replace(":eId", payload.data.id).replace(":template", payload.data.template),
      payload.data.jsonBody,
      config
    );
  }
  
  // Generator Call
  function* installTemplate(payload) {
    try {
      const sessionToken = yield select(sessionTokenSelector);
      yield call(callInstallTemplate, sessionToken, payload);
      toast.success('Template install triggered');
    } catch (error) {
      // yield put({ type: GET_ALERT_LISTS_FAILURE });
      toast.error('Some thing wrong while installing Template');
    }
  }

  function callUnInstallTemplate(sessionToken, payload) {
    const config = {
      headers: {
        Authorization: "basic " + sessionToken,
      },
    };
    return axios.delete(
      endpoints.ENVIRONMENT.INSTALL_ALERT_TEMPLATE.replace(":eId", payload.data.id).replace(":template", payload.data.template),
      config
    );
  }
  
  // Generator Call
  function* unInstallTemplate(payload) {
    try {
      const sessionToken = yield select(sessionTokenSelector);
      yield call(callUnInstallTemplate, sessionToken, payload);
      yield call(getAlertLists, sessionToken , payload);
      toast.success('Template uninstall triggered');
    } catch (error) {
      // yield put({ type: GET_ALERT_LISTS_FAILURE });
      toast.error('Some thing wrong while Uninstalling Template');
    }
  }

  function callGetNotifiAlertLists(sessionToken, payload) {
    const config = {
      headers: {
        Authorization: "basic " + sessionToken,
      },
    };
    return axios.get(
      endpoints.ENVIRONMENT.NOTIFI_ALERT_DETAILS.replace(":eId", payload.data.id),
      config
    );
  }
  
  // Generator Call
  function* getNotifiAlertLists(payload) {
    try {
      const sessionToken = yield select(sessionTokenSelector);
      const response = yield call(callGetNotifiAlertLists, sessionToken, payload);
      const data = response.data;
      yield call(getAlertLists, sessionToken , payload);
      yield put({ type: NOTIFI_ALERT_LIST_SUCCESS, data });
    } catch (error) {
      // yield put({ type: GET_ALERT_LISTS_FAILURE });
    }
  }

  function callUpdateNotifiDetails(sessionToken, payload) {
    const config = {
      headers: {
        Authorization: "basic " + sessionToken,
      },
    };
    return axios.post(
      endpoints.ENVIRONMENT.NOTIFI_ALERT_DETAILS.replace(":eId", payload.data.id),
      payload.data.jsonBody,
      config
    );
  }
  
  // Generator Call
  function* updateNotifiDetails(payload) {
    try {
      const sessionToken = yield select(sessionTokenSelector);
      yield call(callUpdateNotifiDetails, sessionToken, payload);
      yield call(getAlertLists, sessionToken , payload);
      toast.success("Notification updated successfully")
    } catch (error) {
      // yield put({ type: GET_ALERT_LISTS_FAILURE });
      toast.error("Error while updating notification")
    }
  }

  function callAlertDashBoardStatus(sessionToken, payload) {
    const config = {
      headers: {
        Authorization: "basic " + sessionToken,
      },
    };
    return axios.get(
      endpoints.ENVIRONMENT.GET_ALERTS_DASHBOARD_STATUS.replace(":eId", payload.data.id).replace(":state_name", payload.data.status_name),
      config
    );
  }
  
  // Generator Call
  function* alertDashBoardStatus(payload) {
    try {
      const sessionToken = yield select(sessionTokenSelector);
      const response = yield call(callAlertDashBoardStatus, sessionToken, payload);
      const data = response.data;
      yield put({ type: GET_ALERT_STATUS_SUCCESS, data });
    } catch (error) {
      yield put({ type: GET_ALERT_STATUS_FAILURE });
      // toast.error("Error while fetching alert status");
    }
  }

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
    yield takeLatest(GET_ALERT_LISTS, getAlertLists);
    yield takeLatest(GET_ALERT_RULES, getRules);
    yield takeLatest(ADD_RULES, addRule);
    yield takeLatest(INSTALL_TEMPLATE, installTemplate);
    yield takeLatest(UNINSTALL_TEMPLATE, unInstallTemplate);
    yield takeLatest(NOTIFI_ALERT_LIST, getNotifiAlertLists);
    yield takeLatest(UPDATE_NOTIFI_ALERT_DETAIL, updateNotifiDetails);
    yield takeLatest(GET_ALERT_STATUS, alertDashBoardStatus);
  }