import { makeStyles, useTheme } from '@material-ui/core/styles'
import React, { memo } from 'react'
import { Offline, Online } from 'react-detect-offline'
// import { useSelector } from 'react-redux'
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom'
import paths from '../constants/paths'
import Breadcrumb from '../pages/header/Breadcrumb'
import Header from '../pages/header/Header'
import { hasBillingAddressSelector, hasValidSessionSelector } from '../pages/login/redux/selectors'
// import SideBar from '../pages/sidebar'
import styleConstants from '../theme/styleConstants'
import OfflineContent from './OfflineContent'
import GlobalWS from './GlobalWS'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Backdrop } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  toolbar: theme.mixins.toolbar,
  maincontent: {
    //padding : '2rem',
    paddingLeft:'2rem',
    paddingRight: '2rem',
    top: 64,
    position: 'relative',
    [ theme.breakpoints.down('xs') ]: {
      padding:'1rem',
    },
    paddingTop:'1rem',
    paddingBottom: '1rem !important'
    //zIndex:theme.zIndex.drawer + 1,
    //[ theme.breakpoints.up('lg') ]: {
    //  marginLeft: styleConstants.drawerWidth,
    //}
  },
  breadcrumb: {
    padding : '1rem 2rem 0rem 2rem',
    top: 64,
    position: 'relative',
    [ theme.breakpoints.down('xs') ]: {
      padding : '1rem 2rem 0rem 1rem',
    },
    //zIndex:theme.zIndex.drawer + 1,
    //[ theme.breakpoints.up('lg') ]: {
    //  marginLeft: styleConstants.drawerWidth,
    //}
  },
  content: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginLeft: styleConstants.drawerMiniWidth,
    // [ theme.breakpoints.down('sm') ]: {
    //   marginLeft: '0px',
    // },
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: styleConstants.drawerWidth,
  },
  backdrop: {
    zIndex: theme.zIndex.appBar + 1,
    color: '#fff',
  },
}));

export const PrivateRoute = ({ component: Component, location, hasValidSession, hasBillingAddress, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
  const [ sideBarOpen, setSideBarOpen ] = React.useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
  
  // const handleDrawerToggle = () => {
  //   setSideBarOpen(!sideBarOpen);
  // };

  const handleDrawerOpen = () => {
    setSideBarOpen(true);
  };

  const handleDrawerClose= () => {
    setSideBarOpen(false);
  };

  // const hasValidSession = useSelector(hasValidSessionSelector);
  // const hasBillingAddress = useSelector(hasBillingAddressSelector)

  const polling = {
    enabled: false
  };
  return (
      <Route
        { ...rest }
        render={ props =>
          hasValidSession ? (
            <div>
              <Header handleDrawerOpen = { handleDrawerOpen } handleDrawerClose = { handleDrawerClose } isSideBarOpen={sideBarOpen} history={ props.history } data-test="header-component"/>
              <GlobalWS />
                <Online polling={ polling }>
                    <div className={ `${ classes.breadcrumb } ${ (!isMobile && sideBarOpen) ? classes.contentShift : classes.content }` }> 
                        <Breadcrumb />
                    </div>
                </Online>
                {/* <SideBar handleDrawerOpen = { handleDrawerOpen } handleDrawerClose = { handleDrawerClose } isSideBarOpen = { sideBarOpen } history={ props.history } handleDrawerToggle={ handleDrawerToggle } data-test="sidebar-component"/> */}
                <div className={ `${ classes.maincontent } ${ (!isMobile && sideBarOpen) ? classes.contentShift + " sidebar-open" : classes.content }` }>
                    <Offline polling={ polling }>
                      <Backdrop className={classes.backdrop} open={true}>
                        <OfflineContent />
                      </Backdrop>
                    </Offline>
                    {/* <Online polling={ polling }> */}
                      {
                        ((location.pathname === paths.CREATEPROJECT || location.pathname === paths.CREATE_ORGANIZATION || location.pathname === paths.ACCOUNTDETAILS) && !hasBillingAddress) ? (
                          (location.pathname === paths.CREATEPROJECT || location.pathname === paths.CREATE_ORGANIZATION) ? (
                              <Redirect
                                to={{
                                  pathname: paths.ACCOUNTDETAILS,
                                  hash: "#billing",
                                  state: { data: "no-billing-address" },
                                }} 
                              />
                          ) : (
                              <Component { ...props } />
                          )
                        ) : (
                          <Component { ...props } />
                        )
                      }
                    {/* </Online> */}
                </div>
            </div>
        ) : (
            <>
                { 
              // props.path ? 
                    <Redirect to={ { pathname: paths.DEFAULT, state: { from: location } } } /> 
            // <Component { ...props } />
          }
            </>
        )
      }
      data-test="main-route"
      />
  )
}

/* istanbul ignore next */
const mapStateToProps = state => ({
  hasValidSession: hasValidSessionSelector(state),
  hasBillingAddress: hasBillingAddressSelector(state)
})

export default memo(connect(mapStateToProps)(PrivateRoute))
