import React, { useState } from 'react'
import paths from '../../../constants/paths'
import AddUserPopup from '../../adduserpopup/AddUserPopup'
import DeleteUserPopup from '../../deleteUserPopup/deleteUserPopup';
import UsersListTable from './UsersListTable';
import PropTypes from "prop-types";
import CustomButton from '../../custombutton/CustomButton';
import { useTranslation } from 'react-i18next';
import { isAuthorized } from '../../../helpers/utils';
import { getOrgGroups } from '../../../pages/organization/redux/actions'
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from "redux";
import { History } from 'history';
import { EnvironmentModel, UserRole } from '../../../models/Environment.model';
import { EnvironmentUser } from '../../../models/Account.model';

interface Props extends PropsFromRedux{
    history: History,
    envRole: UserRole,
    usersList:EnvironmentUser[],
    currentUser: any,
    environmentDetails: EnvironmentModel,
    helm?: boolean 
}

export const EnvPermission = (props: Props) => {
    const [isDeleteUserPopupOpened, setIsDeleteUserPopupOpened] = useState(0)
    const [userIdToBeDeleted, setUserIdToBeDeleted] = useState(0)
    const [isAddUserPopupOpened, setIsAddUserPopupOpened] = useState(0)
    const [userToBeEdited, setUserToBeEdited] = useState<EnvironmentUser|null>(null)
    const [edit, setEdit] = useState(false)
    const [isLeave, setIsLeave] = useState(false)
    const [t] = useTranslation()

    const handleAddUser = () => {
        setIsAddUserPopupOpened(Math.random())
        setEdit(false)
        setUserToBeEdited(null)
    }

    const handleDeleteUser = (userId: number) => {
        setUserIdToBeDeleted(userId)
        setIsDeleteUserPopupOpened(Math.random())
    }

    const handleLeaveEnvironment = (userId: number) => {
        setUserIdToBeDeleted(userId)
        setIsDeleteUserPopupOpened(Math.random())
        setIsLeave(true)
    }

    // const setEditFalse = () => {
    //     setIsAddUserPopupOpened(Math.random())
    //     setEdit(false)
    //     setUserToBeEdited(null)
    // }

    const handleEdit = (user: EnvironmentUser) => {
        setUserToBeEdited(user)
        setIsAddUserPopupOpened(Math.random())
        setEdit(true)
    }

    const handleClose = () => {
        setIsLeave(false)
    }

    const goToGroups = (id: string) => {
        props.history.push(`${paths.ORGANIZATIONINFO.replace(":id", id)}#groups`)
    }

    return (
        <div data-test="main-component">
            {isAuthorized('addUser', props.envRole?.name) && (
                <div className='alignRight addButtonContainer'>
                    <CustomButton data-test="add-user-btn" label={t('Environment.UserPermissionsTab.addUser')} onClick={() => handleAddUser()} />
                </div>
            )}
            <UsersListTable
                usersList={props.usersList}
                handleLeaveEnvironment={handleLeaveEnvironment}
                handleEdit={handleEdit}
                handleDeleteUser={handleDeleteUser}
                envRole={props.envRole}
                goToGroups={goToGroups}
                currentUser={props.currentUser}
            />
            <AddUserPopup
                environmentDetails={props.environmentDetails}
                data-test="add-user-popup" open={isAddUserPopupOpened}
                instance={edit ? userToBeEdited : null}
                // edit={edit}
                getOrgGroups={props.getOrgGroups}
                environmentDetailsComponent
                helm={props.helm}
            />
            <DeleteUserPopup
                open={isDeleteUserPopupOpened}
                environmentDetails={props.environmentDetails}
                environmentDetailsComponent
                userId={userIdToBeDeleted}
                isLeave={isLeave}
                history={props.history}
                handleClose={handleClose}
                helm={props.helm} 
            />

        </div>
    )
}

const mapStateToProps = (state: any) => ({
    currentUser: state.AuthReducer.user,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getOrgGroups: () => dispatch(getOrgGroups()),
})

// export default connect(mapStateToProps, mapDispatchToProps)(EnvPermission)

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EnvPermission);

EnvPermission.propTypes = {
    usersList: PropTypes.any.isRequired,
    environmentDetails: PropTypes.any.isRequired,
    envRole: PropTypes.any.isRequired,
};
