import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { makeStyles, Tabs, Tab} from "@material-ui/core";
//import PropTypes from "prop-types";
//import { useTranslation } from 'react-i18next';
import paths from "../../../../constants/paths";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
//import HelmPermissions from './HelmPermissions';
import {TabPanel} from "../../../../components/tabpanel/TabPanel"
import ScheduleEnv from '../../../environment/environmentinfo/settingstab/schedule/ScheduleEnv';

/* istanbul ignore next */
function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "inherit",
        display: "flex",
        padding: "0",
        margin: "0",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        [theme.breakpoints.down("md")]: {
            backgroundColor: "white",
        },
    },
    tabPanel: {
        padding: "0",
        margin: "0",
        width: "100%",
        minWidth: 250,
    },
}));

export const HelmSettings = (props) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(
        props.value ?? props.history?.location?.state?.value ?? 0
    );
    //const [t] = useTranslation()

    const getNamebyTabIndex = (newValue) => {
        const tabPanelObj = document.getElementById('settingsTab-' + newValue);
        if (tabPanelObj) {
            const name = tabPanelObj.getAttribute("name");
            if (name) {
                props.history.push({
                    pathname: paths.HELMENVIRONMENTINFO.replace(":eId", props.helmEnvironmentDetails?.id) + "#settings_" + name
                });
            }
        }
    }

    const handleChange = (_, newValue) => {
        getNamebyTabIndex(newValue);
        props.updateInnerTab(newValue);
    };

    /* istanbul ignore next */
    useEffect(() => {
        setValue(props.innerTab);
    }, [props.innerTab]);

    /* istanbul ignore next */
    useEffect(() => {
        return (() => {
            props.updateInnerTab(0);
        })
    }, []);

    const tabIndexes = React.useMemo(() => {
        return {
            //Permissions: 0,
            Scheduler: 0
        }
    }, [])

    const tabs = React.useMemo(() => {
        return [
            // {
            //     name: "permissions",
            //     dataTest: "permission-tab",
            //     label: `${t('Environment.SettingsTab.permissions')}`
            // },
            {
                name: "scheduler",
                dataTest: "scheduler-tab",
                label: "Scheduler" //`${t('Environment.SettingsTab.permissions')}`
            }
        ]

    }, [])

    return (
        <div className={classes.root} data-test="main-container">
            <Tabs
                orientation={isWidthDown("md", props.width) ? "" : "vertical"}
                value={value}
                variant="scrollable"
                onChange={handleChange}
                className={classes.tabs}
                indicatorColor="primary"
                data-test="tabs-container"
            >
                {tabs.map((_tab, ind) => (
                    <Tab key={ind} label={_tab.label} {...a11yProps(ind + 1)} data-test={_tab.dataTest} name={_tab.name} />
                ))}

            </Tabs>
            {/* <TabPanel value={value} index={tabIndexes.Permissions} className={classes.tabPanel} name="permissions" data-test="permission-tabpanel" padding={2} tabPanel="settingsTab">
                <HelmPermissions history={props.history} />
            </TabPanel> */}
            <TabPanel value={value} index={tabIndexes.Scheduler} className={classes.tabPanel} name="scheduler" data-test="scheduler-tabpanel" padding={2} tabPanel="settingsTab">
                <ScheduleEnv isHelmEnv={props.isHelmEnv} />
            </TabPanel>
        </div>
    )
}

/* istanbul ignore next */
const mapStateToProps = (state) => ({
    helmEnvironmentDetails: state.HelmEnvironmentReducer.helmEnvironmentDetails

})

/* istanbul ignore next */
const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(HelmSettings))
