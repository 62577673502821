import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  ButtonBase,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  clearSpecificReport,
  fetchScannerPlugins,
  fetchSpecificReport,
  startScan,
} from "../../redux/actions";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import KeyValueRow from "../../../../components/keyvaluerow/KeyValueRow";
import AceEditor from "react-ace";
import * as yaml from "js-yaml";
import { Skeleton } from "@material-ui/lab";
import BackdropLoader from "../../../../components/loader/BackdropLoader";
import VulnerableDetail from "./VulnerableDetail";
import { getDiffDays, gitDiffBtnScanDate } from "../../../../helpers/utils";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import GetAppIcon from "@material-ui/icons/GetApp";

const useStyles = makeStyles({
  root: {
    width: "100%",
    overflowX: "auto",
    marginTop: "20px",
  },
  table: {
    minWidth: 650,
  },
  backBtn: {
    color: "#357dfd",
    marginBottom: "20px",
  },
  severity: {
    color: "#fff",
    padding: "5px 10px",
    borderRadius: "5px",
    width: "55px",
    textAlign: "center",
  },
  cardHeader: {
    padding: "0 !important",
  },
  expand: {
    transform: "rotate(0deg)",
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  cardHeaderAction: {
    marginTop: 8,
    marginRight: 20,
  },
  downloadIcon: {
    cursor: "pointer",
  },
});

type JsonBody = {
  name: string;
};

interface Props extends PropsFromRedux {
  handleBack: () => void;
  listID: number;
}

function SecurityDetails(props: Props) {
  const classes = useStyles();

  const [filterSeverity, setFilterSeverity] = useState("ALL");
  const [filteredData, setFilteredData] = useState<any>(null);

  useEffect(() => {
    props.fetchScannerPlugins(props.environmentDetails.id);
    props.fetchSpecificReport(props.environmentDetails.id, props.listID);

    return () => {
      props.clearSpecificReport();
    };
  }, []);

  useEffect(() => {
    setFilteredData(props.specificReport?.vulnerability);
  }, [props.specificReport]);

  const handleSeverityFilter = (e: React.ChangeEvent<{ value: unknown }>) => {
    const selected = e.target.value as string;
    setFilterSeverity(selected);
    if (selected === "ALL") {
      setFilteredData(props.specificReport?.vulnerability);
    } else {
      const vulnerability: any = props.specificReport?.vulnerability.filter(
        (el: any) => el.properties.severity === selected
      );
      setFilteredData(vulnerability);
    }
  };

  const keyValueInfo = (key: string, value: string) => {
    return <KeyValueRow rowKey={key} rowValue={value} />;
  };

  const keyValueSkeleton = () => {
    return (
      <>
        <Grid item xs={4}>
          <Skeleton variant="rect" />
        </Grid>
        <Grid item xs={8}>
          <Skeleton variant="rect" />
        </Grid>
      </>
    );
  };

  const severityCount = (severity: string) => {
    let count = 0;
    if (props.specificReport?.vulnerability !== null) {
      props.specificReport?.vulnerability.forEach((el: any) => {
        if (el.properties.severity === severity) {
          count++;
        }
      });
    }
    return count;
  };

  const customKeyValue = (key: string, value: string, color: string) => {
    return (
      <Grid data-test="mainContainer" container>
        <Grid item xs={4}>
          <Tooltip title={key} arrow>
            <Typography
              style={{ color: color }}
              variant="h6"
              className="oneLine"
              data-test="rowKey"
            >
              {key}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid item xs={8}>
          <span>{value}</span>
        </Grid>
      </Grid>
    );
  };

  const handleDownload = (data: any, type: string) => {
    let jsonString;
    if (type === "output") {
      const parsedData = JSON.parse(data);
      jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
        JSON.stringify(parsedData)
      )}`;
    } else {
      jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
        JSON.stringify(data)
      )}`;
    }

    const link = document.createElement("a");
    link.href = jsonString;
    link.download = "data.json";

    link.click();
  };

  return (
    <>
      <ButtonBase
        onClick={() => {
          props.handleBack();
        }}
        data-test="backButton"
        className={classes.backBtn}
      >
        <ArrowBackIcon fontSize="small" />{" "}
        <span className="jobNav">back to list</span>
      </ButtonBase>

      <Grid container spacing={2}>
        <Grid item>
          {props.specificReport?.status === "completed" ? (
            <Tooltip title="status completed" arrow>
              <CheckOutlinedIcon
                fontSize="medium"
                style={{ color: "#4CAF50" }}
              />
            </Tooltip>
          ) : props.specificReport?.status === "error" ? (
            <Tooltip title="status error" arrow>
              <CloseOutlinedIcon
                fontSize="medium"
                style={{ color: "#FF5252" }}
              />
            </Tooltip>
          ) : (
            <Tooltip title="status pending" arrow>
              <CircularProgress size={25} style={{ color: "#FF9800" }} />
            </Tooltip>
          )}
        </Grid>
        <Grid item>
          {" "}
          <Typography variant="h3">
            {props.specificReport?.name} report
          </Typography>
        </Grid>
      </Grid>
      <br />

      <div>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Card>
              <CardContent>
                {props.specificReport ? (
                  <Grid container direction="column" justify="center">
                    {keyValueInfo("Name", props.specificReport?.name)}
                    {keyValueInfo(
                      "Created At",
                      getDiffDays(props.specificReport?.CreatedAt, true)
                    )}
                    {keyValueInfo(
                      "Plugin Name",
                      props.specificReport?.plugin_name
                    )}
                    {keyValueInfo("Status", props.specificReport?.status)}
                    {keyValueInfo(
                      "Time Taken",
                      gitDiffBtnScanDate(props.specificReport.time_taken)
                    )}
                  </Grid>
                ) : (
                  <Grid container justify="center" spacing={2}>
                    {keyValueSkeleton()}
                    {keyValueSkeleton()}
                    {keyValueSkeleton()}
                    {keyValueSkeleton()}
                    {keyValueSkeleton()}
                  </Grid>
                )}
              </CardContent>
            </Card>
          </Grid>

          <Grid item md={6} xs={12}>
            <Card>
              <CardContent>
                {props.specificReport ? (
                  <Grid container direction="column" justify="center">
                    {customKeyValue(
                      "Low",
                      severityCount("LOW").toString(),
                      "#2196f3"
                    )}
                    {customKeyValue(
                      "High",
                      severityCount("HIGH").toString(),
                      "#ff9800"
                    )}
                    {customKeyValue(
                      "Medium",
                      severityCount("MEDIUM").toString(),
                      "#CBCB1E"
                    )}
                    {customKeyValue(
                      "Critical",
                      severityCount("CRITICAL").toString(),
                      "#f44336"
                    )}
                    {customKeyValue(
                      "Total Vulnerabilities",
                      props.specificReport.vulnerability !== null ||
                        props.specificReport.vulnerability?.length > 0
                        ? props.specificReport.vulnerability?.length
                        : 0,
                      "#43425D"
                    )}
                  </Grid>
                ) : (
                  <Grid container justify="center" spacing={2}>
                    {keyValueSkeleton()}
                    {keyValueSkeleton()}
                    {keyValueSkeleton()}
                    {keyValueSkeleton()}
                    {keyValueSkeleton()}
                  </Grid>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {props.specificReport?.vulnerability !== null &&
          props.specificReport?.vulnerability.length > 0 && (
            <div>
              <Card className="m-t-20">
                <CardHeader
                  title={
                    <Typography variant="h5" display="inline">
                      <strong>Vulnerability</strong>
                    </Typography>
                  }
                  action={
                    <Grid container spacing={4} alignItems="center">
                      <Grid item>
                        <FormControl
                          className="w-100"
                          variant="outlined"
                          margin="normal"
                        >
                          <Select
                            name="provider"
                            value={filterSeverity}
                            color="primary"
                            onChange={(
                              e: React.ChangeEvent<{ value: unknown }>
                            ) => handleSeverityFilter(e)}
                            data-test="provider-select"
                            MenuProps={{
                              getContentAnchorEl: null,
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                            }}
                          >
                            <MenuItem value="ALL">ALL</MenuItem>
                            <MenuItem value="LOW">LOW</MenuItem>
                            <MenuItem value="HIGH">HIGH</MenuItem>
                            <MenuItem value="MEDIUM">MEDIUM</MenuItem>
                            <MenuItem value="CRITICAL">CRITICAL</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <Tooltip title="Download">
                          <GetAppIcon
                            onClick={() =>
                              handleDownload(
                                props.specificReport?.vulnerability,
                                "vulnerability"
                              )
                            }
                            className={classes.downloadIcon}
                            color="primary"
                            fontSize="large"
                          >
                            download
                          </GetAppIcon>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  }
                />
                <Divider />
                <CardContent>
                  {!(filteredData === undefined || filteredData === null) &&
                    filteredData.length > 0 &&
                    filteredData.map((el: any, ind: number) => (
                      <>
                        <VulnerableDetail detail={el} serialNum={ind + 1} />
                      </>
                    ))}
                </CardContent>
              </Card>
            </div>
          )}

        {(props.specificReport?.vulnerability === null ||
          props.specificReport?.vulnerability.length === 0) &&
          props.specificReport?.status === "completed" && (
            <div>
              <Card className="m-t-20">
                <CardHeader
                  title="Output"
                  action={
                    <Tooltip title="Download">
                      <GetAppIcon
                        onClick={() =>
                          handleDownload(
                            props.specificReport?.source_data,
                            "output"
                          )
                        }
                        className={classes.downloadIcon}
                        color="primary"
                        fontSize="large"
                      >
                        download
                      </GetAppIcon>
                    </Tooltip>
                  }
                />
                <CardContent>
                  <AceEditor
                    data-test="editor-container"
                    mode="yaml"
                    theme={"xcode"}
                    width="100%"
                    readOnly={true}
                    setOptions={{ showPrintMargin: false }}
                    editorProps={{ $blockScrolling: Infinity }}
                    value={yaml.dump(props.specificReport?.source_data)}
                    className="editor"
                    fontSize="15px"
                  />
                </CardContent>
              </Card>
            </div>
          )}
      </div>

      {props.fetchingSpecificReport && (
        <BackdropLoader message="fetching security report ... " />
      )}
    </>
  );
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  scannerDetails: state.EnvironmentReducer.scannerDetails,
  environmentDetails: state.EnvironmentReducer.environmentDetails,
  specificReport: state.EnvironmentReducer.specificReport,
  fetchingSpecificReport: state.EnvironmentReducer.fetchingSpecificReport,
});
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    fetchScannerPlugins: (id: number) => dispatch(fetchScannerPlugins(id)),
    startScan: (id: number, plugin: JsonBody) =>
      dispatch(startScan(id, plugin)),
    fetchSpecificReport: (eId: number, listID: number) =>
      dispatch(fetchSpecificReport(eId, listID)),
    clearSpecificReport: () => dispatch(clearSpecificReport()),
  };
};

const connector = connect(mapStateToProps, mapDispatchtoProps);
export default connector(SecurityDetails);
type PropsFromRedux = ConnectedProps<typeof connector>;
