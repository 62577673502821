import {
    Grid, Card, CardHeader, Collapse, IconButton, CardContent, makeStyles, FormControlLabel, Switch,
} from '@material-ui/core';
import React, { useState } from 'react'
import { connect } from 'react-redux';
// import DomainInfoCard from '../../../components/domainInfoCard/DomainInfoCard';
import { fetchHelmActivityLogs, fetchHelmEnvOverview } from '../redux/actions';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ActivityLogs from '../../../components/activitylogs/ActivityLogs';
import BackdropLoader from '../../../components/loader/BackdropLoader';
import HelmServiceDetails from '../../../components/HelmServiceDetails/HelmServiceDetails';
import { getDurationInSeconds } from '../../../helpers/utils';
import OverviewInsights from '../../../components/environment/overview/OverviewInsights';
import { AppConstants } from '../../../constants/appconstants';
import { useTranslation } from 'react-i18next';

/* istanbul ignore next */
const useStyles = makeStyles((theme) => ({
    overviewImg: {
        width: 150,
        float: "right",
        [theme.breakpoints.down('xs')]: {
            width: 80
        }
    },

    envState: {
        display: "inline",
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
}));

export function HelmOverviewTab(props) {
    const [showActivity, setShowActivity] = React.useState(false)
    const [selectedNameSpace, setSelectedNameSpace] = React.useState("")
    const [showAllLogs, setShowAllLogs] = useState(false);
    const classes = useStyles()
    const [t] = useTranslation()

    const getUsageData = ({
        id,
        duration = "Hour"
    }) => {
        if (!id) {
            id = props.helmEnvironmentDetails?.id
        }
        const nowInS = Math.round(new Date().getTime() / 1000);
        let payload = {
            start_time: nowInS - getDurationInSeconds(duration),
            end_time: nowInS,
        };
        props.fetchHelmEnvOverview(id, payload);
    }

    React.useEffect(() => {
        if (props.helmEnvironmentDetails?.id) {
            props.fetchHelmActivityLogs(props.helmEnvironmentDetails?.id)
            getUsageData({})
        }
    }, [props.helmEnvironmentDetails?.id])

    React.useEffect(() => {
        if (props.helmEnvironmentState?.state && props.helmEnvironmentState?.pod_status?.length) {
            setSelectedNameSpace(props.helmEnvironmentState?.pod_status[0]?.namespace)
        }
    }, [props.helmEnvironmentState])

    React.useEffect(() => {
        if (props.helmActivityLogs) {
            setShowActivity(true)
        }
    }, [props.helmActivityLogs])

    const handleFilterLogs = (e) => {
        setShowAllLogs(e.target.checked)
    }

    const { helmEnvironmentState, helmServiceDetails } = props
    return (
        <div data-test="overviewContainer">
            {helmEnvironmentState?.state === AppConstants.EnvironmentStatus.Running && (
                <Grid container spacing={2} data-test="overviewCardContainer">
                    {/* <Grid item md={6} xs={12} >
                        <DomainInfoCard environmentState={helmEnvironmentState} tab="Overview" helm={true} />
                    </Grid> */}
                    <Grid item md={12} xs={12}>
                        <HelmServiceDetails serviceDetails={helmServiceDetails} />
                    </Grid>
                </Grid>
            )}
            <div className="m-t-10">
                <Card>
                    <CardHeader
                        title={t('HelmEnvironment.activities')}
                        // onClick={() => setShowActivity(!showActivity)}
                        action={
                            <>
                            {
                              showActivity && 
                                <FormControlLabel
                                    control={
                                    <Switch
                                        name="push"
                                        color="primary"
                                        checked={showAllLogs}
                                        onChange={e => handleFilterLogs(e)}
                                    />
                                    }
                                    label="Show All Logs"
                                />
                            }

                            <IconButton
                                // onClick={() => this.handleShowActivity()}
                                onClick={() => setShowActivity(!showActivity)}
                                aria-label="Show/Hide Activity"
                                aria-expanded={showActivity}
                                className={clsx(classes.expand, {
                                    [classes.expandOpen]: showActivity,
                                })}
                            >
                                <ExpandMoreIcon />
                            </IconButton>
                            </>
                        }
                    >
                    </CardHeader>
                    <Collapse in={showActivity} timeout={600} unmountOnExit>
                        <CardContent>
                            <div>
                                <ActivityLogs activityLogs={props.helmActivityLogs} realTimeActivityLogs={props.realTimeHelmActivityLogs} showAllLogs={showAllLogs} />
                            </div>
                        </CardContent>
                    </Collapse>
                </Card>
                {props.helmEnvironmentState?.ready && props.helmEnvironmentState?.state !== AppConstants.EnvironmentStatus.Stopped && props.helmEnvironmentOverview && (
                    <OverviewInsights
                        environmentDetails={props.helmEnvironmentDetails}
                        environmentState={props.helmEnvironmentState}
                        environmentOverviewInsights={props.helmEnvironmentOverview}
                        getUsageData={getUsageData}
                        selectedNameSpace={selectedNameSpace}
                    />
                )}
            </div>
            {/* {!helmServiceDetails && (<BackdropLoader message="Fetching Helm Service details" />)} */}
            {props.fetchingHelmEnvOverview && (<BackdropLoader message={t('HelmEnvironment.overviewInsights')} data-test="fetch-overview" />)}
        </div>
    )
}

/* istanbul ignore next */
const mapStateToProps = state => ({
    helmEnvironmentDetails: state.HelmEnvironmentReducer.helmEnvironmentDetails,
    helmEnvironmentMetadata: state.HelmEnvironmentReducer.helmEnvironmentMetadata,
    helmEnvironmentState: state.HelmEnvironmentReducer.helmEnvironmentState,
    isDataNotFound: state.HelmEnvironmentReducer.isDataNotFound,
    helmActivityLogs: state.HelmEnvironmentReducer.helmActivityLogs,
    realTimeHelmActivityLogs: state.HelmEnvironmentReducer.realTimeHelmActivityLogs,
    helmServiceDetails: state.HelmEnvironmentReducer.helmServiceDetails,
    helmEnvironmentOverview: state.HelmEnvironmentReducer.helmEnvironmentOverview,
    fetchingHelmEnvOverview: state.HelmEnvironmentReducer.fetchingHelmEnvOverview,
})

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch) => ({
    fetchHelmActivityLogs: (id) => dispatch(fetchHelmActivityLogs(id)),
    fetchHelmEnvOverview: (id, duration) => dispatch(fetchHelmEnvOverview(id, duration)),

})

export default connect(
    mapStateToProps,
    mapDispatchtoProps,
)(HelmOverviewTab)
