import React, { Component } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  MenuItem,
  Menu,
  IconButton,
  Typography,
  Divider,
  LinearProgress,
} from "@material-ui/core";
import { withTranslation,WithTranslation } from "react-i18next";
import { connect,ConnectedProps } from "react-redux";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import "./storagetab.css";
import CustomButton from "../../../../../components/custombutton/CustomButton";
import StoragePopup from "../../../../../components/storagepopup/StoragePopup";
import BackdropLoader from "../../../../../components/loader/BackdropLoader";
import ConfirmDeletePopup from "../../../../../components/confirmdeletepopup/ConfirmDeletePopup";
import {
  getStorage,
  createStorage,
  editStorage,
  deleteStorage,
  clearStorageData,
} from "../../../redux/actions";
import { spaceConversion, isAuthorized } from "../../../../../helpers/utils";
import KeyValueRow from "../../../../../components/keyvaluerow/KeyValueRow";
import NoContentImage from "../../../../../components/nocontentimagecontainer/NoContentImage";
import FileManager from "../../../../../components/filemanager/FileManager"
import  {Dispatch} from 'redux';
import { Status, Storage as StorageModel } from "../../../../../models/Environment.model";
interface Iprops extends PropsFromRedux,WithTranslation{
}
interface ItempPayload {
  name: string;
  capacity: number;
}
interface InonTempPayload{
  name: string;
  capacity: number;
  mount_path: string;
  storage_type: string;
  attached_to: string;
}

type StoragePayload=InonTempPayload|ItempPayload
interface Istate {
  isAddStoragePopupOpened: boolean,
  anchorEl: Element | ((element: Element) => Element) | null;
  isDeleteStoragePopUpOpen: boolean,
  isEditStoragePopUpOpen: boolean,
  currentStorage: null|StorageModel
}

type Tdata={phase:string,accessModes:string,capacity:string}

export class Storage extends Component<Iprops,Istate> {
  constructor(props:Iprops) {
    super(props);
    this.state = {
      isAddStoragePopupOpened: false,
      anchorEl: null,
      isDeleteStoragePopUpOpen: false,
      isEditStoragePopUpOpen: false,
      currentStorage: null,
    };
  }

  componentDidMount = () => {
    if (this.props.environmentDetails?.id) {
      this.props.getStorage(this.props.environmentDetails?.id);
    }
  };

  UNSAFE_componentWillReceiveProps = (newProps:Iprops) => {
    if (!this.props.environmentDetails?.id && newProps.environmentDetails?.id) {
      this.props.getStorage(newProps.environmentDetails?.id);
    }
  };

  handleMenuOpen = (e:React.MouseEvent<HTMLButtonElement, MouseEvent>, storage:StorageModel) => {
    this.setState({
      anchorEl: e.currentTarget,
      currentStorage: storage,
    });
  };

  handleMenuClose = () => {
    this.setState({
      anchorEl: null,
      // currentStorage: null,
    });
  };

  handleAddStorage = () => {
    this.setState({
      isAddStoragePopupOpened: true,
    });
  };

  addStorageAgree = (payload:StoragePayload) => {
    this.props.createStorage(this.props.environmentDetails?.id, payload);
    this.setState({
      isAddStoragePopupOpened: false,
    });
  };

  addStorageDisagree = () => {
    this.setState({
      isAddStoragePopupOpened: false,
    });
  };

  deleteStorage = () => {
    this.setState(
      {
        isDeleteStoragePopUpOpen: true,
      },
      () => {
        this.handleMenuClose();
      }
    );
  };

  deleteStorageAgree = () => {
    if( this.state.currentStorage && this.state.currentStorage.id){
      this.props.deleteStorage(
        this.props.environmentDetails?.id,
        this.state.currentStorage.id
      );
      this.setState({
        isDeleteStoragePopUpOpen: false,
        currentStorage: null,
      });
      this.handleMenuClose();
    }

  };

  deleteStorageDisagree = () => {
    this.setState({
      isDeleteStoragePopUpOpen: false,
      currentStorage: null,
    });
    this.handleMenuClose();
  };

  editStorage = () => {
    this.setState(
      {
        isEditStoragePopUpOpen: true,
      },
      () => {
        this.handleMenuClose();
      }
    );
  };

  editStorageAgree = (payload:StoragePayload) => {
    if( this.state.currentStorage &&  this.state.currentStorage.id){
      this.props.editStorage(
        this.props.environmentDetails?.id,
        this.state.currentStorage.id,
        payload
      );
      this.setState({
        isEditStoragePopUpOpen: false,
        currentStorage: null,
      });
      this.handleMenuClose();
    }
  };

  getStatus = (id:number) => {
    if (this.props.envStorage?.manifest) {
      let data:Partial<Tdata>={};
      const s = this.props.envStorage.manifest.find((e:{id:number,data:{status:Status}}) => {
        return e.id === id;
      });
      if (s) {
        let phase:string;
        if (s.data?.status) {
          if (
            s.data?.status?.conditions &&
            s.data?.status?.conditions?.length > 0
          ) {
            phase = s.data?.status?.conditions[0]?.type;
          } else {
          phase = s.data?.status?.phase;
          }
          data={phase,accessModes:s.data?.status?.accessModes?.join(", "),capacity:s.data.status?.capacity?.storage}
        }
      }
      return data
    }
  };

  editStorageDisagree = () => {
    this.setState({
      isEditStoragePopUpOpen: false,
      currentStorage: null,
    });
    this.handleMenuClose();
  };

  componentWillUnmount = () => {
    this.props.clearStorageData();
  };

  render() {
    const {t} = this.props;
    return (
      <div data-test="storageMainContainer">
        {this.props.environmentDetails?.service_type > 0 && isAuthorized("update", this.props.envRole?.name) && (
          <div className="alignRight addButtonContainer">
            <CustomButton
              data-test="addStorageButton"
              label={t('Environment.StorageTab.addStorage')}
              onClick={() => this.handleAddStorage()}
            />
          </div>
        )}
        <FileManager />
        <Grid>
          <Grid container spacing={3}>
            {this.props.envStorage?.storage?.length > 0 ? (
              this.props.envStorage?.storage?.map((sto:StorageModel, _ind:number) => (
                <Grid data-test="storageGrid" item md={4} key={_ind}>
                  <Card>
                    <CardHeader
                      action={
                        <IconButton
                          aria-label="settings"
                          onClick={(e) => this.handleMenuOpen(e, sto)}
                          data-test="icon-button"
                        >
                          <MoreVertIcon />
                        </IconButton>
                      }
                      title={
                        <Typography color="primary" variant="h5">
                          {sto.name}
                        </Typography>
                      }
                    />
                    <Menu
                      //id={`simple-menu-${index}`}
                      disableScrollLock={true}
                      data-test="storageMenuOption"
                      anchorEl={this.state.anchorEl}
                      open={Boolean(this.state.anchorEl)}
                      onClose={() => this.handleMenuClose()}
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <MenuItem onClick={() => this.editStorage()} data-test="edit-label"> 
                      {t('Projects.LBpopup.editLabel')}
                      </MenuItem>
                      {this.props.environmentDetails?.service_type > 0 && (
                        <MenuItem onClick={() => this.deleteStorage()} data-test="delete-label">
                        {t("StoragePopup.deleteStorageLabel")}
                        </MenuItem>
                      )}
                    </Menu>
                    <Divider />
                    <CardContent>
                        {/* <Grid container spacing={3} style={{margin: 2}}> */}
                          {this.getStatus(sto.id)?.phase && (
                            <KeyValueRow keyXs={6}  rowKey={"Status"} rowValue={this.getStatus(sto.id)?.phase||""} variant="h5"/>
                          )}
                          {/* {sto.mount_path && ( */}
                            <KeyValueRow keyXs={6} rowKey={"Path"} rowValue={sto.mount_path ?? this.props.envStorage?.pvc?.path} variant="h5" />
                          {sto.storage_type && (
                            <KeyValueRow keyXs={6} rowKey={"Type"} rowValue={sto.storage_type ?? this.props.envStorage?.pvc?.type} variant="h5" />
                            )}

                          {sto.attached_to && (
                            <KeyValueRow keyXs={6} rowKey={t('Environment.StorageTab.attachedTo')} rowValue={sto.attached_to ?? this.props.envStorage?.pvc?.sttorageClass} variant="h5" />
                            )}

                          <KeyValueRow 
                            keyXs={6}
                            rowKey={"Capacity"} 
                            rowValue={ this.getStatus(sto.id)?.capacity ? this.getStatus(sto.id)?.capacity??"" : spaceConversion(sto?.capacity)} 
                            variant="h5"
                          />
                          <KeyValueRow
                            keyXs={6}
                            rowKey={"Access mode"}
                            rowValue={sto.access_modes||"none"}
                            variant="h5"
                            />
                      

                          {sto.used_storage && (
                            <Grid item md={12}>
                              <Divider />
                              <Typography
                                // class="m-t-10"
                                color="primary"
                                variant="body2"
                              >
                                {t('Environment.StorageTab.size')}
                                {/* {" "}
                              {parseInt(storage.used_storage)} GB/{" "}
                              {parseInt(storage.capacity)}GB */}
                              </Typography>
                              <Typography variant="h4">
                                {" "}
                                {spaceConversion(
                                  parseInt(sto.used_storage ?? 0)
                                )}{" "}
                                / {spaceConversion(sto.capacity)}{" "}
                              </Typography>

                              <LinearProgress
                                className="m-t-20"
                                value={
                                  (parseInt(sto.used_storage ?? 0) /
                                    sto.capacity) *
                                  100
                                }
                                variant="determinate"
                              />
                            </Grid>
                          )}
                        {/* </Grid> */}
                    </CardContent>
                  </Card>
                </Grid>
              ))
            ) : (
              <NoContentImage
                message={t('Environment.StorageTab.noStorageCreated')}
                alt="No Storage"
                data-test="noStorageGrid"
              />
            )}
          </Grid>
        </Grid>

        {this.state.isAddStoragePopupOpened && (
          <StoragePopup
            open={this.state.isAddStoragePopupOpened}
            handleAgree={this.addStorageAgree}
            handleDisagree={this.addStorageDisagree}
            environmentDetails={this.props.environmentDetails}
            data-test="addStorage-popup"
          />
        )}

        {this.state.isEditStoragePopUpOpen && this.state.currentStorage && (
          <StoragePopup
            open={this.state.isEditStoragePopUpOpen}
            handleAgree={this.editStorageAgree}
            handleDisagree={this.editStorageDisagree}
            edit={true}
            storage={this.state.currentStorage}
            environmentDetails={this.props.environmentDetails}
            data-test="editStorage-popup"
          />
        )}

        {this.state.isDeleteStoragePopUpOpen && this.state.currentStorage &&  (
          <ConfirmDeletePopup
            open={this.state.isDeleteStoragePopUpOpen}
            handleAgree={this.deleteStorageAgree}
            handleDisAgree={this.deleteStorageDisagree}
            // yesText={t('Projects.VariablesTab.yesText')}
            // noText={t('Projects.VariablesTab.noText')}
            toMatchName={this.state.currentStorage?.name}
            toDeleteModule="storage"
            loading={this.props.deletingStorage}
            message={t('Environment.StorageTab.deleteStorage')}
            action="delete"
            data-test="delStorage-popup"
          />
        )}
        {this.props.fetchingStorage && (
          <BackdropLoader message={t('Environment.StorageTab.fetchingStorage')} data-test="fetching-storage"/>
        )}
        {this.props.editingStorage && (
          <BackdropLoader message={t('Environment.StorageTab.editingStorage')} data-test="editing-storage"/>
        )}
        {this.props.deletingStorage && (
          <BackdropLoader message={t('Environment.StorageTab.deletingStorage')} data-test="deleting-storage"/>
        )}
        {this.props.creatingStorage && (
          <BackdropLoader message={t('Environment.StorageTab.creatingStorage')} data-test="creating-storage"/>
        )}
      </div>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state:any) => ({
  envStorage: state.EnvironmentReducer.envStorage,
  fetchingStorage: state.EnvironmentReducer.fetchingStorage,
  environmentDetails: state.EnvironmentReducer.environmentDetails,
  deletingStorage: state.EnvironmentReducer.deletingStorage,
  editingStorage: state.EnvironmentReducer.editingStorage,
  creatingStorage: state.EnvironmentReducer.creatingStorage,
  storages: state.EnvironmentReducer.storages,
  envRole: state.EnvironmentReducer.envRole
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch:Dispatch) => {
  return {
    getStorage: (id:number) => dispatch(getStorage(id)),
    createStorage: (id:number, payload:StoragePayload) => dispatch(createStorage(id, payload)),
    deleteStorage: (id:number, sId:number) => dispatch(deleteStorage(id, sId)),
    editStorage: (id:number, sId:number, payload:StoragePayload) => dispatch(editStorage(id, sId, payload)),
    clearStorageData: () => dispatch(clearStorageData()),
  };
};

const connector=connect(
  mapStateToProps,
  mapDispatchtoProps
)
export default connector(withTranslation()(Storage));
type PropsFromRedux = ConnectedProps<typeof connector>;