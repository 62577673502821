import React, { useState, useEffect } from "react";
import {
	Table,
	TableBody,
	// TableCell,
	TableContainer,
	TableHead,
	// TableRow,
	Paper,
	Typography,
	IconButton,
} from "@material-ui/core";
import CustomButton from "../../../../components/custombutton/CustomButton";
import EditIcon from "@material-ui/icons/Edit";
import { connect ,ConnectedProps} from "react-redux";
import { getDateInStandardFormat } from '../../../../helpers/utils'
import {useTranslation} from 'react-i18next';
import { getDns, clearDnsInfo } from "../../redux/actions";
import AddDNSPopup from "../../../../components/adddnspopup/AddDNSPopup";
import { StyledTableCell, StyledTableRow } from "../../../../components/styledtabelcell/StyledTabelCell";
import { Dispatch } from "redux";
import { DNSModel } from "../../../../models/Organization.model";
export const DNSTab = (props:PropsFromRedux) => {
  const [openPopup, setOpenPopup] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [dnsData, setDnsData] = useState<DNSModel|null>(null);
	const [t] = useTranslation()

	useEffect(() => {
		props.getDns();
		return(() => {
			props.clearDnsInfo()
		});
	}, []);

	const handleOpenPopup = () => {
    setOpenPopup(!openPopup)
  };

  const handleClosePopup = () => {
    if(openPopup) setOpenPopup(false);
		if(editMode) setEditMode(false);
		if(dnsData) setDnsData(null);
  }

	const handleEdit = (dns:DNSModel) => {
		setDnsData(dns);
		setEditMode(true);
	}
	return (
		<div data-test="main-container">
			<div className="listContainer">
				<Typography color="textPrimary" variant="h5">
				{t('Organization.AdminTab.dns')}
				</Typography>
				<CustomButton label={t('Organization.DnsTab.addDns')} onClick={() => handleOpenPopup()} data-test="add-btn" />
        {
			((editMode && dnsData  )|| openPopup) && 
			<AddDNSPopup 
				openPopup={openPopup || editMode} 
				handleClosePopup={handleClosePopup}
				editMode={editMode}
				editData={dnsData}
				data-test="add-dns-popup"
			/>
        }
			</div>
			<TableContainer component={Paper} data-test="table-container">
				<Table aria-label="simple table">
					<TableHead>
						<StyledTableRow>
							<StyledTableCell>{t('Organization.DnsTab.name')}</StyledTableCell>
							<StyledTableCell align="left">{t('Organization.DnsTab.provider')}</StyledTableCell>
							<StyledTableCell align="left">{t('Organization.DnsTab.created')}</StyledTableCell>
							<StyledTableCell align="center">{t('Organization.DnsTab.actions')}</StyledTableCell>
						</StyledTableRow>
					</TableHead>
					<TableBody>
						{props.dnsList.length > 0 &&
							props.dnsList.map((dns:DNSModel, ind:number) => {
								return (
									<StyledTableRow key={ind} data-test="dns-row">
										<StyledTableCell
											component="th"
											scope="row"
											key={ind}
											data-test="dns-name"
										>
											{dns.name}
										</StyledTableCell>
										<StyledTableCell align="left" data-test="dns-provider">
                      {dns.provider}
										</StyledTableCell>
										<StyledTableCell
											align="left"
											data-test="dns-created"
										>
											{getDateInStandardFormat(dns.createdat)}
										</StyledTableCell>
										<StyledTableCell
											align="center"
										>
											<IconButton
												aria-label="edit"
												onClick={() => handleEdit(dns)}
												data-test="edit-btn"
											>
												<EditIcon />
											</IconButton>
										</StyledTableCell>
									</StyledTableRow>
								);
							})}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
};

/* istanbul ignore next */
const mapStateToProps = (state:any) => ({
  dnsList: state.OrganizationReducer.dnsList,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch:Dispatch) => {
	return {
		getDns: () => dispatch(getDns()),
		clearDnsInfo: () => dispatch(clearDnsInfo()),
	};
};

const connector=connect(mapStateToProps, mapDispatchtoProps)
export default connector(DNSTab);
type PropsFromRedux=ConnectedProps<typeof connector >