import { Card, Grid, CardActions, CardContent, CardHeader, Chip, Divider, Tooltip, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { makeStyles } from "@material-ui/core/styles";
import { CatalogModel } from '../../models/HelmCharts.model'; 

type Props = {
    onClick: (catalog: CatalogModel) => void,
    catalog: CatalogModel,
    isClickable: boolean
}

/* istanbul ignore next */
const useStyles = makeStyles((theme) => ({
    root: {},
    imageContainer: {
        // height: 64,
        // width: 64,
        margin: '0 auto',
        border: `1px solid ${theme?.palette?.divider}`,
        borderRadius: '5px',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    catalogImage: {
        width: '100%',
    },
    cardActions: {
        margin: 5
    },
    cardContent: {
        minHeight: "87px"
    },
    linkPointer: {
        cursor: "pointer",
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.09)',
        },
    }
}));

export default function ChartCatalogCard(props: Props) {
    const classes = useStyles()
    const [t] = useTranslation()
    const { catalog } = props

    const handleClick = (_catalog: CatalogModel) => {
        props.onClick(_catalog)
    }
    return (
        <Card onClick={() => { handleClick(catalog) }} className={props.isClickable ? classes.linkPointer : ""} data-test="main-container">
            <CardHeader
                title={catalog?.name}
                data-test="card-header"
            />
            <Divider />

            <CardContent className={classes.cardContent} >
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <div className={classes.imageContainer}>
                            <img
                                src={catalog?.icon ? catalog.icon : "/images/icons/defaultcatalog.png"}
                                alt={catalog?.name ?? t('Organization.ChartCatalog.catalog')}
                                className={classes.catalogImage}
                                data-test="catalogImage"
                            />
                        </div>
                    </Grid>
                    <Grid item md={9}>
                        <Typography gutterBottom variant="body1" className={"threeLine"} data-test="catalog-description">
                            {catalog?.description}
                        </Typography>

                    </Grid>
                </Grid>
            </CardContent>
            <Divider />
            <CardActions className={classes.cardActions}>
                <Grid
                    container
                    justify="flex-end"
                    spacing={2}
                >
                    {/* <Grid item>
                        <Typography variant="body2" >
                            {catalog?.version}
                        </Typography>
                    </Grid> */}
                    <Grid item>
                        {catalog.category && (
                            <Grid container spacing={1} justify="flex-end">
                                {/* {catalog?.Category?.map((cat, ind) => (
                                <Grid item key={ind}> */}
                                <Tooltip title={catalog.category}>
                                    <Chip style={{ maxWidth: "100px", overflow: "hidden", textOverflow: "ellipsis" }}
                                        color={"primary"} variant="outlined" label={catalog.category} size="small"
                                        data-test="category-chip"
                                    />
                                </Tooltip>
                                {/* </Grid>
                            ))} */}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    )
}
