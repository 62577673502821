import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Alert from "@material-ui/lab/Alert";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { createVCluterStart, cleanUpVClusterState } from "../../redux/actions";
import { currentOrganization } from "../../../login/redux/selectors";
import * as H from "history";
const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  alert: {
    marginTop: 10,
    width: "50%",
  },
}));

export const locationOptions = [
  { label: "us-east-1" },
  { label: "us-west-2" },
  { label: "ap-south-1" },
  { label: "ap-south-2" },
  { label: "ap-southeast-1" },
  { label: "ap-southeast-2" },
];

export interface Subscriptions {
  _id: string;
  name: string;
  pods: number;
  service: number;
  config_map: number;
  persistance_vol_claims: number;
  replication_ctl: number;
  secrets: number;
  loadbalancer: number;
  node_port: number;
  created: string;
  updated: string;
}
interface I_VClusterStartArgs {
  cluster_name: string;
  region: string;
  subscription_id: string;
  vcluster_api_key: string;
}

interface I_props extends PropsFromRedux {
  token: string;
  history: H.History;
  subscriptions: Subscriptions[];
  hasRoleValdiationFailed:boolean;
}
export const CreateClusterSection = (props: I_props) => {
  const { subscriptions } = props;
  const [subscriptionPlan, setSubscriptionPlan] =
    useState<Subscriptions | null>(null);
  const [clusterRegion, setClusterRegion] = useState<{ label: string } | null>({
    label: "",
  });
  const [clusterName, setClusterName] = useState("");
  const [createError, setCreateError] = useState("");

  const classes = useStyles();

  const handleCreateCluster = async () => {
    if (!validateClusterName(clusterName)) return;
    if (
      !subscriptionPlan ||
      !clusterRegion ||
      !clusterRegion.label
    ) {
      setCreateError("Please fill in all required fields !");
      return;
    }
    setCreateError("");
    let sub = subscriptions.find(
      (sub: Subscriptions) => sub.name === subscriptionPlan.name
    );
    // Handle cluster creation or any other logic here.

    props.createVCluterStart({
      cluster_name: clusterName,
      region: clusterRegion.label,
      subscription_id: (sub && sub._id) || "0",
      vcluster_api_key: props.token
    });
  };

  const capitalize = (word: string) => {
    return word[0].toUpperCase() + word.slice(1);
  };

  const validateClusterName = (name: string) => {
    // Validation check for symbols and numeric values
    const symbolCheck = /[_&.@#*^%$!~]/;
    const numericStartCheck = /^[0-9]/;
    const hyphenStartCheck = /^-/;
   
    if (
      name &&
      !symbolCheck.test(name) &&
      !numericStartCheck.test(name) &&
      !hyphenStartCheck.test(name)
    ) {
      return true;
    }

    setCreateError("Cluster name is not valid !");
    return false;
  };

  useEffect(() => {
    if (props.vClusterCreated) {
      if (props.currentOrganization && props.currentOrganization.id) {
        props.history.push(
          `/organization/${props.currentOrganization.id}#admin_cluster`
        );
        return;
      }
      props.history.goBack();
    }
  }, [props.vClusterCreated, props.currentOrganization]);
  useEffect(() => {
    props.cleanUpVClusterState();
  }, []);
  return (
    <div className={classes.root}>
      <section>
        <Typography variant="h5" gutterBottom>
          Create New Cluster
        </Typography>
        <div>
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12} md={8}>
              <TextField
                id="cluster-name"
                label="Cluster Name"
                variant="outlined"
                autoFocus={true}
                fullWidth={true}
                value={clusterName}
                disabled={props.hasRoleValdiationFailed}
                onChange={(e) => setClusterName(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Autocomplete
                disablePortal
                disabled={props.hasRoleValdiationFailed}
                id="subscription-box-plan"
                options={subscriptions || []}
                getOptionLabel={(option) => capitalize(option.name)}
                renderInput={(params) => (
                  <TextField variant="outlined" {...params} label="Plan" />
                )}
                value={subscriptionPlan}
                onChange={(_event, newValue) =>
                  setSubscriptionPlan(newValue as Subscriptions)
                }
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Autocomplete
                disablePortal
                disabled={props.hasRoleValdiationFailed}
                id="combo-box-region"
                options={locationOptions || []}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField variant="outlined" {...params} label="Location" />
                )}
                value={clusterRegion}
                onChange={(_event, newValue) => setClusterRegion(newValue)}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                variant="contained"
                style={{ height: "100%" }}
                onClick={handleCreateCluster}
                fullWidth={true}
                disabled={props.creatingVcluster||props.hasRoleValdiationFailed}
                color="primary"
                disableElevation={true}
              >
                {props.creatingVcluster ? (
                  <CircularProgress size={25} />
                ) : (
                  "Create Cluster "
                )}
              </Button>
            </Grid>
          </Grid>
        </div>
        {createError ? (
          <div className={classes.alert}>
            <Alert
              color="error"
              onClose={() => {
                setCreateError("");
              }}
              
            >
              <Typography align="center" variant="subtitle1">{createError}</Typography>
            </Alert>
          </div>
        ) : null}
      </section>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  creatingVcluster: state.ClusterReducer.creatingVcluster,
  vClusterCreated: state.ClusterReducer.vClusterCreated,
  createVclusterError: state.ClusterReducer.createVclusterError,
  currentOrganization: currentOrganization(state),
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    createVCluterStart: (payload: I_VClusterStartArgs) =>
      dispatch(createVCluterStart(payload)),
    cleanUpVClusterState: () => dispatch(cleanUpVClusterState()),
  };
};

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(CreateClusterSection);
