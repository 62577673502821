const initialState = {
    projectDetails : {},
    appList : [],
    projectActivities: [],
    projectInsights: [],
    breadcrumbData : [],
    isDataNotFound : false,
    currentProject: 0,
    projectRole: {},
    activityLoading: false,
    editingProject: false,
    deletingProject: false,
    sidebarProjectDetails: null,
    fetchingSidebarProject: false,
    fetchingProjectDetails: false,
    fetchingProjectAppList: false,
    fetchingUsedResources: false,
    projectUsedResources: null,
    fetchingLoadbalancers: false,
    fetchingLoadbalancer: false,
    creatingLoadbalancer: false, 
    deletingLoadbalancer: false,
    loadbalancersList: null,
    loadbalancerInfo: null,
    fetchingLBStatus: false,
    loadbalancerStatus: null,
    toggleProjectActivatitonCallStarted : false,
    toggleProjectActivatitonCallSuccess : false,
    toggleProjectActivatitonCallFailure : false,
}

export default initialState
