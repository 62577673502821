import React from "react";
import { Tooltip, Typography } from "@material-ui/core";
import Skeleton from "react-loading-skeleton";
import "./LabelHandler.css";

type Props = {
  label: string | number | JSX.Element
  icon: JSX.Element
  iconTooltip: string
  labelTooltip?: string
}

export const LabelHandler = ({ label, icon, iconTooltip, labelTooltip }: Props) => {
  const iconComponent = React.cloneElement(icon, {
    className: "svgicon",
  });

  const Label = () => (
    <span className="topinfoGrid">
      <Typography
        color="textPrimary"
        variant="body2"
        className="label"
      >
        {label !== "" && label ? label : <Skeleton width={80} />}
      </Typography>
    </span>
  );

  return (
    <div
      data-test="main-container"
      className="label-handler-container"
    >
      {iconComponent && (
        <Tooltip title={iconTooltip} arrow data-test="icon-tooltip">
          {iconComponent}
        </Tooltip>
      )}
      {labelTooltip ? (
        <Tooltip title={labelTooltip} arrow data-test="label-tooltip">
          <Label data-test="label-comp" />
        </Tooltip>
      ) : (
        <Label data-test="label-comp"/>
      )}
    </div>
  );
};