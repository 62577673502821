import React, { Component } from 'react';
import { connect } from 'react-redux';
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import { sessionTokenSelector } from '../pages/login/redux/selectors';
import { updateRecentNotifications, updateUnseenCount } from '../pages/notification/redux/actions'

export class GlobalWS extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ws: null,
        }
    }

    componentDidMount() {
        this.socketConnection();
    }

    componentWillUnmount(){
        if(this.state.ws !== null){
          this.state.ws.close(1000);
        }
    }

    /* istanbul ignore next */
    socketConnection = () => {
        if(this.state.ws !== null) return;
        let $this = this;
        const sessionToken = this.props.validSessionId;
        const userId = this.props.userDetails?.id;
        if (userId) {
            var ws = new W3CWebSocket(window?.config?.REACT_APP_SOCKET_IO_ENDPOINT + '?token='+ sessionToken + '&room=user-' + userId);
            this.setState({
                ws
            })
            ws.onopen = () => {
                console.log('Global WebSocket Client Connected');
                this.setState({ ws: ws });
            };
            ws.onclose = e => {
                console.log('Global WebSocket connection closed');
                this.setState({ ws: null });
                if(e.code !== 1000)
                    this.socketConnection();
            };
            ws.onerror = () => {
                console.log('Global WebSocket error');
                ws.close();
                this.setState({ ws: null })
            };
            ws.addEventListener('message', (response) => {
                if(response.type === 'message'){
                    const { data } = response;
                    if(data)
                    {
                        const _data = JSON.parse(data);
                        if(_data.type === 'notifications')
                        {
                            $this.updateNotification(_data);
                        }
                    }
                }
            });
        }
    }

    /* istanbul ignore next */
    updateNotification = (_data) => {
        const recent = this.props.recentNotificationList ?? []
        let _recentNotificationList = [_data.message, ...recent];
        this.props.updateRecentNotifications(_recentNotificationList);
        let _unseen = this.props.unseenCount;
        _unseen = parseInt(_unseen) + 1;
        this.props.updateUnseenCount({ count: _unseen, show_bubble: true });
    }

    render() {
        return (
            <div data-test="main"></div>
        )
    }
}

 /* istanbul ignore next */
const mapStateToProps = state => ({
    validSessionId: sessionTokenSelector(state),
    userDetails: state.AuthReducer.user,
    recentNotificationList: state.NotificationReducer.recentNotificationList,
    unseenCount: state.NotificationReducer.unseenCount
})

 /* istanbul ignore next */
const mapDispatchtoProps = dispatch => {
    return {
        updateRecentNotifications: (notys) => dispatch(updateRecentNotifications(notys)),
        updateUnseenCount: (jsonBody) => dispatch(updateUnseenCount(jsonBody))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchtoProps
)(GlobalWS)