import {
  ButtonBase,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Badge,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect } from "react-redux";
import CustomButton from "../../components/custombutton/CustomButton";
import BackdropLoader from "../../components/loader/BackdropLoader";
import paths from "../../constants/paths";
import { getDateInStandardFormat, getDiffDays } from "../../helpers/utils";
import { updateBreadcrumb } from "../project/redux/actions";
import { deleteTicket, getSupportTypes, getUserTickets } from "./redux/actions";
import { StyledTableCell, StyledTableRow } from "../../components/styledtabelcell/StyledTabelCell";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FilterListRoundedIcon from "@material-ui/icons/FilterListRounded";
import { useTranslation } from 'react-i18next';
import NoContentImage from "../../components/nocontentimagecontainer/NoContentImage";
import SearchField from "../../components/searchfield/SearchField";

/* istanbul ignore next */
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  dropdown: {
    minWidth: 80,
    height: 50,
  },
  accordionSummaryRoot: {
    maxWidth: "5%",
    padding: 0,
    height: "40px",
    marginLeft: "auto",
    "&.Mui-expanded": {
      minHeight: "40px",
    },
  },
  accordionSummaryContent: {
    margin: 0,
    padding: 0,
    position: "absolute",
    right: "0",
  },
  accordionRoot: {
    background: "transparent",
    boxShadow: "none",
  },
  accordionRootDisabled: {
    backgroundColor: "transparent !important",
  },
  accordionDetailsRoot: {
    padding: 0,
  },
  filterGrid: {
    marginTop: "5px",
  },
});

const getPriority = (p) => {
  let arrow;
  switch (p) {
    case "High":
      arrow = <ArrowUpwardIcon style={{ color: "red" }} />;
      break;
    case "Medium":
      arrow = <ArrowUpwardIcon color="primary" />;
      break;
    case "Low":
      arrow = <ArrowDownwardIcon style={{ color: "green" }} />;
      break;
    default:
      arrow = <ArrowUpwardIcon color="primary" />;
      break;
  }
  return (
    <>
      {" "}
      <span style={{ marginTop: 5 }}>{arrow} </span> {p}
    </>
  );
};

export function Support(props) {
  const [t] = useTranslation()
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(1);
  // const [currentTicket, setCurrentTicket] = useState(null);
  // const [ticketDeletePopupOpen, setTicketDeletePopupOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [currentStatus, setCurrentStatus] = useState("all"); // 1: Open, 2: Closed
  const [currentPriority, setCurrentPriority] = useState("all");
  const [currentSupportType, setCurrentSupportType] = useState("all");
  // const [anchorEl, setAnchorEl] = useState({});
  const [reset, setReset] = useState(false);
  const [clearSearch, setClearSearch] = useState(false);

  const matches = useMediaQuery((theme) => theme?.breakpoints?.down("xs") ?? "600");

  const limit = 20;

  const fetchSupportTickets = (pageNumber) => {
    props.getUserTickets(
      pageNumber ?? 1,
      limit,
      currentStatus === "all" ? "" : currentStatus,
      searchText,
      currentPriority === "all" ? "" : currentPriority,
      currentSupportType === "all" ? "" : currentSupportType
    );
  };

  useEffect(() => {
    // fetchSupportTickets(1);
    const breadcrumbData = [
      {
        name: "Tickets",
        path: paths.SUPPORT,
      },
    ];
    props.updateBreadcrumb(breadcrumbData);
    props.getSupportTypes();
  }, []);

  useEffect(() => {
    fetchSupportTickets(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if(reset){
      setReset(false)
    }
  }, [props.userTickets]);

  // const handleMenuClose = () => {
  //   setAnchorEl({});
  // };

  const handleTicketClick = (ticket) => {
    // handleMenuClose();
    props.history.push({
      pathname: paths.TICKET_DETAIL.replace(":id", ticket.id),
      state: { id: ticket.id, ticket: ticket },
    });
  };

  // const handleTicketMenu = (e, ticket) => {
  //   setAnchorEl({ [ticket.id]: e.currentTarget });
  //   setCurrentTicket(ticket);
  // };

  const createTicket = () => {
    // setMode(1);
    props.history.push({
      pathname: paths.TICKET_CREATE,
    });
  };

  // const handleTicketDelete = () => {
  //   handleMenuClose();
  //   setTicketDeletePopupOpen(true);
  // };

  // const deleteCallback = () => {
  //   if (currentPage !== 1) {
  //     setCurrentPage(1);
  //   } else {
  //     fetchSupportTickets(currentPage);
  //   }
  // };

  // const deleteTicketAgreeHandler = () => {
  //   setTicketDeletePopupOpen(false);
  //   props.deleteTicket(currentTicket.id, deleteCallback);
  // };

  // const deleteTicketDisagreeHandler = () => {
  //   setTicketDeletePopupOpen(false);
  // };

  const getStatus = (status) => {
    switch (status) {
      case "Open":
        return "activestatus";
      case "Closed":
        return "inactivestatus";
      default:
        return "";
    }
  };

  const loadMore = () => {
    let newPageNo = currentPage + 1;
    setCurrentPage(newPageNo);
  };

  const handleSearch = (st) => {
     props.getUserTickets(
      1,
      limit,
      currentStatus === "all" ? "" : currentStatus,
      st,
      currentPriority === "all" ? "" : currentPriority,
      currentSupportType === "all" ? "" : currentSupportType
    );
  }

  const handleSearchChange = (st) => {
    setSearchText(st);
    if(st.trim()?.length === 0){
      setClearSearch(true)
    }else{
      setClearSearch(false)
    }
  };

  const handleStatusChange = (e) => {
    setCurrentStatus(e.target.value);
    props.getUserTickets(
      1,
      limit,
      e.target.value === "all" ? "" : e.target.value,
      searchText,
      currentPriority === "all" ? "" : currentPriority,
      currentSupportType === "all" ? 0 : currentSupportType
    );
  };

  const handlePriorityChange = (e) => {
    setCurrentPriority(e.target.value);
    props.getUserTickets(
      1,
      limit,
      currentStatus === "all" ? "" : currentStatus,
      searchText,
      e.target.value === "all" ? "" : e.target.value,
      currentSupportType === "all" ? 0 : currentSupportType
    );
  };

  const handleSupportTypeChange = (e) => {
    setCurrentSupportType(e.target.value);
    props.getUserTickets(
      1,
      limit,
      currentStatus === "all" ? "" : currentStatus,
      searchText,
      currentPriority === "all" ? "" : currentPriority,
      e.target.value === "all" ? 0 : e.target.value
    );
  };

  useEffect(() => {
    if(reset){
      setSearchText("")
    }
  }, [reset])

  const resetFilters = () => {
    setCurrentSupportType("all");
    setCurrentPriority("all");
    setCurrentStatus("all");
    setCurrentPage(1);
    setReset(true)
    // setSearchText("");
    props.getUserTickets(1, limit, "", "", "", ""); // setReset(true)
  };

  const isResetValid = () => {
    if (
      currentStatus === "all" &&
      currentPriority === "all" &&
      currentSupportType === "all" &&
      searchText.trim().length === 0
    ) {
      return false;
    }
    return true;
  };

  const filters = (
    <Grid
      container
      spacing={3}
      justify="flex-end"
      className={classes.filterGrid}
    >
      <Grid item xs={12} sm={4} md={3}>
        <SearchField
          label={t('Support.SupportPage.searchTicket')}
          search={searchText}
          handleSearch = {handleSearch}
          handleSearchChange = {handleSearchChange}
          data-test="search-box"
        />
      </Grid>
      <Grid item xs={12} sm={3} md={2}>
        <FormControl
          variant="outlined"
          className={`w-100 ${classes.formControl}`}
        >
          <InputLabel>{t('Support.SupportPage.status')}</InputLabel>

          <Select
            value={currentStatus}
            onChange={(e) => handleStatusChange(e)}
            label={t('Support.SupportPage.status')}
            className={classes.dropdown}
            data-test="status-field"
          >
            <MenuItem value="all">
              <em>{t('Support.SupportPage.all')}</em>
            </MenuItem>
            <MenuItem value={"Open"}>{t('Support.SupportPage.open')}</MenuItem>
            <MenuItem value={"Closed"}>{t('Support.SupportPage.closed')}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={3} md={2}>
        <FormControl
          variant="outlined"
          className={`w-100 ${classes.formControl}`}
        >
          <InputLabel>{t('Support.SupportPage.priority')}</InputLabel>

          <Select
            value={currentPriority}
            onChange={(e) => handlePriorityChange(e)}
            label={t('Support.SupportPage.priority')}
            className={classes.dropdown}
            data-test="priority-field"
          >
            <MenuItem value="all">
              <em>{t('Support.SupportPage.all')}</em>
            </MenuItem>
            <MenuItem value={"Low"}>{t('Support.SupportPage.low')}</MenuItem>
            <MenuItem value={"Medium"}>{t('Support.SupportPage.medium')}</MenuItem>
            <MenuItem value={"High"}>{t('Support.SupportPage.high')}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={3} md={2}>
        <FormControl
          variant="outlined"
          className={`w-100 ${classes.formControl}`}
        >
          <InputLabel>{t('Support.SupportPage.category')}</InputLabel>

          <Select
            value={currentSupportType}
            onChange={(e) => handleSupportTypeChange(e)}
            className={classes.dropdown}
            label={t('Support.SupportPage.category')}
            data-test="supportType-field"
          >
            <MenuItem value="all">
              <em>{t('Support.SupportPage.all')}</em>
            </MenuItem>
            {props.supportTypes?.reverse()?.map((support) => (
              <MenuItem key={support.id} value={support.id}>
                {support.title}{" "}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {isResetValid() && (
        <Grid item xs={12} sm={2} md={1}>
          <Grid container justify="flex-end">
            <IconButton onClick={() => resetFilters()} data-test="reset-button">
              <Typography color="disabled" variant="h5">
              {t('Support.SupportPage.reset')}{" "}
              </Typography>
            </IconButton>
          </Grid>
        </Grid>
      )}
    </Grid>
  );

  return (
    <div data-test="tickets-container">
      <div data-test="ticket-list-container">
        <div className="listContainer">
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Typography color="textPrimary" variant="h4 ">
              {t('Support.SupportPage.tickets')}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} justify="flex-end">
            <Grid item>
              <CustomButton
                onClick={() => createTicket()}
                label={t('Support.SupportPage.createTicket')}
                data-test="create-ticket-button"
              />
            </Grid>
          </Grid>
        </div>
        <Card>
          <CardHeader
            title={
              !(props.userTickets?.length === 0 && !isResetValid()) &&
              (matches ? (
                <Accordion
                  classes={{
                    root: classes.accordionRoot,
                    disabled: classes.accordionRootDisabled,
                  }}
                  // disabled={}
                >
                  <AccordionSummary
                    classes={{
                      root: classes.accordionSummaryRoot,
                      content: classes.accordionSummaryContent,
                    }}
                  >
                    <Badge
                      color="primary"
                      variant="dot"
                      invisible={!isResetValid()}
                    >
                      <Button
                        size="large"
                        variant="outlined"
                        startIcon={<FilterListRoundedIcon />}
                      >
                        {t('Support.SupportPage.filter')}
                      </Button>
                    </Badge>
                  </AccordionSummary>
                  <AccordionDetails
                    classes={{ root: classes.accordionDetailsRoot }}
                  >
                    {filters}
                  </AccordionDetails>
                </Accordion>
              ) : (
                <>{filters}</>
              ))
            }
            data-test="support-card-header"
          />
          <CardContent>
            {props.userTickets?.length === 0 && !isResetValid() && !reset && !clearSearch ? (
              <NoContentImage 
                message={t('Support.SupportPage.createOne')} 
                type="support" 
                alt="No support tickets"
                data-test="no-support-tickets" 
              />
            ) : (
              <InfiniteScroll
                dataLength={props.userTickets?.length ?? 0}
                next={loadMore}
                hasMore={props.userTickets?.length === currentPage * limit}
                // scrollThreshold={1}
              >
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table" data-test="table-data">
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell>{t('Support.SupportPage.id')}</StyledTableCell>
                        <StyledTableCell>{t('Support.SupportPage.title')}</StyledTableCell>
                        <StyledTableCell align="left">{t('Support.SupportPage.created')}</StyledTableCell>
                        <StyledTableCell align="left">{t('Support.SupportPage.category')}</StyledTableCell>
                        <StyledTableCell align="left">{t('Support.SupportPage.priority')}</StyledTableCell>
                        <StyledTableCell>{t('Support.SupportPage.status')}</StyledTableCell>
                        {/* <StyledTableCell align="right">
                          Actions
                        </StyledTableCell> */}
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {props.userTickets?.length > 0 &&
                        props.userTickets?.map((ticket, ind) => (
                          <StyledTableRow key={ind}>
                            <StyledTableCell>
                              <ButtonBase>
                                <Typography
                                  variant="h5"
                                  color="primary"
                                  title={ticket.title}
                                  style={{ maxWidth: 100 }}
                                  onClick={() => handleTicketClick(ticket)}
                                  data-test="ticket-item-id"
                                >
                                  #{ticket.id}
                                </Typography>
                              </ButtonBase>
                            </StyledTableCell>
                            <StyledTableCell>
                              <ButtonBase>
                                <Typography
                                  // variant="h5"
                                  // color="primary"
                                  title={ticket.title}
                                  className="oneLine"
                                  style={{ maxWidth: 100 }}
                                  onClick={() => handleTicketClick(ticket)}
                                  data-test="ticket-item-title"
                                >
                                  {ticket.title}
                                </Typography>
                              </ButtonBase>
                            </StyledTableCell>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              title={getDiffDays(ticket.date * 1000, true)}
                              align="left"
                            >
                              {getDateInStandardFormat(ticket.date * 1000)}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {ticket.category}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {getPriority(ticket.priority)}
                            </StyledTableCell>
                            <StyledTableCell>
                              <span className={getStatus(ticket.status)}></span>{" "}
                              {ticket.status}
                            </StyledTableCell>
                            {/* <StyledTableCell align="right">
                              <IconButton
                                onClick={(e) => handleTicketMenu(e, ticket)}
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                anchorEl={anchorEl[ticket.id]}
                                open={Boolean(anchorEl[ticket.id])}
                                onClose={() => handleMenuClose()}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "center",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "center",
                                }}
                              >
                                <MenuItem onClick={() => handleTicketClick()}>
                                  View
                                </MenuItem>  
                              </Menu>
                            </StyledTableCell> */}
                          </StyledTableRow>
                        ))}
                    </TableBody>
                  </Table>
                  {!props.userTickets ||
                    (props.userTickets?.length === 0 && isResetValid() && (
                      <div className="alignCenter" data-test="no-tickets">
                        <p>{t('Support.SupportPage.noTickets')}</p>
                      </div>
                    ))}
                </TableContainer>
              </InfiniteScroll>
            )}
          </CardContent>
        </Card>
      </div>
      {/* {ticketDeletePopupOpen && (
        <ConfirmDeletePopup
          open={ticketDeletePopupOpen}
          handleAgree={deleteTicketAgreeHandler}
          handleDisAgree={deleteTicketDisagreeHandler}
          yesText="Yes"
          noText="No"
          toMatchName={"Delete"}
          toDeleteModule="support ticket"
          message={`Are you sure you want to delete the ticket?`}
          action="delete"
        />
      )} */}
      {props.fetchingTickets && <BackdropLoader message={t('Support.SupportPage.fetchingTickets')} data-test="fetching-tickets-loader" />}
      {props.deletingTicket && <BackdropLoader message={t('Support.SupportPage.deletingTickets')} data-test="deleting-ticket-loader" />}
    </div>
  );
}

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  userTickets: state.SupportReducer.userTickets,
  fetchingTickets: state.SupportReducer.fetchingTickets,
  deletingTicket: state.SupportReducer.deletingTicket,
  supportTypes: state.SupportReducer.supportTypes,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch) => {
  return {
    getUserTickets: (
      pageNumber,
      limit,
      status,
      search,
      priority,
      supportType
    ) =>
      dispatch(
        getUserTickets(pageNumber, limit, status, search, priority, supportType)
      ),
    deleteTicket: (id, callback) => dispatch(deleteTicket(id, callback)),
    updateBreadcrumb: (payload) => dispatch(updateBreadcrumb(payload)),
    getSupportTypes: () => dispatch(getSupportTypes()),
  };
};

export default connect(mapStateToProps, mapDispatchtoProps)(Support);
