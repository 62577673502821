import {Collapse,Badge, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Tooltip, Typography, createStyles, Card, CardContent, Divider } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { withStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
// import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Skeleton } from "@material-ui/lab";
import { connect, ConnectedProps } from 'react-redux';
import BackdropLoader from '../../../../components/loader/BackdropLoader';
import ProjectCardDetails from '../../../../components/projectcarddetails/ProjectCardDetails';
import {
    isAuthorized
} from "../../../../helpers/utils";
import { fetchAppListByPID, clearAppList } from '../../redux/actions';
import {fetchPlugins} from "../../../plugin/redux/actions"
import {fetchRegionsList} from "../../../app/createapp/redux/actions"
import './DashboardTab.css';
import NoContentImage from '../../../../components/nocontentimagecontainer/NoContentImage';
import SearchField from '../../../../components/searchfield/SearchField';
import paths from '../../../../constants/paths';
import { Dispatch } from "redux";
import { AppDetailsModel } from '../../../../models/Application.model';
import { History } from 'history';
import { WithStyles} from "@material-ui/core";
import { PluginModel } from '../../../../models/Environment.model';

/* ignore istanbul next */
const useStyles = () => createStyles({
    table: {
        minWidth: 650,
      },
      dropdown: {
        minWidth: 80,
        height: 50,
      },
      accordionSummaryRoot: {
        maxWidth: "5%",
        padding: 0,
        height: "40px",
        marginLeft: "auto",
        "&.Mui-expanded": {
          minHeight: "40px",
        },
      },
      accordionSummaryContent: {
        margin: 0,
        padding: 0,
        position: "absolute",
        right: "0",
      },
      accordionRoot: {
        background: "transparent",
        boxShadow: "none",
      },
      accordionRootDisabled: {
        backgroundColor: "transparent !important",
      },
      accordionDetailsRoot: {
        padding: 0,
      },
      filterGrid: {
        marginTop: "5px",
      },
});

interface Props extends WithTranslation, PropsFromRedux, WithStyles<typeof useStyles>{
    projectId: number
    history: History,
  }

  type State = {
    selectedPlugin: string,
    searchText : string,
    selectedCluster: string,
    expanded: boolean,
    timer: any,
    filterExpanded: boolean
  }

export class Dashboard extends Component<Props, State> {
    constructor(props: Props){
        super(props);
        this.state = {
            selectedPlugin: "all",
            searchText : "",
            selectedCluster: "all",
            expanded: false,
            timer: null,
            filterExpanded: false
        }
    }

    componentDidMount = () => {
        if(this.props.projectId){
            this.handleFilter()
        }
        this.props.fetchPlugins()
        this.props.fetchRegionsList()
    }

    handleExpandClick = () => {
        this.setState({
            filterExpanded: !this.state.filterExpanded
        })
      };
     
    handleFilter = () => {
        this.props.fetchAppListByPID(this.props.projectId, this.state.selectedPlugin, this.state.selectedCluster, this.state.searchText)
    }

    resetFilters = () => {
        clearTimeout(this.state.timer)
        this.setState({
            selectedPlugin: "all",
            selectedCluster: "all",
            searchText: "",
        }, () => {
            this.handleFilter()
        })
    }

    componentWillUnmount = () => {
        this.resetFilters()
        this.props.clearAppList();
    }
    
    onRefresh = () => {
        if(this.props.projectId){
            this.props.fetchAppListByPID(this.props.projectId)
        }     
    }

    navigateToApp = () => {
        this.props.history.push({
            pathname : paths.CREATEAPP.replace(":pId", this.props.projectId.toString())
        });
    }

    isAppQuotaFull = () => {
        const projDetail = this.props.projectDetails
        return(projDetail.apps_count >= projDetail.subscription?.apps)
    }

    isResetValid = () => {
        if(this.state.searchText?.trim()?.length || this.state.selectedCluster !== "all" || this.state.selectedPlugin !== "all"){
            return true
        }
        return false
    }

    pluginChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({
            selectedPlugin: e.target?.value as string
        }, () => {
            this.handleFilter()
        })
    }

    clusterChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({
            selectedCluster: e.target?.value as string
        }, () => {
            this.handleFilter()
        })
    }

    searchTextChange = (st: string) => {
        this.setState({
            searchText: st,
        })
    }

    render () {
        const {projectRole, classes, t, appList} = this.props
        const filters = (
            <Grid
              container
              spacing={3}
              justify="flex-end"
              className={classes.filterGrid}
            >
              {this.isResetValid() && (
                <Grid item xs={12} sm={2} md={1}>
                    <Grid container>
                        <IconButton onClick={() => this.resetFilters()}>
                        <Typography 
                        // color="disabled" 
                        variant="h5">
                        {t('Projects.DashBoardTab.reset')}{" "}
                        </Typography>
                        </IconButton>
                    </Grid>
                </Grid>
              )}
              <Grid item xs={12} sm={4} md={3}>
                <SearchField
                    label={t('Projects.DashBoardTab.searchText')}
                    search={this.state.searchText}
                    handleSearchChange={this.searchTextChange} 
                    handleSearch={this.handleFilter}
                    data-test="search-box"
                />

                {/* <MuiTextField
                  className="w-100"
                  id="searchText"
                  label={t('Projects.DashBoardTab.searchText')}
                  name="searchText"
                  style={{ width: "100%" }}
                  color="primary"
                  onChange={(e) => this.searchTextChange(e)}
                  value={this.state.searchText}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" edge="end">
                          <SearchIcon size="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  data-test="search-box"
                /> */}
              </Grid>
              {this.props.pluginList && (
                <Grid item xs={12} sm={3} md={2}>
                    <FormControl
                    variant="outlined"
                    className="w-100"
                    >
                    <InputLabel>{t('Projects.DashBoardTab.pluginLabel')}</InputLabel>
            
                    <Select
                        value={this.state.selectedPlugin}
                        onChange={(e: React.ChangeEvent<{ value: unknown }>) => this.pluginChange(e)}
                        label={t('Projects.DashBoardTab.pluginLabel')}
                        className={classes.dropdown}
                        data-test="plugin-select"
                    >
                        <MenuItem value="all">
                        <em>{t('Projects.DashBoardTab.all')}</em>
                        </MenuItem>
                        {this.props.pluginList?.map((plu: PluginModel) => (
                            <MenuItem key={plu.id} value={plu.id}>
                                <em>{plu.name}</em>
                            </MenuItem>
                        ))}
                    </Select>
                    </FormControl>
                </Grid>
              )}
              { this.props.regionsList && (
                <Grid item xs={12} sm={3} md={2}>
                    <FormControl
                    variant="outlined"
                    className="w-100"
                    >
                    <InputLabel>{t('Projects.DashBoardTab.clusterLabel')}</InputLabel>
            
                    <Select
                        value={this.state.selectedCluster}
                        onChange={(e: React.ChangeEvent<{ value: unknown }>) => this.clusterChange(e)}
                        label={t('Projects.DashBoardTab.clusterLabel')}
                        className={classes.dropdown}
                        data-test="cluster-select"
                    >
                        <MenuItem value="all">
                        <em>{t('Projects.DashBoardTab.all')}</em>
                        </MenuItem>
                        {this.props.regionsList?.map((clu: string) => (
                            <MenuItem key={clu} value={clu}>
                                <em>{clu}</em>
                            </MenuItem>
                        ))}
                        {/* <MenuItem value={"Low"}>{t('Support.SupportPage.low')}</MenuItem>
                        <MenuItem value={"Medium"}>{t('Support.SupportPage.medium')}</MenuItem>
                    <MenuItem value={"High"}>{t('Support.SupportPage.high')}</MenuItem> */}
                    </Select>
                    </FormControl>
                </Grid>
            )}
            </Grid>
          );
        return (
            <div data-test="appsContainer">
                <div >
                    <div >
                        {isAuthorized('create', projectRole.name) && (
                            <span className='rightbtn' >
                                <Button color='primary' disabled={this.isAppQuotaFull()} variant="contained" onClick={this.navigateToApp} data-test="createAppAction">{t("Projects.DashBoardTab.createAppLabel")}</Button>
                            </span>
                        )}
                        <span className='rightbtn'>
                            <Tooltip title={t('Projects.DashBoardTab.refreshAppList')}>
                                <IconButton onClick={() => {this.onRefresh()}} className="refreshIcon" data-test="refreshButton">
                                    <RefreshIcon color="primary" 
                                    // fontSize={"15"} 
                                    />
                                </IconButton>
                            </Tooltip>
                        </span>
                        {appList && appList.length > 0 &&
                        <span style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                            <Tooltip title={t('Projects.DashBoardTab.showFilters')}>
                                    <IconButton  
                                        onClick={this.handleExpandClick}
                                        aria-expanded={this.state.filterExpanded} 
                                        className="refreshIcon"
                                        data-test="expand-icon"
                                    >
                                        <Badge
                                            color="primary"
                                            variant="dot"
                                            invisible={!this.state.filterExpanded}
                                        >
                                            <FilterListIcon color="primary" 
                                            // fontSize={"15"} 
                                            /> 
                                        </Badge>
                                    </IconButton>
                            </Tooltip>
                        </span>
                       }
                    </div>
                    <Collapse in={this.state.filterExpanded} timeout="auto" unmountOnExit className="m-b-20">
                            {filters}
                    </Collapse>
                                        
                    {/* {filters} */}
                    
                    <Grid container spacing={ 3 } data-test="appsListContainer">
                        {
                            this.props.isLoading && [0,1,2].map(val => {
                                return (
                                    <Grid key={val} item sm={ 6 } xs={ 12 } md={ 4 } data-test="appSkeleton">
                                        <Card >
                                            <CardContent>
                                                    <Grid container spacing={2} >
                                                        <Grid item xs={2}>
                                                            <Skeleton variant='circle' height={50} width={50} className="projectImg" />
                                                        </Grid>
                                                        <Grid item xs={10}>
                                                            <div className='projectTitlesContainer'>
                                                                <span>
                                                                    <Skeleton width='40%'/>
                                                                </span>
                                                            <span>
                                                                <Skeleton width='60%' />
                                                            </span>
                                                            </div>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={2}>
                                                        <Grid item md={4} className="topgridalign">
                                                            <Skeleton width='60%' />
                                                        </Grid>
                                                        <Grid item md={4} className="topgridalign">
                                                            <Skeleton width='60%' />
                                                        </Grid>
                                                        <Grid item md={4} className="topgridalign">
                                                            <Skeleton width='60%' />
                                                        </Grid>
                                                    </Grid>
                                                <Grid container spacing={2} >
                                                    <Grid item md={12}>
                                                        <Divider className="carddivider" />
                                                        <div className="m-b-10">
                                                            <Skeleton width='20%' />
                                                        </div>
                                                        <div className="envavatardiv">
                                                            {
                                                                [0, 1].map(() =>
                                                                    <span>
                                                                        <Skeleton width={35} height={60} />
                                                                    </span>
                                                                )
                                                            }
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                )
                            })
                        }
                        {
                            !this.props.isLoading && this.props.appList && this.props.appList.length === 0 &&      
                                <NoContentImage
                                    message={ this.isResetValid() ?  t("Projects.DashBoardTab.noFilteredAppsLabel") : t("Projects.DashBoardTab.noAppsLabel")}
                                    alt="No apps"
                                    data-test="appListNoImage"
                                    type="app"
                                />                                                             
                        }
                        {
                            !this.props.isLoading && this.props.appList && this.props.appList.length > 0 && this.props.appList.map((appData: AppDetailsModel, idx: number) => {
                              
                                return (
                                    appData && 
                                    <Grid key={idx} item sm={ 6 } xs={ 12 } md={ 4 }>
                                        <ProjectCardDetails appData={ appData } history={ this.props.history } projectRole={this.props.projectRole} />
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </div> 
                <div>
                    {/* <Typography color="textPrimary" variant="h5" className="usersTitle">
                        Users
                    </Typography> */}

                    {/* <Grid container spacing={3}>
                        {
                            typeof this.props.userData === 'undefined' && [0,1,2,3].map(val => {
                                return (
                                    <Grid item xs key={ val }>
                                        <Paper variant='outlined' style={{padding : '1rem'}}>
                                        <SkeletonTheme height={250}>
                                            <div className='displayFlexjustifySpacebetween'>
                                                <div>
                                                    <Skeleton circle={true} height={50} width={50} />
                                                </div>
                                                <div  className='nameSkeleton'>
                                                    <Skeleton  width={180} count={2} />
                                                </div>
                                            </div>
                                        </SkeletonTheme>
                                        </Paper>
                                    </Grid>
                                )
                            })
                        }
                        {   
                            this.props.userData &&  (
                                <Grid item sm={ 12 } xs={ 12 } md={ 4 }>
                                    
                                </Grid>
                            )
                        }
                        {
                            this.props.usersListInProject && this.props.usersListInProject.length > 0 && this.props.usersListInProject.map(user => {
                                return(
                                    <Grid item sm={ 12 } xs={ 12 } md={ 4 }>
                                        
                                    </Grid>
                                )
                            })
                        }
                    </Grid> */}
                </div> 
                {this.props.fetchingProjectAppList && <BackdropLoader message={t("Projects.DashBoardTab.fetchingAppMessage")} />}
            </div>
        )
    }
}

/* ignore istanbul next */
const mapStateToProps = (state: any) => ({
    appList: state.ProjectReducer.appList,
    isLoading: state.ProjectListReducer.isLoading,
    //usersListInProject: state.AddUserPopupReducer.usersListInProject
    pluginList: state.PluginReducer.pluginList,
    projectRole: state.ProjectReducer.projectRole,
    fetchingProjectAppList: state.ProjectReducer.fetchingProjectAppList,
    projectDetails: state.ProjectReducer.projectDetails,
    regionsList: state.CreateAppReducer.regionsList,
})

/* ignore istanbul next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    //fetchAppListByPID : (payload) => dispatch(fetchAppListByPID(payload))
    fetchAppListByPID: (pid: number, plugin?: string, cluster?: string, search?: string) => dispatch(fetchAppListByPID(pid, plugin, cluster, search)),
    fetchPlugins: () => dispatch(fetchPlugins()),
    fetchRegionsList: () => dispatch(fetchRegionsList()),
    clearAppList: () => dispatch(clearAppList()),
  }
}

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withStyles(useStyles)(withTranslation()(Dashboard)));