import React, { useState } from "react";
import {
  Grid,
  Typography,
  Tooltip,
  GridSize,
  Link,
  FormControl,
  InputAdornment,
  IconButton,
  TextField,
} from "@material-ui/core";
import { Variant } from "@material-ui/core/styles/createTypography";
import CopyIcon from "../copyicon/CopyIcon";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { makeStyles } from "@material-ui/core/styles";
import { getDiffDays } from "../../helpers/utils";
import LaunchIcon from "@material-ui/icons/Launch";

interface Props {
  keyXs?: number | string;
  keyMd?: number | string;
  isRowValueOneLine?: boolean;
  className?: string;
  rowKey: string;
  variant?: "inherit" | Variant | undefined;
  copy?: boolean;
  rowValue: string | JSX.Element;
  copyText?: string;
  type?: string;
  hideCopy?:boolean;
  src?: string;
}

const getGridSize = (size?: string | number): number => {
  if (size && typeof size === "string") {
    if (size === "auto") {
      return 4;
    }
    return parseInt(size) > 0 && parseInt(size) <= 12 ? parseInt(size) : 4;
  }
  if (size && typeof size === "number") {
    return size > 0 && size <= 12 ? size : 4;
  }
  return 4;
};

const useStyles = makeStyles(() => ({
  cnameLink: {
    color: "blue!important",
  },
  iconPadding: {
    padding: "0px !important",
  }
}));

function KeyValueRow({ isRowValueOneLine = true, ...props }: Props) {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const keyXs = getGridSize(props.keyXs);
  const keyMd = getGridSize(props.keyMd);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const rowValueType = (type: string) => {
    if (type === "url") {
      return (
        <Link
          href={props.src?props.src:props.rowValue.toString()}
          target="_blank"
          rel="noreferrer"
          underline="always"
          className={classes.cnameLink}
        >
          {props.rowValue}
          <LaunchIcon className="openLinkIcon" />
        </Link>
      );
    } else if (type === "password") {
      return (
        <>
          <FormControl variant="filled">
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <TextField
                  id="outlined-size-small"
                  defaultValue={props.rowValue}
                  size="small"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          size="small"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item className={classes.iconPadding}>
                <CopyIcon copyText={props.rowValue} fontSize="small" />
              </Grid>
            </Grid>
          </FormControl>
        </>
      );
    } else if (type === "time") {
      return getDiffDays(props.rowValue, true);
    } else if (type === "text") {
      return (
        <Grid container spacing={3}>
          <Grid item>{props.rowValue}</Grid>
            <Grid item className={classes.iconPadding}>
            {!props.hideCopy && 
              <CopyIcon copyText={props.rowValue} fontSize="small" />
            }
            </Grid>
        </Grid>
      );
    }
  };

  return (
    <Grid
      data-test="mainContainer"
      container
      // spacing={1}
      className={props.className}
    >
          <Grid
            item
            xs={keyXs as GridSize}
            md={keyMd as GridSize}
            data-test="keyContainer"
          >
            <Tooltip title={props.rowKey} arrow>
              <Typography
                color="primary"
                variant={props.variant ?? "h6"}
                className="oneLine"
                data-test="rowKey"
              >
                {props.rowKey}
              </Typography>
            </Tooltip>
          </Grid>

          <Grid
            item
            md={Number(props.copy ? 10 - keyMd : 12 - keyMd) as GridSize}
            xs={Number(props.copy ? 10 - keyXs : 12 - keyXs) as GridSize}
            data-test="valueContainer"
          >
            {props.type === "url" ||
            props.type === "password" ||
            props.type === "time" ||
            props.type === "text" ? (
              <span className={isRowValueOneLine ? "oneLine" : ""}>
                {rowValueType(props.type)}
              </span>
            ) : (
              <span
                className={isRowValueOneLine ? "oneLine" : ""}
                data-test="rowValue"
              >
                {props.rowValue}
              </span>
            )}
          </Grid>

      {props.copy && (
        <Grid item xs={2} md={2} className="txt-right">
          <CopyIcon copyText={props.copyText ?? props.rowValue} />
        </Grid>
      )}
    </Grid>
  );
}

export default KeyValueRow;
