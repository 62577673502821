import React from "react";
import {
  TableContainer,
  Table,
  TableBody,
  Typography,
  Avatar,
  IconButton,
  Paper,
  TableHead,
} from "@material-ui/core";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../styledtabelcell/StyledTabelCell";
import { useTranslation } from "react-i18next";
import { isAuthorized } from "../../../helpers/utils";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import GroupIcon from "@material-ui/icons/Group";
import DeleteIcon from "@material-ui/icons/Delete";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { UserModel, EnvironmentUser } from "../../../models/Account.model";
import { UserRole } from "../../../models/Environment.model";

type Props = {
  envRole: UserRole;
  usersList: EnvironmentUser[];
  goToGroups: (id: any) => void;
  currentUser: UserModel;
  handleLeaveEnvironment: (id: number) => void;
  handleEdit: (user: EnvironmentUser, userGroup: boolean) => void;
  handleDeleteUser: (id: number) => void;
};

export default function UsersListTable(props: Props) {
  const [t] = useTranslation();
  return (
    <TableContainer data-test="user-list-table" component={Paper}>
      <Table>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell>
              {t("Environment.UserPermissionsTab.name")}
            </StyledTableCell>
            <StyledTableCell>
              {t("Environment.UserPermissionsTab.email")}
            </StyledTableCell>
            <StyledTableCell>
              {t("Environment.UserPermissionsTab.role")}
            </StyledTableCell>
            {isAuthorized("delete", props?.envRole?.name) && (
              <StyledTableCell align="center">
                {t("Environment.UserPermissionsTab.action")}
              </StyledTableCell>
            )}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {props.usersList?.map((user) => (
            <StyledTableRow data-test="user-row" key={user.id}>
              <StyledTableCell>
                {" "}
                {!user.group_id ? (
                  <Avatar src={user?.user?.image ?? ""} />
                ) : (
                  <Avatar>
                    {" "}
                    <GroupIcon />{" "}
                  </Avatar>
                )}
              </StyledTableCell>
              <StyledTableCell>
                {user.user ? (
                  user?.user?.first_name + " " + user?.user?.last_name
                ) : (
                  <Typography
                    color="primary"
                    onClick={() =>
                      props.goToGroups(user?.group?.organization_id)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    {user.group?.Name}
                  </Typography>
                )}
              </StyledTableCell>
              <StyledTableCell>
                {user.user ? user?.email : "N/A"}
              </StyledTableCell>
              <StyledTableCell data-test="user-role">
                {user.user_role ? user?.user_role?.name : ""}
              </StyledTableCell>
              <StyledTableCell align="center">
                {props.currentUser?.id === user?.user_id ? (
                  <Tooltip title="Leave Environment" arrow>
                    <IconButton
                      aria-label="edit"
                      onClick={() => props.handleLeaveEnvironment(user.id)}
                    >
                      <ExitToAppIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <>
                    {isAuthorized("update", props.envRole?.name) && (
                      <Tooltip
                        title={t(
                          "Environment.UserPermissionsTab.updatePermission"
                        )}
                        arrow
                      >
                        <IconButton
                          data-test="edit-btn"
                          aria-label="edit"
                          onClick={() =>
                            props.handleEdit(user, user.group ? true : false)
                          }
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    {isAuthorized("delete", props.envRole?.name) && (
                      <Tooltip
                        title={t("Environment.UserPermissionsTab.removeUser")}
                        arrow
                      >
                        <IconButton
                          data-test="delete-btn"
                          onClick={() => props.handleDeleteUser(user.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </>
                )}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      {(!props.usersList || props.usersList?.length === 0) && (
        <div className="alignCenter">
          <p>{t("Environment.UserPermissionsTab.noUser")}</p>
        </div>
      )}
    </TableContainer>
  );
}
