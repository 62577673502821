import {
    Button, FormControl, InputLabel, makeStyles,MenuItem, Select
} from '@material-ui/core';
import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from 'react-redux';
import BackdropLoader from '../../../../components/loader/BackdropLoader';
import PluginConfigForm from '../../../../components/pluginconfigform/PluginConfigForm';
import { addRegistry, updateRegistry, getRegistryConfig, clearRegistryConfig } from '../../redux/actions';
import {useTranslation} from 'react-i18next';
import { Dispatch } from "redux";
import { Registry } from '../../../../models/Organization.model';
    
/* istanbul ignore next */
const useStyles = makeStyles(() => ({
    selectBox:{
        //minWidth : '100%',
        marginBottom : '1rem',
        fullWidth: true,
        display: "flex"
    },
    selectRegion:{
        textAlign:'left'
    },
}));

interface Props extends PropsFromRedux {
    registryDetails: Registry,
    successCallback:(data?: any)=>void
}

export function AddRegistry(props: Props) {
    const classes = useStyles();
    const [provider, setProvider] = useState('Select');
    const [providerConfig, setProviderConfig] = useState<any>(null);
    const [variables, setVariables] = useState({});
    const [isErrors, setIsErrors] = useState([])
    const [t] = useTranslation()
    //const [hasError, setHasError] = useState(false);
    //const [name, setName] = useState('');
    let RegistryConfig = props.registryConfig;
   // console.log('config file',props.registryConfig)
    useEffect(() => {
        props.getRegistryConfig();
        return(()=> {
            props.clearRegistryConfig()
        })
    }, []);

    useEffect(() => {
        if (props.registryDetails && props.registryDetails.id > 0) { 
            setVariables(props.registryDetails);
            setProvider(props.registryDetails.provider);
        }
    }, [props.registryDetails]);

    useEffect(() => {
        if (provider !== "Select" && RegistryConfig) { 
            const _providerConfig = RegistryConfig?.find((x: Registry) => x.name === provider);
            setProviderConfig(null);
            setTimeout(() => {
                setProviderConfig(_providerConfig);
            }, 0);
        }
    }, [provider, RegistryConfig]);

    const handleProviderChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        if (e.target.value !== "Select") {
            setProvider(e.target.value as string);
        }
        else {
            setProvider(e.target.value);
            setProviderConfig(null);
        }
    }

    /* istanbul ignore next */
    const handleValueChange = (_variables: any, errorsList: any) => {
        setVariables(_variables);
        setIsErrors(errorsList);
            // isErrors: errorsList,
        // const finalError = this.state.isErrors.length > 0;
        // setHasError
    }

    /* istanbul ignore next */
    const successCallback = (data?: any) => { 
        props.successCallback(data);
    }

    const submitAction = () => { 
        const jsonBody = { ...variables, provider: provider };
        if (props.registryDetails)
        {
            props.updateRegistry(props.registryDetails.id, jsonBody, successCallback);
        }
        else
        {
           props.addRegistry(jsonBody, successCallback);
        }
    }

    // const handleChangeName = (e) => {
    //     setName(e.target.value);
    //     if(e.target.value && !new RegExp(item.validation).test(e.target.value)) {
    //         setHasError(true);
    //         props.handleValueChange(item, e.target.value, true);
    //     }
    //     else
    //     {
    //         setHasError(false);
    //         props.handleValueChange(item, e.target.value, false);
    //     }
    // }

    return (
        <div data-test="main-container">
            <>
                {/* <div className="listContainer">
                    <Typography color="textPrimary" variant="h5">
                        { props.registryDetails ? 'Edit Registry' : 'Add Registry' }
                    </Typography>
                </div> */}
                <FormControl className={classes.selectBox} error={Boolean("")} variant="outlined">
                    <InputLabel id="label-provider">{t('Organization.RegistryTab.selectProvider')}</InputLabel>
                    <Select
                        //error={this.state.isRepoTypeDropDownFeildError}
                        //helperText={this.state.repoTypeDropDownErrorMessage}
                        className={classes.selectRegion}
                        color='primary'
                        labelId="label-provider"
                        data-test="select-provider"
                        //id="simple-select"
                        value={ provider }
                        //name='repositoryType'
                        disabled={ Boolean(props.registryDetails) }
                        label='Select Provider'
                        onChange={(e: React.ChangeEvent<{ value: unknown }>) => handleProviderChange(e)}
                        MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            }
                        }}
                    >
                    <MenuItem value='Select'>{t('Organization.RegistryTab.selectProvider')}</MenuItem>
                        {
                            RegistryConfig && RegistryConfig.length > 0 && RegistryConfig.map((_provider: Registry, index: number) => ( 
                                <MenuItem value={ _provider.name } key={ index }>{ _provider.name }</MenuItem>
                            ))
                        }
                    </Select>
                    {/* <FormHelperText error={this.state.isRepoTypeDropDownFeildError}>{ this.state.repoTypeDropDownErrorMessage }</FormHelperText> */}
                </FormControl>
                {
                    providerConfig && 
                    <div>
                        <PluginConfigForm
                            data-test="config-form"
                            pluginVersionConfig={providerConfig.config}
                            handleValueChange={handleValueChange}
                            initialValues = { variables }
                            isErrors={isErrors}
                            gridItemOccupency={12}
                            ignoreAlternateColour = { true }
                            validateEmpty={true}
                        />
                    </div>
                }
                <div className="right">
                    <Button
                        data-test="submit-button"
                        onClick={ submitAction }
                        color="primary"
                        variant="contained"
                        disabled={provider === 'Select' || isErrors.length > 0}
                        className="m-t-20"
                    >
                          { props.registryDetails ? t('Organization.RegistryTab.update') : t('Organization.RegistryTab.add') }
                    </Button>
                </div>
            </>
            { props.addingRegistry && <BackdropLoader message={t('Organization.RegistryTab.adding')} data-test="adding-loader"/>}
            { props.updatingRegistry && <BackdropLoader message={t('Organization.RegistryTab.updating')} data-test="updating-loader"/>}
        </div>
    );
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
    //registrylist: state.OrganizationReducer.registrylist,
    //fetchingRegistries: state.OrganizationReducer.fetchingRegistries
    addingRegistry: state.OrganizationReducer.addingRegistry,
    updatingRegistry: state.OrganizationReducer.updatingRegistry,
    registryConfig: state.OrganizationReducer.registryConfig
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
    addRegistry: (jsonBody: any, callback: () => void) => dispatch(addRegistry(jsonBody, callback)),
    updateRegistry: (id: number, jsonBody: any, callback: () => void) => dispatch(updateRegistry(id, jsonBody, callback)),
    getRegistryConfig: () => dispatch(getRegistryConfig()),
    clearRegistryConfig: () => dispatch(clearRegistryConfig())
    // preserveBackup: (eId, bId, jsonBody) => dispatch(preserveBackup(eId, bId, jsonBody)),
    // restoreBackup: (eId, bId) => dispatch(restoreBackup(eId, bId)),
    // deleteBackup: (eId, bId) => dispatch(deleteBackup(eId, bId)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(AddRegistry);