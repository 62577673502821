import React from "react";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

export default function TabsSkeleton() {
    return(
        <SkeletonTheme data-test="tabs-skeleton-container">
            {[1,2,3,4,5].map(ind => (
                <span key={ind} style={{margin: 1}}>
                    <Skeleton width={"10%"} height={40} />
                </span>
            ))}
        </SkeletonTheme>
    );
}