import React, { Component } from "react";
import { connect,ConnectedProps } from "react-redux";
import ResourceForm from "./ResourceForm";
import { createResourceCall } from "./redux/actions";
import BackdropLoader from '../../components/loader/BackdropLoader';
import { withTranslation,WithTranslation } from 'react-i18next';
import { IpayloadValues,IunsavedResource } from "./Resource";
import { Dispatch } from "redux";

interface IProps extends PropsFromRedux,WithTranslation{
  handleCreateEditSuccess:()=>void
}

export class CreateResource extends Component<IProps> {
  constructor(props:IProps) {
    super(props);
    this.state = {};
  }

  // handleChange = (e) => {
  //   this.setState({
  //     [e.target.name]: e.target.value,
  //   });
  //   console.log(this.state);
  // };

  handleCreateResource = (values:IpayloadValues) => {
    const payload:IunsavedResource = {
      name: values.resourceName,
      cores: values.cores,
      memory:Math.trunc(values.memory),
      weight: values.weight,
      active: values.active,
      attributes: "",
    };
   this.props.createResourceCall(payload);
  };

  UNSAFE_componentWillReceiveProps(nextProps:IProps) {
    if(nextProps) {
      if(!this.props.createResourceCallSuccess && nextProps.createResourceCallSuccess){
        this.props.handleCreateEditSuccess();
      }
    }
  }

  render() {

    const {t}=this.props
    return (
        <div data-test="main-container">
            <ResourceForm
              data-test="resource-form"
              resourceAction={(payload) => this.handleCreateResource(payload)}
            />
            {this.props.executingCreateResource && <BackdropLoader message={t('Resource.ResourcePage.creatingResource')} data-test="loader" />}
        </div>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state:any) => {
  return {
    executingCreateResource: state.ResourceReducer.executingCreateResource,
    createResourceCallSuccess: state.ResourceReducer.createResourceCallSuccess
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch:Dispatch) => ({
  createResourceCall: (payload:IunsavedResource) =>
    dispatch(createResourceCall(payload))
});

const connector=connect(mapStateToProps, mapDispatchToProps)
export default connector(withTranslation()(CreateResource));

type PropsFromRedux= ConnectedProps<typeof connector>
