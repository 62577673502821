import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import "./activitylogs.css";
import { getDiffDays } from "../../helpers/utils";
import { useTranslation } from "react-i18next";
import { ActivityLogModel } from "../../models/Environment.model";

const StyledTableCell = withStyles(() => ({
  root: {
    borderBottom: 0,
    padding: "10px",
    backgroundColor: "#424242",
  },
  head: {
    backgroundColor: "#424242",
    color: "#fff",
  },
  body: {
    backgroundColor: "#424242",
    color: "#fff",
  },
}))(TableCell);

const getClass = (type: string) => {
  let className = "";
  switch (type) {
    case "Normal":
      className = "normal-log";
      break;
    case "Warning":
      className = "warning-log";
      break;
    case "Error":
      className = "error-log";
      break;
    default:
      break;
  }
  return className;
};

type LogRowProps = {
  log: ActivityLogModel;
  showAllLogs?: boolean;
};

export function LogRow(props: LogRowProps) {
  const { log, showAllLogs } = props;
  return (
    <TableRow
      className={getClass(log.type ? log.type : log.Type)}
      data-test="log-tablerow"
    >
      <StyledTableCell component="th" scope="row" data-test="time-stamp">
        {/* {item.lastSeen} */}
        {getDiffDays(
          log.lastTimestamp
            ? log.lastTimestamp
            : log.CreationTimestamp
            ? log.CreationTimestamp.Time
            : ""
        )}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" data-test="log-type">
        {/* {item.Type} */}
        {log.type ? log.type : log.Type}
      </StyledTableCell>

      {showAllLogs && (
        <>
          <StyledTableCell component="th" scope="row" data-test="log-reason">
            {log.reason ? log.reason : log.Reason}
          </StyledTableCell>
          <StyledTableCell component="th" scope="row" data-test="log-object">
            {log.involvedObject
              ? log.involvedObject.name
              : log.InvolvedObject
              ? log.InvolvedObject.Name
              : ""}
          </StyledTableCell>
        </>
      )}

      <StyledTableCell component="th" scope="row" data-test="log-message">
        {log.message ? log.message : log.Message}
      </StyledTableCell>
    </TableRow>
  );
}

type ActivityLogsProps = {
  activityLogs: Array<ActivityLogModel>;
  realTimeActivityLogs?: Array<ActivityLogModel>;
  showAllLogs: boolean;
};

export const ActivityLogs = (props: ActivityLogsProps) => {
  const [filteredActivityLogs, setFilteredActivityLogs] =
    useState<Array<ActivityLogModel> | null>(null);
  const [filteredRealtimeLogs, setFilteredRealtimeLogs] =
    useState<Array<ActivityLogModel> | null>(null);
  const [t] = useTranslation();

  useEffect(() => {
    if (props.activityLogs) {
      setFilteredActivityLogs(
        props.activityLogs.filter((obj) => !obj.hasOwnProperty("reason"))
      );
    }
  }, [props.activityLogs]);

  useEffect(() => {
    if (props.realTimeActivityLogs) {
      setFilteredRealtimeLogs(
        props.realTimeActivityLogs.filter(
          (obj) => !obj.hasOwnProperty("reason")
        )
      );
    }
  }, [props.realTimeActivityLogs]);

  return (
    <div data-test="main-container">
      <div className="">
        <TableContainer
          component={Paper}
          className="logsContainer"
          data-test="table-container"
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead className="sticky">
              <TableRow>
                <StyledTableCell>{t("LastSeen")}</StyledTableCell>
                <StyledTableCell>{t("Type")}</StyledTableCell>
                {props.showAllLogs && (
                  <>
                    <StyledTableCell>{t("Reason")}</StyledTableCell>
                    <StyledTableCell>{t("Object")}</StyledTableCell>
                  </>
                )}
                <StyledTableCell>{t("Message")}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.showAllLogs
                ? props.realTimeActivityLogs &&
                  props.realTimeActivityLogs.length > 0 &&
                  props.realTimeActivityLogs.map((log, index) => {
                    return (
                      <LogRow
                        key={index}
                        log={log}
                        showAllLogs={props.showAllLogs}
                        data-test="log-row"
                      />
                    );
                  })
                : filteredRealtimeLogs &&
                  filteredRealtimeLogs.length > 0 &&
                  filteredRealtimeLogs.map((log, index) => {
                    return (
                      <LogRow
                        key={index}
                        log={log}
                        showAllLogs={props.showAllLogs}
                        data-test="log-row"
                      />
                    );
                  })}
              {props.showAllLogs
                ? props.activityLogs &&
                  props.activityLogs.length > 0 &&
                  props.activityLogs.map((log, index) => {
                    return (
                      <LogRow
                        key={index}
                        log={log}
                        showAllLogs={props.showAllLogs}
                        data-test="log-row"
                      />
                    );
                  })
                : filteredActivityLogs &&
                  filteredActivityLogs.length > 0 &&
                  filteredActivityLogs.map((log, index) => {
                    return (
                      <LogRow
                        key={index}
                        log={log}
                        showAllLogs={props.showAllLogs}
                        data-test="log-row"
                      />
                    );
                  })}
            </TableBody>
          </Table>
          {((props.realTimeActivityLogs &&
            props.realTimeActivityLogs.length <= 0 &&
            props.activityLogs?.length <= 0) ||
            (!props.activityLogs && !props.realTimeActivityLogs)) && (
            <Typography
              className="m-t-20"
              variant="h3"
              style={{ color: "white", textAlign: "center", marginTop: 20 }}
            >
              {t("FetchingActivityLogs")}
            </Typography>
          )}
        </TableContainer>
        {/* <div id="logs-container" ref={this.logEndRef}></div> */}
      </div>
    </div>
  );
};

export default ActivityLogs;
