import React, { useEffect } from "react";
import { Link, Grid, Card, CardContent, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { connect, ConnectedProps } from "react-redux";
import Collapse from "@material-ui/core/Collapse";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import ScheduleOutlinedIcon from "@material-ui/icons/ScheduleOutlined";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import RefreshIcon from "@material-ui/icons/Refresh";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import {
  getDiffDays,
  getDiffBetweenDates,
  isAuthorized,
  getDiffInHours,
} from "../../helpers/utils";
import HourglassEmptyOutlinedIcon from "@material-ui/icons/HourglassEmptyOutlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import Brightness1Icon from "@material-ui/icons/Brightness1";
import StopRoundedIcon from "@material-ui/icons/StopRounded";
import "./cicdcard.css";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import PublicOutlinedIcon from "@material-ui/icons/PublicOutlined";
import CicdLogSkeleton from "../skeletons/CicdLogSkeleton";
import { AppConstants } from "../../constants/appconstants";
import { useTranslation } from "react-i18next";
import {
  WorkflowData,
  LogDataType,
  LogStep,
  StatusType,
  WorkflowType,
  Log,
  PipelineModel,
} from "./../../models/workflow.model";
import CITasks from "./CITasks";
/* istanbul ignore next */
const useStyles = makeStyles((theme) => ({
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

type HandleShowLog = (
  workflowName: string,
  isWorkFlowStale: boolean,
  logStep?: LogStep,
  task?: any,
) => void;

interface StepComponentProps {
  source: string;
  //workflow: WorkflowType;
  isStale: boolean;
  logStep?: LogStep;
  logData: LogDataType;
  handleShowLog: HandleShowLog;
  key: number;
  data: WorkflowData;
}

interface Props extends PropsFromRedux {
  source: string;
  data: WorkflowData;
  logData: LogDataType;
  dataCount: number;
  index: number;
  key: number;
  fetchWorkflowLogs: (workflowName: string) => void;
  handleReRun?: (workflowName: string, buildConfirmMessage?: string) => void;

  handleStop?: (workflowName: string) => void;
  handleShowLog: HandleShowLog;
  clusterDetails?: { cluster_version: string; region: string };
}

export function StatusIndicator(props: {
  pipeline?: PipelineModel;
  isStale: boolean;
}) {
  let statusIndicator = <HourglassEmptyOutlinedIcon />;
  if (props.pipeline) {
    switch (props.pipeline.status) {
      case AppConstants.WorkflowStatus.Succeeded:
        statusIndicator = (
          <CheckIcon
            className="sucessIcon"
            data-test="succeeded-statusIndicator"
          />
        );
        break;
      case AppConstants.WorkflowStatus.Running:
        if (props.isStale) {
          statusIndicator = (
            <ClearOutlinedIcon
              color="error"
              data-test="stale-statusIndicator"
            />
          );
        } else {
          statusIndicator = (
            <div
              className="lds-ring running"
              data-test="running-statusIndicator"
            >
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          );
        }
        break;
      case AppConstants.WorkflowStatus.Pending:
        statusIndicator = (
          <HourglassEmptyOutlinedIcon data-test="pending-statusIndicator" />
        );
        break;
      case AppConstants.WorkflowStatus.Failed:
        statusIndicator = (
          <ClearOutlinedIcon color="error" data-test="failed-statusIndicator" />
        );
        break;
      case AppConstants.WorkflowStatus.Error:
        statusIndicator = (
          <ClearOutlinedIcon color="error" data-test="error-statusIndicator" />
        );
        break;
      case "":
        statusIndicator = (
          <HourglassEmptyOutlinedIcon data-test="noPhase-statusIndicator" />
        );
        break;
      default:
        if (
          props.pipeline.completion_time &&
          props.pipeline.status !== ""
        )
          statusIndicator = (
            <ClearOutlinedIcon
              color="error"
              data-test="finished-statusIndicator"
            />
          );
        else
          statusIndicator = (
            <HourglassEmptyOutlinedIcon data-test="default-statusIndicator" />
          );
    }
  }
  return <div>{statusIndicator}</div>;
}

export function StepStatusIndicator(props: { status: string }) {
  let stepStatusIndicator: string | JSX.Element = "";
  switch (props.status) {
    case AppConstants.WorkflowStatus.Succeeded:
      stepStatusIndicator = (
        <div className="circle" data-test="succeeded-statusIndicator">
          <CheckCircleIcon className="successStep" />
        </div>
      );
      // stepStatusIndicator = <div className="running-circle orange"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>
      break;
    case AppConstants.WorkflowStatus.Running:
      // stepStatusIndicator = <div className="running-circle orange"><div class="lds1-ring"><div></div><div></div><div></div><div></div></div></div>
      stepStatusIndicator = (
        <div className="circle" data-test="running-statusIndicator">
          <Brightness1Icon className="runningStep" />
          <div className="lds1-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      );
      break;
    case AppConstants.WorkflowStatus.Pending:
      stepStatusIndicator = (
        <div className="circle" data-test="pending-statusIndicator">
          <Brightness1Icon className="pendingStep" />
          <div className="lds1-hour">
            <HourglassEmptyOutlinedIcon className="pendingStepIcon" />
          </div>
        </div>
      );
      break;
    case AppConstants.WorkflowStatus.Failed:
      stepStatusIndicator = (
        <div className="circle" data-test="failed-statusIndicator">
          <CancelIcon className="failedStep" />
        </div>
      );
      break;
    case AppConstants.WorkflowStatus.Error:
      stepStatusIndicator = (
        <div className="circle" data-test="error-statusIndicator">
          <CancelIcon className="failedStep" />
        </div>
      );
      break;
    default:
      stepStatusIndicator = "";
  }
  return <div data-test="main-container">{stepStatusIndicator}</div>;
}

export function StepComponent(props: StepComponentProps) {
  const { logStep, isStale } = props; //workflow,
  const pipeline = props.data.pipeline;
  const workflow = { 
    status: {
      phase: pipeline.status,
      message: pipeline.message
    },
    object_meta: {
      name: pipeline.runner
    }
  }

  const getStatus = () => {
    const _phase = workflow && workflow.status && workflow.status.phase;
    let statusIndicator = AppConstants.WorkflowStatus.Pending;

    if (props?.logData?.logs?.length > 0) {
      if (props.source === "1" && logStep) {
        const _currentStep = props.logData.logs.find(
          (x: Log) => x.step === logStep.step
        );
        if (_currentStep) {
          if (
            _phase === AppConstants.WorkflowStatus.Running ||
            _phase === AppConstants.WorkflowStatus.Failed ||
            _phase === AppConstants.WorkflowStatus.Error
          ) {
            const isNextStep = props.logData.logs.find(
              (x: Log) => x.step === logStep.step + 1
            );
            if (isNextStep && isNextStep.log) {
              statusIndicator = AppConstants.WorkflowStatus.Succeeded;
            } else
              statusIndicator = isStale
                ? AppConstants.WorkflowStatus.Failed
                : _phase;
          } else statusIndicator = _phase;
        }
      } else if (props.source === "2") {
        statusIndicator = isStale ? AppConstants.WorkflowStatus.Failed : _phase;
      }
    } else {
      if (_phase) statusIndicator = _phase;
    }

    return statusIndicator;
  };

  const getStepStatus = () => {
    return <StepStatusIndicator status={getStatus()} />;
  };

  const showStepLogOption = () => {
    let isShow = false;
    if (props.logData) {
      if (
        props.logData &&
        props.logData.logs &&
        props.logData.logs.length > 0
      ) {
        if (props.source === "1" && logStep) {
          const _currentStep = props.logData.logs.find(
            (x: Log) => x.step === logStep.step
          );
          if (_currentStep && _currentStep.log) isShow = true;
        } else if (props.source === "2") {
          isShow = true;
        }
      }
    }
    return isShow;
  };

  const getStepElapsedTime = () => {
    let time = "";
    if (props.logData) {
      if (
        props.logData &&
        props.logData.logs &&
        props.logData.logs.length > 0
      ) {
        if (props.source === "1" && logStep) {
          const _currentStep = props.logData.logs.filter(
            (x: Log) => x.step === logStep.step
          );
          if (_currentStep && _currentStep.length > 0) {
            try {
              const _status = getStatus();
              const lastLog = _currentStep[_currentStep.length - 1].log;
              const lastLog_LastLine = lastLog.split("\n").filter(Boolean);
              const endLineTime = lastLog_LastLine[lastLog_LastLine.length - 1].split(" ")[0].trim();
              let finishTime =
                _status === AppConstants.WorkflowStatus.Running
                  ? new Date().toUTCString()
                  : endLineTime;
              time = getDiffBetweenDates(
                _currentStep[0].log.split(" ")[0].trim(),
                finishTime
              );
            } catch (e) {
              console.log(e);
            }
          }
        } else if (props.source === "2") {
          const _status = getStatus();
          const lastLog = props.logData.logs[props.logData.logs.length - 1].log;
          if(lastLog){
            const lastLog_LastLine = lastLog.split("\n").filter(Boolean);
            if(lastLog_LastLine && lastLog_LastLine.length>0){
              const endLineTime = lastLog_LastLine[lastLog_LastLine.length - 1].split(" ")[0].trim();
              let finishTime =
                _status === AppConstants.WorkflowStatus.Running
                  ? new Date().toUTCString()
                  : endLineTime;
              time = getDiffBetweenDates(
                props.logData.logs[0].log.split(" ")[0].trim(),
                finishTime
              );
            }
          }
        }
      }
    }
    return time;
  };

  const handleShowLog = () => {
    // 
    if (logStep) {
      // source 2
      props.handleShowLog(workflow.object_meta.name, isStale, logStep);
    } else {
      // source 1
      props.handleShowLog(workflow.object_meta.name, isStale);
      
    }
  };

  const getTitle = () => {
    let title = "";
    if (props.source === "1" && logStep) {
      title = logStep && logStep.type;
    } else if (props.source === "2") {
      title = props.logData && props.logData.type;
    }
    return title;
  };

  const title = getTitle() ?? workflow?.status?.message;

  return (
    <div className="step" key={logStep && logStep.step}>
      <div className="iconSizeWidth" data-test="status-indicator">
        {getStepStatus()}
      </div>
      <div>
        <div className="title" data-test="title">
          {title ? title : props.data?.type}
        </div>
        <div className="stepsRight">
          {showStepLogOption() && (
            <>
              <span className="time"> {getStepElapsedTime()} </span>
              <span>
                <DescriptionOutlinedIcon
                  className="logsIcon"
                  onClick={() => handleShowLog()}
                  data-test="showLog-button"
                />
              </span>
            </>
          )}
        </div>
        <Divider className="m-t-10" />
      </div>
    </div>
  );
}

export function ClusterStep(props: StepComponentProps) {
  const { logStep, isStale } = props;
  const pipeline = props.data.pipeline;

  const getStatus = () => {
    return pipeline ? pipeline.status : AppConstants.WorkflowStatus.Pending;
  };

  const getStepStatus = () => {
    return <StepStatusIndicator status={getStatus()} />;
  };

  const showStepLogOption = () => {
    let isShow = true;    
    return isShow;
  };

  const getStepElapsedTime = () => {
    let time = "";
    if (pipeline) {
      time = getDiffBetweenDates(
        pipeline.creation_time,
        pipeline.completion_time ? pipeline.completion_time : new Date().toUTCString()
      );
    }
    return time;
  };

  const handleShowLog = () => {
    const first = getFirst();
    props.handleShowLog(pipeline.runner, isStale, logStep, first);
  };

  const getFirst = () => { 
    if (pipeline && pipeline.tasks && pipeline.tasks.length > 0) {
      const firstTask = pipeline.tasks[0];
      if (firstTask && firstTask.steps && firstTask.steps.length > 0) {
        return { task: firstTask, step: firstTask.steps[0] } ;
      }
    }
    return { task: null, step: null };
  }

  const getTitle = () => {
    let title = "";
    const taskInfo = getFirst();
    if (taskInfo && taskInfo.step) {
      title = taskInfo.step.name;
    } 
    return title;
  };

  const title = getTitle() ?? pipeline?.message;

  return (
    <div className="step" key={logStep && logStep.step}>
      <div className="iconSizeWidth" data-test="status-indicator">
        {getStepStatus()}
      </div>
      <div>
        <div className="title" data-test="title">
          {title ? title : props.data?.type}
        </div>
        <div className="stepsRight">
          {showStepLogOption() && (
            <>
              <span className="time"> {getStepElapsedTime()} </span>
              <span>
                <DescriptionOutlinedIcon
                  className="logsIcon"
                  onClick={() => handleShowLog()}
                  data-test="showLog-button"
                />
              </span>
            </>
          )}
        </div>
        <Divider className="m-t-10" />
      </div>
    </div>
  );
}

export const CICDCard = (props: Props) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [expanded, setExpanded] = React.useState(false);
  // const [ isShowFullCommitId, setIsShowFullCommitId ] = React.useState(false);
  const [timer, setTimer] = React.useState<any>(null);
  const [dummydate, setDummydate] = React.useState<number | null>(null);
  const { ci_request, log_steps, pipeline } = props.data; //workflow
  const gitLink =
    ci_request && ci_request.repository_image
      ? ci_request.git_url.substring(0, ci_request.git_url.length - 4) +
        "/commit/" +
        ci_request.repository_image.tag
      : "";
  const isStale =
    pipeline &&
    pipeline.status &&
      pipeline.status === AppConstants.WorkflowStatus.Running
      ? getDiffInHours(
        pipeline.creation_time
        ) > 1
        ? true
        : false
      : false;
  useEffect(() => {
    if (
      timer === null && pipeline &&
      (pipeline.status === AppConstants.WorkflowStatus.Pending ||
        pipeline.status === AppConstants.WorkflowStatus.Running)
    ) {
      let _timer = setInterval(() => setDummydate(Date.now()), 2000);
      setTimer(_timer);
    }
    if (
      timer !== null && pipeline &&
      (pipeline.status === AppConstants.WorkflowStatus.Succeeded ||
        pipeline.status === AppConstants.WorkflowStatus.Failed ||
        pipeline.status === AppConstants.WorkflowStatus.Error)
    ) {
      setTimer(null);
    }
  }, [pipeline]);

  const handleExpandClick = (workflowName: string, isFetchLog: boolean) => {
    // if (!expanded && isFetchLog) {
    //   props.fetchWorkflowLogs(workflowName);
    // } else if (!isFetchLog) {
    //   if (timer === null) {
    //     let _timer = setInterval(() => setDummydate(Date.now()), 2000);
    //     setTimer(_timer);
    //   }
    // }
    setExpanded(!expanded);
  };

  const handleReRun = (workflowName: string) => {
    if (props.handleReRun) {
      props.handleReRun(workflowName);
    }
  };

  const handleStop = (workflowName: string) => {
    if (props.handleStop) {
      props.handleStop(workflowName);
    }
  };

  const handleShowLog = (
    workflowName: string,
    isWorkFlowStale: boolean,
    logStep?: LogStep,
    task?: any
  ) => {
    //workflowName and logStep possibly string type

    props.handleShowLog(workflowName, isWorkFlowStale, logStep, task);
  };

  const getWorkflowFinishedTime = () => {
    let finishTime = "";
    const status = pipeline && pipeline.status;

    if (
      status !== AppConstants.WorkflowStatus.Running &&
      pipeline?.completion_time
    )
      finishTime = pipeline.completion_time;

    return finishTime;
  };

  return (
    <div>
      {ci_request && (
        <Card className="m-b-20" data-test="main-container">
          <CardContent
            data-test="status-indicator"
            data-status={pipeline?.status}
            data-type={props.data.type}
          >
            <span style={{ display: "none" }}> {dummydate} </span>
            <Grid>
              <Grid container alignItems="center" spacing={1}>
                <Grid item md={1}>
                  <StatusIndicator
                    pipeline={pipeline}
                    isStale={isStale}
                  />
                </Grid>

                <Grid item md={10}>
                  <Grid>
                    <Grid container alignItems="center">
                      <Grid item md={10}>
                        <Grid>
                          <Grid container>
                            <Grid item md={12} xs={12}>
                              {props.source === "1" && (
                                <>
                                  {t("Ci")}
                                  {props.dataCount - props.index} {t("Commit")}
                                  &nbsp;
                                  {
                                    <Link
                                      href={gitLink}
                                      target="_blank"
                                      rel="noreferrer"
                                      underline="always"
                                      data-test="repo-tag"
                                    >
                                      {ci_request.repository_image &&
                                        ci_request.repository_image.tag.substring(
                                          0,
                                          7
                                        )}
                                    </Link>
                                  }
                                </>
                              )}
                              {props.source === "2" && (
                                <>#{props.dataCount - props.index}</>
                              )}
                            </Grid>

                            <Grid item md={12} xs={12}>
                              {props.source === "1" && (
                                <p
                                  className="commitmsg oneLine"
                                  title={ci_request.commit_message}
                                  data-test="commit-msg"
                                >
                                  {ci_request.commit_message}
                                </p>
                              )}
                              {props.source === "2" && (
                                <p
                                  className="commitmsg oneLine"
                                  title={props.data && props.data.type}
                                  data-test="commit-msg"
                                >
                                  {props.data && props.data.type}
                                </p>
                              )}
                            </Grid>

                            <Grid item md={12}>
                              <Grid>
                                <Grid container>
                                  {props.source === "1" && (
                                    <>
                                      <Grid
                                        item
                                        md={4}
                                        xs={6}
                                        className="header-details-grid"
                                      >
                                        <PersonOutlineOutlinedIcon className="svgicon" />
                                        <span
                                          className="infoGrid"
                                          data-test="author-name"
                                        >
                                          {ci_request.author}
                                        </span>
                                      </Grid>
                                      <Grid
                                        item
                                        md={3}
                                        xs={6}
                                        className="header-details-grid"
                                      >
                                        <AccountTreeOutlinedIcon className="svgicon" />
                                        <span
                                          className="infoGrid"
                                          data-test="branch-name"
                                        >
                                          {ci_request.git_branch}
                                        </span>
                                      </Grid>
                                    </>
                                  )}
                                  {props.source === "2" && (
                                    <>
                                      {props.clusterDetails && props.clusterDetails.cluster_version &&
                                        <Grid
                                          item
                                          md={4}
                                          xs={6}
                                          className="header-details-grid"
                                        >
                                          <SettingsOutlinedIcon className="svgicon" />
                                          <span
                                            className="infoGrid"
                                            data-test="cluster-version"
                                          >
                                            {props.clusterDetails &&
                                              props.clusterDetails
                                                .cluster_version}
                                          </span>
                                        </Grid>
                                      }
                                      <Grid
                                        item
                                        md={3}
                                        xs={6}
                                        className="header-details-grid"
                                      >
                                        <PublicOutlinedIcon className="svgicon" />
                                        <span
                                          className="infoGrid"
                                          data-test="cluster-region"
                                        >
                                          {props.clusterDetails &&
                                            props.clusterDetails.region}
                                        </span>
                                      </Grid>
                                    </>
                                  )}
                                  <Grid
                                    item
                                    md={3}
                                    xs={6}
                                    className="header-details-grid"
                                  >
                                    <DateRangeOutlinedIcon className="svgicon" />
                                    <span
                                      className="infoGrid"
                                      data-test="time-diff"
                                    >
                                      {getDiffDays(
                                        pipeline?.creation_time,
                                        true
                                      )}
                                    </span>
                                  </Grid>
                                  <Grid
                                    item
                                    md={2}
                                    xs={6}
                                    className="header-details-grid"
                                  >
                                    <ScheduleOutlinedIcon className="svgicon" />
                                    <span
                                      className="infoGrid"
                                      data-test="started-time"
                                    >
                                      {!isStale
                                        ? getDiffBetweenDates(
                                            pipeline?.creation_time,
                                            getWorkflowFinishedTime()
                                          )
                                        : "NA"}
                                    </span>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      {props.source === "1" && (
                        <Grid item md={2}>
                          {isAuthorized("build", props.projectRole.name) &&
                            props.index === 0 &&
                            pipeline &&
                            pipeline.status &&
                            pipeline.status ===
                              AppConstants.WorkflowStatus.Failed && (
                              <Button
                                variant="outlined"
                                onClick={() => handleReRun(pipeline.runner)}
                                startIcon={<RefreshIcon />}
                                data-test="rerun-btn"
                              >
                                {t("ReRun")}
                              </Button>
                            )}
                          {pipeline &&
                            pipeline.status &&
                            (pipeline.status ===
                              AppConstants.WorkflowStatus.Pending ||
                              (pipeline.status ===
                                AppConstants.WorkflowStatus.Running &&
                                !isStale)) && (
                              <Button
                                variant="outlined"
                                onClick={() => handleStop(pipeline.runner)}
                                startIcon={<StopRoundedIcon />}
                                data-test="stop-btn"
                              >
                                {t("Stop")}
                              </Button>
                            )}
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item md={1} className="center stick-right">
                  <IconButton
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: expanded,
                    })}
                    onClick={() =>
                      handleExpandClick(
                        pipeline?.runner,
                        pipeline?.status &&
                          (pipeline.status ===
                            AppConstants.WorkflowStatus.Pending ||
                            (pipeline.status ===
                              AppConstants.WorkflowStatus.Running &&
                              !isStale))
                          ? false
                          : true
                      )
                    }
                    aria-expanded={expanded}
                    aria-label="show more"
                    data-test="expand-icon"
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>

          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Divider />
            <CardContent style={{ overflowX: props.source === "1" ? "auto" : "hidden" }}>
              {props.source === "1" && 
                <>
                  <CITasks data={props.data} />
                </>
              }
              {props.source === "2" &&
                <ClusterStep
                  source={props.source}
                  isStale={isStale}
                  //   logStep={logStep}
                  logData={props.logData}
                  handleShowLog={handleShowLog}
                  key={0}
                  data={props.data}
                />
              }
              {/*{false && props.source === "1" && (
                <>
                  {!props.logData &&
                    [0, 1, 2].map((value) => {
                      return (
                        <React.Fragment key={value}>
                          <CicdLogSkeleton data-test="cicd-skel" />
                        </React.Fragment>
                      );
                    })}
                  {props.logData &&
                    (!props.logData.logs || props.logData.logs.length === 0) &&
                    workflow &&
                    workflow.status &&
                    workflow.status.message && (
                      <div className="step">
                        <span>{t("ErrorMessage")} </span>
                        <div
                          className="title failedStep"
                          data-test="no-workflow-msg"
                        >
                          {workflow.status.message}
                        </div>
                      </div>
                    )}
                  {props.logData &&
                    props.logData.logs &&
                    log_steps &&
                    log_steps.length > 0 &&
                    log_steps.map((logStep: LogStep, ind: number) => {
                      return (
                        <StepComponent
                          source={props.source}
                          workflow={workflow}
                          isStale={isStale}
                          logStep={logStep}
                          logData={props.logData}
                          handleShowLog={handleShowLog}
                          key={ind}
                          data-test="step-comp"
                        />
                      );
                    })}
                </>
                  )} */}
              {/*{false && props.source === "2" && !log_steps && (
                // <div className="step">
                //     <div className="iconSizeWidth">
                //         <StepStatusIndicator status={ Workflow && Workflow.Status && Workflow.Status.Phase }/>
                //     </div>
                //     <div>
                //         <div className="title">{ props.logData && props.logData.Type }</div>
                //         <div className="stepsRight">
                //             <span className="time"> { getDiffBetweenDates(Workflow.Status.StartedAt.Time, getWorkflowFinishedTime()) } </span>
                //             <span><DescriptionOutlinedIcon className="logsIcon" onClick={ () => handleShowLog(Workflow.Name, isStale , null) }/></span>
                //         </div>
                //         <Divider className="m-t-10"/>
                //     </div>
                // </div>
                <StepComponent
                  source={props.source}
                  workflow={workflow}
                  isStale={isStale}
                  //   logStep={logStep}
                  logData={props.logData}
                  handleShowLog={handleShowLog}
                  key={0}
                  data={props.data}
                />
              )} */}
            </CardContent>
          </Collapse>
        </Card>
      )}
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: any) => {
  return {
    projectRole: state.ProjectReducer.projectRole,
  };
};
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(CICDCard);
