const NOTIFICATIONS_PREFIX = '@NOTIFICATIONS';

export const GET_NOTIFICATION_LIST = `${ NOTIFICATIONS_PREFIX }/GET_NOTIFICATION_LIST`
export const GET_NOTIFICATION_LIST_SUCCESS = `${ NOTIFICATIONS_PREFIX }/GET_NOTIFICATION_LIST_SUCCESS`
export const GET_NOTIFICATION_LIST_FAILURE = `${ NOTIFICATIONS_PREFIX }/GET_NOTIFICATION_LIST_FAILURE`
export const GET_MORE_NOTIFICATION_LIST_SUCCESS = `${ NOTIFICATIONS_PREFIX }/GET_MORE_NOTIFICATION_LIST_SUCCESS`

export const GET_RECENT_NOTIFICATION_LIST = `${ NOTIFICATIONS_PREFIX }/GET_RECENT_NOTIFICATION_LIST`
export const GET_RECENT_NOTIFICATION_LIST_SUCCESS = `${ NOTIFICATIONS_PREFIX }/GET_RECENT_NOTIFICATION_LIST_SUCCESS`
export const GET_RECENT_NOTIFICATION_LIST_FAILURE = `${ NOTIFICATIONS_PREFIX }/GET_RECENT_NOTIFICATION_LIST_FAILURE`
export const UPDATE_RECENT_NOTIFICATION_LIST = `${ NOTIFICATIONS_PREFIX }/UPDATE_RECENT_NOTIFICATION_LIST`

export const MARK_AS_READ_NOTIFICATION= `${ NOTIFICATIONS_PREFIX }/MARK_AS_READ_NOTIFICATION`

export const UPDATE_SEEN_UNSEEN = `${ NOTIFICATIONS_PREFIX }/UPDATE_SEEN_UNSEEN`
export const UPDATE_SEEN_UNSEEN_SUCCESS = `${ NOTIFICATIONS_PREFIX }/UPDATE_SEEN_UNSEEN_SUCCESS`
export const UPDATE_SEEN_UNSEEN_FAILURE = `${ NOTIFICATIONS_PREFIX }/UPDATE_SEEN_UNSEEN_FAILURE`

export const GET_UNSEEN_COUNT = `${ NOTIFICATIONS_PREFIX }/GET_UNSEEN_COUNT`
export const GET_UNSEEN_COUNT_SUCCESS = `${ NOTIFICATIONS_PREFIX }/GET_UNSEEN_COUNT_SUCCESS`
export const GET_UNSEEN_COUNT_FAILURE = `${ NOTIFICATIONS_PREFIX }/GET_UNSEEN_COUNT_FAILURE`

export const UPDATE_UNSEEN_COUNT = `${NOTIFICATIONS_PREFIX}/UPDATE_UNSEEN_COUNT`

export const NAVIGATION_CHECK = `${ NOTIFICATIONS_PREFIX }/NAVIGATION_CHECK`
export const NAVIGATION_CHECK_SUCCESS = `${ NOTIFICATIONS_PREFIX }/NAVIGATION_CHECK_SUCCESS`
export const NAVIGATION_CHECK_FAILURE = `${ NOTIFICATIONS_PREFIX }/NAVIGATION_CHECK_FAILURE`

export const MARK_ALL_AS_READ = `${ NOTIFICATIONS_PREFIX }/MARK_ALL_AS_READ`
export const MARK_ALL_AS_READ_SUCCESS = `${ NOTIFICATIONS_PREFIX }/MARK_ALL_AS_READ_SUCCESS`
export const MARK_ALL_AS_READ_FAILURE = `${ NOTIFICATIONS_PREFIX }/MARK_ALL_AS_READ_FAILURE`
export const SHOWHIDE_NOTY_BUBBLE = `${ NOTIFICATIONS_PREFIX }/SHOWHIDE_NOTY_BUBBLE`

export const CLEAR_NOTIFICATIONS = `${ NOTIFICATIONS_PREFIX }/CLEAR_NOTIFICATIONS`

export const getNotifications = (pageNo, pageSize, filter) => ({
	type: GET_NOTIFICATION_LIST,
	data: { pageNo, pageSize, filter }
})

export const clearNotification = () => ({
	type: CLEAR_NOTIFICATIONS,
})

export const getRecentNotifications = (pageNo, pageSize, filter) => ({
	type: GET_RECENT_NOTIFICATION_LIST,
	data: { pageNo, pageSize, filter }
})

export const updateRecentNotifications = (notys) => ({
	type: UPDATE_RECENT_NOTIFICATION_LIST,
	data: { notys }
})

export const showHideNotyBubble = (isShow) => ({
	type: SHOWHIDE_NOTY_BUBBLE,
	data: { isShow }
})

export const markAsRead = (notyIds) => ({
	type: MARK_AS_READ_NOTIFICATION,
	data: { notyIds }
})

export const updateSeenUnseen = (jsonBody) => ({
	type: UPDATE_SEEN_UNSEEN,
	data: { jsonBody }
})

export const getUnseenCount = () => ({
	type: GET_UNSEEN_COUNT
})

export const updateUnseenCount = (jsonBody) => ({
	type: UPDATE_UNSEEN_COUNT,
	data: jsonBody
})

export const navigationCheck = (module, id, callBack) => ({
	type: NAVIGATION_CHECK,
	data: { module, id, callBack }
})

export const markAllAsRead = () => ({
	type: MARK_ALL_AS_READ
})