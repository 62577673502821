// import HCaptcha from '@hcaptcha/react-hcaptcha';
import { Button, Checkbox, CssBaseline, FormControlLabel, Grid, IconButton, InputAdornment, Paper, Theme, Typography, createStyles, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Formik } from 'formik';
import React, { Component } from 'react';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import * as Yup from 'yup';
import CompanyBanner from '../../components/companybanner/CompanyBanner';
import MuiTextField from '../../components/textfield/MuiTextField';
import { requestInviteSignup } from '../login/redux/actions';
import { getInviteDetails } from '../registration/redux/actions';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { History } from 'history';
import { Dispatch } from 'redux';
import { UserModel } from '../../models/Account.model';

const useStyles = (theme: Theme) => createStyles({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(/images/infographics/login.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[ 50 ] : theme.palette.grey[ 900 ],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(15, 8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft : '15%',
    paddingRight : '15%',
    [ theme.breakpoints.down('sm') ]: {
      paddingLeft : '2.5%',
      paddingRight : '2.5%',
      margin: theme.spacing(10, 6),
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(1.5, 0, 2),
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
});

export const Terms = () => {
    const { t } = useTranslation();
    return (
        <div> <span data-test="term-text">{t('AgreeT&C')} </span> <Link to={'/terms'} data-test="term-link">{t('TermsOfUse')}</Link> <span> {t('And')} </span> <Link data-test="privacy-link" to={'/privacy'}>{t('PrivacyPolicy')}</Link> </div>
    )
  };

interface PayloadProps extends Pick<UserModel, "first_name" | "last_name" | "email" | "company" | "designation"> {
  password: string,
  profession: string,
  //hCaptchaKey: string
}

interface State {
  showPassword: boolean;
  inviteToken: string;
  agreeTerms: boolean;
  //hCaptchaKey: string;
}

interface Props extends PropsFromRedux, WithStyles<typeof useStyles>, WithTranslation, RouteComponentProps<{ token: string; }> {
  history: History
}

export class InviteLogin extends Component<Props, State> {
  constructor(props: Props){
    super(props);
    this.state = {
        showPassword:false,
        inviteToken: this.props.match.params.token,
        agreeTerms: false,
        // hCaptchaKey: ''
    }
    // this.captcha = React.createRef();
  }
  captcha = React.createRef<any>();
  
  componentDidMount() {
    this.props.getInviteDetails(this.state.inviteToken)
  }

  handleAgreeTerms = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
        agreeTerms : e.target.checked
    })
  }

  // handleVerificationSuccess = (token) => {
  //   this.setState({
  //     hCaptchaKey: token
  //   })
  // }
  
  handleClickShowPassword = () => {
    this.setState({
      showPassword:!this.state.showPassword
    })
  };

  handleMouseDownPassword = (e: any) => {
    e.preventDefault();
  };

  render () {
    const { t, classes, invitationDetails } = this.props;
    return (
        <Grid container component="main" className={ classes.root } data-test="main-container">
            <CssBaseline />
            <CompanyBanner />
            <Grid item xs={ 12 } sm={ 8 } md={ 6 } component={ Paper } elevation={ 6 } square>
                <div className={classes.contentHeader}>
                    <Link to="/" className='textDecorationNone' data-test="back-arrow">
                        <IconButton >
                            <ArrowBackIcon />
                        </IconButton>
                    </Link>
                </div>
                <div className={ classes.paper }>
                    <img src="/images/logos/logo-blue.svg" alt="01Cloud" className='authlogo' data-test="logo-image"/>
                    {
                    invitationDetails && invitationDetails.id &&
                    <>
                        <Typography variant="h5"  align='center' data-test="page-header">
                          {t('Registration.InviteLogin.signUp')}
                        </Typography>
                        <Formik data-test="formik-container" initialValues={{ email: invitationDetails && invitationDetails.email, password: '', confirmPassword : ''} } 
                          onSubmit={ (values, { setSubmitting }) => {
                            setSubmitting(true);
                            const payload = {
                              first_name: invitationDetails.first_name,
                              last_name: invitationDetails.last_name,
                              email: invitationDetails.email,
                              password: values.password,
                              company: invitationDetails.company,
                              profession: '',
                              designation: invitationDetails.designation,
                              // hCaptchaKey: this.state.hCaptchaKey 
                            }
                            this.props.requestInviteSignup(this.state.inviteToken, payload, this.props.history)
                        } }
                          validationSchema = { 
                            Yup.object().shape({
                              password: Yup
                                          .string().min(2,t('Account.PasswordTab.tooShort')).max(30,t('Account.PasswordTab.tooLong'))
                                          .required(t('Registration.InviteLogin.enterPassword'))
                                          .matches(
                                            /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/,
                                            t('Account.PasswordTab.minCharsError')
                                          ),
                              confirmPassword: Yup
                                        .string()
                                        .oneOf([ Yup.ref('password') ], t('Account.PasswordTab.passwordNotMatching'))
                                        .required(t('Registration.InviteLogin.confirmPassword'))
                            }) }
                        >
                            {
                        (props) => {
                          const {
                            values,
                            touched,
                            errors,
                            dirty,
                            // isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            // handleReset,
                            isValid,
                          } = props;
                          return (
                              <form onSubmit={ handleSubmit } className={ classes.form }>
                                  <MuiTextField 
                                    id="email" 
                                //error={ errors.email && touched.email }
                                    label={t('Registration.InviteLogin.emailAddress')}
                                    name="email" 
                                    //color='primary'
                                //onChange={ handleChange } 
                                    value={ values.email }
                                //onBlur={ handleBlur }
                                //helperText={ (errors.email && touched.email) && errors.email }
                                    margin="normal"
                                    data-test="email-field"
                                    disabled
                                  />
                                  {/* <FormControl variant="outlined" 
                                    margin="normal" 
                                    fullWidth
                                  >
                                      <InputLabel htmlFor="password" error={ errors.password && touched.password }>{t('Registration.InviteLogin.password')}</InputLabel>
                                      <OutlinedInput
                                        id="password"
                                        // autoFocus
                                        error={ errors.password && touched.password }
                                        type={this.state.showPassword ? 'text' : 'password'}
                                        value={values.password}
                                        color='primary'
                                        name="password" 
                                        onChange={ handleChange } 
                                        onBlur={ handleBlur }
                                        data-test="password-field"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                  aria-label="toggle password visibility"
                                                  onClick={this.handleClickShowPassword}
                                                  data-test="password-toggle"
                                                  onMouseDown={this.shandleMouseDownPassword}
                                                  edge="end"
                                                >
                                                    {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                    }
                                        labelWidth={70}
                                      />
                                      <FormHelperText error={ errors.password && touched.password }>{ (errors.password && touched.password) ?  errors.password : ''}</FormHelperText>
                                  </FormControl> */}

                                  <MuiTextField
                                  margin="normal"
                                  label={t('Registration.InviteLogin.password')}
                                  id="password"
                                  error={ errors.password && touched.password ? true : false }
                                  type={this.state.showPassword ? 'text' : 'password'}
                                  value={values.password}
                                  name="password" 
                                  onChange={ handleChange } 
                                  onBlur={ handleBlur }
                                  data-test="password-field"
                                  InputProps={{
                                    endAdornment:(
                                      <InputAdornment position="end">
                                          <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={this.handleClickShowPassword}
                                            data-test="password-toggle"
                                            onMouseDown={this.handleMouseDownPassword}
                                            edge="end"
                                          >
                                              {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                          </IconButton>
                                      </InputAdornment>
                                    )
                                  }}
                                  //labelWidth={70}
                                  helperText={ (errors.password && touched.password) ?  errors.password : ''}
                                  />
                                  
                                  <MuiTextField 
                                    id="confirmPassword"
                                    variant="outlined"
                                    margin="normal"
                                    error={ errors.confirmPassword && touched.confirmPassword ? true : false}
                                    type="password" 
                                    //color='primary'
                                    label={t('Registration.InviteLogin.confPassword')}
                                    name="confirmPassword" 
                                    onChange={ handleChange } 
                                    value={ values.confirmPassword }
                                    onBlur={ handleBlur }
                                    autoComplete="new-password"
                                    data-test="confirm-password-field"
                                    helperText={ errors.confirmPassword && touched.confirmPassword ? errors.confirmPassword : "" }
                                  />
                                  
                                  {/* <div className='registrationButtonDiv'>
                                      <HCaptcha 
                                        ref={ this.captcha }
                                        sitekey= { window?.config?.REACT_APP_HCAPTCHA_SITEKEY } 
                                        onVerify={token => this.handleVerificationSuccess(token)}
                                      />
                                  </div> */}
                                  <div className="tnscheck">
                                      <FormControlLabel
                                        control={
                                            <Checkbox
                                              name="checkedB"
                                              color="primary"
                                              data-test="agree-check-button"
                                          
                                              onChange={ (e)=>{ this.handleAgreeTerms(e) } }
                                            />
                                        }
                                        data-test="agree-check-field"
                                        label={ <Terms/> }
                                      />
                                  </div>
                                  
                                  <Grid container spacing={2}>
                                      <Grid item md={12} sm={12} xs={12}>
                                          <div className='signInButtonDiv'>
                                              <Button
                                                variant="contained"
                                                type='submit'
                                                size="large"
                                                className="w-100"
                                                color='primary'
                                                //align='center'
                                                data-test="submit-button"
                                                disabled={ !(isValid && dirty && this.state.agreeTerms) }
                                              >
                                                  {t('Sumbit')}
                                              </Button>
                                          </div>
                                      </Grid>
                                    
                                  </Grid>
                              </form>
                          )
                        }
                      }
                        </Formik>
                    </>
                }
                    {
                    invitationDetails && !invitationDetails.id &&
                    <Typography variant="h5"  align='center' data-test="invalid-invitaion-container">
                      {t('Registration.InviteLogin.invalidLink')}
                    </Typography>
                }
                </div>
            </Grid>
        </Grid>
    )
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
    invitationDetails: state.RegisterReducer.invitationDetails
})

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
      requestInviteSignup: (token: string, payload: PayloadProps, history: History) => dispatch(requestInviteSignup(token, payload, history)),
      getInviteDetails: (token: string) => dispatch(getInviteDetails(token))
  }
}

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(
  withStyles(useStyles)(withTranslation()(InviteLogin))
);
