import {
  FETCH_INIT_CONTAINER_DETAILS,
  FETCH_INIT_CONTAINER_DETAILS_FAILURE,
  FETCH_INIT_CONTAINER_DETAILS_SUCCESS,
  FETCH_INIT_CONTAINER_LIST,
  FETCH_INIT_CONTAINER_LIST_FAILURE,
  FETCH_INIT_CONTAINER_LIST_SUCCESS,
  CREATE_INIT_CONTAINER,
  CREATE_INIT_CONTAINER_FAILURE,
  CREATE_INIT_CONTAINER_SUCCESS,
  UPDATE_INIT_CONTAINER,
  UPDATE_INIT_CONTAINER_FAILURE,
  UPDATE_INIT_CONTAINER_SUCCESS,
  DELETE_INIT_CONTAINER,
  DELETE_INIT_CONTAINER_FAILURE,
  DELETE_INIT_CONTAINER_SUCCESS,
  GET_IMAGES,
  CLEAR_INIT_CONTAINER_DETAILS,
  CLEAR_INIT_CONTAINER_LIST,
  FETCH_INIT_CONTAINER_LOG
} from './actions';
import initialState from './initialState';

const reducer = (state = initialState, payload) => {
  switch (payload.type) {
    case FETCH_INIT_CONTAINER_DETAILS:
      return {
        ...state,
        fetchingInitContainerDetail: true,
      };
    case FETCH_INIT_CONTAINER_DETAILS_SUCCESS:
      return {
        ...state,
        fetchingInitContainerDetail: false,
        initContainerDetails: payload.data,
      };
    case FETCH_INIT_CONTAINER_DETAILS_FAILURE:
      return {
        ...state,
        fetchingInitContainerDetail: false,
      };
    case FETCH_INIT_CONTAINER_LIST:
      return {
        ...state,
        fetchingInitContainerList: true,
      };
    case FETCH_INIT_CONTAINER_LIST_SUCCESS:
      return {
        ...state,
        initContainerList: payload.data,
        fetchingInitContainerList: false,
      };
    case FETCH_INIT_CONTAINER_LIST_FAILURE:
      return {
        ...state,
        fetchingInitContainerList: false,
      };
    case CREATE_INIT_CONTAINER:
      return {
        ...state,
        creatingInitContainer: true,
      };
    case CREATE_INIT_CONTAINER_SUCCESS:
      return {
        ...state,
        initContainerDetails: payload.data,
        creatingInitContainer: false,
      };
    case CREATE_INIT_CONTAINER_FAILURE:
      return {
        ...state,
        creatingInitContainer: false,
      };
    case UPDATE_INIT_CONTAINER:
      return {
        ...state,
        updatingInitContainer: true,
      };
    case UPDATE_INIT_CONTAINER_SUCCESS:
      return {
        ...state,
        updatingInitContainer: false,
        initContainerDetails: payload.data
      };
    case UPDATE_INIT_CONTAINER_FAILURE:
      return {
        ...state,
        updatingInitContainer: false,
      };
    case DELETE_INIT_CONTAINER:
      return {
        ...state,
        deletingInitContainer: true,
      };
    case DELETE_INIT_CONTAINER_SUCCESS:
      return {
        ...state,
        deletingInitContainer: false,
      };
    case DELETE_INIT_CONTAINER_FAILURE:
      return {
        ...state,
        deletingInitContainer: false,
      };
    case GET_IMAGES:
      return {
        ...state,
        initContainerImages: payload.data,
      };
    case CLEAR_INIT_CONTAINER_LIST:
      return {
        ...state,
        initContainerList: initialState.initContainerList,
      };
    case CLEAR_INIT_CONTAINER_DETAILS:
      return {
        ...state,
        initContainerDetails: null,
      };
    case FETCH_INIT_CONTAINER_LOG:
      return {
        ...state,
        initContainerLogs: payload.data,
      };
    // case CLEAR_CREATE_INIT_CONTAINER:
    //   return {
    //     ...state,
    //     initContainerDetails: null,
    //   };
    default:
      return state;
  }
};

export default reducer;
