import React, { useEffect, useState } from "react";
import {
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
} from "@material-ui/core";
import { Operators } from "./OperatorHelper";
import ConfirmActionPopup from "../confirmactionpopup/ConfirmActionPopup";
import {
  deployOperator,
  initiateFetchOperatorStatus,
} from "../../pages/operators/redux/actions";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { useTranslation } from "react-i18next";
import "./OperatorForm.css";
import { OperatorClass } from "../../models/Operator.model";

interface Props extends PropsFromRedux {
  operator: OperatorClass;
  clusterId: number;
  handlePopupClose: () => void;
}
interface OperatorData {
  package_name: string;
  csv_value: string;
  channel: string;
  installation_mode: string;
  install_plan_approval: string;
}

export function OperatorForm(props: Props) {
  const { operator, clusterId } = props;

  const [t] = useTranslation();

  const [updateChannel, setUpdateChannel] = useState("");
  // const [updateChannelGlobal, setUpdateChannelGlobal] = useState(false);
  // Installation mode: true for global, false for namespaced
  // const [installationModeGlobal, setInstallationModeGlobal] = useState(false);
  // Approval strategy: true for automatic, false for manual
  const [approvalStrategyAutomatic, setApprovalStrategyAutomatic] =
    useState(true);

  // const [installationMode, setInstallationMode] = useState(null);
  // const [approvalStrategy, setApprovalStrategy] = useState(null);

  const [isDeployConfirmOpen, setIsDeployConfirmOpen] = useState(false);

  // const dispatch = useDispatch();

  useEffect(() => {
    if (operator) {
      const defaultChannel = Operators.getDefaultChannel(operator);
      // const global = operator.globalOperator;
      setUpdateChannel(defaultChannel ? defaultChannel.name: "");
      // setUpdateChannelGlobal(global);
      // setInstallationModeGlobal(global);
    }
  }, [operator]);

  // if (!updateChannel) {
  //     return (
  //         alert(`The Operator ${operator?.metadata?.name} doesnt define a valid channel. This is needed to extract
  //         required info.`)
  //     // <Alert theme="danger">
  //     //     The Operator {operator?.metadata?.name} doesnt define a valid channel. This is needed to extract
  //     //     required info.
  //     // </Alert>
  //     );
  // }
  //const { currentCSVDesc } = updateChannel;
  // It's not possible to install a namespaced operator in the "operators" ns
  // const disableInstall =
  //   operator?.metadata?.namespace === "operators" && !updateChannelGlobal;

  const selectChannel = (channel: string) => {
    // const newChannel = operatorDetails?.channels.find(
    //   ch => ch.name === channel
    // );
    // const global = Operators.global(newChannel);
    // return () => {
    setUpdateChannel(channel);
    // setUpdateChannelGlobal(global);
    // setInstallationModeGlobal(global);
    // };
  };

  // const setInstallationMode = global => {
  //   setInstallationModeGlobal(global);
  // };

  const setApprovalStrategy = (automatic: boolean) => {
    setApprovalStrategyAutomatic(automatic);
  };

  // const handleUpdateStrategyChange = e => {
  //   setUpdateChannel(e.target.value);
  // };

  // const handleInstallationModeChange = e => {
  //   setInstallationMode(e.target.value);
  // };

  // const handleApprovalStrategyChange = e => {
  //   setApprovalStrategy(e.target.value);
  // };

  // const handleDeploy = () => {
  // const targetNS = installationModeGlobal ? "operators" : operator?.metadata?.namespace;
  // const approvalStrategy = approvalStrategyAutomatic ? "Automatic" : "Manual";
  // const deployed = await dispatch(
  //   actions.operators.createOperator(
  //     cluster,
  //     targetNS,
  //     operator!.metadata.name,
  //     updateChannel!.name,
  //     approvalStrategy,
  //     updateChannel!.currentCSV,
  //   ),
  // );
  // if (deployed) {
  //   // Success, redirect to operator page
  //   dispatch(push(app.operators.list(cluster, namespace)));
  // }
  // };

  const handleOperatorDeploy = () => setIsDeployConfirmOpen(true);

  const deployCallback = () => {
    props.handlePopupClose();
    props.initiateFetchOperatorStatus(clusterId);
  };
  const handleOperatorDeployAgree = () => {
    props.deployOperator(
      clusterId,
      {
        package_name: operator.packageName,
        csv_value: operator.version,
        channel: updateChannel,
        installation_mode: "default",
        install_plan_approval: approvalStrategyAutomatic
          ? "Automatic"
          : "Manual",
      },
      deployCallback
    );
    setIsDeployConfirmOpen(false);
  };

  const handleOperatorDeployDisagree = () => setIsDeployConfirmOpen(false);

  return (
    operator && (
      <>
        <Grid
          container
          spacing={2}
          alignItems="center"
          className="operator-form-container"
          data-test="operator-form"
        >
          <Grid item xs={2} md={2}>
            <img
              src={operator.thumbUrl}
              alt={operator.displayName}
              className="operator-icon"
            />
          </Grid>
          <Grid item xs={10} md={10}>
            <div className="operator-title">
              {operator.displayName} {t("Operators.OperatorForm.by")}{" "}
              {operator.provider}
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2} className="operator-form-body-container">
          <Grid item xs={2} md={2}>
            {/* <img
            src={operator.imgUrl}
            alt={operator.displayName}
            className="operator-icon"
          /> */}
          </Grid>
          <Grid item xs={10} md={10}>
            <div className="kubeapps-form">
              {/* {
              disableInstall &&
                alert(
                  "Its not possible to install a namespaced operator in the operators namespace"
                )
              // <Alert theme="danger">
              // Its not possible to install a namespaced operator in the operators namespace
              // </Alert>
            } */}

              <div className="operator-version m-t-10">
                {t("Operators.OperatorForm.operatorVersion")}:{" "}
                {operator.version}
              </div>

              <div className="clr-form-control m-t-20">
                <label className="clr-control-label">
                  {t("Operators.OperatorCatalog.updateChannel")}
                </label>
                <div className="clr-control-helper">
                  {t("Operators.OperatorForm.channelInfo")}
                </div>
                <div className="clr-control-container">
                  <RadioGroup
                    aria-label="update-channel"
                    defaultValue="alpha"
                    name="update-channel"
                    onChange={(e) => selectChannel(e.target.value)}
                    value={updateChannel}
                    data-test="update-channel-field"
                  >
                    {operator.channels.map((channel) => (
                      <FormControlLabel
                        value={channel.name}
                        control={<Radio size="small" color="primary" />}
                        label={channel.name}
                        key={channel.name}
                        data-test="update-channel-option"
                      />
                    ))}
                  </RadioGroup>
                </div>
              </div>

              {/* <div className="clr-form-control m-t-20">
                <label className="clr-control-label">Installation Mode</label>
                <div className="clr-control-container">
                  <RadioGroup
                    aria-label="installation-mode"
                    name="installation-mode"
                    onChange={e =>
                      setInstallationMode(e.target.value === "true")
                    }
                    value={installationModeGlobal}
                    data-test="installation-mode-field"
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio size="small" color="primary" />}
                      label="All namespaces on the cluster (default)"
                      disabled={!updateChannelGlobal}
                      data-test="installation-mode-all-namespaces"
                    />
                    {!updateChannelGlobal && (
                      <div className="clr-control-helper">
                        This mode is not supported by this Operator and channel.
                      </div>
                    )}
                    <FormControlLabel
                      value={false}
                      control={<Radio size="small" color="primary" />}
                      label={`The current namespace`}
                    />
                  </RadioGroup>
                </div>
              </div> */}

              <div className="clr-form-control m-t-20">
                <label className="clr-control-label">
                  {t("Operators.OperatorCatalog.approvalStrategy")}
                </label>
                <div className="clr-control-helper">
                  {t("Operators.OperatorForm.approvalInfo")}
                </div>

                <div className="clr-control-container">
                  <RadioGroup
                    aria-label="approval-strategy"
                    defaultValue="automatic"
                    name="approval-strategy"
                    onChange={(e) =>
                      setApprovalStrategy(e.target.value === "true")
                    }
                    value={approvalStrategyAutomatic}
                    data-test="approval-strategy-field"
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio size="small" color="primary" />}
                      label={t("Operators.OperatorForm.automatic")}
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio size="small" color="primary" />}
                      label={t("Operators.OperatorForm.manual")}
                    />
                  </RadioGroup>
                </div>
              </div>
              <div className="alignRight">
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  // disabled={disableInstall}
                  onClick={handleOperatorDeploy}
                  data-test="deploy-button"
                >
                  {t("Operators.AddOperatorPopup.deploy")}
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
        <ConfirmActionPopup
          open={isDeployConfirmOpen}
          message={t("Operators.OperatorForm.deployConfirm")}
          handleAgree={handleOperatorDeployAgree}
          handleDisAgree={handleOperatorDeployDisagree}
          yesText={t("Projects.ProjectInfo.yesLabel")}
        />
      </>
    )
  );
}

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    deployOperator: (
      clusterId: number,
      operatorData: OperatorData,
      callBack: () => void
    ) => dispatch(deployOperator(clusterId, operatorData, callBack)),
    initiateFetchOperatorStatus: (clusterId: number) =>
      dispatch(initiateFetchOperatorStatus(clusterId)),
  };
};
const connector = connect(null, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(OperatorForm);
