import { call, takeLatest, put, select } from 'redux-saga/effects';
import axios from 'axios';
import endpoints from '../../../constants/endpoints';
import paths from '../../../constants/paths';
import { 
  ADD_USER_IN_PROJECT,
  FETCH_USER_LIST_IN_PROJECT,
  DELETE_USERS_IN_PROJECT,
  ADD_USER_IN_ENVIRONMENT,
  USERS_LIST_IN_ENVIRONMENT,
  FETCH_USER_LIST_IN_ENVIRONMENT,
  DELETE_USERS_IN_ENVIRONMENT,
  FETCH_ROLES,
  FETCH_ROLES_SUCCESS,
  ADD_USER_IN_PROJECT_SUCCESS,
  ADD_USER_IN_ENVIRONMENT_SUCCESS,
  ADD_USER_IN_ENVIRONMENT_FAILURE,
  ADD_USER_IN_PROJECT_FAILURE,
  EDIT_USER_IN_ENVIRONMENT,
  EDIT_USER_IN_ENVIRONMENT_FAILURE,
  EDIT_USER_IN_ENVIRONMENT_SUCCESS,
  EDIT_USER_IN_PROJECT,
  EDIT_USER_IN_PROJECT_FAILURE,
  EDIT_USER_IN_PROJECT_SUCCESS,
  DELETE_USERS_IN_PROJECT_SUCCESS,
  DELETE_USERS_IN_PROJECT_FAILURE,
  DELETE_USERS_IN_ENVIRONMENT_FAILURE,
  DELETE_USERS_IN_ENVIRONMENT_SUCCESS,
  FETCH_USER_LIST_IN_PROJECT_SUCCESS,
  FETCH_USER_LIST_IN_PROJECT_FAILURE,
  FETCH_USER_LIST_IN_ENVIRONMENT_FAILURE,
  ADD_USER_IN_HELM_ENVIRONMENT,
  ADD_USER_IN_HELM_ENVIRONMENT_SUCCESS,
  ADD_USER_IN_HELM_ENVIRONMENT_FAILURE,
  FETCH_USER_LIST_IN_HELM_ENVIRONMENT,
  FETCH_USER_LIST_IN_HELM_ENVIRONMENT_SUCCESS,
  FETCH_USER_LIST_IN_HELM_ENVIRONMENT_FAILURE,
  DELETE_USERS_IN_HELM_ENVIRONMENT,
  DELETE_USERS_IN_HELM_ENVIRONMENT_SUCCESS,
  DELETE_USERS_IN_HELM_ENVIRONMENT_FAILURE,
  EDIT_USER_IN_HELM_ENVIRONMENT,
  EDIT_USER_IN_HELM_ENVIRONMENT_SUCCESS,
  EDIT_USER_IN_HELM_ENVIRONMENT_FAILURE
} from './actions';
import toast from '../../../components/toast/Toast';
import { sessionTokenSelector } from '../../../pages/login/redux/selectors';

function listUsersInProject(sessionToken,projectId) {
  const config = {
   headers : {
     'Content-Type': 'application/json',
     'Authorization': 'basic ' + sessionToken
   }
 }
 return axios.get(endpoints.LIST_USERS_IN_PROJECT.replace(':projectId', projectId.data.projectId) , config)
}

// createProject Axios call
function addUserInProjectCall(sessionToken , payload) {
   const config = {
    headers : {
      'Content-Type': 'application/json',
      'Authorization': 'basic ' + sessionToken
    }
  }
  return axios.post(endpoints.ADD_USERS_IN_PROJECT.replace(':projectId', payload.project_id) , payload , config)
}

function editUserInProjectCall(sessionToken , payload, user_id) {
   const config = {
    headers : {
      'Content-Type': 'application/json',
      'Authorization': 'basic ' + sessionToken
    }
  }
  return axios.put(endpoints.EDIT_USER_IN_PROJECT.replace(':projectId', payload.project_id).replace(':userId', user_id) , payload , config)
}

// createProject Axios call
function deleteUserInProject(sessionToken , payload) {
  const config = {
   headers : {
     'Content-Type': 'application/json',
     'Authorization': 'basic ' + sessionToken
   }
 }
 return axios.delete(endpoints.DELETE_USERS_IN_PROJECT.replace(':projectId', payload.data.payload.project_id).replace(':userId', payload.data.payload.user_id) , config)
}

function listUsersInEnvrionment(sessionToken,payload) {
  const config = {
   headers : {
     'Content-Type': 'application/json',
     'Authorization': 'basic ' + sessionToken
   }
 }
 return axios.get(endpoints.LIST_USERS_IN_ENVIRONMENT.replace(':environmentId', payload.data.environmentId) , config)
}

// createProject Axios call
function addUserInEnvironmentCall(sessionToken , payload) {
   const config = {
    headers : {
      'Content-Type': 'application/json',
      'Authorization': 'basic ' + sessionToken
    }
  }
  return axios.post(endpoints.ADD_USERS_IN_ENVIRONMENT.replace(':environmentId', payload.environment_id) , payload , config)
}

function editUserInEnvironmentCall(sessionToken , payload, user_id) {
   const config = {
    headers : {
      'Content-Type': 'application/json',
      'Authorization': 'basic ' + sessionToken
    }
  }
  return axios.put(endpoints.EDIT_USER_IN_ENV.replace(':environmentId', payload.environment_id).replace(':userId', user_id) , payload , config)
}

// createProject Axios call
function deleteUserInEnvironment(sessionToken , payload) {
  const config = {
   headers : {
     'Content-Type': 'application/json',
     'Authorization': 'basic ' + sessionToken
   }
 }
 return axios.delete(endpoints.DELETE_USERS_IN_ENVIRONMENT.replace(':environmentId', payload.data.payload.environment_id).replace(':userId', payload.data.payload.user_id) , config)
}

// createProject Axios call
function fetchRoles(sessionToken) {
  const config = {
   headers : {
     'Content-Type': 'application/json',
     'Authorization': 'basic ' + sessionToken
   }
 }
 return axios.get(endpoints.FETCH_ROLES_DATA, config)
}

function* fetchRolesGenerator() {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const response = yield call(fetchRoles, sessionToken)
    const data = response.data;
    if(response.status === 200){
      yield put({ type: FETCH_ROLES_SUCCESS, data })
    }else {
      toast.error('error while fetching roles');
    }
  } catch (error) {
    toast.error('error while fetching roles');
  }
}

// Generator Call 
function* addUserInProjectGenerator(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const response = yield call(addUserInProjectCall, sessionToken , payload.data.payload)
    //const data = response.data;
    if (response.status === 201){ 
      const createdPayload = {
        data : {
          projectId : payload.data.payload.project_id
        }
      }
      yield call(listUsersInProjectGenerator, createdPayload)
      yield put({ type: ADD_USER_IN_PROJECT_SUCCESS })
      toast.success('Users Added Succesfully');
    } else {
      yield put({ type: ADD_USER_IN_PROJECT_FAILURE })
      toast.error('Some error while Adding users');
    }
  } catch (error) {
    yield put({ type: ADD_USER_IN_PROJECT_FAILURE })
    if(error && error.response && error.response.data && error.response.data.error)
      toast.error(error.response.data.error);
    else
      toast.error('Some error while adding users');
  }
}

// Generator Call 
function* editUserInProjectGenerator(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const response = yield call(editUserInProjectCall, sessionToken , payload.data.payload, payload.data.user_id)
    //const data = response.data;
    if (response.status === 200){ 
      const createdPayload = {
        data : {
          projectId : payload.data.payload.project_id
        }
      }
      yield call(listUsersInProjectGenerator, createdPayload)
      yield put({ type: EDIT_USER_IN_PROJECT_SUCCESS })
      toast.success('User Role updated Succesfully');
    } else {
      yield put({ type: EDIT_USER_IN_PROJECT_FAILURE })
      toast.error('Some error while Editing users');
    }
  } catch (error) {
    yield put({ type: EDIT_USER_IN_PROJECT_FAILURE })
    if(error && error.response && error.response.data && error.response.data.error)
      toast.error(error.response.data.error);
    else
      toast.error('Some error while editing users');
  }
}

function* addUserInEnvironmentGenerator(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const response = yield call(addUserInEnvironmentCall, sessionToken , payload.data.payload)
    //const data = response.data;
    if (response.status === 201){ 
      
      try {
        const createdPayload = {
          data : {
            environmentId : payload.data.payload.environment_id
          }
        }
        const _response = yield call(listUsersInEnvrionment, sessionToken , createdPayload)
        const data = _response.data
        if(_response.status !== 200){
          toast.error('Some error while fetching users list');
        } else {
          yield put({ type: USERS_LIST_IN_ENVIRONMENT, data })
        } 
      } catch (error){
        toast.error('Some error while fetching users list');
      }
      yield put({ type: ADD_USER_IN_ENVIRONMENT_SUCCESS })
      toast.success('User Added Succesfully');
    } else {
      yield put({ type: ADD_USER_IN_ENVIRONMENT_FAILURE })
      toast.error('Some error while Adding users');
    }
  } catch (error) {
    yield put({ type: ADD_USER_IN_ENVIRONMENT_FAILURE })
    if(error && error.response && error.response.data && error.response.data.error)
      toast.error(error.response.data.error);
    else
      toast.error('Some error while adding user');
  }
}

function* editUserInEnvironmentGenerator(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const response = yield call(editUserInEnvironmentCall, sessionToken , payload.data.payload, payload.data.user_id)
    //const data = response.data;
    if (response.status === 200){ 
      
      try {
        const createdPayload = {
          data : {
            environmentId : payload.data.payload.environment_id
          }
        }
        const _response = yield call(listUsersInEnvrionment, sessionToken , createdPayload)
        const data = _response.data
        if(_response.status !== 200){
          toast.error('Some error while fetching users list');
        } else {
          yield put({ type: USERS_LIST_IN_ENVIRONMENT, data })
        } 
      } catch (error){
        toast.error('Some error while fetching users list');
      }
      yield put({ type: EDIT_USER_IN_ENVIRONMENT_SUCCESS })
      toast.success('User updated Succesfully');
    } else {
      yield put({ type: EDIT_USER_IN_ENVIRONMENT_FAILURE })
      toast.error('Some error while updating users');
    }
  } catch (error) {
    yield put({ type: EDIT_USER_IN_ENVIRONMENT_FAILURE })
    if(error && error.response && error.response.data && error.response.data.error)
      toast.error(error.response.data.error);
    else
      toast.error('Some error while updating user');
  }
}

function* deleteUsersInProjectGenerator(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const response = yield call(deleteUserInProject, sessionToken , payload)

    if(response.status === 200){
      yield put({ type: DELETE_USERS_IN_PROJECT_SUCCESS })
      if(payload.data.isLeave){
        toast.success('Project Leave Successful');
        if(payload.data?.history){
          payload.data.history.push({
            pathname: paths.PROJECTLIST,
          });
        }
      }else{
        toast.success('User Deleted succesfully');
        const createdPayload = {
          data : {
            projectId : payload.data.payload.project_id
          }
        }
        yield call(listUsersInProjectGenerator,createdPayload)
      }
    }else {
      yield put({ type: DELETE_USERS_IN_PROJECT_FAILURE })
      toast.error('Error while deleting user');
    }
  } catch (error) {
    yield put({ type: DELETE_USERS_IN_PROJECT_FAILURE })
    if(error && error.response && error.response.data && error.response.data.error)
      toast.error(error.response.data.error);
    else
      toast.error('error while deleting user');
  }
}

function* deleteUsersInEnvironmentGenerator(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const response = yield call(deleteUserInEnvironment, sessionToken , payload)

    if (response.status === 204 || response.status === 200){
      yield put({ type: DELETE_USERS_IN_ENVIRONMENT_SUCCESS })
      if(payload.data.isLeave){
        toast.success('Environment Leave Successful');
        if(payload.data?.history){
          payload.data.history.push({
            pathname: paths.PROJECTLIST,
          });
        }
      }else{
        toast.success('User Deleted succesfully');
        try {
          const createdPayload = {
            data : {
              environmentId : payload.data.payload.environment_id
            }
          }
          const _response = yield call(listUsersInEnvrionment, sessionToken , createdPayload)
          const data = _response.data
  
          if(_response.status !== 200){
            toast.error('Some error while fetching users list');
          } else {
            yield put({ type: USERS_LIST_IN_ENVIRONMENT, data })
          } 
        } catch (error){
          toast.error('Some error while fetching users list');
        }
      }
    } else {
      yield put({ type: DELETE_USERS_IN_ENVIRONMENT_FAILURE })
      toast.error('Error while deleting user');
    }
  } catch (error) {
    yield put({ type: DELETE_USERS_IN_ENVIRONMENT_FAILURE })

    if(error && error.response && error.response.data && error.response.data.error)
      toast.error(error.response.data.error);
    else
      toast.error('Error while deleting user');
  }
}

// Generator Call 
function* listUsersInProjectGenerator(projectId) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const response = yield call(listUsersInProject, sessionToken , projectId)
    const data = response.data;
    yield put({ type: FETCH_USER_LIST_IN_PROJECT_SUCCESS, data })
  } catch (error) {
    yield put({ type: FETCH_USER_LIST_IN_PROJECT_FAILURE })
    toast.error('Some error while fetching users list In Project');
  }
}

function* fetchUsersInEnvironmentGenerator(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const response = yield call(listUsersInEnvrionment, sessionToken , payload)
    const data = response.data;
    yield put({ type  : USERS_LIST_IN_ENVIRONMENT, data })
  } catch (error) {
    yield put({ type  : FETCH_USER_LIST_IN_ENVIRONMENT_FAILURE })
    toast.error('Some error while fetching users list In Environment');
  }
}

function* fetchUsersInHelmEnvironment(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const config = {
      headers : {
        'Content-Type': 'application/json',
        'Authorization': 'basic ' + sessionToken
      }
    }
    const response = yield axios.get(endpoints.HELMENVIRONMENT.GET_USERS.replace(':id', payload.data.id) , config)
    const data = response.data;
    yield put({ type  : FETCH_USER_LIST_IN_HELM_ENVIRONMENT_SUCCESS, data })
  } catch (error) {
    yield put({ type  : FETCH_USER_LIST_IN_HELM_ENVIRONMENT_FAILURE })
    toast.error('Some error while fetching users list In Environment');
  }
}

function* addUserInHelmEnvironment(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const config = {
      headers : {
        'Content-Type': 'application/json',
        'Authorization': 'basic ' + sessionToken
      }
    }
    const response = yield axios.post(endpoints.HELMENVIRONMENT.ADD_USER.replace(':id', payload.data.payload?.environment_id) , payload.data.payload , config)
    const data = response.data;
    yield put({ type  : ADD_USER_IN_HELM_ENVIRONMENT_SUCCESS, data })
    yield call(fetchUsersInHelmEnvironment, {data: {id : payload.data?.payload?.environment_id}})
  } catch (error) {
    yield put({ type  : ADD_USER_IN_HELM_ENVIRONMENT_FAILURE })
    toast.error('Some error while fetching users list In Environment');
  }
}

function* editUserInHelmEnvironment(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const config = {
      headers : {
        'Content-Type': 'application/json',
        'Authorization': 'basic ' + sessionToken
      }
    }
    const response = yield axios.put(endpoints.HELMENVIRONMENT.EDIT_USER.replace(':id', payload.data.payload?.environment_id).replace(":userId", payload.data.user_id) , payload.data.payload , config)
    const data = response.data;
    yield put({ type  : EDIT_USER_IN_HELM_ENVIRONMENT_SUCCESS, data })
    yield call(fetchUsersInHelmEnvironment, {data: {id : payload.data?.payload?.environment_id}})
  } catch (error) {
    yield put({ type  : EDIT_USER_IN_HELM_ENVIRONMENT_FAILURE })
    toast.error('Some error while editing user.');
  }
}

function* deleteUsersInHelmEnvironment(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const config = {
      headers : {
        'Content-Type': 'application/json',
        'Authorization': 'basic ' + sessionToken
      }
    }
    const response = yield axios.delete(endpoints.HELMENVIRONMENT.DELETE_USER.replace(':id', payload.data?.payload?.environment_id).replace(":userId", payload.data.user_id) , config)
    const data = response.data;
    yield put({ type  : DELETE_USERS_IN_HELM_ENVIRONMENT_SUCCESS, data })
    yield call(fetchUsersInHelmEnvironment, {data: {id : payload.data?.payload?.environment_id}})
  } catch (error) {
    yield put({ type  : DELETE_USERS_IN_HELM_ENVIRONMENT_FAILURE })
    toast.error('Some error while deleting user.');
  }
}

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(ADD_USER_IN_PROJECT, addUserInProjectGenerator);
  yield takeLatest(EDIT_USER_IN_PROJECT, editUserInProjectGenerator);
  yield takeLatest(ADD_USER_IN_ENVIRONMENT, addUserInEnvironmentGenerator);
  yield takeLatest(EDIT_USER_IN_ENVIRONMENT, editUserInEnvironmentGenerator);
  yield takeLatest(FETCH_USER_LIST_IN_PROJECT, listUsersInProjectGenerator);
  yield takeLatest(DELETE_USERS_IN_PROJECT, deleteUsersInProjectGenerator);
  yield takeLatest(FETCH_ROLES , fetchRolesGenerator);
  yield takeLatest(FETCH_USER_LIST_IN_ENVIRONMENT , fetchUsersInEnvironmentGenerator);
  yield takeLatest(DELETE_USERS_IN_ENVIRONMENT , deleteUsersInEnvironmentGenerator);
  yield takeLatest(ADD_USER_IN_HELM_ENVIRONMENT, addUserInHelmEnvironment);
  yield takeLatest(EDIT_USER_IN_HELM_ENVIRONMENT, editUserInHelmEnvironment);
  yield takeLatest(FETCH_USER_LIST_IN_HELM_ENVIRONMENT , fetchUsersInHelmEnvironment);
  yield takeLatest(DELETE_USERS_IN_HELM_ENVIRONMENT , deleteUsersInHelmEnvironment);
}