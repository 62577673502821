import React from "react";
import { ButtonBase } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {useTranslation} from "react-i18next"

type Props = {
  clickHandler: () => void,
  name?: string
}

function BackButton({ clickHandler, name }: Props) {
  const [t] = useTranslation();
  
  return (
    <ButtonBase
      onClick={() => {clickHandler()}}
      style={{ color: "#357dfd", marginBottom: 20 }}
      data-test = "back-btn"
    >
      <ArrowBackIcon fontSize="small" />{" "}
      <span className="jobNav" data-test="back-title">{name ? name : t('App.CreateApp.back')}</span>
    </ButtonBase>
  );
}

export default BackButton;
