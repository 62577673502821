const PREFIX = '@REGISTER'

export const REGISTER = `${ PREFIX }/REGISTER`
export const REGISTER_SUCCESS = `${ PREFIX }/REGISTER_SUCCESS`
export const REGISTER_FAILURE = `${ PREFIX }/REGISTER_FAILURE`
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const OAUTH_REGISTER_DATA = `${ PREFIX }/OAUTH_REGISTER_DATA`
export const VERIFY_EMAIL = `${ PREFIX }/VERIFY_EMAIL`
export const VERIFY_EMAIL_SUCCESS = `${ PREFIX }/VERIFY_EMAIL_SUCCESS`
export const IS_RESEND_VERIFY_EMAIL = `${ PREFIX }/IS_RESEND_VERIFY_EMAIL`

export const REQUEST_INVITE = `${ PREFIX }/REQUEST_INVITE`
export const REQUEST_INVITE_SUCCESS = `${ PREFIX }/REQUEST_INVITE_SUCCESS`
export const REQUEST_INVITE_FAILURE = `${ PREFIX }/REQUEST_INVITE_FAILURE`

export const GET_INVITE_DETAILS = `${ PREFIX }/GET_INVITE_DETAILS`
export const GET_INVITE_DETAILS_SUCCESS = `${ PREFIX }/GET_INVITE_DETAILS_SUCCESS`
export const GET_INVITE_DETAILS_FAILURE = `${ PREFIX }/GET_INVITE_DETAILS_FAILURE`

export const FORGOT_PASSWORD_SUCCESS = `${ PREFIX }/FORGOT_PASSWORD_SUCCESS`;
export const FORGOT_PASSWORD_FAILURE = `${ PREFIX }/FORGOT_PASSWORD_FAILURE`;

const register = (payload , history) => ({
    type: REGISTER,
    data: { payload,history }
})

const requestInvite = (payload , history) => ({
    type: REQUEST_INVITE,
    data: { payload,history }
})

const getInviteDetails = (token) => ({
    type: GET_INVITE_DETAILS,
    data: { token }
})

const forgotPassword = (payload) => ({
    type: FORGOT_PASSWORD,
    data: payload
})

const resetPassword = (payload , history) => ({
    type: RESET_PASSWORD,
    data: { payload, history }
})

const verifyemail = (token) => ({
    type: VERIFY_EMAIL,
    data: { token }
})

export {
    register,
    requestInvite,
    getInviteDetails,
    forgotPassword,
    resetPassword,
    verifyemail
}
