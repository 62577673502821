import { ButtonBase, Grid, Icon, Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import ZoomOutMapOutlinedIcon from "@material-ui/icons/ZoomOutMapOutlined";
import React, { useCallback, useState } from "react";
import ImageViewer from "react-simple-image-viewer";
import styleConstants from "../../theme/styleConstants";
import theme from "../../theme";
// import ImageList from "@material-ui/core/ImageList";
// import ImageListItem from "@material-ui/core/ImageListItem";
import useMediaQuery from "@material-ui/core/useMediaQuery";

type Image = {
  url: string,
  fileValue: string
}

type Props = {
  images: Image[],
  readOnly?: boolean,
  remove?: (image: Image) => void,
}

const useStyles = makeStyles((_theme) =>
  createStyles({
    backdrop: {
      zIndex: _theme.zIndex.appBar + 1,
      color: "#fff",
    },
    previewImage: {
      height: "150",
      width: "100",
      opacity: 0.4,
      // maxHeight: 150,
      // maxWidth: 150,
    },
    root: {
      width: 500,
      height: 450,
    },
    imgageWrap: {
      position: "relative",
    },
    imageDeleteButton: {
      position: "absolute",
      top: 0,
      right: 0,
      zIndex: 100,
    },
    image: {
      position: "relative",
      height: 100,
      [_theme.breakpoints.down("xs")]: {
        width: "100% !important", // Overrides inline-style
        height: 100,
      },
      "&:hover, &$focusVisible": {
        zIndex: 1,
        "& $imageBackdrop": {
          opacity: 0.15,
        },
        // "& $imageMarked": {
        //   opacity: 0,
        // },
        // "& $imageTitle": {
        //   border: "4px solid currentColor",
        // },
      },
    },
    focusVisible: {},
    imageButton: {
      position: "absolute",
      // left: 0,
      right: 2,
      top: 2,
      // bottom: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: _theme.palette.common.white,
    },
    imageButtonCenter: {
      position: "absolute",
      left: 0,
      right: 2,
      top: 2,
      bottom: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: _theme.palette.common.white,
    },
    imageSrc: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundSize: "cover",
      backgroundPosition: "center 40%",
    },
    imageBackdrop: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: _theme.palette.common.black,
      opacity: 0.4,
      transition: _theme.transitions.create("opacity"),
    },
    absolute: {
      position: "absolute",
    },
    removeIcon: {
      position: "absolute",
      top: 2,
      right: 3,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: _theme.palette.common.white,
    },
  })
);

// function srcset(image, size, rows = 1, cols = 1) {
//   return `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format 1x,
//   ${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format&dpr=2 2x`;
// }

export default function ImagePopupModal(props: Props) {
  const classes = useStyles();
  const matches = useMediaQuery((_theme: Theme) => _theme?.breakpoints?.down('sm') ?? "600");

  const [currentImage, setCurrentImage] = useState<number>();
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index: number) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <div className="m-t-10 m-b-10" data-test="main-container">
      <Grid container direction={"row"} spacing={2}>
        {props.images?.map((image, ind) => (
          <Grid
            key={image.url ?? image.fileValue ?? ind}
            item
            sm={6}
            xs={12}
            md={4}
            data-test="images-container"
          >
            <ButtonBase
              focusRipple
              className={classes.image}
              focusVisibleClassName={classes.focusVisible}
              style={{
                width: "100%",
              }}
            >
              <span
                className={classes.imageSrc}
                style={{
                  backgroundImage: `url(${image.url ?? image.fileValue})`,
                }}
                data-test="bg-image-container"
              />
              <span className={classes.imageBackdrop} />
              <span className={classes.imageButtonCenter}>
                <Icon
                  fontSize="small"
                  onClick={() => openImageViewer(ind)}
                  data-test="zoomin-icon"
                >
                  <ZoomOutMapOutlinedIcon />
                </Icon>
              </span>
              {!props.readOnly && (
                <span className={classes.removeIcon}>
                  <Icon
                    fontSize="small"
                    onClick={() => { if (props.remove) props.remove(image); }}
                    data-test="remove-icon"
                  >
                    <CloseOutlinedIcon />
                  </Icon>
                </span>
              )}
            </ButtonBase>
          </Grid>
        ))}
      </Grid>
      {/* <Grid container direction={"row"} spacing={2}>
        {props.images?.map((image, ind) => (
          <Grid key={image.url} item sm={12} xs={12} md={3}>
            <div className="imgageWrap">
              <span className="imageDeleteButton">&times;</span>
              <span
                // src={image.url}
                onClick={() => openImageViewer(ind)}
                style={{
                  backgroundImage: `url(${image.url})`,
                }}
                className={classes.previewImage}
              />
            </div>
          </Grid>
        ))}
      </Grid> */}
      {isViewerOpen && (
        <ImageViewer
          src={props.images?.map((u) => u.url ?? u.fileValue)}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          backgroundStyle={{
            width: matches ? `100%` : `calc(100% - ${styleConstants.drawerMiniWidth}px)`,
            marginTop: `64px`,
            marginLeft: matches ? 0 : `${styleConstants.drawerMiniWidth}px`,
            backgroundColor: "rgba(0,0,0,0.9)",
            zIndex: theme.zIndex.appBar + 1,
          }}
        />
      )}
    </div>
  );
}
