import React, { Component } from "react";
import { connect } from "react-redux";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import {
    clearCIBuildTriggered,
    fetchEnvWorkflowLogByName,
    updateEnvWorkflowLog,
    updateEnvWorkflows,
    updateEnvCILogs
} from "../pages/environment/cicdredux/actions";
import {
    fetchEnvironmentState,
    getAddonStatus,
    getInstalledAddons,
    initiateAddonStatus,
    updateActivityLogs,
    updateAddonStatus,
    updateEnvStatus,
    updatePackageStatus,
    updateDomainStatus,
    updateEnvServiceDetails,
    updateEnvErrStatus
} from "../pages/environment/redux/actions";
import { fetchHelmEnvironmentState, updateHelmEnvStatus, updateHelmActivityLogs, updateHelmServiceDetails } from "../pages/helmenvironment/redux/actions"
import { updateBackupList } from "../pages/backup/redux/actions";
import { sessionTokenSelector } from "../pages/login/redux/selectors";
import { AppConstants } from "../constants/appconstants";

export class WebSocketConnection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ws: null,
            isPendingStatusFetched: false,
            isAddonPendingStatusFetched: false,
        };
    }

    componentDidMount() {
        if (this.props.envId > 0) {
            this.socketConnection(this.props.envId);
            if (
                this.props.environmentDetails?.id > 0 &&
                this.props.environmentDetails?.active
            ) {
                this.props.getInstalledAddons(this.props.environmentDetails.id);
            }
        }
    }

    componentWillUnmount() {
        if (this.state.ws !== null) {
            this.state.ws.close(1000);
        }
    }
    /* istanbul ignore next */
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps) {
            if(!this.props.helm || !nextProps.helm){
                if (nextProps.environmentDetails?.id > 0) {
                    if (
                        !this.props.environmentDetails.id ||
                        this.props.environmentDetails.id !==
                            nextProps.environmentDetails.id
                    ) {
                        if (nextProps.environmentDetails.active) {
                            this.props.getInstalledAddons(
                                nextProps.environmentDetails.id
                            );
                        }
                    }
                }
            }
            if (nextProps.envId > 0) {
                if (
                    this.props.envId === 0 ||
                    this.props.envId !== nextProps.envId
                ) {
                    if (this.state.ws !== null) {
                        this.state.ws.close(1000);
                    }
                    this.setState({ isPendingStatusFetched: false });
                    const $this = this;
                    setTimeout(function () {
                        $this.socketConnection(nextProps.envId);
                    }, 1000);
                }
            }
        }
    }
    
    /* istanbul ignore next */
    socketConnection = (id) => {
        if (this.state.ws !== null) return;
        let $this = this;
        let room = "env-"+id
        if(this.props.helm){
            room = "helm-env-" + id
        }
        const sessionToken = this.props.validSessionId;
        var ws = new W3CWebSocket(
            window?.config?.REACT_APP_SOCKET_IO_ENDPOINT +
                "?token=" +
                sessionToken +
                "&room=" + room
        );
        this.setState({
            ws,
        });
        ws.onopen = () => {
            console.log("WebSocket Client Connected");
            this.setState({ ws: ws });
        };
        ws.onclose = (e) => {
            console.log("WebSocket connection closed");
            this.setState({ ws: null });
            if (e.code !== 1000) this.socketConnection(id);
        };
        ws.onerror = () => {
            console.log("WebSocket error");
            ws.close();
            this.setState({ ws: null });
        };
        ws.addEventListener("message", (response) => {
            if (response.type === "message") {
                const { data } = response;
                if (data) {
                    const _data = JSON.parse(data);
                    if (_data.type === "ci-watcher") {
                        $this.updateSocketMessage(_data);
                    } else if (_data.type === "ci-pipeline-watcher") {
                        $this.updatePipeline(_data);
                    } else if (_data.type === "ci-logs") {
                        //$this.updateCILogMessage_Socket(_data);
                        $this.updateCILog_Socket(_data);
                    } else if (_data.type === "activity-logs") {
                        if (_data.data) {
                            let updatedActivityLogs
                              if(this.props.helm){
                                updatedActivityLogs = [
                                    _data.data,
                                    ...this.props.realTimeHelmActivityLogs
                                ];
                                this.props.updateHelmActivityLogs(updatedActivityLogs);
                              } 
                              else{
                                updatedActivityLogs = [
                                    _data.data,
                                    ...this.props.realTimeActivityLogs,
                                ];
                                this.props.updateActivityLogs(updatedActivityLogs);
                              }
                        }
                        if (_data.message === "Add on uninstalled") {
                            this.props.getAddonStatus(this.props.envId);
                        }
                    } else if (_data.type === "fetch-status") {
                        // if (_data.data) {
                        //     if(_data.data === "Running") {
                        //         this.props.fetchEnvironmentState(this.props.envId);
                        //     }
                        //     else if(_data.data === "Pending") {
                        //         if(!this.state.isPendingStatusFetched) {
                        //             this.setState({ isPendingStatusFetched: true })
                        //             let $this = this;
                        //             setTimeout( function () { $this.props.fetchEnvironmentState($this.props.envId); }, 3000)
                        //         }
                        //     }
                        // }
                        if (_data.name === "env") {
                            if(this.props.helm){
                                const environmentState = this.props.helmEnvironmentState;
                                if(_data.data === AppConstants.EnvironmentStatus.Running
                                    && environmentState?.state !== AppConstants.EnvironmentStatus.Running){
                                    this.props.fetchHelmEnvironmentState(
                                        this.props.envId
                                    );        
                                }
                                this.props.updateHelmEnvStatus(_data.data)
                            }else{
                                const { environmentState } = this.props;
                                if(_data.data === AppConstants.EnvironmentStatus.Running
                                    && environmentState?.state !== AppConstants.EnvironmentStatus.Running){    
                                    this.props.fetchEnvironmentState(
                                        this.props.envId
                                    );       
                                }
                                    this.props.updateEnvStatus(_data.data)
                            }
                            // if (environmentState && environmentState.State) {
                            //     if (environmentState.State !== _data.data) {
                            //         this.props.fetchEnvironmentState(
                            //             this.props.envId
                            //         );
                            //     }
                            // } else {
                            //     this.props.fetchEnvironmentState(
                            //         this.props.envId
                            //     );
                            // }
                            // if(_data.data === "Running") {
                            //         this.props.fetchEnvironmentState(this.props.envId);
                            // }
                            // else if(_data.data === "Pending") {
                            //     if(!this.state.isPendingStatusFetched) {
                            //         this.setState({ isPendingStatusFetched: true })
                            //         let $this = this;
                            //         setTimeout( function () {
                            //                 $this.props.fetchEnvironmentState($this.props.envId);
                            //         }, 3000)
                            //     }
                            // }
                        } else {
                            // const {addonsStatus} = this.props
                            // if(addonsStatus){
                            //     const _addons = addonsStatus?.map(a => {
                            //         if (a.Condition?.length > 0) {
                            //             const addonName = a.Condition[0]?.Name;
                            //             if(addonName === _data.name){
                            //                 return a
                            //             }
                            //         }
                            //     })
                            //     let finalAddonStatus = "Pending"
                            //     let finalAddonStatuses = []
                            //     _addons.forEach(_addon => {
                            //         if(_addon){
                            //             if(_addon.Condition?.length > 0){
                            //                 let addonReady = _addon.Condition[0]?.Ready;
                            //                 if (_addon.Status === "Running" && addonReady) {
                            //                     finalAddonStatus = _addon.Status;
                            //                 }
                            //                 finalAddonStatuses.push(finalAddonStatus)
                            //             }
                            //         }
                            //     })
                            //     if(!finalAddonStatuses.includes(_data.data)){
                            //         this.props.getAddonStatus(this.props.envId)
                            //     }
                            // }else{
                            //     //this.props.initiateAddonStatus(this.props.envId)
                            //     this.props.getAddonStatus(this.props.envId)
                            // }
                            const addonName = _data.name;
                            const _addon = this.props.wsAddonStatus.find(
                                (a) => {
                                    return a.name === addonName;
                                }
                            );
                            const _installedAddon = this.props.installedAddons?.addons?.find(
                                (a) => {
                                    if (a.version?.info) {
                                        return (
                                            addonName ===
                                            a.version?.info[0]?.name
                                        );
                                    }
                                }
                            );
                            if (_addon) {
                                if (_addon.status !== _data.data) {
                                    const addonStatus = this.props.wsAddonStatus.filter(
                                        (_a) => {
                                            if (_a.name === _addon.name) {
                                                _a.status = _data.data;
                                            }
                                            if (_installedAddon) {
                                                return true;
                                            }
                                            return false;
                                        }
                                    );
                                    this.props.updateAddonStatus(addonStatus);
                                }
                            } else {
                                const addonStatus = [
                                    ...this.props.wsAddonStatus,
                                    {
                                        name: addonName,
                                        status: _data.data,
                                    },
                                ];
                                const addonsStatus = addonStatus.filter(
                                    () => {
                                        if (_installedAddon) {
                                            return true;
                                        }
                                        return false;
                                    }
                                );
                                this.props.updateAddonStatus(addonsStatus);
                            }
                        }
                    } else if (_data.type === "backup") {
                        if (_data.data && _data.data.status && this.props.backupList) {
                            let _backupList = [...this.props.backupList];
                            const _index = _backupList.findIndex(
                                (x) => x.snapshot === _data.data.snapshot
                            );
                            if (_index > -1) {
                                if (_backupList[_index]) {
                                    _backupList[_index].status = _data.data.status;
                                    _backupList[_index].duration = _data.data.duration; 
                                    this.props.updateBackupList(_backupList);
                                }
                            }
                        }
                    } else if (_data.type === "package-status"){
                        let _packageStatus = this.props.packageStatus ? {...this.props.packageStatus} : {}
                        _packageStatus[_data.data?.namespace] = _data.data?.status
                        this.props.updatePackageStatus(_packageStatus)

                    } else if(_data.type === "service-detail"){
                        if (this.props.helm)
                            this.props.updateHelmServiceDetails(_data.data);
                        else
                            this.props.updateEnvServiceDetails(_data.data);
                    } else if (_data.type === "domain-status") {
                        this.props.updateDomainStatus(_data.data)
                    }
                    else if(_data.type==="error"){
                        this.props.updateEnvErrStatus(data)
                    }
                }
            }
        });
    };
    
    /* istanbul ignore next */
    updateSocketMessage = (_data) => {
        console.log("ci-watcher");
        const _newWorkflow = {
            // workflow: {
            //     object_meta: {
            //         creationTimestamp: _data.data.workflow.started_at,
            //         name: _data.name,
            //     },
            //     status: {
            //         finished_at: _data.data.workflow.finished_at,
            //         phase: _data.data.workflow.phase,
            //         started_at: _data.data.workflow.started_at,
            //         message: _data.data.workflow.message
            //     },
            // },
            ci_request: _data.data.ci_request,
            log_steps: _data.log_steps,
            pipeline: _data.data.pipeline
        };
        let _envWorkflows = [...this.props.envWorkflows];
        const _workflowIndex = _envWorkflows.findIndex(
            (x) => x.pipeline.runner === _data.name
        );
        if (_workflowIndex > -1) {
            _envWorkflows[_workflowIndex] = _newWorkflow;
            // {
            //     ..._newWorkflow, pipeline: _envWorkflows[_workflowIndex].pipeline
            // };
        } else {
            _envWorkflows.unshift(_newWorkflow);
            this.props.clearCIBuildTriggered();
        }
        this.props.updateEnvWorkflows(_envWorkflows);
        // if (_data.data.workflow.phase === "Succeeded") {
        //     let $this = this;
        //     setTimeout(function () {
        //         $this.props.fetchEnvWorkflowLogByName(
        //             $this.props.envId,
        //             _data.name
        //         );
        //     }, 2000);
        // }
        // if(_data.data.Workflow.phase === "Failed")
        // {
        //     let _envWorkflowLog = [...this.props.envWorkflowLog];
        //     const _workflowLogIndex =  _envWorkflowLog.findIndex(x => x.Name === _data.Name);
        //     if(_workflowLogIndex > -1)
        //     {
        //         if(_envWorkflowLog[_workflowLogIndex] && _envWorkflowLog[_workflowLogIndex].Logs && _envWorkflowLog[_workflowLogIndex].Logs.length > 0)
        //         {
        //             _envWorkflowLog[_workflowLogIndex].Logs.map(item => {
        //                 if(item.step)
        //                 {
        //                     item.Status = "";
        //                 }
        //             });
        //             this.props.updateEnvWorkflowLog(_envWorkflowLog);
        //         }
        //     }
        // }
    };

    updatePipeline = (_data) => {
        console.log("ci-pipeline-watcher");
        let _envWorkflows = [...this.props.envWorkflows];
        const _workflowIndex = _envWorkflows.findIndex(
            (x) => x.pipeline.runner === _data.pipeline?.runner
        );
        if (_workflowIndex > -1) {
            _envWorkflows[_workflowIndex] = {
                ..._envWorkflows[_workflowIndex],
                pipeline: _data.pipeline
            };
        }
        this.props.updateEnvWorkflows(_envWorkflows);
    }

    /* istanbul ignore next */
    updateCILogMessage_Socket = (_data) => {
        console.log("ci-logs");
        let _envWorkflowLog = [...this.props.envWorkflowLog];
        const _workflowLogIndex = _envWorkflowLog.findIndex(
            (x) => x.name === _data.workflow_name
        );
        if (_workflowLogIndex > -1) {
            if (
                _envWorkflowLog[_workflowLogIndex] &&
                _envWorkflowLog[_workflowLogIndex].logs &&
                _envWorkflowLog[_workflowLogIndex].logs.length > 0
            ) {
                // for (let i = 0; i < _envWorkflowLog[_workflowLogIndex].Logs.length; i++) {
                //     if(_envWorkflowLog[_workflowLogIndex].Logs[i].step < _data.step.step )
                //         _envWorkflowLog[_workflowLogIndex].Logs[i].Status = "Succeeded";
                // }
                _envWorkflowLog[_workflowLogIndex].logs.push({
                    log: _data.data,
                    step: _data.step ? _data.step.step : 0,
                    type: _data.step ? _data.step.type : "",
                    //Status: "Running"
                });
            }
        } else {
            let newWorkflowlog = {
                name: _data.workflow_name,
                logs: [
                    {
                        log: _data.data,
                        step: _data.step ? _data.step.step : 0,
                        type: _data.step ? _data.step.type : "",
                        //Status: "Running"
                    },
                ],
            };
            _envWorkflowLog.push(newWorkflowlog);
        }
        this.props.updateEnvWorkflowLog(_envWorkflowLog);
    };

    /* istanbul ignore next */
    updateCILog_Socket = (_data) => {
        console.log("ci-logs");
        let _envCILogs = [...this.props.envCILogs];
        const _workflowLogIndex = _envCILogs.findIndex(
            (x) => x.name === _data.workflow_name && x.task === _data.task_name && x.step === _data.step_name
        );
        if (_workflowLogIndex > -1) {
            // if (_envCILogs[_workflowLogIndex] && _envCILogs[_workflowLogIndex].log) {
                _envCILogs[_workflowLogIndex] = {
                    ..._envCILogs[_workflowLogIndex],
                    log: _envCILogs[_workflowLogIndex].log ? _envCILogs[_workflowLogIndex].log + _data.data : _data.data
                }
            // }
        } else {
            let newWorkflowlog = {
                name: _data.workflow_name,
                task: _data.task_name,
                step: _data.step_name,
                log: _data.data
            };
            _envCILogs.push(newWorkflowlog);
        }
        this.props.updateEnvCILogs(_envCILogs);
    };

    getEmptyDiv = () => {
        return (<div data-test="main"></div>)
    }

    render() {
        /* <div>{this.props.children}</div> */
        return this.getEmptyDiv();
    }
}

/* istanbul ignore next */
const mapStateToProps = (state) => ({
    envWorkflows: state.CICDReducer.envWorkflows,
    envWorkflowLog: state.CICDReducer.envWorkflowLog,
    validSessionId: sessionTokenSelector(state),
    realTimeActivityLogs: state.EnvironmentReducer.realTimeActivityLogs,
    realTimeHelmActivityLogs: state.HelmEnvironmentReducer.realTimeHelmActivityLogs,
    addonsStatus: state.EnvironmentReducer.addonsStatus,
    environmentState: state.EnvironmentReducer.environmentState,
    helmEnvironmentState: state.HelmEnvironmentReducer.helmEnvironmentState,
    wsAddonStatus: state.EnvironmentReducer.wsAddonStatus,
    installedAddons: state.EnvironmentReducer.installedAddons,
    environmentDetails: state.EnvironmentReducer.environmentDetails,
    packageStatus: state.EnvironmentReducer.packageStatus,
    backupList: state.BackupReducer.backupList,
    envCILogs: state.CICDReducer.envCILogs
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch) => {
    return {
        fetchEnvWorkflowLogByName: (id, workflowName) =>
            dispatch(fetchEnvWorkflowLogByName(id, workflowName)),
        updateEnvWorkflowLog: (workflowLogs) =>
            dispatch(updateEnvWorkflowLog(workflowLogs)),
        updateEnvCILogs: (workflowLogs) =>
            dispatch(updateEnvCILogs(workflowLogs)),
        updateEnvWorkflows: (workflows) =>
            dispatch(updateEnvWorkflows(workflows)),
        updateActivityLogs: (data) => dispatch(updateActivityLogs(data)),
        updateHelmActivityLogs: (data) => dispatch(updateHelmActivityLogs(data)),
        clearCIBuildTriggered: () => dispatch(clearCIBuildTriggered()),
        fetchEnvironmentState: (id) => dispatch(fetchEnvironmentState(id)),
        fetchHelmEnvironmentState: (id) => dispatch(fetchHelmEnvironmentState(id)),
        updateHelmServiceDetails: (payload) => dispatch(updateHelmServiceDetails(payload)),
        getAddonStatus: (id) => dispatch(getAddonStatus(id)),
        initiateAddonStatus: (id) => dispatch(initiateAddonStatus(id)),
        updateAddonStatus: (id) => dispatch(updateAddonStatus(id)),
        getInstalledAddons: (id) => dispatch(getInstalledAddons(id)),
        updateBackupList: (jsonBody) => dispatch(updateBackupList(jsonBody)),
        updateEnvStatus: (status) => dispatch(updateEnvStatus(status)),
        updateHelmEnvStatus: (status) => dispatch(updateHelmEnvStatus(status)),
        updatePackageStatus: (status) => dispatch(updatePackageStatus(status)),
        updateDomainStatus: (status) => dispatch(updateDomainStatus(status)),
        updateEnvServiceDetails: (payload) => dispatch(updateEnvServiceDetails(payload)),
        updateEnvErrStatus:(errSts)=>dispatch(updateEnvErrStatus(errSts))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchtoProps
)(WebSocketConnection);