import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import {
  Button,
  CardContent,
  Card,
  CardHeader,
  Divider,
  Table,
  ButtonBase,
  IconButton,
  InputAdornment,
  TableBody,
  //TableCell,
  Paper,
  Checkbox,
  TableHead,
  TableRow,
  TableContainer,
  TextField,
  Typography,
  FormControlLabel,
  Switch,
  Box,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { fetchLoggerConfig, fetchPlugInVersionConfig, getSettingVariableConfig } from "../../redux/actions";
import { compose } from "redux";
import { connect, ConnectedProps } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import WbIncandescentOutlinedIcon from "@material-ui/icons/WbIncandescentOutlined";
import BuildOutlinedIcon from "@material-ui/icons/BuildOutlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import InitContainer from "../../../initcontainer/InitContainer";
import Scripts from "../../../../components/scripts/Scripts";
import { getCronImages } from "../../../cronjob/redux/actions";
import PluginConfigForm from "../../../../components/pluginconfigform/PluginConfigForm";
//import toast from '../../../../components/toast/Toast';
import { withTranslation, WithTranslation } from "react-i18next";
import { GeneralStyledTableCell as StyledTableCell } from "../../../../components/styledtabelcell/StyledTabelCell";
import { CreateApp_TemplateTypes } from "../../../../constants/enums";
import DeploymentStrategy from "../../../../components/cddeployment/DeploymentStrategy";
import BackdropLoader from "../../../../components/loader/BackdropLoader";
import MuiTextField from "../../../../components/textfield/MuiTextField";
import ExternelSecret from "./ExternelSecret";
import ExternalLogger from "./ExternalLogger";
import { Dispatch } from "redux";
import { WithStyles, createStyles } from "@material-ui/core";
import { InitContainerType, ScriptsModel } from "../../../../models/Environment.model";
import  VariablesAdapter  from "../../environmentinfo/cicdtab/variables/variablesAdapter";

/* istanbul ignore next */
const useStyles = () => createStyles({
  gridItem: {
    minWidth: 350,
  },
  actions: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
    marginBottom: 20,
  },
  topmargin: {
    marginTop: 15,
  },
  grid: {
    marginTop: 20,
  },
  left: {
    textAlign: "left",
  },
  disableCard: {
    backgroundColor: "lightgray",
  },
  noCursor: { cursor: "not-allowed" }
});

// const StyledTableCell = withStyles(() => ({
//   head: {

//   },
// }))(TableCell);

type InitContainer = Pick<InitContainerType, "name"|"image"|"command">

type CompleteData = {
  versionId: number,
  service_type: number
}

interface Props extends WithTranslation, PropsFromRedux, WithStyles<typeof useStyles> {
  handleChange:(currentStep: number, data: any)=>void,
  completeData: CompleteData,
  activeStep: number,
  goBack:(activeStep: number)=>void,
}

type State = {
      currentStep: number,
      generalsplugInVersionId: number,
      variables: any[],
      ciVariables:any[],
      selectedInstallationType: number,
      initContainers: InitContainerType[],
      currId: number,
      triggers: any,
      prevent_default_build: boolean,
      systemVariables: {},
      isSystemVariablesError: any[],
      apply_immediately: boolean,
      isError: boolean,
      deploymentErrors: any[],
      deployment_strategy: any,
      scripts: Pick<ScriptsModel, "run"|"build"|"ci_variables">,
      enableSecret:boolean,
      enableLogger: boolean,
      externalSecret:{},
      externalLogger:{},
      settingVariable:any,
}

export class Generals extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      currentStep: 1,
      generalsplugInVersionId: 0,
      // generalsformFields: [],
      // generalsdbFields: [],
      //generalsdbType: "",
      //generalsformData: {},
      variables: [],
      ciVariables:[],
      //editRowId: -1,
      selectedInstallationType: 1,
      //formErrors: {},
      initContainers: [],
      //create: true,
      //currrentInitContainer: null,
      currId: 0,
      triggers: { isPush: false, isTag: false },
      prevent_default_build: false,
      systemVariables: {},
      isSystemVariablesError: [],
      apply_immediately: false,
      isError: false,
      deploymentErrors: [],
      deployment_strategy: {},
      scripts: {
        run: "",
        build: "",
      },
      enableSecret:false,
      enableLogger: false,
      externalSecret:{},
      externalLogger:{},
      settingVariable:{},
    };
  }

  componentDidMount() {
    this.props.getCronImages();
    this.props.fetchLoggerConfigDetails()
  }

  updatedExternalSecret = (updatedState: any) => {
    this.setState({externalSecret:{...updatedState}})
  }

  updatedExternalLogger = (updatedState: any) => {
    this.setState({externalLogger:{...updatedState}})
  }

  handleContinue = () => {
    let {
      variables,
      selectedInstallationType,
      //generalsformData,
      initContainers,
      triggers,
      prevent_default_build,
      apply_immediately,
      deployment_strategy,
      scripts,
      externalSecret,
      externalLogger,ciVariables
    } = this.state;
  
    let external_secret = this.state.enableSecret ? { ...externalSecret } : null;
    let external_logger = this.state.enableLogger ? { ...externalLogger } : null;
    
    let _user_variables: any = [];
    variables.map((v: any) => {
      if (v.id !== -1) {
        let _variable: any = {};
        _variable.key = v.key;
        _variable.value = v.value;
        _variable.type = v.isProtected ? "secret" : "normal";
        _user_variables.push(_variable);
      }
    });

    let ci_varaiables:any={};
    ciVariables.map((v:any)=>{
  ci_varaiables[v.key]=v.value
     
    })
    scripts.ci_variables=ci_varaiables
   
    let deploymentStrategy = deployment_strategy;
    if (this.state.selectedInstallationType === 1) {
      deploymentStrategy = {
        rollingUpdate: {
          maxSurge: 1,
        },
      };
    }
    
    let finalInitContainers: InitContainer[] = [];
    initContainers.forEach((i: InitContainerType) => {
      if (i.active) {
        const _initCont: InitContainer = {
          name: i.name,
          image: i.image,
          command: i.command,
        };
        finalInitContainers.push(_initCont);
      }
    });
    
    const data = {
      variables: { ...this.state.systemVariables },
      user_variables: _user_variables,
      selectedInstallationType,
      generalsformData: this.state.systemVariables,
      InitContainers: finalInitContainers,
      triggers,
      deployment_strategy: deploymentStrategy,
      prevent_default_build,
      apply_immediately,
      scripts,
      external_secret,
      external_logger,
      setting: this.state.settingVariable,
    };
    this.props.handleChange(this.state.currentStep + 1, data);
  };

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps) {
      if (
        nextProps.completeData &&
        nextProps.completeData.versionId > 0 &&
        nextProps.completeData.versionId !== this.state.generalsplugInVersionId
      ) {
        this.setState({
          generalsplugInVersionId: nextProps.completeData.versionId,
        });
        this.props.fetchPlugInVersionConfig(nextProps.completeData.versionId);
        this.props.getSettingVariableConfig(nextProps.completeData.versionId)
      }
    }
  }

  getEmptyRowObject() {
    return {
      id: -1,
      key: "",
      value: "",
    };
  }

  addNew = () => {
    const { variables } = this.state;
    variables.push(this.getEmptyRowObject());
    this.setState({
      variables: variables,
    });
  };

  handleKeyChange(e: React.ChangeEvent<{ value: string, name: string }>) {
    const currentId = !isNaN(parseInt(e.target.name))
      ? parseInt(e.target.name)
      : e.target.name;
    const value = e.target.value;
    const { variables } = this.state;

    if (parseInt(currentId.toString()) > -1) {
      let exists = "";
      if (value !== "") {
        exists = variables.find(
          (variable) => variable.key.toUpperCase() === value.toUpperCase()
        );
      }
      let foundIndex = variables.findIndex((x) => x.id === currentId);
      if (foundIndex > -1) {
        variables[foundIndex].key = value;
        variables[foundIndex].isError = !/^[A-Za-z0-9_-]*$/.test(value)
          ? this.props.t("AlphaNumericError")
          : "";

        if (exists) {
          variables[foundIndex].isError = exists
            ? this.props.t("DuplicateError")
            : "";
        }
        if (variables[foundIndex].isError?.length > 0) {
          this.setState({
            isError: true,
          });
        } else {
          this.setState({
            isError: false,
          });
        }
      }
    } else {
      const currentLength = variables.length;
      let foundIndex = variables.findIndex((x) => x.id === -1);
      variables[foundIndex].id = currentLength;
      variables[foundIndex].key = value;
      variables[foundIndex].isError = !/^[A-Za-z0-9_-]*$/.test(value)
        ? this.props.t("AlphaNumericError")
        : "";

      if (variables[foundIndex].isError?.length > 0) {
        this.setState({
          isError: true,
        });
      } else {
        this.setState({
          isError: false,
        });
      }
      // variables.push(this.getEmptyRowObject());
    }

    this.setState({
      variables,
    });
  }

  handleValueChange(e: React.ChangeEvent<{ value: string, name: string }>) {
    const currentId = !isNaN(parseInt(e.target.name))
      ? parseInt(e.target.name)
      : e.target.name;
    const value = e.target.value;
    const { variables } = this.state;
    if (parseInt(currentId.toString()) > -1) {
      let foundIndex = variables.findIndex((x) => x.id === currentId);
      if (foundIndex > -1) {
        variables[foundIndex].value = value;
      }
    } else {
      const currentLength = variables.length;

      let foundIndex = variables.findIndex((x) => x.id === -1);
      variables[foundIndex].id = currentLength;
      variables[foundIndex].value = value;

      // variables.push(this.getEmptyRowObject());
    }

    this.setState({
      variables,
    });
  }

  handleRemoveUserVariable(index: number) {
    const { variables } = this.state;
    variables.splice(index, 1);
    this.setState({
      variables,
    });
  }

  handleProtected(e: React.ChangeEvent<{ checked: unknown }>, index: number) {
    const { variables } = this.state;
    if (variables[index]) {
      variables[index].isProtected = e.target.checked;
      variables[index].isShowPassword = false;
    }
    this.setState({
      variables,
    });
  }

  handleShowPassword_UV(index: number) {
    const { variables } = this.state;

    if (variables[index]) {
      const _isShowPassword = variables[index].isShowPassword;
      variables[index].isShowPassword = !_isShowPassword;
    }
    this.setState({
      variables,
    });
  }

  installationTypeChangeHandler = (value: number) => {
    this.setState(
      {
        selectedInstallationType: value,
        enableSecret: false,
        enableLogger: false
      },
      () => {
        if (this.state.selectedInstallationType === 1) {
          this.setState({
            deployment_strategy: null,
          });
        }
      }
    );
  };

  initContainerChange = (initContainers:InitContainerType[]) => {
    this.setState({
      initContainers,
    });
  };

  scriptsChange = (scripts: ScriptsModel) => {
    this.setState({
      scripts,
    });
  };

  handleTrigger = (e: React.ChangeEvent<{ name: string, checked: boolean }>) => {
    const { triggers } = this.state;
    triggers[e.target.name] = e.target.checked;
    this.setState({
      triggers,
    });
  };

  handlePreventBuild = (e: React.ChangeEvent<{ checked: boolean }>) => {
    this.setState({
      prevent_default_build: e.target.checked,
    });
  };

  handleApplyImmediately = (e: React.ChangeEvent<{ checked: boolean }>) => {
    this.setState({
      apply_immediately: e.target.checked,
    });
  };

  handleSystemVariablesChange = (variables: any, errorsList: any) => {
    this.setState({
      systemVariables: variables,
      isSystemVariablesError: errorsList,
    });
  };

  handleDeploymentStrategy = (payload: any, deploymentType: any, err: any) => {
    this.setState({
      deployment_strategy: deploymentType
        ? { [deploymentType]: payload }
        : null,
      deploymentErrors: deploymentType ? err : [],
    });
  };

  handleApplyExternelSecret = (e: React.ChangeEvent<{ checked: boolean }>) => {
    this.setState({
        enableSecret:e.target.checked
    });
    // if(e.target.checked === false){
    //   this.updatedExternalSecret({});
    // }
  };

  handleApplyExternelLogger = (e: React.ChangeEvent<{ checked: boolean }>) => {
    this.setState({
        enableLogger:e.target.checked
    });
    // if(e.target.checked === false){
    //   this.updatedExternalLogger({});
    // }
  };

  handleSettingVariablesChange = (variables: any, errorsList: any) => {
    this.setState({
      settingVariable: variables,
      isSystemVariablesError: errorsList,
    });
  };


  syncCIVars=(ciVars:any[])=>{
this.setState({ciVariables:ciVars})
  }
  render() {
    const { classes, completeData, t } = this.props;
    const { variables, externalSecret, enableSecret, externalLogger, enableLogger } = this.state;
    if (this.props.activeStep !== this.state.currentStep) {
      return null;
    }
    return (
      <div data-test="main-container">
        <Grid>
          <Grid container spacing={4}>
            <Grid
              item
              lg={4}
              className={
                this.props.completeData.service_type > 0 ? classes.noCursor : ""
              }
            >
              <ButtonBase
                className="w-100"
                onClick={() => this.installationTypeChangeHandler(1)}
                data-test="quick-card"
              >
                <Card className={`w-100`}>
                  <CardContent>
                    <Grid container spacing={4}>
                      <Grid item lg={2}>
                        <WbIncandescentOutlinedIcon />
                      </Grid>
                      <Grid item lg={8}>
                        <Typography className={classes.left}>
                          {t("Environment.Generals.quickInstallation")}
                        </Typography>
                      </Grid>
                      <Grid item lg={2}>
                        {this.state.selectedInstallationType === 1 && (
                          <CheckCircleIcon color="primary" />
                        )}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </ButtonBase>
            </Grid>
            <Grid item lg={4}>
              <ButtonBase
                className="w-100"
                onClick={() => this.installationTypeChangeHandler(2)}
                data-test="custom-card"
              >
                <Card className="w-100">
                  <CardContent>
                    <Grid container spacing={4}>
                      <Grid item lg={2}>
                        <BuildOutlinedIcon />
                      </Grid>
                      <Grid item lg={8}>
                        <Typography className={classes.left}>
                          {t("Environment.Generals.customInstallation")}
                        </Typography>
                      </Grid>
                      <Grid item lg={2}>
                        {this.state.selectedInstallationType === 2 && (
                          <CheckCircleIcon color="primary" />
                        )}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </ButtonBase>
            </Grid>
          </Grid>
        </Grid>

        {this.state.selectedInstallationType === 2 && (
          <Card className="m-t-20" data-test="system-const-card">
            <Box display="flex" flexDirection="row" justifyContent="space-between" m={1}>
              <Box>
                <CardHeader title="System Constants" />
              </Box>
              {(completeData.service_type === 0 || completeData.service_type === CreateApp_TemplateTypes.manageDB) && (
                <Box>
                  <FormControlLabel
                    control={
                      <Switch name="exyternel_secret" color="primary"
                        checked={enableSecret}
                        onChange={this.handleApplyExternelSecret}
                      />
                    }
                    label="Externel Secrets"
                  />
                </Box>
                )}
            </Box>

            <Divider />
            <CardContent>
              <Grid container spacing={2} className={classes.grid}>
                <>
                  {enableSecret === true && (completeData.service_type === 0 || completeData.service_type === CreateApp_TemplateTypes.manageDB)  ? (
                    <ExternelSecret 
                    externalSecret={externalSecret} 
                    enableSecret={enableSecret}
                    updatedExternalSecret={this.updatedExternalSecret}
                    />
                  ) : (
                    <PluginConfigForm
                      pluginVersionConfig={this.props.pluginVersionConfig}
                      handleValueChange={this.handleSystemVariablesChange}
                      initialValues={this.state.systemVariables}
                      isErrors={this.state.isSystemVariablesError}
                      gridItemOccupency={4}
                      replicationNotRequired = {true}
                    />
                  )}
                </>
              </Grid>
            </CardContent>
          </Card>
        )}

        { this.state.selectedInstallationType === 2 && 
          <Card className="m-t-20" data-test="system-var-card">
            <Box>
              <CardHeader title="System Variables" />
            </Box>

          <Divider />
          <CardContent>
          {
            this.props.settingConfig && this.props.settingConfig.setting && Object.keys(this.props.settingConfig.setting?.properties).length > 0 ?
            <Grid container spacing={2} className={classes.grid}>
              <>  
                <PluginConfigForm
                pluginVersionConfig={this.props.settingConfig.setting}
                handleValueChange={this.handleSettingVariablesChange}
                initialValues={this.state.settingVariable}
                isErrors={this.state.isSystemVariablesError}
                gridItemOccupency={4}
                />
              </>
            </Grid>:
            <Grid container justifyContent="center">
              <Grid item>
                <Typography variant="h5">No system variables available</Typography>
              </Grid>
            </Grid>
          }
          </CardContent>
        </Card>
        }

        {this.state.selectedInstallationType === 2 &&
          completeData.service_type > 0 && completeData.service_type !== CreateApp_TemplateTypes.manageDB && (
            <>
              <Card className={classes.topmargin} data-test="env-var-card">
                <Box display="flex" flexDirection="row" justifyContent="space-between" m={1} p={1}>
                  <Box>
                    <CardHeader
                      title={t("Environment.Generals.environmentVariables")}
                      data-test="env-var-cardHeader"
                    />
                  </Box>
                  <Box>
                    <FormControlLabel
                      control={
                        <Switch name="exyternel_secret" color="primary"
                          checked={enableSecret}
                          onChange={this.handleApplyExternelSecret}
                        />
                      }
                      label="Externel Secrets"
                    />
                  </Box>
                </Box>

                <CardContent>
                  {enableSecret === true ? (
                    <ExternelSecret 
                    externalSecret={externalSecret} 
                    enableSecret={enableSecret}
                    updatedExternalSecret={this.updatedExternalSecret}
                    />
                  ) : (
                    <>
                      <Box display="flex" flexDirection="row" justifyContent="space-between">
                        <Box>
                          <FormControlLabel
                            data-test="apply-switch"
                            control={
                              <Switch name="apply_immediately" color="primary"
                                checked={this.state.apply_immediately}
                                onChange={(e: React.ChangeEvent<{ checked: boolean }>) => this.handleApplyImmediately(e)}
                              />
                            }
                            label={t("Environment.Generals.applyImmediately")}
                          />
                        </Box>
                        <Box>
                          <Button
                            variant="contained"
                            disabled={Boolean(
                              this.state.variables?.find((a) => a.id === -1)
                            )}
                            color="primary"
                            onClick={() => this.addNew()}
                          >
                            {" "}
                            {t("Environment.Generals.addVariable")}
                          </Button>
                        </Box>
                      </Box>
                      <TableContainer
                        component={Paper}
                        className={classes.topmargin}
                      >
                        <Table data-test="env-var-table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>
                                {t("Environment.Generals.userVariable")}
                              </StyledTableCell>
                              <StyledTableCell>
                                {t("Environment.Generals.value")}
                              </StyledTableCell>
                              <StyledTableCell>
                                {t("Environment.Generals.secret")}
                              </StyledTableCell>
                              <StyledTableCell>
                                {t("Environment.Generals.actions")}
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {variables.map((item, index) => {
                              return (
                                <TableRow key={index}>
                                  <StyledTableCell component="th" scope="row">
                                    <TextField
                                      data-test="env-var-key"
                                      value={item.key}
                                      variant="outlined"
                                      fullWidth
                                      name={item.id}
                                      error={item.isError}
                                      helperText={item.isError}
                                      onChange={(e: React.ChangeEvent<{ value: string, name: string }>) => this.handleKeyChange(e)}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell component="th" scope="row">
                                    <MuiTextField
                                      data-test="env-var-value"
                                      value={item.value}
                                      name={item.id}
                                      onChange={(e: React.ChangeEvent<{ value: string, name: string }>) =>
                                        this.handleValueChange(e)
                                      }
                                      type={
                                        item.isProtected
                                          ? item.isShowPassword
                                            ? "text"
                                            : "password"
                                          : "text"
                                      }
                                      InputProps={{
                                        endAdornment: item.isProtected && (
                                          <InputAdornment position="end">
                                            <IconButton
                                              aria-label="toggle password visibility"
                                              onClick={() =>
                                                this.handleShowPassword_UV(
                                                  index
                                                )
                                              }
                                              edge="end"
                                              data-test="password-visibility-toggle"
                                            >
                                              {item.isShowPassword ? (
                                                <Visibility />
                                              ) : (
                                                <VisibilityOff />
                                              )}
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell component="th" scope="row">
                                    <Checkbox
                                      color="primary"
                                      inputProps={{
                                        "aria-label": "secondary checkbox",
                                      }}
                                      name={item.id}
                                      onChange={(e: React.ChangeEvent<{ checked: unknown }>) =>
                                        this.handleProtected(e, index)
                                      }
                                      data-test="var-protected-toggle"
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell component="th" scope="row">
                                    {index !== variables.length - 1 && (
                                      <div>
                                        <CloseIcon
                                          onClick={() =>
                                            this.handleRemoveUserVariable(index)
                                          }
                                          data-test="remove-user-var"
                                        />
                                      </div>
                                    )}
                                  </StyledTableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  )}
                </CardContent>
              </Card>
            {completeData.service_type === CreateApp_TemplateTypes.git &&
              <VariablesAdapter actionType={"create"} syncCIVars={this.syncCIVars} environmentDetails={null} />}
              {completeData.service_type === CreateApp_TemplateTypes.git && (
                <Card className={classes.topmargin} data-test="triggers-card">
                  <CardHeader title={t("Environment.Generals.triggers")} />
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item md={12} sm={6} xs={12}>
                        <FormControlLabel
                          data-test="prevent-build-switch"
                          control={
                            <Switch
                              name="prevent_default_build"
                              color="primary"
                              checked={this.state.prevent_default_build}
                              onChange={this.handlePreventBuild}
                              data-test="prevent-build-switch-field"
                            />
                          }
                          label={t("Environment.Generals.preventBuild")}
                        />
                      </Grid>
                      <Grid item md={12} sm={6} xs={12}>
                        <FormControlLabel
                          data-test="push-switch"
                          control={
                            <Switch
                              name="isPush"
                              color="primary"
                              checked={this.state.triggers.isPush}
                              onChange={(e: React.ChangeEvent<{ name: string, checked: boolean }>) => this.handleTrigger(e)}
                              data-test="push-switch-field"
                            />
                          }
                          label={t("Environment.Generals.push")}
                        />
                      </Grid>
                      <Grid item md={12} sm={6} xs={12}>
                        <FormControlLabel
                          data-test="tag-switch"
                          control={
                            <Switch
                              name="isTag"
                              color="primary"
                              checked={this.state.triggers.isTag}
                              onChange={this.handleTrigger}
                            />
                          }
                          label={t("Environment.Generals.tag")}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              )}
              {this.state.selectedInstallationType === 2 &&
                (completeData.service_type === CreateApp_TemplateTypes.git ||
                  completeData.service_type === CreateApp_TemplateTypes.cr) && (
                  <Card className="m-t-20">
                    <CardHeader title="Deployment Strategy" />
                    <Divider />
                    <CardContent>
                      <DeploymentStrategy
                        handleChange={this.handleDeploymentStrategy}
                      />
                    </CardContent>
                  </Card>
                )}

              {completeData.service_type === CreateApp_TemplateTypes.git && (
                <div className={classes.topmargin}>
                  <Scripts
                    source={1}
                    onChange={this.scriptsChange}
                    data-test="env-scripts"
                  />
                </div>
              )}
              <div className={classes.topmargin}>
                <InitContainer
                  data-test="init-container"
                  source={1}
                  handleChange={this.initContainerChange}
                  initContainersPrev={this.state.initContainers}
                />
              </div>
            </>
          )}

          {
            this.state.selectedInstallationType === 2 && (
              <Card className="m-t-20" data-test="system-var-card">
                <Box display="flex" flexDirection="row" justifyContent="space-between" m={1}>
                  <Box>
                    <CardHeader title="External Logger" />
                  </Box>
                  {/* {completeData.service_type === 0 && ( */}
                    <Box>
                      <FormControlLabel
                    control={<Switch name="external_secret" color="primary"
                      checked={enableLogger}
                      onChange={this.handleApplyExternelLogger} />} 
                      label={undefined}                      />
                    </Box>
                    {/* )} */}
                </Box>
    
                <Divider />
                {enableLogger &&
                <CardContent>
                {/* <Grid container spacing={2} className={classes.grid}> */}
                  <ExternalLogger
                    externalLogger={externalLogger} 
                    enableLogger={enableLogger}
                    updatedExternalLogger={this.updatedExternalLogger}
                    loggerConfig={this.props.loggerConfig}
                  />
                {/* </Grid> */}
                </CardContent>
                }
              </Card>
            )
          }
          
        <div className={classes.actions}>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="center"
            alignItems="center"
          >
            {completeData.service_type !== CreateApp_TemplateTypes.cr && (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  // className={classes.continueButton}
                  onClick={() => this.props.goBack(this.props.activeStep - 1)}
                  data-test="back-btn"
                >
                  Back
                </Button>
              </Grid>
            )}

            <Grid item>
              <Button
                data-test="continue-btn"
                variant="contained"
                color="primary"
                onClick={() => this.handleContinue()}
                disabled={
                  this.state.isError ||
                  Boolean(this.state.isSystemVariablesError.length) ||
                  Boolean(this.state.deploymentErrors?.length) 
                  // ||((enableSecret && externalSecret?.enabled) && (!externalSecret.secrets[0]?.secretName))
                }
              >
                {t("Environment.CreateAppMaster.continue")}
              </Button>
            </Grid>
          </Grid>
        </div>

        {this.props.fetchingPluginVersionConfig && (
          <BackdropLoader
            message={t(
              "Environment.CreateAppMaster.fetchingPluginVersionConfig"
            )}
            data-test="fetchingPluginVersionConfig-loader"
          />
        )}
      </div>
    );
  }
}

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    fetchPlugInVersionConfig: (id: number) => dispatch(fetchPlugInVersionConfig(id)),
    getCronImages: () => dispatch(getCronImages()),
    getSettingVariableConfig: (pid: number) => dispatch(getSettingVariableConfig(pid)),    
    fetchLoggerConfigDetails: () => dispatch(fetchLoggerConfig())
  };
};

/* istanbul ignore next */
const mapStateToProps = (state: any) => {
  return {
    pluginVersionConfig: state.EnvironmentReducer.pluginVersionConfig,
    fetchingPluginVersionConfig:
      state.EnvironmentReducer.fetchingPluginVersionConfig,
    initImages: state.CronJobReducer.cronImages,
    settingConfig: state.EnvironmentReducer.settingConfig,
    loggerConfig: state.EnvironmentReducer.loggerConfig,
  };
};

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(compose(withStyles)(useStyles)(withTranslation()(Generals)));