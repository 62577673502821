import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import HeadsetMicIcon from "@material-ui/icons/HeadsetMic";
import { Divider } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ReceiptIcon from "@material-ui/icons/Receipt";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ListItemText from "@material-ui/core/ListItemText";
import { UserModel } from "../../models/Account.model";
import { useTranslation } from "react-i18next";
import { UserAvatar } from "../userAvatar/UserAvatar";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 280,
      boxShadow:'none'
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    userData: {
      display: "flex",
      alignItems: "center",
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
      cursor: "pointer",
    },
    avatarContainer: { flex: 0.2, paddingLeft: theme.spacing(2) },
    userDetailsContainer: {
      flex: 1,
      paddingLeft: theme.spacing(2),
      height: theme.spacing(6),
      paddingRight: theme.spacing(2),
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    vFlex: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      justifyContent: "space-around",
    },

    listRoot: {
      width: "100%",
      paddingLeft: theme.spacing(1),
    },
    logout: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      cursor: "pointer",
    },
    
  })
);

interface Props {
  handleClick: (modulePath: string) => void;
  userDetails: UserModel;
  handleLogoutClick: () => void;
  handleDocsNav: (url: string) => void;
}
export const ProfileMenu = ({
  handleClick,
  userDetails,
  handleLogoutClick,
  handleDocsNav
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const handleNavClick = (moduleName: string) => () => {
    handleClick(moduleName);
  };

  return (
    <Card className={classes.root} elevation={0}>
      <div className={classes.userData} onClick={handleNavClick("/account")}>
        <span className={classes.avatarContainer}>
          <UserAvatar userDetails={userDetails} />
        </span>
        <span className={classes.userDetailsContainer}>
          <div className={classes.vFlex}>
            <Typography
              display="block"
              variant="subtitle1"
              color="textPrimary"
              noWrap={true}
            >
              {userDetails
                ? userDetails.first_name + " " + userDetails.last_name
                : ""}{" "}
            </Typography>

            <Typography  display="block" noWrap={true} variant="body1" color="textPrimary" component="p">
              {userDetails && userDetails.email ? userDetails.email : ""}
            </Typography>
          </div>
        </span>
      </div>

      <Divider />

      <div className={classes.listRoot}>
        <List  component={"nav"}>
          <ListItem onClick={handleNavClick("/tickets")} button>
            <ListItemIcon>
              <HeadsetMicIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant="subtitle1"
                  color="textPrimary"
                  component="p"
                >
                  {t("Header.HeaderPage.support")}
                </Typography>
              }
            />
          </ListItem>
          <ListItem onClick={handleNavClick("/account#billing")} button>
            <ListItemIcon>
              <ReceiptIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant="subtitle1"
                  color="textPrimary"
                  component="p"
                >
                  {t("Billing.BillingTab")}
                </Typography>
              }
            />
          </ListItem>
          <ListItem onClick={()=>handleDocsNav(window?.config?.REACT_APP_01CLOUD_DOCS)} button>
            <ListItemIcon>
              <HelpOutlineIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant="subtitle1"
                  color="textPrimary"
                  component="p"
                >
                  Help
                </Typography>
              }
            />
          </ListItem>
          <ListItem onClick={()=>handleDocsNav(`${window?.config?.REACT_APP_01CLOUD_DOCS}/faq`)} button>
            <ListItemIcon>
              <LiveHelpIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant="subtitle1"
                  color="textPrimary"
                  component="p"
                >
                  FAQ
                </Typography>
              }
            />
          </ListItem>
        </List>
      </div>
      <Divider />
      <div className={classes.listRoot}>
        <List component={"nav"}>
          <ListItem onClick={handleLogoutClick} button>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant="subtitle1"
                  color="textPrimary"
                  component="p"
                >
                  {t("Header.HeaderPage.logout")}
                </Typography>
              }
            />
          </ListItem>
        </List>
      </div>
    </Card>
  );
};
