import { 
  FETCH_PROJECT_DETAIL,
  FETCH_PROJECT_DETAIL_SUCCESS,
  FETCH_PROJECT_DETAIL_FAILURE,
  FETCH_PROJECT_APPLIST,
  FETCH_PROJECT_APPLIST_SUCCESS,
  FETCH_PROJECT_APPLIST_CLEAR,
  UPDATE_BREADCRUMB,
  FETCH_PROJECT_ACTIVITIES,
  FETCH_PROJECT_ACTIVITIES_SUCCESS,
  CLEAR_PROJECT_ACTIVITIES,
  FETCH_PROJECT_INSIGHT_SUCCESS,
  SET_CURRENT_PROJECT,
  FETCH_ROLE_PROJECT_SUCCESS,
  EDIT_PROJECT_CALL,
  EDIT_PROJECT_CALL_SUCCESS,
  EDIT_PROJECT_CALL_FAILURE,
  DELETE_PROJECT_CALL,
  DELETE_PROJECT_CALL_FAILURE,
  DELETE_PROJECT_CALL_SUCCESS,
  GET_SIDEBAR_PROJECT_DETAILS,
  GET_SIDEBAR_PROJECT_DETAILS_SUCCESS,
  FETCH_PROJECT_ACTIVITIES_FAILURE,
  FETCH_PROJECT_APPLIST_FAILURE,
  CLEAR_PROJECT_DETAILS,
  FETCH_PROJECT_USED_RESOURCES,
  FETCH_PROJECT_USED_RESOURCES_SUCCESS,
  FETCH_PROJECT_USED_RESOURCES_FAILURE,
  FETCH_LOADBALANCER,
  FETCH_LOADBALANCER_FAILURE,
  FETCH_LOADBALANCER_SUCCESS,
  FETCH_PROJECT_LOADBALANCER,
  FETCH_PROJECT_LOADBALANCER_FAILURE, 
  FETCH_PROJECT_LOADBALANCER_SUCCESS,
  CREATE_PROJECT_LOADBALANCER,
  CREATE_PROJECT_LOADBALANCER_FAILURE,
  CREATE_PROJECT_LOADBALANCER_SUCCESS,
  DELETE_PROJECT_LOADBALANCER,
  DELETE_PROJECT_LOADBALANCER_FAILURE,
  DELETE_PROJECT_LOADBALANCER_SUCCESS,
  CLEAR_LOADBALANCER_INFO,
  FETCH_LOADBALANCER_STATUS,
  FETCH_LOADBALANCER_STATUS_SUCCESS,
  FETCH_LOADBALANCER_STATUS_FAILURE,
  CLEAR_PROJECT_USED_RESOURCES,
  CLEAR_LOADBALANCER_DATA,
  CLEAR_FETCHING_USED_RESOURCES,
  DEACTIVATE_PROJECT_CALL,
  DEACTIVATE_PROJECT_CALL_SUCCESS,
  DEACTIVATE_PROJECT_CALL_FAILURE,
  DEACTIVATE_PROJECT_CALL_CLEANUP
} from './actions';
import initialState from './initialState';

const reducer = (state = initialState, payload ) => {
  switch (payload.type) {
    case FETCH_PROJECT_DETAIL:
      return {
        ...state,
        fetchingProjectDetails: true
      }
    case FETCH_PROJECT_DETAIL_SUCCESS:
      return {
        ...state,
        projectDetails : payload.data,
        isDataNotFound: false,
        fetchingProjectDetails: false

    }
    case FETCH_PROJECT_DETAIL_FAILURE:
      return{
        ...state,
        isDataNotFound: true,
        fetchingProjectDetails: false
    }
    case FETCH_PROJECT_APPLIST:
      return {
        ...state,
        // projectDetails : payload.data,
        fetchingProjectAppList: true
    }
    case FETCH_PROJECT_APPLIST_SUCCESS:
      return {
        ...state,
        appList : payload.data,
        fetchingProjectAppList :  false
    }

    case FETCH_PROJECT_APPLIST_CLEAR:
      return {
        ...state,
        appList : initialState.appList,
    }

    case FETCH_PROJECT_APPLIST_FAILURE:
      return {
        ...state,
        fetchingProjectAppList :  false
    }
    case UPDATE_BREADCRUMB: 
      return {
        ...state,
        breadcrumbData : payload.data.payload
      }
    case FETCH_PROJECT_ACTIVITIES:
      return {
        ...state,
        activityLoading: true,
    }
    case FETCH_PROJECT_ACTIVITIES_SUCCESS:
      return {
        ...state,
        activityLoading: false,
        projectActivities : [...state.projectActivities, ...payload.data]
    }
    case FETCH_PROJECT_ACTIVITIES_FAILURE:
      return {
        ...state,
        activityLoading: false,
    }
    case CLEAR_PROJECT_ACTIVITIES:
      return {
        ...state,
        projectActivities: []
    } 
    case FETCH_PROJECT_INSIGHT_SUCCESS:
      return {
        ...state,
        projectInsights : payload.data
    }
    case SET_CURRENT_PROJECT:
      return {
        ...state,
        currentProject : payload.data.id
    } 
    case FETCH_ROLE_PROJECT_SUCCESS:
      return {
        ...state,
        projectRole : payload.data
    } 
    case EDIT_PROJECT_CALL:
      return {
        ...state,
        editingProject : true
    } 
    case EDIT_PROJECT_CALL_SUCCESS:
      return {
        ...state,
        editingProject : false
    } 
    case EDIT_PROJECT_CALL_FAILURE:
      return {
        ...state,
        editingProject : false
    }
    case DELETE_PROJECT_CALL:
      return {
        ...state,
        deletingProject : true
    } 
    case DELETE_PROJECT_CALL_SUCCESS:
      return {
        ...state,
        deletingProject : false
    } 
    case DELETE_PROJECT_CALL_FAILURE:
      return {
        ...state,
        deletingProject : false
    } 
    case GET_SIDEBAR_PROJECT_DETAILS:
      return {
        ...state,
        sidebarProjectDetails : null,
        fetchingSidebarProject: true
    } 
    case GET_SIDEBAR_PROJECT_DETAILS_SUCCESS:
      return {
        ...state,
        sidebarProjectDetails : payload.data,
        fetchingSidebarProject: false
    } 
    case CLEAR_PROJECT_DETAILS:
      return {
        ...state,
        projectDetails: initialState.projectDetails
    } 
    case FETCH_PROJECT_USED_RESOURCES:
      return {
        ...state,
        fetchingUsedResources: true
    } 
    case FETCH_PROJECT_USED_RESOURCES_SUCCESS:
      return {
        ...state,
        fetchingUsedResources: false,
        projectUsedResources: payload.data
    } 
    case FETCH_PROJECT_USED_RESOURCES_FAILURE:
      return {
        ...state,
        fetchingUsedResources: false
    } 
    case CLEAR_PROJECT_USED_RESOURCES: 
      return {
        ...state,
        projectUsedResources: initialState.projectUsedResources
    } 
    case FETCH_PROJECT_LOADBALANCER:
      return {
        ...state,
        fetchingLoadbalancers: true
    } 
    case FETCH_PROJECT_LOADBALANCER_SUCCESS:
      return {
        ...state,
        fetchingLoadbalancers: false,
        loadbalancersList: payload.data
    } 
    case FETCH_PROJECT_LOADBALANCER_FAILURE:
      return {
        ...state,
        fetchingLoadbalancers: false
    } 
    case CREATE_PROJECT_LOADBALANCER:
      return {
        ...state,
        creatingLoadbalancer: true
    } 
    case CREATE_PROJECT_LOADBALANCER_SUCCESS:
      return {
        ...state,
        creatingLoadbalancer: false,
    } 
    case CREATE_PROJECT_LOADBALANCER_FAILURE:
      return {
        ...state,
        creatingLoadbalancer: false
    } 
    case DELETE_PROJECT_LOADBALANCER:
      return {
        ...state,
        deletingLoadbalancer: true
    } 
    case DELETE_PROJECT_LOADBALANCER_SUCCESS:
      return {
        ...state,
        deletingLoadbalancer: false,
    } 
    case DELETE_PROJECT_LOADBALANCER_FAILURE:
      return {
        ...state,
        deletingLoadbalancer: false
    } 
    case FETCH_LOADBALANCER:
      return {
        ...state,
        fetchingLoadbalancer: true
    } 
    case FETCH_LOADBALANCER_SUCCESS:
      return {
        ...state,
        fetchingLoadbalancer: false,
        loadbalancerInfo: payload.data
    } 
    case FETCH_LOADBALANCER_FAILURE:
      return {
        ...state,
        fetchingLoadbalancer: false
    } 
    case CLEAR_LOADBALANCER_INFO:
      return {
        ...state,
        loadbalancerInfo: initialState.loadbalancerInfo
    } 
    case FETCH_LOADBALANCER_STATUS:
      return {
        ...state,
        fetchingLBStatus: true
    } 
    case FETCH_LOADBALANCER_STATUS_SUCCESS:
      return {
        ...state,
        loadbalancerStatus: payload.data,
        fetchingLBStatus: false,
    } 
    case FETCH_LOADBALANCER_STATUS_FAILURE:
      return {
        ...state,
        fetchingLBStatus: false,
    }
    case CLEAR_LOADBALANCER_DATA: 
      return {
        ...state,
        loadbalancersList: initialState.loadbalancersList
    } 
    case CLEAR_FETCHING_USED_RESOURCES:
      return {
        ...state,
        projectUsedResources: initialState.projectUsedResources
    } 
    case DEACTIVATE_PROJECT_CALL:
      return {
        ...state,
        toggleProjectActivatitonCallStarted : true,
        toggleProjectActivatitonCallSuccess : false,
        toggleProjectActivatitonCallFailure : false,
      }
    case DEACTIVATE_PROJECT_CALL_SUCCESS:
      return {
        ...state,
        toggleProjectActivatitonCallStarted : false,
        toggleProjectActivatitonCallSuccess : true,
        toggleProjectActivatitonCallFailure : false,
        projectDetails:{...state.projectDetails,active:payload.data.active}
    }
    case DEACTIVATE_PROJECT_CALL_FAILURE:
      return {
        ...state,
        toggleProjectActivatitonCallStarted : false,
        toggleProjectActivatitonCallSuccess : false,
        toggleProjectActivatitonCallFailure : true,
    }
    case DEACTIVATE_PROJECT_CALL_CLEANUP:
      return {
        ...state,
        toggleProjectActivatitonCallStarted : false,
        toggleProjectActivatitonCallSuccess : false,
        toggleProjectActivatitonCallFailure : false,
      }
    default:
      return state
  }
}

export default reducer