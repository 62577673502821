import React, { useState } from "react";
import { connect,ConnectedProps } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ConfirmationNumber } from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import { updatePaymentSetting } from "./redux/actions";
import { useTranslation } from 'react-i18next';
import Popup from "../../../components/confirmactionpopup/ConfirmActionPopup";
import { Dispatch } from "redux";
/* istanbul ignore next */
const useStyles = makeStyles(() => ({
  cardAction: {
    marginTop: 0,
    marginRight: 0,
  },
  cardContent: {
    padding: "5px 16px",
  },
  promoCode: {
    fontWeight: 600,
    borderBottom: "1px dashed",
    margin: "auto 2px",
  },
  fw400: {
    fontWeight: 400,
  },
  mr10: {
    marginRight: 10,
  },
  subHeader: {
    color: "#43425d94",
  },
  card: {
    height: "100%",
  },
  grid:{
    alignItems:'start'
  }
}));


type JsonBody = {
  country: string;
  state: string;
  city: string;
  street: string;
  postal_code: string;
  promocode: string;
};

export const PromoCode = (props:PropsFromRedux) => {
  const [ t ] = useTranslation();

  const [promoCode, setPromoCode] = useState("");
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);

  const makeRequest = (promocode:string) => {
    let _jsonBody:JsonBody = {
      country: props.paymentSetting?.country ?? "",
      state: props.paymentSetting?.state ?? "",
      city: props.paymentSetting?.city ?? "",
      street: props.paymentSetting?.street ?? "",
      postal_code: props.paymentSetting?.postal_code ?? "",
      promocode: promocode,
    };
    props.updatePaymentSetting(_jsonBody);
  };

  const handleClearClick = () => {
    setOpenConfirmPopup(true);
  };

  const handleCloseConfirmPopup = () => {
    setOpenConfirmPopup(false)
  }
  
  const handleAgree = () => {
    makeRequest("NA");
    handleCloseConfirmPopup()
  }

  const handleInputChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setPromoCode(e.target.value);
  };

  const isValid = () => {
    if (promoCode.match(/^[\w-]+$/)) return true;
  };

  const handleButtonClick = () => {
    // e.preventDefault();
    if(!isValid()) return;
    makeRequest(promoCode);
    setPromoCode("");
  };

  const classes = useStyles();

  return (
    <Grid item xs={12} md={6} data-test="main-container">
      <Card className={classes.card}>
        <CardHeader
          classes={{ action: classes.cardAction }}
          title={<Typography variant="h4">{t("Billing.Coupon.title")}</Typography>}
          subheader={
            <Typography variant="caption" className={classes.subHeader}>
              {t("Billing.Coupon.claimCoupon")}
            </Typography>
          }
        />
        <CardContent classes={{ root: classes.cardContent }}>
          {props.paymentSetting?.promocode ? (
            <Typography display="inline" variant="h5" className={classes.fw400} data-test="promo-container">
              <Tooltip title="Remove Promo Code">
                <IconButton className={classes.mr10} onClick={handleClearClick} data-test="clear-icon">
                  <ClearIcon />
                </IconButton>
              </Tooltip>
              {t('Account.Billing.awesome')}{" "}
              <span className={classes.promoCode} data-test="promo-code">
                {props.paymentSetting?.promocode ?? ""}
              </span>{" "}
              {t('Account.Billing.isApplied')}
            </Typography>
          ) : (
            <form onSubmit={handleButtonClick}>
              <Grid container className={classes.grid} spacing={2} data-test="promo-input-container">
                <Grid item xs={12} sm={8}>
                  <TextField
                    fullWidth
                    placeholder="COUPON"
                    value={promoCode}
                    className="w-100"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      style: {
                        height: "1px",
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ConfirmationNumber />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    size="medium"
                    onChange={handleInputChange}
                    helperText={t("Billing.Coupon.onlyAlphaNum")}
                    data-test="promo-input"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Button
                    style={{ borderRadius: 2 }}
                    variant="contained"
                    color="primary"
                    onClick={handleButtonClick}
                    disabled={!isValid()}
                    disableElevation
                    data-test="apply-btn"
                  >
                    {t("Billing.Coupon.applyCoupon")}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </CardContent>
      </Card>
      {openConfirmPopup && (
        <Popup
          open={openConfirmPopup}
          handleDisAgree={handleCloseConfirmPopup}
          message={
            t('Account.Billing.removePromo')
          }
          yesText="Agree"
          handleAgree={handleAgree}
          data-test="confirm-popup"
        />
      )}
    </Grid>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state:any) => ({
  paymentSetting: state.PaymentReducer.paymentSetting,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch:Dispatch) => {
  return {
    updatePaymentSetting: (jsonBody:JsonBody) =>
      dispatch(updatePaymentSetting(jsonBody)),
  };
};


const connector=connect(mapStateToProps, mapDispatchtoProps)
type PropsFromRedux = ConnectedProps<typeof connector>;
export default  connector(PromoCode);
