const PAYMENT_PREFIX = "@PAYMENT";

export const GET_INVOICE = `${PAYMENT_PREFIX}/GET_INVOICE`;
export const GET_INVOICE_SUCCESS = `${PAYMENT_PREFIX}/GET_INVOICE_SUCCESS`;

export const GET_INVOICE_BY_ID = `${PAYMENT_PREFIX}/GET_INVOICE_BY_ID`;
export const GET_INVOICE_BY_ID_SUCCESS = `${PAYMENT_PREFIX}/GET_INVOICE_BY_ID_SUCCESS`;

export const GET_PAYMENT_SETTING = `${PAYMENT_PREFIX}/GET_PAYMENT_SETTING`;
export const GET_PAYMENT_SETTING_SUCCESS = `${PAYMENT_PREFIX}/GET_PAYMENT_SETTING_SUCCESS`;

export const UPDATE_PAYMENT_SETTING = `${PAYMENT_PREFIX}/UPDATE_PAYMENT_SETTING`;
export const UPDATE_PAYMENT_SETTING_SUCCESS = `${PAYMENT_PREFIX}/UPDATE_PAYMENT_SETTING_SUCCESS`;
export const UPDATE_PAYMENT_SETTING_FAILURE = `${PAYMENT_PREFIX}/UPDATE_PAYMENT_SETTING_FAILURE`;

export const GET_PAYMENT_THRESHOLD = `${PAYMENT_PREFIX}/GET_PAYMENT_THRESHOLD`;
export const GET_PAYMENT_THRESHOLD_SUCCESS = `${PAYMENT_PREFIX}/GET_PAYMENT_THRESHOLD_SUCCESS`;

export const UPDATE_PAYMENT_THRESHOLD = `${PAYMENT_PREFIX}/UPDATE_PAYMENT_THRESHOLD`;
export const UPDATE_PAYMENT_THRESHOLD_SUCCESS = `${PAYMENT_PREFIX}/UPDATE_PAYMENT_THRESHOLD_SUCCESS`;
export const UPDATE_PAYMENT_THRESHOLD_FAILURE = `${PAYMENT_PREFIX}/UPDATE_PAYMENT_THRESHOLD_FAILURE`;

export const GET_DEDUCTION_BY_ID = `${PAYMENT_PREFIX}/GET_DEDUCTION_BY_ID`;
export const GET_DEDUCTION_BY_ID_SUCCESS = `${PAYMENT_PREFIX}/GET_DEDUCTION_BY_ID_SUCCESS`;
export const RESET_DEDUCTION = `${PAYMENT_PREFIX}/RESET_DEDUCTION`

export const GET_PAYMENT_HISTORY = `${PAYMENT_PREFIX}/GET_PAYMENT_HISTORY`;
export const GET_PAYMENT_HISTORY_SUCCESS = `${PAYMENT_PREFIX}/GET_PAYMENT_HISTORY_SUCCESS`;
export const GET_PAYMENT_HISTORY_FAILURE = `${PAYMENT_PREFIX}/GET_PAYMENT_HISTORY_FAILURE`;

export const GET_LATEST_PAYMENT_HISTORY = `${PAYMENT_PREFIX}/GET_LATEST_PAYMENT_HISTORY`;
export const GET_LATEST_PAYMENT_HISTORY_SUCCESS = `${PAYMENT_PREFIX}/GET_LATEST_PAYMENT_HISTORY_SUCCESS`;

export const DOWNLOAD_INVOICE_BY_ID = `${PAYMENT_PREFIX}/DOWNLOAD_INVOICE_BY_ID`;
export const DOWNLOAD_INVOICE_BY_ID_SUCCESS = `${PAYMENT_PREFIX}/DOWNLOAD_INVOICE_BY_ID_SUCCESS`;
export const DOWNLOAD_INVOICE_BY_ID_FAILURE = `${PAYMENT_PREFIX}/DOWNLOAD_INVOICE_BY_ID_FAILURE`;

// actions for clean-ups
export const RESET_INVOICE = `${PAYMENT_PREFIX}/RESET_INVOICE`;
export const RESET_PAYMENT_SETTING = `${PAYMENT_PREFIX}/RESET_PAYMENT_SETTING`;
export const RESET_PREVIOUS_INVOICE = `${PAYMENT_PREFIX}/RESET_PREVIOUS_INVOICE`;
export const RESET_PAYMENT_HISTORY = `${PAYMENT_PREFIX}/RESET_PAYMENT_HISTORY`;
export const RESET_PAYMENT_THRESHOLD = `${PAYMENT_PREFIX}/RESET_PAYMENT_THRESHOLD`;
export const RESET_DOWNLOAD_INVOICE_BY_ID = `${PAYMENT_PREFIX}/RESET_DOWNLOAD_INVOICE_BY_ID`;

export const GET_PAYMENT_DETAILS = `${PAYMENT_PREFIX}/GET_PAYMENT_DETAILS`;
export const GET_PAYMENT_DETAILS_SUCCESS = `${PAYMENT_PREFIX}/GET_PAYMENT_DETAILS_SUCCESS`;
export const GET_PAYMENT_DETAILS_FAILURE = `${PAYMENT_PREFIX}/GET_PAYMENT_DETAILS_FAILURE`;

export const RESET_PAYMENT_DETAILS = `${PAYMENT_PREFIX}/RESET_PAYMENT_DETAILS`;

export const GET_GATEWAY_DATA = `${PAYMENT_PREFIX}/GET_GATEWAY_DATA`;
export const GET_GATEWAY_DATA_SUCCESS = `${PAYMENT_PREFIX}/GET_GATEWAY_DATA_SUCCESS`;

export const ADDRESS_UPDATED = `${PAYMENT_PREFIX}/ADDRESS_UPDATED`;

const getInvoice = () => {
  return {
    type: GET_INVOICE,
  };
};

const getInvoiceById = (id) => {
  return {
    type: GET_INVOICE_BY_ID,
    data: { id }
  };
};

const getPaymentSetting = () => {
  return {
    type: GET_PAYMENT_SETTING,
  };
};

const updatePaymentSetting = (jsonBody) => {
  return {
    type: UPDATE_PAYMENT_SETTING,
    data: { jsonBody },
  };
};

const getPaymentThreshold = () => {
  return {
    type: GET_PAYMENT_THRESHOLD,
  };
};

const updatePaymentThreshold = (jsonBody) => {
  return {
    type: UPDATE_PAYMENT_THRESHOLD,
    data: { jsonBody },
  };
};

const getDeductionById = (id) => {
  return {
    type: GET_DEDUCTION_BY_ID,
    data: { id },
  };
};

const resetDeduction = () => {
  return {
    type: RESET_DEDUCTION
  }
}

const getPaymentHistory = (size, page) => {
  return {
    type: GET_PAYMENT_HISTORY,
    data: { size, page }
  };
};

const getLatestPaymentHistory = () => {
  return {
    type: GET_LATEST_PAYMENT_HISTORY,
    data: { size: 1, page: 1 }
  };
};

const resetInvoice = () => {
  return {
    type: RESET_INVOICE
  }
}

const resetPaymentSetting = () => {
  return {
    type: RESET_PAYMENT_SETTING
  }
}

const resetPreviousInvoice = () => {
  return {
    type: RESET_PREVIOUS_INVOICE
  }
}

const resetPaymentHistory = () => {
  return {
    type: RESET_PAYMENT_HISTORY
  }
}

const resetPaymentThreshold = () => {
  return {
    type: RESET_PAYMENT_THRESHOLD
  }
}

const getPaymentDetails = (code,amt) => {
  return {
    type: GET_PAYMENT_DETAILS,
    data: { code,amt }
  }
}

const resetPaymentDetails = () => {
  return {
    type: RESET_PAYMENT_DETAILS
  }
}

const downloadInvoiceById = (id) => {
  return {
    type: DOWNLOAD_INVOICE_BY_ID,
    data: { id }
  };
};

const resetDownloadInvoiceById = () => {
  return {
    type: RESET_DOWNLOAD_INVOICE_BY_ID
  };
};

const getGatewayData = () => {
  return {
    type: GET_GATEWAY_DATA
  }
}

export {
  getInvoice,
  getInvoiceById,
  getPaymentSetting,
  updatePaymentSetting,
  getPaymentThreshold,
  updatePaymentThreshold,
  getDeductionById,
  resetDeduction,
  getPaymentHistory,
  getLatestPaymentHistory,
  resetInvoice,
  resetPaymentSetting,
  resetPreviousInvoice,
  resetPaymentHistory,
  resetPaymentThreshold,
  getPaymentDetails,
  resetPaymentDetails,
  downloadInvoiceById,
  resetDownloadInvoiceById,
  getGatewayData,
};
