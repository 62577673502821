import React from "react";
import { AppConstants } from "../../constants/appconstants";
import { EnvironmentOverview } from "../../models/Environment.model";
import KeyValueRow from "../keyvaluerow/KeyValueRow";

type Props = {
  environmentOverview: EnvironmentOverview[];
  state: string;
};

function OverviewConfig(props: Props) {
  return (
    <>
      {props.environmentOverview.map((el: EnvironmentOverview, key: number) => (
        el.type === "url" && el.is_running === true &&
        props.state !== AppConstants.EnvironmentStatus.Running ? (
          <></>
        ):(
          <KeyValueRow rowKey={el.name} rowValue={el.value} type={el.type} 
          hideCopy={el.name === "Git Branch" || el.name === "Sub Directory" ? true : false}/>
        )
      ))}
    </>
  );
}

export default OverviewConfig;
