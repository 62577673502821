const initialState = {
  fetchingTickets: false,
  userTickets: null,
  ticketDetail: null,
  fetchingTicketDetail: false,
  fetchingTicketGroups: false,
  creatingTicketGroup: false,
  replyingIssue: false,
  creatingIssueType: false,
  assigningTicket: false,
  creatingTicket: false,
  fetchingTypes: false,
  deletingTicket: false,
  supportTypes: null,
  issueTypes: null,
  updatingTicket: false,
};

export default initialState;
