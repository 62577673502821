import React, { useState, useEffect } from "react";
import { Tooltip, Typography, Grid, Link } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { CreateApp_TemplateTypes } from "../../constants/enums";
import Skeleton from "react-loading-skeleton";
import { AppDetailsModel } from "../../models/Application.model";

type Props = {
  source: number,
  appDetails: AppDetailsModel
}

export function RepoName(props: Props) {
  const [url, setUrl] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [urlText, setUrlText] = useState("");
  const [altText, setAltText] = useState("");
  const [t] = useTranslation();

  useEffect(() => {
    const { appDetails } = props;
    let _url = "";
    let _urlText = "";
    let _imageUrl = "";
    let _altText = "";
    switch (appDetails.service_type) {
      case CreateApp_TemplateTypes.git:
        _altText = "Git Repository";
        _url = appDetails?.git_repository_info?.html_url;
        if (props.source === 1) {
          _urlText = appDetails?.git_repository_info?.name;
        } else if (props.source === 2) {
          let value = appDetails?.git_repo_url;
          let repoName = "";
          if (value) {
            // /^(https|git)(:\/\/|@)([^\/:]+)[\/:]([^\/:]+)\/(.+).git$/
            const res = /^(https|git)(:\/\/|@)([^/:]+)[/:]([^/:]+)\/(.+).git$/.exec(
              value
            );
            if (res) repoName = res[res.length - 1];
            _urlText = repoName;
          }
        }
        _imageUrl = "/images/icons/git_blue.svg";

        break;
      case CreateApp_TemplateTypes.cr:
        _altText = "Container Registry";
        _url = appDetails?.image_url;
        _urlText = `${appDetails?.image_namespace}/${appDetails?.image_repo}`;
        _imageUrl = "/images/icons/containerregistry_blue.svg";
        break;
      default:
        _url = "";
        _urlText = "";
    }

    setUrl(_url);
    setUrlText(_urlText);
    setImageUrl(_imageUrl);
    setAltText(_altText);
  }, [props.appDetails, props.source]);

  return (
    <>
      {props.source === 1 && url && urlText && (
        <Grid item className="topgridalign" data-test="appInfoPageContainer">
          <Tooltip title={t("App.AppInfo.repo")}>
            {urlText ? (
              <img
                src={imageUrl}
                alt={altText}
                className="svgiconimg"
                data-test="repoImage"
              />
            ) : (
              <Skeleton circle={true} height={10} width={10} />
            )}
          </Tooltip>
          {urlText ? (
            <Typography
              color="textPrimary"
              variant="body2"
              className="topinfoGrid"
              style={{ fontSize: 14 }}
            >
              <Link
                data-test="url"
                href={url}
                target="_blank"
                className="appGitName"
              >
                {urlText}
              </Link>
            </Typography>
          ) : (
            <Skeleton width={80} />
          )}
        </Grid>
      )}
      {props.source === 2 && urlText && (
        <Grid
          item
          md={4}
          className="topgridalign"
          data-test="projectCardContainer"
        >
          <Tooltip title={t("App.AppInfo.repo")} arrow>
            <img
              src={imageUrl}
              alt={altText}
              className="svgiconimg"
              data-test="repoImageContainer"
            />
          </Tooltip>
          <Tooltip title={urlText}>
            <span
              style={{
                maxWidth: "100px",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              data-test="urlContainer"
              className="oneLine"
            >
              {urlText}
            </span>
          </Tooltip>
        </Grid>
      )}
    </>
  );
}
export default RepoName;
