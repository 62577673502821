import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import DeploymentForm from '../../components/deploymentformbody/DeploymentForm'
import { fetchAppDetails } from '../app/redux/actions'

export const CreateHelmEnvironment = (props) => {
    useEffect(() => {
        if (props?.match?.params?.appId) {
            props.fetchAppDetails(props.match.params.appId)
        }
    }, [props.match?.params?.appId])

    return (
        <div>
            <DeploymentForm history={props.history} appDetails={props.appDetails} />
        </div>
    )
}

/* istanbul ignore next */
const mapStateToProps = (state) => ({
    appDetails: state.AppsReducer.appDetails,

})

/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
    fetchAppDetails: (payload) => dispatch(fetchAppDetails(payload)),

})

export default connect(mapStateToProps, mapDispatchToProps)(CreateHelmEnvironment)
