import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  ButtonBase,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { fetchScannerPlugins, startScan } from "../../redux/actions";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import ConfirmActionPopup from "../../../../components/confirmactionpopup/ConfirmActionPopup";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Skeleton } from "@material-ui/lab";
import BackdropLoader from "../../../../components/loader/BackdropLoader";


const useStyles = makeStyles({
  root: {
    marginTop: 0,
  },
  paperContainer: {
    height: 180,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  iconContainer: {
    width: "100%",
    height: 180,
    padding: 0,
  },
  selectTemplateTypography: {
    marginTop: "1rem",
    textTransform: "none",
  },
  checkselect: {
    position: "absolute",
    top: 20,
    right: 15,
  },
  backBtn: {
    color: "#357dfd", 
    marginBottom: "20px"
  },
  typo:{
    paddingTop: "20px", 
    paddingBottom: "10px"
  }
});


interface Props extends PropsFromRedux {
  handleBack: () => void
}

function NewScan(props: Props) {
  const classes = useStyles();

  const [provider, setProvider] = useState("image");
  const [confirmScan, setConfirmScan] = useState(false);
  const [scanPlugin, setScanPlugin] = useState("")
  const [scanLoader, setScanLoader] = useState(false);

  useEffect(() => {
    props.fetchScannerPlugins(props.environmentDetails.id);
  }, []);

  useEffect(() => {
    filterSelection()
  }, [props.scannerDetails]);


  const handleProviderSelection = (provider: string) => {
    setProvider(provider);
  };

  const handleStartScan = (plugin:string) => {
    setScanPlugin(plugin)
    setConfirmScan(true);
  }

  const runScanLoader = () =>{
    setScanLoader(false)
    props.handleBack();
  }

  const handleConfirmPopupAgree = () => {
    props.startScan(props.environmentDetails.id, scanPlugin, runScanLoader)
    setConfirmScan(false);
    setScanLoader(true)
  }

  const handleConfirmPopupDisagree = () => {
    setConfirmScan(false);
  }

  const imgArray: any = {
    image: "/images/icons/containerregistry.svg",
    k8s:"/images/provider/gke.svg",
    repo:"/images/icons/git.svg"
  }
  

  const filterSelection = () => {
    const filteredType = props.scannerDetails && Object.keys(props.scannerDetails).filter(item => props.scannerDetails[item] !== null)
    if(filteredType){
      setProvider(filteredType[0])
    }
  }

  return (
    <>
      <ButtonBase
        onClick={()=>{ props.handleBack() }}
        className={classes.backBtn}
        data-test="backButton"
      >
        <ArrowBackIcon fontSize="small" />{" "}
        <span className="jobNav">back to list</span>
      </ButtonBase>
      <div>
        {" "}
        <Typography variant="h3">Run New Scan</Typography>{" "}
        <div>
          <div data-test="main-container">
            <Typography
              variant="h5"
              className={classes.typo}
            >
              Selected Type
            </Typography>

          {!props.scannerDetails &&
            <Grid container spacing={2}>
              <Grid item><Skeleton variant="rect" width={150} height={180} /></Grid>
              <Grid item><Skeleton variant="rect" width={150} height={180} /></Grid>
              <Grid item><Skeleton variant="rect" width={150} height={180} /></Grid>
            </Grid>
          }

            <Grid container spacing={2} className={classes.root}>
              {props.scannerDetails && 
                Object.keys(props.scannerDetails)
                .filter(item => props.scannerDetails[item] !== null)
                .map((item: any, index:number) => (
                  <Grid item xs={12} md={2} key={item}>
                    <Button
                      className={classes.iconContainer}
                      onClick={() => handleProviderSelection(item)}
                    >
                      <Paper className={classes.paperContainer}>
                        {provider === item && (
                          <CheckCircleIcon
                            color="primary"
                            className={classes.checkselect}
                            data-test="check-icon"
                          />
                        )}
                        <div>
                          <img
                            src={imgArray[item]}
                            width="80px"
                            alt=""
                            data-test="provider-img"
                          />
                        </div>
                        <Typography
                          className={classes.selectTemplateTypography}
                          variant="h6"
                          color="textPrimary"
                          data-test="provider-name"
                        >
                          {item} Scan
                        </Typography>
                      </Paper>
                    </Button>
                  </Grid>
                ))}
            </Grid>
          </div>
        
          <div data-test="main-container">
            <Typography
              variant="h5"
              className={classes.typo}
            >
              Selected Plugin
            </Typography>

            {!props.scannerDetails &&
            <Grid container spacing={2}>
              <Grid item><Skeleton variant="rect" width={150} height={180} /></Grid>
              <Grid item><Skeleton variant="rect" width={150} height={180} /></Grid>
            </Grid>
            }

            <Grid container spacing={2} className={classes.root}>
              {props.scannerDetails && props.scannerDetails[provider] !== null && props.scannerDetails[provider].map((item: any) => (
                  <Grid item xs={12} md={2}>
                    <Button
                      className={classes.iconContainer}
                      onClick={() => handleStartScan(item.name)}
                    >
                      <Paper className={classes.paperContainer}>
                        <div>
                          <img
                            src={ item.logo }
                            width="80px"
                            height="80px"
                            alt=""
                            data-test="provider-img"
                          />
                        </div>
                        <Typography
                          className={classes.selectTemplateTypography}
                          variant="h6"
                          color="textPrimary"
                          data-test="provider-name"
                        >
                          {item.name}
                        </Typography>
                      </Paper>
                    </Button>
                  </Grid>
                ))}
            </Grid>
          </div>
        </div>
      </div>

      {confirmScan && (
      <ConfirmActionPopup
        open={confirmScan}
        handleAgree={handleConfirmPopupAgree}
        handleDisAgree={handleConfirmPopupDisagree}
        yesText="yes"
        noText="No"
        message={<p>Are you sure to scan using {scanPlugin}</p>}
      />
    )}

    {scanLoader && (<BackdropLoader message={`Scanning using ${scanPlugin} ... `} />)}

    </>
  );
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  scannerDetails: state.EnvironmentReducer.scannerDetails,
  environmentDetails: state.EnvironmentReducer.environmentDetails,
});
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    fetchScannerPlugins: (id:number) => dispatch(fetchScannerPlugins(id)),
    startScan: (id:number, plugin:string, runScanLoader:()=>void) => dispatch(startScan(id, plugin, runScanLoader)),
  };
};

const connector = connect(mapStateToProps, mapDispatchtoProps);
export default connector(NewScan);
type PropsFromRedux = ConnectedProps<typeof connector>;
