import { Link } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home'
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { currentOrganization } from '../login/redux/selectors';
import './header.css';
import { WithStyles, createStyles } from "@material-ui/core";
import DueWarning from './DueWarning';
import { VersionRibbon } from '../../helpers/utils.ext';

/* istanbul ignore next */
const useStyles = () => createStyles({
    breadcrumb: {

    },
    breadcrumbLink: {
        fontSize: 13,
        color: '#43425D'
    }
});

type BreadcrumbData = {
    name: string,
    path: string
    }

interface Props extends WithTranslation, PropsFromRedux, WithStyles<typeof useStyles> {
    breadcrumbData: BreadcrumbData[]
}

interface I_state{
anchorEl: Element | ((element: Element) => Element) | null;
showDueWarning:boolean
}

/* istanbul ignore next */
const LinkRouter = (props: any) => <Link {...props} component={RouterLink} underline='none' />;

export class Breadcrumb extends Component<Props,I_state> {
    constructor(props: Props) {
        super(props);
        this.state = {
            anchorEl: null,
            showDueWarning:true,
        }
    }

    handleDueWaring=(isDueVisible:boolean)=>{
this.setState({showDueWarning:isDueVisible})
    }

    render() {
        const { classes, t } = this.props;
        return (
            <div data-test="main-container">
                <VersionRibbon />
                {
                    this.props.breadcrumbData &&
                    <div className="breadcrumbs" data-test="breadcrumb-container">
                        <ul>
                            <li>
                                <LinkRouter color="inherit" to={ this.props.currentOrganization && this.props.currentOrganization.id > 0 ?  "/organization/" + this.props.currentOrganization.id : "/projects" } className={classes.breadcrumbLink} data-test="home-link">
                                    <HomeIcon className='homeIcon' data-test="home-icon"/>
                                </LinkRouter>
                            </li>
                            <li>
                                <LinkRouter color="inherit" to={ this.props.currentOrganization && this.props.currentOrganization.id > 0 ?  "/organization/" + this.props.currentOrganization.id : "/projects" } className={classes.breadcrumbLink} data-test="home-label-link">
                                    {t('Header.Breadcrumb.home')}
                                </LinkRouter>
                            </li>
                            {
                                this.props.breadcrumbData.length > 0 && this.props.breadcrumbData.map((item, ind) => {
                                    return (
                                        <li key={ind}>
                                            <LinkRouter color="inherit" to={{ pathname: item.path }} className={classes.breadcrumbLink} data-test="breadcrumb-links">
                                                {item.name}
                                            </LinkRouter>
                                        </li>
                                    )
                                })
                            }
                        </ul>

                      {this.state.showDueWarning?<DueWarning handleDueWaring={this.handleDueWaring} />:null}  
                    </div>
                }
                {/* <Breadcrumbs separator="›" aria-label="breadcrumb" className={classes.breadcrumb}>
                {
                    this.props.breadcrumbData && this.props.breadcrumbData.length > 0 && 
                    //
                    <LinkRouter color="inherit" to="/projects" className={classes.breadcrumbLink}>
                        All Projects
                    </LinkRouter>
                }
                {
                    this.props.breadcrumbData && this.props.breadcrumbData.length > 0 && this.props.breadcrumbData.map(item => {
                        return(
                            <LinkRouter color="inherit" to={{ pathname : item.path }} className={classes.breadcrumbLink}>
                                {item.name}
                            </LinkRouter>
                        )
                    })
                }
            </Breadcrumbs> */}
            </div>
        )
    }
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
    breadcrumbData: state.ProjectReducer.breadcrumbData,
    currentOrganization: currentOrganization(state),
})

/* istanbul ignore next */
const mapDispatchtoProps = () => {
    return {

    }
}

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withStyles(useStyles)(withTranslation()(Breadcrumb)));