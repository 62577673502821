import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import SubscriptionForm from "../SubscriptionForm";
import { createSubscriptionCall } from "../redux/actions";
import BackdropLoader from '../../../components/loader/BackdropLoader';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Dispatch } from "redux";
import { History } from 'history';

interface Props extends WithTranslation, PropsFromRedux {
  history?: History,
  handleCreateEditSuccess: () => void
}

export class CreateSubscription extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  createSubscription = (values: any) => {
    const payload = {
      name: values.subscriptionName,
      // description: values.label ? values.label : "",
      disk_space: parseFloat(values.diskSpace),
      memory: parseFloat(values.memory),
      data_transfer: parseFloat(values.dataTransfer),
      cores: parseFloat(values.cores),
      apps: parseInt(values.apps),
      price: parseFloat(values.price),
      backups: parseInt(values.backups),
      active: values.active,
      resource_list : {
        configmaps: values.resource_list.configmaps.toString(),
        persistentvolumeclaims: values.resource_list.persistentvolumeclaims.toString(),
        pods: values.resource_list.pods.toString(),
        replicationcontrollers: values.resource_list.replicationcontrollers.toString(),
        secrets: values.resource_list.secrets.toString(),
        services: values.resource_list.services.toString(),
        loadbalancers: values.resource_list.loadbalancers.toString(),
        gpu: values.resource_list.gpu.toString()
      }
    };
    this.props.createSubscriptionApiCall(payload, this.props.history);
  };

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if(nextProps) {
      if(!this.props.createSubscriptionCallSuccess && nextProps.createSubscriptionCallSuccess){
        this.props.handleCreateEditSuccess();
      }
    }
  }

  render() {
    const { t } = this.props;
    return (
        <div data-test="main-container">
            <SubscriptionForm
              //history={history}
              //t={t}
              subscriptionAction={(values: any) => this.createSubscription(values)}
            />
            {this.props.executingCreateSubscription && <BackdropLoader message={t('Subscription.SubscriptionForm.creatingSubscription')} />}
        </div>
    );
  }
}
/* istanbul ignore next */
const mapStateToProps = (state: any) => {
  return {
    executingCreateSubscription: state.SubscriptionReducer.executingCreateSubscription,
    createSubscriptionCallSuccess: state.SubscriptionReducer.createSubscriptionCallSuccess
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  createSubscriptionApiCall: (payload: any, history: History|undefined) =>
    dispatch(createSubscriptionCall(payload, history))
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withTranslation()(CreateSubscription));
