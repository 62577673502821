import { Button, Card, CardActions, CardContent, CardHeader, Divider, FormHelperText, Grid, IconButton, InputAdornment, TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {
    Formik,
    FormikHelpers
} from 'formik';
import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as Yup from 'yup';
import { updatePassword } from '../profiletab/redux/actions';
import { withTranslation, WithTranslation } from 'react-i18next';
import MuiTextField from '../../../components/textfield/MuiTextField';
import { Dispatch } from "redux";

// const styles = {
//     raisedPrimary: {
//         color: colors.red[900],
//         backgroundColor: colors.red[600],
//         '&:hover': {
//             backgroundColor: colors.red[800],
//             // Reset on mouse devices
//             '@media (hover: none)': {
//                 backgroundColor: colors.red[600],
//             },
//         },
//     },
// };

type Payload = {
    password: string,
    new_password: string,
    retype_password: string
}

type Values = {
    currentPassword: string,
    newPassword: string,
    confirmPassword: string
}

interface Props extends WithTranslation, PropsFromRedux{}

type State = {
    isPasswordToBeEdited: boolean,
    showCurrentPassword: boolean,
    showNewPassword: boolean
}

export class Profile extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isPasswordToBeEdited: false,
            showCurrentPassword: false,
            showNewPassword: false
        }
    }

    // componentDidMount() {
    // }

    // handleOnChange = (e) => {
    //     this.setState({
    //         [e.target.name]: e.target.value
    //     })
    // }

    // handleChangePassword = () => {
    //     this.setState({
    //         isPasswordToBeEdited: true
    //     })
    // }

    // handleCancelPassword = () => {
    //     this.setState({
    //         isPasswordToBeEdited: false
    //     })
    // }

    handleClickShowCurrentPassword = () => {
        this.setState({
            showCurrentPassword:!this.state.showCurrentPassword
        })
    };
    
    handleClickShowNewPassword = () => {
        this.setState({
            showNewPassword:!this.state.showNewPassword
        })
    };

    resetPasswordShowOption = () => {
        this.setState({
            showCurrentPassword: false,
            showNewPassword:false
        })
    }

    render() {
        const { t } = this.props;
        return (
            <div className='detailsContainer'>

                <Formik data-test="formik-component" initialValues={{
                    currentPassword: ''
                    , newPassword: '',
                    confirmPassword: ''
                }}
                  onSubmit={(values: Values, { resetForm }: FormikHelpers<Values>) => {
                        const payload = {
                            password: values.currentPassword,
                            new_password: values.newPassword,
                            retype_password: values.confirmPassword
                        }
                        this.setState({
                            isPasswordToBeEdited: false
                        })
                        this.props.updatePassword(payload)
                        resetForm({values:{currentPassword: '', newPassword: '', confirmPassword: ''}
                        });
                    }}
                  onReset = {() => {
                        this.resetPasswordShowOption();
                    }}
                  validationSchema={
                        Yup.object().shape({
                            currentPassword: Yup
                                .string().min(2, t('Account.PasswordTab.tooShort')).max(30, t('Account.PasswordTab.tooLong'))
                                .required(t('Account.PasswordTab.currentPassword'))
                                .matches(
                                    ///^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                                    //'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
                                    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/,
                                    t('Account.PasswordTab.minCharsError')
                                ),
                            newPassword: Yup
                                .string().min(2, t('Account.PasswordTab.tooShort')).max(30, t('Account.PasswordTab.tooLong'))
                                .required(t('Account.PasswordTab.newPassword'))
                                .matches(
                                    ///^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                                    //'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
                                    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/,
                                    t('Account.PasswordTab.minCharsError')
                                )
                                .test('pwdMatchTest', t('Account.PasswordTab.oldPwdMatchingError'),
                                function() {
                                    let newPwd = this.resolve(Yup.ref("newPassword"));
                                    let oldPwd = this.resolve(Yup.ref("currentPassword"));
                                    if(newPwd === oldPwd){
                                        return false;
                                    }
                                    else
                                        return true;
                                    }),
                            confirmPassword: Yup
                                .string().min(2, t('Account.PasswordTab.tooShort')).max(30, t('Account.PasswordTab.tooLong'))
                                .oneOf([Yup.ref('newPassword')], t('Account.PasswordTab.passwordNotMatching'))
                                .required(t('Account.PasswordTab.reEnterPassword'))
                        })}
                >
                    {
                        (props) => {
                            const {
                                values,
                                touched,
                                errors,
                                //dirty,
                                //isSubmitting,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                handleReset,
                                isValid,
                            } = props;

                            return (
                                <Grid>
                                    <Grid container md={6}>
                                        <Card>
                                        <CardHeader title={t('Account.PasswordTab.updatePassword')} />
                                                <Divider/>

                                            <CardContent>
                                                <form onSubmit={handleSubmit} onReset={handleReset}>
                                                    <Grid>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <FormControl fullWidth variant="outlined" margin="normal" >
                                                                    {/* <InputLabel  error={ Boolean(errors.currentPassword && touched.currentPassword) }>{t('Account.PasswordTab.currentPasswordText')}</InputLabel> */}
                                                                    {/* <OutlinedInput
                                                                      id="currentPassword"
                                                                      autoFocus
                                                                      error={ Boolean(errors.currentPassword && touched.currentPassword) }
                                                                      type={this.state.showCurrentPassword ? "text": "password"}
                                                                      value={values.currentPassword}
                                                                      color='primary'
                                                                      name="currentPassword"
                                                                      onChange={ handleChange } 
                                                                      onBlur={ handleBlur }
                                                                      endAdornment={
                                                                          <InputAdornment position="end" data-test="currentpass-visibility">
                                                                          <IconButton
                                                                        aria-label="toggle current password visibility"
                                                                        onClick={this.handleClickShowCurrentPassword}
                                                                        edge="end"
                                                                        data-test="visibility-icon"
                                                                          >
                                                                          {this.state.showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                                                                          </IconButton>
                                                                          </InputAdornment>
                                                            }
                                                                      labelWidth={110}
                                                                      data-test="current-password-field"
                                                                    /> */}
                                                                    <MuiTextField
                                                                    label={t('Account.PasswordTab.currentPasswordText')}
                                                                    id="currentPassword"
                                                                    autoFocus
                                                                    error={ Boolean(errors.currentPassword && touched.currentPassword) }
                                                                    type={this.state.showCurrentPassword ? "text": "password"}
                                                                    value={values.currentPassword}
                                                                    name="currentPassword"
                                                                    onChange={ handleChange } 
                                                                    onBlur={ handleBlur }
                                                                    InputProps={{
                                                                        endAdornment:(
                                                                            <InputAdornment position="end" data-test="currentpass-visibility">
                                                                            <IconButton
                                                                          aria-label="toggle current password visibility"
                                                                          onClick={this.handleClickShowCurrentPassword}
                                                                          edge="end"
                                                                          data-test="visibility-icon"
                                                                            >
                                                                            {this.state.showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                                                                            </IconButton>
                                                                            </InputAdornment>
                                                                        )
                                                                    }}
                                                                    labelWidth={110}
                                                                    data-test="current-password-field"
                                                                    />
                                                                    <FormHelperText error={ Boolean(errors.currentPassword && touched.currentPassword) } >
                                                                        {errors.currentPassword && touched.currentPassword ? errors.currentPassword : ''}
                                                                    </FormHelperText>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item  xs={12}>
                                                                <FormControl fullWidth variant="outlined" margin="normal" >
                                                                    {/* <InputLabel error={ Boolean(errors.newPassword && touched.newPassword) }>{t('Account.PasswordTab.newPasswordText')}</InputLabel> */}
                                                                    {/* <OutlinedInput
                                                                      id="newPassword"
                                                                      error={ Boolean(errors.newPassword && touched.newPassword) }
                                                                      type={this.state.showNewPassword ? "text" : "password"}
                                                                      value={values.newPassword}
                                                                      color='primary'
                                                                      name="newPassword"
                                                                      onChange={ handleChange } 
                                                                      onBlur={ handleBlur }
                                                                      endAdornment={
                                                                          <InputAdornment position="end" data-test="passwordVisibility">
                                                                          <IconButton
                                                                        aria-label="toggle new password visibility"
                                                                        onClick={this.handleClickShowNewPassword}
                                                                        edge="end"
                                                                        data-test="password-icon"
                                                                          >
                                                                          {this.state.showNewPassword ? <Visibility /> : <VisibilityOff />}
                                                                          </IconButton>
                                                                          </InputAdornment>
                                                            }
                                                                      labelWidth={100}
                                                                      data-test="password-field"
                                                                    /> */}
                                                                    <MuiTextField
                                                                    label={t('Account.PasswordTab.newPasswordText')}
                                                                    id="newPassword"
                                                                    error={ Boolean(errors.newPassword && touched.newPassword) }
                                                                    type={this.state.showNewPassword ? "text" : "password"}
                                                                    value={values.newPassword}
                                                                    name="newPassword"
                                                                    onChange={ handleChange } 
                                                                    onBlur={ handleBlur }
                                                                    InputProps={{
                                                                        endAdornment:(
                                                                            <InputAdornment position="end" data-test="passwordVisibility">
                                                                          <IconButton
                                                                        aria-label="toggle new password visibility"
                                                                        onClick={this.handleClickShowNewPassword}
                                                                        edge="end"
                                                                        data-test="password-icon"
                                                                          >
                                                                          {this.state.showNewPassword ? <Visibility /> : <VisibilityOff />}
                                                                          </IconButton>
                                                                            </InputAdornment>
                                                                        )
                                                                    }}
                                                                    labelWidth={100}
                                                                    data-test="password-field"
                                                                    />
                                                                    <FormHelperText error={ Boolean(errors.newPassword && touched.newPassword) } >
                                                                        {errors.newPassword && touched.newPassword ? errors.newPassword : ''}
                                                                    </FormHelperText>
                                                                </FormControl>
                                                            </Grid>

                                                            <Grid item  xs={12}>
                                                                <TextField
                                                                  id="confirmPassword"
                                                                  error={Boolean(errors.confirmPassword && touched.confirmPassword)}
                                                                  type="password"
                                                                  color='primary'
                                                                  variant='outlined'
                                                                  label={t('Account.PasswordTab.confirmPasswordLabel')}
                                                                  name="confirmPassword"
                                                                  onChange={handleChange}
                                                                  value={values.confirmPassword}
                                                                  onBlur={handleBlur}
                                                                  fullWidth
                                                                  className='oneRemMarginSeperator'
                                                                  helperText={(errors.confirmPassword && touched.confirmPassword) && errors.confirmPassword}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Divider />

                                                    <CardActions>
                                                        <Button disableElevation color="secondary"  variant="contained" type='reset' >
                                                            {t('Account.PasswordTab.clearLabel')}
                                                        </Button>
                                                        <Button disableElevation disabled={!isValid} variant="contained" className='oneRemLeftMarginSeperator' color="primary"  type='submit' >
                                                            {t('Account.PasswordTab.updatePassword')}
                                                        </Button>

                                                    </CardActions>
                                                </form>
                                            </CardContent>
                                        </Card>

                                    </Grid>
                                </Grid>
                              
                            )
                        }
                    }
                </Formik>
            </div>
        )
    }
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
    userDetails: state.AuthReducer.user
})

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
    return {
       
        updatePassword: (payload: Payload) => dispatch(updatePassword(payload))
    }
}

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withTranslation()(Profile));