import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
} from "@material-ui/core";
import { withStyles,createStyles } from "@material-ui/core/styles";
import BuildOutlinedIcon from "@material-ui/icons/BuildOutlined";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import PersonAddDisabledIcon from "@material-ui/icons/PersonAddDisabled";
import LaunchIcon from "@material-ui/icons/Launch";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Skeleton from "react-loading-skeleton";
import { connect,ConnectedProps } from "react-redux";
import { Link } from "react-router-dom";
import BackdropLoader from "../../../../components/loader/BackdropLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import { getDateInStandardFormat } from "../../../../helpers/utils";
import { clearOrgActivities, fetchOrgActivities } from "../../redux/actions";
import { useTranslation } from "react-i18next";
import "./OrganizationActivityTab.css";
import { Theme ,WithStyles} from "@material-ui/core";
import { OrganizationActivity } from "../../../../models/Organization.model";
import { Dispatch } from "redux";

/* istanbul ignore next */
const useStyles = (theme:Theme) =>createStyles ({
  dropdown: {
    minWidth: 140,
    height: 50,
  },
  type: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      display: "none",
    },
  },
  filterGrid: {
    borderRadius: "4px",
    width: "100%",
    padding: "5px 0",
    margin: "5px auto 10px",
  },
  activitiesCard:{
    borderRadius:0
  }
});

interface Props
  extends PropsFromRedux,
    WithStyles<typeof useStyles> {
  orgId: number;
}

export const OrganizationActivityTab = (props:Props) => {
  const { classes } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedAction, setSelectedAction] = useState("all");
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");
  const [selectedUser, setSelectedUser] = useState("all");
  const [selectedModule, setSelectedModule] = useState("all");

  const [dateError, setDateError] = useState(false);
  const [dateErrorText, setDateErrorText] = useState("");

  const limit = 20;

  const [t] = useTranslation();

  const isResetValid = () => {
    if (
      selectedAction === "all" &&
      selectedFromDate === "" &&
      selectedToDate === "" &&
      selectedUser === "all" &&
      selectedModule === "all"
    ) {
      return false;
    }
    return true;
  };

  const resetFilters = () => {
    props.clearOrgActivities();
    setCurrentPage(1);
    setSelectedAction("all");
    setSelectedFromDate("");
    setSelectedToDate("");
    setSelectedUser("all");
    setSelectedModule("all");
  };

  const fetchActivitiesCall = () => {
    props.fetchOrgActivities(
      props.orgId,
      currentPage,
      limit,
      selectedAction !== "all" ? selectedAction : "",
      selectedUser ?? "",
      selectedFromDate || "",
      selectedToDate || "",
      selectedModule !== "all" ? selectedModule : ""
    );
  };

  const fetchMoreActivities = () => {
    setCurrentPage((val) => val + 1);
  };

  const handleActionChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    setCurrentPage(1);
    setSelectedAction(e.target.value as string);
  };

  const handleDateChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(1);
    setSelectedFromDate(
      e.target.name === "fromDate" ? e.target.value : selectedFromDate
    );
    setSelectedToDate(
      e.target.name === "toDate" ? e.target.value : selectedToDate
    );
  };

  const handleModuleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    setCurrentPage(1);
    setSelectedModule(e.target.value as string);
  };

  useEffect(() => {
    const invalidRange =
      selectedFromDate &&
      selectedToDate &&
      !(new Date(selectedToDate) > new Date(selectedFromDate));

    const tempDateErrorText =
      Boolean(selectedToDate) !== Boolean(selectedFromDate)
        ? t("Projects.ActivityTab.fromToError")
        : invalidRange
        ? t("Projects.ActivityTab.dateEarlyError")
        : "";

    setDateErrorText(tempDateErrorText);

    setDateError(Boolean(tempDateErrorText));

    if (!tempDateErrorText) {
      if (currentPage === 1) {
        props.clearOrgActivities();
      }
      fetchActivitiesCall();
    }
  }, [
    props.orgId,
    currentPage,
    selectedAction,
    selectedFromDate,
    selectedToDate,
    selectedUser,
    selectedModule,
  ]);

  useEffect(() => {
    return () => {
      props.clearOrgActivities();
    };
  }, []);
  return (
    <div data-test="main-component" className="org-activities-container">
      <Grid
        className={classes.filterGrid}
        container
        spacing={3}
        justify="flex-end"
        alignItems="center"
      >
        {isResetValid() && (
          <Grid item>
            <Button
              data-test="reset-button"
              color='default'
              onClick={() => resetFilters()}
              variant='text'
            >
              {t("Projects.ActivityTab.resetLabel")}
            </Button>
          </Grid>
        )}
        <Grid item>
          <FormControl variant="outlined" >
            <InputLabel>{t("Projects.ActivityTab.moduleLabel")}</InputLabel>

            <Select
              data-test="module-selector"
              value={selectedModule}
              onChange={(e: React.ChangeEvent<{ value: unknown }>) => handleModuleChange(e)}
              label={t("Projects.ActivityTab.moduleLabel")}
            >
              <MenuItem value="all">
                <em>{t("Projects.ActivityTab.allLabel")}</em>
              </MenuItem>
              <MenuItem value="organization">{t("organization")}</MenuItem>
              <MenuItem value="member">
                {t("Organization.ActivityTab.member")}
              </MenuItem>
              <MenuItem value="group">{t("AddDNSPopup.group")}</MenuItem>
              <MenuItem value="cluster">{t("ClusterLabel")}</MenuItem>
              <MenuItem value="subscription">
                {t("SettingsSubscription")}
              </MenuItem>
              <MenuItem value="registry">
                {t("Organization.AdminTab.registry")}
              </MenuItem>
              <MenuItem value="dns">{t("Organization.AdminTab.dns")}</MenuItem>
              <MenuItem value="resource">
                {t("Organization.ActivityTab.resource")}
              </MenuItem>
              <MenuItem value="chart-repo">
                {t("Organization.ActivityTab.chartRepo")}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <FormControl variant="outlined">
            <InputLabel>{t("Projects.ActivityTab.actionLabel")}</InputLabel>

            <Select
              data-test="action-selector"
              value={selectedAction}
              onChange={(e: React.ChangeEvent<{ value: unknown }>) => handleActionChange(e)}
              label={t("Projects.ActivityTab.actionLabel")}
            >
              <MenuItem value="all">
                <em>{t("Projects.ActivityTab.allLabel")}</em>
              </MenuItem>
              <MenuItem value={"create"}>
                {t("Projects.ActivityTab.createLabel")}
              </MenuItem>
              <MenuItem value={"delete"}>
                {t("Projects.ActivityTab.deleteLabel")}
              </MenuItem>
              <MenuItem value={"update"}>
                {t("Projects.ActivityTab.updateLabel")}
              </MenuItem>
              <MenuItem value={"share"}>
                {t("Projects.ActivityTab.shareLabel")}
              </MenuItem>
              <MenuItem value="open">{t("Support.SupportPage.open")}</MenuItem>
              <MenuItem value="plan">
                {t("Organization.ActivityTab.plan")}
              </MenuItem>
              <MenuItem value="destroy">
                {t("Cluster.ClusterList.destroy")}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item >
          <TextField
            type="date"
            label={t("Projects.ActivityTab.fromLabel")}
            // format="dd/mm/yyyy"
            value={selectedFromDate}
            onChange={(e:React.ChangeEvent<HTMLInputElement>) => handleDateChange(e)}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            data-test="fromDate-textField"
            name="fromDate"
            error={dateError}
            helperText={dateErrorText}
        
          />
        </Grid>
        <Grid item>
          <TextField
            type="date"
            label={t("Projects.ActivityTab.toLabel")}
            // format="yyyy/mm/dd"
            value={selectedToDate}
            onChange={(e:React.ChangeEvent<HTMLInputElement>) => handleDateChange(e)}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            data-test="toDate-textField"
            name="toDate"
            error={dateError}
            helperText={dateErrorText}
          
          />
        </Grid>
      </Grid>
      <div
        className="activityMasterContianer"
        data-test="activityMaster-container"
      >
        <Card className={classes.activitiesCard}>
          <CardContent className="titleHeader">
            <Grid container xs={12} spacing={3}>
              <Grid item xs={1} className={classes.type}>
                <span className="activityHeader">
                  {t("Projects.ActivityTab.typeLabel")}
                </span>
              </Grid>
              <Grid item xs={3}>
                <span className="activityHeader">
                  {t("Projects.ActivityTab.dateLabel")}
                </span>
              </Grid>
              <Grid item xs={2}>
                <span className="activityHeader">
                  {t("Projects.ActivityTab.userLabel")}
                </span>
              </Grid>
              <Grid item xs={6} className="messageStyle">
                <span className="activityHeader">
                  {t("Projects.ActivityTab.messageLabel")}
                </span>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {props.fetchingOrgActivities &&
          currentPage === 1 &&
          [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((val) => {
            return (
              <CardContent key={val} data-test="skeleton">
                <Grid container md={12}>
                  <Grid item md={1}>
                    <Skeleton circle width={40} height={40} />
                  </Grid>

                  <Grid item md={3}>
                    <Skeleton height={20} width="50%" />
                  </Grid>

                  <Grid item md={2}>
                    <Skeleton height={20} width="60%" />
                  </Grid>

                  <Grid item md={6}>
                    <Skeleton height={20} width="60%" />
                  </Grid>
                </Grid>
              </CardContent>
            );
          })}
        <Card className={classes.activitiesCard} >
          {props.orgActivities && (
            <InfiniteScroll
              data-test="infiniteScroll-component"
              loader=""
              dataLength={props.orgActivities.length ?? 0}
              next={fetchMoreActivities}
              hasMore={props.orgActivities.length === currentPage * limit}
              scrollThreshold={0.95}
            >
              {props.orgActivities && props.orgActivities.length === 0 && (
                <div>
                  <CardContent data-test="noOrgActivities">
                    <Grid container>
                      <i style={{ textAlign: "center", width: "100%" }}>
                        {isResetValid()
                          ? t("Projects.ActivityTab.noActivityMatchingError")
                          : t("Projects.ActivityTab.noActivityError")}
                      </i>
                    </Grid>
                  </CardContent>
                </div>
              )}

              {props.orgActivities &&
                props.orgActivities.length > 0 &&
                props.orgActivities.map((item:OrganizationActivity) => {
                  let username = item.user
                    ? item.user.first_name + " " + item.user.last_name
                    : "";
                  let date = getDateInStandardFormat(item.createdat);
                  let moduleData:JSX.Element = <></>;
                  let actionData = "";
                  let actionIcon = <></>;
                  let _to = "";
                  switch (item.module) {
                    case "organization":
                      _to = "/organization/" + item.organization_id;
                      moduleData = (
                        <Link to={_to} className="activityInfoLink">
                          {item.remarks}
                        </Link>
                      );
                      break;
                    case "member":
                      _to = `/organization/${item.organization_id}#groups`;
                      moduleData = (
                        <Link to={_to} className="activityInfoLink">
                          {item.remarks}
                        </Link>
                      );
                      break;
                    case "group":
                      _to = `/organization/${item.organization_id}#groups`;
                      moduleData = (
                        <Link to={_to} className="activityInfoLink">
                          {item.remarks}
                        </Link>
                      );
                      break;
                    case "cluster":
                      _to = `/organization/${item.organization_id}#admin_cluster`;
                      moduleData = (
                        <Link to={_to} className="activityInfoLink">
                          {item.remarks}
                        </Link>
                      );
                      break;
                    case "subscription":
                      _to = `/organization/${item.organization_id}#admin_subscription`;
                      moduleData = (
                        <Link to={_to} className="activityInfoLink">
                          {item.remarks}
                        </Link>
                      );
                      break;
                    case "registry":
                      _to = `/organization/${item.organization_id}/#admin_registry`;
                      moduleData = (
                        <Link to={_to} className="activityInfoLink">
                          {item.remarks}
                        </Link>
                      );
                      break;
                    case "dns":
                      _to = `/organization/${item.organization_id}/#admin_dns`;
                      moduleData = (
                        <Link to={_to} className="activityInfoLink">
                          {item.remarks}
                        </Link>
                      );
                      break;
                    case "resource":
                      _to = `/organization/${item.organization_id}/#admin_resource`;
                      moduleData = (
                        <Link to={_to} className="activityInfoLink">
                          {item.remarks}
                        </Link>
                      );
                      break;
                    case "chart-repo":
                      _to = `/organization/${item.organization_id}/#admin_chartRepos`;
                      moduleData = (
                        <Link to={_to} className="activityInfoLink">
                          {item.remarks}
                        </Link>
                      );
                      break;
                    default:
                      moduleData = <></>;
                  }

                  switch (item.action) {
                    case "create":
                      actionData = "Created";
                      actionIcon = (
                        <BuildOutlinedIcon fontSize="small" color="primary" />
                      );
                      break;
                    case "update":
                      actionData = "Updated";
                      actionIcon = (
                        <EditOutlinedIcon fontSize="small" color="primary" />
                      );
                      break;
                    case "delete":
                      actionData = "Deleted";
                      actionIcon = (
                        <DeleteOutlineOutlinedIcon
                          fontSize="small"
                          color="error"
                        />
                      );
                      break;
                    case "share":
                      actionData = "Shared";
                      actionIcon = (
                        <PersonAddOutlinedIcon
                          fontSize="small"
                          color="action"
                        />
                      );
                      break;
                    case "unshare":
                      actionData = "Unshared";
                      actionIcon = (
                        <PersonAddDisabledIcon fontSize="small" color="error" />
                      );
                      break;
                    case "open":
                      actionData = "Opened";
                      actionIcon = (
                        <LaunchIcon fontSize="small" color="primary" />
                      );
                      break;
                    case "plan":
                      actionData = "Planned";
                      actionIcon = (
                        <LaunchIcon fontSize="small" color="primary" />
                      );
                      break;
                    case "destroy":
                      actionData = "Destroyed";
                      actionIcon = (
                        <HighlightOffIcon fontSize="small" color="error" />
                      );
                      break;
                    default:
                      actionData = item.action;
                      actionIcon = (
                        <CalendarTodayOutlinedIcon fontSize="small" />
                      );
                      break;
                  }
                  return (
                    <div key={item.id}>
                      <CardContent data-test="card-data">
                        <Grid
                          container
                          xs={12}
                          spacing={3}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Grid item xs={1} className={classes.type}>
                            <div className="iconCircle">{actionIcon}</div>
                          </Grid>
                          <Grid item xs={3}>
                            <p className="timeStyle" data-test="date-component">
                              {date} UTC
                            </p>
                          </Grid>

                          <Grid item xs={2} className="nameStyle">
                            <p data-test="username">{username}</p>
                          </Grid>

                          <Grid
                            item
                            xs={6}
                            className="messageStyle"
                            data-test="message"
                          >
                            <p>
                              {actionData} {item.module} {moduleData}{" "}
                              {item.extras}{" "}
                            </p>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </div>
                  );
                })}
            </InfiniteScroll>
          )}
        </Card>
      </div>
      {props.fetchingOrgActivities && (
        <BackdropLoader
          data-test="activity-loader"
          message={
            currentPage === 1
              ? t("Organization.ActivityTab.fetchingOrgActivity")
              : t("Projects.ActivityTab.fetchingMoreProjectActivity")
          }
        />
      )}
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state:any) => ({
  orgActivities: state.OrganizationReducer.orgActivities,
  fetchingOrgActivities: state.OrganizationReducer.fetchingOrgActivities,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch:Dispatch) => {
  return {
    fetchOrgActivities: (
      id:number,
      page:number,
      limit:number,
      action:string,
      userid:string,
      datestart:string,
      dateend:string,
      module:string,
    ) =>
      dispatch(
        fetchOrgActivities(
          id,
          page,
          limit,
          action,
          userid,
          datestart,
          dateend,
          module
        )
      ),
    clearOrgActivities: () => dispatch(clearOrgActivities()),
  };
};

const connector = connect(mapStateToProps, mapDispatchtoProps);
export default connector(withStyles(useStyles)(OrganizationActivityTab));
type PropsFromRedux = ConnectedProps<typeof connector>;
