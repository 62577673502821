const initialState = {
    backupList: null,
    backupRestoreList: null,
    fetchingBackupList: false,
    fetchingBackupRestoreList: false,
    backupSettings: null,
    fetchingBackupSettings: false,
    updatingBackupSettings: false,
    creatingSnapshot: false,
    deletingSnapshot: false,
    preservingSnapshot: false,
    restoringSnapshot: false,
    //checkRestoreStatusCompleted: false  
}

export default initialState