import React from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  DialogTitle,
  DialogActions,
  Grid,
  TextField,
  Button,
} from "@material-ui/core";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { updatePaymentSetting } from "../../pages/account/billingtab/redux/actions";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";
import * as Yup from "yup";
import { Autocomplete } from "@material-ui/lab";
import { AppConstants } from "../../constants/appconstants";
import { Dispatch } from "redux";
import { AddressFormModel, UserModel, CountryModel } from "../../models/Account.model"

const countries = require("../../constants/country-states.json");
// const postalcodeValidation = require("../../constants/postalcodeValidation.json");

type Props = {
  updateAddress: AddressFormModel,
  userDetails: UserModel,
    openPopup: boolean,
    handleClosePopup: Function,
    firstTimeSetup: boolean,
  updatePaymentSetting: (payload: AddressFormModel) => void,
}

export const AddressForm = (props: Props) => {
  const { updateAddress } = props;
  const [t] = useTranslation();

  const countriesList = React.useMemo(() => {
    return countries.map((country: CountryModel) => {
      return {
        name: country.name,
        phone: country.phone,
        code: country.countryCode,
      };
    });
  }, []);

  const getStates = (country: string) => {
    return countries.find((c: CountryModel) => c.name === country)?.stateProvinces ?? [];
  };

  // const getCountryCode = (country) => {
  //   return countries.find((c) => c.name === country)?.countryCode ?? "";
  // };

  const [stateList, setStateList] = React.useState(
    updateAddress?.country ? getStates(updateAddress.country) : []
  );

  const getCountryData = (country: string) => {
    return countriesList.find((c: CountryModel) => c.name === country) ?? {};
  };

  const getStateData = (state: string) => {
    return stateList.find((s: CountryModel) => s.name === state) ?? {};
  };

  const formik = useFormik({
    initialValues: {
      fullName:
        props.userDetails?.first_name && props.userDetails?.last_name
          ? `${props.userDetails?.first_name} ${props.userDetails?.last_name}`
          : "",
      email: props.userDetails?.email ?? "",
      country: updateAddress?.country
        ? getCountryData(updateAddress.country)
        : "",
      state: updateAddress?.state ? getStateData(updateAddress.state) : "",
      city: updateAddress?.city ?? "",
      street: updateAddress?.street ?? "",
      postalCode: updateAddress?.postal_code ?? "",
    },
    validationSchema: Yup.object().shape({
      country: Yup.string()
        .required(t("Billing.AddressForm.requiredCountry"))
        .nullable(),
      state: Yup.string()
        .required(t("Billing.AddressForm.requiredState"))
        .nullable(),
      city: Yup.string()
        .required(t("Billing.AddressForm.requiredCity"))
        .min(2, t("Projects.ProjectsTab.tooShortError"))
        // .max(20, t("Projects.ProjectsTab.tooLongError"))
        .matches(AppConstants.validationRegex?.streetaddress, {message : "City cannot contain special character except , and -"}),
      street: Yup.string()
        .required(t("Billing.AddressForm.requiredStreet"))
        .min(2, t("Projects.ProjectsTab.tooShortError"))
        // .max(50, t("Projects.ProjectsTab.tooLongError"))
        .matches(AppConstants.validationRegex?.streetaddress, {message : "Street cannot contain special character except , and -"}),
      postalCode: Yup.string()
        .required(t("Billing.AddressForm.requiredPostal"))
        .test("maxDigits", "Postal code cannot be longer than 6 digits.", (value: any) => (
          value && value.toString()?.length <= 6
        ))
        // .matches(AppConstants.validationRegex.default, "Not valid Postal code")
        // .when(['country'], (country, schema) => {
        //     console.log("country", country.code)
        //     const postal = postalcodeValidation.find((con) => {
        //       return con.territoryId === country.code
        //     })
        //     if(postal){
        //       const regex = new RegExp(`${postal.validation}`)
        //       return schema.matches(regex, "Not a valid postal code for selected country.")
        //     }else{
        //       return Yup.number().required("Postal code is required.")
        //     }
        // })
    }),
    onSubmit: (values) => {
      const payload = {
        country: values.country.name,
        state: values.state.name,
        city: values.city,
        street: values.street,
        postal_code: values.postalCode,
        promocode: updateAddress?.promocode ?? "",
      };
      props.updatePaymentSetting(payload);
      props.handleClosePopup();
    },
  });
  return (
    <>
      <Dialog
        open={props.openPopup}
        onClose={() => {props.handleClosePopup()}}
        keepMounted
        data-test="main-container"
      >
        <DialogTitle>
          <Typography className="dialogtitle">
            {updateAddress
              ? t("Billing.AddressForm.updateAddress")
              : t("Billing.AddressForm.addAddress")}
          </Typography>

          <IconButton
            aria-label="close"
            size="small"
            className="right"
            onClick={(e: React.MouseEvent<HTMLElement>) => {props.handleClosePopup()}}
            data-test="close-icon"
          >
            <CloseIcon />             
          </IconButton>
        </DialogTitle>

        <form onSubmit={formik.handleSubmit} onReset={formik.handleReset} data-test="form">
          <DialogContent dividers>
            {
              props.firstTimeSetup && (
                <>
                  <Typography variant="caption" color="error" style={{ display: "inline-block", marginBottom: 15 }}>
                    * You need to setup your billing address first to create projects or organizations.
                  </Typography>
                </>
              )
            }
            <Typography variant="body1" style={{marginBottom: 10}}>
              <em>{t("Billing.AddressForm.mandatoryField")}</em>
            </Typography>
            <Grid container spacing={2} style={{ padding: "10px 0" }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Email *"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={formik.values.email}
                  data-test="email-input"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Full Name *"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={formik.values.fullName}
                  data-test="name-input"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  // name="country"
                  fullWidth
                  options={countriesList}
                  value={formik.values.country}
                  onChange={(_, newValue) => {
                    if (!newValue) return;
                    formik.setFieldValue("country", newValue);
                    let _states = getStates(newValue.name);
                    formik.setFieldValue("state", _states[0]);
                    setStateList(_states);
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option.name}
                  renderOption={(option) => (
                    <>
                      {option.name} <Typography style={{fontSize: 12, marginLeft: 5}} variant="subtitle1"> ({option.code}) </Typography>
                    </>
                  )}
                  data-test="country-input"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Country *"
                      variant="outlined"
                      error={!!formik.errors.country}
                      helperText={<>{formik.errors.country ?? ""}</>}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  // name="state"
                  fullWidth
                  options={stateList}
                  value={formik.values.state}
                  onChange={(_, newValue) => formik.setFieldValue("state", newValue)}
                  autoHighlight
                  getOptionLabel={(option) => option.name}
                  renderOption={(option) => <>{option.name}</>}
                  data-test="state-input"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State *"
                      variant="outlined"
                      error={!!formik.errors.state}
                      helperText={<>{formik.errors.state ?? ""}</>}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="city"
                  label="City *"
                  variant="outlined"
                  fullWidth
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={!!formik.errors.city}
                  helperText={formik.errors.city ? formik.errors.city : ""}
                  data-test="city-input"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="street"
                  label="Street Address *"
                  variant="outlined"
                  fullWidth
                  value={formik.values.street}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={!!formik.errors.street}
                  helperText={formik.errors.street ? formik.errors.street : ""}
                  data-test="street-input"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="postalCode"
                  label="Postal Code *"
                  variant="outlined"
                  fullWidth
                  value={formik.values.postalCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={!!formik.errors.postalCode}
                  helperText={formik.errors.postalCode ? formik.errors.postalCode : ""}
                  data-test="postal-input"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              style={{ borderRadius: 2 }}
              disabled={!formik.isValid || !formik.dirty}
              type="submit"
              data-test="submit-btn"
            >
              {updateAddress
                ? t("Billing.AddressForm.updateForm")
                : t("Billing.AddressForm.submitForm")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  userDetails: state.AuthReducer.user,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    updatePaymentSetting: (jsonBody: AddressFormModel) =>
      dispatch(updatePaymentSetting(jsonBody)),
  };
};

export default connect(mapStateToProps, mapDispatchtoProps)(AddressForm);
