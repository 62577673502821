const PREFIX = '@CREATE_PROJECT';

export const CREATE_PROJECT_CALL = `${ PREFIX }/CREATE`
export const CREATE_PROJECT_CALL_SUCCESS = `${ PREFIX }/CREATE_SUCCESS`
export const CREATE_PROJECT_CALL_FAILURE = `${ PREFIX }/CREATE_FAILURE`
export const GET_SUBSCRIPTION_LIST_CALL = `${ PREFIX }/SUBSCRIPTION_LIST`
export const GET_SUBSCRIPTION_LIST_CALL_SUCCESS = `${ PREFIX }/SUBSCRIPTION_LIST_SUCCESS`
export const GET_SUBSCRIPTION_LIST_CALL_FAILURE = `${ PREFIX }/SUBSCRIPTION_LIST_FAILURE`

export const UPDATE_PROJECT_ICON = `${ PREFIX }/UPDATE_PROJECT_ICON`
//export const UPLOAD_PROJECT_ICON_SUCCESS = `${ PREFIX }/UPLOAD_PROJECT_ICON_SUCCESS`

export const RESET_CREATE_PROJECT_CALL_ERROR = `${ PREFIX }/RESET_ERROR`

const getSubscriptionListCall = (history) => ({
	type: GET_SUBSCRIPTION_LIST_CALL,
	data: {
		history,
	}
})

const createProjectApiCall = (payload, iconPayload, history) => ({
	type: CREATE_PROJECT_CALL,
	data: {
		payload,
		iconPayload,
		history,
	}
})

const updateProjectIcon = (id, iconPayload) => ({
	type: UPDATE_PROJECT_ICON,
	data: { id, iconPayload }
})

const resetCreateProjectCallError = () => ({
	type: RESET_CREATE_PROJECT_CALL_ERROR
})

export {
	createProjectApiCall,
	getSubscriptionListCall,
	updateProjectIcon,
	resetCreateProjectCallError
}