import React, {Component} from 'react';
import Stepper from '@material-ui/core/Stepper';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Step, StepButton, Typography, ButtonBase } from '@material-ui/core';
import Template from './template/Template'
import Generals from './generals/Generals'
import Resources from './resources/Resources'
// import Deploy from './deploy/Deploy'
import { connect, ConnectedProps } from 'react-redux';
import './CreateAppMaster.css';
import { fetchAppDetails, fetchAppAvailableResource, fetchGITBranches, fetchPluginDetailsById, clearPluginDetails, clearAppdetail, clearBranches } from '../../app/redux/actions'
import { fetchResources } from '../redux/actions';
import { fetchPluginVersions, clearPlugInVersions } from '../../app/createapp/redux/actions';
import { clearPluginVersionConfig } from "../../environment/redux/actions";
import { Prompt } from 'react-router-dom';
import { CreateApp_TemplateTypes } from '../../../constants/enums'
import { withTranslation, WithTranslation } from 'react-i18next';
import DeploymentForm from '../../../components/deploymentformbody/DeploymentForm';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from "redux";
import { CompleteData } from '../../../models/Environment.model';

interface MatchParams {
    appId: string;
}

type Json = {
    service_name : string,
    repo_id?: string,
    uid?: number,
}

interface Props extends WithTranslation, PropsFromRedux, RouteComponentProps<MatchParams> {}

type State = {
    activeStep : number,
    completeData: CompleteData|{},
    isBlocking: boolean,
    template_type: number
}

export class CreateAppMaster extends Component<Props, State, MatchParams> {
    constructor(props: Props){
        super(props);
        this.state = {
            activeStep : 0,
            completeData: {},
            isBlocking: true,
            template_type: 0
        }
        this.handleStepChange = this.handleStepChange.bind(this)
    }

    componentDidMount(){
        this.props.fetchAppDetails(this.props.match.params.appId)
    }
    componentWillUnmount() {
        this.props.clearPluginVersionConfig();
        this.props.clearPlugInVersions();
        this.props.clearPluginDetails();
        this.props.clearAppdetail();
        this.props.clearBranches();
    }

    getSteps = () => {
        return [
            { label: 'Initials', isDisabled: this.props.appDetails && this.props.appDetails.service_type === CreateApp_TemplateTypes.cr },
            { label: 'Generals', isDisabled: false },
            { label: 'Resources', isDisabled: false }
        ]
    }
    UNSAFE_componentWillReceiveProps = (newProps: Props) => {
        if(newProps.appDetails && newProps.appDetails.id > 0){
            if(newProps.appDetails?.chart_id){
                this.setState({
                    template_type: 1,
                    isBlocking: false
                })
            }
            else if(!this.props.appDetails.id || this.props.appDetails.id !== newProps.appDetails.id) 
            {
                const completeData = {...this.state.completeData , 
                    ...({
                        appName : newProps.appDetails.name,
                        application_id: newProps.appDetails.id,
                        //gitService: newProps.appDetails.git_service,
                        //gitRepository: newProps.appDetails.git_repository,
                        //gitRepoName: newProps.appDetails && newProps.appDetails.git_repository_info ? newProps.appDetails.git_repository_info.name : "",
                        service_type: newProps.appDetails.service_type,
                        region : newProps.appDetails && newProps.appDetails.cluster ? newProps.appDetails.cluster.region : "",
                        projectName: newProps.appDetails && newProps.appDetails.project ? newProps.appDetails.project.name : "",
                        project_id: newProps.appDetails.project_id,
                        zone: newProps.appDetails && newProps.appDetails.cluster ? newProps.appDetails.cluster.zone : "",
                    })
                }
                this.setState({
                    completeData
                })
                
                if (newProps.appDetails.plugin_id) {
                    //Template
                    this.props.fetchPluginDetailsById(newProps.appDetails.plugin_id);
                    this.props.fetchPluginsVersionAction(newProps.appDetails.plugin_id);
                }
                //Resources
                this.props.fetchResources()
                this.props.fetchAppAvailableResource(newProps.appDetails.id);
                if(newProps.appDetails.service_type !== CreateApp_TemplateTypes.default)
                {
                    if (newProps.appDetails.service_type === CreateApp_TemplateTypes.git) {
                        const jsonBody = {
                            service_name : newProps.appDetails.git_service,
                            repo_id: newProps.appDetails.git_repository,
                            uid: newProps.appDetails.owner_id,
                        }
                        this.props.fetchGITBranches(jsonBody, newProps.appDetails.service_type)
                    }
                    else if (newProps.appDetails.service_type === CreateApp_TemplateTypes.cr) {
                        const jsonBody = {
                            service_name : newProps.appDetails.image_service
                        }
                        this.props.fetchGITBranches(jsonBody, newProps.appDetails.service_type, newProps.appDetails.image_namespace, newProps.appDetails.image_repo);

                        this.setState({
                            activeStep: 1,
                        })
                    }
                }
            }
        }

        if(newProps.versionsList && newProps.appDetails?.service_type === CreateApp_TemplateTypes.cr){
            this.setState({
                completeData: {...this.state.completeData, versionId : newProps.versionsList.id}
            })
        }
    }

    handleStepChange(stepNumber: number,data={}){
        const completeData = {...this.state.completeData , ...data}
        this.setState({
            activeStep : stepNumber,
            completeData
        })
    }

    handleStep = (step: number) => {
        this.setState({
            activeStep : step,
        })
    };

    tabChanger = () => {
        (this.props.appDetails && this.props.appDetails.id)? 
        this.props.history.push(`/app/${this.props.appDetails.id}`)
        :this.props.history.goBack();
    }

    updateBlockInfo = () => { 
        this.setState({ isBlocking: false });
    }

    render () {
        const steps = this.getSteps();
        const { t } = this.props;
        return (
            <div data-test="main-container">
                <Prompt
                    when={this.state.isBlocking}
                    message={() =>
                        t('CronJob.CronJobList.errorMessage')
                    }//  ${location.pathname}
                />
                <ButtonBase
                    onClick={this.tabChanger}
                    style={{ color: "#357dfd", marginBottom: 20 }}
                    data-test="backButton"
                >
                    <ArrowBackIcon fontSize="small" />{" "}
                    <span className="jobNav">{t('App.CreateApp.back')}</span>
                </ButtonBase>
                <Typography variant="h4" color="primary" align="center"> {t('Environment.CreateAppMaster.createEnv')} </Typography>
                {
                    this.state.template_type === 1 &&
                    <>
                        <DeploymentForm history={this.props.history} appDetails={this.props.appDetails} />
                    </>
                }
                {
                    this.state.template_type !== 1 &&
                    <>
                        <Stepper style={{ background: '#f6f8fa', paddingLeft: '0px' }} activeStep={this.state.activeStep} alternativeLabel>
                            {steps.map((item, index) => (
                                <Step key={item.label} disabled={item.isDisabled || this.state.activeStep <= index} data-test="step">
                                    {/* <StepLabel>{label}</StepLabel> */}
                                    <StepButton onClick={() => this.handleStep(index)} data-test="step-btn">
                                        {item.label}
                                    </StepButton>
                                </Step>
                            ))}
                        </Stepper>
                        <Template activeStep={this.state.activeStep} handleChange={this.handleStepChange} data-test="template-step" />
                        {"application_id" in this.state.completeData && <Generals activeStep={this.state.activeStep} completeData={this.state.completeData} handleChange={this.handleStepChange} goBack={this.handleStep} data-test="generals-step" />}
                        {"application_id" in this.state.completeData && <Resources activeStep={this.state.activeStep} completeData={this.state.completeData} handleChange={this.handleStepChange} history={this.props.history} updateBlockInfo={this.updateBlockInfo} goBack={this.handleStep} data-test="resources-step" />}
                        {/* <Deploy activeStep={this.state.activeStep} completeData={this.state.completeData} history={this.props.history}/>  */}
                    </>
                }
            </div>
        )
    }
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
    appDetails: state.AppsReducer.appDetails,
    versionsList: state.CreateAppReducer.versionsList,
})

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    fetchAppDetails : (payload: string) => dispatch(fetchAppDetails(payload)),
    fetchPluginsVersionAction : (id: number) => dispatch(fetchPluginVersions(id)),
    fetchPluginDetailsById : (id: number) => dispatch(fetchPluginDetailsById(id)),
    fetchResources : () => dispatch(fetchResources()),
    fetchAppAvailableResource : (id: number) => dispatch(fetchAppAvailableResource(id)),
    fetchGITBranches: (jsonBody: Json, type: number, orgName?: string, repoName?: string) => dispatch(fetchGITBranches(jsonBody, type, orgName, repoName)),
    clearPluginVersionConfig: () => dispatch(clearPluginVersionConfig()),
    clearPlugInVersions: () => dispatch(clearPlugInVersions()),
    clearPluginDetails: () => dispatch(clearPluginDetails()),
    clearAppdetail: () => dispatch(clearAppdetail()),
    clearBranches: () => dispatch(clearBranches()),
  }
}

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withTranslation()(CreateAppMaster));