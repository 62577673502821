const initialState = {
    id: null,
    firstName: null,
    lastName: null,
    email: null,
    signupComplete: false,
    isFetching: false,
    sessionToken: '',
    token:'',
    user: null,
    oAuthRegisterData: {},
    emailVerifyDetails : {},
    isResendVerifyEmail : false,
    resendVerifyEmail : '',
    executingRequestInvite: false,
    invitationDetails: null,
    isRequestingForgotPassword: false
}

export default initialState;