const COMMON_PREFIX = '@COMMON';

export const UPLOAD_FILE = `${ COMMON_PREFIX }/UPLOAD_FILE`
export const UPLOAD_FILE_SUCCESS = `${ COMMON_PREFIX }/UPLOAD_FILE_SUCCESS`
export const UPLOAD_FILE_FAILURE = `${ COMMON_PREFIX }/UPLOAD_FILE_FAILURE`

export const AUTOCOMPLETE_SEARCH = `${ COMMON_PREFIX }/AUTOCOMPLETE_SEARCH`
export const AUTOCOMPLETE_SEARCH_SUCCESS = `${ COMMON_PREFIX }/AUTOCOMPLETE_SEARCH_SUCCESS`
export const AUTOCOMPLETE_SEARCH_FAILURE = `${ COMMON_PREFIX }/AUTOCOMPLETE_SEARCH_FAILURE`

export const UPLOAD_MULTIPLE_FILES = `${ COMMON_PREFIX }/UPLOAD_MULTIPLE_FILES`
export const CLEAR_SUGGESTIONS = `${ COMMON_PREFIX }/CLEAR_SUGGESTIONS`

const uploadFile = (formData, callBack, isPrivate) => ({
	type: UPLOAD_FILE,
	data: { formData, callBack, isPrivate }
})

const uploadMultipleFiles = (files, callBack) => ({
	type: UPLOAD_MULTIPLE_FILES,
	data: { files, callBack }
})

const autocompleteSearch = (query) => ({
	type: AUTOCOMPLETE_SEARCH,
	data: { query }
})

const clearSuggestions = () =>{ return {
	type: CLEAR_SUGGESTIONS,
}}

export {
	uploadFile,
	uploadMultipleFiles,
	autocompleteSearch,
	clearSuggestions,
}