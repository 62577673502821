//import Tabs from "components/Tabs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import { CdsButton } from "@cds/react/button";
// import { CdsIcon } from "@cds/react/icon";
// import Alert from "components/js/Alert";
import { isEqual } from "lodash";
import { parseValues, retrieveBasicFormParams, setValue } from "./SchemaUtils";
//import { DeploymentEvent, IBasicFormParam, IChartState } from "../../shared/types";
import { getValueFromEvent } from "./SchemaUtils";
// import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
// import LoadingWrapper from "../LoadingWrapper/LoadingWrapper";
import AdvancedDeploymentForm from "./AdvancedDeploymentForm";
import BasicDeploymentForm from "./BasicDeploymentForm";
// import DifferentialSelector from "./DifferentialSelector";
// import DifferentialTab from "./DifferentialTab";
import { Grid, FormControl, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";

// export interface IDeploymentFormBodyProps {
//   deploymentEvent: DeploymentEvent;
//   chartID: string;
//   chartVersion: string;
//   deployedValues?: string;
//   chartsIsFetching: boolean;
//   selected: IChartState["selected"];
//   appValues: string;
//   setValues: (values: string) => void;
//   setValuesModified: () => void;
// }

export function DeploymentFormBody({
  deploymentEvent,
  // chartID,
  // chartVersion,
  // deployedValues,
  // chartsIsFetching,
  selected,
  appValues,
  setValues,
  setValuesModified,
}) {
  const [basicFormParameters, setBasicFormParameters] = useState([]);
  //const [restoreModalIsOpen, setRestoreModalOpen] = useState(false);
  const [defaultValues, setDefaultValues] = useState("");
  const [viewType, setViewType] = useState("2");
  const [t] = useTranslation();
  const { schema, values } = selected;
 
  useEffect(() => {
    const params = retrieveBasicFormParams(appValues, schema);
    if (!isEqual(params, basicFormParameters)) {
      setBasicFormParameters(params);
    }
  }, [setBasicFormParameters, schema, appValues, basicFormParameters]);

  useEffect(() => {
    setDefaultValues(values || "");
  }, [values]);

  useEffect(() => {
    if (schema) {
      setViewType("1");
    }
  },[schema])

  const handleValuesChange = (value) => {
    setValues(value);
    setValuesModified();
  };
  // const refreshBasicParameters = () => {
  //   setBasicFormParameters(retrieveBasicFormParams(appValues, schema));
  // };
    
  const handleBasicFormParamChange = (param) => {
    const parsedDefaultValues = parseValues(defaultValues);
    return (e) => {
      setValuesModified();
      if (parsedDefaultValues !== defaultValues) {
        setDefaultValues(parsedDefaultValues);
      }
      const value = getValueFromEvent(e);
      setBasicFormParameters(
        basicFormParameters.map(p => (p.path === param.path ? { ...param, value } : p)),
      );
      // Change raw values
      setValues(setValue(appValues, param.path, value));
    };
  };
  // The basic form should be rendered if there are params to show
  const shouldRenderBasicForm = () => {
    return Object.keys(basicFormParameters).length > 0;
  };

  // const closeRestoreDefaultValuesModal = () => {
  //   setRestoreModalOpen(false);
  // };

  // const openRestoreDefaultValuesModal = () => {
  //   setRestoreModalOpen(true);
  // };

  // const restoreDefaultValues = () => {
  //   if (selected.values) {
  //     setValues(selected.values);
  //     setBasicFormParameters(retrieveBasicFormParams(selected.values, selected.schema));
  //   }
  //   setRestoreModalOpen(false);
  // };

  const handleChangeViewType = (e) => {
    setViewType(e.target.value);
  }
  
  return (
    <div>
      <Grid container justifyContent="center" alignItems="center">
        {
          shouldRenderBasicForm() &&
          <Grid item xs={12} className="alignCenter">
            <FormControl component="fieldset">
              <RadioGroup row aria-label="Form type" name="formtype" value={viewType} onChange={handleChangeViewType} data-test="view-type">
                <FormControlLabel value={"1"} control={<Radio color="primary" />} label="Form" />
                <FormControlLabel value={"2"} control={<Radio color="primary" />} label="YAML" />
              </RadioGroup>
            </FormControl>
          </Grid>
        }
        {
          shouldRenderBasicForm() && viewType === "1" &&
          <Grid item xs={12}>
            <BasicDeploymentForm
              deploymentEvent={deploymentEvent}
              params={basicFormParameters}
              handleBasicFormParamChange={handleBasicFormParamChange}
              appValues={appValues}
              handleValuesChange={handleValuesChange}
            />
          </Grid>
        }
        {
          viewType === "2" &&
          <Grid item xs={12}>
            <AdvancedDeploymentForm
              appValues={appValues}
              handleValuesChange={handleValuesChange}
              key="advanced-deployment-form"
            >
              <p>
                <b> {t("HelmEnvironment.note")}</b> {t("HelmEnvironment.noteText")}
              </p>
            </AdvancedDeploymentForm>
          </Grid>
        }
      </Grid>
    </div>
  );
}

export default DeploymentFormBody;