import { Grid } from '@material-ui/core'
import React from 'react'

type Props = {
    type?: string;
    module?: string;
    alt?: string;
    className?: string;
    message: string|JSX.Element;
}
export default function NoContentImage(props: Props) {
    const [src, setSrc] = React.useState("")

    React.useEffect(() => {
        let _src = ""
        switch (props.type) {
            case "project":
                _src = "/images/infographics/no_proj.svg"
                break
            case "app":
                _src = "/images/infographics/no_app.svg"
                break
            case "env":
                _src = "/images/infographics/no_env.svg"
                break
            case "support":
                _src = "/images/infographics/no_proj.svg"
                break
            case "search":
                _src = "/images/infographics/search.svg"
                break
            case "404":
                _src = "/images/infographics/404.svg"
                break
            default:
                _src = "/images/infographics/no_proj.svg"
        }
        setSrc(_src)
    }, [props.type])

    return (
        <Grid item xs className="imgGrid" data-test="image-grid">
            <img
                src={src}
                alt={props.alt ?? `No ${props.module ?? 'content'}`}
                className={props.className ?? "defaultImg"}
                data-test="no-content-image"
            />
            <p data-test="message-container">{props.message ?? `No ${props.module ?? 'content'} found`}</p>
        </Grid>
    )
}