import React from "react";
import { Card, CardHeader, CardContent, Divider } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { HealthCheckModel } from "../../models/Environment.model";
import KeyValueRow from "../keyvaluerow/KeyValueRow";

export const HealthCheckCard = (props: HealthCheckModel) => {
  const [t] = useTranslation();

  const getHealthCheckUri = () => {
    if (props.httpGet) {
      const host = props.httpGet.host || "[host]";

      const scheme = props.httpGet.scheme
        ? props.httpGet.scheme?.toLocaleLowerCase()
        : "http";

      let uri = `${scheme}://${host}`;

      if (props.httpGet.port) {
        uri += `:${props.httpGet.port}`;
      }

      if (props.httpGet.path) {
        uri += props.httpGet.path;
      }

      return (
        <KeyValueRow
          rowKey={t("Pods.HealthCheck.http")}
          rowValue={uri}
          keyXs={6}
          keyMd={6}
          data-test="http-uri"
        />
      );
    }

    if (props.tcpSocket) {
      let addr = props.tcpSocket.host || "[host]";

      if (props.tcpSocket.port) {
        addr += `:${props.tcpSocket.port}`;
      }

      return (
        <KeyValueRow
          rowKey={t("Pods.HealthCheck.tcp")}
          rowValue={addr}
          keyXs={6}
          keyMd={6}
          data-test="tcp-socket"
        />
      );
    }

    if (props.exec) {
      // let cmd = props.exec.command.join(" ");

      return (
        <>
          <KeyValueRow
            rowKey={t("Pods.HealthCheck.exec")}
            rowValue={""}
            isRowValueOneLine={false}
            keyXs={6}
            keyMd={6}
            data-test="exec-command"
          />
          <div className="execCommand">
            {
              props.exec.command.map((el:any, i:any) => {
                return <div className="whiteSpacePre" key={i}>{decodeURI(el)}</div>
              })
            }
          </div>
        </>
      );
    }
  };

  return (
    <Card data-test="health-check-card">
      <CardHeader title={props.title} />
      <Divider />
      <CardContent>
        <KeyValueRow
          rowKey={t("Pods.HealthCheck.initialDelay")}
          rowValue={(props.initialDelaySeconds).toString()}
          keyXs={6}
          keyMd={6}
          data-test="initial-delay"
        />
        <KeyValueRow
          rowKey={t("Pods.HealthCheck.timeout")}
          rowValue={(props.timeoutSeconds).toString()}
          keyXs={6}
          keyMd={6}
          data-test="timeout"
        />
        <KeyValueRow
          rowKey={t("Pods.HealthCheck.probePeriod")}
          rowValue={(props.periodSeconds).toString()}
          keyXs={6}
          keyMd={6}
          data-test="probe-period"
        />
        <KeyValueRow
          rowKey={t("Pods.HealthCheck.successThreshold")}
          rowValue={(props.successThreshold).toString()}
          keyXs={6}
          keyMd={6}
          data-test="success-threshold"
        />
        <KeyValueRow
          rowKey={t("Pods.HealthCheck.failureThreshold")}
          rowValue={(props.failureThreshold).toString()}
          keyXs={6}
          keyMd={6}
          data-test="failure-threshold"
        />
        {getHealthCheckUri()}
      </CardContent>
    </Card>
  );
};

export default HealthCheckCard;
