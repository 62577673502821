import React from "react"
import {
  Grid,
  Link,
  Divider
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { OperatorClass } from "../../../models/Operator.model";

/* istanbul ignore next */
const useStyles = makeStyles({
  operatorInfo: {
    marginBottom: "10px",
  },
  operatorInfoLabel: {
    fontWeight: 600,
  },
  });

type Props = {
  operatorDetails:OperatorClass;
}

export const ResourceDetail = (props:Props)=>{
  const {operatorDetails} = props

  const classes = useStyles();

     return(
      
        <div data-test="main-container">
            
            <Grid container className={classes.operatorInfo} direction="column">
              <Grid item xs={4} className={classes.operatorInfoLabel}>
                Repository
              </Grid>
              <Grid item xs={8}>
                <Link
                  href= "repository"
                  underline="hover"
                  target="_blank"
                  rel="noopener"
                >
                  {operatorDetails?.repository}
                </Link>
              </Grid>
            </Grid>
            <Divider />
            <Grid container className={classes.operatorInfo} direction="column">
              <Grid item xs={4} className={classes.operatorInfoLabel}>
                Provider
              </Grid>
              <Grid item xs={8}>
              {operatorDetails?.provider}
              </Grid>
            </Grid>
            <Divider />
            <Grid container className={classes.operatorInfo} direction="column">
              <Grid item xs={4} className={classes.operatorInfoLabel}>
                Container Image
              </Grid>
              <Grid item xs={8}>
              {operatorDetails?.containerImage}
              </Grid>
            </Grid>
         
        </div>
   
     )
}

export default  ResourceDetail