const initialState = {
    createProjectCallStarted : false,
    createProjectCallSuccess : false,
    createProjectCallFailure : false,
    createProjectCallError : null, 
    getSubscriptionListCallStarted : false,
    getSubscriptionListCallSuccess : false,
    getSubscriptionListCallFailure : false,
    subscriptionList : null,
    creatingProject: false
    //postProjectIconUploadData: {}
}

export default initialState
