import {
  Avatar,
  ButtonBase,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  // TableRow,
  Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import AddGroupPopup from "../../../../components/addgrouppopup/AddGroupPopup";
import ConfirmActionPopup from "../../../../components/confirmactionpopup/ConfirmActionPopup";
import CustomButton from "../../../../components/custombutton/CustomButton";
import BackdropLoader from "../../../../components/loader/BackdropLoader";
import { isAuthorizedOrg } from "../../../../helpers/utils";
import { currentOrgRole } from "../../../login/redux/selectors";
import {
  addMemberToGroup,
  deleteMemberFromGroup,
  getGroupInfo,
  clearGroupInfo,
} from "../../redux/actions";
import {
  TableCellStyled as StyledTableCell,
  StyledTableRow,
} from "../../../../components/styledtabelcell/StyledTabelCell";
import { useTranslation } from "react-i18next";
import { Dispatch } from "redux";
import { OrgGroupModel } from "../../../../models/Organization.model";
import { UserModel } from "../../../../models/Account.model";

// export const StyledTableCell = withStyles(() => ({
//   head: {
//     // backgroundColor: theme.palette.background.default,
//     // color: 'grey',
//     fontWeight: 500,
//   },
//   body: {
//     color: "#555",
//     fontSize: 14,
//   },
// }))(TableCell);

// export const StyledTableRow = withStyles(() => ({
//   root: {},
// }))(TableRow);

type Payload = {
  email: string
}

interface Props extends PropsFromRedux {
  group: OrgGroupModel,
  handleBack:()=>void
}

export function GroupInfo(props: Props) {
  const [deleteMemberPopupOpen, setDeleteMemberPopupOpen] = useState(false);
  const [addMemberPopupOpen, setAddMemberPopupOpen] = useState(false);
  const [toDeletememberid, setToDeleteMemberid] = useState<any>(null);
  const [t] = useTranslation();

  useEffect(() => {
    props.getGroupInfo(props.group?.id);
    return () => {
      props.clearGroupInfo();
    };
  }, [props.group]);

  const handleDeleteUser = (userId: number) => {
    setToDeleteMemberid(userId);
    setDeleteMemberPopupOpen(true);
  };

  const handleAddMember = () => {
    setAddMemberPopupOpen(true);
    // this.setState({
    //   isAddUserPopupOpened : Math.random(),
    // })
  };

  const addMemberAgreeHandler = (email: string) => {
    const payload = { email };
    setAddMemberPopupOpen(false);
    props.addMemberToGroup(props.groupInfo?.id, payload);
  };

  const addMemberDisagreeHandler = () => {
    setAddMemberPopupOpen(false);
  };

  const deleteMemberAgreeHandler = () => {
    const member = props.groupInfo?.members?.find((m: UserModel) => {
      if (m.id === toDeletememberid) {
        return m;
      }
    });
    let email = "";
    if (member) {
      email = member?.email;
    }
    const payload = { email: email };
    setDeleteMemberPopupOpen(false);
    props.deleteMemberFromGroup(props.groupInfo?.id, payload);
  };

  const deleteMemberDisagreeHandler = () => {
    setDeleteMemberPopupOpen(false);
  };

  const { groupInfo } = props;
  return (
    <div data-test="group-info-container">
      <div className="m-b-20">
        <ButtonBase onClick={props.handleBack} style={{ color: "#357dfd" }}>
          <ArrowBackIcon fontSize="small" />{" "}
          <span className="jobNav">
            {t("Organization.GroupInfo.groupList")}
          </span>
        </ButtonBase>
      </div>
      <div data-test="group-name">
        <Typography variant="h5" color="textPrimary">
          {" "}
          {groupInfo.Name}{" "}
        </Typography>
      </div>
      {isAuthorizedOrg("addUser", props.currentOrgRole) && (
        <div
          className="alignRight addButtonContainer"
          data-test="add-member-button"
        >
          <CustomButton
            label={t("Organization.GroupInfo.addMember")}
            onClick={() => handleAddMember()}
          />
        </div>
      )}
      <div data-test="member-list-container">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell>
                  {t("Organization.DnsTab.name")}
                </StyledTableCell>
                <StyledTableCell>
                  {t("Organization.GroupInfo.email")}
                </StyledTableCell>
                {/* {isAuthorized("delete", projectRole.name) && ( */}
                <StyledTableCell>
                  {t("Organization.DnsTab.actions")}
                </StyledTableCell>
                {/* )} */}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {groupInfo?.members?.length > 0 &&
                groupInfo.members?.map((member: UserModel) => (
                  <StyledTableRow key={member.id}>
                    <StyledTableCell>
                      {" "}
                      <Avatar
                        src={
                          member && member.image
                            ? member.image
                            : "./Images/profile_image.png"
                        }
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      {member?.first_name + " " + member?.last_name}
                      {/* <Chip className='userRole' label="owner" /> */}
                    </StyledTableCell>
                    <StyledTableCell>{member?.email}</StyledTableCell>
                    {isAuthorizedOrg("delete", props.currentOrgRole) && (
                      <StyledTableCell>
                        <IconButton
                          data-test="delete-icon"
                          onClick={() => handleDeleteUser(member.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </StyledTableCell>
                    )}
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
          {groupInfo?.members?.length === 0 && (
            <div className="alignCenter">
              <p>{t("Organization.GroupInfo.noMembers")}</p>
            </div>
          )}
        </TableContainer>
      </div>
      {/* <AddMemberPopup
        open={addMemberPopupOpen}
        handleAgree={addMemberAgreeHandler}
        handleDisagree={addMemberDisagreeHandler}
        rolesData={roles}
      /> */}
      <AddGroupPopup
        open={addMemberPopupOpen}
        handleAgree={addMemberAgreeHandler}
        handleDisagree={addMemberDisagreeHandler}
        title={t("Organization.GroupInfo.addMember")}
        member={true}
      />
      <ConfirmActionPopup
        open={deleteMemberPopupOpen}
        message={t("Organization.GroupInfo.removeMembers")}
        handleAgree={deleteMemberAgreeHandler}
        handleDisAgree={deleteMemberDisagreeHandler}
        loading={props.deletingMember}
        yesText={t("Organization.GroupInfo.remove")}
      />
      {props.addingMember && (
        <BackdropLoader message={t("Organization.GroupInfo.addingUser")} />
      )}
      {props.deletingMember && (
        <BackdropLoader message={t("Organization.GroupInfo.deletingUser")} />
      )}
      {props.gettingGroupInfo && (
        <BackdropLoader message={t("Organization.GroupInfo.fetchingInfo")} />
      )}
    </div>
  );
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  organizationInfo: state.OrganizationReducer.organizationInfo,
  fetchingOrgInfo: state.OrganizationReducer.fetchingOrgInfo,
  addingMember: state.OrganizationReducer.addingMember,
  deletingMember: state.OrganizationReducer.deletingMember,
  groupInfo: state.OrganizationReducer.groupInfo,
  gettingGroupInfo: state.OrganizationReducer.gettingGroupInfo,
  currentOrgRole: currentOrgRole(state),
  // rolesData: state.AddUserPopupReducer.rolesData,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    addMemberToGroup: (id: number, data: Payload) => dispatch(addMemberToGroup(id, data)),
    deleteMemberFromGroup: (id: number, data: Payload) =>
      dispatch(deleteMemberFromGroup(id, data)),
    getGroupInfo: (id: number) => dispatch(getGroupInfo(id)),
    clearGroupInfo: () => dispatch(clearGroupInfo()),
  };
};

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(GroupInfo);
