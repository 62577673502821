const PREFIX = "@ORG_DETAILS";

export const GET_ORG_PLANS_LIST_CALL = `${PREFIX}/GET_ORG_PLANS_LIST_CALL`;
export const GET_ORG_PLANS_LIST_CALL_SUCCESS = `${PREFIX}/GET_ORG_PLANS_LIST_CALL_SUCCESS`;
export const GET_ORG_PLANS_LIST_CALL_FAILURE = `${PREFIX}/GET_ORG_PLANS_LIST_CALL_FAILURE`;

export const CREATE_ORG = `${PREFIX}/CREATE_ORG`;
export const CREATE_ORG_SUCCESS = `${PREFIX}/CREATE_ORG_SUCCESS`;
export const CREATE_ORG_FAILURE = `${PREFIX}/CREATE_ORG_FAILURE`;

export const GET_ORG_LIST = `${PREFIX}/GET_ORG_LIST`;
export const GET_ORG_LIST_SUCCESS = `${PREFIX}/GET_ORG_LIST_SUCCESS`;
export const GET_ORG_LIST_FAILURE = `${PREFIX}/GET_ORG_LIST_FAILURE`;

export const GET_ORG_INFO = `${PREFIX}/GET_ORG_INFO`;
export const GET_ORG_INFO_SUCCESS = `${PREFIX}/GET_ORG_INFO_SUCCESS`;
export const GET_ORG_INFO_FAILURE = `${PREFIX}/GET_ORG_INFO_FAILURE`;

export const GET_ORG_GROUPS = `${PREFIX}/GET_ORG_GROUPS`;
export const GET_ORG_GROUPS_SUCCESS = `${PREFIX}/GET_ORG_GROUPS_SUCCESS`;
export const GET_ORG_GROUPS_FAILURE = `${PREFIX}/GET_ORG_GROUPS_FAILURE`;

export const ADD_ORG_GROUP = `${PREFIX}/ADD_ORG_GROUP`;
export const ADD_ORG_GROUP_SUCCESS = `${PREFIX}/ADD_ORG_GROUP_SUCCESS`;
export const ADD_ORG_GROUP_FAILURE = `${PREFIX}/ADD_ORG_GROUP_FAILURE`;

export const DELETE_ORG_GROUP = `${PREFIX}/DELETE_ORG_GROUP`;
export const DELETE_ORG_GROUP_SUCCESS = `${PREFIX}/DELETE_ORG_GROUP_SUCCESS`;
export const DELETE_ORG_GROUP_FAILURE = `${PREFIX}/DELETE_ORG_GROUP_FAILURE`;

export const UPDATE_ORG_GROUP = `${PREFIX}/UPDATE_ORG_GROUP`;
export const UPDATE_ORG_GROUP_SUCCESS = `${PREFIX}/UPDATE_ORG_GROUP_SUCCESS`;
export const UPDATE_ORG_GROUP_FAILURE = `${PREFIX}/UPDATE_ORG_GROUP_FAILURE`;

export const CLEAR_ORG_INFO = `${PREFIX}/CLEAR_ORG_INFO`;

export const DELETE_ORG = `${PREFIX}/DELETE_ORG`;
export const DELETE_ORG_SUCCESS = `${PREFIX}/DELETE_ORG_SUCCESS`;
export const DELETE_ORG_FAILURE = `${PREFIX}/DELETE_ORG_FAILURE`;

export const ADD_MEMBER_TO_ORG = `${PREFIX}/ADD_MEMBER_TO_ORG`;
export const ADD_MEMBER_TO_ORG_SUCCESS = `${PREFIX}/ADD_MEMBER_TO_ORG_SUCCESS`;
export const ADD_MEMBER_TO_ORG_FAILURE = `${PREFIX}/ADD_MEMBER_TO_ORG_FAILURE`;

export const EDIT_MEMBER_TO_ORG = `${PREFIX}/EDIT_MEMBER_TO_ORG`;
export const EDIT_MEMBER_TO_ORG_SUCCESS = `${PREFIX}/EDIT_MEMBER_TO_ORG_SUCCESS`;
export const EDIT_MEMBER_TO_ORG_FAILURE = `${PREFIX}/EDIT_MEMBER_TO_ORG_FAILURE`;

export const DELETE_MEMBER_FROM_ORG = `${PREFIX}/DELETE_MEMBER_FROM_ORG`;
export const DELETE_MEMBER_FROM_ORG_SUCCESS = `${PREFIX}/DELETE_MEMBER_FROM_ORG_SUCCESS`;
export const DELETE_MEMBER_FROM_ORG_FAILURE = `${PREFIX}/DELETE_MEMBER_FROM_ORG_FAILURE`;

export const GET_GROUP_INFO = `${PREFIX}/GET_GROUP_INFO`;
export const GET_GROUP_INFO_SUCCESS = `${PREFIX}/GET_GROUP_INFO_SUCCESS`;
export const GET_GROUP_INFO_FAILURE = `${PREFIX}/GET_GROUP_INFO_FAILURE`;

export const ADD_MEMBER_TO_GROUP = `${PREFIX}/ADD_MEMBER_TO_GROUP`;
export const ADD_MEMBER_TO_GROUP_SUCCESS = `${PREFIX}/ADD_MEMBER_TO_GROUP_SUCCESS`;
export const ADD_MEMBER_TO_GROUP_FAILURE = `${PREFIX}/ADD_MEMBER_TO_GROUP_FAILURE`;

export const DELETE_MEMBER_FROM_GROUP = `${PREFIX}/DELETE_MEMBER_FROM_GROUP`;
export const DELETE_MEMBER_FROM_GROUP_SUCCESS = `${PREFIX}/DELETE_MEMBER_FROM_GROUP_SUCCESS`;
export const DELETE_MEMBER_FROM_GROUP_FAILURE = `${PREFIX}/DELETE_MEMBER_FROM_GROUP_FAILURE`;

export const SWITCH_ORG = `${PREFIX}/SWITCH_ORG`;
export const SWITCH_ORG_SUCCESS = `${PREFIX}/SWITCH_ORG_SUCCESS`;
export const SWITCH_ORG_FAILURE = `${PREFIX}/SWITCH_ORG_FAILURE`;

export const GET_REGISTRIES = `${PREFIX}/GET_REGISTRIES`;
export const GET_REGISTRIES_SUCCESS = `${PREFIX}/GET_REGISTRIES_SUCCESS`;
export const GET_REGISTRIES_FAILURE = `${PREFIX}/GET_REGISTRIES_FAILURE`;

export const GET_REGISTRY = `${PREFIX}/GET_REGISTRY`;
export const GET_REGISTRY_SUCCESS = `${PREFIX}/GET_REGISTRY_SUCCESS`;
export const GET_REGISTRY_FAILURE = `${PREFIX}/GET_REGISTRY_FAILURE`;

export const ADD_REGISTRY = `${PREFIX}/ADD_REGISTRY`;
export const ADD_REGISTRY_SUCCESS = `${PREFIX}/ADD_REGISTRY_SUCCESS`;
export const ADD_REGISTRY_FAILURE = `${PREFIX}/ADD_REGISTRY_FAILURE`;

export const UPDATE_REGISTRY = `${PREFIX}/UPDATE_REGISTRY`;
export const UPDATE_REGISTRY_SUCCESS = `${PREFIX}/UPDATE_REGISTRY_SUCCESS`;
export const UPDATE_REGISTRY_FAILURE = `${PREFIX}/UPDATE_REGISTRY_FAILURE`;

export const DELETE_REGISTRY = `${PREFIX}/DELETE_REGISTRY`;
export const DELETE_REGISTRY_SUCCESS = `${PREFIX}/DELETE_REGISTRY_SUCCESS`;
export const DELETE_REGISTRY_FAILURE = `${PREFIX}/DELETE_REGISTRY_FAILURE`;

export const GET_REGISTRY_CONFIG = `${PREFIX}/GET_REGISTRY_CONFIG`;
export const GET_REGISTRY_CONFIG_SUCCESS = `${PREFIX}/GET_REGISTRY_CONFIG_SUCCESS`;
export const GET_REGISTRY_CONFIG_FAILURE = `${PREFIX}/GET_REGISTRY_CONFIG_FAILURE`;

export const GET_CHART_CATALOGS = `${PREFIX}/GET_CHART_CATALOGS`;
export const GET_CHART_CATALOGS_SUCCESS = `${PREFIX}/GET_CHART_CATALOGS_SUCCESS`;
export const GET_CHART_CATALOGS_FAILURE = `${PREFIX}/GET_CHART_CATALOGS_FAILURE`;

export const GET_CATALOG_CATAGORY = `${PREFIX}/GET_CATALOG_CATAGORY`;
export const GET_CATALOG_CATAGORY_SUCCESS = `${PREFIX}/GET_CATALOG_CATAGORY_SUCCESS`;
export const GET_CATALOG_CATAGORY_FAILURE = `${PREFIX}/GET_CATALOG_CATAGORY_FAILURE`;

export const CLEAR_CHART_CATALOGS_INFO = `${PREFIX}/CLEAR_CHART_CATALOGS_INFO`;

export const GET_CHART_REPOS = `${PREFIX}/GET_CHART_REPOS`;
export const GET_CHART_REPOS_SUCCESS = `${PREFIX}/GET_CHART_REPOS_SUCCESS`;
export const GET_CHART_REPOS_FAILURE = `${PREFIX}/GET_CHART_REPOS_FAILURE`;

export const ADD_CHART_REPO = `${PREFIX}/ADD_CHART_REPO`;
export const ADD_CHART_REPO_SUCCESS = `${PREFIX}/ADD_CHART_REPO_SUCCESS`;
export const ADD_CHART_REPO_FAILURE = `${PREFIX}/ADD_CHART_REPO_FAILURE`;

export const GET_CHART_DETAIL = `${PREFIX}/GET_CHART_DETAIL`;
export const GET_CHART_DETAIL_SUCCESS = `${PREFIX}/GET_CHART_DETAIL_SUCCESS`;
export const GET_CHART_DETAIL_FAILURE = `${PREFIX}/GET_CHART_DETAIL_FAILURE`;

export const SYNC_CHART_REPO = `${PREFIX}/SYNC_CHART_REPO`;
export const SYNC_CHART_REPO_SUCCESS = `${PREFIX}/SYNC_CHART_REPO_SUCCESS`;
export const SYNC_CHART_REPO_FAILURE = `${PREFIX}/SYNC_CHART_REPO_FAILURE`;

export const DELETE_CHART_REPO = `${PREFIX}/DELETE_CHART_REPO`;
export const DELETE_CHART_REPO_SUCCESS = `${PREFIX}/DELETE_CHART_REPO_SUCCESS`;
export const DELETE_CHART_REPO_FAILURE = `${PREFIX}/DELETE_CHART_REPO_FAILURE`;

export const UPDATE_CHART_REPO = `${PREFIX}/UPDATE_CHART_REPO`;
export const UPDATE_CHART_REPO_SUCCESS = `${PREFIX}/UPDATE_CHART_REPO_SUCCESS`;
export const UPDATE_CHART_REPO_FAILURE = `${PREFIX}/UPDATE_CHART_REPO_FAILURE`;

export const CLEAR_CHART_REPO_INFO = `${PREFIX}/CLEAR_CHART_REPO_INFO`;

export const GET_DNS_BY_ID = `${PREFIX}/GET_DNS_BY_ID`;

export const GET_DNS = `${PREFIX}/GET_DNS`;
export const GET_DNS_SUCCESS = `${PREFIX}/GET_DNS_SUCCESS`;

export const CREATE_DNS = `${PREFIX}/CREATE_DNS`;
export const CREATE_DNS_SUCCESS = `${PREFIX}/CREATE_DNS_SUCCESS`;

export const DELETE_DNS = `${PREFIX}/DELETE_DNS`;
export const DELETE_DNS_SUCCESS = `${PREFIX}/DELETE_DNS_SUCCESS`;
export const DELETE_DNS_FAILURE = `${PREFIX}/DELETE_DNS_FAILURE`

export const UPDATE_DNS = `${PREFIX}/UPDATE_DNS`;
export const UPDATE_DNS_SUCCESS = `${PREFIX}/UPDATE_DNS_SUCCESS`;

export const CLEAR_DNS_INFO = `${PREFIX}/CLEAR_DNS_INFO`;

export const CLEAR_REGISTRY_INFO = `${PREFIX}/CLEAR_REGISTRY_INFO`;

export const CLEAR_GROUP_INFO = `${PREFIX}/CLEAR_GROUP_INFO`;

export const CLEAR_ORG_GROUPS = `${PREFIX}/CLEAR_ORG_GROUPS`;

export const CLEAR_REGISTRY_CONFIG = `${PREFIX}/CLEAR_REGISTRY_CONFIG`;

export const FETCH_ORG_ACTIVITIES = `${ PREFIX }/FETCH_ORG_ACTIVITIES`
export const FETCH_ORG_ACTIVITIES_SUCCESS = `${ PREFIX }/FETCH_ORG_ACTIVITIES_SUCCESS`
export const FETCH_ORG_ACTIVITIES_FAILURE = `${ PREFIX }/FETCH_ORG_ACTIVITIES_FAILURE`
export const CLEAR_ORG_ACTIVITIES = `${ PREFIX }/CLEAR_ORG_ACTIVITIES`

export const getChartCatalogs = (page, search, category, repository) => ({
  type: GET_CHART_CATALOGS,
  data: {
    page, search, category, repository
  }
})

export const clearChartCatalogs = () => ({
  type: CLEAR_CHART_CATALOGS_INFO,
})

export const getCatalogCatagories = () => ({
  type: GET_CATALOG_CATAGORY,
})

export const getChartRepos = () => ({
  type: GET_CHART_REPOS
})

export const fetchChartDetailById = (id) => ({
  type: GET_CHART_DETAIL,
  data:{
    id
  }
})

export const addChartRepo = (payload) => ({
  type: ADD_CHART_REPO,
  data: {
    payload
  }
})

export const syncRepo = (id) => ({
  type: SYNC_CHART_REPO,
  data: {
    id
  }
})

export const deleteChartRepo = (id) => ({
  type: DELETE_CHART_REPO,
  data: {
    id
  }
})

export const editChartRepo = (id, payload) => ({
  type: UPDATE_CHART_REPO,
  data: {
    id,
    payload
  }
})

export const clearReposInfo = () => ({
  type: CLEAR_CHART_REPO_INFO,
})

export const getRegistryConfig = () => ({
  type: GET_REGISTRY_CONFIG
})

export const clearRegistryConfig = () => ({
  type: CLEAR_REGISTRY_CONFIG
})

export const getOrgInfo = () => ({
  type: GET_ORG_INFO,
});

export const clearOrgInfo = () => ({
  type: CLEAR_ORG_INFO,
});

export const deleteOrg = (history) => ({
  type: DELETE_ORG,
  data: {
    history,
  },
});

export const getOrgPlansList = () => ({
  type: GET_ORG_PLANS_LIST_CALL,
});

export const createOrganization = (jsonBody, iconPayload, history) => ({
  type: CREATE_ORG,
  data: { jsonBody, iconPayload, history },
});

export const getOrgList = (history) => ({
  type: GET_ORG_LIST,
  data: { history }
});

export const switchOrg = (id, history, path, source, dependencyCallback) => ({
  type: SWITCH_ORG,
  data: { id, history, path, source, dependencyCallback },
});

export const addMemberToOrg = (data, memberCallBack) => ({
  type: ADD_MEMBER_TO_ORG,
  data: {data, memberCallBack}
});

export const editMemberToOrg = (id, jsonBody) => ({
  type: EDIT_MEMBER_TO_ORG,
  data: { id, jsonBody },
});

export const deleteMember = (data, isLeave, history) => ({
  type: DELETE_MEMBER_FROM_ORG,
  data: { data, isLeave, history}
});

export const getOrgGroups = () => ({
  type: GET_ORG_GROUPS,
});

export const clearOrgGroups = () => ({
  type: CLEAR_ORG_GROUPS,
});

export const addOrgGroup = (data) => ({
  type: ADD_ORG_GROUP,
  data
});

export const deleteOrgGroup = (id) => ({
  type: DELETE_ORG_GROUP,
  data: {id}
});

export const updateOrgGroup = (id, data) => ({
  type: UPDATE_ORG_GROUP,
  data: {id, data}
});

export const getGroupInfo = (id) => ({
  type: GET_GROUP_INFO,
  data: {id}
});

export const clearGroupInfo = () => ({
  type: CLEAR_GROUP_INFO,
});

export const addMemberToGroup = (id, data) => ({
  type: ADD_MEMBER_TO_GROUP,
  data: {id, data}
});

export const deleteMemberFromGroup = (gId, data) => ({
  type: DELETE_MEMBER_FROM_GROUP,
  data: {
    id : gId,
    data: data
  }
});

export const getRegistries = () => ({
  type: GET_REGISTRIES
});

export const clearRegistryInfo = () => ({
  type: CLEAR_REGISTRY_INFO
});

export const getRegistry = (id) => ({
  type: GET_REGISTRY,
  data: { id }
});

export const addRegistry = (jsonBody, callback) => ({
  type: ADD_REGISTRY,
  data: { jsonBody, callback }
});

export const updateRegistry = (id, jsonBody, callback) => ({
  type: UPDATE_REGISTRY,
  data: { id, jsonBody, callback }
});

export const deleteRegistry = (id) => ({
  type: DELETE_REGISTRY,
  data: { id }
});

export const getDns = () => ({
  type: GET_DNS
})

export const clearDnsInfo = () => ({
  type: CLEAR_DNS_INFO
})

export const createDns = (jsonBody, uploadBody, callback) => ({
	type: CREATE_DNS,
	data: { jsonBody, uploadBody, callback },
});

export const deleteDns = (dnsId, callback) => ({
  type: DELETE_DNS,
  data: { dnsId, callback }
})

export const updateDns = (dnsId, jsonBody, uploadBody, callback) => ({
  type: UPDATE_DNS,
  data: { dnsId, jsonBody, uploadBody, callback }
})

export const fetchOrgActivities = (
  id,
  page,
  limit,
  action,
  userid,
  datestart,
  dateend,
  module
) => ({
  type: FETCH_ORG_ACTIVITIES,
  data: { id, page, limit, action, userid, datestart, dateend, module },
});

export const clearOrgActivities = () => ({ type: CLEAR_ORG_ACTIVITIES });