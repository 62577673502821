// CODE ADAPTED FROM: https://sghall.github.io/react-compound-slider/#/slider-demos/horizontal
import React from "react";
import { Handles, Rail, 
  // GetHandleProps, GetTrackProps, SliderItem,
  Slider as ReactSlider, Tracks } from "react-compound-slider";
//import { Handle, Track } from "./components"; // example render components

// CODE FROM: https://sghall.github.io/react-compound-slider/#/slider-demos/horizontal

const sliderStyle = {
  margin: "1.2em",
  position: "relative",
  width: "90%",
};

const railStyle = {
  position: "absolute",
  width: "100%",
  height: 14,
  borderRadius: 7,
  cursor: "pointer",
  backgroundColor: "rgb(155,155,155)",
};

export class Slider extends React.Component {
  render() {
    const { min, max, step, values, onUpdate, onChange } = this.props;
    const domain = [min, max];
    return (
      <ReactSlider
        mode={1}
        step={step}
        domain={domain}
        rootStyle={{
          ...sliderStyle,
          ...this.props.sliderStyle,
        }}
        onUpdate={onUpdate}
        onChange={onChange}
        values={[values]}
      >
        <Rail>{({ getRailProps }) => <div style={railStyle} {...getRailProps()} />}</Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div className="slider-handles">
              {handles.map(handle => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  domain={domain}
                  getHandleProps={getHandleProps}
                />
              ))}
            </div>
          )}
        </Handles>
        <Tracks right={false}>
          {({ tracks, getTrackProps }) => (
            <div className="slider-tracks">
              {tracks.map(({ id, source, target }) => (
                <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
              ))}
            </div>
          )}
        </Tracks>
      </ReactSlider>
    );
  }
}

// *******************************************************
// HANDLE COMPONENT
// *******************************************************
export const Handle = ({
  domain: [min, max],
  handle: { id, value, percent },
  getHandleProps,
}) => (
  <div
    role="slider"
    aria-valuemin={min}
    aria-valuemax={max}
    aria-valuenow={value}
    style={{
      left: `${percent}%`,
      position: "absolute",
      marginLeft: "-11px",
      marginTop: "-6px",
      zIndex: 2,
      width: 24,
      height: 24,
      cursor: "pointer",
      borderRadius: "50%",
      boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.2)",
      backgroundColor: "#34568f",
    }}
    {...getHandleProps(id)}
    data-test="main-container"
  />
);

// *******************************************************
// TRACK COMPONENT
// *******************************************************
export const Track = ({ source, target, getTrackProps }) => (
  <div
    style={{
      position: "absolute",
      height: 14,
      zIndex: 1,
      backgroundColor: "#7aa0c4",
      borderRadius: 7,
      cursor: "pointer",
      left: `${source.percent}%`,
      width: `${target.percent - source.percent}%`,
    }}
    {...getTrackProps()}
    data-test="container"
  />
);

export default Slider;
