import { 
  FETCH_ENV_WORKFLOWS,
  FETCH_ENV_WORKFLOWS_SUCCESS,
  FETCH_ENV_WORKFLOWS_FAILURE,
  FETCH_MORE_ENV_WORKFLOWS_SUCCESS,
  FETCH_ENV_WORKFLOW_LOG_SUCCESS,
  UPDATE_ENV_WORKFLOW_LOG,
  UPDATE_ENV_WORKFLOWS,
  CLEAR_ENV_CI,
  FETCH_CICD_SETTINGS_SUCCESS,
  FETCH_CICD_SETTINGS_FAILURE,
  CLEAR_CICD_SETTINGS, 
  RERUN_ENV_CI_TRIGGERED,
  RERUN_ENV_CI_WORKFLOW,
  //RERUN_ENV_CI_WORKFLOW_SUCCESS,
  //RERUN_ENV_CI_WORKFLOW_FAILURE,
  CLEAR_RERUN_ENV_CI_TRIGGERED,
  UPDATE_CICD_SETTINGS,
  UPDATE_CICD_SETTINGS_SUCCESS,
  UPDATE_CICD_SETTINGS_FAILURE,
  STOP_ENV_CI_WORKFLOW,
  STOP_ENV_CI_WORKFLOW_SUCCESS,
  STOP_ENV_CI_WORKFLOW_FAILURE,
  GET_CI_STATS_SUCCESS,
  CLEAR_CI_STATS,
  GET_CI_STATS_FAILURE,
  GET_CI_STATS,
  FETCH_CICD_SETTINGS,
  TEST_EMAIL,
  TEST_EMAIL_FAILURE,
  TEST_EMAIL_SUCCESS,
  TEST_SLACK,
  TEST_SLACK_FAILURE,
  TEST_SLACK_SUCCESS,
  TEST_WEBHOOK,
  TEST_WEBHOOK_FAILURE,
  TEST_WEBHOOK_SUCCESS,
  GET_DEPLOYMENT_TYPES,
  GET_DEPLOYMENT_TYPES_FAILURE,
  GET_DEPLOYMENT_TYPES_SUCCESS,
  FETCH_CD_SETTINGS,
  FETCH_CD_SETTINGS_SUCCESS,
  FETCH_CD_SETTINGS_FAILURE,
  UPDATE_CD_SETTINGS,
  UPDATE_CD_SETTINGS_SUCCESS,
  UPDATE_CD_SETTINGS_FAILURE,
  FETCH_CD_LIST,
  FETCH_CD_LIST_SUCCESS,
  FETCH_CD_LIST_FAILURE,
  FETCH_CD_LIST_STATUS,
  FETCH_CD_LIST_STATUS_SUCCESS,
  FETCH_CD_LIST_STATUS_FAILURE,
  CLEAR_CD_LIST,
  FETCH_ENV_CI_LOG_SUCCESS,
  UPDATE_ENV_CI_LOG
} from './actions';
import initialState from './initialState';

const reducer = (state = initialState, payload ) => {
  switch (payload.type) {
    case FETCH_ENV_WORKFLOWS:
      return {
        ...state,
        fetchingEnvWorkflows: true
    }
    case FETCH_ENV_WORKFLOWS_SUCCESS:
      return {
        ...state,
        envWorkflows : payload.data ? payload.data : initialState.envWorkflows,
        envWorkflowsLoaded : true,
        fetchingEnvWorkflows: false
    }
    case FETCH_ENV_WORKFLOWS_FAILURE:
      return {
        ...state,
        fetchingEnvWorkflows: false

    }
    case FETCH_MORE_ENV_WORKFLOWS_SUCCESS:
      return {
        ...state,
        envWorkflows : [ ...state.envWorkflows, ...payload.data ],
        fetchingEnvWorkflows: false
    }
    case FETCH_ENV_WORKFLOW_LOG_SUCCESS: {
      let existingLogs = [ ...state.envWorkflowLog ];
      const _index = existingLogs.findIndex(x => x.name === payload.data.name);
      if(_index > -1)
        existingLogs[_index] = payload.data;
      else
        existingLogs.push(payload.data);
      
      return {
          ...state,
          envWorkflowLog : existingLogs
      }
    }
    case UPDATE_ENV_WORKFLOW_LOG: {
      return {
        ...state,
        envWorkflowLog: payload.data.workflowlogs
      }
    }
    case UPDATE_ENV_WORKFLOWS: {
      return {
        ...state,
        envWorkflows: payload.data.workflows
      }
    }
    case FETCH_ENV_CI_LOG_SUCCESS: {
      let existingLogs = [...state.envCILogs];
      const _index = existingLogs.findIndex(x => x.name === payload.data.name && x.task === payload.data.task && x.step === payload.data.step);
      if (_index > -1)
        existingLogs[_index] = payload.data;
      else
        existingLogs.push(payload.data);

      return {
        ...state,
        envCILogs: existingLogs
      }
    }
    case UPDATE_ENV_CI_LOG: {
      return {
        ...state,
        envCILogs: payload.data.workflowlogs
      }
    }
    case CLEAR_ENV_CI: {
      return {
        ...state,
        envWorkflows: initialState.envWorkflows,
        envWorkflowLog: initialState.envWorkflowLog,
        envWorkflowsLoaded: false,
        envCILogs: initialState.envCILogs
      }
    }
    case FETCH_CICD_SETTINGS:
      return {
        ...state, 
        fetchingCiSettings: true
      }
    case FETCH_CICD_SETTINGS_SUCCESS:
      return {
        ...state, 
        cicdSettings: payload.data,
        cicdSettingsError: 0,
        fetchingCiSettings: false

      }
    case FETCH_CICD_SETTINGS_FAILURE:
      return {
        ...state, 
        cicdSettingsError: payload.data.errorCode,
        cicdSettings: {},
        fetchingCiSettings: false
      }
    case CLEAR_CICD_SETTINGS:
      return {
        ...state, 
        cicdSettingsError: 0,
        cicdSettings: {}
      }
    case RERUN_ENV_CI_TRIGGERED:
      return {
        ...state,
        ciBuildTriggered: false
      }
    case RERUN_ENV_CI_WORKFLOW:
      return {
        ...state,
        ciBuildTriggered: true
      }
    // case RERUN_ENV_CI_WORKFLOW_SUCCESS:
    //   return {
    //     ...state,
    //     ciBuildTriggered: false
    //   }
    // case RERUN_ENV_CI_WORKFLOW_FAILURE:
    //   return {
    //     ...state,
    //     ciBuildTriggered: false
      // }
    case CLEAR_RERUN_ENV_CI_TRIGGERED:
      return {
        ...state,
        ciBuildTriggered: false
      }
    case UPDATE_CICD_SETTINGS:
      return {
        ...state,
        updatingCiSettings: true
      }
    case UPDATE_CICD_SETTINGS_SUCCESS:
      return {
        ...state,
        updatingCiSettings: false
      }
    case UPDATE_CICD_SETTINGS_FAILURE:
      return {
        ...state,
        updatingCiSettings: false
      }
    case STOP_ENV_CI_WORKFLOW:
      return {
        ...state,
        stoppingCI: true
      }
    case STOP_ENV_CI_WORKFLOW_SUCCESS:
      return {
        ...state,
        stoppingCI: false
      }
    case STOP_ENV_CI_WORKFLOW_FAILURE:
      return {
        ...state,
        stoppingCI: false
      }
    case GET_CI_STATS:
      return {
        ...state,
        fetchingCiStats:true
      }
    case GET_CI_STATS_SUCCESS:
      return {
        ...state,
        ciStatistics: payload.data,
        fetchingCiStats:false
      }
    case GET_CI_STATS_FAILURE:
      return {
        ...state,
        fetchingCiStats:false
      }
    case CLEAR_CI_STATS:
      return {
        ...state,
        ciStatistics: null
      }
    case TEST_EMAIL:
      return {
        ...state,
        testing: true
      }
    case TEST_EMAIL_SUCCESS:
      return {
        ...state,
        testing:false
      }
    case TEST_EMAIL_FAILURE:
      return {
        ...state,
        testing: false
      }
    case TEST_SLACK:
      return {
        ...state,
        testing: true
      }
    case TEST_SLACK_SUCCESS:
      return {
        ...state,
        testing:false
      }
  case TEST_SLACK_FAILURE:
      return {
        ...state,
        testing: false
      }
    case GET_DEPLOYMENT_TYPES:
      return {
        ...state,
        fetchingDeployments: true
      }
    case GET_DEPLOYMENT_TYPES_SUCCESS:
      return {
        ...state,
        cdConfig: payload.data,
        fetchingDeployments: false,
      }
    case GET_DEPLOYMENT_TYPES_FAILURE:
      return {
        ...state,
        fetchingDeployments: false
      }
    case TEST_WEBHOOK:
      return {
        ...state,
        testing: true
      }
    case TEST_WEBHOOK_SUCCESS:
      return {
        ...state,
        testing:false
      }
    case TEST_WEBHOOK_FAILURE:
      return {
        ...state,
        testing: false
      }
    case FETCH_CD_SETTINGS:
      return {
        ...state,
        fetchingCdSettings: true
      }
    case FETCH_CD_SETTINGS_SUCCESS:
      return {
        ...state,
        fetchingCdSettings:false,
        cdSettings: payload.data,
      }
    case FETCH_CD_SETTINGS_FAILURE:
      return {
        ...state,
        fetchingCdSettings: false,
        cdSettingsError:payload.data.cdSettingsError
      }
    case UPDATE_CD_SETTINGS:
      return {
        ...state,
        updatingCdSettings: true
      }
    case UPDATE_CD_SETTINGS_SUCCESS:
      return {
        ...state,
        updatingCdSettings:false
      }
    case UPDATE_CD_SETTINGS_FAILURE:
      return {
        ...state,
        updatingCdSettings: false
      }
    case FETCH_CD_LIST:
      return {
        ...state,
        fetchingCdList: true
      }
    case FETCH_CD_LIST_SUCCESS:
      return {
        ...state,
        fetchingCdList:false,
        cdList: payload.data,
      }
    case FETCH_CD_LIST_FAILURE:
      return {
        ...state,
        fetchingCdList: false
      }
    case FETCH_CD_LIST_STATUS:
      return {
        ...state,
        fetchingCdListStatus: true
      }
    case FETCH_CD_LIST_STATUS_SUCCESS:
      return {
        ...state,
        fetchingCdListStatus:false,
        cdListStatus: payload.data,
      }
    case FETCH_CD_LIST_STATUS_FAILURE:
      return {
        ...state,
        fetchingCdListStatus: false
      }
    case CLEAR_CD_LIST:
      return {
        ...state,
        cdList: initialState.cdList
      }
    default:
      return state
  }
}

export default reducer