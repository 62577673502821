const PROJECT_DETAILS_PREFIX = '@PROJECT_DETAILS';

export const FETCH_PROJECT_DETAIL = `${ PROJECT_DETAILS_PREFIX }/PROJECT_DETAIL`
export const FETCH_PROJECT_DETAIL_SUCCESS = `${ PROJECT_DETAILS_PREFIX }/PROJECT_DETAIL_SUCCESS`
export const FETCH_PROJECT_DETAIL_FAILURE = `${ PROJECT_DETAILS_PREFIX }/PROJECT_DETAIL_FAILURE`
export const FETCH_PROJECT_APPLIST = `${ PROJECT_DETAILS_PREFIX }/FETCH_PROJECT_APPLIST`
export const FETCH_PROJECT_APPLIST_SUCCESS = `${ PROJECT_DETAILS_PREFIX }/FETCH_PROJECT_APPLIST_SUCCESS`
export const FETCH_PROJECT_APPLIST_CLEAR = `${ PROJECT_DETAILS_PREFIX }/FETCH_PROJECT_APPLIST_CLEAR`
export const FETCH_PROJECT_APPLIST_FAILURE = `${ PROJECT_DETAILS_PREFIX }/FETCH_PROJECT_APPLIST_FAILURE`
export const EDIT_PROJECT_CALL = `${ PROJECT_DETAILS_PREFIX }/EDIT_PROJECT`
export const EDIT_PROJECT_CALL_SUCCESS = `${ PROJECT_DETAILS_PREFIX }/EDIT_PROJECT_SUCCESS`
export const EDIT_PROJECT_CALL_FAILURE = `${ PROJECT_DETAILS_PREFIX }/EDIT_PROJECT_FAILURE`
export const DELETE_PROJECT_CALL = `${ PROJECT_DETAILS_PREFIX }/DELETE_PROJECT`
export const DELETE_PROJECT_CALL_SUCCESS = `${ PROJECT_DETAILS_PREFIX }/DELETE_PROJECT_SUCCESS`
export const DELETE_PROJECT_CALL_FAILURE = `${ PROJECT_DETAILS_PREFIX }/DELETE_PROJECT_FAILURE`
export const UPDATE_BREADCRUMB = 'UPDATE_BREADCRUMB'
export const FETCH_PROJECT_ACTIVITIES = `${ PROJECT_DETAILS_PREFIX }/FETCH_PROJECT_ACTIVITIES`
export const FETCH_PROJECT_ACTIVITIES_SUCCESS = `${ PROJECT_DETAILS_PREFIX }/FETCH_PROJECT_ACTIVITIES_SUCCESS`
export const FETCH_PROJECT_ACTIVITIES_FAILURE = `${ PROJECT_DETAILS_PREFIX }/FETCH_PROJECT_ACTIVITIES_FAILURE`
export const CLEAR_PROJECT_ACTIVITIES = `${ PROJECT_DETAILS_PREFIX }/CLEAR_PROJECT_ACTIVITIES`
export const FETCH_PROJECT_INSIGHT = `${ PROJECT_DETAILS_PREFIX }/FETCH_PROJECT_INSIGHT`
export const FETCH_PROJECT_INSIGHT_SUCCESS = `${ PROJECT_DETAILS_PREFIX }/FETCH_PROJECT_INSIGHT_SUCCESS`
export const SET_CURRENT_PROJECT = `${ PROJECT_DETAILS_PREFIX }/SET_CURRENT_PROJECT`
export const FTECH_ROLE_PROJECT = `@PROJECT/FTECH_ROLE_PROJECT`
export const FETCH_ROLE_PROJECT_SUCCESS = `@PROJECT/FTECH_ROLE_PROJECT_SUCCESS`
// export const SET_SIDEBAR_PNA = `${ PROJECT_DETAILS_PREFIX }/SET_SIDEBAR_PNA`
// export const SET_SIDEBAR_APPS_SUCCESS = `${ PROJECT_DETAILS_PREFIX }/SET_SIDEBAR_APPS_SUCCESS`
export const GET_SIDEBAR_PROJECT_DETAILS = `${ PROJECT_DETAILS_PREFIX }/GET_SIDEBAR_PROJECT_DETAILS`
export const GET_SIDEBAR_PROJECT_DETAILS_SUCCESS = `${ PROJECT_DETAILS_PREFIX }/GET_SIDEBAR_PROJECT_DETAILS_SUCCESS`
export const CLEAR_PROJECT_DETAILS = `${ PROJECT_DETAILS_PREFIX }/CLEAR_PROJECT_DETAILS`
export const FETCH_PROJECT_USED_RESOURCES = `${ PROJECT_DETAILS_PREFIX }/FETCH_PROJECT_USED_RESOURCES`
export const FETCH_PROJECT_USED_RESOURCES_SUCCESS = `${ PROJECT_DETAILS_PREFIX }/FETCH_PROJECT_USED_RESOURCES_SUCCESS`
export const FETCH_PROJECT_USED_RESOURCES_FAILURE = `${ PROJECT_DETAILS_PREFIX }/FETCH_PROJECT_USED_RESOURCES_FAILURE`
export const CLEAR_PROJECT_USED_RESOURCES = `${ PROJECT_DETAILS_PREFIX }/CLEAR_PROJECT_USED_RESOURCES`

export const FETCH_PROJECT_LOADBALANCER = `${ PROJECT_DETAILS_PREFIX }/FETCH_PROJECT_LOADBALANCER`
export const FETCH_PROJECT_LOADBALANCER_SUCCESS = `${ PROJECT_DETAILS_PREFIX }/FETCH_PROJECT_LOADBALANCER_SUCCESS`
export const FETCH_PROJECT_LOADBALANCER_FAILURE = `${ PROJECT_DETAILS_PREFIX }/FETCH_PROJECT_LOADBALANCER_FAILURE`

export const CREATE_PROJECT_LOADBALANCER = `${ PROJECT_DETAILS_PREFIX }/CREATE_PROJECT_LOADBALANCER`
export const CREATE_PROJECT_LOADBALANCER_SUCCESS = `${ PROJECT_DETAILS_PREFIX }/CREATE_PROJECT_LOADBALANCER_SUCCESS`
export const CREATE_PROJECT_LOADBALANCER_FAILURE = `${ PROJECT_DETAILS_PREFIX }/CREATE_PROJECT_LOADBALANCER_FAILURE`

export const DELETE_PROJECT_LOADBALANCER = `${ PROJECT_DETAILS_PREFIX }/DELETE_PROJECT_LOADBALANCER`
export const DELETE_PROJECT_LOADBALANCER_SUCCESS = `${ PROJECT_DETAILS_PREFIX }/DELETE_PROJECT_LOADBALANCER_SUCCESS`
export const DELETE_PROJECT_LOADBALANCER_FAILURE = `${ PROJECT_DETAILS_PREFIX }/DELETE_PROJECT_LOADBALANCER_FAILURE`

export const FETCH_LOADBALANCER = `${ PROJECT_DETAILS_PREFIX }/FETCH_LOADBALANCER`
export const FETCH_LOADBALANCER_SUCCESS = `${ PROJECT_DETAILS_PREFIX }/FETCH_LOADBALANCER_SUCCESS`
export const FETCH_LOADBALANCER_FAILURE = `${ PROJECT_DETAILS_PREFIX }/FETCH_LOADBALANCER_FAILURE`

export const FETCH_LOADBALANCER_FETCH_STATUS_CALL = `${ PROJECT_DETAILS_PREFIX }/FETCH_LOADBALANCER_FETCH_STATUS_CALL`
export const FETCH_LOADBALANCER_FETCH_STATUS_CALL_SUCCESS = `${ PROJECT_DETAILS_PREFIX }/FETCH_LOADBALANCER_FETCH_STATUS_CALL_SUCCESS`
export const FETCH_LOADBALANCER_FETCH_STATUS_CALL_FAILURE = `${ PROJECT_DETAILS_PREFIX }/FETCH_LOADBALANCER_FETCH_STATUS_CALL_FAILURE`

export const FETCH_LOADBALANCER_STATUS = `${ PROJECT_DETAILS_PREFIX }/FETCH_LOADBALANCER_STATUS`
export const FETCH_LOADBALANCER_STATUS_SUCCESS = `${ PROJECT_DETAILS_PREFIX }/FETCH_LOADBALANCER_STATUS_SUCCESS`
export const FETCH_LOADBALANCER_STATUS_FAILURE = `${ PROJECT_DETAILS_PREFIX }/FETCH_LOADBALANCER_STATUS_FAILURE`

export const CLEAR_LOADBALANCER_INFO = `${ PROJECT_DETAILS_PREFIX }/CLEAR_LOADBALANCER_INFO`
export const CLEAR_LOADBALANCER_DATA = `${ PROJECT_DETAILS_PREFIX }/CLEAR_LOADBALANCER_DATA`

export const FETCH_PROJECT_DETAIL_ENV = `${ PROJECT_DETAILS_PREFIX }/FETCH_PROJECT_DETAIL_ENV`

export const DEACTIVATE_PROJECT_CALL=`${PROJECT_DETAILS_PREFIX}/DEACTIVATE_PROJECT`
export const DEACTIVATE_PROJECT_CALL_SUCCESS = `${ PROJECT_DETAILS_PREFIX }/DEACTIVATE_PROJECT_SUCCESS`
export const DEACTIVATE_PROJECT_CALL_FAILURE = `${ PROJECT_DETAILS_PREFIX }/DEACTIVATE_PROJECT_FAILURE`
export const DEACTIVATE_PROJECT_CALL_CLEANUP = `${ PROJECT_DETAILS_PREFIX }/DEACTIVATE_PROJECT_CLEANUP`

const clearLoadBanalcerData = () => ({
	type: CLEAR_LOADBALANCER_DATA,
})

export const CLEAR_FETCHING_USED_RESOURCES = `${ PROJECT_DETAILS_PREFIX }/CLEAR_FETCHING_USED_RESOURCES`

const clearFetchingUsedResources = () => ({
	type: CLEAR_FETCHING_USED_RESOURCES
})

const fetchProjectDetails = (id) => ({
	type: FETCH_PROJECT_DETAIL,
	data: { id }
})

const fetchAppListByPID = (id, plugin, cluster, search) => ({
	type: FETCH_PROJECT_APPLIST,
	data: { id, plugin, cluster, search }
})

const clearAppList = () => ({
	type: FETCH_PROJECT_APPLIST_CLEAR,
})

const editProjectApiCall = (payload , id) => ({
	type: EDIT_PROJECT_CALL,
	data: {
		payload,
		id,
	}
})

const deleteProjectApiCall = (id , history) => ({
	type: DELETE_PROJECT_CALL,
	data: {
		id,
		history
	}
})

const updateBreadcrumb = (payload) => ({ 
	type: UPDATE_BREADCRUMB,
	data: { payload } 
})

const fetchProjectActivities = (id, page, limit, action, userid, datestart, dateend, module) => ({
	type: FETCH_PROJECT_ACTIVITIES,
	data: { id, page, limit, action, userid, datestart, dateend, module }
})

const clearProjectActivities = () => ({ type: CLEAR_PROJECT_ACTIVITIES})

const fetchProjectInsights = (id) => ({
	type: FETCH_PROJECT_INSIGHT,
	data: { id }
})

const setCurrentProject = (id) => ({
	type: SET_CURRENT_PROJECT,
	data: { id }
})

const fetchProjectRole = (id) => ({
	type: FTECH_ROLE_PROJECT,
	data:{id}
})

const getSidebarProjectDetails = (id) => ({
	type: GET_SIDEBAR_PROJECT_DETAILS,
	data: { id }
})

const clearProjectDetails = () => ({ type: CLEAR_PROJECT_DETAILS})

const fetchProjectUsedResources = (id) => ({
	type: FETCH_PROJECT_USED_RESOURCES,
	data: { id }
})

export const clearProjectUsedResources = () => ({ type: CLEAR_PROJECT_USED_RESOURCES})

export const fetchLoadbalancers = (id) => ({
	type: FETCH_PROJECT_LOADBALANCER,
	data: { id }
})

export const createLoadbalancer = (payload) => ({
	type: CREATE_PROJECT_LOADBALANCER,
	data: { payload }
})

export const deleteLoadbalancer = (id, pId) => ({
	type: DELETE_PROJECT_LOADBALANCER,
	data: { id , pId}
})

export const getLoadbalancer = (id) => ({
	type: FETCH_LOADBALANCER,
	data: { id }
})

export const clearLBInfo = () => ({
	type: CLEAR_LOADBALANCER_INFO
})

export const fetchLBStatusCall = (id) => ({
	type: FETCH_LOADBALANCER_FETCH_STATUS_CALL,
	data: { id }
})

export const fetchLBStatus = (id) => ({
	type: FETCH_LOADBALANCER_STATUS,
	data: { id }
})

export const fetchProjectDetailForEnv = (id, callBack) => ({
	type: FETCH_PROJECT_DETAIL_ENV,
	data: { id, callBack }
})

const toggleProjectActivation=(id,is_Operational)=>{
	return {type:DEACTIVATE_PROJECT_CALL,data:{id,is_Operational}}
}

export {
	fetchProjectDetails,
	fetchAppListByPID,
	clearAppList,
	deleteProjectApiCall,
	editProjectApiCall,
	updateBreadcrumb,
	fetchProjectActivities,
	clearProjectActivities,
	fetchProjectInsights,
	setCurrentProject,
	fetchProjectRole,
	getSidebarProjectDetails,
	clearProjectDetails,
	fetchProjectUsedResources,
	clearLoadBanalcerData,
	clearFetchingUsedResources,
	toggleProjectActivation
}