const INIT_CONTAINER_PREFIX = "@INITCONTAINER";

export const FETCH_INIT_CONTAINER_LIST = `${INIT_CONTAINER_PREFIX}/FETCH_INIT_CONTAINER_LIST`;
export const FETCH_INIT_CONTAINER_LIST_SUCCESS = `${INIT_CONTAINER_PREFIX}/FETCH_INIT_CONTAINER_LIST_SUCCESS`;
export const FETCH_INIT_CONTAINER_LIST_FAILURE = `${INIT_CONTAINER_PREFIX}/FETCH_INIT_CONTAINER_LIST_FAILURE`;
export const FETCH_INIT_CONTAINER_DETAILS = `${INIT_CONTAINER_PREFIX}/FETCH_INIT_CONTAINER_DETAILS`;
export const FETCH_INIT_CONTAINER_DETAILS_SUCCESS = `${INIT_CONTAINER_PREFIX}/FETCH_INIT_CONTAINER_DETAILS_SUCCESS`;
export const FETCH_INIT_CONTAINER_DETAILS_FAILURE = `${INIT_CONTAINER_PREFIX}/FETCH_INIT_CONTAINER_DETAILS_FAILURE`;
export const CREATE_INIT_CONTAINER = `${INIT_CONTAINER_PREFIX}/CREATE_INIT_CONTAINER`;
export const CREATE_INIT_CONTAINER_SUCCESS = `${INIT_CONTAINER_PREFIX}/CREATE_INIT_CONTAINER_SUCCESS`;
export const CREATE_INIT_CONTAINER_FAILURE = `${INIT_CONTAINER_PREFIX}/CREATE_INIT_CONTAINER_FAILURE`;
export const UPDATE_INIT_CONTAINER = `${INIT_CONTAINER_PREFIX}/UPDATE_INIT_CONTAINER`;
export const UPDATE_INIT_CONTAINER_SUCCESS = `${INIT_CONTAINER_PREFIX}/UPDATE_INIT_CONTAINER_SUCCESS`;
export const UPDATE_INIT_CONTAINER_FAILURE = `${INIT_CONTAINER_PREFIX}/UPDATE_INIT_CONTAINER_FAILURE`;
export const DELETE_INIT_CONTAINER = `${INIT_CONTAINER_PREFIX}/DELETE_INIT_CONTAINER`;
export const DELETE_INIT_CONTAINER_SUCCESS = `${INIT_CONTAINER_PREFIX}/DELETE_INIT_CONTAINER_SUCCESS`;
export const DELETE_INIT_CONTAINER_FAILURE = `${INIT_CONTAINER_PREFIX}/DELETE_INIT_CONTAINER_FAILURE`;
export const GET_IMAGES = `${INIT_CONTAINER_PREFIX}/GET_IMAGES`;
export const CLEAR_CREATE_INIT_CONTAINER = `${INIT_CONTAINER_PREFIX}/CLEAR_CREATE_INIT_CONTAINER`;
export const CLEAR_INIT_CONTAINER_DETAILS = `${INIT_CONTAINER_PREFIX}/CLEAR_INIT_CONTAINER_DETAILS`;
export const CLEAR_INIT_CONTAINER_LIST = `${INIT_CONTAINER_PREFIX}/CLEAR_INIT_CONTAINER_LIST`;
export const FETCH_INIT_CONTAINER_LOG = `${INIT_CONTAINER_PREFIX}/FETCH_INIT_CONTAINER_LOG`;

const fetchInitContainerList = (eId) => ({
  type: FETCH_INIT_CONTAINER_LIST,
  data: { eId },
});

const fetchInitContainerDetails = (eId, cId) => ({
  type: FETCH_INIT_CONTAINER_DETAILS,
  data: { eId, cId },
});

const createInitContainer = (eId, jsonBody) => ({
  type: CREATE_INIT_CONTAINER,
  data: { eId, jsonBody },
});

const updateInitContainer = (eId, cId, jsonBody, callback) => ({
  type: UPDATE_INIT_CONTAINER,
  data: { eId, cId, jsonBody, callback },
});

const deleteInitContainer = (eId, cId) => ({
  type: DELETE_INIT_CONTAINER,
  data: { eId, cId },
});

const fetchContainerImages = () => ({
  type: GET_IMAGES,
});

const clearCreateInitContainer = () => ({ type: CLEAR_CREATE_INIT_CONTAINER });

const clearInitContainerDetails = () => ({
  type: CLEAR_INIT_CONTAINER_DETAILS,
});

const clearInitContainerList = () => ({ type: CLEAR_INIT_CONTAINER_LIST });

// const fetchInitContainerLog = (eId, cId, pageNo, pageSize) => ({
//   type: FETCH_INIT_CONTAINER_LOG,
//   data: { eId, cId, pageNo, pageSize },
// });

export {
  fetchInitContainerList,
  fetchInitContainerDetails,
  createInitContainer,
  updateInitContainer,
  deleteInitContainer,
  fetchContainerImages,
  clearCreateInitContainer,
  clearInitContainerDetails,
  clearInitContainerList,
};
