import {
  FormControl,
  Select,
  AppBar,
  Badge,
  Divider,
  Grid,
  IconButton,
  Link,
  List,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  Tooltip,
  Hidden,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import NotificationsIcon from "@material-ui/icons/Notifications";
import React, { Component } from "react";
import { withTranslation,WithTranslation } from "react-i18next";
import { connect ,ConnectedProps} from "react-redux";
import { withRouter } from "react-router-dom";
import Search from "../../components/searchinput/SearchInput";
import styleConstants from "../../theme/styleConstants";
import { logout, updateLanguageSelection } from "../login/redux/actions";
import NotificationRow from "../notification/NotificationItem";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from '@material-ui/icons/Close';
import {
  getRecentNotifications,
  getUnseenCount,
  showHideNotyBubble,
  markAllAsRead,
} from "../notification/redux/actions";
import paths from "../../constants/paths";
import { fetchProfile } from "../login/redux/actions";
import { ProfileMenu } from "../../components/profileMenu/ProfileMenu";
import { UserAvatar } from "../../components/userAvatar/UserAvatar";
import SearchIcon from '@material-ui/icons/Search';
import { Dispatch } from "redux";
import { StaticContext } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { WithWidth } from "@material-ui/core/withWidth";
import { NotificationModel } from "../../models/Common.model";
import OrganizationSwitch from "../organization/organizationswitch/OrganizationSwitch";
import { Theme, WithStyles,createStyles } from "@material-ui/core";
 import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
 import { getSidebarProjectDetails } from "../project/redux/actions";
 import { getOrgList } from "../organization/redux/actions";
 import { History } from 'history';
//import TranslateIcon from '@material-ui/icons/Translate';
import { Link as RouterLink } from 'react-router-dom';
import RefreshIcon from '@material-ui/icons/Refresh';
import { currentOrganization } from "../login/redux/selectors";

/* istanbul ignore next */
const useStyles = (theme:Theme) => createStyles({
  //mainappBar: {
    // zIndex: 1100 + "!important",
    //backgroundColor: "white",
    //[ theme.breakpoints.up('lg') ]: {
    //width: `calc(100% - ${ styleConstants.drawerWidth }px)`,
    //marginLeft: styleConstants.drawerWidth,
    //},
  //},
  mainappBarSearch: {
    zIndex: theme.zIndex.appBar + 1,
    backgroundColor: "white",
  },
  menuButton: {
    //marginRight: theme.spacing(2),
    // [ theme.breakpoints.up('lg') ]: {
    //   display: 'none',
    // }
  },
  userTitle: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  grow: {
    flexGrow: 1,
  },
  navLogo:{marginRight:1},
  block: {
    display: "block",
  },
  appBar: {
    width: `calc(100% - ${styleConstants.drawerMiniWidth}px)`,
    marginLeft: styleConstants.drawerMiniWidth,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: "0px",
    },
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${styleConstants.drawerWidth}px)`,
    marginLeft: styleConstants.drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  notificationsList: {
    maxHeight: 300,
    overflowY: "auto",
    width: 300,
  },
  formControlStyle: {
    padding: 10,
    //marginTop: 10
    color:'white'
  },
});

/* istanbul ignore next */
interface Iprops extends PropsFromRedux,WithStyles,WithTranslation,WithWidth,RouteComponentProps<{}, StaticContext>{
  handleDrawerOpen : ()=>void;
   handleDrawerClose:()=>void;
   isSideBarOpen:boolean;
   history:History;
}
interface Istate {
  anchorEl1: Element | ((element: Element) => Element) | null;
  anchorEl2: Element | ((element: Element) => Element) | null;
  anchorEl3: Element | ((element: Element) => Element) | null;
  isSearching:boolean;
  value:string,
  isSearchIconTouched:boolean

}
export class Header extends Component<Iprops,Istate> {
  constructor(props:Iprops) {
    super(props);
    this.state = {
      anchorEl1: null,
      anchorEl2: null,
      anchorEl3: null,
      isSearching: false,
      value: "en", //test
      isSearchIconTouched:false,
    };
  }

  componentDidMount() {
    // this.props.getSidebarProjectDetails()
    this.props.getOrgList(this.props.history);
    this.props.getUnseenCount();
    this.props.getRecentNotifications(1, 10, "all");
    localStorage.loginAttempt = 1;
    if (this.props.userDetails && this.props.userDetails.id > 0) {
      this.props.fetchUserProfile(this.props.userDetails.id);
    }
  }

  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   //if(!this.props.unseenCount && nextProps.unseenCount) this.setState({show_bubble: nextProps.unseenCount.show_bubble});
  // }

  /* istanbul ignore next */
  // handleTranslateClick = (event) => {
  //   this.setState({ anchorEl3: event.currentTarget });
  // };

  handleMenuClick = (event:React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl1: event.currentTarget });
  };
  handleNotificationClick = (event:React.MouseEvent<HTMLButtonElement>) => {
    //this.props.showHideNotyBubble(false);
    this.setState({ anchorEl2: event.currentTarget });
  };
  handleMenuClose = () => {
    this.setState({ anchorEl1: null });
  };
  handleNotificationClose = () => {
    this.setState({ anchorEl2: null });
  };
  handleTranslateClose = () => {
    this.setState({ anchorEl3: null });
  };
  handleProfileClick = (path:string) => {
    this.handleMenuClose();
    this.props.history.push(path);
  };

  handleDocsNav=(url: string)=> {
    window.open(url, '_blank');
    this.handleMenuClose();
  }

  handleLogoutClick = () => {
    this.handleMenuClose();
    const logOutCallBack=()=>{
   
      this.props.history.push("/")
    }
    this.props.logout(logOutCallBack);
    //localStorage.removeItem('token')
  
  };

  markAllAsRead = () => {
    this.setState({ anchorEl2: null });
    this.props.markAllAsRead();
  };

  gotoNotifications = () => {
    this.setState({ anchorEl2: null });
    this.props.history.push("/notifications");
  };

  goToSupport = () => {
    this.props.history.push(paths.SUPPORT);
  };

  updateHeaderOnSearch = (isSearch:boolean) => {
    this.setState({ isSearching: isSearch });
  };

  // test
 langChanger = (event: React.ChangeEvent<{
  name?: string | undefined;
  value: unknown;
}>): void => {
    let newlang = event.target.value as string;
    this.setState({ value: newlang });
    this.props.i18n.changeLanguage(newlang);
    this.props.updateLanguageSelection(newlang);
    this.handleTranslateClose();
};
  // test

  UNSAFE_componentWillReceiveProps = (newProps:Iprops) => {
    if (newProps.selectedLanguage !== this.state.value) {
      this.setState({ value: newProps.selectedLanguage });
      this.props.i18n.changeLanguage(newProps.selectedLanguage);
    }
  };

  refreshNav = () => {
    // this.props.getProjectListApiCall(this.props.history)
    // this.props.getSidebarProjectDetails();
    this.props.getOrgList(this.props.history);
  }

  render() {
    const { width } = this.props;
    const isMobile = isWidthDown('sm', width);

    
    const { classes, userDetails, isSideBarOpen, t } = this.props;
    const { isSearching } = this.state;
    return (
      <div data-test="header-main-div" className={classes.grow}>
        <AppBar
          position="fixed"
          elevation={1}
          style={{borderRadius:0}}
          color="default"
          className={`${
            isSearching ? classes.mainappBarSearch : "mainappBarImportant"
          }`}
        >
          <Toolbar>

              {
              this.props.currentOrganization && this.props.currentOrganization.id > 0 && 
              <RouterLink data-test="organization-link" to={ "/organization/" + this.props.currentOrganization.id } className={classes.logoContainer}>
                  <img
                    alt="Logo"
                    data-test="org-image"
                    width='40'
                    className={classes.navLogo}
                    src={ this.props.currentOrganization.image ? this.props.currentOrganization.image : "/images/logos/logo-blue.svg" }
                  />
              </RouterLink>
              }
              {
                  (!this.props.currentOrganization || this.props.currentOrganization.id === 0) && 
                  <RouterLink data-test="project-link" to="/projects" className={classes.logoContainer}>
                  <img
                    alt="Logo"
                    data-test="project-image"
                    width='40'
                    className={classes.navLogo}
                    src="/images/logos/logo-blue.svg"
                  />
                  </RouterLink>
              }
            {isMobile && this.state.isSearchIconTouched?   null:<div className="organizationDiv" data-test="organizationdiv-container">
                  <Grid container justifyContent="space-between">
                      <Grid item className="oneLine">
                          <OrganizationSwitch history={ this.props.history }/>
                      </Grid>
                      {/* <Grid item>
                          <Tooltip title={t('SideBar.SideBarPage.refresh')} arrow enterDelay={300} placement="right">  
                              <IconButton onClick={() => this.refreshNav()} size="small" data-test="refreshnav-button" >
                                  <RefreshIcon></RefreshIcon>
                              </IconButton>
                          </Tooltip>
                      </Grid> */}
                  </Grid>
              </div>}

            <div className={classes.grow} />
          
            {isMobile && !this.state.isSearchIconTouched?<SearchIcon onClick={()=>{
              this.setState({isSearchIconTouched:true})
            }}/>: <Search
              updateHeaderOnSearch={this.updateHeaderOnSearch}
              isSideBarOpen={isSideBarOpen}
              data-test="search"
              endormentElement={isMobile?<CloseIcon onClick={()=>{
                this.setState({isSearchIconTouched:false})
              }}/>:undefined}
              cb={()=>{
                this.setState({isSearchIconTouched:false})
              }}
            />}
         
          {!this.state.isSearchIconTouched &&   <Tooltip title={t("Header.HeaderPage.notifications")} arrow>
              <IconButton
                data-test="notif-icon-button"
                onClick={(e) => this.handleNotificationClick(e)}
                style={{marginRight:10}}
              >
                <Badge
                  badgeContent={this.props.unseenCount}
                  color="primary"
                  invisible={!this.props.showNotificationBubble}
                >
                  <NotificationsIcon  />
                </Badge>
              </IconButton>
            </Tooltip>}

            {/* test */}
           {!this.state.isSearchIconTouched && !isMobile &&  <FormControl className={classes.formControlStyle}>
              <Select
              
                data-test="translate-select"
                MenuProps={{ disableScrollLock: true }}
                disableUnderline
                onClose={() => this.handleTranslateClose()}
                onChange={this.langChanger}
                value={this.state.value}
                //renderValue={() => (<TranslateIcon />)}
              >
                <MenuItem value="en"> EN</MenuItem>
                <MenuItem value="hin">IN</MenuItem>
              </Select>
            </FormControl>}

            {/* test */}

        {!this.state.isSearchIconTouched &&     <Menu
              id="simple-menu"
              anchorEl={this.state.anchorEl2}
              open={Boolean(this.state.anchorEl2)}
              onClose={() => this.handleNotificationClose()}
              data-test="notif-menu"
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Grid
                className="notificationHeader"
                container
                spacing={2}
                justify="space-between"
              >
                <Grid item>
                  <Typography variant="h5">
                    {t("Header.HeaderPage.notifications")}
                  </Typography>
                </Grid>

                <Grid item>
                  {" "}
                  <Link
                    className="link-pointer"
                    data-test="mark-read-link"
                    onClick={() => this.markAllAsRead()}
                  >
                    <Typography variant="body2" color="primary">
                      {" "}
                      {t("Header.HeaderPage.allRead")}{" "}
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
              <Grid>
                <List className={classes.notificationsList}>
                  {this.props.recentNotificationList &&
                    this.props.recentNotificationList.length > 0 &&
                    this.props.recentNotificationList.map((noty:NotificationModel, ind:number) => (
                      <NotificationRow
                        key={ind}
                        data-test="notif-item"
                        source={1}
                        notification={noty}
                        history={this.props.history}
                        handleNotificationClose={this.handleNotificationClose}
                      />
                    ))}
                </List>
              </Grid>
              <Grid className="notificationFooter" alignItems="center">
                <Grid item>
                  <Typography>
                    <Link
                      className="link-pointer"
                      data-test="notification-link"
                      onClick={() => this.gotoNotifications()}
                    >
                      {" "}
                      {t("Header.HeaderPage.viewAll")}{" "}
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </Menu>}
            {/* {!this.state.isSearchIconTouched && <OrganizationSwitch history={ this.props.history }/>  }   */}
            {!this.state.isSearchIconTouched && <Divider
              className={classes.userTitle}
              orientation="vertical"
              variant="middle"
              flexItem
            />}
            {/* <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={e => this.handleMenuClick(e)}>
                        </IconButton> */}

         {!this.state.isSearchIconTouched &&    <Menu
              data-test="user-menu"
              id="simple-menu"
              disableScrollLock={true}
              anchorEl={this.state.anchorEl1}
              open={Boolean(this.state.anchorEl1)}
              onClose={() => this.handleMenuClose()}
              //className={classes.profileMenu}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
            >
              {userDetails && userDetails.id && (
                <ProfileMenu
                  data-test="profile-menu"
                  userDetails={userDetails}
                  handleClick={this.handleProfileClick}
                  handleLogoutClick={this.handleLogoutClick}
                  handleDocsNav={this.handleDocsNav}
                />
              )}
            </Menu>}
         {!this.state.isSearchIconTouched &&    <IconButton onClick={(e) => this.handleMenuClick(e)}>
              <UserAvatar
                data-test="user-avatar"
                userDetails={userDetails}
              />
            </IconButton>}
            <div>



            </div>
          </Toolbar>
          
        </AppBar>
      </div>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state:any) => ({
  userDetails: state.AuthReducer.user,
  recentNotificationList: state.NotificationReducer.recentNotificationList,
  unseenCount: state.NotificationReducer.unseenCount,
  showNotificationBubble: state.NotificationReducer.showNotificationBubble,
  selectedLanguage: state.AuthReducer.selectedLanguage,
  currentOrganization: currentOrganization(state),
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch:Dispatch) => {
  return {
    logout: (cb:()=>void) => dispatch(logout(cb)),
    getRecentNotifications: (pageNo:number, pageSize:number, filter:string) =>
      dispatch(getRecentNotifications(pageNo, pageSize, filter)),
    getUnseenCount: () => dispatch(getUnseenCount()),
    markAllAsRead: () => dispatch(markAllAsRead()),
    showHideNotyBubble: (isShow:boolean) => dispatch(showHideNotyBubble(isShow)),
    updateLanguageSelection: (lang:string) => dispatch(updateLanguageSelection(lang)),
    fetchUserProfile: (id:number) => dispatch(fetchProfile(id)),
    // getSidebarProjectDetails: () => dispatch(getSidebarProjectDetails()),
    getOrgList: (history:History) => dispatch(getOrgList(history))
  };
};
const connector= connect(
  mapStateToProps,
  mapDispatchtoProps
)
export default withRouter(
 connector(withStyles(useStyles)(withTranslation()(withWidth()(Header))))
);

type PropsFromRedux=ConnectedProps<typeof connector>
// fetchProfile
