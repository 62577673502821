import React, { useEffect }  from "react";
//import PropTypes from "prop-types";
import { makeStyles, Tabs, Tab,Theme} from "@material-ui/core";
import UserPermissionsTab from "./user&permissionstab/UserPermissionsTab";
import ResourcesTab from "./resourcestab/ResourcesTab";
import VariablesTab from "./variablestab/VariablesTab";
import DomainTab from "./domaintab/DomainTab";
import StorageTab from "./storagetab/StorageTab";
import InitContainer from "../../../initcontainer/InitContainer";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import { connect,ConnectedProps } from "react-redux";
import  ScriptsTab  from "./scriptstab/ScriptsTab";
import {useTranslation} from 'react-i18next';
import { CreateApp_TemplateTypes } from "../../../../constants/enums";
import paths from "../../../../constants/paths";
import {TabPanel} from "../../../../components/tabpanel/TabPanel"
import ScheduleEnv from "./schedule/ScheduleEnv";
import ExternalSecret from "./externalsecrettab/externalSecret";
import ExternalLoggerTab from "./loggertab/ExternalLogger";
import { StaticContext } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import IpWhitelisting from "../ipwhitelisting/IpWhitelisting"

/* istanbul ignore next */
function a11yProps(index:number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

/* istanbul ignore next */
const useStyles = makeStyles((theme:Theme) => ({
  root: {
    backgroundColor: "inherit",
    display: "flex",
    padding: "0",
    margin: "0",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down("md")]: {
      backgroundColor: "white",
    },
  },
  tabPanel: {
    padding: "0",
    margin: "0",
    width: "100%",
    minWidth: 250,
  },
}));
type LocationState = {
	value: number;
  };
interface TabIndexes {
  Permissions: number;
  Resources: number;
  Storage: number;
  Variables: number;
  ExternalSecret:number;
  Scheduler:number;
  Startup:number;
  //Scripts:number;
  Domain: number;
  Logger: number;
  Network: number;
}

interface Iprops extends PropsFromRedux{
  value:number;
  history:RouteComponentProps<{}, StaticContext, LocationState> ["history"];
  updateInnerTab:(tabValue:number)=>void;
  innerTab:number;
  width:Breakpoint;
  changeHash:(hash:string,value:number,envId:number,delay?:number)=>void;
}

export function VerticalTabs(props:Iprops) {
  const classes = useStyles();
  const [tabIndexes, setTabIndexes] = React.useState<Partial<TabIndexes>>({});
  const [value, setValue] = React.useState(
    props.value
      ? props.value
      : props.history &&
        props.history.location &&
        props.history.location.state &&
        props.history.location.state.value
      ? props.history.location.state.value
      : 0
  );
  const [t] = useTranslation()

  /* istanbul ignore next */
  const getNamebyTabIndex = (newValue:number) => {
    const tabPanelObj = document.getElementById('settingsTab-' + newValue);
    if (tabPanelObj) {
      const name = tabPanelObj.getAttribute("name");
      if (name) {
        props.history.push({
          pathname: paths.ENVIRONMENTINFO.replace(":eId", props.environmentDetails?.id) + "#settings_" + name
        });
      }
    }
  }

  const handleChange = (event:React.ChangeEvent<{}>|{}, newValue:number) => {
    //setValue(newValue);
    getNamebyTabIndex(newValue);
    props.updateInnerTab(newValue);
  };

  useEffect(()=>{
    if(Number.isInteger(props.history?.location?.state?.value)){
      handleChange({}, props.history.location.state.value)
    }
  },[tabIndexes, props.history])

  useEffect(() => {
    setValue(props.innerTab);
  }, [props.innerTab]);

  useEffect(() => {
    return (() => {
      props.updateInnerTab(0);
    })
  }, []);

  useEffect(() => {
    if (props.environmentDetails) {
      let _tabs:Partial<TabIndexes> = {
        Domain: 0,
        Permissions: 1,
        Resources: 2,
        Storage: 3,
        Logger: 4,
        Variables: 5,
      }
      if(props.environmentDetails?.external_secret){
        _tabs.ExternalSecret=6;
        _tabs.Scheduler=7;
        _tabs.Network=8;
        _tabs.Startup=9;
      }else{
        _tabs.Scheduler=6;
        _tabs.Network=7;
        _tabs.Startup=8;
        //_tabs.Scripts=8;
      }

      const { service_type } = props.environmentDetails;
      if (service_type === CreateApp_TemplateTypes.default || service_type === CreateApp_TemplateTypes.cr ) {
        _tabs.Startup = -1;
        //_tabs.Scripts = -1;
      }
      else if (props.environmentDetails.service_type === CreateApp_TemplateTypes.op) {
        _tabs = {
          Permissions: 0,
          Resources: 1,
          Storage: 2,
          Logger: 3,
          
          // Variables: 4
        }
      }
      else if(props.environmentDetails.service_type === CreateApp_TemplateTypes.manageDB){
        if(props.environmentDetails?.external_secret){
          _tabs = {
            Permissions: 0,
            Resources: 1,
            Storage: 2,
            Logger: 3,
            Variables: 4,
            ExternalSecret:5,
            Network: 6
          }
        }
        else{
          _tabs = {
            Permissions: 0,
            Resources: 1,
            Storage: 2,
            Logger: 3,
            Variables: 4,
            Network: 5
          }
        }
      }
      setTabIndexes(_tabs);
    }
  }, [props.environmentDetails]);
   
  return tabIndexes &&  (
    <div className={classes.root} data-test="main-container">
    <Tabs
      orientation={isWidthDown("md", props.width) ? undefined : "vertical"}
      value={value}
      variant="scrollable"
      onChange={handleChange}
      className={classes.tabs}
      indicatorColor="primary"
      data-test="tabs-container"
    >
      {/* <Tab label="App" { ...a11yProps(0) } /> */}
      {tabIndexes.Domain!==undefined && tabIndexes.Domain > -1 && (
        <Tab label={t('Environment.SettingsTab.domain')} {...a11yProps(1)} data-test="domain-tab-label" />
      )}
      <Tab label={t('Environment.SettingsTab.permissions')} {...a11yProps(2)} data-test="permissions-tab-label" />
      <Tab label={t('Environment.SettingsTab.resources')} {...a11yProps(3)} data-test="resources-tab-label" />
      {/* {props.environmentDetails?.git_url != "" && ( */}
        <Tab label={t('Environment.SettingsTab.storage')} {...a11yProps(4)} data-test="storage-tab-label" />
      {/* )} */}

      <Tab label="Logger" {...a11yProps(5)} data-test="variables-tab-label" />
      
      {tabIndexes.Variables !== undefined && tabIndexes.Variables > -1 &&  
      <Tab label={t('Environment.SettingsTab.variables')} {...a11yProps(6)} data-test="variables-tab-label" />
      }

      {props.environmentDetails?.external_secret && 
      <Tab label="External Secret" {...a11yProps(7)} />}

      {tabIndexes.Scheduler !==undefined && tabIndexes.Scheduler > -1 && 
        <Tab label={"Scheduler"} {...a11yProps(8)} data-test="scheduler-tab-label" />
      }
      {tabIndexes.Network !== undefined && tabIndexes.Network > -1 &&
        <Tab label={t('Environment.SettingsTab.ipwhitelisting')} {...a11yProps(9)} data-test="ipwhitelisting-tab-label" />
      }
      { tabIndexes.Startup !==undefined && tabIndexes.Startup > -1 && 
        <Tab label={t('Environment.SettingsTab.startUp')} {...a11yProps(10)} data-test="startup-tab-label" />
      }
      {/* {tabIndexes.Scripts !==undefined  && tabIndexes.Scripts > -1 && (
        <Tab label={t('Environment.SettingsTab.scripts')} {...a11yProps(10)} data-test="scripts-tab-label" />
      )} */}

    </Tabs>
        {/* <TabPanel value={ value } index={ 0 } className={ classes.tabPanel }>
            <AppTab history={ props.history }/>
        </TabPanel> */}
    {tabIndexes.Domain!==undefined && tabIndexes.Domain > -1 &&
      <TabPanel value={value} index={tabIndexes.Domain} className={classes.tabPanel} name="domain" data-test="domain-tabpanel" padding={2} tabPanel="settingsTab">
        <DomainTab />
      </TabPanel>
    }
    {tabIndexes.Permissions !==undefined && tabIndexes.Permissions > -1 &&
      <TabPanel value={value} index={tabIndexes.Permissions} className={classes.tabPanel} name="permissions" data-test="permissions-tabpanel" padding={2} tabPanel="settingsTab">
        <UserPermissionsTab history={props.history} />
      </TabPanel>
    }
    { tabIndexes.Resources!==undefined && tabIndexes.Resources > -1 &&
      <TabPanel value={value} index={tabIndexes.Resources} className={classes.tabPanel} name="resources" data-test="resources-tabpanel" padding={2} tabPanel="settingsTab">
        <ResourcesTab changeHash={props.changeHash} />
      </TabPanel>
    }

    { tabIndexes.Storage!==undefined  && tabIndexes.Storage > -1 &&
      <TabPanel value={value} index={tabIndexes.Storage} className={classes.tabPanel} name="storage" data-test="storage-tabpanel" padding={2} tabPanel="settingsTab">
        <StorageTab />
      </TabPanel>
    }

    {tabIndexes.Logger !==undefined && tabIndexes.Logger > -1 &&
      <TabPanel value={value} index={tabIndexes.Logger} className={classes.tabPanel} name="logger" data-test="logger-tabpanel" padding={2} tabPanel="settingsTab">
        <ExternalLoggerTab />
      </TabPanel>
    }

    {tabIndexes.Variables!== undefined && tabIndexes.Variables > -1 && 
      <TabPanel value={value} index={tabIndexes.Variables} className={classes.tabPanel} name="variables" data-test="variables-tabpanel" padding={2} tabPanel="settingsTab">
        <VariablesTab />
      </TabPanel>
    }
    {tabIndexes.ExternalSecret!==undefined && tabIndexes.ExternalSecret > -1 && props.environmentDetails?.external_secret &&
      <TabPanel value={value} index={tabIndexes.ExternalSecret} className={classes.tabPanel} name="externalsecret" data-test="ExternalSecret-tabpanel" padding={2} tabPanel="settingsTab">
        <ExternalSecret />
      </TabPanel>
    }
    { tabIndexes.Scheduler!==undefined && tabIndexes.Scheduler > -1 && 
      <TabPanel value={value} index={tabIndexes.Scheduler} className={classes.tabPanel} name="scheduler" data-test="scheduler-tabpanel" padding={2} tabPanel="settingsTab">
          <ScheduleEnv isHelmEnv={false}/>
      </TabPanel>
    }
   {tabIndexes.Network!== undefined && tabIndexes.Network > -1 && 
      <TabPanel value={value} index={tabIndexes.Network} className={classes.tabPanel} name="network" data-test="ipwhitelisting-tabpanel" padding={2} tabPanel="settingsTab">
        <IpWhitelisting />
      </TabPanel>
    }
    {tabIndexes.Startup!==undefined && tabIndexes.Startup > -1 && (
      <TabPanel value={value} index={tabIndexes.Startup} className={classes.tabPanel} name="startup" data-test="startup-tabpanel" padding={2} tabPanel="settingsTab">
        <InitContainer source={2} />
      </TabPanel>
    )}
    {/* {tabIndexes.Scripts!==undefined && tabIndexes.Scripts > -1 && (
      <TabPanel value={value} index={tabIndexes.Scripts} className={classes.tabPanel} name="scripts" data-test="scripts-tabpanel" padding={2} tabPanel= "settingsTab">
        <ScriptsTab changeHash={props.changeHash}  />
      </TabPanel>
    )} */}

        {/* <TabPanel value={ value } index={ 4 }>
            Item Five
        </TabPanel>
        <TabPanel value={ value } index={ 5 }>
            Item Six
        </TabPanel>
        <TabPanel value={ value } index={ 6 }>
            Item Seven
        </TabPanel> */}
  </div>
  );
}

/* istanbul ignore next */
const mapStateToProps = (state:any) => ({
  environmentDetails: state.EnvironmentReducer.environmentDetails,
});
const mapDispatchtoProps = () => {
  return {

  };
};
const connector=connect(mapStateToProps, mapDispatchtoProps)

export default connector(withWidth()(VerticalTabs));
type PropsFromRedux= ConnectedProps<typeof connector>