import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  Grid,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiTextField from "../textfield/MuiTextField";
import Loader from "../loader/Loader";
import { useTranslation } from 'react-i18next';
import { Transition } from "../../helpers/utils.ext";

type Props = {
  toMatchName: string,
  toDeleteModule: string,
  action?: string,
  handleAgree: () => void,
  handleDisAgree: () => void,
  open: boolean,
  message?: string|JSX.Element,
  loading?: undefined | boolean,
  label?: string
}

export const Popup = (props: Props) => {
  const userInputText = useRef<HTMLInputElement>();
  const [t] = useTranslation();
  const [userInput, setUserInput] = useState({
    text: "",
    isFieldError: false,
    fieldErrorMessage: "",
  });
  const [initialLoad, setInitialLoad] = useState(true);

  const handleUserInputChange = (e: React.ChangeEvent<{ value: string }>) => {
    setInitialLoad(false);
    if (e.target.value !== props.toMatchName) {
      let message;
      switch (props.toDeleteModule){
        case 'snapshot':
          message = "Please Enter 'Delete' To Remove the Backup"
          break;
        case 'environment':
          message = `Please enter the name of the ${props.toDeleteModule} you are ${props.action === 'stop' ? 'stopping' : 'deleting'}.`;
          break;
          case 'project':
            message = `Please enter the name of the ${props.toDeleteModule} ,  ${props.action?`you are about to ${props.action}`:"you are deleting/deactivating"}.`;
            break;
        default:
          message = `Please enter the name of the ${props.toDeleteModule} you are deleting.`;
      }
      
      const toSet = { 
        text: e.target.value,
        isFieldError: true,
        fieldErrorMessage: message,
      };
      setUserInput({
        ...userInput,
        ...toSet,
      });
    } else {
      setUserInput({
        text: e.target.value,
        isFieldError: false,
        fieldErrorMessage: "",
      });
    }
  };

  useEffect(() => {
    if (userInputText && userInputText.current) {
      userInputText.current.focus();
    }
  });

  const handleAgree = () => {
    if (userInput.isFieldError) {
      setUserInput({
        ...userInput,
        fieldErrorMessage: `Please enter the name of the ${props.toDeleteModule} you are deleting.`,
      });
    } else {
      setUserInput({
        ...userInput,
        text: "",
        fieldErrorMessage: "",
        isFieldError: false,
      });
      props.handleAgree();
    }
  };

  const preventCopy = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
  };

  const handleDisAgree = () => {
    setUserInput({
      ...userInput,
      text: "",
      fieldErrorMessage: "",
      isFieldError: false,
    });
    setInitialLoad(true);
    props.handleDisAgree();
  };

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
        onClose={(_, reason) => {
          if (reason !== 'backdropClick') {
            handleDisAgree()
          }}
				}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      onKeyDown={(e) => {
        if (e.keyCode === 13 && !(userInput.isFieldError || initialLoad)) {
          e.preventDefault()
          handleAgree();
        }
      }}
      onEscapeKeyDown={handleDisAgree}
      data-test="main-container"
    >
      <DialogTitle>
        <Typography className="dialogtitle">
          <b> {t('Sure')} </b>
        </Typography>

        <IconButton
          aria-label="close"
          size="small"
          className="right"
          onClick={handleDisAgree}
          data-test="close-btn"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ minWidth: 275 }} dividers>
        <Typography variant="subtitle1" data-test="delete-msg">
          {props.message ? (
            props.message
          ) : (
            <>
              {t('ConfirmDeleteAction1')} <b> {t('ConfirmDeleteAction2')}</b> {t('ConfirmDeleteAction3')}
              <b> {t('ConfirmDeleteAction4')}</b> {t('ConfirmDeleteAction5')} {props.toDeleteModule}
              <b> {props.toMatchName}</b>
            </>
          )}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
        {t('ConfirmDeleteAction6')} <b>{props.toMatchName} </b>.
        </Typography>
        <Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <MuiTextField
                inputProps={{ autoFocus: true }}
                value={userInput.text}
                inputRef={userInputText}
                variant="outlined"
                label={props.label || ""}
                style={{
                  marginRight: "1rem",
                  marginBottom: "1rem",
                  width: "100%",
                }}
                name="userInputText"
                error={userInput.isFieldError}
                helperText={userInput.fieldErrorMessage}
                // onBlur={handleInputOnBlur}
                onChange={handleUserInputChange}
                autoComplete="off"
                onCut={preventCopy}
                onCopy={preventCopy}
                onPaste={preventCopy}
                data-test="input-field"
                id = "" 
                type = "" 
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      {!props.loading ? (
        <DialogActions>
          <Button
            onClick={handleAgree}
            color="primary"
            variant="contained"
            disabled={userInput.isFieldError || initialLoad}
            type="submit"
            data-test="confirm-btn"
          >
            {t('ConfirmDeleteAction7')} {props.action ? props.action : t("Delete")} {t('ConfirmDeleteAction8')}{" "}
            {props.toDeleteModule}
          </Button>
        </DialogActions>
      ) : (
        <div className="loader-center">
          <Loader data-test="loader" />
        </div>
      )}
    </Dialog>
  );
};

export default Popup;
