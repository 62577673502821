import React, { Component } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Grid,
  DialogTitle,
  //FormHelperText
} from "@material-ui/core";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import ConfirmActionPopup from "../confirmactionpopup/ConfirmActionPopup";
import { withTranslation, WithTranslation } from "react-i18next";
// import { Ttransition } from "../../helpers/utils";
import { Transition } from "../../helpers/utils.ext";
import {
  EnvironmentModel,
  Storage,
} from "./../../models/Environment.model";

/* istanbul ignore next */
const useStyles = () => ({
  // paper: {
  //   minWidth: 100
  // }
});
type InonTempPayload = Pick<Storage, "name" | "capacity" | "storage_type" | "attached_to" | "mount_path">

type ItempPayload = Pick<Storage, "name" | "capacity">

type StoragePayload=InonTempPayload|ItempPayload

interface Props extends WithTranslation {
  handleAgree: (payload:StoragePayload) => void;
  handleDisagree: () => void;
  edit?: boolean;
  open: boolean;
  storage?:Storage;
  environmentDetails: EnvironmentModel;
}

interface State {
  isPopupOpened: boolean;
  title: string;
  size: string;
  initialSize: string;
  type: string;
  attached: string;
  path: string;
  isConfirmPopupOpen: boolean;
  payload: StoragePayload| null;
  error: boolean;
  errorMessage: string | null;
  pathError: boolean;
  pathErrorMessage: string | null;
}

export class Popup extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isPopupOpened: false,
      title: "",
      size: "1",
      initialSize: "1",
      type: "default",
      attached: "default",
      path: "",
      isConfirmPopupOpen: false,
      payload: null,
      error: false,
      errorMessage: null,
      pathError: false,
      pathErrorMessage: null,
    };
  }

  componentDidMount = () => {
    if (this.props.storage && this.props.edit) {
      this.setState({
        title: this.props.storage.name,
        size: this.props.storage.capacity
          ? (this.props.storage.capacity / 1024).toString()
          : "1",
        initialSize: this.props.storage.capacity
          ? (this.props.storage.capacity / 1024).toString()
          : "1",
        type: this.props.storage.storage_type,
        attached: this.props.storage.attached_to,
        path: this.props.storage.mount_path,
      });
    }
  };

  UNSAFE_componentWillReceiveProps = (newProps: Props) => {
    if (
      !this.props.storage &&
      !this.props.edit &&
      newProps.storage &&
      newProps.edit
    ) {
      this.setState({
        title: newProps.storage.name,
        size: newProps.storage.capacity
          ? (newProps.storage.capacity / 1024).toString()
          : "1",
        initialSize: newProps.storage.capacity
          ? (newProps.storage.capacity / 1024).toString()
          : "1",
        type: newProps.storage.storage_type,
        attached: newProps.storage.attached_to,
        path: newProps.storage.mount_path,
      });
    }
  };

  handleClose = () => {
    this.props.handleDisagree();
  };

  // handleNameOnblur = () => {
  //   if(this.state.title?.length === 0) {
  //     this.setState({
  //       error: true,
  //       errorMessage: 'Name Cannot Be Empty'
  //     })
  //   }else {
  //     this.setState({
  //       error: false,
  //       errorMessage: ''
  //     })
  //   }
  // }

  // handlePathOnblur = ()  => {
  //   if(this.state.path?.length === 0) {
  //     this.setState({
  //       pathError: true,
  //       pathErrorMessage: 'Path Cannot Be Empty'
  //     })
  //   }else {
  //     this.setState({
  //       pathError: false,
  //       pathErrorMessage: ''
  //     })
  //   }
  // }

  handleChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    if (e.target.name === "title") {
      if (!/^[A-Za-z0-9_ -]*$/.test(e.target.value as string)) {
        this.setState({
          error: true,
          errorMessage: this.props.t("AlphaNumericError"),
        });
      } else if (/^\s*$/.test(e.target.value as string)) {
        this.setState({
          error: true,
          errorMessage: this.props.t("StoragePopup.nameError"),
        });
      } else {
        this.setState({
          error: false,
          errorMessage: "",
        });
      }
    }

    if (e.target.name === "path") {
      if (/^\s*$/.test(e.target.value as string)) {
        this.setState({
          pathError: true,
          pathErrorMessage: this.props.t("StoragePopup.pathError"),
        });
      } else {
        this.setState({
          pathError: false,
          pathErrorMessage: "",
        });
      }
    }

    this.setState({
      [e.target.name as string]: e.target.value,
    } as Pick<State, keyof State>);
  };

  handleAgree = () => {
    let payload: StoragePayload|null=null
    if (this.props.environmentDetails?.service_type > 0) {
       payload = {
        name: this.state.title,
        capacity: parseInt(this.state.size) * 1024,
        storage_type : this.state.type,
        attached_to : this.state.attached,
        mount_path : this.state.path
      };
     
    }
    else {
       payload = {
        name: this.state.title,
        capacity: parseInt(this.state.size) * 1024,
      };
    }

    if(!payload) return

    if (this.props.edit) {
      this.setState({
        isConfirmPopupOpen: true,
        payload,
      });
    } else {
      this.props.handleAgree(payload);
    }
  };

  isSizeValid = (size: string) => {
    if (parseInt(size) >= parseInt(this.state.initialSize)) {
      return true;
    }
  };

  handleUpdateAgree = () => {
    if (this.state.payload) {
      this.props.handleAgree(this.state.payload);
    }
    this.setState({
      isConfirmPopupOpen: false,
      payload: null,
    });
  };

  handleUpdateDisagree = () => {
    this.setState({
      isConfirmPopupOpen: false,
      payload: null,
    });
  };

  render() {
    //const { isPopupOpened } = this.state;
    const { t } = this.props;
    return (
      <div data-test="main-container">
        <Dialog
          data-test="dialog"
          open={this.props.open}
          TransitionComponent={Transition}
          disableEscapeKeyDown={true}
          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              this.handleClose();
            }
          }}
          keepMounted
          // classes={{paper: classes.paper}}
        >
          <DialogTitle>
            <Typography className="dialogtitle" data-test="dialog-title">
              {this.props.edit ? t("Update") : t("Add")}{" "}
              {t("StoragePopup.storage")}
            </Typography>
            <IconButton
              data-test="close-button"
              aria-label="close"
              size="small"
              className="right"
              onClick={() => this.handleClose()}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent dividers data-test="dialog-content">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="h5" data-test="name">
                  {t("StoragePopup.name")} *
                </Typography>

                <TextField
                  data-test="storage-nameField"
                  variant="outlined"
                  margin="normal"
                  // label="Title"
                  name="title"
                  className="w-100"
                  value={this.state.title}
                  onChange={this.props.environmentDetails?.service_type > 0 ? this.handleChange : undefined}
                  placeholder={t("StoragePopup.namePlaceHolder")}
                  error={this.state.error}
                  helperText={this.state.errorMessage}
                  onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                    this.handleChange(e)
                  }
                  disabled={this.props.environmentDetails?.service_type > 0 ? false : true}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h5" data-test="size">
                  {t("StoragePopup.size")}
                </Typography>
                <FormControl
                  className="w-100"
                  variant="outlined"
                  margin="normal"
                >
                  <Select
                    data-test="size-field"
                    variant="outlined"
                    color="primary"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="size"
                    value={this.state.size}
                    className="w-100"
                    onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                      this.handleChange(e)
                    }
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    }}
                  >
                    {/* <MenuItem value="Select">Size</MenuItem> */}
                    <MenuItem disabled={!this.isSizeValid("1")} value="1">
                      1 GB
                    </MenuItem>
                    <MenuItem disabled={!this.isSizeValid("2")} value="2">
                      2 GB
                    </MenuItem>
                    <MenuItem disabled={!this.isSizeValid("4")} value="4">
                      4 GB
                    </MenuItem>
                    <MenuItem disabled={!this.isSizeValid("8")} value="8">
                      8 GB
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {this.props.environmentDetails?.service_type > 0 && (
                <>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h5" data-test="type">
                      {t("StoragePopup.type")}
                    </Typography>
                    <FormControl
                      className="w-100"
                      variant="outlined"
                      margin="normal"
                    >
                      <Select
                        variant="outlined"
                        color="primary"
                        data-test="type-field"
                        name="type"
                        value={this.state.type}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        className="w-100"
                        onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                          this.handleChange(e)
                        }
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          // margin: "normal",
                        }}
                      >
                        {/* <MenuItem value="Select">Type</MenuItem> */}

                        <MenuItem value="default">default</MenuItem>
                       {this.props.environmentDetails.application.cluster.storage_class?.length>0? <MenuItem value="RWX">RWX</MenuItem>:null}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h5" data-test="attachedTo">
                      {t("StoragePopup.attachedTo")}
                    </Typography>
                    <FormControl
                      className="w-100"
                      variant="outlined"
                      margin="normal"
                    >
                      <Select
                        variant="outlined"
                        color="primary"
                        // margin="normal"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        className="w-100"
                        name="attached"
                        data-test="attachedTo-field"
                        value={this.state.attached}
                        onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                          this.handleChange(e)
                        }
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          // margin: "normal",
                        }}
                      >
                        {/* <MenuItem value="Select">Attached to</MenuItem> */}
                        <MenuItem value="default">default</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h5" data-test="path">
                      {t("StoragePopup.path")} *
                    </Typography>

                    <TextField
                      variant="outlined"
                      // label="Path"
                      margin="normal"
                      name="path"
                      value={this.state.path}
                      error={this.state.pathError}
                      helperText={this.state.pathErrorMessage}
                      data-test="path-field"
                      onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                        this.handleChange(e)
                      }
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        this.handleChange(e)
                      }
                      className="w-100"
                      placeholder={t("StoragePopup.pathPlaceHolder")}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              data-test="agree-button"
              color="primary"
              variant="contained"
              onClick={(e) => this.handleAgree()}
              disabled={
                this.state.error ||
                this.state.pathError ||
                Boolean(
                  this.state.title?.trim() === "" ||
                    this.state.path?.trim() === ""
                )
              }
            >
              {this.props.edit ? t("Update") : t("StoragePopup.create")}
            </Button>
          </DialogActions>
        </Dialog>
        <div>
          {this.state.isConfirmPopupOpen && (
            <ConfirmActionPopup
              data-test="confirm-popup"
              open={this.state.isConfirmPopupOpen}
              message={t("StoragePopup.updateStorageMessage")}
              handleAgree={() => this.handleUpdateAgree()}
              handleDisAgree={() => this.handleUpdateDisagree()}
              yesText={t("Projects.VariablesTab.yesText")}
            />
          )}
        </div>
      </div>
    );
  }
}

export default connect()(withStyles(useStyles)(withTranslation()(Popup)));