import {
  // Card,
  // CardActions,
  // CardContent,
  CardHeader,
  // Checkbox,
  // Divider,
  // FormControl,
  // FormControlLabel,
  // Button,
  // RadioGroup,
  // Switch,
  // Typography,
  // Grid,
  // Radio,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import CISettingsTab from "../cisettingstab/CISettingsTab";
import CDSettingsTab from "../cdsettingstab/CDSettingsTab";
import { connect ,ConnectedProps} from "react-redux";
import { CreateApp_TemplateTypes } from "../../../../../constants/enums";
import CDChangePopup from "../../../../../components/cdchangepopup/CDChangePopup";
import { updateGitBranch } from "../../../redux/actions";
import BackdropLoader from "../../../../../components/loader/BackdropLoader";
import { EnvSettingTabs } from "../../../../../constants/environmentTabs";
import { Dispatch } from "redux";
const useStyles = makeStyles(() => ({
  cardHeader: {
    padding: 0,
  },
  accordion: {
    marginTop: 15,
  },
}));
interface Iprops extends PropsFromRedux{
  changeHash:(hash:string,value:number,envId?:number,delay?:number)=>void;
  serviceType:number
}
export function SettingTab(props:Iprops) {
  const [ciExpanded, setCiExpanded] = useState(true);
  const [cdExpanded, setCdExpanded] = useState(true);

  const [isCDChangeOpenPopup, setIsCDChangePopupOpen] = useState(false);

  const [t] = useTranslation();
  const classes = useStyles();

  const handleCDChange = () => {
    setIsCDChangePopupOpen(true);
  };

  const handleCDChangeDisagree = () => {
    setIsCDChangePopupOpen(false);
  };

  const branchChangeCallback = () => {
    const subIndex = EnvSettingTabs(
      "#cicd",
      props.environmentDetails
    ).indexOf("ci");
    props.changeHash("cicd", subIndex);
  };

  const tagChangeCallback = () => {
    const subIndex = EnvSettingTabs(
      "#cicd",
      props.environmentDetails
    ).indexOf("cd");
    props.changeHash("cicd", subIndex);
  };

  const handleGitBranchChange = (branch:string) => {
    setIsCDChangePopupOpen(false);
    props.updateGitBranch(
      props.environmentDetails.id,
      props.environmentDetails.service_type === CreateApp_TemplateTypes.git
        ? {
            giturl: props.environmentDetails.application.git_repository,
            branch,
          }
        : {
            repository: props.environmentDetails.application.image_url,
            tag: branch,
          },
      props.environmentDetails.application.service_type,
      props.environmentDetails.service_type === CreateApp_TemplateTypes.git
        ? branchChangeCallback
        : tagChangeCallback
    );
  };
  return (
    <>
      <div data-test="main-container">
        {props.serviceType === 1 && (
          <Accordion
            expanded={ciExpanded}
            onChange={() => setCiExpanded(!ciExpanded)}
            data-test="ci-expand"
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <CardHeader
                subheader={t("Environment.SettingsTab.manageCI")}
                title={t("Environment.SettingsTab.ciSettings")}
                classes={{ root: classes.cardHeader }}
              />
            </AccordionSummary>
            <AccordionDetails>
              <CISettingsTab handleCDChange={handleCDChange} />
            </AccordionDetails>
          </Accordion>
        )}
        {(props.serviceType === 1 || props.serviceType === 2) && (
          <Accordion
            expanded={cdExpanded}
            onChange={() => setCdExpanded(!cdExpanded)}
            classes={{ root: classes.accordion }}
            data-test="cd-expand"
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <CardHeader
                subheader={t("Environment.SettingsTab.manageCD")}
                title={t("Environment.SettingsTab.cdSettings")}
                classes={{ root: classes.cardHeader }}
              />
            </AccordionSummary>
            <AccordionDetails>
              <CDSettingsTab handleCDChange={handleCDChange} />
            </AccordionDetails>
          </Accordion>
        )}
      </div>
      <CDChangePopup
        open={isCDChangeOpenPopup}
        handleAgree={handleGitBranchChange}
        handleDisagree={handleCDChangeDisagree}
      />
      {props.updatingGitBranch && (
        <BackdropLoader
          message={
            props.environmentDetails.service_type ===
            CreateApp_TemplateTypes.git
              ? t("Environment.SettingsTab.updatingBranch")
              : t("Environment.SettingsTab.updatingTag")
          }
        />
      )}
    </>
  );
}

/* istanbul ignore next */
const mapStateToProps = (state:any) => ({
  envRole: state.EnvironmentReducer.envRole,
  environmentDetails: state.EnvironmentReducer.environmentDetails,
  environmentMetadata: state.EnvironmentReducer.environmentMetadata,
  gitBranches: state.AppsReducer.gitBranches,
  updatingGitBranch: state.EnvironmentReducer.updatingGitBranch,
  //testing: state.CICDReducer.testing,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch:Dispatch) => {
  //dispatch
  return {
    // fetchCDSettings: (id) => dispatch(fetchCDSettings(id)),
    // clearCDSettings: () => dispatch(clearCDSettings()),
    // testEmail: (eId) => dispatch(testEmail(eId)),
    // testSlack: (eId) => dispatch(testSlack(eId)),
    // testWebhook: (eId) => dispatch(testWebhook(eId)),
    // fetchGITBranches: (jsonBody, type, orgName, repoName) =>
    //   dispatch(fetchGITBranches(jsonBody, type, orgName, repoName)),
    // clearBranches: () => dispatch(clearBranches()),
    updateGitBranch: (eId:number, jsonBody:{giturl:string;branch:string}|{repository:string,tag:string}, service_type:number, callback:() => void) =>
      dispatch(updateGitBranch(eId, jsonBody, service_type, callback)),
  };
};
const connector=connect(mapStateToProps, mapDispatchtoProps)

export default connector(SettingTab);
type PropsFromRedux=ConnectedProps<typeof connector>