import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { convertToChartData, formatSizeUnits } from '../../helpers/utils'
import ChartCard from './ChartCard'

export default function DataUsageChartCard(props) {
    const { selectedPod, environmentInsights } = props
    const [t] = useTranslation()

    const [data_transfer_usage, unitObjectRT] = useMemo(() => {
        let converted_data = []
        let receive_tranfer_usage = selectedPod && environmentInsights?.data_transfer?.receive?.filter(itm => itm.metric?.pod ? itm.metric.pod === selectedPod : itm.metric?.container === selectedPod);
        let transfer_tranfer_usage = selectedPod && environmentInsights?.data_transfer?.transfer?.filter(itm => itm.metric?.pod ? itm.metric.pod === selectedPod : itm.metric?.container === selectedPod);
        const maxValueOfY_Recieve = receive_tranfer_usage && receive_tranfer_usage.length > 0 && Math.max(...receive_tranfer_usage[0].values.map(o => o[1]), 0);
        const maxValueOfY_Transfer = transfer_tranfer_usage && transfer_tranfer_usage.length > 0 && Math.max(...transfer_tranfer_usage[0].values.map(o => o[1]), 0);
        const maxOfRT = Math.max(maxValueOfY_Recieve, maxValueOfY_Transfer);
        let unitObjectRTData = formatSizeUnits(maxOfRT, 2);
        // if (receive_tranfer_usage && receive_tranfer_usage.length > 0) data_transfer_usage.push({ "name" : "receive", "data" :  convertToChartData(receive_tranfer_usage[0].values, 3, unitObjectRT) })
        // if (transfer_tranfer_usage && transfer_tranfer_usage.length > 0) data_transfer_usage.push({ "name" : "transfer", "data" :  convertToChartData(transfer_tranfer_usage[0].values, 3, unitObjectRT) })
        if (receive_tranfer_usage && receive_tranfer_usage.length > 0 && transfer_tranfer_usage && transfer_tranfer_usage.length > 0) {
            const _temp_data = [
                {
                    receive_data: receive_tranfer_usage[0].values,
                    transfer_data: transfer_tranfer_usage[0].values
                },
            ]
            converted_data = convertToChartData(_temp_data, 5, unitObjectRTData)
        }
        return [converted_data, unitObjectRTData]
    }, [selectedPod, environmentInsights])
    return (
        <div>
            <ChartCard
                data-test="chart-card"
                title={t('Environment.InsightTab.dataTransfer')}
                lineColor='blue'
                data={data_transfer_usage ? data_transfer_usage : []}
                legend={true}
                suffix={unitObjectRT?.Unit}
                ytitle="Data"
                xtitle="Time"
            />
        </div>
    )
}
