import React, { useEffect } from "react";
//import PropTypes from "prop-types";
import { makeStyles, Tabs, Tab, Theme } from "@material-ui/core";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import paths from "../../../../constants/paths";
import { TabPanel } from "../../../../components/tabpanel/TabPanel";
import { StaticContext } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import Template from "./templatetab/Template";
import Rules from "./rulestab/Rules";
import { Dispatch } from "redux";
import { getAlertLists } from "./redux/action";
import AlertNotif from "./notificationtab/AlertNotif";
import Loader from "../../../../components/loader/Loader";
import BackdropLoader from "../../../../components/loader/BackdropLoader";
import AlertDashBoard from "../../../../components/alertmanager/AlertDashBoard";
import History from "./historytab/History";

/* istanbul ignore next */
function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

/* istanbul ignore next */
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "inherit",
    display: "flex",
    padding: "0",
    margin: "0",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down("md")]: {
      backgroundColor: "white",
    },
  },
  tabPanel: {
    padding: "0",
    margin: "0",
    width: "100%",
    minWidth: 250,
  },
}));
type LocationState = {
  value: number;
};
interface TabIndexes {
  Dashboard: number;
  Rules: number;
  Notifications: number;
  Templates: number;
  History: number;
}

interface Iprops extends PropsFromRedux {
  value: number;
  history: RouteComponentProps<{}, StaticContext, LocationState>["history"];
  updateInnerTab: (tabValue: number) => void;
  innerTab: number;
  width: Breakpoint;
  // changeHash: (
  //   hash: string,
  //   value: number,
  //   envId?: number,
  //   delay?: number
  // ) => void;
}

export function AlertManager(props: Iprops) {
  const classes = useStyles();
  const [tabIndexes, setTabIndexes] = React.useState<Partial<TabIndexes>>({});
  const [value, setValue] = React.useState(
    props.value
      ? props.value
      : props.history &&
        props.history.location &&
        props.history.location.state &&
        props.history.location.state.value
      ? props.history.location.state.value
      : 0
  );
  const [installedRules, setInstalledRules] = React.useState(null);
  const [t] = useTranslation();

  useEffect(()=>{
      const rules = props.alertLists?.filter((el:any,ind:number)=>el.is_installed === true);
      setInstalledRules(rules);
  },[props.alertLists])

  /* istanbul ignore next */
  const getNamebyTabIndex = (newValue: number) => {
    const tabPanelObj = document.getElementById("alertTab-" + newValue);
    if (tabPanelObj) {
      const name = tabPanelObj.getAttribute("name");
      if (name) {
        props.history.push({
          pathname:
            paths.ENVIRONMENTINFO.replace(
              ":eId",
              props.environmentDetails?.id
            ) +
            "#alert_" +
            name,
        });
      }
    }
  };

  const handleChange = (
    event: React.ChangeEvent<{}> | {},
    newValue: number
  ) => {
    getNamebyTabIndex(newValue);
    props.updateInnerTab(newValue);
  };

  useEffect(() => {
    if (Number.isInteger(props.history?.location?.state?.value)) {
      handleChange({}, props.history.location.state.value);
    }
  }, [tabIndexes, props.history]);

  useEffect(() => {
    setValue(props.innerTab);
  }, [props.innerTab]);

  useEffect(() => {
    props.getAlertLists(props.environmentDetails?.id);
    return () => {
      props.updateInnerTab(0);
    };
  }, []);

  useEffect(() => {
    if (props.environmentDetails) {
      let _tabs: Partial<TabIndexes> = {
        Dashboard: 0,
        Rules: 1,
        Notifications: 2,
        Templates: 3,
        History: 4,
      };

      setTabIndexes(_tabs);
    }
  }, [props.environmentDetails]);

  return (
    <>
    {
      (tabIndexes && !props.gettingTempList)?
      <div className={classes.root}>
      <Tabs
        orientation={isWidthDown("md", props.width) ? undefined : "vertical"}
        value={value}
        variant="scrollable"
        onChange={handleChange}
        className={classes.tabs}
        indicatorColor="primary"
        data-test="tabs-container"
      >
        <Tab label="Dashboard" {...a11yProps(1)} />
        <Tab label="Rules" {...a11yProps(2)} />
        <Tab label="Notifications" {...a11yProps(3)} />
        <Tab label="Templates" {...a11yProps(4)} />
        <Tab label="History" {...a11yProps(5)} />
      </Tabs>

      <TabPanel
        value={value}
        index={tabIndexes.Dashboard}
        className={classes.tabPanel}
        name="dashboard"
        padding={2}
        tabPanel="alertTab"
      >
        <AlertDashBoard source={1} changeInnerTab={handleChange}/>
      </TabPanel>

      <TabPanel
        value={value}
        index={tabIndexes.Rules}
        className={classes.tabPanel}
        name="rules"
        padding={2}
        tabPanel="alertTab"
      >
        <Rules alertLists={installedRules} history={props.history} changeInnerTab={handleChange} />
      </TabPanel>

      <TabPanel
        value={value}
        index={tabIndexes.Notifications}
        className={classes.tabPanel}
        name="notifications"
        padding={2}
        tabPanel="alertTab"
      >
        <AlertNotif/>
      </TabPanel>

      <TabPanel
        value={value}
        index={tabIndexes.Templates}
        className={classes.tabPanel}
        name="templates"
        padding={2}
        tabPanel="alertTab"
      >
        <Template alertLists={props.alertLists} />
      </TabPanel>

      <TabPanel
        value={value}
        index={tabIndexes.History}
        className={classes.tabPanel}
        name="history"
        padding={2}
        tabPanel="alertTab"
      >
        <History/>
      </TabPanel>
    </div>:
    <BackdropLoader message="fetching alert details ... " />
    }
    </>
  );
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  environmentDetails: state.EnvironmentReducer.environmentDetails,
  alertLists: state.AlertReducer.templateLists,
  gettingTempList: state.AlertReducer.gettingTempList,
});
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    getAlertLists: (id: number) => dispatch(getAlertLists(id)),
  };
};
const connector = connect(mapStateToProps, mapDispatchtoProps);

export default connector(withWidth()(AlertManager));
type PropsFromRedux = ConnectedProps<typeof connector>;
