import { call, takeLatest, put, select } from "redux-saga/effects";
import axios from "axios";
import endpoints from "../../../constants/endpoints";
import {
  sessionTokenSelector,
  currentOrganization,
} from "../../login/redux/selectors";
import {
  GET_ORG_PLANS_LIST_CALL,
  GET_ORG_PLANS_LIST_CALL_SUCCESS,
  GET_ORG_PLANS_LIST_CALL_FAILURE,
  CREATE_ORG,
  CREATE_ORG_SUCCESS,
  CREATE_ORG_FAILURE,
  GET_ORG_LIST,
  GET_ORG_LIST_SUCCESS,
  GET_ORG_LIST_FAILURE,
  SWITCH_ORG,
  SWITCH_ORG_SUCCESS,
  SWITCH_ORG_FAILURE,
  GET_ORG_INFO,
  GET_ORG_INFO_SUCCESS,
  GET_ORG_INFO_FAILURE,
  DELETE_ORG,
  DELETE_ORG_FAILURE,
  DELETE_ORG_SUCCESS,
  ADD_MEMBER_TO_ORG,
  ADD_MEMBER_TO_ORG_FAILURE,
  ADD_MEMBER_TO_ORG_SUCCESS,
  EDIT_MEMBER_TO_ORG,
  EDIT_MEMBER_TO_ORG_SUCCESS,
  EDIT_MEMBER_TO_ORG_FAILURE,
  DELETE_MEMBER_FROM_ORG,
  DELETE_MEMBER_FROM_ORG_FAILURE,
  DELETE_MEMBER_FROM_ORG_SUCCESS,
  GET_ORG_GROUPS,
  GET_ORG_GROUPS_FAILURE,
  GET_ORG_GROUPS_SUCCESS,
  ADD_ORG_GROUP,
  ADD_ORG_GROUP_FAILURE,
  ADD_ORG_GROUP_SUCCESS,
  DELETE_ORG_GROUP,
  DELETE_ORG_GROUP_FAILURE,
  DELETE_ORG_GROUP_SUCCESS,
  UPDATE_ORG_GROUP,
  UPDATE_ORG_GROUP_FAILURE,
  UPDATE_ORG_GROUP_SUCCESS,
  ADD_MEMBER_TO_GROUP,
  ADD_MEMBER_TO_GROUP_FAILURE,
  ADD_MEMBER_TO_GROUP_SUCCESS,
  DELETE_MEMBER_FROM_GROUP,
  DELETE_MEMBER_FROM_GROUP_FAILURE,
  DELETE_MEMBER_FROM_GROUP_SUCCESS,
  GET_GROUP_INFO,
  GET_GROUP_INFO_FAILURE,
  GET_GROUP_INFO_SUCCESS,
  GET_REGISTRIES,
  GET_REGISTRIES_SUCCESS,
  GET_REGISTRIES_FAILURE,
  GET_REGISTRY,
  GET_REGISTRY_SUCCESS,
  GET_REGISTRY_FAILURE,
  ADD_REGISTRY,
  ADD_REGISTRY_SUCCESS,
  ADD_REGISTRY_FAILURE,
  UPDATE_REGISTRY,
  UPDATE_REGISTRY_SUCCESS,
  UPDATE_REGISTRY_FAILURE,
  DELETE_REGISTRY,
  DELETE_REGISTRY_SUCCESS,
  DELETE_REGISTRY_FAILURE,
  GET_REGISTRY_CONFIG,
  GET_REGISTRY_CONFIG_FAILURE,
  GET_REGISTRY_CONFIG_SUCCESS,
  GET_CHART_CATALOGS,
  GET_CHART_CATALOGS_FAILURE,
  GET_CHART_CATALOGS_SUCCESS,
  GET_CATALOG_CATAGORY,
  GET_CATALOG_CATAGORY_FAILURE,
  GET_CATALOG_CATAGORY_SUCCESS,
  GET_CHART_REPOS,
  GET_CHART_REPOS_FAILURE,
  GET_CHART_REPOS_SUCCESS,
  ADD_CHART_REPO,
  ADD_CHART_REPO_FAILURE,
  ADD_CHART_REPO_SUCCESS,
  SYNC_CHART_REPO,
  SYNC_CHART_REPO_SUCCESS,
  SYNC_CHART_REPO_FAILURE,
  DELETE_CHART_REPO,
  DELETE_CHART_REPO_FAILURE,
  DELETE_CHART_REPO_SUCCESS,
  UPDATE_CHART_REPO,
  UPDATE_CHART_REPO_FAILURE,
  UPDATE_CHART_REPO_SUCCESS,
  GET_DNS,
  GET_DNS_SUCCESS,
  CREATE_DNS,
  CREATE_DNS_SUCCESS,
  DELETE_DNS,
  DELETE_DNS_SUCCESS,
  DELETE_DNS_FAILURE,
  UPDATE_DNS,
  UPDATE_DNS_SUCCESS,
  GET_CHART_DETAIL,
  GET_CHART_DETAIL_SUCCESS,
  GET_CHART_DETAIL_FAILURE,
  FETCH_ORG_ACTIVITIES,
  FETCH_ORG_ACTIVITIES_SUCCESS,
  FETCH_ORG_ACTIVITIES_FAILURE,
} from "./actions";
import { UPDATE_TOKEN, SET_CURRENT_ORG } from "../../login/redux/actions";
import toast from "../../../components/toast/Toast";
import { GET_SIDEBAR_PROJECT_DETAILS } from "../../project/redux/actions";

function* getOrgPlansList() {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = {
      headers: {
        Authorization: "basic " + sessionToken,
      },
    };
    const response = yield axios.get(endpoints.ORGANIZATION.GET_PLANS, config);
    const data = response.data;
    if (response.status === 200) {
      yield put({ type: GET_ORG_PLANS_LIST_CALL_SUCCESS, data });
    } else {
      yield put({
        type: GET_ORG_PLANS_LIST_CALL_FAILURE,
        data: {
          message: "Some error while getting Subscription List",
        },
      });
    }
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    )
      toast.error(error.response.data.error);
    else toast.error("Some error while getting Subscription List");
  }
}

function* createOrganization(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    if (payload.data.iconPayload) {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "basic " + sessionToken,
        },
      };
      const iconResponse = yield axios.post(
        endpoints.GCS_UPLOAD,
        payload.data.iconPayload,
        config
      );
      const iconData = iconResponse.data;
      if (iconResponse.status === 200 && iconData) {
        payload.data.jsonBody["image"] = iconData.url;
      }
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "basic " + sessionToken,
      },
    };
    if (payload.data.jsonBody.id > 0) {
      //for update
      const response = yield axios.put(
        endpoints.ORGANIZATION.CREATE_ORG,
        payload.data.jsonBody,
        config
      );
      const data = response.data;
      if (data) {
        yield put({ type: CREATE_ORG_SUCCESS });
        yield put({ type: GET_ORG_INFO_SUCCESS, data });
        yield put({ type: SET_CURRENT_ORG, data });
        toast.success("Organization Updated Successfully");
        yield call(getOrgList, { history: payload.data.history }, true);
      }
    } else {
      //for create
      const response = yield axios.post(
        endpoints.ORGANIZATION.CREATE_ORG,
        payload.data.jsonBody,
        config
      );
      const data = response.data;
      //if (response.status === 201){
      if (data && data.organization) {
        yield put({ type: UPDATE_TOKEN, data: data.token });
        yield put({ type: CREATE_ORG_SUCCESS });
        yield put({ type: GET_SIDEBAR_PROJECT_DETAILS });
        yield put({ type: SET_CURRENT_ORG, data: data.organization });
        toast.success("Organization Created Successfully");
        payload.data.history.push(
          data.organization.id > 0
            ? "/organization/" + data.organization.id
            : "/projects"
        );
        yield call(getOrgList, { history: payload.data.history }, true);
      } else {
        yield put({ type: CREATE_ORG_FAILURE });
        toast.error("Some error while creating Organization");
      }
    }
  } catch (error) {
    yield put({
      type: CREATE_ORG_FAILURE,
      data: {
        message: "Some error while creating Organization",
      },
    });
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    )
      toast.error(error.response.data.error);
    else
      toast.error("Some error while creating Organization. Please try again");
  }
}

function* getOrgList(payload, isNotDefault) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = { headers: { Authorization: "basic " + sessionToken } };
    const response = yield axios.get(endpoints.ORGANIZATION.GET_LIST, config);
    const data = response.data;
    if (data) {
      yield put({ type: GET_ORG_LIST_SUCCESS, data });
      if (!isNotDefault) {
        const _currentOrganization = yield select(currentOrganization);
        if (data.length === 0) {
          if (_currentOrganization && _currentOrganization.id !== 0) {
            yield call(switchOrg, {
              data: { id: 0, history: payload.data.history },
            });
          }
        } else {
          if (_currentOrganization && _currentOrganization.id > 0) {
            const exist = data.find((x) => x.id === _currentOrganization.id);
            if (!exist) {
              yield call(switchOrg, {
                data: { id: 0, history: payload.data.history },
              });
            }
          }
        }
      }
    }
  } catch (error) {
    yield put({ type: GET_ORG_LIST_FAILURE });
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    )
      toast.error(error.response.data.error);
  }
}

function* switchOrg(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = { headers: { Authorization: "basic " + sessionToken } };
    const response = yield axios.get(
      endpoints.ORGANIZATION.SWITCH_ORG.replace(":id", payload.data.id),
      config
    );
    const data = response.data;
    if (data) {
      yield put({ type: UPDATE_TOKEN, data: data.token });
      yield put({ type: SWITCH_ORG_SUCCESS });
      yield put({ type: GET_SIDEBAR_PROJECT_DETAILS });
      yield put({ type: SET_CURRENT_ORG, data: data.organization });
      if (payload.data.history)
        //payload.data.history.push('/')
        payload.data.history.push(
          payload.data.id > 0
            ? "/organization/" + payload.data.id + "#projects"
            : "/projects",
          { path: payload.data.path }
        );
      if (payload.data.source === 1) {
        //1: Switching from OrganizationInfo page
        yield put({ type: GET_ORG_INFO_SUCCESS, data: data.organization });
      }
      if (payload.data.dependencyCallback) {
        payload.data.dependencyCallback();
      }
    }
  } catch (error) {
    yield put({ type: SWITCH_ORG_FAILURE });
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    )
      toast.error(error.response.data.error);
  }
}

function getOrgInfoApiCall(sessionToken) {
  const config = { headers: { Authorization: "basic " + sessionToken } };
  return axios.get(endpoints.ORGANIZATION.GET_INFO, config);
}

function* getOrgInfo(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(getOrgInfoApiCall, sessionToken, payload);

    const organizationData = response.data;

    if (organizationData) {
      yield put({ type: GET_ORG_INFO_SUCCESS, data: organizationData });
      yield put({ type: SET_CURRENT_ORG, data: organizationData });
    } else {
      yield put({ type: GET_ORG_INFO_FAILURE });
    }
  } catch (error) {
    yield put({ type: GET_ORG_INFO_FAILURE });
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    )
      toast.error(error.response.data.error);
  }
}
function getGroupsApiCall(sessionToken) {
  const config = { headers: { Authorization: "basic " + sessionToken } };
  return axios.get(endpoints.GROUP.GET_GROUPS, config);
}

function* getGroups(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(getGroupsApiCall, sessionToken, payload);
    const data = response.data;
    if (data) {
      yield put({ type: GET_ORG_GROUPS_SUCCESS, data });
    } else {
      yield put({ type: GET_ORG_GROUPS_FAILURE });
    }
  } catch (error) {
    yield put({ type: GET_ORG_GROUPS_FAILURE });
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    )
      toast.error(error.response.data.error);
  }
}

function addMemberApiCall(sessionToken, data) {
  const config = { headers: { Authorization: "basic " + sessionToken } };
  return axios.post(endpoints.ORGANIZATION.ADD_MEMBER, data, config);
}

function* addMember(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(
      addMemberApiCall,
      sessionToken,
      payload.data.data
    );

    const data = response.data;
    if (response.status === 200) {
      toast.success(response.message ?? "Member added successfully");

      // toast.success("Member added successfully");
      yield put({ type: ADD_MEMBER_TO_ORG_SUCCESS, data });
      yield put({ type: GET_ORG_INFO });
      if (payload.data.memberCallBack) {
        payload.data.memberCallBack();
      }
    } else {
      yield put({ type: ADD_MEMBER_TO_ORG_FAILURE });
      toast.error(data.message ?? "Couldn't add member to Organization");
    }
  } catch (error) {
    yield put({ type: ADD_MEMBER_TO_ORG_FAILURE });
    //toast.error(error.message)
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    )
      toast.error(error.response.data.error);
  }
}

function* editMember(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = { headers: { Authorization: "basic " + sessionToken } };
    const response = yield axios.put(
      endpoints.ORGANIZATION.EDIT_MEMBER,
      payload.data.jsonBody,
      config
    );
    const data = response.data;
    if (response.status === 200) {
      toast.success("Member updated successfully");
      yield put({ type: EDIT_MEMBER_TO_ORG_SUCCESS, data });
      yield put({ type: GET_ORG_INFO });
    } else {
      yield put({ type: EDIT_MEMBER_TO_ORG_FAILURE });
      toast.error(data.message ?? "Couldn't update member");
    }
  } catch (error) {
    yield put({ type: EDIT_MEMBER_TO_ORG_FAILURE });
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    )
      toast.error(error.response.data.error);
    else toast.error(error.message);
  }
}

function addGroupApiCall(sessionToken, data) {
  const config = { headers: { Authorization: "basic " + sessionToken } };
  return axios.post(endpoints.GROUP.ADD_GROUP, data, config);
}

function getGroupInfoApiCall(sessionToken, payload) {
  const config = { headers: { Authorization: "basic " + sessionToken } };
  return axios.get(
    endpoints.GROUP.GET_INFO.replace(":gId", payload.id),
    config
  );
}

function* getGroupInfo(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(
      getGroupInfoApiCall,
      sessionToken,
      payload.data
    );

    const data = response.data;

    if (data) {
      yield put({ type: GET_GROUP_INFO_SUCCESS, data });
    } else {
      yield put({ type: GET_GROUP_INFO_FAILURE });
    }
  } catch (error) {
    yield put({ type: GET_GROUP_INFO_FAILURE });
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    )
      toast.error(error.response.data.error);
  }
}

function* addGroup(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    console.log(payload.data);
    const response = yield call(addGroupApiCall, sessionToken, payload.data);
    const data = response.data;
    if (data) {
      toast.success(data.message ?? "Group added successfully");
      yield put({ type: ADD_ORG_GROUP_SUCCESS, data });
      yield put({ type: GET_ORG_INFO });
      yield put({ type: GET_ORG_GROUPS });
    } else {
      yield put({ type: ADD_ORG_GROUP_FAILURE });
      toast.error(data.message ?? "Couldn't add Group to Organization");
    }
  } catch (error) {
    yield put({ type: ADD_ORG_GROUP_FAILURE });
    //toast.error(error.message)
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    )
      toast.error(error.response.data.error);
  }
}

function deleteGroupApiCall(sessionToken, data) {
  const config = { headers: { Authorization: "basic " + sessionToken } };
  return axios.delete(
    endpoints.GROUP.DELETE_GROUP.replace(":gId", data.id),
    config
  );
}

function* deleteGroup(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(deleteGroupApiCall, sessionToken, payload.data);
    const { message } = response;

    if (response.status === 200) {
      yield put({ type: DELETE_ORG_GROUP_SUCCESS });
      toast.success(message ?? "Group Deletion Triggred");
      yield put({ type: GET_ORG_INFO });
      yield put({ type: GET_ORG_GROUPS });
    } else {
      yield put({ type: DELETE_ORG_GROUP_FAILURE });
      toast.error(message ?? "Couldn't delete Group from Organization");
    }
  } catch (error) {
    yield put({ type: DELETE_ORG_GROUP_FAILURE });
    toast.error(error.message);
    if (error && error.response && error.response.data && error.response.data.error)
      toast.error(error.response.data.error);
  }
}

function updateGroupApiCall(sessionToken, payload) {
  const config = { headers: { Authorization: "basic " + sessionToken } };
  return axios.put(
    endpoints.GROUP.UPDATE_GROUP.replace(":gId", payload.id),
    payload.data,
    config
  );
}

function* updateGroup(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(updateGroupApiCall, sessionToken, payload.data);
    const data = response.data;
    if (response.status === 200) {
      yield put({ type: UPDATE_ORG_GROUP_SUCCESS });
      toast.success(data.message ?? "Group Update Triggred");
      yield put({ type: GET_ORG_INFO });
      yield put({ type: GET_ORG_GROUPS });
    } else {
      yield put({ type: UPDATE_ORG_GROUP_FAILURE });
      toast.error(data?.message ?? "Couldn't  Update Group");
    }
  } catch (error) {
    yield put({ type: UPDATE_ORG_GROUP_FAILURE });
    toast.error(error.message);
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    )
      toast.error(error.response.data.error);
  }
}

function deleteMemberApiCall(sessionToken, data) {
  const config = { headers: { Authorization: "basic " + sessionToken }, data };
  return axios.delete(endpoints.ORGANIZATION.DELETE_MEMBER, config);
}

function* deleteMember(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(
      deleteMemberApiCall,
      sessionToken,
      payload.data.data
    );
    if (response.status === 200) {
      yield put({ type: DELETE_MEMBER_FROM_ORG_SUCCESS });
      if (payload.data.isLeave) {
        yield call(switchOrg, {
          data: { id: 0, history: payload.data.history },
        });
        yield call(getOrgList, { history: payload.data.history }, true);
      } else yield put({ type: GET_ORG_INFO });
    } else {
      const data = response.data;
      yield put({ type: DELETE_MEMBER_FROM_ORG_FAILURE });
      toast.error(data?.message ?? "Couldn't delete member from Organization");
    }
  } catch (error) {
    yield put({ type: DELETE_MEMBER_FROM_ORG_FAILURE });
    toast.error(error.message);
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    )
      toast.error(error.response.data.error);
  }
}

function addMemberToGroupApiCall(sessionToken, payload) {
  const config = { headers: { Authorization: "basic " + sessionToken } };
  return axios.post(
    endpoints.GROUP.ADD_MEMBER.replace(":gId", payload.id),
    payload.data,
    config
  );
}

function* addMemberToGroup(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);

    const response = yield call(
      addMemberToGroupApiCall,
      sessionToken,
      payload.data
    );

    const { data, message, status } = response;
    if (status === 200) {
      yield put({ type: ADD_MEMBER_TO_GROUP_SUCCESS, data });

      yield toast.success(message ?? "Member added successfully");
      yield put({ type: GET_GROUP_INFO, data: { id: payload.data.id } });

      yield put({ type: GET_ORG_INFO });

      yield put({ type: GET_ORG_GROUPS });
    } else {
      yield put({ type: ADD_MEMBER_TO_GROUP_FAILURE });
      toast.error(message ?? "Couldn't add member to the group");
    }
  } catch (error) {
    yield put({ type: ADD_MEMBER_TO_GROUP_FAILURE });
    toast.error(error.message);
    if (error && error.response && error.response.data && error.response.data.error)
      toast.error(error.response.data.error);
  }
}

function deleteMemberFromGroupCall(sessionToken, payload) {
  const config = {
    headers: { Authorization: "basic " + sessionToken },
    data: payload.data,
  };
  return axios.delete(
    endpoints.GROUP.DELETE_MEMBER.replace(":gId", payload.id),
    config
  );
}

function* deleteMemberFromGroup(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(
      deleteMemberFromGroupCall,
      sessionToken,
      payload.data
    );
    const { message } = response;
    if (response.status === 200) {
      yield put({ type: DELETE_MEMBER_FROM_GROUP_SUCCESS });
      toast.success(message ?? "Member added successfully");
      yield put({ type: GET_GROUP_INFO, data: { id: payload.data.id } });
      yield put({ type: GET_ORG_INFO });
    } else {
      yield put({ type: DELETE_MEMBER_FROM_GROUP_FAILURE });
      toast.error(message ?? "Couldn't delete member from thr group");
    }
  } catch (error) {
    yield put({ type: DELETE_MEMBER_FROM_GROUP_FAILURE });
    toast.error(error.message);
    if (error && error.response && error.response.data && error.response.data.error)
      toast.error(error.response.data.error);
  }
}

function deleteOrgApiCall(sessionToken) {
  const config = { headers: { Authorization: "basic " + sessionToken } };
  return axios.delete(endpoints.ORGANIZATION.DELETE, config);
}

function* deleteOrg(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(deleteOrgApiCall, sessionToken, payload);
    const data = response.data;
    if (response.status === 204 || response.status === 200) {
      yield put({ type: DELETE_ORG_SUCCESS });
      toast.success("Organization Deletion Triggered");
      // if (payload.data.history) {
      //   payload.data.history.push(`/`);
      // }
      yield call(switchOrg, { data: { id: 0, history: payload.data.history } });
      yield call(getOrgList, { history: payload.data.history }, true);
    } else {
      toast.error(data.message ?? "Cannot delete Organization");
      yield put({ type: DELETE_ORG_FAILURE });
    }
  } catch (error) {
    yield put({ type: DELETE_ORG_FAILURE });
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    )
      toast.error(error.response.data.error);
    else toast.error(error.message);
  }
}

function* getRegistries() {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = { headers: { Authorization: "basic " + sessionToken } };
    const response = yield axios.get(
      endpoints.REGISTRY.GET_REGISTRY_LIST,
      config
    );
    const data = response.data;
    if (data) {
      yield put({ type: GET_REGISTRIES_SUCCESS, data });
    } else {
      yield put({ type: GET_REGISTRIES_FAILURE });
    }
  } catch (error) {
    yield put({ type: GET_REGISTRIES_FAILURE });
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    )
      toast.error(error.response.data.error);
  }
}

function* getRegistry(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = { headers: { Authorization: "basic " + sessionToken } };
    const response = yield axios.get(
      endpoints.REGISTRY.GET_REGISTRY.replace(":id", payload.data.id),
      config
    );
    const data = response.data;
    if (data) {
      yield put({ type: GET_REGISTRY_SUCCESS, data });
    } else {
      yield put({ type: GET_REGISTRY_FAILURE });
    }
  } catch (error) {
    yield put({ type: GET_REGISTRY_FAILURE });
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    )
      toast.error(error.response.data.error);
  }
}

function* addRegistry(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = { headers: { Authorization: "basic " + sessionToken } };
    const response = yield axios.post(
      endpoints.REGISTRY.ADD_REGISTRY,
      payload.data.jsonBody,
      config
    );
    const data = response.data;
    if (data) {
      toast.success(data.message ?? "Registry added successfully");
      yield put({ type: ADD_REGISTRY_SUCCESS, data });
      if (payload.data.callback) {
        payload.data.callback(data);
      }
    } else {
      yield put({ type: ADD_REGISTRY_FAILURE });
    }
  } catch (error) {
    yield put({ type: ADD_REGISTRY_FAILURE });
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    )
      toast.error(error.response.data.error);
  }
}

function* updateRegistry(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = { headers: { Authorization: "basic " + sessionToken } };
    const response = yield axios.put(
      endpoints.REGISTRY.UPDATE_REGISTRY.replace(":id", payload.data.id),
      payload.data.jsonBody,
      config
    );
    const data = response.data;
    if (data) {
      toast.success(data.message ?? "Registry updated successfully");
      yield put({ type: UPDATE_REGISTRY_SUCCESS, data });
      if (payload.data.callback) {
        payload.data.callback(data);
      }
    } else {
      yield put({ type: UPDATE_REGISTRY_FAILURE });
    }
  } catch (error) {
    yield put({ type: UPDATE_REGISTRY_FAILURE });
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    )
      toast.error(error.response.data.error);
  }
}

function* deleteRegistry(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = { headers: { Authorization: "basic " + sessionToken } };
    yield axios.delete(
      endpoints.REGISTRY.DELETE_REGISTRY.replace(":id", payload.data.id),
      config
    );
    //const response =
    //const data = response.data;
    toast.success("Registry deleted successfully");
    yield put({ type: DELETE_REGISTRY_SUCCESS });
    yield call(getRegistries);
  } catch (error) {
    yield put({ type: DELETE_REGISTRY_FAILURE });
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    )
      toast.error(error.response.data.error);
  }
}

function* getRegistryConfig() {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = { headers: { Authorization: "basic " + sessionToken } };
    const response = yield axios.get(
      endpoints.REGISTRY.GET_REGISTRY_CONFIG,
      config
    );
    const data = response.data;
    console.log(data, "data");
    //const data = true;
    if (data) {
      yield put({ type: GET_REGISTRY_CONFIG_SUCCESS, data });
    } else {
      yield put({ type: GET_REGISTRY_CONFIG_FAILURE });
    }
  } catch (error) {
    yield put({ type: GET_REGISTRY_CONFIG_FAILURE });
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    )
      toast.error(error.response.data.error);
  }
}

function* getAllDns() {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = { headers: { Authorization: "Basic " + sessionToken } };
    const response = yield axios.get(endpoints.ORGANIZATION.GET_DNS, config);
    if (response.data) {
      yield put({ type: GET_DNS_SUCCESS, data: response.data });
    }
  } catch (error) {
    toast.error(error.response.data.error);
  }
}

function* getChartRepos() {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = { headers: { Authorization: "Basic " + sessionToken } };
    const response = yield axios.get(endpoints.CHART.GET_CHART_REPOS, config);
    if (response.data) {
      yield put({ type: GET_CHART_REPOS_SUCCESS, data: response.data });
    }
  } catch (error) {
    if (error?.response?.data?.error) {
      toast.error(error.response.data.error);
    }
    yield put({ type: GET_CHART_REPOS_FAILURE });
  }
}

function* getCatalogCategories() {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = { headers: { Authorization: "Basic " + sessionToken } };
    const response = yield axios.get(
      endpoints.CHART.GET_CATALOG_CATEGORY,
      config
    );
    if (response.data) {
      yield put({ type: GET_CATALOG_CATAGORY_SUCCESS, data: response.data });
    }
  } catch (error) {
    if (error?.response?.data?.error) {
      toast.error(error.response.data.error);
    }
    yield put({ type: GET_CATALOG_CATAGORY_FAILURE });
  }
}

function* getChartCatalogs(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = { headers: { Authorization: "Basic " + sessionToken } };
    const endpoint = endpoints.CHART.GET_CHART_CATALOGS.replace(
      ":page",
      payload.data.page
    )
      .replace(":search", payload.data.search)
      .replace(
        ":category",
        payload.data.category === "all" ? "" : payload.data.category
      )
      .replace(
        ":repository",
        payload.data.repository === "all" ? "" : payload.data.repository
      );
    const response = yield axios.get(endpoint, config);
    if (response.data) {
      yield put({ type: GET_CHART_CATALOGS_SUCCESS, data: response.data });
    }
  } catch (error) {
    if (error?.response?.data?.error) {
      toast.error(error.response.data.error);
    }
    yield put({ type: GET_CHART_CATALOGS_FAILURE });
  }
}

function* getChartDetail(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = { headers: { Authorization: "Basic " + sessionToken } };
    const endpoint = endpoints.CHART.GET_CHART_DETAIL.replace(
      ":id",
      payload.data.id
    );
    const response = yield axios.get(endpoint, config);
    if (response.data) {
      yield put({ type: GET_CHART_DETAIL_SUCCESS, data: response.data });
    }
  } catch (error) {
    if (error?.response?.data?.error) {
      toast.error(error.response.data.error);
    }
    yield put({ type: GET_CHART_DETAIL_FAILURE });
  }
}

function* addChartRepo(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = { headers: { Authorization: "Basic " + sessionToken } };
    const response = yield axios.post(
      endpoints.CHART.ADD_CHART_REPO,
      payload.data.payload,
      config
    );
    if (response.data) {
      yield put({ type: ADD_CHART_REPO_SUCCESS, data: response.data });
      yield put({ type: GET_CHART_REPOS });
      toast.success("Repository added successfully!");
    }
  } catch (error) {
    if (error?.response?.data?.error) {
      toast.error(error.response.data.error);
    }
    yield put({ type: ADD_CHART_REPO_FAILURE });
  }
}

function* syncChartRepo(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = { headers: { Authorization: "Basic " + sessionToken } };
    const response = yield axios.get(
      endpoints.CHART.SYNC_CHART_REPO.replace(":id", payload.data.id),
      config
    );
    if (response.status === 200 || response.status === 201) {
      yield put({ type: SYNC_CHART_REPO_SUCCESS, data: response.data });
      yield put({ type: GET_CHART_REPOS });
      toast.success("Repository syynced successfully!");
    }
  } catch (error) {
    if (error?.response?.data?.error) {
      toast.error(error.response.data.error);
    }
    yield put({ type: SYNC_CHART_REPO_FAILURE });
  }
}

function* updateChartRepo(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = { headers: { Authorization: "Basic " + sessionToken } };
    const response = yield axios.put(
      endpoints.CHART.UPDATE_CHART_REPO.replace(":id", payload.data.id),
      payload.data.payload,
      config
    );
    if (response.data) {
      yield put({ type: UPDATE_CHART_REPO_SUCCESS, data: response.data });
      yield put({ type: GET_CHART_REPOS });
      toast.success("Repository updated successfully!");
    }
  } catch (error) {
    if (error?.response?.data?.error) {
      toast.error(error.response.data.error);
    }
    yield put({ type: UPDATE_CHART_REPO_FAILURE });
  }
}

function* deleteChartRepo(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = { headers: { Authorization: "Basic " + sessionToken } };
    const response = yield axios.delete(
      endpoints.CHART.DELETE_CHART_REPO.replace(":id", payload.data.id),
      config
    );
    if (response.status === 204 || response.status === 200) {
      yield put({ type: DELETE_CHART_REPO_SUCCESS, data: response.data });
      yield put({ type: GET_CHART_REPOS });
      toast.success("Repository deleted successfully!");
    }
  } catch (error) {
    if (error?.response?.data?.error) {
      toast.error(error.response.data.error);
    }
    yield put({ type: DELETE_CHART_REPO_FAILURE });
  }
}

function* createDns(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);

    if (payload.data.uploadBody) {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "basic " + sessionToken,
        },
      };
      const uploadResponse = yield axios.post(
        endpoints.PROJECT.UPLOAD_ICON,
        payload.data.uploadBody,
        config
      );
      const fileData = uploadResponse.data;
      if (uploadResponse.status === 200 && fileData) {
        payload.data.jsonBody["credentials"] = fileData.path;
      }
    }

    const _config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "basic " + sessionToken,
      },
    };
    const response = yield axios.post(
      endpoints.ORGANIZATION.CREATE_DNS,
      payload.data.jsonBody,
      _config
    );
    if (response.data) {
      yield call(getAllDns);
      toast.success("DNS created successfully!");
      yield put({ type: CREATE_DNS_SUCCESS, data: response.data });
      if (payload.data.callback) payload.data.callback();
    }
  } catch (error) {
    toast.error(error.response.data.error);
  }
}

function* deleteDns(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = { headers: { Authorization: "Basic " + sessionToken } };
    const response = yield axios.delete(
      endpoints.ORGANIZATION.DELETE_DNS.replace(":dns_id", payload.data.dnsId),
      config
    );
    if (response.status === 204 || response.status === 200) {
      yield call(getAllDns);
      toast.success("DNS deleted successfully!");
      yield put({ type: DELETE_DNS_SUCCESS, data: response.data });
      if (payload.data.callback) payload.data.callback(true);
    }
  } catch (error) {
    toast.error(error.response.data.error);
    yield put({ type: DELETE_DNS_FAILURE });
  }
}

function* updateDns(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);

    if (payload.data.uploadBody) {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "basic " + sessionToken,
        },
      };
      const uploadResponse = yield axios.post(
        endpoints.PROJECT.UPLOAD_ICON,
        payload.data.uploadBody,
        config
      );
      const fileData = uploadResponse.data;
      if (uploadResponse.status === 200 && fileData) {
        payload.data.jsonBody["credentials"] = fileData.path;
      }
    }

    const _config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "basic " + sessionToken,
      },
    };
    const response = yield axios.put(
      endpoints.ORGANIZATION.UPDATE_DNS.replace(":dns_id", payload.data.dnsId),
      payload.data.jsonBody,
      _config
    );
    if (response.data) {
      yield call(getAllDns);
      toast.success("DNS updated successfully!");
      yield put({ type: UPDATE_DNS_SUCCESS, data: response.data });
      if (payload.data.callback) payload.data.callback();
    }
  } catch (error) {
    toast.error(error.response.data.error);
  }
}

function* fetchOrgActivities(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);

    const config = {
      headers: {
        Authorization: "basic " + sessionToken,
      },
      params: {
        page: payload.data.page,
        limit: payload.data.limit,
        action: payload.data.action,
        userid: parseInt(payload.data.userid) || "",
        datestart: payload.data.datestart,
        dateend: payload.data.dateend,
        module: payload.data.module,
      },
    };
    const response = yield axios.get(
      endpoints.ORGANIZATION.GET_ACTIVITIES.replace(":orgId", payload.data.id),
      config
    );
    const data = response.data;

    if (data !== null) {
      yield put({ type: FETCH_ORG_ACTIVITIES_SUCCESS, data });
    } else {
      yield put({ type: FETCH_ORG_ACTIVITIES_FAILURE });
    }
  } catch (error) {
    yield put({ type: FETCH_ORG_ACTIVITIES_FAILURE });
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    )
      toast.error(error.response.data.error);
    else toast.error("Unable to get activities. Please try again");
  }
}

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(GET_ORG_PLANS_LIST_CALL, getOrgPlansList);
  yield takeLatest(CREATE_ORG, createOrganization);
  yield takeLatest(GET_ORG_LIST, getOrgList);
  yield takeLatest(SWITCH_ORG, switchOrg);
  yield takeLatest(GET_ORG_INFO, getOrgInfo);
  yield takeLatest(DELETE_ORG, deleteOrg);
  yield takeLatest(ADD_MEMBER_TO_ORG, addMember);
  yield takeLatest(EDIT_MEMBER_TO_ORG, editMember);
  yield takeLatest(DELETE_MEMBER_FROM_ORG, deleteMember);
  yield takeLatest(GET_ORG_GROUPS, getGroups);
  yield takeLatest(ADD_ORG_GROUP, addGroup);
  yield takeLatest(DELETE_ORG_GROUP, deleteGroup);
  yield takeLatest(UPDATE_ORG_GROUP, updateGroup);
  yield takeLatest(GET_GROUP_INFO, getGroupInfo);
  yield takeLatest(ADD_MEMBER_TO_GROUP, addMemberToGroup);
  yield takeLatest(DELETE_MEMBER_FROM_GROUP, deleteMemberFromGroup);
  yield takeLatest(GET_REGISTRIES, getRegistries);
  yield takeLatest(GET_REGISTRY, getRegistry);
  yield takeLatest(ADD_REGISTRY, addRegistry);
  yield takeLatest(UPDATE_REGISTRY, updateRegistry);
  yield takeLatest(DELETE_REGISTRY, deleteRegistry);
  yield takeLatest(GET_REGISTRY_CONFIG, getRegistryConfig);
  yield takeLatest(GET_CHART_REPOS, getChartRepos);
  yield takeLatest(GET_CATALOG_CATAGORY, getCatalogCategories);
  yield takeLatest(GET_CHART_CATALOGS, getChartCatalogs);
  yield takeLatest(GET_CHART_DETAIL, getChartDetail);
  yield takeLatest(ADD_CHART_REPO, addChartRepo);
  yield takeLatest(SYNC_CHART_REPO, syncChartRepo);
  yield takeLatest(UPDATE_CHART_REPO, updateChartRepo);
  yield takeLatest(DELETE_CHART_REPO, deleteChartRepo);
  yield takeLatest(GET_DNS, getAllDns);
  yield takeLatest(CREATE_DNS, createDns);
  yield takeLatest(DELETE_DNS, deleteDns);
  yield takeLatest(UPDATE_DNS, updateDns);
  yield takeLatest(FETCH_ORG_ACTIVITIES, fetchOrgActivities);
}
