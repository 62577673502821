import React from 'react'
import {
    Card,
    CardContent,
    Grid,
    Typography,
  } from "@material-ui/core";
  import { makeStyles } from "@material-ui/core/styles";
  import clsx from "clsx";
  
const useStyles = makeStyles((theme) => ({
    root: {
      height: "100%",
    },
    content: {
      alignItems: "center",
      display: "flex",
    },
    title: {
      fontWeight: 900,
      color:"#fff"
    },
    avatar: {
      backgroundColor: "#fff",
      height: 56,
      width: 56,
      borderRadius:"5px",
      display: "flex",
      alignItems: "center", 
      justifyContent: "center"
    },
    icon: {
      height: 32,
      width: 32,
    },
    progress: {
      marginTop: theme.spacing(3),
    },
    primaryBackground: {
        backgroundColor: theme.palette.primary.main,
      },
      secondaryBackground: {
        backgroundColor: theme.palette.secondary.light,
      },
      successBackground: {
        backgroundColor: theme.palette.success.light,
      },
      warningBackground: {
        backgroundColor: theme.palette.warning.light,
      },
      state:{
        color:"#fff"
      }
  }));

  interface Props {
    title:string,
    stateNo: number,
    background:string,
    icon: any
}

function AlertDashCard(props:Props) {
    const classes = useStyles();
  return (
    <div>
<Card
  className={clsx(
    classes.root,
    props.background === "primary"
      ? classes.primaryBackground
      : props.background === "secondary"
      ? classes.secondaryBackground
      : props.background === "success"
      ? classes.successBackground
      : classes.warningBackground // Add a default class here
  )}
>
        <CardContent color='primary'>
          <Grid container justify="space-between">
            <Grid item>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                variant="h5"
                data-test="title-container"
              >
                {props.title}
              </Typography>
              <Typography className={classes.state} variant="h3">
                {props.stateNo}
              </Typography>
            </Grid>

            <Grid item>
              <div
                className={classes.avatar}
                // colorDefault={props.color ?? "primary"}
                data-test="avatar-container"
              >
                {/* <InsertChartIcon className={classes.icon} /> */}
                {props.icon}
              </div>
            </Grid>

          </Grid>

        </CardContent>
      </Card>
    </div>
  )
}

export default AlertDashCard