import { takeLatest, put, call, select } from 'redux-saga/effects';

import {
  FETCH_RESOURCES_FAILURE,
  FETCH_RESOURCES,
  FETCH_RESOURCES_SUCCESS,
  FETCH_RESOURCE,
  FETCH_RESOURCE_FAILURE,
  FETCH_RESOURCE_SUCCESS,
  DELETE_RESOURCE,
  DELETE_RESOURCE_FAILURE,
  DELETE_RESOURCE_SUCCESS,
  CREATE_RESOURCE_CALL,
  CREATE_RESOURCE_CALL_SUCCESS,
  CREATE_RESOURCE_CALL_FAILURE,
  EDIT_RESOURCE_CALL,
  EDIT_RESOURCE_CALL_SUCCESS,
  EDIT_RESOURCE_CALL_FAILURE,
} from './actions';
import axios from 'axios';
import { sessionTokenSelector } from '../../login/redux/selectors';
import endpoints from '../../../constants/endpoints';
import toast from '../../../components/toast/Toast';

async function fetchResourcesCall(sessionToken) {
  const config = {
    headers: {
      Authorization: 'basic ' + sessionToken,
    },
  };
  const response = await axios.get(endpoints.ORGANIZATION.FETCH_RESOURCES, config);
  return response;
}
async function fetchResourceCall(sessionToken, id) {
  const config = {
    headers: {
      Authorization: 'basic ' + sessionToken,
    },
  };
  const response = await axios.get(
    endpoints.RESOURCES.GET_RESOURCE.replace(':id', id),
    config
  );
  return response;
}

function* fetchResources() {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(fetchResourcesCall, sessionToken);
    const data = response.data;
    if (data) {
      yield put({ type: FETCH_RESOURCES_SUCCESS, data });
    } else {
      yield put({
        type: FETCH_RESOURCES_FAILURE,
        data: {
          error: 'Error While fetching Resources',
        },
      });
    }
  } catch (error) {
    yield put({
      type: FETCH_RESOURCES_FAILURE,
      data: {
        error: 'Error While fetching Resources',
      },
    });
  }
}
function* fetchResource(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(
      fetchResourceCall,
      sessionToken,
      payload.data.id
    );
    const data = response.data;
    if (data) {
      yield put({ type: FETCH_RESOURCE_SUCCESS, data });
    } else {
      yield put({
        type: FETCH_RESOURCE_FAILURE,
        data: {
          error: 'Error While fetching Resource',
        },
      });
    }
  } catch (error) {
    yield put({
      type: FETCH_RESOURCE_FAILURE,
      data: {
        error: error.message,
      },
    });
  }
}

async function deleteResourceApiCall(sessionToken, id) {
  const config = {
    headers: {
      Authorization: 'basic ' + sessionToken,
    },
  };
  const response = await axios.delete(
    endpoints.RESOURCES.EDIT_RESOURCE.replace(':id', id),
    config
  );
  return response;
}

function* deleteResource(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(
      deleteResourceApiCall,
      sessionToken,
      payload.data.id
    );
    const data = response.data;
    if (response.status === 200) {
      yield put({ type: DELETE_RESOURCE_SUCCESS, data });
      yield put({ type: FETCH_RESOURCES });

      toast.success('Successfully Deleted Resource');
    } else {
      yield put({
        type: DELETE_RESOURCE_FAILURE,
        data: {
          error: 'Error While deleting a Resource',
        },
      });
      toast.error("Couldn't Delete Resource");
    }
  } catch (error) {
    yield put({
      type: DELETE_RESOURCE_FAILURE,
      data: {
        error: error.message,
      },
    });
    if(error && error.response && error.response.data && error.response.data.error)
      toast.error(error.response.data.error);
  }
}

const createResourceCall = async (sessionToken, payload) => {
  const config = {
    headers: {
      Authorization: 'basic ' + sessionToken,
    },
  };
  const response = await axios.post(
    endpoints.RESOURCES.CREATE_RESOURCE,
    payload,
    config
  );
  return response;
};
const editResourceCall = async (sessionToken, payload, id) => {
  const config = {
    headers: {
      Authorization: 'basic ' + sessionToken,
    },
  };
  const response = await axios.put(
    endpoints.RESOURCES.EDIT_RESOURCE.replace(':id', id),
    payload,
    config
  );
  return response;
};

function* createResource(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);

    const response = yield call(
      createResourceCall,
      sessionToken,
      payload.data.payload
    );
    if (response.status === 201) {
      yield put({ type: CREATE_RESOURCE_CALL_SUCCESS });
      toast.success('Resource created Successfully');
      //payload.data.history.push(`${paths.RESOURCES}`);
    } else {
      yield put({
        type: CREATE_RESOURCE_CALL_FAILURE,
        data: {
          error: ' Cannot Create Resource',
        },
      });
      toast.error('Some error while adding Resource');
    }
  } catch (error) {
    yield put({
      type: CREATE_RESOURCE_CALL_FAILURE,
      data: {
        error: error.message,
      },
    });
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    )
      toast.error(error.response.data.error);
    else toast.error('Some error while creating Resource. Please try again');
  }
}
function* editResource(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);

    const response = yield call(
      editResourceCall,
      sessionToken,
      payload.data.payload,
      payload.data.id
    );
    if (response.status === 200) {
      yield put({ type: EDIT_RESOURCE_CALL_SUCCESS });
      toast.success('Resource Updated Successfully');
      //payload.data.history.push(`${paths.RESOURCES}`);
    } else {
      yield put({
        type: EDIT_RESOURCE_CALL_FAILURE,
        data: {
          error: ' Cannot Update Resource',
        },
      });
      toast.error('Some error while updating Resource');
    }
  } catch (error) {
    yield put({
      type: EDIT_RESOURCE_CALL_FAILURE,
      data: {
        error: error.message,
      },
    });
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    )
      toast.error(error.response.data.error);
    else toast.error('Some error while updating Resource.');
  }
}

export default function* watcherSaga() {
  yield takeLatest(FETCH_RESOURCES, fetchResources);
  yield takeLatest(FETCH_RESOURCE, fetchResource);
  yield takeLatest(DELETE_RESOURCE, deleteResource);
  yield takeLatest(CREATE_RESOURCE_CALL, createResource);
  yield takeLatest(EDIT_RESOURCE_CALL, editResource);
}
