import React from "react";
import { Grid } from "@material-ui/core";
import { HealthCheckCard } from "./index";
import { useTranslation } from "react-i18next";
import { HealthCheckModel } from "../../models/Environment.model";

type Props = {
  livenessProbe: HealthCheckModel | undefined, 
  readinessProbe: HealthCheckModel | undefined,
  startupProbe: HealthCheckModel | undefined
}

export const ContainerHealthCheck = ({
  livenessProbe,
  readinessProbe,
  startupProbe,
}: Props) => {
  const [t] = useTranslation();

  return (
    <div>
      <Grid container spacing={2} data-test="health-check-container">
        {livenessProbe && (
          <Grid item xs={12} md={6} lg={6} xl={4}>
            <HealthCheckCard
              title={t("Pods.HealthCheck.livenessProbe")}
              {...livenessProbe}
              data-test="liveness-probe"
            />
          </Grid>
        )}
        {readinessProbe && (
          <Grid item xs={12} md={6} lg={6} xl={4}>
            <HealthCheckCard
              title={t("Pods.HealthCheck.readinessProbe")}
              {...readinessProbe}
              data-test="readiness-probe"
            />
          </Grid>
        )}
        {startupProbe && (
          <Grid item xs={12} md={6} lg={6} xl={4}>
            <HealthCheckCard
              title={t("Pods.HealthCheck.startupProbe")}
              {...startupProbe}
              data-test="startup-probe"
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default ContainerHealthCheck;
