import React, { useEffect } from "react";
import * as yaml from "js-yaml";
import { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
    Grid,
    Button,
    Card,
    CardContent,
    Divider
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import BackdropLoader from "../../../components/loader/BackdropLoader";
import MuiTextField from '../../../components/textfield/MuiTextField';
import OperatorInstanceForm from "../OperatorInstanceForm";
import { fetchResources, createEnv } from "../../environment/redux/actions"
import { createOperatorEnvironment } from "../redux/actions"
import OperatorResource from "../OperatorResource"
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/ext-searchbox";
import "ace-builds/src-noconflict/mode-yaml";
import "ace-builds/src-noconflict/theme-solarized_dark";
import "ace-builds/src-noconflict/theme-xcode";
import CancelIcon from '@material-ui/icons/Cancel';
import { makeStyles } from '@material-ui/core/styles';
import { Dispatch } from "redux";
import { Resource } from "../../../models/Environment.model";
import { AppDetailsModel } from "../../../models/Application.model";
import { OperatorClass } from "../../../models/Operator.model";
import { History } from 'history';

const useStyles = makeStyles(() => ({
    secret:{
        display: "flex", 
        flexDirection: "column", 
        alignItems: "flex-end", 
        border: "1px solid #000000", 
        marginBottom:"40px"
    },
    crossIcon:{
        cursor:"pointer"
    },
    // configBtn:{
    //     marginLeft:"20px"
    // },
    // btnGroup:{
    //     paddingTop:"25px",
    //     paddingBottom:"10px"
    // }
}));

interface Props extends PropsFromRedux {
    resourcesList:Resource[];
    completeData:any;
    setCompleteData:(data:any)=>void;
    appAvailableResource:Pick<Resource, "cpu"|"disk"|"memory">;
    appDetails:AppDetailsModel;
    operatorDetails:OperatorClass;
    setActiveStep:(step:number)=>void;
    activeStep:number;
    history:History;
}

type JsonBody = {
    name: string;
    resource_id: number;
    application_id: number;
    replicas:number;
    operator_payload: any;
};

export function Configure(props:Props) {
    const classes = useStyles()
    const { resourcesList, completeData, setCompleteData, appAvailableResource, appDetails, operatorDetails, setActiveStep } = props
    const [environmentName, setEnvironmentName] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    //const [currentStep, setCurentStep] = useState(0)
    const [newValues, setNewValues] = useState("");
    const [secret, setSecret] = useState<any>([]);

    const currentStep = 1

    const [t] = useTranslation();

    useEffect(() => {
        props.fetchResources()
    }, [])

    const responseSecret = {
        "apiVersion": "v1",
        "kind": "Secret",
        "metadata": {
            "name": "value"
        },
        "type": "Opaque",
        "data": {
            "key": "value"
        }
    }

    const responseConfig = {
        "apiVersion": "v1",
        "kind": "ConfigMap",
        "metadata": {
            "name": "value"
        },
        "data": {
            "key": "value"
        }
    }

    const handleEnvironmentNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEnvironmentName(e.target.value);
        if (e.target?.value?.trim().length === 0) {
            setErrorMessage("Name can't be empty");
        } else {
            setErrorMessage("");
        }
    };

    const createEnvironment = () => {
        const payload = {
            name: environmentName,
            resource_id: completeData.resource.id,
            application_id: appDetails.id,
            replicas: 1,
            operator_payload: [yaml.load(newValues), ...secret]
        };
        props.createEnv(payload,null, props.history);
    };

    const isErrors = () => {
        if (environmentName.trim().length === 0) {
            return true;
        }
        return false;
    };

    const handleResourceUpdate = (systemDetail:{resource: Resource}) => {
        const data = { ...completeData, ...systemDetail }
        //setCurentStep(currentStep + 1)
        //props.handleStepChange(currentStep, data)
        setCompleteData(data)
    }

    const handleBack = () =>{
        setActiveStep(0)
        setCompleteData({})
    }

    const updateNewValues = (_newValues:any) => {
        setNewValues(_newValues);
    }

    const handleUpdateConfig = (config:string) => {
        if(config){
            if(config === "secret"){
                setSecret([responseSecret, ...secret])
            }else{
                setSecret([responseConfig, ...secret])
            }
        }
    }

    let timeout:any;
    const onChange = (value:string,index:number) => {
        if(value && index > -1){
            const updatedSecret:any = [...secret];
            updatedSecret[index] = yaml.load(value);  
            clearTimeout(timeout);
            timeout = setTimeout(() => setSecret(updatedSecret), 500);
        }
    };

    const handleCancelSecret = (index:number) => {
        const updatedSecret = [...secret];
        updatedSecret.splice(index, 1);
        setSecret(updatedSecret)
    }

    return (
        <Grid item xs={12} data-test="main-container" style= {{display : props.activeStep !== currentStep ? "none": ""}}>
            <Card className={`w-100`}>
                <CardContent>
                    <Grid
                        container
                        spacing={2}
                        direction="column"
                        className="m-t-20"
                    >
                        <Grid item xs={12} sm={4}>
                            <MuiTextField
                                error={errorMessage?.length > 0}
                                helperText={errorMessage}
                                autoFocus
                                label={t("ResourcePaper.environmentName")}
                                onChange={handleEnvironmentNameChange}
                                value={environmentName}
                                data-test="name-field"
                            />
                        </Grid>
                        <Divider />
                        <Grid item xs={12} md={12}>
                            <OperatorInstanceForm selectedResource={completeData?.selectedResource} updateValues={updateNewValues} operatorDetails ={operatorDetails}/>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={3}>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleUpdateConfig("secret")}
                                        disabled={secret.length > 9}
                                    >
                                        Add Secret
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleUpdateConfig("config")}
                                        disabled={secret.length > 9}
                                    >
                                        Add Config
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                        <Grid container spacing={3}>
                        {
                            secret && secret.length > 0 && secret.map((config:any, index:number)=>(
                                
                                    <Grid item xs={12} md={4} key={index}>
                                        <div className={classes.secret}>
                                        <CancelIcon
                                        color="secondary"
                                        fontSize="medium"
                                        className={classes.crossIcon}
                                        onClick={()=>handleCancelSecret(index)}
                                        />
                                        <AceEditor
                                            data-test="editor-container"
                                            mode="yaml"
                                            theme={"xcode"}
                                            width="100%"
                                            height="300px"
                                            onChange={(value) => onChange(value, index)}
                                            setOptions={{ showPrintMargin: false }}
                                            editorProps={{ $blockScrolling: Infinity }}
                                            value={yaml.dump(config)}
                                            className="editor"
                                            fontSize="15px"
                                        />
                                        </div>
                                    </Grid>
                                    
                            ))
                        }
                        </Grid>
                        </Grid>

                        <Grid item >
                            <OperatorResource
                                resources={resourcesList}
                                handleResourceUpdate={handleResourceUpdate}
                                loadSource={1}
                                defaultResource={completeData?.systemDetail?.resource ?? null}
                                availableResources={appAvailableResource}
                                appDetails={appDetails}
                                data-test="operator-resource"
                            />
                        </Grid>

                        <Grid item xs={12} className="alignCenter" >
                            <Button className="m-r-10"
                                data-test="back-button"
                                variant="contained"
                                color="primary"
                                onClick={handleBack}
                                //onClick={() => setActiveStep(0)}
                                disabled={isErrors()}
                            >
                                Back
                            </Button>
                            <Button
                                data-test="create-env-button"
                                variant="contained"
                                color="primary"
                                onClick={() => createEnvironment()}
                                disabled={isErrors()}
                            >
                                {t("Environment.CreateAppMaster.createEnv")}
                            </Button>

                        </Grid>
                    </Grid>
                    {props.creatingOperatorEnv && (
                        <BackdropLoader message="Initializing environment creation" data-test="creating-backdrop" />
                    )}
                </CardContent>
            </Card>
        </Grid>

    );
}
/* istanbul ignore next */
const mapStateToProps = (state:any) => ({
    resourcesList: state.EnvironmentReducer.resourcesList,
    appDetails: state.AppsReducer.appDetails,
    appAvailableResource: state.AppsReducer.appAvailableResource,
    creatingOperatorEnv: state.OperatorReducer.creatingOperatorEnv,
    // responseSecret:{value:"apiVersion: v1\nkind: Secret\nmetadata:\n  name: mysecret\ntype: Opaque\ndata:\n  username: YWRtaW4="},
    // responseConfig:{value:"apiVersion: v1\nkind: ConfigMap\nmetadata:\n name: myconfigmap\ndata:\n key: value"}
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch:Dispatch) => ({
    fetchResources: () => dispatch(fetchResources()),
    createOperatorEnvironment: (payload:any, history:History) => dispatch(createOperatorEnvironment(payload, history)),
    createEnv : (jsonBody:JsonBody, triggerPayload:null, history:History) => dispatch(createEnv(jsonBody, triggerPayload, history)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(Configure);