import {
  GET_USER_TICKETS,
  GET_MORE_USER_TICKETS_SUCCESS,
  GET_USER_TICKETS_FAILURE,
  GET_USER_TICKETS_SUCCESS,
  DELETE_TICKET,
  DELETE_TICKET_FAILURE,
  DELETE_TICKET_SUCCESS,
  ASSIGN_USER_TICKETS,
  ASSIGN_USER_TICKETS_FAILURE,
  ASSIGN_USER_TICKETS_SUCCESS,
  GET_TICKET_DETAIL,
  GET_TICKET_DETAIL_FAILURE,
  GET_TICKET_DETAIL_SUCCESS,
  GET_TICKET_GROUP,
  GET_TICKET_GROUP_FAILURE,
  GET_TICKET_GROUP_SUCCESS,
  CREATE_ISSUE_TYPE,
  CREATE_ISSUE_TYPE_FAILURE,
  CREATE_ISSUE_TYPE_SUCCESS,
  CREATE_TICKET_GROUP,
  CREATE_TICKET_GROUP_SUCCESS,
  CREATE_TICKET_GROUP_FAILURE,
  REPLY_ISSUE,
  REPLY_ISSUE_FAILURE,
  REPLY_ISSUE_SUCCESS,
  CREATE_USER_TICKETS,
  CREATE_USER_TICKETS_FAILURE,
  CREATE_USER_TICKETS_SUCCESS,
  GET_SUPPORT_TYPES,
  GET_ISSUE_TYPES,
  GET_ISSUE_TYPES_FAILURE,
  GET_ISSUE_TYPES_SUCCESS,
  GET_SUPPORT_TYPES_FAILURE,
  GET_SUPPORT_TYPES_SUCCESS,
  UPDATE_TICKET,
  UPDATE_TICKET_FAILURE,
  UPDATE_TICKET_SUCCESS,
  RESET_TICKET_DETAIL
} from "./actions";
import initialState from "./initialState";

const reducer = (state = initialState, payload) => {
  switch (payload.type) {
    case GET_USER_TICKETS:
      return {
        ...state,
        fetchingTickets: true,
      };
    case GET_USER_TICKETS_SUCCESS:
      return {
        ...state,
        userTickets: payload.data?.data ?? [],
        fetchingTickets: false,
      };
    case GET_MORE_USER_TICKETS_SUCCESS:
      return {
        ...state,
        userTickets: payload.data?.data
          ? [...state.userTickets, ...payload.data?.data]
          : [...state.userTickets],
        fetchingTickets: false,
      };
    case GET_USER_TICKETS_FAILURE:
      return {
        ...state,
        fetchingTickets: false,
      };
    case CREATE_USER_TICKETS:
      return {
        ...state,
        creatingTicket: true,
      };
    case CREATE_USER_TICKETS_SUCCESS:
      return {
        ...state,
        // userTickets: payload.data?.tickets,
        creatingTicket: false,
      };
    case CREATE_USER_TICKETS_FAILURE:
      return {
        ...state,
        creatingTicket: false,
      };
    case GET_TICKET_DETAIL:
      return {
        ...state,
        fetchingTicketDetail: true,
      };
    case GET_TICKET_DETAIL_SUCCESS:
      return {
        ...state,
        // ticketDetail: {
        //   conversation: [
        //     {
        //       date: 59581499,
        //       files: [
        //         {
        //           fileName: "<string>",
        //           fileType: "<string>",
        //           fileValue:
        //             "https://www.hindustantimes.com/rf/image_size_960x540/HT/p2/2020/05/06/Pictures/_64a0738c-8fb6-11ea-8c4e-d383a7371370.jpg",
        //         },
        //         {
        //           fileName: "<string>",
        //           fileType: "<string>",
        //           fileValue:
        //             "https://www.hindustantimes.com/rf/image_size_960x540/HT/p2/2020/05/06/Pictures/_64a0738c-8fb6-11ea-8c4e-d383a7371370.jpg",
        //         },
        //       ],
        //       id: 43309709,
        //       message: "eu eiusmod aute proident dolore",
        //       userAvatar:
        //         "https://www.hindustantimes.com/rf/image_size_960x540/HT/p2/2020/05/06/Pictures/_64a0738c-8fb6-11ea-8c4e-d383a7371370.jpg",
        //       userId: 30713717,
        //       userName: "consect",
        //     },
        //     {
        //       date: -7411241,
        //       files: [
        //         {
        //           fileName: "<string>",
        //           fileType: "<string>",
        //           fileValue: "<string>",
        //         },
        //         {
        //           fileName: "<string>",
        //           fileType: "<string>",
        //           fileValue: "<string>",
        //         },
        //       ],
        //       id: 71135675,
        //       message: "Ut est magna consequat",
        //       userAvatar: "in id dolore est reprehenderit",
        //       userId: 27727079,
        //       userName: "mollit in quis aute adipisicing",
        //     },
        //   ],
        //   description: "labore veniam sint",
        //   files: [
        //     {
        //       fileName: "<string>",
        //       fileType: "<string>",
        //       fileValue:
        //         "https://www.hindustantimes.com/rf/image_size_960x540/HT/p2/2020/05/06/Pictures/_64a0738c-8fb6-11ea-8c4e-d383a7371370.jpg",
        //     },
        //     {
        //       fileName: "<string>",
        //       fileType: "<string>",
        //       fileValue:
        //         "https://www.hindustantimes.com/rf/image_size_960x540/HT/p2/2020/05/06/Pictures/_64a0738c-8fb6-11ea-8c4e-d383a7371370.jpg",
        //     },
        //     {
        //       fileName: "<string>",
        //       fileType: "<string>",
        //       fileValue:
        //         "https://www.hindustantimes.com/rf/image_size_960x540/HT/p2/2020/05/06/Pictures/_64a0738c-8fb6-11ea-8c4e-d383a7371370.jpg",
        //     },
        //     {
        //       fileName: "<string>",
        //       fileType: "<string>",
        //       fileValue:
        //         "https://www.hindustantimes.com/rf/image_size_960x540/HT/p2/2020/05/06/Pictures/_64a0738c-8fb6-11ea-8c4e-d383a7371370.jpg",
        //     },
        //   ],
        //   notes: "Duis",
        //   ok: true,
        //   ticketDetails: {
        //     assignee: "cupidatat sed",
        //     category: "in consequat irure",
        //     date: -90947893,
        //     id: -19215097,
        //     lastResponse: -99672185,
        //     priority: "sed",
        //     status: "labore ipsum sed Lorem minim",
        //     title: "cupidatat enim",
        //   },
        // },

        ticketDetail: payload.data?.data,
        fetchingTicketDetail: false,
      };
    case GET_TICKET_DETAIL_FAILURE:
      return {
        ...state,
        fetchingTicketDetail: false,
      };
    case CREATE_TICKET_GROUP:
      return {
        ...state,
        creatingTicketGroup: true,
      };
    case CREATE_TICKET_GROUP_SUCCESS:
      return {
        ...state,
        creatingTicketGroup: false,
      };
    case CREATE_TICKET_GROUP_FAILURE:
      return {
        ...state,
        creatingTicketGroup: false,
      };
    case GET_TICKET_GROUP:
      return {
        ...state,
        fetchingTicketGroups: true,
      };
    case GET_TICKET_GROUP_SUCCESS:
      return {
        ...state,
        fetchingTicketGroups: false,
      };
    case GET_TICKET_GROUP_FAILURE:
      return {
        ...state,
        fetchingTicketGroups: false,
      };
    case ASSIGN_USER_TICKETS:
      return {
        ...state,
        assigningTicket: true,
      };
    case ASSIGN_USER_TICKETS_SUCCESS:
      return {
        ...state,
        assigningTicket: false,
      };
    case ASSIGN_USER_TICKETS_FAILURE:
      return {
        ...state,
        assigningTicket: false,
      };
    case CREATE_ISSUE_TYPE:
      return {
        ...state,
        creatingIssueType: true,
      };
    case CREATE_ISSUE_TYPE_SUCCESS:
      return {
        ...state,
        creatingIssueType: false,
      };
    case CREATE_ISSUE_TYPE_FAILURE:
      return {
        ...state,
        creatingIssueType: false,
      };
    case REPLY_ISSUE:
      return {
        ...state,
        replyingIssue: true,
      };
    case REPLY_ISSUE_SUCCESS:
      return {
        ...state,
        replyingIssue: false,
      };
    case REPLY_ISSUE_FAILURE:
      return {
        ...state,
        replyingIssue: false,
      };
    case GET_SUPPORT_TYPES:
      return {
        ...state,
        fetchingTypes: true,
      };
    case GET_SUPPORT_TYPES_SUCCESS:
      return {
        ...state,
        fetchingTypes: false,
        supportTypes: payload.data?.data ?? payload.data,
      };
    case GET_SUPPORT_TYPES_FAILURE:
      return {
        ...state,
        fetchingTypes: false,
      };
    case GET_ISSUE_TYPES:
      return {
        ...state,
        fetchingTypes: true,
      };
    case GET_ISSUE_TYPES_SUCCESS:
      return {
        ...state,
        fetchingTypes: false,
        issueTypes: payload.data?.data ?? payload.data,
      };
    case GET_ISSUE_TYPES_FAILURE:
      return {
        ...state,
        fetchingTypes: false,
      };
    case DELETE_TICKET:
      return {
        ...state,
        deletingTicket: true,
      };
    case DELETE_TICKET_SUCCESS:
      return {
        ...state,
        deletingTicket: false,
      };
    case DELETE_TICKET_FAILURE:
      return {
        ...state,
        deletingTicket: false,
      };
    case UPDATE_TICKET:
      return {
        ...state,
        updatingTicket: true,
      };
    case UPDATE_TICKET_SUCCESS:
      return {
        ...state,
        updatingTicket: false,
      };
    case UPDATE_TICKET_FAILURE:
      return {
        ...state,
        updatingTicket: false,
      };
    case RESET_TICKET_DETAIL:
      return {
        ...state,
        ticketDetail: null,
      };
    default:
      return state;
  }
};

export default reducer;
