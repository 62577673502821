import React from 'react'
//import { push } from "connected-react-router";
import * as yaml from "js-yaml";
// import { get } from "lodash";
import { useEffect, useState } from "react";

// import actions from "actions";
// import Alert from "components/js/Alert";
// import Column from "components/js/Column";
// import Row from "components/js/Row";
// import OperatorSummary from "components/OperatorSummary/OperatorSummary";
// import OperatorHeader from "components/OperatorView/OperatorHeader";
//import { useDispatch, useSelector } from "react-redux";
// import { Action } from "redux";
// import { ThunkDispatch } from "redux-thunk";
// import * as url from "shared/url";
// import placeholder from "../../placeholder.png";
import OperatorInstanceFormBody from "./OperatorInstanceFormBody";
import { CustomResourceDefinition, OperatorClass } from '../../models/Operator.model';

type Props = {
  selectedResource:CustomResourceDefinition;
  updateValues:(_newValues:any)=>void;
  operatorDetails:OperatorClass;
}

/* istanbul ignore next */
// export function parseCSV(
//   csv,
//   crdName,
//   //setIcon,
//   setCRD,
//   setDefaultValues
// ) {
//   const ownedCRDs = get(
//     csv,
//     "spec.customresourcedefinitions.owned",
//     [],
//   );
//   //const csvIcon = get(csv, "spec.icon[0]");
//   // if (csvIcon) {
//   //   setIcon(`data:${csvIcon.mediatype};base64,${csvIcon.base64data}`);
//   // }

//   ownedCRDs.forEach(ownedCRD => {
//     if (ownedCRD.name === crdName) {
//       setCRD(ownedCRD);
//       // Got the target CRD, extract the example
//       if (setDefaultValues) {
//         const kind = ownedCRD.kind;
//         const rawExamples = get(csv, 'metadata.annotations["alm-examples"]', "[]");
//         const examples = JSON.parse(rawExamples);
//         examples.forEach(example => {
//           if (example.kind === kind) {
//             // Found the example, set the default values
//             setDefaultValues(yaml.dump(example));
//           }
//         });
//       }
//     }
//   });
// }

export default function OperatorInstanceForm({
  selectedResource,
  updateValues,
  operatorDetails
}:Props) {
  //csvName,
//crdName,
//cluster,
//namespace,
  //const dispatch = useDispatch();
  const [defaultValues, setDefaultValues] = useState("");
  const [newValues, setNewValues] = useState("");
  //const [crd, setCRD] = useState(undefined);
  //const [icon, setIcon] = useState(placeholder);
  
  //const [csv, setCSV] = useState(null); // this need to be removed and set data in csv when data recieved from api

  // useEffect(() => {
  //   // Clean up component state
  //   setDefaultValues("");
  //   setCRD(undefined);
  //   //setIcon(placeholder);
  //   //dispatch(actions.operators.getCSV(, namespace, csvName)); // this need to implement
  // }, [dispatch, csvName]); //cluster, namespace

  // const {
  //   operators: {
  //     csv,
  //     isFetching,
  //     errors: {
  //       csv: { fetch: fetchError },
  //       resource: { create: createError },
  //     },
  //   },
  // } = useSelector((state) => state);

  // useEffect(() => {
  //   if (csv) {
  //     //parseCSV(csv, crdName, setIcon, setCRD, setDefaultValues);
  //     parseCSV(csv, crdName, setCRD, setDefaultValues);
  //   }
  // }, [csv, crdName]);

  // if (!fetchError && !isFetching && !crd) {
  //   return (
  //     <Alert theme="danger">
  //       {crdName} not found in the definition of {csvName}
  //     </Alert>
  //   );
  // }

  // const handleDeploy = () => {
  //   if (!crd || !csv) {
  //     // Unexpected error, CRD and CSV should have been previously populated
  //     throw new Error(`Missing CRD (${JSON.stringify(crd)}) or CSV (${JSON.stringify(csv)})`);
  //   }
  //   const resourceType = crd.name.split(".")[0];
  //   // const created = dispatch(
  //   //   actions.operators.createResource(
  //   //     cluster,
  //   //     namespace,
  //   //     newValues.apiVersion,
  //   //     resourceType,
  //   //     newValues,
  //   //   ),
  //   // );
  //   // if (created) {
  //   //   dispatch(
  //   //     push(
  //   //       url.app.operatorInstances.view(
  //   //         cluster,
  //   //         namespace,
  //   //         csv.metadata.name,
  //   //         crd.name,
  //   //         newValues.metadata.name,
  //   //       ),
  //   //     ),
  //   //   );
  //   // }
  // };

  // if (fetchError) {
  //   return (
  //     <Alert theme="danger">
  //       An error occurred while fetching the ClusterServiceVersion: {fetchError.message}
  //     </Alert>
  //   );
  // }

  useEffect(() => {
    if (selectedResource) {
      const example = yaml.dump(selectedResource.yamlExample);
      if (example) {
        //const _yml = yaml.dump(example);
        setDefaultValues(example);
        setNewValues(example);
      }
    }
  }, [selectedResource]);

  const updateNewValues = (_newValues:any) => {
    setNewValues(_newValues);
  };

  useEffect(() => {
    updateValues(newValues);
  }, [newValues]);

  return (
    <section>
      {/* <OperatorHeader title={`Create ${crd?.kind}`} icon={icon} /> */}
      {/* <section> */}
        {/* {createError && (
          <Alert theme="danger">
            An error occurred while creating the instance: {createError.message}
          </Alert>
        )} */}
        {/* <Row> */}
          {/* <Column span={3}>
            <OperatorSummary />
          </Column> */}
          {/* <Column span={9}>
            <p>{crd?.description}</p> */}
            <OperatorInstanceFormBody
              operatorDetails = {operatorDetails}
              //isFetching={isFetching}
              //namespace={namespace}
              //handleDeploy={handleDeploy}
              defaultValues={defaultValues}
              updateNewValues={updateNewValues}
            />
          {/* </Column> */}
        {/* </Row> */}
      {/* </section> */}
    </section>
  );
}
