const SUBSCRIPTION_PREFIX = "@SUBSCRIPTION";

export const FETCH_SUBSCRIPTIONS = `${SUBSCRIPTION_PREFIX}/FETCH_SUBSCRIPTIONS`;
export const FETCH_SUBSCRIPTIONS_SUCCESS = `${SUBSCRIPTION_PREFIX}/FETCH_SUBSCRIPTIONS_SUCCESS`;
export const FETCH_SUBSCRIPTIONS_FAILURE = `${SUBSCRIPTION_PREFIX}/FETCH_SUBSCRIPTIONS_FAILURE`;
export const FETCH_SUBSCRIPTION = `${SUBSCRIPTION_PREFIX}/FETCH_SUBSCRIPTION`;
export const FETCH_SUBSCRIPTION_SUCCESS = `${SUBSCRIPTION_PREFIX}/FETCH_SUBSCRIPTION_SUCCESS`;
export const FETCH_SUBSCRIPTION_FAILURE = `${SUBSCRIPTION_PREFIX}/FETCH_SUBSCRIPTION_FAILURE`;
export const DELETE_SUBSCRIPTION = `${SUBSCRIPTION_PREFIX}/DELETE_SUBSCRIPTION`;
export const DELETE_SUBSCRIPTION_SUCCESS = `${SUBSCRIPTION_PREFIX}/DELETE_SUBSCRIPTION_SUCCESS`;
export const DELETE_SUBSCRIPTION_FAILURE = `${SUBSCRIPTION_PREFIX}/DELETE_SUBSCRIPTION_FAILURE`;
export const CREATE_SUBSCRIPTION_CALL = `${SUBSCRIPTION_PREFIX}/CREATE`;
export const CREATE_SUBSCRIPTION_CALL_SUCCESS = `${SUBSCRIPTION_PREFIX}/CREATE_SUCCESS`;
export const CREATE_SUBSCRIPTION_CALL_FAILURE = `${SUBSCRIPTION_PREFIX}/CREATE_FAILURE`;
export const EDIT_SUBSCRIPTION_CALL = `${SUBSCRIPTION_PREFIX}/EDIT`;
export const EDIT_SUBSCRIPTION_CALL_SUCCESS = `${SUBSCRIPTION_PREFIX}/EDIT_SUCCESS`;
export const EDIT_SUBSCRIPTION_CALL_FAILURE = `${SUBSCRIPTION_PREFIX}/EDIT_FAILURE`;

const fetchSubscriptions = () => ({
  type: FETCH_SUBSCRIPTIONS,
});

const fetchSubscription = (id) => ({
  type: FETCH_SUBSCRIPTION,
  data: {
    id,
  },
});

const deleteSubscription = (id) => ({
  type: DELETE_SUBSCRIPTION,
  data: {
    id,
  },
});

const createSubscriptionCall = (payload, history) => {
  return {
    type: CREATE_SUBSCRIPTION_CALL,
    data: {
      subscriptionData: payload,
      history,
    },
  };
};
const editSubscriptionCall = (subId, payload, history) => {
  return {
    type: EDIT_SUBSCRIPTION_CALL,
    data: {
      id: subId,
      subscriptionData: payload,
      history,
    },
  };
};

export { 
  fetchSubscriptions, 
  fetchSubscription, 
  deleteSubscription, 
  createSubscriptionCall, 
  editSubscriptionCall 
};
