import { call, takeLatest, put, select } from "redux-saga/effects";
import axios from "axios";
import endpoints from "../../../constants/endpoints";
import { sessionTokenSelector } from "../../login/redux/selectors";
import {
  FETCH_SUBSCRIPTIONS,
  FETCH_SUBSCRIPTIONS_SUCCESS,
  FETCH_SUBSCRIPTIONS_FAILURE,
  FETCH_SUBSCRIPTION,
  FETCH_SUBSCRIPTION_SUCCESS,
  FETCH_SUBSCRIPTION_FAILURE,
  DELETE_SUBSCRIPTION,
  DELETE_SUBSCRIPTION_SUCCESS,
  DELETE_SUBSCRIPTION_FAILURE,
  CREATE_SUBSCRIPTION_CALL,
  CREATE_SUBSCRIPTION_CALL_SUCCESS,
  CREATE_SUBSCRIPTION_CALL_FAILURE,
  EDIT_SUBSCRIPTION_CALL,
  EDIT_SUBSCRIPTION_CALL_SUCCESS,
  EDIT_SUBSCRIPTION_CALL_FAILURE,
} from "./actions";
import toast from "../../../components/toast/Toast";

async function subscriptionsCall(sessionToken) {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  const response = await axios.get(
    endpoints.ORGANIZATION.FETCH_SUBSCRIPTIONS,
    config
  );
  return response;
}

function* fetchSubscriptions() {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(subscriptionsCall, sessionToken);
    const data = response.data;
    if (data) {
      yield put({ type: FETCH_SUBSCRIPTIONS_SUCCESS, data });
    } else {
      yield put({
        type: FETCH_SUBSCRIPTIONS_FAILURE,
        data: {
          error: "Error While fetching Subscription",
        },
      });
    }
  } catch (err) {
    yield put({
      type: FETCH_SUBSCRIPTIONS_FAILURE,
      data: {
        err: err.message,
      },
    });
  }
}

async function fetchSubscriptionApiCall(sessionToken, id) {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  const response = await axios.get(
    endpoints.GET_SUBSCRIPTION.replace(":id", id),
    config
  );
  return response;
}

async function deleteSubscriptionApiCall(sessionToken, id) {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  const response = await axios.delete(
    endpoints.EDIT_SUBSCRIPTION.replace(":id", id),
    config
  );
  return response;
}

function* fetchSubscription(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(
      fetchSubscriptionApiCall,
      sessionToken,
      payload.data.id
    );
    const data = response.data;
    if (data) {
      yield put({ type: FETCH_SUBSCRIPTION_SUCCESS, data });
    } else {
      yield put({
        type: FETCH_SUBSCRIPTION_FAILURE,
        data: {
          error: "Error While fetching a Subscription",
        },
      });
    }
  } catch (err) {
    yield put({
      type: FETCH_SUBSCRIPTIONS_FAILURE,
      data: {
        error: err.message,
      },
    });
  }
}
function* deleteSubscription(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(
      deleteSubscriptionApiCall,
      sessionToken,
      payload.data.id
    );
    const data = response.data;
    if (response.status === 204 || response.status === 200) {
      yield put({ type: DELETE_SUBSCRIPTION_SUCCESS, data });
      yield put({ type: FETCH_SUBSCRIPTIONS });

      toast.success("Successfully Deleted Subscription");
    } else {
      yield put({
        type: DELETE_SUBSCRIPTION_FAILURE,
        data: {
          error: "Error While deleting a Subscription",
        },
      });
      toast.error("Couldn't Delete Subscription");
    }
  } catch (error) {
    yield put({
      type: DELETE_SUBSCRIPTION_FAILURE,
      data: {
        error: error.message,
      },
    });
    if(error && error.response && error.response.data && error.response.data.error)
      toast.error(error.response.data.error);
  }
}

const SubscriptionCall = async (sessionToken, data, method, id) => {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  let res;
  if (method === "post") {
    res = await axios.post(endpoints.CREATE_SUBSCRIPTION, data, config);
  } else if (method === "put") {
    res = await axios.put(
      endpoints.EDIT_SUBSCRIPTION.replace(":id", id),
      data,
      config
    );
  }
  return res;
};

function* createSubscription(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const res = yield call(
      SubscriptionCall,
      sessionToken,
      payload.data.subscriptionData,
      "post"
    );
    const data = res.data;
    if (data) {
      yield put({ type: CREATE_SUBSCRIPTION_CALL_SUCCESS, data });
      toast.success("Subscription Created Successfully");
    } else {
      yield put({
        type: CREATE_SUBSCRIPTION_CALL_FAILURE,
        data: {
          error: "Error While creating subscription",
        },
      });
      toast.error("Some Error while adding Subscription");
    }
  } catch (error) {
    yield put({
      type: CREATE_SUBSCRIPTION_CALL_FAILURE,
      data: { error: error.message },
    });
    if(error && error.response && error.response.data && error.response.data.error)
      toast.error(error.response.data.error);
  }
}
function* editSubscription(payload) {
  try {
    console.log("Edit Subscriptions");
    const sessionToken = yield select(sessionTokenSelector);
    if (!payload.data.id) {
      toast.error("Cannot Update subscription without ID");
      yield put({
        type: EDIT_SUBSCRIPTION_CALL_FAILURE,
        data: {
          error: "Error While Updating subscription",
        },
      });
    } else {
      const res = yield call(
        SubscriptionCall,
        sessionToken,
        payload.data.subscriptionData,
        "put",
        payload.data.id
      );
      const data = res.data;
      if (data) {
        yield put({ type: EDIT_SUBSCRIPTION_CALL_SUCCESS, data });
        toast.success("Subscription Updated Successfully");
      } else {
        yield put({
          type: EDIT_SUBSCRIPTION_CALL_FAILURE,
          data: {
            error: "Error While Updating subscription",
          },
        });
        toast.error("Some Error while updating Subscription");
      }
    }
  } catch (error) {
    yield put({
      type: EDIT_SUBSCRIPTION_CALL_FAILURE,
      data: { error: error.message },
    });
    if(error && error.response && error.response.data && error.response.data.error)
      toast.error(error.response.data.error);
  }
}

export default function* watcherSaga() {
  yield takeLatest(FETCH_SUBSCRIPTIONS, fetchSubscriptions);
  yield takeLatest(FETCH_SUBSCRIPTION, fetchSubscription);
  yield takeLatest(DELETE_SUBSCRIPTION, deleteSubscription);
  yield takeLatest(CREATE_SUBSCRIPTION_CALL, createSubscription);
  yield takeLatest(EDIT_SUBSCRIPTION_CALL, editSubscription);
}
