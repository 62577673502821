import { 
  FETCH_JOB_LIST_SUCCESS,
  FETCH_JOB_DETAILS_SUCCESS,
  CREATE_CRONJOB,
  CREATE_CRONJOB_SUCCESS,
  CREATE_CRONJOB_FAILURE,
  CLEAR_CREATE_CRONJOB,
  UPDATE_CRONJOB,
  UPDATE_CRONJOB_SUCCESS,
  UPDATE_CRONJOB_FAILURE,
  DELETE_CRONJOB,
  DELETE_CRONJOB_SUCCESS,
  DELETE_CRONJOB_FAILURE,
  CRONJOB_FETCH_SUCCESS,
  GET_CRONJOB_STATUS_SUCCESS,
  GET_CRON_IMAGES_SUCCESS,
  RUN_CRONJOB,
  RUN_CRONJOB_SUCCESS,
  RUN_CRONJOB_FAILURE,
  CLEAR_CRONJOB_DETAILS,
  CLEAR_CRONJOB_LIST,
  FETCH_CRONJOB_LOG_SUCCESS,
  FETCH_MORE_CRONJOB_LOG_SUCCESS,
  FETCH_JOB_LIST_FAILURE,
  FETCH_JOB_LIST,
  FETCH_JOB_DETAILS_FAILURE,
  FETCH_JOB_DETAILS,
} from './actions';
import initialState from './initialState';

const reducer = (state = initialState, payload ) => {
  switch (payload.type) {
    case FETCH_JOB_LIST:
      return {
        ...state,
        fetchingCronjobList : true
      }
    case FETCH_JOB_LIST_SUCCESS:
      return {
        ...state,
        cronjobList : payload.data,
        fetchingCronjobList: false
      }
    case FETCH_JOB_LIST_FAILURE:
      return {
        ...state,
        fetchingCronjobList : false
      }
    case FETCH_JOB_DETAILS:
      return {
        ...state,
        fetchingJobDetail : true
      }
    case FETCH_JOB_DETAILS_SUCCESS:
      return {
        ...state,
        cronjobDetails : payload.data,
        fetchingJobDetail : false

      }
    case FETCH_JOB_DETAILS_FAILURE:
      return {
        ...state,
        fetchingJobDetail : false
      }
    case CREATE_CRONJOB:
      return {
        ...state,
        isSavingCronJob : true
      }  
    case CREATE_CRONJOB_SUCCESS:
      return {
        ...state,
        cronjob_createdId : payload.data,
        isSavingCronJob : false
      }
    case CREATE_CRONJOB_FAILURE:
      return {
        ...state,
        isSavingCronJob : false
      }
    case UPDATE_CRONJOB:
      return {
        ...state,
        isSavingCronJob: true
      }
    case UPDATE_CRONJOB_SUCCESS:
      return {
        ...state,
        isSavingCronJob : false
      }
    case UPDATE_CRONJOB_FAILURE:
      return {
        ...state,
        isSavingCronJob : false
      }
    case DELETE_CRONJOB:
      return {
        ...state,
        isDeletingJob: true
      }
    case DELETE_CRONJOB_SUCCESS:
      return {
        ...state,
        isDeletingJob: false
      }
    case DELETE_CRONJOB_FAILURE:
      return {
        ...state,
        isDeletingJob: false
      }
    case CRONJOB_FETCH_SUCCESS:
      return {
        ...state,
        cronjob_fetch : payload.data
      }
    case GET_CRONJOB_STATUS_SUCCESS:
      return {
        ...state,
        cronjob_status : payload.data
      }
    case GET_CRON_IMAGES_SUCCESS:
      return {
        ...state,
        cronImages: payload.data
      }
    case CLEAR_CREATE_CRONJOB:
      return {
        ...state,
        cronjob_createdId: -1,
        isSavingCronJob: false
      }
    case CLEAR_CRONJOB_DETAILS:
      return {
        ...state,
        cronjobDetails: null,
        cronjob_Log: null
      }
    case CLEAR_CRONJOB_LIST:
      return {
        ...state,
        cronjobList: null
      }
    case RUN_CRONJOB:
      return {
        ...state,
        showCronJobListLoader: true
      }
    case RUN_CRONJOB_SUCCESS:
      return {
        ...state,
        showCronJobListLoader: false
      }
    case RUN_CRONJOB_FAILURE:
      return {
        ...state,
        showCronJobListLoader: false
      }
    case FETCH_CRONJOB_LOG_SUCCESS:
      return {
        ...state,
        cronjob_Log: payload.data
      }
    case FETCH_MORE_CRONJOB_LOG_SUCCESS:
      return {
        ...state,
        cronjob_Log : [ ...state.cronjob_Log, ...payload.data ]
      }
    default:
      return state
  }
}

export default reducer