import {
    Card,
    CardContent,
    CardHeader,
    Button,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableHead,
    TableContainer,
    Paper
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { isAuthorized } from "../../helpers/utils";
import EditIcon from "@material-ui/icons/Edit";
import { useTranslation } from 'react-i18next';
import { UserRole } from "../../models/Environment.model";
import MuiTextField from "../textfield/MuiTextField";
import {
    StyledTableCell,
    StyledTableRow,
} from "../styledtabelcell/StyledTabelCell";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

interface Pipeline {
    name: string
    command: string
}

interface PipelineLocal extends Pipeline {
    //id: number,
    isError?: string
}

type Props = {
    pipeLine: Pipeline[],
    source: number,
    envRole?: UserRole
    handleSubmit: () => void,
    onChange: (ciSteps: Pipeline[]) => void
}

export default function CIPipeline(props: Props) {
    const [pipeLineSteps, setPipeLineSteps] = useState<PipelineLocal[]>([]);
    const [edit, setEdit] = useState(false);
    // const [fieldError, setFieldError] = useState(false);
    const [t] = useTranslation()

    useEffect(() => {
        if (props.source === 1 || edit) {
            props.onChange(pipeLineSteps);
        }
    }, [pipeLineSteps]);

    useEffect(() => {
        setInitialValues();
        return () => {
            // setEdit(false);
            // setBuildScript("");
            // setRunScript("");
        };
    }, []);

    const setInitialValues = () => {
        // let newPipeline: PipelineLocal[] = [];
        if (props.pipeLine && props.pipeLine.length > 0) {
            //     props.pipeLine.map((item, index) => {
            //         newPipeline.push({ ...item, id: index });
            //     })
            setPipeLineSteps(props.pipeLine);
        }
        else 
            setPipeLineSteps([]);
    }

    const editHandler = () => {
        setEdit(true);
    };

    const handleSubmit = () => {
        props.handleSubmit();
    };

    const handleCancel = () => {
        setEdit(false);
        setInitialValues();
        // setBuildScript(props.scripts?.build ?? "");
        // setRunScript(props.scripts?.run ?? "");
    };

    const getEmptyRowObject = () => {
        return {
            //id: -1,
            name: "",
            command: "",
        };
    }

    const addNew = () => {
        let variables = [ ...pipeLineSteps ];
        if (variables)
            variables.push(getEmptyRowObject());

        setPipeLineSteps(variables);
    };

    const handleDelete = (index: number) => {
        let variables = [...pipeLineSteps];
        variables.splice(index, 1);
        setPipeLineSteps(variables);
    }

    const isKeyDuplicate = (name: string) => {
        if (name !== "" && pipeLineSteps) {
            const exists = pipeLineSteps.filter(
                (variable) => variable?.name?.toUpperCase() === name?.toUpperCase()
            );
            if (exists?.length > 1) {
                return true;
            }
        }
        return false;
    };

    const handleKeyChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        //this.setState({ isBlocking: true });
        /*const currentId = !isNaN(parseInt(e.target.name))
            ? parseInt(e.target.name)
            : e.target.name;
        const value = e.target.value;

        const variables = [...pipeLineSteps];
        if (variables) {
            if (currentId > -1) {
                let foundIndex = variables.findIndex((x) => x.id === currentId);
                if (foundIndex !== -1) {
                    variables[foundIndex]["name"] = value;
                    variables[foundIndex]["isError"] = !/^[A-Za-z0-9_-]*$/.test(value)
                        ? t("AlphaNumericError")
                        : value.trim().length === 0
                            ? t("EmptyError")
                            : "";

                    let variable = variables[foundIndex];

                    if (!!variable.isError && variable.isError.length) {
                        setFieldError(true);
                    } else {
                        setFieldError(false);
                    }
                }
            } else {
                const currentLength = variables.length;

                let foundIndex = variables.findIndex((x) => x.id === -1);
                variables[foundIndex]["isError"] = !/^[A-Za-z0-9_-]*$/.test(value)
                    ? t("AlphaNumericError")
                    : "";

                variables[foundIndex]["id"] = currentLength - 1;
                variables[foundIndex]["name"] = value;
                let variable = variables[foundIndex];

                if (!!variable.isError && variable.isError.length) {
                    setFieldError(true);
                } else {
                    setFieldError(false);
                }
            }
        }*/
        const value = e.target.value;
        const variables = [...pipeLineSteps];
        if (variables) {
            //variables[index]["id"] = currentLength - 1;
            variables[index]["name"] = value;
            variables[index]["isError"] = !/^[A-Za-z0-9_-]*$/.test(value)
                ? t("AlphaNumericError")
                : value.trim().length === 0
                    ? t("EmptyError")
                    : "";
            // let variable = variables[index];

            // if (!!variable.isError && variable.isError.length) {
            //     setFieldError(true);
            // } else {
            //     setFieldError(false);
            // }
        }
        setPipeLineSteps(variables);
        validateFieldError(variables);
    }

    const validateFieldError = (variables: PipelineLocal[]) => {
        let isFieldError = false;
        variables && variables.map((item) => {
            if (item.isError) {
                isFieldError = Boolean(item.isError) || isFieldError;
            }
        });
        // setFieldError(isFieldError);
    }

    const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        //this.setState({ isBlocking: true });
        /*const currentId = !isNaN(parseInt(e.target.name))
            ? parseInt(e.target.name)
            : e.target.name;
        const value = e.target.value;

        const variables = [...pipeLineSteps];
        if (variables) {
            let foundIndex;
            if (currentId > -1) {
                foundIndex = variables.findIndex((x) => x.id === currentId);
                if (foundIndex > -1) {
                    variables[foundIndex].command = value;
                }
            } else {
                const currentLength = variables.length;

                foundIndex = variables.findIndex((x) => x.id === -1);
                variables[foundIndex].id = currentLength - 1;
                variables[foundIndex].command = value;
            }
        }*/
        const value = e.target.value;
        const variables = [...pipeLineSteps];
        if (variables) {
            variables[index].command = value;
        }
       
        setPipeLineSteps(variables);
        //validateFieldError(variables);
    }

    return (
        <div data-test="pipeLineContainer">
            <Card>
                <CardHeader
                    title={t('CISteps')}
                    action={
                        props.source === 2 ? (
                            isAuthorized("update", props.envRole?.name) ? (
                                <Grid container spacing={2} data-test="actionsContainer">
                                    {edit && (
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => addNew()}
                                                // className="m-l-40"
                                                data-test="add-var-btn"
                                            >
                                                Add Step
                                            </Button>
                                        </Grid>
                                    )}
                                    {edit && (
                                        <Grid item>
                                            <Button
                                                onClick={handleCancel}
                                                variant="contained"
                                                color="primary"
                                                data-test="cancelButton"
                                            >
                                                {t('Cancel')}
                                            </Button>
                                        </Grid>
                                    )}
                                    <Grid item>
                                        {edit ? (
                                            <Button
                                                onClick={handleSubmit}
                                                variant="contained"
                                                color="primary"
                                                //disabled={!hasChanged()}
                                                data-test="updateButton"
                                            >
                                                {t('Update')}
                                            </Button>
                                        ) : (
                                            <IconButton
                                                data-test="editButton"
                                                title="Edit CI Pipeline"
                                                onClick={() => editHandler()}
                                            >
                                                <EditIcon color="primary" />
                                            </IconButton>
                                        )}
                                    </Grid>
                                </Grid>
                            ) : (
                                ""
                            )
                        ) : (
                            ""
                        )
                    }
                />
                <CardContent>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell>NAME</StyledTableCell>
                                    <StyledTableCell>COMMAND</StyledTableCell>
                                    {/* <StyledTableCell>ACTIONS</StyledTableCell> */}
                                    <StyledTableCell></StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {pipeLineSteps && pipeLineSteps.map((item, index) => {
                                    const isDuplicate = isKeyDuplicate(item.name);
                                    return (
                                        <StyledTableRow key={index} data-test="var-row">
                                            <StyledTableCell component="th" scope="row">
                                                <MuiTextField
                                                    value={item.name}
                                                    //name={item.id.toString()}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleKeyChange(e, index)}
                                                    error={isDuplicate ? true : false}
                                                    helperText={isDuplicate ? "Dupicate Key" : ""}
                                                    disabled={!edit}
                                                    data-test="var-key"
                                                ></MuiTextField>
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                <MuiTextField
                                                    value={item.command}
                                                    //name={item.id.toString()}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleValueChange(e, index)}
                                                    disabled={!edit}
                                                    data-test="var-value"
                                                ></MuiTextField>
                                            </StyledTableCell>
                                            {edit &&
                                                <StyledTableCell align="center" component="th" scope="row">
                                                    <div className="actionsIcon">
                                                        <IconButton
                                                            aria-label="delete"
                                                            onClick={() => {                                                                
                                                                handleDelete(index);
                                                            }}
                                                        //data-test="userVariable-delete-icon"
                                                        >
                                                            <DeleteForeverIcon className="actionsIcon" />
                                                        </IconButton>
                                                    </div>
                                                </StyledTableCell>
                                            }
                                        </StyledTableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        {(!pipeLineSteps || pipeLineSteps.length === 0) && 
                            <div className="alignCenter">
                                <p> No variables defined. </p>
                            </div>
                        }
                    </TableContainer>
                </CardContent>
            </Card>
        </div>
    );
}