const CRONJOB_PREFIX = '@CRONJOB';

export const FETCH_JOB_LIST = `${ CRONJOB_PREFIX }/FETCH_JOB_LIST`
export const FETCH_JOB_LIST_SUCCESS = `${ CRONJOB_PREFIX }/FETCH_JOB_LIST_SUCCESS`
export const FETCH_JOB_LIST_FAILURE = `${ CRONJOB_PREFIX }/FETCH_JOB_LIST_FAILURE`
export const FETCH_JOB_DETAILS = `${ CRONJOB_PREFIX }/FETCH_JOB_DETAILS`
export const FETCH_JOB_DETAILS_SUCCESS = `${ CRONJOB_PREFIX }/FETCH_JOB_DETAILS_SUCCESS`
export const FETCH_JOB_DETAILS_FAILURE = `${ CRONJOB_PREFIX }/FETCH_JOB_DETAILS_FAILURE`
export const CREATE_CRONJOB = `${ CRONJOB_PREFIX }/CREATE_CRONJOB`
export const CREATE_CRONJOB_SUCCESS = `${ CRONJOB_PREFIX }/CREATE_CRONJOB_SUCCESS`
export const CREATE_CRONJOB_FAILURE = `${ CRONJOB_PREFIX }/CREATE_CRONJOB_FAILURE`
export const UPDATE_CRONJOB = `${ CRONJOB_PREFIX }/UPDATE_CRONJOB`
export const UPDATE_CRONJOB_SUCCESS = `${ CRONJOB_PREFIX }/UPDATE_CRONJOB_SUCCESS`
export const UPDATE_CRONJOB_FAILURE = `${ CRONJOB_PREFIX }/UPDATE_CRONJOB_FAILURE`
export const DELETE_CRONJOB = `${ CRONJOB_PREFIX }/DELETE_CRONJOB`
export const DELETE_CRONJOB_SUCCESS = `${ CRONJOB_PREFIX }/DELETE_CRONJOB_SUCCESS`
export const DELETE_CRONJOB_FAILURE = `${ CRONJOB_PREFIX }/DELETE_CRONJOB_FAILURE`
export const CRONJOB_FETCH = `${ CRONJOB_PREFIX }/CRONJOB_FETCH`
export const CRONJOB_FETCH_SUCCESS = `${ CRONJOB_PREFIX }/CRONJOB_FETCH_SUCCESS`
export const CRONJOB_FETCH_FAILURE = `${ CRONJOB_PREFIX }/CRONJOB_FETCH_FAILURE`
export const GET_CRONJOB_STATUS = `${ CRONJOB_PREFIX }/GET_CRONJOB_STATUS`
export const GET_CRONJOB_STATUS_SUCCESS = `${ CRONJOB_PREFIX }/GET_CRONJOB_STATUS_SUCCESS`
export const GET_CRONJOB_STATUS_FAILURE = `${ CRONJOB_PREFIX }/GET_CRONJOB_STATUS_FAILURE`
export const GET_CRON_IMAGES = `${ CRONJOB_PREFIX }/GET_CRON_IMAGES`
export const GET_CRON_IMAGES_SUCCESS = `${ CRONJOB_PREFIX }/GET_CRON_IMAGES_SUCCESS`
export const GET_CRON_IMAGES_FAILURE = `${ CRONJOB_PREFIX }/GET_CRON_IMAGES_FAILURE`
export const CLEAR_CREATE_CRONJOB = `${ CRONJOB_PREFIX }/CLEAR_CREATE_CRONJOB`
export const CLEAR_CRONJOB_DETAILS = `${ CRONJOB_PREFIX }/CLEAR_CRONJOB_DETAILS`
export const CLEAR_CRONJOB_LIST = `${ CRONJOB_PREFIX }/CLEAR_CRONJOB_LIST`
export const RUN_CRONJOB = `${ CRONJOB_PREFIX }/RUN_CRONJOB`
export const RUN_CRONJOB_SUCCESS = `${ CRONJOB_PREFIX }/RUN_CRONJOB_SUCCESS`
export const RUN_CRONJOB_FAILURE = `${ CRONJOB_PREFIX }/RUN_CRONJOB_FAILURE`
export const FETCH_CRONJOB_LOG = `${ CRONJOB_PREFIX }/FETCH_CRONJOB_LOG`
export const FETCH_CRONJOB_LOG_SUCCESS = `${ CRONJOB_PREFIX }/FETCH_CRONJOB_LOG_SUCCESS`
export const FETCH_MORE_CRONJOB_LOG_SUCCESS= `${ CRONJOB_PREFIX }/FETCH_MORE_CRONJOB_LOG_SUCCESS`
export const FETCH_CRONJOB_LOG_FAILURE = `${ CRONJOB_PREFIX }/FETCH_CRONJOB_LOG_FAILURE`

const fetchJobList = (eId) => ({
	type: FETCH_JOB_LIST,
	data: { eId }
})

const fetchJobDetails = (eId, cId) => ({
	type: FETCH_JOB_DETAILS,
	data: { eId, cId }
})

const createCronJob = (eId, jsonBody) => ({
	type: CREATE_CRONJOB,
	data: { eId, jsonBody }
})

const updateCronJob = (eId, cId, jsonBody, callback) => ({
	type: UPDATE_CRONJOB,
	data: { eId, cId, jsonBody, callback }
})

const deleteCronJob = (eId, cId) => ({
	type: DELETE_CRONJOB,
	data: { eId, cId }
})

const fetchCronJob = (eId) => ({
	type: CRONJOB_FETCH,
	data: { eId }
})

const getCronJobStatus = (eId) => ({
	type: GET_CRONJOB_STATUS,
	data: { eId }
})

const getCronImages = () => ({
	type: GET_CRON_IMAGES,
})

const runCronJob = (eId, cId) => ({
	type: RUN_CRONJOB,
	data: { eId, cId }
})

const clearCreateCronJob = () =>({ type: CLEAR_CREATE_CRONJOB })

const clearCronJobDetails = () =>({ type: CLEAR_CRONJOB_DETAILS })

const clearCronJobList = () => ({ type: CLEAR_CRONJOB_LIST })

const fetchCronJobLog = (eId, cId, pageNo, pageSize) => ({
	type: FETCH_CRONJOB_LOG,
	data: { eId, cId, pageNo, pageSize }
})

export {
	fetchJobList,
	fetchJobDetails,
	createCronJob,
	updateCronJob,
	deleteCronJob,
	fetchCronJob,
	getCronJobStatus,
	getCronImages,
	clearCreateCronJob,
	clearCronJobDetails,
	clearCronJobList,
	runCronJob,
	fetchCronJobLog
}