import React, { Component } from 'react'
//import { withStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import './userpermissionstab.css';
import { fetchUserListInEnvironment, clearUserListInEnvironment } from '../../../../../components/adduserpopup/redux/actions'
import BackdropLoader from '../../../../../components/loader/BackdropLoader';
import EnvPermission from '../../../../../components/environment/settings/EnvPermission';
import { Dispatch } from "redux";
import { History } from 'history';

interface Props extends WithTranslation, PropsFromRedux {
  history: History
}

export class UserPermissionTab extends Component<Props> {
    constructor(props: Props){
        super(props);
        this.state = {
          isDeleteUserPopupOpened : 0,
          userIdToBeDeleted : 0,
          isAddUserPopupOpened : 0,
          userToBeEdited: null,
          edit: false,
          isLeave: false,
        }
    }

    componentDidMount(){
      if (this.props.environmentDetails.id > 0)
        this.props.fetchUserListInEnvironmentAction(this.props.environmentDetails.id)
    }

    componentWillUnmount(){
      this.props.clearUserListInEnvironment();
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
      if(nextProps)
      {
        if(nextProps.environmentDetails.id > 0)
        {
          if (!this.props.environmentDetails.id || this.props.environmentDetails.id !== nextProps.environmentDetails.id)
          {
              this.props.fetchUserListInEnvironmentAction(nextProps.environmentDetails.id);
          }
        }
      }
    }

    // handleAddUser = () => {
    //   this.setState({
    //     isAddUserPopupOpened : Math.random(),
    //     edit: false,
    //     userToBeEdited: null,
    //   })
    // }
  
    // handleDeleteUser = (userId) => {
    //   this.setState({
    //     userIdToBeDeleted : userId,
    //     isDeleteUserPopupOpened : Math.random(),
    //   })
    // }
  
    // handleLeaveEnvironment = (userId) => {
    //   this.setState({
    //     userIdToBeDeleted : userId,
    //     isDeleteUserPopupOpened : Math.random(),
    //     isLeave: true,
    //   })
    // }

    // setEditFalse = () =>{
    //   console.log("called")
    //   this.setState({
    //     edit: false,
    //     userToBeEdited: null,
    //     isAddUserPopupOpened: Math.random()
    //   })
    // }

    // handleEdit = (user) => {
    //   this.setState({
    //     userToBeEdited : user,
    //     isAddUserPopupOpened : Math.random(),
    //     edit: true,
    //   })
    // }

    // handleClose = () => {
    //   this.setState({
    //     isLeave: false
    //   })
    // }

    // goToGroups = (id) => {
    //   this.props.history.push(`/organization/${id}#groups`)
    // }

    render () {
      const { t } = this.props
        return (
            <div data-test="main-container">
              <EnvPermission
                  usersList={this.props.usersListInEnvironment}
                  environmentDetails={this.props.environmentDetails}
                  envRole = {this.props.envRole}
                  history={this.props.history}
              />
               
                { this.props.addingUser && <BackdropLoader message={t('Environment.UserPermissionsTab.addUserToEnv')} />}
                { this.props.editingUser && <BackdropLoader message={t('Environment.UserPermissionsTab.updatingRole')} />}
                { this.props.deletingUser && <BackdropLoader message={t('Environment.UserPermissionsTab.deletingUser')} />}
                { this.props.fetchingUserList && <BackdropLoader message={t('Environment.UserPermissionsTab.fetchingUser')} />}
            </div>
        )
    }
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  environmentDetails: state.EnvironmentReducer.environmentDetails,
  projectDetails: state.ProjectReducer.projectDetails,
  usersListInEnvironment: state.AddUserPopupReducer.usersListInEnvironment,
  envRole: state.EnvironmentReducer.envRole,
  addingUser: state.AddUserPopupReducer.addingUser,
  editingUser: state.AddUserPopupReducer.editingUser,
  deletingUser: state.AddUserPopupReducer.deletingUser,
  fetchingUserList: state.AddUserPopupReducer.fetchingUserList,
  currentUser: state.AuthReducer.user,
})

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    fetchUserListInEnvironmentAction : (evironmentId: number) => dispatch(fetchUserListInEnvironment(evironmentId)),
    clearUserListInEnvironment: () => dispatch(clearUserListInEnvironment()),
  }
}

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withTranslation()(UserPermissionTab));