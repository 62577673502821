import React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
// import DeploymentFormBody from "./DeploymentFormBody";
import {
    Grid,
    Button,
    ButtonBase
} from "@material-ui/core";
// import { makeStyles } from "@material-ui/styles";
import YAML from "yaml";
import * as jsonpatch from "fast-json-patch";
import { useTranslation } from "react-i18next";
// import { RouterAction } from "connected-react-router";
// import { JSONSchema4 } from "json-schema";
// import { CreateError, FetchError, IChartState, IChartVersion } from "../../shared/types";
// import * as url from "../../shared/url";
// import LoadingWrapper from "../LoadingWrapper/LoadingWrapper";
// import actions from "actions";
// import ChartSummary from "components/Catalog/ChartSummary";
// import ChartHeader from "components/ChartView/ChartHeader";
// import Alert from "components/js/Alert";
// import Column from "components/js/Column";
// import Row from "components/js/Row";
// import { useDispatch } from "react-redux";
// import "react-tabs/style/react-tabs.css";
// import { yamlData } from "./test"
// import { useTranslation } from "react-i18next";
import {
    clearChartVersionConfig,
    getChartVersions,
    getChartVersionConfig,
    redeployHelmEnvironment,
} from "../../pages/helmenvironment/redux/actions";
import BackdropLoader from "../loader/BackdropLoader";
import { deleteValue, setValue } from "./SchemaUtils";
import HelmEnvBasicFields from "./HelmEnvBasicFields";

function applyModifications(mods, values) {
    // And we add any possible change made to the original version
    if (mods.length) {
        mods.forEach(modification => {
            if (modification.op === "remove") {
                values = deleteValue(values, modification.path);
            } else {
                // Transform the modification as a ReplaceOperation to read its value
                const value = modification.value;
                values = setValue(values, modification.path, value);
            }
        });
    }
    return values;
}

export function UpgradeForm(props) {
    //const [isDeploying, setDeploying] = useState(false);
    const [releaseName, setReleaseName] = useState("");
    const [releaseNameErrorMessage, setReleaseNameErrorMessage] = useState("");
    const [selectedVersion, setSelectedVersion] = useState("Version");
    const [appValues, setAppValues] = useState(props.appCurrentValues || "");
    const [selected, setSelected] = useState({});
    const [valuesModified, setValuesModified] = useState(false);
    const [modifications, setModifications] = useState();
    const [deployedValues, setDeployedValues] = useState("");
    const [t] = useTranslation();
    // const [t] = useTranslation();
    // const classes = useStyles();

    const { environmentDetails, chartConfig, appCurrentValues } = props
    //const { version } = selected;
    //const dispatch = useDispatch();

    useEffect(() => {
        if (props.appDetails?.chart_id) {
            props.getChartVersions(props.appDetails.chart_id);
        }
        return () => {
            props.clearChartVersionConfig();
        };
    }, [props.appDetails]);

    useEffect(() => {
        if (chartConfig) {
            try {
                setSelected({
                    ...chartConfig,
                    schema: JSON.parse(chartConfig.schema),
                });
            } catch (e) {
                setSelected({
                    ...selected,
                    values: chartConfig.values,
                });
            }
        }
    }, [chartConfig]);

    useEffect(() => {
        if (deployedValues) {
            setSelected({
                ...selected,
                values: deployedValues,
            });
        }
    }, [deployedValues]);

    useEffect(() => {
        if (environmentDetails) {
            setReleaseName(environmentDetails.name)
            setSelectedVersion(environmentDetails?.chart_version_id)
            setAppValues(environmentDetails.values)
        }
    }, [environmentDetails])

    useEffect(() => {
        if (chartConfig?.values && !modifications) {
            // Calculate modifications from the default values
            const defaultValuesObj = YAML.parse(chartConfig.values);
            const deployedValuesObj = YAML.parse(appCurrentValues || "");
            const newModifications = jsonpatch.compare(defaultValuesObj, deployedValuesObj);
            const values = applyModifications(newModifications, chartConfig.values);
            setModifications(newModifications);
            setAppValues(values);
        }
    }, [chartConfig?.values, appCurrentValues, modifications]);

    useEffect(() => {
        if (chartConfig?.values) {
            // Apply modifications to deployed values
            const values = applyModifications(modifications || [], chartConfig.values);
            setDeployedValues(values);
        }
    }, [chartConfig?.values, modifications]);

    useEffect(() => {
        if (!valuesModified && selected.values) {
            // Apply modifications to the new selected version
            const newAppValues = modifications?.length
                ? applyModifications(modifications, selected.values)
                : selected.values;
            setAppValues(newAppValues);
        }
    }, [selected.values, modifications, valuesModified]);

    // useEffect(() => {
    //     if (props.chartVersionValues) {
    //         setAppValues(props.chartVersionValues)
    //     }
    // }, [props.chartVersionValues])

    useEffect(() => {
        if (selectedVersion && selectedVersion !== "Version") {
            props.getChartVersionConfig(selectedVersion);
        }
    }, [selectedVersion]);

    // useEffect(() => {
    //     fetchChartVersions(cluster, chartNamespace, chartID);
    // }, [fetchChartVersions, cluster, chartNamespace, chartID]);

    useEffect(() => {
        if (!valuesModified) {
            setAppValues(selected.values || "");
        }
    }, [selected.values, valuesModified]);

    // useEffect(() => {
    //     dispatch(actions.charts.getChartVersion(cluster, chartNamespace, chartID, chartVersion));
    // }, [ chartVersion, dispatch]);

    const handleValuesChange = (value) => {
        setAppValues(value);
    };

    const setValuesModifiedTrue = () => {
        setValuesModified(true);
    };

    const handleReleaseNameChange = (e) => {
        setReleaseName(e.target.value);
        if (e.target?.value?.trim().length === 0) {
            setReleaseNameErrorMessage("Name can't be empty");
        } else {
            setReleaseNameErrorMessage("");
        }
    };

    const versionChangeHandler = (e) => {
        setSelectedVersion(e.target.value);
    };

    const redeployEnvironment = () => {
        const payload = {
            name: releaseName,
            chart_version_id: selectedVersion,
            application_id: props.appDetails.id,
            values: appValues,
            // schema: selected.schema
        };
        props.redeployHelmEnvironment(props.environmentDetails?.id, payload, props.history);
    };

    const isErrors = () => {
        if (releaseName?.trim()?.length === 0) {
            return true;
        }
        if (!selectedVersion || selectedVersion === "Version") {
            return true;
        }
        return false;
    };

    const tabChanger = () => {
        props.history.goBack();
    }

    return (
        <section data-test="main-container">
            <ButtonBase
                onClick={tabChanger}
                style={{ color: "#357dfd", marginBottom: 20 }}
                data-test="backButton"
            >
                <ArrowBackIcon fontSize="small" />
                <span className="jobNav"> {t("HelmEnvironment.back")}</span>
            </ButtonBase>
            {/* <ChartHeader
                chartAttrs={chartAttrs}
                versions={selected.versions}
                onSelect={selectVersion}
                selectedVersion={selected.version?.attributes.version}
            /> */}
            {/* {isDeploying && (
                <h3 className="center" style={{ marginBottom: "1.2rem" }}>
                    Hang tight, the application is being deployed...
                </h3>
            )} */}
            {/* <LoadingWrapper loaded={!isDeploying}> */}
            {/* <Row> */}
            {/* <Column span={3}>
                        <ChartSummary version={version} chartAttrs={chartAttrs} />
                    </Column> */}
            {/* <Column span={9}>
                        {error && <Alert theme="danger">An error occurred: {error.message}</Alert>} */}
            {/* <form onSubmit={handleDeploy}> */}
            {/* <div> */}
            {/* <label
                                    htmlFor="releaseName"
                                    className="deployment-form-label deployment-form-label-text-param"
                                >
                                    Name
                                </label> */}
            {/* <input
                                    //id="releaseName"
                                    //pattern="[a-z0-9]([-a-z0-9]*[a-z0-9])?(\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*"
                                    //title="Use lower case alphanumeric characters, '-' or '.'"
                                    //className="clr-input deployment-form-text-input"
                                    onChange={handleReleaseNameChange}
                                    value={releaseName}
                                    required={true}
                /> */}
            <Grid
                container
                spacing={2}
                direction="column"
                //justify="center"
                //alignItems="center"
                className="m-t-20"
            >
                <HelmEnvBasicFields
                    releaseName={releaseName}
                    releaseNameErrorMessage={releaseNameErrorMessage}
                    handleReleaseNameChange={handleReleaseNameChange}
                    appDetails={props.appDetails}
                    versionChangeHandler={versionChangeHandler}
                    selectedVersion={selectedVersion}
                    selected={selected}
                    chartVersions={props.chartVersions}
                    appValues={appValues}
                    setValuesModifiedTrue={setValuesModifiedTrue}
                    deploymentEvent={"upgrade"}
                    handleValuesChange={handleValuesChange}
                    deployedValues={deployedValues}
                />
                {/* <Grid item xs={12} data-test="form-container">
                    <Card className={`w-100`}>
                        <CardContent>
                            <Grid container direction="column" spacing={2}>
                                <Grid item xs={12}>
                                    <div className="alignCenter">
                                        <img
                                            src={props.appDetails?.chart?.Icon ?? ""}
                                            alt={props.appDetails?.chart?.Name ?? "Chart"}
                                            className={classes.logo}
                                            data-test="chart-image"
                                        />
                                    </div>
                                    <div className="alignCenter m-b-10">
                                        <Typography variant="h5">
                                            {props.appDetails?.chart?.Name}{" "}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <MuiTextField
                                        //variant="outlined"
                                        //color='primary'
                                        error={releaseNameErrorMessage.length > 0}
                                        helperText={releaseNameErrorMessage}
                                        //required
                                        autoFocus
                                        label={t("ResourcePaper.environmentName")}
                                        //onBlur={handleEnvironmentNameOnBlur}
                                        onChange={handleReleaseNameChange}
                                        value={releaseName}
                                        data-test="name-field"
                                        disabled
                                    //data-test="environment-field"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div className="basic-deployment-form-param">
                                        <FormControl
                                            variant="outlined"
                                            className="w-100"
                                            data-test="env-version-select"
                                        >
                                            <InputLabel id="demo-simple-select-outlined-label">
                                                {t("Environment.CreateAppMaster.version")}
                                            </InputLabel>
                                            <Select
                                                //data-test="version-select"
                                                color="primary"
                                                //labelId="demo-simple-select-label"
                                                labelId="demo-simple-select-outlined-label"
                                                //id="demo-simple-select"
                                                value={selectedVersion}
                                                //gutterButtom
                                                //className=""
                                                onChange={versionChangeHandler}
                                                MenuProps={{
                                                    getContentAnchorEl: null,
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "left",
                                                    },
                                                }}
                                                label={t("Environment.CreateAppMaster.version")}
                                                data-test="version-field"
                                            >
                                                {(!props.chartVersions ||
                                                    !props.chartVersions.chartVersions ||
                                                    props.chartVersions.chartVersions.length === 0) && (
                                                        <MenuItem value={"Version"}>
                                                            {t("Environment.CreateAppMaster.version")}
                                                        </MenuItem>
                                                    )}
                                                {props.chartVersions &&
                                                    props.chartVersions.chartVersions?.map(
                                                        (versionDetails, ind) => (
                                                            <MenuItem value={versionDetails.id} key={ind}>
                                                                {versionDetails.version}
                                                            </MenuItem>
                                                        )
                                                    )}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    {
                                        selected && selected.values &&
                                        <>
                                            <Divider />
                                            <DeploymentFormBody
                                                deploymentEvent="upgrade"
                                                //chartID={chartID}
                                                //chartVersion={chartVersion}
                                                //chartsIsFetching={chartsIsFetching}
                                                deployedValues={deployedValues}
                                                selected={selected}
                                                setValues={handleValuesChange}
                                                appValues={appValues}
                                                setValuesModified={setValuesModifiedTrue}
                                            />
                                        </>
                                    }
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid> */}
                <Grid item xs={12} className="alignCenter">
                    <Button
                        data-test="upgrade-env-button"
                        variant="contained"
                        color="primary"
                        onClick={() => redeployEnvironment()}
                        disabled={isErrors()}
                    >
                          {t("HelmEnvironment.redeployEnv")}
                    </Button>
                </Grid>
            </Grid>

            {/* </form> */}
            {/* </Column>
                </Row> */}
            {/* </LoadingWrapper> */}
            {props.fetchingChartVersions && (
                <BackdropLoader message="Fetching chart Versions" data-test="chart-Version"/>
            )}
            {props.fetchingChartConfig && (
                <BackdropLoader message="Fetching chart Version Config" data-test="chart-config" />
            )}
            {props.redeployingHelmEnv && (
                <BackdropLoader message="Redeploying environment." data-test="helmEnv" />
            )}
        </section>
    );
}

/* istanbul ignore next */
const mapStateToProps = (state) => ({
    chartVersions: state.HelmEnvironmentReducer.chartVersions,
    chartVersionValues: state.HelmEnvironmentReducer.chartVersionValues,
    chartVersionSchema: state.HelmEnvironmentReducer.chartVersionSchema,
    chartConfig: state.HelmEnvironmentReducer.chartConfig,
    fetchingChartVersions: state.HelmEnvironmentReducer.fetchingChartVersions,
    fetchingChartConfig: state.HelmEnvironmentReducer.fetchingChartConfig,
    redeployingHelmEnv: state.HelmEnvironmentReducer.redeployingHelmEnv,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => ({
    getChartVersions: (id) => dispatch(getChartVersions(id)),
    getChartVersionConfig: (version) => dispatch(getChartVersionConfig(version)),
    clearChartVersionConfig: (version) =>
        dispatch(clearChartVersionConfig(version)),
    redeployHelmEnvironment: (id, payload, history) =>
        dispatch(redeployHelmEnvironment(id, payload, history)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeForm);
