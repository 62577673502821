import {
  Card,
  Grid,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Operator } from "../../models/Operator.model";
const useStyles = makeStyles((theme) => ({
  root: {},
  imageContainer: {
    margin: "0 auto",
    border: `1px solid ${theme?.palette?.divider}`,
    borderRadius: "5px",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "80px",
    width: "80px",
  },
  catalogImage: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  cardActions: {
    margin: 5,
  },
  cardContent: {
    minHeight: "87px",
  },
  linkPointer: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.09)",
    },
  },
}));

interface Props {
  catalog: Operator;
  onClick?: (catalog: Operator) => void;
}
export default function OperatorCatalogCard(props: Props) {
  const classes = useStyles();
  const [t] = useTranslation();
  const { catalog } = props;
  console.log(props, "operator catalog card");

  const handleClick = (_catalog: Operator) => {
    if (props.onClick) {
      props.onClick(_catalog);
    }
  };
  return (
    <Card
      onClick={() => {
        handleClick(catalog);
      }}
      className={props.onClick ? classes.linkPointer : ""}
      data-test="main-container"
    >
      <CardHeader
        title={catalog?.operator_details.operator.packageName}
        data-test="card-header"
      />
      <Divider />

      <CardContent className={classes.cardContent}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <div className={classes.imageContainer}>
              <img
                src={
                  catalog?.operator_details.operator.thumbUrl
                    ? catalog?.operator_details.operator.thumbUrl
                    : "/images/icons/defaultcatalog.png"
                }
                alt={
                  catalog?.operator_details.operator.packageName ??
                  t("Operators.OperatorCatalog.catalog")
                }
                className={classes.catalogImage}
                data-test="catalogImage"
              />
            </div>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography
              gutterBottom
              variant="body1"
              className={"threeLine"}
              data-test="catalog-description"
            >
              {catalog?.operator_details.operator.description}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions className={classes.cardActions}>
        <Grid container justify="flex-end" spacing={2}>
          <Grid item>
            {catalog?.operator_details.operator.categories && (
              <Grid container spacing={1} justify="flex-end">
                <Tooltip title={catalog?.operator_details.operator.categories}>
                  <Chip
                    style={{
                      maxWidth: "100px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    color={"primary"}
                    variant="outlined"
                    label={catalog?.operator_details.operator.categories}
                    size="small"
                    data-test="category-chip"
                  />
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}
