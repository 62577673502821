//import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { connect,ConnectedProps } from "react-redux";
import ConfirmDeletePopup from '../../components/confirmdeletepopup/ConfirmDeletePopup';
import BackdropLoader from '../../components/loader/BackdropLoader';
import { getCronImages } from "../cronjob/redux/actions";
import InitContainerCreate from "./InitContainerCreate";
import InitContainerList from "./InitContainerList";
import {
  clearInitContainerList, createInitContainer,
  deleteInitContainer,
  fetchInitContainerList, updateInitContainer
} from "./redux/actions";
import {useTranslation} from 'react-i18next';
import { Dispatch } from "redux";
import { InitContainerType } from '../../models/Environment.model';
// const useStyles = makeStyles((theme) => ({
//   expand: {
//     transform: "rotate(0deg)",
//     marginLeft: "auto",
//     transition: theme.transitions.create("transform", {
//       duration: theme.transitions.duration.shortest,
//     }),
//   },
//   expandOpen: {
//     transform: "rotate(180deg)",
//   },
//   svgicon: {
//     fontSize: 14,
//     color: "#357dfd",
//     marginRight: 5,
//   },
// }));

export type UnsavedInitContainer=Pick<InitContainerType,"name"|"image"|"command">
interface Props extends PropsFromRedux{
source:number;
handleChange?:(initContainers:Partial<InitContainerType>[])=>void;

initContainersPrev?:InitContainerType[];

}

export const InitContainer = (props:Props) => {
  const [create, setCreate] = useState(props.source === 1 ? true : false);
  const [initContainers, setInitContainers] = useState<(InitContainerType)[]>([]);
  const [currentInitContainer, setCurrentInitContainer] = useState<(InitContainerType)|null>(null);
  const [currId, setCurrId] = useState(1);
  const [confirmDeletePopup, setConfirmDeletePopup] = useState(false);
  const [ t ] = useTranslation()

  useEffect(() => {
    props.getCronImages();
    // return () => {
      // props.clearInitContainers();
    // };

  }, []);

  const onInitSave = (initContainer: InitContainerType | UnsavedInitContainer) => {
    if ("id" in initContainer && initContainer.id) {
      if (props.source === 1) {
        const newInitContainers: InitContainerType[] = initContainers.map((_ic: InitContainerType) => {
          let ic = _ic
          if (ic.id === initContainer.id) {
            ic = {
              ...initContainer,
            };
          }
          return ic;
        });
        setInitContainers(newInitContainers);
       props.handleChange &&  props.handleChange(newInitContainers)
      }
      else if (props.source === 2) {
        let _id = initContainer.id;
        const _editContainer: UnsavedInitContainer = {
          name: initContainer.name,
          image: initContainer.image,
          command: initContainer.command
        }
        props.updateInitContainer(parseInt(props.environmentDetails?.id), _id, _editContainer);
      }
    } else {
      if (props.source === 1) {
        let _newInit: InitContainerType = {
          ...initContainer,
          id: currId,
          active: true
        }
        setInitContainers([...initContainers, _newInit]);
        setCurrId(currId + 1);
        props.handleChange && props.handleChange([...initContainers, initContainer])
      } else if (props.source === 2) {
        const _initContainer = {
          name: initContainer.name,
          image: initContainer.image,
          command: initContainer.command,
        };
        props.createInitContainer(parseInt(props.environmentDetails?.id), _initContainer);
      }
    }
    setCurrentInitContainer(null)
    setCreate(false)
  };

  const addInitContainer = () => {
    setCreate(true);
  };

  const deleteAction = (id:number) => {
    let _initContainer = initContainers.find((i) => {
      const _id = i.id
      return _id === id;
    });
    if (_initContainer) {
      setCurrentInitContainer(_initContainer);
      setConfirmDeletePopup(true);
      // setCurrId(id);
    }
  };

  const deleteAgreeHandler = () => {

    if (props.environmentDetails?.id && currentInitContainer && currentInitContainer.id) {
      props.deleteInitContainer(props.environmentDetails?.id, currentInitContainer.id);
      setConfirmDeletePopup(false)
      setCurrentInitContainer(null)
    }
  };

  const deleteDisagreeHandler = () => {
    setConfirmDeletePopup(false);
    // setCurrId(null);
    setCurrentInitContainer(null)
    setCreate(false)
  };

  const editAction = (id:number) => {
    let _initContainer = initContainers.find((i) => {
      const _id = i.id
      return _id === id;
    });
    if (_initContainer) {
      setCurrentInitContainer(_initContainer);
      setCreate(true);
    }
  };

  const deleteActionOnCreate = (id:number) => {
    // const _initContainer = this.state.initContainers.find(i => {
    //   return i.id === id
    // })
    // if(_initContainer){
    //   _initContainer.active = false
    // }

    const newInitContainers = initContainers.map((ic) => {
      if (ic.id === id) {
        ic = {
          ...ic,
          active: false,
        };
      }
      return ic;
    });

    setInitContainers(newInitContainers)
    setCreate(false)
   props.handleChange &&  props.handleChange(newInitContainers)
    // this.setState({
    //   initContainers: newInitContainers,
    //   create: false
    // });
  };
  
  const restoreAction = (id:number) => {
    if(props.source === 1) {
      const newInitContainers = initContainers.map((_ic) => {
        let ic = _ic
        if (ic.id === id) {
          ic = {
            ...ic,
            active: true,
          };
        }
        return ic;
      });

      setInitContainers(newInitContainers)
      setCreate(false)
       props.handleChange &&  props.handleChange(newInitContainers)
      }
  };

  const onCancel = () => {
    setCreate(false)
    setCurrentInitContainer(null)
  }

  useEffect(() => {
    if(props.source === 2) {
      props.fetchInitContainers(props.environmentDetails.id);
    }
    // props.getCronImages();
    // return () => {
    //   setInitContainers([]);
    // };
  }, [props.environmentDetails?.id]);

  useEffect(() => {
    if (props.initContainers && props.source === 2 ) {
      setInitContainers(props.initContainers);
    }
  }, [props.initContainers, props.source]);

  useEffect(() => {
    if (props.initContainersPrev && props.source === 1) {
      setInitContainers(props.initContainersPrev);
      // if(props.initContainersPrev?.length > 0 ){
      //   setCreate(false)
      // }
    }
  }, [props.initContainersPrev, props.source]);
  return (
      <div data-test="main-container">
          { (!create || (props.initContainersPrev && props.initContainersPrev?.length > 0 && props.source === 1))  &&
          <InitContainerList
            initContainers={initContainers}
            source={props.source}
            deleteAction={props.source === 1 ? deleteActionOnCreate : deleteAction}
            editAction={editAction}
            restoreAction={restoreAction}
            addInitContainer={addInitContainer}
            create={create}
            currentInitContainer={currentInitContainer}
            envRole={props.envRole}
            data-test="init-cont-list"
          />
      }
      {create  &&  (
          <InitContainerCreate
            create={create}
            onSave={onInitSave}
            initContainer={currentInitContainer}
            initImages={props.initImages}
            environmentDetails={props.environmentDetails}
            onCancel={onCancel}
            source={props.source}
            data-test="init-cont-create"
          />
      )}

{currentInitContainer && currentInitContainer.name && <ConfirmDeletePopup open={confirmDeletePopup} handleAgree={deleteAgreeHandler} handleDisAgree={deleteDisagreeHandler} message={`Are you sure you want to delete the initContainer`}   toMatchName={currentInitContainer && currentInitContainer.name} toDeleteModule="init container" loading={props.deletingInitContainer} action={"Delete"} data-test="delete-popup" /> 
}          {props.deletingInitContainer && <BackdropLoader message={t('InitContainer.InitContainerPage.deleting')} data-test="deleting-loader" />}
          {props.updatingInitContainer && <BackdropLoader message={t('InitContainer.InitContainerPage.updating')} data-test="updating-loader" />}
          {props.creatingInitContainer && <BackdropLoader message={t('InitContainer.InitContainerPage.initializing')} data-test="initializing-loader" />}
          {props.fetchingInitContainerList && <BackdropLoader message={t('InitContainer.InitContainerPage.fetching')} data-test="fetching-loader" />}
      </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state:any) => {
  return {
    initContainers: state.InitContainerReducer.initContainerList,
    deletingInitContainer: state.InitContainerReducer.deletingInitContainer,
    updatingInitContainer: state.InitContainerReducer.updatingInitContainer,
    creatingInitContainer: state.InitContainerReducer.creatingInitContainer,
    fetchingInitContainerList: state.InitContainerReducer.fetchingInitContainerList,
    environmentDetails: state.EnvironmentReducer.environmentDetails,
    initImages: state.CronJobReducer.cronImages,
    envRole: state.EnvironmentReducer.envRole,

  };
};

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch:Dispatch) => {
  return {
    fetchInitContainers: (id:number) => dispatch(fetchInitContainerList(id)),
    clearInitContainers: () => dispatch(clearInitContainerList()),
    deleteInitContainer: (eId:number, cId:number) => dispatch(deleteInitContainer(eId, cId)),
    updateInitContainer: (eId:number, cId:number, data:UnsavedInitContainer) => dispatch(updateInitContainer(eId, cId, data)),
    createInitContainer: (eId:number, data:UnsavedInitContainer) => dispatch(createInitContainer(eId, data)),
    getCronImages: () => dispatch(getCronImages()),
  };
};
const connector=connect(mapStateToProps, mapDispatchtoProps)
export default connector(InitContainer); 
type PropsFromRedux = ConnectedProps<typeof connector>;