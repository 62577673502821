import React, { useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Chip,
  Tooltip,
  Button,
  IconButton,
  CardHeader,
  Divider,
} from "@material-ui/core";
import MultiValueChipInput from "../emailinput/MultiValueChipInput";
import AdjustIcon from "@material-ui/icons/Adjust";
import KeyValueRow from "../keyvaluerow/KeyValueRow";
import Skeleton from "react-loading-skeleton";
import { getDateInStandardFormat, getStatusColor } from "../../helpers/utils";
import { useTranslation } from "react-i18next";
import {
  Container,
  Pod,
  Labels,
  Annotations,
  Port,
} from "./../../models/Environment.model";
import EditIcon from "@material-ui/icons/Edit";
import ConfirmActionPopup from "../confirmactionpopup/ConfirmActionPopup";
import {
  updatePodLabels,
  clearUpdatePodLabels,
} from "../../pages/pods/redux/actions";
import { ConnectedProps, connect } from "react-redux";
import { Dispatch } from "redux";
import _ from 'lodash';
interface IlabelState extends Pick<Pod, "labels" | "annotations"> {
  match_labels: Pod["labels"];
}

const renderContainerLabels = (
  labels: Labels | Annotations | Port[],
  editModeEnabled?: boolean,
  labelType?: "labels" | "annotations" | "match_labels",
  updateLabelState?: (labelStateData: IlabelState) => void,
  labelState?: IlabelState
) => {
  let labelsStr;
  let labelsArr = [];

  const isTagDisabled = (tag: string): boolean => {
    if (!labelState) return false;

    const [tagKey] = tag.split(":");
    const match_lablelKeys = labelState.match_labels;
    if (!match_lablelKeys) return false;

    return !!match_lablelKeys[tagKey.trim() as keyof typeof labelType];
  };
  // handleValues

  const handleValues = (values: string[]) => {
    if (
      !labelType ||
      !editModeEnabled ||
      !updateLabelState ||
      !labelType ||
      !labelState
    )
      return;

    let payloadObj: Pod["labels"] | Pod["annotations"] = {};
    // prepare the data for call back fxn
    values.forEach((label: string) => {
      let [tagKey, tagValue] = label.split(":");
      if (!tagKey || !tagValue) return;
      tagKey = tagKey.trim();
      tagValue = tagValue.trim();
      // keep below condition check until validation is written

      payloadObj[tagKey] = tagValue;
    });
    let labelStatekey = labelState[labelType as keyof typeof labelState];
    if (!labelStatekey) return;

    labelState[labelType as keyof typeof labelState] = payloadObj;

    updateLabelState(labelState);
  };

  if (Array.isArray(labels)) {
    labels.map((labelObj) => {
      labelsArr.push(`${labelObj.name}: ${labelObj.containerPort}`);
    });
  } else {
    for (const key in labels) {
      const value: string = labels[key as keyof typeof labels];
      labelsArr.push(`${key}: ${value}`);
    }
  }

  labelsStr = labelsArr.join(",");

  let tagsComponent;

  if (labelsStr?.length > 0 && !editModeEnabled) {
    tagsComponent = (
      <Grid container spacing={1}>
        {labelsStr?.split(",").map((_tag, ind) => (
          <Grid
            item
            key={ind}
            style={{ maxWidth: "100%" }}
            data-test="tags-item"
          >
            <Tooltip title={_tag}>
              <Chip
                color={"primary"}
                variant="outlined"
                label={_tag}
                size="small"
                style={{ maxWidth: "100%" }}
              />
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    );
  } else if (labelsStr.length && editModeEnabled) {
    tagsComponent = (
      <MultiValueChipInput
        data-test="tags-input"
        values={labelsStr.split(",")}
        handleValues={handleValues}
        disableAction={isTagDisabled}
        disabled={labelType === "match_labels"}
      />
    );
  } else {
    tagsComponent = (
      <Typography
        color="textSecondary"
        component="em"
        data-test="no-cluster-labels"
      >
        N/A
      </Typography>
    );
  }
  return tagsComponent;
};

const getContainerStatus = (status: string) => {
  const statusColor = getStatusColor(status);
  return (
    <Typography variant="h4">
      <span>
        <Tooltip title="Contianer Status">
          <>
            {status ? (
              <AdjustIcon className="topIcon" style={{ color: statusColor }} />
            ) : (
              <Skeleton circle={true} height={10} width={10} />
            )}
          </>
        </Tooltip>
        <span style={{ color: statusColor }} className="statusTxt">
          {" "}
          {status ? status : <Skeleton width={80} />}
        </span>
      </span>
    </Typography>
  );
};

interface Props extends PropsFromRedux {
  pod: Pod | null;
  container: Container | null;
  type: string;
  restartCount: number;
  containerCreatedAt: Date;
  envId?: number;
  handleBack:(updateAction?:boolean)=>void
}

export const ContainerOverViewCard = ({
  pod,
  container,
  type,
  restartCount,
  containerCreatedAt,
  envId,
  updatePodLabels,
  ...props
}: Props) => {
  const [t] = useTranslation();

  const [labelState, setLabelState] = React.useState<null | IlabelState>(null);
  const [editModeEnabled, setEditModeEnabled] = React.useState(false);
  const [isConfirmationVisible, setIsConfirmationVisible] =
    React.useState(false);
  const handleCancel = () => {
    setEditModeEnabled(false);
    setLabelState(null);
  };

  console.log(" rrrr=> ", labelState)

  const handleEditClick = () => {
    if (!pod || !pod.match_labels) return;
    setEditModeEnabled(true);
    setLabelState({
      labels: pod.labels,
      annotations: pod.annotations,
      match_labels: pod.match_labels,
    });
  };

  const updateLabelState = (labelStateData: IlabelState) => {
    setLabelState(() => {
      return { ...labelStateData };
    });
  };

  const handleSave = () => {
    //todo open confirmation modal
    setIsConfirmationVisible(true);
  };
  const SaveEditedLabels = () => {
    // preparing the data for put api
    if (!envId || !pod || !pod.name) return;
    if (!labelState || !labelState.labels || !labelState.annotations) return;
    const apiPayload: Pick<Pod, "annotations" | "labels"> = {
      labels: labelState.labels,
      annotations: labelState.annotations,
    };
    // push the data to api
    updatePodLabels(envId, apiPayload, pod.name);
    setIsConfirmationVisible(false);
  };

  const handleCancelSave = () => {
    // todo close the confirmation modal
    setIsConfirmationVisible(false);
  };

  const isDataUpdated=()=>{
    if(!labelState) return;
    return _.isEqual({labels:pod?.labels,annotations:pod?.annotations}, {
      labels: labelState.labels,
      annotations: labelState.annotations,
    })
  }
  React.useEffect(() => {
    if (props.updatePodLabelsSuccess) {
      setEditModeEnabled(false);
      setLabelState(null);
      props.handleBack(true);
      props.clearUpdatePodLabels();
    }
  }, [props.updatePodLabelsSuccess]);

  return (
    <Card data-test="container-overview-card">
      {isConfirmationVisible ? (
        <ConfirmActionPopup
          yesText={t("Projects.VariablesTab.yesText")}
          message="Are you sure you want to update labels and annotations."
          open={isConfirmationVisible}
          handleAgree={SaveEditedLabels}
          handleDisAgree={handleCancelSave}
        />
      ) : null}
      <CardHeader
        title={
          <div>
            <Typography variant="h5" display="inline">
              <strong>Details</strong>
            </Typography>
          </div>
        }
        action={
          <>
            {type === "pod" && pod && (
              <div>
                {editModeEnabled ? (
                  <Grid container spacing={1}>
                    <Grid item>
                      <Button
                        variant="contained"
                        onClick={handleCancel}
                        color="secondary"
                        disableElevation={true}
                      >
                        cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        onClick={handleSave}
                        color="primary"
                        disabled={isDataUpdated()}
                        disableElevation={true}
                      >
                        save
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <IconButton onClick={handleEditClick} aria-label="settings">
                    <EditIcon
                      fontSize="small"
                      className=""
                      data-test="edit-icon"
                    />
                  </IconButton>
                )}
              </div>
            )}
          </>
        }
      />
      <Divider />
      <CardContent>
        {type === "pod" && pod && (
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <KeyValueRow
                rowKey={t("Organization.ChartRepoTab.namespace")}
                rowValue={pod.namespace}
                keyMd={2}
                data-test="pod-namespace"
              />
            </Grid>
            <Grid item>
              <KeyValueRow
                rowKey={t("Environment.EnvironmentInfo.createdAt")}
                rowValue={getDateInStandardFormat(pod.created_at)}
                keyMd={2}
                data-test="pod-createdAt"
              />
            </Grid>
            <Grid item>
              <KeyValueRow
                rowKey={t("Pods.restarts")}
                rowValue={restartCount.toString()}
                keyMd={2}
                data-test="pod-restarts"
              />
            </Grid>
            {pod.match_labels ? (
              <Grid item>
                <KeyValueRow
                  rowKey={t("Pods.match_labels")}
                  rowValue={renderContainerLabels(pod.match_labels)}
                  keyMd={2}
                  data-test="pod-annotations"
                />
              </Grid>
            ) : null}

            {editModeEnabled && labelState ? (
              <Grid item>
                <Grid style={{ marginBottom: 16 }} container>
                  <Grid item md={2} xs={12}>
                    <Tooltip
                      title={t("Cluster.ClusterAdditionalSettings.labels")}
                      arrow
                    >
                      <Typography
                        color="primary"
                        variant={"h6"}
                        className="oneLine"
                        data-test="rowKey"
                      >
                        {t("Cluster.ClusterAdditionalSettings.labels")}
                      </Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item md={10} xs={12}>
                    {renderContainerLabels(
                      labelState.labels,
                      editModeEnabled,
                      "labels",
                      updateLabelState,
                      labelState
                    )}
                  </Grid>
                </Grid>

                <Grid style={{ marginBottom: 16 }} container>
                  <Grid item md={2} xs={12}>
                    <Tooltip title={t("Pods.annotations")} arrow>
                      <Typography
                        color="primary"
                        variant={"h6"}
                        className="oneLine"
                        data-test="rowKey"
                      >
                        {t("Pods.annotations")}
                      </Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item md={10} xs={12}>
                    {renderContainerLabels(
                      labelState.annotations,
                      editModeEnabled,
                      "annotations",
                      updateLabelState,
                      labelState
                    )}
                  </Grid>
                </Grid>

                {/* <Grid style={{marginBottom:16}} item>
                  <Grid container>
                    <Grid item md={2} xs={12}>
                    <Tooltip
                        title={t("Pods.match_lables")}
                        arrow
                      >
                        <Typography
                          color="primary"
                          variant={"h6"}
                          className="oneLine"
                          data-test="rowKey"
                        >
                        {t("Pods.match_labels")}
                        </Typography>
                      </Tooltip>
                    </Grid>
                    <Grid item md={10} xs={12}>
                      {renderContainerLabels(
                        labelState.match_labels,
                        editModeEnabled,
                        "match_labels",
                        updateLabelState,
                        labelState
                      )}
                    </Grid>
                  </Grid>
                </Grid> */}
              </Grid>
            ) : (
              <>
                <Grid item>
                  <KeyValueRow
                    rowKey={t("Cluster.ClusterAdditionalSettings.labels")}
                    rowValue={renderContainerLabels(
                      pod.labels,
                      editModeEnabled
                    )}
                    keyMd={2}
                    data-test="pod-labels"
                  />
                </Grid>
                <Grid item>
                  <KeyValueRow
                    rowKey={t("Pods.annotations")}
                    rowValue={renderContainerLabels(
                      pod.annotations,
                      editModeEnabled
                    )}
                    keyMd={2}
                    data-test="pod-annotations"
                  />
                </Grid>
              </>
            )}
          </Grid>
        )}
        {type === "container" && container && (
          <>
            {container.status && (
              <KeyValueRow
                rowKey="Status"
                rowValue={getContainerStatus(container.status)}
                keyMd={2}
                data-test="container-status"
              />
            )}
            <KeyValueRow
              rowKey={t("CronJob.CronJobCreate.image")}
              rowValue={container.image}
              keyMd={2}
              data-test="container-image"
            />
            <KeyValueRow
              rowKey={t("Pods.port")}
              rowValue={renderContainerLabels(container.ports)}
              keyMd={2}
              data-test="container-ports"
            />
            {containerCreatedAt && (
              <KeyValueRow
                rowKey={t("Pods.startedAt")}
                rowValue={getDateInStandardFormat(containerCreatedAt)}
                keyMd={2}
                data-test="container-startedAt"
              />
            )}
            <KeyValueRow
              rowKey={t("Pods.restarts")}
              rowValue={restartCount.toString() ?? "0"}
              keyMd={2}
              data-test="container-restarts"
            />
            {container.resources?.requests?.cpu && (
              <KeyValueRow
                rowKey={t("Pods.cpuRequest")}
                rowValue={container.resources.requests.cpu}
                keyMd={2}
                data-test="container-cpu-request"
              />
            )}
            {container.resources?.limits?.cpu && (
              <KeyValueRow
                rowKey={t("Pods.cpuLimit")}
                rowValue={container.resources.limits.cpu}
                keyMd={2}
                data-test="container-cpu-limit"
              />
            )}
            {container.resources?.requests?.memory && (
              <KeyValueRow
                rowKey={t("Pods.memoryRequest")}
                rowValue={container.resources.requests.memory}
                keyMd={2}
                data-test="container-memory-request"
              />
            )}
            {container.resources?.limits?.memory && (
              <KeyValueRow
                rowKey={t("Pods.memoryLimit")}
                rowValue={container.resources.limits.memory}
                keyMd={2}
                data-test="container-memory-limit"
              />
            )}
            {/* {containerInsights && containerInsights[containerIndex].cpu && (
              <KeyValueRow
                rowKey={t("Pods.currentCPUUsage")}
                rowValue={containerInsights[containerIndex].cpu}
                keyMd={2}
                data-test="container-currentCPUUsage"
              />
            )}
            {containerInsights && containerInsights[containerIndex].memory && (
              <KeyValueRow
                rowKey={t("Pods.currentMemoryUsage")}
                rowValue={containerInsights[containerIndex].memory}
                keyMd={2}
                data-test="container-currentMemoryUsage"
              />
            )} */}
          </>
        )}
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state: any) => {
  return {
    updatingPodLabels: state.PodsReducer.updatingPodLabels,
    updatePodLabelsSuccess: state.PodsReducer.updatePodLabelsSuccess,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    clearUpdatePodLabels: () => dispatch(clearUpdatePodLabels()),
    updatePodLabels: (
      envId: number,
      labelData: Pick<Pod, "annotations" | "labels">,
      podName: string
    ) => dispatch(updatePodLabels(envId, labelData, podName)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(ContainerOverViewCard);
