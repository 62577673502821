import React, { useEffect, useState } from 'react';
import CardNode from '@carbon/charts-react/diagrams/CardNode';
import { Grid } from '@material-ui/core';
import { getDiffBetweenDates } from '../../helpers/utils';
import { connect } from "react-redux";
import { StepStatusIndicator } from "../statusindicator/statusindicator";
import CiLogsPopup from '../cicdlogspopup/CiLogsPopup';
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import { fetchEnvCILogs } from "../../pages/environment/cicdredux/actions";

function Node(props) {
  const [task, setTask] = useState(props.node);
  const [isLogOpen, setIsLogOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState("");

  useEffect(() => {
    if (props.envWorkflows && props.envWorkflows.length > 0) {
      const workflow = props.envWorkflows.find(x => x.pipeline.runner === props.node.workflowName);
      if (workflow && workflow.pipeline) {
        const tasks = workflow.pipeline.tasks;
        if (tasks && tasks.length > 0) {
          let _task = tasks.find(x => x.name === task.title)
          if (_task) {
            setTask({
              ...task,
              status: _task.status,
              creation_time: _task.creation_time,
              completion_time: _task.completion_time,
              steps: _task.steps,
            })
          }
        }
      }
    }
  }, [props.envWorkflows])

  const handleShowLog = (stepData) => {
    setCurrentStep(stepData.name);
    setIsLogOpen(true);
    const { envCILogs } = props;
    if (envCILogs && envCILogs.length > 0) {
      const index = envCILogs.findIndex(x => x.name === task.workflowName && x.task === task.title && x.step === stepData.name);
      if (index > -1) {
        return;
      }
    }
    props.fetchEnvCILogs(props.environmentDetails.id, task.workflowName, task.title, stepData.name);
  }
  
  const handleCloseLog = () => {
    setIsLogOpen(false);
    setCurrentStep("");
  }
  
  return (
    <>
      <foreignObject
        transform={`translate(${task.x},${task.y})`}
        height={task.height} 
        width={task.width}
        style={{ overflow: 'visible' }}
      >
        <div style={{ height: task.height, width: task.width }}>
        
          <CardNode
            className={`card-status-${task.status}`}
            _href="#"
          >
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <StepStatusIndicator status={task.status} />
              </Grid>
              <Grid item xs={10}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <span className='bx--cc--card-node__title nowrap' title={task.title}>{task.title}</span>
                  </Grid>
                  {
                    task.steps && task.steps.map((step, index) => {
                      return (
                        <Grid item key={index}>
                          <Grid container spacing={1}>
                            <Grid item xs={2}>
                              <StepStatusIndicator status={step.status} />
                            </Grid>
                            <Grid item xs={5} className='nowrap'>
                              <span title={step.name}>{step.name}</span>
                            </Grid>
                            <Grid item xs={3} className='textend'>
                              {
                                step.creation_time ? getDiffBetweenDates(step.creation_time, step.completion_time ? step.completion_time : new Date().toUTCString()) : ""
                              }
                            </Grid>
                          <Grid item xs={2}>
                              <DescriptionOutlinedIcon
                                fontSize="small"
                                className="logsIcon"
                                onClick={() => handleShowLog(step)}
                              //data-test="showLog-button"
                              />
                          </Grid>
                          </Grid>
                        </Grid>
                              )
                    })
                  }
                </Grid>
              </Grid>
            </Grid>
            {/* <CardNodeColumn>
              <StepStatusIndicator status={task.status} />
            </CardNodeColumn>
            <CardNodeColumn >
              <CardNodeTitle>
                <span title={task.title}>{task.title}</span>
              </CardNodeTitle>
              <Grid container style={{ marginTop: '10px' }} spacing={3}>
                {
                  task.steps && task.steps.map((step, index) => {
                    return (
                      <Grid item xs={12} key={index}>
                        <Grid container spacing={2}>
                          <Grid item>
                            <StepStatusIndicator status={step.status} />
                          </Grid>
                          <Grid item>
                            {step.name}
                          </Grid>
                          <Grid item>
                            {
                              step.creation_time ? getDiffBetweenDates(step.creation_time, step.completion_time ? step.completion_time : new Date().toUTCString()) : ""
                            }
                          </Grid>
                          <Grid item>
                            <DescriptionOutlinedIcon
                              fontSize="small"
                              className="logsIcon"
                              onClick={() => handleShowLog(step)}
                              //data-test="showLog-button"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                  })
                }
              </Grid>
            </CardNodeColumn> */}
          </CardNode>
        </div>
      </foreignObject>
      {
        isLogOpen && currentStep &&
        <CiLogsPopup
          isOpen={isLogOpen}
          handleClose={handleCloseLog}
          task={task.title}
          step={task.steps?.find(x => x.name === currentStep)}
          logData={props.envCILogs && props.envCILogs.find((x) => x.name === task.workflowName && x.task === task.title && x.step === currentStep)}
        />
      }
    </>
  );
}

const mapStateToProps = state => ({
  envWorkflows: state.CICDReducer.envWorkflows,
  envCILogs: state.CICDReducer.envCILogs,
  environmentDetails: state.EnvironmentReducer.environmentDetails,
})

const mapDispatchtoProps = dispatch => {
  return {
    fetchEnvCILogs: (id, workflowName, task, step) => dispatch(fetchEnvCILogs(id, workflowName, task, step))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(Node)
