import React, { useEffect, useState } from "react";
import { connect,ConnectedProps } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import { getInvoiceById, resetPreviousInvoice, downloadInvoiceById, getInvoice, getLatestPaymentHistory } from "./redux/actions";
// import { Link } from "react-router-dom";
import { paymentSummaryDateFormat, paymentSummaryDateFormat2 } from "../../../helpers/utils";
import { useTranslation } from 'react-i18next';
import PaymentPopup from "../../../components/billing/PaymentPopup";
import { Dispatch } from "redux";
import { UserInvoice } from "../../../models/Billing.model";

/* istanbul ignore next */
const useStyles = makeStyles(() => ({
  actionButton: {
    borderRadius: 2,
    minWidth: 125,
  },
  cardAction: {
    marginTop: 0,
    marginRight: 0,
  },
  cardContent: {
    padding: "5px 16px",
  },
  costText: {
    fontWeight: 400,
  },
  subHeader: {
    color: "#43425d94",
  },
  ml5: {
    marginLeft: 5,
  },
  loader: {
    animationDuration: '550ms',
  },
}));
interface Props extends PropsFromRedux{
  invoiceId?:number;
  fetchingPayuDetails?:boolean;

}
export const PaymentSummary = (props:Props) => {
  const [ t ] = useTranslation();
  const [invoice, setInvoice] = useState<UserInvoice>(props.userInvoice);
  const [openPaymentPopup, setOpenPaymentPopup] = useState(false);
  
  const classes = useStyles();

  useEffect(() => {
    if(props.invoiceId) props.getInvoiceById(props.invoiceId);
    return () => {
      props.resetPreviousInvoice();
    }
  }, [])

  useEffect(() => {
    let _invoice = props.invoiceId ? props.previousInvoice : props.userInvoice;
    setInvoice(_invoice)
  }, [props.userInvoice, props.previousInvoice]);

  useEffect(() => {
    if(!props.invoiceData) return;
    var a = document.createElement("a");
    a.href = props.invoiceData;
    a.setAttribute("download", "invoice.pdf");
    a.click();
  }, [props.invoiceData])

  const handleInvoiceDownload = () => {
    props.downloadInvoiceById(props.invoiceId ?? 0)
  }

  const handlePayEarlyClick = () => {
    setOpenPaymentPopup(true);
  }

  const handleClosePopup = () => {
    setOpenPaymentPopup(false);
  }

  const paymentSuccessCallback = () => {
    handleClosePopup();
    props.getInvoice();
    props.getLatestPaymentHistory();
  }

  return (
    <Grid item xs={12} data-test="main-container">
      <Card>
        <CardHeader
          classes={{ action: classes.cardAction }}
          data-test="card-header"
          title={
            <Typography variant="h4">
              {!props.invoiceId ? t("Billing.PaymentSummary.monthToDate") : t("Billing.PaymentSummary.monthly")} |{" "}
              <Typography
                variant="h5"
                display="inline"
                style={{ color: "lightslategray" }}
                data-test="summary-date"
              >
                {
                  invoice &&
                    (props.invoiceId
                      ? paymentSummaryDateFormat2(invoice?.start_date, invoice?.end_date)
                      : paymentSummaryDateFormat(invoice?.start_date, invoice?.end_date))
                }
              </Typography>
              { invoice?.total_cost !== 0 ?
                <Tooltip title={t('Billing.DownloadInvoice')} placement={"right"}>
                  <IconButton className={classes.ml5} onClick={handleInvoiceDownload} data-test="download-invoice">
                    <GetAppIcon />
                  </IconButton>
                </Tooltip>
                : ''
              }
            </Typography>
          }
          subheader={
            !props.invoiceId && 
              <Typography variant="caption" className={classes.subHeader}>
                {t("Billing.PaymentSummary.subText")}
              </Typography>
          }
          action={
            !props.invoiceId && (props.latestPaymentHistory?.data?.length === 0 || (props.latestPaymentHistory?.data?.length > 0 && props.latestPaymentHistory.data[0].balance === 0)) && invoice?.total_cost > 0 &&
              <Button
                className={classes.actionButton}
                variant="contained"
                color="primary"
                onClick={handlePayEarlyClick}
                disabled={props.fetchingPayuDetails}
                startIcon={props.fetchingPayuDetails && <CircularProgress size={20} thickness={4.5} className={classes.loader} />}
                data-test="payEarly-button"
              >
                {t('Account.Billing.payEarly')}
              </Button>
          }
        />
        <CardContent classes={{ root: classes.cardContent }}>
          <TableContainer component={Paper}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={7}>{t("Addons.details")}</TableCell>
                  <TableCell colSpan={1}>{t("Billing.PaymentSummary.days")}</TableCell>
                  <TableCell align="center">{t("Billing.PaymentSummary.cost")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoice?.invoice_items?.length > 0 &&
                  invoice.invoice_items.map((item, idx) => (
                    <TableRow key={idx} data-test="item-row">
                      <TableCell colSpan={7} data-test="item-name">
                        {/* <Link to="#" style={{ textDecoration: "none" }} data-test="item-name"> */}
                          {item.particular}
                        {/* </Link> */}
                      </TableCell>
                      <TableCell colSpan={1} data-test="item-days"> {item.days} </TableCell>
                      <TableCell align="center" data-test="item-total"> ${item.total.toFixed(2)} </TableCell>
                    </TableRow>
                  ))}
                <TableRow>
                  <TableCell rowSpan={4} colSpan={6} />
                  <TableCell colSpan={2} align="right">
                    {t("Billing.PaymentSummary.subTotal")}
                  </TableCell>
                  <TableCell align="center" data-test="subtotal">${invoice?.sub_total}</TableCell>
                </TableRow>
                {
                  invoice?.promo_amount > 0 &&
                    <TableRow>
                      <TableCell colSpan={2} align="right" data-test="promo-name">
                      {
                          invoice?.promocode?.Code ? 
                          <> Promocode(<i>{invoice.promocode?.Code}</i>) discount </>
                          : <>Discount Amount</>
                      }
                      </TableCell>
                      <TableCell align="center" data-test="promo-amount">
                        -$
                        {invoice?.promo_amount}
                      </TableCell>
                    </TableRow>
                }
                {
                  invoice?.deduct_amount > 0 && invoice?.deduction?.name && invoice?.deduction?.value &&
                  <TableRow>
                      <TableCell colSpan={2} align="right" data-test="deduction-name">
                        {`${invoice.deduction.name} (${invoice.deduction.value}%)`}
                      </TableCell>
                      <TableCell align="center" data-test="deduction-amount">
                        $
                        {invoice?.deduct_amount}
                      </TableCell>
                  </TableRow>
                }
                <TableRow>
                  <TableCell colSpan={2} align="right">
                  <b>{props.invoiceId ? t("Cluster.ProviderPermissions.total") : t("Billing.PaymentSummary.estimatedTotal")}</b>
                  </TableCell>
                  <TableCell align="center" data-test="total">
                    <b>${invoice?.total_cost.toFixed(2) ?? 0.0}</b>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      {
        openPaymentPopup && (
          <PaymentPopup 
            openPopup={openPaymentPopup}
            handleClosePopup={handleClosePopup}
            invoiceId={0}
            amount={invoice?.total_cost}
            callback={paymentSuccessCallback}
            data-test="paymentPopup"
          />
        )
      }
    </Grid>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state:any) => ({
  invoiceData: state.PaymentReducer.invoiceData,
  userInvoice: state.PaymentReducer.userInvoice,
  previousInvoice: state.PaymentReducer.previousInvoice,
  latestPaymentHistory: state.PaymentReducer.latestPaymentHistory,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch:Dispatch) => {
  return {
    downloadInvoiceById: (id:number) => dispatch(downloadInvoiceById(id)),
    getInvoiceById: (id:number) => dispatch(getInvoiceById(id)),
    resetPreviousInvoice: () => dispatch(resetPreviousInvoice()),
    getInvoice: () => dispatch(getInvoice()),
    getLatestPaymentHistory: () => dispatch(getLatestPaymentHistory()),
  };
};
const connector=connect(mapStateToProps, mapDispatchtoProps)
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PaymentSummary);
