import { Grid, Paper, Box, Button, GridSize } from '@material-ui/core'

import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import ResourceCatalogCard from './ResourceCatalogCard';
import { connect, ConnectedProps } from "react-redux"
//import { fetchResources } from "../redux/actions"
//import BackdropLoader from '../../../components/loader/BackdropLoader';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import NoContentImage from '../../../components/nocontentimagecontainer/NoContentImage';
import { OperatorClass, Owned } from '../../../models/Operator.model';

const useStyles = makeStyles({
    dropdown: {
        minWidth: 100,
        height: 55,
    },
    container: {
        marginTop: 20,
        minHeight: '20rem',
    },
    button: {
        marginTop: "0.8rem",
        marginBottom: "0.5rem",
    },
});

interface Props extends PropsFromRedux {
    operatorDetails:OperatorClass;
    selectedResource:Owned;
    activeStep:number;
    handleStepChange:(stepNumber:number, data:{selectedResource:Owned})=>void;
    cardMd: boolean|GridSize|undefined,
    xs?:boolean|GridSize|undefined;
    setSelectedResource: (resource:Owned) => void;
}

export function ResourceCatalogContainer(props: Props) {
    // const [selectedResource, setSelectedResource] = useState(null)
    const currentStep = 0
    const classes = useStyles()

    // useEffect(() => {
    //     props.fetchResources(props.id)
    // }, [])

    const handleContinue = () => {
        const _resource = props.operatorDetails && props.operatorDetails?.customResourceDefinitions.find(resourceDetails => resourceDetails === props.selectedResource)

        if (_resource) {
            const data = {
                selectedResource:props.selectedResource
            }
            props.handleStepChange(currentStep, data)

        }

    }
    const handleCatalogClick = (resource:Owned) => {
        props.setSelectedResource(resource)
        //props.setResourceName(resource.metadata.name)
    }

    return (

        <div data-test="main-container" style= {{display : props.activeStep !== currentStep ? "none": ""}}>

            {
                (props.fetchingOperatorDetails) &&
                <Grid container spacing={2} className={classes.container}>
                    {
                        [0, 1, 2, 3, 4, 5, 6, 7].map((idx) => {
                            return (
                                <Grid item xs={12} sm={6} md={3} xl={3} key={idx} data-test="loading-skeleton">
                                    <Paper 
                                    // className={classes.paperContainer} 
                                    >
                                        <SkeletonTheme height={250}>
                                            <Skeleton width={210} height={118} />
                                            <br />
                                            <br />

                                            <Skeleton height={10} style={{ marginBottom: 6 }} width="80%" />
                                            <Skeleton height={10} style={{ marginBottom: 6 }} width="80%" />
                                            <Skeleton height={10} width="60%" />
                                        </SkeletonTheme>
                                    </Paper>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            }
            {!props.fetchingOperatorDetails && props.operatorDetails && !props.operatorDetails?.customResourceDefinitions ? (<NoContentImage message="No Resources" alt="No Resources" data-test="no-resources" />) : (

                <Grid container spacing={3}>
                    {props.operatorDetails && props.operatorDetails.customResourceDefinitions.map((resource, ind) => (
                        <Grid item xs={props.xs ?? 12} md={props.cardMd ?? 4} key={ind}>
                            <ResourceCatalogCard resource={resource} imgUrl={props.operatorDetails.thumbUrl} categories={props.operatorDetails.categories} onClick={handleCatalogClick} data-test="resource-card" selectedResource={props.selectedResource} />
                        </Grid>
                    ))}
                </Grid>
            )

            }
            {!props.fetchingOperatorDetails &&  props.operatorDetails &&
                <Box textAlign='center'>
                    <Button className={classes.button} variant="contained" color="primary" disabled={props.selectedResource ? false : true}  onClick={handleContinue}>
                        Continue
                    </Button>
                </Box>
            }
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        // resources: state.OperatorReducer.resources,
        fetchingOperatorDetails: state.OperatorReducer.fetchingOperatorDetails,

    }
}

// const mapDispatchToProps = (dispatch) => ({
//     //fetchResources: (id) => dispatch(fetchResources(id)),

// });

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(ResourceCatalogContainer);