import {
  FETCH_RESOURCES,
  FETCH_RESOURCES_SUCCESS,
  FETCH_RESOURCES_FAILURE,
  FETCH_RESOURCE,
  FETCH_RESOURCE_FAILURE,
  FETCH_RESOURCE_SUCCESS,
  DELETE_RESOURCE,
  DELETE_RESOURCE_FAILURE,
  DELETE_RESOURCE_SUCCESS,
  CREATE_RESOURCE_CALL,
  CREATE_RESOURCE_CALL_SUCCESS,
  CREATE_RESOURCE_CALL_FAILURE,
  EDIT_RESOURCE_CALL,
  EDIT_RESOURCE_CALL_SUCCESS,
  EDIT_RESOURCE_CALL_FAILURE,
} from "./actions";
import initialState from "./initialState";

const reducer = (state = initialState, payload) => {
  switch (payload.type) {
    case FETCH_RESOURCES:
      return {
        ...state,
        fetchingResources: true
      };
    case FETCH_RESOURCES_SUCCESS:
      return {
        ...state,
        resourceList: payload.data,
        fetchingResources: false
      };
    case FETCH_RESOURCES_FAILURE:
      return {
        ...state,
        error: payload.error,
        fetchingResources: false
      };
    case FETCH_RESOURCE:
      return {
        ...state,
        fetchingResource: true
      };
    case FETCH_RESOURCE_SUCCESS:
      return {
        ...state,
        resource: payload.data,
        fetchingResource: false
      };
    case FETCH_RESOURCE_FAILURE:
      return {
        ...state,
        error: payload.error,
        fetchingResource: false
      };
    case DELETE_RESOURCE:
      return {
        ...state,
        executingDeleteResource: true,
        deleteResourceSuccess: false,
        deleteResourceFailure: false,
      };
    case DELETE_RESOURCE_SUCCESS:
      return {
        ...state,
        executingDeleteResource: false,
        deleteResourceSuccess: true,
        deleteResourceFailure: false,
      };
    case DELETE_RESOURCE_FAILURE:
      return {
        ...state,
        executingDeleteResource: false,
        deleteResourceSuccess: false,
        deleteResourceFailure: true,
        error: payload.error,
      };
    case CREATE_RESOURCE_CALL:
      return {
        ...state,
        executingCreateResource: true,
        createResourceCallSuccess: false,
        createResourceCallFailure: false,
      };
    case CREATE_RESOURCE_CALL_SUCCESS:
      return {
        ...state,
        executingCreateResource: false,
        createResourceCallSuccess: true,
        createResourceCallFailure: false,
      };
    case CREATE_RESOURCE_CALL_FAILURE:
      return {
        ...state,
        executingCreateResource: false,
        createResourceCallSuccess: false,
        createResourceCallFailure: true,
        error: payload.error,
      };
    case EDIT_RESOURCE_CALL:
      return {
        ...state,
        executingEditResource: true,
        editResourceCallSuccess: false,
        editResourceCallFailure: false,
      };
    case EDIT_RESOURCE_CALL_SUCCESS:
      return {
        ...state,
        executingEditResource: false,
        editResourceCallSuccess: true,
        editResourceCallFailure: false,
      };
    case EDIT_RESOURCE_CALL_FAILURE:
      return {
        ...state,
        executingEditResource: false,
        editResourceCallSuccess: false,
        editResourceCallFailure: true,
        error: payload.error,
      };
    default:
      return state;
  }
};

export default reducer;
