import axios from "axios";
import { put, takeLatest, call, select } from "redux-saga/effects";
import { sessionTokenSelector } from "../../login/redux/selectors";
import endpoints from "../../../constants/endpoints";
import {
  FETCH_PLUGINS_FAILURE,
  FETCH_PLUGINS,
  FETCH_PLUGINS_SUCCESS,
  DELETE_PLUGIN,
  DELETE_PLUGIN_FAILURE,
  DELETE_PLUGIN_SUCCESS,
  FETCH_PLUGIN,
  FETCH_PLUGIN_FAILURE,
  FETCH_PLUGIN_SUCCESS,
  // CREATE_PLUGIN_FAILURE,
  // CREATE_PLUGIN_SUCCESS,
  EDIT_PLUGIN,
  EDIT_PLUGIN_FAILURE,
  EDIT_PLUGIN_SUCCESS,
  ADD_ORG_PLUGIN,
  ADD_ORG_PLUGIN_FAILURE,
  ADD_ORG_PLUGIN_SUCCESS,
  ADD_ORG_PLUGINS,
  ADD_ORG_PLUGINS_FAILURE,
  ADD_ORG_PLUGINS_SUCCESS,
  REMOVE_ORG_PLUGIN,
  REMOVE_ORG_PLUGIN_FAILURE,
  REMOVE_ORG_PLUGIN_SUCCESS,
} from "./actions";
import toast from "../../../components/toast/Toast";
import { GET_ORG_INFO } from "../../organization/redux/actions";

async function fetchPluginsCall(sessionToken) {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  const response = await axios.get(endpoints.PLUGIN.FETCH_ALL_PLUGINS, config);
  return response;
}
async function deletePluginCall(sessionToken, id) {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  const response = await axios.delete(
    endpoints.PLUGIN.EDIT_PLUGIN.replace(":id", id),
    config
  );
  return response;
}

function* fetchPlugins() {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(fetchPluginsCall, sessionToken);
    const data = response.data;
    if (data) {
      yield put({ type: FETCH_PLUGINS_SUCCESS, data });
    } else {
      yield put({
        type: FETCH_PLUGINS_FAILURE,
        data: {
          error: "Problem fetching Plugin",
        },
      });
    }
  } catch (error) {
    yield put({
      type: FETCH_PLUGINS_FAILURE,
      data: {
        error: error.message,
      },
    });
  }
}

async function fetchPluginCall(sessionToken, data) {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  const response = await axios.get(
    endpoints.PLUGIN.GET_PLUGIN.replace(":id", data.id),
    config
  );
  return response;
}

function* fetchPlugin(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(fetchPluginCall, sessionToken, payload.data);
    const data = response.data;
    if (data) {
      yield put({ type: FETCH_PLUGIN_SUCCESS, data });
    } else {
      yield put({
        type: FETCH_PLUGIN_FAILURE,
        data: {
          error: "Problem fetching Plugin",
        },
      });
    }
  } catch (error) {
    yield put({
      type: FETCH_PLUGIN_FAILURE,
      data: {
        error: error.message,
      },
    });
  }
}

async function removePluginFromOrgCall(sessionToken, data) {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  const response = await axios.delete(
    endpoints.ORGANIZATION.REMOVE_PLUGIN.replace(":pId", data.id),
    config
  );
  return response;
}

function* removePluginFromOrg(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(
      removePluginFromOrgCall,
      sessionToken,
      payload.data
    );

    if (response.status === 204 || response.status === 200) {
      yield put({ type: GET_ORG_INFO });
      yield put({ type: REMOVE_ORG_PLUGIN_SUCCESS });
      toast.success(
        response.message ?? "Plugin removed from Organization Successfully"
      );
    } else {
      toast.error(
        response.message ?? "Failed to remove plugin from organization"
      );
      yield put({
        type: REMOVE_ORG_PLUGIN_FAILURE,
        data: {
          error: "Problem Removing Plugin",
        },
      });
    }
  } catch (error) {
    toast.error(
      error.message ??
        error.response?.message ??
        "Failed adding Plugin to Organization"
    );
    yield put({
      type: REMOVE_ORG_PLUGIN_FAILURE,
      data: {
        error: error.message,
      },
    });
  }
}

async function addPluginToOrgCall(sessionToken, data) {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  const response = await axios.get(
    endpoints.ORGANIZATION.ADD_PLUGIN.replace(":pId", data.id),
    config
  );
  return response;
}

function* addPluginToOrg(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(addPluginToOrgCall, sessionToken, payload.data);
    const data = response.data;
    if (data) {
      yield put({ type: GET_ORG_INFO, data });
      yield put({ type: ADD_ORG_PLUGIN_SUCCESS, data });
      toast.success(
        data.message ?? "Plugin added to Organization Successfully"
      );
    } else {
      toast.error(data.message ?? "Failed adding Plugin to Organization");
      yield put({
        type: ADD_ORG_PLUGIN_FAILURE,
        data: {
          error: "Problem Removing Plugin",
        },
      });
    }
  } catch (error) {
    toast.error(
      error.message ??
        error.response?.message ??
        "Failed adding Plugin to Organization"
    );
    yield put({
      type: ADD_ORG_PLUGIN_FAILURE,
      data: {
        error: error.message,
      },
    });
  }
}

async function addPluginsToOrgCall(sessionToken, data) {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  const response = await axios.post(
    endpoints.ORGANIZATION.ADD_PLUGINS,
    data.payload,
    config
  );
  return response;
}

function* addPluginsToOrg(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(
      addPluginsToOrgCall,
      sessionToken,
      payload.data
    );

    if (response.status === 200) {
      yield put({ type: GET_ORG_INFO });
      yield put({ type: ADD_ORG_PLUGINS_SUCCESS });
      toast.success(
        response.message ?? "Plugins added to Organization Successfully"
      );
    } else {
      toast.error(response.message ?? "Failed adding Plugins to Organization");
      yield put({
        type: ADD_ORG_PLUGINS_FAILURE,
        data: {
          error: "Problem Adding Plugins",
        },
      });
    }
  } catch (error) {
    toast.error(
      error.message ??
        error.response?.message ??
        "Failed adding Plugin to Organization"
    );
    yield put({
      type: ADD_ORG_PLUGINS_FAILURE,
      data: {
        error: error.message,
      },
    });
  }
}

function* deletePlugin(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(
      deletePluginCall,
      sessionToken,
      payload.data.id
    );
    const data = response.data;
    if (response.status === 204 || response.status === 200) {
      yield put({ type: DELETE_PLUGIN_SUCCESS, data });
      toast.success("Plugin Deleted Successfully");
      yield put({ type: FETCH_PLUGINS });
    } else {
      yield put({
        type: DELETE_PLUGIN_FAILURE,
        data: {
          error: "Problem deleting Plugin",
        },
      });
      toast.error("Couldn't delete Plugin");
    }
  } catch (error) {
    yield put({
      type: DELETE_PLUGIN_FAILURE,
      data: {
        error: error.message,
      },
    });
    toast.error(error.message);
  }
}

// const createPluginCall = async (sessionToken, payload) => {
//   const config = {
//     headers: {
//       Authorization: "basic " + sessionToken,
//     },
//   };
//   const response = await axios.post(
//     endpoints.PLUGIN.CREATE_PLUGIN,
//     payload,
//     config
//   );
//   return response;
// };

const uploadPluginIconCall = async (sessionToken, payload) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "basic " + sessionToken,
    },
  };
  const response = await axios.post(
    endpoints.PLUGIN.UPLOAD_ICON,
    payload.data.iconPayload,
    config
  );
  return response;
};

const editPLuginCall = async (sessionToken, payload, id) => {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  const response = await axios.put(
    endpoints.PLUGIN.EDIT_PLUGIN.replace(":id", id),
    payload,
    config
  );
  return response;
};

// function* createPlugin(payload) {
//   try {
//     const sessionToken = yield select(sessionTokenSelector);
//     if (payload.data.iconPayload) {
//       const iconResponse = yield call(
//         uploadPluginIconCall,
//         sessionToken,
//         payload
//       );
//       const iconData = iconResponse.data;
//       if (iconResponse.status === 200 && iconData) {
//         payload.data.payload["image"] = iconData.url;
//       }
//     }
//     const response = yield call(
//       createPluginCall,
//       sessionToken,
//       payload.data.payload
//     );
//     const data = response.data;
//     if (response.status === 201) {
//       yield put({ type: CREATE_PLUGIN_SUCCESS });
//       yield put({ type: FETCH_PLUGINS });
//       toast.success("Plugin Created Successfully");
//     } else {
//       yield put({
//         type: CREATE_PLUGIN_FAILURE,
//         data: {
//           error: " Cannot Create Plugin",
//         },
//       });
//       toast.error("Some error while adding Plugin");
//     }
//   } catch (error) {
//     yield put({
//       type: CREATE_PLUGIN_FAILURE,
//       data: {
//         error: "Cannot Create Plugin",
//       },
//     });
//     if (error?.response?.data?.error) toast.error(error.response.data.error);
//     else toast.error("Some error while creating Plugin. Please try again");
//   }
// }

function* editPlugin(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    if (payload.data.iconPayload) {
      const iconResponse = yield call(
        uploadPluginIconCall,
        sessionToken,
        payload
      );
      const iconData = iconResponse.data;
      if (iconResponse.status === 200 && iconData) {
        payload.data.payload["image"] = iconData.url;
      }
    }
    const response = yield call(
      editPLuginCall,
      sessionToken,
      payload.data.payload,
      payload.data.id
    );
    // const data = response.data;
    if (response.status === 200) {
      yield put({ type: EDIT_PLUGIN_SUCCESS });
      yield put({ type: FETCH_PLUGINS });
      toast.success("Plugin Updated Successfully");
    } else {
      yield put({
        type: EDIT_PLUGIN_FAILURE,
        data: {
          error: " Cannot Update Plugin",
        },
      });
      toast.error("Some error while update Plugin");
    }
  } catch (error) {
    yield put({
      type: EDIT_PLUGIN_FAILURE,
      data: {
        error: error.message,
      },
    });
    toast.error(error.message);
  }
}

export default function* watcherSaga() {
  yield takeLatest(FETCH_PLUGINS, fetchPlugins);
  yield takeLatest(FETCH_PLUGIN, fetchPlugin);
  yield takeLatest(DELETE_PLUGIN, deletePlugin);
  yield takeLatest(ADD_ORG_PLUGIN, addPluginToOrg);
  yield takeLatest(ADD_ORG_PLUGINS, addPluginsToOrg);
  yield takeLatest(REMOVE_ORG_PLUGIN, removePluginFromOrg);
  yield takeLatest(EDIT_PLUGIN, editPlugin);
  // yield takeLatest(FETCH_PLUGIN, fetchPlugin);
}
