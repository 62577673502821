import { Divider, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
//import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
//import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import React, { Component } from "react";
import { withTranslation ,WithTranslation} from "react-i18next";
import { connect,ConnectedProps } from "react-redux";
import CustomButton from "../../components/custombutton/CustomButton";
import { coreConversion, spaceConversion } from "../../helpers/utils";
import CreateResource from "./CreateResource";
import EditResource from "./EditResource";
import { deleteResource, fetchResources } from "./redux/actions";
import ConfirmDeletePopup from "../../components/confirmdeletepopup/ConfirmDeletePopup";
import BackdropLoader from '../../components/loader/BackdropLoader';
import { StyledTableCell, StyledTableRow } from "../../components/styledtabelcell/StyledTabelCell";
import { Resource } from "../../models/Environment.model";
import { Dispatch } from "redux";

export interface IunsavedResource extends Pick<Resource,"name"|"memory"|"cores"|"weight"|"active">{
  attributes?:string
}

export interface IpayloadValues extends Omit<IunsavedResource,"name">{
  resourceName:string;
  id?:number
 }

interface Iprops extends PropsFromRedux,WithTranslation {
}
 interface IState {
  isDeletePopupOpened:   boolean;
  selectedResourceId:    number;
  selectedResourceName:  string;
  isCreateEditPopupOpen: boolean;
  editId:                number;
}

export class OrganizationResource extends Component<Iprops,IState> {
  constructor(props:Iprops) {
    super(props);
    this.state = {
      isDeletePopupOpened: false,
      selectedResourceId: 0,
      selectedResourceName: '',
      isCreateEditPopupOpen: false,
      editId: 0
    };
  }

  componentDidMount() {
    this.props.fetchResources();
  }

  handleCreateResource = () => {
    this.setState({
      isCreateEditPopupOpen: true,
      editId: 0
    })
  };

  handleEdit = (id:number) => {
    this.setState({
      isCreateEditPopupOpen: true,
      editId: id
    })
  }

  deletePopupAgreeHandler = () => {
    this.props.deleteResource(this.state.selectedResourceId);
    this.setState({
      selectedResourceId: 0,
      selectedResourceName: '',
      isDeletePopupOpened: false,
    });
  };

  deletePopupDisagreeHandler = () => {
    this.setState({
      selectedResourceId: 0,
      selectedResourceName: '',
      isDeletePopupOpened: false,
    });
  };

  handleDelete(resourceId:number, resourceName:string) {
    this.setState({
      selectedResourceId: resourceId,
      selectedResourceName: resourceName,
      isDeletePopupOpened: true,
    });
  }

  handleCancel = () => {
    this.setState({
      isCreateEditPopupOpen: false,
      editId: 0
    })
  }

  handleCreateEditSuccess = () => {
    this.setState({
      isCreateEditPopupOpen: false,
      editId: 0
    })
    this.props.fetchResources();
  }

  render() {
    const { resourceList, t } = this.props;
    return (
        <div data-test="main-component">
            <div className="listContainer">
                <Typography color="textPrimary" variant="h5"  data-test="typography">
                    {t('Organization.AdminTab.resources')}
                </Typography>
                <CustomButton
                  data-test="createresource-button"
                  label={t("CreateResource")}
                  onClick={() => this.handleCreateResource()}
                />
            </div>
            <TableContainer component={Paper}>
                <Table data-test="table-data">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>{t('Resource.ResourcePage.name')}</StyledTableCell>
                            <StyledTableCell>{t('Resource.ResourcePage.memory')}</StyledTableCell>
                            <StyledTableCell>{t('Resource.ResourcePage.cores')}</StyledTableCell>
                            <StyledTableCell>{t('Resource.ResourcePage.weight')}</StyledTableCell>
                            <StyledTableCell>{t('Resource.ResourcePage.status')}</StyledTableCell>
                            <StyledTableCell align="center"> {t('Projects.UserTab.actionLabel')} </StyledTableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {resourceList &&
                resourceList.map((resource:Resource, idx:number) => (
                    <StyledTableRow key={idx} data-test="resourceTable-data">
                        <StyledTableCell data-test="resource-name">{resource.name}</StyledTableCell>
                        <StyledTableCell data-test="resource-memory">
                            {spaceConversion(resource.memory)}
                        </StyledTableCell>
                        <StyledTableCell data-test="resource-cores">
                            {coreConversion(resource.cores)}
                        </StyledTableCell>
                        <StyledTableCell data-test="resource-weight">
                            {resource.weight}
                        </StyledTableCell>
                        <StyledTableCell>
                            {resource.active ? (
                                <p>
                                    <span className="activestatus" data-test="active-status"></span> {t('Resource.ResourceForm.active')}
                                </p>
                      ) : (
                          <p>
                              <span className="inactivestatus" data-test="inactive-status"></span> {t('Resource.ResourceForm.inactive')}
                          </p>
                      )}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                            <IconButton 
                              data-test="edit-button"
                              aria-label="edit" 
                              onClick={() => this.handleEdit(resource.id)}
                            >
                                <EditIcon />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              data-test="delete-button"
                              onClick={() => this.handleDelete(resource.id, resource.name)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </StyledTableCell>
                    </StyledTableRow>
                ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {
          this.state.isCreateEditPopupOpen && 
          <Dialog
            data-test="create-popup"
            open={this.state.isCreateEditPopupOpen}
            keepMounted
            onClose={() => this.handleCancel()}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            disableBackdropClick={true}
          >
              <DialogTitle id="alert-dialog-slide-title">
                  <Typography className='dialogtitle'>{ this.state.editId > 0 ? t('Resource.ResourcePage.update') : t('Resource.ResourcePage.add') }</Typography>
                  <IconButton aria-label="close" data-test="close-icon" size="small" className='right' onClick={() => this.handleCancel()}>
                      <CloseIcon />
                  </IconButton>
              </DialogTitle>
              <Divider />
              <DialogContent dividers>
                  <DialogContentText id="alert-dialog-slide-description">
                      { this.state.editId > 0 && <EditResource data-test="edit-resource" resourceId={ this.state.editId } handleCreateEditSuccess={ this.handleCreateEditSuccess }/> }
                      { this.state.editId === 0 && <CreateResource data-test="create-resource" handleCreateEditSuccess={ this.handleCreateEditSuccess } /> }   
                  </DialogContentText>
              </DialogContent>
          </Dialog>
        }
        
            <ConfirmDeletePopup
              data-test="confirm-popup"
              open={this.state.isDeletePopupOpened}
              handleAgree={() => this.deletePopupAgreeHandler()}
              handleDisAgree={() => this.deletePopupDisagreeHandler()}
              message={t('Subscription.SubscriptionPage.confirmDelete')}
              // yesText={t('Projects.VariablesTab.yesText')}
              // noText={t('Projects.VariablesTab.noText')}
              toMatchName={this.state.selectedResourceName}
              toDeleteModule="Resource"
              loading={this.props.executingDeleteResource}
            />
            { this.props.executingDeleteResource && <BackdropLoader message={t('Resource.ResourcePage.deletingResource')}  data-test="deleting-resource" />}
            {this.props.fetchingResources && <BackdropLoader message={t('Resource.ResourcePage.fetchingResource')} data-test="fetching-resource" />}
        </div>
    );
  }
}
/* istanbul ignore next */
const mapStateToProps = (state:any) => {
  return {
    resourceList: state.ResourceReducer.resourceList,
    fetchingResources: state.ResourceReducer.fetchingResources,
    executingDeleteResource: state.ResourceReducer.executingDeleteResource
  };
};
/* istanbul ignore next */
const mapDispatchToProps = (dispatch:Dispatch) => ({
  fetchResources: () => dispatch(fetchResources()),
  deleteResource: (id:number) => dispatch(deleteResource(id)),
});

const connector= connect(
  mapStateToProps,
  mapDispatchToProps
)
export default connector(withTranslation()(OrganizationResource));

type PropsFromRedux=ConnectedProps<typeof connector>