import { Paper, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  autocompleteSearch,
  clearSuggestions,
} from "../../pages/common/redux/actions";
// import BackdropLoader from "../loader/BackdropLoader";
import "./autocomplete.css";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';
import { Dispatch } from "redux";
import { UserModel } from "../../models/Account.model"
const setoutTimer = 500;

/* istanbul ignore next */
const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

interface Props extends PropsFromRedux {
  email: string
  setEmail: (val: string) => void
  updateError: (err: boolean, message: string) => void
  disabled?: boolean
}

// Use your imagination to render suggestions.
export function AutoComplete(props: Props) {
  const classes = useStyles();
  const [searchText, setSearchText] = React.useState("");
  const [timer, setTimer] = React.useState<any>(null);
  const [initialLoad, setInitialLoad] = React.useState(true);
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [t] = useTranslation();
  // const [showSuggestion, setShowSuggestion] = useState(false);
  // const [activeSuggestion, setActiveSuggestion] = useState("");

  const handleAutocomplete = (val?: string) => {
    props.clearSuggestions();
    props.autocompleteSearch(val ?? searchText);
  };

  const clean = () => {
    clearTimeout(timer);
    setTimer(null);
    setSearchText("");
    props.clearSuggestions();
    props.updateError(false, "");
    setInitialLoad(true);
  };

  useEffect(() => {
    if (props.email) {
      setSearchText(props.email);
    }
    return () => {
      clean();
    };
  }, []);

  useEffect(() => {
    if (!initialLoad) {
      if (searchText?.trim()?.length === 0) {
        setEmailError(true);
        setEmailErrorMessage(t('AddDNSPopup.emptyEmailError'));
        props.clearSuggestions();
      } else {
        if (!/([\w.-]+@([\w-]+)\.+\w{2,}$)/.test(searchText)) {
          setEmailError(true);
          setEmailErrorMessage(t('AddDNSPopup.validEmailError'));
        } else {
          setEmailError(false);
          setEmailErrorMessage("");
        }
        if (searchText?.trim()?.length >= 2) {
          setTimer(setTimeout(() => handleAutocomplete(), setoutTimer));
        } else {
          props.clearSuggestions();
        }
      }
    } else {
      setInitialLoad(false);
    }
    props.setEmail(searchText);
  }, [searchText]);

  useEffect(() => {
    props.updateError(emailError, emailErrorMessage);
  }, [emailError, emailErrorMessage]);

  const handleOnChange = (e: any) => {
    clearTimeout(timer);
    const newValue = e.target?.value;
    if (typeof newValue === "string") {
      setSearchText(newValue);
    } else if (typeof newValue === "number") {
      const email = props.autocompleteOptions[newValue].email;
      setSearchText(email);
    }
    // setSearchText(e.target?.value);
    // setShowSuggestion(true);
    // if (activeSuggestion !== "") {
    //   setActiveSuggestion(false);
    // }
  };

  const handleOnInputChange = (e: any, newValue: string) => {
    clearTimeout(timer);
    if (typeof newValue === "string") {
      setSearchText(newValue);
    } else if (typeof newValue === "number") {
      const email = props.autocompleteOptions[newValue].email;
      setSearchText(email);
    }
  };

  const renderOption = (option: string) => {
    const sugg = props.autocompleteOptions?.find((s: UserModel) => {
      return s.email === option;
    });
    return `${
      sugg.first_name + " " + sugg.last_name + " (" + sugg.email + ")"
    }`;
  };

  // const renderSuggestion = (suggestion) => (
  //   <div>
  //     <div className={classes.rowContainer}>
  //       <Avatar
  //         src={
  //           suggestion.image ? suggestion.image : "./Images/profile_image.png"
  //         }
  //       />
  //       <div className="projectTitlesContainer">
  //         <Grid container direction="column">
  //           <Grid item>
  //             <span className={classes.title}>{suggestion.email}</span>
  //           </Grid>
  //         </Grid>
  //       </div>
  //     </div>
  //   </div>
  // );

  // const handleRowClick = (item) => {
  //   setSearchText(item.email);
  //   setShowSuggestion(false);
  //   setActiveSuggestion(item.email);
  //   handleClose(null);
  // };

  // const classes = useStyles();

  return (
    <div data-test="autoCompleteContainer">
      <Paper elevation={0}>
        <Autocomplete
          disableClearable
          options={
            props.autocompleteOptions
              ? props.autocompleteOptions.map((option: UserModel) => option.email)
              : []
          }
          freeSolo
          noOptionsText={t('AddDNSPopup.noSuggesions')}
          classes={{
            option: classes.option,
          }}
          loading={props.searching}
          onChange={(e) => handleOnChange(e)}
          getOptionLabel={(option) => option}
          renderOption={renderOption}
          filterOptions={(options) => {
            return options;
          }}
          value={props.email ?? ""}
          disabled={props.disabled}
          onInputChange={(e, newValue) => handleOnInputChange(e, newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              error={emailError}
              disabled={props.disabled}
              label={t('AddDNSPopup.enterName')}
              variant="outlined"
              value={searchText}
              InputProps={{
                ...params.InputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
                type: "search",
              }}
            />
          )}
          data-test="auto-complete"
        />
      </Paper>

      {/* {props.searching && <BackdropLoader message="Loading" />} */}
    </div>
  );
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  autocompleteOptions: state.CommonReducer.autocompleteOptions,
  searching: state.CommonReducer.searching,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    autocompleteSearch: (query: string) => dispatch(autocompleteSearch(query)),
    clearSuggestions: () => dispatch(clearSuggestions()),
  };
};

// export default (withTranslation()());

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(AutoComplete);
