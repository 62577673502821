const PREFIX = '@ADD_USER';

export const ADD_USER_IN_PROJECT = `${ PREFIX }/ADD_USER_IN_PROJECT`
export const ADD_USER_IN_PROJECT_SUCCESS = `${ PREFIX }/ADD_USER_IN_PROJECT_SUCCESS`
export const ADD_USER_IN_PROJECT_FAILURE = `${ PREFIX }/ADD_USER_IN_PROJECT_FAILURE`
export const EDIT_USER_IN_PROJECT = `${ PREFIX }/EDIT_USER_IN_PROJECT`
export const EDIT_USER_IN_PROJECT_SUCCESS = `${ PREFIX }/EDIT_USER_IN_PROJECT_SUCCESS`
export const EDIT_USER_IN_PROJECT_FAILURE = `${ PREFIX }/EDIT_USER_IN_PROJECT_FAILURE`
export const FETCH_USER_LIST_IN_PROJECT = `${ PREFIX }/FETCH_USER_IN_PROJECT`
export const FETCH_USER_LIST_IN_PROJECT_SUCCESS = `${ PREFIX }/FETCH_USER_IN_PROJECT_SUCCESS`
export const FETCH_USER_LIST_IN_PROJECT_FAILURE = `${ PREFIX }/FETCH_USER_IN_PROJECT_FAILURE`
export const CLEAR_USER_LIST_IN_PROJECT = `${ PREFIX }/CLEAR_USER_LIST_IN_PROJECT`
export const DELETE_USERS_IN_PROJECT = `${ PREFIX } /DELETE_USERS_IN_PROJECT`
export const DELETE_USERS_IN_PROJECT_SUCCESS = `${ PREFIX } /DELETE_USERS_IN_PROJECT_SUCCESS`
export const DELETE_USERS_IN_PROJECT_FAILURE = `${ PREFIX } /DELETE_USERS_IN_PROJECT_FAILURE`
export const FETCH_ROLES = `${ PREFIX } /FETCH_ROLES`
export const FETCH_ROLES_SUCCESS = `${ PREFIX } /FETCH_ROLES_SUCCESS`
export const ADD_USER_IN_ENVIRONMENT = `${ PREFIX }/ADD_USER_IN_ENVIRONMENT`
export const ADD_USER_IN_ENVIRONMENT_SUCCESS = `${ PREFIX }/ADD_USER_IN_ENVIRONMENT_SUCCESS`
export const ADD_USER_IN_ENVIRONMENT_FAILURE = `${ PREFIX }/ADD_USER_IN_ENVIRONMENT_FAILURE`
export const EDIT_USER_IN_ENVIRONMENT = `${ PREFIX }/EDIT_USER_IN_ENVIRONMENT`
export const EDIT_USER_IN_ENVIRONMENT_SUCCESS = `${ PREFIX }/EDIT_USER_IN_ENVIRONMENT_SUCCESS`
export const EDIT_USER_IN_ENVIRONMENT_FAILURE = `${ PREFIX }/EDIT_USER_IN_ENVIRONMENT_FAILURE`
export const FETCH_USER_LIST_IN_ENVIRONMENT = `${ PREFIX }/FETCH_USER_IN_ENVIRONMENT`
export const FETCH_USER_LIST_IN_ENVIRONMENT_SUCCESS = `${ PREFIX }/FETCH_USER_IN_ENVIRONMENT_SUCCESS`
export const FETCH_USER_LIST_IN_ENVIRONMENT_FAILURE = `${ PREFIX }/FETCH_USER_IN_ENVIRONMENT_FAILURE`
export const FETCH_USER_LIST_IN_HELM_ENVIRONMENT = `${ PREFIX }/FETCH_USER_IN_HELM_ENVIRONMENT`
export const FETCH_USER_LIST_IN_HELM_ENVIRONMENT_SUCCESS = `${ PREFIX }/FETCH_USER_IN_HELM_ENVIRONMENT_SUCCESS`
export const FETCH_USER_LIST_IN_HELM_ENVIRONMENT_FAILURE = `${ PREFIX }/FETCH_USER_IN_HELM_ENVIRONMENT_FAILURE`
export const USERS_LIST_IN_ENVIRONMENT = `${ PREFIX } /USERS_LIST_IN_ENVIRONMENT`
export const CLEAR_USERS_LIST_IN_ENVIRONMENT = `${ PREFIX } /CLEAR_USERS_LIST_IN_ENVIRONMENT`
export const CLEAR_USERS_LIST_IN_HELM_ENVIRONMENT = `${ PREFIX } /CLEAR_USERS_LIST_IN_HELM_ENVIRONMENT`
export const DELETE_USERS_IN_ENVIRONMENT = `${ PREFIX } /DELETE_USERS_IN_ENVIRONMENT`
export const DELETE_USERS_IN_ENVIRONMENT_SUCCESS = `${ PREFIX } /DELETE_USERS_IN_ENVIRONMENT_SUCCESS`
export const DELETE_USERS_IN_ENVIRONMENT_FAILURE = `${ PREFIX } /DELETE_USERS_IN_ENVIRONMENT_FAILURE`
export const ADD_USER_IN_HELM_ENVIRONMENT = `${ PREFIX }/ADD_USER_IN_HELM_ENVIRONMENT`
export const ADD_USER_IN_HELM_ENVIRONMENT_SUCCESS = `${ PREFIX }/ADD_USER_IN_HELM_ENVIRONMENT_SUCCESS`
export const ADD_USER_IN_HELM_ENVIRONMENT_FAILURE = `${ PREFIX }/ADD_USER_IN_HELM_ENVIRONMENT_FAILURE`
export const EDIT_USER_IN_HELM_ENVIRONMENT = `${ PREFIX }/EDIT_USER_IN_HELM_ENVIRONMENT`
export const EDIT_USER_IN_HELM_ENVIRONMENT_SUCCESS = `${ PREFIX }/EDIT_USER_IN_HELM_ENVIRONMENT_SUCCESS`
export const EDIT_USER_IN_HELM_ENVIRONMENT_FAILURE = `${ PREFIX }/EDIT_USER_IN_HELM_ENVIRONMENT_FAILURE`
export const DELETE_USERS_IN_HELM_ENVIRONMENT = `${ PREFIX } /DELETE_USERS_IN_HELM_ENVIRONMENT`
export const DELETE_USERS_IN_HELM_ENVIRONMENT_SUCCESS = `${ PREFIX } /DELETE_USERS_IN_HELM_ENVIRONMENT_SUCCESS`
export const DELETE_USERS_IN_HELM_ENVIRONMENT_FAILURE = `${ PREFIX } /DELETE_USERS_IN_HELM_ENVIRONMENT_FAILURE`

const addUserInProject = (payload) => ({
	type: ADD_USER_IN_PROJECT,
	data: {
		payload,
	}
})

const editUserInProject = (payload, user_id) => ({
	type: EDIT_USER_IN_PROJECT,
	data: {
		payload,
		user_id
	}
})

const deleteUsersInProject = (payload, isLeave, history) => ({
	type: DELETE_USERS_IN_PROJECT,
	data: {
		payload,
		isLeave,
		history,
	}
})

const fetchUserListInProject = (projectId) => ({
	type: FETCH_USER_LIST_IN_PROJECT,
	data: {
		projectId,
	}
})

const clearUserListInProject = () => ({ type: CLEAR_USER_LIST_IN_PROJECT })

const addUserInEnvironment = (payload) => ({
	type: ADD_USER_IN_ENVIRONMENT,
	data: {
		payload,
	}
})

const editUserInEnvironment = (payload, user_id) => ({
	type: EDIT_USER_IN_ENVIRONMENT,
	data: {
		payload,
		user_id
	}
})

const deleteUsersInEnvironment = (payload, isLeave, history) => ({
	type: DELETE_USERS_IN_ENVIRONMENT,
	data: {
		payload,
		isLeave,
		history,
	}
})

export const addUserInHelmEnvironment = (payload) => ({
	type: ADD_USER_IN_HELM_ENVIRONMENT,
	data: {
		payload,
	}
})

export const editUserInHelmEnvironment = (payload, user_id) => ({
	type: EDIT_USER_IN_HELM_ENVIRONMENT,
	data: {
		payload,
		user_id
	}
})

export const deleteUsersInHelmEnvironment = (payload, isLeave, history) => ({
	type: DELETE_USERS_IN_HELM_ENVIRONMENT,
	data: {
		payload,
		isLeave,
		history,
	}
})

const fetchUserListInEnvironment = (environmentId) => ({
	type: FETCH_USER_LIST_IN_ENVIRONMENT,
	data: {
		environmentId,
	}
})

export const fetchUserListInHelmEnvironment = (id) => ({
	type: FETCH_USER_LIST_IN_HELM_ENVIRONMENT,
	data: {
		id,
	}
})

const clearUserListInEnvironment = () => ({ type: CLEAR_USERS_LIST_IN_ENVIRONMENT })

export const clearUserListInHelmEnvironment = () => ({ type: CLEAR_USERS_LIST_IN_HELM_ENVIRONMENT })

const fetchRoles = () => ({
	type: FETCH_ROLES,
	data: {}
})

export {
	addUserInProject,
	fetchUserListInProject,
	clearUserListInProject,
	deleteUsersInProject,
	addUserInEnvironment,
	fetchUserListInEnvironment,
	clearUserListInEnvironment,
	deleteUsersInEnvironment,
	fetchRoles,
	editUserInEnvironment,
	editUserInProject,
}