import React, { useState, useEffect } from "react";
import {
  makeStyles,
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import RemoveOutlinedIcon from "@material-ui/icons/RemoveOutlined";

const useStyles = makeStyles(() => ({
  textBox: {
    margin: "10px 0px 10px 0px",
  },
  resize: {
    fontSize: "1rem",
    textAlign: "center",
  },
  // textField: {
  //     width:200,
  //     [`& fieldset`]: {
  //       borderRadius: 0
  //     },
  // }
}));

interface Props {
  initialValue: number;
  defaultValue: number;
  updateValueChange: (
    value: number,
    additionalData1?: number | string,
    additionalData2?: string
  ) => void;
  additionalData1?: number | string;
  additionalData2?: string;
  updateIncomingValue?: boolean;
  maxValue?: number;
  label?: string;
  isError: boolean;
  helperText?: string;
  isFullWidth?: boolean;
  customClassName?: string;
  margin?: "none" | "dense" | "normal";
  unit?: string;
}

export function MuiNumberCounter(props: Props) {
  const classes = useStyles();
  const [value, setValue] = useState<number>(
    props.initialValue ? props.initialValue : props.defaultValue
  );

  useEffect(() => {
    if (props.defaultValue) {
      props.updateValueChange(
        value,
        props.additionalData1,
        props.additionalData2
      );
    }
    // return () => {
    //   setValue('') // todo
    // };
  }, []);

  useEffect(() => {
    if (props.updateIncomingValue) {
      setValue(props.initialValue);
    }
  }, [props.initialValue]);

  const updateValue = (_value: number) => {
    setValue(_value);
    props.updateValueChange(
      _value,
      props.additionalData1,
      props.additionalData2
    );
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateValue(parseInt(e.target.value));
  };

  const onClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    mode: number
  ) => {
    if (mode === 1) {
      if (value > props.defaultValue) updateValue(value - 1);
    } else {
      if (props.maxValue) {
        if (props.maxValue >= value + 1) {
          updateValue(value + 1);
          return;
        }
      } else {
        updateValue(value + 1);
      }
    }
    //props.updateValueChange(value);
  };

  return (
    <TextField
      data-test="text-field-container"
      label={props.label}
      name={props.label}
          type="number"
         
      onChange={onChange}
      error={props.isError}
      helperText={props.helperText ?? ""}
      //onKeyDown={ onKeyDown }
      fullWidth={props.isFullWidth}
      value={value}
      variant="outlined"
      className={`${props.customClassName}`}
      margin={props.margin}
      color="primary"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton
              //aria-label="toggle password visibility"
              onClick={(e) => onClick(e, 1)}
              //onMouseDown={handleMouseDownPassword}
              data-test="icon-btn"
            >
              <RemoveOutlinedIcon color="primary" />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {props.unit ? props.unit : ""}
            <IconButton
              //aria-label="toggle password visibility"
              onClick={(e) => onClick(e, 2)}
              //onMouseDown={handleMouseDownPassword}
              data-test="icon-btn-end"
            >
              <AddOutlinedIcon color="primary" />
            </IconButton>
          </InputAdornment>
        ),
        classes: {
          input: classes.resize,
        },
      }}
    />
  );
}

export default MuiNumberCounter;
