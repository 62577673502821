import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  Grid,
  Link,
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import {
  uninstallOperator,
  reDeployOperator,
  fetchOperatorLogs,
  clearOperatorLogs,
} from "../../pages/operators/redux/actions";
import { Transition } from "../../helpers/utils.ext";
import { useTranslation } from "react-i18next";
import ConfirmActionPopup from "../confirmactionpopup/ConfirmActionPopup";
import ActivityLogs from "../activitylogs/ActivityLogs";
import { getDateInStandardFormat, setHttpInUrl } from "../../helpers/utils";
import { AppConstants } from "../../constants/appconstants";
import "./OperatorInfoPopup.css";
// types
import { ConnectedProps } from "react-redux";
import { Operator } from "../../models/Operator.model";
import { ClusterDetailsModel } from "../../models/Cluster.model";
import { Dispatch } from "redux";

/* istanbul ignore next */
const useStyles = makeStyles(() =>
  createStyles({
    operatorHeading: {
      display: "flex",
      alignItems: "center",
    },
    operatorIcon: {
      height: "50px",
      marginRight: "20px",
    },
    operatorInfoContainer: {
      margin: "10px 0",
      fontSize: "14px",
    },
    operatorInfo: {
      marginBottom: "10px",
    },
    operatorInfoLabel: {
      fontWeight: 600,
    },
    logsContainer: {
      marginBottom: 20,
      backgroundColor: "#424242",
      color: "white",
      marginTop: 10,
      height: "200px",
      whiteSpace: "pre-line",
      overflowY: "auto",
      paddingLeft: 20,
      position: "relative",
    },
    operatorActions: {
      gap: 10,
    },
  })
);

/* istanbul ignore next */
// const transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

interface Props extends PropsFromRedux {
  open: boolean;
  handleClose: () => void;
  operator: Operator;
  operatorStatus: string;
  cluster: ClusterDetailsModel;
}

const getOperatorStatusClass = (status: string) => {
  let className = "";
  switch (status) {
    case AppConstants.OperatorStatus.Succeeded:
      className = "installed";
      break;
    case AppConstants.OperatorStatus.Failed:
      className = "failed";
      break;
    default:
      break;
  }
  return className;
};

export const OperatorInfoPopup = (props: Props) => {
  const [t] = useTranslation();
  const { operator, cluster, handleClose, operatorStatus } = props;

  const operatorDetails = operator.operator_details.operator;

  const [isUninstallConfirmOpen, setIsUninstallConfirmOpen] = useState(false);
  const [isRedeployConfirmOpen, setIsRedeployConfirmOpen] = useState(false);

  const classes = useStyles();

  const handleRedeploy = () => {
    setIsRedeployConfirmOpen(true);
  };

  const handleOperatorUninstall = () => {
    setIsUninstallConfirmOpen(true);
  };

  const handleOperatorUninstallAgree = () => {
    props.uninstallOperator(cluster.id, operator.id, handleClose);
    setIsUninstallConfirmOpen(false);
  };

  const handleOperatorUninstallDisagree = () =>
    setIsUninstallConfirmOpen(false);

  const handleOperatorRedeployAgree = () => {
    props.reDeployOperator(cluster.id, operator.id);
    setIsRedeployConfirmOpen(false);
  };

  const handleOperatorRedeployDisagree = () => setIsRedeployConfirmOpen(false);

  useEffect(() => {
    props.fetchOperatorLogs(cluster.id, operator.id);

    return () => {
      props.clearOperatorLogs();
    };
  }, []);

  return (
    operatorDetails && (
      <>
        <Dialog
          open={props.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          data-test="operator-info-dialogue"
          className="operator-info-popup-container"
        >
          <DialogTitle>
            <Typography className="dialogtitle" data-test="dialogue-title">
              {t("Operators.OperatorCatalog.operator")}
            </Typography>

            <IconButton
              aria-label="close"
              size="small"
              className="right"
              onClick={handleClose}
              data-test="close-popup-button"
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers className="dialogue-content">
            <div data-test="operator-details">
              <div className={classes.operatorHeading}>
                <img
                  src={operatorDetails.thumbUrl}
                  alt={operatorDetails.packageName}
                  className={classes.operatorIcon}
                  data-test="operator-icon"
                />
                <Typography
                  variant="h6"
                  color="textPrimary"
                  data-test="operator-heading"
                >
                  {operatorDetails.displayName}
                </Typography>
              </div>
              <div className={classes.operatorInfoContainer}>
                {operatorStatus && (
                  <Grid container className={classes.operatorInfo}>
                    <Grid item xs={4} className={classes.operatorInfoLabel}>
                      {t("Operators.OperatorCatalog.status")}
                    </Grid>
                    <Grid
                      item
                      xs={8}
                      className={`operator-status ${getOperatorStatusClass(
                        operatorStatus
                      )}`}
                      data-test="operator-status"
                    >
                      {operatorStatus}
                    </Grid>
                  </Grid>
                )}
                {operatorDetails.version && (
                  <Grid container className={classes.operatorInfo}>
                    <Grid item xs={4} className={classes.operatorInfoLabel}>
                      {t("Operators.OperatorCatalog.version")}
                    </Grid>
                    <Grid item xs={8} data-test="operator-version">
                      {operatorDetails.version}
                    </Grid>
                  </Grid>
                )}
                {operatorDetails.repository && (
                  <Grid container className={classes.operatorInfo}>
                    <Grid item xs={4} className={classes.operatorInfoLabel}>
                      {t("Operators.OperatorCatalog.repository")}
                    </Grid>
                    <Grid item xs={8}>
                      <Link
                        href={setHttpInUrl(operatorDetails.repository)}
                        underline="hover"
                        target="_blank"
                        rel="noopener"
                        data-test="operator-repository"
                      >
                        {operatorDetails.repository}
                      </Link>
                    </Grid>
                  </Grid>
                )}
                {operatorDetails.provider && (
                  <Grid container className={classes.operatorInfo}>
                    <Grid item xs={4} className={classes.operatorInfoLabel}>
                      {t("Operators.OperatorCatalog.provider")}
                    </Grid>
                    <Grid item xs={8} data-test="operator-provider">
                      {operatorDetails.provider}
                    </Grid>
                  </Grid>
                )}
                {operatorDetails.containerImage && (
                  <Grid container className={classes.operatorInfo}>
                    <Grid item xs={4} className={classes.operatorInfoLabel}>
                      {t("Operators.OperatorCatalog.containerImage")}
                    </Grid>
                    <Grid
                      item
                      xs={8}
                      data-test="operator-containerImage"
                      style={{ overflowWrap: "break-word" }}
                    >
                      {operatorDetails.containerImage}
                    </Grid>
                  </Grid>
                )}
                {operator.channel && (
                  <Grid container className={classes.operatorInfo}>
                    <Grid item xs={4} className={classes.operatorInfoLabel}>
                      {t("Operators.OperatorCatalog.updateChannel")}
                    </Grid>
                    <Grid item xs={8} data-test="operator-containerImage">
                      {operator.channel}
                    </Grid>
                  </Grid>
                )}
                {operator.install_plan_approval && (
                  <Grid container className={classes.operatorInfo}>
                    <Grid item xs={4} className={classes.operatorInfoLabel}>
                      {t("Operators.OperatorCatalog.approvalStrategy")}
                    </Grid>
                    <Grid item xs={8} data-test="operator-containerImage">
                      {operator.install_plan_approval}
                    </Grid>
                  </Grid>
                )}
                {operatorDetails.createdAt && (
                  <Grid container className={classes.operatorInfo}>
                    <Grid item xs={4} className={classes.operatorInfoLabel}>
                      {t("Operators.OperatorCatalog.createdAt")}
                    </Grid>
                    <Grid item xs={8} data-test="operator-createdAt">
                      {getDateInStandardFormat(operatorDetails.createdAt)}
                    </Grid>
                  </Grid>
                )}
              </div>

              <div>
                <Typography variant="h6" color="textPrimary" className="m-b-10">
                  Logs
                </Typography>
                <ActivityLogs
                  activityLogs={props.operatorLogs}
                  realTimeActivityLogs={props.realTimeOperatorLogs?.filter(
                    (log: any) =>
                      log.involvedObject.name === operatorDetails.name
                  )}
                  showAllLogs={true}
                />
              </div>
            </div>
          </DialogContent>

          {(operatorStatus === AppConstants.OperatorStatus.Succeeded ||
            operatorStatus === AppConstants.OperatorStatus.Failed) && (
            <DialogActions className={classes.operatorActions}>
              {(operatorStatus === AppConstants.OperatorStatus.Succeeded ||
                operatorStatus === AppConstants.OperatorStatus.Failed) && (
                <Button
                  onClick={handleOperatorUninstall}
                  color="primary"
                  variant="contained"
                  size="small"
                  data-test="uninstall-button"
                >
                  {t("Operators.OperatorCatalog.uninstall")}
                </Button>
              )}
              {operatorStatus === AppConstants.OperatorStatus.Failed && (
                <Button
                  onClick={handleRedeploy}
                  color="primary"
                  variant="contained"
                  size="small"
                  data-test="redeploy-button"
                >
                  {t("Operators.OperatorCatalog.redeploy")}
                </Button>
              )}
            </DialogActions>
          )}
        </Dialog>
        <ConfirmActionPopup
          open={isUninstallConfirmOpen}
          message="Are you sure you want to uninstall this operator?"
          handleAgree={handleOperatorUninstallAgree}
          handleDisAgree={handleOperatorUninstallDisagree}
          yesText="Yes"
          data-test="uninstall-confirm"
        />
        <ConfirmActionPopup
          open={isRedeployConfirmOpen}
          message="Are you sure you want to redeploy this operator?"
          handleAgree={handleOperatorRedeployAgree}
          handleDisAgree={handleOperatorRedeployDisagree}
          yesText="Yes"
          data-test="redeploy-confirm"
        />
      </>
    )
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  operatorLogs: state.OperatorReducer.operatorLogs,
  realTimeOperatorLogs: state.OperatorReducer.realTimeOperatorLogs,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    uninstallOperator: (
      clusterId: number,
      operatorId: number,
      callback: () => void
    ) => dispatch(uninstallOperator(clusterId, operatorId, callback)),
    reDeployOperator: (clusterId: number, operatorId: number) =>
      dispatch(reDeployOperator(clusterId, operatorId)),
    fetchOperatorLogs: (clusterId: number, operatorId: number) =>
      dispatch(fetchOperatorLogs(clusterId, operatorId)),
    clearOperatorLogs: () => dispatch(clearOperatorLogs()),
  };
};
const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(OperatorInfoPopup);
