import React, { Component } from "react";
import { connect,ConnectedProps } from "react-redux";
import ResourceForm from "./ResourceForm";
import { editResourceCall } from "./redux/actions";
import { fetchResource } from "./redux/actions";
import BackdropLoader from '../../components/loader/BackdropLoader';
import { withTranslation,WithTranslation } from 'react-i18next';
import { Dispatch } from "redux";
import { IunsavedResource,IpayloadValues } from "./Resource";

interface IProps extends PropsFromRedux,WithTranslation{
  resourceId:number;
  handleCreateEditSuccess:()=>void
}

export class EditResource extends Component<IProps> {
  constructor(props:IProps) {
    super(props);
    this.state = {
      //resourceId: this.props.match.params.id,
    };
  }

  componentDidMount() {
    this.props.fetchResourceData(this.props.resourceId);
  }

  UNSAFE_componentWillReceiveProps(nextProps:IProps) {
    if(nextProps) {
      if(!this.props.editResourceCallSuccess && nextProps.editResourceCallSuccess){
        this.props.handleCreateEditSuccess();
      }
    }
  }

  editResource = (values:IpayloadValues) => {
    const payload: IunsavedResource = {
      name: values.resourceName,
      memory: Math.trunc(values.memory),
      cores: values.cores,
      weight: values.weight,
      active: values.active === true ? true : false,
    };
   if(values.id){
    this.props.editResourceApiCall(values.id, payload);
   }
  };

  render() {
    const { t, resource } = this.props;
    return (
        <div data-test="main-container">
            <ResourceForm
              data-test="resource-form"
              resourceData={resource}
              edit={true}
              resourceAction={(payload:IpayloadValues) => this.editResource(payload)}
            />
            {this.props.executingEditResource && <BackdropLoader message={t('Resource.ResourcePage.updatingResource')} data-test="loader" />}
            {this.props.fetchingResource && <BackdropLoader message={t('Resource.ResourcePage.fetchingDetails')} data-test="fetching-resource-loader" />}
        </div>
    );
  }
}
/* istanbul ignore next */
const mapStateToProps = (state:any) => {
  return {
    resource: state.ResourceReducer.resource,
    editResourceCallSuccess: state.ResourceReducer.editResourceCallSuccess,
    executingEditResource: state.ResourceReducer.executingEditResource,
    fetchingResource: state.ResourceReducer.fetchingResource
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch:Dispatch) => ({
  editResourceApiCall: (id:number, payload:IunsavedResource) =>
    dispatch(editResourceCall(id, payload)),
  fetchResourceData: (id:number) => {
    dispatch(fetchResource(id));
  }
});
const connector=connect(mapStateToProps, mapDispatchToProps)
export default connector(withTranslation()(EditResource));

type PropsFromRedux = ConnectedProps<typeof connector>;