import axios, { AxiosResponse } from "axios";
import endpoints from "../constants/endpoints";

interface I_validateTokenSuccessResponse {
  success: number;
  message: string;
  data: string;
}
type T_validateTokenResponse = I_validateTokenSuccessResponse | null;

export const validateTokenCall = async (
  token: string,
  sessionToken: string
): Promise<AxiosResponse<T_validateTokenResponse>> => {
  const apiUrl =
    endpoints.CLUSTER.VALIDATE_V_CLUSTER_TOKEN + `?vtoken=${token}`; // to be replaced with internal api endpoint
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `basic ${sessionToken}`,
    },
  };
  return axios.get(apiUrl, config);
};

export const getvClusterSubs = <T extends unknown>(
  sessionToken: string
): Promise<AxiosResponse<T>> => {
  const url = `${window?.config?.REACT_APP_RESTAPI_ENDPOINT}/public/file/subscriptions.json`;
  const headers = {
    Authorization: `basic ${sessionToken} `,
  };

  return axios.get(url, { headers });
};
