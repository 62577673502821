import React from "react";
import { IconButton } from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import "./ContainerLogs.css";

type Props = {
  url: string,
  isOpen: boolean,
  handleLaunchExternalLogs:() => void
}

const ContainerLogs = ({ url, isOpen, handleLaunchExternalLogs }: Props) => {
  return (
    <>
      {
        isOpen && (
          <div className="logs-container">
            <iframe
              src={url}
              title="Logs"
              width="100%"
              height={500}
              data-test="logs-container"
            />
            <IconButton
              className="launch-external-logs"
              onClick={handleLaunchExternalLogs}
              data-test="launch-external-logs-button"
            >
              <LaunchIcon />
            </IconButton>
          </div>
        )}
    </>
  );
};

export default ContainerLogs;
