const initialState = {
    appDetails : {},
    environmentList: [],
    isRenaming:false,
    isRenameSuccess:false,
    hasRenameErrored:false,
    pluginDetails : {},
    isDataNotFound : false,
    appAvailableResource : {},
    gitOrganizations: null,
    gitRepos: null,
    gitBranches: [],
    isGITNotAuthorized: false, 
    appRole: {},
    envListLoading: false,
    creatingApp: false,
    deletingApp: false,
    connecting: false,
    errorMessage: null
}

export default initialState
