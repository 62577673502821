// const someCommonValues = ['common', 'values'];
import React from "react"
import {Slide} from "@material-ui/core";
import { AppConstants } from "../constants/appconstants"
// const days = [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday' ];
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const getDiffDays = (oldDate, addAgo) => {
   let finalString = '';
   var date1 = new Date(oldDate);
   var date2 = new Date();
   var msec = date2.getTime() - date1.getTime();
   var secs = Math.floor(msec / 1000);
   var mins = Math.floor(msec / 60000);
   var hrs = Math.floor(mins / 60);
   var days = Math.floor(hrs / 24);
   var yrs = Math.floor(days / 365);
   mins = mins % 60;
   hrs = hrs % 24;
   days = days % 365;

   if (yrs > 0) finalString = yrs + 'y';
   else if (days > 0) finalString = days + 'd';
   else if (hrs > 0) finalString = hrs + 'h';
   else if (mins > 0) finalString = mins + 'm';
   else if (secs > 0 && secs < 60) finalString = secs + 's';

   return finalString ? finalString.trim() + (addAgo ? ' ago' : '') : 'Just now';
};

export const getDiffBetweenDates = (_date1, _date2) => {
   let finalString = '';
   var date1 = new Date(_date1);
   var date2 = new Date(_date2);
   var msec = date2.getTime() - date1.getTime();
   var sec = (msec % 60000) / 1000;
   var mins = Math.floor(msec / 60000);
   // var hrs = Math.floor(mins / 60);
   // var days = Math.floor(hrs / 24);
   // var yrs = Math.floor(days / 365);
   // mins = mins % 60;
   // hrs = hrs % 24;
   // days = days % 365;

   // if (yrs > 0) finalString = yrs + "y";
   // if (days > 0) finalString +=  " " + days + "d";
   // if (hrs > 0) finalString += " " + hrs + "h";
   // if (mins > 0) finalString += " " + mins + "m";

   if (mins > 0) finalString = mins + 'm';
   if (sec > 0) finalString += ' ' + Math.round(sec) + 's';

   return finalString ? finalString.trim() : '0s';
}

export const getDiffInHours = (oldDate) => {
   let hrs = 0;
   if (oldDate) {
      const date1 = new Date(oldDate);
      const date2 = new Date();
      const msec = date2.getTime() - date1.getTime();
      hrs = Math.floor(msec / 3600000);
   }
   return hrs;
}

export const getDurationInSeconds = (duration) => {
   let inSeconds = 0;

   switch (duration) {
      case 'Day':
         inSeconds = 60 * 60 * 24;
         break;
      case 'Week':
         inSeconds = 60 * 60 * 24 * 7;
         break;
      case 'Month':
         inSeconds = 60 * 60 * 24 * 30;
         break;
      case 'Hour':
      default:
         inSeconds = 60 * 60
         break;
   }
   return inSeconds;
}

export const spaceCoversion = (space) => {
   if (space < 1024)
      return `${space} MB`
   else
      return `${(space / 1024).toFixed(2)} GB`
}

export const coreConversion = (coreValue) => {
   // if(coreValue < 1000){
   //    return `${coreValue} MilliCore CPU`
   // } else {
   //    return `${coreValue/1000} Core CPU`
   // }
   if (coreValue > 0) {
      return `${coreValue / 1000} Core`
   }
   return `${0/1000} Core`
}

export const getDateInStandardFormat = (date, shortMonth) => {
   let standardDate = date;
   try {
      let d = new Date(date);

      if(isNaN(d)) return standardDate;

      // standardDate = days[d.getDay()] + ', ' +  months[d.getMonth()] + ' ' + d.getDate() + ', ' +d.getFullYear() + ' ' + formatAMPM(d);
      if (shortMonth) {
         standardDate = shortMonths[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear() + ' ' + formatAMPM(d);

      } else {
         standardDate = months[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear() + ' ' + formatAMPM(d);
      }
   }
   catch (e) {
      return standardDate
   }
   return standardDate;
}

export const getDateInChartFormat = (date) => {
   let chartDate = date;
   try {
      let d = new Date(date);
      chartDate = shortMonths[d.getMonth()] + ' ' + d.getDate() + ' - ' + d.getHours() + ':' + d.getMinutes();
   }
   catch (e) {
      return chartDate
   }
   return chartDate;
}

function formatAMPM(date) {
   var hours = date.getHours();
   var minutes = date.getMinutes();
   var ampm = hours >= 12 ? 'PM' : 'AM';
   hours = hours % 12;
   hours = hours ? hours : 12; // the hour '0' should be '12'
   minutes = minutes < 10 ? '0' + minutes : minutes;
   var strTime = hours + ':' + minutes + ' ' + ampm;
   return strTime;
}

export const convertToChartData = (data, type, unitObject) => {
   let finalData = {};
   let fData = []
   let _fData = {}
   if (data && data.length > 0) {
      // 1: Core; 2: Memory
      if (type === 1) {
         data.map((item) => {
            let myItem = {
               xAxixLabel: new Date(item[0] * 1000).toString(),
               // yAxisLabel:,
               'CPU_Usage': (item[1] * unitObject.MultiplyBy).toFixed(2),
            }
            fData.push(myItem)
         })
         _fData.data = fData
         _fData.maxY = Math.max(...fData.map(d => {
            return d.CPU_Usage
         }))

         _fData.label = [
            {
               name: 'CPU_Usage',
               strokeColor: '#00AAAA'
            },
         ]
         return _fData
      }
      else if (type === 2) {
         data.map((item) => {
            let myItem = {
               xAxixLabel: new Date(item[0] * 1000),
               // yAxisLabel:,
               'CPU_Usage': (item[1] / 1048576),
            }
            fData.push(myItem)
         })
         _fData.data = fData
         _fData.maxY = Math.max(...fData.map(d => {
            return d.CPU_Usage
         }))
         _fData.label = [
            {
               name: 'CPU_Usage',
               strokeColor: '#FF0000'
            },
         ]
         return _fData
      }
      else if (type === 3) {
         data.map((item) => {
            let myItem = {
               xAxixLabel: new Date(item[0] * 1000).toString(),
               // yAxisLabel:,
               'RAM_Usage': (item[1] / unitObject.DivideBy).toFixed(2),
            }
            fData.push(myItem)
         })
         _fData.data = fData
         _fData.maxY = Math.max(...fData.map(d => {
            return d.RAM_Usage
         }))
         _fData.label = [
            {
               name: 'RAM_Usage',
               strokeColor: '#0000FF'
            },
         ]
         return _fData
      }
      else if (type === 4) {
         data.map((item) => {
            let myItem = {
               xAxixLabel: new Date(item[0] * 1000).toString(),
               // yAxisLabel:,
               // label: [RAM_Usage],
               'DISK_Usage': (item[1] / unitObject.DivideBy).toFixed(2),
            }
            fData.push(myItem)
         })
         _fData.data = fData
         _fData.maxY = Math.max(...fData.map(d => {
            return d.DISK_Usage
         }))
         _fData.label = [
            {
               name: 'DISK_Usage',
               strokeColor: '#0000FF'
            },
         ]
         return _fData
      }
      else if (type === 5) {
         const _data = data[0]
         if (_data.receive_data && _data.transfer_data) {
            for (let i = 0; i < _data.transfer_data.length; i++) {
               let myItem = {
                  'xAxixLabel': new Date(_data.transfer_data[i][0] * 1000).toString(),
                  // yAxisLabel:,
                  'Outgoing': (_data.transfer_data[i][1] / unitObject.DivideBy).toFixed(2),
                  'Incoming': (_data.receive_data[i][1] / unitObject.DivideBy).toFixed(2),
               }
               fData.push(myItem)
            }
            _fData.data = fData
            _fData.maxY = Math.max(...fData.map(d => {
               return Math.max(d.Outgoing, d.Incoming)
            }))
            _fData.label = [
               {
                  name: 'Outgoing',
                  strokeColor: '#FF0000'
               },
               {
                  name: 'Incoming',
                  strokeColor: '#0000FF'
               },
            ]
            return _fData
         }

      }
      else if (type === 6) {
         const _data = data[0]
         if (_data.running && _data.desired) {
            for (let i = 0; i < _data.running.length; i++) {
               let myItem = {
                  'xAxixLabel': new Date(_data.running[i][0] * 1000).toString(),
                  // yAxisLabel:,r
                  'Running': parseInt(_data.running[i][1]),
                  'Desired': parseInt(_data.desired[i][1]),
                  'Max Replica': parseInt(_data.hpa_insight_max_replica[i][1]),
                  'Min Replica': parseInt(_data.hpa_insight_min_replica[i][1]),
               }
               fData.push(myItem)
            }
            _fData.data = fData
            _fData.maxY = Math.max(...fData.map(d => {
               return Math.max(d.Running, d.Desired)
            }))
            _fData.label = [
               {
                  name: 'Running',
                  strokeColor: '#4ea27d'
               },
               {
                  name: 'Desired',
                  strokeColor: '#1d6afa'
               },
               {
                  name: 'Max Replica',
                  strokeColor: '#db3c69'
               },
               {
                  name: 'Min Replica',
                  strokeColor: '#eee631'
               },
            ]
            return _fData
         }

      }
   }
   return finalData;
}

export const formatSizeUnits = (data, type) => {
   let dividingObject = {};
   if (type === 2) {
      if (data >= 1073741824) { dividingObject.DivideBy = '1073741824'; dividingObject.Unit = ' GB' }
      else if (data >= 1048576) { dividingObject.DivideBy = '1048576'; dividingObject.Unit = ' MB' }
      else if (data >= 1024) { dividingObject.DivideBy = '1024'; dividingObject.Unit = ' KB' }
      else { dividingObject.DivideBy = '1'; dividingObject.Unit = ' Bytes' }
   }
   else if (type === 1) {
      if (data <= 1) { dividingObject.MultiplyBy = '1000'; dividingObject.Unit = ' m' }
      else { dividingObject.MultiplyBy = '1'; dividingObject.Unit = ' Core' }
   }
   return dividingObject;
}

//  export const  isAuthorized = (action, role) => {
//     switch(action){
//        case 'create':
//           if(role==="Admin" || role==="Owner" || role==="Write"){
//              return true
//           }
//           return false
//        case 'read':
//           if(role==="Admin" || role==="Owner" || role==="Write" || role==="Read"){
//              return true
//           }
//           return false
//        case 'update':
//           if(role==="Admin" || role==="Owner" || role==="Write"){
//              return true
//           }
//           return false
//        case 'delete':
//           if(role==="Admin" || role==="Owner"){
//              return true
//           }
//           return false
//        case 'build':
//           if(role==="Admin" || role==="Owner"){
//              return true
//           }
//           return false
//        case 'addUser':
//           if(role==="Admin" || role==="Owner"){
//              return true
//           }
//           return false
//        default:
//           return false
//     }
//  }\

export const flatten = (obj, prefix = '', res = {}) =>
   Object.entries(obj).reduce((r, [key, val]) => {
      const k = `${prefix}${key}`
      if (typeof val === 'object') {
         flatten(val, `${k}.`, r)
      } else {
         res[k] = val
      }
      return r
   }, res)

export const isAuthorizedOrg = (action, role) => {
   let authorized = false
   //2: Member; 1:Admin; 0:Owner
   if (role === 1 || role === 0) {
      authorized = true;
   }
   return authorized
}

export const isAuthorized = (action, role,
   //  source, state
) => {
   let authorized = false
   //  if(source && state){
   //     if(isStopped(source, state)){
   //        return false
   //     }
   //  }
   if (role === 'Admin' || role === 'Owner' || role === "Org Admin") {
      authorized = true
      return authorized
   }

   switch (action) {
      case 'create':
         if (role === 'Write') {
            authorized = true
         }
         break
      case 'read':
         if (role === 'Write' || role === 'Read') {
            authorized = true
         }
         break
      case 'update':
         if (role === 'Write') {
            authorized = true
         }
         break
 
      case 'build':
         if (role === 'Write') {
            authorized = true
         }
         break
      default:
         break
   }
   return authorized
}

export const isStopped = (source, state) => {
   if (source === 'environment' && state === 'Stopped') {
      return true
   }

}

export const getPieChartDataFormat = (data) => {
   const objectKeys = Object.keys(data);
   let finalData = [];
   objectKeys.map(function (key) {
      finalData.push({
         name: key,
         value: data[key]
      });
   });
   return finalData;
}

export const isSharedProject = (proj, user) => {
   return user.id !== proj.user_id
}

export const spaceConversion = (space) => {
   if (space < 1024) return `${space} MB`;
   else return `${(space / 1024).toFixed(2)} GB`;
};

export const getStatusColor = (state) => {
   let statusColor = ''
   switch (state) {
      case AppConstants.Status.Running:
         statusColor = '#4CAF50';
         break;
      case AppConstants.Status.Succeeded:
         statusColor = '#2196F3';
         break;
      case AppConstants.EnvironmentStatus.FetchingStatus:
         statusColor = '#2196F3';
         break;
      case AppConstants.Status.Failed:
         statusColor = '#FF5252';
         break;
      case AppConstants.Status.Stopped:
         statusColor = '#757575';
         break;
      case AppConstants.PackageStatus.NotInstalled:
         statusColor = '#FF9800';
         break;
      case AppConstants.PackageStatus.Installed:
         statusColor = '#4CAF50';
         break;
      default:
         statusColor = "#FF9800"
   }
   return statusColor
}

export const getStatusText = (state) => {
   let statusTxt = ''
   switch (state) {
      case AppConstants.Status.Running:
         statusTxt = "Your site is live on";
         break;
      case AppConstants.Status.Pending:
         statusTxt = "Your site is building";
         break;
      case AppConstants.Status.Succeeded:
         statusTxt = "Your site is live on";
         break;
      case AppConstants.Status.Failed:
         statusTxt = "Your site build is failed";
         break;
      case AppConstants.Status.Stopped:
         statusTxt = "Your environment is currently stopped.";
         break;
      case AppConstants.Status.Starting:
         statusTxt = "Your site is building";
         break;
      case AppConstants.Status.Stopping:
         statusTxt = "Please wait your environment will be stopped soon";
         break;
      case AppConstants.EnvironmentStatus.NotReady:
         statusTxt = "Please wait your environment will be live soon";
         break;
      default:
         statusTxt = "Environment " + state + " Please wait...";
         break;
   }
   return statusTxt
}

export const validateRegex = (value, regex) => {
   return new RegExp(regex).test(value)
}

export const findByTestAttr = (component, attr) => {
   const wrapper = component.find(`[data-test='${attr}']`)
   return wrapper;

}

export const getEmptyRowObject = () => {
   return {
      id: -1,
      key: "",
      value: "",
   };
}

export const getElapsedTimebySeconds = (seconds) => {
   let finalString = "";
   if (seconds) {
      if (seconds >= 60) {
         const p1 = seconds % 60;
         let p2 = parseInt(seconds / 60);
         const p3 = p2 % 60;
         p2 = parseInt(p2 / 60);
         if (p2 > 0) finalString = p2 + "h";
         if (p3 > 0) finalString += (finalString !== "" ? (" " + p3) : p3) + "m";
         if (p1 > 0) finalString += (finalString !== "" ? (" " + p1) : p1) + "s";
      }
      else
         finalString = seconds + "s"
   }
   return finalString;
}

export const getNotificationNavigationId = (noti) => {
   if (noti.project_id && noti.scope === "project") {
      return noti.project_id
   } else if (noti.application_id && noti.scope === "application") {
      return noti.application_id
   } else if (noti.environment_id && noti.scope === "environment") {
      return noti.environment_id
   } else if (noti.scope === "support") {
      return noti.scope_id
   }
}

export const parseApiResponse = (message) => {
   if (!message) return { field: null, message: null };
   let fieldAndDesc, field, desc;
   fieldAndDesc = message.split("error: ")[1];
   if (fieldAndDesc) {
      desc = fieldAndDesc.split(" desc = ")[1]
      if (desc) {
         field = fieldAndDesc.split(" desc = ")[0];
         field = field.split("field = ")[1]
      }
   }
   if (field && desc) return { field, message: desc };
   else return { field: null, message }
}

export const paymentSummaryDateFormat = (startDate, endDate) => {
   let fromMonth = shortMonths[new Date(startDate).getUTCMonth()];
   let toMonth = shortMonths[new Date(endDate).getUTCMonth()];
   let fromDate = new Date(startDate).getUTCDate();
   let toDate = new Date(endDate).getUTCDate();
   let year = new Date(endDate).getUTCFullYear();
   return `${fromMonth} ${fromDate} - ${toMonth} ${toDate}, ${year}`;
}

export const paymentSummaryDateFormat2 = (startDate, endDate) => {
   let month = months[new Date(startDate).getUTCMonth()];
   let year = new Date(endDate).getUTCFullYear();
   return `${month} ${year}`;
}

export const getDate = (date) => {
   let month = months[new Date(date).getMonth()];
   let day = new Date(date).getDate();
   let year = new Date(date).getFullYear();
   return `${month} ${day}, ${year}`;
}

export const validateInputField = (value, type = "default") => {
   let regex = AppConstants.validationRegex[type];
   if (!regex.test(value.trim())) {
      return { error: true, message: `ValidationRegex.${type}` };
   }
   return { error: false, message: '' };
};

export const openWindowWithPost = (url, data) => {
   var win;
   var winName = 'MyWindow';
   var winURL = url;
   var windowoption = `resizable=yes,height=600,width=800,top=${(window.innerHeight / 2) - (600 / 2)},left=${(window.innerWidth / 2) - (800 / 2)},location=0,menubar=0,scrollbars=1`;

   var form = document.createElement("form");
   form.target = "_blank";
   form.method = "POST";
   form.action = winURL;
   form.style.display = "none";

   for (var key in data) {
      var input = document.createElement("input");
      input.type = "hidden";
      input.name = key;
      input.value = data[key];
      form.appendChild(input);
   }

   document.body.appendChild(form);

   win = window.open('', winName, windowoption);
   form.target = winName;

   if (win) {
      form.submit();
   }
   
   document.body.removeChild(form);
   return win;
}

export const covertResourceUsage = (total, used) => {
   if (total < 1024) {
     return { total, used };
   }
 
   return { total: (total / 1024).toFixed(2), used: used && (used / 1024).toFixed(2) };
};

export const transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export const setHttpInUrl = link => {
   if (link.search(/^http[s]?:\/\//) === -1) {
     link = "http://" + link;
   }
   
   return link;
 };

export const getPodStatusColor = status => {
   let color;

   switch (status) {
     case AppConstants.PodStatus.Running:
       color = "#4CAF50";
       break;
     case AppConstants.PodStatus.Pending:
       color = "#FF9800";
       break;
     case AppConstants.PodStatus.Succeeded:
       color = "#2196F3";
       break;
     case AppConstants.PodStatus.Failed:
       color = "#FF5252";
       break;
     case AppConstants.PodStatus.Unknown:
       color = "#757575";
       break;
     default:
       color = "#757575";
       break;
   }

   return color;
 };
export const is_hex_light = (color) => {
   const hex = color.replace('#', '');
   const c_r = parseInt(hex.substr(0, 2), 16);
   const c_g = parseInt(hex.substr(2, 2), 16);
   const c_b = parseInt(hex.substr(4, 2), 16);
   const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
   return brightness > 155;
}

export const removeServiceAccountFromMount = mounts => {
   return mounts.filter(
     mount => mount.mountPath !== "/var/run/secrets/kubernetes.io/serviceaccount"
   );
 };

export const getNestedValue = (obj, key) => {
    return key.split(".").reduce((o, _key) => o && o[_key] !== 'undefined' ? o[_key] : undefined, obj);
}

export const getAppNameChar = (name) =>{
      const projectName = name && name.split(" ");
      return(
         projectName.length>1
         ?projectName.slice(0,2).join(' ').match(/\b(\w)/g).join('').toUpperCase()
         :name.substring(0,2).toUpperCase()
      )
   }

  export const isEmail=(emailStr)=> {

      return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(emailStr);
    }

export const getErrorInfo = (error) => {
   let errorRecord = {
      "msg": error.message
   };
   if (error && error.code) {
      const code = error.code
      switch (true) {
         case (code === 1001):
            errorRecord = {
               ...errorRecord,
               "subTab": "",
               "tabName": "",
               "settingsName": "External Secret settings",
               "isNavigatable": false
            }
            break;
         case (code >= 1002 && code <= 1005):
            errorRecord = {
               ...errorRecord,
               "subTab": "externalsecret",
               "tabName": "#settings",
               "settingsName": "External Secret settings",
               "isNavigatable": true
            }
            break;
         case (code >= 1101 && code <= 1109):
            errorRecord = {
               ...errorRecord,
               "subTab": "",
               "tabName": "#cicd",
               "settingsName": "CI settings",
               "isNavigatable": true
            }
            break;
         default:
            break
      }
   }
   return errorRecord;
};

export const getAlertDate = (date) => {
   if(date.endsWith("s")){
      return getElapsedTimebySeconds(date.slice(0, -1));
    } else {
      return date
    }
}

export const getSecuritySeverity = status => {
   let color;

   switch (status) {
     case "HIGH":
       color = "#ff9800";
       break;
     case "MEDIUM":
       color = "#CBCB1E";
       
       break;
     case "CRITICAL":
       color = "#f44336";
       break;
     case "LOW":
       color = "#2196f3";
       break;
     default:
       color = "#808080";
       break;
   }

   return color;
 };

 export const gitDiffBtnScanDate = (date) => {
   let finalString = '';
   var msec = date * 1000;
   var sec = (msec % 60000) / 1000;
   var mins = Math.floor(msec / 60000);

   if (mins > 0) finalString = mins + 'm';
   if (sec > 0) finalString += ' ' + Math.round(sec) + 's';

   return finalString ? finalString.trim() : '0s';
 }