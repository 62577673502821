import { combineReducers } from 'redux';
import AuthReducer from '../../pages/login/redux/reducer';
// import { reducer as TemplateReducer } from '../../pages/app/createapp/redux';
import { RegisterReducer } from '../../pages/registration';
import { CreateProjectReducer } from '../../pages/project/createproject';
import { ProjectListReducer } from '../../pages/project/projectlist';
import { reducer as ProjectReducer } from '../../pages/project/redux';
import { reducer as EnvironmentReducer } from '../../pages/environment/redux'
import { reducer as HelmEnvironmentReducer } from '../../pages/helmenvironment/redux'
import { reducer as CICDReducer } from '../../pages/environment/cicdredux'
import { reducer as AppsReducer } from '../../pages/app/redux';
import { reducer as HeaderReducer } from '../../pages/header/redux'
import ProfileTabReducer from '../../pages/account/profiletab/redux/reducer';
import { CreateAppReducer } from '../../pages/app/createapp';
import { AddUserPopupReducer } from '../../components/adduserpopup';
import { reducer as CronJobReducer } from '../../pages/cronjob/redux';
import { reducer as InitContainerReducer } from '../../pages/initcontainer/redux';
import { reducer as OrganizationReducer } from '../../pages/organization/redux'
import { reducer as SubscriptionReducer } from '../../pages/subscription/redux'
import { reducer as ResourceReducer } from '../../pages/resource/redux'
import { reducer as ClusterReducer } from '../../pages/cluster/redux'
import { reducer as PluginReducer } from '../../pages/plugin/redux'
import { reducer as CommonReducer } from '../../pages/common/redux'
import { reducer as BackupReducer } from '../../pages/backup/redux'
import { reducer as NotificationReducer } from '../../pages/notification/redux'
import { reducer as SupportReducer } from '../../pages/support/redux'
import { LOGOUT_SUCCESS } from '../../pages/login/redux/actions'
import { reducer as PaymentReducer } from '../../pages/account/billingtab/redux'
import { reducer as OperatorReducer} from "../../pages/operators/redux"
import { reducer as PodsReducer } from "../../pages/pods/redux"
import { reducer as AlertReducer } from "../../pages/environment/environmentinfo/alertmanager/redux"

const rootReducer = combineReducers({
    AuthReducer,
    // TemplateReducer,
    CreateAppReducer,
    RegisterReducer,
    ProjectReducer,
    CreateProjectReducer,
    ProjectListReducer,
    EnvironmentReducer,
    CICDReducer,
    AppsReducer,
    ProfileTabReducer,
    AddUserPopupReducer,
    HeaderReducer,
    CronJobReducer,
    InitContainerReducer,
    OrganizationReducer,
    SubscriptionReducer,
    ResourceReducer,
    ClusterReducer,
    PluginReducer,
    CommonReducer,
    NotificationReducer,
    SupportReducer,
    BackupReducer,
    PaymentReducer,
    HelmEnvironmentReducer,
    OperatorReducer,
    PodsReducer,
    AlertReducer,
})

export default (state, action) =>
  rootReducer(action.type === LOGOUT_SUCCESS ? undefined : state, action);