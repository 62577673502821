import { Button, Grid, Typography, ButtonBase, Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-mde/lib/styles/css/react-mde-all.css";
import { connect } from "react-redux";
import ConfirmActionPopup from "../../components/confirmactionpopup/ConfirmActionPopup";
import ImagePopupModal from "../../components/imagepopupmodal/ImagePopupModal";
import BackdropLoader from "../../components/loader/BackdropLoader";
import MarkdownField from "../../components/markdownfield/MarkdownField";
import MultiFileUpload from "../../components/multifileupload";
import { getDateInStandardFormat } from "../../helpers/utils";
import { getTicketDetail, replyIssue, resetTicketDetail, updateTicket } from "./redux/actions";
import { navigationCheck } from "../notification/redux/actions";
import Reply from "./Reply";
import paths from "../../constants/paths";
import Toast from "../../components/toast/Toast";
import { updateBreadcrumb } from "../project/redux/actions";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useTranslation } from 'react-i18next';

/* istanbul ignore next */
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    // color: theme.palette?.text?.secondary,
    marginTop: theme.spacing(2),
  },
  styleGrid: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  labelStyle: {
    color: "black",
    fontWeight: "bolder",
    textAlign: "start",
    marginBottom: theme.spacing(2),
  },
  customButtonStyle: {
    marginTop: theme.spacing(2),
    height: 40,
    width: "6rem",
    minWidth: "200px",
    maxWidth: "100%",
  },
  styleParagraph: {
    // textAlign: 'center',
    fontSize: "16px",
    color: "black",
    fontWeight: "bolder",
  },
  styleGridText: {
    color: "#0057fa",
    fontWeight: "bolder",
    fontSize: "14px",
  },
  styleGridValue: {
    color: "gray",
    fontWeight: "bolder",
    fontSize: "14px",
  },
  headerText: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  divStyling: {
    // padding: "2%",
  },
  styledGrid: {
    fontSize: "16px",
    color: "black",
    fontWeight: "bolder",
    backgroundColor: "gray",
    margin: "0",
    padding: "0",
  },
  paperUser: {
    color: theme.palette?.text?.secondary,
    marginTop: theme.spacing(2),
  },
  style1: {
    fontSize: "16px",
    color: "black",
    fontWeight: "bolder",
    backgroundColor: theme.palette?.grey[500],
    padding: "2%",
  },
  agentGrid: {
    padding: "2%",
  },
  fileStyle: {
    padding: "2%",
  },
  spanStyle: {
    color: "#0057fa",
  },
  listContainer: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  fixedPosition: {
    marginTop: theme.spacing(2),
    position: "sticky",
    zIndex: theme.zIndex.appBar,
    padding: "10px 0px 10px 10px",
    top: "65px",
    background: "#f6f8fa",
    marginBottom: theme.spacing(2),
    // boxShadow: "0px 0px 0px rgba(63,63,68,0.15), 0px -1px 3px rgba(63,63,68,0.15)",
  },
  fixedPositionRight: {
    marginTop: theme.spacing(2),
    position: "sticky",
    zIndex: theme.zIndex.appBar,
    top: "70px",
    background: "#f6f8fa",
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  fixedHeight: {
    paddingRight: theme.spacing(1) + "px !important",
    marginLeft: theme.spacing(1),
  },
  rightGrid: {
    zIndex: -1,
  },
}));

export function TicketDetail(props) {
  const [t] = useTranslation()
  const [reply, setReply] = React.useState("");
  const [reset, setReset] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [openAttachment, setOpenAttachment] = useState(false);
  const [updateTicketDetails, setUpdateTicketDetails] = useState(false);

  const [file, setFile] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    // if (props.ticket) {
    // props.getTicketDetail(1);
    let ticket_id;
    if (props?.match?.params?.id) {
      ticket_id = props.match.params.id;
    } else {
      ticket_id = props.history?.location?.state?.id;
    }
    const breadcrumbData = [
      {
        name: "Tickets",
        path: paths.SUPPORT,
      },
      {
        name: "Ticket#" + ticket_id,
        path: paths.TICKET_DETAIL.replace(":id", ticket_id),
      },
    ];
    props.updateBreadcrumb(breadcrumbData);
    props.getTicketDetail(ticket_id);
    // }
    return () => {
      props.resetTicketDetail();
    };
  }, []);

  // const backToListHandler = () => {
  //   props.handleBack()
  // }

  const setFiles = (files) => {
    setFile(files);
    if (files.length > 0) {
      setReset(false);
    }
  };

  const handleCloseUpdate = () => {
    setUpdateTicketDetails(false);
  };

  const closeCallback = () => {
    const payload = {
      status: "Closed",
      // note: note,
    };
    props.updateTicket(
      props.ticketDetail?.ticketDetails?.id ?? props.ticketDetail?.id,
      payload
    );
  };

  const clearDatas = () => {
    setFile([]);
    setReply("");
  };

  const replyHandler = (callback) => {
    let payload = {
      reply: reply,
      ticketId: props.ticketDetail?.ticketDetails?.id ?? props.ticketDetail?.id,
      userId: props.currentUser?.id,
    };
    if (file?.length > 0) {
      const uploadFiles = [];
      file.forEach((f) => {
        const _f = {
          fileName: f.file?.name,
          fileType: f.file?.type,
          fileValue: f.url,
        };
        uploadFiles.push(_f);
      });
      payload.file = uploadFiles;
    }
    clearDatas();
    setReset(true);
    props.replyIssue(payload, callback);
  };

  const closeTicketHandler = () => {
    if (props.ticketDetail) {
      if (reply.trim()?.length) {
        replyHandler(closeCallback);
      } else if (file?.length) {
        Toast.error("To attach files, you must provide some description");
        handleCloseUpdate();
        return;
      } else {
        closeCallback();
      }
    } else {
      Toast.error("Cannot update ticket");
    }
    handleCloseUpdate();
  };

  const reOpenCallback = () => {
    const payload = {
      status: "Open",
    };
    props.updateTicket(
      props.ticketDetail?.ticketDetails?.id ?? props.ticketDetail?.id,
      payload
    );
  };

  const reopenTicketHandler = () => {
    if (props.ticketDetail) {
      if (reply.trim()?.length) {
        replyHandler(reOpenCallback);
      } else if (file?.length) {
        Toast.error("To attach files, you must provide some description");
        handleCloseUpdate();
        return;
      } else {
        reOpenCallback();
      }
    } else {
      Toast.error("Cannot update ticket");
    }
    handleCloseUpdate();
  };

  const isReplyValid = () => {
    let val = false;
    if (props.ticketDetail?.status !== "Closed" && reply?.trim()?.length > 0) {
      val = true;
    }
    return val;
  };

  const handleImageOpen = (image) => {
    setOpenAttachment(!openAttachment);
    setCurrentImage(image);
  };

  const getStatus = (status) => {
    switch (status) {
      case "Open":
        return "activestatus";
      case "Closed":
        return "inactivestatus";
      default:
        return "";
    }
  };

  const navToTicketList = () => {
    props.history.push({
      pathname: paths.SUPPORT,
    });
  };

  /* istanbul ignore next */
  const existanceCallBack = (data, module, id) => {
    if (data) {
      let path = "";
      switch (module) {
        case "project":
          path = paths.PROJECTINFO.replace(":id", id);
          break;
        case "application":
          path = paths.APPDETAILS.replace(":appId", id);
          break;
        case "environment":
          path = paths.ENVIRONMENTINFO.replace(":eId", id);
          break;
        default:
          path = "";
      }
      if (props.history && path) {
        props.history.push(path);
      }
    } else {
      Toast.error(
        "The " + module + " you are looking for, doesn't exist anymore"
      );
    }
  };

  const navigation = (module, id) => {
    props.navigationCheck(module, id, existanceCallBack);
  };

  const handleUpdateTicket = () => {
    setUpdateTicketDetails(true);
  };

  const cancelReply = () => {
    setReset(true);
    setReply("");
  };

  return (
    <div className={classes.root} data-test="ticket-container">
      <div data-test="back-button">
        <ButtonBase
          onClick={() => navToTicketList()}
          style={{ color: "#357dfd" }}
          data-test="backButton"
        >
          <ArrowBackIcon fontSize="small" />{" "}
          <span className="jobNav">{t('Support.CreateTicketForm.ticketList')}</span>
        </ButtonBase>
      </div>
      <Grid
        // xs={12}
        // sm={7}
        className={classes.fixedPosition}
        data-test="ticket-title"
      >
        <Typography color="textPrimary" variant="h4">
          <strong>
            {" "}
            {props.ticketDetail?.ticketDetails?.title ??
              props.ticketDetail?.title ??
              props.ticket?.title ?? <Skeleton width={250} height={25} />}
          </strong>{" "}
          #{props.ticketDetail?.ticketDetails?.id ?? props.ticketDetail?.id}{" "}
        </Typography>
      </Grid>

      <Grid container spacing={1}>
        <Grid
          className={classes.fixedHeight}
          item
          xs={12}
          sm={7}
          data-test="left-detail-container"
        >
          {/* ticket description start */}
          {(props.ticketDetail?.ticketDetails?.description ||
            props.ticketDetail?.files?.length > 0) && (
            <Card className={classes.paper}>
              {props.ticketDetail?.ticketDetails?.description && (
                <Grid container spacing={1} className={classes.styleGrid}>
                  <div data-test="ticket-description" style={{ width: "100%" }}>
                    <strong>{t('Support.TicketDetail.description')}</strong>
                    <hr />
                    <MarkdownField
                      value={props.ticketDetail?.ticketDetails?.description}
                      readOnly={true}
                    />
                  </div>
                </Grid>
              )}
              {props.ticketDetail?.files?.length > 0 && (
                <div data-test="files-container">
                  <Grid container spacing={3} className={classes.divStyling}>
                    <Grid className={classes.styleParagraph} item md={12}>
                      <Typography color="textPrimary" variant="h6">
                      {t('Support.TicketDetail.attachments')}
                      </Typography>
                    </Grid>
                  </Grid>
                  <ImagePopupModal
                    images={props.ticketDetail?.files}
                    readOnly={true}
                  />
                </div>
              )}
            </Card>
          )}
          {/* ticket description end */}

          {/* Agent Start */}

          {props.ticketDetail?.conversation?.length > 0 && (
            <div data-test="conversation-container">
              {props.ticketDetail?.conversation?.map((convo, ind) => (
                <Reply
                  convo={convo}
                  key={ind}
                  handleImageOpen={handleImageOpen}
                />
              ))}
            </div>
          )}

          {/* Reply Ticket Start */}
          {/* {props.ticketDetail?.ticketDetails?.status === "Open" && ( */}
          <Card className={classes.paper} data-test="reply-container">
            <Typography
              style={{ paddingBottom: 10 }}
              variant="h5"
              textColor="secondary"
            >
              {t('Support.TicketDetail.reply')}  (*)
            </Typography>
            <MarkdownField
              value={reply}
              onChange={setReply}
              title={t('Support.TicketDetail.reply')}
              data-test="reply-field"
            />

            <MultiFileUpload setFile={setFiles} reset={reset} data-test="files-field" />

            <Grid container spacing={1} justify="flex-end">
              {!(reply.trim()?.length === 0 && file?.length === 0) && (
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.customButtonStyle}
                    onClick={() => cancelReply()}
                    disabled={Boolean(
                      reply.trim()?.length === 0 && file?.length === 0
                    )}
                    data-test="cancel-button"
                  >
                    {" "}
                    {t('Support.TicketDetail.cancel')}{" "}
                  </Button>
                </Grid>
              )}
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.customButtonStyle}
                  onClick={() => handleUpdateTicket()}
                  size="small"
                  data-test="update-button"
                >
                  {props.ticketDetail?.ticketDetails?.status === "Closed"
                    ? "Re-Open Ticket"
                    : "Close Ticket"}
                </Button>
              </Grid>
              {props.ticketDetail?.ticketDetails?.status === "Open" && (
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.customButtonStyle}
                    onClick={() => replyHandler()}
                    disabled={!isReplyValid()}
                    data-test="reply-button"
                  >
                    {" "}
                    {t('Support.TicketDetail.replyLabel')}{" "}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Card>
          {/* )} */}
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          data-test="right-detail-container"
          className={classes.rightGrid}
        >
          <div className={classes.fixedPositionRight}>
            <Card className={classes.paper}>
              <Grid spacing={1} container>
                <Grid
                  className={classes.styleParagraph}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <p> {t('Support.TicketDetail.ticketDetails')} </p>
                </Grid>
              </Grid>
              {props.ticketDetail?.ticketDetails?.date && (
                <Grid spacing={1} container>
                  <Grid
                    className={classes.styleGridText}
                    item
                    xs={12}
                    sm={5}
                    md={5}
                  >
                    {t('Support.TicketDetail.issueDate')}
                  </Grid>
                  <Grid
                    className={classes.styleGridValue}
                    item
                    xs={12}
                    sm={7}
                    md={7}
                  >
                    {getDateInStandardFormat(
                      props.ticketDetail?.ticketDetails?.date * 1000,
                      true
                    )}
                  </Grid>
                </Grid>
              )}

              <Grid spacing={1} container>
                <Grid
                  className={classes.styleGridText}
                  item
                  xs={12}
                  sm={5}
                  md={5}
                >
                  {t('Support.TicketDetail.lastResponse')}
                </Grid>
                <Grid
                  className={classes.styleGridValue}
                  item
                  xs={12}
                  sm={7}
                  md={7}
                >
                  <div data-test="lastResponse-container">
                    {props.ticketDetail?.conversation?.length > 0
                      ? getDateInStandardFormat(
                          props.ticketDetail?.conversation[
                            props.ticketDetail?.conversation?.length - 1
                          ]?.date * 1000,
                          true
                        )
                      : "N/A"}
                  </div>
                </Grid>
              </Grid>

              <Grid spacing={1} container>
                <Grid
                  className={classes.styleGridText}
                  item
                  xs={12}
                  sm={5}
                  md={5}
                >
                  {t('Support.CreateTicketForm.priority')}
                </Grid>
                <Grid
                  className={classes.styleGridValue}
                  item
                  xs={12}
                  sm={7}
                  md={7}
                >
                  {props.ticketDetail?.ticketDetails?.priority ??
                    props.ticketDetail?.priority}
                </Grid>
              </Grid>

              <Grid spacing={1} container>
                <Grid
                  className={classes.styleGridText}
                  item
                  xs={12}
                  sm={5}
                  md={5}
                >
                  {t('Support.SupportPage.category')}
                </Grid>
                <Grid
                  className={classes.styleGridValue}
                  item
                  xs={12}
                  sm={7}
                  md={7}
                >
                  {props.ticketDetail?.ticketDetails?.category ??
                    props.ticketDetail?.category}
                </Grid>
              </Grid>

              <Grid spacing={1} container>
                <Grid
                  className={classes.styleGridText}
                  item
                  xs={12}
                  sm={5}
                  md={5}
                >
                  {t('Support.SupportPage.status')}
                </Grid>
                <Grid
                  className={classes.styleGridValue}
                  item
                  xs={12}
                  sm={7}
                  md={7}
                >
                  <span
                    className={getStatus(
                      props.ticketDetail?.ticketDetails?.status
                    )}
                  >
                  </span>
                  {props.ticketDetail?.ticketDetails?.status ??
                    props.ticketDetail?.status}
                </Grid>
              </Grid>

              {props.ticketDetail?.ticketDetails?.project && (
                <Grid spacing={1} container data-test="project-container">
                  <Grid
                    className={classes.styleGridText}
                    item
                    xs={12}
                    sm={5}
                    md={5}
                  >
                    {t('Support.TicketDetail.project')}
                  </Grid>
                  <Grid
                    className={classes.styleGridValue}
                    item
                    xs={12}
                    sm={7}
                    md={7}
                  >
                    <ButtonBase
                      onClick={() =>
                        navigation(
                          "project",
                          props.ticketDetail?.ticketDetails?.project?.id
                        )
                      }
                      data-test="project-navigation-button"
                    >
                      <Typography color="primary" variant="h5">
                        {props.ticketDetail?.ticketDetails?.project?.name}
                      </Typography>
                    </ButtonBase>
                  </Grid>
                </Grid>
              )}

              {props.ticketDetail?.ticketDetails?.application && (
                <Grid spacing={1} container data-test="application-container">
                  <Grid
                    className={classes.styleGridText}
                    item
                    xs={12}
                    sm={5}
                    md={5}
                  >
                    {t('Support.TicketDetail.application')}
                  </Grid>
                  <Grid
                    className={classes.styleGridValue}
                    item
                    xs={12}
                    sm={7}
                    md={7}
                  >
                    <ButtonBase
                      onClick={() =>
                        navigation(
                          "application",
                          props.ticketDetail?.ticketDetails?.application?.id
                        )
                      }
                      data-test="application-navigation-button"
                    >
                      <Typography color="primary" variant="h5">
                        {props.ticketDetail?.ticketDetails?.application?.name}
                      </Typography>
                    </ButtonBase>
                  </Grid>
                </Grid>
              )}

              {props.ticketDetail?.ticketDetails?.environment && (
                <Grid spacing={1} container data-test="environment-container">
                  <Grid
                    className={classes.styleGridText}
                    item
                    xs={12}
                    sm={5}
                    md={5}
                  >
                    {t('Support.TicketDetail.environment')}
                  </Grid>
                  <Grid
                    className={classes.styleGridValue}
                    item
                    xs={12}
                    sm={7}
                    md={7}
                  >
                    <ButtonBase
                      onClick={() =>
                        navigation(
                          "environment",
                          props.ticketDetail?.ticketDetails?.environment?.id
                        )
                      }
                      data-test="environment-navigation-button"
                    >
                      <Typography color="primary" variant="h5">
                        {props.ticketDetail?.ticketDetails?.environment?.name}
                      </Typography>
                    </ButtonBase>
                  </Grid>
                </Grid>
              )}
            </Card>

            {/* <Grid container justify="center">
              <Button
                variant="contained"
                color="primary"
                className={classes.customButtonStyle}
                onClick={() => handleUpdateTicket()}
                size="small"
                data-test="update-button"
              >
                {props.ticketDetail?.ticketDetails?.status === "Closed"
                  ? "Re-Open Ticket"
                  : "Close Ticket"}
              </Button>
            </Grid> */}
            {/* </Card> */}
            {/* ticket closed end */}
          </div>
        </Grid>
      </Grid>
      {props.fetchingTicketDetail && (
        <BackdropLoader message={t('Support.TicketDetail.fetchingDetail')} data-test="fetchingTicketDetail-loader" />
      )}
      {props.replyingIssue && <BackdropLoader message={t('Support.TicketDetail.replyingIssue')} data-test="replyingIssue-loader" />}
      {props.updatingTicket && <BackdropLoader message={t('Support.TicketDetail.updatingIssue')}  data-test="updatingTicket-loader" />}
      {currentImage && openAttachment && (
        <ImagePopupModal
          open={openAttachment}
          image={currentImage?.fileValue}
          alt={currentImage?.fileName}
          onClick={handleImageOpen}
          data-test="reply-image-popup"
        />
      )}

      {updateTicketDetails && (
        <ConfirmActionPopup
          open={updateTicketDetails}
          message={`Are you sure you want to ${
            props.ticketDetail?.ticketDetails?.status === "Closed"
              ? "Re-Open"
              : "Close"
          } the ticket?`}
          handleAgree={
            props.ticketDetail?.ticketDetails?.status === "Closed"
              ? reopenTicketHandler
              : closeTicketHandler
          }
          handleDisAgree={handleCloseUpdate}
          yesText={t('Projects.VariablesTab.yesText')}
        />
      )}
    </div>
  );
}

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  currentUser: state.AuthReducer.user,
  fetchingTicketDetail: state.SupportReducer.fetchingTicketDetail,
  ticketDetail: state.SupportReducer.ticketDetail,
  replyingIssue: state.SupportReducer.replyingIssue,
  updatingTicket: state.SupportReducer.updatingTicket,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch) => {
  return {
    getTicketDetail: (id) => dispatch(getTicketDetail(id)),
    replyIssue: (payload, callback) => dispatch(replyIssue(payload, callback)),
    updateTicket: (id, payload) => dispatch(updateTicket(id, payload)),
    updateBreadcrumb: (payload) => dispatch(updateBreadcrumb(payload)),
    navigationCheck: (module, id, callBack) =>
      dispatch(navigationCheck(module, id, callBack)),
    resetTicketDetail: (id) => dispatch(resetTicketDetail(id)),
  };
};

export default connect(mapStateToProps, mapDispatchtoProps)(TicketDetail);
