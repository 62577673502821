import {
    IconButton,
    Menu,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Chip,
    Button,
    Grid,
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, { useEffect, useState } from "react";
import { connect ,ConnectedProps} from "react-redux";
import ConfirmActionPopup from '../../../components/confirmactionpopup/ConfirmActionPopup';
import ConfirmDeletePopup from '../../../components/confirmdeletepopup/ConfirmDeletePopup';
//import CustomButton from "../../../components/custombutton/CustomButton";
import BackdropLoader from "../../../components/loader/BackdropLoader";
import { getDateInStandardFormat, isAuthorized, getElapsedTimebySeconds } from '../../../helpers/utils';
import { createBackup, deleteBackup, fetchBackupList, preserveBackup, restoreBackup } from "../redux/actions";
import { BackupStatusIndicator } from '../../../components/statusindicator/statusindicator';
import { AppConstants } from '../../../constants/appconstants';
import {useTranslation} from 'react-i18next';
import { Dispatch } from "redux";
import { BackupModel } from "../../../models/Environment.model";
import CreateSnapShotPopup from "../../../components/CreateSnapshotPopup/CreateSnapshotPopup";

/* istanbul ignore next */
const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    infoPaper: {
        margin: "0px 10px"
    },
    widthHelper: {
        minWidth : '80px !important'
    }
});

interface BackupMenuProps {
    backup: BackupModel;
    disabled: boolean;
    handleRestoreSnapshot: (backup: BackupModel) => void;
    handlePreserveSnapshot: (backup: BackupModel) => void;
    handleDeleteSnapshot: (backup: BackupModel) => void;
}
export const BackupMenu = (props:BackupMenuProps) => {
    const [anchorEl, setAnchorEl] = useState<Element | ((element: Element) => Element) | null>(null);
    const [t] = useTranslation();

    const handleBackupMenu = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setAnchorEl(e.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleRestoreSnapshot = () => {
        handleMenuClose();
        props.handleRestoreSnapshot(props.backup)
    }
    
    const handlePreserveSnapshot = () => {
        handleMenuClose();
        props.handlePreserveSnapshot(props.backup)
    }
    
    const handleDeleteSnapshot = () => {
        handleMenuClose();
        props.handleDeleteSnapshot(props.backup)
    }

    return (
        <>
            {
                props.backup.status !== AppConstants.BackupStatus.Deleting &&
                <>
                    <IconButton data-test="icon-btn" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleBackupMenu(e)}>
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => handleMenuClose()}
                        getContentAnchorEl={null}
                        disableScrollLock={true}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                        data-test="menu-container"
                    >
                        {
                            props.backup.status === AppConstants.BackupStatus.Completed &&
                            <>
                                <MenuItem data-test="restore-menu" onClick={() => handleRestoreSnapshot()} disabled={props.disabled}> {t('Backup.SnapshotsTab.restore') } </MenuItem>
                                <MenuItem data-test="preserve-menu" onClick={() => handlePreserveSnapshot()}>
                                    { props.backup.preserved ?  "UnPreserve" : "Preserve" }
                                </MenuItem>
                            </>
                        }
                        {
                            !props.backup.preserved && 
                            <MenuItem data-test="delete-menu" onClick={() => handleDeleteSnapshot()}> {t('Backup.SnapshotsTab.delete') }</MenuItem>
                        }
                    </Menu>
                </>
            }
        </>                    
    )
}
interface Props extends PropsFromRedux{
    isHelmEnv:boolean;
    changeTab:()=>void;
    }
export const SnapshotsTab = (props:Props) => {
    const environmentDetails = props.isHelmEnv ? props.helmEnvironmentDetails :  props.environmentDetails
    const envRole = props.isHelmEnv ? {name: "Admin"} :  props.envRole
    const environmentState = props.isHelmEnv ? props.helmEnvironmentState :  props.environmentState
    const classes = useStyles();
    const [createSnapConfirmPopup, setCreateSnapConfirmPopup] = useState(false);
    const [preserveConfirmPopup, setPreserveConfirmPopup] = useState(false);
    const [restoreConfirmPopup, setRestoreConfirmPopup] = useState(false);
    const [deleteConfirmPopup, setDeleteConfirmPopup] = useState(false);
    const [currentBackup, setCurrentBackup] = useState<BackupModel|null>(null);
    const [isRestoring, setIsRestoring] = useState(false);
    const [t] = useTranslation();
    // useEffect(() => {
    //     return () => {};
    // }, []);

    useEffect(() => {
        if(isRestoring && !props.restoringSnapshot)
        {
            setIsRestoring(false);
            props.changeTab();
        }
    }, [props.restoringSnapshot]);

    useEffect(() => {
       // console.log('env details',environmentDetails)
        if(environmentDetails && environmentDetails.id > 0)
        {
            props.fetchBackupList(environmentDetails.id, props.isHelmEnv);
        }
    }, [environmentDetails]);

    //create snapshot section start
    const handleCreateSnapshot = () => {
        setCreateSnapConfirmPopup(true);
        console.log("create snapshot");
    }
    
    const handleCreateSnapDisAgreeHandler = () => {
        setCreateSnapConfirmPopup(false);
    }

    const handleCreateSnapAgreeHandler = (remarks:string) => {
        props.createBackup(environmentDetails.id, { name : environmentDetails.name,remarks:remarks }, props.isHelmEnv)
        handleCreateSnapDisAgreeHandler();
    }
    
    //create snapshot section end

    //preserve snapshot section start
    /* istanbul ignore next */
    const handlePreserveSnapshot = (backup: BackupModel) => {
        setCurrentBackup(backup);
        setPreserveConfirmPopup(true);
    }
    
    const handlePreserveSnapDisAgreeHandler = () => {
        setCurrentBackup(null);
        setPreserveConfirmPopup(false);
    }

    const handlePreserveSnapAgreeHandler = () => {
        if(!currentBackup){
            return;
        }
        const jsonBody = { "preserved": !currentBackup?.preserved }
        props.preserveBackup(environmentDetails.id, currentBackup?.id, jsonBody, props.isHelmEnv)
        handlePreserveSnapDisAgreeHandler();
    }
    
    //preserve snapshot section end

    //restore snapshot section start
    /* istanbul ignore next */
    const handleRestoreSnapshot = (backup: BackupModel) => {
        setCurrentBackup(backup);
        setRestoreConfirmPopup(true);
    }
    
    const handleRestoreSnapDisAgreeHandler = () => {
        setCurrentBackup(null);
        setRestoreConfirmPopup(false);
    }

    const handleRestoreSnapAgreeHandler = () => {
        if(!currentBackup){
            return;
        }
        setIsRestoring(true);
        props.restoreBackup(environmentDetails.id, currentBackup?.id, props.isHelmEnv)
        handleRestoreSnapDisAgreeHandler();
    }
    
    //restore snapshot section end

    //delete snapshot section start
    /* istanbul ignore next */
    const handleDeleteSnapshot = (backup: BackupModel) => {
        setCurrentBackup(backup);
        setDeleteConfirmPopup(true);
    }

    const handleDeleteSnapDisAgreeHandler = () => {
        setCurrentBackup(null);
        setDeleteConfirmPopup(false);
    }

    const handleDeleteSnapAgreeHandler = () => {
        if(!currentBackup){
            return;
        }
        props.deleteBackup(environmentDetails.id, currentBackup?.id, props.isHelmEnv)
        handleDeleteSnapDisAgreeHandler();
    }

    //delete snapshot section end
    
    return (
        <div data-test="main-container">
            <div className="listContainer">
                <Typography color="textPrimary" variant="h5">
                    {t('Backup.SnapshotsTab.snapshots')}
                </Typography>
                {environmentDetails?.application?.project?.subscription?.backups >= 0 && (
                    <div>
                        <Paper className= { classes.infoPaper }>
                            <Alert severity="info">
                                <Grid container spacing={1}>
                                    <Grid item> 
                                        Snapshot limit - {environmentDetails?.application?.project?.subscription?.backups}
                                    </Grid>
                                    <Grid item>
                                        |
                                    </Grid>
                                    <Grid item> 
                                        Remaining Snapshots - {((environmentDetails?.application?.project?.subscription?.backups ?? 0) - (props.backupList?.length ?? 0))}
                                    </Grid>
                                </Grid>
                            </Alert>
                        </Paper>
                    </div>
                )}
                { 
                    isAuthorized("create", envRole.name) &&
                    <Button
                        data-test="create-btn"
                        variant="contained"
                        color="primary"
                        className={classes.widthHelper}
                        disabled={!environmentState || environmentState.state !== AppConstants.EnvironmentStatus.Running}
                        onClick={() => handleCreateSnapshot() }
                    >
                        {t('Backup.SnapshotsTab.createSnapshot')}
                    </Button>
                }
            </div>
           
            <TableContainer component={Paper} data-test="snapshots-table">
                <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {/* <TableCell>{t('Backup.SnapshotsTab.snapshot')}</TableCell> */}
                        <TableCell align="left">{t('Backup.SnapshotsTab.createdBy')}</TableCell>
                        <TableCell align="left">{t('Backup.SnapshotsTab.created')}</TableCell>
                        <TableCell align="left">{t('Backup.SnapshotsTab.duration')}</TableCell>
                        {/* <TableCell align="left">Time</TableCell> */}
                        <TableCell align="left">{t('Backup.SnapshotsTab.tag')}</TableCell>
                        <TableCell align="left">{t('Backup.SnapshotsTab.status')}</TableCell>
                        <TableCell align="left">{t('Backup.SnapshotsTab.remarks')}</TableCell>
                        {
                            isAuthorized("update", envRole.name) &&
                            <TableCell align="right">{t('Backup.SnapshotsTab.actions')}</TableCell>
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                        {props.backupList && props.backupList.length > 0 && props.backupList.map((backup: BackupModel, ind:number) => {
                            //const backup = JSON.parse(item.status);
                        return (
                            <TableRow key={ind} data-test="snapshot-row">
                                {/* <TableCell component="th" scope="row" data-test="backup-name"> { backup.name} </TableCell> */}
                                <TableCell align="left">{ backup.triggered_by_name }</TableCell>
                                <TableCell data-test="backup-created" align="left">{ getDateInStandardFormat(backup.created * 1000) }</TableCell>
                                <TableCell align="left">{ getElapsedTimebySeconds(backup.duration) }</TableCell>
                                {/* <TableCell align="left">{ backup.snapshot }</TableCell> */}
                                <TableCell align="left">{ backup.preserved ? <Chip label="Preserved"  color="primary"/> : "---"}</TableCell>
                                {/* <TableCell align="left"><span className="activestatus"></span>{ backup.status }</TableCell> */}
                                <TableCell data-test="backup-status" align="left">{ <BackupStatusIndicator status={backup.status} /> }</TableCell>
                                <TableCell data-test="backup-remarks" align="left">{ backup.remarks||"No remarks"}</TableCell>
                                {
                                    isAuthorized("update", envRole.name) && 
                                    <TableCell align="right" data-test="backup-menu">
                                        <BackupMenu backup={backup} disabled={environmentState?.state !== "Running" ? true : false}
                                            handlePreserveSnapshot={ handlePreserveSnapshot }
                                            handleRestoreSnapshot={ handleRestoreSnapshot }
                                            handleDeleteSnapshot={ handleDeleteSnapshot }
                                        />
                                    </TableCell>
                                }
                            </TableRow>
                        )
                    })}
                </TableBody>
                </Table>
                { (!props?.backupList || props.backupList?.length < 1) ? (
                    <div className="alignCenter" data-test="no-snapshots">
                    <p> {t('Backup.SnapshotsTab.noSnapshots')}  </p>
                    </div>
                ) : ''}
            </TableContainer>
            <CreateSnapShotPopup 
                data-test="create-confirm"
                open={ createSnapConfirmPopup } 
                handleAgree={ handleCreateSnapAgreeHandler } 
                handleDisAgree={ handleCreateSnapDisAgreeHandler } 
                message={t('Backup.SnapshotsTab.createSnapshotMessage')} 
                yesText={t('App.AppInfo.yesText')} noText={t('App.AppInfo.noText')}
                
            />
            <ConfirmActionPopup 
                data-test="preserve-confirm"
                open={ preserveConfirmPopup } 
                handleAgree={ handlePreserveSnapAgreeHandler } 
                handleDisAgree={ handlePreserveSnapDisAgreeHandler } 
                message={ `Are you sure you want to ${ currentBackup && currentBackup.preserved ? "UnPreserve" : "Preserve" } this snapshot ?` }
                yesText={t('App.AppInfo.yesText')} noText={t('App.AppInfo.noText')} 
            />
            <ConfirmActionPopup 
                data-test="restore-confirm"
                open={ restoreConfirmPopup } 
                handleAgree={ handleRestoreSnapAgreeHandler } 
                handleDisAgree={ handleRestoreSnapDisAgreeHandler } 
                message={t('Backup.SnapshotsTab.restoreSnapshotMessage')} 
                yesText={t('App.AppInfo.yesText')} noText={t('App.AppInfo.noText')}
            />
            <ConfirmDeletePopup
                data-test="delete-popup"
                open={ deleteConfirmPopup }
                handleAgree={ handleDeleteSnapAgreeHandler }
                handleDisAgree={ handleDeleteSnapDisAgreeHandler }
                message={`This action will permanently delete snapshot data. Please type "${ "Delete" }" to delete the Snapshot : ${ currentBackup && currentBackup.name }`}
                // yesText={t('App.AppInfo.yesText')}
                // noText={t('App.AppInfo.noText')}
                action="delete"
                toMatchName={ "Delete" }
                toDeleteModule="snapshot"
                loading={ props.deletingSnapshot }
            />
            { props.creatingSnapshot && <BackdropLoader message={t('Backup.SnapshotsTab.creatingSnapshot')} />}
            { props.deletingSnapshot && <BackdropLoader message={t('Backup.SnapshotsTab.deletingSnapshot')} />}
            { props.restoringSnapshot && <BackdropLoader message={t('Backup.SnapshotsTab.restoringSnapshot')} />}  
            { props.fetchingBackupList && <BackdropLoader message={t('Backup.SnapshotsTab.fetchingSnapshot')} />}            
        </div>
    );
}

/* istanbul ignore next */
const mapStateToProps = (state:any) => ({
    environmentDetails: state.EnvironmentReducer.environmentDetails,
    helmEnvironmentDetails: state.HelmEnvironmentReducer.helmEnvironmentDetails,
    helmEnvironmentState: state.HelmEnvironmentReducer.helmEnvironmentState,
    environmentState: state.EnvironmentReducer.environmentState,
    backupList: state.BackupReducer.backupList,
    fetchingBackupList: state.BackupReducer.fetchingBackupList,
    creatingSnapshot: state.BackupReducer.creatingSnapshot,
    deletingSnapshot: state.BackupReducer.deletingSnapshot,
    preservingSnapshot: state.BackupReducer.preservingSnapshot,
    restoringSnapshot: state.BackupReducer.restoringSnapshot,
    envRole: state.EnvironmentReducer.envRole,
    //checkRestoreStatusCompleted: state.BackupReducer.checkRestoreStatusCompleted
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch:Dispatch) => ({
    fetchBackupList : (eId:number, isHelm:boolean) => dispatch(fetchBackupList(eId, isHelm)),
    createBackup: (eId: number, jsonBody: Pick<BackupModel,'name'|'remarks'>, isHelm:boolean) => dispatch(createBackup(eId, jsonBody, isHelm)),
    preserveBackup: (eId: number, bId: number, jsonBody: Pick<BackupModel,"preserved">, isHelm:boolean) => dispatch(preserveBackup(eId, bId, jsonBody, isHelm)),
    restoreBackup: (eId:number, bId:number, isHelm:boolean) => dispatch(restoreBackup(eId, bId, isHelm)),
    deleteBackup: (eId:number, bId:number, isHelm:boolean) => dispatch(deleteBackup(eId, bId, isHelm)),
});
const connector=connect(mapStateToProps, mapDispatchToProps)
export default connector(SnapshotsTab);
type PropsFromRedux = ConnectedProps<typeof connector>;