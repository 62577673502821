import { 
  FETCH_BACKUP_LIST,
  FETCH_BACKUP_LIST_SUCCESS,
  FETCH_BACKUP_LIST_FAILURE,
  FETCH_BACKUP_SETTINGS,
  FETCH_BACKUP_SETTINGS_FAILURE,
  FETCH_BACKUP_SETTINGS_SUCCESS,
  UPDATE_BACKUP_SETTINGS,
  UPDATE_BACKUP_SETTINGS_SUCCESS,
  UPDATE_BACKUP_SETTINGS_FAILURE,
  PRESERVE_BACKUP_SUCCESS,
  PRESERVE_BACKUP,
  PRESERVE_BACKUP_FAILURE,
  RESTORE_BACKUP,
  RESTORE_BACKUP_SUCCESS,
  RESTORE_BACKUP_FAILURE,
  DELETE_BACKUP,
  DELETE_BACKUP_SUCCESS,
  DELETE_BACKUP_FAILURE,
  CREATE_BACKUP,
  CREATE_BACKUP_SUCCESS,
  CREATE_BACKUP_FAILURE,
  FETCH_BACKUP_RESTORE_LIST,
  FETCH_BACKUP_RESTORE_LIST_SUCCESS,
  FETCH_BACKUP_RESTORE_LIST_FAILURE,
  UPDATE_BACKUP_LIST,
  CLEAR_BACKUP_RESTORE_DETAIL,
  CLEAR_BACKUP_SETTINGS,
  // CHECK_RESTORE_STATUS_COMPLETED,
  // CHECK_RESTORE_STATUS_COMPLETED_FALSE
} from './actions';
import initialState from './initialState';

const reducer = (state = initialState, payload ) => {
  switch (payload.type) {
    case FETCH_BACKUP_LIST:
      return {
        ...state,
        fetchingBackupList: true
      }
    case FETCH_BACKUP_LIST_SUCCESS:
      return {
        ...state,
        backupList: payload.data?.data,
        fetchingBackupList: false
      }
    case FETCH_BACKUP_LIST_FAILURE:
      return {
        ...state,
        fetchingBackupList: false
      }
    case FETCH_BACKUP_SETTINGS:
      return {
        ...state,
        fetchingBackupSettings: true
      }
    case FETCH_BACKUP_SETTINGS_SUCCESS:
      return {
        ...state,
        backupSettings: payload.data,
        fetchingBackupSettings: false
      }
    case FETCH_BACKUP_SETTINGS_FAILURE:
      return {
        ...state,
        fetchingBackupSettings: false
      }
    case UPDATE_BACKUP_SETTINGS:
      return {
        ...state,
        updatingBackupSettings: true
      }
    case UPDATE_BACKUP_SETTINGS_SUCCESS:
      return {
        ...state,
        //backupSettings: payload.data,
        updatingBackupSettings: false
      }
    case UPDATE_BACKUP_SETTINGS_FAILURE:
      return {
        ...state,
        updatingBackupSettings: false
      }
    case CREATE_BACKUP:
      return {
        ...state,
        creatingSnapshot: true
      }
    case CREATE_BACKUP_SUCCESS:
      return {
        ...state,
        creatingSnapshot: false
      }
    case CREATE_BACKUP_FAILURE:
      return {
        ...state,
        creatingSnapshot: false
      }
    case PRESERVE_BACKUP:
      return {
        ...state,
        preservingSnapshot: true
      }
    case PRESERVE_BACKUP_SUCCESS:
      return {
        ...state,
        preservingSnapshot: false
      }
    case PRESERVE_BACKUP_FAILURE:
      return {
        ...state,
        preservingSnapshot: false
      }
    case RESTORE_BACKUP:
      return {
        ...state,
        restoringSnapshot: true
      }
    case RESTORE_BACKUP_SUCCESS:
      return {
        ...state,
        restoringSnapshot: false
      }
    case RESTORE_BACKUP_FAILURE:
      return {
        ...state,
        restoringSnapshot: false
      }
    case DELETE_BACKUP:
      return {
        ...state,
        deletingSnapshot: true
      }
    case DELETE_BACKUP_SUCCESS:
      return {
        ...state,
        deletingSnapshot: false
      }
    case DELETE_BACKUP_FAILURE:
      return {
        ...state,
        deletingSnapshot: false
      }
    case FETCH_BACKUP_RESTORE_LIST:
      return {
        ...state,
        fetchingBackupRestoreList: true
      }
    case FETCH_BACKUP_RESTORE_LIST_SUCCESS:
      return {
        ...state,
        backupRestoreList: payload.data?.restores,
        fetchingBackupRestoreList: false
      }
    case FETCH_BACKUP_RESTORE_LIST_FAILURE:
      return {
        ...state,
        fetchingBackupRestoreList: false
      }
    case UPDATE_BACKUP_LIST:
      return {
        ...state,
        backupList: payload.data.jsonBody
      }
    case CLEAR_BACKUP_RESTORE_DETAIL:
      return {
        ...state,
        backupRestoreList: initialState.backupRestoreList,
        fetchingBackupRestoreList: initialState.fetchingBackupRestoreList,
      }
    // case CHECK_RESTORE_STATUS_COMPLETED:
    case CLEAR_BACKUP_SETTINGS:
      return {
        ...state,
        backupSettings: initialState.backupSettings,
        fetchingBackupSettings: initialState.fetchingBackupSettings,
        updatingBackupSettings: initialState.updatingBackupSettings,      
      }
    // case CHECK_RESTORE_STATUS_COMPLETED:
    //     return {
    //       ...state,
    //       checkRestoreStatusCompleted: true
    //     }
    // case CHECK_RESTORE_STATUS_COMPLETED_FALSE:
    //     return {
    //       ...state,
    //       checkRestoreStatusCompleted: false
    //     }
    default:
      return state
  }
}

export default reducer