import { Divider, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
//import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
//import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
// import TableRow from "@material-ui/core/TableRow";
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import ConfirmDeletePopup from "../../components/confirmdeletepopup/ConfirmDeletePopup";
import CustomButton from "../../components/custombutton/CustomButton";
import { coreConversion, spaceConversion } from "../../helpers/utils";
import CreateSubscription from './createsubscription/CreateSubscription';
import EditSubscription from "./editsubscription/EditSubscription";
import { deleteSubscription, fetchSubscriptions } from "./redux/actions";
import BackdropLoader from '../../components/loader/BackdropLoader';
import { StyledTableCell, StyledTableRow } from "../../components/styledtabelcell/StyledTabelCell";
import { Dispatch } from "redux";
import { SubscriptionModel } from "../../models/Project.model";

interface Props extends WithTranslation, PropsFromRedux {}

type State = {
  subscriptionList: SubscriptionModel[],
  isDeletePopupOpened: boolean,
  selectedSubID: number,
  selectedSubName: string,
  isCreateEditPopupOpen: boolean,
  editId: number
}

export class Subscription extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      subscriptionList: this.props.subscriptionList,
      isDeletePopupOpened: false,
      selectedSubID: 0,
      selectedSubName: '',
      isCreateEditPopupOpen: false,
      editId: 0 
    };
  }
  handleCreateSubscription = () => {
    this.setState({
      isCreateEditPopupOpen: true,
      editId: 0
    })
  };

  handleEdit = (id: number) => {
    this.setState({
      isCreateEditPopupOpen: true,
      editId: id
    })
  }

  deletePopupAgreeHandler = () => {
    this.props.deleteSubscription(this.state.selectedSubID);
    
    this.setState({
      selectedSubID: 0,
      selectedSubName: '',
      isDeletePopupOpened: false,
    });
  };

  deletePopupDisagreeHandler = () => {
    this.setState({
      selectedSubID: 0,
      selectedSubName: '',
      isDeletePopupOpened: false,
    });
  };

  getSubcriptionList = () => {};

  componentDidMount() {
    this.props.fetchSubscriptions();
  }

  handleDelete(subId: number, subName: string) {
    this.setState({
      selectedSubID: subId,
      selectedSubName: subName,
      isDeletePopupOpened: true,
    });
  }
  
  handleCancel = () => {
    this.setState({
      isCreateEditPopupOpen: false,
      editId: 0
    })
  }

  handleCreateEditSuccess = () => {
    this.setState({
      isCreateEditPopupOpen: false,
      editId: 0
    })
    this.props.fetchSubscriptions();
  }

  render() {
    const { subscriptionList } = this.props;
    const { t } = this.props;
    return (
        <div data-test="main-component">
            <div className="listContainer">
                <Typography color="textPrimary" variant="h5" data-test="title-text">
                    {t('Organization.AdminTab.subscription')}
                </Typography>
                <CustomButton
                  data-test="create-button"
                  label={t("Create")}
                  onClick={() => this.handleCreateSubscription()}
                />
            </div>
            <TableContainer component={Paper}>
                <Table data-test="subscription-table">
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell data-test="name-cell">{t('Resource.ResourcePage.name')}</StyledTableCell>
                            <StyledTableCell data-test="ds-cell">{t('Subscription.SubscriptionPage.diskSpace')}</StyledTableCell>
                            <StyledTableCell data-test="memory-cell">{t('Resource.ResourcePage.memory')}</StyledTableCell>
                            <StyledTableCell data-test="cores-cell">{t('Resource.ResourcePage.cores')}</StyledTableCell>
                            <StyledTableCell data-test="dt-cell">{t('Subscription.SubscriptionPage.dataTransfers')}</StyledTableCell>
                            {/* <StyledTable data-test="name-cell"Cell>{t('Subscription.SubscriptionPage.price')}</StyledTableCell> */}
                            <StyledTableCell data-test="apps-cell">{t('Subscription.SubscriptionPage.apps')}</StyledTableCell>
                            <StyledTableCell data-test="bu-cell">{t('Subscription.SubscriptionPage.backups')}</StyledTableCell>
                            <StyledTableCell data-test="status-cell">{t('Resource.ResourcePage.status')}</StyledTableCell>
                            <StyledTableCell align="center"> {t('Projects.UserTab.actionLabel')} </StyledTableCell>
                        </StyledTableRow>
                    </TableHead>

                    <TableBody>
                        {subscriptionList &&
                subscriptionList.map((sub: SubscriptionModel) => (
                    <StyledTableRow key={sub.id} data-test="sub-lists-rows">
                        <StyledTableCell>{sub.name}</StyledTableCell>
                        <StyledTableCell>
                            {spaceConversion(sub.disk_space)}
                        </StyledTableCell>
                        <StyledTableCell>
                            {spaceConversion(sub.memory)}
                        </StyledTableCell>
                        <StyledTableCell>
                            {coreConversion(sub.cores)}
                        </StyledTableCell>
                        <StyledTableCell>
                            {spaceConversion(sub.data_transfer)}
                        </StyledTableCell>
                        {/* <StyledTableCell>${sub.price}</StyledTableCell> */}
                        <StyledTableCell>{sub.apps}</StyledTableCell>
                        <StyledTableCell>{sub.backups}</StyledTableCell>
                        <StyledTableCell >
                            {sub.active ? (
                                <p>
                                    <span className="activestatus"></span> {t('Resource.ResourceForm.active')}
                                </p>
                      ) : (
                          <p>
                              <span className="inactivestatus"></span> {t('Resource.ResourceForm.inactive')}
                          </p>
                      )}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                            <IconButton aria-label="edit" onClick={() => this.handleEdit(sub.id)} data-test="edit-button">
                                <EditIcon />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              data-test="delete-button"
                              onClick={() => this.handleDelete(sub.id, sub.name)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </StyledTableCell>
                    </StyledTableRow>
                ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {
          this.state.isCreateEditPopupOpen &&
          <Dialog
            open={this.state.isCreateEditPopupOpen}
            keepMounted
            onClose={this.handleCancel}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            disableBackdropClick={true}
            className="subscriptionPopup"
          >
              <DialogTitle id="alert-dialog-slide-title">
                  <Typography className='dialogtitle'>{ this.state.editId > 0 ? t('Subscription.SubscriptionPage.editSubs') : t('Subscription.SubscriptionPage.addSubs') }</Typography>
                  <IconButton aria-label="close" size="small" className='right' onClick={this.handleCancel}>
                      <CloseIcon />
                  </IconButton>
              </DialogTitle>
              <Divider />
              <DialogContent dividers>
                  <DialogContentText id="alert-dialog-slide-description">
                      { this.state.editId > 0 && <EditSubscription subId={ this.state.editId } handleCreateEditSuccess={ this.handleCreateEditSuccess }/> }
                      { this.state.editId === 0 && <CreateSubscription handleCreateEditSuccess={ this.handleCreateEditSuccess } /> }   
                  </DialogContentText>
              </DialogContent>
          </Dialog>
        }
      
         { this.state.isDeletePopupOpened &&  <ConfirmDeletePopup
            open={this.state.isDeletePopupOpened}
            handleAgree={this.deletePopupAgreeHandler}
            handleDisAgree={() => this.deletePopupDisagreeHandler()}
            message={t('Subscription.SubscriptionPage.confirmDelete')}
            toMatchName={this.state.selectedSubName}
            toDeleteModule="Subscription"
            loading={this.props.executingDeleteSubscription}
          />}
        
            { this.props.executingDeleteSubscription && <BackdropLoader message={t('Subscription.SubscriptionPage.deletingSubs')} />}
            {this.props.fetchingSubscriptions && <BackdropLoader message={t('Subscription.SubscriptionPage.fetchingSubs')} />}
        </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return { 
    subscriptionList: state.SubscriptionReducer.subscriptionList,
    fetchingSubscriptions: state.SubscriptionReducer.fetchingSubscriptions,
    executingDeleteSubscription: state.SubscriptionReducer.executingDeleteSubscription
   };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchSubscriptions: () => dispatch(fetchSubscriptions()),
  deleteSubscription: (subId: number) => dispatch(deleteSubscription(subId)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withTranslation()(Subscription));