import React from 'react';
import { Paper } from '@material-ui/core';
import './SubscriptionCard.css';
import { spaceCoversion , coreConversion } from '../../helpers/utils';
import { connect } from "react-redux"
import { useTranslation } from 'react-i18next';
import { SubscriptionCardModel } from '../../models/Project.model';

//const SubscriptionCard = ({disabled = false ,subscriptionName , subscriptionPrice , subscriptionCore , subscriptionApps ,subsciptionSpace , subscriptionDataTransfer , subscriptionRam}) => {
export const SubscriptionCard = (props: SubscriptionCardModel) => {	
 const { details } = props; 
 const disabled = props.selectedSubscriptionId !== details.id; 
 const cardDisabled = props.cardDisabled;
 const [t] = useTranslation()

 const handleClick = ()=> {
     if(!cardDisabled){
         props.handleSubscriptionClick(details)
     }
 }
 
  return (
      <>
          <Paper elevation={3} className={ `SubscriptionCard-container ${cardDisabled ? "disabled-card" : ""}` } data-test="main-container">
              <div onClick={cardDisabled ? ()=> {} : handleClick} data-test="card-link" style={{ cursor: 'pointer' }}>
                  {/* <div className={ disabled === true ? 'SubscriptionCard-header-disabled' : 'SubscriptionCard-header' }>{details.name}</div>	 */}
                  <div className={`SubscriptionCard-header ${cardDisabled ? "" : "" }`} data-test="details-name">{details.name}</div>
                  {!props.currentOrganization?.id && (
                    <div className={ `${ disabled === true ? 'SubscriptionCard-pricing-disabled' : 'SubscriptionCard-pricing'}  ${cardDisabled ? "disabled-card" : "" }` }>
                        <div>
                            <span className={ disabled === true ? 'price-disabled' : 'price' } data-test="details-price">${details.price}</span><span className={ disabled === true ? 'perMonth-disabled' : 'perMonth' }> / {t('PerMonth')}</span>
                        </div>
                    </div>
                  )}
                  <div className={ `${disabled === true ? 'SubscriptionCard-content-disabled' : 'SubscriptionCard-content'}  ${cardDisabled ? "disabled-card" : "" }` }>
                      <div>
                          <p style={{textAlign:'center'}} className={ disabled === true ? 'sc-contentText-disabled' : 'sc-contentText' } data-test="app-details">{details.apps > 1 ? details.apps + ' Apps': details.apps + ' App'}</p>
                          <p style={{textAlign:'center'}} className={ disabled === true ? 'sc-contentText-disabled' : 'sc-contentText' } data-test="memory-details">{spaceCoversion(details.memory)} {t('RAM')}</p>
                          <p style={{textAlign:'center'}} className={ disabled === true ? 'sc-coreText-disabled' : 'sc-coreText' } data-test="core-details">{coreConversion(details.cores)}</p>
                          <p style={{textAlign:'center'}} className={ disabled === true ? 'sc-contentText-disabled' : 'sc-contentText' } data-test="disk-details">{spaceCoversion(details.disk_space)} {t('Storage')}</p>
                          <p style={{textAlign:'center'}} className={ disabled === true ? 'sc-contentText-disabled' : 'sc-contentText' } data-test="data-details">{spaceCoversion(details.data_transfer)} {t('Bandwidth')}</p>
                          <p style={{textAlign:'center'}} className={ disabled === true ? 'sc-contentText-disabled' : 'sc-contentText' } data-test="backups-details">{details.backups} {t('Backups')} </p>
                      </div>
                  </div>
              </div>
          </Paper>
      </>
    )
};

const mapStateToProps = (state: any) => ({
    currentOrganization: state.AuthReducer.currentOrganization,
  });
  
  export default connect(
    mapStateToProps,
    null
  )(SubscriptionCard);
