import {
    FETCH_PLUGINS,
    FETCH_PLUGINS_FAILURE,
    FETCH_PLUGINS_SUCCESS,
    FETCH_PLUGIN,
    FETCH_PLUGIN_FAILURE,
    FETCH_PLUGIN_SUCCESS,
    CREATE_PLUGIN,
    CREATE_PLUGIN_FAILURE,
    CREATE_PLUGIN_SUCCESS,
    EDIT_PLUGIN,
    EDIT_PLUGIN_FAILURE,
    EDIT_PLUGIN_SUCCESS,
    DELETE_PLUGIN,
    DELETE_PLUGIN_FAILURE,
    DELETE_PLUGIN_SUCCESS,
    UPDATE_BREADCRUMB,
    ADD_ORG_PLUGIN,
    ADD_ORG_PLUGIN_FAILURE,
    ADD_ORG_PLUGIN_SUCCESS,
    ADD_ORG_PLUGINS,
    ADD_ORG_PLUGINS_FAILURE,
    ADD_ORG_PLUGINS_SUCCESS,
    REMOVE_ORG_PLUGIN,
    REMOVE_ORG_PLUGIN_FAILURE,
    REMOVE_ORG_PLUGIN_SUCCESS,
  } from './actions';
  import initialState from './initialState';
  
  const reducer = (state = initialState, payload) => {
    switch (payload.type) {
      case FETCH_PLUGINS:
        return {
          ...state,
          fetchingPlugins: true,
        };
      case FETCH_PLUGINS_SUCCESS:
        return {
          ...state,
          pluginList: payload.data,
          fetchingPlugins: false,
        };
      case FETCH_PLUGINS_FAILURE:
        return {
          ...state,
          fetchingPlugins: false,
        };
      case FETCH_PLUGIN:
        return {
          ...state,
          fetchingPlugin: true,
        };
      case FETCH_PLUGIN_SUCCESS:
        return {
          ...state,
          plugin: payload.data,
          fetchingPlugin: false,
        };
      case FETCH_PLUGIN_FAILURE:
        return {
          ...state,
          fetchingPlugin: false,
        };
      case CREATE_PLUGIN:
        return {
          ...state,
          creatingPlugin: true,
          createPluginSuccess: false,
          createPluginFail: false,
        };
      case CREATE_PLUGIN_SUCCESS:
        return {
          ...state,
          creatingPlugin: false,
          createPluginSuccess: true,
          createPluginFail: false,
        };
      case CREATE_PLUGIN_FAILURE:
        return {
          ...state,
          creatingPlugin: false,
          createPluginSuccess: false,
          createPluginFail: true,
        };
      case ADD_ORG_PLUGIN:
        return {
          ...state,
          creatingPlugin: true,
        };
      case ADD_ORG_PLUGIN_SUCCESS:
        return {
          ...state,
          creatingPlugin: false,
        };
      case ADD_ORG_PLUGIN_FAILURE:
        return {
          ...state,
          creatingPlugin: false,
        };
      case ADD_ORG_PLUGINS:
        return {
          ...state,
          creatingPlugin: true,
        };
      case ADD_ORG_PLUGINS_SUCCESS:
        return {
          ...state,
          creatingPlugin: false,
        };
      case ADD_ORG_PLUGINS_FAILURE:
        return {
          ...state,
          creatingPlugin: false,
        };
      case REMOVE_ORG_PLUGIN:
        return {
          ...state,
          deletingPlugin: true,
        };
      case REMOVE_ORG_PLUGIN_SUCCESS:
        return {
          ...state,
          deletingPlugin: false,
        };
      case REMOVE_ORG_PLUGIN_FAILURE:
        return {
          ...state,
          deletingPlugin: false,
        };
      case EDIT_PLUGIN:
        return {
          ...state,
          editingPlugin: true,
          editPluginSuccess: false,
          editPluginFail: false,
        };
      case EDIT_PLUGIN_SUCCESS:
        return {
          ...state,
          // plugin: payload.data,
          editingPlugin: false,
          editPluginSuccess: true,
          editPluginFail: false,
        };
      case EDIT_PLUGIN_FAILURE:
        return {
          ...state,
          editingPlugin: false,
          editPluginSuccess: false,
          editPluginFail: true,
        };
      case DELETE_PLUGIN:
        return {
          ...state,
          deletingPlugin: true
        };
      case DELETE_PLUGIN_SUCCESS:
        return {
          ...state,
          deletingPlugin: false

        };
      case DELETE_PLUGIN_FAILURE:
        return {
          ...state,
          deletingPlugin: false,
        };
        case UPDATE_BREADCRUMB: 
        return {
          ...state,
          breadcrumbData : payload.data.payload
        }
      default:
        return state;
    }
  };
  
  export default reducer;
