import React, { memo } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import routes from '../routes'
import PrivateRoute from './PrivateRoute'
import { withTranslation } from 'react-i18next';
import StaticPrivateRoute from './StaticPrivateRoute';

export const MainRouter = () => {
  // props.i18n.changeLanguage("hin");
  return (
      <Router>
          <Switch>
              <Route path={ routes.DEFAULT.route } component={ routes.DEFAULT.component } exact />
              {/* <Route path={ routes.REGISTER.route } component={ routes.REGISTER.component } exact /> */}
              <Route path={ routes.REQUESTDEMO.route } component={ routes.REQUESTDEMO.component } exact />
              <Route path={ routes.FORGOTPASSWORD.route } component={ routes.FORGOTPASSWORD.component } exact />
              <Route path={ routes.ResetPassword.route } component={ routes.ResetPassword.component } exact />
              <Route path={ routes.LOGINCALLBACK.route } component={ routes.LOGINCALLBACK.component } exact />
              <Route path={ routes.BITBUCKETLOGINCALLBACK.route} component={routes.BITBUCKETLOGINCALLBACK.component} exact />
              <Route path={ routes.LOGINCALLBACKGITLAB.route } component={ routes.LOGINCALLBACKGITLAB.component } exact />
              <Route path={ routes.PAYMENT_SUCCESS.route } component={ routes.PAYMENT_SUCCESS.component } exact />
              <Route path={ routes.PAYMENT_FAILURE.route } component={ routes.PAYMENT_FAILURE.component } exact />
              <Route path={ routes.VERIFYEMAIL.route } component={ routes.VERIFYEMAIL.component } exact />
              <Route path={ routes.INVITE_LOGIN.route } component={ routes.INVITE_LOGIN.component } exact />
              {/* <PrivateRoute path={ routes.DASHBOARD.route } component={ routes.DASHBOARD.component } exact /> */}
              <PrivateRoute path={ routes.ACCOUNTDETAILS.route } component={ routes.ACCOUNTDETAILS.component } exact />
              <PrivateRoute path={ routes.PROJECTLIST.route } component={ routes.PROJECTLIST.component } exact />
              <PrivateRoute path={ routes.CREATEPROJECT.route } component={ routes.CREATEPROJECT.component } exact />
              <PrivateRoute path={ routes.PROJECTINFO.route } component={ routes.PROJECTINFO.component } exact />
              {/* <PrivateRoute path={ routes.OPERATOR_DETAIL.route } component={ routes.OPERATOR_DETAIL.component } exact /> */}
              <PrivateRoute path={ routes.CREATEAPP.route } component={ routes.CREATEAPP.component } exact />
              <PrivateRoute path={ routes.APPDETAILS.route } component={ routes.APPDETAILS.component } exact />
              <PrivateRoute path={ routes.CREATEAPP_SELECTPLUGIN.route } component={ routes.CREATEAPP_SELECTPLUGIN.component } exact />
              <PrivateRoute path={ routes.CREATEAPP_SELECTCATALOG.route } component={ routes.CREATEAPP_SELECTCATALOG.component } exact />
              <PrivateRoute path={ routes.CREATEAPP_SELECTREGION.route } component={ routes.CREATEAPP_SELECTREGION.component } exact />
              <PrivateRoute path={ routes.CREATEAPP_SELECTOPERATOR.route } component={ routes.CREATEAPP_SELECTOPERATOR.component } exact />
              <PrivateRoute path={ routes.CREATEOPERATORENVIRONMENT.route } component={ routes.CREATEOPERATORENVIRONMENT.component } exact />
              <PrivateRoute path={ routes.EDITOPERATORENVIRONMENT.route } component={ routes.EDITOPERATORENVIRONMENT.component } exact />
              {/* <PrivateRoute path={routes.CREATEAPPGENERALS.route} component={routes.CREATEAPPGENERALS.component} exact />
        <PrivateRoute path={routes.CREATEAPPDEPLOY.route} component={routes.CREATEAPPDEPLOY.component} exact /> */}
              <PrivateRoute path={ routes.CREATEENVIRONMENT.route } component={ routes.CREATEENVIRONMENT.component } exact />
              <PrivateRoute path={ routes.CREATEHELMENVIRONMENT.route } component={ routes.CREATEHELMENVIRONMENT.component } exact />
              <PrivateRoute path={ routes.EDITHELMENVIRONMENT.route } component={ routes.EDITHELMENVIRONMENT.component } exact />
              <PrivateRoute path={ routes.ENVIRONMENTINFO.route } component={ routes.ENVIRONMENTINFO.component } exact />
              <PrivateRoute path={ routes.HELMENVIRONMENTINFO.route } component={ routes.HELMENVIRONMENTINFO.component } exact />
              <PrivateRoute path={ routes.CREATE_ORGANIZATION.route } component={ routes.CREATE_ORGANIZATION.component } exact />
              <PrivateRoute path={ routes.CREATE_ORG_CLUSTER.route } component={ routes.CREATE_ORG_CLUSTER.component } exact />
              <PrivateRoute path={ routes.CREATE_V_CLUSTER.route } component={ routes.CREATE_V_CLUSTER.component } exact />
              <PrivateRoute path={ routes.EDIT_ORG_CLUSTER.route } component={ routes.EDIT_ORG_CLUSTER.component } exact />
              <PrivateRoute path={ routes.ORGANIZATIONINFO.route } component={ routes.ORGANIZATIONINFO.component } exact />
              <PrivateRoute path={ routes.SUPPORT.route } component={ routes.SUPPORT.component } exact />
              <PrivateRoute path={ routes.TICKET_DETAIL.route } component={ routes.TICKET_DETAIL.component } exact />
              <PrivateRoute path={ routes.TICKET_CREATE.route } component={ routes.TICKET_CREATE.component } exact />
              <PrivateRoute path={ routes.NOTIFICATIONS.route } component={ routes.NOTIFICATIONS.component } exact />
              <StaticPrivateRoute path={ routes.LOGIN_SSO.route } component={ routes.LOGIN_SSO.component } exact />
              <StaticPrivateRoute path={ routes.OAuth.route} component={routes.OAuth.component} exact />
              <PrivateRoute component={ routes.NOMATCHPAGE.component } />
    
          </Switch>
      </Router>
  )
}

export default memo(withTranslation()(MainRouter))
