const initialState = {
    chartConfig: null,
    fetchingHelmEnv: false,
    fetchingHelmEnvState: false,
    helmEnvironmentDetails: null,
    isDataNotFound: false,
    helmEnvironmentMetadata: null,
    currentHelmEnvironment: null,
    helmEnvironmentState: {},
    helmActivityLogs: [],
    realTimeHelmActivityLogs: [],
    helmServiceDetails: null,
    helmPods: null,
    helmLogs: null,
    fetchingHelmLogs: false,
    fetchingHelmPods: false,
    helmLogsInitiate: null,
    isLoading: false,
    updatingHelmEnv: false,
    startingHelmEnv: false,
    stoppingHelmEnv: false,
    redeployingHelmEnv: false,
    launchingHelmEnv: false,
    fetchingHelmEnvInsight: false,
    helmEnvironmentInsights: null,
    helmEnvironmentOverview: null,
    fetchingHelmEnvOverview: null,
}

export default initialState