import React from 'react';
import { FormControl, RadioGroup, Radio, FormControlLabel, Button, Grid, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, IconButton, Divider } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PluginConfigForm from '../pluginconfigform/PluginConfigForm';
import { CreateApp_TemplateTypes } from '../../constants/enums'
import { connect } from 'react-redux';
import { crConnect, gitConnect, clearErrorMessage } from '../../pages/app/redux/actions'
import Alert from '@material-ui/lab/Alert';
import { Dispatch } from "redux";
import { AuthProvider } from '../../models/Account.model'

 type Props = {
    isOpenAuthPopup: boolean,
    selectedProvider: AuthProvider,
    authTokenTypeCallBack:(data:string)=>void,
    clearErrorMessage: () => void,
    crConnect: (jsonBody:any, callback: (data: string) => void)=> void,
    gitConnect: (jsonBody:any,window: any, callback: (data: string) => void)=> void,
    handleCancelPopUp: () => void,
    errorMessage: string
}

export const AuthorizeContainerPopUp = (props: Props) => {
    const { isOpenAuthPopup, selectedProvider } = props;
    const [variables, setVariables] = React.useState({});
    const [isErrors, setIsErrors] = React.useState<string[]>([]);
    const [ loginMethod, setLoginMethod ] = React.useState('oauth')

    const loginHandler = (e:React.ChangeEvent<HTMLInputElement>) => {
        setLoginMethod(e.target.value)
    }

    const openPopup = () => {
        setTimeout(() => window.open(`${selectedProvider.authorizeEndpoint}?${selectedProvider.params}`, "01Cloud - OAuth", "status=no,location=no,toolbar=no,menubar=no,width:200,height=100") && props.handleCancelPopUp(), 100)
    }

    const handleValueChange = (_variables: any, errorsList: string[]) => {
        setVariables(_variables);
        setIsErrors(errorsList);
    }

    const _authTokenTypeCallBack = (data: string) => {
        props.authTokenTypeCallBack(data);
    }
    const authorizeAction = () => {
        const jsonBody = { ...variables, "service_name": selectedProvider.service_name };
        if (selectedProvider.templateType === CreateApp_TemplateTypes.cr) {
            props.clearErrorMessage()
            props.crConnect(jsonBody, _authTokenTypeCallBack);
        }
        else if (selectedProvider.templateType === CreateApp_TemplateTypes.git) {
            props.clearErrorMessage()
            props.gitConnect(jsonBody, null, _authTokenTypeCallBack)
        }
    }

    const closeAlert = () => {
        setTimeout(() => {props.clearErrorMessage()}, 0)
    }

    const closePopup = () => {
        props.clearErrorMessage()
        props.handleCancelPopUp()
    }

    return (
        <Dialog
            disableEscapeKeyDown = {true}
            data-test="main-container"
            open={isOpenAuthPopup}
            keepMounted
            onClose={closePopup}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            disableBackdropClick={true}
        >
            <DialogTitle id="alert-dialog-slide-title" data-test="dialog-Title">
                <Typography className='dialogtitle' data-test="title-name">Authorize {selectedProvider && selectedProvider.name}</Typography>
                <IconButton aria-label="close" size="small" className='right' onClick={closePopup} data-test="close-icon">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent dividers>
                <DialogContentText id="alert-dialog-slide-description">
                    {
                        selectedProvider?.authType === 3 &&
                        <Grid container className="m-b-20" justify="center" alignItems="center">
                            <FormControl component="fieldset">
                                <RadioGroup data-test="login-method-radio" aria-label="type" name="type" value={loginMethod} onChange={loginHandler} style={{display:'flex', flexDirection: 'row'}}>
                                    <FormControlLabel value="oauth" control={<Radio />} label="OAuth" />
                                    <FormControlLabel value="token" control={<Radio />} label="Token" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    }

                    {
                        props.errorMessage && 
                        <Alert severity="error" onClose={closeAlert} className="m-b-20">{props.errorMessage}</Alert>
                    }
                        
                    {
                        selectedProvider && (
                            (selectedProvider.authType === 2 && selectedProvider.config) 
                            || (selectedProvider.authType === 3 && loginMethod === 'token')
                        ) &&
                        <>
                        <div>
                            <PluginConfigForm
                                data-test="config-form"
                                pluginVersionConfig={selectedProvider.config}
                                handleValueChange={handleValueChange}
                                initialValues={variables}
                                isErrors={isErrors}
                                gridItemOccupency={12}
                                ignoreAlternateColour={true}
                                validateEmpty={true}
                            />
                            {
                                selectedProvider.infoLinkUrl &&
                                <a 
                                    href={selectedProvider.infoLinkUrl} 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                    data-test="config-link"
                                    style={{
                                        display: "inline-block",
                                        marginTop: "10px",
                                        padding: "3px 0",
                                    }}
                                >
                                    {selectedProvider.infoLinkText ?? "Learn more"}
                                </a>
                            }
                        </div>
                        <div className="right">
                            <Button
                                data-test="authorize-button"
                                onClick={() => authorizeAction()}
                                color="primary"
                                variant="contained"
                                disabled={isErrors.length > 0}
                                className="m-t-20"
                            >
                                Authorize
                            </Button>
                        </div>
                        </>
                    }
                    {
                        (selectedProvider?.authType === 3 && loginMethod === 'oauth') &&
                        <div className="right">
                            <Button
                                data-test="proceed-button"
                                onClick={openPopup}
                                color="primary"
                                variant="contained"
                                className="m-t-20"
                            >
                                Proceed
                            </Button>
                        </div>
                    }
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
    errorMessage: state.AppsReducer.errorMessage
})

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch:Dispatch) => {
    return {
        clearErrorMessage: () => dispatch(clearErrorMessage()),
        crConnect: (jsonBody:any, callback: (data: string) => void) => dispatch(crConnect(jsonBody, callback)),
        gitConnect: (jsonBody:any, window: any, callback: (data: string) => void) => dispatch(gitConnect(jsonBody, window, callback))
    }
}

export default connect(mapStateToProps, mapDispatchtoProps)(AuthorizeContainerPopUp)