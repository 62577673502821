import React from 'react'
import { InputAdornment, IconButton } from '@material-ui/core'
import MuiTextField from '../textfield/MuiTextField'
import SearchIcon from "@material-ui/icons/Search";

type Props = {
    label: string
    search: string
    handleSearch: (st: string) => void
    handleSearchChange: (str: string) => void
}

export default function SearchField(props: Props) {
    const [timer, setTimer] = React.useState<any>(null)

    React.useEffect(() => {
        return (() => {
            if (timer) {
                clearTimeout(timer)
            }
        })
    }, [])

    const handleSearch = (st: string) => {
        if (props.handleSearch) {
            props.handleSearch(st)
        }
    }

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (timer) {
            clearTimeout(timer)
        }
        props.handleSearchChange(e.target.value)
        setTimer(setTimeout(handleSearch, 1000, e.target.value))
    }

    return (
        <>
            <MuiTextField
                className="w-100"
                id="search"
                label={props.label ?? "Search"}
                name="search"
                style={{ width: "100%" }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSearchChange(e)}
                value={props.search}
                variant="outlined"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility" edge="end">
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                data-test="search-box"
            />
        </>
    )
}