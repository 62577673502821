import {
  Avatar,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  // TableRow,
  Tooltip,
} from "@material-ui/core";
//import { withStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import AddMemberPopup from "../../../../components/addmemberpopup/AddMemberPopup";
import ConfirmActionPopup from "../../../../components/confirmactionpopup/ConfirmActionPopup";
import CustomButton from "../../../../components/custombutton/CustomButton";
import BackdropLoader from "../../../../components/loader/BackdropLoader";
import { isAuthorizedOrg } from "../../../../helpers/utils";
import { currentOrgRole } from "../../../login/redux/selectors";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import {
  addMemberToOrg,
  deleteMember,
  editMemberToOrg,
} from "../../redux/actions";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../../components/styledtabelcell/StyledTabelCell";
import { useTranslation } from "react-i18next";
import { Dispatch } from "redux";
import { MembersModel } from "../../../../models/Organization.model";
import { History } from "history";

// export const StyledTableCell = withStyles(() => ({
//   head: {
//     // backgroundColor: theme.palette.background.default,
//     // color: 'grey',
//     fontWeight: 500,
//   },
//   body: {
//     color: "#555",
//     fontSize: 14,
//   },
// }))(TableCell);

// export const StyledTableRow = withStyles(() => ({
//   root: {},
// }))(TableRow);

type Payload = {
  email: string | undefined;
  role?: string;
}

interface Props extends PropsFromRedux {
  history: History
}

export function MembersTab(props: Props) {
  const [deleteMemberPopupOpen, setDeleteMemberPopupOpen] = useState(false);
  const [leavePopup, setLeavePopup] = useState(false);
  const [addUserPopupOpen, setAddUserPopupOpen] = useState(false);
  const [toDeletememberid, setToDeleteMemberid] = useState<any>(null);
  const [currentMember, setCurrentMember] = useState<any>(null);
  const [t] = useTranslation();

  const roles = [
    {
      id: 1,
      name: "Admin",
    },
    {
      id: 2,
      name: "Member",
    },
  ];

  // useEffect(() => {
  //   props.getOrgInfo();
  //   // props.fetchRolesAction();
  //   return () => {};
  // }, []);

  const handleDeleteUser = (userId: number) => {
    setToDeleteMemberid(userId);
    setDeleteMemberPopupOpen(true);
  };

  const handleLeave = (userId: number) => {
    setToDeleteMemberid(userId);
    setLeavePopup(true);
  };

  const handleEditUser = (user: MembersModel) => {
    setCurrentMember(user);
    setAddUserPopupOpen(true);
  };

  const handleAddMember = () => {
    setAddUserPopupOpen(true);
    // this.setState({
    //   isAddUserPopupOpened : Math.random(),
    // })
  };

  const memberCallBack = () => {
    setAddUserPopupOpen(false);
  };

  const addMemberAgreeHandler = (email: string, role: string) => {
    const payload = { email, role };
    if (currentMember) {
     
      props.editMemberToOrg(currentMember.id, payload);
    } else {
      props.addMemberToOrg(payload, memberCallBack);

    }
    setCurrentMember(null);
  };

  const addMemberDisagreeHandler = () => {
    setAddUserPopupOpen(false);
    setCurrentMember(null);
  };

  const getRole = (id: number) => {
    const role = roles.find((r) => {
      return r.id === id;
    });
    return role?.name;
  };

  const deleteMemberAgreeHandler = (_email?: string) => {
    let email = _email;
    const member = props.organizationInfo?.members?.find((m: MembersModel) => {
      if (m.id === toDeletememberid) {
        return m;
      }
    });
    if (member) {
      email = member.user?.email;
    }
    const payload = { email: email };
    setDeleteMemberPopupOpen(false);
    props.deleteMember(payload);
  };

  const leaveAgreeHandler = (_email?: string) => {
    let email = _email;
    const member = props.organizationInfo?.members?.find((m: MembersModel) => {
      if (m.id === toDeletememberid) {
        return m;
      }
    });
    if (member) {
      email = member.user?.email;
    }
    const payload = { email: email };
    setLeavePopup(false);
    props.deleteMember(payload, true, props.history);
  };

  const deleteMemberDisagreeHandler = () => {
    setDeleteMemberPopupOpen(false);
    setLeavePopup(false);
  };

  const { organizationInfo } = props;
  return (
    <div data-test="membersTabContainer">
      {isAuthorizedOrg("add", props.currentOrgRole) && (
        <div className="alignRight addButtonContainer">
          <CustomButton
            label={t("Organization.MembersTab.addMember")}
            onClick={() => handleAddMember()}
            data-test="add-member"
          />
        </div>
      )}
      <TableContainer component={Paper} data-test="membersTable">
        <Table>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell>{t("Organization.DnsTab.name")}</StyledTableCell>
              <StyledTableCell>
                {t("Organization.GroupInfo.email")}
              </StyledTableCell>
              <StyledTableCell>
                {t("Organization.MembersTab.roles")}
              </StyledTableCell>
              {/* {isAuthorizedOrg("delete", props.currentOrgRole) && ( */}
              <StyledTableCell align="center" data-test="action">
                {t("Organization.DnsTab.actions")}
              </StyledTableCell>
              {/* )} */}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {organizationInfo && organizationInfo.user && (
              <StyledTableRow key={organizationInfo.user?.id}>
                <StyledTableCell>
                  {" "}
                  <Avatar
                    src={
                      organizationInfo.user && organizationInfo.user.image
                        ? organizationInfo.user.image
                        : "./Images/profile_image.png"
                    }
                  />
                </StyledTableCell>
                <StyledTableCell>
                  {organizationInfo.user?.first_name +
                    " " +
                    organizationInfo.user.last_name}
                  {/* <Chip className='userRole' label="owner" /> */}
                </StyledTableCell>
                <StyledTableCell>
                  {organizationInfo.user?.email}
                </StyledTableCell>
                <StyledTableCell>
                  {t("Organization.MembersTab.owner")}
                </StyledTableCell>
                {/* {isAuthorized("delete", projectRole.name) && ( */}
                <StyledTableCell>
                  {/* <IconButton onClick={ e => this.handleDeleteUser(user.id) }>
                                          <DeleteIcon />
                                      </IconButton> */}
                </StyledTableCell>
                {/* )} */}
              </StyledTableRow>
            )}
            {organizationInfo?.members?.length > 0 &&
              organizationInfo?.members.map((member: MembersModel) => (
                <StyledTableRow key={member.id} data-test="rows">
                  <StyledTableCell>
                    {" "}
                    <Avatar
                      src={
                        member.user && member.user.image
                          ? member.user.image
                          : "./Images/profile_image.png"
                      }
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    {member.user &&
                    member.user.first_name + " " + member.user.last_name}
                    {/* <Chip className='userRole' label="owner" /> */}
                  </StyledTableCell>
                  <StyledTableCell>{member.user?.email}</StyledTableCell>
                  <StyledTableCell>
                    {getRole(member.user_role) ?? ""}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {props.userDetails?.id === member.user?.id ? (
                      <Tooltip
                        title={t("Organization.MembersTab.leaveOrg")}
                        arrow
                      >
                        <IconButton
                          onClick={() => handleLeave(member.id)}
                          data-test="leave-button"
                        >
                          <ExitToAppIcon fontSize="inherit" />
                          {/* <Button variant="outlined" color="secondary" > Leave </Button> */}
                        </IconButton>
                      </Tooltip>
                    ) : (
                      isAuthorizedOrg("delete", props.currentOrgRole) &&
                      props.userDetails &&
                      props.userDetails.id !== member.user.id && (
                        <>
                          <Tooltip
                            title={t("Organization.MembersTab.editMember")}
                            arrow
                          >
                            <IconButton
                              onClick={() => handleEditUser(member)}
                              data-test="edit-user"
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            title={t("Organization.MembersTab.deleteMember")}
                            arrow
                          >
                            <IconButton
                              onClick={() => handleDeleteUser(member.id)}
                              data-test="delete-user"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </>
                      )
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
        {organizationInfo?.members?.length === 0 && (
          <div className="alignCenter">
            <p>{t("Organization.GroupInfo.noMembers")}</p>
          </div>
        )}
      </TableContainer>
      {addUserPopupOpen && (
        <AddMemberPopup
          member={true}
          open={addUserPopupOpen}
          handleAgree={addMemberAgreeHandler}
          handleDisagree={addMemberDisagreeHandler}
          rolesData={roles}
          editMember={currentMember}
          data-test="member-popup"
        />
      )}
      <ConfirmActionPopup
        open={deleteMemberPopupOpen}
        message={t("Organization.MembersTab.removeFromOrg")}
        handleAgree={deleteMemberAgreeHandler}
        handleDisAgree={deleteMemberDisagreeHandler}
        loading={props.deletingMember}
        yesText={t("Organization.GroupInfo.remove")}
        data-test="confirm-popup"
      />
      <ConfirmActionPopup
        open={leavePopup}
        message={t("Organization.OrganizationInfo.sureLeave")}
        handleAgree={leaveAgreeHandler}
        handleDisAgree={deleteMemberDisagreeHandler}
        loading={props.deletingMember}
        yesText={t("Organization.OrganizationInfo.leave")}
        data-test="leavePopup"
      />
      {props.addingMember && (
        <BackdropLoader
          message={t("Organization.MembersTab.adding")}
          data-test="adding-backdrop"
        />
      )}
      {props.editingMember && (
        <BackdropLoader
          message={t("Organization.MembersTab.updating")}
          data-test="editing-backdrop"
        />
      )}
      {props.deletingMember && (
        <BackdropLoader
          message={t("Organization.MembersTab.deleting")}
          data-test="delete-backdrop"
        />
      )}
      {props.fetchingOrgInfo && (
        <BackdropLoader
          message={t("Organization.MembersTab.fetching")}
          data-test="fetch-backdrop"
        />
      )}
    </div>
  );
}
/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  organizationInfo: state.OrganizationReducer.organizationInfo,
  fetchingOrgInfo: state.OrganizationReducer.fetchingOrgInfo,
  addingMember: state.OrganizationReducer.addingMember,
  editingMember: state.OrganizationReducer.editingMember,
  deletingMember: state.OrganizationReducer.deletingMember,
  currentOrgRole: currentOrgRole(state),
  userDetails: state.AuthReducer.user,
  // rolesData: state.AddUserPopupReducer.rolesData,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    addMemberToOrg: (data: Payload, memberCallBack: () => void) =>
      dispatch(addMemberToOrg(data, memberCallBack)),
    editMemberToOrg: (id: number, jsonBody: Payload) => dispatch(editMemberToOrg(id, jsonBody)),
    deleteMember: (data: Payload, leave?: boolean, history?: History) =>
      dispatch(deleteMember(data, leave, history)),
    // fetchRolesAction: (payload) => dispatch(fetchRoles(payload)),
  };
};

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(MembersTab);