import React from 'react';
//import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import AvailableTab from './availabletab/AvailableTab';
import { RouteComponentProps } from 'react-router-dom';
import { StaticContext } from 'react-router';

// import { connect } from 'react-redux';
// import { fetchAddons } from '../../redux/actions'
// import withWidth from '@material-ui/core/withWidth';
//import {TabPanel} from "../../../../components/tabpanel/TabPanel"

// function a11yProps(index) {
//   return {
//     id: `vertical-tab-${ index }`,
//     'aria-controls': `vertical-tabpanel-${ index }`,
//   };
// }

/* istanbul ignore next */
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'inherit',
    display: 'flex',
    padding : '0',
    margin : '0',
    [ theme.breakpoints.down('md') ]: {
      flexDirection : "column",
    }
  },
  // tabs: {
  //   borderRight: `1px solid ${ theme.palette.divider }`,
  //   [ theme.breakpoints.down('md') ]: {
  //     backgroundColor:'white'
  //   }
  // },
  // tabPanel :  {
  //   padding : '0',
  //   margin : '0',
  //   width : '100%',
  //   minWidth:250,
  // }
}));
type LocationState = {
	addonEnvId: number;
  };
interface Iprops {
 history: RouteComponentProps<{}, StaticContext, LocationState> ["history"];
  changeHash:(hash:string,value:number,envId:number,delay?:number)=>void;
}

const AddonsTab = (props:Iprops) => {
  const classes = useStyles();
  //const [ value, setValue ] = React.useState(0);
  // const [pluginId, setPluginId] = React.useState(props.environmentDetails && props.environmentDetails.plugin_version && props.environmentDetails.plugin_version.plugin_id)
  // const [envId, setEnvId] = React.useState(props.environmentDetails && props.environmentDetails.id)

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  // useEffect(()=> {
  //   if(pluginId)
  //   props.fetchAddons(pluginId)
  // }, [pluginId])

  return (
      <div className={ classes.root } data-test="addons-tab-container">
          {/* <Tabs
        orientation= { isWidthDown('md', props.width) ? "" : "vertical" }
        value={ value }
        variant="scrollable"
        onChange={ handleChange }
        className={ classes.tabs }
        indicatorColor="primary"
      >
              <Tab label="Available" { ...a11yProps(0) } />
              <Tab label="Installed" { ...a11yProps(1) } />
           
          </Tabs> */}
          {/* <TabPanel value={ value } index={ 0 } className={ classes.tabPanel }> */}
          <AvailableTab history={props.history} changeHash={props.changeHash} data-test="availabletab" />
          {/* </TabPanel> */}
          {/* <TabPanel value={ value } index={ 1 } className={ classes.tabPanel }>
              <InstalledTab history={props.history} />
          </TabPanel> */}
        
      </div>
  );
}

// const mapStateToProps = (state) => ({
//   addons: state.EnvironmentReducer.addons,
//   environmentDetails: state.EnvironmentReducer.environmentDetails,
// })
// const mapDispatchToProps = (dispatch) => ({
//   fetchAddons: (id) => dispatch(fetchAddons(id)),
// })

// export default  connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withWidth()(AddonsTab));

export default AddonsTab;