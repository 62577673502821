import React, { Component } from "react";
import {
  Typography,
  Link,
  Grid,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  //Tooltip,
  Menu,
  MenuItem,
  IconButton,
  Icon,
} from "@material-ui/core";
import "./domaintab.css";
import DnsPopup from "../../../../../components/dnspopup/DnsPopup";
import { fetchEnvVariables, updateEnvVariables, updateCustomDomain, updateDomainStatus } from "../../../redux/actions"; //recheckDomain 
import { withTranslation, WithTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { connect, ConnectedProps } from "react-redux";
import Skeleton from "react-loading-skeleton";
import MuiTextField from "../../../../../components/textfield/MuiTextField";
import BackdropLoader from "../../../../../components/loader/BackdropLoader";
import CloseIcon from "@material-ui/icons/Close";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ConfirmDeletePopup from "../../../../../components/confirmdeletepopup/ConfirmDeletePopup";
import WarningIcon from "@material-ui/icons/Warning";
//import LockIcon from "@material-ui/icons/Lock";
import { getDateInStandardFormat, isAuthorized } from "../../../../../helpers/utils";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LaunchIcon from '@material-ui/icons/Launch';
import DomainInfoCard from "../../../../../components/domainInfoCard/DomainInfoCard";
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { AppConstants } from "../../../../../constants/appconstants";
import { Dispatch } from "redux";
import { WithStyles, createStyles } from "@material-ui/core";

/* istanbul ignore next */
const useStyles = () => createStyles({});

interface Props extends WithTranslation, PropsFromRedux, WithStyles<typeof useStyles> {}

type State = {
  dnspopupOpen: boolean,
  custompopupOpen: boolean,
  domain_name: string,
  domainMode: number,
  isDomainFieldError: boolean,
  DomainFieldErrorMessage: string,
  isSaveButtonDisabled: boolean,
  isDomainNameDeleteConfirmPopupOpen: boolean,
  loaderMsg: string,
  dnsReady: boolean,
  clients_fqdn: string,
  anchorEl: any
}

export class DomainTab extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      dnspopupOpen: false,
      custompopupOpen: false,
      domain_name: "",
      domainMode: 0,
      isDomainFieldError: false,
      DomainFieldErrorMessage: "",
      isSaveButtonDisabled: true,
      isDomainNameDeleteConfirmPopupOpen: false,
      loaderMsg: "",
      dnsReady: false,
      clients_fqdn: "",
      anchorEl: null
    };
  }

  componentDidMount() {
    if (this.props.environmentDetails?.id > 0)
      this.props.fetchEnvVariables(this.props.environmentDetails.id);

    const cert =
      this.props.environmentState?.certificates &&
      this.props.environmentState?.certificates[0];
    const certCondition =
      cert &&
      cert.status?.conditions &&
      cert.status?.conditions[0];
    const certStatus = certCondition?.status === "True";
    this.setState({
      dnsReady: certStatus,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps) {
      if (nextProps.environmentDetails.id > 0) {
        if (
          !this.props.environmentDetails.id ||
          this.props.environmentDetails.id !== nextProps.environmentDetails.id
        ) {
          this.props.fetchEnvVariables(nextProps.environmentDetails.id);
        }
      }
      if (nextProps.environmentVariables !== this.props.environmentVariables) {
        const _variables = nextProps.environmentVariables;
        if (_variables && _variables.system_variables) {
          this.props.updateDomainStatus(_variables.system_variables.clients_fqdn_status);
          
          this.setState({
            clients_fqdn:
              _variables.system_variables.clients_fqdn ||
              _variables.system_variables.clients_fqdn_temp,
          });
        }
      }
      // if (
      //   this.props.updatingCustomDomain === true &&
      //   nextProps.updatingCustomDomain === false
      // ) {
      //   this.handleDomainSaveCancel();
      // }

      const cert =
        nextProps.environmentState?.certificates &&
        nextProps.environmentState?.certificates[0];
      const certCondition =
        cert &&
        cert.status?.conditions &&
        cert.status?.conditions[0]
      const certStatus = certCondition?.status === "True";
      this.setState({
        dnsReady: certStatus,
      });
    }
  }

  tooglednsOpen = () => {
    this.setState({
      dnspopupOpen: !this.state.dnspopupOpen,
    });
  };
  handlednsClose = () => {
    this.setState({
      dnspopupOpen: false,
    });
  };

  /* istanbul ignore next */
  tooglecustomOpen = () => {
    this.setState({
      custompopupOpen: !this.state.custompopupOpen,
    });
  };
  /* istanbul ignore next */
  handlecustomClose = () => {
    this.setState({
      custompopupOpen: false,
    });
  };

  handleAdd = () => {
    this.setState({
      domainMode: 1,
    });
  };

  handleDomainSave = () => {
    this.setState({
      loaderMsg: this.props.t('Environment.DomainTab.updaingDomainName'),
    });
    this.updateDomainNameData(this.state.domain_name);
  };

  updateCallBack = () => {
    // this.setState({
    //   updatingCustomDomain: false
    // })
    this.handleDomainSaveCancel();
  }

  updateDomainNameData = (domainName: string, isDelete?: boolean) => {
    let variables = this.props.environmentVariables;
    if (variables?.system_variables) {
      variables.system_variables.clients_fqdn_temp = domainName;
      variables.system_variables.clients_fqdn = domainName;
      if (isDelete) {
        variables.system_variables.clients_fqdn_status = '';
        variables.system_variables.clients_fqdn_secondary = '';
      }
    }
    if (isDelete)
      this.props.updateEnvVariables(this.props.environmentDetails.id, variables);
    else
      this.props.updateCustomDomain(this.props.environmentDetails.id, variables, this.updateCallBack);
  };

  handleDomainSaveCancel = () => {
    this.setState({
      domainMode: 0,
      domain_name: "",
    });
  };

  handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleDomainNameChange = (e: React.ChangeEvent<{value: string}>) => {
    if (e.target.value.length > 0) {
      if (e.target.value.length > 0 && e.target.value.length < 2) {
        this.setState({
          isSaveButtonDisabled: true,
          isDomainFieldError: true,
          DomainFieldErrorMessage: this.props.t('Projects.ProjectsTab.tooShortError'),
        });
      } else if (e.target.value.length > 80) {
        this.setState({
          isSaveButtonDisabled: true,
          isDomainFieldError: true,
          DomainFieldErrorMessage: this.props.t('Projects.ProjectsTab.tooLongError'),
        });
      } else if (!/([a-z0-9|-]+\.)*[a-z0-9|-]+\.[a-z]+/.test(e.target.value)) {
        this.setState({
          isSaveButtonDisabled: true,
          isDomainFieldError: true,
          DomainFieldErrorMessage: this.props.t('Environment.DomainTab.validDomainName'),
        });
      } else {
        this.setState({
          isSaveButtonDisabled: false,
          isDomainFieldError: false,
          DomainFieldErrorMessage: "",
        });
      }
    } else {
      this.setState({
        isSaveButtonDisabled: true,
        isDomainFieldError: true,
        DomainFieldErrorMessage: this.props.t('Environment.DomainTab.cannotBeEmpty'),
      });
    }

    this.setState({
      domain_name: e.target.value,
    });
  };

  /* istanbul ignore next */
  handleDomainNameEdit = () => {
    this.handleMenuClose();
    this.setState({
      domainMode: 1,
      domain_name: this.state.clients_fqdn,
    });
  };

  //Domain name delete section start
  /* istanbul ignore next */
  handleDomainNameDelete = () => {
    this.handleMenuClose();
    this.setState({
      isDomainNameDeleteConfirmPopupOpen: true,
    });
  };

  handleDomainNameDeleteAgreeHandler = () => {
    this.updateDomainNameData("", true);
    this.setState({
      loaderMsg: this.props.t('Environment.DomainTab.deletingDomain'),
      isDomainNameDeleteConfirmPopupOpen: false,
    });
  };
  handleDomainNameDeleteDisAgreeHandler = () => {
    this.setState({
      isDomainNameDeleteConfirmPopupOpen: false,
    });
  };

  // recheckCallBack = () => {
  //   this.setState({
  //     isReChecking: false
  //   });
  // }

  recheckDomain = () => {
    // this.setState({
    //   isReChecking: true
    // });
    // this.props.recheckDomain(this.props.environmentDetails.id, this.recheckCallBack);
    this.updateDomainNameData(this.state.clients_fqdn);
  }

  //Domain name delete section end
  render() {
    const { environmentState, environmentVariables, environmentDetails, t } = this.props;
    
    return (
        <div data-test="domain-tab-div">
            <Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                      <DomainInfoCard environmentState={environmentState} tab="Domain" envRole={this.props.envRole} environmentDetails={environmentDetails}/>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                      {environmentState?.state ? (
                            <Card data-test="env-domain-card" className="customCard">
                                <CardHeader title={t('Environment.DomainTab.customDomain')} />
                                <Divider />
                                <CardContent>
                                    {environmentVariables?.system_variables && (
                                    <Grid container alignItems="center" spacing={2}>
                                        {!this.state.clients_fqdn && (
                          <>
                              {this.state.domainMode === 0 && (
                              <>
                                  <Grid item md={9}>
                                  {t('Environment.DomainTab.useDomain')}
                                  </Grid>
                                  {isAuthorized("update", this.props.envRole?.name) &&
                                  <Grid item md={2}>
                                      <Button
                                        data-test="set-btn"
                                        variant="contained"
                                        color="primary"
                                        onClick={this.handleAdd}
                                      >
                                          {t('Environment.DomainTab.set')}
                                      </Button>
                                  </Grid>
                                 }
                              </>
                              )}
                          </>
                          )}
                          {this.state.clients_fqdn && (
                                      <>
                                        {this.state.domainMode === 0 && (
                                      <>
                                        <Grid item md={7}>
                                          {
                                            <Link
                                              data-test="custom-domain"
                                              target="_blank"
                                              rel="noreferrer"
                                              href={`//${this.state.clients_fqdn}`}
                                            >
                                              {this.state.clients_fqdn}
                                              <LaunchIcon className="openLinkIcon" />
                                            </Link>
                                          }
                                        </Grid>
                                        <Grid item md={3} className="checkdns">
                                          {!this.state.dnsReady &&
                                        <>
                                          <Icon>
                                            <WarningIcon className="warningIcon" fontSize="small" />
                                          </Icon>
                                          <Link data-test="check-dns" onClick={this.tooglednsOpen}>
                                          {t('Environment.DomainTab.checkDns')}
                                          </Link>
                                        </>
                                  }
                                        </Grid>
                                        <Grid item md={2}>
                                          <IconButton
                                          className="right"
                                          data-test="menu-btn"
                                          onClick={(e: React.MouseEvent<HTMLElement>) => this.handleMenuClick(e)}
                                          >
                                            <MoreVertIcon />
                                          </IconButton>
                                          <Menu
                                          data-test="custom-dns-menu"
                                          anchorEl={this.state.anchorEl}
                                          disableScrollLock={true}
                                          open={Boolean(this.state.anchorEl)}
                                          onClose={() => this.handleMenuClose()}
                                          getContentAnchorEl={null}
                                          anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "center",
                                    }}
                                          transformOrigin={{
                                      vertical: "top",
                                      horizontal: "center",
                                    }}
                                          >
                                            {isAuthorized("update", this.props.envRole?.name) &&
                                          <MenuItem
                                          onClick={() =>
                                          this.handleDomainNameEdit()
                                        }
                                          >
                                          {t('Environment.DomainTab.edit')}
                                          </MenuItem>
                                    }
                                            {isAuthorized("delete", this.props.envRole?.name) &&
                                          <MenuItem
                                          onClick={() =>
                                          this.handleDomainNameDelete()
                                        }
                                          >
                                           {t('Environment.DomainTab.delete')}
                                          </MenuItem>
                                    }
                                            {this.state.dnsReady &&
                                          <MenuItem onClick={this.tooglednsOpen}>
                                          {t('Environment.DomainTab.dnsConfigurations')}
                                          </MenuItem>
                                    }
                                          </Menu>
                                        </Grid>
                                      </>
                            )}
                                      </>
                        )}
                                        {this.state.domainMode === 1 && (
                                      <>
                                        <Grid data-test="domain-input-field" item md={7}>
                                          <MuiTextField
                                          name="domain_name"
                                          data-test="domain-name-field"
                                //defaulValue = "Domain name"
                                          onChange={(e: React.ChangeEvent<{value: string}>) => this.handleDomainNameChange(e)}
                                          value={this.state.domain_name}
                                          error={this.state.isDomainFieldError}
                                          helperText={this.state.DomainFieldErrorMessage}
                                          autoFocus
                                          label={t('Environment.DomainTab.domainName')}
                                          // size="small"
                                          />
                                        </Grid>
                                        <Grid item md={3}>
                                          <Button
                                          data-test="save-btn"
                                          variant="contained"
                                          color="primary"
                                          onClick={this.handleDomainSave}
                                          disabled={
                                            this.state.isSaveButtonDisabled ||
                                            this.state.clients_fqdn ===
                                              this.state.domain_name
                                          }
                                          >
                                  Save
                                          </Button>
                                        </Grid>
                                        <Grid item md={2}>
                                          <IconButton
                                          className="right"
                                          data-test="save-cancel-btn"
                                          onClick={this.handleDomainSaveCancel}
                                          >
                                            <CloseIcon />
                                          </IconButton>
                                        </Grid>
                                      </>
                        )}
                                    </Grid>
                    )}
                                    <DnsPopup
                                      open={this.state.dnspopupOpen}
                                      handleClose={this.handlednsClose}
                                      environmentVariables={environmentVariables}
                                      environmentState={environmentState}
                                    />
                                </CardContent>
                            </Card>
              ) : (
                  <>
                      <Card className="skeletoncard">
                          <CardContent>
                              <Skeleton height={20} width="80%" />
                              <Skeleton height={20} width="100%" />
                          </CardContent>
                      </Card>
                  </>
                )
              }
                    </Grid>
            {environmentState?.state ? (
                        <Grid data-test="env-certificate-card" item xs={12} md={12}>
                            <Card>
                                <CardContent>
                                    <Grid container spacing={3} className="m-b-10" alignItems="center">
                                        <Grid item md={12}>
                                            <Grid container spacing={2} alignItems="center">
                                              <Grid item>
                                                <LockOutlinedIcon fontSize="small" className="greenIcon" />
                                              </Grid>
                                              <Grid item>
                                                <Typography variant="h5" className="m-b-10">{t('Environment.DomainTab.certificate')}</Typography>
                                              </Grid>
                                            </Grid>
                                            <Divider />
                                        </Grid>
                                    </Grid>
                                    {
                      !this.state.clients_fqdn ? (
                          <Grid container spacing={2} className="m-10" >
                              <Grid item md={12}>
                                  <Typography variant="h5" >
                                  {t('Environment.DomainTab.customDomainCert')}
                                  </Typography>

                              </Grid>
                              <Grid item md={12}>
                                  <Typography variant="body1">
                                    {t('Environment.DomainTab.enableHttps')}
                                  </Typography>
                              </Grid>
                          </Grid>
                      ) : (
                          <>
                              {this.state.dnsReady &&
                              environmentState &&
                              environmentState.certificates &&
                              environmentState.certificates[0] ? (
                                <>
                                  <Typography gutterBottom variant="h5" >
                                    {t('Environment.DomainTab.automaticTLS')}
                                  </Typography>
                                  <Grid>
                                      <Grid container spacing={3}>
                                          <Grid item xs={6} md={2}>
                                              <Typography color="primary" variant="h6">  {t('Environment.DomainTab.cert')} </Typography>

                                          </Grid>
                                          <Grid item xs={6} md={10}>
                                          {t('Environment.DomainTab.letsEncrypt')}
                                          </Grid>
                                      </Grid>
                                      <Grid container>
                                          <Grid item xs={6} md={2}>
                                              <Typography color="primary" variant="h6">{t('Environment.DomainTab.domains')} </Typography>
                                          </Grid>
                                          <Grid item xs={6} md={10}>
                                        {environmentState.certificates[0].spec &&
                                          environmentState.certificates[0].spec
                                          .commonName}
                                          </Grid>
                                      </Grid>
                                      <Grid container>
                                          <Grid item xs={6} md={2}>
                                              <Typography color="primary" variant="h6">{t('Environment.DomainTab.created')} </Typography>
                                          </Grid>
                                          <Grid item xs={6} md={10}>
                                        {environmentState.certificates[0].metadata &&
                                        getDateInStandardFormat(
                                          environmentState.certificates[0].metadata
                                            .creationTimestamp
                                        )}
                                          </Grid>
                                      </Grid>
                                    {environmentState.certificates[0].status &&
                                      environmentState.certificates[0].status
                                      .notAfter && (
                                      <Grid container>
                                          <Grid item xs={6} md={2}>
                                              <Typography color="primary" variant="h6">{t('Common.expiryDate')} </Typography>
                                          </Grid>
                                          <Grid item xs={6} md={10}>
                                              {getDateInStandardFormat(
                                                environmentState.certificates[0].status
                                              .notAfter
                                          )}
                                          </Grid>
                                      </Grid>
                                    )}
                                  </Grid>
                                </>
                            ) : (
                                <>
                                  {
                                    (!this.props.domainStatus || this.props.domainStatus === AppConstants.DomainStatus.Processing || this.props.domainStatus === AppConstants.DomainStatus.Pending) &&
                                    <Alert severity="info">
                                      <AlertTitle>
                                        <Typography variant="h5">
                                          {t('Environment.DomainTab.certificateReady')}
                                        </Typography>
                                      </AlertTitle>
                                      <Typography variant="body1">
                                        {t('Environment.DomainTab.automaticTLS')}
                                      </Typography>
                                    </Alert>
                                  }
                                  {
                                    this.props.domainStatus === AppConstants.DomainStatus.Failed && 
                                    <Alert
                                      severity="error"
                                      action={
                                        <Button variant="contained" color="primary" onClick={() => this.recheckDomain()}>
                                          {t("ReCheck")}
                                        </Button>
                                      }
                                    >
                                      <AlertTitle>
                                        <Typography variant="h5" color="error">Failed</Typography>
                                      </AlertTitle>
                                      <Typography variant="body1">
                                        {t('Environment.DomainTab.provisioningFailed')}
                                      </Typography>
                                    </Alert>
                                  }
                                </>
                            )}          
                          </>
                        )}
                                </CardContent>
                            </Card>
                        </Grid>
            ) : (
                <>
                    <Grid item md={12}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={2} className="m-10" >
                                    <Grid item md={12}>
                                        <Typography variant="h5" >
                                            <Skeleton height={20} width="100%" />
                                        </Typography>
                                    </Grid>
                                    <Grid item md={12}>
                                        <Typography variant="body1">
                                            <Skeleton height={20} width="100%" />
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </>
              )
            }
                </Grid>
            </Grid>
        {(this.props.updatingVariables || this.props.updatingCustomDomain) && (
            <div className="loader-center">
                <BackdropLoader message={this.state.loaderMsg} />
            </div>
        )}
            <ConfirmDeletePopup
            data-test="domain-delete-popup"
              open={this.state.isDomainNameDeleteConfirmPopupOpen}
              handleAgree={this.handleDomainNameDeleteAgreeHandler}
              handleDisAgree={this.handleDomainNameDeleteDisAgreeHandler}
              message={`This action will remove the custom domain name. Please type "${
            environmentVariables &&
            environmentVariables.system_variables &&
            this.state.clients_fqdn
            }" to remove the custom domain : ${
            environmentVariables &&
            environmentVariables.system_variables &&
            this.state.clients_fqdn
            }`}
              // yesText={t('Projects.VariablesTab.yesText')}
              // noText={t('Projects.VariablesTab.noText')}
              toMatchName={
            environmentVariables &&
            environmentVariables.system_variables &&
            this.state.clients_fqdn
          }
              toDeleteModule="custom domain"
              loading={this.props.updatingVariables}
            />
            {this.props.fetchingEnvVar && <BackdropLoader message={t('Environment.DomainTab.fetchingDomainInfo')} />}
        </div>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  environmentDetails: state.EnvironmentReducer.environmentDetails,
  environmentState: state.EnvironmentReducer.environmentState,
  environmentVariables: state.EnvironmentReducer.environmentVariables,
  updatingVariables: state.EnvironmentReducer.updatingVariables,
  fetchingEnvVar: state.EnvironmentReducer.fetchingEnvVar,
  envRole: state.EnvironmentReducer.envRole,
  updatingCustomDomain: state.EnvironmentReducer.updatingCustomDomain,
  domainStatus: state.EnvironmentReducer.domainStatus
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    fetchEnvVariables: (id: number) => dispatch(fetchEnvVariables(id)),
    updateEnvVariables: (id: number, payload: any) => dispatch(updateEnvVariables(id, payload)),
    updateCustomDomain: (id: number, payload: any, callBack:() => void) => dispatch(updateCustomDomain(id, payload, callBack)),
    updateDomainStatus: (status: string) => dispatch(updateDomainStatus(status))
    //recheckDomain: (id, callBack) => dispatch(recheckDomain(id, callBack))
  };
};

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withStyles(useStyles)(withTranslation()(DomainTab)));