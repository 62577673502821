import { call, takeLatest, put, select } from 'redux-saga/effects';
import axios from 'axios';
import endpoints from '../../../constants/endpoints';
import { sessionTokenSelector } from '../../login/redux/selectors'
import { 
  FETCH_SEARCH_RESULT,
  FETCH_SEARCH_RESULT_SUCCESS
 } from './actions';
import Toast from '../../../components/toast/Toast';

function searchResultCall(sessionToken , payload) {
   const config = {
    headers : {
      'Authorization': 'basic ' + sessionToken
    }
  }
  return axios.get(endpoints.SEARCH.GET_RESULT.replace(':query' , payload.data.keyword), config)
}

// Generator Call 
function* fetchSearchResult(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const response = yield call(searchResultCall, sessionToken , payload)
    const data = response.data;

    if (data !== null) {
      yield put({ type: FETCH_SEARCH_RESULT_SUCCESS, data })
    } else {
      Toast.error('Search failed. Please try again.')      
    }
  } catch (error) {
    if(error && error.response && error.response.data && error.response.data.error)
      Toast.error(error.response.data.error);
    else
      Toast.error('Search failed. Please try again.')
    }
}

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(FETCH_SEARCH_RESULT, fetchSearchResult);
}