import {
    Grid,
    Typography,
    Select,
    Paper,
    FormControl,
    MenuItem,
    InputLabel,
    IconButton,
    Divider
} from "@material-ui/core";
import { GridSize } from "@material-ui/core/Grid";
import { Dispatch } from "redux";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import ChartCatalogCard from "./ChartCatalogCard";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect, ConnectedProps } from "react-redux";
import {
    getChartCatalogs,
    clearChartCatalogs,
    getCatalogCatagories,
    getChartRepos,
} from "../../pages/organization/redux/actions";
import BackdropLoader from "../loader/BackdropLoader";
// import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Skeleton } from "@material-ui/lab";

import NoContentImage from "../nocontentimagecontainer/NoContentImage";
import SearchField from "../searchfield/SearchField";
import { CatalogModel, ChartRepoModel } from './../../models/HelmCharts.model'
/* istanbul ignore next */
const useStyles = makeStyles({
    dropdown: {
        minWidth: 100,
        height: 55,
    },
    container: {
      
        minHeight: "20rem",
    },
    paperSkeletonContainer:{
     padding:10
    },
    skeletonHeader:{paddingTop:10,paddingBottom:10},
    skeletonBody:{
        paddingTop: 10,
        paddingBottom: 10,
      },
});

const size = 5;


interface Props extends PropsFromRedux {
    title: string;
    cardMd: number;
    xs?: GridSize;
    onClick?: (cat: CatalogModel) => void;
}
export function ChartCatalogContainer(props: Props) {
    const classes = useStyles();
    const [t] = useTranslation();
    const [searchText, setSearchText] = React.useState("");
    const [category, setCategory] = React.useState("all");
    const [repository, setRepository] = React.useState("all");
    const [page, setPage] = React.useState(1);
    const [reset, setReset] = React.useState(false);
    const [clearSearch, setClearSearch] = React.useState(false);

    const fetchCatalogsCall = (newpage?: number) => {
        props.getChartCatalogs(newpage ?? page, searchText, category, repository);
    };

    React.useEffect(() => {
        props.getCatalogCatagories();
        props.getChartRepos();
        return () => {
            props.clearChartCatalogs();
        };
    }, []);

    React.useEffect(() => {
        if (reset) {
            setReset(false);
        }
    }, [props.chartCatalogs]);

    const fetchMoreCatalogsCall = () => {
        setPage(page + 1);
    };

    React.useEffect(() => {
        fetchCatalogsCall();
    }, [page]);

    React.useEffect(() => {
        if (reset) {
            setSearchText("");
        }
    }, [reset]);

    const handleSearch = (st: string) => {
        props.getChartCatalogs(page, st, category, repository);
    };

    const handleSearchChange = (st: string) => {
        setSearchText(st);
        if (searchText.trim()?.length === 0) {
            setClearSearch(true);
        } else {
            setClearSearch(false);
        }
    };

    const handleCategoryChange = (
        e: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ) => {
        setCategory(e.target.value as string);
        props.getChartCatalogs(1, searchText, e.target.value as string, repository);
    };

    const handleRepoChange = (
        e: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ) => {
        setRepository(e.target.value as string);
        props.getChartCatalogs(1, searchText, category, e.target.value as string);
    };

    const resetFilters = () => {
        setRepository("all");
        setCategory("all");
        setPage(1);
        setReset(true);
        props.getChartCatalogs(1, "", "all", "all");
    };

    const isResetValid = () => {
        if (
            category !== "all" ||
            repository !== "all" ||
            searchText.trim().length
        ) {
            return true;
        }
        return false;
    };

    const handleCatalogClick = (catalog: CatalogModel) => {
        if (props.onClick) {
            props.onClick(catalog);
        }
    };

    const filters = (
        <Grid container spacing={2} justify="flex-end">
            {isResetValid() && (
                <Grid item xs={12} sm={2} md={1}>
                    <Grid container justify="flex-end">
                        <IconButton onClick={() => resetFilters()} data-test="reset-button">
                            <Typography variant="h5">
                                {t("Organization.ChartCatalog.reset")}
                            </Typography>
                        </IconButton>
                    </Grid>
                </Grid>
            )}
            <Grid item xs={12} sm={4} md={3}>
                <SearchField
                    label={t("Organization.ChartCatalog.search")}
                    search={searchText}
                    handleSearch={handleSearch}
                    handleSearchChange={handleSearchChange}
                    data-test="search-box"
                />
            </Grid>

            <Grid item xs={12} sm={3} md={2}>
                <FormControl
                    variant="outlined"
                    className={`w-100`}
                >
                    <InputLabel>{t("Organization.ChartCatalog.category")}</InputLabel>

                    <Select
                        value={category}
                        onChange={(e) => handleCategoryChange(e)}
                        label={t("Organization.ChartCatalog.category")}
                        className={classes.dropdown}
                        data-test="category-select"
                    >
                        <MenuItem value="all">
                            <em>{t("Support.SupportPage.all")}</em>
                        </MenuItem>
                        {props.catalogCategories?.map((cat: string, ind: number) => (
                            <MenuItem value={cat} key={ind}>
                                <em>{cat}</em>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
                <FormControl
                    variant="outlined"
                    className={`w-100`}
                >
                    <InputLabel>{t("Organization.ChartCatalog.repository")}</InputLabel>

                    <Select
                        value={repository}
                        onChange={(e) => handleRepoChange(e)}
                        data-test="repo-select"
                        className={classes.dropdown}
                        label={t("Organization.ChartCatalog.repository")}
                    >
                        <MenuItem value="all">
                            <em>{t("Support.SupportPage.all")}</em>
                        </MenuItem>
                        {props.chartRepos?.map((repo: ChartRepoModel, ind: number) => (
                            <MenuItem value={repo.id} key={ind}>
                                <em>{repo.name}</em>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );

    return (
        <div data-test="main-container">
            <Grid container className="listContainer" >
                <Grid item xs={12} md={2}>
                    <Typography color="textPrimary" variant="h5" data-test="chart-title">
                        {props.title ?? t("Organization.ChartCatalog.chartCatalog")}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                    {filters}
                </Grid>
            </Grid>
            {
                (!props.chartCatalogs || props.fetchingChartCatalogs) && <Grid
                container
                spacing={2}
                //alignItems="center"
                className={classes.container}
            >
                {[0, 1, 2, 3, 4, 5, 6, 7].map((idx) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        xl={3}
                        key={idx}
                        data-test="loading-skeleton"
                      >
                        <Paper className={classes.paperSkeletonContainer}>
                          <div className={classes.skeletonHeader}>
                            <Skeleton
                              animation="wave"
                              height={10}
                              width="40%"
                            />
                          </div>
                          <Divider />
                          {/* <SkeletonTheme height={250}> */}
                          <div
                            className={classes.skeletonBody}
                          >
                            <Grid
                              container
                              spacing={2}
                              //alignItems="center"
                            >
                              <Grid item xs={12} sm={6} md={3} xl={3}>
                                <Skeleton
                                  variant="rect"
                                  width="100%"
                                  height={100}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={9} xl={9}>
                                <Skeleton
                                  animation="wave"
                                  height={10}
                                  width="80%"
                                />
                              </Grid>
                            </Grid>
                          </div>
                          <Divider />

                          <div className={classes.skeletonHeader}>
                            <Skeleton
                              animation="wave"
                              height={10}
                              width="40%"
                            />
                          </div>
                          {/* </SkeletonTheme> */}
                        </Paper>
                      </Grid>
                    );
                })}
            </Grid>
            }
            
         
            {Boolean(props.chartCatalogs) &&
                props.chartCatalogs?.length === 0 &&
                !isResetValid() &&
                !reset &&
                !clearSearch ? (
                <NoContentImage
                    message="No Helm Charts"
                    alt="No helm charts"
                    data-test="no-charts"
                />
            ) : (
                <InfiniteScroll
                    dataLength={props.chartCatalogs?.length ?? 0}
                    next={fetchMoreCatalogsCall}
                    hasMore={props.chartCatalogs?.length === page * size}
                    scrollThreshold={0.95}
                    loader=""
                    style={{ overflow: "hidden" }}
                    scrollableTarget="scrollableDiv"
                    data-test="infinite-scroll"
                >
                    <Grid container spacing={3}>
                        {props.chartCatalogs?.length &&
                            props.chartCatalogs.map((catalog: CatalogModel, ind: number) => (
                                <Grid
                                    item
                                    xs={props.xs ?? 12}
                                    md={(props.cardMd as GridSize) ?? 4}
                                    key={ind}
                                >
                                    <ChartCatalogCard
                                        catalog={catalog}
                                        onClick={handleCatalogClick}
                                        data-test="chart-card"
                                        isClickable = {true}
                                    />
                                </Grid>
                            ))}
                    </Grid>
                    {props.chartCatalogs?.length === 0 && isResetValid() && (
                        <NoContentImage
                            message="No Helm Charts for given filter"
                            alt="No helm charts"
                        />
                    )}
                </InfiniteScroll>
            )}
            {props.fetchingChartCatalogs && (
                <BackdropLoader message="Fetching catalogs" />
            )}
        </div>
    );
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
    chartCatalogs: state.OrganizationReducer.chartCatalogs,
    fetchingChartCatalogs: state.OrganizationReducer.fetchingChartCatalogs,
    catalogCategories: state.OrganizationReducer.catalogCategories,
    chartRepos: state.OrganizationReducer.chartRepos,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
    getChartCatalogs: (
        page: number,
        search: string,
        category: string,
        repository: string
    ) => dispatch(getChartCatalogs(page, search, category, repository)),
    clearChartCatalogs: () => dispatch(clearChartCatalogs()),
    getCatalogCatagories: () => dispatch(getCatalogCatagories()),
    getChartRepos: () => dispatch(getChartRepos()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(ChartCatalogContainer);
