import { 
  FETCH_SEARCH_RESULT_SUCCESS
} from './actions';
import initialState from './initialState';

const reducer = (state = initialState, payload ) => {
  switch (payload.type) {
    case FETCH_SEARCH_RESULT_SUCCESS:
      return {
        ...state,
        searchResult : payload.data
    }
    default:
      return state
  }
}

export default reducer