const PODS_PREFIX = "@PODS";

export const FETCH_PODS = `${PODS_PREFIX}/FETCH_PODS`;
export const FETCH_PODS_SUCCESS = `${PODS_PREFIX}/FETCH_PODS_SUCCESS`;
export const FETCH_PODS_FAILURE = `${PODS_PREFIX}/FETCH_PODS_FAILURE`;
export const CLEAR_PODS = `${PODS_PREFIX}/CLEAR_PODS`;

export const UPDATE_POD_LABELS = `${PODS_PREFIX}/UPDATE_POD_LABELS`;
export const UPDATE_POD_LABELS_SUCCESS = `${PODS_PREFIX}/UPDATE_POD_LABELS_SUCCESS`;
export const UPDATE_POD_LABELS_FAILURE = `${PODS_PREFIX}/UPDATE_POD_LABELS_FAILURE`;
export const CLEAR_UPDATE_POD_LABELS = `${PODS_PREFIX}/CLEAR_UPDATE_POD_LABELS`;

export const FETCH_POD_INSIGHTS = `${PODS_PREFIX}/FETCH_POD_INSIGHTS`;
export const FETCH_POD_INSIGHTS_SUCCESS = `${PODS_PREFIX}/FETCH_POD_INSIGHTS_SUCCESS`;
export const FETCH_POD_INSIGHTS_FAILURE = `${PODS_PREFIX}/FETCH_POD_INSIGHTS_FAILURE`;

export const FETCH_CONTAINER_INSIGHTS = `${PODS_PREFIX}/FETCH_CONTAINER_INSIGHTS`;
export const FETCH_CONTAINER_INSIGHTS_SUCCESS = `${PODS_PREFIX}/FETCH_CONTAINER_INSIGHTS_SUCCESS`;
export const FETCH_CONTAINER_INSIGHTS_FAILURE = `${PODS_PREFIX}/FETCH_CONTAINER_INSIGHTS_FAILURE`;
export const CLEAR_CONTAINER_INSIGHTS = `${PODS_PREFIX}/CLEAR_CONTAINER_INSIGHTS`;

export const FETCH_CONTAINER_LOGS_URL = `${PODS_PREFIX}/FETCH_CONTAINER_LOGS_URL`;
export const FETCH_CONTAINER_LOGS_URL_SUCCESS = `${PODS_PREFIX}/FETCH_CONTAINER_LOGS_URL_SUCCESS`;
export const FETCH_CONTAINER_LOGS_URL_FAILURE = `${PODS_PREFIX}/FETCH_CONTAINER_LOGS_URL_FAILURE`;
export const CLEAR_CONTAINER_LOGS_URL = `${PODS_PREFIX}/CLEAR_CONTAINER_LOGS_URL`;

export const FETCH_CONTAINER_WEBSHELL_URL = `${PODS_PREFIX}/FETCH_CONTAINER_WEBSHELL_URL`;
export const FETCH_CONTAINER_WEBSHELL_URL_SUCCESS = `${PODS_PREFIX}/FETCH_CONTAINER_WEBSHELL_URL_SUCCESS`;
export const FETCH_CONTAINER_WEBSHELL_URL_FAILURE = `${PODS_PREFIX}/FETCH_CONTAINER_WEBSHELL_URL_FAILURE`;
export const CLEAR_CONTAINER_WEBSHELL_URL = `${PODS_PREFIX}/CLEAR_CONTAINER_WEBSHELL_URL`;

export const FETCH_POD_EVENTS = `${PODS_PREFIX}/FETCH_POD_EVENTS`;
export const FETCH_POD_EVENTS_SUCCESS = `${PODS_PREFIX}/FETCH_POD_EVENTS_SUCCESS`;
export const CLEAR_POD_EVENTS = `${PODS_PREFIX}/CLEAR_POD_EVENTS`;

export const FETCH_INSIGHT_GRAPH_DATA = `${PODS_PREFIX}/FETCH_INSIGHT_GRAPH_DATA`;
export const FETCH_INSIGHT_GRAPH_DATA_SUCCESS = `${PODS_PREFIX}/FETCH_INSIGHT_GRAPH_DATA_SUCCESS`;
export const FETCH_INSIGHT_GRAPH_DATA_FAILURE = `${PODS_PREFIX}/FETCH_INSIGHT_GRAPH_DATA_FAILURE`;
export const CLEAR_INSIGHT_GRAPH_DATA = `${PODS_PREFIX}/CLEAR_INSIGHT_GRAPH_DATA`;

export const RESTART_POD = `${PODS_PREFIX}/RESTART_POD`;

const fetchPods = (envId, isHelmEnv) => ({
  type: FETCH_PODS,
  data: { envId, isHelmEnv },
});
const clearPods = () => ({
  type: CLEAR_PODS,
});

const updatePodLabels = (envId, labelData, podName) => ({
  type: UPDATE_POD_LABELS,
  data: { envId, labelData, podName },
});

const clearUpdatePodLabels = () => ({
  type: CLEAR_UPDATE_POD_LABELS,
});

const fetchPodInsights = () => ({
  type: FETCH_POD_INSIGHTS,
});

const fetchContainerInsights = (envId, podName) => ({
  type: FETCH_CONTAINER_INSIGHTS,
  data: { envId, podName },
});

const clearContainerInsights = () => ({
  type: CLEAR_CONTAINER_INSIGHTS,
});

const fetchContainerLogsUrl = (jsonBody, isHelmEnv, callback) => ({
  type: FETCH_CONTAINER_LOGS_URL,
  data: { jsonBody, isHelmEnv, callback },
});

const clearContainerLogsUrl = () => ({
  type: CLEAR_CONTAINER_INSIGHTS,
});

const fetchContainerWebshellUrl = (jsonBody, isHelmEnv, callback) => ({
  type: FETCH_CONTAINER_WEBSHELL_URL,
  data: { jsonBody, isHelmEnv, callback },
});

const clearContainerWebshellUrl = () => ({
  type: CLEAR_CONTAINER_WEBSHELL_URL,
});

const fetchPodEvents = (envId, isHelmEnv) => ({
  type: FETCH_POD_EVENTS,
  data: { envId, isHelmEnv },
});

const clearPodEvents = () => ({
  type: CLEAR_POD_EVENTS,
});

const fetchInsightGraphData = (envId, jsonBody) => ({
  type: FETCH_INSIGHT_GRAPH_DATA,
  data: { envId, jsonBody },
});

const clearInsightGraphData = () => ({
  type: CLEAR_INSIGHT_GRAPH_DATA,
});

const restartPod = (envId, jsonBody, callBack) => ({
  type: RESTART_POD,
  data: { envId, jsonBody, callBack },
});

export {
  fetchPods,
  updatePodLabels,
  clearUpdatePodLabels,
  fetchPodInsights,
  fetchContainerInsights,
  clearPods,
  clearContainerInsights,
  fetchContainerLogsUrl,
  clearContainerLogsUrl,
  fetchContainerWebshellUrl,
  clearContainerWebshellUrl,
  fetchPodEvents,
  clearPodEvents,
  fetchInsightGraphData,
  clearInsightGraphData,
  restartPod,
};
