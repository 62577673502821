import { call, takeLatest, put, select } from "redux-saga/effects";
import axios from "axios";
import endpoints from "../../../constants/endpoints";
import { sessionTokenSelector } from "../../login/redux/selectors";
import {
  FETCH_INIT_CONTAINER_DETAILS,
  FETCH_INIT_CONTAINER_DETAILS_FAILURE,
  FETCH_INIT_CONTAINER_DETAILS_SUCCESS,
  FETCH_INIT_CONTAINER_LIST,
  FETCH_INIT_CONTAINER_LIST_FAILURE,
  FETCH_INIT_CONTAINER_LIST_SUCCESS,
  CREATE_INIT_CONTAINER,
  CREATE_INIT_CONTAINER_FAILURE,
  CREATE_INIT_CONTAINER_SUCCESS,
  UPDATE_INIT_CONTAINER,
  UPDATE_INIT_CONTAINER_FAILURE,
  UPDATE_INIT_CONTAINER_SUCCESS,
  DELETE_INIT_CONTAINER,
  DELETE_INIT_CONTAINER_FAILURE,
  DELETE_INIT_CONTAINER_SUCCESS,
} from "./actions";
import toast from "../../../components/toast/Toast";

function initContainerListCall(sessionToken, payload) {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.get(
    endpoints.INIT_CONTAINER.FETCH_INIT_CONTAINERS.replace(
      ":eId",
      payload.data.eId
    ),
    config
  );
}

function* fetchInitContainerList(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(initContainerListCall, sessionToken, payload);
    const data = response.data;
    if (data !== null) {
      yield put({ type: FETCH_INIT_CONTAINER_LIST_SUCCESS, data });
    } else {
      yield put({ type: FETCH_INIT_CONTAINER_LIST_FAILURE });
    }
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    ) {
      toast.error(error.response.data.error);
      yield put({ type: FETCH_INIT_CONTAINER_LIST_FAILURE });
    }
  }
}

function initContainerDetailsCall(sessionToken, payload) {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.get(
    endpoints.INIT_CONTAINER.GET_INIT_CONTAINER_DETAILS.replace(
      ":eId",
      payload.data.eId
    ).replace(":cId", payload.data.cId),
    config
  );
}

function* fetchInitContainerDetails(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(
      initContainerDetailsCall,
      sessionToken,
      payload
    );
    const data = response.data;
    if (data !== null) {
      yield put({ type: FETCH_INIT_CONTAINER_DETAILS_SUCCESS, data });
    } else {
      yield put({ type: FETCH_INIT_CONTAINER_DETAILS_FAILURE });
    }
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    ) {
      toast.error(error.response.data.error);
      yield put({ type: FETCH_INIT_CONTAINER_DETAILS_FAILURE });
    }
  }
}

function createInitContainerCall(sessionToken, payload) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.post(
    endpoints.INIT_CONTAINER.CREATE_INIT_CONTAINER.replace(
      ":eId",
      payload.data.eId
    ),
    payload.data.jsonBody,
    config
  );
}

function* createInitContainer(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(createInitContainerCall, sessionToken, payload);
    const data = response.data;
    const message =  response.data?.message ?? "Created Successfully";
    if (data !== null) {
      toast.success(message);
      yield put({ type: CREATE_INIT_CONTAINER_SUCCESS });
      yield call(fetchInitContainerList, payload);

      //payload.data.history.push("/environment/" +  data.id + (payload.data.jsonBody.git_branch ? "#cicd" : "#activity"));
    } else {
      yield put({ type: CREATE_INIT_CONTAINER_FAILURE });
    }
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    )
      toast.error(error.response.data.error);
    else toast.error(" creation failed. Please try again");
    yield put({ type: CREATE_INIT_CONTAINER_FAILURE });
  }
}

function updateInitContainerCall(sessionToken, payload) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.put(
    endpoints.INIT_CONTAINER.UPDATE_INIT_CONTAINER.replace(
      ":eId",
      payload.data.eId
    ).replace(":cId", payload.data.cId),
    payload.data.jsonBody,
    config
  );
}

function* updateInitContainer(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(updateInitContainerCall, sessionToken, payload);
    console.log("response")
    console.log(response)
    const data = response.data;
    const message = response.data?.message ?? "Updated Successfully";

    if (data !== null) {
      toast.success(message);
      yield put({ type: UPDATE_INIT_CONTAINER_SUCCESS });
      yield call(fetchInitContainerList, payload);

      if (payload.data.callback) payload.data.callback();
    } else {
      yield put({ type: CREATE_INIT_CONTAINER_FAILURE });
    }
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    )
      toast.error(error.response.data.error);
    else toast.error(" update failed. Please try again");
    yield put({ type: UPDATE_INIT_CONTAINER_FAILURE });
  }
}

function deleteInitContainerCall(sessionToken, payload) {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.delete(
    endpoints.INIT_CONTAINER.DELETE_INIT_CONTAINER.replace(
      ":eId",
      payload.data.eId
    ).replace(":cId", payload.data.cId),
    config
  );
}

function* deleteInitContainer(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(deleteInitContainerCall, sessionToken, payload);
    const message =  response.data?.message ?? "Deleted Successfully";

    toast.success(message);
    yield put({ type: DELETE_INIT_CONTAINER_SUCCESS });
    yield call(fetchInitContainerList, payload);
    //payload.data.history.push((payload.data.app_id > 0 ?  "/app/" +  payload.data.app_id : "/projects" ))
  } catch (error) {
    yield put({ type: DELETE_INIT_CONTAINER_FAILURE });
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    )
      toast.error(error.response.data.error);
    else toast.error("Operation failed. Please try again");
  }
}

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(FETCH_INIT_CONTAINER_LIST, fetchInitContainerList);
  yield takeLatest(FETCH_INIT_CONTAINER_DETAILS, fetchInitContainerDetails);
  yield takeLatest(CREATE_INIT_CONTAINER, createInitContainer);
  yield takeLatest(UPDATE_INIT_CONTAINER, updateInitContainer);
  yield takeLatest(DELETE_INIT_CONTAINER, deleteInitContainer);
}
