import React, { useEffect, useState } from 'react'
import { connect,ConnectedProps } from 'react-redux'
import { Button, Card, CardHeader, CardContent, CardActions, Divider, Grid, Typography } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import KeyValueRow from "../../../components/keyvaluerow/KeyValueRow";
import PluginConfigForm from "../../../components/pluginconfigform/PluginConfigForm";
import MuiTextField from "../../../components/textfield/MuiTextField";
import BackdropLoader from '../../../components/loader/BackdropLoader';
import { CreateApp_TemplateTypes } from '../../../constants/enums';
import { cloneEnvironment } from '../redux/actions'
import { validateInputField } from '../../../helpers/utils'
import { EnvSettingTabs } from "../../../constants/environmentTabs";
import { Dispatch } from 'redux';
import { History } from 'history';

interface Iprops extends PropsFromRedux{
    changeHash: (hash: string, val: number, envId: number) => void ;
    cancelCloning:()=>void;
    history:History
}
type Tvalidation={ error: boolean; message: string; }

interface ImoduleSelection{
    "is_resource": boolean;
    "is_build_and_run_scrpt": boolean;
    "is_environment_variable": boolean;
    "is_user_permission": boolean;
    "is_hpa_setting": boolean;
    "is_backup_setting": boolean;
    "is_addon": boolean;
    "is_pvc": boolean;
    "is_scheduler": boolean;
    "is_cron_job": boolean;
    "is_ci_config": boolean;
    "is_cd_config": boolean;
}
interface IjsonBody extends Partial<ImoduleSelection>{
    name:string
}

export const CloneEnvironment = (props:Iprops) => {
    const defaultModuleList = {
        "is_resource": {
            "form": true,
            "transTitle": 'Environment.Resources.title',
            "type": "boolean",
            "value": true,
            "disabled": true
        },
        "is_environment_variable": {
            "form": true,
            "transTitle": "Environment.Generals.environmentVariables",
            "type": "boolean",
            "value": true,
            "disabled": true
        },
        "is_user_permission": {
            "form": true,
            "transTitle": "Environment.UserPermissionsTab.userPermissions",
            "type": "boolean",
            "value": false
        },
        "is_hpa_setting": {
            "form": true,
            "transTitle": "HPA.hpaSettings",
            "type": "boolean",
            "value": false
        },
        "is_backup_setting": {
            "form": true,
            "transTitle": "Backup.backupSettings",
            "type": "boolean",
            "value": false
        },
        "is_addon": {
            "form": true,
            "transTitle": "Addons.title",
            "type": "boolean",
            "value": false
        },
        "is_pvc": {
            "form": true,
            "transTitle": "Storage",
            "type": "boolean",
            "value": true,
            "disabled": true
        },
        "is_scheduler": {
            "form": true,
            "transTitle": "Environment.SchedulerEnv.title",
            "type": "boolean",
            "value": false
        }
    }

    const crModuleList = {
        "is_cron_job": {
            "form": true,
            "transTitle": "CronJob.title2",
            "type": "boolean",
            "value": false
        },
        "is_cd_config": {
            "form": true,
            "transTitle": "Environment.SettingsTab.cdSettings",
            "type": "boolean",
            "value": false
        }
    }
    const gitModuleList = {
        "is_build_and_run_scrpt": {
            "form": true,
            "transTitle": "Environment.Clone.buildRun",
            "type": "boolean",
            "value": true,
            "disabled": true
        },
        "is_cron_job": {
            "form": true,
            "transTitle": "CronJob.title2",
            "type": "boolean",
            "value": false
        },
        "is_ci_config": {
            "form": true,
            "transTitle": "Environment.SettingsTab.ciSettings",
            "type": "boolean",
            "value": false
        },
        "is_cd_config": {
            "form": true,
            "transTitle": "Environment.SettingsTab.cdSettings",
            "type": "boolean",
            "value": false
        }
    }
    const [t] = useTranslation();
    const [moduleSelection, setModuleSelection] = useState<Partial<ImoduleSelection>>({"is_resource":true, "is_environment_variable":true,"is_pvc":true});
    const [moduleList, setModuleList] = useState<null|{properties:typeof defaultModuleList,type:string}>(null);
    const [environmentName, setEnvironmentName] = useState("");
    const [environmentNameError, setEnvironmentNameError] = useState<null|Tvalidation>(null);
    const handleEnvironmentChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        let validation = validateInputField(e.target.value)
        setEnvironmentName(e.target.value);
        setEnvironmentNameError(validation);
    }

    const handleModuleSelection = (variables:ImoduleSelection) => {
        setModuleSelection(variables)
    }

    const isFormValid = () => {
        let valid = false;
        if (environmentName && (!environmentNameError || !environmentNameError.error)) {
            valid = true;
        }
        return !valid;
    }

    const cancel = () => {
        props.cancelCloning()
    }
    
    /* istanbul ignore next */
    const cloneCallBack = (data:{id:number}) => {
        cancel();
        if (props.environmentDetails.service_type === CreateApp_TemplateTypes.git) { 
            const subIndex = EnvSettingTabs(
                "#cicd",
                props.environmentDetails
            ).indexOf("ci");
            props.changeHash("cicd", subIndex, data.id);
        } 
        else 
            props.changeHash("overview", 0, data.id);
    }

    const cloneEnv = () => {
        const jsonBody:IjsonBody = {
            name: environmentName,
            ...moduleSelection
        }
        props.cloneEnvironment(props.environmentDetails.id, jsonBody, cloneCallBack)
    }
    
    useEffect(() => {
        if (props.environmentDetails && props.environmentDetails.id && !moduleList) {
            let _props = { ...defaultModuleList }
            let _selection = { ...moduleSelection };
            if (props.environmentDetails.service_type === CreateApp_TemplateTypes.git) {
                _props = { ..._props, ...gitModuleList };
                _selection = { ..._selection, "is_build_and_run_scrpt": true };
            }
            else if (props.environmentDetails.service_type === CreateApp_TemplateTypes.cr)
                _props = { ..._props, ...crModuleList };

            setModuleList({
                "properties": _props,
                "type": "object"
            });
            setModuleSelection(_selection);
        }
    }, [props.environmentDetails]);
    return (
        <div data-test="clone-container">
            <Card>
                <CardHeader title={t('Environment.Clone.cloneEnv')} />
                <Divider />
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <KeyValueRow
                                //keyXs={1}
                                keyMd={2}
                                rowKey={t("Projects.title")}
                                rowValue={props.environmentDetails?.application?.project?.name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <KeyValueRow
                                //keyXs={1}
                                keyMd={2}
                                rowKey={t("App.title")}
                                rowValue={props.environmentDetails?.application?.name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <KeyValueRow
                                //keyXs={1}
                                keyMd={2}
                                rowKey={t('Environment.Clone.sourceEnv') }
                                rowValue={props.environmentDetails?.name}
                            />
                        </Grid>
                        <Grid item xs={12} className="m-t-20">
                            <Grid container spacing={3}>
                                <Grid item sm={4} xs={12}>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item>
                                            <Typography variant='h5'> {t('Environment.Clone.destinationEnv')} </Typography>
                                        </Grid>
                                        <Grid item>
                                            {/* <TextField style={{ width: "0px", height: "0px" }} /> */}
                                            <MuiTextField
                                                value={environmentName}
                                                // variant="outlined"
                                                //size="small"
                                                //fullWidth
                                                //margin="normal"
                                                name="environmentName"
                                                type={"text"}
                                                onChange={(e:React.ChangeEvent<HTMLInputElement>) => handleEnvironmentChange(e)}
                                                error={environmentNameError?.error}
                                                helperText={environmentNameError?.message ? t(environmentNameError?.message) : ""}
                                                data-test="environment-name-input"
                                            />
                                          
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {
                            moduleList &&
                            <Grid item xs={12} className="m-t-20">
                                <Typography variant='h5'> {t('Environment.Clone.cloneModules')} </Typography>
                                <Grid container className="m-t-10">
                                    <PluginConfigForm
                                        pluginVersionConfig={moduleList}
                                        handleValueChange={handleModuleSelection}
                                        initialValues={moduleSelection}
                                        isErrors={[]}
                                        gridItemOccupency={12}
                                        ignoreAlternateColour={true}
                                        data-test="config-form"
                                    />
                                    {/* {
                                moduleList.map(module =>
                                    <Grid item>
                                        <FormControlLabel
                                            //data-test="prevent-build-switch"
                                            control={
                                                <Switch
                                                    name={module.name}
                                                    color="primary"
                                                //checked={this.state.prevent_default_build}
                                                //onChange={this.handlePreventBuild}
                                                //data-test="prevent-build-switch-field"
                                                />
                                            }
                                            label={module.name}
                                        />
                                    </Grid>
                                )
                            } */}
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </CardContent>
                <Divider />
                <CardActions className='right'>
                    <Button data-test="cancel-clone-btn" color="primary" variant="contained" onClick={() => cancel()}>
                        {t('Common.cancel')}
                    </Button>
                    <Button data-test="clone-btn" color="primary" variant="contained" className="m-l-20" onClick={() => cloneEnv()} disabled={isFormValid()}>
                        {t('Environment.Clone.title')}
                    </Button>
                </CardActions>
            </Card>
            {props.cloningEnvironment && <BackdropLoader message={t('Environment.Clone.cloningMsg')} data-test="cloning-loader" />}
        </div>
    )
}

/* istanbul ignore next */
const mapStateToProps = (state:any) => ({
    environmentDetails: state.EnvironmentReducer.environmentDetails,
    cloningEnvironment: state.EnvironmentReducer.cloningEnvironment
})

/* istanbul ignore next */
const mapDispatchToProps = (dispatch:Dispatch) => {
    return {
        cloneEnvironment: (eId:number, jsonBody:IjsonBody, callBack:(data:{id:number}) => void) => dispatch(cloneEnvironment(eId, jsonBody, callBack))
    }
}
const connector=connect(mapStateToProps, mapDispatchToProps)
export default connector(CloneEnvironment)
type PropsFromRedux=ConnectedProps<typeof connector>