import {
    FormControl,
    InputLabel,
    Select,
    Grid,
    MenuItem,FormControlLabel,Switch,
  } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { AppConstants } from "../../../../constants/appconstants";
import PluginConfigForm from "../../../../components/pluginconfigform/PluginConfigForm";
import MuiTextField from "../../../../components/textfield/MuiTextField";
import { LoggerModel } from "../../../../models/Environment.model";
import Typography from "@material-ui/core/Typography";

  interface Props{
    externalLogger: any,
    enableLogger?:boolean,
    updatedExternalLogger:(state:Partial<LoggerModel>)=>void,
    edit?:boolean,
    loggerConfig?: any
}

function ExternalLogger(props: Props) {
    const { enableLogger, externalLogger, updatedExternalLogger, edit } = props;

    const  editCredentials = ( ({enabled, additional_setting, provider, ...keep }) => keep)(externalLogger);

    const [selectedProvider, setSelectedProvider] = useState<any>(edit?externalLogger.provider:"s3");
    const [secretConfig, setSecretConfig] = useState<any>(null);
    const [variables, setVariables] = useState<any>({});
    const [isErrors, setIsErrors] = useState([])
    const [loggerDetails, setLoggerDetails] = useState({
        enabled:edit?externalLogger.enabled:enableLogger,
        provider:edit?externalLogger.provider:"s3",
        additional_setting:{
            timekey:edit?(externalLogger.additional_setting?.timekey):"1m",
        }
    })

    useEffect(() => {
        updatedExternalLogger(loggerDetails)
    },[loggerDetails])

    useEffect(()=>{
        setLoggerDetails({
          ...loggerDetails, ...variables
        })
      },[variables])

    const handleChangeProvider = (e: any) => {
        setSelectedProvider(e);
      }

      useEffect(()=>{
        if(selectedProvider){
          setLoggerDetails({...loggerDetails, provider:selectedProvider})
          const _providerConfig = props.loggerConfig?.externalLogger.find((loggerconfig: any, index: number) => loggerconfig.name === selectedProvider);
          setSecretConfig(null);
          setVariables({})
          setIsErrors([])
          setSecretConfig(_providerConfig && _providerConfig.config)
        }
      },[selectedProvider])

      const handleValueChange = (_variables:any, errorsList:any) => {
        setVariables(_variables)
        setIsErrors(errorsList);
        setLoggerDetails({
          ...loggerDetails, ..._variables
        })
      }

      const handleAdditionalSetting = (name: string, value: any) =>{
        setLoggerDetails((state)=>{
          return {...loggerDetails, additional_setting:{...state.additional_setting, [name]:value}}
        })
      }
      
      const handleChange=(event: React.ChangeEvent<HTMLInputElement>, checked: boolean)=>{
        setLoggerDetails({...loggerDetails,enabled:checked})
        // updatedExternalLogger({...loggerDetails,enabled:checked})
      }
    return (
        <>
         {edit &&   <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={loggerDetails.enabled}
                            onChange={handleChange}
                            name="active"
                            color="primary"
                          />
                        }
                        label={
                          <Typography variant="subtitle1">{ loggerDetails.enabled? "Enabled":"Disabled"}</Typography>
                        }
                        labelPlacement="start"
                        data-test="active-field"
                      />
                    </Grid>
                    </Grid>
                    }
       {loggerDetails.enabled &&  <Grid container spacing={2}>
        
        <Grid item xs={12} md={4}>
          <FormControl variant="outlined" className="w-100">
            <InputLabel>Select provider</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              name="select_provider"
              value={selectedProvider}
              label="Externel Secret"
              onChange={ (e: React.ChangeEvent<{ value: unknown }>) => handleChangeProvider(e.target.value) }
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
              }}
            >
              {
                props.loggerConfig?.externalLogger.map((_provider:any, index: number)=>
                <MenuItem value={_provider.name} key={_provider.name}>{_provider.displayName}</MenuItem>
                )
              }
            </Select>
          </FormControl>
        </Grid>

        {selectedProvider && 
        <Grid item xs={12}>
        <Grid container spacing={2}>
        <Grid item xs={12}>
          {
            secretConfig &&
            <PluginConfigForm
            pluginVersionConfig={secretConfig ?? {}}
            handleValueChange={handleValueChange}
            initialValues = {edit?editCredentials:variables}
            isErrors={isErrors}
            gridItemOccupency={4}
            ignoreAlternateColour = { true }
            validateEmpty={true}
            />
          }
        </Grid>
        </Grid>
        </Grid>
        }

        <Grid item xs={12} md={4}>
          <MuiTextField
            label="Refresh Interval"
            value={loggerDetails.additional_setting.timekey}
            name="refreshInterval"
            onChange={ (e: React.ChangeEvent<{ value: unknown }>) => handleAdditionalSetting("timekey",e.target.value) }
          />
        </Grid>
        
        </Grid>
        }
                    
        </>
    )
}

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => {
  // return {
  //   fetchLoggerConfigDetails: () => dispatch(fetchLoggerConfig())
  //   };
  };
  
  /* istanbul ignore next */
  const mapStateToProps = (state: any) => {
    // return {
    //   loggerConfig: state.EnvironmentReducer.loggerConfig,
    // };
  };
  
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(ExternalLogger);
