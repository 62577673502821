import { 
  GET_NOTIFICATION_LIST,
  GET_NOTIFICATION_LIST_SUCCESS,
  GET_NOTIFICATION_LIST_FAILURE,
  GET_RECENT_NOTIFICATION_LIST,
  GET_RECENT_NOTIFICATION_LIST_SUCCESS,
  GET_RECENT_NOTIFICATION_LIST_FAILURE,
  UPDATE_RECENT_NOTIFICATION_LIST,
  GET_MORE_NOTIFICATION_LIST_SUCCESS,
  UPDATE_SEEN_UNSEEN_SUCCESS,
  UPDATE_SEEN_UNSEEN,
  UPDATE_SEEN_UNSEEN_FAILURE,
  GET_UNSEEN_COUNT_SUCCESS,
  UPDATE_UNSEEN_COUNT,
  NAVIGATION_CHECK,
  NAVIGATION_CHECK_SUCCESS,
  NAVIGATION_CHECK_FAILURE,
  MARK_ALL_AS_READ,
  MARK_ALL_AS_READ_SUCCESS,
  MARK_ALL_AS_READ_FAILURE,
  SHOWHIDE_NOTY_BUBBLE,
  CLEAR_NOTIFICATIONS,
} from './actions';
import initialState from './initialState';

const reducer = (state = initialState, payload ) => {
  let _notificationList = null;
  let _recentNotificationList = null;
  switch (payload.type) {
    case GET_NOTIFICATION_LIST:
      return {
        ...state,
        fetchingNotifications: true
      }
    case GET_NOTIFICATION_LIST_SUCCESS:
      return {
        ...state,
        notificationList: payload.data,
        fetchingNotifications: false
      }
    case GET_NOTIFICATION_LIST_FAILURE:
      return {
        ...state,
        fetchingNotifications: false
      }
    case GET_MORE_NOTIFICATION_LIST_SUCCESS:
      return {
        ...state,
        notificationList: [...state.notificationList, ...payload.data],
        fetchingNotifications: false
      }
    case GET_RECENT_NOTIFICATION_LIST:
      return {
        ...state,
        fetchingRecentNotifications: true
      }
    case GET_RECENT_NOTIFICATION_LIST_SUCCESS:
      return {
        ...state,
        recentNotificationList: payload.data,
        fetchingRecentNotifications: false
      }
    case GET_RECENT_NOTIFICATION_LIST_FAILURE:
      return {
        ...state,
        fetchingRecentNotifications: false
      }
    case UPDATE_RECENT_NOTIFICATION_LIST:
      return {
        ...state,
        recentNotificationList: payload.data.notys,
        showNotificationBubble: true
      }
    case SHOWHIDE_NOTY_BUBBLE:
      return {
        ...state,
        showNotificationBubble: payload.data.isShow
      }
    case UPDATE_SEEN_UNSEEN_SUCCESS:
      if (state.notificationList) {
        _notificationList = [...state.notificationList];
        if (_notificationList) {
          _notificationList.map(x => {
            const exist = payload.data.ids.indexOf(x.id)
            if (exist > -1) {
              x.seen = payload.data.seen;
            }
          })
        }
      }
      if (state.recentNotificationList) {
        _recentNotificationList = [...state.recentNotificationList];
        if (_recentNotificationList) {
          _recentNotificationList.map(x => {
            const exist = payload.data.ids.indexOf(x.id)
            if (exist > -1) {
              x.seen = payload.data.seen;
            }
          })
        }
      }
      return {
        ...state,
        notificationList: _notificationList,
        recentNotificationList: _recentNotificationList,
        isLoading: false
    } 

    case UPDATE_SEEN_UNSEEN:
      return {
        ...state,
        isLoading: true
    } 
    case UPDATE_SEEN_UNSEEN_FAILURE:
      return {
        ...state,
        isLoading: false
    } 
    case GET_UNSEEN_COUNT_SUCCESS:
      return {
        ...state,
        unseenCount: payload.data.count,
        showNotificationBubble: payload.data.show_bubble
    } 
    case UPDATE_UNSEEN_COUNT:
      return {
        ...state,
        unseenCount: payload.data.count,
        showNotificationBubble: payload.data.show_bubble
      }
    case NAVIGATION_CHECK:
      return {
        ...state,
        isCheckNavigation : true
    } 
    case NAVIGATION_CHECK_SUCCESS:
      return {
        ...state,
        isCheckNavigation : false
    } 
    case NAVIGATION_CHECK_FAILURE:
      return {
        ...state,
        isCheckNavigation : false
    }
    case MARK_ALL_AS_READ:
      return {
        ...state,
        isMarkAllAsRead : true
    } 
    case MARK_ALL_AS_READ_SUCCESS:
      return {
        ...state,
        isMarkAllAsRead : false
    } 
    case MARK_ALL_AS_READ_FAILURE:
      return {
        ...state,
        isMarkAllAsRead : false
    } 
    case CLEAR_NOTIFICATIONS:
      return {
        ...state,
        notificationList : initialState.notificationList
    } 
    default:
      return state
  }
}

export default reducer