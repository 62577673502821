import Brightness1Icon from "@material-ui/icons/Brightness1";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HourglassEmptyOutlinedIcon from "@material-ui/icons/HourglassEmptyOutlined";
import { AppConstants } from "../../constants/appconstants";
import AdjustIcon from "@material-ui/icons/Adjust";
import React from "react";
import CheckIcon from "@material-ui/icons/Check";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";

type Props = {
  status: string
}

export function StepStatusIndicator(props: { status: string }) {
  let stepStatusIndicator: string | JSX.Element = "";
  switch (props.status) {
    case AppConstants.WorkflowStatus.Succeeded:
      stepStatusIndicator = (
        <div className="" data-test="succeeded-statusIndicator">
          <CheckCircleIcon className="successStep" fontSize="small" />
        </div>
      );
      // stepStatusIndicator = <div className="running-circle orange"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>
      break;
    case AppConstants.WorkflowStatus.Running:
      // stepStatusIndicator = <div className="running-circle orange"><div class="lds1-ring"><div></div><div></div><div></div><div></div></div></div>
      stepStatusIndicator = (
        <div className="" data-test="running-statusIndicator">
          <Brightness1Icon className="runningStep" fontSize="small" />
          <div className="lds1-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      );
      break;
    case AppConstants.WorkflowStatus.Pending:
      stepStatusIndicator = (
        <div className="" data-test="pending-statusIndicator">
          <Brightness1Icon className="pendingStep" fontSize="small" />
          <div className="lds1-hour">
            <HourglassEmptyOutlinedIcon className="pendingStepIcon" fontSize="small" />
          </div>
        </div>
      );
      break;
    case AppConstants.WorkflowStatus.Failed:
      stepStatusIndicator = (
        <div className="" data-test="failed-statusIndicator">
          <CancelIcon className="failedStep" fontSize="small" />
        </div>
      );
      break;
    case AppConstants.WorkflowStatus.Error:
      stepStatusIndicator = (
        <div className="" data-test="error-statusIndicator">
          <CancelIcon className="failedStep" fontSize="small" />
        </div>
      );
      break;
    default:
      stepStatusIndicator = "";
  }
  return <div data-test="main-container">{stepStatusIndicator}</div>;
}


export function StatusIndicator(props: Props) {
  let statusIndicator: JSX.Element = <></>;
  switch (props.status) {
    case AppConstants.Status.Succeeded:
      statusIndicator = (
        <div data-test="succeeded-statusIndicator">
          <CheckCircleIcon className="successStep" />
        </div>
      );
      break;
    case AppConstants.Status.Running:
      statusIndicator = (
        <div data-test="running-statusIndicator">
          <Brightness1Icon className="runningStep" />
          <div className="lds1-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      );
      break;
    case AppConstants.Status.Pending:
      statusIndicator = (
        <div data-test="pending-statusIndicator">
          <Brightness1Icon className="pendingStep" />
          <div className="lds1-hour">
            <HourglassEmptyOutlinedIcon className="pendingStepIcon" />
          </div>
        </div>
      );
      break;
    case AppConstants.Status.Failed:
      statusIndicator = (
        <div data-test="failed-statusIndicator">
          <CancelIcon className="failedStep" />
        </div>
      );
      break;
    case AppConstants.Status.Undefined:
      statusIndicator = (
        <div data-test="undefined-statusIndicator">
          <CancelIcon className="failedStep" />
        </div>
      );
      break;
    default:
      statusIndicator = <></>;
  }
  return <div data-test="main-container">{statusIndicator}</div>;
}

export function FilledStatusIndicator(props: Props) {
  let statusIndicator: JSX.Element = <></>;
  switch (props.status) {
    case AppConstants.Status.Succeeded:
      statusIndicator = (
        <div className="status-circle" data-test="succeeded-statusIndicator">
          <CheckCircleIcon className="successStep" />
        </div>
      );
      break;
    case AppConstants.Status.Running:
      statusIndicator = (
        <div className="status-circle" data-test="running-statusIndicator">
          <Brightness1Icon className="runningStep" />
          <div className="lds1-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      );
      break;
    case AppConstants.Status.Pending:
      statusIndicator = (
        <div className="status-circle" data-test="pending-statusIndicator">
          <Brightness1Icon className="pendingStep" />
          <div className="lds1-hour">
            <HourglassEmptyOutlinedIcon className="pendingStepIcon" />
          </div>
        </div>
      );
      break;
    case AppConstants.Status.Failed:
      statusIndicator = (
        <div className="status-circle" data-test="failed-statusIndicator">
          <CancelIcon className="failedStep" />
        </div>
      );
      break;
    case AppConstants.Status.Undefined:
      statusIndicator = (
        <div className="status-circle" data-test="undefined-statusIndicator">
          <CancelIcon className="failedStep" />
        </div>
      );
      break;
    default:
      statusIndicator = <></>;
  }
  return <div data-test="main-container">{statusIndicator}</div>;
}

export function StepStatusIndicatorPopUp(props: Props) {
  let stepStatusIndicator: JSX.Element = <></>;
  switch (props.status) {
    case AppConstants.Status.Succeeded:
      stepStatusIndicator = (
        <div data-test="succeeded-statusIndicator">
          <CheckCircleIcon className="successStep" />
        </div>
      );
      break;
    case AppConstants.Status.Running:
      stepStatusIndicator = (
        <div data-test="running-statusIndicator">
          <Brightness1Icon className="runningStep" />
          <div className="lds1-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      );
      break;
    case AppConstants.Status.Pending:
      stepStatusIndicator = (
        <div data-test="pending-statusIndicator">
          <Brightness1Icon className="pendingStep" />
          <div className="lds1-hour">
            <HourglassEmptyOutlinedIcon className="pendingStepIcon" />
          </div>
        </div>
      );
      break;
    case AppConstants.Status.Failed:
      stepStatusIndicator = (
        <div data-test="failed-statusIndicator">
          <CancelIcon className="failedStep" />
        </div>
      );
      break;
    default:
      stepStatusIndicator = <></>;
  }
  return <div data-test="main-container">{stepStatusIndicator}</div>;
}

export function ClusterStatus(props: Props) {
  let statusColor = "";
  let statusText = "";
  switch (props.status) {
    case AppConstants.ClusterStatus.Drafted:
      statusColor = "sandybrown";
      statusText = "Drafted";
      break;
    case AppConstants.ClusterStatus.Planning:
      statusColor = "orange"; //<div>Planned</div>
      statusText = "Planning";
      break;
    case AppConstants.ClusterStatus.Planned:
      statusColor = "orange"; //<div>Planned</div>
      statusText = "Planned";
      break;
    case AppConstants.ClusterStatus.Applying:
      statusColor = "orange"; //<div>Active</div>
      statusText = "Applying";
      break;
    case AppConstants.ClusterStatus.Applied:
      statusColor = "green"; //<div>Active</div>
      statusText = "Active";
      break;
    case AppConstants.ClusterStatus.Destroyed:
      statusColor = "red";
      statusText = "Destroyed";
      break;
    case AppConstants.ClusterStatus.Imported:
      statusColor = "blue";
      statusText = "Imported";
      break;
    case AppConstants.ClusterStatus.PackageInstalling:
      statusColor = "orange";
      statusText = "Package Installing";
      break;
    case AppConstants.ClusterStatus.PackageInstalled:
      statusColor = "green";
      statusText = "Active";
      break;
    case AppConstants.ClusterStatus.Failed:
      statusColor = "red";
      statusText = "Failed";
      break;
    default:
      statusColor = "";
      statusText = "";
  }
  return (
    <div>
      <span className="m-l-20">
        <AdjustIcon className="topIcon" style={{ color: statusColor }} />
        <span
          style={{ color: statusColor }}
          className="statusTxt"
          data-test="status-text"
        >
          {" "}
          {statusText}
        </span>
      </span>
    </div>
  );
}

export function LoadbalancerStatusIndicator(props: Props) {
  let icon, text, color;
  //let iconStyle = { position: "absolute", fontSize: "20px" };
  let textStyle = { marginLeft: "25px" };

  switch (props.status) {
    case AppConstants.Status.Running:
      case AppConstants.Status.Succeeded:
      icon = <CheckCircleIcon style={{ position: "absolute", fontSize: "20px" }} />;
      text = (
        <span style={textStyle} data-test="status-text">
          Active
        </span>
      );
      color = "#5b4";
      break;

    case AppConstants.Status.Pending:
    case "":
      icon = <HourglassEmptyOutlinedIcon style={{ position: "absolute", fontSize: "20px" }} />;
      text = (
        <span style={textStyle} data-test="status-text">
          In Progress
        </span>
      );
      color = "orange";
      break;

    default:
      icon = <i></i>;
  }
  return (
    <span style={{ position: "relative", color: color }} data-test="lb-status">
      {icon} {text}
    </span>
  );
}

export function BackupStatusIndicator(props: Props) {
  let textStyle = { marginLeft: "5px" };
  let color = "";
  let text = props.status;
  switch (props.status) {
    case AppConstants.BackupStatus.New:
      color = "blue";
      break;
    case AppConstants.BackupStatus.Completed:
      color = "green";
      break;
    case AppConstants.BackupStatus.PartiallyDone:
    case AppConstants.BackupStatus.InProgress:
      color = "orange";
      text = "InProgress";
      break;
    case AppConstants.BackupStatus.Failed:
    case AppConstants.BackupStatus.FailedValidation:
    case AppConstants.BackupStatus.PartiallyFailed:
      color = "red";
      text = "Failed";
      break;
    case AppConstants.BackupStatus.Deleting:
      color = "sandybrown";
      break;
    default:
      color = "black";
      text = "Pending";
  }
  return (
    <div className="topgridalign">
      <Brightness1Icon
        style={{ color: color, fontSize: 15 }}
        data-test="status-icon"
      />{" "}
      <span style={textStyle} data-test="status-text">
        {text}
      </span>
    </div>
  );
}

export function DeploymentStatusIndicator(props: Props) {
  let statusIndicator = <HourglassEmptyOutlinedIcon />;
  if (props.status) {
    switch (props.status) {
      case AppConstants.DeploymentStatus.Unknown:
        statusIndicator = <HourglassEmptyOutlinedIcon />;
        break;
      case AppConstants.DeploymentStatus.Deployed:
      case AppConstants.DeploymentStatus.Superseded:
      case AppConstants.DeploymentStatus.Uninstalled:
        statusIndicator = <CheckIcon className="sucessIcon" />;
        break;
      case AppConstants.DeploymentStatus.Uninstalling:
      case AppConstants.DeploymentStatus.Pending_Install:
      case AppConstants.DeploymentStatus.Pending_Upgrade:
      case AppConstants.DeploymentStatus.Pending_Rollback:
        statusIndicator = (
          <div className="lds-ring running" data-test="running-icon">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        );
        break;
      case AppConstants.DeploymentStatus.Failed:
        statusIndicator = <ClearOutlinedIcon color="error" />;
        break;
      default:
        statusIndicator = <HourglassEmptyOutlinedIcon />;
    }
  }
  return <div data-test="deployment-status">{statusIndicator}</div>;
}