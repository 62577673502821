import initialState from "./initialState";
import {
  FETCH_SUBSCRIPTIONS,
  FETCH_SUBSCRIPTIONS_SUCCESS,
  FETCH_SUBSCRIPTIONS_FAILURE,
  FETCH_SUBSCRIPTION,
  FETCH_SUBSCRIPTION_SUCCESS,
  FETCH_SUBSCRIPTION_FAILURE,
  DELETE_SUBSCRIPTION,
  DELETE_SUBSCRIPTION_SUCCESS,
  DELETE_SUBSCRIPTION_FAILURE,
  CREATE_SUBSCRIPTION_CALL,
  CREATE_SUBSCRIPTION_CALL_SUCCESS,
  CREATE_SUBSCRIPTION_CALL_FAILURE,
  EDIT_SUBSCRIPTION_CALL,
  EDIT_SUBSCRIPTION_CALL_SUCCESS,
  EDIT_SUBSCRIPTION_CALL_FAILURE,
} from "./actions";

const reducer = (state = initialState, payload) => {
  switch (payload.type) {
    case FETCH_SUBSCRIPTIONS:
      return {
        ...state,
        fetchingSubscriptions: true
      };
    case FETCH_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        subscriptionList: payload.data,
        fetchingSubscriptions: false
      };
    case FETCH_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        error: payload.error,
        fetchingSubscriptions: false
      };
    case FETCH_SUBSCRIPTION:
      return {
        ...state,
        fetchingSubscription: true
      };
    case FETCH_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscription: payload.data,
        fetchingSubscription: false
      };
    case FETCH_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        error: payload.error,
        fetchingSubscription: false
      };
    case DELETE_SUBSCRIPTION:
      return {
        ...state,
        executingDeleteSubscription: true,
        deleteSubscriptionSuccess: false,
        deleteSubscriptionFailure: false,
      };
    case DELETE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        executingDeleteSubscription: false,
        deleteSubscriptionSuccess: true,
        deleteSubscriptionFailure: false,
      };
    case DELETE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        executingDeleteSubscription: false,
        deleteSubscriptionSuccess: false,
        deleteSubscriptionFailure: true,
        error: payload.error,
      };
    case CREATE_SUBSCRIPTION_CALL:
        return {
          ...state,
          executingCreateSubscription: true,
          createSubscriptionCallSuccess: false,
          createSubscriptionCallFailure: false,
        };
    case CREATE_SUBSCRIPTION_CALL_SUCCESS:
        return {
          ...state,
          executingCreateSubscription: false,
          createSubscriptionCallSuccess: true,
          createSubscriptionCallFailure: false,
        };
    case CREATE_SUBSCRIPTION_CALL_FAILURE:
        return {
          ...state,
          executingCreateSubscription: false,
          createSubscriptionCallSuccess: false,
          createSubscriptionCallFailure: true,
          error: payload.data.error,
        };
    case EDIT_SUBSCRIPTION_CALL:
        return {
          ...state,
          executingEditSubscription: true,
          editSubscriptionCallSuccess: false,
          editSubscriptionCallFailure: false,
        };
    case EDIT_SUBSCRIPTION_CALL_SUCCESS:
        return {
          ...state,
          executingEditSubscription: false,
          editSubscriptionCallSuccess: true,
          editSubscriptionCallFailure: false,
        };
    case EDIT_SUBSCRIPTION_CALL_FAILURE:
        return {
          ...state,
          executingEditSubscription: false,
          editSubscriptionCallSuccess: false,
          editSubscriptionCallFailure: true,
          error: payload.data.error,
        };
    default:
      return state;
  }
};

export default reducer;
