import { FormControl, Grid, InputLabel, MenuItem, Select } from "@material-ui/core";
import _ from 'lodash';
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import PluginConfigForm from '../../../../../components/pluginconfigform/PluginConfigForm';
import { coreConversion, spaceConversion } from "../../../../../helpers/utils";
import { clearPlugInVersions, fetchPluginVersions } from "../../../../app/createapp/redux/actions";
import { clearProjectDetails, clearProjectUsedResources, fetchProjectDetails, fetchProjectUsedResources } from "../../../../project/redux/actions";
import { clearPluginVersionConfig, fetchPlugInVersionConfig, fetchResources } from "../../../redux/actions";

export class AddonInstallFormNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedVersion: "Version",
            selectedVersionObject: null,
            versionError: "",
            selectedResource: 0,
            selectedResourceObject: null,
            variables:{},
            isErrors: [],
            availableResource: { memory:0, cores: 0 },
            noOfMasterResource : 1
        };
    }

    componentDidMount() {
        this.props.fetchPluginVersions(this.props.addonId);
        if(this.props.resourcesList)
        {
            this.setDefaultResource(this.props.resourcesList);
        }
        else {
            this.props.fetchResources();    
        }
        if(this.props.envAddon)
        {
            const { envAddon } = this.props;
            this.setState({
                selectedVersion: envAddon.version?.name,
                selectedResource : envAddon.resource_id,
                variables: envAddon.variables
            })
        }
        if(this.props.currentProject > 0)
        {
            this.props.fetchProjectDetails(this.props.currentProject)
        }
    }

    componentWillUnmount() {
        this.props.clearPluginVersionConfig();
        this.props.clearPlugInVersions();
        this.props.clearProjectDetails();
        this.props.clearProjectUsedResources();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps) {
            if(nextProps.versionsList && nextProps.versionsList.id)
            {
                if(!this.props.versionsList?.id || (this.props.versionsList?.plugin_id !== nextProps.versionsList?.plugin_id) ) {
                    this.props.fetchPlugInVersionConfig(nextProps.versionsList?.id);
                    if (this.state.selectedVersion !== "Version") {
                        this.setState({
                            selectedVersionObject: this.getVersionItem(this.state.selectedVersion, nextProps.versionsList),
                        }, () => this.updateValuesToParent());
                    }
                }
            }
            if(!this.props.resourcesList && nextProps.resourcesList)
            {
                this.setDefaultResource(nextProps.resourcesList);
            }
            if(!this.props.projectDetails.id && nextProps.projectDetails && nextProps.projectDetails.id > 0)
            {
                this.props.fetchProjectUsedResources(this.props.currentProject)
            }
            if(!this.props.projectUsedResources && nextProps.projectUsedResources)
            {
                const sub = this.props.projectDetails && this.props.projectDetails.subscription;
                if(sub)
                {
                    const _cores = sub.cores - nextProps.projectUsedResources.core;
                    const _memory = sub.memory - nextProps.projectUsedResources.memory;
                    this.setState({
                        availableResource: { memory:_memory, cores: _cores }
                    })
                }
            }
        }
    }

    versionChangeHandler = (e) => {
        let msg = "";
        if (e.target.value === "Version" || e.target.value === "") {
             msg = "Invalid Version"
        }
        let selectedVersionObject = this.getVersionItem(e.target.value, this.props.versionsList);
        this.setState({
            selectedVersion: e.target.value,
            selectedVersionObject: selectedVersionObject,
            versionError: msg
        }, () => this.updateValuesToParent())
    }

    getVersionItem = (value, versionsList) => { 
        return versionsList?.versions &&
            versionsList.versions[0]?.versions.find((v) => {
                return v.name === value;
        });
    }

    resourceChangeHandler = (e) => {
        this.setSelectedResource(e.target.value);
    };

    getReplicasFinalFieldName = () => {
        let keyName = "slave.replicas";
        if(this.state.variables)
        {
            const objKeys = Object.keys(this.state.variables);
            if(objKeys.indexOf("replicas") > -1)
            {
                keyName = "replicas";
            }
        }
        return keyName;
    }

    setSelectedResource = (id, selectedResObj) => {
        const { isErrors } = this.state;
        let errorsList = [...isErrors];
        if(!selectedResObj)
        {
            selectedResObj = this.props.resourcesList?.find((res) => {
                return res.id === id;
            });
        }
        
        const replicaFinalFieldName = this.getReplicasFinalFieldName(this.state.variables);
        const isError = this.calculateResources(_.get(this.state.variables, replicaFinalFieldName), selectedResObj)
        if(isError) {
            if(errorsList.indexOf(replicaFinalFieldName) <= -1)
            {
                errorsList.push(replicaFinalFieldName);
            } 
        }
        else{
            const ind = errorsList.indexOf(replicaFinalFieldName);
            if(ind > -1)
            {
                errorsList.splice(ind, 1);
            } 
        }
    
        this.setState({ 
            selectedResource: id, 
            selectedResourceObject: selectedResObj,
            isErrors : errorsList,
        }, () => this.updateValuesToParent());
    }

    updateValuesToParent = () => {
        const finalError = this.state.selectedVersion === "Version" || !this.state.selectedResource || this.state.isErrors.length > 0;
        const payload = {
            variables: this.state.variables,
            version: this.state.selectedVersionObject,
            resource_id: this.state.selectedResource
        };
        this.props.handleContinue(payload, finalError);
    }

    handleValueChange = (variables, errorsList) => {
        this.setState({
            variables,
            isErrors : errorsList,
        }, () => this.updateValuesToParent())
    }

    calculateResources = (_value, _selectedResourceObject) => {
        let isError = false;
        if(_value && _selectedResourceObject)
        {
            const { availableResource, noOfMasterResource } = this.state;
            const newMemory = _selectedResourceObject.memory * (_value + noOfMasterResource);
            const newCores = _selectedResourceObject.cores * (_value + noOfMasterResource);
            if(newMemory > availableResource.memory || newCores > availableResource.cores)
            {
                isError = true;
            }
            else
            {
                isError = false;
            }
        }
        return isError;
    } 

    setDefaultResource = (resourcesList) => {
        if (this.props.envAddon) {
            const selectedResourceObj = resourcesList?.find((res) => {
                return res.id === this.props.envAddon.resource.id;
            });
          this.setSelectedResource(this.props.envAddon.resource.id, selectedResourceObj);
        }
        else
        {
            if (resourcesList?.length > 0) {
                const min_cpu = this.props.addon?.min_cpu;
                const min_memory = this.props.addon?.min_memory;
          
                const defaultRes = resourcesList.find((res) => {
                  return res.memory >= min_memory && res.cores >= min_cpu;
                });
                if (defaultRes) 
                    this.setSelectedResource(defaultRes.id, defaultRes)
            } 
        }
    };

    resourceEnough = (res) => {
        return Boolean(
          res.memory >= this.props.addon?.min_memory && res.cores >= this.props.addon?.min_cpu
        );
    };

  render() {
    const { versionsList, resourcesList,t } = this.props;
    return (
        <Grid data-test="main-container">
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <FormControl className="w-100" variant="outlined">
                        <InputLabel htmlFor="outlined-version-native-simple">
                        {t('Cluster.ClusterInfo.version')}
                        </InputLabel>
                        <Select
                          variant="outlined"
                          label={t('Cluster.ClusterInfo.version')}
                          value={this.state.selectedVersion}
                          gutterButtom
                          onChange={e => this.versionChangeHandler(e)}
                          inputProps={{
                                name: "Version",
                                id: "outlined-version-native-simple",
                            }}
                          MenuProps={{
                                getContentAnchorEl: null,
                                anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                                },
                            }}
                          error={this.state.versionError}
                          helperText={this.state.versionError}
                          data-test="version-select"
                        >
                            <MenuItem value={"Version"}>{t('Cluster.ClusterPage.selectVersion')}</MenuItem>
                            {
                                versionsList?.versions &&
                                versionsList.versions[0] &&
                                versionsList.versions[0].versions &&
                                versionsList.versions[0].versions.map((versionDetails, ind) => (
                                    <MenuItem value={versionDetails.name} key={ind}>
                                        {versionDetails.name}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl className="w-100" variant="outlined">
                        <InputLabel htmlFor="outlined-resource-native-simple">
                        {t('Environment.AddonInfo.resources')}
                        </InputLabel>
                        <Select
                          variant="outlined"
                          label={t('Environment.AddonInfo.resources')}
                          value={this.state.selectedResource}
                          gutterButtom
                          onChange={e => this.resourceChangeHandler(e)}
                          inputProps={{
                                name: "Resource",
                                id: "outlined-resource-native-simple",
                            }}
                          MenuProps={{
                                disableScrollLock: true,
                                getContentAnchorEl: null,
                                anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                                },
                            }}
                          data-test="resource-select"
                        >
                            {
                                resourcesList?.map((res, _ind) => (
                                    <MenuItem value={res.id} disabled={!this.resourceEnough(res)} key={_ind}>
                                        {spaceConversion(res.memory) + " " + coreConversion(res.cores)}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <>
                    <PluginConfigForm
                      pluginVersionConfig = {this.props.pluginVersionConfig}
                      handleValueChange = { this.handleValueChange }
                      initialValues = { this.state.variables}
                      isErrors = { this.state.isErrors }
                      calculateResources = { this.calculateResources }
                      selectedResourceObject = { this.state.selectedResourceObject }
                      data-test="plugin-form"
                    />
                </>
            </Grid>
        </Grid>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state) => ({
    pluginVersionConfig: state.EnvironmentReducer.pluginVersionConfig,
    versionsList: state.CreateAppReducer.versionsList,
    resourcesList: state.EnvironmentReducer.resourcesList,
    projectUsedResources: state.ProjectReducer.projectUsedResources,
    projectDetails: state.ProjectReducer.projectDetails,
    currentProject: state.ProjectReducer.currentProject
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch) => {
  return {
    fetchPlugInVersionConfig: (id) => dispatch(fetchPlugInVersionConfig(id)),
    fetchPluginVersions: (id) => dispatch(fetchPluginVersions(id)),
    fetchResources: () => dispatch(fetchResources()),
    clearPluginVersionConfig: () => dispatch(clearPluginVersionConfig()),
    clearPlugInVersions: () => dispatch(clearPlugInVersions()),
    fetchProjectUsedResources : (id) => dispatch(fetchProjectUsedResources(id)),
    fetchProjectDetails: (payload) => dispatch(fetchProjectDetails(payload)),
    clearProjectDetails: () => dispatch(clearProjectDetails()),
    clearProjectUsedResources: () => dispatch(clearProjectUsedResources())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(withTranslation()(AddonInstallFormNew));
