// import { CdsButton } from "@cds/react/button";
// import { CdsIcon } from "@cds/react/icon";
// import DifferentialTab from "components/DeploymentFormBody/DifferentialTab";
// import Alert from "components/js/Alert";
// import Tabs from "components/Tabs";
//import * as yaml from "js-yaml";
import React,{ useEffect, useState } from "react";
import {Grid} from "@material-ui/core"
// import { IResource } from "../../shared/types";
// import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import AdvancedDeploymentForm from "../../components/deploymentformbody/AdvancedDeploymentForm";
import ResourceDetail from "./operatorsinfo/ResourceDetail";
import { OperatorClass } from "../../models/Operator.model";
// import Differential from "../DeploymentFormBody/Differential";
// import LoadingWrapper from "../LoadingWrapper";

type Props = {
  defaultValues:string;
  deployedValues?:string;
  updateNewValues:(_newValues:any)=>void;
  operatorDetails:OperatorClass;
}

export function OperatorInstanceFormBody({
  defaultValues,
  //isFetching,
  //namespace,
  //handleDeploy,
  deployedValues,
  updateNewValues,
  operatorDetails
}:Props) {
  const [values, setValues] = useState(defaultValues);
  //const [modalIsOpen, setModalIsOpen] = useState(false);
  //const [parseError, setParseError] = useState(undefined);
  //const closeModal = () => setModalIsOpen(false);
  //const openModal = () => setModalIsOpen(true);

  const handleValuesChange = (newValues:any) => {
    setValues(newValues);
    updateNewValues(newValues);
  };

  useEffect(() => {
    setValues(deployedValues || defaultValues);
  }, [defaultValues, deployedValues]);

  // const restoreDefaultValues = () => {
  //   setValues(defaultValues);
  //   closeModal();
  // };

  // const parseAndDeploy = async (e) => {
  //   e.preventDefault();
  //   // Clean possible previous errors
  //   //setParseError(undefined);
  //   let resource = {};
  //   try {
  //     resource = yaml.load(values);
  //   } catch (e) {
  //     //setParseError(new Error(`Unable to parse the given YAML. Got: ${e.message}`));
  //     return;
  //   }
  //   // if (!resource.apiVersion) {
  //   //   setParseError(
  //   //     new Error("Unable parse the resource. Make sure it contains a valid apiVersion"),
  //   //   );
  //   //   return;
  //   // }
  //   handleDeploy(resource);
  // };

  // if (isFetching) {
  //   return (
  //     <LoadingWrapper
  //       className="margin-t-xxl"
  //       loadingText="Fetching application..."
  //       loaded={false}
  //     />
  //   );
  // }
  return (
    <>
      {/* <form onSubmit={parseAndDeploy}> */}
        {/* {parseError && <Alert theme="danger">{parseError.message}</Alert>} */}
        {/* <ConfirmDialog
          modalIsOpen={modalIsOpen}
          loading={false}
          headerText={"Restore defaults"}
          confirmationText={"Are you sure you want to restore the default instance values?"}
          confirmationButtonText={"Restore"}
          onConfirm={restoreDefaultValues}
          closeModal={closeModal}
        /> */}
        {/* <div className="deployment-form-tabs">
          <Tabs
            id="deployment-form-body-tabs"
            columns={[
              "YAML",
              <DifferentialTab
                key="differential-selector"
                deploymentEvent={deployedValues ? "upgrade" : "install"}
                defaultValues={defaultValues}
                deployedValues={deployedValues || ""}
                appValues={values}
              />,
            ]}
            data={[ */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
              <AdvancedDeploymentForm
                appValues={values}
                handleValuesChange={handleValuesChange}
                key="advanced-deployment-form"
              />
              </Grid>
              <Grid item md={4}>
              <ResourceDetail operatorDetails = {operatorDetails}/>
              </Grid>
            </Grid>
              {/* <Differential
                oldValues={deployedValues || defaultValues}
                newValues={values}
                emptyDiffText={
                  deployedValues
                    ? "No changes detected from deployed values"
                    : "No changes detected from example defaults"
                }
                key="differential-selector"
              />,
            ]}
          />
        </div> */}
        {/* <div className="deployment-form-control-buttons"> */}
          {/* <CdsButton status="primary" type="submit">
            <CdsIcon shape="deploy" /> Deploy
          </CdsButton> */}
          {/* TODO(andresmgot): CdsButton "type" property doesn't work, so we need to use a normal <button>
            https://github.com/vmware/clarity/issues/5038
          */}
          {/* <span className="color-icon-info">
            <button
              className="btn btn-info-outline"
              type="button"
              onClick={openModal}
              style={{ marginTop: "-22px" }}
            >
              <CdsIcon shape="backup-restore" /> Restore Defaults
            </button>
          </span> */}
        {/* </div> */}
      {/* </form> */}
    </>
  );
}

export default OperatorInstanceFormBody;
