export const hasValidSessionSelector = ({ AuthReducer }) => !!AuthReducer.token
export const sessionTokenSelector = ({ AuthReducer }) => AuthReducer.token
export const currentOrganization = ({ AuthReducer }) => AuthReducer.currentOrganization
export const currentOrgRole = ({ AuthReducer }) => {
    let role = -1;
    if(AuthReducer.currentOrganization && AuthReducer.currentOrganization.members )
    {
        if(AuthReducer.currentOrganization.user_id === AuthReducer.user.id)
        {
            role = 0
        }
        else {
            const currentMember =  AuthReducer.currentOrganization.members.find(x => x.user_id === AuthReducer.user.id)
            if(currentMember)
            {
                role = currentMember.user_role;
            }
        }
        //2: Member; 1:Admin; 0:Owner
    }
    return role;
}
export const hasBillingAddressSelector = ({ AuthReducer }) => AuthReducer.user?.address_updated;