import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import EditDialog from "./EditDialog";
import ConfirmActionPopup from "../../components/confirmactionpopup/ConfirmActionPopup";
import { getAlertDate } from "../../helpers/utils";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: "5px",
  },
  primaryCol: {
    color: theme.palette.primary.main,
  },
  cardGap: {
    marginBottom:"15px"
  }
}));

interface Props {
  type: string;
  rules: any;
}

function AlertCard(props: Props) {
  const classes = useStyles();

  const [openDialog, setOpenDialog] = useState(false);
  const [editRule, setEditRule] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleEdit = (rule:any) => {
    setOpenDialog(true);
    setEditRule(rule)
  };
  
  const handleDisabled = (alertName: string) => {
    setOpenConfirm(true);
  }

  const handleConfirmPopupAgree = () => {
    setOpenConfirm(false)
  }

  const handleConfirmPopupDisagree = () => {
    setOpenConfirm(false)
  } 

  return (
    <>
    {props.rules && props.rules.map((el:any, ind:number)=>(
      <Card className={classes.cardGap}>
      <CardHeader
        title={
          <Typography variant="h5" display="inline">
            <strong>{el.alert}</strong>
          </Typography>
        }
      />

      <Divider />

      <CardContent
      // className={clsx(classes.cardContent)}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5"><span className={classes.primaryCol}>Description:</span> {el.annotations?.description_display}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5"><span className={classes.primaryCol}>Duration:</span> {getAlertDate(el.for)}</Typography>
          </Grid>
        </Grid>
      </CardContent>
      {props.type === "Rules" && 
      <>
      <Divider />
      <CardActions>
        <Grid container spacing={2} className={classes.content}>
          <Grid xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    name="bulk_edit"
                    color="primary"
                    checked={!el.disabled}
                    onChange={() => handleDisabled(el.alert)}
                  />
                }
                label=""
              />
          </Grid>
          <Grid
            xs={6}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
              <IconButton aria-label="edit" onClick={() => handleEdit(el)}>
                <EditIcon />
              </IconButton>
          </Grid>
        </Grid>
      </CardActions>
      </>}
    </Card>
    ))}

      {openDialog && (
        <EditDialog
          rules={editRule}
          openDialog={openDialog}
          handleClose={handleClose}
          template="pass template name here"
        />
      )}

      {openConfirm && (
        <ConfirmActionPopup
          open={openConfirm}
          handleAgree={handleConfirmPopupAgree}
          handleDisAgree={handleConfirmPopupDisagree}
          yesText="yes"
          noText="No"
          message="Are you sure you want to update rule?"
        />
      )}

    </>
  );
}

export default AlertCard;
