import { Button, Checkbox, CssBaseline, FormControlLabel, Grid, IconButton, InputAdornment, Paper, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Formik } from 'formik';
import React, { Component } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import CompanyBanner from '../../components/companybanner/CompanyBanner';
import MuiTextField from '../../components/textfield/MuiTextField';
// import toast from '../../components/toast/Toast';
import { register } from './redux/actions';
import './registration.css';
import ReCAPTCHA from "react-google-recaptcha";

/* istanbul ignore next */
const useStyles = theme => ({
    root: {
      height: '100vh',
    },
    image: {
      backgroundImage: 'url(/images/infographics/login.svg)',
      backgroundRepeat: 'no-repeat',
      backgroundColor:
        theme.palette.type === 'light' ? theme.palette.grey[ 50 ] : theme.palette.grey[ 900 ],
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    paper: {
      margin: theme.spacing(5),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingLeft : '15%',
      paddingRight : '15%',
      [ theme.breakpoints.down('sm') ]: {
        paddingLeft : '2.5%',
        paddingRight : '2.5%',
        margin: theme.spacing(15, 8),
      },
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(2),
    },
    contentHeader: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: theme.spacing(5),
      paddingBototm: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    submit: {
      margin: theme.spacing(1.5, 0, 2),
    },
  });

/* istanbul ignore next */
  const Terms = () => {
    const { t } = useTranslation();
    return (
        <div> <span>{t('AgreeT&C')} </span> <Link to={'/terms'}>{t('TermsOfUse')}</Link> <span> {t('And')} </span> <Link to={'/privacy'}>{t('PrivacyPolicy')}</Link> </div>
    )
  };

export class Registration extends Component {
    constructor(props){
      super(props);
      this.state = {
          first_name: '',
          last_name: '',
          email: '',
          password: '',
          company: '',
          designation: '',
          agreeTerms: false,
          captcha_code: ""
      }
      this.recaptchaRef = React.createRef();
      this.firstNameInput = React.createRef();
    }

    componentDidMount(){
      let $this = this;
      setTimeout( function () { $this.firstNameInput.current.focus(); }, 500)
    }

    // handleOnChange = (e) => {
    //     this.setState({
    //         [ e.target.name ] : e.target.value
    //     })
    // }

    handleAgreeTerms = (e) => {
        this.setState({
            agreeTerms : e.target.checked
        })
    }

    // handleSubmit = () => {
    //     if(this.state.password !== this.state.confirmpassword)
    //     {
    //         toast.error('Password and Confirm password should be same');
    //         return;
    //     }
    //     if(!this.state.agreeTerms){
    //         toast.error('Please agree terms and conditions');
    //         return;
    //     }
    //     const payload = {
    //         first_name: this.state.first_name,
    //         last_name: this.state.last_name,
    //         email: this.state.email,
    //         password: this.state.password,  
    //         company: this.state.company,
    //         designation: this.state.designation 
    //     }
    //     this.props.signupAction(payload , this.props.history)
    // };

    onChangeRecaptcha = (token) => {
      this.setState(
        {
          captcha_code: token?token:null,
        }
      );
    };

    // handleClickShowPassword = () => {
    //   this.setState({
    //     showPassword:!this.state.showPassword
    //   })
    // };
  
    render () {
        const { t,classes } = this.props;
        return (
            <Grid container className={ classes.root } component="main" data-test="main-component">
                <CssBaseline />
                <CompanyBanner />
                <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>

                    <div className={classes.contentHeader}>
                        <Link to="/" variant="body2" className='textDecorationNone' data-test="back-icon">
                            <IconButton >
                                <ArrowBackIcon />
                            </IconButton>
                        </Link>
                    </div>
                    <div className={ classes.paper } data-test="form-container">
                        {/* <p align='center' className='registrationFormHeading'></p> */}
                        <img src="/images/logos/logo-blue.svg" alt="01Cloud" className='authlogo' data-test="logo-image" />
                        <Typography align='left' variant="h5" data-test="registration-text">
                            {t('RegistrationCompleteText')}
                        </Typography>
                        <Formik initialValues={ { firstName:'',lastName:'',email : this.props.oAuthRegisterData.email ,companyName : ''
                            ,profession : '' , password : '' , confirmPassword : '' } } 
                          onSubmit={ (values, { setSubmitting }) => {
                          setSubmitting(true);
                          const payload = {
                            first_name: values.firstName,
                            last_name: values.lastName,
                            email: values.email,
                            password: values.password,
                            company: values.companyName,
                            profession: values.profession,
                            designation: values.designation,
                            hCaptchaKey: this.state.hCaptchaKey 
                        }
                        this.props.signupAction(payload , this.props.history)
                        this.recaptchaRef.current &&  this.recaptchaRef.current.reset();
                        this.setState({
                          hCaptchaKey: ''
                        })
                        
                        } }
                          validationSchema = { 
                            Yup.object().shape({
                              firstName : Yup.string().min(2,t('Account.PasswordTab.tooShort')).max(30,t('Account.PasswordTab.tooLong')).required(t('Account.ProfileTab.firstNameError')),
                              lastName : Yup.string().min(2,t('Account.PasswordTab.tooShort')).max(30,t('Account.PasswordTab.tooLong')).required(t('Account.ProfileTab.lastNameError')),
                              email : Yup.string().min(2,t('Account.PasswordTab.tooShort')).email().required(t('LoginModule.LoginPage.enterEmail')),
                              companyName : Yup.string().min(2,t('Account.PasswordTab.tooShort')).max(30,t('Account.PasswordTab.tooLong')).required(t('Account.ProfileTab.companyNameError')),
                              profession : Yup.string().min(2,t('Account.PasswordTab.tooShort')).max(30,t('Account.PasswordTab.tooLong')).required(t('Registration.RegistrationPage.professionError')),
                              password: Yup
                                          .string().min(2,t('Account.PasswordTab.tooShort')).max(30,t('Account.PasswordTab.tooLong'))
                                          .required(t('Registration.InviteLogin.enterPassword'))
                                          .matches(
                                            ///^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                                            //'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
                                            /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/,
                                            t('Account.PasswordTab.minCharsError')
                                          ),
                             confirmPassword: Yup
                                        .string().min(2,t('Account.PasswordTab.tooShort')).max(30,t('Account.PasswordTab.tooLong'))
                                        .oneOf([ Yup.ref('password') ], t('Account.PasswordTab.passwordNotMatching'))
                                        .required(t('Registration.InviteLogin.confirmPassword'))
                            }) }
                        >
                            {
                        (props) => {
                          const {
                            values,
                            touched,
                            errors,
                            dirty,
                            //isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            //handleReset,
                            isValid,
                          } = props;
                          return (
                              <form onSubmit={ handleSubmit } data-test="form" >
                                  <div className='displayFlex'>
                                      <MuiTextField 
                                        id="firstName" 
                                    //autoFocus
                                        inputRef={ this.firstNameInput }
                                        error={ errors.firstName && touched.firstName }
                                        label={t('Account.ProfileTab.firstNameLabel')} 
                                        name="firstName" 
                                        color='primary'
                                        onChange={ handleChange } 
                                        value={ values.firstName }
                                        customClassName='firstNameInputBox'
                                        onBlur={ handleBlur }
                                        helperText={ (errors.firstName && touched.firstName) && errors.firstName }
                                        margin="normal"
                                      />
                                      <MuiTextField 
                                        id="lastName" 
                                        error={ errors.lastName && touched.lastName }
                                        label={t('Account.ProfileTab.lastNameLabel')}
                                        customClassName='lastNameInputBox'
                                        name="lastName" 
                                        color='primary'
                                        onChange={ handleChange } 
                                        value={ values.lastName }
                                        onBlur={ handleBlur }
                                        helperText={ (errors.lastName && touched.lastName) && errors.lastName }
                                        margin="normal"
                                      />
                                  </div>
                                  <MuiTextField 
                                    id="email" 
                                    error={ errors.email && touched.email }
                                    label={t('Registration.InviteLogin.emailAddress')} 
                                    name="email" 
                                    color='primary'
                                    onChange={ handleChange } 
                                    value={ values.email }
                                    onBlur={ handleBlur }
                                    helperText={ (errors.email && touched.email) && errors.email }
                                    autoComplete="new-password"
                                    margin="normal"
                                    disabled = { this.props.oAuthRegisterData.email ? true : false }
                                  />
                                  <MuiTextField 
                                    id="companyName" 
                                    error={ errors.companyName && touched.companyName }
                                    label={t('Account.ProfileTab.companyNameLabel')}
                                    name="companyName" 
                                    color='primary'
                                    onChange={ handleChange } 
                                    value={ values.companyName }
                                    onBlur={ handleBlur }
                                    helperText={ (errors.companyName && touched.companyName) && errors.companyName }
                                    margin="normal"
                                  />
                                  <MuiTextField 
                                    id="profession" 
                                    error={ errors.profession && touched.profession }
                                    label={t('Registration.Registration.profession')} 
                                    name="profession" 
                                    color='primary'
                                    onChange={ handleChange } 
                                    value={ values.profession }
                                    onBlur={ handleBlur }
                                    helperText={ (errors.profession && touched.profession) && errors.profession }
                                    margin="normal"
                                    autoComplete="new-password"
                                  />
                                  {/* <MuiTextField 
                                    id="password" 
                                    error={ errors.password && touched.password }
                                    label='Password' 
                                    name="password" 
                                    color='primary'
                                    onChange={ handleChange } 
                                    value={ values.password }
                                    onBlur={ handleBlur }
                                    type='password'
                                    helperText={ (errors.password && touched.password) && errors.password }
                                    margin="normal"
                                    /> */}
                                  {/* <FormControl fullWidth variant="outlined" margin="normal" >
                                      <InputLabel error={ Boolean(errors.password && touched.password) }>Password</InputLabel>
                                      <OutlinedInput
                                        id="password"
                                        error={ Boolean(errors.password && touched.password) }
                                        type={this.state.showPassword ? 'text' : 'password'}
                                        value={values.password}
                                        color='primary'
                                        name="password"
                                        onChange={ handleChange } 
                                        onBlur={ handleBlur }
                                        autoComplete="new-password"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                  aria-label="toggle password visibility"
                                                  onClick={this.handleClickShowPassword}
                                                  edge="end"
                                                >
                                                    {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        labelWidth={70}
                                      />
                                      <FormHelperText error={ Boolean(errors.password && touched.password) } >
                                          {errors.password && touched.password ? errors.password : ''}
                                      </FormHelperText>
                                  </FormControl> */}
                                  <MuiTextField
                                  margin="normal"
                                  label="Password"
                                  id="password"
                                  error={ Boolean(errors.password && touched.password) }
                                  type={this.state.showPassword ? 'text' : 'password'}
                                  value={values.password}
                                  name="password"
                                  onChange={ handleChange } 
                                  onBlur={ handleBlur }
                                  autoComplete="new-password"
                                  InputProps={{
                                    endAdornment:(
                                      <InputAdornment position="end">
                                          <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={this.handleClickShowPassword}
                                            edge="end"
                                          >
                                              {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                          </IconButton>
                                      </InputAdornment>
                                    )
                                  }}
                                  labelWidth={70}
                                  helperText={errors.password && touched.password ? errors.password : ''}
                                  />
                                  <MuiTextField 
                                    id="confirmPassword"
                                    variant="outlined"
                                    margin="normal"
                                    error={ errors.confirmPassword && touched.confirmPassword }
                                    type="password" 
                                    color='primary'
                                    label={t('Account.PasswordTab.confirmPasswordLabel')} 
                                    name="confirmPassword" 
                                    onChange={ handleChange } 
                                    value={ values.confirmPassword }
                                    onBlur={ handleBlur }
                                    autoComplete="new-password"
                                    helperText={ (errors.confirmPassword && touched.confirmPassword) && errors.confirmPassword }
                                  /> 
                                  <div className='registrationButtonDiv'>
                                    <ReCAPTCHA
                                      ref={this.recaptchaRef}
                                      sitekey={window?.config?.REACT_APP_RECAPTCHA_SITEKEY}
                                      onChange={this.onChangeRecaptcha}
                                      data-test="captcha"	
                                    />
                                  </div>
                                  <div className="tnscheck">
                                      <FormControlLabel
                                        control={
                                            <Checkbox
                                              name="checkedB"
                                              color="primary"
                                          
                                              onChange={ (e)=>{ this.handleAgreeTerms(e) } }
                                            />
                                        }
                                        label={ <Terms/> }
                                        data-test="terms-checkbox"
                                      />
                                  </div>
                             
                                  <div className='registrationButtonDiv'>
                                      <Button
                                        variant="contained"
                                        type='submit'
                                        color='primary'
                                        align='center'
                                        className="w-100"
                                        data-test="register-button"
                                        disabled={ !(isValid && dirty && this.state.agreeTerms && this.state.hCaptchaKey) }
                                      >
                                          {t('Create Account')}
                                      </Button>
                                  </div>
                              </form>
                          )
                        }
                      }
                        </Formik>
                        {/* <div className='signInDiv'>
                            <Link to="/" variant="body2" className='textDecorationNone'>
                                <Typography variant="body1" className='signInLink'>{t('AlreadyHaveAccount')}</Typography>
                            </Link>
                        </div> */}
                        {/* <Typography className='links' style={ { display:'block' , width : '100%',marginTop : '12px' } }>
                            <div className='containerDiv'>
                                <Link href="#" className='TermsLink'>
                                    {t('TermsOfUse')}
                                </Link>
                                <Link href="#" className='PolicyLink'>
                                    {t('Policy')}
                                </Link>
                            </div>
                        </Typography> */}
                    </div>
                </Grid>
            </Grid>
        );
    }
}

/* istanbul ignore next */
const mapStateToProps = state => ({
  oAuthRegisterData: state.RegisterReducer.oAuthRegisterData
})

/* istanbul ignore next */
const mapDispatchtoProps = dispatch => {
  return {
      signupAction : (payload,history) => dispatch(register(payload,history)),
  }
}

export default connect(
    mapStateToProps,
    mapDispatchtoProps
  )(withStyles(useStyles)(withTranslation()(Registration))) 