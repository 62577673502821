// import { DeploymentEvent, IBasicFormParam } from "shared/types";
import React from "react";
import "./BasicDeploymentForm.css";
import Param from "./Param";
import { Grid } from '@material-ui/core';
import { isArray } from "lodash";
// export interface IBasicDeploymentFormProps {
//   deploymentEvent: DeploymentEvent;
//   params: IBasicFormParam[];
//   handleBasicFormParamChange: (
//     p: IBasicFormParam,
//   ) => (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
//   handleValuesChange: (value: string) => void;
//   appValues: string;
// }

export function BasicDeploymentForm(props) {
  return (
    <div className="deployment-form-tabs-data" style={{ height:"595px", overflowY:"scroll", overflowX:"hidden" }}>
      <Grid container spacing={2}>
      {props.params.map((param, i) => {
        const id = `${param.path}-${i}`;
        const type = isArray(param.type) ? param.type[0] : param.type;
        return (
            <Grid item xs={12} sm={(type === "textArea" || type === "string" || type === "integer") ? 4 : 12} key={id} data-test="param-container">
            <Param {...props} allParams={props.params} param={param} id={id} />
              {/* <hr className="param-separator" /> */}
            </Grid>
        );
      })}
      </Grid>
    </div>
  );
}

export default BasicDeploymentForm;
