const initialState = {
  subscriptionList: [],
  subscription: {},
  error: '',
  executingDeleteSubscription: false,
  deleteSubscriptionSuccess: false,
  deleteSubscriptionFailure: false,
  executingCreateSubscription: false,
  createSubscriptionCallSuccess: false,
  createSubscriptionCallFailure: false,
  executingEditSubscription: false,
  editSubscriptionCallSuccess: false,
  editSubscriptionCallFailure: false,
  fetchingSubscriptions: false,
  fetchingSubscription: false
};

export default initialState;
