import React from "react";
import ChartCard from "./ChartCard";
import { withTranslation } from "react-i18next";
import { convertToChartData, formatSizeUnits } from "../../helpers/utils";

export const CPUChartCard = (props) => {
  const { t, environmentInsights, selectedPod, replicas } = props;
  var data_cpu_usage = null,
    maxOfCPU = null,
    unitObjectCPU = null;

  data_cpu_usage = props.source === "overview" ? 
    selectedPod &&
    environmentInsights?.cpu_usages?.length > 0 &&
    environmentInsights.cpu_usages.filter(
      (itm) => itm.metric.namespace === selectedPod
    ) : selectedPod &&
    environmentInsights?.cpu_usages?.length > 0 &&
    environmentInsights.cpu_usages.filter(
      (itm) => itm.metric?.pod ? itm.metric.pod === selectedPod : itm.metric?.container === selectedPod
    );
  maxOfCPU =
    data_cpu_usage && data_cpu_usage.length > 0
      ? Math.max(...data_cpu_usage[0].values.map((o) => o[1]), 0)
      : 0;
  unitObjectCPU = formatSizeUnits(maxOfCPU, 1);

  var maxY =
    environmentInsights?.total_cpu >= 1
      ? environmentInsights.total_cpu
      : environmentInsights.total_cpu * unitObjectCPU.MultiplyBy;
  maxY = props.source === "overview" ? maxY * replicas : maxY;

  return (
    <ChartCard
      title={t("Environment.OverviewTab.cpuUsage")}
      data={
        data_cpu_usage && data_cpu_usage.length > 0
          ? convertToChartData(data_cpu_usage[0].values, 1, unitObjectCPU)
          : []
      }
      lineColor={"lightBlue"}
      suffix={unitObjectCPU && unitObjectCPU.Unit}
      maxY={maxY}
      ytitle={"CPU"}
      xtitle="Time"
      data-test="chart-card"
    />
  );
};

export default withTranslation()(CPUChartCard);
