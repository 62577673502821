import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import { getSecuritySeverity } from "../../../../helpers/utils";
import KeyValueRow from "../../../../components/keyvaluerow/KeyValueRow";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  cardHeader: {
    padding: "0 !important",
  },
  expand: {
    transform: "rotate(0deg)",
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  cardHeaderAction: {
    marginTop: 8,
    marginRight: 20,
  },
});

interface Props {
  detail: any;
  serialNum: number;
}

function VulnerableDetail(props: Props) {
  const classes = useStyles();
  const { t } = useTranslation(); // Note the change here to use destructuring
  const details = props.detail;

  const [expandSysVar, setExpandSysVar] = useState(false);

  const handleExpand = () => {
    setExpandSysVar(!expandSysVar);
  };

  const vulnerabilityRow = (rowKey: string, rowValue: string) => {
    return (
      <div style={{ paddingBottom: "10px" }}>
        <KeyValueRow rowKey={rowKey} rowValue={rowValue} />
      </div>
    );
  };

  const getSeverityLabel = (severity : any) => {
    switch (severity) {
      case 'LOW':
        return t('Cluster.Security.Low');
      case 'MEDIUM':
        return t('Cluster.Security.Medium');
      case 'HIGH':
        return t('Cluster.Security.High');
      case 'CRITICAL':
        return t('Cluster.Security.Critical');
      default:
        return severity;
    }
  };

  return (
    <>
      <Card className="m-t-20">
        <CardHeader
          className={classes.cardHeader}
          classes={{ action: classes.cardHeaderAction }}
          title={
            <Typography variant="h5" display="inline">
              <strong><span>{props.serialNum}. </span> {details.title}</strong>
            </Typography>
          }
          avatar={
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expandSysVar,
              })}
              onClick={handleExpand}
            >
              <ExpandMoreIcon />
            </IconButton>
          }
          action={
            <Tooltip title={t('Cluster.Security.severityis') + " " + getSeverityLabel(details.properties.severity)} arrow>
              <Typography 
                variant="h5" 
                display="inline" 
                style={{ color: getSecuritySeverity(details.properties.severity) }}
              >
                {getSeverityLabel(details.properties.severity)}
              </Typography>
            </Tooltip>
          }
        />
        <Collapse in={expandSysVar}>
          <Divider />
          <br />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                {vulnerabilityRow(t("Cluster.Security.status"), details.properties.status)}
                <div style={{ paddingBottom: "10px" }}>
                  <KeyValueRow 
                    rowKey={t("Cluster.Security.identifier")} 
                    rowValue={details.identifier} 
                    type="url" 
                    src={`https://www.google.com/search?q=${details.identifier}`} 
                  />
                </div>
                {vulnerabilityRow(t("Cluster.Security.blueprint"), details.blueprint)}
                {vulnerabilityRow(t("Cluster.Security.relations"), details.relations)}
                {vulnerabilityRow(t("Cluster.Security.team"), details.team)}
              </Grid>
              <Grid item xs={12} md={6}>
                {vulnerabilityRow(t("Cluster.Security.fixedversion"), details.properties.fixed_version)}
                {vulnerabilityRow(t("Cluster.Security.installedversion"), details.properties.installed_version)}
                {vulnerabilityRow(t("Cluster.Security.package"), details.properties.package)}
                {vulnerabilityRow(t("Cluster.Security.recommendation"), details.properties.recommendation)}
                {vulnerabilityRow(t("Cluster.Security.references"), details.properties.references)}
              </Grid>
              <Grid item xs={12} md={12}>
                <Grid container spacing={2} direction="row">
                  <Grid item style={{ color: "#2979ff" }}>{t("Cluster.Security.description")}</Grid>
                  <Grid item>{details.properties.description}</Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Collapse>
      </Card>
    </>
  );
}

export default VulnerableDetail;
