import React, { Component } from "react";
import { connect } from "react-redux";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import {
  getWorkflowLog,
  updateClusterPackageStatus,
  updateClusterWorkflowLog,
  updateClusterWorkflows,
  updateClusterLogs
} from "../pages/cluster/redux/actions";
import {
  updateOperatorStatus,
  updateOperatorLogs,
} from "../pages/operators/redux/actions";
import { sessionTokenSelector } from "../pages/login/redux/selectors";

export class ClusterWS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ws: null,
    };
  }

  componentDidMount() {
    if (this.props.clusterId > 0) this.socketConnection(this.props.clusterId);
  }

  componentWillUnmount() {
    if (this.state.ws !== null) {
      this.state.ws.close(1000);
    }
  }

  /*UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps){
            if(nextProps.envId > 0)
            {
                if (this.props.envId === 0 || this.props.envId !== nextProps.envId)
                {
                    if(this.state.ws !== null){
                        this.state.ws.close(1000);
                    }
                    this.socketConnection(nextProps.envId);
                }
            }
        }
    }*/

  /* istanbul ignore next */
  socketConnection = (id) => {
    if (this.state.ws !== null) return;
    let $this = this;
    const sessionToken = this.props.validSessionId;
    var ws = new W3CWebSocket(
      window?.config?.REACT_APP_SOCKET_IO_ENDPOINT +
        "?token=" +
        sessionToken +
        "&room=cluster-" +
        id
    );
    this.setState({
      ws,
    });
    ws.onopen = () => {
      console.log("WebSocket Client Connected");
      this.setState({ ws: ws });
    };
    ws.onclose = (e) => {
      console.log("WebSocket connection closed");
      this.setState({ ws: null });
      if (e.code !== 1000) this.socketConnection(id);
    };
    ws.onerror = () => {
      console.log("WebSocket error");
      ws.close();
      this.setState({ ws: null });
    };
    ws.addEventListener("message", (response) => {
      if (response.type === "message") {
        const { data } = response;
        // if(data.type === "operator-status"){
        // console.log(data)
        // }
        if (data) {
          const _data = JSON.parse(data);
          if (_data.type === "create-cluster-watcher") {
            $this.updateMainMessage(_data);
            // this.setState({
            //     workflowPhase : _data.data && _data.data.workflow && _data.data.workflow.phase
            // })
          } else if (
            _data.type === "cluster-apply" ||
            _data.type === "cluster-destroy" ||
            _data.type === "package-install" ||
            _data.type === "package-uninstall" ||
            _data.type === "vcluster-provision"
          ) {
            //$this.updateLogMessage(_data);
            $this.updateClusterLogMessage(_data);
            // let { logData } = this.state;
            // logData = logData + "\n" + _data.data;
            // this.setState({ logData })
          } else if (_data.type === "package-status") {
            const nsData = _data.data;
            let newStatus = {
              ...this.props.clusterPackageStatus,
              [nsData.namespace]: {
                ...nsData,
              },
            };

            // _dataKeys.forEach(_dk => {

            //     const packageNamespace = _dk
            //     const status = {
            //         namespace : packageNamespace,
            //         status : _data.data[_dk]
            //     }
            //     newStatus.push(status)
            // const pac = this.props.clusterPackageStatus?.find(p => {
            //     return p.namespace === packageNamespace
            // })
            // let newStatus = []
            // if(pac){
            //     newStatus = this.props.clusterPackageStatus.map(ps => {
            //         if(ps.namespace === packageNamespace){
            //             ps.status = data.data[packageNamespace]
            //         }
            //         return ps
            //     })
            // }else{
            //     newStatus = [...this.props.clusterPackageStatus, {
            //         namespace: packageNamespace,
            //         status: data.data[packageNamespace]
            //     }]
            // }
            // })
            this.props.updateClusterPackageStatus(newStatus);
          } else if (_data.type === "operator-status") {
            let newStatus = {
              ...this.props.operatorStatus,
              [_data.name]: _data.data,
            };

            this.props.updateOperatorStatus(newStatus);
          } else if (_data.type === "activity-logs") {
            const updatedOperatorLogs = [
              _data.data,
              ...this.props.realTimeOperatorLogs,
            ];

            this.props.updateOperatorLogs(updatedOperatorLogs);
          }
        }
      }
    });
  };

  /* istanbul ignore next */
  updateMainMessage = (_data) => {
    //console.log("ci-watcher")
    const _newWorkflow = {
      // workflow: {
      //   object_meta: {
      //     creationTimestamp: _data.data.workflow.started_at,
      //     name: _data.name,
      //   },
      //   status: {
      //     finished_at: _data.data.workflow.finished_at,
      //     phase: _data.data.workflow.phase,
      //     started_at: _data.data.workflow.started_at,
      //     message: _data.data.workflow.message,
      //   },
      // },
      ci_request: {}, //_data.data.CIRequest,
      log_steps: _data.log_steps,
      type: _data.data.type ? _data.data.type : "vcluster-provision", //'cluster-watcher' 
      pipeline: _data.data.pipeline
    };
    let _clusterWorkflows = [];
    if (this.props.clusterWorkflows) {
      _clusterWorkflows = [...this.props.clusterWorkflows];
    }
    const _workflowIndex = _clusterWorkflows.findIndex(
      (x) => x.pipeline.runner === _data.name
    );
    if (_workflowIndex > -1) {
      _clusterWorkflows[_workflowIndex] = _newWorkflow;
      // _clusterWorkflows[_workflowIndex] = {
      //   ..._newWorkflow, pipeline: _clusterWorkflows[_workflowIndex].pipeline
      // };
    } else {
      _clusterWorkflows.unshift(_newWorkflow);
    }
    this.props.updateClusterWorkflows(_clusterWorkflows);
    if (_data.data.pipeline.status === "Succeeded") {
      let $this = this;
      setTimeout(function () {
        $this.props.getWorkflowLog($this.props.clusterId, _data.name);
      }, 2000);
    }
  };
  /* istanbul ignore next */
  updateLogMessage = (_data) => {
    // console.log("ci-logs")
    let _clusterWorkflowLog = [...this.props.clusterWorkflowLog];
    const _workflowLogIndex = _clusterWorkflowLog.findIndex(
      (x) => x.name === _data.workflow_name
    );
    if (_workflowLogIndex > -1) {
      if (
        _clusterWorkflowLog[_workflowLogIndex] &&
        _clusterWorkflowLog[_workflowLogIndex].logs &&
        _clusterWorkflowLog[_workflowLogIndex].logs.length > 0
      ) {
        _clusterWorkflowLog[_workflowLogIndex].logs.push({
          log: _data.data,
          // Step:_data.Step ? _data.Step.Step : 0,
          // type:_data.Step ? _data.Step.type : "",
        });
      }
    } else {
      let newWorkflowlog = {
        name: _data.workflow_name,
        logs: [
          {
            log: _data.data,
            // Step:_data.Step ? _data.Step.Step : 0,
            // type:_data.Step ? _data.Step.type : "",
          },
        ],
        type: _data.type,
      };
      _clusterWorkflowLog.push(newWorkflowlog);
    }
    this.props.updateClusterWorkflowLog(_clusterWorkflowLog);
  };

  updateClusterLogMessage = (_data) => {
    console.log("cluster-logs");
    let _clusterLogs = [...this.props.clusterLogs];
    const _workflowLogIndex = _clusterLogs.findIndex(
      (x) => x.name === _data.workflow_name && x.task === _data.task_name && x.step === _data.step_name
    );
    if (_workflowLogIndex > -1) {
      // if (_envCILogs[_workflowLogIndex] && _envCILogs[_workflowLogIndex].log) {
      _clusterLogs[_workflowLogIndex] = {
        ..._clusterLogs[_workflowLogIndex],
        log: _clusterLogs[_workflowLogIndex].log ? _clusterLogs[_workflowLogIndex].log + _data.data : _data.data
      }
      // }
    } else {
      let newWorkflowlog = {
        name: _data.workflow_name,
        task: _data.task_name,
        step: _data.step_name,
        log: _data.data
      };
      _clusterLogs.push(newWorkflowlog);
    }
    this.props.updateClusterLogs(_clusterLogs);
  };

  getEmptyDiv = () => {
    return <div data-test="main"></div>;
  };

  render() {
    return this.getEmptyDiv();
  }
}

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  clusterWorkflows: state.ClusterReducer.clusterWorkflows,
  clusterWorkflowLog: state.ClusterReducer.clusterWorkflowLog,
  clusterPackageStatus: state.ClusterReducer.clusterPackageStatus,
  validSessionId: sessionTokenSelector(state),
  operatorStatus: state.OperatorReducer.operatorStatus,
  realTimeOperatorLogs: state.OperatorReducer.realTimeOperatorLogs,
  clusterLogs: state.ClusterReducer.clusterLogs
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch) => {
  return {
    getWorkflowLog: (id, workflowName) =>
      dispatch(getWorkflowLog(id, workflowName)),
    updateClusterWorkflowLog: (workflowLogs) =>
      dispatch(updateClusterWorkflowLog(workflowLogs)),
    updateClusterWorkflows: (workflows) =>
      dispatch(updateClusterWorkflows(workflows)),
    updateClusterPackageStatus: (status) =>
      dispatch(updateClusterPackageStatus(status)),
    updateOperatorStatus: (status) => dispatch(updateOperatorStatus(status)),
    updateOperatorLogs: (logs) => dispatch(updateOperatorLogs(logs)),
    updateClusterLogs: (logs) => dispatch(updateClusterLogs(logs))
  };
};

export default connect(mapStateToProps, mapDispatchtoProps)(ClusterWS);
