const SUPPORT_PREFIX = "@SUPPORT";

export const GET_USER_TICKETS = `${SUPPORT_PREFIX}/GET_USER_TICKET`;
export const GET_USER_TICKETS_SUCCESS = `${SUPPORT_PREFIX}/GET_USER_TICKET_SUCCESS`;
export const GET_USER_TICKETS_FAILURE = `${SUPPORT_PREFIX}/GET_USER_TICKET_FAILURE`;
export const GET_MORE_USER_TICKETS_SUCCESS = `${SUPPORT_PREFIX}/GET_MORE_USER_TICKETS_SUCCESS`;

export const CREATE_USER_TICKETS = `${SUPPORT_PREFIX}/CREATE_USER_TICKET`;
export const CREATE_USER_TICKETS_SUCCESS = `${SUPPORT_PREFIX}/CREATE_USER_TICKET_SUCCESS`;
export const CREATE_USER_TICKETS_FAILURE = `${SUPPORT_PREFIX}/CREATE_USER_TICKET_FAILURE`;

export const ASSIGN_USER_TICKETS = `${SUPPORT_PREFIX}/ASSIGN_USER_TICKET`;
export const ASSIGN_USER_TICKETS_SUCCESS = `${SUPPORT_PREFIX}/ASSIGN_USER_TICKET_SUCCESS`;
export const ASSIGN_USER_TICKETS_FAILURE = `${SUPPORT_PREFIX}/CREATE_USER_TICKET_FAILURE`;

export const GET_TICKET_DETAIL = `${SUPPORT_PREFIX}/GET_TICKET_DETAIL`;
export const GET_TICKET_DETAIL_SUCCESS = `${SUPPORT_PREFIX}/GET_TICKET_DETAIL_SUCCESS`;
export const GET_TICKET_DETAIL_FAILURE = `${SUPPORT_PREFIX}/GET_TICKET_DETAIL_FAILURE`;

export const GET_TICKET_GROUP = `${SUPPORT_PREFIX}/GET_TICKET_GROUP`;
export const GET_TICKET_GROUP_SUCCESS = `${SUPPORT_PREFIX}/GET_TICKET_GROUP_SUCCESS`;
export const GET_TICKET_GROUP_FAILURE = `${SUPPORT_PREFIX}/GET_TICKET_GROUP_FAILURE`;

export const CREATE_TICKET_GROUP = `${SUPPORT_PREFIX}/CREATE_TICKET_GROUP`;
export const CREATE_TICKET_GROUP_SUCCESS = `${SUPPORT_PREFIX}/CREATE_TICKET_GROUP_SUCCESS`;
export const CREATE_TICKET_GROUP_FAILURE = `${SUPPORT_PREFIX}/CREATE_TICKET_GROUP_FAILURE`;

export const CREATE_ISSUE_TYPE = `${SUPPORT_PREFIX}/CREATE_ISSUE_TYPE`;
export const CREATE_ISSUE_TYPE_SUCCESS = `${SUPPORT_PREFIX}/CREATE_ISSUE_TYPE_SUCCESS`;
export const CREATE_ISSUE_TYPE_FAILURE = `${SUPPORT_PREFIX}/CREATE_ISSUE_TYPE_FAILURE`;

export const REPLY_ISSUE = `${SUPPORT_PREFIX}/REPLY_ISSUE`;
export const REPLY_ISSUE_SUCCESS = `${SUPPORT_PREFIX}/REPLY_ISSUE_SUCCESS`;
export const REPLY_ISSUE_FAILURE = `${SUPPORT_PREFIX}/REPLY_ISSUE_FAILURE`;

export const GET_SUPPORT_TYPES = `${SUPPORT_PREFIX}/GET_SUPPORT_TYPES`;
export const GET_SUPPORT_TYPES_SUCCESS = `${SUPPORT_PREFIX}/GET_SUPPORT_TYPES_SUCCESS`;
export const GET_SUPPORT_TYPES_FAILURE = `${SUPPORT_PREFIX}/GET_SUPPORT_TYPES_FAILURE`;

export const GET_ISSUE_TYPES = `${SUPPORT_PREFIX}/GET_ISSUE_TYPES`;
export const GET_ISSUE_TYPES_SUCCESS = `${SUPPORT_PREFIX}/GET_ISSUE_TYPES_SUCCESS`;
export const GET_ISSUE_TYPES_FAILURE = `${SUPPORT_PREFIX}/GET_ISSUE_TYPES_FAILURE`;

export const DELETE_TICKET = `${SUPPORT_PREFIX}/DELETE_TICKET`;
export const DELETE_TICKET_SUCCESS = `${SUPPORT_PREFIX}/DELETE_TICKET_SUCCESS`;
export const DELETE_TICKET_FAILURE = `${SUPPORT_PREFIX}/DELETE_TICKET_FAILURE`;

export const UPDATE_TICKET = `${SUPPORT_PREFIX}/UPDATE_TICKET`;
export const UPDATE_TICKET_SUCCESS = `${SUPPORT_PREFIX}/UPDATE_TICKET_SUCCESS`;
export const UPDATE_TICKET_FAILURE = `${SUPPORT_PREFIX}/UPDATE_TICKET_FAILURE`;

export const RESET_TICKET_DETAIL = `${SUPPORT_PREFIX}/RESET_TICKET_DETAIL`;

export const getUserTickets = (
  pageNumber,
  limit,
  status,
  search,
  priority,
  category
  ) => ({
  type: GET_USER_TICKETS,
  data: {
    pageNumber,
    limit,
    status,
    search,
    priority,
    category,
  },
});

export const createUserTickets = (payload, callback) => ({
  type: CREATE_USER_TICKETS,
  data: {
    payload,
    callback,
  },
});

export const getTicketDetail = (id) => ({
  type: GET_TICKET_DETAIL,
  data: {
    id,
  },
});

export const deleteTicket = (id, callback) => ({
  type: DELETE_TICKET,
  data: {
    id,
    callback,
  },
});

export const updateTicket = (id, payload) => ({
  type: UPDATE_TICKET,
  data: {
    id,
    payload,
  },
});

export const assignUserTicket = (payload) => ({
  type: ASSIGN_USER_TICKETS,
  data: {
    payload,
  },
});

export const getTicketGroups = () => ({
  type: GET_TICKET_GROUP,
});

export const createTicketGroups = (payload) => ({
  type: CREATE_TICKET_GROUP,
  data: {
    payload,
  },
});

export const createIssueType = (payload) => ({
  type: CREATE_ISSUE_TYPE,
  data: {
    payload,
  },
});

export const replyIssue = (payload, callback) => ({
  type: REPLY_ISSUE,
  data: {
    payload,
    callback,
  },
});

export const getSupportTypes = () => ({
  type: GET_SUPPORT_TYPES,
});

export const getIssueTypes = (payload) => ({
  type: GET_SUPPORT_TYPES,
  data: {
    payload,
  },
});

export const resetTicketDetail = () => ({
  type: RESET_TICKET_DETAIL,
});
