//import { withStyles } from '@material-ui/core/styles';
import React, { useEffect } from "react";
import { withTranslation,WithTranslation } from "react-i18next";
import { connect,ConnectedProps } from 'react-redux';
import { PieChart, Pie, Cell, Legend, LegendPayload } from 'recharts';
import { getPieChartDataFormat } from '../../../../../helpers/utils'
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { getCIStatistics, clearCIStatistics } from '../../../cicdredux/actions'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import BackdropLoader from '../../../../../components/loader/BackdropLoader';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { StyledTableCell } from "../../../../../components/styledtabelcell/StyledTabelCell"
import { Dispatch } from "redux";
import { PieLabelRenderProps ,LegendProps} from "recharts";
const COLORS = ['#0088FE', '#FF8042', '#00C49F', '#FFBB28'];

const RADIAN = Math.PI / 180;

interface CiStatistics {
  aborted:   number;
  not_build: number;
  success:   number;
  unstable:  number;
}

interface Iprops extends PropsFromRedux,WithTranslation{
  handleChange:(e: React.ChangeEvent<{}>|null,value:number)=>void
}
// interface RenderLabelProps extend PieProps
// ContentRenderer<PieLabelRenderProps>
const renderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent,
}:PieLabelRenderProps) => {
  if (!cx || !cy || typeof innerRadius === "undefined" || innerRadius === null || !outerRadius){
    return <></>
  }
  if(!midAngle){
    return <></>
  }
  if (!percent) {
    return <></>
  }

  const radius = parseInt(innerRadius.toString()) + ((parseInt(outerRadius.toString()) - parseInt(innerRadius.toString())) * 0.5);
  const x = parseInt(cx.toString()) + (radius * Math.cos(-midAngle * RADIAN));
  const y = parseInt(cy.toString()) + (radius * Math.sin(-midAngle * RADIAN));

  return (
    <text x={x} y={y} fill="white" textAnchor={x > parseInt(cx.toString()) ? 'start' : 'end'} dominantBaseline="central">
      { percent > 0 && `${(percent * 100).toFixed(0)}%` }
    </text>
  );
};

const useStyles = () => ({
  legendStyle: {
    left: 10
  }
});

const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      '&': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

export const renderLegend = (props:LegendProps) => {
  const { payload } = props;
  return (
    <TableContainer data-test="main-container">
      <Table aria-label="">
        <TableHead>
          <TableRow>
            {/* <StyledTableCell></StyledTableCell> */}
            {/* <StyledTableCell align="right">current</StyledTableCell>
                        <StyledTableCell align="right">percentage</StyledTableCell> */}
          </TableRow>
        </TableHead>
        <TableBody data-test="table-body">
          {
           payload &&  payload.map((entry:LegendPayload, index:number) => {
              return (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                      <div className="displayFlex"><FiberManualRecordIcon fontSize="small" style={{ color: entry.color }}/><span data-test="label-value">{entry.value}</span></div>
                  </StyledTableCell>
                  {/* <StyledTableCell align="right">{ entry.payload.value }</StyledTableCell>
                                <StyledTableCell align="right">{ Math.round(entry.payload.percent * 100) } %</StyledTableCell> */}
                </StyledTableRow>
              )
            })
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const style = {
  top: 60,
  left: 400,
  lineHeight: '24px',
};

const xsstyle = {
  top: 280,
  left: 100,
  lineHeight: '24px'
}

export function OverviewCI(props:Iprops) {
  const theme = useTheme();
  const widthMatches = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    if (props.environmentDetails && props.environmentDetails.id > 0) {
      props.getCIStatistics(props.environmentDetails.id);
    }
    return () => {
      props.clearCIStatistics();
    };
  }, []);

  useEffect(() => {
    if (props.environmentDetails.id > 0) {
      props.getCIStatistics(props.environmentDetails.id);
    }
  }, [props.environmentDetails]);

  useEffect(() => {
    if (props.ciStatistics) {
      const objectKeys = Object.keys(props.ciStatistics);
      if (!objectKeys || objectKeys.length === 0) {
        props.handleChange(null, 1)
      }
    }
  }, [props.ciStatistics]);

  return (
    <div data-test="main-container">
      {
        props.ciStatistics &&
        <PieChart width={widthMatches ? 300 : 400} height={widthMatches ? 300 : 400} data-test="pie-chart">
          <Pie
            data={getPieChartDataFormat(props.ciStatistics)}
            cx={widthMatches ? 150 : 200}
            cy={widthMatches ? 150 : 200}
            labelLine={false}
            label={renderCustomizedLabel}
            fill="#8884d8"
            dataKey="value" 
          >
            {
              getPieChartDataFormat(props.ciStatistics).map((entry:CiStatistics, index:number) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} data-test="pie-cell"/>)
            }
          </Pie>
          <Legend content={renderLegend} layout="vertical" verticalAlign="middle" wrapperStyle={widthMatches ? xsstyle : style} data-test="chart-legend" />
        </PieChart>
      }
      {
        !props.ciStatistics &&
        <SkeletonTheme height={250} data-test="ci-skel">
          <Skeleton circle={true} height={300} width={300} />
        </SkeletonTheme>
      }
      {props.fetchingCiStats && <BackdropLoader message={props.t('Environment.CiTab.fetchCiStats')} />}
    </div>
  )
}
/*
class OverviewCI_Old extends Component {
    constructor(props){
        super(props);
        this.state = {
        }
    }

    componentDidMount(){
      if(this.props.environmentDetails.id > 0)
        this.props.getCIStatistics(this.props.environmentDetails.id);
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
      if(nextProps){
        if(nextProps.environmentDetails.id > 0)
        {
          if (!this.props.environmentDetails.id || this.props.environmentDetails.id != nextProps.environmentDetails.id)
          {
            this.props.getCIStatistics(nextProps.environmentDetails.id);
          }
        }
      }
      if(!this.props.ciStatistics && nextProps.ciStatistics){
        const objectKeys = Object.keys(nextProps.ciStatistics);
        if(!objectKeys || objectKeys.length == 0)
        {
          this.props.handleChange(null, 1)
        }
      }
    }

    componentWillUnmount(){
      this.props.clearCIStatistics();
    }
    render () {
      //const { classes } = this.props;
        return (
          <div>
                {
              this.props.ciStatistics &&
              <PieChart width={400} height={400}>
                  <Pie
                    data={getPieChartDataFormat(this.props.ciStatistics)}
                    cx={200}
                    cy={200}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    fill="#8884d8"
                    dataKey="value"
                  >
                      {
                    getPieChartDataFormat(this.props.ciStatistics).map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                }
                </Pie>
                <Legend content={renderLegend} layout="vertical" verticalAlign="middle" wrapperStyle={ style } />
              </PieChart>
            }
                {
              !this.props.ciStatistics &&
              <SkeletonTheme height={250}>
                  <Skeleton circle={true} height={300} width={300} variant='circle' />
              </SkeletonTheme>
            }
                {this.props.fetchingCiStats && <BackdropLoader message="Fetching CI Stats" />}
            </div>
        )
    }
}
*/
const mapStateToProps = (state:any) => ({
  environmentDetails: state.EnvironmentReducer.environmentDetails,
  ciStatistics: state.CICDReducer.ciStatistics,
  fetchingCiStats: state.CICDReducer.fetchingCiStats,
})

const mapDispatchtoProps = (dispatch:Dispatch) => {
  return {
    getCIStatistics: (id:number) => dispatch(getCIStatistics(id)),
    clearCIStatistics: () => dispatch(clearCIStatistics())
  }
}

const connector=connect(
  mapStateToProps,
  mapDispatchtoProps
)
export default connector(withStyles(useStyles)(withTranslation()(OverviewCI)))
type PropsFromRedux=ConnectedProps<typeof connector>