import { call, takeLatest, put, select } from "redux-saga/effects";
import axios from "axios";
import endpoints from "../../../../constants/endpoints";
import {
  UPDATE_PROFILE_CALL,
  UPDATE_PASSWORD,
  DEACTIVATE_ACCOUNT,
  GET_ACCOUNT_LIST,
  GET_ACCOUNT_LIST_FALIURE,
  GET_ACCOUNT_LIST_SUCCESS,
  UNLINK_ACCOUNT,
  UNLINK_ACCOUNT_FALIURE,
  UNLINK_ACCOUNT_SUCCESS,
  GET_ACCESS_TOKEN_LIST,
  GET_ACCESS_TOKEN_LIST_SUCCESS,
  GET_ACCESS_TOKEN_LIST_FAILURE,
  CREATE_ACCESS_TOKEN,
  CREATE_ACCESS_TOKEN_SUCCESS,
  DELELE_ACCESS_TOKEN,
  DELELE_ACCESS_TOKEN_SUCCESS,
  DELELE_ACCESS_TOKEN_FAILURE,
  DELETE_ALL_ACCESS_TOKEN,
  CLEAR_ACCESS_TOKEN,
  CREATE_ACCESS_TOKEN_FAILURE,
} from "./actions";
import toast from "../../../../components/toast/Toast";
import { sessionTokenSelector } from "../../../login/redux/selectors";
import { fetchProfile } from "../../../login/redux/actions";
import { logout } from "../../../login/redux/actions";

function updateProfile(sessionToken, payload) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.put(
    endpoints.UPDATE_USER_DETAILS.replace(":userId", payload.id),
    payload,
    config
  );
}

// Generator Call
function* updateProfileGenerator(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(
      updateProfile,
      sessionToken,
      payload.data.payload
    );
    //const data = response.data;
    if (response.status === 200) {
      yield put(fetchProfile(payload.data.payload.id));
      toast.success("Profile Details Updated Successfully");
    } else {
      toast.error("Some error while updating Profile details");
    }
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    )
      toast.error(error.response.data.error);
    else toast.error("Some error while updating Profile details");
  }
}

function updatePasswordCall(sessionToken, payload) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.post(endpoints.AUTH.UPDATE_PASSWORD, payload, config);
}

// Generator Call
function* updatePassword(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(
      updatePasswordCall,
      sessionToken,
      payload.data.payload
    );
    //const data = response.data;
    if (response.status === 200) {
      //yield put(fetchProfile(payload.data.payload.id))
      toast.success("Password updated successfully");
    } else {
      toast.error("Some error while updating password");
    }
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    )
      toast.error(error.response.data.error);
    else toast.error("Some error while updating password");
  }
}

function deactivateAccountCall(sessionToken) {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.get(endpoints.AUTH.DEACTIVATE_ACCOUNT, config);
}

// Generator Call
function* deactivateAccount() {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(deactivateAccountCall, sessionToken);
    // const data = response.data;
  
     yield put(logout());
     toast.success(response.message);
  
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    )
      toast.error(error.response.data.error);
    else toast.error("Some error while deactivating account");
  }
}

function* getAccountList() {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "basic " + sessionToken,
      },
    };

    const response = yield axios.get(endpoints.GET_ACCOUNT_LIST, config);

    const data = response.data;
    if (data) {
      yield put({ type: GET_ACCOUNT_LIST_SUCCESS, data });
    } else {
      yield put({ type: GET_ACCOUNT_LIST_FALIURE });
      toast.error(response.message ?? "Failed To Fetch Linked Accounts");
    }
  } catch (error) {
    yield put({ type: GET_ACCOUNT_LIST_FALIURE });
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    ) {
      toast.error(error.response.data.error);
    }
  }
}

function* unlinkAccount(payload) {
  console.log(payload);
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "basic " + sessionToken,
      },
    };
    const response = yield axios.delete(
      endpoints.UNLINK_ACCOUNT.replace(":id", payload.data.id),
      config
    );

    if (response.status === 200 || response.status === 204) {
      yield put({ type: UNLINK_ACCOUNT_SUCCESS });
      yield put({ type: GET_ACCOUNT_LIST });
      toast.success(response.message ?? "Account Successfully Unlinked");
    } else {
      yield put({ type: UNLINK_ACCOUNT_FALIURE });
      toast.error(response.message ?? "Account Unlinking Failed");
    }
  } catch (error) {
    yield put({ type: UNLINK_ACCOUNT_FALIURE });
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    ) {
      toast.error(error.response.data.error);
    }
  }
}

//for access token
function* getTokenList() {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "basic " + sessionToken,
      },
    };

    const response = yield axios.get(endpoints.GET_ACCESS_TOKEN_LIST, config);

    const data = response.data;
    if (response.status === 200) {
      yield put({ type: GET_ACCESS_TOKEN_LIST_SUCCESS, data:data?data:[] });
    } else {
      yield put({ type: GET_ACCESS_TOKEN_LIST_FAILURE });
    }
  } catch (error) {
    yield put({ type: GET_ACCESS_TOKEN_LIST_FAILURE });
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    ) {
      toast.error(error.response.data.error);
    }
  }
}

function createAccessTokenCall(sessionToken, payload) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "basic " + sessionToken,
    },
  };
  // return axios.post(endpoints.CREATE_ACCESS_TOKEN.replace(":name", payload.name).replace(":date", payload.date), null , config)
  return axios.post(endpoints.CREATE_ACCESS_TOKEN, payload, config);
}

function* createAccessToken(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(
      createAccessTokenCall,
      sessionToken,
      payload.data.jsonBody
    );
    const data = response.data;
    yield put({ type: CREATE_ACCESS_TOKEN_SUCCESS, data });
    toast.success("Token created successfully");
      // yield call(getTokenList);
  } catch (error) {
    yield put({type: CREATE_ACCESS_TOKEN_FAILURE})
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    )
      toast.error(error.response.data.error);
    else toast.error("Some error while creating token");
  }
}

function* deleteAccessToken(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "basic " + sessionToken,
      },
    };
    const response = yield axios.delete(
      endpoints.DELELE_ACCESS_TOKEN.replace(":id", payload.data.id),
      config
    );
    if (response.status === 200 || response.status === 204) {
      yield put({ type: DELELE_ACCESS_TOKEN_SUCCESS, data:payload.data });
      // yield call(getTokenList);
      toast.success(response.message ?? "Access Token Successfully deleted");
    } else {
      yield put({ type: DELELE_ACCESS_TOKEN_FAILURE });
    }
  } catch (error) {
    yield put({ type: DELELE_ACCESS_TOKEN_FAILURE });
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    ) {
      toast.error(error.response.data.error);
    }
  }
}

function* deleteAllTokens() {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "basic " + sessionToken,
      },
    };
    const response = yield axios.delete(
      endpoints.DELETE_ALL_ACCESS_TOKEN,
      config
    );
    if (response.status === 200 || response.status === 204) {
      yield put({type: CLEAR_ACCESS_TOKEN});
      toast.success(
        response.message ?? "All Access Token Successfully deleted"
      );
    }
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    ) {
      toast.error(error.response.data.error);
    }
  }
}

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(UPDATE_PROFILE_CALL, updateProfileGenerator);
  yield takeLatest(UPDATE_PASSWORD, updatePassword);
  yield takeLatest(DEACTIVATE_ACCOUNT, deactivateAccount);
  yield takeLatest(GET_ACCOUNT_LIST, getAccountList);
  yield takeLatest(UNLINK_ACCOUNT, unlinkAccount);
  yield takeLatest(GET_ACCESS_TOKEN_LIST, getTokenList);
  yield takeLatest(CREATE_ACCESS_TOKEN, createAccessToken);
  yield takeLatest(DELELE_ACCESS_TOKEN, deleteAccessToken);
  yield takeLatest(DELETE_ALL_ACCESS_TOKEN, deleteAllTokens);
}
