import {
  UPDATE_PAYMENT_SETTING,
  UPDATE_PAYMENT_SETTING_FAILURE,
  UPDATE_PAYMENT_SETTING_SUCCESS,
  GET_INVOICE_SUCCESS,
  GET_PAYMENT_SETTING_SUCCESS,
  GET_PAYMENT_THRESHOLD_SUCCESS,
  UPDATE_PAYMENT_THRESHOLD,
  UPDATE_PAYMENT_THRESHOLD_SUCCESS,
  UPDATE_PAYMENT_THRESHOLD_FAILURE,
  GET_DEDUCTION_BY_ID_SUCCESS,
  RESET_DEDUCTION,
  GET_PAYMENT_HISTORY_SUCCESS,
  GET_INVOICE_BY_ID_SUCCESS,
  DOWNLOAD_INVOICE_BY_ID_SUCCESS,
  RESET_DOWNLOAD_INVOICE_BY_ID,
  RESET_INVOICE,
  RESET_PAYMENT_SETTING,
  RESET_PREVIOUS_INVOICE,
  RESET_PAYMENT_HISTORY,
  RESET_PAYMENT_THRESHOLD,
  GET_PAYMENT_DETAILS_SUCCESS,
  GET_PAYMENT_DETAILS,
  GET_PAYMENT_DETAILS_FAILURE,
  RESET_PAYMENT_DETAILS,
  GET_GATEWAY_DATA_SUCCESS,
  GET_PAYMENT_HISTORY,
  GET_PAYMENT_HISTORY_FAILURE,
  GET_LATEST_PAYMENT_HISTORY_SUCCESS,
} from "./actions";
import initialState from "./initialState";

const reducer = (state = initialState, payload) => {
  switch (payload.type) {
    case GET_INVOICE_SUCCESS:
      return {
        ...state,
        userInvoice: payload.data,
      };
    case GET_PAYMENT_SETTING_SUCCESS:
      return {
        ...state,
        paymentSetting: payload.data,
      };
    case UPDATE_PAYMENT_SETTING:
      return {
        ...state,
        updatingPaymentSetting: true,
      };
    case UPDATE_PAYMENT_SETTING_SUCCESS:
      return {
        ...state,
        updatingPaymentSetting: false,
        paymentSetting: payload.data,
      };
    case UPDATE_PAYMENT_SETTING_FAILURE:
      return {
        ...state,
        updatingPaymentSetting: false,
      };
    case GET_PAYMENT_THRESHOLD_SUCCESS:
      return {
        ...state,
        paymentThreshold: payload.data,
      };
    case UPDATE_PAYMENT_THRESHOLD:
      return {
        ...state,
        updatingPaymentThreshold: true,
      };
    case UPDATE_PAYMENT_THRESHOLD_SUCCESS:
      return {
        ...state,
        updatingPaymentThreshold: false,
        paymentThreshold: payload.data,
      };
    case UPDATE_PAYMENT_THRESHOLD_FAILURE:
      return {
        ...state,
        updatingPaymentThreshold: false,
      };
    case GET_DEDUCTION_BY_ID_SUCCESS:
      return {
        ...state,
        deduction: payload.data,
      };
    case DOWNLOAD_INVOICE_BY_ID_SUCCESS:
      return {
        ...state,
        invoiceData: payload.data,
      };
    case RESET_DOWNLOAD_INVOICE_BY_ID:
      return {
        ...state,
        invoiceData: initialState.invoiceData,
      };
    case RESET_DEDUCTION:
      return {
        ...state,
        deduction: null,
      };
    case GET_PAYMENT_HISTORY:
      return {
        ...state,
        fetchingPaymentHistory: true,
      };
    case GET_PAYMENT_HISTORY_SUCCESS:
      return {
        ...state,
        paymentHistory: payload.data,
        fetchingPaymentHistory: false,
      };
    case GET_PAYMENT_HISTORY_FAILURE:
      return {
        ...state,
        fetchingPaymentHistory: false,
      };
    case GET_LATEST_PAYMENT_HISTORY_SUCCESS:
      return {
        ...state,
        latestPaymentHistory: payload.data,
      };
    case GET_INVOICE_BY_ID_SUCCESS:
      return {
        ...state,
        previousInvoice: payload.data,
      };
    case RESET_INVOICE:
      return {
        ...state,
        userInvoice: initialState.userInvoice,
      };
    case RESET_PAYMENT_SETTING:
      return {
        ...state,
        paymentSetting: initialState.paymentSetting,
      };
    case RESET_PREVIOUS_INVOICE:
      return {
        ...state,
        previousInvoice: initialState.previousInvoice,
      };
    case RESET_PAYMENT_HISTORY:
      return {
        ...state,
        paymentHistory: initialState.paymentHistory,
      };
    case RESET_PAYMENT_THRESHOLD:
      return {
        ...state,
        paymentThreshold: initialState.paymentThreshold,
      };
    case GET_PAYMENT_DETAILS:
      return {
        ...state,
        fetchingPaymentDetails: true,
      };
    case GET_PAYMENT_DETAILS_SUCCESS:
      return {
        ...state,
        paymentDetails: payload.data,
        fetchingPaymentDetails: false,
      };
    case GET_PAYMENT_DETAILS_FAILURE:
      return {
        ...state,
        paymentDetails: payload.data,
        fetchingPaymentDetails: false,
      };
    case RESET_PAYMENT_DETAILS:
      return {
        ...state,
        paymentDetails: initialState.paymentDetails,
      };
    case GET_GATEWAY_DATA_SUCCESS:
      return {
        ...state,
        gatewayData: payload.data,
      };
    default:
      return state;
  }
};

export default reducer;
