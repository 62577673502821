import {
    FETCH_OPERATORS,
    FETCH_OPERATORS_FAILURE,
    FETCH_OPERATORS_SUCCESS,
    CLEAR_OPERATORS,

    INITIATE_FETCH_OPERATORS_STATUS,
    INITIATE_FETCH_OPERATORS_STATUS_SUCCESS,
    INITIATE_FETCH_OPERATORS_STATUS_FAILURE,
    UPDATE_OPERATORS_STATUS,

    FETCH_AVAILABLE_OPERATORS,
    FETCH_AVAILABLE_OPERATORS_SUCCESS,
    FETCH_AVAILABLE_OPERATORS_FAILURE,

    FETCH_OPERATOR_DETAILS,
    FETCH_OPERATOR_DETAILS_SUCCESS,
    FETCH_OPERATOR_DETAILS_FAILURE,
    CLEAR_OPERATOR_DETAILS,
    
    UNINSTALL_OPERATOR,
    UNINSTALL_OPERATOR_SUCCESS,
    UNINSTALL_OPERATOR_FAILURE,

    DEPLOY_OPERATOR,
    DEPLOY_OPERATOR_SUCCESS,
    DEPLOY_OPERATOR_FAILURE,

    REDEPLOY_OPERATOR,
    REDEPLOY_OPERATOR_SUCCESS,
    REDEPLOY_OPERATOR_FAILURE,

    CREATE_OPERATOR_ENVIRONMENT,
    CREATE_OPERATOR_ENVIRONMENT_SUCCESS,
    CREATE_OPERATOR_ENVIRONMENT_FAILURE,
    UPDATE_OPERATOR_DETAILS_LIST,

    FETCH_OPERATOR_LOGS,
    FETCH_OPERATOR_LOGS_SUCCESS,
    FETCH_OPERATOR_LOGS_FAILURE,
    UPDATE_OPERATOR_LOGS,
    CLEAR_OPERATOR_LOGS
  } from './actions';

const initialState = {
    operatorCatalogs: null,
    fetchingOperators: false,

    operatorStatus: null,
    fetchingOperatorStatus: false,

    availableOperators: null,
    fetchingAvailableOperators: false,

    operatorDetails : null,
    fetchingOperatorDetails: false,

    fetchingResources: false,
    resources: null,

    resourceDetails:null,
    fetchingResourceDetails:false,

    fetchingOperatorConfig: false,
    operatorConfig: null,
    uninstallingOperators: false,
    deployingOperators: false,
    redeployingOperators: false,

    operatorDetailsList: [],

    operatorLogs: [],
    realTimeOperatorLogs: [],
    fetchingOperatorLogs: false,
};

  const reducer = (state = initialState, payload) => {
    switch (payload.type) {
      case FETCH_OPERATORS:
        return {
          ...state,
          fetchingOperators: true
        };
      case FETCH_OPERATORS_SUCCESS:
        return {
          ...state,
          operatorCatalogs: payload.data,
          fetchingOperators: false
        };
      case FETCH_OPERATORS_FAILURE:
        return {
          ...state,
          error: payload?.error,
          fetchingOperators: false
        };
      case INITIATE_FETCH_OPERATORS_STATUS:
        return {
          ...state,
          fetchingOperatorStatus: true
        };
      case INITIATE_FETCH_OPERATORS_STATUS_SUCCESS:
        return {
          ...state,
          fetchingOperatorStatus: false,
        };
      case INITIATE_FETCH_OPERATORS_STATUS_FAILURE:
        return {
          ...state,
          fetchingOperatorStatus: false,
        };
      case UPDATE_OPERATORS_STATUS:
        return {
          ...state,
          operatorStatus : payload.data.status,
        }
      case CLEAR_OPERATORS:
        return {
          ...state,
          operatorCatalogs: null,
          operatorStatus: null,
        };
      case FETCH_AVAILABLE_OPERATORS:
        return {
          ...state,
          fetchingAvailableOperators: true
        };
      case FETCH_AVAILABLE_OPERATORS_SUCCESS:
        return {
          ...state,
          availableOperators: payload.data,
          fetchingAvailableOperators: false
        };
      case FETCH_AVAILABLE_OPERATORS_FAILURE:
        return {
          ...state,
          error: payload?.error,
          fetchingAvailableOperators: false
        };
        case FETCH_OPERATOR_DETAILS:
          return {
            ...state,
            fetchingOperatorDetails: true
          }
        case FETCH_OPERATOR_DETAILS_SUCCESS:
          return {
            ...state,
            operatorDetails : payload.data,
            fetchingOperatorDetails: false
    
        }
        case FETCH_OPERATOR_DETAILS_FAILURE:
          return{
            ...state,
            error: payload?.error,
            fetchingOperatorDetails: false
        }
        case CLEAR_OPERATOR_DETAILS:
          return{
            ...state,
            operatorDetails: null
          }
        
        case UNINSTALL_OPERATOR:
          return {
            ...state,
            uninstallingOperators: true
          }
        case UNINSTALL_OPERATOR_SUCCESS:
          return {
            ...state,
            uninstallingOperators: false
          }
        case UNINSTALL_OPERATOR_FAILURE:
          return {
            ...state,
            error: payload?.error,
            uninstallingOperators: false
          }
        case DEPLOY_OPERATOR:
          return {
            ...state,
            deployingOperators: true
          }
        case DEPLOY_OPERATOR_SUCCESS:
          return {
            ...state,
            deployingOperators: false
          }
        case DEPLOY_OPERATOR_FAILURE:
          return {
            ...state,
            error: payload?.error,
            deployingOperators: false
          }
        case REDEPLOY_OPERATOR:
          return {
            ...state,
            redeployingOperators: true,
          };
        case REDEPLOY_OPERATOR_SUCCESS:
          return {
            ...state,
            redeployingOperators: false,
          };
        case REDEPLOY_OPERATOR_FAILURE:
          return {
            ...state,
            error: payload?.error,
            redeployingOperators: false,
          };
        case CREATE_OPERATOR_ENVIRONMENT:
            return {
                ...state,
                creatingOperatorEnv: true,
            }
        case CREATE_OPERATOR_ENVIRONMENT_SUCCESS:
            return {
                ...state,
                creatingOperatorEnv: false,
            }
        case CREATE_OPERATOR_ENVIRONMENT_FAILURE:
            return {
                ...state,
                creatingOperatorEnv: false,
            }
        case UPDATE_OPERATOR_DETAILS_LIST:
          return {
            ...state,
            operatorDetailsList: [
              ...state.operatorDetailsList,
              payload.data,
            ]
          }
          case FETCH_OPERATOR_LOGS:
            return {
              ...state,
              fetchingOperatorLogs: true
            };
          case FETCH_OPERATOR_LOGS_SUCCESS:
            return {
              ...state,
              fetchingOperatorLogs: false,
              operatorLogs: payload.data,
            };
          case FETCH_OPERATOR_LOGS_FAILURE:
            return {
              ...state,
              fetchingOperatorLogs: false,
            };
          case UPDATE_OPERATOR_LOGS:
            return {
              ...state,
              realTimeOperatorLogs: payload.data.logs,
            }
          case CLEAR_OPERATOR_LOGS:
            return {
              ...state,
              operatorLogs: [],
            }
        default:
            return state;
        }
        
      };
      export default reducer;