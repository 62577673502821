import {
  GET_ORG_PLANS_LIST_CALL,
  GET_ORG_PLANS_LIST_CALL_SUCCESS,
  GET_ORG_PLANS_LIST_CALL_FAILURE,
  GET_ORG_LIST_SUCCESS,
  CREATE_ORG,
  CREATE_ORG_SUCCESS,
  CREATE_ORG_FAILURE,
  SWITCH_ORG,
  SWITCH_ORG_SUCCESS,
  SWITCH_ORG_FAILURE,
  GET_ORG_INFO,
  GET_ORG_INFO_SUCCESS,
  GET_ORG_INFO_FAILURE,
  DELETE_ORG,
  DELETE_ORG_FAILURE,
  DELETE_ORG_SUCCESS,
  CLEAR_ORG_INFO,
  ADD_MEMBER_TO_ORG,
  ADD_MEMBER_TO_ORG_FAILURE,
  ADD_MEMBER_TO_ORG_SUCCESS,
  EDIT_MEMBER_TO_ORG,
  EDIT_MEMBER_TO_ORG_SUCCESS,
  EDIT_MEMBER_TO_ORG_FAILURE,
  DELETE_MEMBER_FROM_ORG,
  DELETE_MEMBER_FROM_ORG_FAILURE,
  DELETE_MEMBER_FROM_ORG_SUCCESS,
  GET_ORG_GROUPS,
  GET_ORG_GROUPS_FAILURE,
  GET_ORG_GROUPS_SUCCESS,
  ADD_ORG_GROUP,
  ADD_ORG_GROUP_FAILURE,
  ADD_ORG_GROUP_SUCCESS,
  DELETE_ORG_GROUP,
  DELETE_ORG_GROUP_FAILURE,
  DELETE_ORG_GROUP_SUCCESS,
  UPDATE_ORG_GROUP,
  UPDATE_ORG_GROUP_FAILURE,
  UPDATE_ORG_GROUP_SUCCESS,
  ADD_MEMBER_TO_GROUP,
  ADD_MEMBER_TO_GROUP_FAILURE,
  ADD_MEMBER_TO_GROUP_SUCCESS,
  DELETE_MEMBER_FROM_GROUP,
  DELETE_MEMBER_FROM_GROUP_FAILURE,
  DELETE_MEMBER_FROM_GROUP_SUCCESS,
  GET_GROUP_INFO,
  GET_GROUP_INFO_FAILURE,
  GET_GROUP_INFO_SUCCESS,
  GET_REGISTRIES,
  GET_REGISTRIES_SUCCESS,
  GET_REGISTRIES_FAILURE,
  GET_REGISTRY,
  GET_REGISTRY_SUCCESS,
  GET_REGISTRY_FAILURE,
  ADD_REGISTRY,
  ADD_REGISTRY_SUCCESS,
  ADD_REGISTRY_FAILURE,
  UPDATE_REGISTRY,
  UPDATE_REGISTRY_SUCCESS,
  UPDATE_REGISTRY_FAILURE,
  GET_REGISTRY_CONFIG,
  GET_REGISTRY_CONFIG_FAILURE,
  GET_REGISTRY_CONFIG_SUCCESS,
  // DELETE_REGISTRY,
  // DELETE_REGISTRY_SUCCESS,
  // 
  CLEAR_CHART_CATALOGS_INFO,
  GET_CHART_CATALOGS,
  GET_CHART_CATALOGS_FAILURE,
  GET_CHART_CATALOGS_SUCCESS,
  GET_CATALOG_CATAGORY,
  GET_CATALOG_CATAGORY_FAILURE,
  GET_CATALOG_CATAGORY_SUCCESS,
  GET_CHART_REPOS,
  GET_CHART_REPOS_FAILURE,
  GET_CHART_REPOS_SUCCESS,
  ADD_CHART_REPO,
  ADD_CHART_REPO_FAILURE,
  ADD_CHART_REPO_SUCCESS,
  SYNC_CHART_REPO,
  SYNC_CHART_REPO_FAILURE,
  SYNC_CHART_REPO_SUCCESS,
  DELETE_CHART_REPO,
  DELETE_CHART_REPO_FAILURE,
  DELETE_CHART_REPO_SUCCESS,
  UPDATE_CHART_REPO,
  UPDATE_CHART_REPO_FAILURE,
  UPDATE_CHART_REPO_SUCCESS,
  GET_CHART_DETAIL,
  GET_CHART_DETAIL_FAILURE,
  GET_CHART_DETAIL_SUCCESS,
  CLEAR_CHART_REPO_INFO,
  GET_DNS_SUCCESS,
  CREATE_DNS_SUCCESS,
  DELETE_DNS,
  DELETE_DNS_SUCCESS,
  DELETE_DNS_FAILURE,
  CLEAR_DNS_INFO,
  CLEAR_REGISTRY_INFO,
  CLEAR_GROUP_INFO,
  CLEAR_ORG_GROUPS,
  CLEAR_REGISTRY_CONFIG,
  FETCH_ORG_ACTIVITIES,
  FETCH_ORG_ACTIVITIES_SUCCESS,
  FETCH_ORG_ACTIVITIES_FAILURE,
  CLEAR_ORG_ACTIVITIES
} from './actions';
import initialState from './initialState';

const reducer = (state = initialState, payload) => {
  switch (payload.type) {
    case GET_ORG_PLANS_LIST_CALL:
      return {
        ...state,
        getOrgPlansListCallStarted: true,
        getOrgPlansListCallSuccess: false,
        getOrgPlansListCallFailure: false,
      };
    case GET_ORG_PLANS_LIST_CALL_SUCCESS:
      return {
        ...state,
        orgPlansList: payload.data,
        getOrgPlansListCallStarted: false,
        getOrgPlansListCallSuccess: true,
        getOrgPlansListCallFailure: false,
      };
    case GET_ORG_PLANS_LIST_CALL_FAILURE:
      return {
        ...state,
        getOrgPlansListCallStarted: false,
        getOrgPlansListCallSuccess: false,
        getOrgPlansListCallFailure: true,
      };
    case GET_ORG_LIST_SUCCESS:
      return {
        ...state,
        organizationList: payload.data,
      };
    case CREATE_ORG:
      return {
        ...state,
        creatingOrganization: true,
      };
    case CREATE_ORG_SUCCESS:
      return {
        ...state,
        creatingOrganization: false,
      };
    case CREATE_ORG_FAILURE:
      return {
        ...state,
        creatingOrganization: false,
      };
    case SWITCH_ORG:
      return {
        ...state,
        switchingOrg: true,
      };
    case SWITCH_ORG_SUCCESS:
      return {
        ...state,
        switchingOrg: false,
      };
    case SWITCH_ORG_FAILURE:
      return {
        ...state,
        switchingOrg: false,
      };
    case GET_ORG_INFO:
      return {
        ...state,
        fetchingOrgInfo: true,
      };
    case GET_ORG_INFO_SUCCESS:
      return {
        ...state,
        organizationInfo: payload.data,
        fetchingOrgInfo: false,
      };
    case GET_ORG_INFO_FAILURE:
      return {
        ...state,
        fetchingOrgInfo: false,
      };
    case DELETE_ORG:
      return {
        ...state,
        deletingOrganization: true,
      };
    case DELETE_ORG_SUCCESS:
      return {
        ...state,
        organizationInfo: initialState.organizationInfo,
        deletingOrganization: false,
      };
    case DELETE_ORG_FAILURE:
      return {
        ...state,
        deletingOrganization: false,
        // organizationInfo: initialState.organizationInfo,
      };
    case ADD_MEMBER_TO_ORG:
      return {
        ...state,
        addingMember: true,
      };
    case ADD_MEMBER_TO_ORG_SUCCESS:
      return {
        ...state,
        addingMember: false,
      };
    case ADD_MEMBER_TO_ORG_FAILURE:
      return {
        ...state,
        addingMember: false,
      };
    case EDIT_MEMBER_TO_ORG:
      return {
        ...state,
        editingMember: true,
      };
    case EDIT_MEMBER_TO_ORG_SUCCESS:
      return {
        ...state,
        editingMember: false,
      };
    case EDIT_MEMBER_TO_ORG_FAILURE:
      return {
        ...state,
        editingMember: false,
      };
    case DELETE_MEMBER_FROM_ORG:
      return {
        ...state,
        deletingMember: true,
      };
    case DELETE_MEMBER_FROM_ORG_SUCCESS:
      return {
        ...state,
        deletingMember: false,
      };
    case DELETE_MEMBER_FROM_ORG_FAILURE:
      return {
        ...state,
        deletingMember: false,
      };
    case GET_ORG_GROUPS:
      return {
        ...state,
        fetchingGroups: true,
      };
    case GET_ORG_GROUPS_SUCCESS:
      return {
        ...state,
        organizationGroups: payload.data,
        fetchingGroups: false,
      };
    case GET_ORG_GROUPS_FAILURE:
      return {
        ...state,
        fetchingGroups: false,
      };
    case ADD_ORG_GROUP:
      return {
        ...state,
        addingGroup: true,
      };
    case ADD_ORG_GROUP_SUCCESS:
      return {
        ...state,
        addingGroup: false,
      };
    case ADD_ORG_GROUP_FAILURE:
      return {
        ...state,
        addingGroup: false,
      };
    case DELETE_ORG_GROUP:
      return {
        ...state,
        deletingGroup: true,
      };
    case DELETE_ORG_GROUP_SUCCESS:
      return {
        ...state,
        deletingGroup: false,
      };
    case DELETE_ORG_GROUP_FAILURE:
      return {
        ...state,
        deletingGroup: false,
      };
    case UPDATE_ORG_GROUP:
      return {
        ...state,
        updatingGroup: true,
      };
    case UPDATE_ORG_GROUP_SUCCESS:
      return {
        ...state,
        updatingGroup: false,
      };
    case UPDATE_ORG_GROUP_FAILURE:
      return {
        ...state,
        updatingGroup: false,
      };
    case ADD_MEMBER_TO_GROUP:
      return {
        ...state,
        addingMember: true,
      };
    case ADD_MEMBER_TO_GROUP_SUCCESS:
      return {
        ...state,
        addingMember: false,
      };
    case ADD_MEMBER_TO_GROUP_FAILURE:
      return {
        ...state,
        addingMember: false,
      };
    case DELETE_MEMBER_FROM_GROUP:
      return {
        ...state,
        deletingMember: true,
      };
    case DELETE_MEMBER_FROM_GROUP_SUCCESS:
      return {
        ...state,
        deletingMember: false,
      };
    case DELETE_MEMBER_FROM_GROUP_FAILURE:
      return {
        ...state,
        deletingMember: false,
      };
    case GET_GROUP_INFO:
      return {
        ...state,
        gettingGroupInfo: true,
      };
    case GET_GROUP_INFO_SUCCESS:
      return {
        ...state,
        gettingGroupInfo: false,
        groupInfo: payload.data
      };
    case GET_GROUP_INFO_FAILURE:
      return {
        ...state,
        gettingGroupInfo: false,
    };
    case GET_REGISTRIES:
      return {
        ...state,
        //gettingGroupInfo: true,
      };
    case GET_REGISTRIES_SUCCESS:
      return {
        ...state,
        //gettingGroupInfo: false,
        registrylist: payload.data
      };
    case GET_REGISTRIES_FAILURE:
      return {
        ...state,
        //gettingGroupInfo: false,
    };
    case GET_REGISTRY:
      return {
        ...state,
        //gettingGroupInfo: true,
      };
    case GET_REGISTRY_SUCCESS:
      return {
        ...state,
        //gettingGroupInfo: false,
        registryDetails: payload.data
      };
    case GET_REGISTRY_FAILURE:
      return {
        ...state,
        //gettingGroupInfo: false,
      };
    case GET_CHART_CATALOGS:
      return {
        ...state,
        fetchingChartCatalogs: true,
      };
    case GET_CHART_CATALOGS_SUCCESS:   
      return {
        ...state,
        fetchingChartCatalogs: false,
        chartCatalogs: payload.data
      };
    case GET_CHART_CATALOGS_FAILURE:
      return {
        ...state,
        fetchingChartCatalogs: false,
        chartCatalogs: payload.data
      };
    case GET_CHART_REPOS:
      return {
        ...state,
        fetchingChartRepos: true,
      };
    case GET_CHART_REPOS_SUCCESS:
      return {
        ...state,
        fetchingChartRepos: false,
        chartRepos: payload.data
      };
    case GET_CHART_REPOS_FAILURE:
      return {
        ...state,
        fetchingChartRepos: false,
      };
    case DELETE_CHART_REPO:
      return {
        ...state,
        deletingChartRepo: true,
      };
    case DELETE_CHART_REPO_SUCCESS:
      return {
        ...state,
        deletingChartRepo: false,
      };
    case DELETE_CHART_REPO_FAILURE:
      return {
        ...state,
        deletingChartRepo: false,
      };
    case UPDATE_CHART_REPO:
      return {
        ...state,
        updatingChartRepo: true,
      };
    case UPDATE_CHART_REPO_SUCCESS:
      return {
        ...state,
        updatingChartRepo: false,
      };
    case UPDATE_CHART_REPO_FAILURE:
      return {
        ...state,
        updatingChartRepo: false,
      };
    case GET_CATALOG_CATAGORY:
      return {
        ...state,
        fetchingCatalogCategory: true,
      };
    case GET_CATALOG_CATAGORY_SUCCESS:
      return {
        ...state,
        catalogCategories: payload.data ?? [],
        fetchingCatalogCategory: false,
      };
    case GET_CATALOG_CATAGORY_FAILURE:
      return {
        ...state,
        fetchingCatalogCategory: false,
      };
    case GET_CHART_DETAIL:
      return {
        ...state,
        fetchingDetail: true,
      };
    case GET_CHART_DETAIL_SUCCESS:
      return {
        ...state,
        chartDetails: payload.data ?? [],
        fetchingDetail: false,
      };
    case GET_CHART_DETAIL_FAILURE:
      return {
        ...state,
        fetchingDetail: false,
      };
    case ADD_CHART_REPO:
      return {
        ...state,
        addingChartRepo: true,
      };
    case ADD_CHART_REPO_SUCCESS:
      return {
        ...state,
        addingChartRepo: false,
      };
    case ADD_CHART_REPO_FAILURE:
      return {
        ...state,
        addingChartRepo: false,
      };
    case SYNC_CHART_REPO:
      return {
        ...state,
        syncingChartRepos: true,
      };
    case SYNC_CHART_REPO_SUCCESS:
      return {
        ...state,
        syncingChartRepos: false,
      };
    case SYNC_CHART_REPO_FAILURE:
      return {
        ...state,
        syncingChartRepos: false,
      };
    case CLEAR_CHART_REPO_INFO:
      return {
        ...state,
        chartRepos: null,
      };
    case CLEAR_CHART_CATALOGS_INFO:
      return {
        ...state,
        chartCatalogs: null,
      };
    case ADD_REGISTRY: 
      return {
        ...state,
        addingRegistry: true,
      };
    case ADD_REGISTRY_SUCCESS:
      return {
        ...state,
        addingRegistry: false,
      };
    case ADD_REGISTRY_FAILURE:
      return {
        ...state,
        addingRegistry: false,
      };
    case UPDATE_REGISTRY: 
      return {
        ...state,
        updatingRegistry: true,
      };
    case UPDATE_REGISTRY_SUCCESS:
      return {
        ...state,
        updatingRegistry: false,
      };
    case UPDATE_REGISTRY_FAILURE:
      return {
        ...state,
        updatingRegistry: false,
      };
    case CLEAR_ORG_INFO:
      return {
        ...state,
        organizationInfo: initialState.organizationInfo,
        deletingOrganization: false,
        fetchingOrgInfo: false,
        addingMember: false,
        deletingMember: false,
        organizationGroups: initialState.organizationGroups,
        addingGroup: false,
        deletingGroup: false,
        updatingGroup: false,
        groupInfo: initialState.groupInfo,
        gettingGroupInfo: false,
      }
    case GET_REGISTRY_CONFIG:
      return {
        ...state,
        registryconfigLoading: true
      }
    case GET_REGISTRY_CONFIG_SUCCESS:
      return {
        ...state,
        registryconfigLoading: false,
        registryConfig: payload.data
      }
    case GET_REGISTRY_CONFIG_FAILURE:
      return {
        ...state,
        registryconfigLoading: false
      }
    case GET_DNS_SUCCESS:
      return {
        ...state,
        dnsList: payload.data
      }
    case CREATE_DNS_SUCCESS:
      return {
        ...state,
      }
    case DELETE_DNS:
      return {
        ...state,
        deletingDns: true
      }
    case DELETE_DNS_SUCCESS:
      return {
        ...state,
        deletingDns: false
      }
    case DELETE_DNS_FAILURE:
      return {
        ...state,
        deletingDns: false
      }
    case CLEAR_DNS_INFO:
      return {
        ...state,
        dnsList: initialState.dnsList
      }
    case CLEAR_REGISTRY_INFO:
      return {
        ...state,
        registrylist: initialState.registrylist
      }
    case CLEAR_GROUP_INFO:
      return {
        ...state,
        groupInfo: initialState.groupInfo,
        gettingGroupInfo: initialState.gettingGroupInfo,
      }
    case CLEAR_ORG_GROUPS:
      return {
        ...state,
        organizationGroups: initialState.organizationGroups,
        fetchingGroups: initialState.fetchingGroups,
      }
    case CLEAR_REGISTRY_CONFIG:
      return {
        ...state,
        registryConfig: initialState.registryConfig,
      }
    case FETCH_ORG_ACTIVITIES:
      return {
        ...state,
        fetchingOrgActivities: true,
    }
    case FETCH_ORG_ACTIVITIES_SUCCESS:
      return {
        ...state,
        fetchingOrgActivities: false,
        orgActivities : [...state.orgActivities, ...payload.data]
    }
    case FETCH_ORG_ACTIVITIES_FAILURE:
      return {
        ...state,
        fetchingOrgActivities: false,
    }
    case CLEAR_ORG_ACTIVITIES:
      return {
        ...state,
        orgActivities: initialState.orgActivities
    } 
    default:
      return state;
  }
};

export default reducer;
