import { Avatar, Card, CardContent, Divider, Grid, Tooltip, Typography  } from '@material-ui/core';
//import GitHubIcon from '@material-ui/icons/GitHub';
import MemoryOutlinedIcon from '@material-ui/icons/MemoryOutlined';
import ScheduleIcon from '@material-ui/icons/Schedule';
import StraightenOutlinedIcon from '@material-ui/icons/StraightenOutlined';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import { coreConversion, getDiffDays, spaceCoversion, getStatusColor } from '../../helpers/utils';
import './EnvironmentCard.css';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import CancelIcon from '@material-ui/icons/Cancel';
import { CreateApp_TemplateTypes } from '../../constants/enums';
import { DateHandler } from '../dateHandler/DateHandler'
import {useTranslation} from 'react-i18next';
import { AppConstants } from '../../constants/appconstants';
import paths from "../../constants/paths"
import { EnvironmentModel } from '../../models/Environment.model';
const useStyles = makeStyles(() => ({
    root: {
        marginTop: 20
    },
    avatar: {
        color: 'blue',
        backgroundColor: 'red'
    },
    shortTitle: {
        background: 'white',
        border: '1px solid #0057fa',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 30,
        height: 30
    },
    container: {
        padding: '1rem',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    progress: {
        margin: '7px 20px 0px 20px',
        borderRadius: 20,
    },
    titleContainer: {
        flex: 1
    },
    svgicon: {
        fontSize: 16,
        color: '#357dfd',
        marginRight: 5,
    }
}));

type Props = {
    isHelmChart: boolean,
    details:EnvironmentModel
}

const getStatusIcon = (status: string) => {
    let statusIcon: JSX.Element|string = ""
    switch(status){
        case AppConstants.EnvironmentStatus.Running:
            statusIcon = <CheckCircleIcon style={{color: getStatusColor(status)}} />
            break
        case AppConstants.EnvironmentStatus.Stopped:
            statusIcon = <CheckCircleIcon style={{color: getStatusColor(status)}} />
            break
        case AppConstants.EnvironmentStatus.Failed:
            statusIcon = <CancelIcon style={{color: getStatusColor(status)}} />
            break
        case AppConstants.EnvironmentStatus.Pending:
            statusIcon = <WatchLaterIcon style={{color: getStatusColor(status)}} />
            break
        default:
            statusIcon = <WatchLaterIcon style={{color: getStatusColor(status)}} />
            
    }
    return statusIcon
}

export const EnvironmentCard = (props: Props) => {
    const { details } = props;
    const classes = useStyles();
    const [t] = useTranslation()
    const detailsID = (details.id).toString();

    return (
        <Grid data-test="main-component" item md={ 4 } xs={ 12 }>
            {/* <Paper elevation={1}>

                <Link href={`/environment/${details.id}`} underline='none'>
                <Link to={{
                    pathname: '/environment/' + details.id,
                    state: { details }
                }} style={{ textDecoration: 'none', color: 'black' }}>
                    <div className={classes.container}>
                        <div className={classes.shortTitle}>
            <p className='projectSmallTitle'>{ details.name != "" && details.name.charAt(0).toUpperCase() }</p>
        </div>

                        <img src={details.plugin_version && details.plugin_version.plugin && details.plugin_version.plugin.image} className="projectImg"/>

                        <div className={classes.titleContainer}>
                            <span className='envTitle'>{details.name}</span>
                            <span className='apps'>{ details.resource ? details.resource.cores : 0 } core/{ details.resource ? details.resource.memory : 0 } GB/{ details.replicas } Replica</span> 
                             <LinearProgress className={classes.progress} variant="determinate" value='50' color="secondary" />
                        </div>

                        <Grid container spacing={2} className="p-tb-10">
                            <Grid item md={3} className="topgridalign">
                                <Tooltip title="RAM" arrow>
                                    <StraightenOutlinedIcon className={classes.svgicon} />
                                </Tooltip>
                                <span className="topinfoGrid">
                                    {details && details.resource && details.resource.memory && spaceCoversion(details.resource.memory)}
                                </span>

                            </Grid>
                            <Grid item md={3} className="topgridalign">
                                <Tooltip title="Memry" arrow>
                                    <MemoryOutlinedIcon className={classes.svgicon} />
                                </Tooltip>
                                <span className="topinfoGrid">
                                    {details && details.resource && details.resource.cores && coreConversion(details.resource.cores)}

                                </span>
                            </Grid>
                            <Grid item md={3} className="topgridalign">
                                <Tooltip title="Created" arrow>
                                    <ScheduleIcon className={classes.svgicon} />
                                </Tooltip>
                                <span className="topinfoGrid">
                                    {details && details.createdat && `${getDiffDays(details.createdat)} ago`}

                                </span>

                            </Grid>
                            <Grid item md={3} className="topgridalign">
                                <Tooltip title="Version" arrow>
                                    <SettingsOutlinedIcon className={classes.svgicon} />
                                </Tooltip>
                                <span className="topinfoGrid">
                                    {details.plugin_version && details.plugin_version.plugin && details.plugin_version.plugin.name} {details.version ? details.version.name : ""}

                                </span>
                            </Grid>
                        </Grid>
                  
                    </div>
                </Link>
            </Paper> */}

            <Card className="cardtopstatus" style={{ borderTop: "5px solid " + getStatusColor(details.status) }}>
                <CardContent>
                    <Link to={{ pathname: props.isHelmChart ? paths.HELMENVIRONMENTINFO.replace(":eId", detailsID) : paths.ENVIRONMENTINFO.replace(":eId", detailsID),state: { details } } } style={ { textDecoration: 'none', color: 'black' } }>
                        <Grid container spacing={1} direction="column">
                            <Grid item>
                                <Grid container spacing={ 2 } justify="space-between">
                                    <Grid item>
                                        <div className={ classes.titleContainer }>
                                            <span data-test="env-name" className='envTitle'>{details.name}</span>
                                            {/* <span className='envstatus'> <CheckCircleIcon className="envstatussvg" />Running</span> */}
                                        </div>

                                    </Grid>
                                    <Grid data-test="env-version" item>
                                        {
                                            !props.isHelmChart && details.version && details.version.name
                                        }
                                        {
                                            props.isHelmChart && details?.chartVersion && details.chartVersion.app_version
                                        }
                                        {/* {details.plugin_version && details.plugin_version.plugin && details.plugin_version.plugin.name}  */}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Typography variant="h4" >
                                                <span>
                                                    <Tooltip className="statusIcon" title={t('EnvironmentStatus')}>
                                                        {getStatusIcon(details.status)}
                                                    </Tooltip>
                                                    <span style={{ color: getStatusColor(details.status)}} className="statusTxt"> { details.status }</span>
                                                </span>
                                </Typography>
                            </Grid>
                        </Grid>
                        {/* <span> {details.addons && details.addons.id} </span> */}

                        <Grid container spacing={ 2 } className="m-t-20">
                           
                            <Grid item md={4} className="topgridalign">
                                <DateHandler data-test="created-info" date={details.createdat}  icon={<ScheduleIcon className={classes.svgicon}/>}/>
                                {/* <Tooltip title="Created" arrow>
                                    <ScheduleIcon className={ classes.svgicon } />
                                </Tooltip>
                                <span data-test="created-info" className="topinfoGrid">
                                    {details && details.createdat && `${ getDiffDays(details.createdat, true) }`}
                                </span> */}
                            </Grid>
                            {
                                !props.isHelmChart &&
                                <>
                                    <Grid item md={4} className="topgridalign">
                                        <Tooltip title={t('Environment.EnvironmentInfo.memory')} arrow>
                                            <MemoryOutlinedIcon className={ classes.svgicon } />
                                        </Tooltip>
                                        <span data-test="core-info" className="topinfoGrid">
                                            {details && details.resource && details.resource.cores && coreConversion(details.resource.cores)}
                                        </span>
                                    </Grid>

                                    <Grid item md={4} className="topgridalign">
                                    <Tooltip title={t('Environment.EnvironmentInfo.ram')} arrow>
                                        <StraightenOutlinedIcon className={ classes.svgicon } />
                                    </Tooltip>
                                    <span data-test="ram-info" className="topinfoGrid">
                                        {details && details.resource && details.resource.memory && spaceCoversion(details.resource.memory)}
                                    </span>
                                    </Grid>
                                </>
                            }
                        </Grid>

                    </Link>
                    {
                        !props.isHelmChart && details.service_type !== CreateApp_TemplateTypes.op &&
                        <Grid container spacing={2} >
                            <Grid item md={12}>
                                <Divider className="carddivider" />
                                <Typography variant="body1" className="m-b-10">
                                    {t('AddonsLabel')}
                                </Typography>
                                <div className="envavatardiv">
                                    {
                                        details.addons && details.addons.length > 0 &&
                                        details.addons.map((addOn, ind) =>
                                            <Tooltip data-test="addon-icon" title={addOn?.version?.info[0]?.name} arrow key={ind}>
                                                <Link to={{ pathname: paths.ENVIRONMENTINFO.replace(":eId", detailsID) + "#addons", state: { details, addonEnvId: addOn.id } }} style={{ textDecoration: 'none' }}>
                                                    <div className="addonsavatar">
                                                        <img src={addOn?.plugin_version?.plugin?.image} className="addonsstatus" alt="" />
                                                    </div>
                                                    {/* <Avatar variant="rounded" className="envavatar"> { envItem.name.charAt(0) }</Avatar> */}
                                                </Link>
                                            </Tooltip>
                                        )
                                    }
                                    <Tooltip data-test="addon-add-icon" title={t('AddAddons')} arrow>
                                        <Link to={{ pathname: paths.ENVIRONMENTINFO.replace(":eId", detailsID) + '#addons', state: { details } }} style={{ textDecoration: 'none' }}>
                                            <Avatar variant="rounded" className="addenvavatar" >+</Avatar>
                                        </Link>
                                    </Tooltip>
                                </div>
                            </Grid>
                        </Grid>
                    }
                    {
                        //details.git_branch && details.git_url &&
                        !props.isHelmChart && details.service_type > 0 && details.service_type !== CreateApp_TemplateTypes.op &&
                        <Grid container spacing={ 2 }>
                            <Grid item md={12}>
                                <Divider className="carddivider" />
                                <Typography variant="body1">
                                {t('Environment.EnvironmentInfo.ci/cd')}
                                </Typography>
                            </Grid>
                            <Grid item md={12}>
                                {
                                    details.service_type === CreateApp_TemplateTypes.git &&
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item md={4}>
                                            <span className="cardtitle">{t('LastBuild')}</span>
                                        </Grid>
                                        <Grid data-test="build-time" item md={8} className="lastbuilddiv">
                                            {/* <CheckCircleIcon className="envstatussvg" /> */}
                                            { getDiffDays(details.last_deployed, true) }
                                        </Grid>
                                        <Grid item md={4}>
                                            <span className="cardtitle"> {t('Environment.OverviewTab.branch')} </span>
                                        </Grid>
                                        <Grid data-test="branch-info" item md={8} className="branchdiv">
                                            <img src="/images/icons/git.svg" alt="Git" title="Git" className="svgiconimg"/>{details.git_branch} 
                                        </Grid>
                                    </Grid>
                                }
                                {
                                    details.service_type === CreateApp_TemplateTypes.cr &&
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item md={4}>
                                            <span className="cardtitle">{t('Environment.OverviewTab.lastDeployed')} </span>
                                        </Grid>
                                        <Grid data-test="build-time" item md={8} className="lastbuilddiv">
                                            {/* <CheckCircleIcon className="envstatussvg" /> */}
                                            {getDiffDays(details.last_deployed, true)}
                                        </Grid>
                                        <Grid item md={4}>
                                            <span className="cardtitle"> {t('Environment.OverviewTab.tag')}</span>
                                        </Grid>
                                        <Grid data-test="branch-info" item md={8} className="branchdiv">
                                            <img src="/images/icons/containerregistry.svg" alt="Container Registry" title={t('Environment.OverviewTab.cr')} className="svgiconimg" /> {details.image_tag}
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                        
                    }
                    {
                        !props.isHelmChart && details.service_type !== CreateApp_TemplateTypes.op &&
                    <>
                        <Divider className="carddivider" />
                        <Grid container spacing={2}>
                            
                            <Grid item md={6}>
                                {
                                    //details.git_branch && details.git_url &&
                                    details.service_type > 0 &&
                                    <>
                                        <span className="cardtitle">{t('Jobs')} </span><span data-test="jobs-info">{details.CronJob ? details.CronJob.length : 0}</span>
                                    </>
                                }
                            </Grid>
                            <Grid data-test="autoscale-info" item md={6}><span className="cardtitle">{t('Scaling')} </span> {details.auto_scaler && details.auto_scaler.enabled ? t('Enabled') : t('Disabled')} </Grid>
                        </Grid>
                    </>
                    }
                    
                </CardContent>
            </Card>
        </Grid>
    )
}

export default EnvironmentCard