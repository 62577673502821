import {
  Button, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, 
  FormControl,
  Grid, 
  MenuItem, 
  Select, 
  TextField,
  Typography,
  withStyles,
  InputLabel,
  createStyles
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { compose } from "redux";
import { fetchRegionsList } from "../../../app/createapp/redux/actions";
import { withTranslation, WithTranslation } from "react-i18next";
import { Dispatch } from "redux";
import { WithStyles } from "@material-ui/styles";
import { LoadbalancerInfoModel } from "../../../../models/Project.model";
import { Transition } from "../../../../helpers/utils.ext";

/* istanbul ignore next */
const useStyles = () => createStyles({
  formControl: {
    minWidth: "100%",
  },
  discp: {
    marginTop: "20px",
  },
});

type Payload = {
  name: string,
  region: string,
  project_id?: number
}

interface Props extends WithTranslation, PropsFromRedux, WithStyles<typeof useStyles>{
  edit?: boolean
  loadbalancerInfo?: LoadbalancerInfoModel,
  handleAgree: (payload: Payload) => void,
  handleDisAgree: () => void,
  open: boolean,
  region?: string
}

type State = {
  isPopupOpened: boolean,
  lb_title: string,
  error: {[key:string]:boolean},
  helperText: any,
  lb_region: string,
  confirmPopup: boolean,
  id: boolean
}

export class LBpopup extends Component<Props, State>{
  constructor(props: Props) {
    super(props);
    this.state = {
      isPopupOpened: false,
      lb_title: "",
      error: {},
      helperText: {},
      lb_region: props.region ?? "",
      confirmPopup: false,
      id: false
    };
  }

  componentDidMount = () => {
    this.props.fetchRegionsListAction();
    if (this.props.edit && this.props.loadbalancerInfo) {
      this.setState({
        lb_title: this.props.loadbalancerInfo.name,
        lb_region: this.props.loadbalancerInfo?.cluster?.zone,
      });
    }

    if (!this.props.edit && this.props.regionsList && this.props.regionsList.length > 0){
      this.setState({
        lb_region: this.props.regionsList[0]
      })
    }
  };

  /* istanbul ignore next */
  UNSAFE_componentWillReceiveProps = (newProps: Props) => {
    if (this.state.id !== newProps.open) {
      this.setState({
        isPopupOpened: true,
        id: newProps.open,
      });
    }
    if (newProps.loadbalancerInfo && !this.props.loadbalancerInfo) {
      this.setState({
        lb_title: newProps.loadbalancerInfo.name,
        lb_region: newProps.loadbalancerInfo?.cluster?.zone,
      });
    }
    if (!this.props.edit && newProps.regionsList && newProps.regionsList.length > 0) {
      if (!this.props.regionsList || this.props.regionsList.length === 0) {
        this.setState({
          lb_region: newProps.regionsList[0]
        })
      }
    }
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value?.trim().length <= 0) {
      this.setState({
        [e.target.name]: e.target.value,
        error: { ...this.state.error, [e.target.name]: true },
        helperText: {
          ...this.state.helperText,
          [e.target.name]: this.props.t('Projects.LBpopup.changeError'),
        },
      } as Pick<State, keyof State>);
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        error: { ...this.state.error, [e.target.name]: false },
        helperText: { ...this.state.helperText, [e.target.name]: "" },
      } as Pick<State, keyof State>);
    }
  };

  handleRegionChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    if (e.target.value === "Select Region") {
      this.setState({
        lb_region: e.target.value,
        error: { ...this.state.error, lb_region: true },
        helperText: {
          ...this.state.helperText,
          lb_region: this.props.t('Projects.LBpopup.regionError'),
        },
      });
    } else {
      this.setState({
        lb_region: e.target.value as string,
        error: { ...this.state.error, lb_region: false },
        helperText: { ...this.state.helperText, lb_region: "" },
      });
    }
  };

  handleAgree = () => {
    const payload = {
      name: this.state.lb_title,
      region: this.state.lb_region,
    };
    if (!this.isError()) {
      this.props.handleAgree(payload);
    }
  };

  closePopup = () => {
    this.props.handleDisAgree();
  };

  isError = () => {
    const k = Object.keys(this.state.error);
    if (k.length === 0 || this.state.lb_region === "") {
      return true;
    }
    // k.forEach((_k) => {
    //   if (this.state.error[_k]) {
    //     return true;
    //   }
    // });
    for (var field in this.state.error) {
      if (this.state.error[field]) {
        return true;
      }
    }
    return false;
  };

  render() {
    // const { open } = this.state;
    const { open, classes, t } = this.props;
    return (
        <>
            <Dialog
              open={open}
              TransitionComponent={Transition}
              onClose={this.closePopup}
              keepMounted
            >
                <DialogTitle>
                    <Typography className="dialogtitle" data-test="lb-title">
                        {this.props.edit ? t('Projects.LBpopup.editLoadBalancer') : t('Projects.LBpopup.createLoadBalancer') }
                    </Typography>

                    <IconButton
                      aria-label="close"
                      size="small"
                      className="right"
                      onClick={this.closePopup}
                      data-test="close-icon"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent style={{ minWidth: 300 }} dividers>
                    <Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                  id="lb_title"
                                  label={t('Projects.LBpopup.titleLabel')}
                                  name="lb_title"
                                  style={{ width: "100%" }}
                                  color="primary"
                                  onChange={this.handleChange}
                                  value={this.state.lb_title}
                                  margin="normal"
                                  variant="outlined"
                                  error={this.state.error.lb_title}
                                  helperText={this.state.helperText?.lb_title}
                                  data-test="lb-title-field"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="demo-simple-select-label">{t('Projects.LBpopup.regionLabel')}</InputLabel> 
                                    <Select
                                      //variant="outlined"
                                      data-test="lb-region-field"
                                      label={t('Projects.LBpopup.regionLabel')}
                                      color="primary"
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={this.state.lb_region}
                                      onChange={this.handleRegionChange}
                                      error={this.state.error?.lb_region}
                                      // helperText={this.state.helperText?.lb_region}
                                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                      }}
                                    >
                                    
                                {/* <MenuItem value={this.props.regionsList[0]}> {this.props.regionsList[0]} </MenuItem>  */}
                                        {this.props.regionsList &&
                        // versionsList.versions[0].versions &&
                        this.props.regionsList?.map((region: string, index: number) => (
                            <MenuItem key={index} value={region}>
                                {region}
                            </MenuItem>
                        ))}
                                    </Select>
                                    {/* <FormHelperText error={this.state.isRoleFieldError}>{ this.state.RoleFieldErrorMessage }</FormHelperText> */}
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                      onClick={this.handleAgree}
                      color="primary"
                      variant="contained"
                      disabled={this.isError()}
                      data-test="submit-button"
                    >
                        {this.props.edit ? t('Projects.LBpopup.editLabel') : t('Projects.LBpopup.createLabel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  regionsList: state.CreateAppReducer.regionsList,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch:Dispatch) => {
  return {
    fetchRegionsListAction: () => dispatch(fetchRegionsList()),
  };
};

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(compose(withStyles)(useStyles)(withTranslation()(LBpopup)));
