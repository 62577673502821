import { ADDRESS_UPDATED } from '../../account/billingtab/redux/actions'
import {
    LOGIN,
    LOGIN_SUCCESS,
    FETCH_PROFILE_SUCCESS,
    LOGIN_FAILURE,
    UPDATE_TOKEN,
    SET_CURRENT_ORG,
    REQUEST_INVITE_SIGNUP_SUCCESS,
    CLEAR_LOADING,
    UPDATE_LANGUAGE_SELECTION,
    LOGOUT_SUCCESS
  } from './actions'
import initialState from './initialState'
  
  const auth = (state = initialState, action) => {
    switch (action.type) {
      case LOGIN:
        return {...state, isLoginLoading: true}
      case LOGIN_SUCCESS:
        return { 
          ...state, 
          ...action.data,
          isLoginLoading: false
        }
      case FETCH_PROFILE_SUCCESS : 
        return {...state , user: action.data}
      case LOGIN_FAILURE:
        return { ...state, ...action.data, sessionToken: initialState.sessionToken, isLoginLoading: false }
      case LOGOUT_SUCCESS:
        return initialState
      case UPDATE_TOKEN:
        return { 
          ...state, 
          token: action.data
        }
      case SET_CURRENT_ORG:
        return { 
          ...state, 
          currentOrganization: action.data
        }
      case REQUEST_INVITE_SIGNUP_SUCCESS: 
        return { ...state, ...action.data }
      case CLEAR_LOADING:
        return {...state, isLoginLoading: false}
      case ADDRESS_UPDATED:
        return {
          ...state,
          user: {
            ...state.user,
            address_updated: true
          }
        }
      case UPDATE_LANGUAGE_SELECTION:
        return {
          ...state,
          selectedLanguage: action.data
        };
      default:
        return state
    }
  }
  
  export default auth
