const initialState = {
    fetchpluginsCallStarted : false,
    fetchpluginsCallSuccess : false,
    fetchpluginsCallFailure : false,
    pluginsList : null,
    fetchRegionsCallStarted : false,
    fetchRegionsCallSuccess : false,
    fetchRegionsCallFailure : false,
    regionsList : [],
    versionsList : null,
    fetchingVersionsList: false,
    fetchPluginVersionsCallStarted : false,
    fetchPluginVersionsCallSuccess : false,
    fetchPluginVersionsCallFailure : false,
    isLoading : false,
    resourcesList : [],
    fetchingRegion : false,
    fetchingRegionSuccess : false,
    fetchingRegionFailure : false,
    regions: [],
    fetchingCluster : false,
    fetchingClusterSuccess : false,
    fetchingClusterFailure : false,
    cluster: []
}

export default initialState;