import { call, takeLatest, put } from 'redux-saga/effects'
import axios from 'axios'
import endpoints from '../../../constants/endpoints'
import toast from "../../../components/toast/Toast";
import {
    REGISTER,
    REGISTER_SUCCESS,
    REGISTER_FAILURE,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    VERIFY_EMAIL,
    VERIFY_EMAIL_SUCCESS,
    REQUEST_INVITE,
    REQUEST_INVITE_SUCCESS,
    REQUEST_INVITE_FAILURE,
    GET_INVITE_DETAILS,
    GET_INVITE_DETAILS_SUCCESS,
    GET_INVITE_DETAILS_FAILURE,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE
} from './actions'

function registerCall(payload) {
    const config = {
     headers : {
       'Content-Type': 'application/json'
     }
   }
   return axios.post(endpoints.AUTH.REGISTER , payload , config)
 }

function* registrationCall(payload) {
  try {
    const response = yield call(registerCall, payload.data.payload);
    const data = response.data;
    if (data) {
      // localStorage.setItem('token' , response.data.token)
      yield put({ type: REGISTER_SUCCESS, data })
      toast.success("Signup Successful");
      payload.data.history.push('/')
    } else {
      toast.error("Signup failed");
      yield put({
        type: REGISTER_FAILURE,
        data: {
          message: 'Signup failed',
        },
      })
    }
  } catch (error) {
    if(error && error.response && error.response.data && error.response.data.error)
      toast.error(error.response.data.error);
    else 
      toast.error("Signup failed");
    yield put({
      type: REGISTER_FAILURE,
      data: {
        message: 'Signup failed',
      },
    })
  }
}

function forgotpwdCall(payload) {
  const config = {
   headers : {
     'Content-Type': 'application/json'
   }
  }
  return axios.post(endpoints.AUTH.FORGOT_PASSWORD , payload , config)
}

function* forgotPasswordCall(payload) {
  try {
    yield call(forgotpwdCall, payload.data);
    toast.success("Password reset link sent to email");
    yield put({ type: FORGOT_PASSWORD_SUCCESS }); 
  } catch (error) {
    if(error && error.response && error.response.data && error.response.data.error)
      toast.error(error.response.data.error);
    else 
      toast.error("Operation failed. Please try again");
    yield put({ type: FORGOT_PASSWORD_FAILURE });
  }
}

function resetpwdCall(payload) {
  const config = {
   headers : {
     'Content-Type': 'application/json'
   }
  }
  return axios.post(endpoints.RESET_PASSWORD, payload , config)
}

function* resetPassword(payload) {
  try {
    yield call(resetpwdCall, payload.data.payload);
    toast.success("Password reset successful");
    payload.data.history.push('/');
  } catch (error) {
    if(error && error.response && error.response.data && error.response.data.error)
      toast.error(error.response.data.error);
    else 
      toast.error("Password reset failed. Please try again");
  }
}

function verifyEmailCall(payload) {
  return axios.get(endpoints.AUTH.VERIFY_EMAIL.replace(":token", payload.data.token), null)
}

function* verifyEmail(payload) {
  try {
    const response = yield call(verifyEmailCall, payload);
    const data = response.data;
    if (data) {
      yield put({ type: VERIFY_EMAIL_SUCCESS, data })
    }
  } catch (error) {
    if(error && error.response && error.response.data && error.response.data.error)
      yield put({ 
        type: VERIFY_EMAIL_SUCCESS, 
        data: { message : error.response.data.error } 
      }) 
    else 
      toast.error("Something went wrong. Please try again");
  }
}

function* requestInvite(payload) {
  try {
    const config = { headers : { 'Content-Type': 'application/json' } }
    yield axios.post(endpoints.AUTH.REQUEST_INVITE, payload.data.payload, config)
    yield put({ type: REQUEST_INVITE_SUCCESS })
    toast.success("Request received. Our team will contact you soon", 10000);
    payload.data.history.push('/')
  } catch (error) {
    yield put({ type: REQUEST_INVITE_FAILURE })
    if(error && error.response && error.response.data && error.response.data.error)
      toast.error(error.response.data.error);
    else 
      toast.error("Request for demo failed");
  }
}

function* getInviteDetails(payload) {
  try {
    const response = yield axios.get(endpoints.AUTH.GET_INVITE_DETAILS.replace(":token", payload.data.token), null)
    const data = response.data;
    if (data) {
      yield put({ type: GET_INVITE_DETAILS_SUCCESS, data })
    }
  } catch (error) {
    yield put({ type: GET_INVITE_DETAILS_FAILURE }) 
    // if(error && error.response && error.response.data && error.response.data.error)
    //   toast.error(error.response.data.error);
    // else 
    //   toast.error("Something went wrong. Please try again");
  }
}

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(REGISTER, registrationCall)
  yield takeLatest(FORGOT_PASSWORD, forgotPasswordCall)
  yield takeLatest(RESET_PASSWORD, resetPassword)
  yield takeLatest(VERIFY_EMAIL, verifyEmail)
  yield takeLatest(REQUEST_INVITE, requestInvite)
  yield takeLatest(GET_INVITE_DETAILS , getInviteDetails)
}