import React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
// import DeploymentFormBody from "./DeploymentFormBody";
import {
    Grid,
    Button,
} from "@material-ui/core";
// import { makeStyles } from "@material-ui/styles";
// import { RouterAction } from "connected-react-router";
// import { JSONSchema4 } from "json-schema";
// import { CreateError, FetchError, IChartState, IChartVersion } from "../../shared/types";
// import * as url from "../../shared/url";
// import LoadingWrapper from "../LoadingWrapper/LoadingWrapper";
// import actions from "actions";
// import ChartSummary from "components/Catalog/ChartSummary";
// import ChartHeader from "components/ChartView/ChartHeader";
// import Alert from "components/js/Alert";
// import Column from "components/js/Column";
// import Row from "components/js/Row";
// import { useDispatch } from "react-redux";
// import "react-tabs/style/react-tabs.css";
// import { yamlData } from "./test"
// import MuiTextField from "../textfield/MuiTextField";
import { useTranslation } from "react-i18next";
import {
    clearChartVersionConfig,
    getChartVersions,
    getChartVersionConfig,
    createHelmEnvironment,
} from "../../pages/helmenvironment/redux/actions";
import BackdropLoader from "../loader/BackdropLoader";
import HelmEnvBasicFields from "./HelmEnvBasicFields";

export function DeploymentForm(props) {
    //const [isDeploying, setDeploying] = useState(false);
    const [releaseName, setReleaseName] = useState("");
    const [releaseNameErrorMessage, setReleaseNameErrorMessage] = useState("");
    const [selectedVersion, setSelectedVersion] = useState("Version");
    const [appValues, setAppValues] = useState("");
    const [valuesModified, setValuesModified] = useState(false);
    const [selected, setSelected] = useState({});
    const [t] = useTranslation();
    // const classes = useStyles();
    //const { version } = selected;
    //const dispatch = useDispatch();
    
    useEffect(() => {
        if (props.appDetails?.chart_id) {
            props.getChartVersions(props.appDetails.chart_id);
        }
        return () => {
            props.clearChartVersionConfig();
        };
    }, [props.appDetails]);

    useEffect(() => {
        if (props.chartConfig) {
            try {
                setSelected({
                    values: props.chartConfig.values,
                    schema: JSON.parse(props.chartConfig.schema),
                });
            } catch (e) {
                setSelected({
                    ...selected,
                    values: props.chartConfig.values,
                });
            }
            setAppValues(props.chartConfig?.values);
        }
    }, [props.chartConfig]);

    // useEffect(() => {
    //     if (props.chartVersionValues) {
    //         setAppValues(props.chartVersionValues)
    //     }
    // }, [props.chartVersionValues])

    useEffect(() => {
        if (!selectedVersion || selectedVersion !== "Version") {
            props.getChartVersionConfig(selectedVersion);
        }
    }, [selectedVersion]);

    // useEffect(() => {
    //     fetchChartVersions(cluster, chartNamespace, chartID);
    // }, [fetchChartVersions, cluster, chartNamespace, chartID]);

    useEffect(() => {
        if (!valuesModified) {
            setAppValues(selected.values || "");
        }
    }, [selected.values, valuesModified]);

    // useEffect(() => {
    //     dispatch(actions.charts.getChartVersion(cluster, chartNamespace, chartID, chartVersion));
    // }, [ chartVersion, dispatch]);

    const handleValuesChange = (value) => {
        setAppValues(value);
    };

    const setValuesModifiedTrue = () => {
        setValuesModified(true);
    };

    const handleReleaseNameChange = (e) => {
        setReleaseName(e.target.value);
        if (e.target?.value?.trim().length === 0) {
            setReleaseNameErrorMessage("Name can't be empty");
        } else {
            setReleaseNameErrorMessage("");
        }
    };

    const versionChangeHandler = (e) => {
        setSelectedVersion(e.target.value);
    };

    const createEnvironment = () => {
        const payload = {
            name: releaseName,
            chart_version_id: selectedVersion,
            application_id: props.appDetails?.id,
            values: appValues,
            // schema: selected.schema
        };
        props.createHelmEnvironment(payload, props.history);
    };

    const isErrors = () => {
        if (releaseName.trim().length === 0) {
            return true;
        }
        if (!selectedVersion || selectedVersion === "Version") {
            return true;
        }
        return false;
    };

    // const handleDeploy = async (e: React.FormEvent<HTMLFormElement>) => {
    //     e.preventDefault();
    //     setDeploying(true);
    //     if (selected.version) {
    //         const deployed = await deployChart(
    //             cluster,
    //             namespace,
    //             selected.version,
    //             chartNamespace,
    //             releaseName,
    //             appValues,
    //             selected.schema,
    //         );
    //         setDeploying(false);
    //         if (deployed) {
    //             push(url.app.apps.get(cluster, namespace, releaseName));
    //         }
    //     }
    // };

    // const selectVersion = (e: React.ChangeEvent<HTMLSelectElement>) => {
    //     push(
    //         url.app.apps.new(
    //             cluster,
    //             namespace,
    //             selected.version!,
    //             e.currentTarget.value,
    //             kubeappsNamespace,
    //         ),
    //     );
    // };

    // if (error && error.constructor === FetchError) {
    //     return <Alert theme="danger">Unable to retrieve the current app: {error.message}</Alert>;
    // }

    // if (!version) {
    //     return <LoadingWrapper className="margin-t-xxl" loadingText={`Fetching ${chartID}...`} />;
    // }
    //const chartAttrs = version.relationships.chart.data;
    return (
        <section data-test="main-container">
            {/* <ChartHeader
                chartAttrs={chartAttrs}
                versions={selected.versions}
                onSelect={selectVersion}
                selectedVersion={selected.version?.attributes.version}
            /> */}
            {/* {isDeploying && (
                <h3 className="center" style={{ marginBottom: "1.2rem" }}>
                    Hang tight, the application is being deployed...
                </h3>
            )} */}
            {/* <LoadingWrapper loaded={!isDeploying}> */}
            {/* <Row> */}
            {/* <Column span={3}>
                        <ChartSummary version={version} chartAttrs={chartAttrs} />
                    </Column> */}
            {/* <Column span={9}>
                        {error && <Alert theme="danger">An error occurred: {error.message}</Alert>} */}
            {/* <form onSubmit={handleDeploy}> */}
            {/* <div> */}
            {/* <label
                                    htmlFor="releaseName"
                                    className="deployment-form-label deployment-form-label-text-param"
                                >
                                    Name
                                </label> */}
            {/* <input
                                    //id="releaseName"
                                    //pattern="[a-z0-9]([-a-z0-9]*[a-z0-9])?(\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*"
                                    //title="Use lower case alphanumeric characters, '-' or '.'"
                                    //className="clr-input deployment-form-text-input"
                                    onChange={handleReleaseNameChange}
                                    value={releaseName}
                                    required={true}
                /> */}
            <Grid
                container
                spacing={2}
                direction="column"
                //justify="center"
                //alignItems="center"
                className="m-t-20"
            >
                <HelmEnvBasicFields
                    releaseName={releaseName}
                    releaseNameErrorMessage={releaseNameErrorMessage}
                    handleReleaseNameChange={handleReleaseNameChange}
                    appDetails={props.appDetails}
                    versionChangeHandler={versionChangeHandler}
                    selectedVersion={selectedVersion}
                    selected={selected}
                    chartVersions={props.chartVersions}
                    appValues={appValues}
                    setValuesModifiedTrue={setValuesModifiedTrue}
                    deploymentEvent={"install"}
                    handleValuesChange={handleValuesChange}
                />
                {/* <Grid item xs={12} data-test="form-container">
                    <Card className={`w-100`}>
                        <CardContent>
                            <Grid container direction="column" spacing={2}>
                                <Grid item xs={12}>
                                    <div className="alignCenter">
                                        <img
                                            src={props.appDetails?.chart?.Icon ?? ""}
                                            alt={props.appDetails?.chart?.Name ?? "Chart"}
                                            className={classes.logo}
                                            data-test="chart-image"
                                        />
                                    </div>
                                    <div className="alignCenter m-b-10">
                                        <Typography variant="h5">
                                            {props.appDetails?.chart?.Name}{" "}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <MuiTextField
                                        //variant="outlined"
                                        //color='primary'
                                        error={releaseNameErrorMessage.length > 0}
                                        helperText={releaseNameErrorMessage}
                                        //required
                                        autoFocus
                                        label={t("ResourcePaper.environmentName")}
                                        //onBlur={handleEnvironmentNameOnBlur}
                                        onChange={handleReleaseNameChange}
                                        value={releaseName}
                                        data-test="name-field"
                                    //data-test="environment-field"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div className="basic-deployment-form-param">
                                        <FormControl
                                            variant="outlined"
                                            className="w-100"
                                            data-test="env-version-select"
                                        >
                                            <InputLabel id="demo-simple-select-outlined-label">
                                                {t("Environment.CreateAppMaster.version")}
                                            </InputLabel>
                                            <Select
                                                //data-test="version-select"
                                                color="primary"
                                                //labelId="demo-simple-select-label"
                                                labelId="demo-simple-select-outlined-label"
                                                //id="demo-simple-select"
                                                value={selectedVersion}
                                                //gutterButtom
                                                //className=""
                                                onChange={versionChangeHandler}
                                                MenuProps={{
                                                    getContentAnchorEl: null,
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "left",
                                                    },
                                                }}
                                                label={t("Environment.CreateAppMaster.version")}
                                                data-test="version-field"
                                            >
                                                {(!props.chartVersions ||
                                                    !props.chartVersions.chartVersions ||
                                                    props.chartVersions.chartVersions.length === 0) && (
                                                        <MenuItem value={"Version"}>
                                                            {t("Environment.CreateAppMaster.version")}
                                                        </MenuItem>
                                                    )}
                                                {props.chartVersions &&
                                                    props.chartVersions.chartVersions?.map(
                                                        (versionDetails, ind) => (
                                                            <MenuItem value={versionDetails.id} key={ind}>
                                                                {versionDetails.version}
                                                            </MenuItem>
                                                        )
                                                    )}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    {
                                        selected && selected.values &&
                                        <>
                                            <Divider />
                                            <DeploymentFormBody
                                                deploymentEvent="install"
                                                //chartID={chartID}
                                                //chartVersion={chartVersion}
                                                //chartsIsFetching={chartsIsFetching}
                                                selected={selected}
                                                setValues={handleValuesChange}
                                                appValues={appValues}
                                                setValuesModified={setValuesModifiedTrue}
                                            />
                                        </>
                                    }
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid> */}
                <Grid item xs={12} className="alignCenter">
                    <Button
                        data-test="create-env-button"
                        variant="contained"
                        color="primary"
                        onClick={() => createEnvironment()}
                        disabled={isErrors()}
                    >
                        {t("Environment.CreateAppMaster.createEnv")}
                    </Button>
                </Grid>
            </Grid>

            {/* </form> */}
            {/* </Column>
                </Row> */}
            {/* </LoadingWrapper> */}
            {props.fetchingChartVersions && (
                <BackdropLoader message="Fetching chart Versions" data-test="chart-Version" />
            )}
            {props.fetchingChartConfig && (
                <BackdropLoader message="Fetching chart Version Config" data-test="chart-config" />
            )}
            {props.creatingHelmEnv && (
                <BackdropLoader message="Initializing environment creation" data-test="helmEnv"/>
            )}
        </section>
    );
}

/* istanbul ignore next */
const mapStateToProps = (state) => ({
    chartVersions: state.HelmEnvironmentReducer.chartVersions,
    chartVersionValues: state.HelmEnvironmentReducer.chartVersionValues,
    chartVersionSchema: state.HelmEnvironmentReducer.chartVersionSchema,
    chartConfig: state.HelmEnvironmentReducer.chartConfig,
    fetchingChartVersions: state.HelmEnvironmentReducer.fetchingChartVersions,
    fetchingChartConfig: state.HelmEnvironmentReducer.fetchingChartConfig,
    creatingHelmEnv: state.HelmEnvironmentReducer.creatingHelmEnv,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => ({
    getChartVersions: (id) => dispatch(getChartVersions(id)),
    getChartVersionConfig: (version) => dispatch(getChartVersionConfig(version)),
    clearChartVersionConfig: (version) =>
        dispatch(clearChartVersionConfig(version)),
    createHelmEnvironment: (payload, history) =>
        dispatch(createHelmEnvironment(payload, history)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeploymentForm);
