// WARN: yaml doesn't have updated definitions for TypeScript
// In particular, it doesn't contain definitions for `get` and `set`
// that are used in this package
import Ajv from "ajv";
import * as jsonpatch from "fast-json-patch";
// import * as jsonSchema from "json-schema";
import { isEmpty, set } from "lodash";
import YAML from "yaml";

// Avoid to explicitly add "null" when an element is not defined

const { nullOptions } = require("yaml/types");
nullOptions.nullStr = "";

// retrieveBasicFormParams iterates over a JSON Schema properties looking for `form` keys
// It uses the raw yaml to setup default values.
// It returns a key:value map for easier handling.
export function retrieveBasicFormParams(
    defaultValues,
    schema,
    parentPath,
) {
    let params = [];
    if (schema && schema.properties) {
        const properties = schema.properties;
        Object.keys(properties).forEach(propertyKey => {
            // The param path is its parent path + the object key
            const itemPath = `${parentPath || ""}${propertyKey}`;
            const { type, form } = properties[propertyKey];
            // If the property has the key "form", it's a basic parameter
            if (form) {
                // Use the default value either from the JSON schema or the default values
                const value = getValue(defaultValues, itemPath, properties[propertyKey].default);
                const param = {
                    ...properties[propertyKey],
                    path: itemPath,
                    type,
                    value,
                    enum: properties[propertyKey].enum?.map(item => item?.toString() ?? ""),
                    children:
                        properties[propertyKey].type === "object"
                            ? retrieveBasicFormParams(defaultValues, properties[propertyKey], `${itemPath}/`)
                            : undefined,
                };
                params = params.concat(param);
            } else {
                // If the property is an object, iterate recursively
                if (schema.properties[propertyKey].type === "object") {
                    params = params.concat(
                        retrieveBasicFormParams(defaultValues, properties[propertyKey], `${itemPath}/`),
                    );
                }
            }
        });
    }
    return params;
}

function getDefinedPath(allElementsButTheLast, doc) {
    let currentPath = [];
    let foundUndefined = false;
    allElementsButTheLast.forEach(p => {
        // Iterate over the path until finding an element that is not defined
        if (!foundUndefined) {
            const pathToEvaluate = currentPath.concat(p);
            const elem = (doc).getIn(pathToEvaluate);
            if (elem === undefined || elem === null) {
                foundUndefined = true;
            } else {
                currentPath = pathToEvaluate;
            }
        }
    });
    return currentPath;
}

function splitPath(path) {
    return (
        (path ?? "")
            // ignore the first slash, if exists
            .replace(/^\//, "")
            // split by slashes
            .split("/")
    );
}

function unescapePath(path) {
    // jsonpath escapes slashes to not mistake then with objects so we need to revert that
    return path.map(p => jsonpatch.unescapePathComponent(p));
}

function parsePath(path) {
    return unescapePath(splitPath(path));
}

function parsePathAndValue(doc, path, value) {
    if (isEmpty(doc.contents)) {
        // If the doc is empty we have an special case
        return { value: set({}, path.replace(/^\//, ""), value), splittedPath: [] };
    }
    let splittedPath = splitPath(path);
    // If the path is not defined (the parent nodes are undefined)
    // We need to change the path and the value to set to avoid accessing
    // the undefined node. For example, if a.b is undefined:
    // path: a.b.c, value: 1 ==> path: a.b, value: {c: 1}
    // TODO(andresmgot): In the future, this may be implemented in the YAML library itself
    // https://github.com/eemeli/yaml/issues/131
    const allElementsButTheLast = splittedPath.slice(0, splittedPath.length - 1);
    const parentNode = (doc).getIn(allElementsButTheLast);
    if (parentNode === undefined) {
        const definedPath = getDefinedPath(allElementsButTheLast, doc);
        const remainingPath = splittedPath.slice(definedPath.length + 1);
        value = set({}, remainingPath.join("."), value);
        splittedPath = splittedPath.slice(0, definedPath.length + 1);
    }
    return { splittedPath: unescapePath(splittedPath), value };
}

// setValue modifies the current values (text) based on a path
export function setValue(values, path, newValue) {
    const doc = YAML.parseDocument(values);
    const { splittedPath, value } = parsePathAndValue(doc, path, newValue);
    (doc).setIn(splittedPath, value);
    return doc.toString();
}

// parseValues returns a processed version of the values without modifying anything
export function parseValues(values) {
    return YAML.parseDocument(values).toString();
}

export function deleteValue(values, path) {
    const doc = YAML.parseDocument(values);
    const { splittedPath } = parsePathAndValue(doc, path);
    (doc).deleteIn(splittedPath);
    // If the document is empty after the deletion instead of returning {}
    // we return an empty line "\n"
    return doc.contents && !isEmpty((doc.contents).items) ? doc.toString() : "\n";
}

// getValue returns the current value of an object based on YAML text and its path
export function getValue(values, path, defaultValue) {
    const doc = YAML.parseDocument(values);
    const splittedPath = parsePath(path);
    const value = (doc).getIn(splittedPath);
    return value === undefined || value === null ? defaultValue : value;
}

export function validate(
    values,
    schema,
) {
    const ajv = new Ajv({ strict: false });
    const valid = ajv.validate(schema, YAML.parse(values));
    return { valid: !!valid, errors: ajv.errors };
}

export function getValueFromEvent(
    e
) {
    let value = e.currentTarget.value;
    switch (e.currentTarget.type) {
        case "checkbox":
            // value is a boolean
            //value = value === "true";
            value = e.currentTarget.checked;
            break;
        case "number":
            // value is a number
            value = parseInt(value, 10);
            break;
        default:
            break;
    }
    return value;
}