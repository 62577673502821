import React, { Component, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { WithStyles, createStyles, Card, CardHeader, Collapse, Divider, CardContent, Typography } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  Paper,
  Table,
  TableBody,
  TableHead,
  TableContainer,
  TextField,
  Button,
  Checkbox,
  InputAdornment,
  IconButton,
  // OutlinedInput,
  Tooltip,
  FormControlLabel,
  Switch,
  Grid,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { isAuthorized } from "../../helpers/utils";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { Prompt } from "react-router-dom";
import AceEditor from "react-ace";
import {
  StyledTableCell,
  StyledTableRow,
} from "../styledtabelcell/StyledTabelCell";
// import ConfirmActionPopup from "../../components/confirmactionpopup/ConfirmActionPopup";
import MuiTextField from "../textfield/MuiTextField";
import { UserRole, EnvironmentModel } from "../../models/Environment.model";
import clsx from "clsx";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

/* istanbul ignore next */
const useStyles = () =>
  createStyles({
    save: {
      // marginLeft: "auto",
      marginLeft: "40px",
    },
    addVariables: {
      display: "flex",
      marginLeft: "auto",
      // flexDirection: 'row-reverse',
      marginTop: "5px",
    },
    header: {
      margin: "30px 50px 50px 0px",
    },
    topmargin: {
      marginTop: 15,
    },
    delete: {
      marginTop: 30,
      textTransform: "none",
    },
    table: {
      minWidth: 650,
    },
    input: {
      "&::placeholder": {
        fontStyle: "italic",
      },
    },
    cardHeader: {
      padding: "0 !important",
    },
    dnsEditIcon: {
      marginTop: "8px !important",
      marginRight: "8px !important",
    },
    expand: {
      transform: "rotate(0deg)",
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
  });

export const CopyIcon = (props: { copyText: string }) => {
  const [toolText, setToolText] = useState("Copy");

  const copyToClipBoard = async (copyText: string) => {
    if (copyText !== "") {
      try {
        navigator.clipboard.writeText(copyText);
        setToolText("Copied");

        setTimeout(() => {
          setToolText("Copy");
        }, 1500);
      } catch {
        setToolText("Couldn't Copy");
      }
    }
  };

  return (
    <Tooltip title={toolText} data-test="tool-tip">
      <IconButton data-test="copy-icon" onClick={() => copyToClipBoard(props.copyText ?? "")}>
        <FileCopyOutlinedIcon />
      </IconButton>
    </Tooltip>
  );
};

const aceStyles = {
  mode: "javascript",
  theme: "monokai",
  fontSize: 14,
  showPrintMargin: false,
  width: "100%",
  height: "25vh",
  name: "command",
  className: "m-t-10",
};

export interface Variables {
  key: string;
  type?: string;
  value: string;
  isProtected?: boolean;
}

export interface VariablesLocal extends Variables {
  isShowPassword?: boolean;
  isError?: string;
}

export interface Classes {
  save: string;
  addVariables: string;
  header: string;
  topmargin: string;
  delete: string;
  table: string;
  input: string;
  cardHeader: string;
  dnsEditIcon: string;
  expand: string;
  expandOpen: string;
}

interface Props extends WithTranslation, WithStyles<typeof useStyles> {
  userVariables: VariablesLocal[];
  classes: Classes;
  handleSave: (variable: any) => void;
  open: boolean;
  role: UserRole;
  supportUserVariable: boolean;
  systemVariables: VariablesLocal[];
  updateModule: string;
  envDetails: EnvironmentModel;
  title?: string;
  disableEdit?: boolean;
}
interface State {
  fieldError: boolean;
  editUserRowId: number;
  editSystemRowId: number;
  // editRowId: number;
  userVariables: VariablesLocal[];
  systemVariables: VariablesLocal[];
  userVariablesCopy: VariablesLocal[];
  systemVariablesCopy: VariablesLocal[];
  copyText: string;
  apply_immediately: boolean;
  isBlocking: boolean;
  // cancelPopup: false,
  applyChanged: boolean;
  bulkVars: string;
  bulkEdit: boolean;
  expandSysConst: boolean;
  expandUserVar: boolean;
}

export class UserVariablesTable extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      fieldError: false,
      editUserRowId: -1,
      editSystemRowId: -1,
      // editRowId: -1,
      userVariables: [...props.userVariables] || [],
      systemVariables: [...props.systemVariables] || [],
      userVariablesCopy: [...props.userVariables] || [],
      systemVariablesCopy: [...props.systemVariables] || [],
      // changed: false,
      // keyChanged: false,
      // valueChanged: false,
      // lastChanged: false,
      copyText: "Copy",
      apply_immediately: false,
      isBlocking: false,
      // cancelPopup: false,
      applyChanged: false,
      bulkVars: "",
      bulkEdit: false,
      expandSysConst: true,
      expandUserVar: true
    };
  }

  getEmptyRowObject() {
    return {
      key: "",
      value: "",
    };
  }

  componentDidMount() {
    if (this.props.userVariables) {
      this.setState({
        userVariables: [...this.props.userVariables],
      });
    }
    if (this.props.systemVariables) {
      this.setState({
        systemVariables: [...this.props.systemVariables],
      });
    }
    if (this.props.envDetails && this.props.envDetails.id > 0) {
      this.setState({
        apply_immediately: this.props.envDetails.apply_immediately,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(newProps: Props) {
    if (
      newProps.userVariables &&
      newProps.userVariables !== this.state.userVariables
    ) {
      this.setState(
        {
          userVariables: [...newProps.userVariables],
          bulkVars: this.variablesToScript([...newProps.userVariables]),
          bulkEdit: false,
        },
        () => {
          let userVarCopy: VariablesLocal[] = [];
          this.state.userVariables.forEach((userVar) => {
            const _userVar = {
              ...userVar,
            };
            userVarCopy.push(_userVar);
          });
          this.setState({
            userVariablesCopy: userVarCopy,
          });
        }
      );
    }
    if (
      newProps.systemVariables &&
      newProps.systemVariables !== this.state.systemVariables
    ) {
      this.setState(
        {
          systemVariables: [...newProps.systemVariables],
        },
        () => {
          let systemVarCopy: VariablesLocal[] = [];
          this.state.systemVariables.forEach((systemVar) => {
            const _systemVar = {
              ...systemVar,
            };
            systemVarCopy.push(_systemVar);
          });
          this.setState({
            systemVariablesCopy: systemVarCopy,
          });
        }
      );
    }
  }

  isKeyDuplicate = (val: string, type: number) => {
    const variables =
      type === 1 ? this.state.systemVariables : this.state.userVariables;
    if (val !== "") {
      const exists = variables.filter(
        (variable) => variable?.key?.toUpperCase() === val?.toUpperCase()
      );
      if (exists?.length > 1) {
        return true;
      }
    }
    return false;
  };

  getAnnotations = () => {
    let annotations = [];
    const variables = this.state.userVariables;
    for (let i = 0; i < variables.length; i++) {
      if (this.isKeyDuplicate(variables[i].key, 2)) {
        const err = {
          row: i,
          column: i,
          type: "error",
          text: "Duplicate key Error",
        };
        annotations.push(err);
      }
    }
    return annotations;
  };

  handleKeyChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: number,varIndex:number
  ) {
    this.setState({ isBlocking: true });

    const value = e.target.value;

    const variables =
      type === 1 ? this.state.systemVariables : this.state.userVariables;

        variables[varIndex]["key"] = value;
        variables[varIndex]["isError"] = !/^[A-Za-z0-9_-]*$/.test(value)
          ? this.props.t("AlphaNumericError")
          : value.trim().length === 0
          ? this.props.t("EmptyError")
          : "";

        let variable = variables[varIndex];

        if (!!variable.isError && variable.isError.length) {
          this.setState({ fieldError: true });
        } else {
          this.setState({ fieldError: false });
        }
      
    if (type === 1) {
      this.setState(
        {
          systemVariables: variables,
        },
        () => {
          let isFieldError = false;
          this.state.systemVariables.map((item) => {
            if (item.isError) {
              isFieldError = Boolean(item.isError) || isFieldError;
            }
          });
          this.setState({
            fieldError: isFieldError,
          });
        }
      );
    } else if (type === 2) {
      this.setState(
        {
          userVariables: variables,
        },
        () => {
          let isFieldError = false;
          this.state.userVariables.map((item) => {
            if (item.isError) {
              isFieldError = Boolean(item.isError) || isFieldError;
            }
          });
          this.setState({
            fieldError: isFieldError,
          });
        }
      );
    }

  }

  addNew = (type: number) => {
    const variables =
      type === 1 ? this.state.systemVariables : this.state.userVariables;
    variables.push(this.getEmptyRowObject());

    if (type === 1) {
      this.setState(
        {
          systemVariables: variables,
        },
        () => {
          let isFieldError = false;
          this.state.systemVariables.map((item) => {
            if (item.isError) {
              isFieldError = Boolean(item.isError) || isFieldError;
            }
          });
          this.setState({
            fieldError: isFieldError,
          });
          this.handleEdit(variables.length - 1, type);
        }
      );
    } else if (type === 2) {
      this.setState(
        {
          userVariables: variables,
        },
        () => {
          let isFieldError = false;
          this.state.userVariables.map((item) => {
            if (item.isError) {
              isFieldError = Boolean(item.isError) || isFieldError;
            }
          });
          this.setState({
            fieldError: isFieldError,
          });
          this.handleEdit(variables.length - 1, type);
        }
      );
    }
  };

  handleValueChange(e: React.ChangeEvent<HTMLInputElement>, type: number,varIndex:number) {
    this.setState({ isBlocking: true });
    const value = e.target.value;
    const variables =
      type === 1 ? this.state.systemVariables : this.state.userVariables;

     variables[varIndex].value = value;

    if (type === 1) {
      this.setState(
        {
          systemVariables: variables,
        },
        () => {
          let isFieldError = false;
          this.state.systemVariables.map((item) => {
            if (item.isError) {
              isFieldError = Boolean(item.isError) || isFieldError;
            }
          });
          this.setState({
            fieldError: isFieldError,
          });
        }
      );
    } else if (type === 2) {
      this.setState(
        {
          userVariables: variables,
        },
        () => {
          let isFieldError = false;
          this.state.userVariables.map((item) => {
            if (item.isError) {
              isFieldError = Boolean(item.isError) || isFieldError;
            }
          });
          this.setState({
            fieldError: isFieldError,
          });
        }
      );
    }
  }

  handleProtected(
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    type: number
  ) {
    this.setState({ isBlocking: true });
    const variables =
      type === 1 ? this.state.systemVariables : this.state.userVariables;

    if (variables[index]) {
      variables[index].isProtected = e.target.checked;
      variables[index].isShowPassword = false;

    }
    if (type === 1) {
      this.setState({
        systemVariables: variables,
      });
    } else if (type === 2) {
      this.setState({
        userVariables: variables,
      });
    }
  }

  handleShowPassword_UV(index: number, type: number) {
    const variables =
      type === 1 ? this.state.systemVariables : this.state.userVariables;

    // const { variables } = this.state;

    if (variables[index]) {
      const _isShowPassword = variables[index].isShowPassword;
      variables[index].isShowPassword = !_isShowPassword;
    }
    if (type === 1) {
      this.setState({
        systemVariables: variables,
      });
    } else if (type === 2) {
      this.setState({
        userVariables: variables,
      });
    }
  }

  handleApplyImmediately = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ isBlocking: true });
    if (e.target.checked !== this.props.envDetails.apply_immediately) {
      this.setState({
        applyChanged: true,
      });
    } else {
      this.setState({
        applyChanged: false,
      });
    }
    this.setState({
      apply_immediately: e.target.checked,
    });
  };

  handleSave = () => {
    const { userVariables } = this.state;
    let variable: any = {};
    let uVar: Variables[] = [];
    if (this.props.supportUserVariable) {
      userVariables.map((v) => {
          var uv = uVar.find((u) => u.key === v.key);
          if (!uv) {
            const _var = {
              key: v.key,
              value: v.value,
              type: v.isProtected ? "secret" : "normal",
            };
            uVar.push(_var);
          }
        
      });
      variable.user_variables = uVar;
    }

    if (this.props.updateModule === "environment") {

      variable.apply_immediately = this.state.apply_immediately;

    }
    this.setState({ isBlocking: false });
    this.props.handleSave(variable);
  };

  handleEdit(index: number, type: number) {
    if (type === 1) {
      this.setState({
        // editRowId: index,
        editSystemRowId: index,
      });
    } else if (type === 2) {
      this.setState({
        // editRowId: index,
        editUserRowId: index,
      });
    }
  }

  handleDelete(index: number, type: number) {
    this.setState({ isBlocking: true });
    let variables =
      type === 1 ? this.state.systemVariables : this.state.userVariables;
    let error = false;

    variables = variables.filter((v:VariablesLocal,varIndex:number) => {
      if (index!==varIndex) {
       
        error = error || !!v.isError;
        return v;
      }
    });

    if (type === 1) {
      this.setState({
        systemVariables: [...variables],
        fieldError: error,
        // changed: change,
      });
    } else if (type === 2) {
      this.setState({
        userVariables: [...variables],
        fieldError: error,
      });
    }
   
  }

  handleCancel(index: number, type: number) {
    if (type === 1) {
      this.setState({
        editSystemRowId: -1,
      });
    } else if (type === 2) {
      this.setState({
        editUserRowId: -1,
      });
    }

    const variables =
      type === 1 ? this.state.systemVariables : this.state.userVariables;
    const variablesCopy =
      type === 1
        ? this.state.systemVariablesCopy
        : this.state.userVariablesCopy;
    if (index !== -1 && variables[index] && variablesCopy[index]) {
      variables[index].key = variablesCopy[index]?.key;
      variables[index].value = variablesCopy[index]?.value;
    }

    if (type === 1) {
      this.setState(
        {
          systemVariables: variables,
        },
        () => {
          let isFieldError = false;
          this.state.systemVariables.map((item) => {
            if (item.isError) {
              isFieldError = Boolean(item.isError) || isFieldError;
            }
          });
          this.setState({
            fieldError: isFieldError,
          });
        }
      );
    } else if (type === 2) {
      this.setState(
        {
          userVariables: variables,
        },
        () => {
          let isFieldError = false;
          this.state.userVariables.map((item) => {
            if (item.isError) {
              isFieldError = Boolean(item.isError) || isFieldError;
            }
          });
          this.setState({
            fieldError: isFieldError,
          });
        }
      );
    }
  }

  handleDone = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    type: number
  ) => {
    if (type === 1) {
      this.setState({
        editSystemRowId: -1,
      });
    } else if (type === 2) {
      this.setState({
        editUserRowId: -1,
      });
    }
  };

  displaySystemVarTable = () => {
    if (this.props.updateModule === "environment") {
      if (this.state.systemVariables?.length <= 0) {
        return false;
      } else {
        let originalLength = this.state.systemVariables?.length;
        const dbKey = this.state.systemVariables?.find((v) => {
          return v.key === "mariadb";
        });
        const clientsKey = this.state.systemVariables?.find((v) => {
          return v.key === "clients_fqdn";
        });

        if (dbKey) {
          originalLength -= 1;
        }
        if (clientsKey) {
          originalLength -= 1;
        }
        if (originalLength) {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  hasValidUserVars = () => {
    if (this.state.userVariables?.length <= 0) {
      return false;
    } else {
      let originalLength = this.state.userVariables?.length;
      const dbKey = this.state.userVariables?.find((v) => {
        return v.key === "mariadb";
      });
      if (dbKey) {
        originalLength -= 1;
      }
      if (originalLength) {
        return true;
      } else {
        return false;
      }
    }
  };

  hasChanged = () => {
    let uVars = this.state.userVariables;
    let sVars = this.state.systemVariables;
    // todo use lodash utility to check for changes
    if (
      JSON.stringify(uVars) !== JSON.stringify(this.state.userVariablesCopy) ||
      JSON.stringify(sVars) !== JSON.stringify(this.props.systemVariables)
    ) {
      return true;
    }
    return false;
  };

  handleBulkEdit = () => {
    this.setState({
      bulkEdit: !this.state.bulkEdit,
      bulkVars: this.variablesToScript(this.state.userVariables),
    });
  };

  variablesToScript = (variables: Variables[]) => {
    let script = "";
    variables.forEach((variable) => {
      if (!variable.key || variable.key.trim() === "") return;
      script += `${variable.key}=${variable.value ? variable.value : ""}\n`;
    });
    return script;
  };

  scriptToVariable = (script: string) => {
    let newVariables: VariablesLocal[] = [];
    let i = 0;
    script.split("\n").forEach((line: string) => {
      let kv: VariablesLocal, key: string, value: string;
      if (
        line.trim() === "" ||
        !line.includes("=") ||
        !line.trim().split("=")[0]
      )
        return;
      key = line.trim().split("=")[0].trim();
      if (!/^[A-Za-z0-9_-]*$/.test(key)) return;
      value = line.trim().split("=").slice(1).join("=");
      kv = { key, value };
      let cVar =
        this.state.userVariables.find((v,vIndex) => vIndex === i) ||
        this.props.userVariables.find((v,vIndex) => vIndex === i);
      kv.isProtected = cVar?.isProtected ?? false;
      kv.isShowPassword = cVar?.isShowPassword ?? false;
      newVariables.push(kv);
      i++;
    });
    return newVariables;
  };

  handleVariableChange = (val: string) => {
    this.setState({ userVariables: this.scriptToVariable(val), bulkVars: val });
  };

  handleSysConst = () => {
    this.setState({
      expandSysConst: !this.state.expandSysConst
    })
  }

  handleExpandUserVar = () => {
    this.setState({
      expandUserVar: !this.state.expandUserVar
    })
  }

  render() {
    const { classes, role, supportUserVariable } = this.props;
    const { userVariables, systemVariables, applyChanged } = this.state;

    const annotations = this.getAnnotations();

    return (
      <div data-test="main-container">
        <Prompt
          when={this.state.isBlocking}
          message={() =>
            `There are unsaved changes. Are you sure you want leave ?`
          } //  ${location.pathname}
        />
        {this.displaySystemVarTable() &&
        <Card className="m-t-20">
          <CardHeader
            className={classes.cardHeader}
            title={
              <Typography variant="h5" display="inline">
                <strong> {this.props.title ? this.props.title : `System Constants`}</strong>{" "}
              </Typography>
            }
            avatar={
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: this.state.expandSysConst,
                })}
                onClick={() => this.handleSysConst()}
              >
                <ExpandMoreIcon />
              </IconButton>
            }
          />
          <Collapse in={this.state.expandSysConst} data-test="card-content">
            <Divider />
            <CardContent data-test="not-applied-card-content">
            <TableContainer
              component={Paper}
              className={classes.topmargin}
              data-test="system-var-table"
            >
              <Table className={classes.table}>
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>KEY</StyledTableCell>
                    <StyledTableCell>VALUE</StyledTableCell>
                    {/* <StyledTableCell>SECRET</StyledTableCell> */}
                    {/* <StyledTableCell>ACTIONS</StyledTableCell> */}
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {systemVariables.map((item, index) => {
                    if (
                      [
                        "mariadb",
                        "clients_fqdn",
                        "clients_fqdn_status",
                        "clients_fqdn_temp",
                      ].includes(item.key)
                    )
                      return <StyledTableRow></StyledTableRow>;
                    const isDuplicate = this.isKeyDuplicate(item.key, 1);
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell component="th" scope="row">
                          <TextField
                            value={item.key}
                            variant="outlined"
                            fullWidth
                            error={isDuplicate ? true : false}
                            helperText={isDuplicate ? "Duplicate Key" : ""}
                            onChange={(e) => this.handleKeyChange(e, 1,index)}
                            // disabled={index !== this.state.editSystemRowId}
                            disabled={true}
                            InputProps={{
                              endAdornment: item.key !== "" && (
                                <CopyIcon copyText={item.key} />
                              ),
                            }}
                            data-test="systemVariable-key-input"
                          ></TextField>
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          <MuiTextField
                            value={item.value}
                            id=""
                            label=""
                            customClassName=""
                            onKeyDown=""
                          
                            type={
                              item.isProtected
                                ? item.isShowPassword
                                  ? "text"
                                  : "password"
                                : "text"
                            }
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => this.handleValueChange(e, 1,index)}
                            disabled={index !== this.state.editSystemRowId}
                            InputProps={{
                              endAdornment: (
                                <>
                                  {item.isProtected && (
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() =>
                                          this.handleShowPassword_UV(index, 1)
                                        }
                                        edge="end"
                                        data-test="systemVariable-password-visibility"
                                      >
                                        {item.isShowPassword ? (
                                          <Visibility />
                                        ) : (
                                          <VisibilityOff />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  )}
                                  {item.value !== "" && (
                                    <CopyIcon copyText={item.value} />
                                  )}
                                </>
                              ),
                            }}
                            data-test="systemVariable-value-input"
                          ></MuiTextField>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>

              </CardContent>
            </Collapse>
          </Card>
        }
        {supportUserVariable &&
          <Card className="m-t-20">
            <CardHeader
              className={classes.cardHeader}
              title={
                <Typography variant="h5" display="inline">
                  <strong> {this.props.title ? this.props.title : `User Variables`}</strong>{" "}
                </Typography>
              }
              avatar={
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: this.state.expandUserVar,
                  })}
                  onClick={() => this.handleExpandUserVar()}
                >
                  <ExpandMoreIcon />
                </IconButton>
              }
            />
            <Collapse in={this.state.expandUserVar} data-test="card-content">
              <Divider />
              <br />
              <CardContent data-test="not-applied-card-content">
                <Grid container spacing={2} justifyContent="space-between">
                  {!this.props.disableEdit &&
                    <>
                      <Grid item>
                        
                        <FormControlLabel
                          control={
                            <Switch
                              name="bulk_edit"
                              color="primary"
                              checked={this.state.bulkEdit}
                              onChange={this.handleBulkEdit}
                            // disabled={!this.hasValidUserVars()}
                            />
                          }
                          label="Bulk Edit Mode"
                          labelPlacement="start"
                          data-test="bulk-edit-form"
                        />
                        
                      </Grid>

                      <Grid item>
                        <Grid container spacing={2}>
                          
                            
                          {this.props.updateModule === "environment" && (
                            <Grid item>
                              <FormControlLabel
                                control={
                                  <Switch
                                    name="apply_immediately"
                                    color="primary"
                                    checked={this.state.apply_immediately}
                                    onChange={this.handleApplyImmediately}
                                  />
                                }
                                className="m-l-40"
                                style={{ marginBottom: "10px" }}
                                label="Apply immediately"
                                labelPlacement="start"
                                data-test="apply-immediately-form"
                              />
                            </Grid>
                          )}

                          {isAuthorized("update", role.name) && (
                            <Grid item>
                              <Button
                                variant="contained"
                                disabled={
                                  Boolean(
                                    this.state.userVariables?.find((a) => !a.key.length)
                                  ) || this.state.bulkEdit
                                }
                                color="primary"
                                onClick={() => this.addNew(2)}
                                // className="m-l-40"
                                data-test="add-var-btn"
                              >
                                Add Variable
                              </Button>
                            </Grid>
                          )}

                          {isAuthorized("update", role.name) && (
                            <Grid item>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => this.handleSave()}
                                disabled={
                                  this.state.fieldError ||
                                  annotations?.length > 0 ||
                                  !(this.hasChanged() || applyChanged)
                                }
                                data-test="save-btn"
                              >
                                Save
                              </Button>
                            </Grid>
                          )}
                            
                          
                        </Grid>
                      </Grid>
                    </>
                  }
                </Grid>
        {!this.state.bulkEdit && (
          <TableContainer component={Paper} className={classes.topmargin}>
            <Table className={classes.table}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>
                    {/* {this.props.title ? this.props.title : `VARIABLES`} */}
                    KEY
                  </StyledTableCell>
                  <StyledTableCell>VALUE</StyledTableCell>
                  {this.props.disableEdit ? null : <>
            <StyledTableCell>SECRET</StyledTableCell>
                  <StyledTableCell>ACTIONS</StyledTableCell>
                  <StyledTableCell align="right"></StyledTableCell>
          </>}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {userVariables.map((item, index) => {
                  if (item.key === "mariadb")
                    return <StyledTableRow></StyledTableRow>;
                  const isDuplicate = this.isKeyDuplicate(item.key, 2);
                  return (
                    <StyledTableRow key={index} data-test="var-row">
                      <StyledTableCell component="th" scope="row">
                        <TextField
                          value={item.key}
                          variant="outlined"
                          fullWidth
                          error={isDuplicate ? true : false}
                          helperText={isDuplicate ? "Dupicate Key" : ""}
                          onChange={(e) => this.handleKeyChange(e, 2,index)}
                          disabled={index !== this.state.editUserRowId}
                          InputProps={{
                            endAdornment: item.key && (
                              <CopyIcon copyText={item.key} />
                            ),
                          }}
                          data-test="var-key"
                        ></TextField>
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        <MuiTextField
                          id=""
                          label=""
                          customClassName=""
                          onKeyDown=""
                          value={item.value}
                          type={
                            item.isProtected
                              ? item.isShowPassword
                                ? "text"
                                : "password"
                              : "text"
                          }
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            this.handleValueChange(e, 2,index)
                          }
                          disabled={index !== this.state.editUserRowId}
                          InputProps={{
                            endAdornment: (
                              <>
                                {item.isProtected && (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() =>
                                        this.handleShowPassword_UV(index, 2)
                                      }
                                      edge="end"
                                      data-test="userVariable-password-visibility"
                                    >
                                      {item.isShowPassword ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                )}
                                {item.value?.length > 0 && (
                                  <CopyIcon copyText={item.value} />
                                )}
                              </>
                            ),
                          }}
                          data-test="var-value"
                        ></MuiTextField>
                      </StyledTableCell>
                      {this.props.disableEdit ? null : <>
                        <StyledTableCell component="th" scope="row">
                        {index === this.state.editUserRowId && (
                          <Checkbox
                            color="primary"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                            onChange={(e) => this.handleProtected(e, index, 2)}
                            checked={item.isProtected}
                            data-test="userVariables-secret-check"
                          />
                        )}
                      </StyledTableCell>
                      {/* {isAuthorized("update", role.name) && ( */}
                      <StyledTableCell component="th" scope="row">
                        {index !== this.state.editUserRowId && (
                          <div className="actionsIcon">
                            {/* {item.key !== "" && item.value !== "" && (
                                <Tooltip title={this.state.copyText}>
                                  <IconButton aria-label="delete">
                                    <FileCopyOutlinedIcon
                                      onClick={() =>
                                        this.copyToClipBoard(item.value, item.key)
                                      }
                                    />
                                  </IconButton>
                                </Tooltip>
                              )} */}
                            {isAuthorized("update", role.name) && (
                              <>
                                <IconButton
                                  aria-label="edit"
                                  onClick={() => this.handleEdit(index, 2)}
                                  data-test="userVariables-edit-icon"
                                >
                                  <EditIcon className="actionsIcon" />
                                </IconButton>

                                <IconButton
                                  aria-label="delete"
                                  onClick={() => {
                                      this.handleDelete(index, 2);
                                  }}
                                  data-test="userVariable-delete-icon"
                                >
                                  <DeleteForeverIcon className="actionsIcon" />
                                </IconButton>
                              </>
                            )}
                          </div>
                        )}
                        {isAuthorized("update", role.name) && (
                          <>
                            {index === this.state.editUserRowId && (
                              <div className="actionsIcon">
                                <IconButton
                                  onClick={() => this.handleCancel(index, 2)}
                                  data-test="userVariable-close-icon"
                                >
                                  <CloseIcon className="actionsIcon" />
                                </IconButton>
                                <IconButton
                                  onClick={(
                                    e: React.MouseEvent<
                                      HTMLButtonElement,
                                      MouseEvent
                                    >
                                  ) => this.handleDone(e, 2)}
                                  data-test="userVariable-done-icon"
                                >
                                  <DoneIcon className="actionsIcon" />
                                </IconButton>
                              </div>
                            )}
                          </>
                        )}
                      </StyledTableCell>
                      </>
                      }

                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
            {!this.hasValidUserVars() && (
              <div className="alignCenter">
                <p> No variables defined. </p>
              </div>
            )}
          </TableContainer>
        )}

    {this.state.bulkEdit && (
      <AceEditor
        placeholder={
          this.state.bulkVars
            ? ""
            : `Enter environment variables in the format:\nKEY=VAL`
        }
        onChange={(val) => this.handleVariableChange(val)}
        value={this.state.bulkVars ?? ""}
        {...aceStyles}
        setOptions={{
          showLineNumbers: true,
          tabSize: 4,
        }}
        minLines={10}
        data-test="bulk-editor"
        // annotations={[{ row: 1, column: 2, type: 'error', text: 'Duplicate key Error' }]}
        annotations={this.getAnnotations()}
      />
    )}
        </CardContent>
      </Collapse>
    </Card>
        }
  </div>
    );
  }
}

export default connect(
  null,
  null
)(withStyles(useStyles)(withTranslation()(UserVariablesTable)));
