const PLUGIN_PREFIX = '@PLUGIN';

export const FETCH_PLUGINS = `${ PLUGIN_PREFIX }/FETCH_PLUGINS`;
export const FETCH_PLUGINS_SUCCESS = `${ PLUGIN_PREFIX }/FETCH_PLUGINS_SUCCESS`;
export const FETCH_PLUGINS_FAILURE = `${ PLUGIN_PREFIX }/FETCH_PLUGINS_FAILURE`;

export const FETCH_PLUGIN = `${ PLUGIN_PREFIX }/FETCH_PLUGIN`;
export const FETCH_PLUGIN_SUCCESS = `${ PLUGIN_PREFIX }/FETCH_PLUGIN_SUCCESS`;
export const FETCH_PLUGIN_FAILURE = `${ PLUGIN_PREFIX }/FETCH_PLUGIN_FAILURE`;

export const CREATE_PLUGIN = `${ PLUGIN_PREFIX }/CREATE_PLUGIN`;
export const CREATE_PLUGIN_SUCCESS = `${ PLUGIN_PREFIX }/CREATE_PLUGIN_SUCCESS`;
export const CREATE_PLUGIN_FAILURE = `${ PLUGIN_PREFIX }/CREATE_PLUGIN_FAILURE`;

export const EDIT_PLUGIN = `${ PLUGIN_PREFIX }/EDIT_PLUGIN`;
export const EDIT_PLUGIN_SUCCESS = `${ PLUGIN_PREFIX }/EDIT_PLUGIN_SUCCESS`;
export const EDIT_PLUGIN_FAILURE = `${ PLUGIN_PREFIX }/EDIT_PLUGIN_FAILURE`;

export const ADD_ORG_PLUGIN = `${ PLUGIN_PREFIX }/ADD_ORG_PLUGIN`;
export const ADD_ORG_PLUGIN_SUCCESS = `${ PLUGIN_PREFIX }/ADD_ORG_PLUGIN_SUCCESS`;
export const ADD_ORG_PLUGIN_FAILURE = `${ PLUGIN_PREFIX }/ADD_ORG_PLUGIN_FAILURE`;

export const ADD_ORG_PLUGINS = `${ PLUGIN_PREFIX }/ADD_ORG_PLUGINS`;
export const ADD_ORG_PLUGINS_SUCCESS = `${ PLUGIN_PREFIX }/ADD_ORG_PLUGINS_SUCCESS`;
export const ADD_ORG_PLUGINS_FAILURE = `${ PLUGIN_PREFIX }/ADD_ORG_PLUGINS_FAILURE`;

export const REMOVE_ORG_PLUGIN = `${ PLUGIN_PREFIX }/REMOVE_ORG_PLUGIN`;
export const REMOVE_ORG_PLUGIN_SUCCESS = `${ PLUGIN_PREFIX }/REMOVE_ORG_PLUGIN_SUCCESS`;
export const REMOVE_ORG_PLUGIN_FAILURE = `${ PLUGIN_PREFIX }/REMOVE_ORG_PLUGIN_FAILURE`;

export const DELETE_PLUGIN = `${ PLUGIN_PREFIX }/DELETE_PLUGIN`;
export const DELETE_PLUGIN_SUCCESS = `${ PLUGIN_PREFIX }/DELETE_PLUGIN_SUCCESS`;
export const DELETE_PLUGIN_FAILURE = `${ PLUGIN_PREFIX }/DELETE_PLUGIN_FAILURE`;
export const UPDATE_BREADCRUMB = 'UPDATE_BREADCRUMB';

const fetchPlugins = () => {
  return {
    type: FETCH_PLUGINS,
  };
};

const fetchPlugin = (id) => {
  return {
    type: FETCH_PLUGIN,
    data: { id },
  };
};

const createPluginCall = (payload, iconPayload, history) => {
  return {
    type: CREATE_PLUGIN,
    data: {
      payload,
      iconPayload,
      history,
    },
  };
};

const editPluginCall = (id, payload, iconPayload) => {
  return {
    type: EDIT_PLUGIN,
    data: {
      id,
      payload,
      iconPayload,
    },
  };
};

const deletePlugin = (id) => {
  return {
    type: DELETE_PLUGIN,
    data: {
      id,
    },
  };
};

const addPluginToOrg = (id) => {
  return {
    type: ADD_ORG_PLUGIN,
    data: {
      id,
    },
  };
};

const addPluginsToOrg = (payload) => {
  return {
    type: ADD_ORG_PLUGINS,
    data: {
      payload,
    },
  };
};

const removePluginFromOrg = (id) => {
  return {
    type: REMOVE_ORG_PLUGIN,
    data: {
      id,
    },
  };
};

const updateBreadcrumb = (payload) => ({
  type: UPDATE_BREADCRUMB,
  data: { payload },
});

export {
  fetchPlugin,
  deletePlugin,
  updateBreadcrumb,
  createPluginCall,
  editPluginCall,
  fetchPlugins,
  removePluginFromOrg,
  addPluginToOrg,
  addPluginsToOrg,
};
