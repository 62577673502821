import {
  Checkbox, Grid, Paper, Table,
  TableBody,
  //TableCell,
  TableContainer,
  TableHead,
  // TableRow,
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  InputAdornment, 
  IconButton,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
//import { withStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { ChangeEvent, Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import ConfirmActionPopup from "../../components/confirmactionpopup/ConfirmActionPopup";
import BackdropLoader from "../../components/loader/BackdropLoader";
import MuiTextField from "../../components/textfield/MuiTextField";
import "./plugin.css";
import {
  addPluginsToOrg, addPluginToOrg, deletePlugin, fetchPlugins,
  removePluginFromOrg, updateBreadcrumb
} from "./redux/actions";
import { StyledTableCell, StyledTableRow } from "../../components/styledtabelcell/StyledTabelCell";
import CustomButton from "../../components/custombutton/CustomButton";
import { Dispatch } from "redux";
import { BreadcrumbModel } from "../../models/Common.model";
import { createStyles, WithStyles } from "@material-ui/core";
import { PluginModel } from "../../models/Environment.model";


/* istanbul ignore next */
const useStyles = () => createStyles({
  dropdown: {
    minWidth: 80,
    height: 50,
  },
});

interface Props extends PropsFromRedux, WithStyles, WithTranslation {}

type State = {
  isDeletePopupOpened: boolean,
  selectedPluginId: number|null,
  isCreateEditPopupOpen: boolean,
  editId: number,
  selectedPluginName: string,
  pluginList: PluginModel[],
  addRemovePluginPopUp: boolean,
  removePluginPopup: boolean,
  currentPlugin: PluginModel|null,
  searchText: string,
  toInstallPlugins: number[],
  addPluginsPopup: boolean,
  selectAll: boolean,
  support_ci : string,
  toInstallPluginsCopy: number[]
};

export class Plugin extends Component<Props, State> {
  constructor(props:Props) {
    super(props);
    this.state = {
      isDeletePopupOpened: false,
      selectedPluginId: null,
      isCreateEditPopupOpen: false,
      editId: 0,
      selectedPluginName: "",
      pluginList: [],
      addRemovePluginPopUp: false,
      removePluginPopup: false,
      currentPlugin: null,
      searchText: "",
      toInstallPlugins: [],
      addPluginsPopup: false,
      selectAll: false,
      support_ci : "all",
      toInstallPluginsCopy:[],
    };
  }

  // deletePopupAgreeHandler = () => {
  //   this.props.deletePlugin(this.state.selectedPluginId);
  //   this.setState({
  //     selectedPluginId: null,
  //     selectedPluginName: "",
  //     isDeletePopupOpened: false,
  //   });
  // };

  // deletePopupDisagreeHandler = () => {
  //   this.setState({
  //     selectedPluginId: 0,
  //     selectedPluginName: "",
  //     isDeletePopupOpened: false,
  //   });
  // };

  handleCategoryChange = (e: ChangeEvent<{ value: unknown }>) => {
    this.setState({
      support_ci: e.target.value as string
    })
  }
  
  // handleDelete(pluginId, pluginName) {
  //   this.setState({
  //     selectedPluginId: pluginId,
  //     selectedPluginName: pluginName,
  //     isDeletePopupOpened: true,
  //   });
  // }

  // handleCreatePlugin = () => {
  //   this.setState({
  //     isCreateEditPopupOpen: true,
  //     editId: 0,
  //   });
  // };

  // handleCreateEditSuccess = () => {
  //   this.setState({
  //     isCreateEditPopupOpen: false,
  //     editId: 0,
  //   });
  //   this.props.fetchPlugins();
  // };

  // handleEdit = (id) => {
  //   this.setState({
  //     isCreateEditPopupOpen: true,
  //     editId: id,
  //   });
  // };

  // handleCancel = () => {
  //   this.setState({
  //     isCreateEditPopupOpen: false,
  //     editId: 0,
  //   });
  // };

  handlePluginAction = (plugin: PluginModel) => {
    // if (plugin.installed) {
    this.setState({
      addRemovePluginPopUp: true,
      currentPlugin: plugin,
    });
    // this.props.removePluginFromOrg(plugin.id);
    // } else {

    //   this.props.addPluginToOrg(plugin.id);
    // }
  };

  addRemoveAgreeHandler = () => {
    const { currentPlugin } = this.state;
    if(currentPlugin){
      if (currentPlugin?.installed) {
        this.props.removePluginFromOrg(currentPlugin.id);
      } else {
        this.props.addPluginToOrg(currentPlugin.id);
      }
    }
    this.setState({
      addRemovePluginPopUp: false,
      currentPlugin: null,
    });
  };

  addRemoveDisagreeHandler = () => {
    this.setState({
      addRemovePluginPopUp: false,
      currentPlugin: null,
    });
  };

  handleSearch = (e:React.ChangeEvent<{ value: string }>) => {
    this.setState({
      searchText: e.target.value,
    });
  };

  UNSAFE_componentWillReceiveProps = (newProps:Props) => {
    if (
      newProps.pluginList?.length > 0 &&
      (newProps.organizationInfo || this.props.organizationInfo)
    ) {
      const { pluginList, organizationInfo } = newProps;
      this.updatePluginList(pluginList, organizationInfo.plugins);
      // const breadcrumbData = [{ name: "Plugins ", path: "/plugin" }];
      // this.props.updateBreadcrumb(breadcrumbData);
    }
  };

  updatePluginList = (pluginList: PluginModel[], orgPlugins: PluginModel[]) => {
    const installedPluginIds = orgPlugins?.map((p:PluginModel) => {
      return p.id;
    });
    if(installedPluginIds){
      const newPluginList = pluginList?.map((p:PluginModel) => {
        const isInstalled = installedPluginIds.find((insId:number) => {
          return insId === p.id;
        });
        p.installed = false;
        if (isInstalled) {
          p.installed = true;
        }
        return p;
      });
      this.setState({
        pluginList: newPluginList ?? [],
      });
    }
  };

  componentDidMount() {
    this.props.fetchPlugins();
    if (this.props.pluginList && this.props.organizationInfo) {
      this.updatePluginList(
        this.props.pluginList,
        this.props.organizationInfo?.plugins
      );
    }
  }

  handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, plugin: PluginModel) => {
    const _p = this.state.toInstallPlugins.find((p) => {
      return p === plugin.id;
    });
    if (e.target.checked) {
      this.setState({
        toInstallPlugins: [...this.state.toInstallPlugins, plugin.id],
      });
    } else {
      if (_p) {
        const newToInstallPlugin = this.state.toInstallPlugins.filter((p) => {
          return p !== plugin.id;
        });
        this.setState({
          toInstallPlugins: newToInstallPlugin,
        });
      }
    }
  };

  isChecked = (plugin: PluginModel) => {
    const toInstall = this.state.toInstallPlugins.find((p) => {
      return p === plugin.id;
    });
    return plugin.installed || Boolean(toInstall);
  };

  handleAddPlugin = () => {
    this.setState({
      addPluginsPopup: true,
    });
  };

  addPluginsAgreeHandler = () => {
    const payload = [...this.state.toInstallPlugins];
    this.props.addPluginsToOrg(payload);
    this.setState({
      addPluginsPopup: false,
      toInstallPlugins: [],
      selectAll: false,
    });
  };

  addPluginsDisagreeHandler = () => {
    this.setState({
      addPluginsPopup: false,
    });
  };

  // handleRemovePlugin = () => {
  //   this.setState({
  //     removePluginPopup: true,
  //   });
  // };

  handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      this.setState({
        toInstallPluginsCopy: [...this.state.toInstallPlugins],
      });
      const allPlugins = this.state.pluginList.filter((p:PluginModel) => {
        return !p.installed;
      });
      const ids = allPlugins.map((p:PluginModel) => {
        return p.id;
      });
      this.setState({
        toInstallPlugins: [...ids],
      });
    } else {
      this.setState({
        // toInstallPlugins: [...this.state.toInstallPluginsCopy],
        toInstallPlugins: [],
      });
    }
    this.setState({
      selectAll: e.target.checked,
    });
  };

  render() {
    const { t, classes } = this.props;
    const { pluginList } = this.state;

    return (
        <div>
            <div className="listContainer" data-test="main-container">
                <Typography color="textPrimary" variant="h5" data-test="title-text">
                {t('Organization.AdminTab.plugins')}
                </Typography>
                <Grid container spacing={2} justify="flex-end">
                    <Grid item>
                        <MuiTextField
                          id="searchText"
                          label={t("App.PluginSelection.searchPlugin")}
                          name="searchText"
                          style={{ width: "100%" }}
                          color="primary"
                          onChange={this.handleSearch}
                          value={this.state.searchText}
                          variant="outlined"
                          data-test="search-text"
                          className="w-100"
                          InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton aria-label="toggle password visibility" edge="end">
                                        <SearchIcon 
                                        fontSize="small"
                                        // size="small" 
                                        />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        />
                    </Grid>
                    <Grid item >
                      <FormControl
                        variant="outlined"
                        className={`w-100 ${classes.formControl}`}
                      >
                        <InputLabel>{"Category"}</InputLabel>

                        <Select
                          value={this.state.support_ci}
                          onChange={(e) => this.handleCategoryChange(e)}
                          label={"Category"}
                          className={classes.dropdown}
                          data-test="select"
                        >
                          <MenuItem value="all">
                            <em>{t('Support.SupportPage.all')}</em>
                          </MenuItem>
                          <MenuItem value="false">{"Template"}</MenuItem>
                          <MenuItem value="true">{"Non-template"}</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item>
                        <CustomButton
                          // variant="contained"
                          label={t("Plugin.PluginPage.import")}
                          onClick={() => this.handleAddPlugin()}
                          data-test="add-plugin-button"
                          disabled={!this.state.toInstallPlugins?.length}
                        />
                    </Grid>
                </Grid>
            </div>
            <div>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell data-test="select-all-cell">
                                  {(pluginList.filter((p:PluginModel) => ( p.installed)).length !== pluginList.length)? (
                                    <Checkbox
                                      color="primary"
                                      checked={this.state.selectAll}
                                      onChange={(e) => {
                                        this.handleSelectAll(e);
                                      }}
                                      data-test="select-all-checkbox"
                                    />
                                  ) : <Checkbox
                                        color="primary"
                                        checked={this.state.selectAll}
                                        onChange={(e) => {
                                          this.handleSelectAll(e);
                                        }}
                                        disabled={true}
                                        style={{display:'none'}}
                                        data-test="select-all-checkbox"
                                      />
                                }
                                </StyledTableCell>
                                <StyledTableCell data-test="image-cell"></StyledTableCell>
                                <StyledTableCell data-test="name-cell">{t('Plugin.PluginPage.name')}</StyledTableCell>
                                <StyledTableCell data-test="description-cell">{t('Plugin.PluginPage.description')}</StyledTableCell>
                                {/* <StyledTableCell>Status</StyledTableCell> */}
                                <StyledTableCell data-test="ci-cell">{t('Plugin.PluginPage.supportCi')}</StyledTableCell>
                                <StyledTableCell data-test="empty-cell" align="center"> {t('Projects.UserTab.actionLabel')} </StyledTableCell>

                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {pluginList &&
                  pluginList
                    .filter((p:PluginModel) =>
                      p.name
                        ?.toLowerCase()
                        .includes(this.state.searchText?.toLowerCase())
                    ).filter((p:PluginModel) => 
                      this.state.support_ci === "all" || p.support_ci.toString() === this.state.support_ci
                    )
                    .map((plugin:PluginModel) => (
                        <StyledTableRow key={plugin.id} data-test="plugin-rows">
                            <StyledTableCell>
                                {" "}
                                <Checkbox
                                  color="primary"
                                  checked={this.isChecked(plugin)}
                                  disabled={plugin.installed}
                                  data-test="plugin-checkbox"
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              this.handleCheckboxChange(e, plugin);
                            }}
                                />
                            </StyledTableCell>
                            <StyledTableCell>
                                <img
                                  src={plugin && plugin.image ? plugin.image : ""}
                                  alt="Plugin"
                                  width={50}
                                  data-test="plugin-image"
                                >
                                </img>
                            </StyledTableCell>
                            <StyledTableCell>{plugin.name}</StyledTableCell>
                            <StyledTableCell
                              style={{ width: "44%" }}
                              className="w60"
                            >
                                {plugin.description}
                            </StyledTableCell>
                            {/* <StyledTableCell>
                        {plugin.active ? (
                          <p>
                            <span className="activestatus"></span> Active
                          </p>
                        ) : (
                          <p>
                            <span className="inactivestatus"></span> Inactive
                          </p>
                        )}
                      </StyledTableCell> */}
                            <StyledTableCell>
                                {plugin.support_ci ? (
                                    <p>
                                        <span className="activestatus"></span>
                                        {t('Plugin.PluginPage.support')}
                                    </p>
                          ) : (
                              <p>
                                  <span className="inactivestatus"></span>
                                  {t('Plugin.PluginPage.doesnotsupport')}
                              </p>
                          )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                {/* <IconButton aria-label="view">
                            <VisibilityIcon />
                          </IconButton> */}

                                {/* <IconButton aria-label="edit" onClick={() => this.handleEdit(plugin.id)}>
                            <EditIcon />
                          </IconButton> */}
                                {plugin.installed && (
                                <IconButton
                                  aria-label="delete"
                                  onClick={() =>this.handlePluginAction(plugin)
                              }
                                  title={t('Plugin.PluginPage.removePlugin')}
                                  data-test="icon-button"
                                >
                                    <DeleteIcon />
                                </IconButton>
                          )}

                                {/* <FormControlLabel
                            control={
                              <Switch
                                name="active"
                                color="primary"
                                checked={plugin.installed}
                                onChange={(e) =>
                                  this.handlePluginAction(e, plugin)
                                }
                                // disabled = {!isAuthorized("update", envRoke.name)}
                              />
                            }
                            label={plugin.installed ? "Imported" : "Import"}
                          /> */}
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {this.state.addRemovePluginPopUp && (
                <ConfirmActionPopup
                  open={this.state.addRemovePluginPopUp}
                  handleAgree={this.addRemoveAgreeHandler}
                  handleDisAgree={this.addRemoveDisagreeHandler}
                  message={`Are you sure you want to ${
                this.state.currentPlugin?.installed ? "remove" : "add"
              } the Plugin`}
                  yesText={t('Projects.VariablesTab.yesText')}
                  noText={t('Projects.VariablesTab.noText')}
                  data-test="confirm-popup"
                />
          )}
                {this.state.addPluginsPopup && (
                <ConfirmActionPopup
                  open={this.state.addPluginsPopup}
                  handleAgree={this.addPluginsAgreeHandler}
                  handleDisAgree={this.addPluginsDisagreeHandler}
                  message={t('Plugin.PluginPage.addPlugins')}
                  yesText={t('Projects.VariablesTab.yesText')}
                  noText={t('Projects.VariablesTab.noText')}
                  data-test="confirm"
                />
          )}
                {/* <Dialog
          open={this.state.isCreateEditPopupOpen}
          keepMounted
          onClose={this.handleCancel}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
         
      >
          <DialogTitle id="alert-dialog-slide-title">
            <Typography className='dialogtitle'>{ this.state.editId > 0 ? "Update Plugin" : "Add Plugin" }</Typography>
            <IconButton aria-label="close" size="small" className='right' onClick={this.handleCancel}>
                <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Divider />
          <DialogContent dividers>
              <DialogContentText id="alert-dialog-slide-description">
                { this.state.editId > 0 && <EditPlugin pluginId={ this.state.editId } handleCreateEditSuccess={ this.handleCreateEditSuccess }/> }
                { this.state.editId === 0 && <CreatePlugin handleCreateEditSuccess={ this.handleCreateEditSuccess } /> }   
              </DialogContentText>
          </DialogContent>
        </Dialog> */}
                {/* <ConfirmDeletePopup
            open={this.state.isDeletePopupOpened}
            handleAgree={this.deletePopupAgreeHandler}
            handleDisAgree={this.deletePopupDisagreeHandler}
            message={`The action you are taking cannot be undone. This will permanently delete all the data. If you are sure, please type "${this.state.selectedPluginName}"`}
            yesText="Yes"
            noText="No"
            toMatchName={this.state.selectedPluginName}
            toDeleteModule="Plugin"
            loading={this.props.deletingPLugin}
          /> */}
                {this.props.deletingPlugin && (
                <BackdropLoader message={t('Plugin.PluginPage.deletingPlugin')} data-test="backdrop-delete" />
          )}
                {this.props.creatingPlugin && (
                <BackdropLoader message={t('Plugin.PluginPage.addingPlugin')} data-test="backdrop-create"/>
          )}
                {this.props.fetchingPlugins && (
                <BackdropLoader message={t('Plugin.PluginPage.fetchingPlugin')} data-test="backdrop-fetch"/>
          )}
            </div>
        </div>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => {
  return {
    pluginList: state.PluginReducer.pluginList,
    deletingPlugin: state.PluginReducer.deletingPlugin,
    creatingPlugin: state.PluginReducer.creatingPlugin,
    fetchingPlugins: state.PluginReducer.fetchingPlugins,
    organizationInfo: state.OrganizationReducer.organizationInfo,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchPlugins: () => dispatch(fetchPlugins()),
    deletePlugin: (id: number) => dispatch(deletePlugin(id)),
    removePluginFromOrg: (id: number) => dispatch(removePluginFromOrg(id)),
    addPluginToOrg: (id: number) => dispatch(addPluginToOrg(id)),
    addPluginsToOrg: (payload: any) => dispatch(addPluginsToOrg(payload)),
    updateBreadcrumb: (breadcrumbData: BreadcrumbModel[]) =>
      dispatch(updateBreadcrumb(breadcrumbData)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(withStyles(useStyles)(withTranslation()(Plugin)));

type PropsFromRedux = ConnectedProps<typeof connector>;
