import React, { memo } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import paths from '../constants/paths';
import { hasValidSessionSelector } from '../pages/login/redux/selectors';

export const StaticPrivateRoute = ({ component: Component, location, hasValidSession, ...rest }) => {

  return (
      <Route
        { ...rest }
        render={ props =>
          hasValidSession ?
              <>
                <Component { ...props } />
              </>
         : 
            <>
                <Redirect to={ { pathname: paths.DEFAULT, state: { from: location } } } /> 
            </>
        
      }
      data-test="main-route"
      />
  )
}

/* istanbul ignore next */
const mapStateToProps = state => ({
  hasValidSession: hasValidSessionSelector(state),
})

export default memo(connect(mapStateToProps)(StaticPrivateRoute))
