import React, { useState, useEffect } from "react";
import {
	Grid,
	ButtonBase,
	Typography,
	Chip,
	TextField,
	IconButton
} from "@material-ui/core";
import Autocomplete, { AutocompleteChangeReason } from "@material-ui/lab/Autocomplete";
import "./availabletab.css";
import AddonsCard from "../../../../../components/addonscard/AddonsCard";
import { makeStyles } from "@material-ui/core/styles";
import {
	installAddon,
	uninstallAddon,
	fetchAddons,
	getAddonStatus,
	getInstalledAddons,
	modifyAddon,
	initiateAddonStatus,
	updateAddonStatus,
	clearAddons,
	fetchAddonCategorys
} from "../../../redux/actions";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { fetchPluginVersions } from "../../../../app/createapp/redux/actions";
import { connect,ConnectedProps } from "react-redux";
import BackdropLoader from "../../../../../components/loader/BackdropLoader";
import "./availabletab.css";
import AddonSkeleton from "../../../../../components/skeletons/AddonSkeleton";
import AddonInfo from "../addoninfo/AddonInfo";
import ConfirmDeletePopup from "../../../../../components/confirmdeletepopup/ConfirmDeletePopup";
import { useTranslation } from 'react-i18next';
import NoContentImage from "../../../../../components/nocontentimagecontainer/NoContentImage";
import SearchField from "../../../../../components/searchfield/SearchField";
import { createStyles, Theme } from "@material-ui/core";
import { Dispatch } from "redux";
import { StaticContext } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { AddonModel,AddonCategory } from "../../../../../models/Environment.model";

/* istanbul ignore next */
const useStyles = makeStyles((theme:Theme) =>createStyles ({
	root: {
		padding: theme.spacing(1),
		width: "100%",
	},
	content: {
		marginTop: theme.spacing(1),
		width: "100%",
	},
}));
type LocationState = {
	addonEnvId: number;
  };
interface Iprops extends PropsFromRedux{
	changeHash:(hash:string,value:number,envId:number,delay?:number)=>void;
	history:RouteComponentProps<{}, StaticContext, LocationState>["history"]
}
//interface IinstalledAddon extends AddonModel{
	//version:Version,
	//plugin_version:PluginVersionModel
//}

interface TfinalPayload extends Pick<AddonModel, "version" | "resource_id" | "variables"> {
    plugin_version_id: number;
    //version: Version;
    pvc: any;

    //variables: any;
    //resource_id: number;
}

export const AvailableAddons = (props:Iprops) => {
	const classes = useStyles();
	const envId = props.environmentDetails?.id ?? null;
	const [addons, setAddons] = React.useState<AddonCategory[]>([]);
	//const [settingAddons, setSettingAddons] = React.useState(true);
	// const [pluginId, setPluginId] = React.useState(
	// 	props.environmentDetails?.plugin_version?.plugin_id ?? null
	// );
	const pluginId = props.environmentDetails?.plugin_version?.plugin_id ?? null
	const [mode, setMode] = useState(0);
	const [currentAddon, setCurrentAddon] = useState<AddonCategory|null>(null);

	const [uninstallAddonId, setUninstallAddonId] = useState(0);
	const [uninstallAddonName, setUninstallAddonName] = useState("");
	const [isUninstallPopupOpen, setIsUninstallPopupOpen] = useState(false);
	const [currentView, setCurrentView] = useState(2);
	const [searchText, setSearchText] = useState("");
	const [categoryFilter, setCategoryFilter] = useState("");
	const [categoryFilterValue, setCategoryFilterValue] = useState<AddonCategory[]>([]);
	const [t] = useTranslation()
	const [toRedirect, setToRedirect] = useState(
		props.history?.location?.state?.addonEnvId ? true : false
	);
	
	useEffect(() => {
		if (props.environmentDetails?.id > 0) {
			props.initiateAddonStatus(props.environmentDetails.id);
			props.getInstalledAddons(props.environmentDetails.id);
		}
	}, [props.environmentDetails.id]);

	const viewAddonInfo = (id:number) => {
		const addon = addons?.find((a) => {
			return a.id === id;
		});
		if (addon) {
			setCurrentAddon(addon);
		}
		setMode(1);
	};

	const handleBack = () => {
		setMode(0);
		setCurrentAddon(null);
	};

	const _modifyAddon = (aId:number, payload:AddonModel) => {
		if (aId && envId && props.versionsList) {
			let finalPayload = {
				plugin_version_id: props.versionsList?.id,
				version: payload.version,
				pvc: {},
				variables: payload.variables,
				resource_id: payload.resource_id,
				replicas: parseInt(payload.replicas),
				
			};
			props.modifyAddon(
				envId,
				aId,
				finalPayload,
				props.history,
				props.changeHash
			);
		}
	};

	// Maps all the addons to add whether the addon is installed field to addons
	useEffect(() => {
		let allAddons = props.addons;
		setAddons([...allAddons]);
	}, [props.addons]);

	useEffect(() => {
		if (
			props.installedAddons?.addons?.length > 0 &&
			addons.length > 0 &&
			props.history.location.state?.addonEnvId &&
			toRedirect ) { 
			setToRedirect(false);
			let addonEnvId = props.history.location.state.addonEnvId;
			let addon = props.installedAddons.addons.find((a: AddonModel) => a.id === addonEnvId);
			let addonId = addon ? addon.plugin_version?.plugin_id : null
			viewAddonInfo(addonId);
		}
	}, [addons, props.installedAddons]);

	// const isAddonInstalled = (_addon) => {
	// 	const _detail_add_on = props.installedAddons?.addons?.find((_add) => {
	// 		return _add.version?.info[0]?.name === _addon.name;
	// 	});
	// 	if(_detail_add_on){
	// 		return true
	// 	}
	// 	return false
	// }

	// const getInstalledAddonEnvId = (_addon) => {
	// 	const _installedAddon = props.installedAddons?.addons?.find((_a) => {
	// 		return _a.version?.info[0]?.name === _addon.name;
	// 	});
	// 	return _installedAddon?.id
	// }

	const getInstalledAddonProp = (_addon:AddonCategory) => {
		const _installedAddon = props.installedAddons?.addons?.find((_a: AddonModel) => {
			return _a.version?.info[0]?.name === _addon.name;
		});
		return _installedAddon
	}

	// const findAddonStatus = (_addon) => {
	// 	const wsStatus = props.wsAddonStatus?.find(a => {
	// 		return a.name === _addon.name
	// 	})
	// 	const _detail_add_on = props.installedAddons?.addons?.find((_add) => {
	// 		return _add.version?.info[0]?.name === _addon.name;
	// 	});
	// 	let status = null
	// 	if(wsStatus && !_detail_add_on){
	// 		status = "Uninstalling"
	// 	}else if(wsStatus && _detail_add_on){
	// 		status = wsStatus.status
	// 	}else if(_detail_add_on && !wsStatus){
	// 		status = "Fetching Status"
	// 	}	
	// 	return status
	// }

	// const findServiceDetail = (_addon) => {
	// 	const _detail_add_on = props.installedAddons?.addons?.find((_add) => {
	// 		return _add.version?.info[0]?.name === _addon.name;
	// 	});
	// 	if(_detail_add_on){
	// 		return _detail_add_on.service_detail
	// 	}
	// 	return {}
	// }

	useEffect(() => {
		let addonNames:string[] = [];
		addonNames = props.installedAddons?.addons?.map((a: AddonModel) => {
			if (a.version.info) {
				return a.version.info[0].name;
			}
		});
		const _addonsStatus = props.wsAddonStatus?.filter((_a:{name:string}) => {
			return addonNames?.includes(_a.name);
		});
		props.updateAddonStatus(_addonsStatus);
	}, [props.installedAddons?.addons]);

	const _installAddon = (aId:number, payload:AddonModel) => {
		if (aId && envId && props.versionsList) {
			// const versionInfo =
			// 	props.versionsList?.versions &&
			// 	props.versionsList.versions[0]?.versions[0];
			let finalPayload:TfinalPayload = {
				plugin_version_id: props.versionsList?.id,
				version: payload.version,
				pvc: {},
				variables: payload.variables,
				resource_id: payload.resource_id,	
				//replicas: parseInt(payload.replicas),
			};
			//console.log("finalPayload -- " + JSON.stringify(finalPayload));
			props.installAddon(
				envId,
				aId,
				finalPayload,
				props.history,
				props.changeHash
			);
		}
	};

	const _uninstallAddon = (aId:number, addonName:string) => {
		let toDeleteAddon = props.installedAddons?.addons?.find((a: AddonModel) => {
			return a.plugin_version?.plugin?.id === aId;
		});
		const deleteId = toDeleteAddon?.id;
		if (deleteId && envId) {
			setUninstallAddonId(deleteId);
			setUninstallAddonName(addonName);
			setIsUninstallPopupOpen(true);
		}
	};

	const handleUninstallAgreeHandler = () => {
		props.uninstallAddon(envId, uninstallAddonId);
		setUninstallAddonId(0);
		setUninstallAddonName("");
		setIsUninstallPopupOpen(false);
		handleBack();
	};

	const handleUninstallDisAgreeHandler = () => {
		setUninstallAddonId(0);
		setUninstallAddonName("");
		setIsUninstallPopupOpen(false);
	};

	// const getInstalledorNot = () => {
	// 	return currentView === 1 ? true : false;
	// }

	const _fetchAddons = (_categories:string, _searchText:string) => {
		props.fetchAddons(pluginId, currentView, _categories, _searchText);
	}

	const resetFilters = () => {
		setSearchText("");
		setCategoryFilter("");
		setCategoryFilterValue([]);
		_fetchAddons("", "");
	}

	const handleCurrentView = (_value:number) => {
		setCurrentView(_value);
		// fetchAddons(categoryFilter, searchText);
	}

	const categoryChanged = (e:React.ChangeEvent<{}>, value:AddonCategory[],reason?:AutocompleteChangeReason) => {
		if (e) {
			let categories = "";
			if (value && value.length > 0) {
				categories = value.map(x => x.id).join(',');
				setCurrentView(2);
			}
			setCategoryFilter(categories);
			setCategoryFilterValue(value);
			_fetchAddons(categories, searchText);
		}
	}

	const handleSearch = (st:string) => {
		_fetchAddons(categoryFilter, st)
	}
	
	const handleSearchChange = (st:string) => {
		setCurrentView(2);
		setSearchText(st);
	}

	useEffect(() => {
		if (pluginId) props.fetchAddons(pluginId, currentView);
	}, [pluginId]);

	useEffect(() => {
		if (pluginId) {
			props.fetchAddonCategorys(pluginId);
		}
		if (props.addons) {
			setAddons(props.addons);
		}
		return () => {
			props.clearAddons();
			setAddons([])
			setCurrentView(2)
		};
	}, []);

	// const filtered = currentView === 2 ? addons : addons.filter(a => {
	// 	return isAddonInstalled(a)
	// })
	// console.log(categoryFilter, searchText)
	return (
		<div className={classes.root} data-test={"addonsContainer"}>
			<div className={classes.content}>
				{mode === 1 && (
					<Grid container spacing={2} className="m-b-10">
						<Grid item md={3}>
							<ButtonBase onClick={handleBack} style={{ color: "#357dfd" }} data-test="back-button">
								<ArrowBackIcon fontSize="small" />{" "}
								<span className="jobNav"> {t('Environment.AvailableTab.list')} </span>
							</ButtonBase>
						</Grid>
					</Grid>
				)}
				
				{mode === 0 &&
					<>
					<Grid container justify="space-between" spacing={1}>
						<Grid item sm={5}>
							<Grid container spacing={2} alignItems="center">
								{/* <Grid item>
									<Typography color="textPrimary" variant="h5" data-test="clusters-title">
									{t('Environment.AvailableTab.addons')}
									</Typography>
								</Grid> */}
								{
									<>
										<Grid item >
											<Chip
												label={t('Projects.ActivityTab.allLabel')}
												clickable
												color="primary"
												onClick={() => handleCurrentView(2)}
												variant={currentView === 2 ? "default" : "outlined"}
												data-test="destroyed-btn"
											/>
										</Grid>
										<Grid item >
											<Chip
												label={t('Projects.ActivityTab.installedLabel')}
												clickable
												color="primary"
												onClick={() => handleCurrentView(1)}
												variant={currentView === 1 ? "default" : "outlined"}
												data-test="active-btn"
											/>
										</Grid>
									</>
								}
							</Grid>
						</Grid>
						<Grid item className="right" xs={12} sm={7}>
							<Grid container justify="space-between">
								<Grid item xs={12} sm={1}>
									{
										(categoryFilter || searchText) &&
										<IconButton>
											<Typography
												
												onClick={() => resetFilters()}
												variant="h5"
												data-test="reset-button"
											>
												{t('Projects.ActivityTab.resetLabel')}
											</Typography>
										</IconButton>
									}
								</Grid>
								<Grid item xs={12} sm={5}>
									<Autocomplete
										multiple
										limitTags={2}
										id="multiple-limit-tags"
										options={props.addonCategories}
										getOptionLabel={(option) => option.name}
										defaultValue={props.addonCategories[0]}
										value={categoryFilterValue}
										renderInput={(params) => (
											<TextField {...params} variant="outlined" label="Category" placeholder="Category" />
										)}
										onChange={(e:React.ChangeEvent<{}>, value, reason) => categoryChanged(e, value, reason) }
										data-test="category"
									/>
								</Grid>
								<Grid item xs={12} sm={5}>
									<SearchField 
										label={t('Environment.AvailableTab.search')}
										search={searchText}
										handleSearchChange={handleSearchChange}
										handleSearch={handleSearch}
										data-test="search"
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid container spacing={2} className="m-t-10">
						{
							props.addons ? addons?.length > 0 ? (
									addons.map((addon, ind) =>
											<AddonsCard
												addon={addon}
												key={ind}
												// action={isAddonInstalled(addon) ? viewAddonInfo : _installAddon}
												// source={isAddonInstalled(addon) ? 2 : 1}
												environmentState={props.environmentState}
												// status={findAddonStatus(addon)}
												// installed = {isAddonInstalled(addon)}
												// envId = {getInstalledAddonEnvId(addon)}
												envRole={props.envRole}
												// fetchPluginVersions={props.fetchPluginVersions}
												// serviceDetail={findServiceDetail(addon)}
												installedAddon = {getInstalledAddonProp(addon)}
												viewAddon={viewAddonInfo}
												installAddon = {_installAddon}
												wsAddonStatus={props.wsAddonStatus}
												currentView={currentView}
												data-test="addon-popup"
											/>
							)) : (
								<NoContentImage
									message={ categoryFilterValue.length ? t('Environment.AvailableTab.noAddonsWithFilter') : t('Environment.AvailableTab.noAddons')}
									alt="No addons"
									data-test="noStorageGrid"
								/>
							)
							: (
									[1, 2, 3, 4, 5, 6].map((ind) =>
										<Grid key={ind} item lg={4} md={6} xs={12}>
											<AddonSkeleton />
										</Grid>
									)
							)
						}
					</Grid>
					</>
				}
					{mode === 1 && currentAddon && (
						<Grid container>
							<Grid item xs={12}>
								<AddonInfo
									addon={currentAddon}
									// envAddon={getInstalledAddonEnvId(currentAddon)}
									uninstall={_uninstallAddon}
									modify={_modifyAddon}
									environmentState={props.environmentState}
									// fetchPluginVersions={props.fetchPluginVersions}
									modifyingAddon={props.modifyingAddon}
									envId={envId}
									// status={findAddonStatus(currentAddon)}
									installedAddon = {getInstalledAddonProp(currentAddon)}
									wsAddonStatus={props.wsAddonStatus}
									data-test="addon-info"
								/>
							</Grid>
						</Grid>
					)}
				
				<ConfirmDeletePopup
					open={isUninstallPopupOpen}
					handleAgree={handleUninstallAgreeHandler}
					handleDisAgree={handleUninstallDisAgreeHandler}
					message={`This action will remove the addon and its data. Please type "${uninstallAddonName}" to uninstall Addon : ${uninstallAddonName}`}
					// yesText={t('Projects.VariablesTab.yesText')}
					// noText={t('Projects.VariablesTab.noText')}
					action="uninstall"
					toMatchName={uninstallAddonName}
					toDeleteModule="addon"
					loading={props.uninstallingAddon}
					data-test="delete-popup"
				/>
				{props.installingAddon && <BackdropLoader message={t('Environment.AvailableTab.install')} data-test="installingAddon" />}
				{props.uninstallingAddon && (
					<BackdropLoader message={t('Environment.AvailableTab.uninstall')} data-test="uninstallingAddon" />
				)}
				{props.addonsLoading && <BackdropLoader message={t('Environment.AvailableTab.fetching')} data-test="addonsLoading"/>}
			</div>
		</div>
	);
};

/* istanbul ignore next */
const mapStateToProps = (state:any) => ({
	addons: state.EnvironmentReducer.addons,
	installedAddons: state.EnvironmentReducer.installedAddons,
	environmentDetails: state.EnvironmentReducer.environmentDetails,
	installingAddon: state.EnvironmentReducer.installingAddon,
	modifyingAddon: state.EnvironmentReducer.modifyingAddon,
	uninstallingAddon: state.EnvironmentReducer.uninstallingAddon,
	environmentState: state.EnvironmentReducer.environmentState,
	addonsStatus: state.EnvironmentReducer.addonsStatus,
	fetchingAddonsStatus: state.EnvironmentReducer.fetchingAddonsStatus,
	addonsLoading: state.EnvironmentReducer.addonsLoading,
	envRole: state.EnvironmentReducer.envRole,
	versionsList: state.CreateAppReducer.versionsList,
	wsAddonStatus: state.EnvironmentReducer.wsAddonStatus,
	addonCategories: state.EnvironmentReducer.addonCategories
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch:Dispatch) => ({
	fetchAddonCategorys: (id:number) => dispatch(fetchAddonCategorys(id)),
	fetchAddons: (id:number, installed:number, category?:string, searchText?:string) => dispatch(fetchAddons(id, installed, category, searchText)),
	installAddon: (eId:number, aId:number, payload:TfinalPayload, history:RouteComponentProps<{}, StaticContext, LocationState>["history"], changeHash:(hash:string,value:number,envId:number,delay?:number)=>void) =>
		dispatch(installAddon(eId, aId, payload, history, changeHash)),
	modifyAddon: (eId:number, aId:number, payload:TfinalPayload, history:RouteComponentProps<{}, StaticContext, LocationState>["history"], changeHash:(hash:string,value:number,envId:number,delay?:number)=>void) =>
		dispatch(modifyAddon(eId, aId, payload, history, changeHash)),
	uninstallAddon: (eId:number, aId:number) =>
		dispatch(uninstallAddon(eId, aId)),
	getAddonStatus: (id:number) => dispatch(getAddonStatus(id)),
	getInstalledAddons: (id:number) => dispatch(getInstalledAddons(id)),
	fetchPluginVersions: (id:number) => dispatch(fetchPluginVersions(id)),
	initiateAddonStatus: (id:number) => dispatch(initiateAddonStatus(id)),
	updateAddonStatus: (payload:TfinalPayload) => dispatch(updateAddonStatus(payload)),
	clearAddons: () => dispatch(clearAddons()),
});
const connector=connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>;
export default  connector(AvailableAddons);
