import { takeLatest, put, call, select } from 'redux-saga/effects';
import { 
  CREATE_CLUSTER, 
  CREATE_CLUSTER_FAILURE, 
  CREATE_CLUSTER_SUCCESS, 
  VALIDATE_PERMISSION, 
  VALIDATE_PERMISSION_SUCCESS, 
  VALIDATE_PERMISSION_FAILURE, 
  GET_CLUSTER_LIST,
  GET_CLUSTER_LIST_SUCCESS, 
  GET_CLUSTER_LIST_FAILURE, 
  GET_CLUSTER_DETAILS,
  GET_CLUSTER_DETAILS_SUCCESS,
  GET_CLUSTER_DETAILS_FAILURE,
  DELETE_CLUSTER, 
  DELETE_CLUSTER_SUCCESS,
  DELETE_CLUSTER_FAILURE,
  DESTROY_CLUSTER,
  DESTROY_CLUSTER_SUCCESS,
  DESTROY_CLUSTER_FAILURE,
  APPLY_TERRAFORM,
  APPLY_TERRAFORM_SUCCESS,
  APPLY_TERRAFORM_FAILURE,
  GET_WORKFLOWS,
  GET_WORKFLOWS_SUCCESS,
  GET_WORKFLOWS_FAILURE,
  CANCEL_WORKFLOW,
  CANCEL_WORKFLOW_FAILURE,
  CANCEL_WORKFLOW_SUCCESS,
  GET_WORKFLOW_LOG,
  GET_WORKFLOW_LOG_SUCCESS,
  GET_WORKFLOW_LOG_FAILURE,
  GET_MORE_WORKFLOWS_SUCCESS,
  UPDATE_GCP_FILEPATH,
  EXPORT_WORKFLOW,
  EXPORT_WORKFLOW_SUCCESS,
  EXPORT_WORKFLOW_FAILURE,
  UPDATE_CLUSTER,
  UPDATE_CLUSTER_SUCCESS,
  UPDATE_CLUSTER_FAILURE,
  GET_PROVIDER_CONFIG,
  GET_PROVIDER_CONFIG_SUCCESS,
  GET_PROVIDER_CONFIG_FAILURE,
  APPLIED_CLUSTERS,
  IMPORT_CLUSTER,
  IMPORT_CLUSTER_SUCCESS,
  IMPORT_CLUSTER_FAILURE,
  GET_CLUSTER_PACKAGE,
  GET_CLUSTER_PACKAGE_FAILURE,
  GET_CLUSTER_PACKAGE_SUCCESS,
  GET_PACKAGE_STATUS,
  GET_PACKAGE_STATUS_FAILURE,
  GET_PACKAGE_STATUS_SUCCESS,
  INSTALL_CLUSTER_PACKAGE,
  INSTALL_CLUSTER_PACKAGE_FAILURE,
  INSTALL_CLUSTER_PACKAGE_SUCCESS,
  UNINSTALL_CLUSTER_PACKAGE,
  UNINSTALL_CLUSTER_PACKAGE_FAILURE,
  UNINSTALL_CLUSTER_PACKAGE_SUCCESS,
  ENABLE_DISABLE_CLUSTER,
  UPDATE_CLUSTER_REPO,
  UPDATE_CLUSTER_REPO_FAILURE,
  UPDATE_CLUSTER_REPO_SUCCESS,
  SET_CLUSTER_DNS,
  SET_CLUSTER_DNS_SUCCESS,
  SET_CLUSTER_DNS_FAILURE,
  VALIDATE_DNS_PERMISSION,
  VALIDATE_DNS_PERMISSION_SUCCESS,
  VALIDATE_DNS_PERMISSION_FAILURE,
  GET_CLUSTERENV_DETAILS,
  GET_CLUSTERENV_DETAILS_FAILURE,
  GET_CLUSTERENV_DETAILS_SUCCESS,
  SET_CLUSTER_STORAGE,
  SET_CLUSTER_STORAGE_FAILURE,
  SET_CLUSTER_STORAGE_SUCCESS,
  GET_CLUSTER_INSIGHTS,
  GET_CLUSTER_INSIGHTS_SUCCESS,
  GET_CLUSTER_INSIGHTS_FAILURE,
  UPDATE_CLUSTER_LABELS_AND_COLOR,
  UPDATE_CLUSTER_LABELS_AND_COLOR_SUCCESS,
  UPDATE_CLUSTER_LABELS_AND_COLOR_FAILURE,
  GET_PROVIDER_PERMISSIONS,
  GET_PROVIDER_PERMISSIONS_SUCCESS,
  CREATE_V_CLUSTER_START,
  CREATE_V_CLUSTER_SUCCESS,
  CREATE_V_CLUSTER_FAILURE,
  CREATE_V_CLUSTER_CLEAN_UP,
  FETCH_CLUSTER_LOG,
  FETCH_CLUSTER_LOG_SUCCESS,
  GET_SCAN_CLUSTER_PLUGIN,
  GET_SCAN_CLUSTER_PLUGIN_SUCCESS,
  GET_SCAN_CLUSTER_PLUGIN_FAILURE,
  SCANNER_CLUSTER_SCAN_SUCCESS,
  SCANNER_CLUSTER_SCAN_FAILURE,
  SCANNER_CLUSTER_SCAN,
  GET_CLUSTER_SCAN_REPORT_SUCCESS,
  GET_CLUSTER_SCAN_REPORT_FAILURE,
  GET_CLUSTER_SCAN_REPORT,
  GET_SPECIFIC_CCLUSTER_SCAN_REPORT_SUCCESS,
  GET_SPECIFIC_CCLUSTER_SCAN_REPORT_FAILURE,
  GET_SPECIFIC_CCLUSTER_SCAN_REPORT
} from './actions';
import { GET_DNS } from "../../organization/redux/actions"
import axios from 'axios';
import { sessionTokenSelector } from '../../login/redux/selectors';
import endpoints from '../../../constants/endpoints';
import toast from '../../../components/toast/Toast';

function* createCluster(payload) {
    try {
      const sessionToken = yield select(sessionTokenSelector)
      const config = {
        headers : {
          'Content-Type': 'application/json',
          'Authorization': 'basic ' + sessionToken
        }
      }
      const response = yield axios.post(endpoints.CLUSTER.CREATE_CLUSTER, payload.data.jsonBody, config);
      const data = response.data;
      if (data){   
        yield put({ type: CREATE_CLUSTER_SUCCESS, data })
        //toast.success('Cluster Creation In progress');
        //payload.data.history.push(data.organization.id > 0 ? "/organization/" + data.organization.id : "/projects" );
      } else {
        yield put({ type: CREATE_CLUSTER_FAILURE })
        toast.error('Some error while creating cluster. Please try again');
      }
    }
    catch (error) {
      yield put({ type: CREATE_CLUSTER_FAILURE })
      if(error && error.response && error.response.data && error.response.data.error)
        toast.error(error.response.data.error);
      else
        toast.error('Some error while creating cluster. Please try again');
    }
  }

  function* updateCluster(payload) {
    try {
      const sessionToken = yield select(sessionTokenSelector)
      const config = {
        headers : {
          'Content-Type': 'application/json',
          'Authorization': 'basic ' + sessionToken
        }
      }
      const response = yield axios.put(endpoints.CLUSTER.UPDATE_CLUSTER.replace(':id' , payload.data.id), payload.data.jsonBody, config);
      const data = response.data;
      if (data){   
        yield put({ type: UPDATE_CLUSTER_SUCCESS, data })
        if(payload.data.callback && payload.data.edit){
          payload.data.callback(data)
        }
      } else {
        yield put({ type: UPDATE_CLUSTER_FAILURE })
        toast.error('Some error while updating cluster. Please try again');
      }
    }
    catch (error) {
      yield put({ type: UPDATE_CLUSTER_FAILURE })
      if(error && error.response && error.response.data && error.response.data.error)
        toast.error(error.response.data.error);
      else
        toast.error('Some error while updating cluster. Please try again');
    }
  }
  
function* updateClusterRepo(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const config = {
      headers : {
        'Content-Type': 'multipart/form-data',
        'Authorization': 'basic ' + sessionToken
      }
    }
    const response = yield axios.put(endpoints.CLUSTER.UPDATE_CLUSTER_REPO.replace(':id' , payload.data.id), payload.data.jsonBody, config);
    const data = response.data;
    if (data){   
      yield call(getClusterDetails, { data: { id: payload.data.mainClusterId }} )
      yield put({ type: UPDATE_CLUSTER_REPO_SUCCESS })
      toast.success('Repository details updated successfully');
    } else {
      yield put({ type: UPDATE_CLUSTER_REPO_FAILURE })
      toast.error('Some error while updating repo details. Please try again');
    }
  }
  catch (error) {
    yield put({ type: UPDATE_CLUSTER_REPO_FAILURE })
    if(error && error.response && error.response.data && error.response.data.error)
      toast.error(error.response.data.error);
    else
      toast.error('Some error while updating repo details. Please try again');
  }
}
  
  function* validatePermission(payload) {
    try {
      const sessionToken = yield select(sessionTokenSelector)
      if(payload.data.uploadBody)
      {
        const config = {
          headers : {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'basic ' + sessionToken
          }
        }
        const uploadResponse = yield axios.post(endpoints.PROJECT.UPLOAD_ICON, payload.data.uploadBody, config);
        const fileData = uploadResponse.data;
        if (uploadResponse.status === 200 && fileData) {
          payload.data.jsonBody['credentials'] = fileData.path;
          yield put({ type: UPDATE_GCP_FILEPATH, data: fileData.path })
        } 
      }
      const config = {
        headers : {
          'Content-Type': 'application/json',
          'Authorization': 'basic ' + sessionToken
        }
      }
      const response = yield axios.post(endpoints.CLUSTER.CHECK_PERMISSION, payload.data.jsonBody, config);
      const data = response.data;
      if (data){   
        yield put({ type: VALIDATE_PERMISSION_SUCCESS, data })
      } else {
        yield put({ type: VALIDATE_PERMISSION_FAILURE })
        toast.error('Unable to validate. Please try again');
      }
    }
    catch (error) {
      yield put({ type: VALIDATE_PERMISSION_FAILURE })
      if(error && error.response && error.response.data && error.response.data.error)
        toast.error(error.response.data.error);
      else
        toast.error('Unable to validate. Please try again');
    }
  }

  function* validateDnsPermission(payload) {
    try {
      const sessionToken = yield select(sessionTokenSelector)
      if(payload.data.uploadBody)
      {
        const config = {
          headers : {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'basic ' + sessionToken
          }
        }
        const uploadResponse = yield axios.post(endpoints.PROJECT.UPLOAD_ICON, payload.data.uploadBody, config);
        const fileData = uploadResponse.data;
        if (uploadResponse.status === 200 && fileData) {
          payload.data.jsonBody['credentials'] = fileData.path;
          yield put({ type: UPDATE_GCP_FILEPATH, data: fileData.path })
        } 
      }
      
      const config = {
        headers : {
          'Content-Type': 'application/json',
          'Authorization': 'basic ' + sessionToken
        }
      }
      const response = yield axios.post(endpoints.CLUSTER.CHECK_DNS_PERMISSION, payload.data.jsonBody, config);
      const data = response.data;
      if (data){   
        yield put({ type: VALIDATE_DNS_PERMISSION_SUCCESS, data })
      } else {
        yield put({ type: VALIDATE_DNS_PERMISSION_FAILURE })
        toast.error('Unable to validate. Please try again');
      }
    }
    catch (error) {
      yield put({ type: VALIDATE_DNS_PERMISSION_FAILURE })
      if(error && error.response && error.response.data && error.response.data.error)
        toast.error(error.response.data.error);
      else
        toast.error('Unable to validate. Please try again');
    }
  }

 function* getClusterList() {
   try {
     const sessionToken = yield select(sessionTokenSelector)
     const config = { headers : { 'Authorization': 'basic ' + sessionToken } }
     const response = yield axios.get(endpoints.CLUSTER.GET_CLUSTER_LIST, config)

     const data = response.data;
     if (data) {
       yield put({ type: GET_CLUSTER_LIST_SUCCESS, data })
     } else {
       yield put({ type: GET_CLUSTER_LIST_FAILURE })
     }
   } catch (error) {
    yield put({ type: GET_CLUSTER_LIST_FAILURE }) 
    if(error && error.response && error.response.data && error.response.data.error){
      toast.error(error.response.data.error);
     }
   }
 }

 function* getClusterDetails(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const config = { headers : { 'Authorization': 'basic ' + sessionToken } }
    const response = yield axios.get(endpoints.CLUSTER.GET_CLUSTER.replace(':id' , payload.data.id), config)

    const data = response.data;
    if (data) {
      yield put({ type: GET_CLUSTER_DETAILS_SUCCESS, data })
    } else {
      yield put({ type: GET_CLUSTER_DETAILS_FAILURE })
    }
  } catch (error) {
   yield put({ type: GET_CLUSTER_DETAILS_FAILURE }) 
    if(error && error.response && error.response.data && error.response.data.error){
     toast.error(error.response.data.error);
    }
  }
}

function* deleteCluster(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const config = {
      headers : {
        'Content-Type': 'application/json',
        'Authorization': 'basic ' + sessionToken
      }
    }
    //const response =
      yield axios.delete(endpoints.CLUSTER.DELETE_CLUSTER.replace(':id', payload.data.id), config)
    //const data = response.data;
    toast.success('Cluster delete successful');
    yield put({ type: DELETE_CLUSTER_SUCCESS })
    yield call(getClusterList)
  } catch (error) {
    yield put({ type: DELETE_CLUSTER_FAILURE }) 
    if(error && error.response && error.response.data && error.response.data.error){
     toast.error(error.response.data.error);
    }
  }
}

function* destroyCluster(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const config = {
      headers : {
        //'Content-Type': 'application/json',
        'Authorization': 'basic ' + sessionToken
      }
    }
    //const response =
      yield axios.delete(endpoints.CLUSTER.DESTROY_CLUSTER.replace(':id', payload.data.id), config)
    //const data = response.data;
    toast.success('Cluster destroy triggered');
    yield put({ type: DESTROY_CLUSTER_SUCCESS })
    yield call(getClusterList)
  } catch (error) {
    yield put({ type: DESTROY_CLUSTER_FAILURE }) 
    if(error && error.response && error.response.data && error.response.data.error){
     toast.error(error.response.data.error);
    }
  }
}

function* applyTerraform(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const config = { headers : { 'Authorization': 'basic ' + sessionToken } }
    const response = yield axios.get(endpoints.CLUSTER.APPLY_TERRAFORM.replace(':id' , payload.data.id), config)

    //const data = response.data;
    if (response.status === 200) {
      yield put({ type: APPLY_TERRAFORM_SUCCESS })
      yield put ({ type: APPLIED_CLUSTERS, data: payload.data.id })
      toast.success('Cluster apply triggered');
      if (payload.data.history)
        payload.data.history.push('/organization/' + payload.data.orgId + '#admin' );
    } else {
      yield put({ type: APPLY_TERRAFORM_FAILURE })
    }
  } catch (error) {
   yield put({ type: APPLY_TERRAFORM_FAILURE }) 
    if(error && error.response && error.response.data && error.response.data.error){
     toast.error(error.response.data.error);
    }
  }
}

function* getWorkflows(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const config = { headers : { 'Authorization': 'basic ' + sessionToken } }
    const response = yield axios.get(endpoints.CLUSTER.GET_WORKFLOWS.replace(':id' , payload.data.id)
      .replace(':page', payload.data.pageNo)
      .replace(':limit', payload.data.pageSize), config);

    const data = response.data;
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      if (payload.data.pageNo === 1)
          yield put({ type: GET_WORKFLOWS_SUCCESS, data: data.data ?? [] });
      else
        yield put({ type: GET_MORE_WORKFLOWS_SUCCESS, data: data.data ?? [] });
    } else {
      yield put({ type: GET_WORKFLOWS_FAILURE })
    }
  } catch (error) {
   yield put({ type: GET_WORKFLOWS_FAILURE }) 
    if(error && error.response && error.response.data && error.response.data.error){
     toast.error(error.response.data.error);
    }
  }
}

function* cancelWorkflow(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const config = { headers : { 'Authorization': 'basic ' + sessionToken } }
    const response = yield axios.get(endpoints.CLUSTER.CANCEL_WORKFLOW.replace(':id' , payload.data.id).replace(':workflow', payload.data.workflow), config);

    const data = response.data;
    if (response.status === 200 || response.status === 201) {
          yield put({ type: CANCEL_WORKFLOW_SUCCESS });
          toast.success(data.message ?? 'Workflow cancellation initiated')
    } 
    else {
      yield put({ type: CANCEL_WORKFLOW_FAILURE })
      toast.error(data.message ?? 'Workflow cancellation failed')

    }
  } catch (error) {
   yield put({ type: CANCEL_WORKFLOW_FAILURE }) 
    if(error && error.response && error.response.data && error.response.data.error){
     toast.error(error.response.data.error);
    }
  }
}

function* getWorkflowLog(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const config = { headers : { 'Authorization': 'basic ' + sessionToken } }
    const response = yield axios.get(endpoints.CLUSTER.GET_WORKFLOW_LOG.replace(':id' , payload.data.id).replace(':workflow-name', payload.data.workflowName), config)

    const data = response.status === 204 && !payload.data.noEmptyCheck ? { Name: payload.data.workflowName } : response.data;
    if (data) {
      yield put({ type: GET_WORKFLOW_LOG_SUCCESS, data })
    } else {
      yield put({ type: GET_WORKFLOW_LOG_FAILURE })
    }
  } catch (error) {
   yield put({ type: GET_WORKFLOW_LOG_FAILURE }) 
    if(error && error.response && error.response.data && error.response.data.error){
     toast.error(error.response.data.error);
    }
  }
}

function* exportWorkflow(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const config = { headers : { 'Authorization': 'basic ' + sessionToken } }
    const response = yield axios.get(endpoints.CLUSTER.DOWNLOAD_TF_FILES.replace(':id' , payload.data.id), config);

    const data = response.data;
    if (data) {
      yield put({ type: EXPORT_WORKFLOW_SUCCESS, data });
    } else {
      yield put({ type: EXPORT_WORKFLOW_FAILURE })
    }
  } catch (error) {
    yield put({ type: EXPORT_WORKFLOW_FAILURE }) 
    if(error && error.response && error.response.data && error.response.data.error){
     toast.error(error.response.data.error);
    }
  }
}

function* getProviderConfig(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const config = { headers : { 'Authorization': 'basic ' + sessionToken } }
    const response = yield axios.get(endpoints.CLUSTER.GET_PROVIDER_CONFIG.replace(':provider' , payload.data.provider), config);

    const data = response.data;
    if (data) {
      yield put({ type: GET_PROVIDER_CONFIG_SUCCESS, data });
    } else {
      yield put({ type: GET_PROVIDER_CONFIG_FAILURE })
    }
  } catch (error) {
    yield put({ type: GET_PROVIDER_CONFIG_FAILURE }) 
    if(error && error.response && error.response.data && error.response.data.error){
     toast.error(error.response.data.error);
    }
  }
}

function* importCluster(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    if(payload.data.formData)
    {
      const config = {
        headers : {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'basic ' + sessionToken
        }
      }
      const response = yield axios.post(endpoints.CLUSTER.IMPORT_CLUSTER, payload.data.formData, config);
      const data = response.data;
      if (data){   
        yield put({ type: IMPORT_CLUSTER_SUCCESS, data })
        if(payload.data.callback)
        {
          payload.data.callback(data);
        }
      } else {
        yield put({ type: IMPORT_CLUSTER_FAILURE })
        //toast.error('Unable to validate. Please try again');
      }
    }
  }
  catch (error) {
    yield put({ type: IMPORT_CLUSTER_FAILURE })
    if(error && error.response && error.response.data && error.response.data.error)
      toast.error(error.response.data.error);
    else
      toast.error('Unable to import Cluster. Please try again');
  }
}

function* getClusterPackage() {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const config = { headers : { 'Authorization': 'basic ' + sessionToken } }
    const response = yield axios.get(endpoints.CLUSTER.GET_CLUSTER_PACKAGE, config);

    const data = response.data;
    if (data) {
      yield put({ type: GET_CLUSTER_PACKAGE_SUCCESS, data });
    } else {
      yield put({ type: GET_CLUSTER_PACKAGE_FAILURE })
    }
  } catch (error) {
    yield put({ type: GET_CLUSTER_PACKAGE_FAILURE }) 
    if(error && error.response && error.response.data && error.response.data.error){
     toast.error(error.response.data.error);
    }
  }
}

function* getPackageStatus(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const config = { headers : { 'Authorization': 'basic ' + sessionToken } }
    const response = yield axios.get(endpoints.CLUSTER.GET_PACKAGE_STATUS.replace(':id', payload.data.id), config);

    const data = response.data;
    if (response.status === 200) {
      yield put({ type: GET_PACKAGE_STATUS_SUCCESS, data });
    } else {
      yield put({ type: GET_PACKAGE_STATUS_FAILURE })
    }
  } catch (error) {
    yield put({ type: GET_PACKAGE_STATUS_FAILURE }) 
    if(error && error.response && error.response.data && error.response.data.error){
     toast.error(error.response.data.error);
    }
  }
}

function* installPackage(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const config = { headers : { 'Authorization': 'basic ' + sessionToken } }
    const response = yield axios.post(endpoints.CLUSTER.INSTALL_CLUSTER_PACKAGE.replace(':id', payload.data.id), payload.data.packages, config);
      yield put({ type: INSTALL_CLUSTER_PACKAGE_SUCCESS });
      toast.success(response.message ?? 'Package installation initiated');
      payload.data.callback()
  } catch (error) {
    yield put({ type: INSTALL_CLUSTER_PACKAGE_FAILURE }) 
    if(error && error.response && error.response.data && error.response.data.error){
     toast.error(error.response.data.error);
    }
  }
}

function* uninstallPackage(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const config = { headers : { 'Authorization': 'basic ' + sessionToken } }
    const response = yield axios.post(endpoints.CLUSTER.UNINSTALL_CLUSTER_PACKAGE.replace(':id', payload.data.id), payload.data.packages, config);
      yield put({ type: UNINSTALL_CLUSTER_PACKAGE_SUCCESS });
      toast.success(response.message ?? 'Package uninstallation initiated');
      payload.data.callback()
  } catch (error) {
    yield put({ type: UNINSTALL_CLUSTER_PACKAGE_FAILURE }) 
    if(error && error.response && error.response.data && error.response.data.error){
     toast.error(error.response.data.error);
    }
  }
}

function* enableDisableCluster(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const config = { headers : { 'Authorization': 'basic ' + sessionToken } }
    //const response =
      yield axios.post(endpoints.CLUSTER.ENABLE_DISABLE_CLUSTER.replace(':id', payload.data.id), payload.data.jsonBody, config);

    //const data = response.data;
    yield call(getClusterList);
    toast.success('Cluster successfully ' + (payload.data.jsonBody.active ? 'enabled' : 'disabled'));
  } catch (error) {
    // yield put({ type: UNINSTALL_CLUSTER_PACKAGE_FAILURE }) 
    if(error && error.response && error.response.data && error.response.data.error){
      toast.error(error.response.data.error);
    }
  }
}

function* setupClusterDNS(payload) { 
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = {
      headers : {
        'Content-Type': 'multipart/form-data',
        'Authorization': 'basic ' + sessionToken
      }
    }
    const response = yield axios.put(endpoints.CLUSTER.UPDATE_CLUSTER_REPO.replace(':id' , payload.data.id), payload.data.jsonBody, config);
    const data = response.data;
    if (data) {   
      yield call(getClusterDetails, { data: { id: payload.data.mainClusterId }} );
      yield put({type: GET_DNS});
      toast.success('DNS details setup successfully');
      yield put({ type: SET_CLUSTER_DNS_SUCCESS })
      if(payload.data.callback)
      {
        payload.data.callback(data);
      }
    } else {
      yield put({ type: SET_CLUSTER_DNS_FAILURE })
      toast.error('Unable to update Cluster DNS. Please try again');
    }
  }
  catch (error) {
    yield put({ type: SET_CLUSTER_DNS_FAILURE })
    if(error && error.response && error.response.data && error.response.data.error)
      toast.error(error.response.data.error);
    else
      toast.error('Unable to update Cluster DNS. Please try again');
  }
}

function* getClusterEnvDetails(payload) { 
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const config = { headers : { 'Authorization': 'basic ' + sessionToken } }
    const response = yield axios.get(endpoints.GET_ENVIRONMENT_IN_CLUSTER.replace(':id', payload.id), config);
    
    const data = response.data;

    if (data) {
      yield put({ type: GET_CLUSTERENV_DETAILS_SUCCESS, data });
    } else {
      yield put({ type: GET_CLUSTERENV_DETAILS_FAILURE })
    }
  } catch (error) {
    yield put({ type: GET_CLUSTERENV_DETAILS_FAILURE }) 
    if(error && error.response && error.response.data && error.response.data.error){
     toast.error(error.response.data.error);
    }
  }
}
function* createClusterStorage(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);

    if (payload.data.uploadBody)
    {
      const config = {
        headers : {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'basic ' + sessionToken
        }
      }
      const uploadResponse = yield axios.post(endpoints.PROJECT.UPLOAD_ICON, payload.data.uploadBody, config);
      const fileData = uploadResponse.data;
      if (uploadResponse.status === 200 && fileData) {
        payload.data.jsonBody['credentials'] = fileData.path;
      } 
    }
    const _config = {
      headers : {
        'Content-Type': 'application/json',
        'Authorization': 'basic ' + sessionToken
      }
    }
    const response = yield axios.post(endpoints.CLUSTER.CREATE_STORAGE.replace(":cluster_id", payload.data.id), payload.data.jsonBody, _config);
    if(response.data) {
      yield put({type: GET_CLUSTER_DETAILS, data: {
        id: payload.data.clusterId
      }})
      toast.success("Storage added successfully!")
      yield put({type: SET_CLUSTER_STORAGE_SUCCESS, data: response.data});
      if(payload.data.callback) payload.data.callback();
    }
  } catch(error) {
    toast.error(error?.response?.data?.error);
    yield put({type: SET_CLUSTER_STORAGE_FAILURE});
  }
}

function* getClusterInsights(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const config = { headers : { 'Authorization': 'basic ' + sessionToken } }
    const response = yield axios.post(endpoints.CLUSTER.GET_CLUSTER_INSIGHTS.replace(':cluster_id', payload.data.id), payload.data.jsonBody, config);

    const data = response.data;
    if (response.status === 200) {
      yield put({ type: GET_CLUSTER_INSIGHTS_SUCCESS, data });
    }
  } catch (error) {
    if(error && error.response && error.response.data && error.response.data.error){
     toast.error(error.response.data.error);
    }
    yield put({type: GET_CLUSTER_INSIGHTS_FAILURE});
  }
}

function* updateClusterLabelsAndColor(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = { headers: { Authorization: "basic " + sessionToken } };
    const response = yield axios.put(
      endpoints.CLUSTER.UPDATE_CLUSTER_COLOR_AND_LABELS.replace(
        ":id",
        payload.data.clusterId
      ),
      payload.data.jsonBody,
      config
    );

    if (response.status === 200) {
      yield put({ type: UPDATE_CLUSTER_LABELS_AND_COLOR_SUCCESS });
      yield put({
        type: GET_CLUSTER_DETAILS,
        data: { id: payload.data.clusterRequestId },
      });
      toast.success("Labels and color updated successfully!");
    }
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    ) {
      toast.error(error.response.data.error);
    }
    yield put({ type: UPDATE_CLUSTER_LABELS_AND_COLOR_FAILURE });
  }
}

function* getProviderPermissions(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const config = { headers: { 'Authorization': 'basic ' + sessionToken } }
    const response = yield axios.get(endpoints.CLUSTER.GET_PROVIDER_PERMISSIONS.replace(':provider', payload.data.provider), config);

    const data = response.data;
    if (data) {
      yield put({ type: GET_PROVIDER_PERMISSIONS_SUCCESS, data });
    } 
  } catch (error) {
    // if (error && error.response && error.response.data && error.response.data.error) {
    //   toast.error(error.response.data.error);
    // }
  }
}

function* createVCluster(payload){
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const config = { headers : { 'Authorization': 'basic ' + sessionToken } }
    const response = yield axios.post(endpoints.CLUSTER.CREATE_V_CLUSTER, payload.data, config);
    if (response.status === 201) {
      yield put({ type: CREATE_V_CLUSTER_SUCCESS });
      if(response.data){
        yield put({ type: CREATE_CLUSTER_SUCCESS, data:response.data })
      }
      toast.success('Operation successful');
      yield put({type:CREATE_V_CLUSTER_CLEAN_UP})
    }
  } catch (error) {
    if(error && error.response && error.response.data && error.response.data.error){
     toast.error(error.response.data.error);
    }
    yield put({type: CREATE_V_CLUSTER_FAILURE});
    yield put({type:CREATE_V_CLUSTER_CLEAN_UP})
  }

}

function* fetchClusterLogs(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = {
      headers: {
        Authorization: "basic " + sessionToken,
      },
    };

    const response = yield axios.get(
      endpoints.CLUSTER.GET_CLUSTER_LOG.replace(":id", payload.data.id)
        .replace(":workflow-name", payload.data.workflowName)
        .replace(":task", payload.data.task)
        .replace(":step", payload.data.step),
      config
    );
    const data = {
      name: payload.data.workflowName,
      task: payload.data.task,
      step: payload.data.step,
      log: response.data
    };

    yield put({ type: FETCH_CLUSTER_LOG_SUCCESS, data });

  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    )
      toast.error(error.response.data.error);
  }
}

function* getScanClusterPlugin() {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const config = { headers : { 'Authorization': 'basic ' + sessionToken } }
    const response = yield axios.get(endpoints.CLUSTER.CLUSTER_SCANNER_PLUGINS, config)

    const data = response.data;
    if (data) {
      yield put({ type: GET_SCAN_CLUSTER_PLUGIN_SUCCESS, data: data })
    } else {
      yield put({ type: GET_SCAN_CLUSTER_PLUGIN_FAILURE })
    }
  } catch (error) {
   yield put({ type: GET_SCAN_CLUSTER_PLUGIN_FAILURE }) 
   if(error && error.response && error.response.data && error.response.data.error){
     toast.error(error.response.data.error);
    }
  }
}

function* handleScanningCluster(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = { headers: { 'Authorization': 'basic ' + sessionToken } };

    const requestBody = { name: payload.data.clusterName };

    const response = yield call(
      axios.post,
      endpoints.CLUSTER.CLUSTER_SCANNER_SCAN.replace(':id', payload.data.clusterId.toString()),
      requestBody,
      config
    );

    const data = response.data;
    console.log(data);

    if (data) {
      yield put({ type: SCANNER_CLUSTER_SCAN_SUCCESS, data });
    } else {
      yield put({ type: SCANNER_CLUSTER_SCAN_FAILURE });
    }
  } catch (error) {
    yield put({ type: GET_SCAN_CLUSTER_PLUGIN_FAILURE });
    if (error && error.response && error.response.data && error.response.data.error) {
      toast.error(error.response.data.error);
    }
  }
}

function* getClusterScaningReport(payload){
  try{
    const sessionToken = yield select(sessionTokenSelector);

    const config = { headers: { 'Authorization': 'basic ' + sessionToken } };

    const response = yield call(
      axios.get,
      endpoints.CLUSTER.GET_CLUSTER_SCAN_REPOART.replace(':id', payload.data.clusterId.toString()),
      config
    );

    const data = response.data;
    if (data) {
      yield put({ type: GET_CLUSTER_SCAN_REPORT_SUCCESS, data });
    } else {
      yield put({ type: GET_CLUSTER_SCAN_REPORT_FAILURE });
    }
  }catch (error){
    yield put({ type: GET_CLUSTER_SCAN_REPORT_FAILURE });
    if (error && error.response && error.response.data && error.response.data.error) {
      toast.error(error.response.data.error);
    }
  }
}

function specificReportApicall(sessionToken, payload) {
  const config = {
    headers : {
      'Content-Type': 'application/json',
      'Authorization': 'basic ' + sessionToken,
    }
  }
  return axios.get(endpoints.CLUSTER.GET_SPECIFIC_CLUSTER_RERPORT.replace(":cId", payload.data.cId).replace(":reportId", payload.data.listId), config)
} 

function* fetchSpecificClusterReport(payload) {
  try{
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(specificReportApicall, sessionToken, payload);
    const data = response.data;
    if(data !== null) {
      yield put({type: GET_SPECIFIC_CCLUSTER_SCAN_REPORT_SUCCESS, data});
    }
  }catch(error){
    yield put({ type: GET_SPECIFIC_CCLUSTER_SCAN_REPORT_FAILURE});
    if (error && error.response && error.response.data && error.response.data.error) {
      toast.error(error.response.data.error);
    }
  }
}

export default function* watcherSaga() {
    yield takeLatest(CREATE_CLUSTER, createCluster);
    yield takeLatest(UPDATE_CLUSTER, updateCluster);
    yield takeLatest(VALIDATE_PERMISSION, validatePermission);
    yield takeLatest(GET_CLUSTER_LIST, getClusterList);
    yield takeLatest(GET_CLUSTER_DETAILS, getClusterDetails);
    yield takeLatest(DELETE_CLUSTER, deleteCluster);
    yield takeLatest(DESTROY_CLUSTER, destroyCluster);
    yield takeLatest(APPLY_TERRAFORM, applyTerraform);
    yield takeLatest(GET_WORKFLOWS, getWorkflows);
    yield takeLatest(CANCEL_WORKFLOW, cancelWorkflow);
    yield takeLatest(GET_WORKFLOW_LOG, getWorkflowLog);
    yield takeLatest(GET_CLUSTER_PACKAGE, getClusterPackage)
    yield takeLatest(GET_PACKAGE_STATUS, getPackageStatus)
    yield takeLatest(INSTALL_CLUSTER_PACKAGE, installPackage)
    yield takeLatest(UNINSTALL_CLUSTER_PACKAGE, uninstallPackage)
    yield takeLatest(EXPORT_WORKFLOW, exportWorkflow);
    yield takeLatest(GET_PROVIDER_CONFIG, getProviderConfig)
    yield takeLatest(IMPORT_CLUSTER, importCluster)
    yield takeLatest(ENABLE_DISABLE_CLUSTER, enableDisableCluster)
    yield takeLatest(UPDATE_CLUSTER_REPO, updateClusterRepo)
    yield takeLatest(SET_CLUSTER_DNS, setupClusterDNS)
    yield takeLatest(SET_CLUSTER_STORAGE, createClusterStorage)
    yield takeLatest(VALIDATE_DNS_PERMISSION, validateDnsPermission)
    yield takeLatest(GET_CLUSTERENV_DETAILS, getClusterEnvDetails)
    yield takeLatest(GET_CLUSTER_INSIGHTS, getClusterInsights)
    yield takeLatest(UPDATE_CLUSTER_LABELS_AND_COLOR, updateClusterLabelsAndColor)
    yield takeLatest(GET_PROVIDER_PERMISSIONS, getProviderPermissions)
    yield takeLatest(CREATE_V_CLUSTER_START,createVCluster)
    yield takeLatest(FETCH_CLUSTER_LOG, fetchClusterLogs)
    yield takeLatest(GET_SCAN_CLUSTER_PLUGIN, getScanClusterPlugin);
    yield takeLatest(SCANNER_CLUSTER_SCAN,handleScanningCluster);
    yield takeLatest(GET_CLUSTER_SCAN_REPORT,getClusterScaningReport);
    yield takeLatest(GET_SPECIFIC_CCLUSTER_SCAN_REPORT,fetchSpecificClusterReport)
}
