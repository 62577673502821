import React, { useEffect } from "react"
import { Step, StepButton, Typography, ButtonBase } from '@material-ui/core';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Stepper from '@material-ui/core/Stepper';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import ResourceCatalogContainer from "./resources/ResourceCatalogContainer";
import { fetchAppDetails, fetchAppAvailableResource } from '../app/redux/actions'
import { fetchOperatorDetails,clearOperatorDetails} from "./redux/actions"
//import { fetchOperatorConfig } from "./redux/actions"
import Configure from "./configure/Configure"
import BackdropLoader from "../../components/loader/BackdropLoader";
import { Dispatch } from "redux";
import { RouteComponentProps } from 'react-router-dom';
import { History } from 'history';

interface MatchParams {
    appId: string;
}

interface Props extends PropsFromRedux, RouteComponentProps<MatchParams> {
    history:History;
}

export const CreateOperatorEnvironment = (props:Props) => {
    const [t] = useTranslation()
    const appId = props.match.params.appId

    const [activeStep, setActiveStep] = React.useState(0)
    const [completeData, setCompleteData] = React.useState<any>({})
    const [selectedResource, setSelectedResource] = React.useState<any>(null)
    
    //const [resourceName, setResourceName] = React.useState()

    useEffect(() => {
        props.fetchAppDetails(appId)
        props.fetchAppAvailableResource(appId)
    }, [])

    useEffect(()=>{
        if(props.appDetails?.operator_package_name ){
            props.fetchOperatorDetails({packageName: props.appDetails.operator_package_name});
        }
       return () =>{
           props.clearOperatorDetails()
       }
    },[props.appDetails])

    const steps = [
        { label: 'Resource', isDisabled: false },
        { label: 'Configure', isDisabled: false }
    ]

    const handleStepChange = (stepNumber:number, data = {}) => {
        const Data = { ...completeData, ...data }
        setActiveStep(stepNumber + 1)
        setCompleteData(Data)
        //props.fetchOperatorConfig(resourceName)

    }

    const handleStep = (step:number) => {
        setActiveStep(step)
    };

    return (

        <div data-test="main-container">
            <ButtonBase
                onClick={() => props.history.goBack()}
                style={{ color: "#357dfd", marginBottom: 20 }}
                data-test="backButton"
            >
                <ArrowBackIcon fontSize="small" />{" "}
                <span className="jobNav">{t('App.CreateApp.back')}</span>
            </ButtonBase>
            {activeStep === 0 && <Typography variant="h4" color="primary" align="center"> Install Resource </Typography>}
            {activeStep === 1 && <Typography variant="h4" color="primary" align="center"> Install Custom Resource </Typography>}
            <Stepper style={{ background: '#f6f8fa', paddingLeft: '0px' }} activeStep={activeStep} alternativeLabel>
                {steps.map((item, index) => (
                    <Step key={item.label} disabled={item.isDisabled || activeStep <= index} data-test="step">
                        <StepButton onClick={() => handleStep(index)} data-test={`step-btn-${index}`}>
                            {item.label}
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
            <ResourceCatalogContainer activeStep={activeStep} cardMd={3} handleStepChange={handleStepChange} data-test="resource-step" operatorDetails= {props.operatorDetails} setSelectedResource={setSelectedResource} selectedResource={selectedResource} />

            <Configure activeStep={activeStep} setActiveStep={setActiveStep} operatorDetails= {props.operatorDetails} completeData={completeData} setCompleteData={setCompleteData} history={props.history}  data-test="configure-step" />
            
            {props.creatingEnv && (
                <BackdropLoader message={t('Environment.Resources.creatingEnv')} />
            )}
        </div>

    )
}
/* istanbul ignore next */
const mapStateToProps = (state:any) => ({
    operatorDetails: state.OperatorReducer.operatorDetails,
    appDetails: state.AppsReducer.appDetails,
    creatingEnv: state.EnvironmentReducer.creatingEnv,
})

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch:Dispatch) => {
    return {
        fetchAppDetails: (payload:string) => dispatch(fetchAppDetails(payload)),
        //fetchOperatorConfig: (name) => dispatch(fetchOperatorConfig(name)),
        fetchAppAvailableResource: (id:string) => dispatch(fetchAppAvailableResource(id)),
        fetchOperatorDetails: ({packageName}:{packageName:string}) => dispatch(fetchOperatorDetails({packageName})),
        clearOperatorDetails: () => dispatch(clearOperatorDetails()),

    }
}

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(CreateOperatorEnvironment);