import { CssBaseline, Grid, Paper, Typography, IconButton, Theme, createStyles, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import CompanyBanner from '../../components/companybanner/CompanyBanner';
import { verifyemail } from '../registration/redux/actions';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import './registration.css';
//import queryString from 'query-string';
import { Dispatch } from 'redux';

/* istanbul ignore next */
const useStyles = (theme: Theme) => createStyles({
  root: {
    height: '100vh',
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  paper: {
    margin: theme.spacing(9, 6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft : '15%',
    paddingRight : '15%',
    [ theme.breakpoints.down('sm') ]: {
      paddingLeft : '2.5%',
      paddingRight : '2.5%',
      margin: theme.spacing(10, 6),
    },
  },
});

interface Props extends RouteComponentProps, PropsFromRedux, WithStyles, WithTranslation {}

export class VerifyEmail extends Component<Props> {

    componentDidMount(){
      if(this.props.location && this.props.location.search)
      {
          const params = new URLSearchParams(this.props.location.search);
          //let params = queryString.parse(this.props.location.search)
        if (params && params.has("token")) {
          const _token = params.get("token");
          if (_token)
            this.props.verifyemail(_token.toString());
        }
      }
    }

  render () {
    const { classes, t } = this.props;
    return (
        <Grid container component="main" className={ classes.root } data-test="main-container">
            <CssBaseline />
            <CompanyBanner />
            <Grid item xs={ 12 } sm={ 8 } md={ 6 } component={ Paper } elevation={ 6 } square>
                <div className={classes.contentHeader}>
                    <Link to="/" className='textDecorationNone' data-test="back-arrow">
                        <IconButton >
                            <ArrowBackIcon />
                        </IconButton>
                    </Link>
                </div>
                <div className={ classes.paper }>
                    <img src="/images/logos/logo-blue.svg" alt="01Cloud" className='authlogo' data-test="logo-image"/>
                    {
                        this.props.emailVerifyDetails && (!this.props.emailVerifyDetails.message || this.props.emailVerifyDetails.message === "") &&  
                        <Typography align='center' variant="h5" data-test="verifying-text">
                            {t('Registration.VerifyEmail.verifyingEmail')}
                        </Typography>
                    }
                    {
                        this.props.emailVerifyDetails && this.props.emailVerifyDetails.message &&  
                        <Typography align='center' variant="h5" color={ this.props.emailVerifyDetails.user ? "textPrimary" : "error"} data-test="verify-message">
                            { this.props.emailVerifyDetails.message }
                        </Typography>
                    }

                </div>
                {/* <div className='signInDiv'>
                    <Link to="/" variant="body2" className='textDecorationNone'>
                        <Typography variant="body1" className='signInLink'>{t('ReturnToSignIn')}</Typography>
                    </Link>
                </div> */}
            </Grid>
        </Grid>
    )
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
    emailVerifyDetails: state.RegisterReducer.emailVerifyDetails
})

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    verifyemail : (token: string) => dispatch(verifyemail(token)),
  }
}

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(
  withStyles(useStyles)(withTranslation()(VerifyEmail))
);