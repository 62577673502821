// import { ButtonBase } from '@material-ui/core'
import React from 'react'
import { connect,ConnectedProps } from 'react-redux'
import ChartCatalogContainer from '../../../components/chartcatalog/ChartCatalogContainer'
// import ArrowBackIcon from "@material-ui/icons/ArrowBack";
// import { useTranslation } from 'react-i18next';
import paths from '../../../constants/paths';
import BackButton from '../../../components/button/BackButton';
 import { StaticContext,RouteComponentProps } from 'react-router';
 import { CatalogModel } from '../../../models/HelmCharts.model';
 type Props = PropsFromRedux & RouteComponentProps<{pId:string,tType:string,source:string}, StaticContext> 
export const CatalogSelection = (props:Props) => {
    // const [t] = useTranslation()

    const handleCatalogClick = (catalog:CatalogModel) => {
        props.history.push({
            pathname: paths.CREATEAPP_SELECTREGION
                .replace(':pId', props.match.params.pId)
                .replace(':tType', props.match.params.tType)
                .replace(':source', props.match.params.source)
                .replace(':plugId', catalog.id),
        });
    }
    return (
        <div data-test="main-container">
            <BackButton
                clickHandler={() => props.history?.goBack()}
                data-test='back-button'
            /> 
            <ChartCatalogContainer title="Helm Charts" cardMd={3} onClick={handleCatalogClick}  data-test="helm-catalog"/>
        </div>
    )
}

/* istanbul ignore next */
const mapStateToProps = () => ({

})

/* istanbul ignore next */
const mapDispatchToProps = {

}
const connector=connect(mapStateToProps, mapDispatchToProps)
export default connector(CatalogSelection)
type PropsFromRedux = ConnectedProps<typeof connector>;