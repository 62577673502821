import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@material-ui/core";
import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import MuiTextField from "../textfield/MuiTextField";
import { validateInputField } from "../../helpers/utils";
import { useTranslation } from 'react-i18next';

type Props = {
    openRenameDialog: boolean,
    handleCloseDialog:() => void,
    envName: string,
    handleNameSubmit:(envName: string) => void
}

function EnvRenameDialog(props: Props) {
    const [t] = useTranslation();

    const [envName, setEnvName] = useState(props.envName);
    const [isEnvRenameFieldError, setIsEnvRenameFieldError] = useState(false);
    const [EnvNameFieldErrorMessage, setEnvNameFieldErrorMessage] = useState("");

    const handleUpdateEnvName = (e: React.ChangeEvent<{ value: string }>) => {
        let validation = validateInputField(e.target.value)
        setEnvName(e.target.value);
        setIsEnvRenameFieldError(validation.error);
        setEnvNameFieldErrorMessage(validation.error ? t(validation.message) : "")
      }

    const handleEnvNameOnBlur = () => {    
        if (envName.length === 0) {
            setIsEnvRenameFieldError(true);
            setEnvNameFieldErrorMessage("Environment name can't be empty");
        }
    }

  return (
    <div>
      <Dialog
        open={props.openRenameDialog}
        disableEscapeKeyDown={true}
        onClose={() => {
          props.handleCloseDialog();
        }}
        //onClose={ this.handleClose }
        keepMounted
        data-test="main-container"
      >
        <DialogTitle>
          <Typography className="dialogtitle">
            Update Environment Name
          </Typography>

          <IconButton
            aria-label="close"
            size="small"
            className="right"
            onClick={() => props.handleCloseDialog()}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent style={{ minWidth: 100 }} dividers>
          <MuiTextField
            label="Environment Name"
            error={isEnvRenameFieldError}
            helperText={EnvNameFieldErrorMessage}
            value={envName}
            required
            autoFocus
            onChange={(e: React.ChangeEvent<{ value: string }>) =>
            handleUpdateEnvName(e)
            }
            onBlur={handleEnvNameOnBlur}
          />
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => props.handleNameSubmit(envName)}
            color="primary"
            variant="contained"
            disabled={isEnvRenameFieldError}
          >
            Rename
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EnvRenameDialog;
