import Register from './Registration'
import {
    actions as RegisterActions,
    initialState as RegisterInitialState,
    reducer as RegisterReducer,
    Sagas as RegisterSaga,
} from './redux'

export default Register
export {
    RegisterActions,
    RegisterInitialState,
    RegisterReducer,
    RegisterSaga
}