import { call, takeLatest, select, put } from "redux-saga/effects";
import axios from "axios";
import endpoints from "../../../constants/endpoints";
import toast from "../../../components/toast/Toast";
import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  GETPROFILE,
  FETCH_PROFILE_SUCCESS,
  LOGIN_OAUTH,
  RESEND_VERIFICATION_MAIL,
  REQUEST_INVITE_SIGNUP,
  REQUEST_INVITE_SIGNUP_SUCCESS,
  REQUEST_INVITE_SIGNUP_FAILURE,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGIN_SSO,
} from "./actions";
import {
  OAUTH_REGISTER_DATA,
  IS_RESEND_VERIFY_EMAIL,
} from "../../registration/redux/actions";
import { sessionTokenSelector } from "./selectors";
import paths from "../../../constants/paths";
function loginCall(payload) {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios.post(endpoints.AUTH.LOGIN, payload, config);
}

function getProfile(sessionToken, payload) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.get(endpoints.GET_PROFILE.replace(":userId", payload), config);
}
function logOutApiCall(sessionToken) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.post(endpoints.AUTH.LOGOUT, {}, config);
}

function* getProfileApiCall(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(getProfile, sessionToken, payload.data);
    const data = response.data;
    if (data) {
      yield put({ type: FETCH_PROFILE_SUCCESS, data });
    } else {
      toast.error("Error while fetching profile");
    }
  } catch (error) {
    toast.error("Error while fetching profile");
  }
}

function* login(payload) {
  try {
    const response = yield call(loginCall, payload.data.payload);
    const data = response.data;
    if (data) {
      yield put({ type: LOGIN_SUCCESS, data });
      if(payload.data.location){
        payload.data.history.push(payload.data.location)
      }else{
        payload.data.history.push(paths.PROJECTLIST);
      }
    } else {
      toast.error("Login failed");
      yield put({
        type: LOGIN_FAILURE,
        data: {
          message: "Login failed",
        },
      });
    }
  } catch (error) {
    if (error && error.response) {
      if (error.response.status === 401) {
        yield put({
          type: IS_RESEND_VERIFY_EMAIL,
          data: {
            isResendVerifyEmail: true,
            resendVerifyEmail: payload.data ? payload.data.email : "",
          },
        });
      }
      if (error.response.data && error.response.data.error)
        toast.error(error.response.data.error);
    } else toast.error("Login failed");
    yield put({
      type: LOGIN_FAILURE,
      data: {
        message: "Login failed",
      },
    });
  }
}

function loginOAuthCall(payload) {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios.post(endpoints.AUTH.LOGIN_OAUTH, payload.data.payload, config);
}

function* loginOAuth(payload) {
  try {
    const response = yield call(loginOAuthCall, payload);
    const data = response.data;

    if (response.status === 203) {
      if (data && data.user && data.user.email) {
        yield put({
          type: OAUTH_REGISTER_DATA,
          data: {
            email: data.user.email,
          },
        });
        payload.data.history.push(paths.REQUESTDEMO);
      } else {
        toast.error("Some error occured. Please try again");
        payload.data.history.push(paths.DEFAULT);
      }
    } else {
      if (data && data.token) {
        yield put({ type: LOGIN_SUCCESS, data });
        payload.data.history.push(paths.PROJECTLIST);
      } else {
        toast.error("Some error occured. Please try again");
        payload.data.history.push(paths.DEFAULT);
      }
    }
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    )
      toast.error(error.response.data.error);
    else toast.error("Some error occured. Please try again");

    payload.data.history.push(paths.DEFAULT);
  }
}

function resendVerificationCall(payload) {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios.post(endpoints.AUTH.RESEND_VERIFY_EMAIL, payload.data, config);
}

function* resendVerification(payload) {
  try {
    const response = yield call(resendVerificationCall, payload);
    const data = response.data;
    if (data) {
      toast.success(data.message);
    }
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    )
      toast.error(error.response.data.error);
    else toast.error("Resend verification mail failed. Please try again");
  }
}

function* requestInviteSignup(payload) {
  try {
    const config = { headers: { "Content-Type": "application/json" } };
    const response = yield axios.post(
      endpoints.AUTH.REQUEST_INVITE_SIGNUP.replace(
        ":token",
        payload.data.token
      ),
      payload.data.payload,
      config
    );
    const data = response.data;
    if (data) {
      yield put({ type: REQUEST_INVITE_SIGNUP_SUCCESS, data });
      //toast.success("Signup Successful");
      payload.data.history.push(paths.PROJECTLIST);
    } else {
      toast.error("Request failed");
      yield put({ type: REQUEST_INVITE_SIGNUP_FAILURE });
    }
  } catch (error) {
    yield put({ type: REQUEST_INVITE_SIGNUP_FAILURE });
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    )
      toast.error(error.response.data.error);
    else toast.error("Request failed");
  }
}

function* logOutUser(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(logOutApiCall, sessionToken);
    if (response.message === "Success") {
      // yield logout success
      yield put({ type: LOGOUT_SUCCESS });
      yield payload.data.cb();
    }
  } catch (error) {
    // yield logout failed
    toast.error("Failed to log you out");
  }
}

function loginSsoApi(sessionToken,payload){
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.put(endpoints.AUTH.LOGIN_SSO, payload, config);
}

function* loginSso(payload){
  try{
    const sessionToken = yield select(sessionTokenSelector);
    yield call(loginSsoApi, sessionToken, payload.data.payload);
    if(payload.data.callBack){
      payload.data.callBack();
    }
  }catch(error){
    toast.error(error.response.data.error);
  }
}

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(LOGIN, login);
  yield takeLatest(GETPROFILE, getProfileApiCall);
  yield takeLatest(LOGIN_OAUTH, loginOAuth);
  yield takeLatest(RESEND_VERIFICATION_MAIL, resendVerification);
  yield takeLatest(REQUEST_INVITE_SIGNUP, requestInviteSignup);
  yield takeLatest(LOGOUT, logOutUser);
  yield takeLatest(LOGIN_SSO, loginSso);
}
