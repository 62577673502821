import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import "./emailinput.css";
import { withTranslation, WithTranslation } from "react-i18next";
import { WithStyles, createStyles } from "@material-ui/core";

/* istanbul ignore next */
const useStyles = () =>
  createStyles({
    tagItem: {
      backgroundColor: "#d4d5d6",
      //display: "inline-block",
      fontSize: "14px",
      borderRadius: "30px",
      height: "30px",
      padding: "0 4px 0 1rem",
      display: "inline-flex",
      alignItems: "center",
      margin: "0 0.3rem 0.3rem 0",
    },
    button: {
      backgroundColor: "white",
      width: "22px",
      height: "22px",
      borderRadius: "50%",
      border: "none",
      cursor: "pointer",
      font: "inherit",
      marginLeft: "10px",
      fontWeight: "bold",
      padding: "0",
      lineHeight: "1",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  });

type State = {
  value: string;
  error: string | null;
};
interface Props extends WithTranslation, WithStyles<typeof useStyles> {
  emails: string[];
  handleEmails: (emails: string[]) => void;
  source?: string;
  name?: string;
  setFieldValue?: (name: string, newValues: string[]) => void;
  error: Boolean;
  helperText: string;
}
export class EmailInput extends Component<Props, State> {
  emailInputRef = React.createRef<HTMLInputElement>();
  constructor(props: Props) {
    super(props);
    this.state = {
      value: "",
      error: null,
    };
  }

  handleKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.value.trim();

      if (value && this.isValid(value)) {
        const newValues = [...this.props.emails, this.state.value];
        this.setState({
          value: "",
        });
        this.updateToParent(newValues);
      }
    }
  };

  handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      value: evt.target.value,
      error: null,
    });
  };

  handleDelete = (item: string) => {
    const newValues = this.props.emails.filter((i) => i !== item);
    this.updateToParent(newValues);
  };

  handlePaste = (evt: React.ClipboardEvent) => {
    evt.preventDefault();

    var paste = evt.clipboardData.getData("text");
    // /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/
    var emails = paste.match(/[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/g);

    if (emails) {
      var toBeAdded = emails.filter((email) => !this.isInList(email));

      const newValues = [...this.props.emails, ...toBeAdded];
      this.updateToParent(newValues);
    }
  };

  focusOut = () => {
    var value = this.state.value.trim();
    if (value && this.isValid(value)) {
      const newValues = [...this.props.emails, this.state.value];
      this.setState({
        value: "",
      });
      this.props.handleEmails(newValues);
    }
  };

  isValid = (email: string) => {
    let error = null;

    if (this.isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (!this.isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (error) {
      this.setState({ error });

      return false;
    }

    return true;
  };

  isInList = (email: string) => {
    return this.props.emails.includes(email);
  };

  isEmail = (email: string) => {
    // [\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+
    return /[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/.test(email);
  };

  updateToParent = (newValues: string[]) => {
    if (
      this.props.source === "formik" &&
      this.props.setFieldValue !== undefined &&
      this.props.name !== undefined
    ) {
      this.props.setFieldValue(this.props.name, newValues);
    } else {
      this.props.handleEmails(newValues);
    }
  };
  
  render() {
    const { classes, t } = this.props;
    return (
      <div
        className="react-multi-email"
        onClick={() => {
          if (this.emailInputRef.current) {
            this.emailInputRef.current.focus();
          }
        }}
        data-test="main-container"
      >
        {this.props.emails.map((item) => (
          <div className={classes.tagItem} key={item} data-test="email-div">
            {item}
            <button
              type="button"
              className={classes.button}
              onClick={() => this.handleDelete(item)}
              data-test="handleDelete"
            >
              &times;
            </button>
          </div>
        ))}

        <input
          //className={"input " + (this.state.error && " has-error")}
          ref={this.emailInputRef }
          value={this.state.value}
          placeholder={t("EmailInputPlaceholder")}
          onKeyDown={this.handleKeyDown}
          onChange={this.handleChange}
          onPaste={this.handlePaste}
          onBlur={this.focusOut}
          data-test="email-input"
        />

        {/* {<p>Press `Enter` or `Tab` after typing email or paste a email...</p>} */}
        {this.state.error && <p className="error">{this.state.error}</p>}
        {this.props.error && <p className="error">{this.props.helperText}</p>}
      </div>
    );
  }
}

export default withStyles(useStyles)(withTranslation()(EmailInput));
