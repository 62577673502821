import React from 'react';
import { Paper } from '@material-ui/core';
import './SubscriptionCard.css';
import { spaceCoversion , coreConversion } from '../../helpers/utils';
import { useTranslation } from 'react-i18next';
import { SubscriptionCardModel } from '../../models/Project.model';

//const SubscriptionCard = ({disabled = false ,subscriptionName , subscriptionPrice , subscriptionCore , subscriptionApps ,subsciptionSpace , subscriptionDataTransfer , subscriptionRam}) => {
export const OrgSubscriptionCard = (props: SubscriptionCardModel) => {	
 const { details } = props; 
 const disabled = props.selectedSubscriptionId !== details.id ; 
 const cardDisabled = props.cardDisabled;
 const [t] = useTranslation()

 const handleClick = ()=> {
     if(!cardDisabled){
        props.handleSubscriptionClick(details)
     }
 }
 
  return (
      <>
          <Paper elevation={3} className={`SubscriptionCard-container ${cardDisabled ? "disabled-card" : ""}`} data-test="main-container">
              <div 
            //   underline='none' 
              onClick={cardDisabled ? ()=> {} : handleClick} data-test="card-link">
                  {/* <div className={ disabled === true ? 'SubscriptionCard-header-disabled' : 'SubscriptionCard-header' }>{details.name}</div>	 */}
                  <div className={`SubscriptionCard-header ${cardDisabled ? "" : "" }`} data-test="details-name">{details.name}</div>
                  <div className={ `${ disabled === true ? 'SubscriptionCard-pricing-disabled' : 'SubscriptionCard-pricing'}  ${cardDisabled ? "disabled-card" : "" }` }>
                      <div>
                          <span className={ disabled === true ? 'price-disabled' : 'price' } data-test="details-price">${details.price}</span><span className={ disabled === true ? 'perMonth-disabled' : 'perMonth' }> / {t('PerMonth')} </span>
                      </div>
                  </div>
                  <div className={ `${disabled === true ? 'SubscriptionCard-content-disabled' : 'SubscriptionCard-content'}  ${cardDisabled ? "disabled-card" : "" }` } data-test="details-card">
                      <div>
                          <p style={{textAlign:'center'}} className={ disabled === true ? 'sc-contentText-disabled' : 'sc-contentText' } data-test="user-details">{details.no_of_user > 1 ? details.no_of_user + ' Users': details.no_of_user + ' User'}</p>
                          <p style={{textAlign:'center'}} className={ disabled === true ? 'sc-contentText-disabled' : 'sc-contentText' } data-test="cluster-details">{ details.cluster } {t('ClusterLabel')}</p>
                          {/* <p style={{textAlign:'center'}} className={ disabled === true ? 'sc-contentText-disabled' : 'sc-contentText' } data-test="memory-details">{spaceCoversion(details.memory)} {t('RAM')}</p> */}
                          {/* <p style={{textAlign:'center'}} className={ disabled === true ? 'sc-coreText-disabled' : 'sc-coreText' } data-test="core-details">{coreConversion(details.cores)}</p> */}
                          {/* <p align='center' className={ disabled === true ? 'sc-contentText-disabled' : 'sc-contentText' }>{spaceCoversion(details.data_transfer)} Data Transfer</p> */}
                      </div>
                  </div>
              </div>
          </Paper>
      </>
    )
};

export default OrgSubscriptionCard;
