import React from 'react';
import { Backdrop, Grid } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Loader from './Loader';

type Props = {
  message?: string
}

const useStyles = makeStyles((theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.appBar + 1,
      color: '#fff',
    },
  })
);

export default function BackdropLoader(props: Props) {
  const classes = useStyles();
  const message = props.message || 'Loading...'
  return (
    <div>
      <Backdrop className={classes.backdrop} open={true}>
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid item>
            <Loader />
          </Grid>
          <Grid item>{message}</Grid>
        </Grid>
      </Backdrop>
    </div>
  );
}