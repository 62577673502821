import { Card, CardContent, Grid, Tooltip, IconButton } from "@material-ui/core";
import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import BackdropLoader from "../../../../components/loader/BackdropLoader";
import { LoadbalancerStatusIndicator } from "../../../../components/statusindicator/statusindicator";
import { getDateInStandardFormat } from "../../../../helpers/utils";
import {
  clearLBInfo,
  fetchLBStatus,
  getLoadbalancer,
} from "../../redux/actions";
import RefreshIcon from '@material-ui/icons/Refresh';
import KeyValueRow from "../../../../components/keyvaluerow/KeyValueRow";
import { Dispatch } from "redux";
import { Port } from "../../../../models/HelmCharts.model";

interface Props extends WithTranslation, PropsFromRedux {
  //getLoadbalancer: (id: number) => { type: string; data: { id: number; }; },
  //fetchLBStatus: (id: number) => { type: string; data: { id: number; }; },
  lbId: number,
  //clearLBInfo: () => { type: string; },
  // loadbalancerInfo: LoadbalancerInfoModel,
  // loadbalancerStatus: LoadbalancerStatusModel,
  // fetchingLBStatus: boolean
}

export class LoadbalancerInfo extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  fetchLb = (id: number) => {
    this.props.getLoadbalancer(id);
    this.props.fetchLBStatus(id);
  }

  componentDidMount() {
    if (this.props.lbId) {
      this.fetchLb(this.props.lbId)
    }
  }

  UNSAFE_componentWillReceiveProps(newProps: Props) {
    if (newProps.lbId && this.props.lbId !== newProps.lbId) {
      this.fetchLb(newProps.lbId)
    }
  }
      
  onRefresh = () => {
    if(this.props.lbId){
      this.fetchLb(this.props.lbId)
    }     
  }

  UNSAFE_componentWillMount() {
    this.props.clearLBInfo();
  }
  render() {
    const { t } = this.props
    return (
      <div data-test="main-container">
        <span className='rightbtn' data-test="refreshButton" >
          <Tooltip title={t('Projects.LoadBalancerTab.refresh')} placement="left">
              <IconButton onClick={() => this.onRefresh()} data-test="refresh-icon-button" >
                  <RefreshIcon color="primary" 
                  // fontSize={"15"} 
                  />
              </IconButton>
          </Tooltip>
        </span>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Card>
              <CardContent data-test="left-card-info">
                <KeyValueRow rowKey={t('Projects.LoadbalancerInfo.nameLabel')} rowValue={this.props.loadbalancerInfo?.name} data-test="lb-name" />
                
                <KeyValueRow rowKey={t('Projects.LoadbalancerInfo.regionLabel')} rowValue={this.props.loadbalancerInfo?.cluster?.zone} data-test="lb-zone" />
                {this.props?.loadbalancerStatus && (
                  <KeyValueRow rowKey={t('Projects.LoadbalancerInfo.statusLabel')} rowValue={(<LoadbalancerStatusIndicator status={this.props.loadbalancerStatus.status} />) } data-test="lb-status" />
                )}
                <KeyValueRow rowKey={t('Projects.LoadbalancerInfo.createdLabel')} rowValue={getDateInStandardFormat(this.props?.loadbalancerInfo?.createdat)} data-test="lb-created" />
              </CardContent>
            </Card>
          </Grid>
          {this.props?.loadbalancerStatus?.service &&
            this.props?.loadbalancerStatus?.status === "Running" && (
              <Grid item md={6} xs={12}>
                <Card>
                  <CardContent data-test="service-info">
                    {this.props?.loadbalancerStatus?.service?.spec?.ports &&
                      this.props.loadbalancerStatus.service?.spec?.ports.map(
                        (ser: Port, _ind: number) => (
                          <KeyValueRow key={_ind} rowKey={`${ser.name} ${t('Projects.LoadbalancerInfo.portLabel')}`} rowValue={`${ser.port}:${ser.nodePort}/${ser.protocol}`} data-test="lb-port" />
                          // <Grid container spacing={2} key={_ind}>
                          //   <Grid item md={4} xs={4}>
                          //     <Typography color="primary" variant="h6">
                          //       {ser.Name} {t('Projects.LoadbalancerInfo.portLabel')}{" "}
                          //     </Typography>
                          //   </Grid>
                          //   <Grid item md={8} xs={8}>
                          //     {ser.Port}:{ser.NodePort}/{ser.Protocol}
                          //   </Grid>
                          // </Grid>
                        )
                      )}
                    {this.props.loadbalancerStatus?.service?.spec
                      ?.clusterIP && (
                        <KeyValueRow rowKey={t('Projects.LoadbalancerInfo.internalIpLabel')} rowValue={this.props.loadbalancerStatus?.service?.spec?.clusterIP} data-test="lb-cluster-ip" />
                    )}
                    {this.props.loadbalancerStatus?.service?.status
                      ?.loadBalancer?.ingress &&
                      this.props.loadbalancerStatus?.service?.status
                        ?.loadBalancer?.ingress[0] && (
                          <KeyValueRow rowKey={t('Projects.LoadbalancerInfo.externalIpLabel')} rowValue={this.props.loadbalancerStatus?.service?.status
                            ?.loadBalancer?.ingress[0]?.ip} data-test="lb-ingress-ip"
                          />
                      )}
                  </CardContent>
                </Card>
              </Grid>
            )}
        </Grid>
        {this.props.fetchingLBStatus && (
          <BackdropLoader message={t('Projects.LoadbalancerInfo.statusFetchingMessage')} />
        )}
      </div>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  //environmentDetails: state.ProjectReducer.projectDetails,
  loadbalancerInfo: state.ProjectReducer.loadbalancerInfo,
  fetchingLBStatus: state.ProjectReducer.fetchingLBStatus,
  loadbalancerStatus: state.ProjectReducer.loadbalancerStatus,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    getLoadbalancer: (id: number) => dispatch(getLoadbalancer(id)),
    fetchLBStatus: (id: number) => dispatch(fetchLBStatus(id)),
    clearLBInfo: () => dispatch(clearLBInfo()),
  };
};

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withTranslation()(LoadbalancerInfo));
