import { makeStyles, Tab, Tabs} from "@material-ui/core";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
//import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import ProjectTab from "./projectstab/ProjectsTab";
import SubscriptionTab from "./subscriptiontab/SubscriptionTab";
import VariablesTab from "./variablestab/VariablesTab";
import { useTranslation } from 'react-i18next';
import paths from "../../../../constants/paths";
import {TabPanel} from "../../../../components/tabpanel/TabPanel"
import { WithWidth } from '@material-ui/core/withWidth';
import { History } from 'history';
/* istanbul ignore next */
function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

/* istanbul ignore next */
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "inherit",
    display: "flex",
    padding: "0",
    margin: "0",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down("md")]: {
      backgroundColor: "white",
    },
  },
  tabPanel: {
    padding: "0",
    margin: "0",
    width: "100%",
    minWidth: 250,
  },
}));

interface Props extends WithWidth,PropsFromRedux {
  history: History,
  innerTab: number,
  updateInnerTab:(value: number)=>void,
}

export function VerticalTabs(props: Props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [t] = useTranslation()

  /* istanbul ignore next */
  const getNamebyTabIndex = (newValue: any) => {
    const tabPanelObj = document.getElementById('settingsTab-' + newValue);
    if (tabPanelObj) {
      const name = tabPanelObj.getAttribute("name");
      if (name) {
        props.history.push({
          pathname: paths.PROJECTINFO.replace(":id", props.projectDetails?.id) + "#settings_" + name
        });
      }
    }
  }

  const handleChange = (e: any, newValue: any) => {
    //setValue(newValue);
    getNamebyTabIndex(newValue);
    props.updateInnerTab(newValue);
  };

  useEffect(() => {
    setValue(props.innerTab);
  }, [props.innerTab]);

  useEffect(() => {
    return (() => {
      props.updateInnerTab(0);
    })
  }, []);

  return (
      <div className={classes.root} data-test="main-container">
          <Tabs
            orientation={isWidthDown("md", props.width) ? undefined : "vertical"}
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            className={classes.tabs}
            variant="scrollable"
            indicatorColor="primary"
            data-test="tabs-container"
          >
              <Tab label={t('General')} {...a11yProps(0)} data-test="general-tab"/>
              {/* <Tab label="Permissions" { ...a11yProps(1) } /> */}
              {/* <Tab label="Billing History" { ...a11yProps(2) } /> */}
              <Tab label={t('Variables')} {...a11yProps(1)} data-test="variables-tab"/>
              <Tab label={t('SettingsSubscription')} {...a11yProps(2)} data-test="subscription-tab"/>
          </Tabs>
          <TabPanel value={value} index={0} className={classes.tabPanel} name="general" data-test="general-tabpanel" padding={2} tabPanel="settingsTab">
              <ProjectTab />
          </TabPanel>
          {/* <TabPanel value={ value } index={ 1 } className={ classes.tabPanel }>
              <UserPermissionsTab />
          </TabPanel> */}
          {/* <TabPanel value={ value } index={ 2 } className={ classes.tabPanel }>
              <BillingTab />
          </TabPanel> */}
          <TabPanel value={value} index={1} className={classes.tabPanel} name="variables" data-test="variables-tabpanel" padding={2} tabPanel="settingsTab">
              <VariablesTab />
          </TabPanel>
          <TabPanel value={value} index={2} className={classes.tabPanel} name="subscription" data-test="subscription-tabpanel" padding={2} tabPanel="settingsTab">
              <SubscriptionTab />
          </TabPanel>
      </div>
  );
}

/* istanbul ignore next */
const mapStateToProps = (state: any) =>{
  return {
    projectRole: state.ProjectReducer.projectRole,
    projectDetails: state.ProjectReducer.projectDetails
  };
};

// export default connect(mapStateToProps, null)(withWidth()(VerticalTabs));

const connector=connect(mapStateToProps)

type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(withWidth()(VerticalTabs));
