import { 
  CLEAR_ACCOUNT_LIST, 
  GET_ACCOUNT_LIST, 
  GET_ACCOUNT_LIST_FALIURE, 
  GET_ACCOUNT_LIST_SUCCESS, 
  UNLINK_ACCOUNT, 
  UNLINK_ACCOUNT_FALIURE, 
  UNLINK_ACCOUNT_SUCCESS,
  GET_ACCESS_TOKEN_LIST,
  GET_ACCESS_TOKEN_LIST_SUCCESS,
  GET_ACCESS_TOKEN_LIST_FAILURE,
  CREATE_ACCESS_TOKEN,
  DELELE_ACCESS_TOKEN,
  DELELE_ACCESS_TOKEN_SUCCESS,
  DELELE_ACCESS_TOKEN_FAILURE,
  CREATE_ACCESS_TOKEN_SUCCESS,
  CLEAR_ACCESS_TOKEN,
  DELETE_ALL_ACCESS_TOKEN,
  CREATE_ACCESS_TOKEN_FAILURE,

} from './actions';
import initialState from './initialState';

const reducer = (state = initialState, payload ) => {
  switch (payload.type) {
    case GET_ACCOUNT_LIST: 
      return {
        ...state,
        loadingAccountList: true,
      }
    case GET_ACCOUNT_LIST_SUCCESS: 
      return {
        ...state,
        loadingAccountList: false,
        accountList: payload.data
      }
    case GET_ACCOUNT_LIST_FALIURE: 
      return {
        ...state,
        loadingAccountList: false,
      }
    case CLEAR_ACCOUNT_LIST: 
      return {
        ...state,
        accountList: initialState.accountList
      }
    case UNLINK_ACCOUNT: 
      return {
        ...state,
        unlinkingAccount: true
      }
    case UNLINK_ACCOUNT_SUCCESS:
    case UNLINK_ACCOUNT_FALIURE:
      return {
        ...state,
        unlinkingAccount: false
      }
    //for access token
    case GET_ACCESS_TOKEN_LIST:
    return{
      ...state,
      loadingTokenList: true,
    }
    case GET_ACCESS_TOKEN_LIST_SUCCESS:
      return{
        ...state,
        loadingTokenList: false,
        tokenList:payload.data,
      }
    case GET_ACCESS_TOKEN_LIST_FAILURE: 
      return {
        ...state,
        loadingTokenList: false,
      }
    case CREATE_ACCESS_TOKEN:
      return{
        ...state,
        creatingToken: true,
      }
    case CREATE_ACCESS_TOKEN_SUCCESS:
      return{
        ...state,
        creatingToken: false,
        newToken:payload.data,
        tokenList: [payload.data, ...state.tokenList],
      }
      case CREATE_ACCESS_TOKEN_FAILURE:
      return{
        ...state,
        creatingToken: false,
      }
    case DELELE_ACCESS_TOKEN:
      return{
        ...state,
        deletingTokenList: true,
      }
    case DELELE_ACCESS_TOKEN_SUCCESS:
     
      return{
        ...state,
        deletingTokenList: false,
        tokenList: state.tokenList.filter((el) => el.id !== payload.data.id),
      }
    case DELELE_ACCESS_TOKEN_FAILURE: 
      return {
        ...state,
        deletingTokenList: false,
      }
    case DELETE_ALL_ACCESS_TOKEN:
      return {
        ...state,
        deletingAllToken: true,
      }
    case CLEAR_ACCESS_TOKEN:
      return {
        ...state,
        tokenList:initialState.tokenList,
        newToken: initialState.newToken,
        deletingAllToken: false,
      }
    default:
      return state
  }
}

export default reducer