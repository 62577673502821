import React from 'react';
import './companybanner.css';
import Grid from '@material-ui/core/Grid';

const LandingScreen = (props: {mode: number}) => {
  // return <Grid item xs={ false } sm={ 4 } md={ 6 } className='image' data-test="image-container" />
  return <Grid item md={ 7 } data-test="image-container" className='landing-screen'>
    <img
                    alt="Logo"
                    data-test="project-image"
                    width="120"
                    src="/images/logos/logo-blue.svg"
                    style={{marginTop: "50px", marginLeft:"50px", position: "absolute"}}
                  />

  <link rel='stylesheet' id='google-fonts-1-css'
    href='https://fonts.googleapis.com/css?family=Golos+Text%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic%7CJetBrains+Mono%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic&#038;display=swap&#038;ver=6.5.3'
    media='all' />

<div className="elementor-kit-14 ">
  <div data-elementor-type="wp-page" data-elementor-id="1430" className="elementor elementor-1430">
    <div className="elementor-element elementor-element-113a269 e-con-full e-flex e-con e-parent" data-id="113a269"
      data-element_type="container" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
      <div className="elementor-element elementor-element-8bc3715 e-con-full e-flex e-con e-child" data-id="8bc3715"
        data-element_type="container">
        {/* <div
          className="elementor-element elementor-element-a0e3fcc lqd-text-tag-p elementor-widget elementor-widget-lqd-text"
          data-id="a0e3fcc" data-element_type="widget" data-widget_type="lqd-text.default">
          <div className="elementor-widget-container">
            <p className="lqd-text-el m-0 p-0">
              <span className="lqd-text-item relative elementor-repeater-item-dac949e"></span>
              <span className="lqd-text-item relative elementor-repeater-item-5cdb239">Our beta version is out now. Start
                exploring today!</span>
            </p>
          </div>
        </div> */}
        <div
          className="elementor-element elementor-element-9ce76f6 lqd-text-tag-h2 elementor-widget elementor-widget-lqd-text"
          data-id="9ce76f6" data-element_type="widget" data-widget_type="lqd-text.default">
          <div className="elementor-widget-container">
            {props.mode === 1 && 
              <h2 className="lqd-text-el m-0 p-0">
                <span className="lqd-text-item relative elementor-repeater-item-a1db407">An </span>
                <span className="lqd-text-item relative elementor-repeater-item-dd28e1d">easier </span>
                <span className="lqd-text-item relative elementor-repeater-item-37c9e84">way to deploy your </span>
                <span className="lqd-text-item relative elementor-repeater-item-19905a5">apps</span>
              </h2>
            }
            {
              props.mode === 2 &&
              <h2 className="lqd-text-el m-0 p-0">
                <span className="lqd-text-item relative elementor-repeater-item-dd28e1d">Revolutionizing<br /></span>
                <span className="lqd-text-item relative elementor-repeater-item-a1db407">how you<br /></span>
                <span className="lqd-text-item relative elementor-repeater-item-37c9e84">build, deploy & manage<br /></span>
                <span className="lqd-text-item relative elementor-repeater-item-19905a5">apps</span>
                
              </h2>
            }
          </div>
        </div>
        <div
          className="elementor-element elementor-element-9f0d554 lqd-text-tag-p elementor-widget__width-initial elementor-widget elementor-widget-lqd-text"
          data-id="9f0d554" data-element_type="widget" data-widget_type="lqd-text.default">
          <div className="elementor-widget-container">
            <p className="lqd-text-el m-0 p-0">
              <span className="lqd-text-item relative elementor-repeater-item-4355b36" style={{fontSize: "17px"}}>Simplify your cloud journey with 01Cloud, 
              from DevOps to NoOps, effortlessly.</span>
            </p>
          </div>
        </div>
        <div className="elementor-element elementor-element-0050c00 elementor-widget elementor-widget-lqd-button"
          data-id="0050c00" data-element_type="widget" data-widget_type="lqd-button.default">
          <div className="elementor-widget-container"></div>
        </div>
      </div>
    </div>
  </div>
</div>

  </Grid>
};

export default LandingScreen;