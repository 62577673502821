import React from "react";
import {
    Button,
    Table,
    IconButton,
    InputAdornment,
    TableBody,
    Paper,
    TableHead,
    TableRow,
    TableContainer,
    TextField,
    FormControlLabel,
    Switch,
    Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { GeneralStyledTableCell as StyledTableCell } from "./../../../../../components/styledtabelcell/StyledTabelCell";
import MuiTextField from "./../../../../../components/textfield/MuiTextField";
import { useTranslation } from "react-i18next";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import _ from "lodash";
import AceEditor from "react-ace";
import { useEffect } from "react";

const useStyles = makeStyles(() => ({
    gridItem: {
        minWidth: 350,
    },
    actions: {
        display: "flex",
        justifyContent: "center",
        marginTop: 20,
        marginBottom: 20,
    },
    topmargin: {
        marginTop: 15,
    },
    grid: {
        marginTop: 20,
    },
    left: {
        textAlign: "left",
    },
    disableCard: {
        backgroundColor: "lightgray",
    },
    noCursor: { cursor: "not-allowed" },
    actionArea: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: 16,
    },
}));
const aceStyles = {
    mode: "javascript",
    theme: "monokai",
    fontSize: 14,
    showPrintMargin: false,
    width: "100%",
    height: "25vh",
    name: "command",
    className: "m-t-10",
};
interface I_context {
    isError: boolean;
}
interface I_props {
    ciVars: VariablesLocal[];
    copyContext: (context: I_context) => void;
    edit?: boolean;
    setCiVariables: (ciVars: VariablesLocal[]) => void;
}
export interface Variables {
    key: string;
    type?: string;
    value: string;
    isProtected?: boolean;
}

export interface VariablesLocal extends Variables {
    id: number;
    isShowPassword?: boolean;
    isError?: string;
}

export const CiVariables = (props: I_props) => {

    const [isError, setIsError] = React.useState(false);
    const [bulkEdit, setBulkEdit] = React.useState(false);
    const [bulkVars, setBulkVars] = React.useState("");
    const { t } = useTranslation();
    const classes = useStyles();

    useEffect(() => {
        props.copyContext({ isError: isError });
    }, [isError]);

    const getEmptyRowObject = () => {
        return {
            id: -1,
            key: "",
            value: "",
            isShowPassword: false,
            isProtected: true,
        };
    };

    const addNew = () => {
        const newCiVariables = [...props.ciVars];
        newCiVariables.push(getEmptyRowObject());
        props.setCiVariables(newCiVariables);
    };

    const handleKeyChange = (
        e: React.ChangeEvent<{
            value: string;
            name: string;
        }>, keyIndex: number
    ) => {

        // todo find if the value already exists as a key
        const value = e.target.value;
        const stateVariable = [...props.ciVars];
        const duplicateKeyIndex = stateVariable.findIndex((variable) => variable.key.toUpperCase() === value.toUpperCase())

        // todo add the value in its index place in list
        stateVariable[keyIndex]["key"] = value

        // todo if value already exists then set an duplicate error else check for alpha numeric error
        stateVariable[keyIndex].isError = !/^[A-Za-z0-9_-]*$/.test(value)
        ? t("AlphaNumericError")
        : "";
        stateVariable[keyIndex].isError = duplicateKeyIndex>=0? t("DuplicateError") : "";
       
        stateVariable[keyIndex].id = stateVariable[keyIndex-1]?stateVariable[keyIndex-1].id+1:1
        setIsError(!!stateVariable[keyIndex].isError)

        props.setCiVariables(stateVariable);
    };

    const handleValueChange = (
        e: React.ChangeEvent<{
            value: string;
            name: string;
        }>, valueIndex: number
    ) => {
        const value = e.target.value;
        const stateVariable = [...props.ciVars];

        stateVariable[valueIndex]["value"] = value;
        stateVariable[valueIndex]["id"] = stateVariable[valueIndex-1]?stateVariable[valueIndex-1].id+1:1

        props.setCiVariables(stateVariable);
    };

    const handleShowPassword_UV = (index: number) => {
        const stateVariables = [...props.ciVars];
        if (stateVariables[index]) {
            const _isShowPassword = stateVariables[index].isShowPassword;
            stateVariables[index].isShowPassword = !_isShowPassword;
        }

        props.setCiVariables(stateVariables);
    };

    const handleRemoveUserVariable = (index: number) => {
        const stateVariable = [...props.ciVars];
        stateVariable.splice(index, 1);
        props.setCiVariables(stateVariable);
    };


    const scriptToVariable = (script: string) => {
        let newVariables: VariablesLocal[] = [];
        let i = 0;
        script.split("\n").forEach((line: string) => {
            let kv: VariablesLocal, key: string, value: string;
            if (
                line.trim() === "" ||
                !line.includes("=") ||
                !line.trim().split("=")[0]
            )
                return;
            key = line.trim().split("=")[0].trim();
            if (!/^[A-Za-z0-9_-]*$/.test(key)) return;
            value = line.trim().split("=").slice(1).join("=");
            kv = { id: i, key, value };
            let cVar = props.ciVars.find((v) => v.id === i);
            kv.isProtected = cVar?.isProtected ?? true;
            kv.isShowPassword = cVar?.isShowPassword ?? false;
            newVariables.push(kv);
            i++;
        });
        return newVariables;
    };

    const variablesToScript = (variables: VariablesLocal[]) => {
        let script = "";
        variables.forEach((variable: VariablesLocal) => {
            if (!variable.key || variable.key.trim() === "") return;
            script += `${variable.key}=${variable.value ? variable.value : ""}\n`;
        });
        return script;
    };
    const handleBulkEdit = () => {
        setBulkEdit(!bulkEdit);
        setBulkVars(variablesToScript(props.ciVars));
    };
    const isKeyDuplicate = (val: string) => {
        const variables = props.ciVars;
        if (val !== "") {
            const exists = variables.filter(
                (variable) => variable?.key?.toUpperCase() === val?.toUpperCase()
            );
            if (exists?.length > 1) {
                return true;
            }
        }
        return false;
    };
    const getAnnotations = () => {
        let annotations = [];
        const variables = props.ciVars;
        for (let i = 0; i < variables.length; i++) {
            if (isKeyDuplicate(variables[i].key)) {
                const err = {
                    row: i,
                    column: i,
                    type: "error",
                    text: "Duplicate key Error",
                };
                annotations.push(err);
            }
        }
        return annotations;
    };
    const handleVariableChange = (val: string) => {
        setBulkVars(val);
        props.setCiVariables(scriptToVariable(val));
    };
    return (
        <>
            {props.edit ? (
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                    <FormControlLabel
                        control={
                            <Switch
                                name="bulk_edit"
                                color="primary"
                                checked={bulkEdit}
                                onChange={handleBulkEdit}
                            // disabled={!this.hasValidUserVars()}
                            />
                        }
                        label="Bulk Edit Mode"
                        labelPlacement="start"
                        data-test="bulk-edit-form"
                    />

                    {!bulkEdit ? (
                        <Box>
                            <Button
                                variant="contained"
                                disabled={
                                    Boolean(props.ciVars?.find((a) => a.id === -1)) || bulkEdit
                                }
                                color="primary"
                                disableElevation={true}
                                onClick={() => addNew()}
                            >
                                {" "}
                                {t("Environment.Generals.addVariable")}
                            </Button>
                        </Box>
                    ) : null}
                </Box>
            ) : null}

            {bulkEdit ? (
                <AceEditor
                    placeholder={
                        bulkVars
                            ? ""
                            : `Enter environment variables in the format:\nKEY=VAL`
                    }
                    onChange={(val) => handleVariableChange(val)}
                    value={bulkVars ?? ""}
                    {...aceStyles}
                    setOptions={{
                        showLineNumbers: true,
                        tabSize: 4,
                    }}
                    minLines={10}
                    data-test="bulk-editor"
                    // annotations={[{ row: 1, column: 2, type: 'error', text: 'Duplicate key Error' }]}
                    annotations={getAnnotations()}
                />
            ) : (
                <TableContainer component={Paper} className={classes.topmargin}>
                    <Table data-test="env-var-table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>
                                    {t("Environment.Generals.key")}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {t("Environment.Generals.value")}
                                </StyledTableCell>
                                {props.edit ? (
                                    <StyledTableCell>
                                        {t("Environment.Generals.actions")}
                                    </StyledTableCell>
                                ) : null}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.ciVars.map((item, index) => {
                                return (
                                    <TableRow key={index}>
                                        <StyledTableCell component="th" scope="row">
                                            <TextField
                                                data-test="env-var-key"
                                                value={item.key}
                                                variant="outlined"
                                                fullWidth
                                                name={item.id.toString()}
                                                error={!!item.isError}
                                                helperText={item.isError}
                                                onChange={(
                                                    e: React.ChangeEvent<{
                                                        value: string;
                                                        name: string;
                                                    }>
                                                ) => handleKeyChange(e, index)}
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            <MuiTextField
                                                data-test="env-var-value"
                                                value={item.value}
                                                name={item.id.toString()}
                                                onChange={(
                                                    e: React.ChangeEvent<{
                                                        value: string;
                                                        name: string;
                                                    }>
                                                ) => handleValueChange(e, index)}
                                                type={
                                                    item.isProtected
                                                        ? item.isShowPassword
                                                            ? "text"
                                                            : "password"
                                                        : "text"
                                                }
                                                InputProps={{
                                                    endAdornment: item.isProtected && (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={() => handleShowPassword_UV(index)}
                                                                edge="end"
                                                                data-test="password-visibility-toggle"
                                                            >
                                                                {item.isShowPassword ? (
                                                                    <Visibility />
                                                                ) : (
                                                                    <VisibilityOff />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </StyledTableCell>
                                        {props.edit ? (
                                            <StyledTableCell component="th" scope="row">
                                                {index !== props.ciVars.length && (
                                                    <div>
                                                        <CloseIcon
                                                            onClick={() => handleRemoveUserVariable(index)}
                                                            data-test="remove-user-var"
                                                        />
                                                    </div>
                                                )}
                                            </StyledTableCell>
                                        ) : null}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    );
};
