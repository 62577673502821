import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  Card,
  ButtonBase,
} from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useRef, useState } from "react";
import "react-mde/lib/styles/css/react-mde-all.css";
import { connect } from "react-redux";
import BackdropLoader from "../../components/loader/BackdropLoader";
import MarkdownField from "../../components/markdownfield/MarkdownField";
import MultiFileUpload from "../../components/multifileupload";
import MuiTextField from "../../components/textfield/MuiTextField";
import { fetchEnvironmentsByAppId } from "../app/redux/actions";
import { getProjectListApiCall } from "../project/projectlist/redux/actions";
import {
  fetchAppListByPID,
  getSidebarProjectDetails,
} from "../project/redux/actions";
import { createUserTickets, getSupportTypes } from "./redux/actions";
import { updateBreadcrumb } from "../project/redux/actions";
import paths from "../../constants/paths";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useTranslation } from 'react-i18next';

/* istanbul-ignore-next */
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(2),
  },
  styleGrid: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  labelStyle: {
    color: "black",
    fontWeight: "bolder",
    textAlign: "start",
    marginBottom: theme.spacing(1),
  },
  customButtonStyle: {
    height: 40,
    width: "6rem",
    minWidth: "200px",
    maxWidth: "100%",
  },
  styleParagraph: {
    // textAlign: 'center',
    fontSize: "16px",
    color: "black",
    fontWeight: "bolder",
  },
  styleGridText: {
    color: "#0057fa",
    fontWeight: "bolder",
  },
  spanStyle: {
    color: "#0057fa",
  },
  styleGridValue: {
    color: "gray",
    fontWeight: "bolder",
  },
  headerText: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  fileStyle: {
    padding: "2%",
  },
}));

export function CreateTicketForm(props) {
  //const [projects, setProjects] = useState([]);
  const [ t ] = useTranslation();
  const [titleValue, setTitleValue] = useState("");
  const [supportTypeValue, setSupportTypeValue] = useState("1");
  const [priority, setPriorityValue] = useState("Medium");
  // const [issueTypeSales, setIssueTypeSales] = useState("1");
  // const [issueTypeEngineering, setIssueTypeEngineering] = useState("1");
  const [issueType, setIssueType] = useState(0);
  const [projectValue, setProjectValue] = useState(0);
  const [appValue, setAppValue] = useState(0);
  const [envValue, setEnvValue] = useState(0);
  const [value, setValue] = React.useState("");
  const [disabled, setDisabled] = useState(true);
  const [file, setFile] = useState([]);
  const [errors, setErrors] = useState({});
  const [helperText, setHelperText] = useState({});
  const [projects, setProjects] = useState([]);
  const [apps, setApps] = useState([]);
  const [environments, setEnvironments] = useState([]);
  const [issueTypes, setIssueTypes] = useState([]);

  const firstRender = useRef(true);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const classes = useStyles();

  useEffect(() => {
    const breadcrumbData = [
      {
        name: "Tickets",
        path: paths.SUPPORT,
      },
      {
        name: "CreateTicket",
        path: paths.TICKET_CREATE,
      },
    ];
    props.updateBreadcrumb(breadcrumbData);
    props.getSidebarProjectDetails();
    props.getSupportTypes();
  }, []);

  useEffect(() => {
    setProjects(props.sidebarProjectDetails);
  }, [props.sidebarProjectDetails]);

  const setFiles = (files) => {
    setFile(files);
  };

  // To set projects
  useEffect(() => {
    setEnvValue(0);
    setAppValue(0);
    setProjectValue(0);
  }, [projects]);

  // To set applucations
  useEffect(() => {
    setEnvValue(0);
    setAppValue(0);
  }, [apps]);

  const navToTicketList = () => {
    props.history.push({
      pathname: paths.SUPPORT,
    });
  };

  const formValidation = () => {
    if (titleValue === "") {
      return true;
    } else {
      setButtonDisabled(false);
      return false;
    }
  };

  // When title Value changes
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      setDisabled(false);
    } else {
      setDisabled(formValidation());
      if (titleValue.trim().length <= 0) {
        setErrors({
          ...errors,
          titleValue: true,
        });
        setHelperText({
          ...helperText,
          titleValue: "Title cannot be empty.",
        });
      } else {
        setErrors({
          ...errors,
          titleValue: false,
        });
        setHelperText({
          ...helperText,
          titleValue: "",
        });
      }
    }
  }, [titleValue]);

  //When appValue Changes
  useEffect(() => {
    if (appValue > 0) {
      const app = apps?.find((a) => {
        return a.id === appValue;
      });
      setEnvironments(app?.environments);
    } else {
      setEnvironments([]);
    }
    setEnvValue(0);
  }, [appValue]);

  //When projectValue Changes
  useEffect(() => {
    if (projectValue > 0) {
      const proj = props.sidebarProjectDetails?.find((p) => {
        return p.id === projectValue;
      });
      setApps(proj?.applications);
      setEnvironments([]);
    } else {
      setAppValue(0);
      setApps([]);
    }
  }, [projectValue]);

  useEffect(() => {
    if (props.supportTypes?.length > 0) {
      setSupportTypeValue(props.supportTypes[0].id);
    }
  }, [props.supportTypes]);

  useEffect(() => {
    if (props.supportTypes?.length > 0) {
      const support = props.supportTypes?.find((_support) => {
        return _support.id === supportTypeValue;
      });
      if (support?.issues) {
        setIssueTypes(support.issues);
        if (support.issues.length > 0) {
          setIssueType(support.issues[0].id);
        }
      } else {
        setIssueTypes([]);
      }
    }
  }, [supportTypeValue]);

  const titleValueChangeHandler = (event) => {
    setTitleValue(event.target.value);
  };

  const supportTypeValueChangeHandler = (event) => {
    setSupportTypeValue(event.target.value);
  };

  const priorityChangeHandler = (event) => {
    setPriorityValue(event.target.value);
  };

  const issueTypeChangeHandler = (event) => {
    setIssueType(parseInt(event.target.value));
  };

  const projectValueChangeHandler = (event) => {
    setProjectValue(event.target.value);
  };

  const appValueChangeHandler = (event) => {
    setAppValue(event.target.value);
  };

  const envValueChangeHandler = (event) => {
    setEnvValue(event.target.value);
  };

  /* istanbul ignore next */
  const createSuccess = (ticket) => {
    props.history.push({
      pathname: paths.TICKET_DETAIL.replace(":id", ticket.id),
      state: {
        id: ticket.id,
        ticket: ticket,
      },
    });
  };

  const createHandler = () => {
    let app = null;
    let env = null;
    let pro = null;
    if (supportTypeValue === 1) {
      pro = projectValue === 0 ? null : projectValue;
      app = appValue === 0 ? null : appValue;
      env = envValue === 0 ? null : envValue;
    }
    const ticket = {
      application: app,
      description: value,
      environment: env,
      issueType: issueType,
      priority: priority,
      project: pro,
      supportType: supportTypeValue,
      title: titleValue.trim(),
      userId: props.currentUser.id,
    };
    let payload = {
      ticket,
    };
    if (file?.length > 0) {
      const uploadFiles = [];
      file.forEach((f) => {
        const _f = {
          fileName: f.file?.name,
          fileType: f.file?.type,
          fileValue: f.url,
        };
        uploadFiles.push(_f);
      });
      payload.files = uploadFiles;
    }
    props.createUserTickets(payload, createSuccess);
    // props.handleBack();
  };

  const isValid = () => {
    let valid = true;
    if (value?.trim()?.length <= 0) {
      valid = false;
    }
    return valid;
  };

  return (
    <div className={classes.root} data-test="main-container">
      <div>
        <ButtonBase
          onClick={() => navToTicketList()}
          style={{ color: "#357dfd" }}
          data-test="back-button"
        >
          <ArrowBackIcon fontSize="small" />{" "}
          <span className="jobNav">{t('Support.CreateTicketForm.ticketList')}</span>
        </ButtonBase>
      </div>
      <div className={classes.headerText} data-test="header-component">
        <Typography color="textPrimary" variant="h4">
          {t('Support.CreateTicketForm.createTicket')}
        </Typography>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <Card className={classes.paper}>
            <Grid container spacing={1} className={classes.styleGrid}>
              <Grid item xs={12} sm={12} md={12}>
                <FormControl variant="outlined" className="w-100">
                  <FormLabel className={classes.labelStyle} data-test="title-label">
                    {" "}
                    {t('Support.CreateTicketForm.title')}{" "}
                  </FormLabel>
                  <MuiTextField
                    data-test="title-field"
                    id="Title"
                    value={titleValue}
                    placeholder="Title for your ticket"
                    required
                    error={errors.titleValue}
                    helperText={helperText.titleValue}
                    variant="outlined"
                    onChange={(e) => titleValueChangeHandler(e)}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} className={classes.styleGrid}>
              <Grid item xs={12} sm={6} md={6}>
                {props.supportTypes?.length > 0 && (
                  <FormControl variant="outlined" className="w-100">
                    <FormLabel className={classes.labelStyle} data-test="supportType-label">
                      {" "}
                      {t('Support.CreateTicketForm.supportType')}{" "}
                    </FormLabel>
                    <Select
                      input={<OutlinedInput />}
                      data-test="supportType-field"
                      labelId="supportType"
                      name="support_type"
                      value={supportTypeValue}
                      label={t('Support.CreateTicketForm.supportType')}
                      onChange={supportTypeValueChangeHandler}
                    >
                      {props.supportTypes?.map((support) => (
                        <MenuItem key={support.id} value={support.id}>
                          {support.title}{" "}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <FormControl variant="outlined" className="w-100">
                  <FormLabel className={classes.labelStyle} data-test="priority-label">
                    {" "}
                    {t('Support.CreateTicketForm.priority')}{" "}
                  </FormLabel>
                  <Select
                    data-test="priority-field"
                    labelId="demo-simple-select-outlined-label"
                    name="priority"
                    value={priority}
                    input={<OutlinedInput />}
                    label={t('Support.CreateTicketForm.priority')}
                    onChange={priorityChangeHandler}
                  >
                    <MenuItem value="High">{t('Support.CreateTicketForm.high')}</MenuItem>
                    <MenuItem value="Medium">{t('Support.CreateTicketForm.medium')}</MenuItem>
                    <MenuItem value="Low">{t('Support.CreateTicketForm.low')}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            {issueTypes?.length > 0 && (
              <Grid container spacing={1} className={classes.styleGrid}>
                <Grid item md={6} sm={6} xs={12}>
                  <FormControl variant="outlined" className="w-100">
                    <FormLabel className={classes.labelStyle} data-test="issueTypes-label">
                      {" "}
                      {t('Support.CreateTicketForm.issueType')}{" "}
                    </FormLabel>
                    <Select
                      data-test="issueTypes-field"
                      labelId="demo-simple-select-outlined-label"
                      name="issueType"
                      value={issueType}
                      input={<OutlinedInput />}
                      label={t('Support.CreateTicketForm.issueType')}
                      onChange={issueTypeChangeHandler}
                    >
                      {issueTypes.map((issue) => (
                        <MenuItem key={issue.id} value={issue.id}>
                          {issue.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            )}
            {/* if the support type is for Engineering */}
            {supportTypeValue === 1 && (
              <div data-test="supportTypeValue-component">
                <Grid container spacing={2} className={classes.styleGrid}>
                  <Grid item xs={12} sm={4} md={4}>
                    <FormControl variant="outlined" className="w-100">
                      <FormLabel className={classes.labelStyle}>
                        {" "}
                        {t('Support.CreateTicketForm.project')}{" "}
                      </FormLabel>
                      {projects?.length > 0 ? (
                        <Select
                          data-test="select-project"
                          input={<OutlinedInput />}
                          labelId="demo-simple-select-outlined-label"
                          name="project"
                          value={projectValue}
                          //variant='outlined'
                          label={t('Support.CreateTicketForm.project')}
                          onChange={projectValueChangeHandler}
                        >
                          <MenuItem value={0}> {t('Support.CreateTicketForm.selectProject')} </MenuItem>

                          {/* <MenuItem value={0}> Select Project  </MenuItem> */}
                          {projects?.map((proj, ind) => (
                            <MenuItem value={proj.id} key={ind}>
                              {" "}
                              {proj.name}{" "}
                            </MenuItem>
                          ))}
                        </Select>
                      ) : (
                        <Typography variant="h5" class="m-t-5">
                        {t('Support.CreateTicketForm.noProjects')}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={4} md={4}>
                    <FormControl variant="outlined" className="w-100">
                      <FormLabel className={classes.labelStyle}>
                        {" "}
                        {t('Support.CreateTicketForm.app')}{" "}
                      </FormLabel>
                      {apps?.length > 0 ? (
                        <Select
                          data-test="select-app"
                          input={<OutlinedInput />}
                          labelId="demo-simple-select-outlined-label"
                          name="app"
                          value={appValue}
                          //variant='outlined'
                          label={t('Support.CreateTicketForm.app')}
                          onChange={appValueChangeHandler}
                        >
                          <MenuItem value={0}> {t('Support.CreateTicketForm.selectApp')} </MenuItem>

                          {/* <MenuItem value={0}> Select App  </MenuItem> */}
                          {apps?.map((app, ind) => (
                            <MenuItem value={app.id} key={ind}>
                              {" "}
                              {app.name}{" "}
                            </MenuItem>
                          ))}
                        </Select>
                      ) : (
                        <Typography variant="h5" class="m-t-5">
                        {t('Support.CreateTicketForm.noApp')}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <FormControl variant="outlined" className="w-100">
                      <FormLabel className={classes.labelStyle}>
                        {" "}
                        {t('Support.CreateTicketForm.env')}{" "}
                      </FormLabel>
                      {environments?.length > 0 ? (
                        <Select
                          data-test="select-environment"
                          input={<OutlinedInput />}
                          name="env"
                          value={envValue}
                          //variant='outlined'
                          label={t('Support.CreateTicketForm.env')}
                          onChange={envValueChangeHandler}
                        >
                          <MenuItem value={0}>
                            {" "}
                            {t('Support.CreateTicketForm.selectEnv')}{" "}
                          </MenuItem>

                          {/* <MenuItem value={0}> Select Environment  </MenuItem> */}
                          {environments?.map((env, ind) => (
                            <MenuItem value={env.id} key={ind}>
                              {" "}
                              {env.name}{" "}
                            </MenuItem>
                          ))}
                        </Select>
                      ) : (
                        <Typography variant="h5" color="primary" class="m-t-5">
                        {t('Support.CreateTicketForm.noEnv')}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            )}

            <div>
              <MarkdownField value={value} onChange={setValue} data-test="markdown-field"/>

              <MultiFileUpload setFile={setFiles} data-test="multifile-upload"/>
            </div>
            <br />
            <Grid container justify="flex-end">
              <Button
                data-test="create-button"
                variant="contained"
                color="primary"
                className={classes.customButtonStyle}
                onClick={(e) => createHandler(e)}
                disabled={
                  disabled || buttonDisabled || !isValid() ? true : false
                }
                size="small"
              >
                {" "}
                {t('Support.CreateTicketForm.create')}
              </Button>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Card className={classes.paper}>
            <Grid spacing={1} container>
              <Grid
                className={classes.styleParagraph}
                item
                xs={12}
                sm={12}
                md={12}
                data-test="responseTime-component"
              >
                <p> {t('Support.CreateTicketForm.responseTime')} </p>
              </Grid>
            </Grid>

            <Grid spacing={1} container>
              <Grid
                className={classes.styleGridText}
                item
                xs={12}
                sm={5}
                md={5}
                data-test="days-component"
              >
                {t('Support.CreateTicketForm.days')}
              </Grid>
              <Grid
                className={classes.styleGridValue}
                item
                xs={12}
                sm={7}
                md={7}
                data-test="time-component"
              >
                {t('Support.CreateTicketForm.time')}
              </Grid>
            </Grid>

            <Grid spacing={1} container>
              <Grid
                className={classes.styleGridText}
                item
                xs={12}
                sm={5}
                md={5}
                data-test="generalIssue-component"
              >
                {t('Support.CreateTicketForm.generalIssue')}
              </Grid>
              <Grid
                className={classes.styleGridValue}
                item
                xs={12}
                sm={7}
                md={7}
                data-test="generalIssueTime-component"
              >
                4 Hour
              </Grid>
            </Grid>

            <Grid spacing={1} container>
              <Grid
                className={classes.styleGridText}
                item
                xs={12}
                sm={5}
                md={5}
                data-test="businessCritical-component"
              >
                {t('Support.CreateTicketForm.critical')}
              </Grid>
              <Grid
                className={classes.styleGridValue}
                item
                xs={12}
                sm={7}
                md={7}
                data-test="businessCriticalHour-component"
              >
                {t('Support.CreateTicketForm.hour')}
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>

      {props.isLoading && <BackdropLoader message={t('Support.CreateTicketForm.data')} data-test="data-loading" />}

      {props.envListLoading && (
        <BackdropLoader message={t('Support.CreateTicketForm.environment')} data-test="env-loading" />
      )}

      {props.fetchingTypes && <BackdropLoader message={t('Support.CreateTicketForm.fetching')} data-test="fetching-types" />}
      {props.creatingTicket && <BackdropLoader message={t('Support.CreateTicketForm.creating')} data-test="creating-ticket" />}
    </div>
  );
}

/* istanbul-ignore-next */
const mapStateToProps = (state) => ({
  projectList: state.ProjectListReducer.projectList,
  appList: state.ProjectReducer.appList,
  environmentList: state.AppsReducer.environmentList,
  envListLoading: state.AppsReducer.envListLoading,
  isLoading: state.ProjectListReducer.isLoading,
  currentUser: state.AuthReducer.user,
  creatingTicket: state.SupportReducer.creatingTicket,
  fetchingTypes: state.SupportReducer.fetchingTypes,
  supportTypes: state.SupportReducer.supportTypes,
  issueTypes: state.SupportReducer.issueTypes,
  sidebarProjectDetails: state.ProjectReducer.sidebarProjectDetails,
});

/* istanbul-ignore-next */
const mapDispatchtoProps = (dispatch) => {
  return {
    getProjectListApiCall: (history) =>
      dispatch(getProjectListApiCall(history)),
    fetchAppListByPID: (payload) => dispatch(fetchAppListByPID(payload)),
    fetchEnvironmentsByAppId: (payload) =>
      dispatch(fetchEnvironmentsByAppId(payload)),
    createUserTickets: (payload, callback) =>
      dispatch(createUserTickets(payload, callback)),
    getSupportTypes: () => dispatch(getSupportTypes()),
    getSidebarProjectDetails: () => dispatch(getSidebarProjectDetails()),
    updateBreadcrumb: (payload) => dispatch(updateBreadcrumb(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchtoProps)(CreateTicketForm);
