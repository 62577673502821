import {
  FETCH_PLUGINS_LIST,
  FETCH_PLUGINS_LIST_SUCCESS,
  FETCH_MORE_PLUGINS_LIST_SUCCESS,
  FETCH_PLUGINS_LIST_FAILURE,
  FETCH_PLUGINS_VERSIONS,
  FETCH_PLUGINS_VERSIONS_SUCCESS,
  FETCH_PLUGINS_VERSIONS_FAILURE,
  BEGIN_LOADING_INDICATOR,
  END_LOADING_INDICATOR,
  FETCH_REGIONS_SUCCESS,
  FETCH_REGIONS,
  FETCH_REGIONS_FAILURE,
  FETCH_REGION,
  FETCH_REGION_SUCCESS,
  FETCH_REGION_FAILURE,
  FETCH_CLUSTER,
  FETCH_CLUSTER_SUCCESS,
  FETCH_CLUSTER_FAILURE,
  CLEAR_PLUGINS_VERSIONS,
  CLEAR_PLUGINS_LIST_DATA
} from './actions';
import initialState from './initialState'
  const auth = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_PLUGINS_LIST:
        return {
          ...state,
          fetchpluginsCallStarted : true,
          fetchpluginsCallSuccess : false,
          fetchpluginsCallFailure : false,
      }
      case FETCH_MORE_PLUGINS_LIST_SUCCESS:
        return {
          ...state,
          pluginsList : state.pluginsList ?
           [...state.pluginsList, ...action.data]
          : [...action.data],
          fetchpluginsCallStarted : false,
          fetchpluginsCallSuccess : true,
          fetchpluginsCallFailure : false,
      }
      case FETCH_PLUGINS_LIST_SUCCESS:
        return {
          ...state,
          pluginsList : action.data,
          fetchpluginsCallStarted : false,
          fetchpluginsCallSuccess : true,
          fetchpluginsCallFailure : false,
      }
      case FETCH_PLUGINS_LIST_FAILURE:
        return {
          ...state,
          fetchpluginsCallStarted : false,
          fetchpluginsCallSuccess : false,
          fetchpluginsCallFailure : true,
      }
      case FETCH_REGIONS:
        return {
          ...state,
          fetchRegionsCallStarted : true,
          fetchRegionsCallSuccess : false,
          fetchRegionsCallFailure : false,
      }
      case FETCH_REGIONS_SUCCESS:
        return {
          ...state,
          regionsList : action.data,
          fetchRegionsCallStarted : false,
          fetchRegionsCallSuccess : true,
          fetchRegionsCallFailure : false,
      }
      case FETCH_REGIONS_FAILURE:
        return {
          ...state,
          fetchRegionsCallStarted : false,
          fetchRegionsCallSuccess : false,
          fetchRegionsCallFailure : true,
      }
      case FETCH_REGION:
        return {
          ...state,
          fetchingRegion : true,
          fetchingRegionSuccess : false,
          fetchingRegionFailure : false,
      }
      case FETCH_REGION_SUCCESS:
        return {
          ...state,
          regions : action.data,
          fetchingRegion : false,
          fetchingRegionSuccess : true,
          fetchingRegionFailure : false,
      }
      case FETCH_REGION_FAILURE:
        return {
          ...state,
          fetchingRegion : false,
          fetchingRegionSuccess : false,
          fetchingRegionFailure  : true,
      }
      case FETCH_CLUSTER:
        return {
          ...state,
          fetchingCluster : true,
          fetchingClusterSuccess : false,
          fetchingClusterFailure : false,
      }
      case FETCH_CLUSTER_SUCCESS:
        return {
          ...state,
          cluster : action.data,
          fetchingCluster : false,
          fetchingClusterSuccess : true,
          fetchingClusterFailure : false,
      }
      case FETCH_CLUSTER_FAILURE:
        return {
          ...state,
          fetchingCluster : false,
          fetchingClusterSuccess : false,
          fetchingClusterFailure  : true,
      }
      case FETCH_PLUGINS_VERSIONS:
        return {
          ...state,
          fetchPluginVersionsCallStarted : true,
          fetchPluginVersionsCallSuccess : false,
          fetchPluginVersionsCallFailure : false,
          fetchingVersionsList : true,
        }
      case FETCH_PLUGINS_VERSIONS_SUCCESS:
        return {
          ...state,
          versionsList : action.data,
          fetchPluginVersionsCallStarted : false,
          fetchPluginVersionsCallSuccess : true,
          fetchPluginVersionsCallFailure : false,
          fetchingVersionsList : false,

      }
      case CLEAR_PLUGINS_VERSIONS:
        return {
          ...state,
          versionsList : initialState.versionsList,
      } 
      case FETCH_PLUGINS_VERSIONS_FAILURE:
        return {
          ...state,
          fetchPluginVersionsCallStarted : false,
          fetchPluginVersionsCallSuccess : false,
          fetchPluginVersionsCallFailure : true,
          fetchingVersionsList : false,

      }
      case BEGIN_LOADING_INDICATOR : 
        return {
          ...state,
          isLoading : true
        }
      case END_LOADING_INDICATOR : 
        return {
          ...state,
          isLoading : false
      }
      case CLEAR_PLUGINS_LIST_DATA :
        return {
          ...state,
          pluginsList : initialState.pluginsList,
      } 
      default:
        return state
    }
  }
  
export default auth
