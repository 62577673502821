import { Button, Card, CardContent, Grid, IconButton, Link, Tooltip, Typography } from "@material-ui/core";
import AdjustIcon from "@material-ui/icons/Adjust";
import LaunchIcon from "@material-ui/icons/Launch";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import _ from 'lodash';
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { connect, ConnectedProps } from "react-redux";
import AddonsPopUp from "../../../../../components/addonspopup/AddonsPopUp";
import BackdropLoader from "../../../../../components/loader/BackdropLoader";
import { coreConversion, getDateInStandardFormat, getStatusColor, spaceCoversion } from "../../../../../helpers/utils";
import { fetchAddonInfo, fetchPlugInVersionConfig, clearPluginVersionConfig, clearAddonInfo, toggleExternalUrlAction } from "../../../redux/actions";
import "./addoninfo.css";
import { useTranslation } from 'react-i18next';
import KeyValueRow from "../../../../../components/keyvaluerow/KeyValueRow";
import { AppConstants } from "../../../../../constants/appconstants";
import { CreateApp_TemplateTypes } from "../../../../../constants/enums";
import { Dispatch } from "redux";
import { AddonCategory, AddonModel, AddonStatusModel, EnvironmentState } from "../../../../../models/Environment.model";

interface Props extends PropsFromRedux {
  installedAddon: {id: number},
  modify: (aId: number, payload: any)=>void,
  addon: AddonCategory,
  wsAddonStatus: AddonStatusModel[],
  envId: number,
  uninstall: (id: number, name: string) => void,
  environmentState:EnvironmentState,
  // loading: boolean,
  modifyingAddon: boolean,
  addonInfo: AddonModel
}

export function AddonInfo(props: Props) {
  const { addon } = props;
  const [editMode, setEditMode] = useState(false);
  const [modifyVariables, setModifyVariables] = useState<any>({});
  const [status, setStatus] = useState<any>("");
  const [t] = useTranslation()

  const toggleModify = () => {
    setEditMode(!editMode);
  };

  const modifyAddon = (payload: any) => {
    props.modify(props.installedAddon?.id, payload);
    // setEditMode(false);
  };

  const reset = () => {
    setEditMode(false)
    setModifyVariables({})
    setStatus("")
  }

  useEffect(() => {
      return(()=> {
          props.clearPluginVersionConfig();
          props.clearAddonInfo()
          reset()
      })       
  }, []);

  useEffect(() => {
    if (props.installedAddon && props.addon) {
      props.fetchAddonInfo(props.installedAddon.id, props.envId);
    }
  }, [props.installedAddon, props.envId]);

  const setAddonStatus = (_addon: AddonCategory) => {
    const wsStatus = props.wsAddonStatus?.find(a => {
			return a.name === _addon.name
		})
		const _detail_add_on = props.installedAddon
		let _status = null
		if(wsStatus && !_detail_add_on){
      _status = "Uninstalling"
		}else if(wsStatus && _detail_add_on){
      _status = wsStatus.status
		}else if(_detail_add_on && !wsStatus){
      _status = "Fetching Status"
    }	
    setStatus(_status)
  }

  useEffect(() => {
    if(props.addon){
      setAddonStatus(props.addon)
    }   
  }, [props.wsAddonStatus, props.addon, props.installedAddon]);

  useEffect(() => {
    const { addonInfo, pluginVersionConfig } = props;
    if(addonInfo)
    {
      if(addonInfo.plugin_version && addonInfo.plugin_version.id > 0 && !pluginVersionConfig.type) {
        props.fetchPlugInVersionConfig(addonInfo.plugin_version.id)
      }
    }
  }, [props.addonInfo]);

  const getConfigObject = (key: string) => {
    const replacedVal = key.replace(/~/g, ".properties.");
    return _.get(props.pluginVersionConfig, "properties." + replacedVal);
  }

  const getValueProps = (keyName: string, keyPath: string, node: string) => {
    if(!node) return []
    let newObj: any = []
    let _keyName = keyPath ? keyPath + "~" + keyName : keyName;
    if (typeof node !== "object") {
      const configObject = getConfigObject(_keyName)
      if(configObject)
      {
        newObj.push({
          key : configObject.title,
          value : node,
          hidden: configObject.hidden,
          fullKeyName: _keyName,
          isShowText: false
        })
      }
    } 
    else {
      Object.keys(node).forEach((key) => {
        const _newObj = getValueProps(key, _keyName, node[key]);
        newObj = [...newObj, ..._newObj]
      });
    }
    return newObj;
  }

  useEffect(() => {
    const { addonInfo, pluginVersionConfig } = props;
    if(addonInfo)
    {
      if (addonInfo.id > 0 && pluginVersionConfig.type) {
        let varKeys = addonInfo.variables ? Object.keys(addonInfo.variables) : [];
        if(varKeys && varKeys.length > 0)
        {
          let modVars: any = [];
          varKeys.forEach((key) => {
            const _modVars = getValueProps(key, "", addonInfo.variables[key]);
            modVars = [...modVars, ..._modVars]
          });
          setModifyVariables(modVars);
        }
      }
    }
  }, [props.addonInfo, props.pluginVersionConfig]);

  const handleShowPassword = (key: any, isShow: boolean) => {
    let _variables = [...modifyVariables];
    _variables.map(x => {
      if(x.fullKeyName === key)
      {
        x.isShowText = isShow;
      }
    })
    setModifyVariables(_variables);
  }

  const handleUninstall = () => {
    props.uninstall(props.addon?.id, props?.addon.Name ?? props?.addon.name);
  };

  const toggleExternalUrl = (externl_url:string)=>() => {
     props.toggleExternalUrlAction(props.envId,props.addonInfo.id,!externl_url)
  }

  const renderActiveOnLink = () => {
    if (props.environmentDetails.service_type === CreateApp_TemplateTypes.op ) {
      if (
        props.addonMetaData?.envs?.length &&
        props.addonMetaData?.envs[0]?.env?.length &&
        props.addonInfo?.plugin_version?.plugin?.service_detail
          ?.service_type === "external"
      ) {
        const absoluteUrl = props.addonMetaData.envs[0].env.filter(
          (env: any) => env.name === "PMA_ABSOLUTE_URI"
        );

        if (absoluteUrl.length) {
          return (
            <KeyValueRow
              keyXs={2}
              rowKey={t("Environment.AddonInfo.activeOn")}
              rowValue={
                <Link
                  href={absoluteUrl[0].value}
                  target="_blank"
                  className="cnameLink oneLine"
                  rel="noreferrer"
                  underline="always"
                  data-test="addon-link"
                >
                  {absoluteUrl[0].value}
                  <LaunchIcon className="openLinkIcon" />
                </Link>
              }
            />
          );
        }
      }
    } else {
      if (
        props.addonInfo?.plugin_version?.plugin?.service_detail
          ?.service_external_url &&
        props.addonInfo?.plugin_version?.plugin?.service_detail
          ?.service_type === "external" &&
        props.environmentState.cname
      ) {
        return (
          <KeyValueRow
            keyXs={2}
            rowKey={t("Environment.AddonInfo.activeOn")}
            rowValue={
              <Link
                href={`https://${props.environmentState.cname}${props.addonInfo?.plugin_version?.plugin?.service_detail.service_external_url}`}
                target="_blank"
                className="cnameLink oneLine"
                rel="noreferrer"
                underline="always"
                data-test="addon-link"
              >
                {`${props.environmentState.cname}${props.addonInfo?.plugin_version?.plugin?.service_detail.service_external_url}`}
                <LaunchIcon className="openLinkIcon" />
              </Link>
            }
          />
        );
      }
    }
  };
  //const copyText = (txt) => {};
  return (
      <div data-test="main-container">   
          {editMode && (
          <AddonsPopUp
            open={editMode}
            setOpen={setEditMode}
            action={modifyAddon}
            addon={props.addon}
            source={3}
            envAddon={props.addonInfo}
            // environmentState={props.environmentState}
            // loading={props.loading}
            // fetchPluginVersions={props.fetchPluginVersions}
          />
      )}
          {!editMode && (
          <>
              <Grid>
                  <Grid container spacing={2}>
                      <Grid item md={12} sm={12} xs={12}>
                          <Card>
                              <CardContent>
                                  <Grid container spacing={2}>
                                      <Grid item md={6}>
                                        <Grid container>
                                          <Grid item>
                                                <img src={props.addon?.image} width="50px" alt='' data-test="addon-image"/>
                                          </Grid>
                                          <Grid>
                                                <Typography variant="h3" data-test="addon-name" className="addons-title">
                                                  {props?.addon?.Name ?? props.addon?.name}
                                                </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>

                                      <Grid item md={6}>
                                        
                                        <Grid container justify="flex-end" spacing={2}>
                                          {status === AppConstants.AddonStatus.Running && (
                                            <Grid item>
                                              <Button
                                              variant="contained"
                                              color="primary"
                                              onClick={toggleModify}
                                              data-test="modify-btn"
                                              >
                                                {" "}
                                                {t('Environment.AddonInfo.modify')}{" "}
                                              </Button>
                                            </Grid>
                          )}              
                              {status === AppConstants.AddonStatus.Running && (
                                            <Grid item>
                                              <Button
                                              variant="contained"
                                              color="primary"
                                              onClick={toggleExternalUrl(props.addonInfo?.plugin_version?.plugin?.service_detail?.external_url)}
                                              data-test="modify-btn"
                                              >
                                                {" "}
                                             {props.addonInfo?.plugin_version?.plugin?.service_detail?.external_url?(t("Environment.AddonInfo.DisableExternalUrl")) : t("Environment.AddonInfo.EnableExternalUrl")}   
                                             </Button>
                                            </Grid>
                          )}    
                                         

                                          <Grid item>
                                                <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleUninstall}
                                                data-test="uninstall-btn"
                                                >
                                                  {" "}
                                                  {t('Environment.AddonInfo.uninstall')}{" "}
                                                </Button>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                  </Grid>

                                  <Grid>
                                      {/* <Grid container spacing={2}>
                                        <Grid item xs={6} md={2}>
                                          <Typography color="primary" variant="h6">
                                                {" "}
                                                {t('Environment.AddonInfo.version')}{" "}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={6} md={10} data-test="addon-version">
                                          {props.addonInfo?.version?.name}
                                        </Grid>
                                      </Grid> */}
                                      <KeyValueRow rowKey={t('Environment.AddonInfo.version')} rowValue={props.addonInfo?.version?.name} data-test="addon-version"/>
                                      <KeyValueRow  rowKey={t('Environment.OverviewTab.ram')} rowValue={spaceCoversion(props.addonInfo?.resource?.memory)} data-test="addon-memory"/>
                                      <KeyValueRow  rowKey={t('Environment.AddonInfo.cpu')} rowValue={coreConversion(props.addonInfo?.resource?.cores)} data-test="addon-core"/>
                                      <KeyValueRow rowKey={t('Environment.AddonInfo.created')} rowValue={getDateInStandardFormat(props.addonInfo?.createdat)} data-test="addon-created"/>
                                      <KeyValueRow  rowKey={t('Environment.AddonInfo.updated')} rowValue={getDateInStandardFormat(props.addonInfo?.updatedat)} data-test="addon-updated" />
                                      {addon?.error && status === "Failed" && (
                                        <KeyValueRow  rowKey={t('Environment.AddonInfo.reason')} rowValue={addon.error} />
                                        )}
                                      <KeyValueRow  rowKey={t('Environment.AddonInfo.status')} rowValue={(
                                        <>
                                          <Tooltip title="State">
                                            {status !== "Fetching Status" &&
                                            status !== "Uninstalling" ? (
                                              <AdjustIcon
                                                className="topIcon"
                                                style={{ color: getStatusColor(status) }}
                                              />
                                            ) : (
                                              <Skeleton
                                                circle={true}
                                                height={10}
                                                width={10}
                                                style={{ marginRight: "5" }}
                                              />
                                            )}
                                          </Tooltip>
                                          <span
                                              title={props?.addon?.error}
                                              style={{ color: getStatusColor(status) }}
                                              data-test="addon-status"
                                          >
                                            {status !== "Fetching Status" &&
                                            status !== "Uninstalling" ? (
                                              status
                                            ) : (
                                              <Skeleton width={80} height={12} />
                                            )}
                                          </span>
                                        </>
                                        )}
                                      />                                      
                                  </Grid>
                              </CardContent>
                          </Card>
                      </Grid>
                      {status === AppConstants.AddonStatus.Running && (
                      <Grid item md={12} xs={12}>
                          <Card>
                              <CardContent>
                                  <Grid direction="column">
                                    <KeyValueRow  rowKey={t('Environment.AddonInfo.replicas')} rowValue={props.addonInfo.replicas} className="bggrey" />
                                    {/* <Grid container spacing={2} className="bggrey">
                                      <Grid item xs={5} md={2}>
                                            <Typography color="primary" variant="h6">
                                              {" "}
                                              {t('Environment.AddonInfo.replicas')}{" "}
                                            </Typography>
                                      </Grid>
                                      <Grid item xs={5} md={8} data-test="addon-replica">
                                            {props.addonInfo.replicas}
                                      </Grid>
                                    </Grid> */}
                                    {renderActiveOnLink()}
                                    {/* {props.addonInfo?.plugin_version?.plugin?.service_detail?.service_external_url && 
                                      props.addonInfo?.plugin_version?.plugin?.service_detail?.service_type === "external" && props.environmentState.CName && status === "Running" && (
                          <KeyValueRow
                            rowKey={t('Environment.AddonInfo.activeOn')}
                            rowValue={(
                              <Link
                                href={`https://${props.environmentState.CName}${
                                      props.addonInfo?.plugin_version?.plugin?.service_detail.service_external_url
                                  }`}
                                target="_blank"
                                className="cnameLink oneLine"
                                rel="noreferrer"
                                underline="always"
                                data-test="addon-link"
                              >
                                {`${props.environmentState.CName}${props.addonInfo?.plugin_version?.plugin?.service_detail.service_external_url}`}
                                <LaunchIcon className="openLinkIcon" />
                              </Link>)}
                          />
                                        
                        // <Grid container spacing={2} className="bggrey">
                        //     <Grid item xs={5} md={2}>
                        //         <Typography color="primary" variant="h6">
                        //             {" "}
                        //             {t('Environment.AddonInfo.activeOn')}{" "}
                        //         </Typography>
                        //     </Grid>
                        //     <Grid item xs={5} md={8}>
                        //         <Link
                        //           href={`https://${props.environmentState.CName}${
                        //             props.addonInfo?.plugin_version?.plugin?.service_detail.service_external_url
                        //         }`}
                        //           target="_blank"
                        //           className="cnameLink oneLine"
                        //           rel="noreferrer"
                        //           underline="always"
                        //           data-test="addon-link"
                        //         >
                        //             {`${props.environmentState.CName}${props.addonInfo?.plugin_version?.plugin?.service_detail.service_external_url}`}
                        //             <LaunchIcon className="openLinkIcon" />
                        //         </Link>
                        //         {}
                        //     </Grid>
                        // </Grid>
                        ) } */}

                                    {props.addonInfo?.plugin_version?.plugin?.service_detail &&
                          status === AppConstants.AddonStatus.Running &&
                          Object.keys(
                            props.addonInfo?.plugin_version?.plugin?.service_detail
                          )
                            ?.filter(
                              (v) =>
                                typeof props.addonInfo?.plugin_version?.plugin
                                  ?.service_detail[v] !== "object"
                            )
                            .map((k, ind) => (
                              <KeyValueRow  key = {ind} rowKey={k} rowValue={props.addonInfo?.plugin_version?.plugin
                                ?.service_detail[k]} copy={true} className={ind % 2 ? "" : "bggrey"}
                              />
                            ))}
                          { modifyVariables && modifyVariables.length > 0 && 
                          modifyVariables.map((item: any, ind: number) => 
                          <KeyValueRow 
                            
                            key = {ind} 
                            rowKey={item.key}
                            copyText={item.value}
                            rowValue={(
                              <>
                                { 
                                    item.hidden && 
                                    <>
                                        {
                                        !item.isShowText &&  <>********** <IconButton size="small"><Visibility onClick={() => handleShowPassword(item.fullKeyName, true)} /></IconButton></>
                                      }
                                        {
                                        item.isShowText && <>{ item.value } <IconButton size="small"><VisibilityOff onClick={() => handleShowPassword(item.fullKeyName, false)} /></IconButton> </>
                                      }
                                    </>
                                  }
                                        {
                                    !item.hidden && <>{ item.value }</>
                                  }
                              </>
                            )} 
                            copy={true} 
                            className={ind % 2 ? "" : "bggrey"}
                          />
                          )}
                                  </Grid>
                              </CardContent>
                          </Card>
                      </Grid>
              )}
                  </Grid>
              </Grid>
          </>
      )}
          {props.modifyingAddon && <BackdropLoader message={t('Environment.AddonInfo.modifyingAddon')} data-test="loader" />}
          {(props.fetchingAddon || props.toggleExternalUrlFetching) && (
          <BackdropLoader message={t('Environment.AddonInfo.fetching')}  data-test="backdropLoader"/>
      )}
      </div>
  );
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  addonInfo: state.EnvironmentReducer.addonInfo,
  addonMetaData: state.EnvironmentReducer.addonMetaData,
  fetchingAddon: state.EnvironmentReducer.fetchingAddon,
  toggleExternalUrlFetching: state.EnvironmentReducer.toggleExternalUrlFetching,
  pluginVersionConfig: state.EnvironmentReducer.pluginVersionConfig,
  environmentDetails: state.EnvironmentReducer.environmentDetails,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  // fetchAddons: (id) => dispatch(fetchAddons(id)),
  fetchAddonInfo: (aId: number, eId: number) => dispatch(fetchAddonInfo(aId, eId)),
  fetchPlugInVersionConfig: (id: number) => dispatch(fetchPlugInVersionConfig(id)),
  clearPluginVersionConfig: () => dispatch(clearPluginVersionConfig()),
  clearAddonInfo: () => dispatch(clearAddonInfo()),
  toggleExternalUrlAction:(eid:number,aid:number,status:boolean)=>dispatch(toggleExternalUrlAction(eid,aid,status))
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AddonInfo);
