import React, { useEffect, useState } from "react";
import { CiVariables } from "./variablesTab";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { updateEnvironment } from "../../../redux/actions";
import { EnvironmentModel } from "../../../../../models/Environment.model";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import _ from "lodash";
import { Card, CardContent, Box, CardHeader,Divider } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  headerWrap: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 16,
    paddingRight: 16,
  },
  onEditActions: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 10,
  },
  cardMargin:{
    marginTop:15
  }
}));

interface I_props extends PropsFromRedux {
  actionType: "create";
  syncCIVars?: (ciVars: any[]) => void;
  changeHash?: any;
  environmentDetails: null;
}

interface I_propsUpdate extends PropsFromRedux {
  actionType: "update";
  syncCIVars?: (ciVars: any[]) => void;
  changeHash?: any;
  environmentDetails: EnvironmentModel;
}

type IProps = I_props | I_propsUpdate;
export interface Variables {
  key: string;
  type?: string;
  value: string;
  isProtected?: boolean;
}

export interface VariablesLocal extends Variables {
  id: number;
  isShowPassword?: boolean;
  isError?: string;
}

interface I_context {
  isError: boolean;
}
export const VariablesAdapter = ({
  actionType,
  environmentDetails,
  updateEnvironment, syncCIVars
}: IProps) => {
  const [ciVars, setCiVars] = useState<VariablesLocal[]>([]);
  const [edit, setEdit] = useState(false);
  const [disableUpdate, setDisableUpdate] = useState(false);
  const [isError, setIsError] = useState(false);
  const [renderKey, setRenderKey] = useState(0)
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    
    if (environmentDetails===null) return;

    if (!environmentDetails.scripts.ci_variables) return;

    const ci_variables = environmentDetails.scripts.ci_variables;

    let ciVars: VariablesLocal[] = [];

    //? trasformation to internal state

    Object.keys(ci_variables).forEach((ciKey: string, ciKeyIndex: number) => {
      ciVars.push({
        key: ciKey,
        value: ci_variables[ciKey],
        id: ciKeyIndex + 1,
        isError: "",
        isShowPassword: false,
        isProtected: true,
      });
    });

    setCiVars(ciVars);
    setRenderKey(edit ? 1 : 0)
  }, [environmentDetails, edit]);

  useEffect(() => {
    if (actionType !== "create") return
    if (isError) return
    if(syncCIVars){
      syncCIVars(ciVars)
    }
  }, [ciVars, isError, actionType])

  useEffect(() => {
    if (shouldUpdate()) {
      setDisableUpdate(false);
      return;
    }
    setDisableUpdate(true);
  }, [ciVars]);

  const transformVariables = (): { [key: string]: string } => {
    let payloadVars: { [key: string]: string } = {};
    ciVars.forEach((ciVar: { key: string; value: string }) => {
      if (!ciVar.key) return;
      let key = ciVar.key;
      let val = ciVar.value;
      payloadVars[key] = val;
    });
    return payloadVars;
  };

  const shouldUpdate = () => {
    if (actionType === "create") return
    const envCiVariables = environmentDetails.scripts.ci_variables;
    const userCiVariables = transformVariables();
    const hasUpdated = !_.isEqual(envCiVariables, userCiVariables);
    return hasUpdated;
  };

  const updateEnvironmentCallBack = (
    id: number,
    jsonBody: unknown
  ) => {
    if (actionType !== "update") return;
    updateEnvironment(id, jsonBody)
    toggleEdit()
  };

  const updateVariables = () => {
    if (actionType !== "update") return;
    let scripts = { ...environmentDetails.scripts };
    if (!scripts["ci_variables"]) return;
    scripts.ci_variables = transformVariables();
    updateEnvironmentCallBack(environmentDetails.id, { scripts: scripts });
  };

  const toggleEdit = () => {
    setEdit(!edit);
  };

  const copyContext = (context: I_context) => {
    setIsError(context.isError);
  };

  const setCiVariables = (ciVars: VariablesLocal[]) => {
    setCiVars(ciVars)
  }
  return (
    <Card className={classes.cardMargin}>
      <div className={classes.headerWrap}>
        <CardHeader
          title={t("Environment.Generals.ciVariables")}
          data-test="env-var-cardHeader"
        />

        {actionType === "update" && !edit ? (
          <>
            <IconButton onClick={toggleEdit} color="primary">
              <EditIcon />
            </IconButton>
          </>
        ) : null}
        {edit ? (
          <div className={classes.onEditActions}>
            <Button
              variant="contained"
              disabled={isError || disableUpdate}
              color="primary"
              disableElevation={true}
              onClick={updateVariables}
            >
              Update variables
            </Button>
            <Button
              variant="contained"
              color="secondary"
              disableElevation={true}
              onClick={toggleEdit}
            >
              cancel
            </Button>
          </div>
        ) : null}
      </div>
      <Divider/>
      {/* //!  key implementation to be disccussed.... */}
      <CardContent key={renderKey}>
        <CiVariables
          ciVars={ciVars}
          copyContext={copyContext}
          edit={actionType === "create" ? true : edit}
          setCiVariables={setCiVariables}
        />
      </CardContent>
    </Card>
  );
};

const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    updateEnvironment: (
      id: number,
      jsonBody: unknown,
      source?: number,
      changeHash?: (hash: string, val: number, envId: number) => void
    ) => dispatch(updateEnvironment(id, jsonBody, source, changeHash)),
  };
};

/* istanbul ignore next */
const connector = connect(null, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(VariablesAdapter);
