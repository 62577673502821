import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import HelpIcon from '@material-ui/icons/Help';
import { toast } from 'react-toastify';
import { ToastOptions } from 'react-toastify';
//import { css } from 'glamor';

type MessageProps={msg:string,icon?:JSX.Element}
const Message = ({ msg, icon}:MessageProps) => (
    <>
        <Grid container direction="row">
            <Grid item>{icon}</Grid>
      &nbsp;&nbsp;&nbsp;
            <Grid item>
                <Typography variant="subtitle1" style={ { color : 'white' } }>{msg}</Typography>
            </Grid>
        </Grid>
    </>
);

export const clearNotifications = () => {
  toast.clearWaitingQueue();
  toast.dismiss();
}

const getToastOptions = (time = 4000):ToastOptions => ({
  position: toast.POSITION.TOP_RIGHT,
  autoClose: time,
  className: 'rotateY animated',
  onOpen:()=>{
    toast.clearWaitingQueue()
  },
})


const ToastConfig = {
  default(msg:string) { 
    return toast(<Message msg={ msg } />)
  },
  success(msg:string, time:number) {
    return toast.success(<Message msg={ msg } icon={ <CheckIcon /> } />, getToastOptions(time));
  },
  info(msg:string) {
    return toast.info(<Message msg={ msg } icon={ <InfoIcon /> } />, getToastOptions());
  },
  warn(msg:string) {
    return toast.warn(<Message msg={ msg } icon={ <HelpIcon /> } />, getToastOptions());
  },
  error(msg:string) {
    return toast.error(<Message msg={ msg } icon={ <WarningIcon /> } />, getToastOptions());
  }
};

export default ToastConfig;
