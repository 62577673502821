import React, { Component } from "react";
import { withTranslation,WithTranslation } from "react-i18next";
import { connect,ConnectedProps } from "react-redux";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import ScheduleOutlinedIcon from "@material-ui/icons/ScheduleOutlined";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import StraightenOutlinedIcon from "@material-ui/icons/StraightenOutlined";
import { withStyles } from "@material-ui/core/styles";
import CronJobCreate from "./CronJobCreate";
import {
  Link,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  IconButton,
  ButtonBase,
  Menu,
  MenuItem,
  FormControlLabel,
  Switch,
  createStyles, WithStyles
} from "@material-ui/core";
import CronJobInfo from "./CronJobInfo";
import {
  fetchJobList,
  deleteCronJob,
  runCronJob,
  clearCronJobList,
  fetchCronJob,
  updateCronJob,
} from "./redux/actions";
import { getDiffDays } from "../../helpers/utils";
import BackdropLoader from "../../components/loader/BackdropLoader";
import ConfirmDeletePopup from "../../components/confirmdeletepopup/ConfirmDeletePopup";
import ConfirmActionPopup from "../../components/confirmactionpopup/ConfirmActionPopup";
import cronstrue from "cronstrue";
import CronJobSkeleton from "../../components/skeletons/CronJobSkeleton";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import { isAuthorized } from "../../helpers/utils";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import { AppConstants } from "../../constants/appconstants";
import { Prompt } from "react-router-dom";
import NoContentImage from "../../components/nocontentimagecontainer/NoContentImage";
import { LabelHandler } from "../../components/labelHandler/LabelHandler";
import { DateHandler } from "../../components/dateHandler/DateHandler"
import { Dispatch } from "redux";
import { CronJobModel,CronStatus } from "../../models/cron.model";
/* istanbul ignore next */
const useStyles = () =>createStyles ({
  jobHeader: {
    display: "flex",
  },
  createJob: {
    marginLeft: "auto",
  },

  jobStatus: {
    paddingLeft: 15,
    display: "flex",
  },
});

type MsgProps={checked:boolean,name:string}
/* istanbul ignore next */
function EnableDisableMsg(props:MsgProps) {
  return (
    <>
      Are you sure to {props.checked ? "enable" : "disable"}{" "}
      <strong>{props.name}</strong> job?
    </>
  );
}

interface State {
  mode:                      number;
  currentJobId:              number|null;
  anchorEl:                  Element | ((element: Element) => Element) | null;
  deletingJobName:           string;
  isDeleteConfirmPopupOpen:  boolean;
  isRunnowConfirmPopupOpen:  boolean;
  isSuspendConfirmPopupOpen: boolean;
  suspendConfirmMessage:     string|JSX.Element;
  suspendJsonBody:           CronJobModel|null;
  suspendData:               boolean|null;
  isBlocking:                boolean;
  suspendCallback:          (()=>void)|null
}

interface Props extends PropsFromRedux, WithStyles<typeof useStyles>,WithTranslation{

}

export class CronJobList extends Component<Props,State> {
  constructor(props:Props) {
    super(props);
    this.state = {
      mode: 0, //0:List; 1:Create/Edit; 2:Info
      currentJobId: 0,
      anchorEl: null,
      // showLoader: false,
      // loaderMsg: ''
      deletingJobName: "",
      isDeleteConfirmPopupOpen: false,
      isRunnowConfirmPopupOpen: false,
      isSuspendConfirmPopupOpen: false,
      suspendConfirmMessage: "",
      suspendJsonBody: null,
      suspendData: null,
      isBlocking: false,
      suspendCallback: null
    };
  }

  componentDidMount() {
    if (this.props.environmentDetails && this.props.environmentDetails.id > 0) {
      this.props.fetchJobList(this.props.environmentDetails.id);
      this.props.fetchCronJob(this.props.environmentDetails.id);
    }
  }

  componentWillUnmount() {
    this.props.clearCronJobList();
  }

  UNSAFE_componentWillReceiveProps(nextProps:Props) {
    if (nextProps) {
      if (nextProps.environmentDetails.id > 0) {
        if (
          !this.props.environmentDetails.id ||
          this.props.environmentDetails.id !== nextProps.environmentDetails.id
        ) {
          this.props.fetchJobList(nextProps.environmentDetails.id);
          this.props.fetchCronJob(nextProps.environmentDetails.id);
        }
      }
    }
  }

  handleAddJob = () => {
    this.setState({
      mode: 1,
      currentJobId: null,
      deletingJobName: "",
    });
  };

  handleJobClick = (jobId:CronJobModel["id"]) => {
    this.handleMenuClose();
    this.setState({
      mode: 2,
      currentJobId: jobId,
    });
  };

  handleJobClickAction = () => {
    this.handleMenuClose();
    this.setState({
      mode: 2,
      // currentJobId: jobId,
    });
  };

  createSuccessHandler = () => {
    this.setState({
      mode: 0,
      currentJobId: null,
      deletingJobName: "",
    });
    this.props.fetchJobList(this.props.environmentDetails.id);
  };

  handleJobMenu = (e:React.MouseEvent<HTMLButtonElement>, jobId:CronJobModel["id"], jobName:string) => {
    this.setState({
      anchorEl: e.currentTarget,
      currentJobId: jobId,
      deletingJobName: jobName,
    });
  };

  handleMenuClose = () => {
    this.setState({
      anchorEl: null,
      // currentJobId: 0,
      // deletingJobName: ""
    });
  };

  handleBack = () => {
    this.handleMenuClose();
    this.setState(
      {
        mode: 0,
        currentJobId: 0,
      },
      () => {
        setTimeout(() => {
          this.setState({
            isBlocking: false,
          });
        }, 0);
      }
    );

    this.props.fetchJobList(this.props.environmentDetails.id);
    this.props.fetchCronJob(this.props.environmentDetails.id);
  };

  handleJobEdit = () => {
    this.handleMenuClose();
    this.setState({
      mode: 1,
    });
  };

  //Delete section
  handleJobDelete = () => {
    this.handleMenuClose();
    this.setState({
      isDeleteConfirmPopupOpen: true,
      // currentJobId: jobId,
      // deletingJobName: jobName
    });
  };

  handleDeleteDisAgreeHandler = () => {
    this.setState({
      isDeleteConfirmPopupOpen: false,
      // currentJobId: 0,
      // deletingJobName: ''
    });
  };

  handleDeleteAgreeHandler = () => {
    if(!this.state.currentJobId || !this.props.environmentDetails.id) return ;
    this.props.deleteCronJob(
      this.props.environmentDetails.id,
      this.state.currentJobId
    );
    this.setState({
      mode: 0,
      isDeleteConfirmPopupOpen: false,
      // currentJobId: 0,
      // deletingJobName: ''
    });
  };
  //Delete section end

  //Run now section
  handleJobRunnow = () => {
    this.handleMenuClose();
    this.setState({
      isRunnowConfirmPopupOpen: true,
      // currentJobId: jobId
    });
  };

  handleRunnowDisAgreeHandler = () => {
    this.setState({
      isRunnowConfirmPopupOpen: false,
      // currentJobId: 0,
    });
  };

  handleRunnowAgreeHandler = () => {
    if(!this.state.currentJobId || !this.props.environmentDetails.id) return ;
  
    this.props.runCronJob(
      this.props.environmentDetails.id,
      this.state.currentJobId
    );
    this.setState({
      isRunnowConfirmPopupOpen: false,
      // currentJobId: 0,
    });
  };
  //Run now section end

  getLastRunDateTime = (jobId:CronJobModel["id"] )=> {
    let finalString = "";
    if (
      jobId > 0 &&
      this.props.cronjob_status &&
      this.props.cronjob_status.length > 0
    ) {
      const currentJobStatus = this.props.cronjob_status.find(
      (x:CronStatus) => x.id === jobId
      );
      if (currentJobStatus) {
        if (
          currentJobStatus.Data &&
          currentJobStatus.Data.Status &&
          currentJobStatus.Data.Status.LastScheduleTime &&
          currentJobStatus.Data.Status.LastScheduleTime.Time
        ) {
          finalString = getDiffDays(
            currentJobStatus.Data.Status.LastScheduleTime.Time
          );
        }
      }
    }
    return finalString;
  };

  //Suspend section
  handleUpdateSuspend = (e:React.ChangeEvent<HTMLInputElement>, jobDetails:CronJobModel, callBack?:()=>void) => {
    this.setState({
      isSuspendConfirmPopupOpen: true,
      suspendConfirmMessage: (
        <EnableDisableMsg checked={e.target.checked} name={jobDetails.name} />
      ),
      suspendJsonBody: jobDetails,
      suspendData: e.target.checked,
      suspendCallback: callBack?callBack:null,
    });
  };

  handleSuspendDisAgreeHandler = () => {
    this.setState({
      isSuspendConfirmPopupOpen: false,
      suspendConfirmMessage: "",
      suspendJsonBody: null,
      suspendData: null,
      suspendCallback: null,
    });
  };

  handleSuspendAgreeHandler = () => {
  if(  !this.state.suspendJsonBody){
    return;
  }
    let { suspendJsonBody } = this.state;
 

    suspendJsonBody.suspend = !this.state.suspendData;
    this.props.updateCronJob(
      this.props.environmentDetails.id,
      this.state.suspendJsonBody.id,
      suspendJsonBody,
      this.state.suspendCallback
    );
    this.handleSuspendDisAgreeHandler();
  };
  //Run now section end

  handleStateBlocking = (blockedStatus:boolean) => {
    this.setState({
      isBlocking: blockedStatus,
    });
  };

  render() {
    const { classes, cronjobList, environmentState, envRole, t } = this.props;

    return (
      <div data-test="cronJobContainer">
        <Prompt
          when={this.state.isBlocking}
          message={() => t("CronJob.CronJobList.errorMessage")} //  ${location.pathname}
        />
        {this.state.mode === 0 && (
          <div data-test="cronjobListContainer">
            <div className={classes.jobHeader}>
              {/* <TextField
                                className={classes.margin}
                                id="input-with-icon-textfield"
                                label=""
                                variant='outlined'
                                placeholder='Search jobs'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon className={classes.icon} /> 
                                        </InputAdornment>
                                    ),
                                }}
                            /> */}
              {isAuthorized("create", envRole.name) && (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.createJob}
                  onClick={this.handleAddJob}
                  disabled={
                    !environmentState ||
                      environmentState.state !==
                      AppConstants.EnvironmentStatus.Running
                      ? true
                      : false
                  }
                  data-test="cronJobCreateButton"
                >
                  {t("CronJob.CronJobList.addJob")}
                </Button>
              )}
            </div>
            <div className="m-t-20" data-test="cronJobsCardContainer">
              {cronjobList &&
                cronjobList.length > 0 &&
                cronjobList.map((job:CronJobModel, index:number) => {
                  return (
                    <Card
                      key={index}
                      className="m-b-20 w-100"
                      data-test="cronJobCard"
                    >
                      <CardContent>
                        <Grid container alignItems="center">
                          <Grid
                            item
                           md={10}
                            xs={12}
                            onClick={() => this.handleJobClick(job.id)}
                            data-test="cron-job-grid-item"
                          >
                            <Grid container spacing={1}>
                              <Grid item md={12} xs={12}>
                                {/* <p className="jobname oneLine" title={ job.name }> { job.name } </p> */}
                                {/* <Link href="#" onClick={ () => this.handleJobClick(job.id) } underline='none' className="jobname oneLine" title={ job.name }>{ job.name }</Link> */}
                                <ButtonBase>
                                  <Typography
                                    variant="h4"
                                    color="primary"
                                    className="oneLine"
                                    title={job.name}
                                    onClick={() => this.handleJobClick(job.id)}
                                    data-test="cron-job-name"
                                  >
                                    {" "}
                                    {job.name}{" "}
                                  </Typography>
                                </ButtonBase>
                              </Grid>
                              <Grid item md={12} xs={12}>
                                <Grid container spacing={3}>
                                  {job.schedule && (
                                    <Grid
                                      item
                                      md={2}
                                      xs={6}
                                      className="header-details-grid"
                                    >
                                      <LabelHandler
                                        label={cronstrue.toString(job.schedule)}
                                        icon={<DateRangeOutlinedIcon />}
                                        iconTooltip={t(
                                          "CronJob.CronJobInfo.jobTime"
                                        )}
                                        labelTooltip={cronstrue.toString(
                                          job.schedule
                                        )}
                                      />
                                    </Grid>
                                  )}
                                  {job.user && (
                                    <Grid
                                      item
                                      md={2}
                                      xs={6}
                                      className="header-details-grid"
                                    >
                                      <LabelHandler
                                        label={`${job.user?.first_name ?? ""} ${
                                          job.user?.last_name ?? ""
                                        } `}
                                        icon={<PersonOutlineIcon />}
                                        iconTooltip={t(
                                          "CronJob.CronJobInfo.createdBy"
                                        )}
                                        labelTooltip={t(
                                          "CronJob.CronJobInfo.createdBy"
                                        )}
                                      />
                                    </Grid>
                                  )}
                                  {job.createdat && (
                                    <Grid
                                      item
                                      md={2}
                                      xs={6}
                                      className="header-details-grid"
                                    >
                                      <DateHandler
                                        date={job.createdat.toString()}
                                        icon={<ScheduleOutlinedIcon />}
                                        // iconTooltip={t('CronJob.CronJobInfo.createdTime')}
                                      />
                                    </Grid>
                                  )}
                                  {job.image && (
                                    <Grid
                                      item
                                      md={3}
                                      xs={6}
                                      className="header-details-grid"
                                    >
                                      <LabelHandler
                                        label={job.image}
                                        icon={<StraightenOutlinedIcon />}
                                        iconTooltip={t(
                                          "CronJob.CronJobInfo.image"
                                        )}
                                      />
                                    </Grid>
                                  )}
                                  {this.getLastRunDateTime(job.id) && (
                                    <Grid
                                      item
                                      md={2}
                                      xs={6}
                                      className="header-details-grid"
                                    >
                                      <LabelHandler
                                        label={this.getLastRunDateTime(job.id)}
                                        icon={<DirectionsRunIcon />}
                                        iconTooltip={t("CronJob.CronJobInfo.lastRun")}
                                      />
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          {/* {isAuthorized("update", envRole.name) && ( */}
                          <Grid
                            item
                            md={1}
                            className={`center ${
                              !isAuthorized("update", this.props.envRole.name)
                                ? "noCursor"
                                : ""
                            }`}
                          >
                            <div className={classes.jobStatus}>
                              {/* <FiberManualRecordRoundedIcon fontSize="small" style={{ color: job.suspend ? "red" : green[500] }}/> 
                                                                  <Typography style={{ marginLeft:"10px" }} variant="h5">{ job.suspend ? "Inactive" : "Active" }</Typography> */}
                              <FormControlLabel
                                control={
                                  <Switch
                                    name="active"
                                    color="primary"
                                    checked={!job.suspend}
                                    onChange={e =>
                                      this.handleUpdateSuspend(e, job)
                                    }
                                  />
                                }
                                label={t("CronJob.CronJobCreate.active")}
                                className={
                                  !isAuthorized(
                                    "update",
                                    this.props.envRole.name
                                  )
                                    ? "avoid-clicks"
                                    : ""
                                }
                                data-test="active-status-toggle"
                              />
                            </div>
                          </Grid>
                          {/* )} */}

                          {/* {isAuthorized("update", envRole.name) && ( */}
                          <Grid item md={1} className="center">
                            <IconButton
                              onClick={e =>
                                this.handleJobMenu(e, job.id, job.name)
                              }
                              data-test="cron-job-menu-expand"
                            >
                              <MoreVertIcon />
                            </IconButton>

                            <Menu
                              //id={`simple-menu-${index}`}
                              disableScrollLock={true}
                              anchorEl={this.state.anchorEl}
                              open={Boolean(this.state.anchorEl)}
                              onClose={() => this.handleMenuClose()}
                              getContentAnchorEl={null}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                              data-test="cron-job-menu"
                            >
                              <MenuItem
                                onClick={() =>
                                  this.handleJobClickAction()
                                }
                                data-test="view-item"
                              >
                                {t("CronJob.CronJobInfo.viewDetail")}
                              </MenuItem>
                              {isAuthorized("update", envRole.name) && (
                                <MenuItem
                                  onClick={() => this.handleJobEdit()}
                                  data-test="edit-item"
                                >
                                  {t("CronJob.CronJobInfo.edit")}
                                </MenuItem>
                              )}
                              {isAuthorized("update", envRole.name) && (
                                <MenuItem
                                  onClick={() => this.handleJobRunnow()}
                                  data-test="run-item"
                                >
                                  {t("CronJob.CronJobInfo.run")}
                                </MenuItem>
                              )}
                              {isAuthorized("delete", envRole.name) && (
                                <MenuItem
                                  onClick={() =>
                                    this.handleJobDelete()
                                  }
                                  data-test="delete-item"
                                >
                                  {t("CronJob.CronJobInfo.delete")}
                                </MenuItem>
                              )}
                            </Menu>
                          </Grid>
                          {/* )} */}
                        </Grid>
                      </CardContent>
                    </Card>
                  );
                })}
              {!cronjobList &&
                [0, 1, 2, 3].map((item, ind) => (
                  <CronJobSkeleton  key={ind} />
                ))}
              {cronjobList && cronjobList.length === 0 && (
                <NoContentImage
                  type="env"
                  alt="No Jobs"
                  data-test="noCronJobsImage"
                  message={t("CronJob.CronJobList.noJobs")}
                />
              )}
            </div>
          </div>
        )}
        {this.state.mode !== 0 && (
          <div className="m-b-20">
            <Link  href="#jobs" underline="none">
              <ButtonBase
                onClick={this.handleBack}
                style={{ color: "#357dfd" }}
                data-test="backButton"
              >
                <ArrowBackIcon fontSize="small" />{" "}
                <span className="jobNav">
                  {t("CronJob.CronJobList.jobList")}
                </span>
              </ButtonBase>
            </Link>
          </div>
        )}
        {this.state.mode === 1  && (
          <div data-test="cronJobCreateComponent">
            <CronJobCreate
              createSuccessHandler={this.createSuccessHandler}
              jobId={this.state.currentJobId}
              handleBlockingStatus={(blockedStatus:boolean) =>
                this.handleStateBlocking(blockedStatus)
              }
            />
          </div>
        )}
        {this.state.mode === 2 && this.state.currentJobId && (
          <div data-test="cronJobInfoComponent">
            <CronJobInfo
              jobId={this.state.currentJobId}
              handleJobEdit={this.handleJobEdit}
              handleJobRunnow={this.handleJobRunnow}
              handleJobDelete={this.handleJobDelete}
              handleJobMenu={this.handleJobMenu}
              handleMenuClose={this.handleMenuClose}
              getLastRunDateTime={this.getLastRunDateTime}
              handleUpdateSuspend={this.handleUpdateSuspend}
            />
          </div>
        )}
        {this.props.isDeletingJob && (
          <BackdropLoader message={t("CronJob.CronJobList.deletingCronJob")} data-test="deleting-loader" />
        )}
        <ConfirmDeletePopup
          open={this.state.isDeleteConfirmPopupOpen}
          handleAgree={this.handleDeleteAgreeHandler}
          handleDisAgree={this.handleDeleteDisAgreeHandler}
          message={`The action you are taking cannot be undone. This will permanently delete all the data. If you are sure, please type "${this.state.deletingJobName}"`}
          // yesText={t("Projects.VariablesTab.yesText")}
          // noText={t("Projects.VariablesTab.noText")}
          toMatchName={this.state.deletingJobName}
          toDeleteModule="Cron job"
          loading={this.props.isDeletingJob}
          data-test="delete-confirm"
        />
        <ConfirmActionPopup
          open={this.state.isRunnowConfirmPopupOpen}
          handleAgree={this.handleRunnowAgreeHandler}
          handleDisAgree={this.handleRunnowDisAgreeHandler}
          message={t("CronJob.CronJobList.jobStart")}
          yesText={t("CronJob.CronJobList.yesText")}
          noText={t("CronJob.CronJobList.noText")}
          data-test="runnow-confirm"
        />
        <ConfirmActionPopup
          open={this.state.isSuspendConfirmPopupOpen}
          handleAgree={this.handleSuspendAgreeHandler}
          handleDisAgree={this.handleSuspendDisAgreeHandler}
          message={this.state.suspendConfirmMessage}
          yesText={t("CronJob.CronJobList.updateText")}
          noText={t("CronJob.CronJobList.noText")}
          data-test="suspend-confirm"
        />
        {this.props.isSavingCronJob && (
          <BackdropLoader message={t("CronJob.CronJobList.updatingJob")} data-test="updating-loader" />
        )}
        {this.props.fetchingCronjobList && (
          <BackdropLoader message={t("CronJob.CronJobList.fetchingJob")} data-test="fetching-loader" />
        )}
        {this.props.showCronJobListLoader && <BackdropLoader message="loading..." data-test="showing-loader" />}
      </div>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state:any) => ({
  environmentDetails: state.EnvironmentReducer.environmentDetails,
  cronjobList: state.CronJobReducer.cronjobList,
  isDeletingJob: state.CronJobReducer.isDeletingJob,
  environmentState: state.EnvironmentReducer.environmentState,
  cronjob_status: state.CronJobReducer.cronjob_status,
  envRole: state.EnvironmentReducer.envRole,
  isSavingCronJob: state.CronJobReducer.isSavingCronJob,
  fetchingCronjobList: state.CronJobReducer.fetchingCronjobList,
  showCronJobListLoader: state.CronJobReducer.showCronJobListLoader,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch:Dispatch) => {
  return {
    fetchJobList: (id:number) => dispatch(fetchJobList(id)),
    deleteCronJob: (eId:number, cId:number) => dispatch(deleteCronJob(eId, cId)),
    runCronJob: (eId:number, cId:number) => dispatch(runCronJob(eId, cId)),
    fetchCronJob: (eId:number) => dispatch(fetchCronJob(eId)),
    clearCronJobList: () => dispatch(clearCronJobList()),
    updateCronJob: (eId:number, cId:number, jsonBody:CronJobModel, callback:null|(()=>void)) =>
      dispatch(updateCronJob(eId, cId, jsonBody, callback)),
  };
};


const connector= connect(
  mapStateToProps,
  mapDispatchtoProps
)
export default connector(withStyles(useStyles)(withTranslation()(CronJobList)));
type PropsFromRedux = ConnectedProps<typeof connector>;