import React from "react";
import { makeStyles, TextField } from "@material-ui/core";
import { createStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    textBox: {
      margin: "10px 0px 10px 0px",
    },
    resize: {
      fontSize: "1rem",
    },
  })
);

interface Props {
  value?: any;
  id?: string;
  label?: string | JSX.Element;
  type?: string;
  onChange?:
    | React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | undefined;
  inputRef?: React.MutableRefObject<HTMLInputElement | undefined>;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement> | string;
  InputProps?: any;
  inputProps?: any;
  color?: 'primary' | 'secondary';
  variant?: "filled" | "outlined" | "standard";
  style?: any;
  name?: string;
  error?: boolean;
  helperText?: React.ReactNode;
  autoComplete?: string;
  onCut?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
  onCopy?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
  customClassName?: string;
  className?: string;
  margin?: "dense" | "none" | "normal";
  placeholder?: string;
  disabled?: boolean;
  onBlur?:React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  required?:boolean;
  InputLabelProps?:{shrink: boolean};
  multiline?: boolean;
  rows?: number;
  autoFocus?: boolean;
  labelWidth?: number;
}

export function MuiTextField({
  value,
  id,
  label='',
  type,
  onChange,
  variant = "outlined",
  onKeyDown,
  name,
  customClassName = "",
  ...rest
}: Props) {
  const classes = useStyles();
  return (
    <TextField
      id={id}
      data-test="text-field-container"
      label={label}
      name={name}
      type={type}
      onChange={onChange}
      onKeyDown={typeof onKeyDown==='string'?undefined:onKeyDown}
      value={value}
      variant={variant}
      fullWidth
      className={`${customClassName}`}
      color="primary"
      InputProps={{
        classes: {
          input: classes.resize,
        },
      }}
      {...rest}
    />
  );
}

export default MuiTextField;
