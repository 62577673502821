import React from "react";
import { Typography, Paper } from "@material-ui/core";
import { AppConstants } from "../../constants/appconstants";
// type definitions
import { Operator} from "../../models/Operator.model";
import "./InstalledOperatorItem.css";

const getOperatorClassName = (status: string) => {
  let className = "operator-status ";

  switch (status) {
    case AppConstants.OperatorStatus.Succeeded:
      className += "installed-operator-status";
      break;
    case AppConstants.OperatorStatus.Failed:
      className += "failed-operator-status";
      break;
    default:
      className += "other-operator-status";
  }

  return className;
};

interface Props {
  operator: Operator;
  operatorStatus: string;
  handleOperatorView: (Operator: Operator) => void;
}

const InstalledOperatorItem = ({
  operator,
  handleOperatorView,
  operatorStatus,
}:Props) => {

  return (
    <Paper
      className="installed-operator-item-container"
      onClick={() => handleOperatorView(operator)}
      data-test="installed-operator-container"
    >
      <img
        src={operator.operator_details.operator.thumbUrl}
        alt={operator.operator_details.operator.name}
        title={operator.operator_details.operator.name}
        className="img-icon"
      />
      <Typography className="operator-title" variant="h6" color="textPrimary">
        {operator.operator_details.operator.displayName}
      </Typography>
      <div
        className={getOperatorClassName(operatorStatus)}
        data-test="operator-status-bar"
        data-operatorStatus={operatorStatus}
        data-operatorName={operator.operator_details.operator.displayName}
      ></div>
    </Paper>
  );
};

export default InstalledOperatorItem;
