import React from "react";
import AlertExpandableCard from "../../../../../components/alertmanager/AlertExpandableCard";
import { connect, ConnectedProps } from "react-redux";
import { Button, createStyles, Grid } from "@material-ui/core";
import { Dispatch } from "redux";
import NoContentImage from "../../../../../components/nocontentimagecontainer/NoContentImage";
import paths from "../../../../../constants/paths";
import { History } from "history";
import { getAlertLists } from "../redux/action";
import { EnvSettingTabs } from "../../../../../constants/environmentTabs";
import { AppConstants } from "../../../../../constants/appconstants";

interface Props extends PropsFromRedux {
  alertLists: any;
  history: History;
  // changeHash: (
  //   hash: string,
  //   value: number,
  //   envId?: number,
  //   delay?: number
  // ) => void;
  changeInnerTab: (event: any, value: number) => void;
}

function Rules(props: Props) {

  const handleTempPath = () => {
    const subIndex = EnvSettingTabs("#alert", props.environmentDetails).indexOf(AppConstants.EnvSettingsTabs.alerttemplates);
    //props.changeHash("alert", subIndex);
    props.changeInnerTab({}, subIndex);
  };

  const handleRefresh = () => {
    props.getAlertLists(props.environmentDetails?.id);
  }

  return (
    <>
      <Grid container spacing={2} justify="flex-end">
        <Grid item>
          <Button
            onClick={() => handleRefresh()}
            color="primary"
            variant="contained"
          >
            Refresh
          </Button>
        </Grid>
      </Grid>
      {props.alertLists && props.alertLists.length > 0 ? (
        props.alertLists.map((item: any, ind: number) => (
          <AlertExpandableCard type="Rules" alertDetails={item} />
        ))
      ) : (
        <Grid
          container
          justify="center"
          alignItems="center"
          data-test="noDataFound"
          direction="column"
        >
          <Grid item>
            <NoContentImage
              type="env"
              message="No alert template installed, Please install template to receive alerts"
              alt="No Rules details"
            />
          </Grid>
          <Grid item>
            <Button
              onClick={() => handleTempPath()}
              color="primary"
              variant="contained"
            >
              Go To Template
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  environmentDetails: state.EnvironmentReducer.environmentDetails,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    getAlertLists: (id: number) => dispatch(getAlertLists(id)),
  };
};

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(Rules);
