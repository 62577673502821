const ALERT_PREFIX = "ALERT_PREFIX";

export const GET_ALERT_LISTS = `${ALERT_PREFIX}/GET_ALERT_LISTS`;
export const GET_ALERT_LISTS_SUCCESS = `${ALERT_PREFIX}/GET_ALERT_LISTS_SUCCESS`;
export const GET_ALERT_LISTS_FAILURE = `${ALERT_PREFIX}/GET_ALERT_LISTS_FAILURE`;

export const GET_ALERT_RULES = `${ALERT_PREFIX}/GET_ALERT_RULES`;
export const GET_ALERT_RULES_SUCCESS = `${ALERT_PREFIX}/GET_ALERT_RULES_SUCCESS`;
export const GET_ALERT_RULES_FAILURE = `${ALERT_PREFIX}/GET_ALERT_RULES_FAILURE`;

export const ADD_RULES = `${ALERT_PREFIX}/ADD_RULES`;

export const INSTALL_TEMPLATE = `${ALERT_PREFIX}/INSTALL_TEMPLATE`;
export const UNINSTALL_TEMPLATE = `${ALERT_PREFIX}/UNINSTALL_TEMPLATE`;

export const NOTIFI_ALERT_LIST = `${ALERT_PREFIX}/NOTIFI_ALERT_LIST`;
export const NOTIFI_ALERT_LIST_SUCCESS = `${ALERT_PREFIX}/NOTIFI_ALERT_LIST_SUCCESS`;
export const UPDATE_NOTIFI_ALERT_DETAIL = `${ALERT_PREFIX}/UPDATE_NOTIFI_ALERT_DETAIL`;

export const GET_ALERT_STATUS = `${ALERT_PREFIX}/GET_ALERT_STATUS`;
export const GET_ALERT_STATUS_SUCCESS = `${ALERT_PREFIX}/GET_ALERT_STATUS_SUCCESS`;
export const GET_ALERT_STATUS_FAILURE = `${ALERT_PREFIX}/GET_ALERT_STATUS_FAILURE`;

export const getAlertLists = (id) => ({
    type: GET_ALERT_LISTS,
	data:{id}
})

export const installTemplate = (id, template, jsonBody) =>({
    type: INSTALL_TEMPLATE,
    data:{id, template, jsonBody}
})

export const unInstallTemplate = (id, template) => ({
    type: UNINSTALL_TEMPLATE,
    data:{id, template}
})

export const getRules = (id, template) => ({
    type: GET_ALERT_RULES,
    data:{id, template}
})

export const addRule = (id, template, jsonBody) => ({
    type: ADD_RULES,
    data:{id, template, jsonBody}
})

export const getNotifiAlertLists = (id) => ({
    type: NOTIFI_ALERT_LIST,
	data:{id}
})

export const updateNotifiDetails = (id, jsonBody) => ({
    type: UPDATE_NOTIFI_ALERT_DETAIL,
	data:{id, jsonBody}
}) 

export const alertDashBoardStatus = (id, status_name) => ({
    type: GET_ALERT_STATUS,
	data:{id, status_name}
})   