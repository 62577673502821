import { 
  FETCH_APP_DETAIL,
  FETCH_APP_DETAIL_SUCCESS,
  FETCH_APP_DETAIL_FAILURE,
  DELETE_APP_SUCCESS,
  FETCH_APP_ENVIRONMENTLIST,
  FETCH_APP_ENVIRONMENTLIST_SUCCESS,
  //PLUGIN_SELECTION,
  FETCH_PLUGIN_DETAIL_SUCCESS,
  CLEAR_PLUGIN_DETAILS,
  CLEAR_APP_DETAIL,
  FETCH_APP_AVAILABLE_RESOURCE_SUCCESS,
  FETCH_GIT_ORGANIZATIONS_SUCCESS,
  FETCH_GIT_ORGANIZATIONS_FAILURE,
  FETCH_GIT_REPOS,
  FETCH_GIT_ORG_REPOS,
  FETCH_GIT_REPOS_SUCCESS,
  FETCH_GIT_REPOS_FAILURE,
  FETCH_GIT_BRANCHES_SUCCESS,
  RENAME_APP,
  RENAME_SUCCESS,
  RENAME_ERROR,
  //CREATE_APP_TEMPLATETYPE,
  RESET_GIT_AUTHORIZATION,
  FETCH_APP_ROLE_SUCCESS,
  CREATE_APP, 
  CREATE_APP_FAILURE,
  CREATE_APP_SUCCESS,
  DELETE_APP,
  DELETE_APP_FAILURE,
  GIT_CONNECT,
  GIT_CONNECT_FAILURE,
  GIT_CONNECT_SUCCESS,
  CLEAR_ERROR_MESSAGE,
  CLEAR_BRANCHES,
  CR_CONNECT,
  CR_CONNECT_SUCCESS,
  CR_CONNECT_FAILURE
} from './actions';
import initialState from './initialState';

const reducer = (state = initialState, payload ) => {
  switch (payload.type) {
    case GIT_CONNECT:
      return {
        ...state,
        connecting: true
      }
    case GIT_CONNECT_SUCCESS:
        return {
          ...state,
          connecting: false
        }
    case GIT_CONNECT_FAILURE:
        return {
          ...state,
          connecting: false,
          errorMessage: payload.data
        }
    case CLEAR_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: initialState.errorMessage
      }

    case RENAME_APP:
      return {
        ...state,
        isRenaming: true,
        isRenameSuccess: false,
        hasRenameErrored: false
      }
    case RENAME_SUCCESS: 
    return {
      ...state,
      isRenaming: false,
      isRenameSuccess: true,
      hasRenameErrored: false
    }
    case RENAME_ERROR:
      return {
        ...state,
        isRenaming: false,
        isRenameSuccess: false,
        hasRenameErrored: true
      }
    case FETCH_APP_DETAIL:
      return {
        ...state,
        isDataNotFound: false,
      }
    case FETCH_APP_DETAIL_SUCCESS:
      return {
        ...state,
        appDetails : payload.data,
        isDataNotFound: false,

      }
    case FETCH_APP_DETAIL_FAILURE:
      return{
        ...state,
        isDataNotFound: true
    }
    case CLEAR_APP_DETAIL:
      return {
        ...state,
        appDetails : {},
        appRole: {},
        environmentList: []
      }
    // case DELETE_APP_SUCCESS:
    //   return {
    //     ...state,
    //     appDetails : []
    //   }
    case FETCH_APP_ENVIRONMENTLIST:
      return {
        ...state,
        isLoading: true,
      }
    case FETCH_APP_ENVIRONMENTLIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        environmentList : payload.data
      }
    //case PLUGIN_SELECTION:
    //   return {  
    //     ...state,
    //     selectedPlugin : payload.data.id
    //   }
    // case CREATE_APP_TEMPLATETYPE:
    //   return {
    //     ...state,
    //     selectedTemplate_Create : payload.data.type
    //   }
    case FETCH_PLUGIN_DETAIL_SUCCESS:
      return {  
        ...state,
        pluginDetails : payload.data
      }
    case CLEAR_PLUGIN_DETAILS:
      return {  
        ...state,
        pluginDetails : initialState.pluginDetails
      }
    case FETCH_APP_AVAILABLE_RESOURCE_SUCCESS:
      return {  
        ...state,
        appAvailableResource : payload.data
      }
    case FETCH_GIT_ORGANIZATIONS_SUCCESS:
        return {  
          ...state,
          gitOrganizations : payload.data,
          isGITNotAuthorized: false
        }
    case FETCH_GIT_ORGANIZATIONS_FAILURE:
        return{
          ...state,
          isGITNotAuthorized: true
        }
    case FETCH_GIT_ORG_REPOS:
        return {  
          ...state,
          gitRepos : initialState.gitRepos,
        }
    case FETCH_GIT_REPOS:
        return {  
          ...state,
          gitRepos : initialState.gitRepos,
        }
    case FETCH_GIT_REPOS_SUCCESS:
        return {  
          ...state,
          gitRepos : payload.data,
          isGITNotAuthorized: false
        }
    case FETCH_GIT_REPOS_FAILURE:
        return{
          ...state,
          isGITNotAuthorized: true
        }
    case RESET_GIT_AUTHORIZATION:
      return{
        ...state,
        isGITNotAuthorized: false
      }
    case FETCH_GIT_BRANCHES_SUCCESS:
      return {  
        ...state,
        gitBranches : payload.data
      }
    case FETCH_APP_ROLE_SUCCESS:
      return {  
        ...state,
        appRole : payload.data
      }
    case CREATE_APP:
      return {  
        ...state,
        creatingApp : true
      }
    case CREATE_APP_SUCCESS:
      return {  
        ...state,
        creatingApp : false
      }
    case CREATE_APP_FAILURE:
      return {  
        ...state,
        creatingApp : false
      }
    case DELETE_APP:
      return {  
        ...state,
        deletingApp : true
      }
    case DELETE_APP_SUCCESS:
      return {  
        ...state,
        deletingApp : false,
        appDetails:{}
      }
    case DELETE_APP_FAILURE:
      return {  
        ...state,
        deletingApp : false
      }
    case CLEAR_BRANCHES:
      return {  
        ...state,
        gitBranches : initialState.gitBranches
      }
    case CR_CONNECT:
      return {
        ...state,
        connecting: true
      }
    case CR_CONNECT_SUCCESS:
      return {
        ...state,
        connecting: false
      }
    case CR_CONNECT_FAILURE:
      return {
        ...state,
        connecting: false,
        errorMessage: payload.data
      }
    default:
      return state
  }
}

export default reducer