import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import {
    Formik
} from 'formik';
import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as Yup from 'yup';
import ConfirmActionPopup from '../../../components/confirmactionpopup/ConfirmActionPopup';
import ImageCropper from '../../../components/imagecropper/ImageCropper';
import MuiTextField from '../../../components/textfield/MuiTextField';
import './ProfileTab.css';
import { deactivateAccount, updateProfileDetails } from './redux/actions';
import {withTranslation, WithTranslation} from 'react-i18next';
import LinkedAccounts from './LinkedAccounts';
import { Dispatch } from "redux";
import { UserModel } from '../../../models/Account.model';
import { QuotaInfo } from '../../../components/quotaInfo/quotaInfo';

// const styles = {
//     raisedPrimary: {
//         color: colors.red[900],
//         backgroundColor: colors.red[600],
//         '&:hover': {
//             backgroundColor: colors.red[800],
//             // Reset on mouse devices
//             '@media (hover: none)': {
//                 backgroundColor: colors.red[600],
//             },
//         },
//     },
// };

type Payload = Pick<UserModel, "first_name"|"last_name"|"email"|"id">

interface ExtendedPayload extends Payload{
    image?: string,
    company?: string
}

interface Props extends WithTranslation, PropsFromRedux {}

type State = {
    first_name: string,
    last_name: string,
    email: string,
    company: string,
    isformTobeEdited: boolean,
    isPasswordToBeEdited: boolean,
    isPopupOpened: boolean,
    imgUploadeditModeEnabled: boolean
}

export class Profile extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            first_name: this.props.userDetails?.first_name,
            last_name: this.props.userDetails?.last_name,
            email: this.props.userDetails?.email,
            company: this.props.userDetails?.company,
            isformTobeEdited: false,
            isPasswordToBeEdited: false,
            isPopupOpened: false,
            imgUploadeditModeEnabled: false
        }
    }

    // componentDidMount() {
    //     this.props.getAccountsList()
    //     const $this = this;
    //     window.addEventListener('message', function(e){
    //         // if(e?.data?.source?.match('react-devtools') || e.origin !== window?.config?.REACT_APP_MESSAGE_ORIGIN_URL) return;
    //         if(e?.data?.source?.match('react-devtools')) return;
    //         $this.props.getAccountsList();
    //     }, false) 
    // }

    // componentWillUnmount(){
    //     this.props.clearAccountList()
    // }

    /* istanbul ignore next */
    // handleOnChange = (e) => {
    //     this.setState({
    //         [e.target.name]: e.target.value
    //     })
    // }

    handleDeleteAccount = () => {
        this.setState({
            isPopupOpened: true,
        })
    }

    handleDisAgreeHandler = () => {
        this.setState({
            isPopupOpened: false
        })
    }

    handleAgreeHandler = () => {
        this.props.deactivateAccount();
        this.setState({
            isPopupOpened: false
        })
    }

    /* istanbul ignore next */
    handleUpdate = () => {
        const payload: Payload = {
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            email: this.state.email,
            id: this.props.userDetails.id,
        }
        this.props.updateProfileDetailsAction(payload)
    }

    handleEdit = () => {
        this.setState({
            isformTobeEdited: true
        })
    };

    handleCancel = () => {
        this.setState({
            isformTobeEdited: false
        })
    }

    handleImgUpload = () => {
        this.setState({
            imgUploadeditModeEnabled: true,
        })
    }

    handleImgUploadCancel = () => {
        this.setState({
            imgUploadeditModeEnabled: false
        })
    }

    imageSuccessCallBack = (fileURL: string) => {
        const payload: ExtendedPayload = {
            image: fileURL,
            id: this.props.userDetails.id,
            first_name: this.props.userDetails.first_name,
            last_name: this.props.userDetails.last_name,
            email: this.props.userDetails.email
        }
        this.props.updateProfileDetailsAction(payload)
        this.handleImgUploadCancel();
    }

    render() {
        const { isformTobeEdited } = this.state;
        const {t} = this.props;
        return (
            <div data-test="main-container" className='detailsContainer'>
                <Grid>
                    <Grid container spacing={2}>
                        <Grid item lg={6} md={6} xl={6} xs={12} >
                            <Card>
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <div className="imgContaineruser">
                                                <div className="imgWrapperuser" style={{ backgroundImage: `url(${this.props.userDetails && this.props.userDetails.image ? this.props.userDetails.image : '/images/infographics/noimg.jpg'})` }} >

                                                </div>

                                                <span className="circlebtnuser">
                                                    <EditIcon data-test="image-edit-icon" onClick={() => this.handleImgUpload()} fontSize="small" className="upload-icon-align" />
                                                </span>

                                            </div>
                                        </Grid>
                                        <Grid item>
                                            <div className='userDetaisContainer'>
                                                <p className='userName'>{this.props.userDetails?.first_name + ' ' + this.props.userDetails?.last_name}</p>
                                                <p className='userCompany'>{this.props.userDetails?.company}</p>
                                                <p className='profileEmail'>{this.state.email}</p>

                                            </div>

                                        </Grid>
                                    </Grid>

                                </CardContent>
                                <Divider />
                                <CardActions>

                                    <Button data-test="da-button" color="secondary" size="small" startIcon={<HighlightOffRoundedIcon />} onClick={() => this.handleDeleteAccount()}>
                                        {t('Account.ProfileTab.deactivateAccount')}
                                    </Button>

                                    <Button data-test="ea-button" color="primary" size="small" startIcon={<EditIcon />} onClick={() => this.handleEdit()}>
                                        {t('Account.ProfileTab.editAccount')}
                                    </Button>

                                </CardActions>
                            </Card>
                        </Grid>
                        {this.props.userDetails && this.props.userDetails.quotas &&   
                          <Grid item lg={6} md={6} xl={6} xs={12} >
                            <QuotaInfo quotaInfo={this.props.userDetails.quotas}/>
                        </Grid>
                        }
                    
                    </Grid>
                </Grid>
                <div>
                    {
                        isformTobeEdited ? (
                            <Grid data-test="edit-profile-form">
                                <Grid container spacing={2}>
                                    <Grid item lg={6} md={6} xl={6} xs={12}>
                                        <Card>
                                            <CardHeader title={t('Account.ProfileTab.editInfoTitle')}/>
                                            <Divider />
                                            <CardContent>

                                                <Formik initialValues={{
                                                    firstName: this.props.userDetails.first_name
                                                    , company: this.props.userDetails.company,
                                                    lastName: this.props.userDetails.last_name
                                                }}
                                                  onSubmit={(values) => {
                                                        const payload: ExtendedPayload = {
                                                            first_name: values.firstName,
                                                            last_name: values.lastName,
                                                            email: this.state.email,
                                                            company: values.company,
                                                            id: this.props.userDetails.id,
                                                        }
                                                        this.setState({
                                                            isformTobeEdited: false
                                                        })
                                                        this.props.updateProfileDetailsAction(payload)
                                                    }}
                                                  validationSchema={
                                                        Yup.object().shape({
                                                            firstName: Yup.string()
                                                                .min(2, t('Account.PasswordTab.tooShort'))
                                                                .max(30, t('Account.PasswordTab.tooLong'))
                                                                .required(t('Account.ProfileTab.firstNameError')),
                                                            lastName: Yup
                                                                .string()
                                                                .min(2, t('Account.PasswordTab.tooShort'))
                                                                .max(30, t('Account.PasswordTab.tooLong'))
                                                                .required(t('Account.ProfileTab.lastNameError')),
                                                            company: Yup
                                                                .string()
                                                                .min(2, t('Account.PasswordTab.tooShort'))
                                                                .max(30, t('Account.PasswordTab.tooLong'))
                                                                .required(t('Account.ProfileTab.companyNameError'))
                                                        })}
                                                >
                                                    {
                                                        (props) => {
                                                            const {
                                                                values,
                                                                touched,
                                                                errors,
                                                                //dirty,
                                                                //isSubmitting,
                                                                handleChange,
                                                                handleBlur,
                                                                handleSubmit,
                                                                //handleReset,
                                                                isValid,
                                                            } = props;

                                                            return (
                                                                <form onSubmit={handleSubmit} >
                                                                    <Grid>
                                                                        <Grid container spacing={2}>
                                                                            <Grid item xs={12}>
                                                                                <MuiTextField
                                                                                  id="firstName"
                                                                                  error={Boolean(errors.firstName && touched.firstName)}
                                                                                  label={t('Account.ProfileTab.firstNameLabel')}
                                                                                  name="firstName"
                                                                                  autoFocus
                                                                                  style={{ display: 'block' }}
                                                                                  onChange={handleChange}
                                                                                  value={values.firstName}
                                                                                  onBlur={handleBlur}
                                                                                  helperText={((errors.firstName && touched.firstName) && errors.firstName)?.toString()} margin="normal"
                                                                                />

                                                                            </Grid>

                                                                            <Grid item xs={12}>

                                                                                <MuiTextField
                                                                                  id="lastName"
                                                                                  error={Boolean(errors.lastName && touched.lastName)}
                                                                                  type="lastName"
                                                                                  label={t('Account.ProfileTab.lastNameLabel')}
                                                                                  name="lastName"
                                                                                  style={{ display: 'block' }}
                                                                                  onChange={handleChange}
                                                                                  value={values.lastName}
                                                                                  onBlur={handleBlur}
                                                                                  helperText={((errors.lastName && touched.lastName) && errors.lastName)?.toString()} margin="normal"
                                                                                />

                                                                            </Grid>

                                                                            <Grid item xs={12}>
                                                                                <MuiTextField
                                                                                  id="company"
                                                                                  error={Boolean(errors.company && touched.company)}
                                                                                  type="company"
                                                                                //   color='secondary'
                                                                                  label={t('Account.ProfileTab.companyNameLabel')}
                                                                                  style={{ display: 'block' }}
                                                                                  name="company"
                                                                                  onChange={handleChange}
                                                                                  value={values.company}
                                                                                  onBlur={handleBlur}
                                                                                  helperText={((errors.company && touched.company) && errors.company)?.toString()}
                                                                                />

                                                                            </Grid>

                                                                        </Grid>
                                                                    </Grid>
                                                                    <br />
                                                                    <Divider />
                                                                    <CardActions>
                                                                        <Button color="secondary" variant="contained" size="small" onClick={this.handleCancel} >
                                                                            {t('Account.ProfileTab.cancelLabel')}
                                                                        </Button>
                                                                        <Button variant="contained" disabled={!isValid || (this.state.first_name === values.firstName && this.state.last_name === values.lastName && this.state.company === values.company? true: false)} className='oneRemLeftMarginSeperator' color="primary" size="small" type='submit' >
                                                                            {t('Account.ProfileTab.updateDetailsLabel')}
                                                                        </Button>

                                                                    </CardActions>

                                                                </form>
                                                            )
                                                        }
                                                    }
                                                </Formik>

                                            </CardContent>
                                        </Card>

                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : (
                            <></>
                            )
                    }
                    <LinkedAccounts />
                </div>

                <ConfirmActionPopup open={this.state.isPopupOpened} handleAgree={this.handleAgreeHandler} handleDisAgree={this.handleDisAgreeHandler} message={t('Account.ProfileTab.deactivateMessage')} yesText={t('Account.ProfileTab.yesText')} noText={t('Account.ProfileTab.noText')} />
                {
                    this.state.imgUploadeditModeEnabled && 
                    <ImageCropper 
                        data-test="image-cropper"
                        title={t('Account.ProfileTab.imageCropperTitle')}
                        isOpen={this.state.imgUploadeditModeEnabled}
                        onClose={this.handleImgUploadCancel}
                        onSuccess={ this.imageSuccessCallBack}
                    />
                }
            </div>
        )
    }
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
    userDetails: state.AuthReducer.user,
})

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
    return {
        updateProfileDetailsAction: (payload: ExtendedPayload) => dispatch(updateProfileDetails(payload)),
        deactivateAccount: () => dispatch(deactivateAccount())
    }
}

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withTranslation()(Profile));
