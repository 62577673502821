import React from "react";
import { Typography, Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ReplayRoundedIcon from "@material-ui/icons/ReplayRounded";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";

/* istanbul ignore next */
const useStyles = makeStyles(() => ({
  grid: {
    width: "55%",
    margin: "100px auto",
    minWidth: "275px",
    padding: "15px 10px",
    borderRadius: 4,
    boxShadow: "2px 2px 7.5px #949594",
  },
  image: {
    width: "50%",
    margin: "auto",
  },
}));

export const ErrorFallback = () => {
  const classes = useStyles();

  const handleClick = (btn: number) => {
    if(btn === 0) window.location.reload();
    else if(btn === 1) window.location.replace(window.location.origin);
  };

  return (
    <Grid container className={classes.grid} direction="column" spacing={2} data-test="main-container">
      <img
        data-test="image"
        src="/images/infographics/search.svg"
        alt="Error"
        className={classes.image}
      />
      <Typography align="center" data-test="error-text">
        {"Oops! Seems like some error occured. We'll fix it asap."}
      </Typography>
      <br />
      <Grid container justify="space-around" spacing={2}>
        <Grid item>
          <Button
            color="primary"
            variant="outlined"
            data-test="reload-button"
            startIcon={<ReplayRoundedIcon />}
            onClick={() => handleClick(0)}
          >
            Reload the page
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            data-test="home-button"
            startIcon={<HomeRoundedIcon />}
            onClick={() => handleClick(1)}
          >
            {" "}
            Go to Home{" "}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ErrorFallback;
