import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import AddonsPopUp from "../../components/addonspopup/AddonsPopUp";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Grid,
  Button,
  Divider,
  Link,
  Tooltip,
  Chip,
} from "@material-ui/core";
import { isAuthorized } from "../../helpers/utils";
import AdjustIcon from "@material-ui/icons/Adjust";
import Skeleton from "react-loading-skeleton";
import LaunchIcon from "@material-ui/icons/Launch";
import { useTranslation } from "react-i18next";
import "./addonscard.css";
import { AppConstants } from "../../constants/appconstants";
import { Theme } from "@material-ui/core";
import {
  AddonCategory,
  UserRole,
  AddonStatusModel,
  EnvironmentState,
  AddonServiceDetails,
} from "../../models/Environment.model";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  imageContainer: {
    height: 64,
    width: 64,
    margin: "0 auto",
    border: `1px solid ${theme?.palette?.divider}`,
    borderRadius: "5px",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  addonimage: {
    width: "100%",
  },
  cardContent: {
    minHeight: 200,
  },
}));

interface Props {
  addon: any;
  envRole: UserRole;
  installedAddon: any;
  wsAddonStatus: Array<AddonStatusModel>;
  currentView: number;
  environmentState: EnvironmentState;
  viewAddon: (id: number) => void;
  installAddon: (aId: number, payload: any) => void;
}

export const AddonsCard = (props: Props) => {
  const { addon } = props;
  const [open, setOpen] = useState(false);
  const [statusColor, setStatusColor] = useState("");
  const [installed, setInstalled] = useState(false);
  const [status, setStatus] = useState("");
  const [source, setSource] = useState(1);
  const [serviceDetail, setServiceDetail] = useState({} as AddonServiceDetails);
  const [t] = useTranslation();
  const classes = useStyles();

  const addonAction = (payload?: any) => {
    if (source === 2) {
      props.viewAddon(addon.id);
    } else if (source === 1) {
      props.installAddon(addon.id, payload);
    }
  };

  const addonCardAction = () => {
    if (source === 2) {
      props.viewAddon(addon.id);
    } else {
      setOpen(!open);
    }
  };

  const setAddonStatus = (_addon: any) => {
    const wsStatus = props.wsAddonStatus?.find((a) => {
      return a.name === _addon.name;
    });
    const _detail_add_on = props.installedAddon;
    let _status = "";
    if (wsStatus && !_detail_add_on) {
      _status = AppConstants.AddonStatus.Uninstalling;
    } else if (wsStatus && _detail_add_on) {
      _status = wsStatus.status;
    } else if (_detail_add_on && !wsStatus) {
      _status = AppConstants.AddonStatus.FetchingStatus;
    }
    setStatus(_status);
  };

  useEffect(() => {
    if (props.installedAddon) {
      setInstalled(true);
      setSource(2);
      setServiceDetail(props.installedAddon?.service_detail);
    } else {
      setSource(1);
    }
  }, [props.installedAddon]);

  useEffect(() => {
    if (props.addon) {
      setAddonStatus(props.addon);
    }
  }, [props.wsAddonStatus, props.addon, props.installedAddon]);

  /* istanbul ignore next */
  useEffect(() => {
    let _statusColor = "";
    if (status) {
      switch (status) {
        case AppConstants.EnvironmentStatus.Running:
          _statusColor = "green";
          break;
        case AppConstants.EnvironmentStatus.Pending:
          _statusColor = "orange";
          break;
        case AppConstants.EnvironmentStatus.Succeeded:
          _statusColor = "blue";
          break;
        case AppConstants.EnvironmentStatus.FetchingStatus:
          _statusColor = "blue";
          break;
        case AppConstants.EnvironmentStatus.Failed:
          _statusColor = "red";
          break;
        case AppConstants.EnvironmentStatus.NotReady:
          _statusColor = "blue";
          break;
      }
    }
    setStatusColor(_statusColor);
  }, [status]);

  return (props.currentView === 1 && installed) || props.currentView === 2 ? (
    <Grid item lg={4} md={6} sm={6} xs={12} data-test="addonsCardContainer">
      <Card className={clsx(classes.root)}>
        <CardContent className={clsx(classes.cardContent)}>
          <Grid container spacing={2}>
            <Grid item md={3}>
              <div className={classes.imageContainer}>
                <img
                  src={addon && addon.image}
                  alt="Addon"
                  className={classes.addonimage}
                  data-test="addonImage"
                />
              </div>
            </Grid>
            <Grid item md={9}>
              <Typography
                gutterBottom
                variant="h5"
                data-test="addonNameContainer"
              >
                <Grid container justify="space-between" spacing={2}>
                  <Grid item>
                    <span data-test="addonName">{addon?.name}</span>
                    <span className="m-l-20" data-test="addonStatus">
                      {status && (
                        <Tooltip title={t("Addons.state")}>
                          {status !==
                          AppConstants.AddonStatus.FetchingStatus ? (
                            <AdjustIcon
                              className="topIcon"
                              style={{ color: statusColor }}
                            />
                          ) : (
                            <Skeleton
                              circle={true}
                              height={10}
                              width={10}
                              style={{ marginRight: "5" }}
                            />
                          )}
                        </Tooltip>
                      )}
                      <span
                        title={props.addon?.error}
                        style={{ color: statusColor }}
                      >
                        {status !== AppConstants.AddonStatus.FetchingStatus ? (
                          status
                        ) : (
                          <Skeleton width={80} height={12} />
                        )}
                      </span>
                    </span>
                  </Grid>
                  {/* <Grid item>
                    <Typography display="inline" component="span" align="right" className="m-l-20" data-test="addonCatgories">
                      <Grid container spacing={1}>
                        {addon.categories?.map(category => (
                          <Grid item>
                            <Chip style={{
                              maxWidth: "150px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }} color={"primary"}  variant="outlined" label={"Update Available"} size="small" />
                          </Grid>
                        ))}
                      </Grid>
                    </Typography>
                  </Grid> */}
                </Grid>
              </Typography>
              <Typography gutterBottom variant="body1" className={"threeLine"}>
                {addon?.description}
              </Typography>
              <Typography gutterBottom variant="body2">
                <Link
                  href={addon?.source_url}
                  target="_blank"
                  underline="always"
                >
                  {t("Addons.read")}{" "}
                </Link>
              </Typography>
              <Typography
                display="inline"
                component="span"
                className="m-l-20"
                data-test="addonCatgories"
              >
                <Grid container spacing={1} justify="flex-end">
                  {addon.Categories?.map((category: AddonCategory) => (
                    <Grid item key={category.id}>
                      <Tooltip title={category.name}>
                        <Chip
                          style={{
                            maxWidth: "100px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          color={"primary"}
                          variant="outlined"
                          label={category.name}
                          size="small"
                        />
                      </Tooltip>
                    </Grid>
                  ))}
                </Grid>
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Grid
            container
            justify={
              installed &&
              status === AppConstants.AddonStatus.Running &&
              serviceDetail?.service_type === "external" &&
              props.environmentState?.cname
                ? "space-between"
                : "flex-end"
            }
            alignItems="center"
            spacing={2}
            data-test="externalAddonLink"
          >
            {installed &&
              status === AppConstants.AddonStatus.Running &&
              serviceDetail?.service_type === "external" &&
              serviceDetail?.service_external_url &&
              props.environmentState?.cname && (
                <Grid item data-test="externalLink">
                  <Typography display="inline" variant="body2">
                    <span>
                      <Typography display="inline" variant="body2">
                        {t("Addons.active")}
                      </Typography>
                      <Link
                        href={`https://${props.environmentState.cname}${serviceDetail?.service_external_url}`}
                        target="_blank"
                        className="cnameLink oneLine"
                        rel="noreferrer"
                        underline="always"
                        display="inline"
                        data-test="addonExternalURL"
                      >
                        {"  "}
                        {`${serviceDetail?.service_external_url}`}
                        <LaunchIcon className="openLinkIcon" />
                      </Link>
                    </span>
                  </Typography>
                </Grid>
              )}
            <Grid item data-test="addonAction">
              {(isAuthorized("update", props.envRole?.name) ||
                source === 2) && (
                <Button
                  variant="contained"
                  color="primary"
                  className="right"
                  onClick={addonCardAction}
                  disabled={status === AppConstants.AddonStatus.Uninstalling}
                  data-test={"addonActionButton"}
                >
                  {source === 1 && t("Addons.install")}
                  {source === 2 && t("Addons.viewDetails")}
                </Button>
              )}
            </Grid>
          </Grid>
        </CardActions>
      </Card>
      {open && (
        <AddonsPopUp
          open={open}
          setOpen={setOpen}
          action={addonAction}
          addon={props.addon}
          source={source}
          //environmentState={ props.environmentState }
          //fetchPluginVersions={ props.fetchPluginVersions }
        />
      )}
    </Grid>
  ) : (
    <> </>
  );
};

export default AddonsCard;
