import { Card, CardContent, Chip, Divider, Grid, Typography } from "@material-ui/core";
import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect, ConnectedProps } from 'react-redux';
import BackdropLoader from '../../components/loader/BackdropLoader';
//import CicdCardSkeleton from '../../components/skeletons/CicdCardSkeleton';
import { updateBreadcrumb } from "../project/redux/actions";
import './Notification.css';
import NotificationRow from './NotificationItem';
import { getNotifications, clearNotification } from './redux/actions';
import { withTranslation, WithTranslation } from 'react-i18next';
import Skeleton from "react-loading-skeleton";
import { Dispatch } from "redux";
import { BreadcrumbModel } from "../../models/Common.model";
import { History } from 'history';
import { NotificationModel } from "../../models/Common.model";

interface Props extends WithTranslation, PropsFromRedux {
    notificationList: NotificationModel[]
    history: History
}

type State = {
    pageNo: number,
    pageSize: number,
    filter: string,
}

export class NotificationComponent extends Component <Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            pageNo: 1,
            pageSize: 50,
            filter: "all",
            // selectedNotys: [],
            //selectionType: 0 //1:unseen, 2:seen
        }
    }

    componentDidMount() {
        this.props.getNotifications(this.state.pageNo, this.state.pageSize, this.state.filter);
        const breadcrumbData = [
            {
                name: "Notifications",
                path: "/notifications"
            },
        ];
        this.props.updateBreadcrumb(breadcrumbData);
    }

    loadMore = () => {
        let newPageNo = this.state.pageNo + 1;
        this.props.getNotifications(newPageNo, this.state.pageSize, this.state.filter);
        this.setState({
            pageNo: newPageNo
        });
    }

    handleListFilterClick = (filter: string) => {
        this.setState({
            filter: filter,
            pageNo: 1
        });
        this.props.getNotifications(1, this.state.pageSize, filter);
    }

    // getIsChecked = (noty) => {
    //     let checked = false;
    //     const { selectedNotys } = this.state;
    //     if (selectedNotys.indexOf(noty.id) > -1) {
    //         checked = true;
    //     }
    //     return checked;
    // }

    // onNotificationCheck = (e, noty) => {
    //     let selectedNotys = [...this.state.selectedNotys];
    //     let type = 0;
        
    //     if(e.target.checked)
    //     {
    //         selectedNotys.push(noty.id)
    //     }
    //     else 
    //     {
    //         selectedNotys = selectedNotys.filter(item => item !== noty.id)
    //     }

    //     if(selectedNotys.length > 0)
    //     {
    //         type = noty.seen ? 2 : 1
    //     }

    //     this.setState({ 
    //         selectedNotys,
    //         selectionType: type
    //     })
    // }
    
    // UpdateSeen = (mode) => {
    //     let jsonBody = {
    //         seen: mode === 1 ? true : false,
    //         ids: this.state.selectedNotys
    //     };
    //     this.props.updateSeenUnseen(jsonBody);
    //     this.setState({
    //         selectedNotys: [],
    //         selectionType: 0
    //     })
    // }

    UNSAFE_componentWillMount() {
        this.props.clearNotification()
    }
    
    render() {
        const { t } = this.props;
        return (
            <div data-test="notificationContainer">

                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <Typography color="textPrimary" variant="h5" data-test="notyHeaderText">
                            {t('Notification.NotificationPage.notifications')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Chip
                            label="All"
                            clickable
                            color="primary"
                            onClick={() => this.handleListFilterClick("all")}
                            variant={this.state.filter === "all" ? "default" : "outlined"}
                            data-test="list-filter"
                        />
                    </Grid>
                    <Grid item>
                        <Chip
                            label="Unread"
                            clickable
                            color="primary"
                            onClick={() => this.handleListFilterClick("unseen")}
                            variant={this.state.filter === "unseen" ? "default" : "outlined"}
                            data-test="filter"
                        />
                    </Grid>
                </Grid>
                {
                    !this.props.notificationList &&
                    <div className="notificationcard">
                        {
                            [0, 1, 2, 3, 4].map(value => {
                                return (
                                    <Card key={value} data-test="notySkeleton">
                                        <CardContent key={value}>
                                            <Grid container>
                                                <Grid item xs={1} ><Skeleton circle width={40} height={40} /></Grid>
                                                <Grid item xs={7}><Skeleton height={20} width="50%" /></Grid>
                                                <Grid item xs={2}><Skeleton height={20} width="60%" /></Grid>
                                                <Grid item xs={2}><Skeleton height={20} width="60%" /></Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                )
                            })
                        }
                    </div>
                }
                {
                    this.props.notificationList &&
                    <>
                        {/* <CardContent className="titleHeaderNotification">
                            <Grid container>
                                <Grid item >
                                              
                                </Grid>
                                <Grid item>
                                
                                    {                                                 
                                        this.state.selectedNotys.length > 0 && 
                                        <>
                                            {
                                                this.state.selectionType === 1 &&
                                                <>
                                                    <Button color="primary" variant="contained" onClick={() => this.UpdateSeen(1) } data-test="notyMarkAsRead">{t('Notification.NotificationPage.markAsRead')}</Button>
                                                </>
                                            }
                                            {
                                                this.state.selectionType === 2 && 
                                                <Button color="primary" variant="contained" onClick={() => this.UpdateSeen(2) } data-test="notyMarkAsUnRead">{t('Notification.NotificationPage.markAsUnread')}</Button>
                                            }
                                        </>
                                    }
                                </Grid>
                            </Grid>
                        </CardContent> */}
                        {/* switch(item.action) {
                                    case "create" : actionData = "Created"; actionIcon=<BuildOutlinedIcon fontSize="small" color="primary"/> ; break;
                                    case "update" : actionData = "Updated"; actionIcon=<EditOutlinedIcon fontSize="small" color="warning"/>; break;
                                    case "delete" : actionData = "Deleted"; actionIcon=<DeleteOutlineOutlinedIcon fontSize="small" color="error"/>;break;
                                    case "share" : actionData = "Shared"; actionIcon=<PersonAddOutlinedIcon fontSize="small" color="action" />; break;
                                    default : actionData = item.action ;actionIcon=<CalendarTodayOutlinedIcon fontSize="disabled"/>; break;
                            } */}
                        {
                            this.props.notificationList.length > 0 ?
                                <div data-test="notyListContainer">
                                    <InfiniteScroll
                                        dataLength={this.props.notificationList.length}
                                        next={this.loadMore.bind(this)}
                                        hasMore={this.props.notificationList.length === this.state.pageSize * this.state.pageNo}
                                        className="notificationcard"
                                        data-test="infinite-scroll"
                                        loader=""
                                    >
                                        {
                                            this.props.notificationList.map((notification, index) => {
                                                return (
                                                    <div data-test="notyListItemContainer" key={index}>
                                                        {/* <CardContent className={ notification.seen ? "" : "unread" } key={index} data-test="notyListItemContainer">
                                                <Grid container alignItems="center" spacing={3}> */}
                                                        {/* <Grid item className="checkboxGrid" sm={1} >
                                                        {
                                                            (this.state.selectionType === 0 || ((this.state.selectionType === 1 && !notification.seen) || (this.state.selectionType === 2 && notification.seen))) &&
                                                            <Checkbox
                                                              color="primary"
                                                              checked={this.getIsChecked(notification)}
                                                              onChange={(e) => this.onNotificationCheck(e, notification)}
                                                              data-test="notyListItemCheck"
                                                                //disabled={ this.state.selectionType > 0 ? (((this.state.selectionType === 1 && !notification.seen) || (this.state.selectionType === 2 && notification.seen))  ? false : true ): false}
                                                            />
                                                        }
                                                        </Grid> */}
                                                        { <NotificationRow source={2} notification={notification} history={this.props.history} />}
                                                        {/* </Grid>
                                            </CardContent> */}
                                                        <Divider variant="middle" />
                                                    </div>
                                                )
                                            })
                                        }
                                    </InfiniteScroll>
                                </div> :
                                <Card className="notificationcard" data-test="notyCard">
                                    <CardContent >
                                        <div className='alignCenter'><p>{t('Notification.NotificationPage.noNotifications')}</p></div>
                                    </CardContent>
                                </Card>
                        }
                    </>
                }
                {/* {this.props.isLoading ? <BackdropLoader message={t('Notification.NotificationPage.loading')} /> : null} */}
                { this.props.isCheckNavigation && <BackdropLoader message={t('Notification.NotificationPage.navigatingToModule')} />}
                { this.props.isMarkAllAsRead && <BackdropLoader message={t('Notification.NotificationPage.markAllAsRead')} data-test="backdrop-loader"/>}
            </div>
        )
    }
}
/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
    notificationList : state.NotificationReducer.notificationList,
    fetchingNotifications : state.NotificationReducer.fetchingNotifications,
    isCheckNavigation: state.NotificationReducer.isCheckNavigation,
    isMarkAllAsRead: state.NotificationReducer.isMarkAllAsRead,
    isLoading: state.NotificationReducer.isLoading
})
/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
    return {
        getNotifications : (pageNo: number, pageSize: number, filter: string) => dispatch(getNotifications(pageNo, pageSize, filter)),
        // updateSeenUnseen: (jsonBody) => dispatch(updateSeenUnseen(jsonBody)),
        updateBreadcrumb: (breadcrumbData: BreadcrumbModel[]) => dispatch(updateBreadcrumb(breadcrumbData)),
        // clearNotification: (breadcrumbData: BreadcrumbModel[]) => dispatch(clearNotification(breadcrumbData)),
        clearNotification: () => dispatch(clearNotification()),
    }
}

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withTranslation()(NotificationComponent));
