const initialState = {
    environmentDetails : {},
    environmentMetadata: {},
    environmentOverview: {},
    environmentVariables : {},
    resourcesList : null,
    startFetchLogs : {},
    environmentStatus : [],
    pluginVersionConfig : {},
    fetchingPluginVersionConfig: false,
    environmentInsights: {},
    environmentOverviewInsights: {},
    environmentState: {},
    isDataNotFound : false,
    envTabValue: 0, 
    updatingEnv: false,
    envRole: {},
    activityLogs: [],
    reCreationTriggered: false,
    realTimeActivityLogs: [],
    addonCategories: [],
    addons: [],
    addonInfo: {},
    addonMetaData: {},
    fetchingAddon: false,
    installedAddons: [],
    addonsLoading: false,
    addonsError: null,
    installError: null,
    //uninstallError: null,
    installingAddon: false,
    modifyingAddon: false,
    uninstallingAddon: false,
    updatingVariables: false,
    creatingEnv: false,
    deletingEnv: false,
    recreatingEnv: false,
    addonsStatus: null,
    fetchingAddonsStatus: false,
    hpaInsight: null,
    fetchingHpaInsight: false,
    fetchingEnvInsight: false,
    fetchingEnvOverviewInsight: false,
    fetchingEnvLogs: false,
    fetchingEnvVar: false,
    currentEnvironment: null,
    environmentPods: [],
    fetchingPods: false,
    fetchingResources: false,
    wsAddonStatus: [],
    rollingBack: false,
    buildImages: null,
    fetchingBuildImages: false,
    fetchingStorage: false,
    envStorage: null,
    creatingStorage: false,
    editingStorage: false,
    deletingStorage: false,
    fetchingPackageStatus: false,
    packageStatus: null,
    domainStatus: "",
    updatingCustomDomain: false,
    envServiceDetails: null,
    updatingEnvSchedule: false,
    envScheduleLogs: null,
    updatingFileManagerStatus: false,
    updatingGitBranch: false,
    cloningEnvironment: false,
    fetchingExternalSecret: false,
    updatingSecret: false,
    secretLogs:[],
    settingConfig: null,
    loggerConfig: null,
    scannerDetails: null,
    fetchingScanner: false,
    fetchingScannerList: false,
    scannerList: null,
    fetchingSpecificReport: false,
    specificReport: null,
    toggleExternalUrlFetching:false,
    external_url:null,
    toggleExternalUrlFailure:false,
    ipWhitelistingData:null,
    isIPWhitelisting:false,
    isExternalUrl:null,
    isExternalUrlLoading:false
}

export default initialState