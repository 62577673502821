import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Grid } from "@material-ui/core";
import { LabelHandler } from "../labelHandler/LabelHandler";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import AceEditor from "react-ace";
import { getDateInStandardFormat } from "../../helpers/utils";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  status: {
    width: "60px",
    color: "white",
    textAlign: "center",
    padding: "3px",
    fontWeight: 500,
    borderRadius: "15px",
    marginRight: "10px",
    fontSize: "smaller",
  },
});

const aceStyles = {
  mode: "markdown",
  theme: "monokai",
  fontSize: 14,
  showPrintMargin: false,
  width: "100%",
  height: "15vh",
  name: "command",
  className: "m-t-10",
  showGutter: false,
};

interface Iprops {
  historyDetails: any;
}

export default function HistoryRow(props: Iprops) {
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  const splitLabel = (des: string) => {
    const label = des.split("\n");
    const labelEle = label[label.length - 1]
      .split("LABELS = map[")[1]
      .split(" ");
    label.splice(label.length - 1, 1);
    return label.join("\n") + "\nLabels:\n  " + labelEle.join("\n  ");
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <Grid container alignItems="center" spacing={1} justifyContent="space-between">
            <Grid item>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                  >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography variant="h5">
                    {props.historyDetails.alert_name}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container spacing={4} alignItems="center">
                <Grid item md={3} xs={12}>
                  <div
                    className={classes.status}
                    style={{
                      backgroundColor:
                        props.historyDetails.status === "firing"
                          ? "#FF5252"
                          : "#4CAF50",
                    }}
                  >
                    {props.historyDetails.status}
                  </div>
                </Grid>

                <Grid item md={9} xs={12}>
                  <LabelHandler
                    icon={<DateRangeOutlinedIcon />}
                    iconTooltip="History date"
                    label={getDateInStandardFormat(
                      props.historyDetails.created_at
                    )}
                    labelTooltip={getDateInStandardFormat(
                      props.historyDetails.created_at
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div>
                <AceEditor
                  placeholder="Alert History"
                  {...aceStyles}
                  readOnly={true}
                  value={`Pod: ${props.historyDetails.pod}\nSummary: ${
                    props.historyDetails.summary
                  }\nDescription: ${splitLabel(
                    props.historyDetails.description
                  )}`}
                  setOptions={{
                    tabSize: 4,
                  }}
                  minLines={5}
                />
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
