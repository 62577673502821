export default {
    DEFAULT:'/',
    //DASHBOARD:'/dashboard',
    REGISTER : '/signup',
    REQUESTDEMO : '/requestdemo',
    INVITE_LOGIN: '/user/invite/:token',
    FORGOTPASSWORD : '/forgot',
    RESETPASSWORD : '/resetpassword/:token',
    ACCOUNTDETAILS : '/account',
    PROJECTLIST: '/projects',
    CREATEPROJECT: '/project/create',
    PROJECTINFO: '/project/:id',
    APPDETAILS : '/app/:appId',
    CREATEAPP: '/app/create/:pId',
    CREATEAPP_SELECTPLUGIN: '/app/create/plugins/:pId/:tType/:source',
    CREATEAPP_SELECTCATALOG: '/app/create/catalogs/:pId/:tType/:source',
    CREATEAPP_SELECTREGION: '/app/create/regions/:pId/:tType/:source/:plugId',
    CREATEAPP_SELECTOPERATOR: '/app/create/operators/:pId/:tType/:source',
    CREATEOPERATORENVIRONMENT: '/operator-environment/create/:appId',
    EDITOPERATORENVIRONMENT: '/operator-environment/:eId/update',
    CREATE_V_CLUSTER:'/organization/create-v-cluster',
    //CREATEAPPDETAILS: '/app/create/details',
    // CREATEAPPTEMPLATE: '/app/create/template',
    // CREATEAPPRESOURCES: '/app/create/resources',
    // CREATEAPPGENERALS: '/app/create/generals',
    // CREATEAPPDEPLOY: '/app/create/deploy',
    ENVIRONMENTINFO: '/environment/:eId',
    HELMENVIRONMENTINFO: '/environment/helm/:eId',
    CREATE_ORGANIZATION: '/organization/create',
    CREATE_ORG_CLUSTER:  '/organization/createcluster',
    EDIT_ORG_CLUSTER:  '/organization/editcluster/:id',
    ORGANIZATIONINFO: '/organization/:id',
    CREATEENVIRONMENT: '/environment/create/:appId',
    CREATEHELMENVIRONMENT: '/helm-environment/create/:appId',
    EDITHELMENVIRONMENT: '/helm-environment/:eId/update',
    LOGINCALLBACK: '/login/external/github',
    BITBUCKETLOGINCALLBACK: '/login/external/bitbucket',
    LOGINCALLBACKGITLAB: '/login/external/gitlab',
    VERIFYEMAIL: '/user/verify',
    SUPPORT: '/tickets',
    TICKET_DETAIL: '/ticket/:id',
    TICKET_CREATE: '/tickets/create',
    NOTIFICATIONS: '/notifications',
    PAYMENTSUCCESSCALLBACK: '/payment/success',
    PAYMENTFAILURECALLBACK: '/payment/failure',
    //OPERATOR_DETAIL: '/operator/:id',
    LOGIN_SSO: '/login/sso',
    OAuth: '/loginsso/clustermanager'
}