import { ButtonBase, Grid, MenuItem, MenuList, Paper, Popover, Typography, createStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import BackdropLoader from '../../../components/loader/BackdropLoader';
import { currentOrganization } from '../../login/redux/selectors';
import { switchOrg } from '../redux/actions';
import { Theme } from "@material-ui/core";
import { Dispatch } from "redux";
import { WithStyles } from "@material-ui/styles";
import { OrganizationModel } from '../../../models/Organization.model';
import { History } from 'history';

/* istanbul ignore next */
const useStyles = (theme: Theme) => createStyles({
  icon: {
    marginRight: 3,
    fontSize:'1rem',
    verticalAlign:'middle'
  },
  input: {
    flexGrow: 1,
    lineHeight: '16px',
    letterSpacing: '-0.05px'
  },
  popover: {
    padding: theme.spacing(1),
    background: "rgba(128,128,128,0.5)"
  },
  searchImg:{
    width:170,
    objectFit:'cover',
    padding:20,
  },
  resultList:{
    minWidth:300,
    marginTop:10
  },
  link:{
    textDecoration:'none',
  },
  paperRoot:{
    width:'100%'
  },
  rowContainer: {
    display: 'flex',
    padding: '0.5rem',
    alignItems: 'center',
    justifyContent: "space-between"
  },
  title: {
    color: "#0057fa",
    marginLeft: "1rem",
    fontWeight : 500
  },
  subTitle: {
    color: "#43425D",
    marginLeft: "1rem",
    fontWeight : 200,
    fontSize:12
  },
  smallTitle: {
    color : "#0057fa",
    fontSize: "1rem"
  },
  titleShorter: {
    width: 30,
    height: 30,
    background: 'white',
    border: "1px solid #0057fa",
    borderRadius: '50%',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 0 
  },
  vertMiddle:{
    verticalAlign:'middle'
  }
});

interface Props extends PropsFromRedux, WithTranslation, WithStyles<typeof useStyles> {
  history: History
}

interface State {
  // value: string;
  anchorEl: Element | ((element: Element) => Element) | null;
  //tempAnchorEl: Element | ((element: Element) => Element) | null;
}

export class OrganizationSwitch extends Component<Props, State> {
  constructor(props: Props){
    super(props);
    this.state = {
      // value: '',
      anchorEl: null,
      //tempAnchorEl : null,
      //selectedOrgName: '01Cloud'
    }
  }

  handleExpandClick = (e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    this.setState({ 
        anchorEl : e.currentTarget 
    });
  }

  handleClose = () => {
    this.setState({ anchorEl : null });
  };

//   handleResultClick = (id) => {
//     this.clearResult();
//   }

  handleItemClick = (item: OrganizationModel | Pick<OrganizationModel, "id">) => {
    this.props.switchOrg(item.id, this.props.history)
    // this.setState({
    //     selectedOrgName: item.name
    // })
    this.handleClose();
    //this.props.onSelectedOrg(item)
  }
  isCreateOrgDisabled=()=>{
    const {organizationList,currentUser}=this.props;
  
    if(organizationList===null ||!organizationList.length|| !currentUser){
      return false
    }
    
    const orgCount=organizationList.filter((project: OrganizationModel)=>project.user_id===currentUser.id).length
    
      return orgCount>= currentUser.quotas.user_organization;
    }
  render () {
    const { classes, userDetails, t } = this.props;
    return (
        <div data-test="main-container">
      
            {/* <SearchIcon className={classes.icon} />
            <Input
              className={classes.input}
              disableUnderline
              onChange={ this.handleOnChange }
              value={ this.state.value }
              placeholder={t("SearchProjectsApps")}
              //onFocus={ this.handleSearchFocus}
              aria-describedby='simple-popper'
            /> */}
            <ButtonBase onClick={ (e) => this.handleExpandClick(e) } data-test="switch-btn">
                <Typography variant="h6" color={'textPrimary'}  className="top-org" data-test="current-org">
                  { this.props.currentOrganization && this.props.currentOrganization.id > 0 ? this.props.currentOrganization.name : 
                  ( userDetails ? userDetails.first_name + ' ' + userDetails.last_name : '01Cloud' )  } <ExpandMoreIcon className={classes.vertMiddle}/>
                </Typography>
            </ButtonBase>
            <Popover
              id='simple-popper' 
              open={Boolean(this.state.anchorEl)} 
              anchorEl={this.state.anchorEl} 
              className={classes.popover} 
              onClose={this.handleClose}
              disableAutoFocus
              disableEnforceFocus
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              data-test="popover"
            >
                <MenuList className={classes.resultList}>
                    {
              (!this.props.organizationList) &&
                [0,1].map((item) => (
                    <MenuItem key={item} data-test="skel">
                        <Paper elevation={1} className={classes.paperRoot}>
                            <div className={ classes.rowContainer }>
                                <Skeleton circle={true} height={40} width={40} />
                                <div className='projectTitlesContainer'>
                                    <Grid container direction='column'>
                                        <Grid item>
                                            <Skeleton height={10} width="40%" />
                                        </Grid>
                                        <Grid item>
                                            <Skeleton height={10} width="20%" />
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </Paper>
                    </MenuItem>
                ))
            }
                    {
                this.props.organizationList && this.props.organizationList.length > 0 &&  
                this.props.organizationList.map((item: OrganizationModel, ind: number) => {
                    return (
                        <MenuItem key={ind} data-test="org-item">
                            <Paper elevation={1} className={classes.paperRoot}>
                                <div className={classes.link} onClick={() => this.handleItemClick(item) } data-test="item-div">
                                    <div className={ classes.rowContainer }>
                                        {
                                            item.image && <img data-test="org-image" src= { item.image } alt='' height={40} width={40} />
                                        }
                                        { !item.image &&
                                            <div className={ classes.titleShorter } data-test="org-avatar">
                                                <p className={ classes.smallTitle }>{item.name !== "" && item.name.charAt(0).toUpperCase()}</p>  
                                            </div>
                                        }
                                        <div className='projectTitlesContainer'>
                                            <Grid container direction='column'>
                                                <Grid item>
                                                    <span className={classes.title} data-test="org-name">{ item.name }</span>
                                                </Grid>
                     
                                                <Grid item>      
                                    {item &&
                                      item.user &&
                                      item.user.first_name &&
                                      item.user.last_name &&
                                      <span className={classes.subTitle}>
                                       Owner :{" "}  {`${item.user.first_name} ${item.user.last_name} ${this.props.currentUser && item.user.id===this.props.currentUser.id?"( me )":""}` }
                                      </span>}
                                                
                                                </Grid>
                                               
                                            </Grid>
                                        </div>
                                    </div>
                                </div>
                            </Paper>
                        </MenuItem>
                    )
                })
            }
                    {
              this.props.organizationList && this.props.organizationList.length > 0 &&  
              <MenuItem data-test="default-org">
                  <Paper elevation={1} className={classes.paperRoot}>
                      <div className={classes.link} onClick={() => this.handleItemClick({ id:0 }) }>
                          <div className={ classes.rowContainer }>
                              <img src="/images/logos/logo-blue.svg" alt='' height={40} width={40} data-test="default-org-image" />
                              <div className='projectTitlesContainer'>
                                  <Grid container direction='column'>
                                      <Grid item>
                                          <span className={classes.title} data-test="default-org-name">{ userDetails ? userDetails.first_name + ' ' + userDetails.last_name : '01Cloud' }</span>
                                      </Grid>
                                      <Grid item>
                                          <span className={classes.subTitle}>Default Organization</span>
                                      </Grid>
                                    
                                  </Grid>
                              </div>
                          </div>
                      </div>
                  </Paper>
              </MenuItem>
            } {
              !this.isCreateOrgDisabled() &&
                <MenuItem>
              {/* onClick={() => props.handleResultClick(props.id, props.type) } */}
              <div className={ classes.rowContainer }>
                  <Link data-test="create-org-link" to='/organization/create' className={classes.link} onClick={() => this.handleClose() }> + {t('Organization.CreateOrganization.createOrg')}</Link>
              </div>
                </MenuItem>
            }
                   
                </MenuList>
            </Popover>
            {this.props.switchingOrg && <BackdropLoader message={t('Organization.CreateOrganization.switchOrg')} /> }
        </div>
    )
  }
}

/*istanbul ignore next */
const mapStateToProps = (state: any) => ({
  userDetails : state.AuthReducer.user,
  organizationList: state.OrganizationReducer.organizationList,
  switchingOrg: state.OrganizationReducer.switchingOrg,
  currentOrganization: currentOrganization(state),
  currentUser: state.AuthReducer.user,
})

/*istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    switchOrg: (id: number, history: History) => dispatch(switchOrg(id, history))
  }
}

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withStyles(useStyles)(withTranslation()(OrganizationSwitch)));
