import {
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  //TableCell,
  TableContainer,
  TableHead,
  // TableRow,
  Typography,
} from "@material-ui/core";
//import { withStyles } from "@material-ui/core/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import AddGroupPopup from "../../../../components/addgrouppopup/AddGroupPopup";
import ConfirmDeletePopup from "../../../../components/confirmdeletepopup/ConfirmDeletePopup";
import CustomButton from "../../../../components/custombutton/CustomButton";
import BackdropLoader from "../../../../components/loader/BackdropLoader";
import { isAuthorizedOrg } from "../../../../helpers/utils";
import {
  currentOrgRole,
  sessionTokenSelector,
} from "../../../login/redux/selectors";
import {
  addOrgGroup,
  deleteOrgGroup,
  getOrgGroups,
  updateOrgGroup,
  clearOrgGroups,
} from "../../redux/actions";
import GroupInfo from "./GroupInfo";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../../components/styledtabelcell/StyledTabelCell";
import { useTranslation } from "react-i18next";
import { Dispatch } from "redux";
import { OrgGroupModel } from "../../../../models/Organization.model";

// export const StyledTableCell = withStyles(() => ({
//   head: {
//     // backgroundColor: theme.palette.background.default,
//     // color: 'grey',
//     fontWeight: 500,
//   },
//   body: {
//     color: "#555",
//     fontSize: 14,
//   },
// }))(TableCell);

// export const StyledTableRow = withStyles(() => ({
//   root: {},
// }))(TableRow);

type Payload = {
  name: string
}

interface Props extends PropsFromRedux {
}

export function GroupsTab(props: Props) {
  const [deleteGroupPopupOpen, setDeleteGroupPopupOpen] = useState(false);
  const [addGroupPopupOpen, setAddGroupPopupOpen] = useState(false);
  // const [currentGroupId, setCurrentGroupId] = useState(null);
  const [mode, setMode] = useState(1);
  const [edit, setEdit] = useState(false);
  const [currentGroup, setCurrentGroup] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [t] = useTranslation();

  useEffect(() => {
    props.getOrgGroups();
    return () => {
      props.clearOrgGroups();
    }
  }, [props.validSessionId]);

  const handleJobMenu = (e: any, group: OrgGroupModel) => {
    setAnchorEl(e.currentTarget);
    // setCurrentGroupId(groupId);
    setCurrentGroup(group);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    // setCurrentGroupId(null);
    // setCurrentGroup(null);
  };

  const handleAddGroup = () => {
    handleMenuClose();
    setAddGroupPopupOpen(true);
    // this.setState({
    //   isAddUserPopupOpened : Math.random(),
    // })
  };

  const addGroupAgreeHandler = (name: string) => {
    const payload = { name };
    setAddGroupPopupOpen(false);
    props.addOrgGroup(payload);
  };

  const addGroupDisagreeHandler = () => {
    setAddGroupPopupOpen(false);
  };

  const handleDeleteGroup = () => {
    handleMenuClose();
    setDeleteGroupPopupOpen(true);
    // this.setState({
    //   isAddUserPopupOpened : Math.random(),
    // })
  };

  const deleteGroupAgreeHandler = () => {
    // const payload = { name };
    setDeleteGroupPopupOpen(false);
    props.deleteOrgGroup(currentGroup?.id);
  };

  const deleteGroupDisagreeHandler = () => {
    setDeleteGroupPopupOpen(false);
  };

  const handleEditGroup = () => {
    handleMenuClose();
    setEdit(true);
    setAddGroupPopupOpen(true);
  };

  const editGroupAgreeHandler = (name: string) => {
    const payload = { name };
    props.updateOrgGroup(currentGroup?.id, payload);
    setEdit(false);
    setAddGroupPopupOpen(false);
  };

  const editGroupDisabgreeHandler = () => {
    setEdit(false);
    setAddGroupPopupOpen(false);
  };

  const viewGroupInfo = () => {
    handleMenuClose();
    setMode(2);
  };

  const handleGroupClick = (group: OrgGroupModel) => {
    setCurrentGroup(group);
    setMode(2);
  };

  const handleBack = () => {
    setMode(1);
    props.getOrgGroups();
    setCurrentGroup(null);
  };

  const { organizationGroups } = props;

  return (
    <div data-test="groups-container">
      {mode === 1 && (
        <div data-test="group-list-container">
          {isAuthorizedOrg("addUser", props.currentOrgRole) && (
            <div
              className="alignRight addButtonContainer"
              data-test="create-group-button"
            >
              <CustomButton
                label={t("Organization.GroupsTab.createGroup")}
                onClick={() => {
                  handleAddGroup();
                }}
              />
            </div>
          )}
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <StyledTableRow>
                  {/* <StyledTableCell></StyledTableCell> */}
                  <StyledTableCell>
                    {t("Organization.GroupsTab.groupName")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {t("Organization.GroupsTab.members")}
                  </StyledTableCell>
                  {/* {isAuthorized("delete", projectRole.name) && ( */}
                  <StyledTableCell align="center">
                    {t("Organization.GroupsTab.actions")}
                  </StyledTableCell>
                  {/* )} */}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {organizationGroups?.length > 0 &&
                  organizationGroups?.map((group: OrgGroupModel) => (
                    <StyledTableRow key={group.id}>
                      {/* <StyledTableCell
                        onClick={() => {
                          handleGroupClick(group);
                        }}
                      >
                        {" "}
                        <Avatar src="./Images/profile_image.png" />
                      </StyledTableCell> */}
                      <StyledTableCell
                        onClick={() => {
                          handleGroupClick(group);
                        }}
                      >
                        <Typography
                          color="primary"
                          variant="h6"
                          className="groupnameLink"
                        >
                          {group?.Name ?? group?.name}
                        </Typography>
                        {/* <Chip className='userRole' label="owner" /> */}
                      </StyledTableCell>
                      <StyledTableCell
                        onClick={() => {
                          handleGroupClick(group);
                        }}
                      >
                        {group?.members?.length ?? 0}
                      </StyledTableCell>
                      {/* {isAuthorized("delete", projectRole.name) && ( */}
                      <StyledTableCell align="center">
                        <IconButton
                          onClick={(e) => handleJobMenu(e, group)}
                          data-test="job-menu"
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          //id={`simple-menu-${index}`}
                          disableScrollLock={true}
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={() => handleMenuClose()}
                          getContentAnchorEl={null}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                        >
                          <MenuItem
                            onClick={() => viewGroupInfo()}
                            data-test="viewDetail"
                          >
                            {t("Organization.GroupsTab.viewDetail")}
                          </MenuItem>
                          {isAuthorizedOrg("update", props.currentOrgRole) && (
                            <MenuItem
                              onClick={() => {
                                handleEditGroup();
                              }}
                              data-test="rename"
                            >
                              {t("Organization.GroupsTab.rename")}
                            </MenuItem>
                          )}
                          {isAuthorizedOrg("delete", props.currentOrgRole) && (
                            <MenuItem
                              onClick={() => handleDeleteGroup()}
                              data-test="delete"
                            >
                              {t("Common.delete")}
                            </MenuItem>
                          )}
                        </Menu>
                      </StyledTableCell>
                      {/* )} */}
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
            {organizationGroups?.length === 0 && (
              <div className="alignCenter">
                <p>{t("Organization.GroupInfo.noMembers")}</p>
              </div>
            )}
          </TableContainer>
          {addGroupPopupOpen && (
            <AddGroupPopup
              open={addGroupPopupOpen}
              handleAgree={edit ? editGroupAgreeHandler : addGroupAgreeHandler}
              handleDisagree={
                edit ? editGroupDisabgreeHandler : addGroupDisagreeHandler
              }
              title={
                edit
                  ? t("Organization.GroupsTab.renameGroup")
                  : t("Organization.GroupsTab.createGroup")
              }
              group={edit ? currentGroup : null}
            />
          )}
          {/* <ConfirmActionPopup
        open={deleteMemberPopupOpen}
        message={`Are you sure you want to remove the user from organization?`}
        handleAgree={deleteMemberAgreeHandler}
        handleDisAgree={deleteMemberDisagreeHandler}
        loading={props.deletingMember}
        yesText={"Remove"}
      /> */}
          {deleteGroupPopupOpen && (
            <ConfirmDeletePopup
              open={deleteGroupPopupOpen}
              handleAgree={deleteGroupAgreeHandler}
              handleDisAgree={deleteGroupDisagreeHandler}
              // yesText={t("Projects.VariablesTab.yesText")}
              // noText={t("Projects.VariablesTab.noText")}
              toMatchName={currentGroup?.name ?? currentGroup?.Name}
              toDeleteModule="group"
              loading={props.deletingGroup}
            />
          )}
        </div>
      )}
      {mode === 2 && <GroupInfo group={currentGroup} handleBack={handleBack} />}

      {props.fetchingGroups && (
        <BackdropLoader message={t("Organization.GroupsTab.fetchGroup")} />
      )}
      {props.deletingGroup && (
        <BackdropLoader message={t("Organization.GroupsTab.deleteGroup")} />
      )}
      {props.addingGroup && (
        <BackdropLoader message={t("Organization.GroupsTab.addGroup")} />
      )}
      {props.updatingGroup && (
        <BackdropLoader message={t("Organization.GroupsTab.updatingInfo")} />
      )}
    </div>
  );
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  organizationGroups: state.OrganizationReducer.organizationGroups,
  fetchingGroups: state.OrganizationReducer.fetchingGroups,
  addingGroup: state.OrganizationReducer.addingGroup,
  deletingGroup: state.OrganizationReducer.deletingGroup,
  updatingGroup: state.OrganizationReducer.updatingGroup,
  validSessionId: sessionTokenSelector(state),
  currentOrgRole: currentOrgRole(state),
  // rolesData: state.AddUserPopupReducer.rolesData,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    getOrgGroups: () => dispatch(getOrgGroups()),
    clearOrgGroups: () => dispatch(clearOrgGroups()),
    addOrgGroup: (data: Payload) => dispatch(addOrgGroup(data)),
    deleteOrgGroup: (data: number) => dispatch(deleteOrgGroup(data)),
    updateOrgGroup: (id: number, data: Payload) => dispatch(updateOrgGroup(id, data)),
  };
};

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(GroupsTab);
