import { call, takeLatest, put, select } from 'redux-saga/effects';
import axios from 'axios';
import endpoints from '../../../constants/endpoints';
import { sessionTokenSelector } from '../../login/redux/selectors';
import toast from '../../../components/toast/Toast';
import { CreateApp_TemplateTypes } from '../../../constants/enums';
import {
  FETCH_APP_DETAIL,
  FETCH_APP_DETAIL_SUCCESS,
  FETCH_APP_DETAIL_FAILURE,
  DELETE_APP,
  DELETE_APP_SUCCESS,
  DELETE_APP_FAILURE,
  FETCH_APP_ENVIRONMENTLIST,
  FETCH_APP_ENVIRONMENTLIST_SUCCESS,
  FETCH_PLUGIN_DETAIL,
  FETCH_PLUGIN_DETAIL_SUCCESS,
  CREATE_APP,
  CREATE_APP_SUCCESS,
  CREATE_APP_FAILURE,
  FETCH_APP_AVAILABLE_RESOURCE,
  FETCH_APP_AVAILABLE_RESOURCE_SUCCESS,
  GIT_CONNECT,
  CR_CONNECT,
  CR_CONNECT_SUCCESS,
  CR_CONNECT_FAILURE,
  FETCH_GIT_REPOS,
  FETCH_GIT_REPOS_SUCCESS,
  FETCH_GIT_REPOS_FAILURE,
  FETCH_GIT_BRANCHES,
  FETCH_GIT_BRANCHES_SUCCESS,
  FETCH_GIT_ORGANIZATIONS,
  FETCH_GIT_ORGANIZATIONS_SUCCESS,
  FETCH_GIT_ORGANIZATIONS_FAILURE,
  FETCH_GIT_ORG_REPOS,
  FETCH_APP_ROLE,
  FETCH_APP_ROLE_SUCCESS,
  GIT_CONNECT_FAILURE,
  GIT_CONNECT_SUCCESS,
  RENAME_APP,
  RENAME_SUCCESS,
  RENAME_ERROR,
} from './actions';

import { GET_SIDEBAR_PROJECT_DETAILS } from '../../project/redux/actions';

import Toast from '../../../components/toast/Toast';

function appDetailsCall(sessionToken, payload) {
  const config = {
    headers: {
      Authorization: 'basic ' + sessionToken,
    },
  };
  return axios.get(
    endpoints.GET_APP_DETAILS.replace(':aid', payload.data.id),
    config
  );
}

// Generator Call
function* fetchAppDetail(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(appDetailsCall, sessionToken, payload);
    const data = response.data;
    if (data !== null) {
      yield put({ type: FETCH_APP_DETAIL_SUCCESS, data });
    } 
  } catch (error) {
    if (
      error &&
      error.response &&
      (error.response.status === 404 || error.response.status === 401)
    ) {
      yield put({ type: FETCH_APP_DETAIL_FAILURE });
    }
  }
}

function deleteAppCall(sessionToken, payload) {
  const config = {
    headers: {
      Authorization: 'basic ' + sessionToken,
    },
  };
  return axios.delete(
    endpoints.DELETE_APP.replace(':aid', payload.data.id),
    config
  );
}

// Generator Call
function* deleteApp(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(deleteAppCall, sessionToken, payload);
    console.log('response');
    console.log(response);
    if (response.status === 204 || response.status === 200) {
      yield put({ type: DELETE_APP_SUCCESS });
      yield put({ type: GET_SIDEBAR_PROJECT_DETAILS });
      toast.success('App deleted successfully');

      payload.data.history.push(
        payload.data.project_id > 0
          ? '/project/' + payload.data.project_id
          : '/projects'
      );
    } else {
      yield put({ type: DELETE_APP_FAILURE });
      payload.data.history.push(
        payload.data.project_id > 0
          ? '/project/' + payload.data.project_id
          : '/projects'
      );
    }
  } catch (error) {
    yield put({ type: DELETE_APP_FAILURE });
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    )
      toast.error(error.response.data.error);
    else toast.error('Operation failed. Please try again');
  }
}

function fetchEnvironmentsCall(sessionToken, payload) {
  const config = {
    headers: {
      Authorization: 'basic ' + sessionToken,
    },
  };
  const _url = payload.data.isHelm ? endpoints.GET_HELMENVIRONMENT_LIST : endpoints.GET_ENVIRONMENT_LIST
  return axios.get(_url.replace(':aid', payload.data.id), config);
}

// Generator Call
function* fetchEnvironmentsByAppId(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(fetchEnvironmentsCall, sessionToken, payload);
    const data = response.data;
    if (data !== null) {
      yield put({ type: FETCH_APP_ENVIRONMENTLIST_SUCCESS, data });
    } 
  } catch (error) {
    //test
  }
}

function pluginDetailsCall(sessionToken, payload) {
  const config = {
    headers: {
      Authorization: 'basic ' + sessionToken,
    },
  };
  return axios.get(
    endpoints.PLUGIN_DETAILS.replace(':pId', payload.data.id),
    config
  );
}

// Generator Call
function* fetchPluginDetailsById(payload) {
  if (typeof payload.data.id === 'undefined') {
    toast.error('Plugin Id is Required');
  } else {
    try {
      const sessionToken = yield select(sessionTokenSelector);
      const response = yield call(pluginDetailsCall, sessionToken, payload);
      const data = response.data;
      if (data !== null) {
        yield put({ type: FETCH_PLUGIN_DETAIL_SUCCESS, data });
      } else {
        toast.error('Something went wrong while fetching Plugin Details');
      }
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      )
        toast.error(error.response.data.error);
      else toast.error('Something went wrong while fetching Plugin Details');
    }
  }
}

function createAppCall(sessionToken, payload) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'basic ' + sessionToken,
    },
  };
  return axios.post(
    endpoints.CREATE_APPLICATION,
    payload.data.jsonBody,
    config
  );
}

// Generator Call
function* createApplication(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(createAppCall, sessionToken, payload);
    const data = response.data;
    if (data !== null) {
      yield put({ type: CREATE_APP_SUCCESS });

      yield put({ type: GET_SIDEBAR_PROJECT_DETAILS });

      toast.success('App created successfully');
      //yield put({ type: FETCH_APP_DETAIL_SUCCESS, data })
      
      if(payload.data.jsonBody.operator_package_name){
        console.log(payload.data.jsonBody.operator_package_name)
        payload.data.history.push('/operator-environment/create/' + data.id);
      }else{
        // payload.data.history.push('/helm-environment/create/' + data.id);
        payload.data.history.push(payload.data.jsonBody.service_type === CreateApp_TemplateTypes.helm
          ?'/helm-environment/create/' + data.id
          :'/environment/create/' + data.id
          );
      }
    } else {
      yield put({ type: CREATE_APP_FAILURE });

      toast.error('Something went wrong while creating app');
    }
  } catch (error) {
    yield put({ type: CREATE_APP_FAILURE });
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    )
      toast.error(error.response.data.error);
    else toast.error('App creation failed. Please try again');
  }
}

function fetchAppAvailableResourceCall(sessionToken, payload) {
  const config = {
    headers: {
      Authorization: 'basic ' + sessionToken,
    },
  };
  return axios.get(
    endpoints.APP.GET_AVAILABLE_RESOURCE.replace(':aId', payload.data.id),
    config
  );
}

// Generator Call
function* fetchAppAvailableResource(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(
      fetchAppAvailableResourceCall,
      sessionToken,
      payload
    );
    const data = response.data;
    if (data !== null) {
      yield put({ type: FETCH_APP_AVAILABLE_RESOURCE_SUCCESS, data });
    }
  } catch (error) {
    // if(error && error.response && error.response.data && error.response.data.error)
    //   toast.error(error.response.data.error);
    // else
    //   toast.error("App creation failed. Please try again");
  }
}

function gitConnectCall(sessionToken, payload) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'basic ' + sessionToken,
    },
  };
  return axios.post(
    endpoints.GITHUB.GIT_CONNECT,
    payload.data.jsonBody,
    config
  );
}

// Generator Call
function* gitConnect(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(gitConnectCall, sessionToken, payload);
    const data = response.data;
    if (data !== null) {
      // toast.success("App created successfully");
      // yield put({ type: FETCH_APP_DETAIL_SUCCESS, data })
      // payload.data.history.push("/app/" +  data.id);
      if (payload.data.callback){
        payload.data.callback(data); 
        yield put({ type: GIT_CONNECT_SUCCESS });
        toast.success("Git Login Success");
      }
      else {
        if (payload.data.window) {
          yield put({ type: GIT_CONNECT_SUCCESS });
          payload.data.window.opener.postMessage('Github login success');
          payload.data.window.close();
        }
      }
    }
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
      ){
        yield put({ type: GIT_CONNECT_FAILURE, data: error.response.data.error });
    }
    //toast.error(error.response.data.error);
    // else
    //   toast.error("App creation failed. Please try again");
  }
}

function* crConnect(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'basic ' + sessionToken,
      },
    };
    const response = yield axios.post(endpoints.CONTAINERREGISTRY.CR_CONNECT, payload.data.jsonBody, config);
    const data = response.data;
    if (data !== null) {
      if (payload.data.callback)
        toast.success("Container Registry Login Success");
        yield put({ type: CR_CONNECT_SUCCESS });
        payload.data.callback(data);
      // payload.data.window.opener.postMessage('Github login success');
      // payload.data.window.close();
    }
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    )
    yield put({ type: CR_CONNECT_FAILURE, data: error.response.data.error });
    //toast.error(error.response.data.error);
    // else
    //   toast.error("App creation failed. Please try again");
  }
}

function fetchGITOrganizationsCall(sessionToken, payload) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'basic ' + sessionToken,
    },
  };
  return axios.post(
    payload.data.type === CreateApp_TemplateTypes.git ? endpoints.GITHUB.GET_ORGANIZATIONS.replace(':service', payload.data.jsonBody.service_name) : endpoints.CONTAINERREGISTRY.GET_ORGANIZATIONS.replace(':service', payload.data.jsonBody.service_name),
    payload.data.jsonBody,
    config
  );
}

// Generator Call
function* fetchGITOrganizations(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(
      fetchGITOrganizationsCall,
      sessionToken,
      payload
    );
    const data = response.data;
    if (data !== null) {
      yield put({ type: FETCH_GIT_ORGANIZATIONS_SUCCESS, data });
    }
  } catch (error) {
    if (error && error.response && (error.response.status === 403 || error.response.status === 422))
      yield put({ type: FETCH_GIT_ORGANIZATIONS_FAILURE });
  }
}

function fetchGITOrgReposCall(sessionToken, payload) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'basic ' + sessionToken,
    },
  };
  return axios.post(
    payload.data.type === CreateApp_TemplateTypes.git ? endpoints.GITHUB.GET_ORG_REPOS.replace(':service', payload.data.jsonBody.service_name).replace(':org_name', payload.data.orgName) : endpoints.CONTAINERREGISTRY.GET_ORG_REPOS.replace(':service', payload.data.jsonBody.service_name).replace(':org_name', payload.data.orgName),
    payload.data.jsonBody,
    config
  );
}

// Generator Call
function* fetchGITOrgRepos(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(fetchGITOrgReposCall, sessionToken, payload);
    const data = response.data;
    if (data !== null) {
      yield put({ type: FETCH_GIT_REPOS_SUCCESS, data });
    }
  } catch (error) {
    if (error && error.response && error.response.status === 403)
      yield put({ type: FETCH_GIT_REPOS_FAILURE });
  }
}

function fetchGITReposCall(sessionToken, payload) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'basic ' + sessionToken,
    },
  };
  return axios.post(
    payload.data.type === CreateApp_TemplateTypes.git ? endpoints.GITHUB.GET_PR_REPOS.replace(':service', payload.data.jsonBody.service_name) : endpoints.CONTAINERREGISTRY.GET_PR_REPOS.replace(':service', payload.data.jsonBody.service_name),
    payload.data.jsonBody,
    config
  );
}

// Generator Call
function* fetchGITRepos(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(fetchGITReposCall, sessionToken, payload);
    const data = response.data;
    if (data !== null) {
      // toast.success("App created successfully");
      yield put({ type: FETCH_GIT_REPOS_SUCCESS, data });
      // payload.data.history.push("/app/" +  data.id);
    }
  } catch (error) {
    if (error && error.response && error.response.status === 403)
      yield put({ type: FETCH_GIT_REPOS_FAILURE });
    // if(error && error.response && error.response.data && error.response.data.error)
    //   toast.error(error.response.data.error);
    // else
    //   toast.error("App creation failed. Please try again");
  }
}

function fetchGITBranchesCall(sessionToken, payload) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'basic ' + sessionToken,
    },
  };
  return axios.post(
    payload.data.type === CreateApp_TemplateTypes.git ? endpoints.GITHUB.GET_BRANCHES.replace(':service', payload.data.jsonBody.service_name).replace(':repo_id', payload.data.jsonBody.repo_id).replace(":uid", payload.data.jsonBody.uid) : endpoints.CONTAINERREGISTRY.GET_TAGS.replace(':service', payload.data.jsonBody.service_name).replace(':org_name', payload.data.orgName).replace(':repo_name', payload.data.repoName),
    payload.data.jsonBody,
    config
  );
}

// Generator Call
function* fetchGITBranches(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(fetchGITBranchesCall, sessionToken, payload);
    const data = response.data;
    if (data !== null) {
      // toast.success("App created successfully");
      yield put({ type: FETCH_GIT_BRANCHES_SUCCESS, data });
      // payload.data.history.push("/app/" +  data.id);
    }
  } catch (error) {
    // if(error && error.response && error.response.data && error.response.data.error)
    //   toast.error(error.response.data.error);
    // else
    //   toast.error("App creation failed. Please try again");
  }
}

function fetchAppRoleCall(sessionToken, id) {
  const config = {
    headers: {
      Authorization: 'basic ' + sessionToken,
    },
  };
  return axios.get(
    endpoints.GET_ROLE.replace(':id', id).replace(':module', 'application'),
    config
  );
}

function* fetchAppRole(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(
      fetchAppRoleCall,
      sessionToken,
      payload.data.id
    );
    const data = response.data;
    if (data !== null) {
      yield put({ type: FETCH_APP_ROLE_SUCCESS, data });
    } 
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    )
      Toast.error(error.response.data.error);
    else Toast.error('Unable to get App Role. Please try again');
  }
}

function renameAppCall(sessionToken, payload) {
  const config = {
    headers: {
      Authorization: 'basic ' + sessionToken,
    },
  };

  return axios.put(
    endpoints.RENAME_APP.replace(":id", payload.data.id),
    payload.data.name,
    config
  );
}

// Generator Call
function* renameApp(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(renameAppCall, sessionToken, payload);
    if(response.status){
      yield call(fetchAppDetail, payload)
      yield put ({type:RENAME_SUCCESS})
      toast.success('App name updated successfully')
    }
  } catch (error) {
    yield put({type:RENAME_ERROR})
      toast.error(error.response.data.error);
  }
}

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(FETCH_APP_DETAIL, fetchAppDetail);
  yield takeLatest(DELETE_APP, deleteApp);
  yield takeLatest(FETCH_APP_ENVIRONMENTLIST, fetchEnvironmentsByAppId);
  yield takeLatest(FETCH_PLUGIN_DETAIL, fetchPluginDetailsById);
  yield takeLatest(CREATE_APP, createApplication);
  yield takeLatest(FETCH_APP_AVAILABLE_RESOURCE, fetchAppAvailableResource);
  yield takeLatest(GIT_CONNECT, gitConnect);
  yield takeLatest(CR_CONNECT, crConnect);
  yield takeLatest(FETCH_GIT_ORGANIZATIONS, fetchGITOrganizations);
  yield takeLatest(FETCH_GIT_ORG_REPOS, fetchGITOrgRepos);
  yield takeLatest(FETCH_GIT_REPOS, fetchGITRepos);
  yield takeLatest(FETCH_GIT_BRANCHES, fetchGITBranches);
  yield takeLatest(FETCH_APP_ROLE, fetchAppRole);
  yield takeLatest(RENAME_APP, renameApp);
}
