import React, { useEffect, useState } from "react";
import { Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import ExternelSecret from "../../../createenvironment/generals/ExternelSecret";
import { fetchSecretLogs, updateExternalSecret, updateSecretSync } from "../../../redux/actions";
import { StyledTableCell } from "../../../../../components/styledtabelcell/StyledTabelCell";
import BackdropLoader from "../../../../../components/loader/BackdropLoader";
import TooltipChip from "../../../../../components/tooltipchip/TooltipChip";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import ActivityLogs from "../../../../../components/activitylogs/ActivityLogs";

/* istanbul ignore next */
const useStyles = makeStyles((theme) => ({
  secret: {
    fontSize: 16,
    fontWeight: 700,
    color: "#039be5",
  },
  mainDiv: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
  },
  typo: {
    marginTop: "15px",
  },
  editBtn: {
    marginLeft: "4px",
  },
  external_secret: {
    backgroundColor: "#fff",
    padding: "0px 20px 20px 20px",
  },
  BtnGroup: {
    padding: "10px",
    marginTop: "30px",
    display: "flex",
    justifyContent: "flex-end",
  },
  btn: {
    marginLeft: "20px",
  },
  table:{
    marginTop:"20px"
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  logsSection:{
    marginTop:"20px"
  }
}));

interface Props extends PropsFromRedux {}

function ExternalSecret(props: Props) {
  const { environmentDetails, updateSecretSync, updateExternalSecret, 
    fetchingExternalSecret, updatingSecret, fetchSecretLogs, secretLogs } = props;

  const classes = useStyles();

  const [edit, setEdit] = useState(false);
  const [showActivity, setShowSctivity] = useState(true);
  const [externalSecret, setExternalSecret] = useState(null);

  useEffect(()=>{
    const eid = environmentDetails.id;
    fetchSecretLogs(eid);
    setExternalSecret(environmentDetails.external_secret);
  },[])

  const updatedExternalSecret = (updatedState: any) => {
    setExternalSecret({...updatedState})
  }

  const handleEditSecret = () => {
    setEdit(true);
  };

  const handleUpdateSecret = () => {
    const id = environmentDetails.id;
    const external_secret = externalSecret;
    updateExternalSecret(id, external_secret);
    setEdit(false);
  };

  const onRefresh = () => {
    const eId = environmentDetails.id;
    updateSecretSync(eId);
  }

  const getSecretKeys = (arr:string[]) => {
      let keyValues;
      if(environmentDetails.external_secret.secrets){
        keyValues = (
          <Grid container spacing={1}>
            {arr.map((_tag: string, ind: number) => (
                <TooltipChip title={_tag} key={ind} />
            ))}
          </Grid>
        )
      } 
      return keyValues;
  }

  return (
    <>
      {edit === false ? (
        <>
          <Card>
            <div className={classes.mainDiv}>
              <div className={classes.typo}>
                <Typography color="inherit" variant="h5">
                  External Secrets
                </Typography>
              </div>
              <div>
                <span>
                  <Tooltip title="Refresh External Secrets">
                    <IconButton
                    onClick={() => onRefresh()}
                    >
                      <RefreshIcon color="primary" fontSize="medium" />
                    </IconButton>
                  </Tooltip>
                </span>
                <span className={classes.editBtn}>
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<EditIcon />}
                    onClick={() => handleEditSecret()}
                  >
                    Edit Secret
                  </Button>
                </span>
              </div>
            </div>
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={4} md={4} className={classes.secret}>
                  Provider
                </Grid>
                <Grid item xs={8} md={8}>
                  {environmentDetails.external_secret.provider}
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={4} md={4} className={classes.secret}>
                  Refresh Interval
                </Grid>
                <Grid item xs={8} md={8}>
                  {
                    environmentDetails.external_secret.additional_setting
                      .refresh_interval
                  }
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={4} md={4} className={classes.secret}>
                  Creation Policy
                </Grid>
                <Grid item xs={8} md={8}>
                  {
                    environmentDetails.external_secret.additional_setting
                      .creation_policy
                  }
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={4} md={4} className={classes.secret}>
                  Deletion Policy
                </Grid>
                <Grid item xs={8} md={8}>
                  {
                    environmentDetails.external_secret.additional_setting
                      .deletion_policy
                  }
                </Grid>
              </Grid>
              <Grid container spacing={3} className={classes.table}>
                <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Secret Name</StyledTableCell>
                        <StyledTableCell>Keys</StyledTableCell>
                      </TableRow>
                    </TableHead>

                    {environmentDetails && Object.keys(environmentDetails.external_secret.secrets).length &&
                      Object.keys(environmentDetails.external_secret.secrets).map((key, keyIndex) => {
                        return (
                          <>
                            <TableRow>
                              <StyledTableCell component="th" scope="row">
                                {key}
                              </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                {environmentDetails.external_secret.secrets[key] !== ""?
                                  getSecretKeys(environmentDetails.external_secret.secrets[key].split(',')) : <div style={{ fontStyle: 'italic' }} >No keys added</div>
                                }
                                </StyledTableCell>
                            </TableRow>
                          </>
                        );
                      })}
                  </Table>
                </TableContainer>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </>
      ) : (
        <Card className={classes.external_secret}>
        <CardHeader
        title="External Secret Update"
        />
        <Divider/>
        <CardContent>
          <ExternelSecret
            externalSecret={environmentDetails.external_secret}
            edit={edit}
            updatedExternalSecret={updatedExternalSecret}
          />
        </CardContent>
        <CardActions className={classes.BtnGroup}>
          <div>
            <span>
              <Button
                color="secondary"
                variant="contained"
                size="small"
                onClick={() => setEdit(false)}
              >
                cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                size="small"
                className={classes.btn}
                onClick={() => handleUpdateSecret()}
                // disabled = {!externalSecret.secrets[0]?.secretName}
              >
                Update
              </Button>
            </span>
          </div>
        </CardActions>
        </Card>
      )}
      
      {fetchingExternalSecret && (
        <BackdropLoader
          message="Fetching External Secret"
          />
        )}
     {updatingSecret && (
        <BackdropLoader
          message="Updating External Secret"
          />
        )}
        <div className={classes.logsSection}>
          <Card>
            <CardHeader
            title={"Activites"}
            onClick={()=>setShowSctivity(!showActivity)}
            action={
              <IconButton
              aria-label="Show/Hide Activity"
              aria-expanded={showActivity}
              className={clsx(classes.expand, {
              [classes.expandOpen]: showActivity,
              })}
              >
                <ExpandMoreIcon />
              </IconButton>
            }
            >
            </CardHeader>
            <Divider/>
            <Collapse in={showActivity} timeout={600} unmountOnExit>
                <CardContent>
                    <div>
                        <ActivityLogs activityLogs={secretLogs} showAllLogs={true}/>
                    </div>
                </CardContent>
            </Collapse>
          </Card>
        </div>
    </>
  );
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  environmentDetails: state.EnvironmentReducer.environmentDetails,
  fetchingExternalSecret: state.EnvironmentReducer.fetchingExternalSecret,
  updatingSecret: state.EnvironmentReducer.updatingSecret,
  secretLogs: state.EnvironmentReducer.secretLogs,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateExternalSecret: (id: number, external_secret: any) =>
      dispatch(updateExternalSecret(id, external_secret)),
      updateSecretSync: (eid: any) => dispatch(updateSecretSync(eid)),
      fetchSecretLogs: (eid: number) => dispatch(fetchSecretLogs(eid))
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(ExternalSecret);
