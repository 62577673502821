const initialState = {
    initContainerList: null,
    initContainerDetails: null,
    initContainrCreatedId: -1,
    initContainerStatus: null,
    initContainerImages: null,
    initContainerListLoader: false,
    deletingInitContainer: false,
    initContainerLog: null,
    fetchingInitContainerList: false,
    fetchingInitContainerDetail: false,
    updatingInitContainer: false,
    creatingInitContainer: false,
}

export default initialState