import { call, takeLatest, put, select } from 'redux-saga/effects';
import axios from 'axios';
import endpoints from '../../../../constants/endpoints';
import { sessionTokenSelector } from '../../../login/redux/selectors'
import { 
  GET_PROJECT_LIST_CALL,
  GET_PROJECT_LIST_CALL_SUCCESS,
  GET_PROJECT_LIST_CALL_FAILURE,
  BEGIN_LOADING_INDICATOR,
  END_LOADING_INDICATOR,
} from './actions';
import toast from '../../../../components/toast/Toast';

// project list Axios call
function getProjectList(sessionToken) {
   const config = {
    headers : {
      'Content-Type': 'application/json',
      'Authorization': 'basic ' + sessionToken,
    }
  }
  return axios.get(endpoints.GET_PROJECT_LIST,config)
}

// Generator Call 
function* getProjectListGenerator() {
  yield put({ type: BEGIN_LOADING_INDICATOR })
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const response = yield call(getProjectList, sessionToken)
    
    const data = response.data;
    if (response.status === 200){
      yield put({ type: END_LOADING_INDICATOR })
      // if(data.length > 0){
      //   data[0].currentProject = true
      // }
      yield put({ type: GET_PROJECT_LIST_CALL_SUCCESS, data })
    } else {
      toast.error('Some error while getting Project List');
      yield put({ type: END_LOADING_INDICATOR })
      yield put({
        type: GET_PROJECT_LIST_CALL_FAILURE,
        data: {
          message: 'Some error while getting Project List',
        },
      })
    }
  } catch (error) {
    toast.error('Some error while getting Project List');
    yield put({ type: END_LOADING_INDICATOR })
    yield put({
      type: GET_PROJECT_LIST_CALL_FAILURE,
      data: {
        message: 'Some error while getting Project List',
      },
    })
  }
}

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(GET_PROJECT_LIST_CALL, getProjectListGenerator)
}