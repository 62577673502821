import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { connect,ConnectedProps } from "react-redux";
import BillingHistory from "./BillingHistory";
import BillingSettings from "./BillingSettings";
// import { makeStyles } from "@material-ui/styles";
import PaymentDue from "./PaymentDue";
import PaymentSummary from "./PaymentSummary";
import PromoCode from "./PromoCode";
import {
  getInvoice,
  getLatestPaymentHistory,
  getPaymentSetting,
  resetInvoice,
  resetPaymentSetting,
} from "./redux/actions";
import BackdropLoader from "../../../components/loader/BackdropLoader";
import { useTranslation } from 'react-i18next';
import { Dispatch } from "redux";


export const BillingTab = (props:PropsFromRedux) => {
  const [t] = useTranslation()
  useEffect(() => {
    props.getInvoice();
    props.getPaymentSetting();
    props.getLatestPaymentHistory();
    return () => {
      props.resetInvoice();
      props.resetPaymentSetting();
    };
  }, []);

  return (
    <Grid container spacing={2} data-test="main-container">
      {
        <>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <PaymentDue data-test="payment-due" />
              {props.paymentSetting && 
              <PromoCode data-test="promo-code" />
              }
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <PaymentSummary data-test="payment-summary" />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <BillingHistory data-test="billing-history" />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <BillingSettings data-test="billing-settings" />
            </Grid>
          </Grid>
        </>
      }
      {props.updatingPaymentSetting && (
        <BackdropLoader
          message={t('Account.Billing.updatePayment')}
          data-test="loader"
        />
      )}
    </Grid>
  );
};

/*istanbul ignore next */
const mapStateToProps = (state:any) => ({
  updatingPaymentSetting: state.PaymentReducer.updatingPaymentSetting,
  paymentSetting: state.PaymentReducer.paymentSetting
});

/*istanbul ignore next */
const mapDispatchtoProps = (dispatch:Dispatch) => {
  return {
    getInvoice: () => dispatch(getInvoice()),
    getPaymentSetting: () => dispatch(getPaymentSetting()),
    resetInvoice: () => dispatch(resetInvoice()),
    resetPaymentSetting: () => dispatch(resetPaymentSetting()),
    getLatestPaymentHistory: () => dispatch(getLatestPaymentHistory()),
  };
};
const connector= connect(mapStateToProps, mapDispatchtoProps)
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(BillingTab);
