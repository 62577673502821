import axios from "axios";
import { put, takeLatest, select } from "redux-saga/effects";
import { sessionTokenSelector } from "../../login/redux/selectors";
import endpoints from "../../../constants/endpoints";
import {
  FETCH_PODS,
  FETCH_PODS_SUCCESS,
  FETCH_PODS_FAILURE,
  FETCH_CONTAINER_INSIGHTS,
  FETCH_CONTAINER_INSIGHTS_SUCCESS,
  FETCH_CONTAINER_INSIGHTS_FAILURE,
  FETCH_CONTAINER_LOGS_URL,
  FETCH_CONTAINER_LOGS_URL_SUCCESS,
  FETCH_CONTAINER_LOGS_URL_FAILURE,
  FETCH_CONTAINER_WEBSHELL_URL,
  FETCH_CONTAINER_WEBSHELL_URL_SUCCESS,
  FETCH_CONTAINER_WEBSHELL_URL_FAILURE,
  FETCH_POD_EVENTS,
  FETCH_POD_EVENTS_SUCCESS,
  FETCH_INSIGHT_GRAPH_DATA,
  FETCH_INSIGHT_GRAPH_DATA_SUCCESS,
  FETCH_INSIGHT_GRAPH_DATA_FAILURE,
  UPDATE_POD_LABELS,
  UPDATE_POD_LABELS_SUCCESS,
  UPDATE_POD_LABELS_FAILURE,
  RESTART_POD,
} from "./actions";
import toast from "../../../components/toast/Toast";

function* fetchPods(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = {
      headers: {
        Authorization: "basic " + sessionToken,
      },
    };

    let podListEndpoint = endpoints.PODS.GET_PODS.replace(
      ":envId",
      payload.data.envId
    );

    if (payload.data.isHelmEnv) {
      podListEndpoint += "?type=helm";
    }

    const response = yield axios.get(podListEndpoint, config);
    const data = response.data;
    if (data) {
      yield put({ type: FETCH_PODS_SUCCESS, data });
    }
  } catch (error) {
    if (error?.response?.data?.error) {
      toast.error(error.response.data.error);
    }
    yield put({ type: FETCH_PODS_FAILURE });
  }
}

function* fetchContainerInsights(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = {
      headers: {
        Authorization: "basic " + sessionToken,
      },
    };
    const response = yield axios.get(
      endpoints.PODS.GET_CONTAINER_INSIGHTS.replace(
        ":envId",
        payload.data.envId
      ).replace(":podName", payload.data.podName),
      config
    );
    const data = response.data;
    if (data) {
      yield put({ type: FETCH_CONTAINER_INSIGHTS_SUCCESS, data });
    }
  } catch (error) {
    if (error?.response?.data?.error) {
      toast.error(error.response.data.error);
    }
    yield put({ type: FETCH_CONTAINER_INSIGHTS_FAILURE });
  }
}

function* fetchContainerLogsUrl(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = {
      headers: {
        Authorization: "basic " + sessionToken,
      },
    };

    let terminalEndpoint = endpoints.PODS.GET_LOGS_WEBSHELL_TOKEN;

    if (payload.data.isHelmEnv) {
      terminalEndpoint += "?type=helm";
    }

    const response = yield axios.post(
      terminalEndpoint,
      payload.data.jsonBody,
      config
    );

    const data = response.data;
    if (data) {
      if (payload.data.callback) {
        yield put({ type: FETCH_CONTAINER_LOGS_URL_FAILURE });

        payload.data.callback(data.url);
      } else {
        yield put({ type: FETCH_CONTAINER_LOGS_URL_SUCCESS, data: data.url });
      }
    }
  } catch (error) {
    if (error?.response?.data?.error) {
      toast.error(error.response.data.error);
    }
    yield put({ type: FETCH_CONTAINER_LOGS_URL_FAILURE });
  }
}

function* fetchContainerWebshellUrl(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = {
      headers: {
        Authorization: "basic " + sessionToken,
      },
    };

    let terminalEndpoint = endpoints.PODS.GET_LOGS_WEBSHELL_TOKEN;

    if (payload.data.isHelmEnv) {
      terminalEndpoint += "?type=helm";
    }

    const response = yield axios.post(
      terminalEndpoint,
      payload.data.jsonBody,
      config
    );

    const data = response.data;
    if (data) {
      if (payload.data.callback) {
        yield put({ type: FETCH_CONTAINER_WEBSHELL_URL_FAILURE });
        payload.data.callback(data.url);
      } else {
        yield put({
          type: FETCH_CONTAINER_WEBSHELL_URL_SUCCESS,
          data: data.url,
        });
      }
    }
  } catch (error) {
    if (error?.response?.data?.error) {
      toast.error(error.response.data.error);
    }
    yield put({ type: FETCH_CONTAINER_WEBSHELL_URL_FAILURE });
  }
}

function* fetchPodEvents(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = {
      headers: {
        Authorization: "basic " + sessionToken,
      },
    };

    let podEventsEndpoint = endpoints.PODS.FETCH_POD_EVENTS.replace(
      ":envId",
      payload.data.envId
    );

    if (payload.data.isHelmEnv) {
      podEventsEndpoint += "?type=helm";
    }

    const response = yield axios.get(podEventsEndpoint, config);

    const data = response.data;
    if (data !== null) {
      yield put({ type: FETCH_POD_EVENTS_SUCCESS, data });
    } else {
      toast.error("Couldn't fetch Pod Events");
    }
  } catch (err) {
    if (err?.response?.data?.error) {
      toast.error(err.response.data.error);
    } else {
      toast.error("Failed to fetch Pod Events");
    }
  }
}

function* fetchInsightGraphData(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = {
      headers: {
        Authorization: "basic " + sessionToken,
      },
    };
    const response = yield axios.post(
      endpoints.PODS.FETCH_INSIGHT_GRAPH_DATA.replace(
        ":envId",
        payload.data.envId
      ),
      payload.data.jsonBody,
      config
    );

    const data = response.data;
    if (data) {
      yield put({ type: FETCH_INSIGHT_GRAPH_DATA_SUCCESS, data });
    }
  } catch (err) {
    if (err?.response?.data?.error) {
      toast.error(err.response.data.error);
    }

    yield put({ type: FETCH_INSIGHT_GRAPH_DATA_FAILURE });
  }
}

function* updatePodLabelsData(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = {
      headers: {
        Authorization: "basic " + sessionToken,
      },
    };

    let { envId, podName, labelData } = payload.data;
    envId = envId.toString();

    let url = endpoints.PODS.UPDATE_POD_LABEL.replace(":envId", envId).replace(
      ":podName",
      podName
    );

    const response = yield axios.put(url, { ...labelData }, config);

    const data = response.data;
    if (data) {
      yield put({ type: UPDATE_POD_LABELS_SUCCESS, data });
      toast.success("Succesfully triggered pod update.Click refresh button to see the updates");
    }
  } catch (err) {
    yield put({ type: UPDATE_POD_LABELS_FAILURE });
    if (err?.response?.data?.error) {
      toast.error(err.response.data.error);
    }
    else {
      toast.error("Failed to update pod data");
    }
  }
}

function* restartPod(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = {
      headers: {
        Authorization: "basic " + sessionToken,
      },
    };

    let resstartEndpoint = endpoints.PODS.RESTART_POD.replace(
      ":envId",
      payload.data.envId
    );
    yield axios.post(resstartEndpoint, payload.data.jsonBody, config);
    // toast.success("Pod Restart Initiated");
    payload.data.callBack();
  } catch (error) {
    if (error?.response?.data?.error) {
      toast.error(error.response.data.error);
    }
  }
}

export default function* watcherSaga() {
  yield takeLatest(UPDATE_POD_LABELS, updatePodLabelsData);
  yield takeLatest(FETCH_PODS, fetchPods);
  yield takeLatest(FETCH_CONTAINER_INSIGHTS, fetchContainerInsights);
  yield takeLatest(FETCH_CONTAINER_LOGS_URL, fetchContainerLogsUrl);
  yield takeLatest(FETCH_CONTAINER_WEBSHELL_URL, fetchContainerWebshellUrl);
  yield takeLatest(FETCH_POD_EVENTS, fetchPodEvents);
  yield takeLatest(FETCH_INSIGHT_GRAPH_DATA, fetchInsightGraphData);
  yield takeLatest(RESTART_POD, restartPod);
}
