import React from 'react'
import '../../environment/environmentinfo/environmentinfo.css';
import { Tabs, Grid, Button, Menu, MenuItem, Divider, Tooltip } from '@material-ui/core';
//import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
//import Box from '@material-ui/core/Box';
// import LogsTab from './logstab/LogsTab';
// import InsightTab from './insighttab/InsightTab';
// import CicdTab from './cicdtab/CicdTab';
// import SettingsTab from './settingstab/SettingsTab';
// import AddonsTab from './addonstab/AddonsTab';
// import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { fetchHelmEnvDetails, fetchEnvRole } from '../redux/actions'
import { fetchRoles } from '../../../components/adduserpopup/redux/actions'
import { getStatusColor } from '../../../helpers/utils'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { deleteHelmEnvironment, clearHelmEnvironmentdetails, fetchHelmEnvironmentStateInitiate, fetchHelmEnvironmentState, reCreateEnvironment, setCurrentEnvironment, clearCurrentEnvironment, startHelmEnvironment, stopHelmEnvironment, rollbackEnv, initiateFetchPackageStatus } from '../redux/actions'
import { changeEnvTabValue } from "../../environment/redux/actions"
import { fetchPluginVersions, clearPlugInVersions } from '../../app/createapp/redux/actions'
import { fetchPluginDetailsById, clearPluginDetails } from '../../app/redux/actions'
import { switchOrg } from "../../organization/redux/actions";
import ConfirmActionPopup from '../../../components/confirmactionpopup/ConfirmActionPopup'
import ConfirmDeletePopup from '../../../components/confirmdeletepopup/ConfirmDeletePopup'
import { updateBreadcrumb } from '../../project/redux/actions'
import { setCurrentProject } from '../../project/redux/actions'
import { isAuthorized } from '../../../helpers/utils'
//import { w3cwebsocket as W3CWebSocket } from "websocket";
import { sessionTokenSelector } from '../../login/redux/selectors';
import Skeleton from 'react-loading-skeleton'
import BackdropLoader from '../../../components/loader/BackdropLoader';
import TabsSkeleton from '../../../components/skeletons/TabsSkeleton';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import AdjustIcon from '@material-ui/icons/Adjust';
// import CronJobList from '../../cronjob/CronJobList'
// import RecreatePopup from './RecreatePopup'
// import MemoryOutlinedIcon from '@material-ui/icons/MemoryOutlined';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
// import StraightenOutlinedIcon from '@material-ui/icons/StraightenOutlined';
import WebsocketConnection from '../../../containers/WebsocketConnection'
// import RollbackPopup from './RollbackPopup';
// import BackupTab from '../../backup/BackupTab';
import { HelmEnvSettingTabs } from "../../../constants/environmentTabs";
import { CreateApp_TemplateTypes } from '../../../constants/enums';
import { DateHandler } from '../../../components/dateHandler/DateHandler';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { AppConstants } from '../../../constants/appconstants';
import NoContentImage from '../../../components/nocontentimagecontainer/NoContentImage';
import paths from '../../../constants/paths';
import { useTranslation } from 'react-i18next';
import HelmOverviewTab from './HelmOverviewTab';
// import HelmLogsTab from './HelmLogsTab.js';
import HelmSettings from './helmsettings/HelmSettings';
// import HelmInsights  from './HelmInsights';
import BackupTab from '../../backup/BackupTab';
import {TabPanel} from "../../../components/tabpanel/TabPanel"
import { fetchProjectRole } from "../../project/redux/actions";
import PodsOverview from "../../pods/PodsOverview"

//import moment from 'moment';

/* istanbul ignore next */
const useStyles = makeStyles((theme) => ({
    header: {
        margin: "0px 50px 0px 0px"
    },
    tab: {
        minWidth: 100
    },
    envTitle: {
        padding: "5px 20px 5px 0px",
        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
            maxWidth: 150,
            overflow: "hidden",
            textOverflow: "ellipsis"
        },
    }
}));

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

// function handleClick(event) {
//   event.preventDefault();

// }

export const HelmEnvironmentInfo = (props) => {
    const [isConfirmPopupOpen, setIsConfirmPopupOpen] = React.useState(false)
   // const [isRecreateConfirmPopupOpen, setIsRecreateConfirmPopupOpen] = React.useState(false)
    const [value, setValue] = React.useState(0)
    //const [currentVersions, setCurrentVersions] = React.useState(null)
    const [environmentId, setEnvironmentId] = React.useState(0)
    const [stopEnvPopupOpen, setStopEnvPopupOpen] = React.useState(false)
    const [startEnvPopupOpen, setStartEnvPopupOpen] = React.useState(false)
    //const [rollbackPopup, setRollbackPopup] = React.useState(false)
    const [innerTab, setInnerTab] = React.useState(0)
    const [anchorEl, setAnchorEl] = React.useState(null)

    const updateInnerTab = (_value) => {
        setInnerTab(_value)
    }

    const handleChange = (event, newValue) => {
        setValue(newValue)
        if (event) {
            const tabPanelObj = document.getElementById('simple-tabpanel-' + newValue);
            if (tabPanelObj) {
                const name = tabPanelObj.getAttribute("name");
                if (name) {
                    props.history.push({
                        pathname: paths.HELMENVIRONMENTINFO.replace(":eId", props.helmEnvironmentDetails?.id) + name
                    });
                }
            }
        }
    };

    const getTabPanelIndex = React.useCallback((name) => {
        const tabPanel = document.getElementsByName(name);
        if (tabPanel && tabPanel.length > 0) {
            const id = tabPanel[0].id;
            return id.split('-').pop();
        }
    }, [])

    const getTabIndexbyName = () => {
        let _hash = "";
        if (props.location.hash) {
            _hash = props.location.hash;
        }
        else {
            const hash = props.location.pathname && props.location.pathname.trim().split("#");
            if (hash && hash.length === 2) {
                _hash = "#" + hash[1];
            }
        }

        if (_hash) {
            const splitHash = _hash.split("_");
            if (splitHash.length > 1) {
                const subIndex = HelmEnvSettingTabs(splitHash[0]).indexOf(splitHash[1]);
                updateInnerTab(subIndex > -1 ? subIndex : 0);
            }

            const index = getTabPanelIndex(splitHash[0]);
            if (index > -1)
                handleChange(null, parseInt(index));
        }
    }

    const changeHash = (hash, val) => {
        const index = parseInt(getTabPanelIndex(`#${hash}`))
        setValue(index)
        props.history.push({
            pathname: paths.HELMENVIRONMENTINFO.replace(":eId", props.helmEnvironmentDetails?.id) + "#" + hash,
            state: { value: val ? val : 0 }
        })
    }

    const manageVersions = React.useCallback((main, other) => {
        const mainVersion = typeof main !== "object" ? JSON.parse(main) : main
        const otherVersion = typeof other !== "object" ? JSON.parse(other) : other
        let currentVersions = {}
        currentVersions.main = mainVersion.name
        otherVersion.forEach(_v => {
            currentVersions[_v.deployment_name] = _v.name
        })
        //setCurrentVersions(currentVersions)
    }, [])

    const initialize = (id) => {
        // props.clearHelmEnvironmentdetails()
        props.fetchHelmEnvDetails(id)
        //props.fetchUserListInEnvironmentAction(id)
        //props.fetchEnvLogs(id, "")
        props.setCurrentEnvironment(id);
        props.fetchHelmEnvironmentStateInitiate(id, props?.history?.location?.state?.initial);
        props.fetchEnvRole(id)
        // props.changeEnvTabValue(0);
        //socketConnection(id)
        setEnvironmentId(id)
    }

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget)
    };

    const handleMenuClose = () => {
        setAnchorEl(null)
    };

    const handleDeleteEnv = () => {
        handleMenuClose();
        setIsConfirmPopupOpen(true)
    }

    const handleDisAgreeHandler = () => {
        setIsConfirmPopupOpen(false)
    }

    const handleAgreeHandler = () => {
        props.deleteHelmEnvironment(props.helmEnvironmentDetails?.id, props.helmEnvironmentDetails?.application_id ?? 0, props.history)
        setIsConfirmPopupOpen(false)
    }

    const handleRecreateEnv = () => {
        handleMenuClose();
        props.fetchPluginVersions(props.helmEnvironmentDetails?.application?.plugin_id)
        props.fetchPluginDetailsById(props.helmEnvironmentDetails?.application?.plugin_id);
        //setIsRecreateConfirmPopupOpen(true)
    }

    const switchOrgCallback = () => {
        props.fetchEnvRole(environmentId)
    }

    // const handleRecreateDisAgreeHandler = () => {
    //     setIsRecreateConfirmPopupOpen(false)
    // }

    // const handleRecreateAgreeHandler = (data) => {
    //     const { helmEnvironmentDetails } = props
    //     if (helmEnvironmentDetails?.version) {
    //         // const initialVersion = helmEnvironmentDetails.version.name
    //         // if(initialVersion !== data?.version?.name){
    //         const payload = {
    //             ...data
    //         }
    //         props.reCreateEnvironment(props.helmEnvironmentDetails?.id, payload, helmEnvironmentDetails?.git_url === "" ? null : changeHash)
    //         // }
    //     }

    //     setIsRecreateConfirmPopupOpen(false)
    // }

    /* Initial Render call */
    React.useEffect(() => {
        props.fetchRolesAction();
        initialize(props.match?.params?.eId?.split('#')[0]);
        //getTabIndexbyName(props.location.hash)
        // if (props.helmEnvironmentDetails && props.helmEnvironmentDetails.application) {
        //     // props.fetchPluginVersions(props.helmEnvironmentDetails?.application?.plugin_id)
        //     // props.fetchPluginDetailsById(props.helmEnvironmentDetails.application.plugin_id)
        // }
        return (() => {
            props.clearHelmEnvironmentdetails()
            props.setCurrentEnvironment(0);
        })
    }, [])

    /* Value Change SideEffects call */
    React.useEffect(() => {
        props.changeEnvTabValue(value)
    }, [value])

    /* Params Eid Change SideEffects call */
    React.useEffect(() => {
        if (props.match?.params?.eId) {
            // initialize(props.match?.params?.eId?.split('#')[0]);
            const newHash = props.match.params.eId.split('#');
            if (newHash.length > 1) {
                const index = getTabPanelIndex("#" + newHash[1]);
                if (index > -1) {
                    handleChange(null, parseInt(index));
                }
            }
        }
    }, [props.match?.params?.eId, props.helmEnvironmentDetails])

    /* EnvironmentDetails change sideeffects call */
    React.useEffect(() => {
        if (props.helmEnvironmentDetails) {
            const { helmEnvironmentDetails } = props
            const breadcrumbData = [
                { name: helmEnvironmentDetails.application?.project?.name, path: paths.PROJECTINFO.replace(":id", helmEnvironmentDetails?.application?.project_id) },
                { name: helmEnvironmentDetails.application?.name, path: paths.APPDETAILS.replace(":appId", helmEnvironmentDetails?.application?.id) },
                { name: helmEnvironmentDetails.name, path: paths.HELMENVIRONMENTINFO.replace(":id", helmEnvironmentDetails?.id) }
            ]
            props.updateBreadcrumb(breadcrumbData);
            props.setCurrentProject(helmEnvironmentDetails?.application?.project_id);
            if(helmEnvironmentDetails?.application?.project_id){
                props.fetchProjectRole(helmEnvironmentDetails.application.project_id)
            }
            // props.setCurrentEnvironment(helmEnvironmentDetails?.id);

            const org_id = helmEnvironmentDetails.application?.project?.organization_id;
            if (org_id) {
                if (!props.currentOrganization || props.currentOrganization.id.toString() !== org_id) {
                    props.switchOrg(org_id, null, null, 0, switchOrgCallback);
                }
            }
            else if (props.currentOrganization?.id) {
                props.switchOrg(0, null, null, 0, switchOrgCallback);
            }
            setTimeout(getTabIndexbyName, 200);

        }
    }, [props.helmEnvironmentDetails])

    /* Recreateion Triggered change Sideeffects */
    React.useEffect(() => {
        if (props.reCreationTriggered) {
            props.history.push({
                pathname: paths.HELMENVIRONMENTINFO.replace(":eId", props.helmEnvironmentDetails?.id) + "#overview"
            });
            const index = parseInt(getTabPanelIndex("#overview"));
            setValue(index)
        }
    }, [props.reCreationTriggered])

    /* Version and other version Changed Sideeffects */
    React.useEffect(() => {
        if (props.helmEnvironmentDetails?.version && props.helmEnvironmentDetails?.other_version) {
            manageVersions(props.helmEnvironmentDetails.version, props.helmEnvironmentDetails.other_version)
        }
    }, [props.helmEnvironmentDetails?.version, props.helmEnvironmentDetails?.other_version])

    // const handleEnvRollback = () => {
    //     handleMenuClose();
    //     setRollbackPopup(true)
    // }

    // const handleRollbackAgree = (payload) => {
    //     setRollbackPopup(false)
    //     props.rollbackEnv(props.helmEnvironmentDetails.id, payload, changeHash)
    // }

    // const handleRollbackDisagree = () => {
    //     setRollbackPopup(false)
    // }

    const startEnvCall = () => {
        handleMenuClose();
        setStartEnvPopupOpen(true)
    }

    const startEnvDisagreeHandler = () => {
        setStartEnvPopupOpen(false)
    }

    const startEnvAgreeHandler = () => {
        props.startHelmEnvironment(props.helmEnvironmentDetails?.id)
        setStartEnvPopupOpen(false)
    }

    const stopEnvCall = () => {
        handleMenuClose();
        setStopEnvPopupOpen(true)
    }

    const stopEnvDisagreeHandler = () => {
        setStopEnvPopupOpen(false)
    }

    const stopEnvAgreeHandler = () => {
        props.stopHelmEnvironment(props.helmEnvironmentDetails?.id, changeHash, props.history)
        setStopEnvPopupOpen(false)
    }

    const redeployEnv = () => {
        props.history.push(paths.EDITHELMENVIRONMENT.replace(":eId", props.helmEnvironmentDetails?.id))
    }

    const navigateSchedule = (hash, val) => {
        changeHash(hash, val);
        handleMenuClose();
    }

    const { helmEnvironmentDetails, helmEnvironmentState } = props
    const [t] = useTranslation()
    const classes = useStyles()

    let statusColor = React.useMemo(() => {
        return getStatusColor(helmEnvironmentState?.state);
    }, [props.helmEnvironmentState])
    // let _backup = true
    // let _cicd = true
    // if (props.packageStatus && helmEnvironmentDetails?.application?.project?.organization_id > 0) {
    //     if (props.packageStatus[AppConstants.packages.VELERO] !== AppConstants.PackageStatus.Installed) {
    //         _backup = false
    //     }
    //     if (props.packageStatus[AppConstants.packages.SECRET_PATCHER] !== AppConstants.PackageStatus.Installed || props.packageStatus[AppConstants.packages.ARGO] !== AppConstants.PackageStatus.Installed) {
    //         _cicd = false
    //     }

    // }

    let tabIndexes = React.useMemo(() => {
        return {
            Overview: 0,
            Insight: 1,
            // Logs: 2,
            Backup: 2,
            Settings: 3
        }
    }, [])

    // let tabIndexes = props.helmEnvironmentDetails ? (props.helmEnvironmentDetails.service_type > 0 ?
    //     //props.helmEnvironmentDetails.service_type === CreateApp_TemplateTypes.git ?
    //     {
    //         Overview: 0,
    //         Insight: 1,
    //         Cicd: _cicd ? 2 : null,
    //         Logs: _cicd ? 3 : 2,
    //         Addons: _cicd ? 4 : 3,
    //         Jobs: _cicd ? 5 : 4,
    //         Backup: _backup ? _cicd ? 6 : 5 : null,
    //         Settings: _backup ? _cicd ? 7 : 6 : _cicd ? 6 : 5
    //     } : {
    //         //   Overview: 0,
    //         //   Insight: 1,
    //         //   Logs: 2,
    //         //   Addons: 3,
    //         //   Jobs: 4,
    //         //   Backup: _backup ? 5 : null,
    //         //   Settings: _backup ? 6 : 5,
    //         // }
    //         // : {
    //         Overview: 0,
    //         Insight: 1,
    //         Logs: 2,
    //         Addons: 3,
    //         Backup: _backup ? 4 : null,
    //         Settings: _backup ? 5 : 4
    //     }) : {}

    const getImage = () => {
        const { helmEnvironmentDetails: _helmEnvironmentDetails } = props;
        let _image = "";
        if (_helmEnvironmentDetails && _helmEnvironmentDetails.application) {
            if (_helmEnvironmentDetails.application.chart?.Icon) {
                _image = _helmEnvironmentDetails.application.chart.Icon
            }
            else {
                _image = _helmEnvironmentDetails.application.plugin?.image;
            }
        }
        return _image;
    }

    const tabs = React.useMemo(() => {
        return [
            {
                name: "#overview",
                label: `${t('Environment.EnvironmentInfo.overview')}`,
                render: true,
                dataTest:"overview-tab"
            },
            {
                name: "#insight",
                label: `${t('Environment.EnvironmentInfo.insight')}`,
                disabled: props.helmEnvironmentState?.state === AppConstants.EnvironmentStatus.Stopped,
                render: true,
                dataTest:"insight-tab"
            },
            // {
            //     name: "#logs",
            //     label: `${t('Environment.EnvironmentInfo.logs')}`,
            //     disabled: props.helmEnvironmentState?.State === AppConstants.EnvironmentStatus.Stopped,
            //     render: true,
            //     dataTest:"logs-tab"
            // },
            {
                name: "#backup",
                label: `${t('Environment.EnvironmentInfo.backup')}`,
                disabled: props.helmEnvironmentState?.state === AppConstants.EnvironmentStatus.Stopped,
                render: true,
                dataTest:"backup-tab"
            },
            {
                name: "#settings",
                label: `${t('Environment.EnvironmentInfo.settings')}`,
                disabled: props.helmEnvironmentState?.state === AppConstants.EnvironmentStatus.Stopped,
                render: true,
                dataTest: "settings-tab"
            },
        ]
    }, [])

    return (
        <div data-test="environmentInfoContainer">
            <WebsocketConnection envId={environmentId} helm={true} />
            {props.isDataNotFound &&
                <Grid container justify="center" alignItems="center" className="notFoundGrid" data-test="noDataFound">
                    <NoContentImage
                        type="404"
                        message={t('Environment.EnvironmentInfo.notFoundEnv')}
                        alt="No environment details"
                    />
                </Grid>
            }
            {!props.isDataNotFound &&
                <div data-test="dataFoundContainer">
                    <Grid container variant="outlined" className={classes.header} data-test="environmentDetailsContainer">
                        <Grid item md={12} xs={12}>
                            <Grid container direction="row" spacing={2} alignItems="center">
                                <Grid item>

                                    {/* <DashboardIcon color='primary' className='projectIcon' /> */}
                                    {helmEnvironmentDetails?.application?.plugin ?

                                        <img src={getImage()} alt='Plugin' height={25} className="pluginImg" data-test="environmentPluginImage" />
                                        : (<Skeleton circle={false} height={25} width={25} />)}

                                </Grid>
                                <Grid item xs>
                                    <Grid container direction="column">
                                        <Grid container direction="row" alignItems="center">
                                            <Tooltip title={helmEnvironmentDetails?.name ?? ""} arrow>
                                                <Typography variant="h4" className={classes.envTitle} data-test="environmentName"> {helmEnvironmentDetails?.name ?? (<Skeleton width={150} />)}
                                                </Typography>
                                            </Tooltip>
                                            <Typography variant="h4" >
                                                <span>
                                                    <Tooltip title={t('Environment.EnvironmentInfo.state')} data-test="environmentState">
                                                        <>
                                                            {
                                                                helmEnvironmentState?.state ?
                                                                    <AdjustIcon className='topIcon' style={{ color: statusColor }} /> :
                                                                    <Skeleton circle={true} height={10} width={10} />
                                                            }
                                                        </>
                                                    </Tooltip>
                                                    <span style={{ color: statusColor }} className="statusTxt" data-test="envStatusText"> {helmEnvironmentState?.state ? helmEnvironmentState.state : <Skeleton width={80} />}</span>
                                                </span>
                                            </Typography>

                                            {/* <span className='envSubDetails'>
                                                <span>
                                                    <Tooltip title="Uptime">
                                                        {helmEnvironmentDetails && helmEnvironmentDetails.createdat ?
                                                            (<ScheduleIcon className='topIcon' />) :
                                                            (<Skeleton circle={true} height={10} width={10} />)
                                                        }
                                                    </Tooltip>
                                                    <Typography color="textPrimary" variant="body2">{helmEnvironmentDetails?.createdat ? getDiffDays(helmEnvironmentDetails.createdat) : (<Skeleton width={80} />)}</Typography>
                                                </span>
                                                <span>
                                                    <Tooltip title="Version">
                                                        {
                                                            helmEnvironmentDetails?.chartVersion?.app_version ?
                                                                <SettingsOutlinedIcon className='topIcon' /> :
                                                                <Skeleton circle={true} height={10} width={10} />
                                                        }
                                                    </Tooltip>
                                                    <Typography color="textPrimary" variant="body2">{helmEnvironmentDetails?.chartVersion?.app_version ?? (<Skeleton width={80} />)}

                                                    </Typography>
                                                </span>
                                            </span> */}

                                            {/* <span className='envSubDetails'>
                                <span>
                                  <Typography color="primary">
                                    <Link href={ cname } target="_blank" rel="noreferrer" underline='always' color="primary">
                                      { helmEnvironmentState && helmEnvironmentState.CName }
                                    </Link>
                                  </Typography>
                                </span>
                              </span> */}
                                        </Grid>
                                        {/* <Grid item>
                                <Typography variant="caption">{helmEnvironmentDetails ? helmEnvironmentDetails.createdat : ""}</Typography>
                              </Grid> */}

                                        {/* <Grid item>
                              <Typography variant="caption">{ helmEnvironmentDetails && helmEnvironmentDetails.application && helmEnvironmentDetails.application.project ? helmEnvironmentDetails.application.project.name : ""} </Typography>
                            </Grid> */}
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    { 
                                    (isAuthorized("update", props.projectRole.name) || isAuthorized("delete", props.projectRole.name)) &&
                                    <Button variant="contained" color="primary" endIcon={<ExpandMoreIcon />} disableElevation disableFocusRipple disableTouchRipple
                                        aria-controls="simple-menu" aria-haspopup="true" onClick={e => handleMenuClick(e)}
                                        data-test="environmentActionsButton"
                                    // disabled={!helmEnvironmentState.State}
                                    >
                                        {t("Projects.ProjectInfo.actionsLabel")}
                                    </Button>
                                    }
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        disableScrollLock={true}
                                        onClose={() => handleMenuClose()}
                                        data-test="environmentMenu"
                                        //className={classes.profileMenu}
                                        getContentAnchorEl={null}
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    >
                                        {isAuthorized("delete", props.projectRole.name) && (
                                        <MenuItem onClick={() => handleDeleteEnv()} data-test="deleteOption">{t("Environment.EnvironmentInfo.deleteLabel")}</MenuItem>
                                        )}
                                        {isAuthorized("update", props.projectRole.name) && helmEnvironmentState?.state !== AppConstants.EnvironmentStatus.Stopped && helmEnvironmentDetails?.service_type !== CreateApp_TemplateTypes.cr && (
                                            <MenuItem onClick={() => handleRecreateEnv()} data-test="recreateOption">{t('Environment.EnvironmentInfo.recreate')}</MenuItem>
                                        )}
                                        {
                                            isAuthorized("update", props.projectRole.name) && 
                                            helmEnvironmentState.state !== AppConstants.EnvironmentStatus.Stopped &&
                                            <MenuItem onClick={() => stopEnvCall()} data-test="stopOption">{t('Environment.EnvironmentInfo.stop')}</MenuItem>
                                        }
                                        {
                                            isAuthorized("update", props.projectRole.name) &&
                                            helmEnvironmentState.state === AppConstants.EnvironmentStatus.Stopped &&
                                            <MenuItem onClick={() => startEnvCall()} data-test="startOption">{t('Environment.EnvironmentInfo.start')}</MenuItem>
                                        }
                                        {
                                            isAuthorized("update", props.projectRole.name) &&
                                            helmEnvironmentState.state !== AppConstants.EnvironmentStatus.Stopped &&
                                            <MenuItem onClick={() => redeployEnv()} data-test="redeployOption">{t('HelmEnvironment.redeploy')}</MenuItem>
                                        }
                                        {/* {isAuthorized("update", envRole.name) && helmEnvironmentState.State !== AppConstants.EnvironmentStatus.Stopped && helmEnvironmentDetails.service_type === CreateApp_TemplateTypes.git
                                            // &&  props.buildImages?.data?.length > 0
                                            &&
                                            <MenuItem onClick={() => handleEnvRollback()} data-test="rollbackOption">{t('Environment.EnvironmentInfo.rollBack')}</MenuItem>
                                        } */}
                                        {
                                           helmEnvironmentState.state !== AppConstants.EnvironmentStatus.Stopped &&
                                           <MenuItem onClick={() => navigateSchedule("settings",3)} >Scheduler</MenuItem> 
                                        }
                                    </Menu>
                                </Grid>
                            </Grid>

                        </Grid>

                        <Grid container spacing={3} className="p-tb-20">
                            <Grid item className="topgridalign">
                                <Tooltip title={t('Environment.EnvironmentInfo.version')} arrow>
                                    <span>
                                        {
                                            (helmEnvironmentDetails?.chartVersion?.app_version) ?
                                                <SettingsOutlinedIcon className="svgicon" /> :
                                                <Skeleton circle={true} height={10} width={10} />
                                        }
                                    </span>
                                </Tooltip>
                                <span className="topinfoGrid" data-test="chart-app-version">
                                    {(helmEnvironmentDetails?.chartVersion?.app_version ?? <Skeleton width={80} />)}
                                </span>
                            </Grid>

                            {/* <Grid item className="topgridalign">
                                <Tooltip title={t('Environment.EnvironmentInfo.ram')} arrow>
                                    <span>
                                        {
                                            helmEnvironmentDetails && helmEnvironmentDetails.resource ?
                                                <StraightenOutlinedIcon className={classes.svgicon} /> :
                                                <Skeleton circle={true} height={10} width={10} />
                                        }
                                    </span>
                                </Tooltip>
                                <span className="topinfoGrid">
                                    {helmEnvironmentDetails && helmEnvironmentDetails.resource ? spaceCoversion(helmEnvironmentDetails.resource.memory) : <Skeleton width={80} />}
                                </span>
                            </Grid> */}
                            {/* <Grid item className="topgridalign">
                                <Tooltip title={t('Environment.EnvironmentInfo.memory')} arrow>
                                    <span>
                                        {
                                            helmEnvironmentDetails && helmEnvironmentDetails.resource ?
                                                <MemoryOutlinedIcon className={classes.svgicon} /> :
                                                <Skeleton circle={true} height={10} width={10} />
                                        }
                                    </span>
                                </Tooltip>
                                <span className="topinfoGrid">
                                    {helmEnvironmentDetails && helmEnvironmentDetails.resource ? coreConversion(helmEnvironmentDetails.resource.cores) : <Skeleton width={80} />}
                                </span>

                            </Grid> */}
                            <Grid item className="topgridalign">
                                <Tooltip title={t('Projects.LoadbalancerInfo.regionLabel')} arrow data-test="region-label">
                                    <span>
                                        {
                                            helmEnvironmentDetails?.application?.cluster?.name ?
                                                <LanguageOutlinedIcon className="svgicon" /> :
                                                <Skeleton circle={true} height={10} width={10} />
                                        }
                                    </span>
                                </Tooltip>
                                <Tooltip title={helmEnvironmentDetails?.application?.cluster?.zone} data-test="zone-container">
                                    <span className="topinfoGrid">
                                        {helmEnvironmentDetails?.application?.cluster?.name ?? <Skeleton width={80} />}
                                    </span>
                                </Tooltip>

                            </Grid>
                            <Grid item className="topgridalign">
                                <DateHandler date={helmEnvironmentDetails?.createdat} icon={<ScheduleIcon className="svgicon" />} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider />

                    <div className='tabsContainer' data-test="tabsContainer">
                        {
                            helmEnvironmentDetails?.name ? (
                                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" indicatorColor="primary" variant="scrollable" data-test="tab-change">
                                    {tabs
                                        .filter(_t => (_t.render === true))
                                        .map((_tab, ind) => (
                                            <Tab key={ind} label={_tab.label} data-test={_tab.dataTest} {...a11yProps(ind)} className={classes.tab} name={_tab.name} disabled={_tab.disabled} />
                                        ))
                                    }
                                    {/* <Tab label={t('Environment.EnvironmentInfo.overview')} data-test="overview" {...a11yProps(0)} className={classes.tab} name="#overview" /> */}
                                    {/* <Tab label={t('Environment.EnvironmentInfo.logs')} data-test="logs" {...a11yProps(1)} className={classes.tab} disabled={helmEnvironmentState.State === AppConstants.EnvironmentStatus.Stopped} name="#logs" /> */}
                                    {/* <Tab label={t('Environment.EnvironmentInfo.insight')} data-test="insight" {...a11yProps(2)} className={classes.tab} disabled={helmEnvironmentState.State === AppConstants.EnvironmentStatus.Stopped} /> */}
                                    {/* {
                                        helmEnvironmentDetails && helmEnvironmentDetails.service_type > 0 && _cicd &&
                                        <Tab label={t('Environment.EnvironmentInfo.ci/cd')} data-test="ci/cd" {...a11yProps(2)} className={classes.tab} disabled={helmEnvironmentState.State === AppConstants.EnvironmentStatus.Stopped} />
                                    } */}
                                    {/* <Tab label={t('Environment.EnvironmentInfo.addons')} data-test="addons" {...a11yProps(4)} className={classes.tab} disabled={helmEnvironmentState.State === AppConstants.EnvironmentStatus.Stopped} /> */}
                                    {/* {
                                        helmEnvironmentDetails && helmEnvironmentDetails.service_type > 0 &&
                                        <Tab label={t('Environment.EnvironmentInfo.jobs')} data-test="jobs" {...a11yProps(5)} className={classes.tab} disabled={helmEnvironmentState.State === AppConstants.EnvironmentStatus.Stopped} />
                                    } */}
                                    {/* {_backup && (
                                        <Tab label={t('Environment.EnvironmentInfo.backup')} data-test="backup" {...a11yProps(6)} className={classes.tab} disabled={helmEnvironmentState.State === AppConstants.EnvironmentStatus.Stopped} />
                                    )} */}

                                    {/* <Tab label={t('Environment.EnvironmentInfo.settings')} data-test="settings" {...a11yProps(2)} className={classes.tab} disabled={helmEnvironmentState.State === AppConstants.EnvironmentStatus.Stopped} name="#settings" /> */}
                                </Tabs>

                            ) : (
                                <TabsSkeleton />
                            )
                        }
                        <div className='horizontalLine m-b-20' />
                        <TabPanel value={value} index={tabIndexes.Overview} name="#overview" tabPanel="simple-tabpanel">
                            <HelmOverviewTab changeHash={changeHash} data-test="helm-overview"/>
                        </TabPanel>
                        <TabPanel value={value} index={tabIndexes.Insight} name="#insight" tabPanel="simple-tabpanel">
                            {/* <HelmInsights changeHash={changeHash} /> */}
                            <PodsOverview environmentId={helmEnvironmentDetails?.id} isHelmEnv={true} />
                        </TabPanel>
                        {/* <TabPanel value={value} index={tabIndexes.Logs} name="#logs" tabPanel="simple-tabpanel">
                            <HelmLogsTab changeHash={changeHash} />
                        </TabPanel> */}
                        <TabPanel value={value} index={tabIndexes.Backup} name="#backup" tabPanel="simple-tabpanel">
                            <BackupTab changeHash={changeHash} history={props.history} updateInnerTab={updateInnerTab} innerTab={innerTab} isHelmEnv = {true} data-test="helm-backup"/>
                        </TabPanel>
                        <TabPanel value={value} index={tabIndexes.Settings} name="#settings" tabPanel="simple-tabpanel">
                            <HelmSettings changeHash={changeHash} history={props.history} updateInnerTab={updateInnerTab} innerTab={innerTab} isHelmEnv={true}/>
                        </TabPanel>
                        {/* <TabPanel className='removePadding' value={value} index={tabIndexes.Insight} name="#insight">
                            <InsightTab />
                        </TabPanel>
                        {
                            helmEnvironmentDetails && helmEnvironmentDetails.service_type > 0 &&
                            <TabPanel value={value} index={tabIndexes.Cicd} name="#cicd">
                                <CicdTab history={props.history} innerTab={innerTab} updateInnerTab={updateInnerTab} />
                            </TabPanel>
                        }
                        <TabPanel value={value} index={tabIndexes.Logs} name="#logs">
                            <LogsTab />
                        </TabPanel>
                        <TabPanel value={value} index={tabIndexes.Addons} name="#addons">
                            <AddonsTab history={props.history} changeHash={changeHash} />
                        </TabPanel>
                        <TabPanel value={value} index={tabIndexes.Jobs} name="#jobs">
                            <CronJobList history={props.history} />
                        </TabPanel>

                        <TabPanel value={value} index={tabIndexes.Backup} name="#backup">
                            <BackupTab history={props.history} innerTab={innerTab} updateInnerTab={updateInnerTab} />
                        </TabPanel>
                        <TabPanel value={value} index={tabIndexes.Settings} name="#settings" className={helmEnvironmentState.State === AppConstants.EnvironmentStatus.Stopped ? "avoid-clicks" : ""}>
                            <SettingsTab history={props.history} changeHash={changeHash} innerTab={innerTab} updateInnerTab={updateInnerTab} />
                        </TabPanel> */}
                    </div>
                    {/* <Grid item xs>
                    <div>
                        <Button color="secondary" size="large" startIcon={<HighlightOffRoundedIcon />} onClick={() => handleDeleteEnv()}>
                            <Typography >Delete this Environment</Typography>
                        </Button>
                    </div>
                </Grid> */}

                    {startEnvPopupOpen && (
                        <ConfirmActionPopup open={startEnvPopupOpen} handleAgree={startEnvAgreeHandler} handleDisAgree={startEnvDisagreeHandler} message={t('Environment.EnvironmentInfo.startEnvMessage')} yesText={t('Projects.VariablesTab.yesText')} noText={t('Projects.VariablesTab.yesText')} data-test="action-popup" />
                    )}
                    {isConfirmPopupOpen && (
                        <ConfirmDeletePopup open={isConfirmPopupOpen} handleAgree={handleAgreeHandler} handleDisAgree={handleDisAgreeHandler} yesText={t('Projects.VariablesTab.yesText')} noText={t('Projects.VariablesTab.yesText')} toMatchName={helmEnvironmentDetails.name} toDeleteModule="environment" loading={props.deletingEnv} data-test="confirm-popup"/>
                    )}
                    {stopEnvPopupOpen && (
                        <ConfirmDeletePopup open={stopEnvPopupOpen} handleAgree={stopEnvAgreeHandler} handleDisAgree={stopEnvDisagreeHandler} yesText={t('Projects.VariablesTab.yesText')} noText={t('Projects.VariablesTab.yesText')} toMatchName={helmEnvironmentDetails.name} toDeleteModule="environment" loading={props.stoppingEnv} message={t('Environment.EnvironmentInfo.stopEnvMessage')} action="stop" data-test="delete-popup" />
                    )}
                    {/* {isRecreateConfirmPopupOpen && (
                        <RecreatePopup open={isRecreateConfirmPopupOpen} handleAgree={handleRecreateAgreeHandler} handleDisAgree={handleRecreateDisAgreeHandler} message={t('Environment.EnvironmentInfo.recreateEnvMessage')} yesText={t('Environment.EnvironmentInfo.recreate')} noText={t('Projects.ProjectsTab.cancelLabel')} version={helmEnvironmentDetails && helmEnvironmentDetails.version && helmEnvironmentDetails.version.name} currentVersions={currentVersions} />
                    )}
                    {rollbackPopup && (
                        <RollbackPopup open={rollbackPopup} handleAgree={handleRollbackAgree} handleDisAgree={handleRollbackDisagree} message={t('Environment.EnvironmentInfo.rollbackEnvMessage')} yesText={t('Environment.EnvironmentInfo.rollback')} noText={t('Projects.ProjectsTab.cancelLabel')} envId={environmentId} repository_image={props.helmEnvironmentDetails?.repository_image} />
                    )} */}
                    {props.deletingHelmEnv && <BackdropLoader message={t('Environment.EnvironmentInfo.deletingMessage')} data-test="delete-env" />}
                    {props.reCreationTriggered && <BackdropLoader message={t('Environment.EnvironmentInfo.recreatingMessage')} data-test="creation-message"/>}
                    {props.stoppingEnv && <BackdropLoader message={t('Environment.EnvironmentInfo.stoppingMessage')} data-test="stop-env"/>}
                    {props.startingEnv && <BackdropLoader message={t('Environment.EnvironmentInfo.startingMessage')} data-test="start-env"/>}
                    {props.rollingBack && <BackdropLoader message={t('Environment.EnvironmentInfo.initiatingMessage')} data-test="roll-back" />}
                    {props.fetchingHelmEnv && <BackdropLoader message={t('HelmEnvironment.fetchingEnvDetails')} data-test="fetch-helmEnv"/>}
                    {props.fetchingHelmEnvState && <BackdropLoader message={t('HelmEnvironment.fetchingEnvState')} data-test="fetch-helmEnvState"/>}
                </div>
            }
        </div>
    )
}

/* istanbul ignore next */
const mapStateToProps = state => ({
    helmEnvironmentDetails: state.HelmEnvironmentReducer.helmEnvironmentDetails,
    helmEnvironmentMetadata: state.HelmEnvironmentReducer.helmEnvironmentMetadata,
    helmEnvironmentState: state.HelmEnvironmentReducer.helmEnvironmentState,
    isDataNotFound: state.HelmEnvironmentReducer.isDataNotFound,
    fetchingHelmEnv: state.HelmEnvironmentReducer.fetchingHelmEnv,
    fetchingHelmEnvState: state.HelmEnvironmentReducer.fetchingHelmEnvState,
    deletingHelmEnv: state.HelmEnvironmentReducer.deletingHelmEnv,
    envTabValue: state.EnvironmentReducer.envTabValue,
    validSessionId: sessionTokenSelector(state),
    envRole: state.EnvironmentReducer.envRole,
    reCreationTriggered: state.EnvironmentReducer.reCreationTriggered,
    versionsList: state.CreateAppReducer.versionsList,
    startingEnv: state.EnvironmentReducer.startingEnv,
    stoppingEnv: state.EnvironmentReducer.stoppingEnv,
    rollingBack: state.EnvironmentReducer.rollingBack,
    buildImages: state.EnvironmentReducer.buildImages,
    packageStatus: state.EnvironmentReducer.packageStatus,
    currentOrganization: state.AuthReducer.currentOrganization,
    projectRole: state.ProjectReducer.projectRole,
})

/* istanbul ignore next */
const mapDispatchtoProps = dispatch => {
    return {
        fetchHelmEnvDetails: (payload) => dispatch(fetchHelmEnvDetails(payload)),
        // fetchUserListInEnvironmentAction : (evironmentId) => dispatch(fetchUserListInEnvironment(evironmentId)),
        fetchRolesAction: () => dispatch(fetchRoles()),
        //fetchEnvLogs : (id, duration) => dispatch(fetchEnvLogs(id, duration)),
        //startStopEnvironment : (id, isActive) => dispatch(startStopEnvironment(id, isActive)),
        deleteHelmEnvironment: (id, app_id, history) => dispatch(deleteHelmEnvironment(id, app_id, history)),
        updateBreadcrumb: (breadcrumbData) => dispatch(updateBreadcrumb(breadcrumbData)),
        clearHelmEnvironmentdetails: () => dispatch(clearHelmEnvironmentdetails()),
        fetchHelmEnvironmentStateInitiate: (id, initial) => dispatch(fetchHelmEnvironmentStateInitiate(id, initial)),
        fetchHelmEnvironmentState: (id) => dispatch(fetchHelmEnvironmentState(id)),
        setCurrentProject: (id) => dispatch(setCurrentProject(id)),
        changeEnvTabValue: (id) => dispatch(changeEnvTabValue(id)),
        fetchEnvRole: (id) => dispatch(fetchEnvRole(id)),
        reCreateEnvironment: (id, payload, changeHash) => dispatch(reCreateEnvironment(id, payload, changeHash)),
        fetchPluginVersions: (id) => dispatch(fetchPluginVersions(id)),
        fetchPluginDetailsById: (id) => dispatch(fetchPluginDetailsById(id)),
        setCurrentEnvironment: (id) => dispatch(setCurrentEnvironment(id)),
        clearCurrentEnvironment: () => dispatch(clearCurrentEnvironment()),
        startHelmEnvironment: (id) => dispatch(startHelmEnvironment(id)),
        stopHelmEnvironment: (id, changeHash, history) => dispatch(stopHelmEnvironment(id, changeHash, history)),
        rollbackEnv: (id, payload, changeHash) => dispatch(rollbackEnv(id, payload, changeHash)),
        clearPlugInVersions: () => dispatch(clearPlugInVersions()),
        clearPluginDetails: () => dispatch(clearPluginDetails()),
        initiateFetchPackageStatus: (id) => dispatch(initiateFetchPackageStatus(id)),
        switchOrg: (id, history, path, source, dependencyCallback) => dispatch(switchOrg(id, history, path, source, dependencyCallback)),
        fetchProjectRole: (id) => dispatch(fetchProjectRole(id)),
        //fetchEnvironmentState: (id) => dispatch(fetchEnvironmentState(id)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchtoProps
)(HelmEnvironmentInfo)
