import { IconButton, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
//import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
//import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from "@material-ui/icons/Edit";
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AddUserPopup from '../../../../components/adduserpopup/AddUserPopup';
import { clearUserListInProject, fetchRoles, fetchUserListInProject } from '../../../../components/adduserpopup/redux/actions';
import CustomButton from '../../../../components/custombutton/CustomButton';
import DeleteUserPopup from '../../../../components/deleteUserPopup/deleteUserPopup';
import BackdropLoader from '../../../../components/loader/BackdropLoader';
import { isAuthorized } from '../../../../helpers/utils';
import { getOrgGroups } from '../../../organization/redux/actions';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { TableCellStyled as StyledTableCell , StyledTableRow} from '../../../../components/styledtabelcell/StyledTabelCell';
import GroupIcon from '@material-ui/icons/Group';

// const StyledTableCell = withStyles(() => ({
//     head: {
//       // backgroundColor: theme.palette.background.default,
//       // color: 'grey',
//       fontWeight : 500,
//     },
//     body: {
//         color : '#555',
//       fontSize: 14,
//     },
//   }))(TableCell);
  
//   const StyledTableRow = withStyles(() => ({
//     root: {
//     },
//   }))(TableRow);

export class UserPermissionTab extends Component {
    constructor(props){
        super(props);
        this.state = {
          isDeleteUserPopupOpened : 0,
          userIdToBeDeleted : 0,
          isAddUserPopupOpened : 0,
          deleteGroup: false,
          userToBeEdited : null,
          isEditPopUpOpen : 0,
          editGroup: false,
          edit: false,
          leavePopup: 0,
          isLeave: false
        }
    }

    componentDidMount(){
      this.props.fetchUserListInProjectAction(this.props.projectId);
      this.props.fetchRolesAction();
    }

    componentWillUnmount(){
      this.props.clearUserListInProject();
    }

    UNSAFE_componentWillReceiveProps = (newProps) => {
      if(this.props.projectId !== newProps.projectId) 
      {
          this.props.fetchUserListInProjectAction(newProps.projectId)
      }
    }
    
    setEditFalse = () =>{
      this.setState({
        edit: false,
        userToBeEdited: null,
        isAddUserPopupOpened: Math.random(),
        leavePopup: Math.random(),
      })
    }

    handleDeleteUser = (userId, isGroup) => {
      this.setState({
        userIdToBeDeleted : userId,
        isDeleteUserPopupOpened : Math.random(),
        deleteGroup: isGroup,
        edit: false
      })
    }

    handleLeaveProject = (userId, isGroup) => {
      this.setState({
        userIdToBeDeleted : userId,
        isDeleteUserPopupOpened : Math.random(),
        deleteGroup: isGroup,
        edit: false,
        isLeave: true,
      })
    }

    handleEdit = (user) => {
      this.setState({
        userToBeEdited : user,
        isAddUserPopupOpened : Math.random(),
        edit: true,
      })
    }

    handleAddUser = () => {
      this.setState({
        isAddUserPopupOpened : Math.random(),
        edit: false
      })
    }

    handleClose = () => {
      this.setState({
        isLeave: false,
      })
    }

    goToGroups = (id) => {
      this.props.history.push(`/organization/${id}#groups`)
    }

    render () {
      const { usersListInProject, projectDetails, projectRole, t } = this.props;
      const { userIdToBeDeleted } = this.state;
      const userData = usersListInProject.length > 0 ? usersListInProject : [];
        return (
            <div data-test="main-container">
                { isAuthorized('addUser', projectRole.name) && (
                <div className='alignRight addButtonContainer'>
                    <CustomButton label={t('Projects.UserTab.addUserLabel')} onClick={e => this.handleAddUser(e)} data-test="add-user-btn" />
                </div>
                )}
                <TableContainer component={ Paper }>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell>{t('Projects.UserTab.nameLabel')}</StyledTableCell>
                                <StyledTableCell>{t('Projects.UserTab.emailLabel')}</StyledTableCell>
                                <StyledTableCell>{t('Projects.UserTab.roleLabel')}</StyledTableCell>
                                { isAuthorized('delete', projectRole.name) && (
                                <StyledTableCell align="center" data-test="action-label">{t('Projects.UserTab.actionLabel')}</StyledTableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody> 
                            {
                            projectDetails && projectDetails.user &&
                            <StyledTableRow key={ projectDetails.user.id } data-test="owner-row">
                                <StyledTableCell> <Avatar src={ projectDetails.user.image ? projectDetails.user.image : "./Images/profile_image.png" } /></StyledTableCell>
                                <StyledTableCell data-test="owner-name">{ projectDetails.user.first_name + " " + projectDetails.user.last_name }
                                    {/* <Chip className='userRole' label="owner" /> */}
                                </StyledTableCell>
                                <StyledTableCell data-test="owner-email">{ projectDetails.user.email }</StyledTableCell>
                                <StyledTableCell data-test="owner-role">{t('Projects.UserTab.owner')}</StyledTableCell>
                                { isAuthorized('delete', projectRole.name) && (
                                <StyledTableCell>
                                    {/* <IconButton onClick={ e => this.handleDeleteUser(user.id) }>
                                          <DeleteIcon />
                                      </IconButton> */}
                                </StyledTableCell>
                                )}
                            </StyledTableRow>
                          }
                            {userData.length > 0 && userData.map((user) => (
                                <StyledTableRow key={ user.id } data-test="user-row">
                                    <StyledTableCell> {!user.group_id ?  <Avatar src={ user.user && user.user.image ? user.user.image : "./Images/profile_image.png" } /> : <Avatar> <GroupIcon /> </Avatar> } </StyledTableCell>
                                    <StyledTableCell data-test="user-name">{user.user? user?.user?.first_name + ' ' + user?.user?.last_name : (
                                      <Typography
                                        color="primary" 
                                        onClick={() => this.goToGroups(user?.group?.organization_id)}
                                        style={{cursor: 'pointer'}}
                                      >
                                          {user.group.Name} 
                                      </Typography>)}
                                        {/* <Chip className='userRole' label="owner" /> */}
                                    </StyledTableCell>
                                    <StyledTableCell data-test="user-email">{user.user ? user?.email : 'N/A'}</StyledTableCell>
                                    <StyledTableCell data-test="user-role">{ user.user_role ? user.user_role.name : "" }</StyledTableCell>
                                    <StyledTableCell align="center">
                                    {this.props.currentUser?.id === user?.user_id ? (
                                      <Tooltip title={t('Projects.UserTab.leaveProject')} arrow>
                                        <IconButton aria-label="edit" onClick={() => this.handleLeaveProject(user.group ? user?.id : user.id, user.group ? true : false)} data-test="leave-icon">
                                          <ExitToAppIcon fontSize="inherit" />
                                        </IconButton>
                                      </Tooltip>
                                    ) : (
                                      <>
                                        {isAuthorized('editUser', projectRole.name) && (
                                          <Tooltip arrow title={t('Projects.UserTab.editPermission')}>
                                            <IconButton aria-label="edit" onClick={() => this.handleEdit(user,user.group ? true : false)} data-test="edit-icon">
                                                <EditIcon />
                                            </IconButton>
                                          </Tooltip>
                                          )}
                                          {isAuthorized('delete', projectRole.name) && (
                                          <Tooltip arrow title={t('Projects.UserTab.removeUser')}>
                                            <IconButton onClick={ () => this.handleDeleteUser(user.group ? user?.id : user.id, user.group ? true : false) } data-test="remove-icon">
                                                <DeleteIcon />
                                            </IconButton>
                                          </Tooltip>
                                          )}
                                      </>
                                    ) }                                       
                                    </StyledTableCell>
                                </StyledTableRow>
                      ))}
                        </TableBody>
                    </Table>
                    {userData.length === 0 && <div className='alignCenter'><p>{t('Projects.UserTab.noSharedUser')}</p></div>}
                </TableContainer>
                  <AddUserPopup open={ this.state.isAddUserPopupOpened } instance={this.state.edit ? this.state.userToBeEdited: null} edit={this.state.edit} getOrgGroups={this.props.getOrgGroups} data-test="add-user-popup"/>
                  <DeleteUserPopup open={ this.state.isDeleteUserPopupOpened } userId={ userIdToBeDeleted } deleteGroup={this.state.deleteGroup} setEditFalse={this.setEditFalse} isLeave={this.state.isLeave} history={this.props.history} handleClose={this.handleClose} data-test="delete-user-popup"/>
                { this.props.addingUser && <BackdropLoader message={t('Projects.UserTab.addingUser')} />}
                { this.props.editingUser && <BackdropLoader message={t('Projects.UserTab.editingUser')} />}
                { this.props.deletingUser && <BackdropLoader message={t('Projects.UserTab.deletingUser')} />}
                { this.props.fetchingUserList && <BackdropLoader message={t('Projects.UserTab.fetchingUser')} />}
            </div>
        )
    }
}

/* istanbul ignore next */
const mapStateToProps = state => ({
  projectDetails: state.ProjectReducer.projectDetails,
  usersListInProject: state.AddUserPopupReducer.usersListInProject,
  projectRole: state.ProjectReducer.projectRole,
  addingUser: state.AddUserPopupReducer.addingUser,
  editingUser: state.AddUserPopupReducer.editingUser,
  deletingUser: state.AddUserPopupReducer.deletingUser,
  fetchingUserList: state.AddUserPopupReducer.fetchingUserList,
  currentUser: state.AuthReducer.user,
})

/* istanbul ignore next */
const mapDispatchtoProps = dispatch => {
  return {
    fetchUserListInProjectAction : payload => dispatch(fetchUserListInProject(payload)),
    fetchRolesAction : (payload) => dispatch(fetchRoles(payload)),
    clearUserListInProject: () => dispatch(clearUserListInProject()),
    getOrgGroups: () => dispatch(getOrgGroups()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(withTranslation()(UserPermissionTab))
