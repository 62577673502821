const PREFIX = '@Login'

export const LOGIN = `${PREFIX}/LOGIN`
export const GETPROFILE = `${PREFIX}/GETPROFILE`
export const LOGIN_SUCCESS = `${PREFIX}/LOGIN_SUCCESS`
export const LOGIN_FAILURE = `${PREFIX}/LOGIN_FAILURE`

export const LOGOUT_SUCCESS = `${PREFIX}/LOGOUT_SUCCESS`
export const LOGOUT_FAILURE = `${PREFIX}/LOGOUT_FAILURE`
export const FETCH_PROFILE_SUCCESS = `${PREFIX}/FETCH_PROFILE_SUCCESS`
export const LOGIN_OAUTH = `${PREFIX}/LOGIN_OAUTH`
export const RESEND_VERIFICATION_MAIL = `${PREFIX}/RESEND_VERIFICATION_MAIL`
export const UPDATE_TOKEN = `${PREFIX}/UPDATE_TOKEN`
export const SET_CURRENT_ORG = `${PREFIX}/SET_CURRENT_ORG`

export const LOGOUT = `${PREFIX}/LOGOUT`

export const REQUEST_INVITE_SIGNUP = `${PREFIX}/REQUEST_INVITE_SIGNUP`
export const REQUEST_INVITE_SIGNUP_SUCCESS = `${PREFIX}/REQUEST_INVITE_SIGNUP_SUCCESS`
export const REQUEST_INVITE_SIGNUP_FAILURE = `${PREFIX}/REQUEST_INVITE_SIGNUP_FAILURE`

export const CLEAR_LOADING = `${PREFIX}/CLEAR_LOADING`

export const UPDATE_LANGUAGE_SELECTION = `${PREFIX}/UPDATE_LANGUAGE_SELECTION`

export const LOGIN_SSO = `${PREFIX}/LOGIN_SSO`

const login = (payload, history, location) => ({
    type: LOGIN,
    data: { payload, history, location }
})

const loginOAuth = (payload, history) => ({
    type: LOGIN_OAUTH,
    data: { payload, history }
})

const fetchProfile = (payload) => ({
    type: GETPROFILE,
    data: payload
})

const logout = (cb) => ({
    type: LOGOUT,
    data: {cb}
})

const logoutSuccess = () => ({
    type: LOGOUT_SUCCESS,
    
})
const resendVerification = (payload) => ({
    type: RESEND_VERIFICATION_MAIL,
    data: payload
})

const requestInviteSignup = (token, payload, history) => ({
    type: REQUEST_INVITE_SIGNUP,
    data: { token, payload, history }
})

const clearLoading = () => ({
    type: CLEAR_LOADING
})

const updateLanguageSelection = lang => ({
    type: UPDATE_LANGUAGE_SELECTION,
    data: lang
});

const loginSso = (payload, history, callBack) => ({
    type: LOGIN_SSO,
    data:{payload, history, callBack}
});

export {
    login,
    logout,
    fetchProfile,
    loginOAuth,
    resendVerification,
    requestInviteSignup,
    clearLoading,
    logoutSuccess,
    updateLanguageSelection,
    loginSso
}
