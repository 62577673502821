const initialState = {
    accountList: null,
    loadingAccountList: false,
    unlinkingAccount: false,
    tokenList:[],
    loadingTokenList: false,
    deletingTokenList: false,
    newToken:null,
    creatingToken:false,
    deletingAllToken: false
}

export default initialState
