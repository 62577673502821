import { Card, Grid, CardContent, CardHeader, Divider , Typography } from '@material-ui/core'
// import { Card, Grid, CardActions, CardContent, CardHeader, Chip, Divider, Tooltip, Typography } from '@material-ui/core'
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { Owned } from '../../../models/Operator.model';

const useStyles = makeStyles((theme) => ({
    root: {},
    imageContainer: {
        margin: '0 auto',
        border: `1px solid ${theme?.palette?.divider}`,
        borderRadius: '5px',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    catalogImage: {
        width: '100%',
    },
    cardActions: {
        margin: 5
    },
    cardContent: {
        minHeight: "87px"
    },
    linkPointer: {
        cursor: "pointer",
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.09)',
        },
    },
    typeSelection: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "2rem"
    },
    cardHeader: {
        paddingLeft: "1rem",

    },
    chip: {
        display: "flex",
        flexWrap: "wrap",
        gap: "8px"
    }
}));

type Props={
    resource:Owned;
    imgUrl:string;
    onClick:(catalog:Owned)=>void;
    selectedResource:Owned;
    categories:string[];
}

function ResourceCatalogCard(props:Props) {
    const classes = useStyles()
    const { resource , imgUrl} = props

    const handleClick = (_resource:Owned) => {
        props.onClick(_resource)
    }
    return (
        <Card onClick={() => { handleClick(resource) }} className={typeof props.onClick==='function' ? classes.linkPointer : ""} data-test="main-container">

            <CardHeader className={classes.cardHeader} title={<div className={classes.typeSelection} data-resourceName={resource?.displayName}>{resource?.displayName} {props.selectedResource === resource && <CheckCircleIcon color="primary" className={classes.typeSelection} />}</div>} data-test="card-header" />
            <Divider />

            <CardContent className={classes.cardContent} >
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <div className={classes.imageContainer}>
                            <img
                                src={imgUrl ? imgUrl : "/images/icons/defaultcatalog.png"}
                                alt={resource?.displayName ?? "resource"}
                                className={classes.catalogImage}
                                data-test="resourceImage"
                            />
                        </div>
                    </Grid>
                    <Grid item md={9}>
                        <Typography gutterBottom variant="body1" className={"threeLine"} data-test="resource-description">
                            {resource?.description}
                        </Typography>

                    </Grid>
                </Grid>
            </CardContent>
            {/* <Divider />
            <CardActions className={classes.cardActions}>
                <Grid
                    container
                    justify="flex-end"
                    spacing={2}
                >
                    <Grid item className= {classes.chip} >
                    {categories && categories.map((cat, ind) => (
                        <Tooltip title={cat} key={ind}>
                            <Chip style={{ maxWidth: "100px", overflow: "hidden", textOverflow: "ellipsis" }}
                                color={"primary"} variant="outlined" label={cat} size="small"
                                data-test="category-chip"
                            />
                        </Tooltip>
                    ))}
                    </Grid>
                </Grid>
            </CardActions> */}
        </Card>

    )
}

export default ResourceCatalogCard
