import { ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import ClearCache from 'react-clear-cache';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import theme from '../theme';
import MainRouter from './MainRouter';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from "../components/fallback/ErrorFallback"

type ClearCacheProps = {
    isLatestVersion: boolean,
    emptyCacheStorage: () => void
}
export function App() {
  return (
      <ErrorBoundary FallbackComponent={ErrorFallback} >
          <div className='masterContainer' data-test="main-container">
              <ClearCache auto={ true } data-test="clearcache">
                  {({ isLatestVersion, emptyCacheStorage }: ClearCacheProps) => (	          
                      <div className="Cache-block">	              
                          {!isLatestVersion && (
                          <p> Click here for&nbsp; 	                
                              <button	            
                                onClick={ e => {	           
                      e.preventDefault();	           
                      emptyCacheStorage();	
                    } }
                    data-test="updated-version"
                              >	
                                  Updated version	
                                
                              </button>	
                  &nbsp;of app
                          </p>	
              )}	
                      </div>	
          )}
              </ClearCache>
              <ThemeProvider theme={ theme }>
                  <MainRouter />
                  <ToastContainer position="top-center"
                    autoClose={ 4000 }
                    hideProgressBar
                    newestOnTop
                    closeOnClick
                    rtl={ false }
                    draggable
                    pauseOnHover
                    limit={1}
                  />
              </ThemeProvider>
          </div>
      </ErrorBoundary>
  );
}

export default App;
