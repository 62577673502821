import React from 'react';
import { withStyles, Theme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
//import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import './dnspopup.css'
import { useTranslation } from 'react-i18next';
import { EnvironmentState, EnvironmentVariables } from '../../models/Environment.model';
import { makeStyles } from "@material-ui/styles";

type DialogProps = {
  children: JSX.Element,
  onClose: () => void,
  id: string
}

type Props = {
  environmentVariables: EnvironmentVariables,
  environmentState: EnvironmentState,
  handleClose: () => void,
  open: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

/* istanbul ignore next */
const DialogTitle = (props: DialogProps) => {
  const { children, onClose, ...other } = props;
  const classes = useStyles();
  return (
      <MuiDialogTitle disableTypography className={ classes.root } { ...other }>
          <Typography variant="h6">{children}</Typography>
          {onClose ? (
              <IconButton aria-label="close" className={ classes.closeButton } onClick={ onClose }>
                  <CloseIcon />
              </IconButton>
      ) : null}
      </MuiDialogTitle>
  );
};

/* istanbul ignore next */
const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

// const DialogActions = withStyles((theme) => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(1),
//   },
// }))(MuiDialogActions);

export default function DnsPopup(props: Props) {
  const [t] = useTranslation()
  const clients_fqdn = props.environmentVariables && props.environmentVariables.system_variables && props.environmentVariables.system_variables.clients_fqdn
  const CName = props.environmentState && props.environmentState.cname
  return (
      <div data-test="main-container">
          <Dialog 
            disableEscapeKeyDown = {true}
            onClose={(_, reason) => {
              if (reason !== 'backdropClick') {
                props.handleClose()
              }}
            }
            aria-labelledby="customized-dialog-title" open={ props.open }
          >
              <DialogTitle data-test="dialog-title" id="customized-dialog-title" onClose={ props.handleClose }>
                  <Typography variant="h5" data-test="dialog-text"> {t('Config')} </Typography>
              </DialogTitle>
              <DialogContent dividers>
                  <Typography gutterBottom data-test="dialog-content-header">

                  {t('SupportDns')} {clients_fqdn } {t('To')} {CName}.
                  </Typography>
                  <div className="codeDivdns" data-test="dialog-content-section">

                      { clients_fqdn} {'    '}
                      {t('Cname')}{'    '} {CName}
                  </div>
                  {/* <Typography gutterBottom>

              Create an A record for hemshrestha.com.np pointing to our load balancer’s IP address 114.198.14.57.
            </Typography>
            <div className="codeDiv">

              hemshrestha.com.np A 114.198.14.57. 
            </div> */}
              </DialogContent>

          </Dialog>
      </div>
  );
}
