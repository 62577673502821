import {
  Button,
  ButtonBase,
  Card,
  CardContent,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ScheduleOutlinedIcon from "@material-ui/icons/ScheduleOutlined";
import StraightenOutlinedIcon from "@material-ui/icons/StraightenOutlined";
import Skeleton from '@material-ui/lab/Skeleton';
import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import ConfirmDeletePopup from "../../../../components/confirmdeletepopup/ConfirmDeletePopup";
import BackdropLoader from "../../../../components/loader/BackdropLoader";
import NoContentImage from "../../../../components/nocontentimagecontainer/NoContentImage";
import { DateHandler } from "../../../../components/dateHandler/DateHandler";
import { LabelHandler } from "../../../../components/labelHandler/LabelHandler";
//import CronJobSkeleton from "../../../../components/skeletons/CronJobSkeleton";
import { isAuthorized } from "../../../../helpers/utils";
import {
  clearLoadBanalcerData,
  createLoadbalancer,
  deleteLoadbalancer,
  fetchLoadbalancers,
  getLoadbalancer,
} from "../../redux/actions";
import LBpopup from "./LBpopup";
import LoadbalancerInfo from "./LoadbalancerInfo";
import { Dispatch } from "redux";
import { WithStyles } from "@material-ui/styles";
import { LoadbalancerInfoModel } from "../../../../models/Project.model";
import { UserRole } from "../../../../models/Environment.model";

/* istanbul ignore next */
const useStyles = () => ({
  lbHeader: {
    display: "flex",
  },
  createLB: {
    marginLeft: "auto",
  },
  lbStatus: {
    paddingLeft: 15,
    display: "flex",
  },
});

type Payload = {
  name: string,
  region: string,
  project_id?: number
}

interface Props extends WithTranslation, PropsFromRedux, WithStyles<typeof useStyles> {
  projectRole: UserRole
}

type State = {
  mode: number,
  currentLoadbalancerId: number,
  anchorEl: null|EventTarget & HTMLElement,
  deletingLBName: string,
  isDeleteConfirmPopupOpen: boolean,
  isRunnowConfirmPopupOpen: boolean,
  isSuspendConfirmPopupOpen: boolean,
  suspendConfirmMessage: string,
  suspendJsonBody: string|null,
  createLBpopup: boolean|null,
  createLBpopupOpen:boolean,
  edit: boolean,
  deleteLBpopupOpen:boolean
}

export class LoadbalancerTab extends Component<Props, State> {
  constructor(props:Props) {
    super(props);
    this.state = {
      mode: 0, //0:List; 1:Create/Edit; 2:Info
      currentLoadbalancerId: 0,
      anchorEl: null,
      // showLoader: false,
      // loaderMsg: ''
      deletingLBName: "",
      isDeleteConfirmPopupOpen: false,
      isRunnowConfirmPopupOpen: false,
      isSuspendConfirmPopupOpen: false,
      suspendConfirmMessage: "",
      suspendJsonBody: null,
      createLBpopup: null,
      //added at TS migration time
      createLBpopupOpen: false,
      edit: false,
      deleteLBpopupOpen: false
    };
  }

  componentDidMount() {
    if (this.props.projectDetails && this.props.projectDetails.id > 0) {
      this.props.fetchLoadbalancers(this.props.projectDetails.id);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps:Props) {
    if (nextProps) {
      if (nextProps.projectDetails?.id > 0) {
        if (
          !this.props.projectDetails?.id ||
          this.props.projectDetails?.id !== nextProps.projectDetails.id
        ) {
          this.props.fetchLoadbalancers(nextProps.projectDetails.id);
        }
      }
    }
  }

  handleLBMenu = (e: React.MouseEvent<HTMLElement>, lbId:number, lbName:string) => {
    this.setState({
      anchorEl: e.currentTarget,
      currentLoadbalancerId: lbId,
      deletingLBName: lbName,
      // edit: true,
    });
  };

  handleMenuClose = () => {
    this.setState({
      anchorEl: null,
      // currentLoadbalancerId: 0,
      // deletingLBName: "",
      // edit: false,
    });
  };

  handleBack = () => {
    this.handleMenuClose();
    this.setState({
      mode: 0,
      currentLoadbalancerId: 0,
    });
    this.props.fetchLoadbalancers(this.props.projectDetails?.id);
  };

  /* istanbul ignore next */
  handleLBEdit = () => {
    this.handleMenuClose();
    this.setState({
      createLBpopupOpen: true,
      edit: true,
    });
    this.props.getLoadbalancer(this.state.currentLoadbalancerId);
  };

  handleLBClickAction = (lbId: number) => {
    this.handleMenuClose();
    this.setState({
      mode: 1,
      currentLoadbalancerId: lbId,
    });
  };

  handleLBClick = () => {
    this.handleMenuClose();
    this.setState({
      mode: 1,
    });
  };

  handleAddLb = () => {
    this.setState({
      createLBpopupOpen: true,
      currentLoadbalancerId: 0,
      deletingLBName: "",
    });
  };

  agreeCreateHandler = (payload: Payload) => {
    payload.project_id = this.props.projectDetails?.id;
    this.props.createLoadbalancer(payload);
    this.setState({
      createLBpopupOpen: false,
    });
  };

  disagreeCreateHandler = () => {
    this.setState({
      createLBpopupOpen: false,
    });
  };

  agreeEditHandler = (payload: Payload) => {
    // payload.project_id = this.props.projectDetails?.id;
    // this.props.createLoadbalancer(payload);
    this.setState({
      createLBpopupOpen: false,
      edit: false,
    });
  };

  disagreeEditHandler = () => {
    this.setState({
      createLBpopupOpen: false,
      edit: false,
    });
  };

  handleLBDelete = (id:number, name:string) => {
    this.handleMenuClose();
    this.setState({
      deleteLBpopupOpen: true,
      // currentLoadbalancerId: lbId,
      // deletingLBName: lbName,
    });
  };

  agreeDeleteHandler = () => {
    this.handleMenuClose();
    this.props.deleteLoadbalancer(
      this.state.currentLoadbalancerId,
      this.props.projectDetails?.id
    );
    this.setState({
      deleteLBpopupOpen: false,
      // currentLoadbalancerId: null,
    });
  };

  disagreeDeleteHandler = () => {
    this.handleMenuClose();
    this.setState({
      deleteLBpopupOpen: false,
      // currentLoadbalancerId: null,
    });
  };

  componentWillUnmount() {
    this.props.clearLoadBanalcerData();
  }

  render() {
    const { classes, projectRole, loadbalancersList, t } = this.props;

    return (
      // <div>
      //   <div>
      //     <Button variant="contained" color="primary">
      //       Add Job
      //     </Button>
      //   </div>

      //   <Grid container>
      //     <Grid md="12" spacing="2">
      //       <Card>
      //         <CardContent>ddd</CardContent>
      //       </Card>
      //     </Grid>
      //   </Grid>
      // </div>
        <div data-test="loadbalancerComponent">
            {this.state.mode === 0 && (
            <div data-test="loadnbalancerList">
                <div data-test="loadbalancerActions">
                    {projectRole && isAuthorized("create", projectRole.name) && (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.createLB+" right m-b-20"}
                      onClick={this.handleAddLb}
                      data-test="loadbalancerCreateButton"
                    >
                        {t('Projects.LoadBalancerTab.createLbLabel')}
                    </Button>
              )}
                </div>
                
                <div className="m-t-20">
                <Grid container spacing={2}>
                    {loadbalancersList &&
                loadbalancersList.length > 0 &&
                loadbalancersList.map((lb: LoadbalancerInfoModel, index: number) => {
                  return (
                    <Grid item md={4} sm={6} xs={12} key={index}>
                      <Card
                        key={index}
                        className="m-t-20  w-100"
                        data-test="loadbalancerCard"
                      >
                          <CardContent>
                              <Grid container alignItems="center">
                                  <Grid
                                    item
                                    md={11}
                                    sm={11}
                                    xs={11}
                                    onClick={() => this.handleLBClickAction(lb.id)}
                                    data-test="lb-card"
                                  >
                                      <Grid container spacing={1}>
                                          <Grid item md={12} xs={12}>
                                              <ButtonBase>
                                                  <Typography
                                                    variant="h4"
                                                    color="primary"
                                                    className="oneLine"
                                                    title={lb.name}
                                                    onClick={() =>
                                      this.handleLBClickAction(lb.id)
                                    }
                                                  >
                                                      {" "}
                                                      {lb.name}{" "}
                                                  </Typography>
                                              </ButtonBase>
                                          </Grid>
                                          <Grid item md={12} xs={12}>
                                              <Grid container spacing={3}>
                                                  {lb.createdat && (
                                                  <Grid
                                                    item
                                                    md={3}
                                                    xs={6}
                                                    className="header-details-grid"
                                                  >
                                                      <DateHandler
                                                        date={lb.createdat}
                                                        icon={<ScheduleOutlinedIcon />}
                                                        // iconTooltip="Created time"
                                                      />
                                                  </Grid>
                                  )}
                                                  {lb.image && (
                                                  <Grid
                                                    item
                                                    md={3}
                                                    sm={6}
                                                    xs={6}
                                                    className="header-details-grid"
                                                  >
                                                      <LabelHandler
                                                        label={lb.image}
                                                        icon={<StraightenOutlinedIcon />}
                                                        iconTooltip="Image"
                                                      />
                                                  </Grid>
                                  )}
                                                  {lb.cluster?.zone && (
                                                  <Grid
                                                    item
                                                    md={3}
                                                    sm={6}
                                                    xs={6}
                                                    className="header-details-grid"
                                                  >
                                                      <LabelHandler
                                                        label={lb.cluster?.zone}
                                                        icon={<StraightenOutlinedIcon />}
                                                        iconTooltip={t('Projects.LoadBalancerTab.toolTipTitleLabel')}
                                                      />
                                                  </Grid>
                                  )}
                                                  {
                                                      <Grid
                                                        item
                                                        md={3}
                                                        sm={6}
                                                        xs={6}
                                                        className="header-details-grid"
                                                      >
                                                        <LabelHandler
                                                          label={t('Projects.LoadBalancerTab.publicLabel')}
                                                          icon={<StraightenOutlinedIcon />}
                                                          iconTooltip={t('Projects.LoadBalancerTab.toolTipTitleLabel')}
                                                        />
                                                      </Grid>
                                  }
                                              </Grid>
                                          </Grid>
                                      </Grid>
                                  </Grid>

                                  {/* {isAuthorized("update", projectRole.name) && ( */}
                                  <Grid item md={1} sm={1} xs={1}  className="center">
                                      <IconButton
                                        data-test="lb-menu"
                                        onClick={(e) =>
                                          this.handleLBMenu(e, lb.id, lb.name)
                                        }
                                      >
                                          <MoreVertIcon />
                                      </IconButton>
                                      <Menu
                              //id={`simple-menu-${index}`}
                                        data-test="menu"
                                        disableScrollLock={true}
                                        anchorEl={this.state.anchorEl}
                                        open={Boolean(this.state.anchorEl)}
                                        onClose={() => this.handleMenuClose()}
                                        getContentAnchorEl={null}
                                        anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                              }}
                                        transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                                      >
                                          <MenuItem onClick={() => this.handleLBClick()} data-test="lb-view-item">
                                              {t('Projects.LoadBalancerTab.viewLabel')}
                                          </MenuItem>
                                          {projectRole &&
                                isAuthorized("delete", projectRole.name) && (
                                <MenuItem
                                data-test="lb-delete-item"
                                  onClick={() =>
                                      this.handleLBDelete(lb.id, lb.name)
                                    }
                                >
                                    {t('Projects.LoadBalancerTab.deleteLabel')}
                                </MenuItem>
                                )}
                                      </Menu>
                                  </Grid>
                                  {/* )} */}
                              </Grid>
                          </CardContent>
                      </Card>
                    </Grid>           
                  );
                })}

                    {!loadbalancersList &&
                [1, 2, 3, 4].map((ind) => 
                  <Grid item md={4} sm={6} xs={12} key={ind}>
                    <Card className="m-t-20  w-100">
                      <CardContent>
                        <Skeleton animation="wave" height={20} width="80%" style={{ marginBottom: 6 }} />
                        <Skeleton animation="wave" height={20} width="40%" style={{ marginBottom: 6 }} />
                      </CardContent>
                    </Card>
                  </Grid>
                )}
                    {loadbalancersList && loadbalancersList.length === 0 && (
                      <NoContentImage
                        message={t('Projects.LoadBalancerTab.noLbMessage')}
                        alt="No Loadbalancers"
                        type="env"
                        data-test="noLoadbalancerImage"
                      />
              )}
                </Grid>
                </div>
            </div>
        )}
            {this.state.mode !== 0 && (
            <div className="m-b-20">
                {/* <Link href="#"  underline='none' alignItem="center"> */}
                <ButtonBase onClick={this.handleBack} style={{ color: "#357dfd" }} data-test="backButton">
                    <ArrowBackIcon fontSize="small" />{" "}
                    <span className="jobNav">{t('Projects.LoadBalancerTab.lbList')}</span>
                </ButtonBase>
                {/* </Link> */}
            </div>
        )}
            {this.state.mode === 1 && (
            <LoadbalancerInfo
              data-test="LoadbalancerInfo"
              lbId={this.state.currentLoadbalancerId}
            />
        )}

            {this.state.createLBpopupOpen && (
            <LBpopup
            data-test="lb-popup"
              handleAgree={
              this.state.edit ? this.agreeEditHandler  : this.agreeCreateHandler
            }
              handleDisAgree={
              this.state.edit
                ? this.disagreeEditHandler
                : this.disagreeCreateHandler
            }
              open={this.state.createLBpopupOpen}
              edit={this.state.edit}
              loadbalancerInfo={
              this.state.edit ? this.props.loadbalancerInfo : null
            }
            />
        )}
            {this.state.deleteLBpopupOpen && (
            <ConfirmDeletePopup
              open={this.state.deleteLBpopupOpen}
              handleAgree={this.agreeDeleteHandler}
              handleDisAgree={this.disagreeDeleteHandler}
              message={`The action you are taking cannot be undone. This will permanently delete all the data. If you are sure, please type "${this.state.deletingLBName}"`}
              // yesText={t('Projects.ProjectInfo.yesLabel')}
              // noText={t('Projects.ProjectInfo.noLabel')}
              toMatchName={this.state.deletingLBName}
              toDeleteModule="Loadbalancer"
              data-test="delete-popup"
            />
        )}
            {this.props.creatingLoadbalancer && (
            <BackdropLoader message={t('Projects.LoadBalancerTab.creatingLb')} />
        )}
            {this.props.deletingLoadbalancer && (
            <BackdropLoader message={t('Projects.LoadBalancerTab.deletingLb')} />
        )}
            {this.props.fetchingLoadbalancers && (
            <BackdropLoader message={t('Projects.LoadBalancerTab.fetchingLb')} />
        )}
        </div>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  loadbalancersList: state.ProjectReducer.loadbalancersList,
  projectRole: state.ProjectReducer.projectRole,
  projectDetails: state.ProjectReducer.projectDetails,
  fetchingLoadbalancers: state.ProjectReducer.fetchingLoadbalancers,
  creatingLoadbalancer: state.ProjectReducer.creatingLoadbalancer,
  deletingLoadbalancer: state.ProjectReducer.deletingLoadbalancer,
  loadbalancerInfo: state.ProjectReducer.loadbalancerInfo,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    fetchLoadbalancers: (id: number) => dispatch(fetchLoadbalancers(id)),
    getLoadbalancer: (id: number) => dispatch(getLoadbalancer(id)),
    createLoadbalancer: (payload: Payload) => dispatch(createLoadbalancer(payload)),
    deleteLoadbalancer: (id: number, pId: number) => dispatch(deleteLoadbalancer(id, pId)),
    clearLoadBanalcerData: () => dispatch(clearLoadBanalcerData()),
  };
};

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withStyles(useStyles)(withTranslation()(LoadbalancerTab)));
