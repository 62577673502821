const RESOURCE_PREFIX = "@RESOURCE";

export const FETCH_RESOURCES = `${RESOURCE_PREFIX}/FETCH_RESOURCES`;
export const FETCH_RESOURCES_SUCCESS = `${RESOURCE_PREFIX}/FETCH_RESOURCES_SUCCESS`;
export const FETCH_RESOURCES_FAILURE = `${RESOURCE_PREFIX}/FETCH_RESOURCES_FAILURE`;
export const FETCH_RESOURCE = `${RESOURCE_PREFIX}/FETCH_RESOURCE`;
export const FETCH_RESOURCE_SUCCESS = `${RESOURCE_PREFIX}/FETCH_RESOURCE_SUCCESS`;
export const FETCH_RESOURCE_FAILURE = `${RESOURCE_PREFIX}/FETCH_RESOURCE_FAILURE`;
export const DELETE_RESOURCE = `${RESOURCE_PREFIX}/DELETE_RESOURCE`;
export const DELETE_RESOURCE_SUCCESS = `${RESOURCE_PREFIX}/DELETE_RESOURCE_SUCCESS`;
export const DELETE_RESOURCE_FAILURE = `${RESOURCE_PREFIX}/DELETE_RESOURCE_FAILURE`;
export const CREATE_RESOURCE_CALL = `${RESOURCE_PREFIX}/CREATE`;
export const CREATE_RESOURCE_CALL_SUCCESS = `${RESOURCE_PREFIX}/CREATE_SUCCESS`;
export const CREATE_RESOURCE_CALL_FAILURE = `${RESOURCE_PREFIX}/CREATE_FAILURE`;
export const EDIT_RESOURCE_CALL = `${RESOURCE_PREFIX}/EDIT`;
export const EDIT_RESOURCE_CALL_SUCCESS = `${RESOURCE_PREFIX}/EDIT_SUCCESS`;
export const EDIT_RESOURCE_CALL_FAILURE = `${RESOURCE_PREFIX}/EDIT_FAILURE`;

const fetchResources = () => ({
  type: FETCH_RESOURCES,
});

const fetchResource = (id) => ({
  type: FETCH_RESOURCE,
  data: {
    id,
  },
});

const deleteResource = (id) => ({
  type: DELETE_RESOURCE,
  data: {
    id,
  },
});

const createResourceCall = (payload) => {
  return {
    type: CREATE_RESOURCE_CALL,
    data: {
      payload,
      // history  history not being used in saga
    },
  };
};
const editResourceCall = (id, payload) => {
  return {
    type: EDIT_RESOURCE_CALL,
    data: {
      id,
      payload,
      // history, not used  in saga
    },
  };
};

export {
  fetchResources,
  fetchResource,
  deleteResource,
  createResourceCall,
  editResourceCall,
};
