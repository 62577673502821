import React from 'react'
import ChartCatalogContainer from '../../../../components/chartcatalog/ChartCatalogContainer'
import { useTranslation } from 'react-i18next'

export const ChartCatalogTab = () => {
    const [t] =  useTranslation()
    

    return (
        <div>
            <ChartCatalogContainer title={t('Organization.CreateOrganization.chartCatalog')} cardMd={4} />
        </div>
    )
}

// const mapStateToProps = () => ({
// })

// const mapDispatchToProps = () => ({
// });

export default ChartCatalogTab
