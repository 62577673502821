import React, { useEffect, useState } from "react";
import { Typography, Grid } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { makeStyles } from "@material-ui/styles";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(() => ({
  checkIcon: {
    color: "green",
    marginRight: 10,
  },
  grid: {
    margin: "100px auto",
    flexDirection: "column",
    textAlign: "center",
  },
  paymentText: {
    display: "flex",
    justifyContent: "center",
  },
}));

export const OAuthRedirectSuccess = () => {
  const [countdown, setCountdown] = useState(5);

  const locationParams = useLocation();

  useEffect(() => {
    window.opener.postMessage("Payment Successful");
    const interval = startTimer();
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (Object.keys(locationParams).length) {
      const urlObject = locationParams;
      const searchParams = new URLSearchParams(urlObject.search);
      const searchFields = {
        userEmail: searchParams.get("email"),
        authToken: searchParams.get("token"),
      };
      if (searchFields && searchFields.userEmail && searchFields.authToken) {
        window.opener.postMessage(searchFields);
      }
    }
  }, [locationParams]);

  function startTimer() {
    let itv = setInterval(() => {
      setCountdown((oldValue) => {
        const newValue = oldValue - 1;
        if (newValue === 0) {
          clearInterval(itv);
          window.close();
        }
        if (newValue >= 0) {
          return newValue;
        }
        return 0;
      });
    }, 1000);
    return itv;
  }

  const classes = useStyles();

  return (
    <Grid container className={classes.grid} data-test="main-container">
      <Typography
        className={classes.paymentText}
        variant="h3"
        data-test="success-message"
      >
        <CheckCircleIcon className={classes.checkIcon} /> Login Successful
      </Typography>
      <br />
      <Typography data-test="countdown-message">
        This window will close in {countdown} seconds.
      </Typography>
    </Grid>
  );
};
