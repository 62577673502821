import React from 'react';
import { Card, CardContent, CardHeader , Divider, InputLabel } from '@material-ui/core';
//import { makeStyles } from '@material-ui/styles';
import LineChart from '../linechart/LineChart'

// const useStyles = makeStyles(() => ({
    
//   }));

export const ChartCard = ( props ) => {
    //const classes = useStyles();

    return (
        <Card data-test="main-container">
            <CardHeader title={ props.title } />
            <Divider />
            <CardContent>
                {props.data?.data ?  <LineChart data={ props.data } maxY={ props.maxY } legend={ props.legend } lineColor={ props.lineColor } xtitle= { props.xtitle } ytitle={ props.ytitle } source={ props.source } suffix={ props.suffix } data-test="line-chart"/> : <InputLabel data-test="no-data-label">No data available</InputLabel> }   
            </CardContent>
        </Card>  
    )
};

export default ChartCard;
