import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import EcoIcon from "@material-ui/icons/Eco";
import InsertChartIcon from "@material-ui/icons/InsertChartOutlined";
import MemoryIcon from "@material-ui/icons/Memory";
import SaveIcon from "@material-ui/icons/Save";
import DataUsageIcon from '@material-ui/icons/DataUsage';
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import BackdropLoader from "../../../../components/loader/BackdropLoader";
import ProgressCard from "../../../../components/progresscard/ProgressCard";
import {
  clearFetchingUsedResources,
  fetchProjectUsedResources,
} from "../../redux/actions";
import { covertResourceUsage } from "../../../../helpers/utils";
import "./InsightTab.css";

/* istanbul ignore next */
const useStyles = () => ({});

export class Insights extends Component {
  data = [
    {
      name: "Workout",
      data: {
        "2017-01-01": 3,
        "2017-01-02": 4,
        "2017-01-03": 8,
        "2017-01-04": 9,
        "2017-01-05": 2,
        "2017-01-06": 7,
      },
    },
  ];

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.fetchProjectUsedResources(this.props.projectId);
  }

  UNSAFE_componentWillReceiveProps = newProps => {
    if (this.props.projectId !== newProps.projectId) {
      this.props.fetchProjectUsedResources(newProps.projectId);
    }
  };

  componentWillUnmount() {
    this.props.clearFetchingUsedResources();
  }

  render() {
    const { projectDetails, projectUsedResources, t } = this.props;

    return (
      <div data-test="insightContainer">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={3} data-test="appsContainer">
            <ProgressCard
              data-test="appsProgressCard"
              title={t("Projects.InsightTab.appTitleLabel")}
              total={projectDetails?.subscription?.apps}
              used={projectUsedResources?.apps}
              message={t("Projects.InsightTab.appMessageLabel")}
              color="primary"
              CardIcon={<InsertChartIcon />}
              unit={""}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3} data-test="memoryContainer">
            <ProgressCard
              data-test="memoryProgressCard"
              title={t("Projects.InsightTab.memoryTitleLabel")}
              {...covertResourceUsage(
                projectDetails?.subscription?.memory,
                projectUsedResources?.memory
              )}
              message={
                projectDetails?.subscription?.memory < 1024
                  ? t("Projects.InsightTab.memoryMessageLabelMB")
                  : t("Projects.InsightTab.memoryMessageLabelGB")
              }
              color="secondary"
              CardIcon={<MemoryIcon />}
              unit={
                projectDetails?.subscription?.memory < 1024
                  ? t("Projects.InsightTab.memoryUnitLabelMB")
                  : t("Projects.InsightTab.memoryUnitLabelGB")
              }
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3} data-test="coreContainer">
            <ProgressCard
              data-test="coreProgressCard"
              title={t("Projects.InsightTab.coreUsageTitleLabel")}
              total={projectDetails?.subscription?.cores}
              used={projectUsedResources?.core}
              message={t("Projects.InsightTab.coreUsageMessageLabel")}
              color="green"
              CardIcon={<EcoIcon />}
              unit={t("Projects.InsightTab.coreUsageUnitLabel")}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3} data-test="storageContainer">
            <ProgressCard
              data-test="storageProgressCard"
              title={t("Projects.InsightTab.storageUsageTitleLabel")}
              {...covertResourceUsage(
                projectDetails?.subscription?.disk_space,
                projectUsedResources?.disk
              )}
              message={
                projectDetails?.subscription?.disk_space < 1024
                  ? t("Projects.InsightTab.storageUsageMessageLabelMB")
                  : t("Projects.InsightTab.storageUsageMessageLabelGB")
              }
              color="secondary"
              CardIcon={<SaveIcon />}
              unit={
                projectDetails?.subscription?.disk_space < 1024
                  ? t("Projects.InsightTab.storageUsageUnitLabelMB")
                  : t("Projects.InsightTab.storageUsageUnitLabelGB")
              }
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3} data-test="dataTransferContainer">
            <ProgressCard
              data-test="dataTransferPogressCard"
              title={t("Environment.InsightTab.dataTransfer")}
              {...covertResourceUsage(
                projectDetails?.subscription?.data_transfer,
                projectUsedResources?.data_transfer?.data_transfer &&
                  projectUsedResources?.data_transfer.data_transfer.receive +
                    projectUsedResources?.data_transfer.data_transfer.transmit
              )}
              message={
                projectDetails?.subscription?.data_transfer < 1024
                  ? t("Projects.InsightTab.storageUsageMessageLabelMB")
                  : t("Projects.InsightTab.storageUsageMessageLabelGB")
              }
              color="secondary"
              CardIcon={<DataUsageIcon />}
              unit={
                projectDetails?.subscription?.disk_space < 1024
                  ? t("Projects.InsightTab.storageUsageUnitLabelMB")
                  : t("Projects.InsightTab.storageUsageUnitLabelGB")
              }
            />
          </Grid>
        </Grid>
        {this.props.fetchingUsedResources && (
          <BackdropLoader
            message={t("Projects.InsightTab.fetchingInsightMessage")}
          />
        )}
        {/* <div className='graphDropdownContainer'>
                    <FormControl variant="outlined" className='graphDropdown'>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={'Hourly'}
                        >
                            <MenuItem value={'Hourly'}>Hourly</MenuItem>
                            <MenuItem value={'Daily'}>Daily</MenuItem>
                            <MenuItem value={'Monthly'}>Monthly</MenuItem>
                            <MenuItem value={'Yearly'}>Yearly</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div>
                    <Grid container spacing={3}>
                        <Grid item sm={12} xs={12} md={4}>
                            <ChartCard title="Data Transfer" 
                                data={{ "data" : (this.props.projectInsights.data_transfer && this.props.projectInsights.data_transfer.length > 0 ? this.props.projectInsights.data_transfer : []) }} 
                                lineColor='blue' />
                        </Grid>
                        <Grid item sm={12} xs={12} md={4}>
                            <ChartCard title="CPU Usage" 
                                data={{ "data" : (this.props.projectInsights.cpu && this.props.projectInsights.cpu.length > 0 ? this.props.projectInsights.cpu : []) }} 
                                lineColor='lightBlue' />
                        </Grid>
                        <Grid item sm={12} xs={12} md={4}>
                            <ChartCard title="RAM" 
                                data={{ "data" : (this.props.projectInsights.memory && this.props.projectInsights.memory.length > 0 ? this.props.projectInsights.memory : []) }} 
                                lineColor='lightPink' />
                        </Grid>
                    </Grid>
                </div> */}
      </div>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = state => ({
  projectDetails: state.ProjectReducer.projectDetails,
  //projectInsights: state.ProjectReducer.projectInsights,
  projectUsedResources: state.ProjectReducer.projectUsedResources,
  fetchingUsedResources: state.ProjectReducer.fetchingUsedResources,
});

/* istanbul ignore next */
const mapDispatchtoProps = dispatch => {
  return {
    fetchProjectUsedResources: id => dispatch(fetchProjectUsedResources(id)),
    clearFetchingUsedResources: () => dispatch(clearFetchingUsedResources()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(withStyles(useStyles)(withTranslation()(Insights)));
