import { Button, CssBaseline, Grid, Theme, Typography, WithStyles, createStyles, CardContent,
  Card, } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
// import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Formik } from 'formik';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
// import CompanyBanner from '../../components/companybanner/CompanyBanner';
import MuiTextField from '../../components/textfield/MuiTextField';
import { requestInvite } from './redux/actions';
import './registration.css';
import Loader from "../../components/loader/Loader"
import { Dispatch } from 'redux';
import { History } from 'history';
import { UserModel } from '../../models/Account.model';
import { isEmail } from '../../helpers/utils';
import ReCAPTCHA from "react-google-recaptcha";
import { VersionRibbon } from '../../helpers/utils.ext';
import LandingScreen from "../../components/companybanner/LandingScreen";

/* istanbul ignore next */
const useStyles = (theme: Theme) => createStyles ({
    root: {
      height: '100vh',
    },
    // image: {
    //   backgroundImage: 'url(/images/infographics/login.svg)',
    //   backgroundRepeat: 'no-repeat',
    //   backgroundColor:
    //     theme.palette.type === 'light' ? theme.palette.grey[ 50 ] : theme.palette.grey[ 900 ],
    //   backgroundSize: 'cover',
    //   backgroundPosition: 'center',
    // },
    paper: {
      //margin: theme.spacing(5),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingLeft : '15%',
      paddingRight : '15%',
      paddingTop: "5%",
      paddingBottom: "5%",
      [ theme.breakpoints.down('sm') ]: {
        paddingLeft : '2.5%',
        paddingRight : '2.5%',
        //margin: theme.spacing(15, 8),
      },
    },
    // avatar: {
    //   margin: theme.spacing(1),
    //   backgroundColor: theme.palette.secondary.main,
    // },
    // form: {
    //   width: '100%', // Fix IE 11 issue.
    //   marginTop: theme.spacing(2),
    // },
    contentHeader: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: theme.spacing(5),
      paddingBototm: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    // submit: {
    //   margin: theme.spacing(1.5, 0, 2),
    // },
    textfield: {
      '& .MuiOutlinedInput-root': {
        borderRadius: '10px', // Adjust the radius value as needed
        // '&:hover fieldset': {
        //   borderColor: 'green', // You can change the hover border color if needed
        // },
        // '&.Mui-focused fieldset': {
        //   borderColor: 'blue', // You can change the focused border color if needed
        // },
      },
    },
  });

//   const Terms = () => {
//     const { t } = useTranslation();
//     return (
//       <div> <span>{t('AgreeT&C')} </span> <Link to={'/terms'}>{t('TermsOfUse')}</Link> <span> {t('And')} </span> <Link to={'/privacy'}>{t('PrivacyPolicy')}</Link> </div>
//     )
//   };
interface Props extends PropsFromRedux, WithTranslation, WithStyles<typeof useStyles> {
  history : History
}

type State = {
  captcha_code: string
}

interface Payload extends Pick<UserModel, 'first_name' | 'last_name' | 'email' | 'company' | 'designation'> {
  captcha_code: string;
  remarks: string;
}


export class RequestDemo extends Component<Props, State> {
  constructor(props: Props){
      super(props);
      this.state = {
          captcha_code:'',
      }
  }

  recaptchaRef = React.createRef<ReCAPTCHA>();
  firstNameInput = React.createRef<any>();

    componentDidMount(){
      let $this = this;
      setTimeout( function () { $this.firstNameInput.current.focus(); }, 500)
    }

    // handleAgreeTerms = (e) => {
    //     this.setState({
    //         agreeTerms : e.target.checked
    //     })
    // }
   

    hasValidEmailFeild=()=>{
      // check if email field exists
    let hasEmailField= this.props.oAuthRegisterData && this.props.oAuthRegisterData.email

    if(!hasEmailField){
      return false
    }
    // check if the email string is valid
    return isEmail(this.props.oAuthRegisterData.email)

    }

    getEmailValue=():string=>{
     return this.hasValidEmailFeild()?this.props.oAuthRegisterData.email as string:''
    }

  onChangeRecaptcha = (value: string | null) =>{
    console.log("Captcha value:", value);
    this.setState({
     captcha_code: value ? value : ""
    })
   }
    
    render () {
        const { t,classes } = this.props;
        return (
            <Grid container className={`${classes.root} login-bg`} component="main" data-test="main-component">
                <CssBaseline />
                <LandingScreen mode={2}/>
                <Grid item xs={12} sm={12} md={5}>
                  <VersionRibbon />
                    {/* <div className={classes.contentHeader} data-test="header-component">
                        <Link to="/" className='textDecorationNone'>
                            <IconButton >
                                <ArrowBackIcon />
                            </IconButton>
                        </Link>
                    </div> */}
                    <Grid container justifyContent="flex-start" alignContent="center" style={{height: "100%"}}>
            <Grid item xs={12} md={9}>
              <Card elevation={10} style={{borderRadius: "30px"}}>
                <CardContent>
                    <div className={ classes.paper }>
                        <img src="/images/logos/logo-blue.svg" alt="01Cloud" className='authlogo'/>
                        <Typography align='center' variant="h3">
                            {t('Registration.RequestDemo.provideInfo')}
                        </Typography>
                        <Formik data-test="formik-component" initialValues={ { firstName:'',lastName:'',email :this.getEmailValue() ,companyName : ''
                            ,designation : '' , password : '' , confirmPassword : '', purpose : '' } } 
                          onSubmit={ (values, { setSubmitting }) => {
                          setSubmitting(true);
                            const payload: Payload = {
                              first_name: values.firstName.trim(),
                              last_name: values.lastName.trim(),
                              email: values.email.trim(),
                              company: values.companyName.trim(),
                              designation: values.designation.trim(),
                              remarks: values.purpose.trim(),
                              captcha_code: this.state.captcha_code 
                            }
                            this.props.requestInvite(payload , this.props.history);
                            this.recaptchaRef.current?.reset();
                            this.setState({
                              captcha_code: ''
                            })
                        } }
                          validationSchema = { 
                            Yup.object().shape({
                              firstName : Yup.string().min(2,t('Account.PasswordTab.tooShort')).max(30,t('Account.PasswordTab.tooLong')).required(t('Account.ProfileTab.firstNameError')).trim(),
                              lastName : Yup.string().min(2,t('Account.PasswordTab.tooShort')).max(30,t('Account.PasswordTab.tooLong')).required(t('Account.ProfileTab.lastNameError')).trim(),
                              email : Yup.string().min(2,t('Account.PasswordTab.tooShort')).email().required(t('LoginModule.LoginPage.enterEmail')),
                              companyName : Yup.string().min(2,t('Account.PasswordTab.tooShort')).max(30,t('Account.PasswordTab.tooLong')).required(t('Account.ProfileTab.companyNameError')).trim(),
                              designation : Yup.string().min(2,t('Account.PasswordTab.tooShort')).max(30,t('Account.PasswordTab.tooLong')).required(t('Registration.RequestDemo.designationError')).trim(),
                              purpose: Yup.string().min(2,t('Account.PasswordTab.tooShort')).max(30,t('Account.PasswordTab.tooLong')).required(t('Registration.RequestDemo.purposeError')).trim(),
                            }) }
                        >
                            {
                        (props) => {
                          const {
                            values,
                            touched,
                            errors,
                            dirty,
                            // isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            // handleReset,
                            isValid,
                          } = props;
                          return (
                              <form onSubmit={ handleSubmit } >
                                  <div className='displayFlex'>
                                      <MuiTextField 
                                        id="firstName" 
                                    //autoFocus
                                        inputRef={ this.firstNameInput }
                                        error={errors.firstName && touched.firstName ? true : false}
                                        label={t('Account.ProfileTab.firstNameLabel')} 
                                        name="firstName" 
                                        data-test="firstname-input"
                                        onChange={ handleChange } 
                                        value={ values.firstName }
                                        customClassName={`${classes.textfield} firstNameInputBox`}
                                        onBlur={ handleBlur }
                                        helperText={ errors.firstName && touched.firstName ? errors.firstName : undefined}
                                        margin="normal"
                                      />
                                      <MuiTextField 
                                        id="lastName" 
                                        error={errors.lastName && touched.lastName ? true : false}
                                        label={t('Account.ProfileTab.lastNameLabel')} 
                                        customClassName={`${classes.textfield} lastNameInputBox`}
                                        name="lastName" 
                                        data-test="lastname-input"
                                        onChange={ handleChange } 
                                        value={ values.lastName }
                                        onBlur={ handleBlur }
                                        helperText={errors.lastName && touched.lastName ? errors.lastName : undefined}
                                        margin="normal"
                                      />
                                  </div>
                                  <MuiTextField 
                                    id="email" 
                                    error={errors.email && touched.email ? true : false}
                                    label={t('Registration.InviteLogin.emailAddress')}
                                    name="email" 
                                    data-test="email-input"
                                    onChange={ handleChange } 
                                    value={ values.email }
                                    disabled={this.hasValidEmailFeild()}
                                    onBlur={ handleBlur }
                                    helperText={errors.email && touched.email ? errors.email  : undefined}
                                    autoComplete="new-password"
                                    margin="normal"
                                    customClassName={classes.textfield}
                                  />
                                  <MuiTextField 
                                    id="companyName" 
                                    error={errors.companyName && touched.companyName ? true : false}
                                    label={t('Account.ProfileTab.companyNameLabel')}
                                    name="companyName" 
                                    data-test="companyname-input"
                                    onChange={ handleChange } 
                                    value={ values.companyName }
                                    onBlur={ handleBlur }
                                    helperText={errors.companyName && touched.companyName ? errors.companyName: undefined }
                                    margin="normal"
                                    customClassName={classes.textfield}
                                  />
                                  <MuiTextField 
                                    id="designation" 
                                    error={errors.designation && touched.designation ? true : false}
                                    label={t('Registration.RequestDemo.role')} 
                                    name="designation" 
                                    data-test="designation-input"
                                    onChange={ handleChange } 
                                    value={ values.designation }
                                    onBlur={ handleBlur }
                                    helperText={errors.designation && touched.designation ? errors.designation  : undefined}
                                    margin="normal"
                                    autoComplete="new-password"
                                    customClassName={classes.textfield}
                                  />
                                  <MuiTextField 
                                    id="purpose" 
                                    error={errors.purpose && touched.purpose ? true : false}
                                    label={t('Registration.RequestDemo.purpose')}
                                    name="purpose" 
                                    data-test="purpose-input"
                                    onChange={ handleChange } 
                                    value={ values.purpose }
                                    onBlur={ handleBlur }
                                    helperText={errors.purpose && touched.purpose ? errors.purpose  : undefined}
                                    margin="normal"
                                    autoComplete="new-password"
                                    customClassName={classes.textfield}
                                  /> 

                                  {
                                    <div className='registrationButtonDiv'>
                                      <ReCAPTCHA
                                        ref={this.recaptchaRef}
                                        data-test="captcha"	
                                        sitekey={window?.config?.REACT_APP_RECAPTCHA_SITEKEY}
                                        onChange={this.onChangeRecaptcha}
                                      />
                                    </div>
                                  } 
 
                                  {/* <div className="tnscheck">
                                  <FormControlLabel
                                        control={
                                            <Checkbox
                                            name="checkedB"
                                            color="primary"
                                          
                                            onChange={ (e)=>{ this.handleAgreeTerms(e) } }
                                        />
                                        }
                                    label={ <Terms/> }
                                    />
                                    </div> */}
                             
                                  <div className='registrationButtonDiv'>
                                      {
                                        this.props.executingRequestInvite && <Loader data-test="loader"/>
                                      }
                                      {
                                        !this.props.executingRequestInvite && 
                                        <Button
                                          variant="contained"
                                          data-test="button"
                                          type='submit'
                                          color='primary'
                                          //align='center'
                                          className="w-100 m-t-20 r-69"
                                          disabled={ !(isValid && dirty && this.state.captcha_code) } //&& this.state.agreeTerms
                                        >
                                            {t('Request A Demo')}
                                        </Button>
                                      }
                                  </div>
                              </form>
                          )
                        }
                      }
                        </Formik>
                        <div className="signInDiv">
              
              <Link
                to="/"
                // variant="body2"
                className="textDecorationNone"
              >
                <Typography variant="body1" className="signInLink">
                  {/* {t("LoginModule.LoginPage.account")} */}
                  <span className="signup">
                    {/* {" "} */}
                    Back to Login
                  </span>
                </Typography>
              </Link>
            </div>
                    </div>
                    </CardContent>
              </Card>
            </Grid>
          </Grid>
                </Grid>
            </Grid>
        );
    }
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  executingRequestInvite: state.RegisterReducer.executingRequestInvite,
  oAuthRegisterData: state.RegisterReducer.oAuthRegisterData
})

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    requestInvite: (payload: Payload,history: History) => dispatch(requestInvite(payload,history)),
  }
}

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(withStyles(useStyles)(withTranslation()(RequestDemo)));