import { Button, Card, CardActions, CardContent, CardHeader, Collapse, Divider, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Select, Switch, Typography } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import AddPhotoAlternateOutlinedIcon from '@material-ui/icons/AddPhotoAlternateOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import _ from 'lodash';
import React, { Component } from 'react';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { connect, ConnectedProps } from 'react-redux';
import { Prompt } from 'react-router-dom';
import MultiValueChipInput from '../../../components/emailinput/MultiValueChipInput';
import ImageCropper from '../../../components/imagecropper/ImageCropper';
import BackdropLoader from '../../../components/loader/BackdropLoader';
// import SubscriptionCard from '../../../components/subscriptioncard/SubscriptionCard';
import SubscriptionList from '../../../components/subscriptionlist/SubscriptionList';
import MuiTextField from '../../../components/textfield/MuiTextField';
import { fetchRegionsList } from '../../app/createapp/redux/actions';
import { setCurrentProject, updateBreadcrumb } from '../redux/actions';
import './CreateProject.css';
import { createProjectApiCall, getSubscriptionListCall, resetCreateProjectCallError } from './redux/actions';
import { parseApiResponse,validateInputField } from "../../../helpers/utils";
import { withTranslation, WithTranslation } from 'react-i18next';
import { History } from 'history';
import { ProjectModel, SubscriptionModel } from '../../../models/Project.model';
import { Dispatch } from "redux";
import { WithStyles } from "@material-ui/styles";
import { ModuleHelp } from '../../../components/modulehelp/modulehelp';

/* istanbul ignore next */
const useStyles = (theme: any) => ({
    actions: {
        display: "flex",
    },
    save: {
        marginLeft: "auto",
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    imgStyle: {
        borderRadius: "4px"
    },
    advancedSetting:{
        display:"none"
    },
    uploadBtn:{
        display:'none'
    }
});

type AdvanceSettings = Pick<ProjectModel,"project_code"|"base_domain"|"description"|"cluster_scope"|"region"|
"logging"|"monitoring"|"optimize_cost"|"dedicated_lb"|"tags">

interface AdvanceSettingModel extends AdvanceSettings{
    isProjectCodeError: boolean,
    projectCodeErrorMessage: string
}

type Payload = Pick<ProjectModel,"name"|"subscription_id"|"description"|"project_code"|"base_domain"|
"cluster_scope"|"region"|"logging"|"monitoring"|"optimize_cost"|"dedicated_lb"|"tags">

interface Props extends WithTranslation, PropsFromRedux, WithStyles<typeof useStyles> {
    history: History,
    subscriptionList: SubscriptionModel[],
}

type State = {
    projectName: string,
    selectedSubscriptionId: number,
    advanceSettings: AdvanceSettingModel,
    selectedFile:any,
    showSettings: boolean,
    isBlocking: boolean,
    isProjectFeildError: boolean,
    projectFeildErrorMessage: string,
    isSubmitButtonDisabled: boolean,
    imagePreview: string,
    isSubscriptionSelected: boolean,
    selectedSubscriptionAmount: number,
    selectedSubscriptionName: string,
    subscriptionList: SubscriptionModel[],
    openAdvanceSettings: boolean,
    imgUploadeditModeEnabled: boolean,
}
  

export class CreateProject extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            projectName: '',
            isSubmitButtonDisabled: true,
            isSubscriptionSelected: false,
            selectedSubscriptionId: 0,
            selectedSubscriptionAmount: 0,
            isProjectFeildError: false,
            projectFeildErrorMessage: '',
            selectedSubscriptionName: 'Starter',
            subscriptionList: this.props.subscriptionList,
            selectedFile: null,
            imagePreview: '',
            openAdvanceSettings: false,
            showSettings: false,
            advanceSettings: {
                project_code:"",
                base_domain: "",
                description: "",
                cluster_scope: 0,
                region: " ",
                logging: "01Logs",
                monitoring: "Prometheus",
                optimize_cost: false,
                dedicated_lb: false,
                tags: "",
                isProjectCodeError: false,
                projectCodeErrorMessage: ""
            },
            imgUploadeditModeEnabled: false,
            isBlocking: false
        }
    }

    componentDidMount() {
        const { getSubscriptions, history } = this.props;
        getSubscriptions(history);
        this.props.updateBreadcrumb([]);
        this.props.setCurrentProject(0);
        this.props.fetchRegionsList();
    }

    UNSAFE_componentWillReceiveProps = (nextProps: Props) => {
        if (nextProps.subscriptionList) {
            if (nextProps.subscriptionList !== this.props.subscriptionList && nextProps.subscriptionList.length > 0) {
                this.setState({
                    subscriptionList: nextProps.subscriptionList,
                    selectedSubscriptionId: nextProps.subscriptionList[0].id,
                    selectedSubscriptionAmount: nextProps.subscriptionList[0].price,
                    selectedSubscriptionName: nextProps.subscriptionList[0].name,
                })
            }
        }
    }

    handleCreateProject = () => {
        const payload = {
            name: this.state.projectName.trim(),
            subscription_id: this.state.selectedSubscriptionId,
            description: this.state.advanceSettings.description,
            project_code: this.state.advanceSettings.project_code,
            base_domain: this.state.advanceSettings.base_domain,
            cluster_scope: this.state.advanceSettings.cluster_scope,
            region: this.state.advanceSettings.region.trim(),
            logging: this.state.advanceSettings.logging,
            monitoring: this.state.advanceSettings.monitoring,
            optimize_cost: this.state.advanceSettings.optimize_cost,
            dedicated_lb: this.state.advanceSettings.dedicated_lb,
            tags: this.state.advanceSettings.tags
        }

        let iconPayload = null;
        if (this.state.selectedFile) {
            iconPayload = new FormData();
            iconPayload.append('file_name', this.state.selectedFile.name);
            iconPayload.append('file_type', this.state.selectedFile.type);
            iconPayload.append('file', this.state.selectedFile);
        }
        this.setState({ isBlocking: false });
        this.props.createProjectApiCall(payload, iconPayload, this.props.history)
    }

    handleProjectNameOnChange = (e: React.ChangeEvent<{ value: string }>) => {
        // if (e.target.value.length > 0) {
        //     if (e.target.value.length > 0 && e.target.value.length < 2) {
        //         this.setState({
        //             projectName: e.target.value,
        //             isSubmitButtonDisabled: true,
        //             isProjectFeildError: true,
        //             projectFeildErrorMessage: this.props.t('Account.PasswordTab.tooShort'),
        //         })
        //     }
        //     else if (e.target.value.length > 30) {
        //         this.setState({
        //             projectName: e.target.value,
        //             isSubmitButtonDisabled: true,
        //             isProjectFeildError: true,
        //             projectFeildErrorMessage: this.props.t('Account.PasswordTab.tooLong'),
        //         })
        //     }
        //     else if (!/^[A-Za-z0-9_ -]*$/.test(e.target.value)) { // /^[A-Za-z0-9_\ \-]*$/
        //         this.setState({
        //             projectName: e.target.value,
        //             isSubmitButtonDisabled: true,
        //             isProjectFeildError: true,
        //             projectFeildErrorMessage: this.props.t('Projects.ProjectsTab.alphanumericError'),
        //         })
        //     }
        //     else if (/^\s*$/.test(e.target.value)) {
        //         this.setState({
        //             projectName: e.target.value,
        //             isSubmitButtonDisabled: true,
        //             isProjectFeildError: true,
        //             projectFeildErrorMessage: this.props.t('Projects.ProjectsTab.spaceError'),
        //         })
        //     }
        //     else {
        //         this.setState({
        //             projectName: e.target.value,
        //             isSubmitButtonDisabled: false,
        //             isProjectFeildError: false,
        //             projectFeildErrorMessage: '',
        //         })
        //     }
        // }
        // else {
        //     this.setState({
        //         projectName: e.target.value,
        //         isSubmitButtonDisabled: true,
        //         isProjectFeildError: true,
        //         projectFeildErrorMessage: this.props.t('Projects.ProjectsTab.projetEmptyError'),
        //     })
        // }
        let validation = validateInputField(e.target.value);
        this.setState({
          projectName: e.target.value,
          isSubmitButtonDisabled: validation.error,
          isProjectFeildError: validation.error,
          projectFeildErrorMessage: validation.error
            ? this.props.t(validation.message)
            : "",
        });
        this.setState({ isBlocking: true });
        if(this.props.createProjectCallError) this.props.resetCreateProjectCallError();
    }

    handleProjectNameOnBlur = () => {
        const { projectName } = this.state;

        if (projectName.length === 0) {
            this.setState({
                isProjectFeildError: true,
                projectFeildErrorMessage: this.props.t('Projects.ProjectsTab.projetEmptyError'),
            })
        }
    }

    handleShowSettings = () => {
        this.setState({
            showSettings: !this.state.showSettings
        })

    };

    handleSubscriptionClick = (subscription: SubscriptionModel) => {
        // const subscriptionListDummy = [ ...this.state.subscriptionList ];
        // let selectedSubscriptionAmount;
        // let selectedSubscriptionName;

        // subscriptionListDummy.forEach(subscription => {
        //     if(subscription.id === subscriptionId){
        //         subscription[ 'selected' ] = true;
        //         selectedSubscriptionAmount = subscription.price;
        //         selectedSubscriptionName = subscription.name;
        //     }else {
        //         subscription[ 'selected' ] = false
        //     }
        // });
        this.setState({
            // subscriptionList : subscriptionListDummy,
            selectedSubscriptionId: subscription.id,
            //isSubscriptionSelected : true,
            isBlocking: true,
            selectedSubscriptionAmount: subscription.price,
            selectedSubscriptionName: subscription.name
        })
    }

    handleImgUpload = () => {
        this.setState({
            imgUploadeditModeEnabled: true,
        })
    }

    handleImgUploadCancel = () => {
        this.setState({
            imgUploadeditModeEnabled: false
        })
    }
    
    handleIconSelection = (data: any) => {
        this.setState({
            imagePreview: URL.createObjectURL(data),
            selectedFile: data
        })
        this.setState({ isBlocking: true });
        this.handleImgUploadCancel();
    }

    handleAdvanceSettings = (e: React.ChangeEvent<{ value: unknown, name?: string|boolean, checked?:boolean }>) => {
        const { advanceSettings } = this.state;
        const {name, value, checked} = e.target;
        if(name === "optimize_cost" || name === "dedicated_lb") 
        {
            advanceSettings[name] = checked as boolean;
        }
        else if(name === "project_code") {
            advanceSettings[name] = value as string;
            let validation = validateInputField(value, "projectCode")
            this.setState({
                isSubmitButtonDisabled: validation.error
            });
            advanceSettings.isProjectCodeError = validation.error;
            advanceSettings.projectCodeErrorMessage = validation.error ? this.props.t(validation.message): ""
        }
        else if (name === "base_domain" || name === "logging" || name === "region"
         || name === "monitoring" || name === "description"){
            advanceSettings[name] = value as string;
        }

        else if (name === "cluster_scope"){
            advanceSettings[name] = value as number;
        }
        // else
            // advanceSettings[name] = value;
        this.setState({ isBlocking: true });
        this.setState({
            advanceSettings
        })
    }

    handleTags = (data: string[]) => {
        const { advanceSettings } = this.state;
        advanceSettings.tags = data.join(",");
        this.setState({
            advanceSettings
        })
    }
    render() {
        const { projectName, isProjectFeildError, projectFeildErrorMessage, isSubmitButtonDisabled } = this.state;
        const { project_code, isProjectCodeError, projectCodeErrorMessage } = this.state.advanceSettings;
        const { subscriptionList, classes, regionsList , createProjectCallError, t } = this.props;
        return (
            <div data-test="main-container">
                <Prompt
                    when={this.state.isBlocking}
                    message={() =>
                        t('CronJob.CronJobList.errorMessage')
                    }//  ${location.pathname}
                    data-test="prompt"
                />
                <Card>
                    <CardHeader title={
                        <Grid container spacing={1}>
                            <Grid item>
                                <span>  {t("Projects.CreateProject.title")} </span>
                            </Grid>
                            <Grid item>
                                <ModuleHelp module='project' action='create' tooltipMsg='See how to create a project' />
                            </Grid>
                        </Grid>
                    } />
                    <Divider />
                    <CardContent>
                        <Grid container className="projectNameContainer" spacing={1}>
                            <Grid item xs={12} sm={6} md={4}>
                                <MuiTextField
                                  id='ProjectName'
                                  value={projectName}
                                  label={t("Projects.CreateProject.projectLabel")}
                                  error={Boolean(parseApiResponse(createProjectCallError).field === "name") || isProjectFeildError}
                                  helperText={parseApiResponse(createProjectCallError).field === "name" ? parseApiResponse(createProjectCallError).message : projectFeildErrorMessage}
                                  required
                                  variant='outlined'
                                  onBlur={this.handleProjectNameOnBlur}
                                  onChange={e => this.handleProjectNameOnChange(e)}
                                  data-test="project-name-field"
                                />
                            </Grid>
                            <Grid className={classes.uploadBtn} item xs={12} sm={6} md={12}>
                                {this.state.imagePreview && <img className={clsx(classes.imgStyle)} src={this.state.imagePreview} height={100} width={100} alt={"Preview"} data-test="img-preview" />}
                                {this.state.imagePreview && <br/>}
                                <Button endIcon={<AddPhotoAlternateOutlinedIcon />} color="primary" variant="contained" onClick={() => this.handleImgUpload()} data-test="add-icon-btn" >{t("Projects.CreateProject.addIconLabel")}</Button>
                            </Grid>
                        </Grid>
                    </CardContent>

                    <CardHeader title={t("Projects.CreateProject.subscriptionTitle")} />
                    {/* <Divider /> */}
                    <CardContent>

                        {/* <Typography component="h6" 
                        variant="subtitle1" className='m-t-20'>
                        Select subscription*
                    </Typography> */}
                        <Grid container
                          className='subscriptionContianer'
                          spacing={2}
                        >
                            {
                                subscriptionList === null && [0, 1, 2, 3, 4, 5, 6, 7].map(value => {
                                    return (
                                        <Grid item md={3} sm={6} xs={12} key={value} data-test="sub-skel-grid">
                                            <Card variant='outlined' style={{ padding: '1rem' }}>
                                                <SkeletonTheme height={250}>
                                                    <div >
                                                        <Skeleton />
                                                    </div>
                                                    <div >
                                                        <Skeleton />
                                                    </div>
                                                    <br />
                                                    <Skeleton count={5} />
                                                </SkeletonTheme>
                                            </Card>
                                        </Grid>
                                    )
                                })
                            }
                            {
                                subscriptionList && subscriptionList.length === 0 &&
                                <Grid item xs={12}>
                                    <Card variant='outlined' style={{ padding: '1rem' }} data-test="no-sub-card">
                                        <Typography className='oneRemMarginTopAndoneRemBottomSeperator'
                                          component="h6"
                                          gutterBottom
                                          variant="subtitle1"
                                        //   color="body2"
                                        >
                                            {t("Projects.CreateProject.noSubscriptionPlans")}
                                        </Typography>
                                    </Card>
                                </Grid>
                            }
                        </Grid>

                        <Grid container
                          className='subscriptionContianer'
                          spacing={2}
                        >
                            {
                                subscriptionList && subscriptionList.length > 0 && 
                                <SubscriptionList 
                                    // isLowerSubscription = {this.isLowerSubscription}
                                    handleSubscriptionClick = {this.handleSubscriptionClick}
                                    selectedSubscriptionId = {this.state.selectedSubscriptionId}
                                    subscriptionList = {subscriptionList}
                                    data-test="subs-list-card"
                                />   
                                // subscriptionList.map(subscription => {
                                //     return (
                                //         <Grid item md={3} sm={6} xs={12} key={subscription.id}>
                                //             <SubscriptionCard
                                //               details={subscription}
                                //               handleSubscriptionClick={this.handleSubscriptionClick}
                                //               selectedSubscriptionId={this.state.selectedSubscriptionId}
                                //             />
                                //         </Grid>
                                //     )
                                // })
                            }
                        </Grid>
                        {
                            subscriptionList && subscriptionList.length > 0 &&
                            <Typography className='oneRemMarginTopAndoneRemBottomSeperator italicfont'
                              component="h6"
                              gutterBottom
                              variant="subtitle1"
                           
                            //   color="body2"
                            >
                                {t("Projects.CreateProject.cannotDowngrade")}
                            </Typography>
                        }
                    </CardContent>

                    <CardActions className={classes.advancedSetting}>
                        <Typography variant="h6" color="primary">{t("Projects.CreateProject.advanceSettingLabel")}</Typography>
                        <IconButton
                          onClick={() => this.handleShowSettings()}
                          aria-label="Show/Hide Settings"
                          aria-expanded={this.state.showSettings}
                          className={clsx(classes.expand, {
                                [classes.expandOpen]: this.state.showSettings,
                            })}
                          data-test="expand-more"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    </CardActions>
                    <Collapse in={this.state.showSettings} timeout={600} unmountOnExit>
                        <CardContent>
                            <Grid container className="projectNameContainer" spacing={2}>
                                <Grid item xs={12} sm={6} md={4}>
                                    <MuiTextField
                                      name='project_code'
                                      label={t("Projects.CreateProject.projectCodeLabel")}
                                      error={isProjectCodeError}
                                      helperText={projectCodeErrorMessage}
                                      value= { project_code }
                                      onChange={ (e:React.ChangeEvent<HTMLInputElement>) => this.handleAdvanceSettings(e) }
                                      data-test="project-code-field"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={4}>
                                    <MuiTextField
                                      name='base_domain'
                                      label={t("Projects.CreateProject.baseDomainLabel")}
                                      value= { this.state.advanceSettings.base_domain }
                                      onChange={ (e:React.ChangeEvent<HTMLInputElement>) => this.handleAdvanceSettings(e) }
                                      data-test="base-domain-field"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={4}>
                                    <FormControl variant="outlined" className="w-100">
                                        <InputLabel id="demo-simple-select-outlined-label">{t("Projects.CreateProject.cluseterScopeLabel")}</InputLabel>
                                        <Select
                                          labelId="demo-simple-select-outlined-label"
                                          name="cluster_scope"
                                          value={ this.state.advanceSettings.cluster_scope }
                                            //variant='outlined' 
                                          label={t("Projects.CreateProject.cluseterScopeLabel")}
                                          onChange={ (e) => this.handleAdvanceSettings(e) }
                                          data-test="cluster-scope-select"
                                        >
                                            <MenuItem value={0}>{t("Projects.CreateProject.shared")}</MenuItem>
                                            <MenuItem value={1}>{t("Projects.CreateProject.organization")}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4}>
                                    <FormControl variant="outlined" className="w-100">
                                        <InputLabel id="demo-simple-select-outlined-label">{t("Projects.CreateProject.defaultRegionLabel")}</InputLabel>
                                        <Select
                                          labelId="demo-simple-select-outlined-label"
                                          name="region"
                                          value={ this.state.advanceSettings.region }
                                            //variant='outlined' 
                                          label={t("Projects.CreateProject.defaultRegionLabel")}
                                          onChange={ (e:React.ChangeEvent<{ value: unknown, name?: string }>) => this.handleAdvanceSettings(e) }
                                          data-test="default-region-select"
                                        >
                                            <MenuItem value=' '>All region</MenuItem>
                                            {
                                                regionsList.map((region:string) => <MenuItem key={region} value={region}>{region}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4}>

                                    <FormControl variant="outlined" className="w-100">
                                        <InputLabel id="demo-simple-select-outlined-label">{t("Projects.CreateProject.loggingLabel")}</InputLabel>
                                        <Select
                                          labelId="demo-simple-select-outlined-label"
                                          name="logging"
                                          value={ this.state.advanceSettings.logging }
                                          label={t("Projects.CreateProject.loggingLabel")}
                                          onChange={ (e) => this.handleAdvanceSettings(e) }
                                          MenuProps={{
                                                getContentAnchorEl: null,
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left"
                                                }
                                            }}
                                            data-test="logging-select"
                                        >
                                            {/* <MenuItem value="Select">Select</MenuItem> */}
                                            <MenuItem value="01Logs">{t("Projects.CreateProject.logs")}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4}>
                                    <FormControl variant="outlined" className="w-100">
                                        <InputLabel id="demo-simple-select-outlined-label">{t("Projects.CreateProject.monitoringLabel")}</InputLabel>
                                        <Select
                                          labelId="demo-simple-select-outlined-label"
                                          name="monitoring"
                                          value={ this.state.advanceSettings.monitoring }
                                            //variant='outlined' 
                                          label={t("Projects.CreateProject.monitoringLabel")}
                                          onChange={ (e) => this.handleAdvanceSettings(e) }
                                          MenuProps={{
                                                getContentAnchorEl: null,
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left"
                                                }
                                            }}
                                          data-test="monitoring-select"
                                        >
                                            {/* <MenuItem value="Select">Select</MenuItem> */}
                                            <MenuItem value="Prometheus">{t("Projects.CreateProject.prometheus")}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    {/* <MuiTextField
                                        name='tags'
                                        label='Tags'
                                        //variant='outlined'
                                        value= { this.state.advanceSettings.tags }
                                        onChange={ this.handleAdvanceSettings }
                                    /> */}
                                    <span className="chiplabel">{t("Projects.CreateProject.tags")}</span>
                                    <MultiValueChipInput values={ _.without(this.state.advanceSettings.tags.split(','), '')  } handleValues={this.handleTags} data-test="project-tags-field"/>
                                </Grid>

                                <Grid item xs={12}>
                                    <MuiTextField
                                      name="description"
                                      label={t("Projects.CreateProject.descriptionLabel")}
                                        //style={{ margin: 8 }}
                                        //fullWidth
                                        //margin="normal"
                                      InputLabelProps={{
                                            shrink: true,
                                        }}
                                      multiline
                                      rows={3}
                                        //variant="outlined"
                                      onChange={ (e:React.ChangeEvent<HTMLInputElement>) => this.handleAdvanceSettings(e) }
                                      data-test="project-desc-field"
                                    />
                                </Grid>
                                <Grid item md={12}>
                                    <FormControlLabel
                                      control={
                                          <Switch
                                            name="dedicated_lb"
                                            color="primary"
                                            checked= { this.state.advanceSettings.dedicated_lb }
                                            onChange={ (e) => this.handleAdvanceSettings(e) }
                                          />
                                        }
                                        label={t("Projects.CreateProject.dedicatedLoadBalancer")}
                                        data-test="project-lb-switch"
                                    />
                                </Grid>
                                <Grid item md={12}>
                                    <FormControlLabel
                                      control={
                                          <Switch
                                            name="optimize_cost"
                                            color="primary"
                                            checked= { this.state.advanceSettings.optimize_cost }
                                            onChange={ (e) => this.handleAdvanceSettings(e) }
                                          />
                                        }
                                        label={t("Projects.CreateProject.optimizeCostLabel")}
                                        data-test="project-cost-switch"
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Collapse>

                </Card>
                <div className='margintoponerem'>
                    <Button
                      onClick={this.handleCreateProject}
                      variant='contained'
                      color='primary'
                      disabled={!(!isSubmitButtonDisabled && this.state.selectedSubscriptionId)}
                      data-test="create-project-btn"
                    >{t("Projects.CreateProject.createProjectLabel")}
                    </Button>
                    {this.props.creatingProject && <BackdropLoader message={t('Projects.CreateProject.creatingProject')} data-test="backdrop-loader"/>}

                </div>
                {
                    this.state.imgUploadeditModeEnabled && 
                    <ImageCropper 
                        title ={t("Projects.CreateProject.projectIconLabel")}
                        dontUpload = {true}
                        isOpen={this.state.imgUploadeditModeEnabled}
                        onClose={this.handleImgUploadCancel}
                        onSuccess={ this.handleIconSelection}
                        data-test="image-crop"
                    />
                }
            </div>
        )
    }
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => {
    return {
        subscriptionList: state.CreateProjectReducer.subscriptionList,
        creatingProject: state.CreateProjectReducer.creatingProject,
        createProjectCallError: state.CreateProjectReducer.createProjectCallError,
        regionsList: state.CreateAppReducer.regionsList,
    }
}

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
    return {
        createProjectApiCall: (payload: Payload, iconPayload: any, history: History) => dispatch(createProjectApiCall(payload, iconPayload, history)),
        getSubscriptions: (history: History) => dispatch(getSubscriptionListCall(history)),
        setCurrentProject: (id: number) => dispatch(setCurrentProject(id)),
        updateBreadcrumb: (breadcrumbData: any[]) => dispatch(updateBreadcrumb(breadcrumbData)),
        fetchRegionsList : () => dispatch(fetchRegionsList()),
        resetCreateProjectCallError : () => dispatch(resetCreateProjectCallError())
    }
}

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withStyles(useStyles)(withTranslation()(CreateProject)));