import React, { useEffect, useState } from "react";
import { connect,ConnectedProps } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
  TablePagination,
  MenuItem,
  Select
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { downloadInvoiceById, getPaymentHistory, resetDownloadInvoiceById, resetPaymentHistory } from "./redux/actions";
import { getDate } from "../../../helpers/utils";
import { useTranslation } from 'react-i18next';
import PaymentSummaryPopup from "../../../components/billing/PaymentSummaryPopup";
import BackdropLoader from "../../../components/loader/BackdropLoader";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { Dispatch } from "redux";
import { createStyles, Theme } from "@material-ui/core";
import { BillingHistory as Payment } from "../../../models/Billing.model";
import { TablePaginationActionsProps } from "@material-ui/core/TablePagination/TablePaginationActions";
/* istanbul ignore next */
const useStyles = makeStyles((theme:Theme) =>createStyles ({
  actionButton: {
    borderRadius: 2,
    minWidth: 125,
  },
  cardAction: {
    marginTop: 0,
    marginRight: 0,
  },
  cardContent: {
    padding: "5px 16px",
  },
  costText: {
    fontWeight: 400,
  },
  subHeader: {
    color: "#43425d94",
  },
  ml5: {
    marginLeft: 5,
  },
  tableCell: {
    padding: 0
  },
  tableContainer: {
    maxHeight: 600
  },
  minHeight: {
    minHeight: 300,
  },
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing ? theme.spacing(2.5) : 10,
    display: "flex",
    alignItems: "center"
  },
  selectComp: {
    marginLeft: theme.spacing ? theme.spacing(2) : 10,
    border: 0,
    "&::before": {
      border: "none"
    }
  },
}));

export function TablePaginationActions(props:TablePaginationActionsProps) {
  const classes = useStyles();
  const { count, page, rowsPerPage, onPageChange } = props;
  const pageCount =
    count % rowsPerPage === 0
      ? count / rowsPerPage
      : (count / rowsPerPage) + 1;

  const pages = new Array(Math.trunc(pageCount)).fill("0").map((_, i) => i);

  const handleBackButtonClick = ( ) => {
    onPageChange(null, page - 1);
  };

  const handleNextButtonClick = ( ) => {
    onPageChange(null, page + 1);
  };

  const handleGotoPageChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    onPageChange(null, e.target.value as number);
  };

  
  return (
    <div className={classes.root}>
      <Typography variant="body1">
        Go To Page
        <Select
          value={page}
          onChange={handleGotoPageChange}
          displayEmpty
          className={classes.selectComp}
          inputProps={{ "aria-label": "Without label" }}
          data-test="select-page"
        >
          {pages.map((p) => (
            <MenuItem key={p} value={p}>{p + 1}</MenuItem>
          ))}
        </Select>
      </Typography>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        data-test="back-icon-button"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        data-test="next-icon-button"
      >
        <KeyboardArrowRight />
      </IconButton>
    </div>
  );
}
// only the props from redux are used....no additional props utilized
export const BillingHistory = (props:PropsFromRedux) => {
  const [ t ] = useTranslation();
  const [expanded, setExpanded] = useState(true);
  const [openSummaryPopup, setOpenSummaryPopup] = useState(false);
  const [currentInvoice, setCurrentInvoice] = useState(0);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [history, setHistory] = useState([]);
  const rowsPerPage = 10;

  const classes = useStyles();

  useEffect(() => {
    props.getPaymentHistory(rowsPerPage, currentPage + 1);
    return () => {
      props.resetPaymentHistory();
    }
  }, [])

  useEffect(() => {
    setTotalRows(props.paymentHistory?.count ?? 0);
    setHistory(props.paymentHistory?.data ?? []);
  }, [props.paymentHistory])

  useEffect(() => {
    if(!props.invoiceData) return;
    var a = document.createElement("a");
    a.href = props.invoiceData;
    a.setAttribute("download", "invoice.pdf");
    a.click();
  }, [props.invoiceData])

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const handleInvoiceDownload = (invoiceId:number) => {
    props.downloadInvoiceById(invoiceId)
  }

  const handleOpenSummaryPopup = (invoiceId:number) => {
    setCurrentInvoice(invoiceId);
    setOpenSummaryPopup(true);
  }

  const handleCloseSummaryPopup = () => {
    setOpenSummaryPopup(false);
  }

  const handleChangePage = (_: any , newPage:number) => {
    props.getPaymentHistory(rowsPerPage, newPage + 1);
    setCurrentPage(newPage);
  }
  return (
    <Grid item xs={12} data-test="main-container">
      <Card>
        <CardHeader
          classes={{ action: classes.cardAction }}
          title={<Typography variant="h4">{t("Billing.BillingHistory.title")}</Typography>}
          action={
            <IconButton onClick={handleExpand}>
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          }
          data-test="expand"
        />
        <Collapse in={expanded} data-test="collapse-container">
          <CardContent className={classes.cardContent}>
            <TableContainer component={Paper} className={`${classes.tableContainer} ${(totalRows > 0) && classes.minHeight}`}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow hover>
                    <TableCell colSpan={2}>{t("Billing.BillingHistory.genDate")}</TableCell>
                    <TableCell colSpan={3}>{t("Support.SupportPage.title")}</TableCell>
                    <TableCell colSpan={2}>{t("Billing.BillingHistory.amount")}</TableCell>
                    <TableCell colSpan={2}>{t("Billing.BillingHistory.balance")}</TableCell>
                    <TableCell align={'center'} colSpan={2}>{t('Billing.DownloadInvoice')} </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    history.length > 0 ? history.map((payment:Payment, idx:number) => (
                      <TableRow hover key={idx} data-test="payment-row">
                        <TableCell colSpan={2} data-test="payment-date-cell">
                          {getDate(payment?.date)}
                        </TableCell>
                        <TableCell colSpan={3}>
                          { ((payment?.invoice_id ?? 0) > 0) ? 
                            <Link href="#billing" onClick={() => handleOpenSummaryPopup(payment.invoice_id)} data-test="payment-title-cell">{payment?.title}</Link>
                            : payment?.title }
                        </TableCell>
                        <TableCell colSpan={1} data-test="payment-balance-cell">{(payment?.debit ?? 0) > 0 ? `- $${payment.debit?.toFixed(2)}` : `$${payment?.credit?.toFixed(2) ?? "0.0"}`}</TableCell>
                        <TableCell colSpan={3}>
                         {payment.balance.toFixed(2)}
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          { ((payment?.invoice_id ?? 0) > 0) ?
                          <Tooltip title={t('Billing.DownloadInvoice')} placement="left">
                            <IconButton onClick={() => handleInvoiceDownload(payment.invoice_id)} data-test="payment-download-icon">
                              {/* <a href={this.state.currentClusterUrl} download ref={this.exportRef} >{""}</a> */}
                              <GetAppIcon/>
                            </IconButton>
                          </Tooltip>
                          : ""}
                        </TableCell>
                      
                      </TableRow>
                    )) : (
                      <TableRow hover>
                        <TableCell colSpan={7} align="center">
                          <Typography variant="body2" data-test="no-history-label"><em>{t("Billing.BillingHistory.noBillingHistory")}</em></Typography>
                        </TableCell>
                      </TableRow>
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
            {
              totalRows > 0 && (
                <TablePagination
                  rowsPerPageOptions={[]}
                  component="div"
                  count={totalRows}
                  rowsPerPage={rowsPerPage}
                  onPageChange={handleChangePage}
                  page={currentPage}
                  // onPageChange={handleChangePage}
                  ActionsComponent={TablePaginationActions}
                  data-test="table-pagination"
                />
              )
            }
          </CardContent>
        </Collapse>
      </Card>
      {
        openSummaryPopup && 
          <PaymentSummaryPopup 
            openPopup={openSummaryPopup} 
            handleClosePopup={handleCloseSummaryPopup} 
            currentInvoice={currentInvoice}
            data-test="payment-popup"
          />
      }
      {
        props.fetchingPaymentHistory && <BackdropLoader message={t('Billing.BillingHistory.fetchingPaymentHistory')} />
      }
    </Grid>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state:any) => ({
  paymentHistory: state.PaymentReducer.paymentHistory,
  invoiceData: state.PaymentReducer.invoiceData,
  fetchingPaymentHistory: state.PaymentReducer.fetchingPaymentHistory,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch:Dispatch) => {
  return {
    resetDownloadInvoiceById: () => dispatch(resetDownloadInvoiceById()),
    downloadInvoiceById: (id:number) => dispatch(downloadInvoiceById(id)),
    getPaymentHistory: (size:number, page:number) => dispatch(getPaymentHistory(size, page)),
    resetPaymentHistory: () => dispatch(resetPaymentHistory()),
  };
};
const connector=connect(mapStateToProps, mapDispatchtoProps)
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(BillingHistory);
