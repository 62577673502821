import React from "react";
import { Grid, Card, CardContent, Typography, Divider, CardActions } from "@material-ui/core";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";

/* istanbul ignore next */
const useStyles = makeStyles((theme) => ({
    root: {},
    imageContainer: {
      height: 64,
      width: 64,
      margin: "0 auto",
      border: `1px solid ${theme?.palette?.divider}`,
      borderRadius: "5px",
      overflow: "hidden",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    image: {
      width: "100%",
    },
  }));

export default function AddonSkeleton() {
    const classes = useStyles();

  return (
      <SkeletonTheme height={250} data-test="skeleton-main-container">
          <Card className={clsx(classes.root)}>
              <CardContent>
                  <Grid container spacing={2}>
                      <Grid item md={3}>
                          <div>
                              <Skeleton width={"50%"} height={60} />
                          </div>
                      </Grid>
                      <Grid item md={9}>
                          <Typography variant="h5">
                              <Skeleton width={"30%"} />
                          </Typography>
                          <Typography gutterBottom variant="body2" className="threeLine">
                              <Skeleton width={"70%"} count={3} />
                          </Typography>
                          <Typography gutterBottom variant="body2">
                              <Skeleton width={"10%"} />
                          </Typography>
                          <Typography display="inline" variant="body2">
                              <Skeleton width={"30%"}  height={20} />
                          </Typography>
                      </Grid>
                  </Grid>
              </CardContent>
              <Divider />
              <CardActions>
                  <Grid container justify="space-between">
                      <Grid item>
                          <Skeleton width={"30%"} height={30} />
                      </Grid>
                      <Grid item>
                          <Typography display="inline" variant="body2">
                              <Skeleton width={"20%"} height={30} />

                          </Typography>
                      </Grid>
                  </Grid>
              </CardActions>
          </Card>
      </SkeletonTheme>
  );
}
