import {
  FormControl,
  Grid,
  MenuItem,
  Select,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MultiValueChipInput from "../emailinput/MultiValueChipInput";
import MuiTextField from "../textfield/MuiTextField";
import _ from "lodash";
//import { makeStyles } from '@material-ui/styles';
import MuiNumberCounter from "../textfield/MuiNumberCounter";

// const useStyles = makeStyles(() => ({
//     spacing:{
//       marginBottom:"12px"
//     }
//   }));

type Props = {
  updatedAdvancedScheduling: (state: any) => void;
  schedulingData?: any;
};

function Scheduling(props: Props) {
  //const classes = useStyles();
  const [scheduling, setScheduling] = useState<any>({
    max_skew: 1,
    min_domains: 1,
    topology_key: "kubernetes.io/hostname",
    when_unsatisfiable: "DoNotSchedule",
    label_selector: {
      key: "app.kubernetes.io/provider",
      value: "zerone",
    },
    match_label_keys: "",
    node_affinity_policy: "Honor",
    node_taint_policy: "Honor",
  });

  useEffect(() => { 
    if (props.schedulingData && props.schedulingData.max_skew)
      setScheduling(props.schedulingData);
  }, []);
  useEffect(() => {
    props.updatedAdvancedScheduling(scheduling);
  }, [scheduling]);

  const handleTags = (data: string[]) => {
    setScheduling({
      ...scheduling,
      match_label_keys: data
    });
  };

  const handleChangeScheduler = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<{ value: unknown }>,
    type: string
  ) => {
    setScheduling({
      ...scheduling,
      [type]: e.target.value,
    });
  };

  const handleNumericChange = (val: number, type?: string | number) => {
    if (type) {
      setScheduling({
        ...scheduling,
        [type]: val,
      });
    }
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <Grid container spacing={2} alignItems="center" className="m-b-10">
            <Grid item md={4} xs={4}>
              <label>Maximum SKew</label>
            </Grid>
            <Grid item md={6} xs={8}>
              {/* <MuiTextField
                value={scheduling.max_skew}
                customClassName="oneRemMarginBottomSeperator"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeScheduler(e, "max_skew")
                }
                type="number"
              /> */}
              <MuiNumberCounter
                //data-test="min-replica-changer"
                // label={"min_replicas"}
                defaultValue={1}
                initialValue={scheduling.max_skew}
                //maxValue={this.state.max_replicas}
                isError={false}
                additionalData1="max_skew"
                updateValueChange={(val, name) => { handleNumericChange(val, name) } }
                updateIncomingValue={true}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center" className="m-b-10">
            <Grid item md={4} xs={4}>
              <label>Minimum Domains</label>
            </Grid>
            <Grid item md={6} xs={8}>
              <MuiNumberCounter
                //data-test="min-replica-changer"
                // label={"min_replicas"}
                defaultValue={1}
                initialValue={scheduling.min_domains}
                //maxValue={this.state.max_replicas}
                isError={false}
                additionalData1="min_domains"
                updateValueChange={(val, name) => { handleNumericChange(val, name) }}
                updateIncomingValue={true}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center" className="m-b-10">
            <Grid item md={4} xs={4}>
              <label>Topology Key</label>
            </Grid>
            <Grid item md={6} xs={8}>
              <MuiTextField
                value={scheduling.topology_key}
                //customClassName="oneRemMarginBottomSeperator"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeScheduler(e, "topology_key")
                }
                type="string"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center" className="m-b-10">
            <Grid item md={4} xs={4}>
              <label>When Unsatisfiable</label>
            </Grid>
            <Grid item md={6} xs={8}>
              <FormControl variant="outlined" className="w-100">
                <Select
                  labelId="demo-simple-select-outlined-label"
                  name="deletion_policy"
                  value={scheduling.when_unsatisfiable}
                  onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                    handleChangeScheduler(e, "when_unsatisfiable")
                  }
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                  }}
                >
                  <MenuItem value="DoNotSchedule">Do Not Schedule</MenuItem>
                  <MenuItem value="ScheduleAnyway">Schedule Any way</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems="center" className="m-b-10">
            <Grid item md={4} xs={4}>
              <label>Label Selector</label>
            </Grid>
            <Grid item md={6} xs={8}>
              <MuiTextField
                value={scheduling.label_selector.value}
                //customClassName="oneRemMarginBottomSeperator"
                // onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeScheduler(e, "label_selector") }
                type="string"
                helperText="Default filed can't be change"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems="center" className="m-b-10">
            <Grid item md={4} xs={4}>
              <label>Match Label Keys</label>
            </Grid>
            <Grid item md={6} xs={8}>
              <MultiValueChipInput
                values={_.without(
                  scheduling.match_label_keys &&
                    scheduling.match_label_keys,
                  ""
                )}
                handleValues={handleTags}
                placeholder="Type or paste KEYS values and press `Enter` ..."
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems="center" className="m-b-10">
            <Grid item md={4} xs={4}>
              <label>Node Affinity Policy</label>
            </Grid>
            <Grid item md={6} xs={8}>
              <FormControl variant="outlined" className="w-100">
                <Select
                  labelId="demo-simple-select-outlined-label"
                  name="deletion_policy"
                  value={scheduling.node_affinity_policy}
                  onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                    handleChangeScheduler(e, "node_affinity_policy")
                  }
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                  }}
                >
                  <MenuItem value="Honor">Honor</MenuItem>
                  <MenuItem value="Ignore">Ignore</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems="center">
            <Grid item md={4} xs={4}>
              <label>Node Taint Policy</label>
            </Grid>
            <Grid item md={6} xs={8}>
              <FormControl variant="outlined" className="w-100">
                <Select
                  labelId="demo-simple-select-outlined-label"
                  name="deletion_policy"
                  value={scheduling.node_taint_policy}
                  onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                    handleChangeScheduler(e, "node_taint_policy")
                  }
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                  }}
                >
                  <MenuItem value="Honor">Honor</MenuItem>
                  <MenuItem value="Ignore">Ignore</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Scheduling;
