import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Grid,
  DialogTitle,
  FormHelperText,
} from "@material-ui/core";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import AutoComplete from "../autocomplete/AutoComplete";
import { useTranslation } from "react-i18next";
import { validateInputField } from "../../helpers/utils";
import { MemberModel, RolesModel } from "../../models/Account.model";

type Props = {
  member: boolean;
  open: boolean;
  editMember: MemberModel;
  rolesData: Array<RolesModel>;
  handleDisagree: () => void;
  handleAgree: (email: string, role: string) => void;
};

export const AddMemberPopup = (props: Props) => {
  const [userEmail, setUserEmail] = useState(
    props.editMember && props.editMember.user ? props.editMember.user.email : ""
  );
  const [userRole, setUserRole] = useState(
    props.editMember ? props.editMember.user_role : "Select Role"
  );
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [roleError, setRoleError] = useState(false);
  const [roleErrorMessage, setRoleErrorMessage] = useState("");
  const [t] = useTranslation();
  let EmailInput = useRef<HTMLInputElement>();

  const handledefaultfocus = () => {
    setTimeout(function () {
      if (EmailInput?.current?.focus) {
        EmailInput.current.focus();
      }
    }, 200);
  };

  useEffect(() => {
    if (props.open) {
      handledefaultfocus();
    }
  }, []);

  const handleClose = () => {
    props.handleDisagree();
  };

  const handleAgree = () => {
    props.handleAgree(userEmail.trim(), userRole);
  };

  const handleUserEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let validation = validateInputField(e.target.value, "email");
    setEmailError(validation.error);
    setEmailErrorMessage(validation.error ? t(validation.message) : "");

    setUserEmail(e.target.value);
  };

  const emailChange = (val: string) => {
    setUserEmail(val);
  };

  const updateError = (error: boolean) => {
    setEmailError(error);
    // setEmailErrorMessage(message)
  };

  const handleUserEmailOnBlur = () => {
    if (userEmail.length === 0) {
      setEmailError(true);
      setEmailErrorMessage(t("AddDNSPopup.emptyEmailError"));
    }
  };

  const handleRoleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    if (e.target.value === "Select Role") {
      setRoleError(true);
      setRoleErrorMessage(t("AddDNSPopup.selectRole"));
      setUserRole(e?.target?.value);
    } else {
      setRoleError(false);
      setRoleErrorMessage("");
    }
    setUserRole(e.target.value as string);
  };

  const isDisabled = () => {
    if (emailError || userRole === "Select Role") {
      return true;
    }
    return false;
  };

  return (
    <Dialog
      disableEscapeKeyDown={true}
      open={props.open ?? false}
      //TransitionComponent={ transition }
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      keepMounted
      data-test="main-container"
    >
      <DialogTitle>
        <Typography className="dialogtitle" data-test="popup-title">
          {props.editMember ? t("Edit") : t("Add")}
        </Typography>

        <IconButton
          aria-label="close"
          size="small"
          className="right"
          onClick={handleClose}
          data-test="close-icon"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent style={{ minWidth: 275 }} dividers>
        <Grid data-test="content-container">
          <Grid container spacing={1}>
            <Grid item xs={8}>
              {props.member ? (
                <TextField
                  value={userEmail}
                  inputRef={EmailInput}
                  variant="outlined"
                  label={t("EmailAddress")}
                  style={{ marginRight: "1rem", width: "100%" }}
                  name="userEmail"
                  error={emailError}
                  helperText={emailErrorMessage}
                  onBlur={handleUserEmailOnBlur}
                  onChange={handleUserEmailChange}
                  disabled={props.editMember && props.editMember.id > 0}
                  data-test="member-field"
                />
              ) : (
                <AutoComplete
                  email={userEmail}
                  setEmail={emailChange}
                  updateError={updateError}
                  disabled={props.editMember && props.editMember.id > 0}
                  data-test="email-field"
                />
              )}
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: "100%" }}>
                <Select
                  variant="outlined"
                  color="primary"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={userRole}
                  onChange={(e) => handleRoleChange(e)}
                  error={roleError}
                  //helperText={ roleErrorMessage }
                  data-test="roles-field"
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                  }}
                >
                  <MenuItem value="Select Role">{t("Role")}</MenuItem>
                  {props.rolesData?.map((roles) => (
                    <MenuItem key={roles.id} value={roles.id}>
                      {roles.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={roleError}>
                  {roleErrorMessage}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleAgree}
          color="primary"
          variant="contained"
          disabled={isDisabled()}
          data-test="action-button"
        >
          {props.editMember ? t("Update") : t("Add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default connect(null, null)(AddMemberPopup);
