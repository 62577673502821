const PREFIX = '@UPDATE_PROFILE';
const ACCESS_TOKEN = '@ACCESS_TOKEN';

export const UPDATE_PROFILE_CALL = `${ PREFIX }/UPADATEPROFILE`
export const UPDATE_PASSWORD = `${ PREFIX }/UPDATE_PASSWORD`
export const DEACTIVATE_ACCOUNT = `${ PREFIX }/DEACTIVATE_ACCOUNT`

export const GET_ACCOUNT_LIST = `${ PREFIX }/GET_ACCOUNT_LIST`
export const GET_ACCOUNT_LIST_SUCCESS = `${ PREFIX }/GET_ACCOUNT_LIST_SUCCESS`
export const GET_ACCOUNT_LIST_FALIURE = `${ PREFIX }/GET_ACCOUNT_LIST_FALIURE`

export const UNLINK_ACCOUNT = `${ PREFIX }/UNLINK_ACCOUNT`
export const UNLINK_ACCOUNT_SUCCESS = `${ PREFIX }/UNLINK_ACCOUNT_SUCCESS`
export const UNLINK_ACCOUNT_FALIURE = `${ PREFIX }/UNLINK_ACCOUNT_FALIURE`

export const CLEAR_ACCOUNT_LIST = `${ PREFIX }/CLEAR_ACCOUNT_LIST`

//for access token
export const GET_ACCESS_TOKEN_LIST = `${ACCESS_TOKEN}/GET_ACCESS_TOKEN_LIST`
export const GET_ACCESS_TOKEN_LIST_SUCCESS = `${ACCESS_TOKEN}/GET_ACCESS_TOKEN_LIST_SUCCESS`
export const GET_ACCESS_TOKEN_LIST_FAILURE = `${ACCESS_TOKEN}/GET_ACCESS_TOKEN_LIST_FAILURE`
export const CREATE_ACCESS_TOKEN = `${ACCESS_TOKEN}/CREATE_ACCESS_TOKEN`
export const CREATE_ACCESS_TOKEN_SUCCESS = `${ACCESS_TOKEN}/CREATE_ACCESS_TOKEN_SUCCESS`
export const CREATE_ACCESS_TOKEN_FAILURE = `${ACCESS_TOKEN}/CREATE_ACCESS_TOKEN_FAILURE`
export const DELELE_ACCESS_TOKEN = `${ACCESS_TOKEN}/DELETE_ACCESS_TOKEN`
export const DELELE_ACCESS_TOKEN_SUCCESS = `${ACCESS_TOKEN}/DELELE_ACCESS_TOKEN_SUCCESS`
export const DELELE_ACCESS_TOKEN_FAILURE = `${ACCESS_TOKEN}/DELELE_ACCESS_TOKEN_FAILURE`
export const CLEAR_ACCESS_TOKEN = `${ACCESS_TOKEN}/CLEAR_ACCESS_TOKEN`
export const DELETE_ALL_ACCESS_TOKEN = `${ACCESS_TOKEN}/DELETE_ALL_ACCESS_TOKEN`

const updateProfileDetails = (payload) => ({
	type: UPDATE_PROFILE_CALL,
	data: {
		payload,
	}
})

const updatePassword = (payload) => ({
	type: UPDATE_PASSWORD,
	data: { payload }
})

const deactivateAccount = () => ({
	type: DEACTIVATE_ACCOUNT
})

const getAccountsList = () => ({
	type: GET_ACCOUNT_LIST
})

const clearAccountList = () => ({
	type: CLEAR_ACCOUNT_LIST
})

const unlinkAccount = (id) => ({
	type: UNLINK_ACCOUNT,
	data: {
		id
	}
})

//for access token
const getAccessTokenList = () => ({
	type: GET_ACCESS_TOKEN_LIST,
})

const createAccessToken = (jsonBody) => ({
	type: CREATE_ACCESS_TOKEN,
	data: {jsonBody}
})

const deleteAccessToken = (id) => ({
	type: DELELE_ACCESS_TOKEN,
	data: {id}
})

const clearAccessToken = () => ({
	type: CLEAR_ACCESS_TOKEN,
})

const deleteAllTokens = () => ({
	type: DELETE_ALL_ACCESS_TOKEN,
})

export {
	updateProfileDetails,
	updatePassword,
	deactivateAccount,
	getAccountsList,
	unlinkAccount,
	clearAccountList,
	getAccessTokenList,
	createAccessToken,
	deleteAccessToken,
	clearAccessToken,
	deleteAllTokens,
}