import axios from "axios";
import { put, select, takeLatest } from "redux-saga/effects";
import Toast from "../../../components/toast/Toast";
import endpoints from "../../../constants/endpoints";
import { sessionTokenSelector } from "../../login/redux/selectors";
import {
  ASSIGN_USER_TICKETS,
  ASSIGN_USER_TICKETS_FAILURE,
  ASSIGN_USER_TICKETS_SUCCESS,
  CREATE_ISSUE_TYPE,
  CREATE_ISSUE_TYPE_FAILURE,
  CREATE_ISSUE_TYPE_SUCCESS,
  CREATE_TICKET_GROUP,
  CREATE_TICKET_GROUP_FAILURE,
  CREATE_TICKET_GROUP_SUCCESS,
  CREATE_USER_TICKETS,
  CREATE_USER_TICKETS_FAILURE,
  CREATE_USER_TICKETS_SUCCESS,
  DELETE_TICKET,
  DELETE_TICKET_FAILURE,
  DELETE_TICKET_SUCCESS,
  GET_ISSUE_TYPES,
  GET_ISSUE_TYPES_FAILURE,
  GET_ISSUE_TYPES_SUCCESS,
  GET_SUPPORT_TYPES,
  GET_SUPPORT_TYPES_FAILURE,
  GET_SUPPORT_TYPES_SUCCESS,
  GET_TICKET_DETAIL,
  GET_TICKET_DETAIL_FAILURE,
  GET_TICKET_DETAIL_SUCCESS,
  GET_TICKET_GROUP,
  GET_TICKET_GROUP_FAILURE,
  GET_TICKET_GROUP_SUCCESS,
  GET_USER_TICKETS,
  GET_MORE_USER_TICKETS_SUCCESS,
  GET_USER_TICKETS_FAILURE,
  GET_USER_TICKETS_SUCCESS,
  REPLY_ISSUE,
  REPLY_ISSUE_FAILURE,
  REPLY_ISSUE_SUCCESS,
  UPDATE_TICKET,
  UPDATE_TICKET_FAILURE,
  UPDATE_TICKET_SUCCESS,
} from "./actions";

function* getSupportTypes() {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = { headers: { Authorization: "basic " + sessionToken } };
    const response = yield axios.get(
      endpoints.SUPPORT.GET_SUPPORT_TYPES,
      config
    );

    const data = response.data;
    if (data) {
      yield put({ type: GET_SUPPORT_TYPES_SUCCESS, data });
    } else {
      yield put({ type: GET_SUPPORT_TYPES_FAILURE });
      Toast.error(response.message ?? "Failed to fetch Support Types");
    }
  } catch (error) {
    yield put({ type: GET_SUPPORT_TYPES_FAILURE });
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    ) {
      Toast.error(error.response.data.error);
    }
  }
}

function* getIssueTypes() {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = { headers: { Authorization: "basic " + sessionToken } };
    const response = yield axios.get(endpoints.SUPPORT.GET_ISSUE_TYPES, config);

    const data = response.data;
    if (data) {
      yield put({ type: GET_ISSUE_TYPES_SUCCESS, data });
    } else {
      yield put({ type: GET_ISSUE_TYPES_FAILURE });
      Toast.error(response.message ?? "Failed to fetch issue Types");
    }
  } catch (error) {
    yield put({ type: GET_ISSUE_TYPES_FAILURE });
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    ) {
      Toast.error(error.response.data.error);
    }
  }
}

function* getTickets(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = {
      headers: { Authorization: "basic " + sessionToken },
      params: {
        page: parseInt(payload.data.pageNumber),
        limit: Number(payload.data.limit),
        query: payload.data.search ?? "",
        status: payload.data.status ?? "",
        priority: payload.data.priority ?? "",
        category: payload.data.category ? parseInt(payload.data.category) : 0,
      },
    };

    const response = yield axios.get(
      endpoints.SUPPORT.GET_USER_TICKETS,
      config
    );

    const data = response.data;
    if (data) {
      if (payload.data?.pageNumber === 1) {
        yield put({ type: GET_USER_TICKETS_SUCCESS, data });
      } else {
        yield put({ type: GET_MORE_USER_TICKETS_SUCCESS, data });
      }
    } else {
      yield put({ type: GET_USER_TICKETS_FAILURE });
      Toast.error(response.message ?? "Failed to fetch user Tickets");
    }
  } catch (error) {
    yield put({ type: GET_USER_TICKETS_FAILURE });
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    ) {
      Toast.error(error.response.data.error);
    }
  }
}

function* getTicketDetail(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = { headers: { Authorization: "basic " + sessionToken } };
    const response = yield axios.get(
      endpoints.SUPPORT.GET_TICKET_DETAIL.replace(":id", payload.data.id),
      config
    );

    const data = response.data;
    if (data) {
      yield put({ type: GET_TICKET_DETAIL_SUCCESS, data });
    } else {
      yield put({ type: GET_TICKET_DETAIL_FAILURE });
      Toast.error(response.message ?? "Failed to fetch user ticket detail");
    }
  } catch (error) {
    yield put({ type: GET_TICKET_DETAIL_FAILURE });
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    ) {
      Toast.error(error.response.data.error);
    }
  }
}

function* getTicketGroups() {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = { headers: { Authorization: "basic " + sessionToken } };
    const response = yield axios.get(
      endpoints.SUPPORT.GET_TICKET_GROUP,
      config
    );

    const data = response.data;
    if (data) {
      yield put({ type: GET_TICKET_GROUP_SUCCESS, data });
    } else {
      yield put({ type: GET_TICKET_GROUP_FAILURE });
      Toast.error(response.message ?? "Failed to fetch ticket groups");
    }
  } catch (error) {
    yield put({ type: GET_TICKET_GROUP_FAILURE });
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    ) {
      Toast.error(error.response.data.error);
    }
  }
}

function* createTicket(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = {
      headers: {
        Authorization: "basic " + sessionToken,
        "Content-Type": "application/json",
        // "x-user-id": payload?.data?.payload?.ticket?.userId,
      },
    };
    const response = yield axios.post(
      endpoints.SUPPORT.CREATE_SUPPORT_TICKET,
      payload.data.payload,
      config
    );
    // const data = response.data;
    if (response.status === 200 || response.status === 201) {
      yield put({ type: CREATE_USER_TICKETS_SUCCESS });
      Toast.success(response.message ?? "Support ticket created successfully");
      if (payload.data.callback) {
        payload.data.callback(response.data?.success?.ticket);
      }
    } else {
      yield put({ type: CREATE_USER_TICKETS_FAILURE });
      Toast.error(response.message ?? "Support ticket creation failed");
    }
  } catch (error) {
    yield put({ type: CREATE_USER_TICKETS_FAILURE });
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    ) {
      Toast.error(error.response.data.error);
    }
  }
}

function* updateTicket(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = {
      headers: {
        Authorization: "basic " + sessionToken,
        "Content-Type": "application/json",
        // "x-user-id": payload?.data?.payload?.ticket?.userId,
      },
    };
    const response = yield axios.put(
      endpoints.SUPPORT.UPDATE_SUPPORT_TICKET.replace(":id", payload.data.id),
      payload.data.payload,
      config
    );
    // const data = response.data;
    if (response.status === 200 || response.status === 201) {
      yield put({ type: UPDATE_TICKET_SUCCESS });
      Toast.success(response.message ?? "Support ticket updated successfully");
      yield put({ type: GET_TICKET_DETAIL, data: { id: payload.data.id } });
    } else {
      yield put({ type: UPDATE_TICKET_FAILURE });
      Toast.error(response.message ?? "Support ticket updated failed");
    }
  } catch (error) {
    yield put({ type: UPDATE_TICKET_FAILURE });
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    ) {
      Toast.error(error.response.data.error);
    }
  }
}

function* deleteTicket(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = {
      headers: {
        Authorization: "basic " + sessionToken,
        // "x-user-id": payload?.data?.payload?.ticket?.userId,
      },
    };
    const response = yield axios.delete(
      endpoints.SUPPORT.DELETE_TICKET.replace(":id", payload.data.id),
      config
    );
    // const data = response.data;
    if (response.status === 200 || response.status === 201) {
      yield put({ type: DELETE_TICKET_SUCCESS });
      Toast.success(response.message ?? "Support ticket deleted successfully");
      // yield put({
      //   type: GET_USER_TICKETS,
      //   data: { pageNumber: 1, limit: 20, status: "Open", search: "" },
      // });
      if (payload.data.callback) {
        payload.data.callback();
      }
    } else {
      yield put({ type: DELETE_TICKET_FAILURE });
      Toast.error(response.message ?? "Support ticket deletion failed");
    }
  } catch (error) {
    yield put({ type: DELETE_TICKET_FAILURE });
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    ) {
      Toast.error(error.response.data.error);
    }
  }
}

function* createIssueType(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = {
      headers: {
        Authorization: "basic " + sessionToken,
        "Content-Type": "application/json",
      },
    };
    const response = yield axios.post(
      endpoints.SUPPORT.CREATE_ISSUE_TYPE,
      payload.data.payload,
      config
    );

    const data = response.data;
    if (data) {
      yield put({ type: CREATE_ISSUE_TYPE_SUCCESS });
      Toast.success(response.message ?? "Issue Type created successfully");
    } else {
      yield put({ type: CREATE_ISSUE_TYPE_FAILURE });
      Toast.error(response.message ?? "Issue Type creation failed");
    }
  } catch (error) {
    yield put({ type: CREATE_ISSUE_TYPE_FAILURE });
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    ) {
      Toast.error(error.response.data.error);
    }
  }
}

function* createTicketGroup(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = { headers: { Authorization: "basic " + sessionToken } };
    const response = yield axios.post(
      endpoints.SUPPORT.CREATE_TICKET_GROUP,
      payload.data.payload,
      config
    );

    const data = response.data;
    if (data) {
      yield put({ type: CREATE_TICKET_GROUP_SUCCESS });
      Toast.success(response.message ?? "Ticket Group created successfully");
    } else {
      yield put({ type: CREATE_TICKET_GROUP_FAILURE });
      Toast.error(response.message ?? "Ticket Group creation failed");
    }
  } catch (error) {
    yield put({ type: CREATE_TICKET_GROUP_FAILURE });
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    ) {
      Toast.error(error.response.data.error);
    }
  }
}

function* assignTicket(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = { headers: { Authorization: "basic " + sessionToken } };
    const response = yield axios.post(
      endpoints.SUPPORT.ASSIGN_TICKET,
      payload.data.payload,
      config
    );

    const data = response.data;
    if (data) {
      yield put({ type: ASSIGN_USER_TICKETS_SUCCESS });
      Toast.success(response.message ?? "Ticket assigned successfully");
    } else {
      yield put({ type: ASSIGN_USER_TICKETS_FAILURE });
      Toast.error(response.message ?? "Ticket assign failed");
    }
  } catch (error) {
    yield put({ type: ASSIGN_USER_TICKETS_FAILURE });
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    ) {
      Toast.error(error.response.data.error);
    }
  }
}

function* replyTicket(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = { headers: { Authorization: "basic " + sessionToken } };
    const response = yield axios.post(
      endpoints.SUPPORT.REPLY_ISSUE,
      payload.data.payload,
      config
    );
    if (response.status === 200 || response.status === 201) {
      yield put({ type: REPLY_ISSUE_SUCCESS });
      if(payload.data?.callback){
        payload.data.callback()
      }else{
        Toast.success(response.message ?? "Replied successfully");
        if (payload.data?.payload?.ticketId) {
          yield put({
            type: GET_TICKET_DETAIL,
            data: {
              id: payload.data?.payload?.ticketId,
            },
          });
        } else {
          Toast.error("Couldn't fetch updated data");
        }
      }
    } else {
      yield put({ type: REPLY_ISSUE_FAILURE });
      Toast.error(response.message ?? "Failed to reply");
    }
  } catch (error) {
    yield put({ type: REPLY_ISSUE_FAILURE });
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    ) {
      Toast.error(error.response.data.error);
    }
  }
}

export default function* watcherSaga() {
  yield takeLatest(GET_USER_TICKETS, getTickets);
  yield takeLatest(DELETE_TICKET, deleteTicket);
  yield takeLatest(UPDATE_TICKET, updateTicket);
  yield takeLatest(CREATE_USER_TICKETS, createTicket);
  yield takeLatest(CREATE_ISSUE_TYPE, createIssueType);
  yield takeLatest(CREATE_TICKET_GROUP, createTicketGroup);
  yield takeLatest(GET_TICKET_GROUP, getTicketGroups);
  yield takeLatest(ASSIGN_USER_TICKETS, assignTicket);
  yield takeLatest(REPLY_ISSUE, replyTicket);
  yield takeLatest(GET_TICKET_DETAIL, getTicketDetail);
  yield takeLatest(GET_SUPPORT_TYPES, getSupportTypes);
  yield takeLatest(GET_ISSUE_TYPES, getIssueTypes);
}
