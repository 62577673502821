import React from 'react'
import { useTranslation } from "react-i18next";
import {
    Grid,
    Card,
    CardContent,
    Select,
    FormControl,
    MenuItem,
    InputLabel,
    Typography,
    Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MuiTextField from '../textfield/MuiTextField';
import DeploymentFormBody from './DeploymentFormBody';

const useStyles = makeStyles(() => ({
    logo: {
        height: "12vh",
        width: "auto",
        objectFit: "cover",
        padding: "10px 0",
        align: "center",
    },
}));

export default function HelmEnvBasicFields({
    releaseName,
    releaseNameErrorMessage,
    appDetails,
    handleReleaseNameChange,
    versionChangeHandler,
    selectedVersion,
    selected,
    chartVersions,
    appValues,
    setValuesModifiedTrue,
    deploymentEvent,
    handleValuesChange,
    deployedValues,
}) {
    const [t] = useTranslation()
    const classes = useStyles()
    return (
        <Grid item xs={12} data-test="form-container">
            <Card className={`w-100`}>
                <CardContent>
                    <Grid container direction="column" spacing={2}>
                        <Grid item xs={12} data-test="image-container">
                            <div className="alignCenter">
                                <img
                                    src={appDetails?.chart?.Icon ?? "/images/icons/defaultcatalog.png"}
                                    alt={appDetails?.chart?.Name ?? "Chart"}
                                    className={classes.logo}
                                    data-test="chart-image"
                                />
                            </div>
                            <div className="alignCenter m-b-10">
                                <Typography variant="h5" data-test="chart-name">
                                    {appDetails?.chart?.Name}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <MuiTextField
                                //variant="outlined"
                                //color='primary'
                                error={releaseNameErrorMessage?.length > 0}
                                helperText={releaseNameErrorMessage}
                                //required
                                autoFocus
                                label={t("ResourcePaper.environmentName")}
                                //onBlur={handleEnvironmentNameOnBlur}
                                onChange={handleReleaseNameChange}
                                value={releaseName}
                                data-test="name-field"
                                disabled={deploymentEvent === "upgrade"}
                            //data-test="environment-field"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <div className="basic-deployment-form-param">
                                <FormControl
                                    variant="outlined"
                                    className="w-100"
                                    data-test="env-version-select"
                                >
                                    <InputLabel id="demo-simple-select-outlined-label">
                                        {t("Environment.CreateAppMaster.version")}
                                    </InputLabel>
                                    <Select
                                        //data-test="version-select"
                                        color="primary"
                                        //labelId="demo-simple-select-label"
                                        labelId="demo-simple-select-outlined-label"
                                        //id="demo-simple-select"
                                        value={selectedVersion}
                                        //gutterButtom
                                        //className=""
                                        onChange={versionChangeHandler}
                                        MenuProps={{
                                            getContentAnchorEl: null,
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left",
                                            },
                                        }}
                                        label={t("Environment.CreateAppMaster.version")}
                                        data-test="version-field"
                                    >
                                        {(!chartVersions ||
                                            !chartVersions.chartVersions ||
                                            chartVersions.chartVersions.length === 0) && (
                                                <MenuItem value={"Version"}>
                                                    {t("Environment.CreateAppMaster.version")}
                                                </MenuItem>
                                            )}
                                        {chartVersions &&
                                            chartVersions.chartVersions?.map(
                                                (versionDetails, ind) => (
                                                    <MenuItem value={versionDetails.id} key={ind}>
                                                        {versionDetails.version}
                                                    </MenuItem>
                                                )
                                            )}
                                        {/* <MenuItem value={"test"}>
                                            test
                                        </MenuItem> */}
                                    </Select>
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            {
                                selected && selected.values &&
                                <>
                                    <Divider />
                                    <DeploymentFormBody
                                        deploymentEvent={deploymentEvent}
                                        //chartID={chartID}
                                        //chartVersion={chartVersion}
                                        //chartsIsFetching={chartsIsFetching}
                                        selected={selected}
                                        setValues={handleValuesChange}
                                        appValues={appValues}
                                        setValuesModified={setValuesModifiedTrue}
                                        deployedValues={deployedValues}
                                    />
                                </>
                            }
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    )
}
