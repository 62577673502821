import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
// import DeploymentForm from '../../components/deploymentformbody/DeploymentForm'
import UpgradeForm from '../../components/deploymentformbody/UpgradeForm'
import BackdropLoader from '../../components/loader/BackdropLoader'
import { fetchHelmEnvDetails } from './redux/actions'

export const EditHelmEnvironment = (props) => {
    const [t] = useTranslation()
    useEffect(() => {
        if (props?.match?.params?.eId) {
            props.fetchHelmEnvDetails(props.match.params.eId)
        }
    }, [props.match?.params?.eId])

    return (
        <div>
            {
                props.helmEnvironmentDetails &&
                <UpgradeForm
                    history={props.history}
                    appDetails={props.helmEnvironmentDetails?.application}
                    edit={true}
                    environmentDetails={props.helmEnvironmentDetails}
                    appCurrentValues={props.helmEnvironmentDetails?.values}
                />
            }
            {/* <DeploymentForm
                history={props.history}
                appDetails={props.helmEnvironmentDetails?.application}
                edit={true}
                environmentDetails={props.helmEnvironmentDetails}
            /> */}
            {props.fetchingHelmEnv && <BackdropLoader message={t('HelmEnvironment.fetchingEnvDetails')} />}
        </div>
    )
}

/* istanbul ignore next */
const mapStateToProps = (state) => ({
    helmEnvironmentDetails: state.HelmEnvironmentReducer.helmEnvironmentDetails,
    fetchingHelmEnv: state.HelmEnvironmentReducer.fetchingHelmEnv,
})

/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
    fetchHelmEnvDetails: (id) => dispatch(fetchHelmEnvDetails(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditHelmEnvironment)
