import { Grid } from '@material-ui/core';
import React from 'react';

//xmlns:xlink="http://www.w3.org/1999/xlink"
//xml:space="preserve"
export function OfflineSvg() {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            viewBox="0px 0px 500px 500px" style={{ enableBackground: 'new 0 0 500 500', overflow: 'visible' }}
            data-test="svg"
        >
            <style type="text/css">
                {'.st0 { display:none }'}
                {'.st1 { display:inline; fill:#D5E9F2 }'}
                {'.st2 { fill:#F2F2F2 }'}
                {'.st3 { opacity:0.38px }'}
                {'.st4 { opacity:0.35px }'}
                {'.st5 { opacity:0.42px; fill:#F2F2F2 }'}
                {'.st6 { fill:#2F2E41 }'}
                {'.st7 { fill:#FFFFFF }'}
                {'.st8 { fill:#3F3D56 }'}
                {'.st9 { fill:#0057FA }'}
                {'.st10 { fill:none; stroke:#3F3D56; stroke-width:2px; stroke-miterlimit:10px }'}
            </style>
            <g id="BACKGROUND" className="st0">
                <rect className="st1" width="500" height="500" />
            </g>
            <g id="OBJECTS">
                <g>
                    <g>
                        <path className="st2" d="M257,210.1" />
                        <g className="st3">
                            <path className="st2" d="M150.2,275.9c-5.9,4.6-1.3,1.1,0.2,0c2.6-1.8,5.2-3.5,7.9-5.1c4.2-2.5,8.4-4.8,12.8-7
                                c2.6-1.3,5.2-2.5,7.8-3.7c1-0.4,5.5-2.6,1.4-0.7c1.7-0.8,3.5-1.4,5.2-2.1c6.7-2.6,13.5-4.8,20.4-6.7c4-1.1,8-2,12-2.8
                                c1.9-0.4,3.8-0.7,5.7-1.1c0.8-0.1,1.6-0.3,2.3-0.4c0.8-0.1,1.6-0.2,2.4-0.4c-1.8,0.2-1.9,0.2-0.2,0c8.9-1,17.8-1.6,26.7-1.4
                                c4.8,0.1,9.5,0.4,14.2,0.9c1.2,0.1,2.3,0.3,3.5,0.4c4.5,0.5-1.6-0.3,1.1,0.1c2.9,0.4,5.7,0.9,8.6,1.5
                                c26.7,5.3,49.5,15.6,72.2,30.6c6.5,4.3,15.9,1.3,19.7-5.2c4.1-7,1.3-15.4-5.2-19.7c-39.6-26.2-86.9-41-134.6-36.7
                                c-30.7,2.8-60.7,12.1-87.4,27.5c-6,3.4-11.7,7.2-17.1,11.5c-6.1,4.8-5,15.4,0,20.4C135.8,282,144,280.7,150.2,275.9L150.2,275.9
                                z"
                            />
                        </g>
                        <g className="st4">
                            <path className="st2" d="M195.2,328.8c3.2-2.4,6.7-4.5,10.1-6.4c1.9-1,3.8-2,5.8-3c0.8-0.4,1.6-0.8,2.4-1.2c0.8-0.4,4.8-2.1,1-0.5
                                c5-2.1,10.2-3.8,15.4-5.2c2.7-0.7,5.4-1.3,8.2-1.9c0.6-0.1,5.9-0.9,2.4-0.5c1.7-0.2,3.4-0.4,5.1-0.5c3.1-0.2,6.2-0.3,9.3-0.3
                                c1.8,0,3.7,0.1,5.5,0.3c0.5,0,6,0.7,3.2,0.3c16.5,2.5,29.2,8.6,42.7,18.8c6.2,4.7,16.1,1,19.7-5.2c4.3-7.4,1.1-15-5.2-19.7
                                c-27.8-20.9-62.6-28-96.4-19.8c-15.2,3.7-31.3,10.2-43.9,19.8c-6.2,4.7-9.4,12.4-5.2,19.7C179,329.7,189,333.5,195.2,328.8
                                L195.2,328.8z"
                            />
                        </g>
                        <circle className="st5" cx="252.3" cy="373.6" r="27.6" />
                    </g>
                    <path className="st2" d="M255.5,205.9" />
                    <g>
                        <path className="st2" d="M148.6,271.7c-5.9,4.6-1.3,1.1,0.2,0c2.6-1.8,5.2-3.5,7.9-5.1c4.2-2.5,8.4-4.8,12.8-7
                            c2.6-1.3,5.2-2.5,7.8-3.7c1-0.4,5.5-2.6,1.4-0.7c1.7-0.8,3.5-1.4,5.2-2.1c6.7-2.6,13.5-4.8,20.4-6.7c4-1.1,8-2,12-2.8
                            c1.9-0.4,3.8-0.7,5.7-1.1c0.8-0.1,1.6-0.3,2.3-0.4c0.8-0.1,1.6-0.2,2.4-0.4c-1.8,0.2-1.9,0.2-0.2,0c8.9-1,17.8-1.6,26.7-1.4
                            c4.8,0.1,9.5,0.4,14.2,0.9c1.2,0.1,2.3,0.3,3.5,0.4c4.5,0.5-1.6-0.3,1.1,0.1c2.9,0.4,5.7,0.9,8.6,1.5
                            c26.7,5.3,49.5,15.6,72.2,30.6c6.5,4.3,15.9,1.3,19.7-5.2c4.1-7,1.3-15.4-5.2-19.7c-39.6-26.2-86.9-41-134.6-36.7
                            c-30.7,2.8-60.7,12.1-87.4,27.5c-6,3.4-11.7,7.2-17.1,11.5c-6.1,4.8-5,15.4,0,20.4C134.3,277.8,142.4,276.5,148.6,271.7
                            L148.6,271.7z"
                        />
                    </g>
                    <g>
                        <path className="st2" d="M193.6,324.6c3.2-2.4,6.7-4.5,10.1-6.4c1.9-1,3.8-2,5.8-3c0.8-0.4,1.6-0.8,2.4-1.2c0.8-0.4,4.8-2.1,1-0.5
                            c5-2.1,10.2-3.8,15.4-5.2c2.7-0.7,5.4-1.3,8.2-1.9c0.6-0.1,5.9-0.9,2.4-0.5c1.7-0.2,3.4-0.4,5.1-0.5c3.1-0.2,6.2-0.3,9.3-0.3
                            c1.8,0,3.7,0.1,5.5,0.3c0.5,0,6,0.7,3.2,0.3c16.5,2.5,29.2,8.6,42.7,18.8c6.2,4.7,16.1,1,19.7-5.2c4.3-7.4,1.1-15-5.2-19.7
                            c-27.8-20.9-62.6-28-96.4-19.8c-15.2,3.7-31.3,10.2-43.9,19.8c-6.2,4.7-9.4,12.4-5.2,19.7C177.5,325.5,187.4,329.3,193.6,324.6
                            L193.6,324.6z"
                        />
                    </g>
                    <circle className="st2" cx="250.8" cy="369.4" r="27.6" />
                    <circle className="st6" cx="259.7" cy="170.1" r="38.3" />

                    <rect x="272.5" y="197.5" transform="matrix(0.984 -0.1781 0.1781 0.984 -32.5827 52.907)" className="st6" width="11.6" height="20.9" />

                    <rect x="249.6" y="201.6" transform="matrix(0.984 -0.1781 0.1781 0.984 -33.6889 48.8938)" className="st6" width="11.6" height="20.9" />

                    <ellipse transform="matrix(0.7631 -0.6463 0.6463 0.7631 -84.867 218.269)" className="st6" cx="255.3" cy="224.9" rx="9.7" ry="3.6" />

                    <ellipse transform="matrix(0.7631 -0.6463 0.6463 0.7631 -76.9282 231.8049)" className="st6" cx="277.7" cy="220.8" rx="9.7" ry="3.6" />
                    <circle className="st7" cx="257" cy="160.8" r="13.1" />
                    <circle className="st8" cx="251.6" cy="156.1" r="4.4" />
                    <path className="st9" d="M289.9,128.4c0.6-14.2-11.7-26.2-27.3-26.8c-15.7-0.6-28.8,10.3-29.4,24.5c-0.6,14.2,10.3,17.4,26,18
                        C274.8,144.8,289.3,142.6,289.9,128.4z"
                    />

                    <ellipse transform="matrix(0.2234 -0.9747 0.9747 0.2234 15.1609 345.3226)" className="st6" cx="224.3" cy="163.1" rx="6" ry="19.2" />

                    <ellipse transform="matrix(0.2234 -0.9747 0.9747 0.2234 61.3824 428.3556)" className="st6" cx="299.5" cy="175.7" rx="6" ry="19.2" />
                    <path className="st7" d="M270.2,185.2c0.9,4.7-2.3,9.3-7,10.1c-4.7,0.9-9.3-2.3-10.1-7c-0.9-4.7,2.8-6.4,7.6-7.3
                        S269.3,180.5,270.2,185.2z"
                    />
                    <line className="st10" x1="165.8" y1="126.2" x2="210" y2="160.2" />
                    <circle className="st2" cx="157" cy="118.1" r="16.5" />
                    <circle className="st2" cx="351.3" cy="118" r="8.2" />
                </g>
            </g>
            <g id="DESIGNED_BY_FREEPIK">
            </g>
        </svg>
    );
}

export default function OfflineContent() {
    return (
        <div data-test="main-container">
            <Grid container
                direction="column"
                justify="center"
                alignItems="center"
                spacing={3}
            >
                <Grid item style={{ minHeight: 410, marginLeft: -199 }}>
                    {/* <WifiOffIcon color="inherit" style={{ fontSize: 100 }} /> */}
                    <OfflineSvg />
                </Grid>
                <Grid item>
                    Internet connection lost
                </Grid>
            </Grid>
        </div>
    );
}