const ENVIRONMENT_PREFIX = "HELM_ENVIRONMENT"
export const GET_CHART_VERSIONS = `${ENVIRONMENT_PREFIX}/GET_CHART_VERSIONS`
export const GET_CHART_VERSIONS_SUCCESS = `${ENVIRONMENT_PREFIX}/GET_CHART_VERSIONS_SUCCESS`
export const GET_CHART_VERSIONS_FAILURE = `${ENVIRONMENT_PREFIX}/GET_CHART_VERSIONS_FAILURE`

export const CREATE_HELM_ENVIRONMENT = `${ENVIRONMENT_PREFIX}/CREATE_HELM_ENVIRONMENT`
export const CREATE_HELM_ENVIRONMENT_SUCCESS = `${ENVIRONMENT_PREFIX}/CREATE_HELM_ENVIRONMENT_SUCCESS`
export const CREATE_HELM_ENVIRONMENT_FAILURE = `${ENVIRONMENT_PREFIX}/CREATE_HELM_ENVIRONMENT_FAILURE`

export const FETCH_HELM_ENVIRONMENT_DETAIL = `${ENVIRONMENT_PREFIX}/FETCH_HELM_ENVIRONMENT_DETAIL`
export const FETCH_HELM_ENVIRONMENT_DETAIL_SUCCESS = `${ENVIRONMENT_PREFIX}/FETCH_HELM_ENVIRONMENT_DETAIL_SUCCESS`
export const FETCH_HELM_ENVIRONMENT_DETAIL_FAILURE = `${ENVIRONMENT_PREFIX}/FETCH_HELM_ENVIRONMENT_DETAIL_FAILURE`

export const DELETE_HELM_ENVIRONMENT = `${ENVIRONMENT_PREFIX}/DELETE_HELM_ENVIRONMENT`
export const DELETE_HELM_ENVIRONMENT_SUCCESS = `${ENVIRONMENT_PREFIX}/DELETE_HELM_ENVIRONMENT_SUCCESS`
export const DELETE_HELM_ENVIRONMENT_FAILURE = `${ENVIRONMENT_PREFIX}/DELETE_HELM_ENVIRONMENT_FAILURE`

export const UPDATE_HELM_ENVIRONMENT = `${ENVIRONMENT_PREFIX}/UPDATE_HELM_ENVIRONMENT`
export const UPDATE_HELM_ENVIRONMENT_SUCCESS = `${ENVIRONMENT_PREFIX}/UPDATE_HELM_ENVIRONMENT_SUCCESS`
export const UPDATE_HELM_ENVIRONMENT_FAILURE = `${ENVIRONMENT_PREFIX}/UPDATE_HELM_ENVIRONMENT_FAILURE`

export const START_HELM_ENVIRONMENT = `${ENVIRONMENT_PREFIX}/START_HELM_ENVIRONMENT`
export const START_HELM_ENVIRONMENT_SUCCESS = `${ENVIRONMENT_PREFIX}/START_HELM_ENVIRONMENT_SUCCESS`
export const START_HELM_ENVIRONMENT_FAILURE = `${ENVIRONMENT_PREFIX}/START_HELM_ENVIRONMENT_FAILURE`

export const STOP_HELM_ENVIRONMENT = `${ENVIRONMENT_PREFIX}/STOP_HELM_ENVIRONMENT`
export const STOP_HELM_ENVIRONMENT_SUCCESS = `${ENVIRONMENT_PREFIX}/STOP_HELM_ENVIRONMENT_SUCCESS`
export const STOP_HELM_ENVIRONMENT_FAILURE = `${ENVIRONMENT_PREFIX}/STOP_HELM_ENVIRONMENT_FAILURE`

export const LAUNCH_HELM_ENVIRONMENT = `${ENVIRONMENT_PREFIX}/LAUNCH_HELM_ENVIRONMENT`
export const LAUNCH_HELM_ENVIRONMENT_SUCCESS = `${ENVIRONMENT_PREFIX}/LAUNCH_HELM_ENVIRONMENT_SUCCESS`
export const LAUNCH_HELM_ENVIRONMENT_FAILURE = `${ENVIRONMENT_PREFIX}/LAUNCH_HELM_ENVIRONMENT_FAILURE`

export const REDEPLOY_HELM_ENVIRONMENT = `${ENVIRONMENT_PREFIX}/REDEPLOY_HELM_ENVIRONMENT`
export const REDEPLOY_HELM_ENVIRONMENT_SUCCESS = `${ENVIRONMENT_PREFIX}/REDEPLOY_HELM_ENVIRONMENT_SUCCESS`
export const REDEPLOY_HELM_ENVIRONMENT_FAILURE = `${ENVIRONMENT_PREFIX}/REDEPLOY_HELM_ENVIRONMENT_FAILURE`

export const FETCH_HELM_ENV_INSIGHT = `${ENVIRONMENT_PREFIX}/FETCH_HELM_ENV_INSIGHT`
export const FETCH_HELM_ENV_INSIGHT_SUCCESS = `${ENVIRONMENT_PREFIX}/FETCH_HELM_ENV_INSIGHT_SUCCESS`
export const FETCH_HELM_ENV_INSIGHT_FAILURE = `${ENVIRONMENT_PREFIX}/FETCH_HELM_ENV_INSIGHT_FAILURE`

export const FETCH_HELM_ENV_OVERVIEW = `${ENVIRONMENT_PREFIX}/FETCH_HELM_ENV_OVERVIEW`
export const FETCH_HELM_ENV_OVERVIEW_SUCCESS = `${ENVIRONMENT_PREFIX}/FETCH_HELM_ENV_OVERVIEW_SUCCESS`
export const FETCH_HELM_ENV_OVERVIEW_FAILURE = `${ENVIRONMENT_PREFIX}/FETCH_HELM_ENV_OVERVIEW_FAILURE`

export const FETCH_HELM_ENV_STATE = `${ENVIRONMENT_PREFIX}/FETCH_HELM_ENV_STATE`
export const FETCH_HELM_ENV_STATE_SUCCESS = `${ENVIRONMENT_PREFIX}/FETCH_HELM_ENV_STATE_SUCCESS`
export const FETCH_HELM_ENV_STATE_FAILURE = `${ENVIRONMENT_PREFIX}/FETCH_HELM_ENV_STATE_FAILURE`

export const FETCH_HELM_ENV_STATE_INITIATE = `${ENVIRONMENT_PREFIX}/FETCH_HELM_ENV_STATE_INITIATE`
export const FETCH_HELM_ENV_STATE_INITIATE_SUCCESS = `${ENVIRONMENT_PREFIX}/FETCH_HELM_ENV_STATE_INITIATE_SUCCESS`
export const FETCH_HELM_ENV_STATE_INITIATE_FAILURE = `${ENVIRONMENT_PREFIX}/FETCH_HELM_ENV_STATE_INITIATE_FAILURE`

export const UPDATE_HELM_ENV_STATUS = `${ENVIRONMENT_PREFIX}/UPDATE_HELM_ENV_STATUS`
export const UPDATE_HELM_ENV_STATUS_SUCCESS = `${ENVIRONMENT_PREFIX}/UPDATE_HELM_ENV_STATUS_SUCCESS`
export const UPDATE_HELM_ENV_STATUS_FAILURE = `${ENVIRONMENT_PREFIX}/UPDATE_HELM_ENV_STATUS_FAILURE`

export const FETCH_HELM_ENV_ROLE = `${ENVIRONMENT_PREFIX}/FETCH_HELM_ENV_ROLE`
export const FETCH_HELM_ENV_ROLE_SUCCESS = `${ENVIRONMENT_PREFIX}/FETCH_HELM_ENV_ROLE_SUCCESS`
export const FETCH_HELM_ENV_ROLE_FAILURE = `${ENVIRONMENT_PREFIX}/FETCH_HELM_ENV_ROLE_FAILURE`

export const FETCH_HELM_ACTIVITY_LOGS = `${ENVIRONMENT_PREFIX}/FETCH_HELM_ACTIVITY_LOGS`
export const FETCH_HELM_ACTIVITY_LOGS_SUCCESS = `${ENVIRONMENT_PREFIX}/FETCH_HELM_ACTIVITY_LOGS_SUCCESS`
export const FETCH_HELM_ACTIVITY_LOGS_FAILURE = `${ENVIRONMENT_PREFIX}/FETCH_HELM_ACTIVITY_LOGS_FAILURE`

export const FETCH_HELM_PODS = `${ENVIRONMENT_PREFIX}/FETCH_HELM_PODS`
export const FETCH_HELM_PODS_SUCCESS = `${ENVIRONMENT_PREFIX}/FETCH_HELM_PODS_SUCCESS`
export const FETCH_HELM_PODS_FAILURE = `${ENVIRONMENT_PREFIX}/FETCH_HELM_PODS_FAILURE`

export const FETCH_HELM_LOGS_INITIATE = `${ENVIRONMENT_PREFIX}/FETCH_HELM_LOGS_INITIATE`
export const FETCH_HELM_LOGS_INITIATE_SUCCESS = `${ENVIRONMENT_PREFIX}/FETCH_HELM_LOGS_INITIATE_SUCCESS`
export const FETCH_HELM_LOGS_INITIATE_FAILURE = `${ENVIRONMENT_PREFIX}/FETCH_HELM_LOGS_INITIATE_FAILURE`

export const FETCH_HELM_LOGS = `${ENVIRONMENT_PREFIX}/FETCH_HELM_LOGS`
export const FETCH_HELM_LOGS_SUCCESS = `${ENVIRONMENT_PREFIX}/FETCH_HELM_LOGS_SUCCESS`
export const FETCH_HELM_LOGS_FAILURE = `${ENVIRONMENT_PREFIX}/FETCH_HELM_LOGS_FAILURE`

export const UPDATE_HELM_ACTIVITY_LOGS = `${ENVIRONMENT_PREFIX}/UPDATE_HELM_ACTIVITY_LOGS`
export const UPDATE_HELM_ACTIVITY_LOGS_SUCCESS = `${ENVIRONMENT_PREFIX}/UPDATE_HELM_ACTIVITY_LOGS_SUCCESS`
export const UPDATE_HELM_ACTIVITY_LOGS_FAILURE = `${ENVIRONMENT_PREFIX}/UPDATE_HELM_ACTIVITY_LOGS_FAILURE`

export const UPDATE_HELM_SERVICE_DETAILS = `${ENVIRONMENT_PREFIX}/UPDATE_HELM_SERVICE_DETAILS`
export const UPDATE_HELM_SERVICE_DETAILS_SUCCESS = `${ENVIRONMENT_PREFIX}/UPDATE_HELM_SERVICE_DETAILS_SUCCESS`
export const UPDATE_HELM_SERVICE_DETAILS_FAILURE = `${ENVIRONMENT_PREFIX}/UPDATE_HELM_SERVICE_DETAILS_FAILURE`

export const SET_CURRENT_HELM_ENVIRONMENT = `${ENVIRONMENT_PREFIX}/SET_CURRENT_HELM_ENVIRONMENT`

export const GET_CHART_VERSION_CONFIG = `${ENVIRONMENT_PREFIX}/GET_CHART_VERSION_CONFIG`
export const GET_CHART_VERSION_CONFIG_SUCCESS = `${ENVIRONMENT_PREFIX}/GET_CHART_VERSION_CONFIG_SUCCESS`
export const GET_CHART_VERSION_CONFIG_FAILURE = `${ENVIRONMENT_PREFIX}/GET_CHART_VERSION_CONFIG_FAILURE`

export const CLEAR_CHART_INFO = `${ENVIRONMENT_PREFIX}/CLEAR_CHART_INFO`

export const CLEAR_ENVIRONMENT_INFO = `${ENVIRONMENT_PREFIX}/CLEAR_ENVIRONMENT_INFO`
export const CLEAR_ENVIRONMENT_DETAILS = `${ENVIRONMENT_PREFIX}/CLEAR_ENVIRONMENT_DETAILS`
export const DELETE_ENVIRONMENT = `${ENVIRONMENT_PREFIX}/DELETE_ENVIRONMENT`

export const CLEAR_HELM_LOGS = `${ENVIRONMENT_PREFIX}/CLEAR_HELM_LOGS`

export const getChartVersions = (id) => (
    {
        type: GET_CHART_VERSIONS,
        data: { id }
    }
)

export const getChartVersionConfig = (id) => (
    {
        type: GET_CHART_VERSION_CONFIG,
        data: { id }
    }
)

export const createHelmEnvironment = (payload, history) => (
    {
        type: CREATE_HELM_ENVIRONMENT,
        data: {
            payload,
            history
        }
    }
)

export const clearChartVersionConfig = () => (
    {
        type: CLEAR_CHART_INFO,
    }
)

export const clearCurrentEnvironment = () => (
    {
        type: CLEAR_ENVIRONMENT_INFO,
    }
)

export const clearHelmEnvironmentdetails = () => (
    {
        type: CLEAR_ENVIRONMENT_DETAILS,
    }
)

export const deleteHelmEnvironment = (id, appId, history) => (
    {
        type: DELETE_HELM_ENVIRONMENT,
        data: {
            id,
            appId,
            history
        }
    }
)

export const fetchHelmEnvDetails = (id) => (
    {
        type: FETCH_HELM_ENVIRONMENT_DETAIL,
        data: {
            id
        }
    }
)

export const fetchEnvRole = (id) => (
    {
        type: FETCH_HELM_ENV_ROLE,
        data: {
            id
        }
    }
)

export const fetchHelmEnvironmentState = (id) => (
    {
        type: FETCH_HELM_ENV_STATE,
        data: {
            id
        }
    }
)

export const fetchHelmPods = (id) => (
    {
        type: FETCH_HELM_PODS,
        data: {
            id
        }
    }
)

export const startfetchHelmLogs = (id, duration) => (
    {
        type: FETCH_HELM_LOGS_INITIATE,
        data: {
            id,
            duration
        }
    }
)

export const clearHelmLogsDetails = () => (
    {
        type: CLEAR_HELM_LOGS,
    }
)

export const fetchHelmLogs = (id) => (
    {
        type: FETCH_HELM_LOGS,
        data: {
            id,
        }
    }
)

export const fetchHelmEnvironmentStateInitiate = (id, history) => (
    {
        type: FETCH_HELM_ENV_STATE_INITIATE,
        data: {
            id,
            history
        }
    }
)

export const updateHelmEnvStatus = (status) => (
    {
        type: UPDATE_HELM_ENV_STATUS,
        data: {
            status
        }
    }
)

export const fetchHelmActivityLogs = (id) => (
    {
        type: FETCH_HELM_ACTIVITY_LOGS,
        data: {
            id
        }
    }
)

export const updateHelmActivityLogs = (logs) => (
    {
        type: UPDATE_HELM_ACTIVITY_LOGS,
        data: {
            logs
        }
    }
)

export const updateHelmServiceDetails = (payload) => (
    {
        type: UPDATE_HELM_SERVICE_DETAILS,
        data: {
            payload
        }
    }
)

export const fetchEnvironmentStateInitiate = () => (
    {
        type: DELETE_ENVIRONMENT,
    }
)

export const initiateFetchPackageStatus = () => (
    {
        type: DELETE_ENVIRONMENT,
    }
)

export const reCreateEnvironment = () => (
    {
        type: DELETE_ENVIRONMENT,
    }
)

export const rollbackEnv = () => (
    {
        type: DELETE_ENVIRONMENT,
    }
)

export const setCurrentEnvironment = (id) => (
    {
        type: SET_CURRENT_HELM_ENVIRONMENT,
        data: {
            id
        }
    }
)

export const startHelmEnvironment = (id) => (
    {
        type: START_HELM_ENVIRONMENT,
        data: {
            id
        }
    }
)

export const stopHelmEnvironment = (id, changeHash, history) => (
    {
        type: STOP_HELM_ENVIRONMENT,
        data: {
            id,
            changeHash,
            history,
        }
    }
)

export const redeployHelmEnvironment = (id, payload, history) => (
    {
        type: REDEPLOY_HELM_ENVIRONMENT,
        data: {
            id,
            payload,
            history
        }
    }
)

export const updateHelmEnvironment = (id, payload) => (
    {
        type: UPDATE_HELM_ENVIRONMENT,
        data: {
            id,
            payload
        }
    }
)

export const launchHelmEnvironment = (id, payload) => (
    {
        type: LAUNCH_HELM_ENVIRONMENT,
        data: {
            id,
            payload
        }
    }
)

export const fetchHelmEnvInsight = (id, payload) => (
    {
        type: FETCH_HELM_ENV_INSIGHT,
        data: {
            id,
            payload,
        }
    }
)

export const fetchHelmEnvOverview = (id, duration) => (
    {
        type: FETCH_HELM_ENV_OVERVIEW,
        data: {
            id,
            duration,
        }
    }
)