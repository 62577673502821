import React, { useEffect, useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Link } from "@material-ui/core";
interface Iprops {
  module: string;
  subModule?: string;
  action?: string;
  tooltipMsg?: string;
}

export const ModuleHelp = ({
  module,
  subModule = "",
  action = "",
  tooltipMsg = "",
}: Iprops) => {
  const baseUrl = window?.config?.REACT_APP_01CLOUD_DOCS;
  const [urlPath, setUrlPath] = useState("/");
  useEffect(() => {
    const subModulePath = subModule ? `/${subModule}` : "";
    const actionPath = action ? `/${action}` : "";

    setUrlPath(`/${module}${subModulePath}${actionPath}`);
  }, [module, subModule, action]);

  return (
    <>
      <Link href={baseUrl + urlPath} target="_blank" underline="always">
        <Tooltip title={tooltipMsg || `Explore ${module} docs`}>
          <HelpOutlineIcon fontSize="small"/>
        </Tooltip>
      </Link>
    </>
  );
};
