import { 
    GET_PROJECT_LIST_CALL,
    GET_PROJECT_LIST_CALL_SUCCESS,
    GET_PROJECT_LIST_CALL_FAILURE,
    BEGIN_LOADING_INDICATOR,
    END_LOADING_INDICATOR,
} from './actions';
import initialState from './initialState';

const reducer = (state = initialState, payload) => {
  switch (payload.type) {
    case GET_PROJECT_LIST_CALL:
      return {
        ...state,
        getProjectListCallStarted : true,
        getProjectListSuccess : false,
        getProjectListFailure : false,
      }
    case GET_PROJECT_LIST_CALL_SUCCESS:
      return {
        ...state,
        projectList : payload.data,
        getProjectListCallStarted : false,
        getProjectListSuccess : true,
        getProjectListFailure : false,
    }
    case GET_PROJECT_LIST_CALL_FAILURE:
      return {
        ...state,
        getProjectListCallStarted : false,
        getProjectListSuccess : false,
        getProjectListFailure : true,
    }
    case BEGIN_LOADING_INDICATOR : 
    return {
      ...state,
      isLoading : true
    }
  case END_LOADING_INDICATOR : 
    return {
      ...state,
      isLoading : false
  }
    default:
      return state
  }
}

export default reducer