import React from "react";
import AceEditor from "react-ace";
//import { useSelector } from "react-redux";
//import { IStoreState } from "shared/types";

import "ace-builds/src-noconflict/ext-searchbox";
import "ace-builds/src-noconflict/mode-yaml";
import "ace-builds/src-noconflict/theme-solarized_dark";
import "ace-builds/src-noconflict/theme-xcode";

// export interface IAdvancedDeploymentForm {
//     appValues?: string;
//     handleValuesChange: (value: string) => void;
//     children?: JSX.Element;
// }

export function AdvancedDeploymentForm(props) {
    let timeout;
    const onChange = (value) => {
        // Gather changes before submitting
        clearTimeout(timeout);
        timeout = setTimeout(() => props.handleValuesChange(value), 500);
    };
    // const {
    //     config: { theme },
    // } = useSelector((state) => state);

    return (
        <div className="deployment-form-tabs-data" data-test="main-container">
            <AceEditor
                data-test="editor-container"
                mode="yaml"
                theme={"xcode"}
                width="100%"
                onChange={onChange}
                setOptions={{ showPrintMargin: false }}
                editorProps={{ $blockScrolling: Infinity }}
                value={props.appValues}
                className="editor"
                fontSize="15px"
            />
            {props.children}
        </div>
    );
}

export default AdvancedDeploymentForm;
