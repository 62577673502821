const initialState = {
    cronjobList: null,
    cronjobDetails: null,
    cronjob_createdId: -1,
    cronjob_fetch: null,
    cronjob_status: null,
    cronImages: null,
    isSavingCronJob: false,
    showCronJobListLoader: false,
    isDeletingJob: false,
    cronjob_Log: null,
    fetchingCronjobList: false,
    fetchingJobDetail: false,
}

export default initialState