import {
    REGISTER,
    REGISTER_SUCCESS,
    REGISTER_FAILURE,
    OAUTH_REGISTER_DATA,
    VERIFY_EMAIL_SUCCESS,
    IS_RESEND_VERIFY_EMAIL,
    REQUEST_INVITE,
    REQUEST_INVITE_SUCCESS,
    REQUEST_INVITE_FAILURE,
    GET_INVITE_DETAILS_SUCCESS,
    GET_INVITE_DETAILS_FAILURE,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE
  } from './actions'
import initialState from './initialState'
  
  const register = (state = initialState, action) => {
    switch (action.type) {
      case REGISTER:
        return state
      case REGISTER_SUCCESS:
        return { ...state, ...action.data }
      case REGISTER_FAILURE:
        return { ...state, ...action.data, sessionToken: initialState.sessionToken }
      case OAUTH_REGISTER_DATA:
        return { ...state , oAuthRegisterData: action.data }
      case VERIFY_EMAIL_SUCCESS:
        return { ...state, emailVerifyDetails : action.data }
      case IS_RESEND_VERIFY_EMAIL:
        return { ...state, ...action.data }
      case REQUEST_INVITE: 
        return {
          ...state, 
          executingRequestInvite: true
        }
      case REQUEST_INVITE_SUCCESS:
        return {
          ...state, 
          executingRequestInvite: false
        }
      case REQUEST_INVITE_FAILURE:
        return {
          ...state, 
          executingRequestInvite: false
        }
      case GET_INVITE_DETAILS_SUCCESS:
        return {
          ...state, 
          invitationDetails: action.data
        }
      case GET_INVITE_DETAILS_FAILURE:
        return {
          ...state, 
          invitationDetails: {}
        }
      case FORGOT_PASSWORD:
        return {
          ...state,
          isRequestingForgotPassword: true
        }
      case FORGOT_PASSWORD_SUCCESS:
        return {
          ...state,
          isRequestingForgotPassword: false
        }
      case FORGOT_PASSWORD_FAILURE:
        return {
          ...state,
          isRequestingForgotPassword: false
        }
      default:
        return state
    }
  }
  
  export default register
