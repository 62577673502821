import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducer from './reducers/index';
import { LoginSaga } from '../pages/login';
import { CreateAppSaga } from '../pages/app/createapp';
// import { Sagas as TemplateSaga } from '../pages/app/createapp/redux';
import { RegisterSaga } from '../pages/registration';
import { CreateProjectSaga } from '../pages/project/createproject';
import { ProjectListSaga } from '../pages/project/projectlist';
import { Sagas as ProjectSaga } from '../pages/project/redux'
import { Sagas as EnvironmentSaga } from '../pages/environment/redux'
import { Sagas as HelmEnvironmentSaga } from '../pages/helmenvironment/redux'
import { Sagas as CICDSaga } from '../pages/environment/cicdredux'
import { Sagas as AppSaga } from '../pages/app/redux'
import { Sagas as HeaderSaga } from '../pages/header/redux'
import ProfileTabSaga from '../pages/account/profiletab/redux/saga';
import { AddUserPopupSaga } from '../components/adduserpopup';
import { Sagas as CronJobSaga } from '../pages/cronjob/redux';
import { Sagas as InitContainerSaga } from '../pages/initcontainer/redux';
import { Sagas as OrganizationSaga } from '../pages/organization/redux'
import { Sagas as SubscriptionSaga } from '../pages/subscription/redux'
import { Sagas as ResourceSaga } from '../pages/resource/redux'
import { Sagas as ClusterSaga } from '../pages/cluster/redux'
import { Sagas as PluginSaga } from '../pages/plugin/redux'
import { Sagas as CommonSaga } from '../pages/common/redux'
import { Sagas as BackupSaga } from '../pages/backup/redux'
import { Sagas as NotificationSaga } from '../pages/notification/redux'
import { Sagas as SupportSaga } from '../pages/support/redux'
import { Sagas as PaymentSaga } from "../pages/account/billingtab/redux"
import { Sagas as OperatorSaga} from "../pages/operators/redux"
import { Sagas as PodsSaga } from "../pages/pods/redux"
import { Sagas as AlertSaga } from '../pages/environment/environmentinfo/alertmanager/redux';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: [ 'AuthReducer' ]
  }

export default () => {
    const sagaMiddleware = createSagaMiddleware()
  
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose
  
    const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware))
  
    const persistedReducer = persistReducer(persistConfig, reducer)
  
    const store = createStore(persistedReducer, enhancer)
  
    const persistor = persistStore(store)
  
    sagaMiddleware.run(LoginSaga)
    // sagaMiddleware.run(TemplateSaga)
    sagaMiddleware.run(CreateAppSaga)
    sagaMiddleware.run(RegisterSaga)
    sagaMiddleware.run(ProjectSaga);
    sagaMiddleware.run(CreateProjectSaga)
    sagaMiddleware.run(ProjectListSaga)
    sagaMiddleware.run(EnvironmentSaga)
    sagaMiddleware.run(CICDSaga)
    sagaMiddleware.run(AppSaga);
    sagaMiddleware.run(ProfileTabSaga);
    sagaMiddleware.run(AddUserPopupSaga);
    sagaMiddleware.run(HeaderSaga);
    sagaMiddleware.run(CronJobSaga);
    sagaMiddleware.run(InitContainerSaga);
    sagaMiddleware.run(OrganizationSaga);
    sagaMiddleware.run(SubscriptionSaga);
    sagaMiddleware.run(ResourceSaga);
    sagaMiddleware.run(ClusterSaga);
    sagaMiddleware.run(PluginSaga);
    sagaMiddleware.run(CommonSaga);
    sagaMiddleware.run(BackupSaga);
    sagaMiddleware.run(NotificationSaga);
    sagaMiddleware.run(SupportSaga);
    sagaMiddleware.run(PaymentSaga);
    sagaMiddleware.run(HelmEnvironmentSaga);
    sagaMiddleware.run(OperatorSaga);
    sagaMiddleware.run(PodsSaga);
    sagaMiddleware.run(AlertSaga);
    
    return { store, persistor }
  }  
