import React from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  DialogTitle,
} from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from 'react-i18next';
import PaymentSummary from "../../pages/account/billingtab/PaymentSummary";
// import { makeStyles } from "@material-ui/styles";
import { makeStyles } from '@material-ui/core/styles';

interface Props extends PropsFromRedux {
  currentInvoice: number,
  openPopup: boolean,
  handleClosePopup: () => void
}

/* istanbul ignore next */
const useStyles = makeStyles((theme) => ({
  paper: {
    [theme?.breakpoints?.up('sm')]: {
      minWidth: 600,
    },
  }
}));

export const PaymentSummaryPopup = (props: Props) => {
  const [ t ] = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Dialog
        open={props.openPopup}
        onClose={props.handleClosePopup}
        keepMounted
        data-test="main-container"
        classes={{paper: classes.paper}}
      >
        <DialogTitle>
          <Typography className="dialogtitle">
            {t("Billing.BillingHistory.popupTitle")}
          </Typography>

          <IconButton
            aria-label="close"
            size="small"
            className="right"
            onClick={props.handleClosePopup}
            data-test="close-icon"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <PaymentSummary invoiceId={props.currentInvoice} data-test="payment-summary-comp"/>
        </DialogContent>
        
      </Dialog>
    </>
  );
};

/* istanbul ignore next */
const mapStateToProps = () => ({
});

/* istanbul ignore next */
const mapDispatchtoProps = () => {
  return {
  };
};

// export default connect(mapStateToProps, mapDispatchtoProps)(PaymentSummaryPopup);

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(PaymentSummaryPopup);

