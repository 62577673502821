import React, { useState } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";

type Props = {
  copyText: string | JSX.Element
  fontSize?: "small" | "inherit" | "default" | "large" | undefined
}

export const CopyIcon = (props: Props) => {
  const [toolText, setToolText] = useState("Copy");

  const copyToClipBoard = async (copyText: string) => {
    if (copyText !== "") {
      try {
        navigator.clipboard.writeText(copyText);
        setToolText("Copied");

        setTimeout(() => {
          setToolText("Copy");
        }, 1500);
      } catch {
        setToolText("Couldn't Copy");
      }
    }
  };

  return (
    <Tooltip title={toolText} data-test="main-container">
      <IconButton data-test="icon-btn">
        <FileCopyOutlinedIcon
          onClick={() => copyToClipBoard(props.copyText.toString() ?? "")}
          fontSize={props.fontSize}
          data-test="icon"
        />
      </IconButton>
    </Tooltip>
  );
};

export default CopyIcon;
