import React, { useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { getDiffBetweenDates } from "../../helpers/utils";
import { StepStatusIndicator } from "../statusindicator/statusindicator";
import { createStyles, WithStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core";
import Ansi from "ansi-to-react";

import "./cicdpopup.css";

/* istanbul ignore next */
const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        dialog: {
            //zIndex: 1302,
        },
        closeButton: {
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });

interface Props {
    isOpen: boolean;
    handleClose: () => void;
    task: any;
    step: any;
    logData: any;
}

interface DialogTitleProps extends WithStyles<typeof styles> {
    children: JSX.Element;
    onClose: () => void;
    id: string;
}

/* istanbul ignore next */
const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6" color="primary">
                {children}
            </Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

/* istanbul ignore next */
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        background: "#424242",
    },
}))(MuiDialogContent);

export default function CiLogsPopup(props: Props) {
    const logEndRef = useRef<null | HTMLDivElement>(null);
    const [dummydate, setDummydate] = React.useState<number | null>(null);
    const [timer, setTimer] = React.useState<any>(null); // to be discussed

    const { step } = props;

    const scrollToBottom = () => {
        if (logEndRef.current) {
            logEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [props.logData]);

    const didMountRef = useRef(false);
    useEffect(() => {
        if (didMountRef.current) {
            scrollToBottom();
        } else didMountRef.current = true;
    });

    useEffect(() => {
        if (props.isOpen) {
            let _timer = setInterval(() => setDummydate(Date.now()), 2000);
            setTimer(_timer);
        } else {
            setTimer(null);
        }
    }, [props.isOpen]);

    return (
        <div data-test="main-container">
            <span style={{ display: "none" }}>
                {dummydate} {timer}
            </span>
            <Dialog
                aria-labelledby="customized-dialog-title"
                open={props.isOpen}
                maxWidth="lg"
                className="modalZindex"
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={() => props.handleClose()}
                >
                    <Grid>
                        <Grid container spacing={2}>
                            <Grid item>
                                <StepStatusIndicator status={step?.status} />
                            </Grid>
                            <Grid item data-test="build-header">
                                {`${props.task} -> ${step?.name} log`}
                                {
                                    step && step.creation_time ? `(${getDiffBetweenDates(step.creation_time, step.completion_time ? step.completion_time : new Date().toUTCString()) })` : ""
                                }
                            </Grid>
                            {/* <Grid item data-test="build-timer">
                                {
                                    step && step.creation_time ? `-> ${getDiffBetweenDates(step.creation_time, step.completion_time ? step.completion_time : new Date().toUTCString()) }` : ""
                                }
                            </Grid> */}
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Typography gutterBottom className="logstxt">
                        <div className="logs-container">                           
                            {
                                props.logData && props.logData.log &&
                                <div data-test="log-div">
                                    <div className="inner">
                                        <span className="cicdPre">
                                            <Ansi>{props.logData.log.trim()}</Ansi>
                                        </span>
                                    </div>       
                                </div>
                            }
                            {
                                (!props.logData || !props.logData.log) &&
                                <>Loading...</>
                            }
                        </div>
                    </Typography>
                </DialogContent>
            </Dialog>
        </div>
    );
}
