import React from 'react'
import { Grid, Chip, Tooltip } from "@material-ui/core"
export default function TooltipChip({ title }: { title: string}) {
    return (
        <Grid item data-test="main-container">
            <Tooltip title={title} >
                <Chip style={{ maxWidth: "100px", overflow: "hidden", textOverflow: "ellipsis" }}
                    color={"primary"} variant="outlined" label={title} size="small"
                />
            </Tooltip>
        </Grid>
    )
}
