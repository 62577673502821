import { call, takeLatest, put, select, delay } from 'redux-saga/effects';
import axios from 'axios';
import endpoints from '../../../constants/endpoints';
import { sessionTokenSelector } from '../../login/redux/selectors'
import { currentOrganization } from '../../login/redux/selectors'
import { 
  FETCH_PROJECT_DETAIL,
  FETCH_PROJECT_DETAIL_SUCCESS,
  FETCH_PROJECT_DETAIL_FAILURE,
  FETCH_PROJECT_APPLIST,
  FETCH_PROJECT_APPLIST_SUCCESS,
  EDIT_PROJECT_CALL,
  EDIT_PROJECT_CALL_SUCCESS,
  EDIT_PROJECT_CALL_FAILURE,
  DELETE_PROJECT_CALL,
  DELETE_PROJECT_CALL_FAILURE,
  DELETE_PROJECT_CALL_SUCCESS,
  fetchProjectDetails,
  FETCH_PROJECT_ACTIVITIES,
  FETCH_PROJECT_ACTIVITIES_SUCCESS,
  FETCH_PROJECT_INSIGHT,
  FETCH_PROJECT_INSIGHT_SUCCESS,
  FTECH_ROLE_PROJECT,
  FETCH_ROLE_PROJECT_SUCCESS,
  GET_SIDEBAR_PROJECT_DETAILS,
  GET_SIDEBAR_PROJECT_DETAILS_SUCCESS,
  FETCH_PROJECT_ACTIVITIES_FAILURE,
  FETCH_PROJECT_APPLIST_FAILURE,
  FETCH_PROJECT_USED_RESOURCES,
  FETCH_PROJECT_USED_RESOURCES_SUCCESS,
  FETCH_PROJECT_USED_RESOURCES_FAILURE,
  FETCH_LOADBALANCER,
  FETCH_LOADBALANCER_FAILURE,
  FETCH_LOADBALANCER_SUCCESS,
  FETCH_PROJECT_LOADBALANCER,
  FETCH_PROJECT_LOADBALANCER_FAILURE, 
  FETCH_PROJECT_LOADBALANCER_SUCCESS,
  CREATE_PROJECT_LOADBALANCER,
  CREATE_PROJECT_LOADBALANCER_FAILURE,
  CREATE_PROJECT_LOADBALANCER_SUCCESS,
  DELETE_PROJECT_LOADBALANCER,
  DELETE_PROJECT_LOADBALANCER_FAILURE,
  DELETE_PROJECT_LOADBALANCER_SUCCESS,
  FETCH_LOADBALANCER_STATUS,
  FETCH_LOADBALANCER_STATUS_SUCCESS,
  FETCH_LOADBALANCER_STATUS_FAILURE,
  FETCH_PROJECT_DETAIL_ENV,
  DEACTIVATE_PROJECT_CALL,
  DEACTIVATE_PROJECT_CALL_CLEANUP,
  DEACTIVATE_PROJECT_CALL_SUCCESS,
  DEACTIVATE_PROJECT_CALL_FAILURE
 } from './actions';
import {  BEGIN_LOADING_INDICATOR, END_LOADING_INDICATOR } from '../projectlist/redux/actions';
import Toast from '../../../components/toast/Toast';
import { getProjectListApiCall } from '../projectlist/redux/actions';

function projectDetailsCall(sessionToken , payload) {
   const config = {
    headers : {
      'Authorization': 'basic ' + sessionToken
    }
  }
  return axios.get(endpoints.PROJECT_DETAILS.replace(':pId' , payload.data.id), config)
}

// Generator Call 
function* fetchProjectDetail(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const response = yield call(projectDetailsCall, sessionToken , payload)
    const data = response.data;

    if (data !== null) {
      yield put({ type: FETCH_PROJECT_DETAIL_SUCCESS, data })
    } else {
      yield put({ type: FETCH_PROJECT_DETAIL_FAILURE })
    }
  } catch (error) {
    if(error && error.response && (error.response.status === 404 || error.response.status === 401)){
      yield put({ type: FETCH_PROJECT_DETAIL_FAILURE })
    }
  }
}

function* fetchProjectDetailForEnv(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const response = yield call(projectDetailsCall, sessionToken , payload)
    const data = response.data;
    if(payload.data.callBack){
      payload.data.callBack(data);
    }
  } catch (error) {
    if(error && error.response && (error.response.status === 404 || error.response.status === 401)){
      yield put({ type: FETCH_PROJECT_DETAIL_FAILURE })
    }
  }
}

function appListCall(sessionToken , payload) {
  const config = {
   headers : {
     'Authorization': 'basic ' + sessionToken
   }
 }
 const endPoint = endpoints.GET_APP_LIST
                    .replace(':pId' , payload.data.id)
                    .replace(':plugin' , payload.data.plugin ?? "")
                    .replace(':cluster' , payload.data.cluster ?? "")
                    .replace(':query' , payload.data.search ?? "")
 return axios.get(endPoint, config)
}

// Generator Call 
function* fetchAppListByPID(payload) {
  yield put({ type: BEGIN_LOADING_INDICATOR })
 try {
   //debugger;
   const sessionToken = yield select(sessionTokenSelector)
   const response = yield call(appListCall, sessionToken , payload)
   const data = response.data;
   if (data !== null) {
    yield put({ type: END_LOADING_INDICATOR })
    yield put({ type: FETCH_PROJECT_APPLIST_SUCCESS, data })
   } else {
    yield put({ type: END_LOADING_INDICATOR })
    yield put({ type: FETCH_PROJECT_APPLIST_FAILURE })
    
  }
} catch (error) {
  yield put({ type: FETCH_PROJECT_APPLIST_FAILURE })
    yield put({ type: END_LOADING_INDICATOR })
 }
}

// edit project Axios call
function editProjectDetails(sessionToken , payload) {
  const config = {
   headers : {
     'Content-Type': 'application/json',
     'Authorization': 'basic ' + sessionToken
   }
 }
 const id = payload.data.payload['id']
 payload.data.payload.id = undefined;
 payload.data.payload = JSON.parse(JSON.stringify(payload.data.payload));
 return axios.put(endpoints.UPDATE_PROJECT_DETAILS.replace(':projectId' , id) ,payload.data.payload, config)
}

// Generator Call 
function* editProjectDetailsGenerator(payload) {
  try {
   const sessionToken = yield select(sessionTokenSelector)
   const response = yield call(editProjectDetails, sessionToken , payload )
   if (response.status === 200 || response.status === 201){
     yield put(getProjectListApiCall({}))
     yield put({ type: EDIT_PROJECT_CALL_SUCCESS })
     yield put(fetchProjectDetails(payload.data.id))
     Toast.success('Project details updated succesfully');
     yield put({ type: GET_SIDEBAR_PROJECT_DETAILS })
    } else {
      yield put({ type: EDIT_PROJECT_CALL_FAILURE })
      yield put({ type: GET_SIDEBAR_PROJECT_DETAILS })

      Toast.error(response.error)
    }
  } catch (error) {
    yield put({ type: EDIT_PROJECT_CALL_FAILURE })
    if(error && error.response && error.response.data && error.response.data.error)
      Toast.error(error.response.data.error);
    else
      Toast.error('Error while updating project details. Please try again.')
  }
}

// delete project Axios call
function deleteProject(sessionToken , payload) {
 const config = {
  headers : {
    'Content-Type': 'application/json',
    'Authorization': 'basic ' + sessionToken
  }
}
return axios.delete(endpoints.DELETE_PROJECT.replace(':projectId' , payload.data.id) , config)

}

// Generator Call 
function* deleteProjectGenerator(payload) {
try {
  const _currentOrganization = yield select(currentOrganization)
  const sessionToken = yield select(sessionTokenSelector)
  const response = yield call(deleteProject, sessionToken , payload )
  // const data = response.data;
  if (response.status === 200){
    yield put({ type: DELETE_PROJECT_CALL_SUCCESS })
    yield put({ type: GET_SIDEBAR_PROJECT_DETAILS })
    yield put(getProjectListApiCall({}))
    Toast.success('Project deleted successfully');
    if(_currentOrganization && _currentOrganization.id > 0){
      payload.data.history.push('/organization/' + _currentOrganization.id + '#projects')
    }else{
      payload.data.history.push('/projects')
    }
  } else {
    yield put({ type: DELETE_PROJECT_CALL_FAILURE })
    Toast.error(response.error);
  }
} catch (error) {
    yield put({ type: DELETE_PROJECT_CALL_FAILURE })
    if(error && error.response && error.response.data && error.response.data.error)
      Toast.error(error.response.data.error);
    else
      Toast.error("Operation failed. Please try again");
}
}

function projectActivitiesCall(sessionToken , payload) {
  const config = {
   headers : {
     'Authorization': 'basic ' + sessionToken
   },
   params: {
     page: payload.data.page,
     limit: payload.data.limit,
     action: payload.data.action,
     userid: parseInt(payload.data.userid) || "",
     datestart: payload.data.datestart,
     dateend: payload.data.dateend,
     module: payload.data.module,
   }
 }
 return axios.get(endpoints.PROJECT.GET_ACTIVITIES.replace(':pId' , payload.data.id), config)
}

// Generator Call 
function* fetchProjectActivities(payload) {
 try {
   const sessionToken = yield select(sessionTokenSelector)
   const response = yield call(projectActivitiesCall, sessionToken , payload)
   const data = response.data;
   if (data !== null) {
     yield put({ type: FETCH_PROJECT_ACTIVITIES_SUCCESS, data })
    } else {
      
      yield put({ type: FETCH_PROJECT_ACTIVITIES_FAILURE })
    }
  } catch (error) {
    yield put({ type: FETCH_PROJECT_ACTIVITIES_FAILURE })
    if(error && error.response && error.response.data && error.response.data.error)
      Toast.error(error.response.data.error);
    else
      Toast.error("Unable to get activities. Please try again");
  }
}

function projectInsightsCall(sessionToken , payload) {
  const config = {
   headers : {
     'Authorization': 'basic ' + sessionToken
   }
 }
 return axios.get(endpoints.PROJECT.GET_INSIGHTS.replace(':pId' , payload.data.id), config)
}

// Generator Call 
function* fetchProjectInsights(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const response = yield call(projectInsightsCall, sessionToken , payload)
    const data = response.data;
    if (data !== null) {
      yield put({ type: FETCH_PROJECT_INSIGHT_SUCCESS, data })
    } else {
      Toast.error("Unable to get insight. Please try again");      
    }
  } catch (error) {
    if(error && error.response && error.response.data && error.response.data.error)
    Toast.error(error.response.data.error);
    else
    Toast.error("Unable to get insight. Please try again");
    
  }
}

function fetchProjectRoleCall(sessionToken , id) {
  const config = {
    headers : {
      'Authorization': 'basic ' + sessionToken
    }
  }
  return axios.get(endpoints.GET_ROLE.replace(':id' ,id).replace(":module","project"), config)
}

function* fetchProjectRole(payload) {
 try {
   const sessionToken = yield select(sessionTokenSelector)
   const response = yield call(fetchProjectRoleCall, sessionToken , payload.data.id)
   const data = response.data;
   if (data !== null) {
     yield put({ type: FETCH_ROLE_PROJECT_SUCCESS, data })
   } else {
    Toast.error("Unable to get Project Role. Please try again");   
   }
 } catch (error) {
  if(error && error.response && error.response.data && error.response.data.error)
    Toast.error(error.response.data.error);
  else
    Toast.error("Unable to get Project Role. Please try again");

 }
}

function getSidebarProjectDetailsCall(sessionToken) {
  const config = {
   headers : {
     'Authorization': 'basic ' + sessionToken
   }
 }
 return axios.get(endpoints.PROJECT.GET_SIDEBAR_INFO, config)
}

function* getSidebarProjectDetails(payload){
  const sessionToken = yield select(sessionTokenSelector);
  try {
    const response = yield call(getSidebarProjectDetailsCall, sessionToken , payload)
    const data = response.data === null ? [] : response.data;
    yield put({ type: GET_SIDEBAR_PROJECT_DETAILS_SUCCESS, data })
    
  } catch (error) {
     //yield put({ type: END_LOADING_INDICATOR })
  }
}

function* fetchProjectUsedResources(payload)
{
  try {
  const sessionToken = yield select(sessionTokenSelector);
  const config = { headers : { 'Authorization': 'basic ' + sessionToken } }
  const response = yield axios.get(endpoints.PROJECT.GET_USED_RESOURCES.replace(':pId' ,payload.data.id), config)
  const data = response.data;
  if (data) {
    yield put({ type: FETCH_PROJECT_USED_RESOURCES_SUCCESS , data })
  } else {
    yield put({ type: FETCH_PROJECT_USED_RESOURCES_SUCCESS, data: {}}) 
   }
 } catch (error) {
    yield put({ type: FETCH_PROJECT_USED_RESOURCES_FAILURE})
    if(error && error.response && error.response.data && error.response.data.error)
      Toast.error(error.response.data.error);
  }
}

function* fetchLoadbalancers(payload)
{
  try {
  const sessionToken = yield select(sessionTokenSelector);
  const config = { headers : { 'Authorization': 'basic ' + sessionToken } }
  const response = yield axios.get(endpoints.PROJECT.GET_LOADBALANCERS.replace(':pId' ,payload.data.id), config)
  const data = response.data;
  if (data) {
    yield put({ type: FETCH_PROJECT_LOADBALANCER_SUCCESS , data })
  } else {
    yield put({ type: FETCH_PROJECT_LOADBALANCER_FAILURE, data: {}}) 
   }
 } catch (error) {
    yield put({ type: FETCH_PROJECT_LOADBALANCER_FAILURE})
    if(error && error.response && error.response.data && error.response.data.error)
      Toast.error(error.response.data.error);
  }
}

function* fetchLoadbalancer(payload)
{
  try {
  const sessionToken = yield select(sessionTokenSelector);
  const config = { headers : { 'Authorization': 'basic ' + sessionToken } }
  const response = yield axios.get(endpoints.LOADBALANCER.GET_LOADBALANCER.replace(':lId' ,payload.data.id), config)
  const data = response.data;
  if (data) {
    yield put({ type: FETCH_LOADBALANCER_SUCCESS , data })
  } else {
    yield put({ type: FETCH_LOADBALANCER_FAILURE, data: {}}) 
   }
 } catch (error) {
    yield put({ type: FETCH_LOADBALANCER_FAILURE})
    if(error && error.response && error.response.data && error.response.data.error)
      Toast.error(error.response.data.error);
  }
}

function* createLoadbalancer(payload)
{
  try {
  const sessionToken = yield select(sessionTokenSelector);
  const config = { headers : { 'Authorization': 'basic ' + sessionToken } }
  const response = yield axios.post(endpoints.LOADBALANCER.CREATE_LOADBALANCER, payload.data.payload,config)
  const data = response.data;
  if (response.status === 201) {
    yield put({ type: CREATE_PROJECT_LOADBALANCER_SUCCESS  })
    yield put({ type: FETCH_PROJECT_LOADBALANCER, 	data: { id: data.project_id }
    })
    Toast.success(data.message ?? "Loadbalancer creation initiated")

  } else {
    yield put({ type: CREATE_PROJECT_LOADBALANCER_FAILURE}) 
    Toast.error(data.message ?? "Loadbalancer creation failed")
   }
 } catch (error) {
    yield put({ type: CREATE_PROJECT_LOADBALANCER_FAILURE})
    if(error && error.response && error.response.data && error.response.data.error)
      Toast.error(error.response.data.error);
  }
}

function* deleteLoadbalancer(payload)
{
  try {
  const sessionToken = yield select(sessionTokenSelector);
  const config = { headers : { 'Authorization': 'basic ' + sessionToken } }
  const response = yield axios.delete(endpoints.LOADBALANCER.DELETE_LOADBALANCER.replace(":lId", payload.data.id),config)
  //const data = response.data;
  if (response.status === 200) {
    yield put({ type: DELETE_PROJECT_LOADBALANCER_SUCCESS  })
    yield put({ type: FETCH_PROJECT_LOADBALANCER, 	data: { id: payload.data.pId }})
    // Toast.success(data.message ?? "Loadbalancer deletion initiated")
    Toast.success("Loadbalancer deletion initiated")
  } else {
    yield put({ type: DELETE_PROJECT_LOADBALANCER_FAILURE}) 
    // Toast.error(data.message ?? "Loadbalancer deletion failed")
    Toast.error("Loadbalancer deletion failed")
   }
 } catch (error) {
    yield put({ type: DELETE_PROJECT_LOADBALANCER_FAILURE})
    if(error && error.response && error.response.data && error.response.data.error)
      Toast.error(error.response.data.error);
  }
}

function *fetchLBStatus(payload){
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = { headers : { 'Authorization': 'basic ' + sessionToken } }
    yield axios.get(endpoints.LOADBALANCER.FETCH_STATUS_CALL.replace(":lId", payload.data.id),config)
    yield delay(3000)
    const response = yield axios.get(endpoints.LOADBALANCER.FETCH_STATUS.replace(":lId", payload.data.id),config)
    const data = response.data;
    if (response.status === 200) {
      yield put({ type: FETCH_LOADBALANCER_STATUS_SUCCESS , data })
      // yield put({ type: FETCH_PROJECT_LOADBALANCER, 	data: { id: payload.data.pId }})
    } else {
      yield put({ type: FETCH_LOADBALANCER_STATUS_FAILURE}) 
     }
   } catch (error) {
      yield put({ type: FETCH_LOADBALANCER_STATUS_FAILURE})
      if(error && error.response && error.response.data && error.response.data.error)
        Toast.error(error.response.data.error);
    }
}

// delete project Axios call
function deactivateProject(sessionToken , payload) {
  const config = {
   headers : {
     'Content-Type': 'application/json',
     'Authorization': 'basic ' + sessionToken
   }
 }
 return axios.post(endpoints.PROJECT.DEACTIVATE_PROJECT.replace(':pId' , payload.data.id) ,{is_operational:payload.data.is_Operational}, config)
 
 }
 
function* deactivateProjectGenerator(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const response = yield call(deactivateProject, sessionToken , payload )
 
    if (response.status === 200){
      yield put({ type: DEACTIVATE_PROJECT_CALL_SUCCESS,data:{active:payload.data.is_Operational} })
      yield put({ type: GET_SIDEBAR_PROJECT_DETAILS })
      Toast.success(`Project ${payload.data.is_Operational?"Activated":"Deactivated"} Successfully`);
      yield put({type:DEACTIVATE_PROJECT_CALL_CLEANUP});
    } else {
      yield put({
        type: DEACTIVATE_PROJECT_CALL_FAILURE,
      
      })
      Toast.error("Project Deactivation unsuccessful")
      yield put({type:DEACTIVATE_PROJECT_CALL_CLEANUP})
    }
  } catch (error) {
    yield put({
      type: DEACTIVATE_PROJECT_CALL_FAILURE,
    
    })
     if(error && error.response && error.response.data && error.response.data.error)
       Toast.error(error.response.data.error);
     else 
       Toast.error('Project Deactivation unsuccessful');
     // yield put({
     //   type: DELETE_PROJECT_CALL_FAILURE,
     //   data: {
     //     message: 'Some error while editning Project Details',
     //   },
     // })
     yield put({type:DEACTIVATE_PROJECT_CALL_CLEANUP})
  }
 }

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(FETCH_PROJECT_DETAIL, fetchProjectDetail);
  yield takeLatest(FETCH_PROJECT_APPLIST, fetchAppListByPID);
  yield takeLatest(EDIT_PROJECT_CALL, editProjectDetailsGenerator);
  yield takeLatest(DELETE_PROJECT_CALL, deleteProjectGenerator);
  yield takeLatest(FETCH_PROJECT_ACTIVITIES, fetchProjectActivities);
  yield takeLatest(FETCH_PROJECT_INSIGHT, fetchProjectInsights);
  yield takeLatest(FTECH_ROLE_PROJECT, fetchProjectRole);
  yield takeLatest(GET_SIDEBAR_PROJECT_DETAILS, getSidebarProjectDetails);
  yield takeLatest(FETCH_PROJECT_USED_RESOURCES, fetchProjectUsedResources)
  yield takeLatest(FETCH_PROJECT_LOADBALANCER, fetchLoadbalancers)
  yield takeLatest(FETCH_LOADBALANCER, fetchLoadbalancer)
  yield takeLatest(CREATE_PROJECT_LOADBALANCER, createLoadbalancer)
  yield takeLatest(DELETE_PROJECT_LOADBALANCER, deleteLoadbalancer)
  yield takeLatest(FETCH_LOADBALANCER_STATUS, fetchLBStatus)
  //yield takeLatest(SET_CURRENT_PROJECT, getSidebarProjectDetails);
  yield takeLatest(FETCH_PROJECT_DETAIL_ENV, fetchProjectDetailForEnv);
  yield takeLatest(DEACTIVATE_PROJECT_CALL,deactivateProjectGenerator)
}