import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { getAppNameChar } from "../../helpers/utils";
import { UserModel } from "../../models/Account.model";

const backgroundColor:string[]=["#EB455F",'#1C315E',"#439A97",'#863A6F',"#645CAA","#2B4865"]
const randomIndex:number=Math.trunc(Math.random()*backgroundColor.length)
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatarContainer: { flex: 0.2, paddingLeft: theme.spacing(2) },
    avatar: {
      backgroundColor: backgroundColor[randomIndex],
      width: theme.spacing(5),
      height: theme.spacing(5),
      fontSize:16
    },
  })
);
interface Props {
    userDetails:UserModel
}
export const UserAvatar = ({ userDetails }: Props) => {
  const classes = useStyles();

  return userDetails && userDetails.image ? (
    <Avatar 
      src={
        userDetails && userDetails.image
          ? userDetails.image
          : "./Images/profile_image.png"
      }
      aria-label="user-avatar"
      className={classes.avatar}
    />
  ) : (
    <Avatar  aria-label="user-avatar" className={classes.avatar}>
      {userDetails && 
        getAppNameChar(`${userDetails.first_name} ${userDetails.last_name}`)}
    </Avatar>
  );
};
