import React, { useState, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  Button,
  TextField,
  Grid,
  DialogTitle,
  DialogActions
  //FormHelperText
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import  AutoComplete from '../autocomplete/AutoComplete';
import { useTranslation } from 'react-i18next';
//import { transition } from "../../helpers/utils";

type Group = {
  Name: string
}
type GroupPopupProps = { 
  group?: Group,
  title: string,
  member?: boolean,
  open: boolean,
  handleDisagree: () => void,
  handleAgree: (name: string) => void
}

export const AddGroupPopup = (props: GroupPopupProps) => {
  const [ name, setName ] = useState<any>('');
  const [ nameError, setNameError ] = useState(false);
  // const [ buttonDisabled, setButtonDisabled] = useState(true);
  const [ nameErrorMesssage, setNameErrorMessage ] = useState('');
  const NameInput = useRef<HTMLInputElement>();
  const [t] = useTranslation()

  const setDefault = () => {
    setName('');
    setNameErrorMessage('');
    setNameError(false);
  };

  const handledefaultfocus = () => {
    // let $this = this;
    setTimeout(() => {
      if(NameInput?.current){
        NameInput.current.focus();
      }
    }, 200);
  };

  useEffect(() => {
    handledefaultfocus();
    return () => {
      setDefault();
    };
  }, []);

  useEffect(() => {
    setName(props.group?.Name);
  }, [ props.group ]);

  const handleClose = () => {
    setDefault();
    props.handleDisagree();
  };

  const handleAgree = () => {
    setDefault();
    props.handleAgree(name);
  };

  const handleNameOnblur = () => {
    if (name?.length === 0) {
      setNameError(true);
      setNameErrorMessage(t('AddDNSPopup.fieldEmptyError'));
    }
  };
  
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 0) {
      if (props.member) {
        if (!/([\w.-]+@([\w-]+)\.+\w{2,}$)/.test(e.target.value)) {
          setNameError(true);
          setNameErrorMessage(t('AddDNSPopup.validEmailError'));
        } else {
          setNameError(false);
          setNameErrorMessage('');
        }
      } else {
        if ((!/^[A-Za-z0-9_ -]*$/.test(e.target.value))) {
          setNameError(true);
          setNameErrorMessage(t('Projects.ProjectsTab.alphanumericError'));
        } else {
          setNameError(false);
          setNameErrorMessage('');
        }
      }
    } else {
      setNameError(true);
      setNameErrorMessage(t('AddDNSPopup.fieldEmptyError'));
    }
    setName(e.target.value);
  };

  // handleAddChip = (chip) => {
  //     const allUsers = [...this.state.allUsers];
  //     allUsers.push(chip);
  //     this.setState({
  //         allUsers
  //     });
  // }

  // sendRequest = () => {
  //     if (this.props.environmentDetailsComponent) {
  //         this.addUserInEnvironmentHandler();
  //     } else {
  //         this.addUserInProjectHandler();
  //     }
  // }

  // addUserInProjectHandler = () => {
  //     const payload = {
  //         'email': this.state.userEmail,
  //         'project_id': this.props.projectDetails.id,
  //         'user_role_id': this.state.selectedRole,
  //     }
  //     this.handleClose();
  //     this.props.addUserInProjectAction(payload)
  // }

  // addUserInEnvironmentHandler = () => {
  //     const payload = {
  //         'email': this.state.userEmail,
  //         'project_id': this.props.environmentDetails.application.project_id,
  //         'user_role_id': this.state.selectedRole,
  //         'environment_id': this.props.environmentDetails.id,
  //         'application_id': this.props.environmentDetails.application_id
  //     }

  //     this.setState({
  //         isPopupOpened: false,
  //     })
  //     this.props.addUserInEnvironmentAction(payload)
  // }

  // const { userEmail, isPopupOpened } = this.state;

  const emailChange = (val: string) => {
    setName(val)
  }

  const updateError = (err: boolean, message: string) => {
    setNameError(err)
    setNameErrorMessage(message)
  }

  return (
      <Dialog
        disableEscapeKeyDown = {true}
        open={ props.open ?? false }
        //TransitionComponent={ transition }
        onClose={(_, reason) => {
          if (reason !== 'backdropClick') {
            handleClose()
          }}
				}
        keepMounted
        data-test= "dialog-box"
      >
          <DialogTitle>
              <Typography className="dialogtitle">{props.title ?? t('Cluster.ClusterStorage.add')}</Typography>

              <IconButton
                aria-label="close"
                size="small"
                className="right"
                onClick={ handleClose }
                data-test="close-icon"
              >
                  <CloseIcon />
              </IconButton>
          </DialogTitle>

          <DialogContent style={ { minWidth: 280 } } dividers>
              <Grid data-test="content-container">
                  <Grid container spacing={ 1 }>
                      <Grid item xs={ 12 }>
                          {props.member ? (
                            <AutoComplete email={name} setEmail={emailChange} updateError={updateError} data-test="email-field" />
                            ): (
                              <TextField
                                value={ name }
                                inputRef={ NameInput }
                                variant="outlined"
                                label={ props.member ? t('Environment.UserPermissionsTab.email') : t('Environment.UserPermissionsTab.name')}
                                style={ { marginRight: '1rem', width: '100%' } }
                                name="name"
                                error={ nameError }
                                helperText={ nameErrorMesssage }
                                onBlur={ handleNameOnblur }
                                onChange={ handleNameChange }
                                data-test="name-field"
                              />
                          )}
                      </Grid>
         
                  </Grid>
              </Grid>
          </DialogContent>
          <DialogActions>
              <Button
                onClick={ handleAgree }
                color="primary"
                variant="contained"
                disabled={ !name?.trim().length || nameError  }
                data-test="action-button"
              >
                  {props.group ? t('AddDNSPopup.rename') : props.member ? t('Cluster.ClusterStorage.add') : t('AddDNSPopup.create')}
              </Button>
          </DialogActions>
      </Dialog>
  );
};

export default AddGroupPopup;
