import { Button, CardActions, FormControlLabel, Grid, InputAdornment, Switch, TextField } from "@material-ui/core";
import { Formik } from "formik";
import React, { Component } from "react";
import * as Yup from "yup";
import MuiTextField from "../../components/textfield/MuiTextField";
import { withTranslation,WithTranslation } from 'react-i18next';
import { Resource } from "../../models/Environment.model";
import  {IpayloadValues} from'./Resource'

interface IProps extends WithTranslation{
  edit?:boolean;
  resourceData?:Resource;
  resourceAction:(payload: IpayloadValues) => void
}

export class ResourceForm extends Component<IProps> {
  constructor(props:IProps) {
    super(props);
    this.state = {};
  }

  submitValues = (values:IpayloadValues) => {
    if (this.props.edit && this.props.resourceData && this.props.resourceData.id) {
      values.id = this.props.resourceData.id;
    }
    this.props.resourceAction(values);
  };

  render() {
    
    const { edit, resourceData, t } = this.props;
    return (
        <div data-test="main-container">

            <Formik
              data-test="formik-component"
              initialValues={{
            resourceName:
              edit &&  resourceData && resourceData.name ? resourceData.name : "",
            cores: edit && resourceData && resourceData.cores ? ((resourceData.cores)/1000) : 0,
            memory: edit && resourceData && resourceData.memory ? resourceData.memory : 0,
            weight: edit && resourceData && resourceData.weight ? resourceData.weight : 0,
            active:edit && resourceData  ? resourceData.active : true,
          }}
              enableReinitialize={true}
              onSubmit={(values:IpayloadValues) => {
                let newValues = {
                  ...values,
                  cores: values.cores * 1000
                }
                this.submitValues(newValues)
              }}
              validationSchema={Yup.object().shape({
            resourceName: Yup.string()
              .min(2, t('Account.PasswordTab.tooShort'))
              .max(30, t('Account.PasswordTab.tooLong'))
              .required(t('Resource.ResourceForm.resourceName')),
            cores: Yup.number()
              .positive(t('Resource.ResourceForm.positive'))
              .required(t('Resource.ResourceForm.cores')),
            memory: Yup.number()
              .positive(t('Resource.ResourceForm.positive'))
              .required(t('Resource.ResourceForm.memory')),
            weight: Yup.number()
              .positive(t('Resource.ResourceForm.positive'))
              .required(t('Resource.ResourceForm.weight')),
            active: Yup.bool(),
          })}
            >
                {(props) => {
            const {
              values,
              touched,
              errors,
              dirty,
              //isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              //handleReset,
              isValid,
            } = props;

            return (
                <form onSubmit={handleSubmit}>
                    <Grid>
                        <Grid container spacing={2}>
                            <Grid item md={12} xs={12}>
                                <MuiTextField
                                  id="resourceName"
                                  error={!!errors.resourceName && touched.resourceName}
                                  label={t('Resource.ResourceForm.resourceNameLabel')}
                                  name="resourceName"
                                  data-test="resourcename-input"
                                  style={{ width: "100%" }}
                                  color="primary"
                                  margin="normal"
                                  onChange={handleChange}
                                  value={values.resourceName}
                                  onBlur={handleBlur}
                                  variant="outlined"
                        helperText={
                          errors.resourceName &&
                          touched.resourceName &&
                          errors.resourceName
                        }
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item md={4} xs={12}>
                                <TextField
                                  id="cores"
                                  error={!!errors.cores && touched.cores}
                                  label={t('Resource.ResourceForm.coresLabel')}
                                  type="number"
                                  name="cores"
                                  data-test="cores-input"
                                  style={{ width: "100%" }}
                                  color="primary"
                                  margin="normal"
                                  value={values.cores}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  variant="outlined"
                                  helperText={
                          errors.cores && touched.cores && errors.cores
                        }
                                  InputProps={{
                          endAdornment: <InputAdornment position="end">Cores</InputAdornment>,
                        }}
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <TextField
                                  id="memory"
                                  error={!!errors.memory && touched.memory}
                                  label={t('Resource.ResourceForm.memoryLabel')}
                                  type="number"
                                  name="memory"
                                  data-test="memory-input"
                                  style={{ width: "100%" }}
                                  color="primary"
                                  margin="normal"
                                  value={values.memory}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  variant="outlined"
                                  helperText={
                          errors.memory && touched.memory && errors.memory
                        }
                                  InputProps={{
                          endAdornment: <InputAdornment position="end">MB</InputAdornment>,
                        }}
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <TextField
                                  id="weight"
                                  error={!!errors.weight && touched.weight}
                                  label={t('Resource.ResourceForm.weightLabel')}
                                  type="number"
                                  name="weight"
                                  data-test="weight-input"
                                  style={{ width: "100%" }}
                                  color="primary"
                                  margin="normal"
                                  value={values.weight}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  variant="outlined"
                                  helperText={
                          errors.weight && touched.weight && errors.weight
                        }
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item md={12} xs={12}>
                                <FormControlLabel
                                  data-test="active"
                                  control={
                                      <Switch
                                        checked={Boolean(values.active)}
                                        onChange={handleChange}
                                        name="active"
                                        color="primary"
                                      />
                        }
                                  label={values.active ? t('Resource.ResourceForm.active') : t('Resource.ResourceForm.inactive')}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <CardActions className="right">
                        <Button
                          data-test="button"
                          disabled={!(isValid && dirty)}
                          className="oneRemLeftMarginSeperator"
                          color="primary"
                          type="submit"
                          variant="contained"
                        >
                            {edit ? t('Resource.ResourceForm.update') : t('Resource.ResourceForm.add')}
                        </Button>
                    </CardActions>
                </form>
            );
          }}
            </Formik>
        </div>
    );
  }
}

export default withTranslation()(ResourceForm);
