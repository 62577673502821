import {
  Grid,
  Typography,
  Select,
  Paper,
  FormControl,
  MenuItem,
  InputLabel,
  IconButton,
  GridSize,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import OperatorCatalogCard from "./OperatorCatalogCard";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect } from "react-redux";
import {
  fetchInstalledOperators,
  initiateFetchOperatorStatus,
} from "../../pages/operators/redux/actions";
import BackdropLoader from "../loader/BackdropLoader";
import NoContentImage from "../nocontentimagecontainer/NoContentImage";
import Skeleton from "@material-ui/lab/Skeleton";
import { SkeletonTheme } from "react-loading-skeleton";
import SearchField from "../searchfield/SearchField";
import { AppConstants } from "../../constants/appconstants";
import ClusterWS from "../../containers/ClusterWS";
// type definitions
import { Operator } from "../../models/Operator.model";
import { ConnectedProps } from "react-redux";
import { Dispatch } from "redux";

const useStyles = makeStyles({
  dropdown: {
    minWidth: 100,
    height: 55,
  },
  container: {
    marginTop: 20,
    minHeight: "20rem",
  },
  formControl: {},
});

//const size = 5

interface Props extends PropsFromRedux {
  region: string;
  clusterId: number;
  title: string;
  onClick: (catalog: Operator) => void;
  xs?: number;
  cardSm?: number;
  cardMd?: number;
}
export function OperatorCatalogContainer(props: Props) {
  const { clusterId } = props;
  const classes = useStyles();
  const [t] = useTranslation();
  const [searchText, setSearchText] = useState("");
  const [category, setCategory] = useState("all");
  const [provider, setProvider] = useState("all");
  //const [page, setPage] = useState(1)
  const [reset, setReset] = useState(false);
  const [clearSearch, setClearSearch] = useState(false);
  const [filterdOperators, setFilteredOperators] = useState<Operator[]>([]);
  const [categoryList, setCategoryList] = useState<string[]>([]);
  const [providersList, setProvidersList] = useState<string[]>([]);

  useEffect(() => {
    props.fetchInstalledOperators(clusterId);
    props.initiateFetchOperatorStatus(clusterId);
  }, [clusterId, reset]);

  useEffect(() => {
    if (searchText === "" && category === "all" && provider === "all") {
      setFilteredOperators(
        props.operatorCatalogs?.filter(
          (op: Operator) =>
            props.operatorStatus &&
            props.operatorStatus[op.operator_details.operator.name] ===
              AppConstants.OperatorStatus.Succeeded
        )
      );
    } else {
      const searchedOperators = props.operatorCatalogs
        ?.filter(
          (operator: Operator) =>
            searchText === "" || operator?.package_name.includes(searchText)
        )
        .filter(
          (operator: Operator) =>
            category === "all" ||
            operator?.operator_details?.operator.categories.includes(category)
        )
        .filter(
          (operator: Operator) =>
            provider === "all" ||
            operator?.operator_details?.operator.provider === provider
        )
        .filter(
          (op: Operator) =>
            props.operatorStatus &&
            props.operatorStatus[op.operator_details.operator.name] ===
              AppConstants.OperatorStatus.Succeeded
        );
      setFilteredOperators(searchedOperators);
    }
  }, [
    searchText,
    props.operatorCatalogs,
    category,
    provider,
    props.operatorStatus,
  ]);

  useEffect(() => {
    if (reset) {
      setReset(false);
    }

    if (props.operatorCatalogs?.length) {
      const tempCategories: string[] = [];
      const tempProviders: string[] = [];

      props.operatorCatalogs.forEach((operator: Operator) => {
        const temp = operator.operator_details.operator.categories.filter(
          (cat) => !tempCategories.includes(cat)
        );
        tempCategories.push(...temp);

        if (
          !tempProviders.includes(operator.operator_details.operator.provider)
        ) {
          tempProviders.push(operator.operator_details.operator.provider);
        }
      });

      setCategoryList(tempCategories);
      setProvidersList(tempProviders);
    }
  }, [props.operatorCatalogs]);

  // const fetchMoreCatalogsCall = () => {
  //     setPage(page + 1)
  // }

  useEffect(() => {
    if (reset) {
      setSearchText("");
    }
  }, [reset]);

  const handleSearch = (st: string) => {
    setSearchText(st);
  };

  const handleSearchChange = (st: string) => {
    setSearchText(st);
    if (searchText.trim()?.length === 0) {
      setClearSearch(true);
    } else {
      setClearSearch(false);
    }
  };
  const handleCategoryChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    setCategory(e.target.value as string);
  };
  const handleProviderChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    setProvider(e.target.value as string);
  };

  const resetFilters = () => {
    setReset(true);
    setCategory("all");
    setProvider("all");
    //setPage(1)
  };

  const isResetValid = () => {
    if (searchText.trim().length) {
      return true;
    }
    return false;
  };

  const handleCatalogClick = (catalog: Operator) => {
    if (props.onClick) {
      props.onClick(catalog);
    }
  };

  const filters = (
    <Grid container spacing={2} justify="flex-end">
      {isResetValid() && (
        <Grid item xs={12} sm={2} md={1}>
          <Grid container justify="flex-end">
            <IconButton onClick={() => resetFilters()}>
              <Typography variant="h5">
                {t("Operators.OperatorCatalog.reset")}
              </Typography>
            </IconButton>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} sm={4} md={3}>
        <SearchField
          label={t("Operators.OperatorCatalog.search")}
          search={searchText}
          handleSearch={handleSearch}
          handleSearchChange={handleSearchChange}
          data-test="search-box"
        />
      </Grid>
      <Grid item xs={12} sm={3} md={2}>
        <FormControl
          variant="outlined"
          className={`w-100 ${classes.formControl}`}
        >
          <InputLabel>{t("Operators.OperatorCatalog.category")}</InputLabel>

          <Select
            value={category}
            onChange={(e) => handleCategoryChange(e)}
            label={t("Operators.OperatorCatalog.category")}
            className={classes.dropdown}
          >
            <MenuItem value="all">
              <em>{"All"}</em>
            </MenuItem>
            {categoryList?.map((cat, ind) => (
              <MenuItem value={cat} key={ind}>
                <em>{cat}</em>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={3} md={2}>
        <FormControl
          variant="outlined"
          className={`w-100 ${classes.formControl}`}
        >
          <InputLabel>Provider</InputLabel>

          <Select
            value={provider}
            onChange={(e) => handleProviderChange(e)}
            label="provider"
            className={classes.dropdown}
          >
            <MenuItem value="all">
              <em>{"All"}</em>
            </MenuItem>
            {providersList?.map((pro, ind) => (
              <MenuItem value={pro} key={ind}>
                <em>{pro}</em>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );

  return (
    <>
      <ClusterWS clusterId={clusterId} />
      <div
        data-test="main-container"
        data-container="operator-selection"
        data-operatorStatus={props.operatorStatus ? "fetched" : "fetching"}
      >
        <Grid container className="listContainer" style={{ marginTop: 0 }}>
          <Grid item xs={12} md={2}>
            <Typography
              color="textPrimary"
              variant="h5"
              data-test="operator-title"
            >
              {props.title ?? t("Operators.OperatorCatalog.operatorCatalog")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={10}>
            {filters}
          </Grid>
        </Grid>
        {props.fetchingOperators && (
          <Grid
            container
            spacing={2}
            alignItems="center"
            className={classes.container}
          >
            {[0, 1, 2, 3, 4, 5, 6, 7].map((idx) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  xl={3}
                  key={idx}
                  data-test="loading-skeleton"
                >
                  <Paper>
                    <SkeletonTheme height={250}>
                      <Skeleton variant="rect" width={210} height={118} />
                      <br />
                      <br />

                      <Skeleton
                        animation="wave"
                        height={10}
                        style={{ marginBottom: 6 }}
                        width="80%"
                      />
                      <Skeleton
                        animation="wave"
                        height={10}
                        style={{ marginBottom: 6 }}
                        width="80%"
                      />
                      <Skeleton animation="wave" height={10} width="60%" />
                    </SkeletonTheme>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        )}
        {props.operatorCatalogs?.length === 0 &&
        !isResetValid() &&
        !reset &&
        !clearSearch ? (
          <NoContentImage
            message="No Installed Operators"
            alt="No Installed Operators"
            data-test="no-operators"
          />
        ) : (
          <InfiniteScroll
            dataLength={props.operatorCatalogs?.length ?? 0}
            next={() => {}}
            hasMore={false}
            loader={<h4>Loading...</h4>}
            scrollThreshold={0.95}
            style={{ overflow: "hidden" }}
            scrollableTarget="scrollableDiv"
            data-test="infinite-scroll"
          >
            <Grid container spacing={3}>
              {filterdOperators?.map((catalog, ind) => (
                <Grid
                  item
                  xs={(props.xs as GridSize) ?? 12}
                  sm={(props.cardSm as GridSize) ?? 6}
                  md={(props.cardMd as GridSize) ?? 4}
                  key={ind}
                >
                  <OperatorCatalogCard
                    catalog={catalog}
                    onClick={handleCatalogClick}
                    data-test="operator-card"
                  />
                </Grid>
              ))}
            </Grid>
            {filterdOperators?.length === 0 && (
              <NoContentImage
                message="No Operators for given filter"
                alt="No Operators"
              />
            )}
          </InfiniteScroll>
        )}
        {props.fetchingOperators && (
          <BackdropLoader message="Fetching Installed Operators" />
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  operatorCatalogs: state.OperatorReducer.operatorCatalogs,
  fetchingOperators: state.OperatorReducer.fetchingOperators,
  operatorStatus: state.OperatorReducer.operatorStatus,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchInstalledOperators: (clusterId: number) =>
    dispatch(fetchInstalledOperators(clusterId)),
  initiateFetchOperatorStatus: (clusterId: number) =>
    dispatch(initiateFetchOperatorStatus(clusterId)),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(OperatorCatalogContainer);
