import React from 'react';
import './loader.css';

const Loader = ( ) => {
    return (
        <div>
            <div>
                <div className="lds-ellipsis">
                    <img src="/images/logos/loader_icon.svg" alt="01 loader" className='loader_icon'/>
                    <div></div><div></div><div></div><div></div>
                </div>
            </div>   
        </div>
    )
};

export default Loader;